import React, { useContext } from 'react';
import ProductsViewContext from './ProductsViewContext';
import { Select } from '../index';

function ProductSorter() {
  const { order, setOrder, tableVariablesSorted } = useContext(ProductsViewContext);
  const values = tableVariablesSorted.flatMap(({ elasticPlaceholderName, name }) => [
    { value: elasticPlaceholderName, label: name },
    { value: `${elasticPlaceholderName} desc`, label: `${name} desc` },
  ]);
  return (
    <Select
      id="sorter"
      name="products[sort_by]"
      value={order}
      collection={values}
      onChange={({ target: { value } }) => setOrder(value)}
    />
  );
}

export default ProductSorter;
