/* eslint-disable jsx-a11y/label-has-for */

import React from 'react';
import PropTypes from 'prop-types';
import { without, uniq } from 'lodash';
import { Button, Row, Col, Select, Input } from '../index';
import { t } from '../../i18n';

const AUDIENCES = ['ViewContent', 'AddToCart', 'Purchase'];
const tevent = event => t(`fb_custom_audience.events.${event}`);

const FBAudienceLine = ({ id, name, value, possibleOptions, onChange, onRemove, possibleProductGroupsOptions }) => {
  const getId = field => `${id}_${field}`;
  const getName = field => `_${name}[${field}]`;

  const handleChangeEvent = event => onChange({ ...value, event: event.target.value });
  const handleChangeFBProductGroupId = event => onChange({ ...value, fb_product_group_id: event.target.value });
  const handleChangeDays = event =>
    onChange({
      ...value,
      days: /^\d+$/.test(event.target.value) ? parseInt(event.target.value, 10) || 1 : event.target.value,
    });

  return (
    <Row center>
      <input type="hidden" name={`${name}[]`} value={JSON.stringify(value)} />
      <Col>
        <Select
          id={getId('event')}
          name={getName('event')}
          doNotUseInternalState
          onChange={handleChangeEvent}
          collection={uniq(possibleOptions.concat([value.event])).map(text => ({
            key: text,
            value: text,
            label: tevent(text),
          }))}
          value={value.event}
        />
      </Col>
      <Col shrink>{t('fb_custom_audience.in_the_last')}</Col>
      <Col shrink>
        <Input
          id={getId('days')}
          doNotUseInternalState
          onChange={handleChangeDays}
          name={getName('days')}
          value={value.days}
        />
      </Col>
      {possibleProductGroupsOptions ? (
        <React.Fragment>
          <Col shrink>{t('fb_custom_audience.days_from')}</Col>
          <Col grow>
            <Select
              id={getId('fb_product_group_id')}
              name={getName('fb_product_group_id')}
              doNotUseInternalState
              onChange={handleChangeFBProductGroupId}
              collection={possibleProductGroupsOptions}
              value={value.fb_product_group_id}
            />
          </Col>
        </React.Fragment>
      ) : (
        <Col shrink>{t('fb_custom_audience.days')}</Col>
      )}
      <Col shrink>
        <Button
          tertiary
          onClick={onRemove}
          onlyIcon
          icon="trash"
          tabIndex="-1"
          data-test-id="custom-audience-field-remove"
        />
      </Col>
    </Row>
  );
};

FBAudienceLine.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.object,
  possibleOptions: PropTypes.array.isRequired,
  possibleProductGroupsOptions: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

class FBCustomAudience extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
  };

  state = {
    inclusion: [{ event: 'Purchase', days: 5 }],
    exclusion: [{ event: 'Purchase', days: 3 }],
    ...JSON.parse(this.props.value),
  };

  getPossibleEvents = array => without(AUDIENCES, ...array.map(x => x.event));

  addInclusion = () => {
    const newEvent = this.getPossibleEvents(this.state.inclusion)[0];
    this.setState({
      inclusion: [...this.state.inclusion, { event: newEvent, days: 14 }],
    });
  };

  addExclusion = () => {
    const newEvent = this.getPossibleEvents(this.state.exclusion)[0];
    this.setState({
      exclusion: [...this.state.exclusion, { event: newEvent, days: 14 }],
    });
  };

  updateValue = (type, newValue, index) => {
    const array = [...this.state[type]];
    array[index] = newValue;
    this.setState({ [type]: array });
  };

  removeValue = (type, index) => {
    this.setState({ [type]: [...this.state[type]].filter((x, i) => i !== index) });
  };

  getValidationErrors = () => {
    const { inclusion, exclusion } = this.state;

    const inclusionInvalidDates = inclusion.filter(item => !/^\d+/.test(item.days)).map(item => item.event);
    if (inclusionInvalidDates.length > 0) {
      return t('fb_custom_audience.errors.inclusion_not_days', {
        default: 'You need to set valid days for inclusion events: %{events}',
        events: inclusionInvalidDates.map(event => tevent(event)).join(', '),
      });
    }
    const exclusionInvalidDates = exclusion.filter(item => !/^\d+/.test(item.days)).map(item => item.event);
    if (exclusionInvalidDates.length > 0) {
      return t('fb_custom_audience.errors.exclusion_not_days', {
        default: 'You need to set valid days for exclusion events: %{events}',
        events: exclusionInvalidDates.map(event => tevent(event)).join(', '),
      });
    }
    const inclusionDays = inclusion.reduce((out, item) => ({ ...out, [item.event]: item.days }), {});
    const exclusionDays = exclusion.reduce((out, item) => ({ ...out, [item.event]: item.days }), {});

    const exclusionOver = Object.keys(exclusionDays).filter(
      event => inclusionDays[event] && exclusionDays[event] >= inclusionDays[event]
    );

    if (exclusionOver.length > 0) {
      return t('fb_custom_audience.errors.exclusion_days_not_match', {
        default: 'You need to set less days for exclusion events: %{events} than set in inclusion',
        events: exclusionOver.map(event => tevent(event)).join(', '),
      });
    }
    return null;
  };

  render() {
    const { id, name } = this.props;
    const { inclusion, exclusion } = this.state;
    const possibleOptionsInclusion = this.getPossibleEvents(inclusion);
    const possibleOptionsExclusion = this.getPossibleEvents(exclusion);
    const error = this.getValidationErrors();
    return (
      <div>
        <input type="hidden" name={`${name}[inclusion][]`} value="" />
        <input type="hidden" name={`${name}[exclusion][]`} value="" />

        <div className="">
          <label>{t('fb_custom_audience.inclusion')}</label>
        </div>
        {inclusion.map((value, index) => (
          <FBAudienceLine
            key={value.event}
            id={id}
            name={`${name}[inclusion]`}
            value={value}
            onChange={newValue => this.updateValue('inclusion', newValue, index)}
            onRemove={() => this.removeValue('inclusion', index)}
            possibleOptions={possibleOptionsInclusion}
          />
        ))}
        <Row>
          {inclusion.length < 3 && (
            <Button
              className="js-add-field mb-0"
              icon={'plus'}
              secondary
              onClick={this.addInclusion}
              data-test-id="add-custom-audience-button"
              type="button"
            >
              {t('fb_custom_audience.add_inclusion')}
            </Button>
          )}
        </Row>

        <div className="mt-8">
          <label>{t('fb_custom_audience.exclusion')}</label>
        </div>
        {exclusion.map((value, index) => (
          <FBAudienceLine
            key={value.event}
            id={id}
            name={`${name}[exclusion]`}
            value={value}
            onChange={newValue => this.updateValue('exclusion', newValue, index)}
            onRemove={() => this.removeValue('exclusion', index)}
            possibleOptions={possibleOptionsExclusion}
          />
        ))}
        <Row>
          {exclusion.length < 3 && (
            <Button
              className="js-add-field mb-0"
              icon={'plus'}
              secondary
              onClick={this.addExclusion}
              data-test-id="add-custom-audience-button"
              type="button"
            >
              {t('fb_custom_audience.add_exclusion')}
            </Button>
          )}
        </Row>
        {error && <span className="ValidationError">{error}</span>}
      </div>
    );
  }
}

export class FBCustomAudienceExclusion extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    productGroups: PropTypes.array,
  };

  state = {
    exclusion: [{ event: 'ViewContent', days: 10 }],
    ...JSON.parse(this.props.value),
  };

  addExclusion = () => {
    this.setState({
      exclusion: [...this.state.exclusion, { event: 'ViewContent', days: 14 }],
    });
  };

  updateValue = (type, newValue, index) => {
    const array = [...this.state[type]];
    array[index] = newValue;
    this.setState({ [type]: array });
  };

  removeValue = (type, index) => {
    this.setState({ [type]: [...this.state[type]].filter((x, i) => i !== index) });
  };

  getValidationErrors = () => {
    const { exclusion } = this.state;

    const exclusionInvalidDates = exclusion.filter(item => !/^\d+/.test(item.days)).map(item => item.event);
    if (exclusionInvalidDates.length > 0) {
      return t('fb_custom_audience.errors.exclusion_not_days', {
        default: 'You need to set valid days for exclusion events: %{events}',
        events: exclusionInvalidDates.map(event => tevent(event)).join(', '),
      });
    }
    return null;
  };

  render() {
    const { id, name, productGroups } = this.props;
    const { exclusion } = this.state;
    const error = this.getValidationErrors();
    return (
      <div>
        <input type="hidden" name={`${name}[]`} value="" />

        <div className="mt-8">
          <label>{t('fb_custom_audience.exclusion')}</label>
        </div>
        {exclusion.map((value, index) => (
          <FBAudienceLine
            key={value.event}
            id={id}
            name={name}
            value={value}
            onChange={newValue => this.updateValue('exclusion', newValue, index)}
            onRemove={() => this.removeValue('exclusion', index)}
            possibleOptions={AUDIENCES}
            possibleProductGroupsOptions={productGroups}
          />
        ))}
        <Row>
          <Button
            className="js-add-field mb-0"
            icon={'plus'}
            secondary
            onClick={this.addExclusion}
            data-test-id="add-custom-audience-button"
            type="button"
          >
            {t('fb_custom_audience.add_exclusion')}
          </Button>
        </Row>
        {error && <span className="ValidationError">{error}</span>}
      </div>
    );
  }
}

export default FBCustomAudience;
