import React, { Component, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class VideoModal extends Component {
  static propTypes = {
    videoUrl: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  setModalContainer = ref => {
    $(ref)
      .modal()
      .show();

    $(ref).on('hide.bs.modal', () => {
      if (typeof this.props.onClose === 'function') {
        this.props.onClose();
      }
    });
  };

  render() {
    const { videoUrl } = this.props;

    return ReactDOM.createPortal(
      <div
        className="modal fade"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myModalLabel"
        ref={this.setModalContainer}
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span className="Text--regular Text--gray Text--normal" aria-hidden="true">
                  &times;
                </span>
              </button>
            </div>
            <div className="modal-body pt-8">
              <div className="video-wrapper">
                <iframe
                  frameBorder="0"
                  src={`${videoUrl}?autoplay=1`}
                  width="560px"
                  height="315px"
                  title="Youtube embeded video"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>
      </div>,
      document.getElementById('react-modal-container')
    );
  }
}

export default VideoModal;

// URL MUST BE IN FORMAT: https://www.youtube.com/embed/w7aTIXF8_xU !!!!!!!

export const InliveVideoWithModal = ({ videoUrl, videoPoster }) => {
  const [show, setShow] = useState(false);

  const image =
    videoPoster || `${videoUrl.replace('https://www.youtube.com/embed/', 'https://i.ytimg.com/vi/')}/maxresdefault.jpg`;

  return (
    <React.Fragment>
      <a className="Link d-inline-block" onClick={() => setShow(true)}>
        <div className="pos-relative text-center">
          <img className="Tile-image Tile-image--small" src={image} alt="Video thumbnail source" />
          <div className="pos-absolute align-center fc-video-play" style={{ fontSize: '60px' }} />
        </div>
      </a>
      {show && <VideoModal videoUrl={videoUrl} onClose={() => setShow(false)} />}
    </React.Fragment>
  );
};

InliveVideoWithModal.propTypes = {
  videoUrl: PropTypes.string,
  videoPoster: PropTypes.string,
};
