import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Cell, Pie, PieChart } from 'recharts';
import { Col, Row, cssVariables, Tooltip, Text, Icon, Heading } from '../index';

import { formatInteger, formatPercentage, t } from '../../i18n';

const GRAPH_DEFAULT_SIZE = 42;
const GRAPH_STATE = {
  positive: {
    color: cssVariables.statusPositiveDefault,
    icon: 'check',
  },
  alert: {
    color: cssVariables.statusAttentionDefault,
    textColor: cssVariables.statusAttentionDefault,
    icon: 'error-circle',
  },
  warning: {
    color: cssVariables.statusWarningDefault,
    icon: 'warning',
  },
};

const getGraphState = percents => {
  if (percents > 75) {
    return GRAPH_STATE.positive;
  } else if (percents > 50) {
    return GRAPH_STATE.warning;
  }
  return GRAPH_STATE.alert;
};

const ExportedProductGraph = ({ totalCount, exportedCount, size = GRAPH_DEFAULT_SIZE, hasLabel = true }) => {
  const usageProductPercent = (exportedCount * 100) / totalCount;
  const graphState = getGraphState(usageProductPercent);
  const productsData = useMemo(
    () => [
      { kind: 'used', count: exportedCount, color: graphState.color },
      { kind: 'unused', count: totalCount - exportedCount, color: 'rgba(0, 22, 42, 0.1)' },
    ],
    [totalCount, exportedCount]
  );

  const graphLineWidth = (size / GRAPH_DEFAULT_SIZE) * 5;

  return (
    <Row>
      <Col shrink>
        <Tooltip
          text={t('react.exported_product_graph.exported_product_tooltip', {
            percents: formatPercentage(isNaN(usageProductPercent) ? 0 : usageProductPercent),
          })}
        >
          <div className="pos-relative">
            <PieChart width={size} height={size}>
              <Pie
                isAnimationActive={false}
                data={productsData}
                dataKey="count"
                paddingAngle={0}
                innerRadius={size / 2 - graphLineWidth}
                outerRadius={size / 2}
                startAngle={90}
                endAngle={-270}
              >
                {productsData.map(entry => (
                  <Cell key={`cell-${entry.kind}`} fill={entry.color} strokeWidth="10" stroke="rgba(0,0,0,0.01)" />
                ))}
              </Pie>
            </PieChart>
            <Icon
              className="pos-absolute align-center"
              size={Math.round(size / 2.4)}
              color={graphState.color}
              kind={graphState.icon}
            />
          </div>
        </Tooltip>
      </Col>
      {hasLabel && (
        <Col grow>
          <Text size="sm"> {t('react.exported_product_graph.exported_product_heading')}</Text>
          <div className="mt-4">
            <Heading tag="span" spacing={0} style={graphState.textColor && { color: graphState.textColor }}>
              {formatInteger(exportedCount)}
            </Heading>
            <Text size="sm" color="softGray">
              {' '}
              {t('react.exported_product_graph.exported_product_count', { count: formatInteger(totalCount) })}
            </Text>
          </div>
        </Col>
      )}
    </Row>
  );
};

ExportedProductGraph.propTypes = {
  exportedCount: PropTypes.number.isRequired,
  size: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
  hasLabel: PropTypes.bool,
};

export default ExportedProductGraph;
