import { v4 } from 'uuid';

const mapDataToInputs = (data = []) => {
  if (data.length === 0) {
    return [{ uuid: v4(), value: '' }];
  }
  return data.map(({ id, text }) => ({
    id,
    uuid: v4(),
    value: text,
  }));
};

export default mapDataToInputs;
