import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { debounce } from 'lodash';
import PlaceholderInput from '../placeholders/Input';
import { Tile, Row, Col, DndDragHandle } from '../components';
import Button from '../components/Button';
import updatePreview from './updatePreview';
import ConditionalLabelFunelCounter from './ConditionalLabelFunelCounter';
import ConditionalLabelSelectedCounter from './ConditionalLabelSelectedCounter';
import NestedFields from './index';
import { t } from '../i18n';

class ConditionalLabel extends React.PureComponent {
  static propTypes = {
    isSortingActive: PropTypes.bool,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    position: PropTypes.number,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
    label: PropTypes.string,
    conditions: PropTypes.array,
    dragHandleProps: PropTypes.any,
    _error_details: PropTypes.object,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    label: this.props.label || '',
    conditions: this.props.conditions || [],
  };

  updateFieldData = value => this.props.updateFieldData(this.props.id, value);

  updateFieldDataDebounced = debounce(this.updateFieldData, 100);
  updateConditionsDataDebounced = debounce(this.updateFieldData, 1000);
  updateAdditionalStateDataDebounced = debounce(this.updateFieldData, 1000);

  handleLabel = ({ target: { value } }) => {
    if (value !== this.state.label) {
      this.setState({ label: value });
      this.updateFieldDataDebounced({ label: value });
      updatePreview();
    }
  };

  handleConditions = updatedConditions => {
    if (this.state.conditions !== updatedConditions) {
      this.setState({ ...this.state, conditions: updatedConditions });
      this.updateConditionsDataDebounced({ conditions: updatedConditions });
    }
  };

  handleAdditionalStateChange = newAdditionalState => {
    this.setState({ ...this.state, ...newAdditionalState });
    this.updateAdditionalStateDataDebounced(newAdditionalState);
  };

  render() {
    const {
      disabled,
      getSubFiledOptions,
      onRemove,
      position,
      dragHandleProps,
      isSortingActive,
      options,
      _error_details,
    } = this.props;
    const { label } = this.state;

    const translatedErrors = _error_details?.label?.map(err => t(`errors.messages.${err.error}`));

    return (
      <React.Fragment>
        <Tile data-test-id="conditinal_labels" className={cs({ 'mb-8': isSortingActive })}>
          <Row baseline className="mb-8 pos-relative negative-ml-16">
            <Col width="25px">
              <DndDragHandle {...dragHandleProps} />
            </Col>

            <Col width="400px">
              <PlaceholderInput
                readOnly={options.special_conditional_variable}
                defaultValue={label}
                onChange={this.handleLabel}
                placeholder={t('views.modifiers.conditional_value_fields.label_name', { default: 'Label name' })}
                error={translatedErrors?.join(',')}
                {...getSubFiledOptions('label')}
              />
            </Col>
            {!isSortingActive && (
              <Col grow>
                <ConditionalLabelSelectedCounter
                  position={position}
                  id={this.props.id}
                  conditions={this.state.conditions}
                  options={this.props.options}
                />
              </Col>
            )}

            {onRemove && !options.special_conditional_variable && !isSortingActive && (
              <Col shrink>
                <Button
                  tertiary
                  onlyIcon
                  disabled={disabled}
                  onClick={onRemove}
                  tabIndex="-1"
                  icon="trash"
                  data-test-id="nested-field-remove"
                />
              </Col>
            )}
          </Row>
          {!isSortingActive && (
            <NestedFields
              formBase={getSubFiledOptions('x').formBase}
              field="conditions"
              onChange={this.handleConditions}
              onAdditionalStateChange={this.handleAdditionalStateChange}
              fieldData={this.state.conditions}
              addNewFieldText={t('views.modifiers.form_fields.and', { default: 'And' })}
              options={{
                ...this.props.options,
                enable_global_input: false,
              }}
              isNestedInNested
            />
          )}
        </Tile>
        {!isSortingActive && (
          <ConditionalLabelFunelCounter
            position={position}
            id={this.props.id}
            conditions={this.state.conditions}
            options={this.props.options}
          />
        )}
      </React.Fragment>
    );
  }
}

export default memo(ConditionalLabel);
