import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { tail } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import { CONTENT, HREF, SRC, CUSTOM_ATTRIBUTE } from './constants';
import { innerText, isValidSelector } from './utils';
import PreviewPanel from './PreviewPanel';
import Webpage from './Webpage';
import {
  Row,
  Col,
  Button,
  ErrorBox,
  SmartSelect,
  Text,
  SmallHeading,
  Loader,
  SegmentedButton,
  Tooltip,
} from '../index';
import { t } from '../../i18n';

const MATCH_CSS_SELECTOR = /\.[^\s.:)]+/g;
const VALID_CLASS_SELECTOR = /^[a-z0-9_-]+$/gi;

const SCRAPER_QUERY = gql`
  query Scrape($url: String!, $useJsBrowser: Boolean!) {
    scraperPageHtml(url: $url, useJsBrowser: $useJsBrowser)
  }
`;

export const classesToSelector = (arr = []) => {
  if (arr.length === 0) return '';
  return arr.map(v => `.${v}`).join('');
};

const getClassesForElement = el =>
  Array.from(el.classList || [])
    .sort()
    .filter(cs => cs.match(VALID_CLASS_SELECTOR));

const getTreeArray = (el, out = []) => {
  if (el.parentElement) {
    return getTreeArray(
      el.parentElement,
      out.concat({
        tag: el.tagName.toLowerCase(),
        selector: `${el.tagName.toLowerCase()}${getClassesForElement(el)
          .map(c => `.${c}`)
          .join('')}`,
        classes: getClassesForElement(el),
      })
    );
  }
  return out;
};

const selectorComplexityCreator = doc => {
  const classCount = {};

  return selector => {
    const classesComplexity = (selector.match(MATCH_CSS_SELECTOR) || []).reduce((out, cs) => {
      if (!classCount[cs]) {
        const count = doc.querySelectorAll(cs).length;
        classCount[cs] = count > 50 ? 50 : count;

        if (cs.match(/row|col/i)) {
          classCount[cs] = 50;
        }
      }
      return classCount[cs];
    }, 0);
    return (
      selector.split(' ').length * 200 +
      (selector.match(/:not/)?.length || 0) * 50 +
      (selector.match(/\./)?.length || 0) * 20 +
      selector.length +
      classesComplexity
    );
  };
};

const getUnusualClasses = (doc, classList, count) => {
  if (!doc || !classList) return [];
  if (!classList || classList.length === 0) return [];

  const mainCount = count || doc.querySelectorAll(classesToSelector(classList)).length;
  return classList.filter(x => doc.querySelectorAll(`.${x}`).length < mainCount * 3);
};

export const getNearestElement = (el, valueSource, doc) => {
  if (valueSource === CONTENT) {
    if (innerText(el.parentElement) !== innerText(el)) {
      return el;
    }
  }

  if (valueSource === HREF) {
    if (el.href) return el;
  }

  if (valueSource === SRC) {
    if (el.src) return el;
  }

  if (!el.parentElement) return null;

  return getNearestElement(el.parentElement, valueSource, doc);
};

const getSelectorLengthCreator = (doc, wantedElement) => selector => {
  if (!isValidSelector(doc, selector)) return -155;
  const foundElements = Array.from(doc.querySelectorAll(selector));
  return foundElements.find(el => el === wantedElement || el.contains(wantedElement))
    ? foundElements.length
    : foundElements.length * -1 * selector.length;
};

const getValidationUrlErrorMessage = sampleUrl => searchText => {
  const urlRegex = /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;

  if (!urlRegex.test(searchText)) {
    return { message: 'Please enter a valid URL in the correct format.' };
  }

  try {
    const hostname = new URL(sampleUrl).hostname;
    if (!searchText.includes(hostname) || hostname !== new URL(searchText).hostname) {
      return { message: 'Please enter a valid domain that matches the domain entered in your data source.' };
    }
  } catch {
    return undefined;
  }
  return undefined;
};

const getFinalSelector = (doc, wantedElement, options = {}) => {
  if (!wantedElement) return null;

  const wantedClasses = getClassesForElement(wantedElement);
  let selector = '';
  const selectorComplexity = selectorComplexityCreator(doc);
  const getSelectorLength = getSelectorLengthCreator(doc, wantedElement);

  let tagSelector = '';
  if (HREF === options.value_source) {
    tagSelector = '[href]';
  } else if (SRC === options.value_source) {
    tagSelector = '[src]';
  } else {
    tagSelector = wantedElement.tagName.toLowerCase();
  }
  selector = tagSelector;

  const fullWantedTreeLength = getSelectorLength(
    [...getTreeArray(wantedElement)]
      .reverse()
      .map(c => c.selector)
      .join(' ')
  );

  if (wantedClasses.length > 0) {
    const selectorLength = getSelectorLength(selector);

    const onlyClasses = classesToSelector(wantedClasses);
    const onlyClassesLength = getSelectorLength(onlyClasses);

    const unusualClasses = getUnusualClasses(doc, wantedClasses, fullWantedTreeLength);
    const onlyUncommonClasses = classesToSelector(unusualClasses);
    const onlyUncommonClassesLength = getSelectorLength(onlyUncommonClasses);

    const tagPlusClasses = [selector, onlyUncommonClasses].join('');
    const tagPlusClassesLength = getSelectorLength(tagPlusClasses);

    const tagPlusCommonClasses = [selector, onlyClasses].join('');
    const tagPlusCommonClassesLength = getSelectorLength(tagPlusClasses);

    if (getSelectorLength(`.${unusualClasses[0]}`) === fullWantedTreeLength) {
      selector = `.${unusualClasses[0]}`;
    } else if (onlyUncommonClassesLength === fullWantedTreeLength) {
      selector = onlyUncommonClasses;
    } else if (onlyClassesLength === fullWantedTreeLength) {
      selector = onlyClasses;
    } else if (tagPlusClassesLength === fullWantedTreeLength) {
      selector = tagPlusClasses;
    } else if (tagPlusCommonClassesLength < selectorLength) {
      selector = tagPlusCommonClasses;
    }
  }

  if (getSelectorLength(selector) === fullWantedTreeLength) return selector;

  let finalSelector = null;
  const wantedTree = tail(getTreeArray(wantedElement));

  const wantedTreeSelectors = wantedTree.reduce(
    (out, level) => {
      // eslint-disable-next-line array-callback-return
      if (finalSelector) return;
      // eslint-disable-next-line consistent-return
      return [
        ...out,
        ...level.classes.map(cs => {
          const out3 = out
            .map(o => ({ sel: `.${cs} ${o}`, count: getSelectorLength(`.${cs} ${o}`) }))
            .sort((a, b) => a.count - b.count);
          if (out3[0].count === fullWantedTreeLength) {
            finalSelector = out3[0].sel;
          }
          return out3[0].sel;
        }),
      ].flat();
    },
    [selector]
  );
  if (finalSelector) {
    return finalSelector;
  }
  const wantedTreeSelectorsWitLengths = wantedTreeSelectors.map(sel => ({ sel, count: getSelectorLength(sel) }));
  const avg =
    wantedTreeSelectorsWitLengths.reduce((out, { count }) => out + count, 0) / wantedTreeSelectorsWitLengths.length;

  return wantedTreeSelectorsWitLengths
    .filter(({ count }) => count <= avg)
    .map(({ sel }) => sel)
    .sort((a, b) => selectorComplexity(a) - selectorComplexity(b))[0];
};

const defaultOptions = {
  value_source: 'content',
  placeholder_name: '',
  select_index: '',
  value_type: 'string',
  attribute_source: '',
};
const ScraperSelector = ({ url, useJsBrowser, onSaveClose, onSaveNew, options: scraperOptions, ...rest }) => {
  const webpageRef = useRef(null);

  const [wantedElement, setWantedElement] = useState(null);
  const [finalSelector, setFinalSelector] = useState('');
  const [options, setOptions] = useState(defaultOptions);
  const [usePageCss, setUsePageCss] = useState('use-css');

  const { data, error, loading } = useQuery(SCRAPER_QUERY, {
    variables: { url, useJsBrowser },
    notifyOnNetworkStatusChange: true,
  });

  const doc = () => webpageRef?.current?.ifrm?.contentWindow?.document;
  const isWebpage = () => !!(webpageRef && webpageRef?.current);

  useEffect(() => {
    setWantedElement(null);
  }, [options.value_source]);

  useEffect(() => {
    if (isWebpage()) {
      webpageRef.current.destroyHighlight();
    }
    if (isWebpage() && finalSelector !== '' && isValidSelector(doc(), finalSelector)) {
      Array.from(doc().querySelectorAll(finalSelector)).map(el => webpageRef.current.createHighlight(el));
    }
  }, [finalSelector, wantedElement, usePageCss]);

  if (loading) {
    return (
      <div className="mv-24 text-center">
        <Loader size="big" />
      </div>
    );
  }

  const handleAddingElements = el => {
    const element = getNearestElement(el, options.value_source, doc());
    if (!element) return;

    setWantedElement(element);
    const fs = getFinalSelector(doc(), element, options);
    setFinalSelector(fs);
  };

  const handleUsingCss = value => {
    // eslint-disable-next-line no-unused-expressions
    webpageRef?.current?.removeCSS(value === 'no-css');
    setUsePageCss(value);
  };

  const validToSave = finalSelector !== '' && options.placeholder_name.length > 0;

  const isInputSelected =
    options.value_source === CONTENT && finalSelector && finalSelector !== '' && finalSelector.endsWith('input');

  const getDataToSave = () => ({
    ...options,
    value_source: isInputSelected ? CUSTOM_ATTRIBUTE : options.value_source,
    attribute_source: isInputSelected ? 'value' : '',
    css: finalSelector,
  });

  return (
    <Row data-test-id="scraper-page" height="85vh">
      <Col className="mr-16" grow>
        <Row shrink center className="pt-16">
          {rest.urls && (
            <Col grow>
              <SmartSelect
                id="scraper_current_url"
                name="scraper_current_url"
                data-test-id="scraper-selector[current-url]"
                collection={rest.urls}
                value={url}
                onChange={({ target: { value } }) => rest.changeURL(value)}
                creatable
                creatableOptions={{ getValidationError: getValidationUrlErrorMessage(rest.urls[0]) }}
              />
            </Col>
          )}
          {rest?.randomizeUrl && typeof rest?.randomizeUrl === 'function' && (
            <Col shrink>
              <Tooltip text={t('react.scraper.random_url')}>
                <Button icon="random" secondary onlyIcon onClick={rest.randomizeUrl} />
              </Tooltip>
            </Col>
          )}
          <Col shrink>
            <SegmentedButton
              value={usePageCss}
              name="scraperTag[use_css_styles]"
              id="scraperUseCSS"
              doNotUseInternalState
              onlyIcons
              onChange={({ target: { value } }) => {
                handleUsingCss(value);
              }}
              className="mb-0 ml-8"
              collection={[
                {
                  icon: 'empty-image',
                  value: 'use-css',
                  tooltipText: t('react.scraper.use_page_styles'),
                },
                {
                  icon: 'type',
                  value: 'no-css',
                  tooltipText: t('react.scraper.do_not_use_page_styles'),
                },
              ]}
            />
          </Col>
        </Row>
        <Row grow className="mb-24">
          {error ? (
            <Col grow>
              <ErrorBox withIcon isComplex>
                <SmallHeading spacing={0}>{t('react.scraper.error_webpage_heading')}</SmallHeading>
                <Text>{t('react.scraper.error_webpage')}</Text>
                <Button className="mt-4" red onClick={rest.randomizeUrl}>
                  {t('react.scraper.error_webpage_button')}
                </Button>
              </ErrorBox>
            </Col>
          ) : (
            <Webpage
              content={data.scraperPageHtml}
              valueSource={options.value_source}
              ref={webpageRef}
              onClick={({ target }) => {
                handleAddingElements(target);
              }}
            />
          )}
        </Row>
      </Col>

      <Col width="392px" padding="s" className="background-white border-left border-color-soft-gray pos-relative">
        <PreviewPanel
          error={error}
          isInputSelected={!!isInputSelected}
          wantedElement={wantedElement}
          setWantedElement={setWantedElement}
          webpageRef={webpageRef}
          finalSelector={finalSelector}
          setFinalSelector={setFinalSelector}
          options={options}
          setOptions={setOptions}
          scraperOptions={scraperOptions}
          actionButtons={[
            {
              kind: 'primary',
              disabled: !validToSave,
              'data-test-id': 'scraper-selector-save-close',
              onClick: () => onSaveClose(getDataToSave()),
              label: t('react.scraper.save_and_close'),
            },
            {
              kind: 'secondary',
              disabled: !validToSave,
              'data-test-id': 'scraper-selector-save-new',
              onClick: () => {
                onSaveNew(getDataToSave());
                setWantedElement(null);
                setFinalSelector(null);
                setOptions(defaultOptions);
              },
              label: t('react.scraper.save_and_create_another'),
            },
          ]}
        />
      </Col>
    </Row>
  );
};

ScraperSelector.propTypes = {
  onSaveClose: PropTypes.func,
  onSaveNew: PropTypes.func,
  options: PropTypes.object,
  url: PropTypes.string,
  useJsBrowser: PropTypes.bool,
};

export default ScraperSelector;
