import React from 'react';
import PropTypes from 'prop-types';
import { Icon, cssVariables } from './index';

function FieldKindIcon({ showFieldKind, className, size = '12px', inheritColor, isActive = false }) {
  return (
    <>
      <Icon
        className={className}
        kind={`field-kind-${(showFieldKind || '').replace('_', '-')}`}
        size={size}
        color={isActive ? cssVariables.iconAccent : cssVariables.iconSubtle}
        inheritColor={inheritColor}
      />
    </>
  );
}
FieldKindIcon.propTypes = {
  showFieldKind: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  inheritColor: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default FieldKindIcon;
