import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import Tile from '../Tile';
import { t } from '../../i18n';
import { Text } from '../Typography';
import Icon from '../Icon';
import Switch from '../form/Switch';
import { Col, Row } from '../layout';
import DropdownMenu from '../DropdownMenu';
import Link from '../Link';
import Badge from '../Badge';
import { DndDragHandle, DndSortableElement } from '../SortableDnd';
import UsedByGraph from '../graphs/UsedByGraph';
import { modalDataUrlOpener } from '../RemoteModal';
import FieldKindIcon from '../FieldKindIcon';
import { UPDATE_TRANSFORMATION_SUBJECT } from './Transformations';
import Tooltip from '../Tooltip';
import { TransformationsFilterT } from './Filter';

export const VARIABLE_RULE_STATUS_RUNNING = 'running';
export const VARIABLE_RULE_STATUS_PAUSED = 'paused';

const getTooltip = (appliedFeedExports, useForAllFeedExports) => {
  if (useForAllFeedExports && appliedFeedExports?.length === 0) {
    return t('react.transformations.no_feed_export_created');
  }
  return `${t('react.transformations.feed_export_usage_rule')} ${appliedFeedExports.map(({ name }) => ` ${name}`)}`;
};

const Transformation = ({ transformationSubject, dragHandleProps, filter, multipleDataSources }) => {
  const {
    transformation,
    appliedFeedExports,
    useForAllFeedExports,
    itemsCount,
    status,
    duplicateLink,
    duplicateToLink,
  } = transformationSubject;
  const isHighlighted = !filter.feedExport || appliedFeedExports.some(feed => feed.id === filter.feedExport);

  const [updateTransformationSubject, { loading }] = useMutation(UPDATE_TRANSFORMATION_SUBJECT, {
    onError: () => {
      new window.NotificationCenter().show_error(t('react.transformations.status_change_error'));
    },
  });

  const handleToggleStatus = event => {
    updateTransformationSubject({
      variables: {
        transformationSubjects: [
          {
            id: transformationSubject.id,
            status: event.target.value ? VARIABLE_RULE_STATUS_RUNNING : VARIABLE_RULE_STATUS_PAUSED,
          },
        ],
      },
    });
  };

  return (
    <div
      className="pos-relative"
      style={{ ...(isHighlighted ? {} : { opacity: '0.5' }) }}
      data-test-id={`transformation-${transformation.name}`}
    >
      <Tile smallSpaced>
        <Row center>
          <Col width="25px">
            <DndDragHandle {...dragHandleProps} />
          </Col>
          <Col shrink>
            <Switch
              onChange={handleToggleStatus}
              value={status === VARIABLE_RULE_STATUS_RUNNING}
              doNotUseInternalState
              loading={loading}
              disabled={loading}
            />
          </Col>
          <Col>
            <Row center>
              <FieldKindIcon showFieldKind={transformation.fieldKind} size="24px" className="mr-4" />
              <Link {...modalDataUrlOpener({ url: transformation.editLink, size: 'fullwithGrid' })}>
                <Text bold color="grape">
                  {transformation.name}
                </Text>
              </Link>
            </Row>
          </Col>
          <Col shrink data-test-id="applied-feed-exports">
            {useForAllFeedExports && (
              <Tooltip text={getTooltip(appliedFeedExports, useForAllFeedExports)}>
                <Badge kind="blue" size="medium">
                  {t('react.transformations.feed_export_usage_all')}
                </Badge>
              </Tooltip>
            )}
            {!useForAllFeedExports && !!appliedFeedExports.length && (
              <Tooltip text={getTooltip(appliedFeedExports)}>
                <Badge kind="lightOrange" size="medium">
                  {appliedFeedExports.length > 1
                    ? t('react.transformations.feed_export_usages', { count: appliedFeedExports.length })
                    : t('react.transformations.feed_export_usage')}
                </Badge>
              </Tooltip>
            )}
          </Col>
          <Col shrink>
            <Row center>
              <UsedByGraph
                size="md"
                products={itemsCount || 0}
                productsTotal={Number(transformation.dataSource.allItemsCount || 0)}
              />
              <Text size="sm" data-test-id="used-products">
                {itemsCount}
              </Text>
            </Row>
          </Col>
          <Col shrink>
            <DropdownMenu dataTestId="menu-dropdown">
              <Link icon="edit" {...modalDataUrlOpener({ url: transformation.editLink, size: 'fullwithGrid' })}>
                {t('react.transformations.rule_actions.edit')}
              </Link>
              <Link
                icon="duplicate"
                {...modalDataUrlOpener({
                  url: transformation.duplicateCopyLink,
                  size: 'fullwithGrid',
                })}
              >
                {t('react.transformations.rule_actions.duplicate')}
              </Link>
              <Link
                icon="duplicate-to"
                {...modalDataUrlOpener({
                  url: duplicateLink,
                })}
              >
                {t('react.transformations.rule_actions.duplicate_to_other_variable')}
              </Link>
              <Link icon="duplicate-to" href={duplicateToLink} disabled={!multipleDataSources}>
                {t('react.transformations.rule_actions.duplicate_to_other_data_source')}
              </Link>
              <Link
                danger
                icon="trash"
                data-remote="true"
                data-confirm={t('react.transformations.rule_delete_confirmation', { name: transformation.name })}
                data-commit={t('react.transformations.yes_btn')}
                data-container="body"
                data-test-id-delete={transformation.name}
                data-method="delete"
                href={transformation.deleteLink}
              >
                {t('react.transformations.rule_actions.delete')}
              </Link>
            </DropdownMenu>
          </Col>
        </Row>
      </Tile>

      <Row justifyCenter className="mv-4 Text--softGray Sortable--hiddenOnSort">
        <Icon kind="arrow-down" inheritColor />
      </Row>
    </div>
  );
};

Transformation.propTypes = {
  dragHandleProps: PropTypes.object,
  isLast: PropTypes.bool,
  transformationSubject: PropTypes.shape({
    status: PropTypes.string,
    duplicateLink: PropTypes.string,
    duplicateToLink: PropTypes.string,
    transformation: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      fieldKind: PropTypes.string,
      dataSource: PropTypes.shape({
        allItemsCount: PropTypes.string,
      }),
    }),
    appliedFeedExports: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    useForAllFeedExports: PropTypes.bool,
  }),
  filter: TransformationsFilterT,
  multipleDataSources: PropTypes.bool,
};

export default DndSortableElement(Transformation, { hasDragHandle: true });
