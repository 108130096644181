/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */

import React, { useEffect, useRef } from 'react';
import RPT from 'prop-types';
import Swiper from 'swiper';
import { Badge, ShowOnHoverElement, ShowOnHoverWrapper } from '../components/index';
import Icon from '../components/Icon';

const styles = {
  container: {
    width: '370px',
  },
};

export const AdtextCarousel = ({ adtextsCount, adtexts, id, isParent, previewJsonData: singlePreviewJsonData }) => {
  const swiper = useRef(null);

  if (adtextsCount === 1 || !isParent) {
    return (
      <Adtext
        {...JSON.parse(adtexts?.[0]?.previewJsonData || singlePreviewJsonData)}
        isParent={isParent}
        adtextsCount={adtextsCount}
      />
    );
  }
  useEffect(() => {
    swiper.current = new Swiper(`#swiper-container-${id}`, {
      autoHeight: true,
      loop: true,
      pagination: {
        el: `#swiper-pagination-${id}`,
        dynamicBullets: adtextsCount > 5,
        dynamicMainBullets: 1,
      },
      navigation: {
        nextEl: `.swiper-button-next-${id}`,
        prevEl: `.swiper-button-prev-${id}`,
      },
    });
  }, []);

  return (
    <React.Fragment>
      <ShowOnHoverWrapper>
        <div style={{ position: 'relative', width: '370px' }} className="CustomSwiper">
          <div style={styles.container} className="swiper-container" id={`swiper-container-${id}`}>
            <div className="swiper-wrapper">
              {adtexts.map(({ previewJsonData }, index) => (
                <div className="swiper-slide" key={[previewJsonData, index].join('-')}>
                  <Adtext
                    {...JSON.parse(previewJsonData)}
                    isParent={isParent}
                    adtextIndex={index + 1}
                    adtextsCount={adtextsCount}
                  />
                </div>
              ))}
            </div>
          </div>
          <ShowOnHoverElement>
            <div className={`swip-nav swip-nav-next swiper-button-next-${id}`}>
              <Icon kind="chevron-right" size="12px" />
            </div>
            <div className={`swip-nav swip-nav-prev swiper-button-prev-${id}`}>
              <Icon kind="chevron-left" size="12px" />
            </div>
            <div className="swiper-pagination" id={`swiper-pagination-${id}`} />
          </ShowOnHoverElement>
        </div>
      </ShowOnHoverWrapper>
    </React.Fragment>
  );
};

AdtextCarousel.propTypes = {
  adtextsCount: RPT.number,
  adtexts: RPT.array,
  isParent: RPT.bool,
  id: RPT.oneOfType([RPT.number, RPT.string]),
  previewJsonData: RPT.string,
};

const AdtextContainer = ({ children, url, adtextIndex, adtextsCount, isParent = false, ...rest }) => (
  <div style={{ width: '100%' }}>
    <a {...rest} className="Link--noUnderline" href={url} title="">
      <ul className="AA_d_t_ext">
        {children}
        {isParent && adtextsCount > 1 && (
          <Badge
            className="CustomSwiper-badge"
            kind="blue"
            size="medium"
            style={{ position: 'absolute', bottom: '4px', right: '4px' }}
          >
            <span>
              {adtextIndex || 1}/{adtextsCount}
            </span>
          </Badge>
        )}
      </ul>
    </a>
  </div>
);

AdtextContainer.propTypes = {
  adtextsCount: RPT.number,
  adtextIndex: RPT.number,
  children: RPT.oneOfType([RPT.object, RPT.array]),
  isParent: RPT.bool,
  url: RPT.string,
};

export const ExpandedAdtext = ({ url, headlines, english, badge, paths, description, description2, ...rest }) => (
  <AdtextContainer {...rest} url={url}>
    <li className="AA_d_t_ext-heading" dangerouslySetInnerHTML={{ __html: headlines }} />
    <li className="AA_d_t_ext-url">
      <div className={`AA_d_t_ext-badge ${english || 'czech'}`} dangerouslySetInnerHTML={{ __html: badge }} />
      <span dangerouslySetInnerHTML={{ __html: paths }} />
    </li>
    <li className="AA_d_t_ext-description" dangerouslySetInnerHTML={{ __html: description }} />
    {description2 && <li className="AA_d_t_ext-description" dangerouslySetInnerHTML={{ __html: description2 }} />}
  </AdtextContainer>
);
ExpandedAdtext.propTypes = {
  url: RPT.string,
  headlines: RPT.string,
  english: RPT.bool,
  badge: RPT.string,
  paths: RPT.string,
  description: RPT.string,
  description2: RPT.string,
};

export const DisplayResponsiveAdtext = ({
  url,
  marketing_images = [],
  square_marketing_images = [],
  portrait_marketing_images = [],
  logo_images = [],
  landscape_logo_images = [],
  display_headlines,
  long_headline,
  business_name,
  english,
  badge,
  display_descriptions,
  ...rest
}) => {
  const allImages = marketing_images
    .concat(square_marketing_images, portrait_marketing_images, logo_images, landscape_logo_images)
    .filter(i => !!i);
  return (
    <AdtextContainer {...rest} url={url}>
      <li className="AA_d_t_ext-heading normal-wrap">
        {allImages.slice(0, 2).map((image, index) => (
          <img alt="" src={image} key={index} style={{ maxHeight: '50px', maxWidth: '80px' }} className="mr-4" />
        ))}
        {allImages.length - 2 > 0 && <b>{`+ ${allImages.length - 2} more`}</b>}
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: display_headlines.map(h => h.text).join(' | '),
          }}
        />
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: long_headline.text,
          }}
        />
      </li>
      <li className="AA_d_t_ext-url">
        <div
          className={`AA_d_t_ext-badge--inverse ${english || 'czech'}`}
          dangerouslySetInnerHTML={{ __html: badge }}
        />
        <span dangerouslySetInnerHTML={{ __html: business_name.text }} />
      </li>
      <li
        className="AA_d_t_ext-description"
        dangerouslySetInnerHTML={{
          __html: display_descriptions.map(h => h.text).join(' <br /> '),
        }}
      />
    </AdtextContainer>
  );
};

DisplayResponsiveAdtext.propTypes = {
  url: RPT.string,
  marketing_images: RPT.arrayOf(RPT.string),
  portrait_marketing_images: RPT.arrayOf(RPT.string),
  square_marketing_images: RPT.arrayOf(RPT.string),
  logo_images: RPT.arrayOf(RPT.string),
  landscape_logo_images: RPT.arrayOf(RPT.string),
  display_headlines: RPT.any,
  long_headline: RPT.object,
  business_name: RPT.object,
  english: RPT.bool,
  badge: RPT.string,
  display_descriptions: RPT.any,
};

export const PerformanceMaxAdtext = ({
  url,
  marketing_images = [],
  square_marketing_images = [],
  portrait_marketing_images = [],
  logo_images = [],
  landscape_logo_images = [],
  short_headlines = [],
  long_headlines = [],
  short_descriptions = [],
  long_descriptions = [],
  english,
  badge,
  ...rest
}) => {
  const allImages = marketing_images
    .concat(portrait_marketing_images, square_marketing_images, logo_images, landscape_logo_images)
    .filter(i => !!i);
  return (
    <AdtextContainer {...rest} url={url}>
      <li className="AA_d_t_ext-heading normal-wrap">
        {allImages.slice(0, 2).map((image, index) => (
          <img alt="" src={image} key={index} style={{ maxHeight: '50px', maxWidth: '80px' }} className="mr-4" />
        ))}
        {allImages.length - 2 > 0 && <b>{`+ ${allImages.length - 2} more`}</b>}
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: short_headlines
              .concat(long_headlines)
              .map(h => h.text)
              .join(' | '),
          }}
        />
      </li>
      <li className="AA_d_t_ext-url">
        <div
          className={`AA_d_t_ext-badge--inverse ${english || 'czech'}`}
          dangerouslySetInnerHTML={{ __html: badge }}
        />
      </li>
      <li
        className="AA_d_t_ext-description"
        dangerouslySetInnerHTML={{
          __html: short_descriptions
            .concat(long_descriptions)
            .map(h => h.text)
            .join(' <br /> '),
        }}
      />
    </AdtextContainer>
  );
};

PerformanceMaxAdtext.propTypes = {
  url: RPT.string,
  marketing_images: RPT.arrayOf(RPT.string),
  portrait_marketing_images: RPT.arrayOf(RPT.string),
  square_marketing_images: RPT.arrayOf(RPT.string),
  logo_images: RPT.arrayOf(RPT.string),
  landscape_logo_images: RPT.arrayOf(RPT.string),
  short_headlines: RPT.any,
  long_headlines: RPT.any,
  short_descriptions: RPT.any,
  long_descriptions: RPT.any,
  english: RPT.bool,
  badge: RPT.string,
};

export const ResponsiveAdtext = ({ url, headlines, english, badge, paths, descriptions, ...rest }) => (
  <AdtextContainer {...rest} url={url}>
    <li className="AA_d_t_ext-heading normal-wrap">
      <span
        dangerouslySetInnerHTML={{
          __html: headlines.map(h => h.text).join(' | '),
        }}
      />
    </li>
    <li className="AA_d_t_ext-url">
      <div className={`AA_d_t_ext-badge ${english || 'czech'}`} dangerouslySetInnerHTML={{ __html: badge }} />
      <span dangerouslySetInnerHTML={{ __html: paths }} />
    </li>
    <li
      className="AA_d_t_ext-description"
      dangerouslySetInnerHTML={{
        __html: descriptions.map(h => h.text).join(' <br /> '),
      }}
    />
  </AdtextContainer>
);

ResponsiveAdtext.propTypes = {
  url: RPT.string,
  headlines: RPT.any,
  english: RPT.bool,
  badge: RPT.string,
  paths: RPT.string,
  descriptions: RPT.any,
};

export const Sitelink = ({ url, head, editLink, desc1, desc2, ...rest }) => (
  <div className="a_container" style={{ width: '200px' }}>
    <ul className="a_cont mw-100">
      <li className="head" data-test-id="sitelink-head">
        <a {...rest} href={editLink} dangerouslySetInnerHTML={{ __html: head }} />
      </li>
      <li className="desc" dangerouslySetInnerHTML={{ __html: desc1 }} data-test-id="sitelink-desc1" />
      {desc2 && <li className="desc" dangerouslySetInnerHTML={{ __html: desc2 }} data-test-id="sitelink-desc2" />}
      <li className="url" dangerouslySetInnerHTML={{ __html: url }} data-test-id="sitelink-url" />
    </ul>
  </div>
);

Sitelink.propTypes = {
  desc1: RPT.string,
  desc2: RPT.string,
  editLink: RPT.string,
  head: RPT.string,
  url: RPT.string,
};

export const DSAAdtext = ({ url, english, badge, description, description2, ...rest }) => (
  <AdtextContainer {...rest} url={url}>
    <li className="AA_d_t_ext-heading">{'[Dynamically generated headline]'}</li>
    <li className="AA_d_t_ext-url">
      <div className={`AA_d_t_ext-badge ${english || 'czech'}`} dangerouslySetInnerHTML={{ __html: badge }} />
      {'[Dynamically generated display URL]'}
    </li>
    <li className="AA_d_t_ext-description" dangerouslySetInnerHTML={{ __html: description }} />
    {description2 && <li className="AA_d_t_ext-description" dangerouslySetInnerHTML={{ __html: description2 }} />}
  </AdtextContainer>
);

DSAAdtext.propTypes = {
  url: RPT.string,
  english: RPT.bool,
  badge: RPT.string,
  description: RPT.string,
  description2: RPT.string,
};

export const SettingAdtext = ({ deprecation, url, head, english, badge, disp_url, desc1, desc2, ...rest }) => (
  <AdtextContainer
    {...rest}
    url={url}
    className="tooltip-lg js-tooltip"
    data-placement="right"
    data-title={deprecation}
  >
    <li className="pull-right mr-4 fs-tiny">
      <span className="fc-danger danger icon--md" />
    </li>
    <li className="AA_d_t_ext-heading" dangerouslySetInnerHTML={{ __html: head }} />
    <li className="AA_d_t_ext-description" dangerouslySetInnerHTML={{ __html: desc1 }} />
    <li className="AA_d_t_ext-description" dangerouslySetInnerHTML={{ __html: desc2 }} />
    <li className="AA_d_t_ext-url">
      <div className={`AA_d_t_ext-badge ${english || 'czech'}`} dangerouslySetInnerHTML={{ __html: badge }} />
      <span dangerouslySetInnerHTML={{ __html: disp_url }} />
    </li>
  </AdtextContainer>
);

SettingAdtext.propTypes = {
  deprecation: RPT.string,
  url: RPT.string,
  head: RPT.string,
  english: RPT.bool,
  badge: RPT.string,
  disp_url: RPT.string,
  desc1: RPT.string,
  desc2: RPT.string,
};

export const Adtext = props => {
  if (props.type === 'expanded') {
    return <ExpandedAdtext {...props} />;
  } else if (props.type === 'responsive') {
    return <ResponsiveAdtext {...props} />;
  } else if (props.type === 'display_responsive') {
    return <DisplayResponsiveAdtext {...props} />;
  } else if (props.type === 'performance_max') {
    return <PerformanceMaxAdtext {...props} />;
  } else if (props.type === 'dsa') {
    return <DSAAdtext {...props} />;
  } else if (props.type === 'sitelink') {
    return <Sitelink {...props} />;
  }

  return <SettingAdtext {...props} />;
};

Adtext.propTypes = {
  type: RPT.string,
};

export default Adtext;
