import React, { useContext } from 'react';
import { SearchContext } from '../index';
import { Select } from '../../form';
import { t } from '../../../i18n';

export const DataSourceFilter = () => {
  const {
    searchState,
    searchChange,
    searchOptions: { dataSources },
  } = useContext(SearchContext);
  if ((dataSources || []).length === 0) {
    return null;
  }

  const collection = dataSources.map(({ id, name }) => ({ label: name, value: id }));

  return (
    <Select
      value={searchState?.dataSourceId || 'all'}
      id="data_sources-search"
      name="table_search[data_source_id]"
      doNotUseInternalState
      medium
      noMargin
      onChange={({ target: { value } }) => searchChange('dataSourceId', value)}
      collection={[...collection, { label: t('react.card_view.all_data_sources'), value: 'all' }]}
    />
  );
};

DataSourceFilter.propTypes = {};

export default DataSourceFilter;
