import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Button, Loader, Select, Col, Text } from '../../../index';
import { useMultiEdit } from '../../MultiEditContext';
import { DEFAULT_COLUMN } from '../../MultiEditBox';
import { t } from '../../../../i18n';
import useVariablePlaceholders, { statuses } from '../hooks/useVariablePlaceholders';

const SWAP_VARIABLES = collectionKey => gql`
  mutation SwapVars($organizationId: BigInt!,$ids: [String!]!, $source: String!, $target: String! ){
    swapVariables(organizationId: $organizationId, collectionKey: "${collectionKey}", ids: $ids, source: $source, target: $target) {
      ${collectionKey} {
        id
        getPlaceholdersWithoutConditions
        possibleVariables
      }
      errors
    }
  }
`;

const VariableSelect = ({ setValue, name, ...rest }) => (
  <Col grow style={{ maxWidth: '200px', minWidth: '140px' }}>
    <Select
      {...rest}
      doNotUseInternalState
      id={`filter-varialbes[${name}]`}
      name={`filters[${name}]`}
      onChange={({ target: { value } }) => setValue(value)}
    />
  </Col>
);
VariableSelect.propTypes = {
  name: PropTypes.string,
  setValue: PropTypes.func.isRequired,
};

const SwapVariables = ({ onCompleted }) => {
  // prettier-ignore
  const { state: { multiEdit, selected, organizationId, collectionKey: tempCollectionKey } } = useMultiEdit();
  const [source, setSource] = useState(DEFAULT_COLUMN);
  const [target, setTarget] = useState(DEFAULT_COLUMN);

  const collectionKey = typeof multiEdit?.changeVariable === 'string' ? multiEdit.changeVariable : tempCollectionKey;

  const [onSubmit, { loading: mutationLoading }] = useMutation(SWAP_VARIABLES(collectionKey), {
    variables: { organizationId, ids: selected.map(id => `${id}`), source, target },
    onCompleted: res => {
      onCompleted({
        res,
        callback: () => {
          setSource(DEFAULT_COLUMN);
          setTarget(DEFAULT_COLUMN);
        },
        successMessage: t('react.multi_edit.swap_success_html', { source, target }),
      });
    },
  });

  const { uniqueVariables, allVariables, status } = useVariablePlaceholders({
    firstIsEmpty: true,
    uniqFunc: (value, allVars) => ({ value, label: allVars.find(x => x.value === value)?.label }),
    allFunc: ({ text, name, ...rest }) => ({
      ...rest,
      value: text.replace(/^_/, '').replace(/_$/, ''),
      label: name,
    }),
    keyType: 'changeVariable',
  });

  if (status === statuses.LOADING) return <Loader size="small" />;
  if (status === statuses.NO_DATA) return <Text>{t('react.multi_edit.no_variables_to_swap')}</Text>;

  return (
    <React.Fragment>
      <VariableSelect
        collection={uniqueVariables}
        disabled={mutationLoading}
        name="source"
        value={source}
        setValue={setSource}
      />
      <VariableSelect
        collection={allVariables}
        disabled={mutationLoading}
        name="target"
        value={target}
        setValue={setTarget}
      />
      <Button
        primary
        disabled={source.value === '' || target.value === ''}
        onClick={() => onSubmit()}
        loadingType="classic"
        loading={mutationLoading}
        data-test-id="multiEdit[swap-variables-button]"
        data-remote
        key={[source.value, target.value].join()}
        data-commit-class="Button Button--primary mr-8"
        data-commit={t('react.multi_edit.modal_confirm_swap')}
        data-confirm={t('react.multi_edit.swap_variables_confirm_html', { source, target })}
      >
        {t('react.multi_edit.change')}
      </Button>
    </React.Fragment>
  );
};

SwapVariables.propTypes = {
  onCompleted: PropTypes.func,
};

export default SwapVariables;
