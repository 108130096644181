document.addEventListener('DOMContentLoaded', () => {
  function dsaHandleUrlSelection() {
    $('.js-adwords-dsa-domain').each((i, el) => {
      const e = $(el);

      if ($('.js-adwords-dsa-url').val() && e.val().length === 0 && e.data('urls')[$('.js-adwords-dsa-url').val()]) {
        e.val($('.js-adwords-dsa-domain').data('urls')[$('.js-adwords-dsa-url').val()]);
      }
    });
  }

  $(document).on('change', '.js-adwords-dsa-url', dsaHandleUrlSelection);
  dsaHandleUrlSelection();
});
