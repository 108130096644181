import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from '../index';
import { t } from '../../i18n';

const ScraperButton = ({ openModal }) => (
  <Tooltip text={t('react.scraper.wizard_button_tooltip')} className="tooltip--button">
    <Button icon="wizard-stick" secondary onClick={openModal} data-test-id="scraper-wizard-button">
      {t('react.scraper.use_scraper_wizard_button')}
    </Button>
  </Tooltip>
);

ScraperButton.propTypes = {
  openModal: PropTypes.func,
};
export default ScraperButton;
