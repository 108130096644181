/* eslint-disable */
import PropTypes from 'prop-types';
import React from 'react';
import { PieChart, Pie } from 'recharts';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import Tile from './Tile';
import { t, formatNumber, formatPercentage } from '../../i18n';

const QUERY_DASHBOARD_SUBSCRIPTION_USAGE_QUERY = gql`
  query DashboardOrganizationSubscriptionUsage($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      allProducts
      productsUsed
      productsLimit
    }
  }
`;

const SUBSCRIPTION_USAGE_COLORS = {
  USED: '#82ca9d',
  UNUSED: '#ececec',
};

const BigPercentage = ({ percentage }) => (
  <div
    style={{
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      top: 45,
      fontSize: 26,
      fontWeight: 800,
      color: !isNaN(percentage) && percentage !== 0 ? SUBSCRIPTION_USAGE_COLORS.USED : SUBSCRIPTION_USAGE_COLORS.UNUSED,
    }}
  >
    {isNaN(percentage)
      ? formatPercentage(0, { precision: 0 })
      : formatPercentage(percentage, percentage > 1 ? { precision: 0 } : { precision: 2 })}
  </div>
);

export const SubscriptionUsageComponent = ({ allProducts, productsUsed, productsLimit, ctaLink, error, loading }) => {
  const percentage = (productsUsed * 100) / productsLimit;
  const chartData =
    !isNaN(percentage) && percentage !== 0
      ? [{ value: percentage }, { value: 100 - percentage, fill: SUBSCRIPTION_USAGE_COLORS.UNUSED }]
      : [{ value: 100, fill: SUBSCRIPTION_USAGE_COLORS.UNUSED }];
  const productsLeft = Math.min(productsLimit - productsUsed, allProducts - productsUsed);

  return (
    <Tile
      header={t('react.dashboard.subscription.header')}
      dashboard
      error={error}
      flexible={!error && !loading}
      fullHeight={!error && !loading}
      loading={loading}
    >
      <div className={(isNaN(percentage) || percentage === 0) && 'mt-16'} style={{ position: 'relative' }}>
        <BigPercentage percentage={percentage} />
        <div style={{ WebkitTransform: 'scaleX(-1) rotate(-90deg)', transform: 'scaleX(-1) rotate(-90deg)' }}>
          <PieChart width={120} height={120}>
            <Pie
              data={chartData}
              cx={60}
              cy={55}
              innerRadius={46}
              outerRadius={54}
              fill={SUBSCRIPTION_USAGE_COLORS.USED}
            />
          </PieChart>
        </div>
      </div>
      {!isNaN(percentage) && percentage !== 0 ? (
        <div className="text-center">
          <div className="Text Text--gray">
            {t('react.dashboard.subscription.product_stats', {
              used: formatNumber(productsUsed, { precision: 0 }),
              limit: formatNumber(productsLimit, { precision: 0 }),
            })}
          </div>
          <div className="Text Text--bold mt-8">
            {t('react.dashboard.subscription.can_advertise', {
              products: formatNumber(productsLeft, { precision: 0 }),
            })}
          </div>
        </div>
      ) : (
        <div className="Text Text--gray">{t('react.dashboard.subscription.no_products')}</div>
      )}
      {ctaLink && (
        <a href={ctaLink} className="Link mt-a" data-test-id="dashboard-subscription-link">
          {t('react.dashboard.subscription.link_text')}
        </a>
      )}
    </Tile>
  );
};

SubscriptionUsageComponent.propTypes = {
  allProducts: PropTypes.number,
  ctaLink: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  productsLimit: PropTypes.number,
  productsUsed: PropTypes.number,
};

export const SubscriptionUsage = ({ organizationId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_SUBSCRIPTION_USAGE_QUERY}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
    }}
  >
    {({ error, data, loading }) => (
      <SubscriptionUsageComponent loading={loading} error={error} {...rest} {...((data && data.organization) || {})} />
    )}
  </Query>
);

SubscriptionUsage.propTypes = {
  organizationId: PropTypes.number.isRequired,
};
