/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalContainer, ModalHeader, ModalBody, ModalFooter, ModalHeading } from '../components/Modal';
import Input from '../components/Input';
import ErrorBox from '../components/ErrorBox';
import InfoBox from '../components/InfoBox';

class Modal extends React.PureComponent {
  static propTypes = {
    accountName: PropTypes.string,
    customerId: PropTypes.string,
    onClose: PropTypes.func,
    apiCall: PropTypes.func,
    i18n: PropTypes.object,
  };

  state = {
    error: null,
    requestError: null,
    message: null,
    loading: true,
    updating: false,
    finalUrlSuffix: '',
    trackingUrlTemplate: '',
    originalFinalUrlSuffix: '',
    originalTrackingUrlTemplate: '',
  };

  componentDidMount() {
    this.getData();
  }

  getData = () =>
    this.props
      .apiCall(
        '',
        {
          query: `SELECT customer.tracking_url_template, customer.final_url_suffix FROM customer WHERE customer.id = '${this.props.customerId.replace(
            /-/g,
            ''
          )}'`,
        },
        'search'
      )
      .then(({ result: [row, ...rest] }) => {
        this.setState({
          trackingUrlTemplate: row.customer.trackingUrlTemplate,
          originalTrackingUrlTemplate: row.customer.trackingUrlTemplate,
          finalUrlSuffix: row.customer.finalUrlSuffix,
          originalFinalUrlSuffix: row.customer.finalUrlSuffix,
          loading: false,
        });
      })
      .catch(error => this.setState({ requestError: error.message, loading: false }));

  updateData = () => {
    this.setState({ updating: true, error: null, message: null });

    this.props
      .apiCall(
        'customer',
        {
          tracking_url_template: this.state.trackingUrlTemplate,
          final_url_suffix: this.state.finalUrlSuffix,
        },
        'mutate_customer'
      )
      .then(response => {
        const {
          result: { finalUrlSuffix, trackingUrlTemplate },
        } = response;
        this.setState({
          finalUrlSuffix,
          trackingUrlTemplate,
          originalTrackingUrlTemplate: trackingUrlTemplate,
          originalFinalUrlSuffix: finalUrlSuffix,
          updating: false,
          message: this.props.i18n.saved,
        });
        setTimeout(
          () =>
            this.setState({
              message: null,
            }),
          5000
        );
      })
      .catch(error => {
        const adwordsErrors = window.reacti18nAdwordsErrors || {};
        const message = error.message;
        const foundKey = Object.keys(adwordsErrors).find(key => message.indexOf(key) !== -1);
        if (foundKey) {
          this.setState({ updating: false, error: adwordsErrors[foundKey] });
        } else {
          this.setState({ updating: false, error: message });
        }
      });
  };

  canotUpdate() {
    const { originalFinalUrlSuffix, finalUrlSuffix, originalTrackingUrlTemplate, trackingUrlTemplate } = this.state;
    return originalFinalUrlSuffix === finalUrlSuffix && originalTrackingUrlTemplate === trackingUrlTemplate;
  }

  render() {
    const { accountName, i18n, onClose } = this.props;
    const { loading, updating, error, requestError, finalUrlSuffix, trackingUrlTemplate, message } = this.state;

    return (
      <ModalContainer onClose={onClose}>
        <ModalHeader>
          <ModalHeading>{accountName}</ModalHeading>
          <p>{i18n.heading}</p>
        </ModalHeader>
        <ModalBody>
          {requestError && <ErrorBox>{requestError}</ErrorBox>}
          {loading && (
            <div className="text-center">
              <div className="Loader Loader--center active" />
            </div>
          )}
          {!loading && !requestError && (
            <React.Fragment>
              <Input
                label={i18n.trackingUrlTemplate}
                hint={i18n.trackingUrlTemplateHint}
                value={trackingUrlTemplate}
                error={error}
                onChange={({ target: { value } }) => this.setState({ trackingUrlTemplate: value })}
              />
              <Input
                label={i18n.finalUrlSuffix}
                hint={i18n.finalUrlSuffixHint}
                value={finalUrlSuffix}
                onChange={({ target: { value } }) => this.setState({ finalUrlSuffix: value })}
              />
            </React.Fragment>
          )}
          {message && (
            <InfoBox className="mt-16" type="success">
              {message}
            </InfoBox>
          )}
        </ModalBody>
        {!loading && !requestError && (
          <ModalFooter>
            {updating && <div className="Loader vam mr-8 Loader--small active" />}
            <button
              className="Button Button--primary"
              onClick={this.updateData}
              disabled={this.canotUpdate() || updating}
            >
              {i18n.update}
            </button>
          </ModalFooter>
        )}
      </ModalContainer>
    );
  }
}

export default Modal;
