import React from 'react';
import { PropTypes } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { unparse } from 'papaparse';
import { hot } from 'react-hot-loader/root';
import { Button, Col, Row, Tile, Select, Chip, Text } from '../index';
import { t } from '../../i18n';
import downloadTextFile from '../../utils/downloadTextFile';
import { FEATURES } from './constants';
import { getValues, isUsed } from './utils';

const CONSOLIDATED_CHILDS_QUERY = gql`
  query consolidatedChildsQuery($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      childOrganizations {
        id
        showLink
        name
      }
    }
  }
`;

const TRANSLATIONS = {
  organization: t('feature_period.header.organization'),
  feature: t('feature_period.header.feature'),
  status: t('feature_period.header.status'),
  current_price: t('feature_period.header.not_future.pricing_level_until_yesterday'),
  forecast_price: t('feature_period.header.not_future.pricing_level_end_of_the_period'),
  current_usage: t('feature_period.header.not_future.fix.until_yesterday'),
  forecast_usage: t('feature_period.header.not_future.fix.end_of_the_period'),
  price: t('feature_period.header.price'),
  usage: t('feature_period.header.spend_usage'),
};

const constructRow = (row, period, organizations) => {
  const isSpend = row.featuresPricing[0].resolvedBy === 'spend';
  const isFeatureUsed = isUsed({ ...row });
  const status = getValues({ tarif: row.tarif, featureStatus: row.status, isFeatureUsed })?.label;

  const result = {
    [TRANSLATIONS.organization]: organizations.find(({ id }) => id === parseInt(row.organizationId, 10))?.name,
    [TRANSLATIONS.feature]: FEATURES[row.feature]?.heading,
    [TRANSLATIONS.status]: status,
  };

  if (period === 'current') {
    return {
      ...result,
      [TRANSLATIONS.current_price]: row?.price || 0,
      [TRANSLATIONS.current_usage]: isSpend ? row.spend || 0 : row.usageCount || 0,
      [TRANSLATIONS.forecast_price]: row?.forecast?.price || 0,
      [TRANSLATIONS.forecast_usage]: isSpend ? row?.forecast?.spend || 0 : row?.forecast?.usageCount || 0,
    };
  }
  return {
    ...result,
    [TRANSLATIONS.price]: row?.price || 0,
    [TRANSLATIONS.usage]: isSpend ? row.spend || 0 : row.usageCount || 0,
  };
};

export const exportCSV = ({ downloadData, period, currentOrgName, orgData, parentOrganizationId, testRun = false }) => {
  const childOrganizations = orgData?.organization?.childOrganizations || [];
  const allOrganizations = [...childOrganizations, { id: parentOrganizationId, name: currentOrgName }];

  const result = downloadData.map(row => constructRow(row, period, allOrganizations));
  const parseResult = unparse(result);
  if (testRun) return parseResult;
  return downloadTextFile(parseResult, 'subscription_summary', 'csv');
};

const PeriodChip = ({ data, handleClick, label, selectedValue, disabled }) =>
  data ? (
    <Chip
      className="mr-8"
      noWrap
      disabled={disabled}
      id={[data.state, data.value].join('-')}
      value={selectedValue === data.value}
      label={label || data.label}
      doNotUseInternalState
      onChange={() => handleClick(data.value)}
    />
  ) : null;

PeriodChip.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  label: PropTypes.string,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const PeriodChips = ({ selectedValue, selectCollection, handleClick, disabled = false }) => {
  if (!selectCollection || selectCollection?.length === 0) return null;

  const future = selectCollection?.find(({ state }) => state === 'future');
  const current = selectCollection?.find(({ state }) => state === 'current' || state === 'current_past');
  const past = selectCollection?.find(({ state }) => state === 'past');

  const filtredCollection = [
    { label: t('feature_period.header.periods_preview'), value: current?.value },
    ...selectCollection.filter(
      el => el?.value !== future?.value && el?.value !== current?.value && el?.value !== past?.value
    ),
  ];

  return (
    <Row center shrink>
      {filtredCollection.length > 1 && (
        <Col shrink style={{ maxWidth: '231px' }}>
          <Select
            name="subscription[select]"
            id="subscriptionSelect"
            data-test-id="subscriptionSelect"
            disabled={disabled}
            value={selectedValue}
            collection={filtredCollection}
            onChange={({ target: { value } }) => handleClick(value)}
          />
        </Col>
      )}
      <PeriodChip
        data={past}
        disabled={disabled}
        label={t('subscription.periods.last')}
        handleClick={handleClick}
        selectedValue={selectedValue}
      />
      <PeriodChip
        data={current}
        disabled={disabled}
        label={t('subscription.periods.current')}
        handleClick={handleClick}
        selectedValue={selectedValue}
      />
      <PeriodChip
        data={future}
        disabled={disabled}
        label={t('subscription.periods.future')}
        handleClick={handleClick}
        selectedValue={selectedValue}
      />
    </Row>
  );
};

PeriodChips.propTypes = {
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  selectCollection: PropTypes.array,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const StickyPeriodTile = ({
  parentOrganizationId,
  downloadCSV,
  selectedValue,
  selectCollection,
  handleClick,
  disabled = false,
  ...rest
}) => {
  const { data, loading } = useQuery(CONSOLIDATED_CHILDS_QUERY, {
    variables: {
      organizationId: parentOrganizationId || -1,
    },
  });

  const enableDownload = downloadCSV && parseInt(selectedValue, 10) !== -1 && !loading && data;

  return (
    <Tile className="mb-16" smallSpaced style={{ position: 'sticky', top: 0, zIndex: 108 }}>
      <Row center justifyEnd noPadding>
        <Col>
          <PeriodChips
            disabled={disabled}
            selectCollection={selectCollection}
            selectedValue={parseInt(selectedValue, 10)}
            handleClick={handleClick}
          />
        </Col>
        {enableDownload && (
          <Col shrink>
            <Button
              secondary
              disabled={disabled}
              size="medium"
              icon="download"
              data-test-id="subscription-usage-csv-btn"
              onClick={() => {
                exportCSV({
                  ...rest,
                  period: selectCollection.find(({ value }) => value === parseInt(selectedValue, 10)).state,
                  orgData: data,
                  parentOrganizationId,
                });
              }}
            >
              {t('feature_period.header.download_csv')}
            </Button>
          </Col>
        )}
      </Row>
    </Tile>
  );
};

StickyPeriodTile.propTypes = {
  downloadCSV: PropTypes.bool,
  disabled: PropTypes.bool,
  handleClick: PropTypes.func,
  parentOrganizationId: PropTypes.number,
  selectCollection: PropTypes.array,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const SubscriptionTile = ({
  dateRange,
  children,
  mainHeading,
  selectCollection,
  subheading,
  subheadingClass = 'mt-8',
  ...rest
}) => (
  <Tile {...rest}>
    <Row justifyBetween={!!((mainHeading || subheading) && (selectCollection || dateRange))} center>
      <Col grow>
        {mainHeading && <h2 className="Heading Heading--xl"> {mainHeading} </h2>}
        {subheading && <span className={subheadingClass}>{subheading}</span>}
      </Col>
      {dateRange && (
        <Col shrink>
          <Text tag="p" size="sm" color="softGray">
            {dateRange}
          </Text>
        </Col>
      )}
    </Row>
    {children}
  </Tile>
);

SubscriptionTile.propTypes = {
  dateRange: PropTypes.string,
  children: PropTypes.any,
  mainHeading: PropTypes.string,
  selectCollection: PropTypes.array,
  subheading: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  subheadingClass: PropTypes.string,
};

export default hot(SubscriptionTile);
