import React, { PureComponent } from 'react';
import OptionsContext from '../../OptionsContext';
import PropertyGroup from './PropertyGroup';
import NestedFields from '../../../../nested_fields';
import { t } from '../../../../i18n';
import { Col, Text } from '../../../index';

export default class ConditionsPanel extends PureComponent {
  render() {
    const { object } = this.props;
    return (
      <OptionsContext.Consumer>
        {({ conditionsOptions }) => (
          <PropertyGroup object={object}>
            <Col className="pos-relative pt-16 mt-l">
              <Text className="pt-4 pb-16 mb-4">{t('react.image_generator.product_set.heading')}</Text>
              <NestedFields
                key={object.uuid}
                field="conditions"
                addNewFieldText={t('react.and')}
                addNextFieldText={t('react.image_generator.product_set.add_condition_group')}
                fieldData={object.conditions || []}
                options={{ ...conditionsOptions, twoLine: true }}
                onChange={this.props.onChange.bind(this, 'conditions')}
                onInputGroupAdded={this.props.onNewProductSetsAdded}
                isNestedInNested
              />
            </Col>
          </PropertyGroup>
        )}
      </OptionsContext.Consumer>
    );
  }
}
