import React, { Component, useState } from 'react';

function ScaleAnchor(props) {
  const { boundingBox } = props;
  const style = {
    borderRightWidth: props.getPixelsScaled(2),
    borderBottomWidth: props.getPixelsScaled(2),
    width: props.getPixelsScaled(10),
    height: props.getPixelsScaled(10),
  };

  const wrapperStyle = {
    marginTop: boundingBox.height - props.getPixelsScaled(10),
    marginLeft: boundingBox.width - props.getPixelsScaled(10),
    padding: props.getPixelsScaled(15),
  };

  const [anchorHovered, setAnchorHovered] = useState(false);

  return (
    <div
      style={{ ...styles.anchorWrapper, ...(anchorHovered ? styles.anchorWrapperHovered : {}), ...wrapperStyle }}
      className={'resize-anchor-wrapper'}
      onMouseOver={() => setAnchorHovered(true)}
      onMouseOut={() => setAnchorHovered(false)}
      onMouseDown={props.onMouseDown}
    >
      <div style={{ ...styles.anchor, ...style }} className={'resize-anchor'} />
    </div>
  );
}

function RotateAnchor(props) {
  const style = {
    borderRightWidth: props.getPixelsScaled(2),
    borderTopWidth: props.getPixelsScaled(2),
    borderTopRightRadius: props.getPixelsScaled(3),
    width: props.getPixelsScaled(10),
    height: props.getPixelsScaled(10),
  };

  const wrapperStyle = {
    marginLeft: props.boundingBox.width - props.getPixelsScaled(10),
    marginTop: props.getPixelsScaled(-23),
    padding: props.getPixelsScaled(15),
  };

  const [anchorHovered, setAnchorHovered] = useState(false);

  return (
    <div
      style={{ ...styles.anchorWrapper, ...(anchorHovered ? styles.anchorWrapperHovered : {}), ...wrapperStyle }}
      className={'rotate-anchor-wrapper'}
      onMouseOver={() => setAnchorHovered(true)}
      onMouseOut={() => setAnchorHovered(false)}
      onMouseDown={props.onMouseDown}
    >
      <div style={{ ...styles.anchor, ...style }} className={'rotate-anchor'} />
    </div>
  );
}

class Handler extends Component {
  onMouseDown(event) {
    // event.preventDefault();
    if (event.target.classList.contains('handler')) {
      this.props.onDrag(event);
    }
  }

  getPixelsScaled = pixel => pixel / this.props.scale;

  render() {
    const {
      boundingBox,
      multiSelect,
      canRotate,
      canResize,
      onRotate,
      onResize,
      onMouseLeave,
      onDoubleClick,
    } = this.props;

    const handlerStyle = {
      ...styles.handler,
      ...boundingBox,
      width: boundingBox.width + this.getPixelsScaled(10),
      height: boundingBox.height + this.getPixelsScaled(10),
      left: boundingBox.left - this.getPixelsScaled(5),
      top: boundingBox.top - this.getPixelsScaled(5),
      border: `${this.getPixelsScaled(2)}px solid #dedede`,
      transform: `rotate(${boundingBox.rotate}deg)`,
      pointerEvents: multiSelect ? 'none' : 'all',
    };

    return (
      <div
        className={'handler'}
        style={handlerStyle}
        onMouseLeave={onMouseLeave}
        onDoubleClick={onDoubleClick}
        onMouseDown={this.onMouseDown.bind(this)}
      >
        {canRotate && (
          <RotateAnchor getPixelsScaled={this.getPixelsScaled} onMouseDown={onRotate} boundingBox={boundingBox} />
        )}
        {canResize && (
          <ScaleAnchor getPixelsScaled={this.getPixelsScaled} onMouseDown={onResize} boundingBox={boundingBox} />
        )}
      </div>
    );
  }
}

const styles = {
  handler: {
    position: 'absolute',
    border: '2px solid #dedede',
    zIndex: 100,
  },
  anchorWrapper: {
    position: 'absolute',
    zIndex: -1,
    borderColor: '#dedede',
  },
  anchorWrapperHovered: {
    borderColor: 'gray',
  },
  anchor: {
    border: '0px solid',
    borderColor: 'inherit',
  },
};

export default Handler;
