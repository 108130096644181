import React from 'react';
import { SortableElement, SortableContainer } from '../../Sortable';
import Layer from './Layer';
import { t } from '../../../i18n';

const SortableLayer = SortableElement(Layer);

const SortableLayers = SortableContainer(
  ({
    objects,
    showHandler,
    onObjectChange,
    removeObject,
    duplicateObject,
    selectedObjectIndex,
    selectedObjectsIndexes,
    selectObjects,
  }) => (
    <div className="mt-24">
      {objects.length === 0 && (
        <div className="Text Text--gray pa-16 text-center">{t('react.image_generator.layer.no_layers')}</div>
      )}
      {objects.map((object, index) => (
        <SortableLayer
          index={index}
          key={object.uuid}
          object={object}
          objectIndex={objects.length - index - 1}
          selectObjects={selectObjects}
          selectedObjectIndex={selectedObjectIndex}
          selectedObjectsIndexes={selectedObjectsIndexes}
          onClick={() => showHandler(index, { forceSingleSelect: true })}
          onObjectChange={onObjectChange(index)}
          removeObject={removeObject(index)}
          duplicateObject={duplicateObject(index)}
        />
      ))}
    </div>
  )
);

export default SortableLayers;
