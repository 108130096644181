import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Row, Col, UsedByGraph, Text, SectionHeading, SmallHeading, Ellipsis } from '../index';
import { formatNumberInflection } from '../../i18n';

const ListHeader = ({
  heading,
  smallHeading,
  newManualCampaign,
  chartSize,
  itemsCount,
  useOnlyProducts,
  useUsageProducts,
  adSetCount,
  dataSourceStillLoading,
}) => (
  <div className="Preview-tileItem Preview-tileItem--noBorder pl-0 pv-0 mb-16">
    <Row center>
      <Col grow>
        {heading && (
          <Ellipsis>
            {smallHeading ? (
              <SmallHeading spacing={0}>{heading}</SmallHeading>
            ) : (
              <SectionHeading spacing={0}>{heading}</SectionHeading>
            )}
          </Ellipsis>
        )}
      </Col>
      <Col width="316px">
        <Row center>
          <Col shrink className={cs('mr-4')} style={newManualCampaign ? { paddingLeft: '6px' } : null}>
            <UsedByGraph
              size={chartSize}
              products={itemsCount}
              adSets={useOnlyProducts || useUsageProducts ? -1 : adSetCount}
              adSetsTotal={useOnlyProducts || useUsageProducts ? -1 : adSetCount}
              kind="remaining"
              productsTotal={itemsCount}
              doNotShowPercent={dataSourceStillLoading}
            />
          </Col>
          <Col shrink>
            <Text color="gray">
              {useUsageProducts &&
                formatNumberInflection({
                  count: itemsCount,
                  scope: 'react.fb_campaign_preview.products',
                  zeroIsSpecial: true,
                })}
              {!useUsageProducts &&
                (useOnlyProducts
                  ? formatNumberInflection({
                      count: itemsCount,
                      scope: 'react.fb_campaign_preview.ad_groups_inflected',
                      zeroIsSpecial: true,
                    })
                  : formatNumberInflection({
                      count: adSetCount,
                      scope: 'react.fb_campaign_preview.adsets_inflected',
                      zeroIsSpecial: true,
                    }))}
            </Text>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);

ListHeader.propTypes = {
  adSetCount: PropTypes.number,
  dataSourceStillLoading: PropTypes.bool,
  heading: PropTypes.string,
  chartSize: PropTypes.string,
  itemsCount: PropTypes.number,
  newManualCampaign: PropTypes.bool,
  smallHeading: PropTypes.bool,
  useOnlyProducts: PropTypes.bool,
  useUsageProducts: PropTypes.bool,
};

export default ListHeader;
