/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class FeedExportFacebookImage extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    url: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    tag: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
    right_part_array: [],
  };

  state = {
    url: this.props.url || '',
    tag: this.props.tag || '',
  };

  handleUrl = ({ target: { value } }) => {
    if (value !== this.state.url) {
      this.setState({ url: value });
      this.props.updateFieldData(this.props.id, { url: value });
      updatePreview();
    }
  };

  handleTag = ({ target: { value } }) => {
    if (value !== this.state.tag) {
      this.setState({ tag: value });
      this.props.updateFieldData(this.props.id, { tag: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { tag, url } = this.state;

    return (
      <Row padding="l" center data-test-id="nested-facebook_image" className="mb-8">
        <Col width="25%" data-test-id="feed_export_facebook_image-url" className="mr-8">
          <PlaceholderInput
            defaultValue={url}
            onChange={this.handleUrl}
            placeholder={t('views.feed_export_facebook_image.fields.url', { default: 'Url' })}
            {...getSubFiledOptions('url')}
          />
        </Col>
        <Col grow data-test-id="feed_export_facebook_image-value">
          <PlaceholderInput
            defaultValue={tag}
            onChange={this.handleTag}
            placeholder={t('views.feed_export_facebook_image.fields.value', { default: 'Tag' })}
            {...getSubFiledOptions('tag')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={'Example: <span class="Text--bold">123</span>'}
          data-field={`feed_export_facebook_image.${url}`}
        >
          <div className="Text">
            {t('views.feed_export.example')} <span className="Text--bold">124</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default FeedExportFacebookImage;
