import React from 'react';
import { Tooltip, Icon, cssVariables, Row } from '../index';
import { t } from '../../i18n';

// temporary solution unless we don't have radio buttons in React
const CropRadioButtons = ({ name, value, options, onChange }) => {
  const changeHandler = e => {
    onChange(e.target.value);
  };

  return (
    <>
      <div className="Radios radio_buttons">
        {options.map(option => (
          <span className="radio Radios-item" key={option.value}>
            <input
              className="Radios-input radio_buttons required"
              type="radio"
              value={option.value}
              name={name}
              id={`${name}_${option.value}`}
              onChange={changeHandler}
              checked={String(value) === String(option.value)}
              disabled={option.disabled}
            />
            <label className="collection_radio_buttons" htmlFor={`${name}_${option.value}`}>
              <Row center>
                <span>{option.label}</span>
                {option.disabled && (
                  <Tooltip
                    text={t('media.does_not_fit_aspect_ratio', {
                      default:
                        'The selected image does not fit the required aspect ratio, which could affect how your ad appears.',
                    })}
                  >
                    <Icon
                      color={cssVariables.textGhost}
                      kind="info-circle-fill"
                      size="16px"
                      style={{ marginLeft: '8px' }}
                    />
                  </Tooltip>
                )}
              </Row>
            </label>
          </span>
        ))}
      </div>
    </>
  );
};

export default CropRadioButtons;
