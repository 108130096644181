import { createAdtextFieldTextFromObject, createAdtextFieldText } from './createAdtextField';

const CATEGORY = 'ad_performance_adtext';
const creatorObject = createAdtextFieldTextFromObject(CATEGORY);
const creatorField = createAdtextFieldText(CATEGORY);

const mapping = {
  ...creatorObject('performanceMaxShortHeadline1'),
  ...creatorObject('performanceMaxShortHeadline2'),
  ...creatorObject('performanceMaxShortHeadline3'),
  ...creatorObject('performanceMaxShortHeadline4'),
  ...creatorObject('performanceMaxShortHeadline5'),
  ...creatorObject('performanceMaxLongHeadline1'),
  ...creatorObject('performanceMaxLongHeadline2'),
  ...creatorObject('performanceMaxLongHeadline3'),
  ...creatorObject('performanceMaxLongHeadline4'),
  ...creatorObject('performanceMaxLongHeadline5'),
  ...creatorObject('performanceMaxShortDescription'),
  ...creatorObject('performanceMaxLongDescription1'),
  ...creatorObject('performanceMaxLongDescription2'),
  ...creatorObject('performanceMaxLongDescription3'),
  ...creatorObject('performanceMaxLongDescription4'),
  ...creatorObject('performanceMaxBusinessName'),
  ...creatorObject('performanceMaxVideo1'),
  ...creatorObject('performanceMaxVideo2'),
  ...creatorObject('performanceMaxVideo3'),
  ...creatorObject('performanceMaxVideo4'),
  ...creatorObject('performanceMaxVideo5'),
  ...creatorField({ field: 'performanceMaxPath1', updateField: 'path1' }),
  ...creatorField({ field: 'performanceMaxPath2', updateField: 'path2' }),
};

export default mapping;
