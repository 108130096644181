import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalHeading } from '../Modal';
import PreviewInputValue from '../PreviewInputValue';
import { PreviewValueCompare } from './Preview';
import { unmodifiedValue } from '../data_view_layout/utils';
import FieldKindIcon from '../FieldKindIcon';

const IMAGE_LINK_KEY = 'image_link';

const ProductPreview = ({ productData, onClose, transformationVariable, productId, tableVariablesSorted }) => {
  if (!productData) {
    return null;
  }

  const data = JSON.parse(productData.data);

  return (
    <Modal onClose={onClose}>
      <ModalHeader centered>
        <ModalHeading>
          <span className="Text--grape">
            <FieldKindIcon
              showFieldKind={transformationVariable.fieldKind}
              size="1.7em"
              className="vas"
              color="inherit"
            />
          </span>
          <span className="Text--capitalize">{transformationVariable.name}</span>: preview transformation for product{' '}
          {productId}
        </ModalHeading>
      </ModalHeader>
      <ModalBody>
        <div className="Sticky-Wrapper">
          <table className="Sticky-Table Sticky-Table--firstColumnDelimiter">
            <thead>
              <tr className="Sticky-Header">
                <th>Parameter</th>
                <th>Data</th>
              </tr>
            </thead>
            <tbody className="Sticky-Body">
              {tableVariablesSorted.map(({ name, placeholderName, showFieldKind }) => (
                <tr key={name} className="Text Text--gray">
                  <td className="js-text-overflow" style={{ maxWidth: '100px' }}>
                    <FieldKindIcon showFieldKind={showFieldKind} size="1.6em" className="vas" />
                    {name}
                  </td>
                  <td className="Text--bold js-text-overflow">
                    {placeholderName === IMAGE_LINK_KEY && (
                      <img
                        className="mb-4"
                        src={unmodifiedValue({ item: data, placeholderName })}
                        alt=""
                        style={{ maxWidth: '120px', maxHeight: '80px' }}
                      />
                    )}
                    {transformationVariable.name !== placeholderName ||
                    productData.originalData === productData.transformedData ? (
                      <PreviewInputValue
                        value={unmodifiedValue({ item: data, placeholderName })}
                        showFieldKind={showFieldKind}
                      />
                    ) : (
                      <PreviewValueCompare {...productData} />
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </ModalBody>
    </Modal>
  );
};

ProductPreview.propTypes = {
  productData: PropTypes.shape({
    data: PropTypes.string,
  }),
  productId: PropTypes.string,
  onClose: PropTypes.func,
  transformationVariable: PropTypes.shape({
    name: PropTypes.string,
    fieldKind: PropTypes.string,
  }),
  tableVariablesSorted: PropTypes.arrayOf(PropTypes.object),
};

export default ProductPreview;
