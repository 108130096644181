import React from 'react';
import PropTypes from 'prop-types';
import { SortableContainer as Container } from 'react-sortable-hoc';

export { SortableElement } from 'react-sortable-hoc';

export const SortableContainer = Component => {
  const SortableList = Container(Component);

  const SortableWrapperComponent = ({ children, onSortEnd, onSortStart, ...rest }) => (
    <SortableList
      {...rest}
      onSortEnd={(d, e) => {
        if (typeof onSortEnd === 'function') onSortEnd(d, e);
        document.body.classList.remove('grabbing');
      }}
      onSortStart={(d, e) => {
        if (typeof onSortStart === 'function') onSortStart(d, e);

        document.body.classList.add('grabbing');
      }}
    >
      {children}
    </SortableList>
  );
  SortableWrapperComponent.propTypes = {
    onSortEnd: PropTypes.func,
    onSortStart: PropTypes.func,
    children: PropTypes.any,
  };
  return SortableWrapperComponent;
};
