import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { t } from '../../../../i18n';
import { Button, Icon } from '../../../index';
import SelectImagePlaceholderModal from './modals/SelectImagePlaceholderModal';
import SelectImageModal from './modals/SelectImageModal';
import SelectImageSnippetModal from './modals/SelectImageSnippetModal';
import { buildUrl } from './ImagePlaceholderPanel';

const customIcons = {
  image_template: 'template',
  image_snippet: 'assets',
};

const InsertMenu = ({ currentTool, forceOpen, tools, onSelect, insertNewLayers, isTemplateModalOpen = false }) => {
  const keys = Object.keys(tools);
  const [showModal, setShowModal] = useState(isTemplateModalOpen ? 'image_template' : null);

  return (
    <div className={cs('ImageGenerator-toolbox', { 'ImageGenerator-toolbox--forceOpen': forceOpen })}>
      <Button
        primary
        onlyIcon
        icon={currentTool ? tools[currentTool].meta.icon : 'plus'}
        onClick={e => e.preventDefault()}
      />
      <div className="ImageGenerator-toolboxMenu" data-test-id="create-new-image-button">
        {keys
          .filter(k => !tools[k].doNotDisplayInInsertMenu)
          .map(type => (
            <a
              className={cs('ImageGenerator-toolboxItem', {
                'ImageGenerator-toolboxItem--selected': currentTool === type,
              })}
              data-test-id={`add-asset-${type}`}
              onMouseDown={() => {
                if (type === 'imgPlaceholder') {
                  setShowModal('imgPlaceholder');
                } else if (type === 'img') {
                  setShowModal('img');
                } else if (type === 'image_snippet') {
                  setShowModal('image_snippet');
                } else if (type === 'image_template') {
                  setShowModal('image_template');
                } else {
                  onSelect(type);
                }
              }}
              key={type}
            >
              <Icon kind={customIcons[type] || tools[type].meta.icon} inheritColor />
              {t(type, { scope: 'image_generator.tools' })}
            </a>
          ))}
        {showModal === 'imgPlaceholder' && (
          <SelectImagePlaceholderModal
            onSelect={variable => {
              onSelect(showModal, { xlinkHref: buildUrl({ placeholder: variable }), optionalLayer: true });
              setShowModal(null);
            }}
            onClose={() => setShowModal(null)}
          />
        )}
        {showModal === 'img' && (
          <SelectImageModal
            onSelect={data => {
              onSelect(showModal, data);
              setShowModal(null);
            }}
            onClose={() => setShowModal(null)}
          />
        )}

        {showModal === 'image_snippet' && (
          <SelectImageSnippetModal
            imageType="snippet"
            onSelect={(layers, template) => {
              insertNewLayers(layers, template);
              setShowModal(null);
            }}
            onClose={() => setShowModal(null)}
          />
        )}
        {showModal === 'image_template' && (
          <SelectImageSnippetModal
            imageType="template"
            onSelect={(layers, template) => {
              insertNewLayers(layers, template);
              setShowModal(null);
            }}
            onClose={() => setShowModal(null)}
          />
        )}
      </div>
    </div>
  );
};

InsertMenu.propTypes = {
  currentTool: PropTypes.string,
  tools: PropTypes.object,
  forceOpen: PropTypes.bool,
  onSelect: PropTypes.func,
  insertNewLayers: PropTypes.func,
  isTemplateModalOpen: PropTypes.bool,
};
export default InsertMenu;
