/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import { isNull } from 'lodash';
import { createAdtextFieldTextFromObject, createAdtextDynamicOptionField } from './createAdtextField';
import { setupColumn } from '../setupColumn';
import { t } from '../../../../i18n';
import { getGqlAttributeText } from '../../../../graphql_provider';

const CATEGORY = 'ad_display_responsive_adtext';
const creatorObject = createAdtextFieldTextFromObject(CATEGORY);
const dynamicAdCreator = createAdtextDynamicOptionField(CATEGORY);

const mapping = {
  ...creatorObject('displayResponsiveHeadline1'),
  ...creatorObject('displayResponsiveHeadline2'),
  ...creatorObject('displayResponsiveHeadline3'),
  ...creatorObject('displayResponsiveHeadline4'),
  ...creatorObject('displayResponsiveHeadline5'),
  ...creatorObject('displayResponsiveLongHeadline', { disableOverflow: true, disablePinned: true }),
  ...creatorObject('displayResponsiveBusinessName', { disableOverflow: true, disablePinned: true }),
  ...creatorObject('displayResponsiveDescription1'),
  ...creatorObject('displayResponsiveDescription2'),
  ...creatorObject('displayResponsiveDescription3'),
  ...creatorObject('displayResponsiveDescription4'),
  ...creatorObject('displayResponsiveDescription5'),

  ...dynamicAdCreator({
    heading: t('activerecord.attributes.display_responsive_adtext.use_custom_colors'),
    fieldsObject: {
      useCustomColors: {
        role: 'main',
        label: t('activerecord.attributes.display_responsive_adtext.use_custom_colors'),
      },
      mainColor: { role: 'color', label: t('activerecord.attributes.display_responsive_adtext.main_color') },
      accentColor: { role: 'color', label: t('activerecord.attributes.display_responsive_adtext.accent_color') },
      allowFlexibleColor: {
        role: 'switcher',
        label: t('activerecord.attributes.display_responsive_adtext.allow_flexible_color'),
      },
    },
    editQuery: data =>
      typeof data.id !== 'string' &&
      !isNull(data.useCustomColors) &&
      (newData => gql`
          mutation {
            updateBaseAdtext(id: ${
              data.id
            }, useCustomColors: ${!!newData?.useCustomColors}, mainColor: ${getGqlAttributeText(
        newData?.mainColor
      )}, accentColor: ${getGqlAttributeText(
        newData?.accentColor
      )}, allowFlexibleColor: ${!!newData?.allowFlexibleColor}) {
              baseAdtexts {
                id
                itemsCount
                useCustomColors
                mainColor
                accentColor
                allowFlexibleColor
              }
            }
          }
        `),
  }),
  ...dynamicAdCreator({
    heading: t('activerecord.attributes.display_responsive_adtext.use_dynamic_ad_options'),
    fieldsObject: {
      useDynamicAdOptions: {
        role: 'main',
        label: t('activerecord.attributes.display_responsive_adtext.use_dynamic_ad_options'),
      },
      pricePrefix: { role: 'text', label: t('activerecord.attributes.display_responsive_adtext.price_prefix') },
      promotionText: { role: 'text', label: t('activerecord.attributes.display_responsive_adtext.promotion_text') },
    },
    editQuery: data =>
      typeof data.id !== 'string' &&
      !isNull(data.useDynamicAdOptions) &&
      (newData => gql`
          mutation {
            updateBaseAdtext(id: ${
              data.id
            }, useDynamicAdOptions: ${!!newData?.useDynamicAdOptions}, pricePrefix: ${getGqlAttributeText(
        newData?.pricePrefix
      )}, promotionText: ${getGqlAttributeText(newData?.promotionText)}) {
              baseAdtexts {
                id
                itemsCount
                useDynamicAdOptions
                pricePrefix
                promotionText
              }
            }
          }
        `),
  }),
  ...dynamicAdCreator({
    heading: t('activerecord.attributes.display_responsive_adtext.use_custom_call_to_action_text'),
    fieldsObject: {
      useCustomCallToActionText: {
        role: 'main',
        label: t('activerecord.attributes.display_responsive_adtext.use_custom_call_to_action_text'),
      },
      callToActionText: {
        role: 'text',
        label: t('activerecord.attributes.display_responsive_adtext.call_to_action_text'),
        notEditable: true,
      },
    },
    editQuery: data =>
      typeof data.id !== 'string' &&
      !isNull(data.useDynamicAdOptions) &&
      (newData => gql`
          mutation {
            updateBaseAdtext(id: ${
              data.id
            }, useCustomCallToActionText: ${!!newData?.useCustomCallToActionText}, callToActionText: ${getGqlAttributeText(
        newData?.callToActionText
      )}) {
              baseAdtexts {
                id
                itemsCount
                useCustomCallToActionText
                callToActionText
              }
            }
          }
        `),
  }),
  ...setupColumn({
    id: 'adFormatPreference',
    graphqlFields: { adtexts: ['adFormatPreference', 'type'] },
    category: CATEGORY,
    header: () => t('activerecord.attributes.display_responsive_adtext.ad_format_preference'),
    resolveChildren: ({ adFormatPreference, type }) =>
      type === 'DisplayResponsiveAdtext' ? adFormatPreference || null : null,
  }),
};

export default mapping;
