window.addEventListener('load', () => {
  const $root = $('html, body');

  if (window.location.hash) {
    const id = window.location.hash.replace('#', '');
    if (document.getElementsByClassName('NewLayout-header').length > 0) {
      const element = document.getElementById(id);
      if (element) {
        $root.animate(
          {
            scrollTop: $(element).offset().top - 70,
          },
          500
        );
        $(element).addClass('Highlight');
      }
    }
  }
});
