import { formatNumber, formatCurrency } from '../../i18n';

const CURRENCY_METRICS = ['cost', 'conversionValue'];

const COLUMNS_ORDER = ['adPlacement', 'clicks', 'cost', 'impressions', 'videoViews', 'conversions', 'conversionValue'];

export const filterRowColumnsForReport = row =>
  Object.keys(row)
    .sort((a, b) => COLUMNS_ORDER.indexOf(a) - COLUMNS_ORDER.indexOf(b))
    .reduce((acc, key) => (key === 'currency' || key === '__typename' ? acc : { ...acc, [key]: row[key] }), {});

export const formatMetricValue = (value, params) => {
  if (!value && value !== 0) {
    return '-';
  }

  if (params?.row && params?.metricName && CURRENCY_METRICS.includes(params?.metricName)) {
    return formatCurrency(value, {
      precision: 2,
      round_strategy: 'ceil',
      unit: params.row.currency || '',
    });
  }

  return typeof value === 'number' ? formatNumber(value, { precision: 0 }) : value;
};
