import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { AdPreviewContainer } from './index';
import { Col, Row } from '../layout';
import { Text } from '../index';

const FbRightPanelPreview = ({ media, primaryText, url }) => (
  <AdPreviewContainer
    icon="facebook-square"
    title={t('fb_ad_preview.facebook_right_panel', { default: 'Facebook - right panel' })}
  >
    <div className="FbRightPanelPreview-container">
      <Row>
        <Col shrink>
          <div className="FbRightPanelPreview-fbRightPanelImage">
            <img src={media.image_url} alt="" />
          </div>
        </Col>
        <Col className="FbRightPanelPreview-fbRightPanelText">
          <Text semibold style={{ display: 'block', fontSize: '8px', color: '#050505' }}>
            {primaryText}
          </Text>
          <Text semibold style={{ display: 'block', fontSize: '8px', color: '#ACB0B8', wordBreak: 'break-all' }}>
            {url}
          </Text>
        </Col>
      </Row>
    </div>
  </AdPreviewContainer>
);

FbRightPanelPreview.propTypes = {
  media: PropTypes.object,
  primaryText: PropTypes.string,
  url: PropTypes.string,
};

export default FbRightPanelPreview;
