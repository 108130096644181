/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { gql, NetworkStatus } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import chevron from '../scss_stylesheets/images/chevron_right.svg';
import { Row, Col, Link, ErrorLoadingQuote } from '../components';
import { t } from '../i18n';
import ErrorBox from '../components/ErrorBox';

// eslint-disable-next-line react/prop-types
const QueryHolder = WrappedComponent => ({ query, variables, ...rest }) => (
  <Query query={query} variables={variables} notifyOnNetworkStatusChange>
    {({ error, data, previousData, refetch, networkStatus }) => {
      const loadingByNetwork = networkStatus !== NetworkStatus.ready;
      if (loadingByNetwork && !data) {
        return (
          <div className="Preview-item">
            <div
              className="loading-status Loader Loader--small w-auto h-auto"
              style={{ left: `${rest.depth * 30 + 15}px`, top: 0 }}
            >
              {t('react.pla_campaign_preview.loading')}
            </div>
          </div>
        );
      }
      if (error) {
        return (
          <div className="Preview-item">
            <ErrorLoadingQuote onClick={() => refetch()} />
          </div>
        );
      }

      return <WrappedComponent data={data || previousData} refetch={() => refetch()} {...variables} {...rest} />;
    }}
  </Query>
);

const QUERY_CAMPAIGNS = gql`
  query OrganizationEstoreCampaigns($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreCampaigns(status: "running") {
          id
          name
          adwordsId
          adwordsError
        }
        adgroupsRunningCount
      }
    }
  }
`;

const QUERY_ADGROUPS = gql`
  query OrganizationEstoreAdgroups($organizationId: BigInt!, $campaignSettingId: BigInt!, $estoreCampaignId: String!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreAdgroups(size: -1, status: "running", adwordsSmartDisplay: "adgroup", campaignId: $estoreCampaignId) {
          id
          name
          adwordsId
          adwordsError
          modelAdtexts
        }
      }
    }
  }
`;

const QUERY_PRODUCTS = gql`
  query OrganizationEstoreProducts($organizationId: BigInt!, $campaignSettingId: BigInt!, $limit: Int!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreAdgroups(size: $limit, status: "running", adwordsSmartDisplay: "product") {
          id
          name
          adwordsFeedData
        }
      }
    }
  }
`;

export default class SmartDisplayCampaignPreview extends React.Component {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    campaignSettingId: PropTypes.number.isRequired,
  };
  render() {
    const { organizationId, campaignSettingId } = this.props;
    return <CampaignList query={QUERY_CAMPAIGNS} variables={{ organizationId, campaignSettingId }} />;
  }
}

let estoreCampaignsInterval = null;

const CampaignList = QueryHolder(({ data, refetch, ...rest }) => {
  const { estoreCampaigns, adgroupsRunningCount } = data.organization.campaignSetting;

  if (estoreCampaignsInterval) {
    clearInterval(estoreCampaignsInterval);
  }

  if (estoreCampaigns.length === 0) {
    estoreCampaignsInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_campaigns_generated_yet')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <div>
      {estoreCampaigns.map(campaign => (
        <CampaignLine key={campaign.id} adgroupsRunningCount={adgroupsRunningCount - 1} {...rest} {...campaign} />
      ))}
    </div>
  );
});

class CampaignLine extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    adwordsId: PropTypes.string,
    adwordsError: PropTypes.string,
    organizationId: PropTypes.number,
    campaignSettingId: PropTypes.number,
    adgroupsRunningCount: PropTypes.number,
  };

  state = {
    show: true,
  };

  render() {
    const {
      id,
      name,
      adwordsId,
      adgroupsRunningCount,
      adwordsError,
      organizationId,
      campaignSettingId,
      ...rest
    } = this.props;
    const { show } = this.state;

    return (
      <div className="pos-relative">
        <div
          className="Preview-item Preview-item--link Preview-item--bold pl-8"
          onClick={() => this.setState({ show: !show })}
        >
          <Row center>
            <Col shrink>
              <img src={chevron} className={`Preview-chevron ${show && 'rotate'}`} alt="chevron" />
            </Col>
            <Col>{name}</Col>
            {adwordsError ? <Col className="danger fc-warning">` ${adwordsError}`</Col> : null}
            <Col shrink className="pr-8">
              {adgroupsRunningCount}
            </Col>
          </Row>
        </div>
        {show && (
          <AdgroupList
            {...rest}
            adgroupsRunningCount={adgroupsRunningCount}
            depth={0}
            query={QUERY_ADGROUPS}
            variables={{ organizationId, campaignSettingId, estoreCampaignId: id }}
          />
        )}
      </div>
    );
  }
}

let estoreCampaignLineInterval = null;

const AdgroupList = QueryHolder(({ adgroupsRunningCount, depth, data, refetch, showAll, ...rest }) => {
  const { estoreAdgroups } = data.organization.campaignSetting;

  if (estoreCampaignLineInterval) {
    clearInterval(estoreCampaignLineInterval);
  }

  if (estoreAdgroups.length === 0) {
    estoreCampaignLineInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_adgroup_product_dimension_generated')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <div>
      {data.organization.campaignSetting.estoreAdgroups.map((dimension, index) => (
        <AdgroupLine
          adgroupsRunningCount={adgroupsRunningCount}
          depth={depth}
          index={index}
          key={dimension.id}
          {...rest}
          {...dimension}
        />
      ))}
    </div>
  );
});

AdgroupList.propTypes = {
  adgroupsRunningCount: PropTypes.number,
  showAll: PropTypes.func.isRequired,
  depth: PropTypes.number,
  data: PropTypes.any,
  refetch: PropTypes.func.isRequired,
};

class AdgroupLine extends React.Component {
  static propTypes = {
    adgroupsRunningCount: PropTypes.number,
    adwordsError: PropTypes.string,
    campaignSettingId: PropTypes.number,
    depth: PropTypes.number,
    modelAdtexts: PropTypes.array,
    name: PropTypes.string,
    organizationId: PropTypes.number,
  };
  state = {
    show: true,
    limit: 100,
  };

  componentDidMount() {
    if (typeof window.initIcons === 'function') {
      setTimeout(window.initIcons, 1);
    }
  }

  componentDidUpdate() {
    if (typeof window.initIcons === 'function') {
      setTimeout(window.initIcons, 1);
    }
  }

  render() {
    const {
      name,
      depth,
      adgroupsRunningCount,
      adwordsError,
      organizationId,
      campaignSettingId,
      modelAdtexts,
    } = this.props;
    const { show, limit } = this.state;

    return (
      <div className="pos-relative">
        <div
          className={`Preview-item Preview-item--link ${depth === 0 && 'Preview-item--bold pl-8'})`}
          style={{ paddingLeft: `${(depth + 1) * 30 + 15}px` }}
          onClick={() => this.setState({ show: !show })}
        >
          <Row center>
            <Col shrink>
              <img src={chevron} className={`Preview-chevron ${show && 'rotate'}`} alt="chevron" />
            </Col>
            <Col>{name}</Col>
            {adwordsError ? <Col className="danger fc-warning">` ${adwordsError}`</Col> : null}
          </Row>
        </div>
        <div>
          <div className="Preview-item Preview-item--gray" style={{ paddingLeft: `${(depth + 2) * 30 + 15}px` }}>
            {modelAdtexts ? (
              // eslint-disable-next-line react/no-danger
              <div className="js-tooltip-no-left-margin" dangerouslySetInnerHTML={{ __html: modelAdtexts.join(' ') }} />
            ) : (
              <ErrorBox withIcon>{t('react.pla_campaign_preview.no_adtext_error')}</ErrorBox>
            )}
          </div>
        </div>
        {show && (
          <ProductList
            depth={depth + 1}
            query={QUERY_PRODUCTS}
            variables={{ organizationId, campaignSettingId, limit }}
          />
        )}
        {adgroupsRunningCount > limit && limit !== -1 && (
          <div
            style={{ paddingLeft: `${depth * 30 + 15}px` }}
            className="Preview-item Preview-item--link"
            onClick={() => this.setState({ limit: -1 })}
          >
            {t('react.pla_campaign_preview.show_all')} ({adgroupsRunningCount - limit})
          </div>
        )}
      </div>
    );
  }
}

const ProductList = QueryHolder(({ data, refetch, depth, ...rest }) => (
  <div>
    <div className="Preview-item Preview-item--gray" style={{ paddingLeft: `${(depth + 1) * 30}px` }}>
      <div className="row mh-0">
        <div className="col-xs-1 col-lg-1" />
        <div className="col-xs-2 col-lg-2 Text--gray Text--bold">{t('react.pla_campaign_preview.product_id')}</div>
        <div className="col-xs-5 col-lg-5 Text--gray Text--bold">{t('react.pla_campaign_preview.product_name')}</div>
        <div className="col-xs-2 Text--gray Text--bold text-right">{t('react.pla_campaign_preview.price')}</div>
        <div className="col-xs-2 Text--gray Text--bold text-right">{t('react.pla_campaign_preview.sale_price')}</div>
      </div>
    </div>
    {data.organization.campaignSetting.estoreAdgroups.map(dimension => (
      <ProductLine key={dimension.id} {...rest} {...dimension} depth={depth} />
    ))}
  </div>
));

class ProductLine extends React.Component {
  static propTypes = {
    depth: PropTypes.number,
    adwordsFeedData: PropTypes.string,
  };

  render() {
    const { depth, adwordsFeedData } = this.props;
    const { id, item_title, final_url, image_url, price, sale_price } = JSON.parse(adwordsFeedData);

    return (
      <div className="Preview-item Preview-item--gray" style={{ paddingLeft: `${(depth + 1) * 30}px` }}>
        <div className="row mh-0">
          <div className="col-xs-1 col-lg-1 text-center">
            <div className="Preview-image" style={{ backgroundImage: `url(${image_url})` }} />
          </div>
          <div className="col-xs-2 col-lg-2">{id}</div>
          <div className="col-xs-5 col-lg-5">
            {final_url ? (
              <Link href={final_url} rel="noopener noreferrer" target="_blank">
                {item_title}
              </Link>
            ) : (
              item_title
            )}
          </div>
          <div className="col-xs-2 text-right">{price}</div>
          <div className="col-xs-2 text-right">{sale_price}</div>
        </div>
      </div>
    );
  }
}
