import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

export const Loader = ({ size, absolute, children, negative, style = {} }) =>
  absolute ? (
    <div className="Loader--absolute" style={style}>
      <div
        data-test-id="loader"
        className={cs('Loader', 'vab', { [`Loader--${size}`]: size, 'Loader--negative': negative })}
      />
      {children}
    </div>
  ) : (
    <div
      data-test-id="loader"
      className={cs('Loader', 'vab', { [`Loader--${size}`]: size, 'Loader--negative': negative })}
    />
  );
Loader.propTypes = {
  size: PropTypes.oneOf(['extraSmall', 'small', 'medium', 'semiBig', 'big', '']),
  absolute: PropTypes.bool,
  negative: PropTypes.bool,
  children: PropTypes.any,
  style: PropTypes.object,
};

export const SyncingLoaderIcon = ({ color = '#596774', size = '17px' }) => (
  <svg className="SyncingLoaderIcon" xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
    <path
      fill={color}
      d="M27.5 6.25a1.25 1.25 0 00-2.5 0v2.979l-.862-.908-.035-.036c-1.38-1.342-3.084-2.32-4.952-2.845s-3.841-.582-5.737-.165C11.518 5.692 9.758 6.57 8.3 7.829s-2.568 2.862-3.222 4.658a1.25 1.25 0 102.349.855c.506-1.39 1.367-2.636 2.508-3.621s2.522-1.676 4.017-2.005 3.052-.284 4.524.13a9.11 9.11 0 013.869 2.214l1.525 1.606h-4.478a1.25 1.25 0 000 2.5h6.861c.69 0 1.25-.56 1.25-1.25V6.25zM6.25 27.499c-.69 0-1.25-.56-1.25-1.25v-6.666c0-.69.56-1.25 1.25-1.25h6.861a1.25 1.25 0 010 2.5H8.633l1.525 1.606a9.108 9.108 0 003.869 2.213c1.472.414 3.029.459 4.524.13s2.877-1.02 4.017-2.005a8.743 8.743 0 002.508-3.621 1.25 1.25 0 012.349.855c-.654 1.796-1.764 3.398-3.222 4.658s-3.218 2.137-5.114 2.555c-1.896.417-3.87.36-5.737-.165S9.78 25.556 8.4 24.214l-.035-.035-.862-.908v2.979c0 .69-.56 1.25-1.25 1.25z"
    />
  </svg>
);

SyncingLoaderIcon.propTypes = {
  color: PropTypes.string,
  size: PropTypes.string,
};

export default SyncingLoaderIcon;
