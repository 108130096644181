import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { gql, useMutation } from '@apollo/client';
import { Button } from '../index';
import { uploadFile } from './utils';
import { t } from '../../i18n';

const UploadNewMedia = ({ onImageUpdate, onError, minWidth = 600, minHeight = 600, organizationId }) => {
  const refDropzone = createRef();
  const [error, setError] = useState(null);
  const [isUploading, setUploading] = useState(0);

  const [createImage, { loading }] = useMutation(
    gql`
      mutation CreateImage($organizationId: BigInt!, $filename: String, $imageData: String) {
        createImage(organizationId: $organizationId, filename: $filename, imageData: $imageData) {
          image {
            id
            url
          }
          errors
        }
      }
    `,
    {
      onCompleted: () => {
        onImageUpdate();
        setUploading(prev => prev - 1);
        setError(null);
        onError(false);
      },
      onError: err => {
        setError(err);
        setUploading(prev => prev - 1);
      },
    }
  );

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }

    acceptedFiles.forEach(file => {
      setUploading(prev => prev + 1);
      const successCallback = signedId =>
        createImage({
          variables: {
            organizationId,
            filename: file.name,
            imageData: signedId,
          },
        });

      const errorCallback = err => {
        setError(err);
        setUploading(prev => prev - 1);
      };

      const reader = new FileReader();
      reader.onload = entry => {
        const image = new Image();
        image.src = entry.target.result;
        image.onload = () => {
          if (image.width < minWidth || image.height < minHeight) {
            setError('wrong_type_resolution');
            setUploading(prev => prev - 1);
          } else {
            uploadFile(file, successCallback, errorCallback);
          }
        };
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Dropzone
      accept="image/png,image/jpg,image/jpeg,image/pjpeg,image/webp"
      onDrop={onDrop}
      multiple
      maxSize={30 * 1024 * 1024}
      ref={refDropzone}
      style={{
        float: 'left',
        marginRight: '3px',
        padding: '3px',
        width: '100%',
        textAlign: 'center',
      }}
      activeStyle={{
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      {({ getRootProps, getInputProps, fileRejections }) => {
        const unsupportedFile = fileRejections?.[0]?.errors?.[0]?.code === 'file-invalid-type';
        if (unsupportedFile) onError('wrong_type_resolution');
        if (error) onError(error);
        const { ref, ...rootProps } = getRootProps();
        return (
          <React.Fragment>
            <Button
              primary
              loadingType="upload"
              className="pos-relative"
              loading={isUploading > 0 || loading}
              error={error}
              {...rootProps}
              setRef={ref}
            >
              <input {...getInputProps()} />
              <span>{t('media.upload_media')}</span>
            </Button>
          </React.Fragment>
        );
      }}
    </Dropzone>
  );
};

// TODO fix any
UploadNewMedia.propTypes = {
  onImageUpdate: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  organizationId: PropTypes.any.isRequired,
};

export default UploadNewMedia;
