import React from 'react';
import PropTypes from 'prop-types';
import { SegmentedButton } from '../components';

const Filters = ({ onChange, name, id, onlyIcons = false, size = 'small', ...rest }) => (
  <SegmentedButton
    {...rest}
    name={name}
    id={id}
    onlyIcons={onlyIcons}
    onChange={e => onChange(e.target.value)}
    size={size}
    doNotUseInternalState
  />
);

Filters.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  onlyIcons: PropTypes.bool,
  size: PropTypes.oneOf[('small', 'medium', 'large')],
};

export default Filters;
