import React from 'react';
import RPT from 'prop-types';
import cs from 'classnames';

const CharacterCounter = ({
  id,
  name,
  chars,
  without_variables,
  show_variables_count,
  without_variables_count,
  overLimit,
  max_text_count,
}) => (
  <div className="Input-info">
    <div className={cs('CharCounter', `${id}_counter`, { red: overLimit })}>
      <input type="hidden" name={name} value={id} />
      {show_variables_count && (
        <React.Fragment>
          <span className={cs('CharCounter-number', `${id}_count_min`)}>0</span>-
          <span className={cs('CharCounter-number', `${id}_count_max`)}>0</span>
          {chars}
          {', '}
        </React.Fragment>
      )}
      <span className={cs('CharCounter-number', `${id}_only_text`)}>{without_variables_count || 0}</span>
      {without_variables}
      {max_text_count > 0 && <span className="CharCounter-max ml-8">({max_text_count} limit)</span>}
    </div>
  </div>
);

CharacterCounter.propTypes = {
  id: RPT.string.isRequired,
  name: RPT.string.isRequired,
  chars: RPT.string.isRequired,
  overLimit: RPT.bool,
  max_text_count: RPT.number,
  without_variables_count: RPT.number,
  show_variables_count: RPT.bool,
  without_variables: RPT.string.isRequired,
};

export default CharacterCounter;
