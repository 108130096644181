import React, { Component } from 'react';
import RPT from 'prop-types';

const SearchIcon = () => (
  <svg width={18} height={18} viewBox="0 0 24 24" className="vam">
    <path
      d="M23.1 21.8zM15.7 9.7c0 .5-.1 1-.2 1.4h2.7c.1-.5.1-.9.1-1.4 0-4.2-2.9-7.7-6.7-8.8L11 3.4c2.7.8 4.7 3.3 4.7 6.3zm-14.2-5c0 .1 0 .1 0 0l.6.4-.6-.4z"
      fill="#efefef"
    />
    <path
      d="M10.8 16l.9 2.5c1.1-.3 2.1-.8 2.9-1.5l6.6 6.6 1.8-1.8-6.6-6.6c.9-1.2 1.5-2.5 1.7-4h-2.7c-.4 2.3-2.3 4.2-4.6 4.8z"
      fill="#347aef"
    />
    <path
      d="M9.2 16.2c-2 0-3.7-.9-4.9-2.3l-2 1.6c1.7 2 4.2 3.2 6.9 3.2.9 0 1.7-.1 2.5-.4l-.9-2.3c-.5.1-1.1.2-1.6.2zm1.6-.2l.9 2.5-.9-2.5z"
      fill="#23a149"
    />
    <path
      d="M2.8 11.2c-.1-.5-.2-1-.2-1.5 0-1.3.4-2.4 1-3.4L2.1 5.2l-.6-.5C.6 6.2.1 7.9.1 9.7c0 .5 0 .9.1 1.4 0 .3.1.5.2.8.2.9.6 1.8 1.1 2.6.2.4.5.8.8 1.1l2-1.6c-.7-.9-1.2-1.8-1.5-2.8z"
      fill="#f1b600"
    />
    <path
      d="M3.7 6.1c1.2-1.8 3.2-2.9 5.4-2.9.6 0 1.3.1 1.9.3l.5-2.6C10.8.7 10 .6 9.2.6c-3.2 0-6 1.7-7.6 4.2l.6.4 1.5.9zm-1.6-.9l1.6 1.1.1-.1-1.7-1z"
      fill="#e83a12"
    />
  </svg>
);

class GoogleSearchIconLink extends Component {
  static propTypes = {
    url: RPT.string.isRequired,
    iconClass: RPT.string,
    tooltip: RPT.string,
    openInNewTab: RPT.bool,
    testId: RPT.string,
  };

  render() {
    const { url, tooltip, iconClass, openInNewTab, testId } = this.props;

    return (
      <a
        className={`Link ml-4 ${iconClass} js-tooltip`}
        data-title={tooltip}
        data-html="true"
        href={url}
        target={openInNewTab ? '_blank' : '_self'}
        data-test-id={testId}
      >
        <SearchIcon />
      </a>
    );
  }
}

export default GoogleSearchIconLink;
