import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Icon, Text } from '../index';
import { AdPreviewContainer, FbPagePreview } from './index';
import fbActions from '../../../../assets/images/fb_ad_preview/fb_actions.png';

const FbFeedPreview = ({
  media,
  pageImageUrl,
  pageName,
  label,
  primaryText,
  url,
  headline,
  description,
  ctaText,
  type,
  creativeSource,
  isSearchResult = false,
}) => (
  <AdPreviewContainer
    icon="facebook-square"
    title={
      !isSearchResult
        ? t('fb_ad_preview.facebook', { default: 'Facebook' })
        : t('fb_ad_preview.facebook_search_result', { default: 'Facebook - search result' })
    }
  >
    <div className="FbFeedPreview-container">
      <div className="FbFeedPreview-header">
        <Row padding="s" center justifyBetween>
          <Col shrink>
            <FbPagePreview
              url={pageImageUrl}
              pageName={pageName}
              label={label}
              imageSize="17px"
              nameSize="7px"
              labelSize="6px"
            />
          </Col>
          <Col shrink>
            <Icon kind="more" style={{ color: '#ACB0B8' }} size="9px" />
          </Col>
        </Row>
        <Text style={{ display: 'block', marginTop: '4px', fontSize: '6px', color: '#050505' }}>{primaryText}</Text>
      </div>
      {type === 'single' && (
        <>
          <div className="FbFeedPreview-main">
            {media?.image_url && (
              <div>
                <img
                  src={media.image_url}
                  alt={t('fb_ad_preview.facebook_feed_preview', { default: 'Facebook feed preview' })}
                />
              </div>
            )}
          </div>
          <Row className="FbFeedPreview-headline" center justifyBetween>
            <Col>
              <Text style={{ display: 'block', fontSize: '5px', color: '#ACB0B8' }}>{url}</Text>
              <Text style={{ display: 'block', fontSize: '7px', color: '#050505', margin: '1x 0' }}>{headline}</Text>
              <Text style={{ display: 'block', fontSize: '6px', color: '#ACB0B8' }}>{description}</Text>
            </Col>
            <Col shrink>
              <div className="FbFeedPreview-cta">
                <Text semibold style={{ display: 'block', fontSize: '7px', color: '#050505' }}>
                  {ctaText}
                </Text>
              </div>
            </Col>
          </Row>
        </>
      )}
      {type === 'carousel' && (
        <div className="FbFeedPreview-main">
          <div className="FbFeedPreview-main--carousel">
            {media?.map(
              (item, i) =>
                i < 2 && (
                  <div key={item.id} className="FbFeedPreview-main--carouselItem">
                    <div>
                      <img
                        src={item.image_url}
                        alt={t('fb_ad_preview.facebook_feed_preview', { default: 'Facebook feed preview' })}
                      />
                    </div>
                    <Row center justifyBetween className="FbFeedPreview-main--carouselItemFooter">
                      <Text style={{ display: 'block', fontSize: '7px', color: '#050505' }}>
                        {creativeSource === 'manual_upload' ? item.name : item.headline}
                      </Text>
                      <div className="FbFeedPreview-main--carouselItemCta">
                        <Text
                          semibold
                          style={{ display: 'block', fontSize: '6px', color: '#050505', whiteSpace: 'nowrap' }}
                        >
                          {ctaText}
                        </Text>
                      </div>
                    </Row>
                  </div>
                )
            )}
          </div>
        </div>
      )}
      <div className="FbFeedPreview-footer">
        <img src={fbActions} alt={t('fb_ad_preview.facebook_actions', { default: 'Facebook actions' })} />
      </div>
    </div>
  </AdPreviewContainer>
);

FbFeedPreview.propTypes = {
  media: PropTypes.any,
  pageImageUrl: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  primaryText: PropTypes.string,
  url: PropTypes.string,
  headline: PropTypes.string,
  description: PropTypes.string,
  ctaText: PropTypes.string,
  type: PropTypes.oneOf(['single', 'carousel']),
};

export default FbFeedPreview;
