import React, { createContext, useContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { getGraphqlQuery } from './getGraphqlQuery';

export const ACTIONS = {
  setDataIds: 'SET_DATA_IDS',
  setSelected: 'SET_SELECTED',
  overrideSelected: 'OVERRIDE_SELECTED',
  selectAll: 'SELECT_ALL',
  deselectAll: 'DESELECT_ALL',
  toggleSingle: 'TOGGLE_SINGLE',
  setState: 'SET_STATE',
  updateQuery: 'UPDATE_QUERY',
};

const MultiEditContext = createContext({ selected: [], dataIds: [], allowSelect: false });
MultiEditContext.displayName = 'MultiEditContext';

const multiEditReducer = (state, action) => {
  const { type, payload } = action;
  const { selected, dataIds } = state;

  if (type === ACTIONS.setState) return { ...state, ...payload };
  if (type === ACTIONS.setSelected) return { ...state, selected: dataIds?.filter(el => selected.includes(el)) };
  if (type === ACTIONS.overrideSelected) return { ...state, selected: payload.newSelected };
  if (type === ACTIONS.selectAll) return { ...state, selected: [...dataIds] };
  if (type === ACTIONS.deselectAll) return { ...state, selected: [] };
  if (type === ACTIONS.updateQuery) {
    return { ...state, getGraphqlQuery: `${getGraphqlQuery(payload.mapping, payload.columns)}` };
  }

  if (type === ACTIONS.toggleSingle) {
    const canBeSelected = selected.indexOf(payload.id) === -1;
    const newSelected = canBeSelected ? [...selected, payload.id] : selected.filter(el => el !== payload.id);
    return { ...state, selected: newSelected };
  }

  throw new Error(`Unhandled action type: ${type}`);
};

const filterColumns = (cols = []) =>
  cols.filter(
    ({ editQuery, id, disableMultieditEditation }) => editQuery && id !== 'name' && !disableMultieditEditation
  );
const getMainColumn = (cols = []) => cols.find(({ editQuery, id }) => editQuery && id === 'name');

const MultiEditProvider = ({
  activeColumns = [],
  allowSelect = false,
  data = [],
  dataIds = [],
  children,
  reducer = multiEditReducer,
  ...rest
}) => {
  const [state, dispatch] = useReducer(reducer, {
    ...rest,
    selected: [],
    dataIds,
    activeColumns: filterColumns(activeColumns),
    mainColumn: getMainColumn(activeColumns) || {},
    allowSelect,
  });

  useEffect(
    () =>
      dispatch({
        type: ACTIONS.setState,
        payload: {
          dataIds,
          data,
          activeColumns: filterColumns(activeColumns),
          mainColumn: getMainColumn(activeColumns) || {},
        },
      }),
    [dataIds, activeColumns, data]
  );

  React.useLayoutEffect(() => {
    const selectedRemoved = state?.selected?.some(id => !dataIds.includes(id));

    if (selectedRemoved) dispatch({ type: ACTIONS.deselectAll });
  }, [dataIds]);

  return <MultiEditContext.Provider value={{ state, dispatch }}>{children}</MultiEditContext.Provider>;
};

MultiEditProvider.propTypes = {
  activeColumns: PropTypes.array,
  allowSelect: PropTypes.bool,
  collectionKey: PropTypes.string,
  data: PropTypes.array,
  dataIds: PropTypes.array,
  children: PropTypes.any.isRequired,
  organizationId: PropTypes.number,
  reducer: PropTypes.any,
};

const useMultiEdit = () => {
  const context = useContext(MultiEditContext);
  if (context === undefined) {
    throw new Error('useMultiEdit must be used within a MultiEditProvider');
  }
  return context;
};

export { MultiEditProvider, useMultiEdit };
