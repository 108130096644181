/* eslint-disable react/prop-types */
import React from 'react';
import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import {
  GenericCell,
  TimeAgoCell,
  IntegerCell,
  MainIdentifierCell,
  SwitchCell,
  VisibilitySwitchCell,
  HtmlCell,
  TextCell,
  LinkContent,
  TextContent,
  StateContent,
  VariableRulesCell,
} from './cells';
import { Col, Row, Icon } from '../../index';
import { LinkEdit, IntegerEdit } from './editable';
import conditions from './conditions';
import labels from './labels';
import { t } from '../../../i18n';
import { modalDataUrlOpener } from '../../RemoteModal';

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: [
      'id',
      'name',
      'placeholderName',
      'editLink',
      'deleteLink',
      'disabledDeleteLink',
      'previewLink',
      'duplicateLink',
      'duplicateCopyLink',
      'showFieldKind',
    ],
    required: true,
    cell: MainIdentifierCell,
    order: 'name',
    editable: LinkEdit,
    editButtonAsProp: true,
    editQuery: ({ id, editLink }) =>
      !editLink
        ? null
        : ({ text }) => gql`mutation { updateVariable(id: "${id}", name: "${text}") { variable { id name } errors } }`,
    width: '390px',
    header: () => t('react.tables.variables.name'),
    resolveChildren: ({
      id,
      name,
      editLink,
      duplicateLink,
      duplicateCopyLink,
      previewLink,
      deleteLink,
      showFieldKind,
      disabledDeleteLink,
    }) => ({
      id,
      icon: `field-kind-${showFieldKind}`,
      size: '20px',
      color: '#596774',
      tooltip: t(showFieldKind, { scope: 'show_field_kinds' }),
      name,
      text: name,
      link: editLink,
      'data-test-id': `dropdown-action-${name}`,
      links: [
        editLink && {
          href: editLink,
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        previewLink && {
          href: '#',
          children: t('preview'),
          icon: 'magnifier',
          ...modalDataUrlOpener({ url: previewLink, heading: t('preview_data', { field: name }), size: 'medium' }),
        },
        duplicateCopyLink && {
          href: duplicateCopyLink,
          children: t('views.campaign_settings.index.duplicate'),
          'data-test-id-duplicate': name,
          icon: 'duplicate',
        },
        duplicateLink && {
          href: duplicateLink,
          children: t('views.variables.index.duplicate_to'),
          'data-test-id-duplicate-to': name,
          icon: 'duplicate-to',
          // ...modalDataUrlOpener({
          //   url: duplicateLink,
          //   heading: t('Duplicate to other data source'),
          //   size: 'dynamic',
          // }),
        },
        deleteLink && {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
        disabledDeleteLink && {
          disabled: disabledDeleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
        },
      ].filter(f => !!f),
    }),
    fixed: 'left',
  }),
  ...setupColumn({
    id: 'rules',
    graphqlFields: ['id', 'name', 'showFieldKind', { transformationSubjects: ['id', 'status'] }],
    header: () => t('react.tables.variables.variable_rules'),
    cell: VariableRulesCell,
    required: true,
  }),
  ...setupColumn({
    id: 'enabled',
    required: true,
    graphqlFields: 'enabled',
    order: 'enabled',
    header: () => t('react.tables.variables.visible_in_forms'),
    cell: VisibilitySwitchCell,
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", enabled: ${enabled}) { variable { id name enabled } errors } }`,
  }),
  ...setupColumn({
    id: 'showInTable',
    required: true,
    graphqlFields: 'showInTable',
    order: 'show_in_table',
    header: () => t('react.tables.variables.visible_in_product_list'),
    cell: VisibilitySwitchCell,
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", showInTable: ${enabled}) { variable { id name showInTable } errors } }`,
  }),
  ...setupColumn({
    id: 'showInCard',
    required: true,
    graphqlFields: 'showInCard',
    order: 'show_in_card',
    header: () => t('react.tables.variables.visible_in_product_grid'),
    cell: VisibilitySwitchCell,
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", showInCard: ${enabled}) { variable { id name showInCard } errors } }`,
  }),
  ...setupColumn({
    id: 'source',
    order: 'source',
    graphqlFields: [
      'source',
      'valueType',
      { additionalSource: [{ additionalDataSource: ['icon', 'showLink'] }, 'status', 'showLink', 'name', 'icon'] },
    ],
    header: () => t('react.tables.variables.source'),
    cell: GenericCell,
    resolveChildren: ({ valueType, source, additionalSource }) => {
      if (!additionalSource && !source) return undefined;

      if (additionalSource) {
        return (
          <Row>
            <Col shrink>
              <LinkContent>
                {{
                  icon: additionalSource?.additionalDataSource?.icon || additionalSource?.icon,
                  text: additionalSource?.name,
                  link: additionalSource?.additionalDataSource?.showLink || additionalSource?.showLink,
                }}
              </LinkContent>
            </Col>
            {additionalSource.status === 'paused' && (
              <Col shrink>
                <StateContent>{{ state: 'paused' }}</StateContent>
              </Col>
            )}
          </Row>
        );
      }
      if (source === 'modifier') {
        return (
          <Row center>
            <Col shrink>
              <Icon size={12} color="#596774" kind={`variable-${valueType.replace('_', '-')}`} />{' '}
            </Col>
            <Col grow>
              <TextContent>{t(valueType, { scope: 'react.tables.variables' })}</TextContent>
            </Col>
          </Row>
        );
      }

      return <TextContent> {t(source, { scope: 'react.tables.variables' })}</TextContent>;
    },
  }),
  ...setupColumn({
    id: 'inputText',
    graphqlFields: 'inputTextPreview',
    width: '400px',
    header: () => t('react.tables.variables.input_text'),
    cell: HtmlCell,
    resolveChildren: ({ inputTextPreview }) => inputTextPreview,
  }),
  ...setupColumn({
    id: 'roundNumberFormat',
    graphqlFields: 'roundNumberFormat',
    cell: TextCell,
    category: 'number_formatting',
    header: () => t('activerecord.attributes.modifier.round_number_format'),
    resolveChildren: ({ roundNumberFormat }) =>
      roundNumberFormat ? t(roundNumberFormat, { scope: 'rounding_types' }) : '',
  }),
  ...setupColumn({
    id: 'roundingFunction',
    graphqlFields: 'roundingFunction',
    cell: TextCell,
    category: 'number_formatting',
    header: () => t('activerecord.attributes.modifier.rounding_function'),
    resolveChildren: ({ roundingFunction }) =>
      roundingFunction ? t(roundingFunction, { scope: 'rounding_functions' }) : '',
  }),
  ...setupColumn({
    id: 'numberDelimiter',
    graphqlFields: 'numberDelimiter',
    cell: TextCell,
    category: 'number_formatting',
    header: () => t('react.tables.variables.number_delimiter'),
    resolveChildren: ({ numberDelimiter }) =>
      numberDelimiter ? t(numberDelimiter, { scope: 'ad_param_delimiters' }) : '',
  }),
  ...setupColumn({
    id: 'numberSeparator',
    graphqlFields: 'numberSeparator',
    cell: TextCell,
    category: 'number_formatting',
    header: () => t('react.tables.variables.number_separator'),
    resolveChildren: ({ numberSeparator }) =>
      numberSeparator ? t(numberSeparator, { scope: 'ad_param_delimiters' }) : '',
  }),

  ...setupColumn({
    id: 'transliterate',
    cell: SwitchCell,
    category: 'text_transformations',
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", transliterate: ${enabled}) { variable { id name transliterate } errors } }`,

    header: () => t('transliterate', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'htmlSanitize',
    cell: SwitchCell,
    category: 'text_transformations',
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", htmlSanitize: ${enabled}) { variable { id name htmlSanitize } errors } }`,

    header: () => t('html_sanitize', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'encodeToUrlParam',
    cell: SwitchCell,
    category: 'text_transformations',
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", encodeToUrlParam: ${enabled}) { variable { id name encodeToUrlParam } errors } }`,

    header: () => t('encode_to_url_param', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'removeDuplicityWords',
    cell: SwitchCell,
    category: 'text_transformations',
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", removeDuplicityWords: ${enabled}) { variable { id name removeDuplicityWords } errors } }`,

    header: () => t('remove_duplicity_words', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'interpunction',
    cell: SwitchCell,
    category: 'text_transformations',
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", interpunction: ${enabled}) { variable { id name interpunction } errors } }`,

    header: () => t('interpunction', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'removeStopWords',
    cell: SwitchCell,
    category: 'text_transformations',
    editQuery: ({ id }) => enabled =>
      gql`mutation SwitchShowInTable { updateVariable(id: "${id}", removeStopWords: ${enabled}) { variable { id name removeStopWords } errors } }`,

    header: () => t('remove_stop_words', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'changecase',
    cell: TextCell,
    category: 'text_transformations',
    header: () => t('changecase', { scope: 'activerecord.attributes.modifier' }),
    resolveChildren: ({ changecase }) =>
      typeof changecase !== 'undefined' && changecase !== null ? t(changecase, { scope: 'changecase' }) : '',
  }),

  ...setupColumn({
    id: 'limitWords',
    cell: IntegerCell,
    editable: IntegerEdit,
    category: 'text_transformations',
    graphqlFields: ['limitWordsEnable', 'limitWords'],
    header: () => t('limit_words', { scope: 'activerecord.attributes.modifier' }),
    resolveChildren: ({ limitWordsEnable, limitWords }) => (limitWordsEnable ? limitWords : null),
    editQuery: ({ limitWordsEnable, id }) =>
      limitWordsEnable &&
      (children =>
        gql`
        mutation {
          updateVariable(id: "${id}", limitWords: ${children}) { variable { id limitWords } errors }
        }
      `),
  }),

  ...setupColumn({
    id: 'limitChars',
    cell: IntegerCell,
    editable: IntegerEdit,
    category: 'text_transformations',
    graphqlFields: ['limitCharsEnable', 'limitChars'],
    header: () => t('limit_chars', { scope: 'activerecord.attributes.modifier' }),
    resolveChildren: ({ limitCharsEnable, limitChars }) => (limitCharsEnable ? limitChars : null),
    editQuery: ({ limitCharsEnable, id }) =>
      limitCharsEnable &&
      (children =>
        gql`
        mutation {
          updateVariable(id: "${id}", limitChars: ${children}) { variable { id limitChars } errors }
        }
      `),
  }),

  ...setupColumn({
    id: 'limitWordsStart',
    cell: IntegerCell,
    editable: IntegerEdit,
    category: 'text_transformations',
    graphqlFields: ['limitWordsStartEnable', 'limitWordsStart'],
    header: () => t('limit_words_start', { scope: 'activerecord.attributes.modifier' }),
    resolveChildren: ({ limitWordsStartEnable, limitWordsStart }) => (limitWordsStartEnable ? limitWordsStart : null),
    editQuery: ({ limitWordsStartEnable, id }) =>
      limitWordsStartEnable &&
      (children =>
        gql`
        mutation {
          updateVariable(id: "${id}", limitWordsStart: ${children}) { variable { id limitWordsStart } errors }
        }
      `),
  }),

  ...setupColumn({
    id: 'splitBy',
    cell: TextCell,
    category: 'array_setup',
    header: () => t('split_by', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'minLength',
    cell: IntegerCell,
    category: 'array_setup',
    header: () => t('min_length', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'maxLength',
    cell: IntegerCell,
    category: 'array_setup',
    header: () => t('max_length', { scope: 'activerecord.attributes.modifier' }),
  }),
  ...setupColumn({
    id: 'arrayIndex',
    graphqlFields: ['arrayFromStart', 'arrayIndex'],
    cell: TextCell,
    category: 'array_setup',
    header: () => t('array_index', { scope: 'activerecord.attributes.modifier' }),
    resolveChildren: ({ arrayIndex, arrayFromStart }) =>
      typeof arrayIndex !== 'undefined' && arrayIndex !== null
        ? [
            t(arrayIndex, { scope: 'modifiers_array_index' }),
            arrayIndex !== -1 ? t(arrayFromStart, { scope: 'modifiers_array_from_start' }) : '',
          ].join(' ')
        : '',
  }),
  ...setupColumn({
    id: 'imageResolution',
    cell: TextCell,
    graphqlFields: 'imageResolution',
    category: 'image',
    reverseOrder: true,
    header: () => t('attributes.image_resolution'),
  }),
  ...labels,
  ...conditions,
  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    cell: TimeAgoCell,
    graphqlFields: 'createdAt',
    reverseOrder: true,
    fixed: 'right',
    header: () => t('attributes.created_at'),
  }),
  ...setupColumn({
    id: 'updatedAt',
    order: 'updated_at',
    cell: TimeAgoCell,
    reverseOrder: true,
    fixed: 'right',
    graphqlFields: 'updatedAt',
    header: () => t('attributes.updated_at'),
  }),
};

export default mapping;
