import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery } from '@apollo/client';
import cs from 'classnames';
import Searchbox from './Searchbox';
import DefaultList from './DefaultList';
import { CreateElementButton, Icon, Row, Col, Ellipsis } from '../components';

const containsText = (name, searchText) => name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;

const filterItems = (items, searchText) => items.filter(item => containsText(item.name, searchText));

class BreadcrumbsDropdown extends React.Component {
  static propTypes = {
    bold: PropTypes.bool,
    coloredIcon: PropTypes.bool,
    createButton: PropTypes.object,
    currentItem: PropTypes.object.isRequired,
    dataTestId: PropTypes.string,
    iconType: PropTypes.string.isRequired,
    items: PropTypes.any,
    itemType: PropTypes.string.isRequired,
    left: PropTypes.bool,
    loaded: PropTypes.bool,
    loading: PropTypes.bool,
    loadItems: PropTypes.func,
    searchBoxText: PropTypes.string,
    small: PropTypes.bool,
    withoutSearch: PropTypes.bool,
  };

  static defaultProps = {
    items: [],
    bold: true,
    withoutSearch: false,
  };

  state = {
    isClosed: true,
    searchValue: '',
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate() {
    window.attach_tooltips_to_text_overflow();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setSearchboxRef = ref => {
    this.searchBoxRef = ref;
  };

  handleClickOutside = e => {
    if (
      !document
        .querySelector(`[data-component="BreadcrumbsDropdown"][data-json*="${this.props.itemType}"]`)
        .contains(e.target)
    ) {
      this.setState({
        isClosed: true,
      });
    }

    if (
      e.target &&
      e.target.parentNode &&
      e.target.parentNode.classList &&
      e.target.parentNode.classList.contains('js-profile') &&
      e.target.parentNode.parentNode.classList.contains('active')
    ) {
      if (document.getElementById('js-navbar-backdrop')) {
        document.getElementById('js-navbar-backdrop').classList.remove('d-none');
      }
    }
  };

  search = () => {
    const { items } = this.props;
    const search = this.state.searchValue.trim();
    return search.length > 0 ? filterItems(items, search) : items;
  };

  handleSearch = ({ target: { value } }) => {
    this.setState({
      searchValue: value,
    });
  };

  openDropdown = () => {
    const isClosed = !this.state.isClosed;
    this.setState({ isClosed });
    if (isClosed) {
      if (document.getElementById('js-navbar-backdrop')) {
        document.getElementById('js-navbar-backdrop').classList.add('d-none');
      }
    } else {
      if (document.getElementById('js-navbar-backdrop')) {
        document.getElementById('js-navbar-backdrop').classList.remove('d-none');
      }
      setTimeout(() => {
        if (this.searchBoxRef) {
          this.searchBoxRef.focus();
        }
      }, 10);
    }

    if (!this.props.loaded && typeof this.props.loadItems === 'function') {
      this.props.loadItems();
    }
  };

  render() {
    const {
      bold,
      coloredIcon,
      createButton,
      currentItem,
      dataTestId,
      iconType,
      left,
      loaded,
      loading,
      searchBoxText,
      small,
      withoutSearch,
    } = this.props;

    const { isClosed, searchValue } = this.state;

    const filteredItems = this.search();

    if (!currentItem) {
      return null;
    }

    return (
      <React.Fragment>
        <div className="Navbar-slash vabl">/</div>
        <div className={cs('Navbar-item', 'ph-0', { active: !isClosed })}>
          <div
            className={cs('Breadcrumbs-dropdownButton js-navbar-dropdown no-user-select', { selected: bold })}
            onClick={this.openDropdown}
            data-test-id={dataTestId}
          >
            <Row center>
              {currentItem && !currentItem.isNewRecord && !currentItem.isEmpty && (
                <React.Fragment>
                  {currentItem.icon && (
                    <Col shrink>
                      <Icon kind={currentItem.icon} inheritColor className="Breadcrumbs-dropdownButton-icon" />
                    </Col>
                  )}
                  <Col grow>
                    <Ellipsis>{currentItem.name}</Ellipsis>
                  </Col>
                </React.Fragment>
              )}
              <Icon kind="chevron-down" inheritColor className="Breadcrumbs-dropdownButton-chevron" />
            </Row>
          </div>

          <div
            className={cs('Breadcrumbs-dropdown', {
              active: !isClosed,
              'Breadcrumbs-dropdown--left': left,
              'Breadcrumbs-dropdown--small': small,
            })}
          >
            {!withoutSearch && (
              <div className="Breadcrumbs-search pa-12 delimiter">
                <Searchbox
                  searchBoxText={searchBoxText || ''}
                  searchValue={searchValue}
                  setInputRef={this.setSearchboxRef}
                  handleSearch={this.handleSearch}
                />
              </div>
            )}

            <DefaultList
              coloredIcon={coloredIcon}
              currentOrganizationId={currentItem.id}
              hasActiveSearch={searchValue?.length > 0}
              iconType={iconType}
              listSpacing={withoutSearch ? 6 : 12}
              loaded={loaded}
              loading={loading}
              organizations={filteredItems}
            />

            {createButton?.buttonText && (
              <div className="pa-12 text-center delimiter-top">
                <CreateElementButton hideText={false} {...createButton} className="w-100 text-left" />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const QUERY = itemType => gql`
  query($organizationId: BigInt, $dataSourceId: BigInt) {
    collection(
      identifier: "${itemType}",
      limit: 10000,
      page: 0,
      order: "name",
      organizationId: $organizationId
      dataSourceId: $dataSourceId
    ) {
      ${itemType} {
        id
        name
        showLink
        icon
      }
    }
  }
`;

const AsyncBreadcrumbsDropdown = memo(
  ({ itemType, organizationId, dataSourceId, graphql, items, createButton, ...rest }) => {
    if (graphql) {
      const [loadItems, { loading, data, called }] = useLazyQuery(QUERY(itemType), {
        variables: { organizationId, dataSourceId },
      });

      return (
        <BreadcrumbsDropdown
          loading={loading}
          loadItems={called ? () => {} : loadItems}
          items={data && data.collection[itemType]}
          loaded={called && data && data.collection[itemType]}
          itemType={itemType}
          createButton={{ ...createButton, organizationId }}
          {...rest}
        />
      );
    }

    return <BreadcrumbsDropdown items={items && items} itemType={itemType} {...rest} />;
  }
);

AsyncBreadcrumbsDropdown.propTypes = {
  itemType: PropTypes.string.isRequired,
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  createButton: PropTypes.object,
  items: PropTypes.any,
  graphql: PropTypes.bool,
};

export default AsyncBreadcrumbsDropdown;
