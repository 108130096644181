import React from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { t, formatCurrency, formatInteger } from '../../i18n';
import { UsedByGraph, Row } from '../index';

const UsageSpend = ({ value, unit, estimated, total }) => {
  if (total) {
    return (
      <Row center inline shrink>
        <UsedByGraph products={value} productsTotal={total} size="md" />
        <span className="ml-4 Text--default">
          {t('views.subscription.usage', { value: formatInteger(value), total: formatInteger(total) })}
        </span>
      </Row>
    );
  }
  return (
    <span>
      {formatCurrency(value, { unit, precision: 0 })}
      {estimated && ` / ${formatCurrency(estimated, { unit, precision: 0 })}`}
    </span>
  );
};

UsageSpend.propTypes = {
  value: PropTypes.number,
  unit: PropTypes.string,
  estimated: PropTypes.number,
  total: PropTypes.number,
};

export default hot(UsageSpend);
