import React from 'react';
import PropTypes from 'prop-types';
import { Chart } from '../components/index';

const defaultData = [
  { date: '2021-01-02', spend: Math.random() * 10 },
  { date: '2021-01-12', spend: Math.random() * 10 },
  { date: '2021-01-14', spend: Math.random() * 10 },
  { date: '2021-01-17', spend: Math.random() * 10 },
  { date: '2021-01-23', spend: Math.random() * 10 },
  { date: '2021-01-28', spend: Math.random() * 10 },
];

const EmptyChart = ({ data = defaultData, ...rest }) => {
  const area = 'spend';

  return (
    <Chart
      areas={{
        [area]: {
          color: '#F2F3F4',
          strokeWidth: 1,
        },
      }}
      data={data}
      height={150}
      isEmpty
      disableTooltip
      margin={{ left: -60 }}
      showToggles={false}
      smooth={false}
      strokeDasharray={null}
      verticalLines={false}
      xAxis={{
        axisLine: false,
        tickLine: false,
      }}
      yAxis={{
        axisLine: false,
        tickLine: false,
      }}
      {...rest}
    />
  );
};

EmptyChart.propTypes = {
  data: PropTypes.array,
};

export default EmptyChart;
