/* eslint-disable no-console */
import { compress, decompress } from 'lz-string';

const tempStorage = {};

const get = (key, opts = {}) => {
  try {
    if (typeof localStorage !== 'undefined') {
      const value = window.localStorage.getItem(key);
      return opts.useCompression && value ? decompress(value) : value || opts.default;
    }

    return tempStorage[key] || opts.default;
  } catch (e) {
    console.warn('Unable to get local storage key', key, e.message);
    return tempStorage[key] || opts.default;
  }
};

const set = (key, value, opts = {}) => {
  try {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.setItem(key, opts.useCompression ? compress(value) : value);
    } else {
      tempStorage[key] = value;
    }
  } catch (e) {
    console.warn('Unable to set local storage key', key, e.message);
    tempStorage[key] = value;
  }
};

const remove = key => {
  try {
    if (typeof localStorage !== 'undefined') {
      window.localStorage.removeItem(key);
    } else {
      delete tempStorage[key];
    }
  } catch (e) {
    console.warn('Unable to remove local storage key', key, e.message);
    delete tempStorage[key];
  }
};

export default {
  get,
  set,
  remove,
};
