import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { groupBy, orderBy, flatten } from 'lodash';
import { t, formatPercentage } from '../i18n';
import { DropdownMenu, Icon, Row, Col, cssVariables, Text, Tooltip, UsedByGraph, Badge } from './index';
import { SyncingLoaderIcon, Loader } from './Icons';
import SystemIcon from './SystemIcon';

const childJobLogsSubjectTypes = {
  AdwordsSetting: {
    icon: <SystemIcon system="adwords" size="17px" />,
    text: t('adwords', { scope: 'campaign_setting' }),
  },
  SklikSetting: {
    icon: <SystemIcon system="sklik" size="17px" />,
    text: t('sklik', { scope: 'campaign_setting' }),
  },
  BingSetting: {
    icon: <SystemIcon system="bing" size="17px" />,
    text: t('bing', { scope: 'campaign_setting' }),
  },
  FBSetting: {
    icon: <SystemIcon system="facebook" size="17px" />,
    text: t('facebook', { scope: 'campaign_setting' }),
  },
};

const statusIconMapping = {
  waiting: <Icon kind="wait" color="#596774" size="16px" />,
  queued: <Icon kind="wait" color="#596774" size="16px" />,
  running: <SyncingLoaderIcon color="#596774" size="13px" />,
  started: <SyncingLoaderIcon color="#596774" size="13px" />,
  finished: <Icon kind="circle-check" color={cssVariables.grapePrimary} size="16px" />,
  failed: <Icon kind="danger" color={cssVariables.redPrimary} size="16px" />,
};

const ChildJobLog = ({ subjectType, state, asyncJobSteps, errorClass, errorMessage }) => {
  const statusIcon = statusIconMapping[state];
  const progress =
    (asyncJobSteps.filter(asyncJobStep => asyncJobStep.state === 'finished').length / asyncJobSteps.length) * 100;

  return (
    <React.Fragment>
      <Row center>
        <Col shrink>
          <div className="Text">{childJobLogsSubjectTypes[subjectType].icon}</div>
        </Col>
        <Col grow>
          <div className="Text">{childJobLogsSubjectTypes[subjectType].text}</div>
        </Col>
        <Col shrink>
          {state === 'started' && (
            <Tooltip text={formatPercentage(progress >= 100 ? 100 : progress)}>
              <UsedByGraph adSets={progress} adSetsTotal="100" size="sm" disableTooltip kind="remaining" />
            </Tooltip>
          )}
        </Col>
        <Col shrink>{statusIcon}</Col>
      </Row>
      {(errorClass || errorMessage) && (
        <div className="red" style={{ fontSize: '120%' }}>
          {errorClass}: {errorMessage?.slice(0, 150)}
        </div>
      )}
    </React.Fragment>
  );
};
ChildJobLog.propTypes = {
  text: PropTypes.string,
  asyncJobSteps: PropTypes.array,
  status: PropTypes.string,
  children: PropTypes.any,
};

const StepDetail = ({ text, status, children }) => {
  const statusIcon = statusIconMapping[status];

  return (
    <Row center>
      <Col grow>
        <div className="Text">{text}</div>
      </Col>
      {children && (
        <Col shrink className="Text">
          {children}
        </Col>
      )}
      <Col shrink>{statusIcon}</Col>
    </Row>
  );
};
StepDetail.propTypes = {
  text: PropTypes.string,
  status: PropTypes.string,
  children: PropTypes.any,
};

class StatusBox extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(['running', 'finished', 'failed', 'loading', 'waiting']).isRequired,
    statusText: PropTypes.string.isRequired,
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.any,
    steps: PropTypes.array,
    childJobLogs: PropTypes.array,
    progressBarId: PropTypes.string,
    lastImport: PropTypes.string,
    previousSyncDuration: PropTypes.number,
    dropdownMenuHeader: PropTypes.string,
    additionalContent: PropTypes.any,
  };

  static defaultProps = {
    steps: [],
  };

  state = {
    now: moment(),
  };

  componentDidMount() {
    this.intervalId = setInterval(this.updateNow, 1000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  progress() {
    const { previousSyncDuration, lastImport } = this.props;
    if (!previousSyncDuration || !lastImport) {
      return undefined;
    }
    if (previousSyncDuration < 0) {
      return undefined;
    }
    const currentDuration = this.state.now.diff(moment(lastImport), 'seconds');
    const progress = (currentDuration * 100) / previousSyncDuration;

    return progress < 100 ? progress : 100;
  }

  updateNow = () => {
    this.setState({ now: moment() });
  };

  render() {
    const {
      statusText,
      status,
      children,
      childJobLogs,
      steps,
      progressBarId,
      dropdownMenuHeader,
      additionalContent,
    } = this.props;
    const progressBar = progressBarId && document.getElementById(progressBarId);
    const progress = this.progress();

    if (status === 'loading') {
      return (
        <div className="StatusBox">
          <div className="StatusBox-content">
            <Row center>
              <Loader size="extraSmall" />
              <div className="Text Text--gray ml-8">{statusText}</div>
              {additionalContent}
            </Row>
          </div>
        </div>
      );
    }

    if (status === 'finished') {
      return (
        <div className="StatusBox" data-test-id={`sync-status-box-${status}`}>
          <div className="StatusBox-content">
            <Badge kind="lightGreen" size="extraLarge" className="Badge--spacedText" isWithBorder uppercase>
              <Row center>
                <Icon kind="active" size="16px" inheritColor className="mr-6" />
                {statusText}
              </Row>
            </Badge>
          </div>
        </div>
      );
    }

    let progressBarComponent = null;

    if (progress > 0 && status === 'running' && progressBar) {
      progressBarComponent = ReactDOM.createPortal(
        <div style={{ width: `${progress}%` }} className="StatusBox-progressBar hide-in-percy" />,
        progressBar
      );
    } else {
      progressBarComponent = <div style={{ width: `${progress}%` }} className="StatusBox-progressBar hide-in-percy" />;
    }

    if (steps.length === 0 && status !== 'failed') {
      return (
        <div className="StatusBox" data-test-id={`sync-status-box-${status}`}>
          <div className="StatusBox-content">
            <Badge kind="lightBlue" size="extraLarge" className="Badge--spacedText" isWithBorder uppercase>
              <Row center>
                <SyncingLoaderIcon size="14px" color={cssVariables.statusInformativeDefault} />
                <span className="ml-6">{statusText}</span>
              </Row>
            </Badge>
          </div>
          {progressBarComponent}
        </div>
      );
    }

    const childJobLogsToShow = (childJobLogs || []).filter(
      ({ subjectType }) => Object.keys(childJobLogsSubjectTypes).indexOf(subjectType) !== -1
    );

    const childJobLogsToShowArray = flatten(
      Object.values(groupBy(orderBy(childJobLogsToShow, ['id'], ['desc']), 'subjectType')).map(arr => arr[0])
    );

    return (
      <div
        className={`StatusBox ${status === 'failed' && 'StatusBox--error'}`}
        data-test-id={`sync-status-box-${status}`}
      >
        <div className="StatusBox-content">
          <DropdownMenu
            label={statusText}
            placement="bottom-start"
            dataTestId="status-box"
            labelIcon={
              status === 'failed' ? (
                <Icon kind="fill-error" inheritColor size="14px" />
              ) : (
                <SyncingLoaderIcon size="14px" color={cssVariables.statusInformativeDefault} />
              )
            }
            forceOpen={status === 'failed'}
            minWidth="288px"
            error={status === 'failed'}
            showButtonLikeBadge
          >
            <div className="pv-4 ph-8">
              {dropdownMenuHeader && (
                <Text className="pb-16 d-block" size="xs" color="softGray" bold spaced uppercase>
                  {dropdownMenuHeader}
                </Text>
              )}

              <Col padding="l">
                {steps.map(step => (
                  <StepDetail key={`${step.id}`} {...step} />
                ))}
              </Col>
              {childJobLogsToShowArray.length > 0 && (
                <div>
                  <div className="DropdownMenu-delimiter" />
                  <Col padding="l">
                    {childJobLogsToShowArray.map(childJobLog => (
                      <ChildJobLog key={childJobLog.id} {...childJobLog} />
                    ))}
                  </Col>
                </div>
              )}
              {children}
            </div>
          </DropdownMenu>
          {status !== 'failed' && progressBarComponent}
        </div>
      </div>
    );
  }
}

export default StatusBox;
