/* eslint-disable react/prop-types */
import React from 'react';
import { orderBy } from 'lodash';
import { setupColumn } from './setupColumn';
import { TextCell, StateCell, DayTimeDifferenceCell, IntegerCell, AdminErrorCell } from './cells';
import { cssVariables, SystemIconWithText } from '../../index';
import { t } from '../../../i18n';

const CUSTOM_STATES = {
  queued: {
    badgeKind: 'lightGray',
    icon: 'wait',
    text: t('react.async_job_logs.state.queued.text'),
    tooltip: t('react.async_job_logs.state.queued.tooltip'),
  },
  started: {
    badgeKind: 'lightBlue',
    icon: 'syncingLoader',
    iconColor: cssVariables.statusInformativeDefault,
    text: t('react.async_job_logs.state.started.text'),
    tooltip: t('react.async_job_logs.state.started.tooltip'),
  },
  waiting_for_child_job_logs: {
    badgeKind: 'lightBlue',
    icon: 'syncingLoader',
    iconColor: cssVariables.statusInformativeDefault,
    text: t('react.async_job_logs.state.started.text'),
    tooltip: t('react.async_job_logs.state.started.tooltip'),
  },
  finished: {
    badgeKind: 'lightGreen',
    icon: 'check',
    text: t('react.async_job_logs.state.finished.text'),
    tooltip: t('react.async_job_logs.state.finished.tooltip'),
  },
  failed: {
    badgeKind: 'lightRed',
    icon: 'danger',
    text: t('react.async_job_logs.state.failed.text'),
    tooltip: t('react.async_job_logs.state.failed.tooltip'),
  },
  partialy_failed: {
    badgeKind: 'lightRed',
    icon: 'danger',
    text: t('react.async_job_logs.state.failed.text'),
    tooltip: t('react.async_job_logs.state.failed.tooltip'),
  },
  failed_and_retried: {
    badgeKind: 'lightRed',
    icon: 'danger',
    text: t('react.async_job_logs.state.failed_and_retried.text'),
    tooltip: t('react.async_job_logs.state.failed_and_retried.tooltip'),
  },
  partialy_failed_and_retried: {
    badgeKind: 'lightRed',
    icon: 'danger',
    text: t('react.async_job_logs.state.failed_and_retried.text'),
    tooltip: t('react.async_job_logs.state.failed_and_retried.tooltip'),
  },
  aborted: {
    badgeKind: 'lightOrange',
    icon: 'menu-blacklist',
    text: t('react.async_job_logs.state.aborted.text'),
    tooltip: t('react.async_job_logs.state.aborted.tooltip'),
  },
};

const mapping = {
  ...setupColumn({
    id: 'state',
    graphqlFields: ['errorHuman', 'state'],
    cell: StateCell,
    required: true,
    header: () => t('state', { scope: 'react.async_job_logs.tables' }),
    resolveChildren: ({ state, errorHuman }) => ({
      state,
      customIcons: CUSTOM_STATES,
      error: errorHuman,
    }),
  }),

  ...setupColumn({
    id: 'kind',
    required: true,
    graphqlField: 'kind',
    header: () => t('kind', { scope: 'react.async_job_logs.tables' }),
    cell: TextCell,
    // eslint-disable-next-line consistent-return
    resolveChildren: ({ kind }) => {
      if (
        kind === 'data_source_refresh' ||
        kind === 'data_source_modifiers_update' ||
        kind === 'campaign_generation' ||
        kind === 'campaign_change_state' ||
        kind === 'campaign_synchronization_clear' ||
        kind === 'campaign_synchronization_delta' ||
        kind === 'campaign_synchronization_force' ||
        kind === 'campaign_synchronization_full'
      ) {
        return t(`react.async_job_logs.${kind}`);
      }

      return '';
    },
  }),
  ...setupColumn({
    id: 'time',
    required: true,
    graphqlFields: ['state', 'createdAt', 'finishedAt', 'startedAt'],
    header: () => t('time', { scope: 'react.async_job_logs.tables' }),
    cell: DayTimeDifferenceCell,
    resolveChildren: ({ state, createdAt, startedAt, finishedAt }) => {
      if (state === 'queued') return { from: createdAt, to: null };
      if (state === 'aborted') return { from: startedAt || createdAt, to: finishedAt };
      return { from: startedAt, to: finishedAt };
    },
  }),
  ...setupColumn({
    id: 'duration',
    required: true,
    header: () => t('duration', { scope: 'react.async_job_logs.tables' }),
    cell: TextCell,
    resolveChildren: ({ duration }) => (duration ? new Date(duration * 1000).toISOString().substring(11, 19) : null),
  }),
  ...setupColumn({
    id: 'addedCount',
    required: true,
    graphqlFields: ['addedCount', 'state'],
    header: () => t('added_count', { scope: 'react.async_job_logs.tables' }),
    cell: IntegerCell,
    resolveChildren: ({ state, addedCount }) => (state === 'finished' ? addedCount : null),
  }),
  ...setupColumn({
    id: 'updatedCount',
    required: true,
    graphqlFields: ['updatedCount', 'state'],
    header: () => t('updated_count', { scope: 'react.async_job_logs.tables' }),
    cell: IntegerCell,
    resolveChildren: ({ state, updatedCount }) => (state === 'finished' ? updatedCount : null),
  }),
  ...setupColumn({
    id: 'unchangedCount',
    required: true,
    graphqlFields: ['unchangedCount', 'state'],
    header: () => t('unchanged_count', { scope: 'react.async_job_logs.tables' }),
    cell: IntegerCell,
    resolveChildren: ({ state, unchangedCount }) => (state === 'finished' ? unchangedCount : null),
  }),
  ...setupColumn({
    id: 'removedCount',
    required: true,
    graphqlFields: ['removedCount', 'state'],
    header: () => t('removed_count', { scope: 'react.async_job_logs.tables' }),
    cell: IntegerCell,
    resolveChildren: ({ state, removedCount }) => (state === 'finished' ? removedCount : null),
  }),
  ...setupColumn({
    id: 'finalCount',
    required: true,
    graphqlFields: ['finalCount', 'state'],
    header: () => t('final_count', { scope: 'react.async_job_logs.tables' }),
    cell: IntegerCell,
    resolveChildren: ({ state, finalCount }) => (state === 'finished' ? finalCount : null),
  }),
  ...setupColumn({
    id: 'AdwordsSetting',
    required: true,
    graphqlFields: { childJobLogs: ['id', 'subjectType', 'state', 'errorHuman'] },
    header: () => (
      <SystemIconWithText system="adwords" size={14}>
        {t('adwords', { scope: 'campaign_setting' })}
      </SystemIconWithText>
    ),
    cell: StateCell,
    resolveChildren: ({ childJobLogs }) => {
      const foundJob = orderBy(childJobLogs, ['id'], ['desc']).filter(
        childJobLog => childJobLog.subjectType === 'AdwordsSetting'
      )[0];
      return {
        state: foundJob?.state || 'do_not_show_state',
        customIcons: CUSTOM_STATES,
        error: foundJob?.errorHuman,
      };
    },
  }),
  ...setupColumn({
    id: 'SklikSetting',
    required: true,
    graphqlFields: { childJobLogs: ['id', 'subjectType', 'state', 'errorHuman'] },
    header: () => (
      <SystemIconWithText system="sklik" size={14}>
        {t('sklik', { scope: 'campaign_setting' })}
      </SystemIconWithText>
    ),
    cell: StateCell,
    resolveChildren: ({ childJobLogs }) => {
      const foundJob = orderBy(childJobLogs, ['id'], ['desc']).filter(
        childJobLog => childJobLog.subjectType === 'SklikSetting'
      )[0];
      return {
        state: foundJob?.state || 'do_not_show_state',
        customIcons: CUSTOM_STATES,
        error: foundJob?.errorHuman,
      };
    },
  }),
  ...setupColumn({
    id: 'BingSetting',
    required: true,
    graphqlFields: { childJobLogs: ['id', 'subjectType', 'state', 'errorHuman'] },
    header: () => (
      <SystemIconWithText system="bing" size={14}>
        {t('bing', { scope: 'campaign_setting' })}
      </SystemIconWithText>
    ),
    cell: StateCell,
    resolveChildren: ({ childJobLogs }) => {
      const foundJob = orderBy(childJobLogs, ['id'], ['desc']).filter(
        childJobLog => childJobLog.subjectType === 'BingSetting'
      )[0];
      return {
        state: foundJob?.state || 'do_not_show_state',
        customIcons: CUSTOM_STATES,
        error: foundJob?.errorHuman,
      };
    },
  }),
  ...setupColumn({
    id: 'FBSetting',
    required: true,
    graphqlFields: { childJobLogs: ['id', 'subjectType', 'state', 'errorHuman'] },
    header: () => (
      <SystemIconWithText system="facebook" size={14}>
        {t('facebook', { scope: 'campaign_setting' })}
      </SystemIconWithText>
    ),
    cell: StateCell,
    resolveChildren: ({ childJobLogs }) => {
      const foundJob = orderBy(childJobLogs, ['id'], ['desc']).filter(
        childJobLog => childJobLog.subjectType === 'FBSetting'
      )[0];
      return {
        state: foundJob?.state || 'do_not_show_state',
        customIcons: CUSTOM_STATES,
        error: foundJob?.errorHuman,
      };
    },
  }),
  ...setupColumn({
    id: 'adminError',
    required: true,
    graphqlFields: [
      'errorClass',
      'errorMessage',
      'errorBacktrace',
      { childJobLogs: ['id', 'subjectType', 'errorClass', 'errorMessage', 'errorBacktrace'] },
    ],
    header: () => t('admin_error', { scope: 'react.async_job_logs.tables' }),
    cell: AdminErrorCell,
    resolveChildren: ({ errorClass, errorMessage, errorBacktrace, childJobLogs }) => ({
      errorClass,
      errorMessage,
      errorBacktrace,
      childJobLogs,
    }),
  }),
};

export default mapping;
