import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReportGraphqlWrapper from './ReportCharts/ReportGraphqlWrapper';
import ReportTable from './ReportTable/ReportTable';
import { Col, Row } from './layout';
import ReportPieChart from './ReportCharts/ReportPieChart';
import DashboardTile from './dashboard/Tile';
import { GraphqlTable, Icon } from './index';
import { t } from '../i18n';
import { MazeButton } from './Button';

const TabAsset = ({ organizationId, campaignId, assetTypes, assetStates, assetPerformances }) => (
  <div>
    <GraphqlTable
      graphql
      canEdit={false}
      organizationId={organizationId}
      campaignSettingId={campaignId}
      limit={10000}
      perOptions={[]}
      collectionKey="assetStats"
      itemKey="assetStat"
      disableColumnsChange
      columns={['text', 'type', 'status', 'performanceLabel', 'assetGroup', 'id', 'link']}
      hidePagination
      enabledFilters={['fulltext', 'pmaxType', 'pmaxPerformance', 'pmaxStatus']}
      searchOptions={{
        pmaxTypes: [
          {
            value: 'all',
            label: 'All types',
          },
          ...assetTypes.map(type => ({ value: type, label: t(`views.campaign_settings.pmax.enums.type.${type}`) })),
        ],
        pmaxStatuses: [
          {
            value: 'all',
            label: 'All statuses',
          },
          ...assetStates.map(status => ({
            value: status,
            label: t(`views.campaign_settings.pmax.enums.status.${status}`),
          })),
        ],
        pmaxPerformances: [
          {
            value: 'all',
            label: 'All performances',
          },
          ...assetPerformances.map(performance => ({
            value: performance,
            label: t(`views.campaign_settings.pmax.enums.performance.${performance}`),
          })),
        ],
      }}
    />
  </div>
);

TabAsset.propTypes = {
  organizationId: PropTypes.number,
  campaignId: PropTypes.number,
  assetTypes: PropTypes.arrayOf(PropTypes.string),
  assetStates: PropTypes.arrayOf(PropTypes.string),
  assetPerformances: PropTypes.arrayOf(PropTypes.string),
};

const PlacementContent = ({ data }) => {
  const [selectedMetric, setSelectedMetric] = useState('cost');
  const reportData = data.organization.campaignSetting.placementStats;

  const tabs = useMemo(
    () => [
      { title: 'Cost', onClick: () => setSelectedMetric('cost') },
      { title: 'Clicks', onClick: () => setSelectedMetric('clicks') },
      { title: 'Impressions', onClick: () => setSelectedMetric('impressions') },
      {
        title: t('views.campaign_settings.pmax.dashboard.conversions', { default: 'Conversions' }),
        onClick: () => setSelectedMetric('conversions'),
      },
      {
        title: t('views.campaign_settings.pmax.dashboard.conversionValue', { default: 'Conversion Value' }),
        onClick: () => setSelectedMetric('conversionValue'),
      },
    ],
    [setSelectedMetric]
  );

  return (
    <>
      <Row padding="l">
        <Col>
          <ReportTable data={reportData} />
        </Col>
      </Row>
      <Row padding="l" className="mt-16">
        <Col>
          <DashboardTile fullHeight tabs={tabs}>
            <Row center justifyCenter className="w-100 h-100">
              <ReportPieChart data={reportData} metricName={selectedMetric} />
            </Row>
          </DashboardTile>
        </Col>
      </Row>
    </>
  );
};

PlacementContent.propTypes = {
  data: PropTypes.shape({}),
};

const TabPlacement = ({ organizationId, campaignId, dateFrom, dateTo }) => (
  <ReportGraphqlWrapper
    organizationId={organizationId}
    campaignId={campaignId}
    dateRange={{ from: dateFrom, to: dateTo }}
  >
    <PlacementContent />
  </ReportGraphqlWrapper>
);

TabPlacement.propTypes = {
  organizationId: PropTypes.number,
  campaignId: PropTypes.number,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
};

const CampaignDashboardStats = ({
  children,
  organizationId,
  campaignId,
  dateFrom,
  dateTo,
  assetTypes,
  assetStates,
  assetPerformances,
}) => {
  const [activeTab, setActiveTab] = useState('placement');

  const tabs = useMemo(
    () => [
      { title: 'Placement performance', onClick: () => setActiveTab('placement') },
      { title: 'Asset performance', onClick: () => setActiveTab('asset') },
    ],
    [setActiveTab]
  );

  return (
    <DashboardTile
      style={{ minHeight: '291px' }}
      tabs={tabs}
      header={t('views.campaign_settings.pmax.asset_stats_title')}
      additionalHeaderContent={
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <Icon color="#859BAB" kind="calendar" className="mr-8" size={20} />
          <span className="Text--lightGray mr-16">{t('views.campaign_settings.pmax.asset_stats_timerange_info')}</span>
          <MazeButton href="https://tally.so/r/mJ2Nqz" />
        </span>
      }
    >
      {activeTab === 'placement' && (
        <TabPlacement organizationId={organizationId} campaignId={campaignId} dateFrom={dateFrom} dateTo={dateTo} />
      )}

      {activeTab === 'asset' && (
        <TabAsset
          organizationId={organizationId}
          campaignId={campaignId}
          assetStates={assetStates}
          assetTypes={assetTypes}
          assetPerformances={assetPerformances}
        />
      )}
      {children}
    </DashboardTile>
  );
};

CampaignDashboardStats.propTypes = {
  children: PropTypes.any,
  organizationId: PropTypes.number,
  campaignId: PropTypes.number,
  dateFrom: PropTypes.string,
  dateTo: PropTypes.string,
  assetTypes: PropTypes.arrayOf(PropTypes.string),
  assetStates: PropTypes.arrayOf(PropTypes.string),
  assetPerformances: PropTypes.arrayOf(PropTypes.string),
};

export default CampaignDashboardStats;
