import React, { useCallback, useMemo, useState } from 'react';
import ModalContainer, { ModalBody, ModalFooter } from '../Modal';
import { Row } from '../layout';
import Button from '../Button';

export const ConfirmationModal = ({
  onClose,
  title = window.old_t('modal.title'),
  message = window.old_t('modal.commit'),
  cancel = window.old_t('modal.cancel'),
  confirm = window.old_t('modal.confirm'),
  onConfirm,
  isOpen,
}) => {
  const handleConfirm = () => {
    if (onConfirm) {
      onConfirm();
      onClose();
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <ModalContainer className="Modal--confirm" onClose={onClose} heading={title}>
      <ModalBody>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </ModalBody>
      <ModalFooter>
        <Row>
          <Button className="commit" red onClick={handleConfirm}>
            {confirm}
          </Button>
          <Button className="cancel" tertiary onClick={onClose}>
            {cancel}
          </Button>
        </Row>
      </ModalFooter>
    </ModalContainer>
  );
};

export const useConfirmationModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [confirmCallback, setConfirmCallback] = useState(null);
  const [customProps, setCustomProps] = useState({});

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);

  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

  const callWithConfirmation = useCallback(
    (callback, props) => {
      setConfirmCallback(() => callback);
      setCustomProps(props || {});
      handleOpen();
    },
    [setConfirmCallback, setCustomProps, handleOpen]
  );

  const modalProps = useMemo(
    () => ({
      isOpen,
      onClose: handleClose,
      onOpen: handleOpen,
      onConfirm: confirmCallback,
      ...customProps,
    }),
    [isOpen, customProps, confirmCallback, handleClose, handleOpen]
  );

  const returnValue = useMemo(() => [callWithConfirmation, modalProps], [callWithConfirmation, modalProps]);

  return returnValue;
};
