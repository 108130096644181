import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAccordion } from './Accordion';
import { ThreeStatedCheckbox, Ellipsis } from './index';

const CheckboxWithDropdown = ({
  value,
  checked,
  openOnInit = false,
  onClick,
  label,
  children,
  type,
  className,
  name,
  lineCheck,
  openAccordion,
  contentClass,
  maxWidth = '200px',
}) => {
  const [isAccordionOpen, Accordion, setShowDetail] = useAccordion({
    forceOpen: openOnInit,
  });

  useEffect(() => {
    if (!isAccordionOpen) setShowDetail(openAccordion);
  }, [openAccordion]);

  return (
    <>
      <Accordion
        key={[type, isAccordionOpen].join('-')}
        bigHeader={false}
        contentClass={contentClass}
        data-test-id="eta"
        onlyClickOnChevron
        style={{ cursor: 'default' }}
        className={`${className} border-none mb-0`}
        hideChevron={!children}
        headerComponent={
          <div className="pa-8 pr-32">
            <ThreeStatedCheckbox
              lineCheck={lineCheck}
              label={
                <div style={{ maxWidth }}>
                  <Ellipsis>{label}</Ellipsis>
                </div>
              }
              value={value}
              name={name}
              doNotUseInternalState
              checked={checked}
              onClick={e => e.stopPropagation()}
              onChange={onClick}
            />
          </div>
        }
      >
        <div className="pl-16 negative-mb-16">{children}</div>
      </Accordion>
    </>
  );
};

CheckboxWithDropdown.propTypes = {
  className: PropTypes.string,
  contentClass: PropTypes.string,
  checked: PropTypes.bool,
  children: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  lineCheck: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  openAccordion: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  openOnInit: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default CheckboxWithDropdown;
