/* eslint-disable no-param-reassign */
/* eslint-disable no-new */
import Swiper from 'swiper/dist/js/swiper';

window.initFBPreviewCarousel = () => {
  const previewCarousels = document.getElementsByClassName('js-fb-preview-carousel');

  Array.from(previewCarousels).map(container => {
    if (!container.dataset.initFBCarousel) {
      container.dataset.initFBCarousel = true;

      new Swiper(container, {
        slidesPerView: 'auto',
        centeredSlides: true,
        slideToClickedSlide: true,
        autoHeight: true,
        spaceBetween: 16,
      });
    }
    return false;
  });
};
