import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Checkbox } from './index';

const ThreeStatedCheckbox = ({ lineCheck, ...rest }) => (
  <div
    className={cs('Checkboxes', {
      'Checkboxes--lineCheck': lineCheck,
    })}
  >
    <Checkbox {...rest} />
  </div>
);
ThreeStatedCheckbox.propTypes = {
  lineCheck: PropTypes.bool,
};

export default ThreeStatedCheckbox;
