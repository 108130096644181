import React from 'react';
import PropTypes from 'prop-types';
import { Status } from '../index';
import { POSSIBLE_TRIAL } from './constants';
import { getValues, isUsed } from './utils';

const SubscriptionStatus = ({ tarif, status, actionLink, period, ...rest }) => {
  if (tarif === POSSIBLE_TRIAL) {
    if (actionLink !== '' && (period === 'current' || period === 'current_past')) return null;
    return <Status kind="not_active" />;
  }

  const isFeatureUsed = isUsed({ tarif, ...rest });
  const kind = getValues({ tarif, featureStatus: status, isFeatureUsed })?.tableStatusKind;

  return <Status kind={kind} />;
};
SubscriptionStatus.propTypes = {
  actionLink: PropTypes.string,
  period: PropTypes.string,
  status: PropTypes.string,
  tarif: PropTypes.string,
};

export default SubscriptionStatus;
