import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

export default class Checkboxes extends Component {
  static propTypes = {
    collection: PropTypes.arrayOf(PropTypes.object).isRequired,
    doNotUseInternalState: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any.isRequired,
    label: PropTypes.string,
  };

  static defaultProps = {
    collection: [],
    doNotUseInternalState: false,
    required: false,
    value: [],
  };

  state = {
    value: this.props.value || [],
    uuid: uuid(),
  };

  handleChange = e => {
    const {
      target: { value, checked },
    } = e;

    const newValue = checked ? this.state.value.concat([value]) : this.state.value.filter(v => v !== value);
    this.setState({ value: newValue });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: newValue } });
    }
  };

  render() {
    const { collection, value, name, id, doNotUseInternalState, label } = this.props;
    const selected = doNotUseInternalState ? value : this.state.value;
    const inputId = id || this.state.uuid;

    return (
      <div className="Checkboxes">
        {label && <label htmlFor={inputId}>{label}</label>}
        {collection.map(option => {
          if (typeof option === 'object') {
            const { key, value: optionValue, labelFromOption } = option;
            const optionId = `${inputId}_${key || optionValue}`;

            return (
              <div className="Checkboxes-item" key={optionId}>
                <input
                  id={optionId}
                  name={`${name}[]`}
                  className="Checkboxes-input"
                  checked={selected.indexOf(optionValue) !== -1}
                  value={optionValue}
                  onChange={this.handleChange}
                  type="checkbox"
                />
                <label htmlFor={optionId}>{labelFromOption || optionValue}</label>
              </div>
            );
          }
          const optionId = `${inputId}_${option}`;
          return (
            <div className="Checkboxes-item" key={optionId}>
              <input
                id={optionId}
                name={`${name}[]`}
                className="Checkboxes-input"
                checked={selected.indexOf(option) !== -1}
                value={option}
                onChange={this.handleChange}
                type="checkbox"
              />
              <label htmlFor={optionId}>{option}</label>
            </div>
          );
        })}
      </div>
    );
  }
}
