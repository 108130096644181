import React from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { SubscriptionTile, UsageFeature } from './index';
import { Row, Col } from '../layout/Grid';
import { t } from '../../i18n';

export const getPeriodVariant = ({ forecastPrice, pastPrice, currentPrice, period, loading = false }) => {
  const variants = {
    current: [
      {
        icon: 'calendar',
        price: currentPrice,
        label: t('feature_period.period_overview.month_to_date_price'),
        noFormat: true,
        loading,
      },
      {
        icon: 'budget',
        price: forecastPrice,
        label: t('feature_period.period_overview.period_price_forecast'),
        subHeading: pastPrice
          ? t('feature_period.period_overview.price_in_previous_period', { price: pastPrice })
          : null,
        noFormat: true,
        loading,
      },
    ],
    past: [
      {
        icon: 'budget',
        price: currentPrice,
        label: t('feature_period.period_overview.period_price'),
        subHeading: pastPrice && t('feature_period.period_overview.price_in_previous_period', { price: pastPrice }),
        noFormat: true,
      },
    ],
    current_past: [
      {
        icon: 'budget',
        price: currentPrice,
        label: t('feature_period.period_overview.period_price'),
        subHeading: pastPrice && t('feature_period.period_overview.price_in_previous_period', { price: pastPrice }),
        noFormat: true,
      },
    ],
    future: [
      {
        icon: 'budget',
        price: currentPrice,
        label: t('feature_period.period_overview.period_price_forecast'),
        subHeading: pastPrice && t('feature_period.period_overview.price_in_previous_period', { price: pastPrice }),
        noFormat: true,
      },
    ],
  };
  return variants[period];
};

const PeriodHeading = () => (
  <h3 className="Heading Heading--lg pt-0 mt-0">{t('feature_period.period_overview.heading')}</h3>
);

const PeriodOverview = ({ ...rest }) => {
  const overviewData = getPeriodVariant({ ...rest }) || [];
  return (
    <SubscriptionTile className="mt-16" subheading={<PeriodHeading />} subheadingClass="mt-0">
      <Row center justifyAround>
        {overviewData.map((feature, i) => (
          <Col shrink key={[feature.label, feature.icon, i].join('_')}>
            <UsageFeature {...feature} />
          </Col>
        ))}
      </Row>
    </SubscriptionTile>
  );
};

PeriodOverview.propTypes = {
  currentPrice: PropTypes.string,
  forecastPrice: PropTypes.string,
  loading: PropTypes.bool,
  pastPrice: PropTypes.string,
  period: PropTypes.string,
};

export default hot(PeriodOverview);
