import React from 'react';
import { hot } from 'react-hot-loader/root';
import { PropTypes } from 'prop-types';
import { Label, ReferenceLine } from 'recharts';
import { Chart, Row, Col, Text, SmallHeading, SectionHeading, ErrorBox, cssVariables } from '../index';
import EmptyChart from '../../dashboard/EmptyChart';
import { CustomizedAxisTick } from '../../dashboard/MainPerformanceChart';
import { formatDate, t } from '../../i18n';

const CustomizedTooltip = hot(({ active, label, payload }) => {
  if (!active || !payload) return null;

  return (
    <div className="CustomizedTooltip pa-8">
      <Text size="sm" color="black-light">
        {formatDate(label, 'medium')}
      </Text>

      {payload.map(item => (
        <SmallHeading className="CustomizedTooltip--mainHeading" spacing={0} key={item?.payload?.id}>
          {item.payload?.formattedValue || item.payload?.value}
        </SmallHeading>
      ))}
    </div>
  );
});

const ChartWrapper = ({ children, isSpend }) => (
  <Row className="mt-16">
    <Col>
      <SectionHeading spacing={8} className="ml-16">
        {t(isSpend ? 'spend.until_yesterday' : 'fix.until_yesterday', { scope: 'feature_period.header.future' })}
      </SectionHeading>
      {children}
    </Col>
  </Row>
);

ChartWrapper.propTypes = {
  children: PropTypes.element,
  isSpend: PropTypes.bool,
};

export const ErrorChartComponent = props => (
  <ChartWrapper {...props}>
    <ErrorBox className="ma-16 mt-0" withIcon>
      {t('react.error_with_component_render')}
    </ErrorBox>
  </ChartWrapper>
);

export const NoDataChartComponent = props => (
  <ChartWrapper {...props}>
    <EmptyChart
      {...props}
      height={220}
      hideYAxis
      legend="date"
      margin={{ top: 5, right: 16, bottom: 5, left: 16 }}
      verticalLines={false}
      scale="point"
      xAxis={{
        tick: (
          <CustomizedAxisTick
            format={val => formatDate(val?.date || val, 'short')}
            dataKey="date"
            options={{ dy: 9, textAnchor: 'middle' }}
            fixPositioning={{ start: props?.period?.beginsOn, end: props?.period?.endsOn }}
          />
        ),
        interval: 4,
        tickLine: false,
        stroke: '#98A2B3',
        strokeWidth: 1,
      }}
    />
  </ChartWrapper>
);

const getLabelPosition = (isScattered, index) => {
  if (isScattered && index === 1) {
    return 'insideTopCenter';
  }
  if (isScattered && index === 2) {
    return 'insideTopRight';
  }
  return 'insideTopLeft';
};

export const ChartComponent = props => {
  const nextTierLimit = props.referenceLines[props.referenceLines.length - 1]?.value;
  return (
    <ChartWrapper {...props}>
      <Chart
        {...props}
        referenceLines={(props.referenceLines || []).map(
          (y, index) =>
            y &&
            y?.value && (
              <ReferenceLine
                key={[y.value, index].join('-')}
                ifOverflow="extendDomain"
                y={y.value}
                stroke="#0085FF"
                strokeDasharray={index !== 1 ? '3 3' : undefined}
                isFront
              >
                <Label
                  value={`${props.format(y.value)} ${y.label}`}
                  position={getLabelPosition(props.scatteredLabels, index)}
                  style={{ fill: '#0085FF' }}
                />
              </ReferenceLine>
            )
        )}
        canAreasChange
        areas={{
          value: {
            color: 'rgba(255, 174, 66, 0.15)',
            strokeColor: cssVariables.blueNebula,
            strokeWidth: 4,
            name: 'value',
          },
        }}
        isAnimated
        legend="date"
        legendFormatter={val => formatDate(val, 'short')}
        showGrid
        showToggles={false}
        smooth={false}
        activeDot={{ r: 4, stroke: cssVariables.blueNebula, strokeWidth: 4, orientation: 'inner', fill: 'white' }}
        strokeDasharray={null}
        customizedTooltip={CustomizedTooltip}
        verticalLines={false}
        scale="point"
        hideYAxis
        getDataKey={d => d.value}
        margin={{ top: 5, right: 16, bottom: 5, left: 16 }}
        xAxis={{
          tick: (
            <CustomizedAxisTick
              format={val => formatDate(val?.date || val, 'short')}
              dataKey="date"
              options={{ dy: 9, textAnchor: 'middle' }}
              fixPositioning={{ start: props?.period?.beginsOn, end: props?.period?.endsOn }}
            />
          ),
          interval: 4,
          tickLine: false,
          stroke: '#98A2B3',
          strokeWidth: 1,
        }}
        yAxis={{
          axisLine: false,
          tickLine: false,
          domain: nextTierLimit ? [0, nextTierLimit] : null,
        }}
      />
    </ChartWrapper>
  );
};

ChartComponent.propTypes = {
  referenceLines: PropTypes.array,
  format: PropTypes.func.isRequired,
  scatteredLabels: PropTypes.bool,
};
