import EventEmitter from 'events';
import { debounce } from 'lodash';

export const emitter = new EventEmitter();
emitter.setMaxListeners(10000);

export const emitterUpdate = new EventEmitter();
emitterUpdate.setMaxListeners(10000);

export const listenOnPlaceholdersChange = cb => {
  emitter.on('changePlaceholders', cb);
  return () => {
    emitter.off('changePlaceholders', cb);
  };
};

export const listenOnPlaceholdersUpdate = cb => {
  emitterUpdate.on('updatePlaceholders', cb);
  return () => {
    emitterUpdate.off('updatePlaceholders', cb);
  };
};

export const pushNewPlaceholder = newPlaceholder => emitter.emit('changePlaceholders', newPlaceholder);

window.pushNewPlaceholder = debounce(pushNewPlaceholder, 10);

export const pushPlaceholderUpdate = () => {
  emitterUpdate.emit('updatePlaceholders', 'Updated');
};

window.pushPlaceholderUpdate = debounce(pushPlaceholderUpdate, 100);
