/* eslint-disable */
import React, { PureComponent, memo } from 'react';
import PropTypes from 'prop-types';
import { groupBy, camelCase } from 'lodash';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Label,
  ZAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import uuid from 'uuid';
import { Button, Row } from '../index';

import colorPalete from './colorPalete';
import { formatNumber } from '../../i18n';

const parseCriteria = criteria => {
  if (/^\["/.test(criteria)) {
    return JSON.parse(criteria);
  }
  return [criteria];
};

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div
        style={{
          margin: '0px',
          padding: '10px',
          backgroundColor: 'rgb(255, 255, 255)',
          border: '1px solid rgb(204, 204, 204)',
          whiteSpace: 'nowrap',
        }}
      >
        {payload.map(dim => (
          <div key={dim.name}>
            {dim.name}: <b>{formatNumber(dim.value, { precision: 2 })}</b>
          </div>
        ))}

        <div>
          {parseCriteria(payload[0].payload.criteria).map(c => (
            <React.Fragment key={c}>
              {c}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
};

const Chart = memo(
  ({
    addSelectedFilter,
    handleMouseEnter,
    handleMouseLeave,
    setChartRef,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseOut,
    onMouseLeave,
    selectedDimensions,
    opacity,
    data,
  }) => {
    const groupedData = selectedDimensions[2]
      ? groupBy(data, d => d[camelCase(selectedDimensions[2])])
      : { 'Keywords group by position': data };

    return (
      <ResponsiveContainer>
        <ScatterChart
          ref={setChartRef}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseOut={onMouseOut}
          onMouseLeave={onMouseLeave}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            type="number"
            key="dimensionX"
            dataKey="dimensionX"
            name={selectedDimensions[0]}
            label={{ value: selectedDimensions[0], position: 'bottom' }}
            unit=""
          />
          <YAxis type="number" key="dimensionY" dataKey="dimensionY" width={70} name={selectedDimensions[1]} unit="">
            <Label value={selectedDimensions[1]} position="insideLeft" angle={-90} style={{ textAnchor: 'middle' }} />
          </YAxis>
          <ZAxis type="number" key="count" dataKey="count" range={[64, 1000]} name="Count" label="Count" unit="" />
          <Tooltip cursor={{ strokeDasharray: '6 6' }} content={<CustomTooltip />} />
          {Object.keys(groupedData).map((group, index) => (
            <Scatter
              key={group}
              name={group}
              data={groupedData[group]}
              onClick={({ dimensionXMin, dimensionXMax, dimensionYMin, dimensionYMax }) =>
                addSelectedFilter({
                  id: uuid(),
                  dimensionX: selectedDimensions[0],
                  minX: dimensionXMin,
                  maxX: dimensionXMax,
                  dimensionY: selectedDimensions[1],
                  minY: dimensionYMin,
                  maxY: dimensionYMax,
                })
              }
              fill={colorPalete[(index % colorPalete.length) + 1]}
              opacity={!opacity || group === opacity ? 1 : 0.2}
              shape={group === opacity ? 'star' : 'point'}
            />
          ))}
          <Legend
            verticalAlign="bottom"
            align="right"
            height={32}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
        </ScatterChart>
      </ResponsiveContainer>
    );
  }
);
Chart.propTypes = {
  addSelectedFilter: PropTypes.function,
  handleMouseEnter: PropTypes.function,
  handleMouseLeave: PropTypes.function,
  setChartRef: PropTypes.function,
  onMouseDown: PropTypes.function,
  onMouseMove: PropTypes.function,
  onMouseUp: PropTypes.function,
  onMouseOut: PropTypes.function,
  onMouseLeave: PropTypes.function,
  selectedDimensions: PropTypes.array,
  opacity: PropTypes.number,
  data: PropTypes.object,
};

export default class ChartWrapper extends PureComponent {
  state = {
    pressed: false,
    start: null,
    end: null,
    opacity: null,
  };

  setChartRef = el => {
    if (el) {
      this.chart = el;
    }
  };

  handleMouseEnter = ({ value }) => this.setState({ opacity: value });
  handleMouseLeave = () => this.setState({ opacity: null });

  onMouseDown = e =>
    e &&
    this.setState({
      start: {
        dimensionX: this.props.selectedDimensions[0],
        dimensionY: this.props.selectedDimensions[1],
        x: e.xValue,
        y: e.yValue,
      },
      end: null,
      pressed: true,
    });

  onMouseMove = e => {
    e &&
      this.state.pressed &&
      this.setState({
        end: {
          dimensionX: this.props.selectedDimensions[0],
          dimensionY: this.props.selectedDimensions[1],
          x: e.xValue,
          y: e.yValue,
        },
      });
  };
  onMouseUp = e =>
    e
      ? this.setState({
          end: {
            dimensionX: this.props.selectedDimensions[0],
            dimensionY: this.props.selectedDimensions[1],
            x: e.xValue,
            y: e.yValue,
          },
          pressed: false,
        })
      : this.setState({ pressed: false });

  onMouseLeave = e => {
    this.state.pressed && this.setState({ pressed: false });
  };

  scaleX = chartX =>
    chartX !== null && typeof chartX !== 'undefined' ? this.chart.state.xAxisMap[0].scale(chartX) : null;
  scaleY = chartY =>
    chartY !== null && typeof chartY !== 'undefined' ? this.chart.state.yAxisMap[0].scale(chartY) : null;

  calculateDimension = () => {
    const { start, end } = this.state;

    if (start && end) {
      return {
        minX: Math.min(start.x, end.x),
        maxX: Math.max(start.x, end.x),
        minY: Math.min(start.y, end.y),
        maxY: Math.max(start.y, end.y),
      };
    }

    return {};
  };

  calculateSelectedRectangle = () => {
    if (this.state.start && this.state.end) {
      const { minX, maxX, minY, maxY } = this.calculateDimension();
      const startX = this.scaleX(minX);
      const startY = this.scaleY(minY);
      const endX = this.scaleX(maxX);
      const endY = this.scaleY(maxY);
      const height = Math.abs(endY - startY);

      return {
        style: {
          position: 'absolute',
          left: `${startX}px`,
          top: `${startY - height}px`,
          width: `${Math.abs(endX - startX)}px`,
          height: `${height}px`,
          backgroundColor: this.state.pressed ? 'rgba(255, 0,0, 0.05)' : 'rgba(0,0,255, 0.05)',
          pointerEvents: 'none',
        },
      };
    }

    return {};
  };

  handleSelection = handler => () => {
    const {
      end: { dimensionX, dimensionY },
    } = this.state;
    const { minX, maxX, minY, maxY } = this.calculateDimension();
    const { data } = this.props;

    const selectedData = data.filter(
      d => d.dimensionX >= minX && d.dimensionX <= maxX && d.dimensionY >= minY && d.dimensionY <= maxY
    );

    handler({
      id: uuid(),
      dimensionX,
      minX: Math.min(minX, ...selectedData.map(d => d.dimensionXMin)),
      maxX: Math.max(maxX, ...selectedData.map(d => d.dimensionXMax)),
      dimensionY,
      minY: Math.min(minY, ...selectedData.map(d => d.dimensionYMin)),
      maxY: Math.max(maxY, ...selectedData.map(d => d.dimensionYMax)),
    });

    this.setState({
      start: null,
      end: null,
      pressed: false,
    });
  };

  render() {
    const { data, selectedDimensions, addSelectedFilter } = this.props;
    const { opacity, pressed } = this.state;
    const selectionRectangle = this.calculateSelectedRectangle();

    return (
      <div>
        <div style={{ width: '100%', height: '50vh', userSelect: 'none', position: 'relative' }}>
          <Chart
            addSelectedFilter={addSelectedFilter}
            handleMouseEnter={this.handleMouseEnter}
            handleMouseLeave={this.handleMouseLeave}
            setChartRef={this.setChartRef}
            onMouseDown={this.onMouseDown}
            onMouseMove={this.onMouseMove}
            onMouseUp={this.onMouseUp}
            onMouseOut={this.onMouseOut}
            onMouseLeave={this.onMouseLeave}
            selectedDimensions={selectedDimensions}
            opacity={opacity}
            data={data}
          />
          {selectionRectangle && <div {...selectionRectangle} />}
        </div>
        {selectionRectangle && !pressed && (
          <Row className="mb-16">
            <Button secondary onClick={this.handleSelection(addSelectedFilter)}>
              Filter selected and zoom in
            </Button>

            <Button secondary onClick={this.handleSelection(this.props.addZoomFilter)}>
              Show summary for selected
            </Button>
          </Row>
        )}
      </div>
    );
  }
}
ChartWrapper.propTypes = {
  data: PropTypes.array,
  addZoomFilter: PropTypes.function,
  addSelectedFilter: PropTypes.function,
  selectedDimensions: PropTypes.array,
};
