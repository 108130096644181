/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Tooltip } from '../index';
import { formatNumber } from '../../i18n';

const DimensionFilter = ({ dimensionX, minX, maxX, dimensionY, minY, maxY, removeFilter, useDimensions }) => (
  <Row>
    <Col grow>
      <span>
        <b>{dimensionX}</b> <br /> {formatNumber(minX, { precision: 2 })} - {formatNumber(maxX, { precision: 2 })}{' '}
      </span>
    </Col>
    <Col grow>
      <span>
        <b>{dimensionY}</b> <br /> {formatNumber(minY, { precision: 2 })} - {formatNumber(maxY, { precision: 2 })}{' '}
      </span>
    </Col>
    <Col shrink>
      <Button secondary onlyIcon icon="trash" onClick={removeFilter} />
    </Col>
    <Col shrink>
      <Tooltip text="Use these dimensions">
        <Button secondary onlyIcon icon="eye" onClick={useDimensions} />
      </Tooltip>
    </Col>
  </Row>
);

export default DimensionFilter;
