/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import smallArrow from '../scss_stylesheets/images/small_arrow.png';
import bigArrow from '../scss_stylesheets/images/big_arrow.png';
import { Button, Row, Col } from '../components';

const filterPossibleFieldsByLevels = (possibleFields, selectedValues) =>
  possibleFields.filter(f => {
    if (f === 'product_type_l1' || f.indexOf('product_type_l') === -1) {
      return true;
    }

    const previousSelectedProductType = `product_type_l${parseInt(f.replace('product_type_l', ''), 10) - 1}`;
    return selectedValues.indexOf(previousSelectedProductType) !== -1;
  });

const HierarchyLevel = ({
  disabled,
  depth,
  selected,
  possibleFields,
  changeField,
  removeLevel,
  increaseLevel,
  selectedValues,
  i18n,
}) => {
  const addMoreAllowed = !(possibleFields.length === selectedValues.length || selectedValues.length === 5);
  return (
    <Row bottom className="mb-16">
      {selected === 'offer_id' ? (
        <Col noPadding style={{ marginLeft: `${depth * 42}px`, position: 'relative' }} width="300px">
          {addMoreAllowed && (
            <React.Fragment>
              <img src={smallArrow} className="pos-absolute input-hierarchyArrow" width="29px" height="37px" alt="" />
              <Row noPadding className="ml-16 mb-24">
                <Button
                  icon="plus"
                  secondary
                  onlyIcon
                  data-test-id="add-level-button"
                  onClick={increaseLevel}
                  disabled={disabled}
                />
              </Row>
            </React.Fragment>
          )}
          <Row className="mt-4 pos-relative" style={{ marginLeft: addMoreAllowed ? '60px' : '20px' }}>
            <img
              src={bigArrow}
              className="pos-absolute input-hierarchyArrow input-hierarchyArrow--product"
              width="25px"
              height="56px"
              alt=""
            />
            <Col className="mt-4">
              <label> {i18n.product} </label>
              <div className="Input">
                <input type="text" disabled value={selected} />
              </div>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col style={{ marginTop: '6px', marginLeft: `${depth * 42}px`, position: 'relative' }} width="300px">
          <img src={bigArrow} className="pos-absolute input-hierarchyArrow" width="24px" height="54px" alt="" />
          <label className="ml-8">
            {' '}
            {i18n.product_group_level} {depth - 1}{' '}
          </label>
          <div className="Dropdown ml-8">
            <div className="Dropdown-wrapper">
              <select disabled={disabled} onChange={changeField} id={selected}>
                {possibleFields
                  .filter(pf => selectedValues.indexOf(pf) === -1 || pf === selected)
                  .map(possibleField => (
                    <option value={possibleField} key={possibleField} selected={possibleField === selected}>
                      {possibleField}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        </Col>
      )}
      <Col width="10%" className="pl-0">
        {!disabled && selected !== 'offer_id' && (
          <div title="" className="Link mb-8">
            <span className="fc-trash" data-test-id="remove-level-button" onClick={removeLevel} />
          </div>
        )}
      </Col>
    </Row>
  );
};

HierarchyLevel.propTypes = {
  depth: PropTypes.number.isRequired,
  selected: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
  changeField: PropTypes.func.isRequired,
  increaseLevel: PropTypes.func.isRequired,
  removeLevel: PropTypes.func.isRequired,
  possibleFields: PropTypes.array.isRequired,
  selectedValues: PropTypes.array.isRequired,
  i18n: PropTypes.object,
};

class Hierarchy extends React.Component {
  static propTypes = {
    possibleFields: PropTypes.array.isRequired,
    disabled: PropTypes.bool.isRequired,
    selectedValues: PropTypes.array.isRequired,
    valueField: PropTypes.any.isRequired,
    i18n: PropTypes.object,
  };

  state = {
    levels: [].concat(this.props.selectedValues),
  };

  updateInputWithNewLevels = newLevels => {
    this.props.valueField.value = newLevels.join(' ');
    this.props.valueField.dispatchEvent(new window.Event('change', { bubbles: true }));
  };

  increaseLevel = () => {
    this.setState(s => {
      const newLevel = this.props.possibleFields.filter(pf => s.levels.indexOf(pf) === -1)[0];
      const newLevels = [].concat(s.levels);
      const lastLevel = newLevels.pop();
      const newLevelsInserted = newLevels.concat([newLevel, lastLevel]);
      this.updateInputWithNewLevels(newLevelsInserted);
      return { levels: newLevelsInserted };
    });
  };

  changeField = depth => ({ target: { value } }) => {
    this.setState(s => {
      const newLevels = [].concat(s.levels);
      newLevels[depth] = value;
      this.updateInputWithNewLevels(newLevels);
      return {
        levels: newLevels,
      };
    });
  };

  removeLevel = depth => () => {
    this.setState(s => {
      const newLevels = [].concat(s.levels);
      newLevels.splice(depth, 1);

      this.updateInputWithNewLevels(newLevels);
      return {
        levels: newLevels,
      };
    });
  };

  render() {
    const { possibleFields, disabled } = this.props;
    const { levels } = this.state;

    return (
      <div className="mt-8">
        {levels.map((level, index) => (
          <HierarchyLevel
            disabled={disabled}
            key={`depth-${level}`}
            depth={index + 2}
            selected={level}
            increaseLevel={this.increaseLevel}
            possibleFields={filterPossibleFieldsByLevels(possibleFields.sort(), levels)}
            removeLevel={this.removeLevel(index)}
            changeField={this.changeField(index)}
            selectedValues={levels}
            i18n={this.props.i18n}
          />
        ))}
      </div>
    );
  }
}

export default Hierarchy;
