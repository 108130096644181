/* eslint-disable no-trailing-spaces */
import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import ErrorBox from '../components/ErrorBox';
import Searchbox from '../organizations_dropdown/Searchbox';
import InfoBox from '../components/InfoBox';
import { t } from '../i18n';
import Switch from '../components/form/Switch';
import Link from '../components/Link';
import Icon from '../components/Icon';
import { sortDataSource, mapDataSourcesCorrectImporter } from './utils';
import { Col, CreateElementButton, Loader, Row } from '../components';
import CampaignDataSourceTile from './CampaignDataSourceTile';

export const CAMPAIGNS_WITH_RECOMMENDATIONS = [
  'FBRetargetingCampaign',
  'PerformanceMaxCampaign',
  'PLACampaign',
  'FBAdvantagePlusCampaign',
];

const QUERY_DATA_SOURCES = gql`
  query DataSources($organizationId: BigInt!, $fulltext: String, $active: String, $campaignType: String!) {
    collection(
      identifier: "dataSources"
      order: "name"
      orderDirection: "asc"
      page: 0
      limit: 1000
      organizationId: $organizationId
    ) {
      dataSources(fulltext: $fulltext, active: $active) {
        icon
        id
        name
        status
        dataSourceState
        importer
        recommendedForCampaign(campaignType: $campaignType)
        supportedForCampaign(campaignType: $campaignType)
      }
    }
  }
`;

const CampaignDataSourceForm = ({ organizationId, campaignType, name, value, createBtnProps }) => {
  const [search, setSearch] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const [selected, setSelected] = useState(value || '');
  const [showMoreBtn, setShowMoreBtn] = useState(false);
  const { loading, error, data } = useQuery(QUERY_DATA_SOURCES, {
    variables: {
      organizationId,
      fulltext: search,
      campaignType,
      active: activeOnly ? 'only_active' : '',
    },
  });

  const showRecommendedAndOthers = CAMPAIGNS_WITH_RECOMMENDATIONS.includes(campaignType);
  const dataSources = data?.collection?.dataSources ? mapDataSourcesCorrectImporter(data?.collection?.dataSources) : [];
  const supportedDataSources = dataSources.filter(item => item?.supportedForCampaign);

  const recommendedDataSources = showRecommendedAndOthers
    ? sortDataSource(supportedDataSources.filter(item => item?.recommendedForCampaign))
    : sortDataSource(supportedDataSources);
  const otherDataSources = showRecommendedAndOthers
    ? sortDataSource(supportedDataSources.filter(item => !item?.recommendedForCampaign))
    : [];

  useEffect(() => {
    const submitBtn = document.querySelector('[data-test-id="continue-with-data-source"]');
    if (!loading && !error) {
      if (selected === '') {
        submitBtn.classList.add('disabled');
      } else {
        submitBtn.classList.remove('disabled');
      }
    }

    if (selected === '') {
      setSelected(recommendedDataSources?.[0]?.id || '');
    }
  }, [recommendedDataSources, otherDataSources, selected]);

  const noResults = supportedDataSources.length === 0 && (search !== '' || activeOnly);

  return (
    <>
      <Row className="mb-16" padding="Xl">
        <Col shrink>
          <CreateElementButton {...createBtnProps} />
        </Col>
        <Col width="186px">
          <Searchbox
            searchValue={search}
            handleSearch={({ target }) => {
              setSearch(target.value);
              setSelected('');
            }}
            debounce={400}
            searchBoxText="Search"
          />
        </Col>
        <Col shrink className="justify-content-center">
          <Switch
            label={t('active_only', { scope: 'react.create_element_modal' })}
            onChange={e => {
              setActiveOnly(e.target.value);
              setSelected('');
            }}
            value={activeOnly}
            doNotUseInternalState
            name="data_source_active_only"
          />
        </Col>
      </Row>
      {error && <ErrorBox>{error.message}</ErrorBox>}
      {loading && <Loader size="small" />}
      {noResults && (
        <InfoBox type="info" withIcon>
          {activeOnly
            ? t('react.filters_and_conditions.no_active_data_source', {
                default: 'You don’t have any active data source',
              })
            : t('react.filters_and_conditions.too_much_filtering', {
                default: 'None of the items match your search query. Try to modify what you’re looking for.',
              })}
        </InfoBox>
      )}
      {!loading && !error && !activeOnly && search === '' && recommendedDataSources?.length === 0 && (
        <InfoBox type="info" withIcon isComplex>
          <span className="Text--bold">
            {t('react.filters_and_conditions.no_suitable_ds_found', {
              default: 'No Suitable Data Source Found',
            })}
          </span>
          <span>
            {t('react.filters_and_conditions.no_suitable_ds_found_description', {
              default:
                'It looks like you don\'t have an appropriate data source. To proceed, you can either create a new data source tailored to your needs or select from existing sources under "Show more" that might not be optimal for your campaign. Creating a new data source will ensure you have the best fit for your campaign goals.',
            })}
          </span>
        </InfoBox>
      )}
      {!loading && !error && (
        <div className="RadioTiles RadioTiles--vertical">
          <div className="RadioTiles-wrapper">
            {recommendedDataSources?.map(item => (
              <CampaignDataSourceTile
                key={item?.id}
                item={item}
                name={name}
                selected={selected}
                setSelected={setSelected}
                campaignType={campaignType}
              />
            ))}
            {showRecommendedAndOthers && otherDataSources?.length > 0 && (
              <>
                {showMoreBtn && (
                  <>
                    <div className="delimiter mb-16 mt-8" />
                    {otherDataSources?.map(item => (
                      <CampaignDataSourceTile
                        key={item?.id}
                        item={item}
                        name={name}
                        selected={selected}
                        setSelected={setSelected}
                        campaignType={campaignType}
                      />
                    ))}
                  </>
                )}
                <div className="mv-16">
                  <Link className="mt-4 mb-16" onClick={() => setShowMoreBtn(!showMoreBtn)}>
                    <span className="d-flex" data-test-id="show-more-ds-btn">
                      {showMoreBtn
                        ? t('react.data_sources.show_less', {
                            default: 'Show less',
                          })
                        : t('react.data_sources.show_more', {
                            default: 'Show more',
                          })}
                      <Icon
                        kind={showMoreBtn ? 'chevron-up' : 'chevron-down'}
                        size="13px"
                        inheritColor
                        className="ml-4"
                      />
                    </span>
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

CampaignDataSourceForm.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignType: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  createBtnProps: PropTypes.object,
};

export default CampaignDataSourceForm;
