import React from 'react';
import Vector from './Vector';
import { loadWebFontIfNeeded } from '../panels/TextPanel';
import { t } from '../../../../i18n';

const alignToTransform = {
  start: 'top',
  center: 'center',
  end: 'bottom',
};

const lineHeight = 1.2;

export default class Text extends Vector {
  static getDescription = objectAttributes => objectAttributes.text;
  static meta = {
    icon: 'type',
    useIconInLayers: true,
    initial: {
      // Box
      automaticFontSize: true,

      width: 200,
      height: 20 * 1.2 + 10,
      blendMode: '',
      rotate: 0,
      borderColor: '',
      borderWidth: '',
      borderStyle: '',
      borderRadius: 5,
      backgroundColor: '',

      // Text
      text: t('react.image_generator.placeholder.text'),
      color: '#000000',
      textAlign: 'left',
      textVerticalAlign: 'start',
      fontWeight: 'normal',
      fontStyle: 'normal',
      textDecorationLine: 'none',
      textDecorationColor: '#000000',
      textDecorationThickness: 2,
      textDecorationLineWidth: '',
      textDecorationStyle: 'solid',
      fontSize: 20,
      padding: 0,
      fontFamily: 'Open Sans',

      fontFamilyHover: null,
      'data-layer-name': 'Text',
    },
  };

  getForeignObjectStyle() {
    return super.getStyle();
  }

  getStyle() {
    const { object } = this.props;
    let decorationLineMultiplier = 2;

    if (object.textDecorationStyle === 'double') {
      decorationLineMultiplier = 4;
    } else if (object.textDecorationStyle === 'wavy') {
      decorationLineMultiplier = 4.6;
    }

    const decorationLineThickness =
      object.textDecorationLine?.includes('underline') || object.textDecoration?.includes('underline')
        ? object.textDecorationThickness || 2
        : 0;

    let maxLines = Math.floor(
      (object.height - object.padding * 2) /
        ((object.fontSize + decorationLineThickness * decorationLineMultiplier) * lineHeight)
    );
    if (maxLines === 0) maxLines = 1;

    return {
      textAlign: object.textAlign,
      textVerticalAlign: object.textVerticalAlign,
      fontWeight: object.fontWeight,
      width: object.width,
      height:
        maxLines * (object.fontSize + decorationLineThickness * decorationLineMultiplier) * lineHeight +
        object.padding * 2 +
        4,
      fontStyle: object.fontStyle,
      fontSize: `${object.fontSize}px`,
      padding: `${object.padding}px`,
      lineHeight: `${lineHeight * (object.fontSize + decorationLineThickness * decorationLineMultiplier)}px`,
      color: object.fill,
      borderColor: object.borderColor,
      borderWidth: `${object.borderWidth}px`,
      borderStyle: object.borderStyle,
      borderRadius: `${object.borderRadius}px`,
      backgroundColor: object.backgroundColor,
      textDecorationLine: object.textDecorationLine,
      textDecorationColor: object.textDecorationColor,
      textDecorationThickness: object.textDecorationThickness,
      textDecorationStyle: object.textDecorationStyle,
      mixBlendMode: object.blendMode,
      WebkitUserSelect: 'none',
      overflow: 'hidden',
      WebkitLineClamp: maxLines,
      display: 'flex',
      fontFamilyHover: object.fontFamilyHover,
    };
  }

  getInerStyle() {
    const { object } = this.props;
    let decorationLineMultiplier = 2;

    if (object.textDecorationStyle === 'double') {
      decorationLineMultiplier = 4;
    } else if (object.textDecorationStyle === 'wavy') {
      decorationLineMultiplier = 4.6;
    }

    const decorationLineThickness =
      object.textDecorationLine?.includes('underline') || object.textDecoration?.includes('underline')
        ? object.textDecorationThickness || 2
        : 0;

    let maxLines = Math.floor(
      (object.height - object.padding * 2) /
        ((object.fontSize + decorationLineThickness * decorationLineMultiplier) * lineHeight)
    );
    if (maxLines === 0) maxLines = 1;

    const automaticFontSize = object.automaticFontSize;

    return {
      textAlign: object.textAlign,
      alignItems: object.textVerticalAlign,
      width: object.width - object.padding * 2,
      height: maxLines * (object.fontSize + decorationLineThickness * decorationLineMultiplier) * lineHeight + 4,
      lineHeight: `${lineHeight * (object.fontSize + decorationLineThickness * decorationLineMultiplier)}px`,
      paddingTop: '2px',
      paddingBottom: '2px',
      overflow: automaticFontSize ? 'unset' : 'hidden',
      WebkitLineClamp: automaticFontSize ? null : maxLines,
      display: 'flex',
      transformOrigin: automaticFontSize ? `left ${alignToTransform[object.textVerticalAlign]}` : 'unset',
      transform: !automaticFontSize && 'scale(1.0)',
    };
  }

  render() {
    const { object } = this.props;

    loadWebFontIfNeeded(object.fontFamily, null, true);
    if (object?.fontFamilyHover) loadWebFontIfNeeded(object.fontFamilyHover, null, true);

    const style = this.getStyle();

    const decorationWidthLevel = object.textDecorationLineWidth;
    const automaticFontSize = object.automaticFontSize;
    const automatiFontSizeSpanStyle = {
      transform: 'unset',
      whiteSpace: 'nowrap',
      flexBasis: '100%',
    };

    const shotrtenTextSpanStyle = {
      transform: 'scale(1.0)',
      flexBasis: '100%',
      WebkitBoxOrient: 'vertical',
      display: '-webkit-box',
      maxHeight: '100%',
      WebkitLineClamp: 'inherit',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    };

    return (
      <foreignObject
        {...this.getObjectAttributes()}
        height={object.height + object.padding * 2}
        width={object.width + object.padding * 2}
        fontFamily={object?.fontFamilyHover || object.fontFamily}
        style={this.getForeignObjectStyle()}
        fontFamilyUrl=""
      >
        <div style={style}>
          <div style={this.getInerStyle()}>
            <span
              style={automaticFontSize ? automatiFontSizeSpanStyle : shotrtenTextSpanStyle}
              className={automaticFontSize ? 'js-image-gen-fit-text' : 'js-image-gen-shorten-text'}
            >
              {`${'\u00A0'.repeat(decorationWidthLevel)}${object.text}${'\u00A0'.repeat(decorationWidthLevel)}`}
            </span>
          </div>
        </div>
      </foreignObject>
    );
  }
}
