import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { PieChart, Pie, Cell } from 'recharts';
import Tooltip from '../Tooltip';
import { Row, Col, Loader, Text, Badge } from '../index';
import { t, formatPercentage, formatInteger } from '../../i18n';
import cssVariables from '../../scss_stylesheets/core/variables.json';

const COLORS = {
  used_products: cssVariables.statusWarningDefault,
  unused_products: cssVariables.interfaceBackgroundMuted,
  used_adSets: cssVariables.statusPositiveDefault,
  unused_adSets: cssVariables.interfaceBackgroundMuted,
};

const REMAINING_COLORS = {
  used_products: cssVariables.statusNeutralDefault,
  unused_products: cssVariables.interfaceBackgroundMuted,
  used_adSets: cssVariables.statusNeutralBold,
  unused_adSets: cssVariables.interfaceBackgroundMuted,
};

const GREEN_COLORS = {
  used_products: cssVariables.statusPositiveDefault,
  unused_products: cssVariables.interfaceBackgroundMuted,
  used_adSets: cssVariables.statusPositiveDefault,
  unused_adSets: cssVariables.interfaceBackgroundMuted,
};

const INVERTED_COLORS = {
  used_products: cssVariables.statusPositiveDefault,
  unused_products: cssVariables.interfaceBackgroundMuted,
  used_adSets: cssVariables.statusWarningDefault,
  unused_adSets: cssVariables.interfaceBackgroundMuted,
};

const COLOR_KIND = {
  color: COLORS,
  remaining: REMAINING_COLORS,
  green: GREEN_COLORS,
  inverted: INVERTED_COLORS,
};

const getData = (name, value, total) => {
  if (total === 0) {
    return [{ name: `unused_${name}`, value: 0 }];
  }

  if (value <= 0) {
    return [{ name: `unused_${name}`, value: total }];
  }

  if (value > total) {
    return [{ name: `used_${name}`, value: total }];
  }
  return [
    { name: `used_${name}`, value },
    { name: `unused_${name}`, value: total - value },
  ];
};

const GRAPH_SIZES = {
  xl: {
    size: 40,
    lineWidth: 6,
    innerSize: 24,
    innerLineWidth: 4,
  },
  lg: {
    size: 32,
    lineWidth: 5,
    innerSize: 18,
    innerLineWidth: 3,
  },
  md: {
    size: 24,
    lineWidth: 3.5,
    innerSize: 14,
    innerLineWidth: 2,
  },
  sm: {
    size: 16,
    lineWidth: 2.5,
    innerSize: 9,
    innerLineWidth: 1.5,
  },
};

export const UsedByGraphWithLegend = memo(
  ({ products, productsTotal, adSets, adSetsTotal, kind = 'color', size = 'xl', doNotShowPercent, padding = 'xl' }) => (
    <Row padding={padding} center>
      <Col shrink>
        <UsedByGraph {...{ products, productsTotal, adSets, adSetsTotal, size }} />
      </Col>
      <Col padding="s">
        <Row baseline>
          <Col shrink>
            <Badge style={{ backgroundColor: COLOR_KIND[kind].used_adSets }} />
          </Col>
          {adSetsTotal >= 0 && (
            <Col>
              <Text nowrap data-test-id="covered-ad_sets">
                {t('react.charts.covered_ad_sets', {
                  adSets: formatInteger(adSets),
                  percentage: doNotShowPercent
                    ? t('data_source_is_still_loading')
                    : formatPercentage(adSetsTotal > 0 ? (adSets / adSetsTotal) * 100 : 0),
                })}
              </Text>
            </Col>
          )}
        </Row>
        <Row baseline>
          <Col shrink>
            <Badge style={{ backgroundColor: COLOR_KIND[kind].used_products }} />
          </Col>
          {productsTotal >= 0 && (
            <Col grow>
              <Text nowrap data-test-id="covered-products">
                {t('react.charts.covered_products', {
                  products: formatInteger(products),
                  percentage: doNotShowPercent
                    ? t('data_source_is_still_loading')
                    : formatPercentage(productsTotal > 0 ? (products / productsTotal) * 100 : 0),
                })}
              </Text>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
);

const getTranslationText = (adSetsTotal, productsTotal) => {
  if (adSetsTotal >= 0 && productsTotal >= 0) {
    return 'react.charts.user_by_graph_tooltip_html';
  }

  if (adSetsTotal >= 0) {
    return 'react.charts.user_by_graph_tooltip_only_ad_sets_html';
  }
  return 'react.charts.user_by_graph_tooltip_only_products_html';
};

const UsedByGraph = memo(
  ({
    disableTooltip,
    products,
    productsTotal,
    adSets,
    loading,
    adSetsTotal,
    kind = 'color',
    size = 'xl',
    doNotShowPercent,
  }) => {
    const productsData = getData('products', products, productsTotal);
    const adSetsData = getData('adSets', adSets, adSetsTotal);
    const graphParam = GRAPH_SIZES[size];

    const tooltipText = disableTooltip
      ? null
      : t(getTranslationText(adSetsTotal, productsTotal), {
          default:
            '<span class="text-nowrap">Ad Sets <b>%{adSets}</b> from %{adSetsTotal} (%{adSetsPercent})<br /> Products <b>%{products}</b> from %{productsTotal} (%{productsPercent})</span>',
          adSets: formatInteger(adSets),
          adSetsTotal: doNotShowPercent ? '???' : formatInteger(adSetsTotal),
          adSetsPercent: doNotShowPercent
            ? t('data_source_is_still_loading')
            : formatPercentage(adSetsTotal > 0 ? (adSets / adSetsTotal) * 100 : 0),
          products: formatInteger(products),
          productsTotal: doNotShowPercent ? '???' : formatInteger(productsTotal),
          productsPercent: doNotShowPercent
            ? t('data_source_is_still_loading')
            : formatPercentage(productsTotal > 0 ? (products / productsTotal) * 100 : 0),
        });
    return (
      <Tooltip
        text={tooltipText}
        data-test-id="graph-tooltip"
        data-ad-sets={adSets}
        data-ad-sets-total={adSetsTotal}
        data-products={products}
        data-products-total={productsTotal}
      >
        {loading ? (
          <Loader size="small" className="pl-4" />
        ) : (
          <PieChart width={graphParam.size} height={graphParam.size}>
            {productsTotal >= 0 && (
              <Pie
                isAnimationActive={false}
                data={productsData}
                startAngle={90}
                endAngle={-270}
                dataKey="value"
                paddingAngle={0}
                innerRadius={graphParam.innerSize / 2 - graphParam.innerLineWidth}
                outerRadius={graphParam.innerSize / 2}
                stroke="none"
              >
                {productsData.map(entry => (
                  <Cell key={`cell-${entry.name}`} fill={COLOR_KIND[kind][entry.name]} />
                ))}
              </Pie>
            )}
            {adSetsTotal >= 0 && (
              <Pie
                isAnimationActive={false}
                data={adSetsData}
                startAngle={90}
                endAngle={-270}
                dataKey="value"
                paddingAngle={0}
                innerRadius={graphParam.size / 2 - graphParam.lineWidth}
                outerRadius={graphParam.size / 2}
                stroke="none"
              >
                {adSetsData.map(entry => (
                  <Cell key={`cell-${entry.name}`} fill={COLOR_KIND[kind][entry.name]} />
                ))}
              </Pie>
            )}
          </PieChart>
        )}
      </Tooltip>
    );
  }
);

UsedByGraph.propTypes = {
  disableTooltip: PropTypes.bool,
  doNotShowPercent: PropTypes.bool,
  adSets: PropTypes.number,
  adSetsTotal: PropTypes.number,
  loading: PropTypes.bool,
  products: PropTypes.number,
  productsTotal: PropTypes.number,
  size: PropTypes.oneOf(Object.keys(GRAPH_SIZES)),
  kind: PropTypes.oneOf(Object.keys(COLOR_KIND)),
};

UsedByGraphWithLegend.propTypes = UsedByGraph.propTypes;

export default UsedByGraph;
