import { hot } from 'react-hot-loader/root';
import { PropTypes } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import React, { useState, createContext } from 'react';
import {
  SubscriptionTile,
  SubscriptionTable,
  putPeriodToUrl,
  parseUrl,
  PeriodOverview,
  ConsolidatedContainer,
  StickyPeriodTile,
} from './index';
import { ErrorBox, Text } from '../index';
import LoadingTable, { GrayBox } from '../table/LoadingTable';
import {
  tableColumnsByPeriodType,
  synchronizationTableColumns,
  synchronizationTableWithSupportColumns,
} from './constants';
import { t, formatDate } from '../../i18n';
import PricingModal from './PricingModal';

const QUERY_SUBSCRIPTION = gql`
  query SubscriptionTable($organizationId: BigInt!, $currentPeriodId: BigInt!, $pastPeriodId: BigInt) {
    organization(id: $organizationId) {
      id
      name
      subscriptionFeatureTable(id: $currentPeriodId)

      currentPeriod: subscriptionPeriod(id: $currentPeriodId) {
        price
        syncFrequency
        beginsOn
        endsOn
        support
      }

      pastPeriod: subscriptionPeriod(id: $pastPeriodId) {
        price
        syncFrequency
        support
      }
    }
  }
`;

export const SubscriptionContext = createContext({ period: {} });
SubscriptionContext.displayName = 'SubscriptionContext';

const pastPeriodId = (selectedPeriod, periods) => {
  if (selectedPeriod) {
    const periodIndex = periods.map(({ value }) => value.toString()).indexOf(selectedPeriod.value.toString());
    return (periods[periodIndex + 1] || {}).value;
  }
  return undefined;
};

const DataFormatter = ({ beginsOn, endsOn }) => {
  const begins = formatDate(beginsOn, 'long');
  const ends = formatDate(endsOn, 'long');
  return (
    <Text tag="p" color="softGray" size="sm" semibold>
      {begins} - {ends}
    </Text>
  );
};

const getDefaultPeriod = (periods = []) => {
  const url = parseUrl(window.location.search);

  return (
    (url?.period && periods.find(p => p.value.toString() === url?.period)?.value.toString()) ||
    periods.find(p => p.state === 'current' || p.state === 'current_past')?.value.toString() ||
    periods.find(p => p.state === 'future')?.value.toString()
  );
};

const LoadingSkeleton = ({ consolidatedChilds }) => (
  <React.Fragment>
    <SubscriptionTile
      subheading={<GrayBox long withoutMargin />}
      mainHeading={consolidatedChilds ? t('feature_period.usage_in_ppcbee') : t('feature_period.usage')}
    >
      <PeriodOverview loading period="current" />
      <div className="mt-16">
        <LoadingTable cols={3} rows={7} />
      </div>

      <div className="mt-16">
        <LoadingTable cols={3} rows={1} />
      </div>
    </SubscriptionTile>

    {consolidatedChilds && (
      <SubscriptionTile
        mainHeading={t('feature_period.usage_of_suborganizations')}
        subheading={<GrayBox long withoutMargin />}
        className="mt-16"
      >
        <div className="mt-16">
          <LoadingTable cols={2} rows={3} />{' '}
        </div>
      </SubscriptionTile>
    )}
  </React.Fragment>
);

LoadingSkeleton.propTypes = {
  consolidatedChilds: PropTypes.bool,
};

const Subscription = ({
  organizationId,
  periods,
  children,
  consolidatedChilds,
  isSpendSearchPricing,
  consolidatedBilling,
}) => {
  const [period, setPeriod] = useState(getDefaultPeriod(periods));
  const [modalPricing, setModalPricing] = useState(null);

  const selectedPeriod = periods.find(p => p.value.toString() === (period || '').toString());

  const { data, error, loading } = useQuery(QUERY_SUBSCRIPTION, {
    variables: {
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
      currentPeriodId: parseInt(period, 10),
      pastPeriodId: pastPeriodId(selectedPeriod, periods),
    },
  });
  const changeDateRange = val => {
    setPeriod(val);
    putPeriodToUrl(val);
  };

  const showPricing = modalData => {
    setModalPricing({ ...modalData });
  };

  if (error) {
    return (
      <SubscriptionTile
        selectCollection={periods}
        selectedValue={period}
        mainHeading="Subscription"
        handleClick={changeDateRange}
      >
        <ErrorBox className="mt-16" withIcon>
          {t('feature_period.server_error')}
        </ErrorBox>
      </SubscriptionTile>
    );
  }

  const parsedData = JSON.parse(data?.organization?.subscriptionFeatureTable || '{"lines":[]}');

  const mainOrgData = !loading && parsedData?.lines?.filter(l => l?.organizationId === organizationId);

  return (
    <SubscriptionContext.Provider
      value={{ period: { ...data?.organization?.currentPeriod, id: parseInt(period, 10) } }}
    >
      <StickyPeriodTile
        disabled={!periods}
        downloadCSV
        selectCollection={periods}
        selectedValue={period}
        mainHeading={consolidatedChilds ? t('feature_period.usage_in_ppcbee') : t('feature_period.usage')}
        parentOrganizationId={organizationId}
        handleClick={changeDateRange}
        downloadData={consolidatedChilds ? parsedData?.lines : mainOrgData}
        currentOrgName={data?.organization?.name}
      />
      {loading && <LoadingSkeleton consolidatedChilds={consolidatedChilds} />}

      {!loading && (
        <SubscriptionTile
          selectCollection={periods}
          selectedValue={period}
          mainHeading={consolidatedChilds ? t('feature_period.usage_in_ppcbee') : t('feature_period.usage')}
          handleClick={changeDateRange}
          subheading={<DataFormatter {...data.organization?.currentPeriod} />}
        >
          <PeriodOverview
            period={selectedPeriod?.state}
            currentPrice={data.organization?.currentPeriod?.price}
            pastPrice={data.organization?.pastPeriod?.price}
            forecastPrice={
              JSON.parse(data.organization.subscriptionFeatureTable)?.forecastPrice ||
              data.organization?.currentPeriod?.price
            }
          />
          <div className="Sticky-Wrapper Sticky-Wrapper--border mt-16">
            <SubscriptionTable
              key={selectedPeriod?.id}
              lines={mainOrgData}
              columns={tableColumnsByPeriodType[(selectedPeriod?.state)]}
              period={selectedPeriod?.state}
              showPricing={showPricing}
            />
          </div>
          <div className="Sticky-Wrapper Sticky-Wrapper--border mt-16">
            <SubscriptionTable
              lines={[{ id: 'sync', ...data.organization.currentPeriod }]}
              disableFirstEmptyColumn
              columns={
                isSpendSearchPricing
                  ? synchronizationTableColumns(consolidatedBilling)
                  : synchronizationTableWithSupportColumns(consolidatedBilling)
              }
            >
              {selectedPeriod?.state !== 'past' && children}
            </SubscriptionTable>
          </div>
        </SubscriptionTile>
      )}

      {!loading && consolidatedChilds && (
        <SubscriptionTile
          mainHeading={t('feature_period.usage_of_suborganizations')}
          subheading={<DataFormatter {...data.organization?.currentPeriod} />}
          className="mt-16"
        >
          <div className="Sticky-Wrapper Sticky-Wrapper--border mt-16">
            <ConsolidatedContainer
              lines={JSON.parse(data.organization.subscriptionFeatureTable).lines}
              parentOrganizationId={organizationId}
              period={selectedPeriod?.state}
              showPricing={showPricing}
              key={selectedPeriod?.id}
              currentPrice={data.organization?.currentPeriod?.price}
              pastPrice={data.organization?.pastPeriod?.price}
              currency={JSON.parse(data.organization.subscriptionFeatureTable)?.currency}
              forecastPrice={
                JSON.parse(data.organization.subscriptionFeatureTable)?.forecastPrice ||
                data.organization?.currentPeriod?.price
              }
            />
          </div>
        </SubscriptionTile>
      )}
      {!loading && modalPricing && <PricingModal modalPricing={modalPricing} setModalPricing={setModalPricing} />}
    </SubscriptionContext.Provider>
  );
};

Subscription.propTypes = {
  consolidatedBilling: PropTypes.bool,
  consolidatedChilds: PropTypes.bool,
  children: PropTypes.object,
  organizationId: PropTypes.number,
  periods: PropTypes.array,
  isSpendSearchPricing: PropTypes.boolean,
};

DataFormatter.propTypes = {
  beginsOn: PropTypes.string,
  endsOn: PropTypes.string,
};

export default hot(Subscription);
