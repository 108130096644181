import { gql, useQuery, NetworkStatus } from '@apollo/client';
import { isEmpty, flatten, uniq } from 'lodash';
import { useMultiEdit } from '../../MultiEditContext';
import { DEFAULT_COLUMN } from '../../MultiEditBox';
import { createHashMap } from '../../utils';

export const statuses = {
  LOADING: 'LOADING',
  NO_DATA: 'NO_DATA',
  SUCCESS: 'SUCCESS',
};

const GET_VARIABLES = collectionKey => gql`
  query MultiEditVariablesQuery($organizationId: BigInt!, $dataSourceId: BigInt, $campaignSettingId: BigInt, $selected: [String!]){
    collection(
      identifier: "${collectionKey}"
      order: null
      orderDirection: "asc"
      page: 0
      limit: 1000
      organizationId: $organizationId
      dataSourceId: $dataSourceId
      campaignSettingId: $campaignSettingId
    ) {
      id
      ${collectionKey}(ids: $selected) {
        id
        getPlaceholdersWithoutConditions
        possibleVariables
      }
    }
  }
`;

const getTransformedPlaceholders = addFirstEmpty => func => {
  if (typeof func !== 'function') throw new Error('Func has to be a function');

  const result = func();
  if (addFirstEmpty) return addEmptyVal(result);
  return result;
};

const addEmptyVal = arr =>
  arr
    .reverse()
    .concat(DEFAULT_COLUMN)
    .reverse();

const useVariablePlaceholders = ({ firstIsEmpty = true, uniqFunc, allFunc, keyType }) => {
  // prettier-ignore
  const { state: { selected, multiEdit, collectionKey: tempCollectionKey, organizationId, dataSourceId, campaignSettingId } } = useMultiEdit();
  const transform = getTransformedPlaceholders(firstIsEmpty);

  const collectionKey = typeof multiEdit?.[keyType] === 'string' ? multiEdit[keyType] : tempCollectionKey;

  const { data, networkStatus } = useQuery(GET_VARIABLES(collectionKey), {
    variables: { organizationId, dataSourceId, campaignSettingId, selected: selected.map(c => c.toString()) },
  });
  if (networkStatus !== NetworkStatus.ready) return { status: statuses.LOADING };

  const possibleVariables = data?.collection?.[collectionKey]?.[0]?.possibleVariables;
  if (!data || data.collection?.length === 0 || !possibleVariables) {
    return { status: statuses.NO_DATA };
  }

  const hashData = createHashMap(data.collection[collectionKey], 'id', curr =>
    selected.indexOf(curr.id) !== -1 && curr.getPlaceholdersWithoutConditions.length > 0 ? curr : null
  );

  const allVariables = transform(() => JSON.parse(possibleVariables).map(x => allFunc(x)));
  if (isEmpty(hashData)) return { allVariables, status: statuses.NO_DATA };

  const values = Object.values(hashData);
  const usedVariables = values.map(obj => obj.getPlaceholdersWithoutConditions);
  const uniqueVariables = transform(() => uniq(flatten(usedVariables)).map(value => uniqFunc(value, allVariables)));

  return { uniqueVariables, allVariables, status: statuses.SUCCESS };
};

export default useVariablePlaceholders;
