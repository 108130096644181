import React, { Component } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { Button, SectionHeading } from './index';

class InfoBanner extends Component {
  static propTypes = {
    isActive: RPT.bool.isRequired,
    close: RPT.func.isRequired,
    closeButtonMoved: RPT.bool,
    isNarrow: RPT.bool,
    children: RPT.any,
    heading: RPT.string,
  };

  render() {
    const { isActive, close, closeButtonMoved, isNarrow, heading, children } = this.props;

    return (
      <div className={`Tile ${!isActive && 'd-none'} mb-16`}>
        <Button
          onlyIcon
          size="small"
          kind="tertiary"
          icon="close"
          className={cs('Tile-close', 'js-dashboard-close-button', { 'Tile-close--movedTop': closeButtonMoved })}
          onClick={close}
        />
        {heading && heading.length > 0 && (
          <div className="Tile-header">
            <SectionHeading spacing={0}>{heading}</SectionHeading>
          </div>
        )}
        <div className={`Tile-content ${isNarrow && 'Tile-content--narrow'}`}>{children}</div>
      </div>
    );
  }
}

export default InfoBanner;
