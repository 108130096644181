// Highly taken from https://github.com/azuqua/react-dnd-scrollzone/blob/master/src/index.js

import { throttle } from 'lodash';
import raf from 'raf';

const DEFAULT_BUFFER = 150;
const state = {
  scaleX: 0,
  scaleY: 0,
};

const attach = () => {
  state.attached = true;
  window.document.body.addEventListener('mousemove', updateScrolling);
  window.document.body.addEventListener('dragover', updateScrolling);
  window.document.body.addEventListener('touchmove', updateScrolling);
};

const detach = () => {
  state.attached = false;
  window.document.body.removeEventListener('mousemove', updateScrolling);
  window.document.body.removeEventListener('dragover', updateScrolling);
  window.document.body.removeEventListener('touchmove', updateScrolling);
};

function intBetween(min, max, val) {
  return Math.floor(Math.min(max, Math.max(min, val)));
}

function getCoords(evt) {
  if (evt.type === 'touchmove') {
    return {
      x: evt.changedTouches[0].clientX,
      y: evt.changedTouches[0].clientY,
    };
  }

  return { x: evt.clientX, y: evt.clientY };
}

const horizontalStrength = ({ x, w, y, h }, point) => {
  const buffer = Math.min(w / 2, DEFAULT_BUFFER);
  const inRange = point.x >= x && point.x <= x + w;
  const inBox = inRange && point.y >= y && point.y <= y + h;

  if (inBox) {
    if (point.x < x + buffer) {
      return (point.x - x - buffer) / buffer;
    } else if (point.x > x + w - buffer) {
      return -(x + w - point.x - buffer) / buffer;
    }
  }

  return 0;
};

const verticalStrength = ({ y, h, x, w }, point) => {
  const buffer = Math.min(h / 2, DEFAULT_BUFFER);
  const inRange = point.y >= y && point.y <= y + h;
  const inBox = inRange && point.x >= x && point.x <= x + w;

  if (inBox) {
    if (point.y < y + buffer) {
      return (point.y - y - buffer) / buffer;
    } else if (point.y > y + h - buffer) {
      return -(y + h - point.y - buffer) / buffer;
    }
  }

  return 0;
};

const updateScrolling = throttle(
  evt => {
    const box = { x: 0, y: 0, w: window.innerWidth, h: window.innerHeight };

    const coords = getCoords(evt);

    // calculate strength
    state.scaleX = horizontalStrength(box, coords);
    state.scaleY = verticalStrength(box, coords);

    // start scrolling if we need to
    if (!state.frame && (state.scaleX || state.scaleY)) {
      startScrolling();
    }
  },
  100,
  { trailing: false }
);

const stopScrolling = () => {
  state.scaleX = 0;
  state.scaleY = 0;

  if (state.frame) {
    raf.cancel(state.frame);
    state.frame = null;
  }
};

const startScrolling = () => {
  let i = 0;
  const tick = () => {
    const { scaleX, scaleY } = state;
    // stop scrolling if there's nothing to do
    if (scaleX + scaleY === 0) {
      stopScrolling();
      return;
    }

    // there's a bug in safari where it seems like we can't get
    // mousemove events from a container that also emits a scroll
    // event that same frame. So we double the strengthMultiplier and only adjust
    // the scroll position at 30fps
    i += 1;
    if (i % 2) {
      const container = document.getElementsByTagName('html')[0];
      const {
        scrollLeft,
        scrollTop,
        scrollWidth,
        scrollHeight,
        clientWidth,
        clientHeight,
      } = container;

      if (scaleX) {
        container.scrollLeft = intBetween(
          0,
          scrollWidth - clientWidth,
          scrollLeft + scaleX * 30
        );
      }

      if (scaleY) {
        container.scrollTop = intBetween(
          0,
          scrollHeight - clientHeight,
          scrollTop + scaleY * 30
        );
      }
    }
    state.frame = raf(tick);
  };

  tick();
};

window.scrollByMouseActivate = () => {
  attach();
};

window.scrollByMouseDectivate = () => {
  detach();
};
