import React, { useState } from 'react';
import PropTypes from 'prop-types';
import RemotePlaceholderModal from './Modal';
import { t } from '../i18n';
import { Button, Tile, Icon, CreateElementModal, Col, Row, SmallHeading } from '../components';

const VariableTile = ({ type, creationLink, setCreation, children }) => (
  <Col width="200px">
    <Tile
      hoverable
      center
      fullHeight
      onClick={() => {
        setCreation({
          creation: `new_${type}`,
          creationLink,
        });
      }}
    >
      <Row height="100%" center>
        <Col>
          <div className="Text Text--bold pb-8">{children}</div>
          <Icon kind={`${type}-type`} size="18px" color="#9B9B9B" />
        </Col>
      </Row>
    </Tile>
  </Col>
);

VariableTile.propTypes = {
  type: PropTypes.string,
  creationLink: PropTypes.string,
  children: PropTypes.any,
  setCreation: PropTypes.any,
};

const AddNewVariable = ({ newLinks, restoreFocus }) => {
  const [{ creation, creationLink }, setCreation] = useState({ creation: false, creationLink: false });
  const [showModal, setShownModalState] = useState(false);

  const closeAddModalAndRefocus = () => {
    setShownModalState(false);
    if (typeof restoreFocus === 'function') {
      restoreFocus();
    }
  };
  const hasAnyNewLink = newLinks.tiles && newLinks.tiles.length > 0;
  if (!hasAnyNewLink) {
    return (
      <Row center className="Placeholders-dropdownHeader">
        <Col grow>
          <div className="Text Text--bold">{t('react.placeholders.variables', { default: 'Variables' })}</div>
        </Col>
      </Row>
    );
  }

  return (
    <div>
      <Row center className="Placeholders-dropdownHeader">
        <Col grow>
          <SmallHeading spacing={0}>{t('react.placeholders.variables', { default: 'Variables' })}</SmallHeading>
        </Col>
        <Col shrink>
          <Button
            data-test-id="create-custom-variable-inline"
            onlyIcon
            kind="secondary"
            size="small"
            icon="plus"
            text={t('react.placeholders.create_new', { default: 'Create New' })}
            style={{ paddingLeft: '8px', paddingRight: '8px' }}
            onClick={e => {
              e.preventDefault();
              setShownModalState(true);
            }}
          />
        </Col>
      </Row>

      {showModal && (
        <CreateElementModal
          {...newLinks}
          tiles={newLinks.tiles.map(tile => ({
            ...tile,
            onClick: () => setCreation({ creation: tile.icon, creationLink: tile.url }),
          }))}
          onClose={closeAddModalAndRefocus}
        />
      )}

      {creation && creationLink && (
        <RemotePlaceholderModal
          name={window.reacti18n.modal[`create_${creation}_variable`]}
          onClose={() => {
            setCreation({ creation: false, creationLink: false });
            setShownModalState(false);
            if (typeof restoreFocus === 'function') {
              restoreFocus();
            }
          }}
          url={creationLink}
        />
      )}
    </div>
  );
};

AddNewVariable.propTypes = {
  newLinks: PropTypes.object,
  restoreFocus: PropTypes.func,
};

export default AddNewVariable;
