import React from 'react';
import PropTypes from 'prop-types';
import {
  Tile,
  Button,
  ImagePreview,
  Ellipsis,
  Link,
  Col,
  Row,
  Icon,
  Tooltip,
  ShowOnHoverWrapper,
  ShowOnHoverElement,
} from '../index';
import { modalDataUrlOpener } from '../RemoteModal';
import ConditionalWrapper from '../ConditionalWrapper';
import { t } from '../../i18n';

const GridRow = ({ icon, name, url = null }) => (
  <Row>
    <Col shrink>
      <Icon kind={icon} />
    </Col>
    <Col grow>
      <ConditionalWrapper condition={url} wrapper={child => <Link href={url}>{child}</Link>}>
        <Ellipsis>{name}</Ellipsis>
      </ConditionalWrapper>
    </Col>
  </Row>
);

const DisplayGrid = ({ data }) => (
  <Row className="ph-0" flexwrap>
    {data.map(
      ({ id, randomPreviewValue, name, imageResolution, dataSource, editLink, duplicateCopyLink, deleteLink }) => (
        <Col width="calc(20% - 8px)" key={id}>
          <ShowOnHoverWrapper>
            <Tile style={{ position: 'relative' }} shadow className="Tile--borderOnHover">
              <ShowOnHoverElement>
                <Col
                  style={{
                    height: '200px',
                    padding: '0',
                    left: 0,
                    width: '100%',
                    position: 'absolute',
                    zIndex: '10',
                  }}
                >
                  <Row shrink justifyEnd>
                    <Tooltip placement="top" text={t('react.image_editor.duplicate_tooltip')}>
                      <Button
                        icon="duplicate"
                        primary
                        onlyIcon
                        tag="a"
                        className="mt-m"
                        size="small"
                        {...modalDataUrlOpener({
                          url: duplicateCopyLink,
                          size: 'fullwithGrid',
                        })}
                      />
                    </Tooltip>
                    <Tooltip
                      placement="top"
                      text={t(deleteLink ? 'delete_tooltip' : 'can_not_be_deleted_tooltip', {
                        scope: 'react.image_editor',
                      })}
                    >
                      <Button
                        red
                        icon="trash"
                        className="mt-m mr-8 ml-8"
                        onlyIcon
                        size="small"
                        tag="a"
                        disabled={!deleteLink}
                        href={deleteLink}
                        data-remote
                        data-method="delete"
                        data-title={t('react.image_editor.delete_modal.delete_heading')}
                        data-confirm={t('react.image_editor.delete_modal.delete_confirmation', { name })}
                        data-commit={t('react.image_editor.delete_modal.delete_confirm')}
                        data-container="body"
                        data-test-id-delete={name}
                        rel="nofollow"
                      />
                    </Tooltip>
                  </Row>
                  <Row grow center justifyCenter>
                    <Button
                      icon="edit"
                      tag="a"
                      style={{ zIndex: 400 }}
                      secondary
                      {...modalDataUrlOpener({
                        url: editLink,
                        size: 'fullwithGrid',
                      })}
                    >
                      {t('react.image_editor.edit_image')}
                    </Button>
                  </Row>
                </Col>
              </ShowOnHoverElement>
              <ImagePreview url={randomPreviewValue?.[0]} size={200} withoutTile fullWidth />
              <div className="delimiter negative-ml-16 negative-mr-16 mb-16" />
              <Col className="Card--content mt-24">
                <Link
                  className="mb-8"
                  {...modalDataUrlOpener({
                    url: editLink,
                    size: 'fullwithGrid',
                  })}
                >
                  <Ellipsis>{name}</Ellipsis>
                </Link>
                <GridRow icon="ig-carousel-ads" name={imageResolution} />
                <GridRow icon="data-sources" url={dataSource?.showLink} name={dataSource?.name} />
              </Col>
            </Tile>
          </ShowOnHoverWrapper>
        </Col>
      )
    )}
  </Row>
);

GridRow.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  url: PropTypes.string,
};
DisplayGrid.propTypes = {
  data: PropTypes.array,
};

export default DisplayGrid;
