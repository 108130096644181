/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { memo, useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { gql, useLazyQuery } from '@apollo/client';
import { debounce, throttle, isEqual, uniqBy, isEmpty } from 'lodash';
import './fontResizer';
import Designer from './react-designer';
import { modes } from './react-designer/constants';
import DesignerErrorBoundary from './DesignerErrorBoundary';
import { addFieldToNestedFieldOptions } from '../../nested_fields/getNestedFieldOptions';
import OptionsContext from './OptionsContext';
import objectTypes from './react-designer/objectTypes';
import { t, formatPercentage } from '../../i18n';
import { Row, Col, Checkbox, DropdownMenu, Link, Button, SegmentedButton, useLocalState, Text } from '../index';
import { PreloadFontFamilies } from './react-designer/panels/TextPanel';
import { listenOnPlaceholdersUpdate } from '../../placeholders/placeholdersEmitter';

const MAX_NUMBER_OF_STEPS_IN_HISTORY = 50;

const HiddenInput = memo(({ id, name, value, setRef }) => (
  <input type="hidden" value={value || ''} name={name} id={id} ref={setRef} />
));
HiddenInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  setRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
};

const getPreviewNodes = formId => {
  // if show in modal use it instead of main layout
  const node =
    document.querySelectorAll(`#React-PreviewPanel-Modal[data-form-id="#${formId}"]`)[0] ||
    document.getElementById('React-PreviewPanel');
  return node
    ? {
        modifierPreviewRoot: node.parentElement,
        modifierPreviewNode: node.parentElement.removeChild(node),
      }
    : {};
};

const AdditionalMenu = ({
  scale,
  preview,
  onChange,
  formId,
  setPanel,
  snapToGuidelines,
  showGuidelines,
  toggleSnapToGuidelines,
  toggleShowGuidelines,
  nextLivePreview,
  historyLength,
  nextStepsFromHistoryLength,
  historyBack,
  historyForward,
  fitCanvasToContainer,
}) => {
  const elements = (
    <Row center>
      {preview && (
        <React.Fragment>
          <Text color="softGray" size="sm">
            Live preview
          </Text>
          <Button className="ml-8 mr-16" size="small" secondary onlyIcon icon="random" onClick={nextLivePreview} />
        </React.Fragment>
      )}
      <Button secondary onlyIcon icon="undo" size="small" onClick={historyBack} disabled={historyLength === 0} />
      <Button
        className="ml-8"
        size="small"
        secondary
        onlyIcon
        icon="redo"
        onClick={historyForward}
        disabled={nextStepsFromHistoryLength === 0}
      />
      <Button
        disabled={historyLength === 0 && nextStepsFromHistoryLength === 0}
        size="small"
        onClick={() => setPanel('history')}
        className="ml-8"
        secondary
        onlyIcon
        icon="history"
      />
      <Button
        className="ml-32"
        secondary
        onlyIcon
        size="small"
        icon="line"
        onClick={() => onChange(scale - 0.1)}
        disabled={scale <= 0.2}
      />
      <span style={{ width: '62px' }} className="text-center">
        {formatPercentage(scale * 100)}
      </span>
      <Button
        secondary
        onlyIcon
        size="small"
        icon="plus"
        className="mr-8"
        onClick={() => onChange(scale + 0.1)}
        disabled={scale >= 2}
      />
      <DropdownMenu small placement="bottom-end" secondary>
        <Link onClick={fitCanvasToContainer}>{t('fit', { scope: 'image_generator.additional_menu.scaling' })}</Link>
        <Link onClick={() => onChange(1)}>{t('zoom_100', { scope: 'image_generator.additional_menu.scaling' })}</Link>
        <Link onClick={() => onChange(0.5)}>{t('zoom_50', { scope: 'image_generator.additional_menu.scaling' })}</Link>
        <Link onClick={() => onChange(2)}>{t('zoom_200', { scope: 'image_generator.additional_menu.scaling' })}</Link>
        <div className="DropdownMenu-delimiter" />
        <Link onClick={toggleSnapToGuidelines}>
          <Row padding="s" center>
            <Col shrink>
              <Checkbox doNotUseInternalState style={{ pointerEvents: 'none' }} checked={snapToGuidelines} />
            </Col>
            <Col>{t('snap', { scope: 'image_generator.additional_menu.guidelines' })}</Col>
          </Row>
        </Link>
        <Link onClick={toggleShowGuidelines}>
          <Row padding="s" center>
            <Col shrink>
              <Checkbox doNotUseInternalState style={{ pointerEvents: 'none' }} checked={showGuidelines} />
            </Col>
            <Col>{t('show', { scope: 'image_generator.additional_menu.guidelines' })}</Col>
          </Row>
        </Link>
      </DropdownMenu>
      {/*      <Button
        className="ml-32"
        secondary
        onlyIcon
        size="small"
        icon="line"
        onClick={() => updateGrid(grid - 1)}
        disabled={scale <= 0}
      />

      <span style={{ width: '62px' }} className="text-center">
        Grid: {grid}px
      </span>

      <Button secondary onlyIcon size="small" icon="plus" onClick={() => updateGrid(grid + 1)} disabled={scale >= 50} />
*/}{' '}
    </Row>
  );
  const container = document.getElementById(`action-bar-${formId}-right_part`);

  if (container) {
    return ReactDOM.createPortal(elements, document.getElementById(`action-bar-${formId}-right_part`));
  }
  return elements;
};

const LiveAndDesignSwitcher = ({ liveAndDesignSwitcherContainterSelector, preview, onChange }) => {
  const container = document.querySelector(liveAndDesignSwitcherContainterSelector);
  if (container) {
    const identifier = liveAndDesignSwitcherContainterSelector.replace('.', '');
    return ReactDOM.createPortal(
      <SegmentedButton
        name={`image_gen${identifier}[preview_mode]`}
        id={`image_gen_preview_mode-${identifier}`}
        value={preview ? 'true' : 'false'}
        doNotUseInternalState
        onChange={onChange}
        collection={[
          { value: 'true', label: 'Live preview', icon: 'flash' },
          { value: 'false', label: 'Design mode', icon: 'design' },
        ]}
      />,
      container
    );
  }
  return null;
};

class ImageGenerator extends React.PureComponent {
  static propTypes = {
    background: PropTypes.string,
    conditionsOptions: PropTypes.object,
    createLayers: PropTypes.array,
    customImages: PropTypes.array,
    dataSourceId: PropTypes.number,
    errors: PropTypes.array,
    formId: PropTypes.string,
    getUpdatedCustomImages: PropTypes.func,
    height: PropTypes.number,
    children: PropTypes.node,
    id: PropTypes.string,
    imageUrlFields: PropTypes.array,
    isTemplateModalOpen: PropTypes.bool,
    liveAndDesignSwitcherContainterSelector: PropTypes.string,
    name: PropTypes.string,
    svgLayerObjects: PropTypes.string,
    organizationId: PropTypes.number,
    variableName: PropTypes.string,
    width: PropTypes.number,
    historyPush: PropTypes.func,
    history: PropTypes.array,
    cannotEdit: PropTypes.bool,
  };

  state = {
    panel: 'setup',
    width: this.props.width,
    height: this.props.height,
    background: this.props.background,
    variableName: this.props.variableName,
    grid: 1,
    scale: 1.0,
    objects: JSON.parse(this.props.svgLayerObjects),
    finalObjects: this.props.svgLayerObjects,
    modifierPreviewRoot: null,
    modifierPreviewNode: null,
    snapToGuidelines: true,
    showGuidelines: true,
    previewObjects: [],
    previewObjectsIndex: 0,
    preview: false,
    newProductSets: [],
    ...getPreviewNodes(this.props.formId),
  };

  history = this.props.history;
  lastHistoryChecksum = null;
  historyDirection = null;
  nextStepsFromHistory = [];

  setPanel = panel => this.setState({ panel });

  componentDidMount() {
    setInterval(window.fontResizer, 100);
    window.notifyImageGenAboutPreviewObjects = this.notifyImageGenAboutPreviewObjects;

    this.fitCanvasToContainer();
  }

  notifyImageGenAboutPreviewObjects = previewObjects => {
    if (
      this.state.previewObjects.map(({ product_id }) => product_id) !==
      previewObjects.map(({ product_id }) => product_id)
    ) {
      this.setState({ previewObjectsIndex: 0 });
    }
    this.setState({ previewObjects });
  };

  nextLivePreview = () => {
    const { previewObjectsIndex, previewObjects } = this.state;
    if (previewObjectsIndex + 1 < previewObjects.length) {
      this.setState({ previewObjectsIndex: previewObjectsIndex + 1 });
    } else if (this.finalObjectsRef) {
      const form = this.finalObjectsRef.closest('form');
      if (form && typeof form.refreshIds === 'function') {
        form.refreshIds();
      }
    }
  };

  historyCheckpoint = () => {
    const { height, width, variableName, background, objects } = this.state;
    const checkpoint = { height, width, variableName, background, objects, historyRecorder: moment() };
    const historyChecksum = JSON.stringify({ height, width, variableName, background, objects });

    if (!this.lastHistoryChecksum || this.lastHistoryChecksum !== historyChecksum) {
      this.historyDirection = null;
      this.lastHistoryChecksum = historyChecksum;
      this.history.push(checkpoint);
      this.props.historyPush(checkpoint);

      this.nextStepsFromHistory = [];

      if (this.history.length > MAX_NUMBER_OF_STEPS_IN_HISTORY) {
        this.history.shift();
      }
    }
  };

  debouncedHistoryCheckpoint = throttle(this.historyCheckpoint, 1000, { leading: false, trailing: true });

  setHistory = newHistory => {
    this.historyDirection = null;
    if (this.nextStepsFromHistory.indexOf(newHistory) !== -1) {
      for (
        let i = this.nextStepsFromHistory.length - this.nextStepsFromHistory.indexOf(newHistory) - 1;
        i > 0;
        i -= 1
      ) {
        const nextHistory = this.nextStepsFromHistory.pop();
        this.history.push({
          width: nextHistory.width,
          height: nextHistory.height,
          background: nextHistory.background,
          objects: nextHistory.objects,
          finalObjects: nextHistory.finalObjects,
          historyRecorder: nextHistory.historyRecorder,
        });
      }
      this.historyDirection = 'forward';
      this.historyForward();
    } else {
      for (let i = this.history.length - this.history.indexOf(newHistory) - 1; i > 0; i -= 1) {
        const lastHistory = this.history.pop();
        this.nextStepsFromHistory.push({
          width: lastHistory.width,
          height: lastHistory.height,
          background: lastHistory.background,
          objects: lastHistory.objects,
          finalObjects: lastHistory.finalObjects,
          historyRecorder: lastHistory.historyRecorder,
        });
      }
      this.historyDirection = 'back';
      this.historyBack();
    }
  };

  historyBack = (withoutStateUpdate = false) => {
    if (!withoutStateUpdate && this.historyDirection !== 'back') {
      this.historyDirection = 'back';
      this.historyBack(true);
    }

    const lastHistory = this.history.pop();

    if (lastHistory) {
      this.nextStepsFromHistory.push({
        width: lastHistory.width,
        height: lastHistory.height,
        background: lastHistory.background,
        objects: lastHistory.objects,
        finalObjects: lastHistory.finalObjects,
        historyRecorder: lastHistory.historyRecorder,
      });

      if (!withoutStateUpdate) {
        if (this.designerRef) {
          this.designerRef.setState({
            mode: modes.FREE,
            selectionHandler: null,
            holdAspectRatio: false,
            selectedTool: null,
            multiSelect: false,
            currentObjectIndex: null,
            selectedObjectIndex: null,
            selectedObjectsIndexes: null,
            showHandler: false,
          });
        }

        this.setState({
          width: lastHistory.width,
          height: lastHistory.height,
          background: lastHistory.background,
          objects: lastHistory.objects,
          finalObjects: lastHistory.finalObjects,
        });
        setTimeout(() => this.forceUpdate(), 100);
      }
    } else {
      this.designerRef.setState({
        multiSelect: false,
      });
    }
  };

  historyForward = (withoutStateUpdate = false) => {
    if (!withoutStateUpdate && this.historyDirection !== 'forward') {
      this.historyDirection = 'forward';
      this.historyForward(true);
    }

    const newHistory = this.nextStepsFromHistory.pop();

    if (newHistory) {
      this.history.push(newHistory);

      if (this.designerRef) {
        this.designerRef.setState({
          mode: modes.FREE,
          selectionHandler: null,
          holdAspectRatio: false,
          selectedTool: null,
          multiSelect: false,
          currentObjectIndex: null,
          selectedObjectIndex: null,
          selectedObjectsIndexes: null,
          showHandler: false,
        });
      }

      this.setState(newHistory);
      setTimeout(() => this.forceUpdate(), 100);
    } else {
      this.designerRef.setState({
        multiSelect: false,
      });

      this.forceUpdate();
    }
  };

  getField = addFieldToNestedFieldOptions({ name: this.props.name, id: this.props.name.replace(/\[|\]/g, '_') });

  designerRef = undefined;

  updateSVG = () => {
    this.setState({
      finalObjects: JSON.stringify(
        this.state.objects.map(e => {
          const Element = objectTypes[e.type];
          return { ...e, svg: ReactDOMServer.renderToString(<Element object={e} />) };
        })
      ),
    });

    setTimeout(() => {
      if (this.finalObjectsRef) {
        const form = $(this.finalObjectsRef).closest('form')[0];
        this.finalObjectsRef.dispatchEvent(new window.Event('change', { bubbles: true }));
        if (form) {
          form.dispatchEvent(new window.Event('change', { bubbles: true }));
        }
      }
    }, 10);
  };

  debouncedUpdateSVG = debounce(this.updateSVG, 500);

  updateState = newState => {
    const newStateKeys = Object.keys(newState);
    if (
      !isEqual(newStateKeys, ['objects']) &&
      !isEqual(newStateKeys, ['grid']) &&
      !isEqual(newStateKeys, ['variableName']) &&
      !isEqual(newStateKeys, ['scale']) &&
      !isEqual(newStateKeys, ['finalObjects'])
    ) {
      this.debouncedHistoryCheckpoint();
    }

    this.setState(newState);

    if (
      (newState.width && this.state.widh !== newState.width) ||
      (newState.height && this.state.height !== newState.height)
    ) {
      this.fitCanvasToContainer(newState.width, newState.height);
    }

    // TODO process by web worker!!!
    this.debouncedUpdateSVG();
  };

  download = event => {
    event.preventDefault();
    const svgElement = this.designerRef.svgElement;

    svgElement.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    const source = svgElement.outerHTML;
    const uri = `data:image/svg+xml;base64,${window.btoa(unescape(encodeURIComponent(source)))}`;

    const a = document.createElement('a'); // Create <a>
    a.href = uri; // Image Base64 Goes here
    a.download = 'Image.svg'; // File name Here
    a.click();
  };

  renderPreviewRef = el => {
    if (el) {
      el.appendChild(this.state.modifierPreviewNode);
    }
  };

  onDesignerUpdate = (newObjects, checkpoint) => {
    this.updateState({ objects: newObjects });
    if (checkpoint) {
      setTimeout(() => this.debouncedHistoryCheckpoint(), 10);
    }
  };

  setDesignerRef = el => {
    this.designerRef = el;
  };

  // eslint-disable-next-line consistent-return
  getSizeOfContainer = () => {
    if (this.designerRef?.svgElement?.closest('.js-main-container-to-get-sizes')) {
      const dimensions = this.designerRef.svgElement
        .closest('.js-main-container-to-get-sizes')
        ?.getBoundingClientRect();
      const fixedDimensions = { width: dimensions.width, height: dimensions.height - 84 }; // remove space under action bar and margin top of bar
      return fixedDimensions;
    }
  };

  fitCanvasToContainer = (overrideWidth, overrideHeight) => {
    const containerSize = this.getSizeOfContainer();

    if (containerSize) {
      const width = (typeof overrideWidth === 'number' ? overrideWidth : undefined) || this.state.width;
      const height = (typeof overrideHeight === 'number' ? overrideHeight : undefined) || this.state.height;

      const scales = [2];

      scales.push(Math.floor((containerSize.width / width) * 10) / 10);
      scales.push(Math.floor((containerSize.height / height) * 10) / 10);

      const newScale = Math.min(...scales.filter(x => !isNaN(x)));
      if (newScale !== this.state.scale) {
        this.setState({ scale: newScale });
      }
    }
  };

  setFinalObjectsRef = el => {
    this.finalObjectsRef = el;
  };

  updateScale = newScale => this.updateState({ scale: newScale });
  updateGrid = newGrid => this.updateState({ grid: newGrid });
  toggleSnapToGuidelines = () => this.updateState({ snapToGuidelines: !this.state.snapToGuidelines });
  toggleShowGuidelines = () => this.updateState({ showGuidelines: !this.state.showGuidelines });

  handleProductSetsAdded(value) {
    if (value) {
      this.setState({ ...this.state, newProductSets: [...this.state.newProductSets, ...Object.values(value)] });
    }
  }

  render() {
    const {
      conditionsOptions,
      createLayers,
      customImages,
      errors,
      formId,
      children,
      id,
      imageUrlFields,
      isTemplateModalOpen,
      organizationId,
      getUpdatedCustomImages,
      dataSourceId,
      cannotEdit,
    } = this.props;
    const {
      width,
      height,
      grid,
      background,
      objects,
      finalObjects,
      modifierPreviewRoot,
      variableName,
      scale,
      panel,
      snapToGuidelines,
      showGuidelines,
      preview,
      newProductSets,
    } = this.state;

    return (
      <React.Fragment>
        <HiddenInput value={width} {...this.getField('width')} />
        <HiddenInput value={variableName} name={this.props.name.replace('[image_gen_attributes]', '[name]')} />
        <HiddenInput value={height} {...this.getField('height')} />
        <HiddenInput value={background || ''} {...this.getField('background')} />
        <HiddenInput setRef={this.setFinalObjectsRef} value={finalObjects} {...this.getField('svg_layer_objects')} />
        <HiddenInput value={id} {...this.getField('id')} />
        <PreloadFontFamilies organizationId={organizationId} objects={objects} />
        <OptionsContext.Provider
          value={{
            imageUrlFields,
            customImages,
            conditionsOptions: !isEmpty(newProductSets)
              ? { ...conditionsOptions, item_groups: [...conditionsOptions.item_groups, ...newProductSets] }
              : { ...conditionsOptions },
            formId,
            organizationId,
            history: this.history,
            nextStepsFromHistory: this.nextStepsFromHistory,
            setHistory: this.setHistory,
            cannotEdit,
          }}
        >
          <DesignerErrorBoundary>
            <Designer
              ref={this.setDesignerRef}
              createLayers={createLayers}
              width={width}
              height={height}
              background={background}
              historyCheckpoint={this.debouncedHistoryCheckpoint}
              grid={grid}
              panel={panel}
              setPanel={this.setPanel}
              errors={errors}
              scale={scale}
              historyBack={this.historyBack}
              historyLength={this.history.length}
              historyForward={this.historyForward}
              nextStepsFromHistoryLength={this.nextStepsFromHistory.length}
              variableName={variableName}
              snapToGrid={grid}
              snapToGuidelines={snapToGuidelines}
              showGuidelines={showGuidelines}
              onUpdate={this.onDesignerUpdate}
              objects={objects}
              renderPreviewRef={this.renderPreviewRef}
              modifierPreviewRoot={modifierPreviewRoot || getPreviewNodes(formId).modifierPreviewRoot}
              updateGeneratorState={this.updateState}
              preview={preview}
              previewObjects={(this.state.previewObjects[this.state.previewObjectsIndex] || {}).objects || {}}
              isTemplateModalOpen={isTemplateModalOpen}
              dataSourceId={dataSourceId}
              getUpdatedCustomImages={getUpdatedCustomImages}
              onNewProductSetsAdded={this.handleProductSetsAdded.bind(this)}
              newProductSetsEmpty={isEmpty(newProductSets)}
            >
              {children}
            </Designer>
          </DesignerErrorBoundary>
        </OptionsContext.Provider>
        <AdditionalMenu
          formId={formId}
          scale={scale}
          grid={grid}
          setPanel={this.setPanel}
          onChange={this.updateScale}
          updateGrid={this.updateGrid}
          toggleSnapToGuidelines={this.toggleSnapToGuidelines}
          snapToGuidelines={snapToGuidelines}
          toggleShowGuidelines={this.toggleShowGuidelines}
          showGuidelines={showGuidelines}
          preview={preview}
          nextLivePreview={this.nextLivePreview}
          historyLength={this.history.length}
          nextStepsFromHistoryLength={this.nextStepsFromHistory.length}
          historyBack={this.historyBack}
          fitCanvasToContainer={this.fitCanvasToContainer}
          historyForward={this.historyForward}
        />
        <LiveAndDesignSwitcher
          liveAndDesignSwitcherContainterSelector={this.props.liveAndDesignSwitcherContainterSelector}
          preview={preview}
          onChange={({ target: { value } }) => this.setState({ preview: value === 'true' })}
        />
      </React.Fragment>
    );
  }
}

const CUSTOM_IMAGES_QUERY = gql`
  query CustomImagesQuery($organizationId: BigInt!, $dataSourceId: BigInt!) {
    organization(id: $organizationId) {
      dataSource(id: $dataSourceId) {
        id
        modifiers {
          id
          name
          placeholderName
          randomFirstImageUrl
          editLink
          imageGen {
            previewSvgElements
            width
            height
          }
        }
      }
    }
  }
`;

const withUpdateOnModifierrefetchCustomImages = Component => {
  const CustomImagesUpdater = props => {
    const [getUpdatedCustomImages, { data }] = useLazyQuery(CUSTOM_IMAGES_QUERY, {
      variables: { organizationId: props.organizationId, dataSourceId: props.dataSourceId },
      fetchPolicy: 'network-only',
    });

    useEffect(
      () =>
        listenOnPlaceholdersUpdate(() => {
          getUpdatedCustomImages();
        }),
      []
    );

    let fetchedCustomImages = props.customImages;
    let imageUrlFields = props.imageUrlFields;

    if (data) {
      fetchedCustomImages = (data.organization?.dataSource?.modifiers || [])
        .filter(el => el.id.split('/').reverse()[0] !== props?.modifierId?.toString())
        .map(el => ({
          placeholderName: el?.placeholderName,
          svg: el.imageGen?.previewSvgElements,
          editLink: el.editLink,
          viewBox: [0, 0, el.imageGen?.width, el.imageGen?.height].join(' '),
        }));

      imageUrlFields = uniqBy(
        [
          ...imageUrlFields,
          ...(data.organization?.dataSource?.modifiers || [])
            .filter(x => x.randomFirstImageUrl)
            .map(el => ({
              isModifier: true,
              name: el.name,
              value: `_${el.placeholderName}_`,
              url: el.randomFirstImageUrl,
            })),
        ],
        'value'
      );
    }

    return (
      <Component
        {...props}
        imageUrlFields={imageUrlFields}
        customImages={fetchedCustomImages}
        getUpdatedCustomImages={getUpdatedCustomImages}
      />
    );
  };

  CustomImagesUpdater.propTypes = {
    organizationId: PropTypes.number,
    dataSourceId: PropTypes.number,
    modifierId: PropTypes.number,
    customImages: PropTypes.array,
    imageUrlFields: PropTypes.array,
  };
  return CustomImagesUpdater;
};

const withHistoryInLocalStorage = Component => {
  const HistoryState = props => {
    const [history, setHistory] = useLocalState(
      [],
      `image-editor-history-ORG:${props.organizationId}-DS:${props.dataSourceId}-MOD:${props.modifierId || 'new'}`,
      { useCompression: true }
    );

    return (
      <Component
        {...props}
        history={history}
        historyPush={newCheckpoint => {
          setHistory([...history, newCheckpoint].slice(-10));
        }}
      />
    );
  };

  HistoryState.propTypes = {
    organizationId: PropTypes.number,
    dataSourceId: PropTypes.number,
    modifierId: PropTypes.number,
  };
  return HistoryState;
};

export default withUpdateOnModifierrefetchCustomImages(withHistoryInLocalStorage(ImageGenerator));
