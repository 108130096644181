let navbarInited = false;
window.initNavbar = () => {
  if (navbarInited) {
    return;
  }
  navbarInited = true;

  const trigger = $('.js-navbar-dropdown');
  const dropdown = $('.Navbar-dropdown');
  const profileTrigger = $('#js-navbar-item-profile');
  const errorsTrigger = $('#js-navbar-item-errors');
  const toolboxTrigger = $('#js-navbar-item-organization-tools');
  const navbarBackdrop = $('#js-navbar-backdrop');

  // eslint-disable-next-line func-names
  trigger.on('click', function() {
    $(this).toggleClass('active');
    $(this)
      .parent()
      .toggleClass('active');
    $(this)
      .siblings(dropdown)
      .toggleClass('active');
    if (navbarBackdrop) {
      navbarBackdrop.toggleClass('d-none');
    }
  });

  document.addEventListener('click', e => {
    if (document.getElementById('js-navbar-item-profile')) {
      if (!document.getElementById('js-navbar-item-profile').contains(e.target)) {
        profileTrigger.removeClass('active');
        profileTrigger.children(dropdown).removeClass('active');
        if (navbarBackdrop && navbarBackdrop.is(':not(.d-none)')) {
          navbarBackdrop.addClass('d-none');
        }
      }
    }

    if (document.getElementById('js-navbar-item-organization-tools')) {
      if (!document.getElementById('js-navbar-item-organization-tools').contains(e.target)) {
        toolboxTrigger.removeClass('active');
        toolboxTrigger.children(dropdown).removeClass('active');
        if (navbarBackdrop && navbarBackdrop.is(':not(.d-none)')) {
          navbarBackdrop.addClass('d-none');
        }
      }
    }

    if (document.getElementById('js-navbar-item-errors')) {
      if (!document.getElementById('js-navbar-item-errors').contains(e.target)) {
        errorsTrigger.removeClass('active');
        errorsTrigger.children(dropdown).removeClass('active');
      }
    }
  });
};
