import React, { PureComponent, useContext } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { createBrowserHistory } from 'history';
import { SearchContext } from '../index';
import * as Layout from '../../layout';
import Button from '../../Button';
import {
  FulltextSearch,
  ActiveSearch,
  ElementType,
  Systems,
  Labels,
  DataSourceFilter,
  PmaxType,
  PmaxStatus,
} from './index';
import { t } from '../../../i18n';
import {
  getQueryStringValue,
  setQueryStringValue,
  clearQueryStringValue,
  getQueryDefaultSearchState,
} from '../../../utils/urlManipulation';
import { getLocalStorageDefaultSearchState } from '../localStorage';
import { Loader } from '../../Icons';
// eslint-disable-next-line import/no-named-as-default
import PmaxPerformance from './PmaxPerformance';

const history = createBrowserHistory();

export const validFilterKeys = ['active', 'elementType', 'fulltext', 'dataSource', 'labels', 'systems'];

const resolveCurrentStateFromUrl = () => ({
  active: getQueryStringValue('active'),
  elementType: getQueryStringValue('elementType'),
  dataSource: getQueryStringValue('dataSource'),
  fulltext: getQueryStringValue('fulltext'),
  systems: getQueryStringValue('systems'),
});

export class SearchContextProvider extends PureComponent {
  static propTypes = {
    onSearchChange: PropTypes.func.isRequired,
    searchOptions: PropTypes.object,
    activeSearchState: PropTypes.object,
    collectionKey: PropTypes.string.isRequired,
    children: PropTypes.any,
    changeAdditionalTriggeredColumns: PropTypes.func,
  };

  state = {
    search:
      getQueryDefaultSearchState(validFilterKeys) || getLocalStorageDefaultSearchState(this.props.collectionKey) || {},
  };

  componentDidMount() {
    this.unlistenHistory = history.listen(this.updateSearchStateFromHistory);
  }

  compomentWillUnmount() {
    if (this.unlistenHistory) {
      this.unlistenHistory();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeSearchState !== this.props.activeSearchState &&
      this.props.activeSearchState !== this.state.search
    ) {
      setTimeout(() => {
        this.setState({ search: this.props.activeSearchState || {} });
      }, 0);
    }
  }

  updateSearchStateFromHistory = e => {
    if (e.search !== window.location.search) {
      const search = resolveCurrentStateFromUrl();
      this.setState({ search });
      this.activateSearch(search);
    }
  };

  searchChange = (field, value) => {
    this.setState(state => ({ ...state, search: { ...state.search, [field]: value } }));
    setQueryStringValue(field, value);
    this.debouncedActivateSearch();
  };

  clearSearch = () => {
    this.setState({ search: {} });
    this.activateSearch({});
    this.props.changeAdditionalTriggeredColumns([]);
    clearQueryStringValue();
  };

  activateSearch = overrideSearch => {
    const searchObj = overrideSearch || this.state.search;

    if (Object.keys(searchObj).length === 0) {
      return this.props.onSearchChange(undefined);
    }

    return this.props.onSearchChange(
      Object.keys(searchObj).reduce((out, key) => (searchObj[key] ? { ...out, [key]: searchObj[key] } : out), {})
    );
  };
  debouncedActivateSearch = debounce(this.activateSearch, 500);

  render() {
    const { searchOptions, children } = this.props;

    return (
      <SearchContext.Provider
        value={{
          searchState: this.state.search,
          searchChange: this.searchChange,
          searchOptions,
          clearSearch: this.clearSearch,
        }}
      >
        {children}
      </SearchContext.Provider>
    );
  }
}

const showFilter = (enabledFilters, name) => {
  if (typeof enabledFilters === 'undefined') {
    return true;
  }

  return enabledFilters.indexOf(name) !== -1;
};

const SearchWrapper = ({ enabledFilters, changeAdditionalTriggeredColumns, loading, compactSearch }) => {
  const { searchState, clearSearch } = useContext(SearchContext);
  const anyActiveSearch = Object.values(searchState).filter(x => !!x && x !== 'all').length > 0;

  return (
    <React.Fragment>
      <Layout.Col shrink className="mr-24">
        {showFilter(enabledFilters, 'fulltext') && <FulltextSearch compactSearch={compactSearch} />}
      </Layout.Col>

      <Layout.Col shrink>
        <Layout.Row center>
          {showFilter(enabledFilters, 'dataSource') && (
            <Layout.Col shrink>
              <DataSourceFilter />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'elementType') && (
            <Layout.Col shrink>
              <ElementType />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'systems') && (
            <Layout.Col shrink>
              <Systems />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'labels') && (
            <Layout.Col shrink>
              <Labels changeAdditionalTriggeredColumns={changeAdditionalTriggeredColumns} />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'active') && (
            <Layout.Col shrink>
              <ActiveSearch />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'pmaxType') && (
            <Layout.Col shrink>
              <PmaxType />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'pmaxStatus') && (
            <Layout.Col shrink>
              <PmaxStatus />
            </Layout.Col>
          )}
          {showFilter(enabledFilters, 'pmaxPerformance') && (
            <Layout.Col shrink>
              <PmaxPerformance />
            </Layout.Col>
          )}
          {anyActiveSearch && (
            <React.Fragment>
              <Layout.Col shrink>
                <Button id="clear-search" secondary icon="close" onClick={clearSearch}>
                  {t('react.clear_filters')}
                </Button>
              </Layout.Col>
            </React.Fragment>
          )}
          {loading !== undefined && loading !== null && (
            <Layout.Col shrink>
              <span style={{ minWidth: '19px' }}>{loading && <Loader size="small" />}</span>
            </Layout.Col>
          )}
        </Layout.Row>
      </Layout.Col>
    </React.Fragment>
  );
};

SearchWrapper.propTypes = {
  enabledFilters: PropTypes.array,
  changeAdditionalTriggeredColumns: PropTypes.func,
  compactSearch: PropTypes.bool,
  loading: PropTypes.bool,
};
export default SearchWrapper;
