import React from 'react';
import PropTypes from 'prop-types';
import ErrorBox from '../ErrorBox';
import { t } from '../../i18n';

export default class DesignerErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  state = { hasError: false, possibleRetry: true };
  usedLocalState = [];

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line no-console
    console.error('BAFFF', error, errorInfo);
  }

  tryToRerender = () => {
    this.setState({ hasError: false, possibleRetry: false });
    setTimeout(() => !this.state.hasError && this.setState({ possibleRetry: true }), 100);
  };

  render() {
    if (this.state.hasError) {
      if (this.state.possibleRetry) {
        setTimeout(this.tryToRerender, 500);
      }

      return (
        <ErrorBox className="ma-16" withIcon>
          {t('react.error_with_component_render')}
        </ErrorBox>
      );
    }

    return this.props.children;
  }
}
