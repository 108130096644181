const formats = {
  date: {
    formats: {
      default: 'D.M.YYYY',
    },
  },
  number: {
    format: {
      precision: 10,
    },
    currency: {
      format: {
        unit: '$',
      },
    },
    percentage: {
      format: {
        format: '%n%',
      },
    },
  },
};

export default formats;
