import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import Dropdown from './multiEdit/Dropdown';
import EditLine from './multiEdit/editLine/index';
import { useMultiEdit, ACTIONS } from './MultiEditContext';
import { Col, Row, Checkbox, Text } from '../index';
import { t, formatNumberInflection } from '../../i18n';
import { createHashMap } from './utils';

export const DEFAULT_COLUMN = { label: t('react.multi_edit.edited_column_label'), value: '' };

const transaformDataForSelect = columns =>
  Object.keys(columns).map(key => ({ label: columns[key].header(), value: columns[key].id }));

const getValidData = ({ activeColumns, selected }, hashData) =>
  createHashMap(activeColumns, 'id', curr => {
    const selectedData = selected.map(id => hashData[id]).filter(d => curr.resolveChildren(d) !== null);
    if (selectedData.length === 0) return null;
    return { ...curr, selectedData };
  });

const getDropdownLabel = selectedColumn => {
  if (
    selectedColumn.value !== '' &&
    selectedColumn.value !== 'find_and_replace' &&
    selectedColumn.value !== 'change_variable' &&
    selectedColumn.value !== 'time_restriction'
  ) {
    return typeof selectedColumn?.label === 'string'
      ? t('react.multi_edit.editing_variable', { variable: selectedColumn?.label?.toLowerCase() })
      : selectedColumn?.label;
  }
  return selectedColumn?.label;
};

const MultiEditBox = ({ onDelete, onPause, onStart, onCompleted, onTimeRestrictionDelete, callWithConfirmation }) => {
  // prettier-ignore
  const { state, dispatch } = useMultiEdit();
  const [selectedColumn, setSelectedColumn] = useState(DEFAULT_COLUMN);
  const { selected, data } = state;
  const selectedCount = selected.length;

  const hashData = createHashMap(data, 'id');
  const hashSelectedData = createHashMap(data, 'id', curr => {
    if (curr.adtexts) {
      return curr.adtexts.find(a => selected.indexOf(a.id) !== -1) || null;
    }
    return selected.indexOf(curr.id) !== -1 ? curr : null;
  });
  const validColumns = getValidData(state, hashData);

  const dropdownLabel = getDropdownLabel(selectedColumn);

  return (
    <div className="Sticky-actionBar js-disableButtonOnClick" data-test-id="multiEdit-box">
      <Row center padding="l">
        <Col shrink className="mr-0">
          <Checkbox
            data-test-id="multiEdit-close"
            doNotUseInternalState
            checked
            hasLineCheck
            onClick={() => dispatch({ type: ACTIONS.deselectAll })}
          />
        </Col>
        <Col shrink nowrap data-test-id="multiEdit-box[count]">
          <Text>
            {formatNumberInflection({
              count: selectedCount,
              scope: 'react.multi_edit.selected_items',
            })}
          </Text>
        </Col>
        <Col shrink>
          <Dropdown
            label={dropdownLabel}
            columns={transaformDataForSelect(validColumns)}
            hashSelectedData={hashSelectedData}
            data-test-id="multiEdit-box[dropdown]"
          >
            <Dropdown.Status onPause={onPause} onStart={onStart} />
            <Dropdown.DuplicateInto />
            <Dropdown.Variable onClick={setSelectedColumn} />
            <Dropdown.FindAndReplace onClick={setSelectedColumn} />
            <Dropdown.Delete onClick={onDelete} count={selectedCount} callWithConfirmation={callWithConfirmation} />
            <Dropdown.TimeRestriction onDelete={onTimeRestrictionDelete} onSelect={setSelectedColumn} />
            <Dropdown.Edit onClick={setSelectedColumn} />
          </Dropdown>
        </Col>
        <EditLine
          selectedColumn={selectedColumn}
          setSelectedColumn={setSelectedColumn}
          validColumns={validColumns}
          onCompleted={onCompleted}
          hashSelectedData={hashSelectedData}
        />
      </Row>
    </div>
  );
};
MultiEditBox.propTypes = {
  onCompleted: PropTypes.func,
  onDelete: PropTypes.func,
  onPause: PropTypes.func,
  onStart: PropTypes.func,
  onTimeRestrictionDelete: PropTypes.func,
  callWithConfirmation: PropTypes.func,
};

export default hot(MultiEditBox);
