import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { decodeURL, encodeURL, getNextId } from './utils';
import { Button, Tile } from '../index';
import { Col, Row } from '../layout';
import ErrorBox from '../ErrorBox';
import { ModalBody, ModalFooter } from '../Modal';
import UtmRow from './UtmRow';
import PlaceholderInput from '../../placeholders/Input';
import { t } from '../../i18n';

const UtmBuilder = ({ url, setParameters, placeholderInputProps }) => {
  if (typeof url !== 'string') return <ErrorBox withIcon>{t('react.utm_parameters.error')}</ErrorBox>;

  const { orderedUtmParams, params } = decodeURL(url);

  const [utmParams, setUtmParams] = useState(orderedUtmParams);
  const [customParams, setCustomParams] = useState(params);

  const updateItem = (id, whichValue, newValue) => {
    const index = customParams.findIndex(x => x.id === id);
    const newCustomParams = [...customParams];
    newCustomParams[index][whichValue] = newValue;
    setCustomParams(newCustomParams);
  };

  const setPar = () => {
    const encoded = encodeURL(utmParams, customParams);
    setParameters(encoded);
  };

  const encodedURL = encodeURL(utmParams, customParams);

  return (
    <React.Fragment>
      <ModalBody>
        <p>{t('react.utm_parameters.info_text')}</p>
        <form>
          {Object.keys(utmParams).map(up => (
            <Col className="mt-16" key={up} width="600px">
              <Row shrink className="mb-4">
                <label className="Input-label string" htmlFor={`utm_builder_${up}`}>
                  {t(up, { scope: 'react.utm_parameters.labels' })}
                </label>
              </Row>
              <Row className="mb-0" shrink>
                <PlaceholderInput
                  id={`utm_builder_${up}`}
                  {...placeholderInputProps}
                  name={`utm_builder[${up}]`}
                  onChange={({ target: { value } }) => setUtmParams({ ...utmParams, [up]: value })}
                  defaultValue={utmParams[up]}
                  placeholder={t(up, { scope: 'react.utm_parameters.examples' })}
                />
              </Row>
            </Col>
          ))}{' '}
          {customParams.length > 0 &&
            customParams.map(param => (
              <UtmRow
                placeholderInputProps={placeholderInputProps}
                key={param.id}
                changeValue={({ target: { value } }) => updateItem(param.id, 'value', value)}
                changeName={({ target: { value } }) => updateItem(param.id, 'name', value)}
                editableLabel
                deleteRow={id => setCustomParams(customParams.filter(el => el.id !== id))}
                {...param}
              />
            ))}
        </form>

        <Row>
          <Col shrink>
            <Button
              data-test-id="add-custom-parameter"
              kind="secondary"
              size="small"
              icon="plus"
              className="mt-16 mb-24"
              onClick={() => setCustomParams([...customParams, { id: getNextId(customParams), name: '', value: '' }])}
            >
              {t('react.utm_parameters.new_param')}
            </Button>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        {encodedURL.length > 0 && (
          <Tile className="Text--gray" data-test-id="result-url" style={{ wordBreak: 'break-all' }}>
            {encodedURL}
          </Tile>
        )}

        <Button kind="primary" data-test-id="use-parameters" onClick={() => setPar()} className="mt-16">
          {t('react.utm_parameters.use_parameters')}
        </Button>
      </ModalFooter>
    </React.Fragment>
  );
};

UtmBuilder.propTypes = {
  url: PropTypes.string,
  setParameters: PropTypes.func,
  placeholderInputProps: PropTypes.object,
};

export default UtmBuilder;
