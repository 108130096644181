/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import EventEmitter from 'events';
import { debounce } from 'lodash';
import cs from 'classnames';
import { v4 } from 'uuid';
import * as Layout from './layout';
import ErrorBox from './ErrorBox';
import { Modal, ModalHeader, ModalBody } from './Modal';

export const emitter = new EventEmitter();
emitter.setMaxListeners(10000);

export const listenOnModalChangeConditions = cb => {
  emitter.on('changePlaceholders', cb);
  return () => {
    emitter.off('changePlaceholders', cb);
  };
};

export const listenOnModalChange = cb => {
  emitter.on('changePlaceholders', cb);
  return () => {
    emitter.off('connection', cb);
  };
};

const pushNewModalChange = (newPlaceholder, additionalData) =>
  emitter.emit('changePlaceholders', newPlaceholder, additionalData);

window.pushNewModalChange = debounce(pushNewModalChange, 10);

export function modalDataUrlOpener({ url, heading, size, tid, notify }) {
  return {
    'data-toggle': 'modal-url',
    'data-url': url,
    'data-test-id': tid || `modal_opener_${url}`,
    'data-heading': heading,
    'data-size': size,
    'data-notify-on-change': notify,
  };
}
export default class RemoteModal extends React.PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    data: PropTypes.object,
    heading: PropTypes.string,
    subHeading: PropTypes.string,
    size: PropTypes.oneOf(['full', 'fullwithGrid', 'medium', 'small', 'dynamic']),
    notifyOnChange: PropTypes.string,
    onClose: PropTypes.func,
    onPrimaryClick: PropTypes.func,
  };

  static defaultProps = {
    data: {},
  };

  state = {
    data: null,
    error: null,
    uuid: null,
  };

  componentDidMount() {
    $.ajax({
      type: 'get',
      url: this.props.url,
      data: this.props.data,
      contentType: 'application/json',
      dataType: 'html',
      success: data => {
        this.setState({ data, uuid: v4() });
        setTimeout(this.initModalContentJavascript, 300);
      },
      error: (response, error) => {
        this.setState({ error: `${response.status}: ${response.statusText}` });
      },
    });
  }

  setRef = el => {
    this.modalRef = el;
  };

  initModalContentJavascript = () => {
    window.initReact();
    if (this.modalRef) {
      // this hack is for dangerouslySetInnerHTML
      const el = $(this.modalRef).find('script');
      if (el) {
        el.each((i, elScript) => {
          const elS = $(elScript);
          if (!elS.hasClass('react-modal-inited')) {
            window.eval(elS.text());
            elS.addClass('react-modal-inited');
          }
        });
      }

      if (typeof this.props.onPrimaryClick === 'function') {
        $(this.modalRef)
          .find('button[kind="primary"]')
          .on('click', () => this.props.onPrimaryClick());
      }

      this.reloadPageOnModalClose = $(this.modalRef).find('.js-reload-page-on-modal-close').length > 0;
      this.submitPageOnModalClose = $(this.modalRef).find('.js-submit-page-on-modal-close').length > 0;

      $(this.modalRef)
        .find('form')
        .on('ajax:success', (e, data, status, xhr) => {
          if (status === 'nocontent' || xhr.status === 201 || xhr.status === 202) {
            $(this.modalRef).modal('hide');
            if (status === 'success' && typeof window.pushNewPlaceholder === 'function') {
              window.pushNewPlaceholder(data);
            }
            if (typeof window.NotificationCenter !== 'undefined') {
              new window.NotificationCenter().show_success('Saved changes');
              window.pushNewModalChange(this.props.notifyOnChange, data);
            }
            if (data && data.reload_page) {
              setTimeout(() => {
                window.location.reload();
              }, 300);
            }
          } else {
            this.setState({ data, uuid: v4() });

            this.reloadPageOnModalClose = $(data).find('.js-reload-page-on-modal-close').length > 0;
            this.submitPageOnModalClose = $(data).find('.js-submit-page-on-modal-close').length > 0;

            setTimeout(this.initModalContentJavascript, 300);
            setTimeout(window.initReact, 50);
            setTimeout(window.initReact, 100);
          }
        })
        .on('ajax:error', (e, xhr, status, error) => {
          // eslint-disable-next-line no-console
          console.log('ERRROR', e, error, status, xhr);
        });
    }
  };

  handleClose = () => {
    if (typeof this.props.onClose === 'function') this.props.onClose();
    if (this.reloadPageOnModalClose) {
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
    if (this.submitPageOnModalClose) {
      setTimeout(() => {
        if (!window.submitPageOnModalCloseLock) {
          window.submitPageOnModalCloseLock = true;
          $(
            '.modal .js-newLayout-actionBar [type="submit"]:visible, .modal .js-newLayout-actionBar [kind="primary"]:visible, .js-newLayout-actionBar [type="submit"]:visible, .js-newLayout-actionBar [kind="primary"]:visible'
          )
            .first()
            .click();
        }
      }, 700);
    }
  };

  render() {
    const { heading, subHeading, size } = this.props;
    const { data, error, uuid } = this.state;

    return (
      <Modal
        onClose={this.handleClose}
        error={error}
        heading={heading}
        subHeading={subHeading}
        setRef={this.setRef}
        size={size}
      >
        {!heading && !data && !error && <ModalHeader />}
        {data && !error && (
          <div
            key={uuid}
            style={{ height: '100%' }}
            className="data-container"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: data }}
          />
        )}
        {!data && !error && (
          <div className="text-center">
            <div className="Loader Loader--center hide active mt-16 mb-16" />
          </div>
        )}
      </Modal>
    );
  }
}
