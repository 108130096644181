import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Col, Row } from './layout/index';
import { Loader } from './Icons';
import Icon from './Icon';
import AnimatedUploadIcon from './AnimatedUploadIcon';
import { t } from '../i18n';

const BEFORE = 'before';
const LOADING = 'loading';
const DONE = 'check';
const ERROR = 'error';

const UploadButtonText = ({ loadingState, children, loadingOnlyIcon }) => {
  if (loadingState === BEFORE && children && children?.props?.children?.filter(c => c)?.length !== 0) return children;
  return loadingOnlyIcon ? null : t(loadingState, { scope: 'react.upload_button_text' });
};

UploadButtonText.propTypes = {
  children: PropTypes.any,
  loadingOnlyIcon: PropTypes.bool,
  loadingState: PropTypes.oneOf([BEFORE, LOADING, DONE]),
};

const LoadingButton = ({
  loading,
  children,
  loadingType = 'classic',
  onlyIcon = false,
  error = null,
  disableFixedWidth = false,
  fixContentWidth,
}) => {
  const [loadingState, setLoadingState] = useState(BEFORE);
  const [colWidth, setColWidth] = useState({ width: null, condition: null });

  const col = useRef(null);
  const hasChildren = children && children?.props?.children?.filter(c => c)?.length !== 0;

  useEffect(() => {
    if (loadingType === 'upload') {
      if (col.current && !disableFixedWidth && hasChildren) {
        const width = `${Math.ceil(col.current.getBoundingClientRect().width)}px`;

        setColWidth({
          width,
          condition: lS => lS !== BEFORE,
        });
      }

      if (!hasChildren && !disableFixedWidth) setColWidth({ width: '73px' });

      if (fixContentWidth) setColWidth({ width: fixContentWidth });
    }
  }, []);

  useEffect(() => {
    if (loading) setLoadingState(LOADING);
    if (loadingState === LOADING && !loading) {
      setLoadingState(error ? ERROR : DONE);
      setTimeout(() => setLoadingState(BEFORE), 1500);
    }
  }, [loading]);

  return (
    <Row center>
      {loadingType === 'classic' && (
        <>
          <Col grow>{children}</Col>
          {loading && (
            <Col shrink className="ml-4">
              <Loader size="small" />
            </Col>
          )}
        </>
      )}
      {loadingType === 'upload' && (
        <>
          {!onlyIcon && (
            <Col
              setRef={col}
              center
              width={!colWidth?.condition || colWidth?.condition(loadingState) ? colWidth.width : undefined}
            >
              <UploadButtonText loadingState={loadingState} loadingOnlyIcon={onlyIcon}>
                {children}
              </UploadButtonText>
            </Col>
          )}

          <Col shrink className={cs('AnimatedUpload')}>
            {onlyIcon && loadingState === BEFORE && children}
            {loadingState === LOADING ? (
              <span>
                <AnimatedUploadIcon />
              </span>
            ) : (
              <Icon
                kind={loadingState === DONE || loadingState === ERROR ? loadingState : 'upload'}
                color="inherit"
                className={cs('AnimatedUpload--Icon', {
                  'AnimatedUpload--check': loadingState === DONE || loadingState === ERROR,
                })}
              />
            )}
          </Col>
        </>
      )}
    </Row>
  );
};

LoadingButton.propTypes = {
  disableFixedWidth: PropTypes.bool,
  error: PropTypes.object,
  fixContentWidth: PropTypes.string,
  children: PropTypes.any,
  loading: PropTypes.bool.isRequired,
  loadingType: PropTypes.oneOf(['classic', 'upload']),
  onlyIcon: PropTypes.bool,
};

export default LoadingButton;
