// eslint-disable jsx-a11y/alt-text
import React from 'react';
import Vector from './Vector';
import { DEFAULT_IMAGE_PLACEHOLDER } from '../constants';
import { t } from '../../../../i18n';

export default class Image extends Vector {
  static meta = {
    icon: 'image-upload',
    initial: {
      preserveAspectRatio: 'xMidYMid meet',
      width: 400,
      height: 400,
      flipHorizontally: false,
      flipVertically: false,
      // Just a simple base64-encoded outline
      xlinkHref: DEFAULT_IMAGE_PLACEHOLDER,
    },
  };

  static getDescription = object =>
    t('react.image_generator.default_name.uploaded_image', { filename: object.filename });

  render() {
    const { object } = this.props;
    return (
      <image
        xlinkHref={object.xlinkHref}
        {...this.getObjectAttributes()}
        transform={null}
        style={this.getTranformStyle(object)}
        filename=""
        preserveAspectRatio={object.preserveAspectRatio}
        width={object.width}
        height={object.height}
      />
    );
  }
}
