import React from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';

const TableHeader = ({ columns = [], period, disableFirstEmptyColumn }) => (
  <thead>
    <tr className="Sticky-Header">
      {!disableFirstEmptyColumn && <th width="50px" />}
      {columns.map(column => (
        <th width={column?.width} key={column.path || column.key}>
          {column?.label ? column?.label(period) : null}
        </th>
      ))}
    </tr>
  </thead>
);

TableHeader.propTypes = {
  columns: PropTypes.array.isRequired,
  period: PropTypes.string,
  disableFirstEmptyColumn: PropTypes.bool,
};

export default hot(TableHeader);
