import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

export const ShowOnHoverWrapper = ({ children, className, ...rest }) => (
  <div className={cs('ShowOnHoverWrapper', className)} {...rest}>
    {children}
  </div>
);
ShowOnHoverWrapper.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export const ShowOnHoverElement = ({ children, className, show = false, ...rest }) => (
  <div className={cs('ShowOnHoverElement', className, { 'ShowOnHoverElement--show': show })} {...rest}>
    {children}
  </div>
);
ShowOnHoverElement.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  show: PropTypes.bool,
};
