import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Cell } from './mapping/cells';
import { useMultiEdit } from './MultiEditContext';
import { Table, Row, Header } from './components';

const resolveHeader = header => (typeof header === 'function' ? header() : header);

const DisplayTable = ({
  additionalData,
  allowSelect,
  canEdit,
  data,
  mapping,
  organizationId,
  dataSourceId,
  statusCampaignSwitchWarning,
}) => {
  const {
    state: { selected },
  } = useMultiEdit();

  return (
    <div className="ph-0">
      <Table oddStriped>
        <Header>
          {mapping.map((column, index) => (
            <Cell
              order={column.order}
              reverseOrder={column.reverseOrder}
              orderedByDescFirst={column.orderedByDescFirst}
              key={column.id}
              id={column.id}
              cellCouldBeEmpty={column?.cellCouldBeEmpty}
              smallPadding={column?.smallPadding}
              width={column.width}
              useEllipsis={column.useEllipsis}
              withCheckbox={(allowSelect && column.id === 'name') || index === 0}
              isHeader
            >
              {resolveHeader(column.header)}
            </Cell>
          ))}
        </Header>
        {data.map(rowData => {
          const selectedRow = selected.indexOf(rowData.id) !== -1;

          return (
            <Row
              key={rowData.id}
              className={cs({
                'Sticky-Row--selected Sticky-Row--selectedBorder': selectedRow,
              })}
            >
              {mapping.map(column => (
                <column.cell
                  key={column.id}
                  id={column.id}
                  width={column.width}
                  canEdit={canEdit}
                  cellCouldBeEmpty={column?.cellCouldBeEmpty}
                  smallPadding={column?.smallPadding}
                  editable={column.editQuery && !!column.editQuery(rowData) && column.editable}
                  onUpdate={column.onUpdate && column.onUpdate(rowData)}
                  editButtonAsProp={column.editButtonAsProp}
                  editQuery={column.editQuery && column.editQuery(rowData)}
                  columnHeader={column.header}
                  organizationId={organizationId}
                  dataSourceId={dataSourceId}
                  rowData={rowData}
                  additionalData={additionalData}
                  statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                >
                  {column.resolveChildren({ ...rowData, organizationId, dataSourceId })}
                </column.cell>
              ))}
            </Row>
          );
        })}
      </Table>
    </div>
  );
};

DisplayTable.propTypes = {
  allowSelect: PropTypes.bool,
  canEdit: PropTypes.bool,
  data: PropTypes.array,
  additionalData: PropTypes.any,
  mapping: PropTypes.array,
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
};

export default DisplayTable;
