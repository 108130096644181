import React from 'react';
import RPT from 'prop-types';
import cs from 'classnames';

const ErrorBox = ({ withIcon, className, children, isComplex, ...rest }) => (
  <div
    data-test-id="error-box-error"
    className={cs('InfoBox', 'InfoBox--error', className, { 'InfoBox--complex': isComplex })}
    {...rest}
  >
    <div className={cs('InfoBox-content', { 'InfoBox-content--withIcon': withIcon })}>{children}</div>
  </div>
);

ErrorBox.propTypes = {
  children: RPT.any,
  className: RPT.string,
  isComplex: RPT.bool,
  withIcon: RPT.bool,
};

export default ErrorBox;
