import React from 'react';
import { Query } from '@apollo/client/react/components';
import ErrorLoadingQuote from '../components/ErrorLoadingQuote';

// eslint-disable-next-line react/prop-types
const QueryHolder = WrappedComponent => ({ query, showChildrenWhileLoading, variables, ...rest }) => (
  <Query query={query} variables={variables}>
    {({ loading, error, data, refetch }) => {
      if (loading) {
        return (
          <div className="Preview-item">
            <div
              className="loading-status Loader Loader--small h-auto w-auto"
              style={{
                left: rest.depth ? `${rest.depth * 30 + 15}px` : 0,
                top: 0,
              }}
            >
              {window.reacti18n.pla_campaign_preview.loading}
            </div>
            {showChildrenWhileLoading && (
              <WrappedComponent data={data} refetch={() => refetch()} {...variables} {...rest} />
            )}
          </div>
        );
      }
      if (error) {
        return (
          <div className="Preview-item">
            <ErrorLoadingQuote onClick={refetch} />
          </div>
        );
      }

      return <WrappedComponent data={data} refetch={() => refetch()} {...variables} {...rest} />;
    }}
  </Query>
);
export default QueryHolder;
