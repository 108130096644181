import React from 'react';
import Vector from './Vector';

export default class Circle extends Vector {
  static meta = {
    icon: 'circle',
    useIconInLayers: true,
    initial: {
      width: 300,
      height: 300,
      rotate: 0,
      fill: 'yellow',
      strokeWidth: 0,
      blendMode: 'normal',
      'data-layer-name': 'Circle',
    },
  };

  static getDescription = () => 'Circle';

  getStyle() {
    const { object } = this.props;

    return {
      fill: object.fill.length === 0 ? 'transparent' : object.fill,
    };
  }

  render() {
    const { object } = this.props;
    return (
      <ellipse
        style={this.getStyle()}
        {...this.getObjectAttributes()}
        rx={object.width / 2}
        ry={object.height / 2}
        cx={object.x + object.width / 2}
        cy={object.y + object.height / 2}
      />
    );
  }
}
