import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { QueryHolder } from '../components/QueryHolder';
import { t, tHtml } from '../i18n';
import { SectionHeading, SectionInfo, Text } from '../components/Typography';
import { Modal, ModalBody } from '../components/Modal';
import { Row, Col } from '../components/layout';
import Input from '../components/form/Input';
import Link from '../components/Link';
import Button from '../components/Button';

const REGEXP_TEMPLATES_QUERY = gql`
  query RegexpTemplatesQuery {
    regexpTemplates {
      id
      description
      regexp
    }
  }
`;

const RegexpTemplateModal = ({ data, onSelect }) => {
  if (data && data.regexpTemplates) {
    return (
      <Row padding="L" flexwrap className="mt-16">
        {data.regexpTemplates.map(({ id, description, regexp }) => (
          <Col width="48%" key={id} className="mb-8">
            <Row width="100%" center>
              <Col>
                <Text>{description}</Text>
              </Col>
              <Col>
                <Input disabled value={regexp} />
              </Col>
              <Col shrink>
                <Button icon="plus" onlyIcon onClick={() => onSelect(regexp)} />
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  }
  return undefined;
};
RegexpTemplateModal.propTypes = {
  data: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
};

const QueryRegexpTemplateModal = QueryHolder(RegexpTemplateModal);

const RegexpTemplateContainer = ({ addNewField, options }) => {
  const [showModal, setModalVisibility] = useState(false);
  if (options.disableHeading) {
    return null;
  }
  return (
    <Row baseline>
      <Col shrink>
        <SectionHeading spacing={8}>{options.customHeading || t('keyword_settings.replaces')}</SectionHeading>
      </Col>
      <Col shrink>
        <Link data-test-id="open-regexp-examples-modal" onClick={() => setModalVisibility(true)}>
          {t('views.shared.regexp_examples_link.regexp_examples')}
        </Link>
      </Col>
      {showModal && (
        <Modal
          heading={t('views.shared.regexp_templates_modal.regexp_templates_title')}
          onClose={() => setModalVisibility(false)}
        >
          <ModalBody>
            <SectionInfo>
              {tHtml('views.shared.regexp_templates_modal.regexp_template_modal_text_html', {
                default:
                  'Regular expressions allow to replace text by regexp template text. More you can learn on <a href="https://www.dotidot.io/blog/articles/127-regular-expressions-hidden-in-the-templates" target="_blank">In our blog post</a>.',
              })}
            </SectionInfo>
            <QueryRegexpTemplateModal
              query={REGEXP_TEMPLATES_QUERY}
              onSelect={regexp => {
                addNewField({ what: regexp, replace_type: 'regexp' });
                setModalVisibility(false);
              }}
            />
          </ModalBody>
        </Modal>
      )}
    </Row>
  );
};
RegexpTemplateContainer.propTypes = {
  addNewField: PropTypes.func.isRequired,
  options: PropTypes.object,
};

export default RegexpTemplateContainer;
