import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Button,
  Col,
  Ellipsis,
  Checkbox,
  Icon,
  Row,
  SmallHeading,
  ExtraSmallHeading,
  StateContent,
  Tile,
  InfoBox,
} from './index';
import { Modal, ModalBody, ModalHeader, ModalHeading } from './Modal';
import { t } from '../i18n';
import Searchbox from '../organizations_dropdown/Searchbox';
import { CreateElementTiles } from './CreateElementButton';

export const CreateModalBySelectContent = ({
  items,
  type,
  backArrowFunc,
  forceSelectedItem,
  parentKind,
  preSelectedItem = null,
}) => {
  const [selectedItem, setselectedItem] = useState(preSelectedItem);
  const [search, setSearch] = useState('');
  const [activeOnly, setActiveOnly] = useState(false);
  const hasBackArrowFunc = typeof backArrowFunc === 'function';

  const filtredItems = items
    .filter(({ tiles }) => tiles.length > 0)
    .filter(({ state }) => !activeOnly || state === 'active')
    .filter(({ name }) => !search || name.toLowerCase().includes(search.toLowerCase()));

  const hasStateProp = items.some(item => typeof item.state !== 'undefined');

  return (
    <React.Fragment>
      <ModalHeader>
        <Row center>
          {!preSelectedItem && (selectedItem || hasBackArrowFunc) && (
            <Button
              onlyIcon
              icon="arrow-left"
              size="small"
              className="mr-8"
              onClick={hasBackArrowFunc ? backArrowFunc : () => setselectedItem(null)}
              kind="tertiary"
            />
          )}
          <ModalHeading>
            {selectedItem || forceSelectedItem
              ? t(`create_${type}.heading_with_item`, {
                  name: forceSelectedItem ? forceSelectedItem.heading : selectedItem.name,
                  scope: 'react.create_element_modal',
                })
              : t(`create_${type}.heading`, { scope: 'react.create_element_modal' })}
          </ModalHeading>
        </Row>
      </ModalHeader>
      <ModalBody>
        <Row justifyCenter style={{ minHeight: '400px' }} top>
          {!selectedItem && (
            <Col width="480px">
              {hasStateProp && (
                <Row center className="mb-16 mt-4">
                  <Col grow>
                    <SmallHeading spacing={0}>
                      {t(`create_${type}.subheading`, { scope: 'react.create_element_modal' })}
                    </SmallHeading>
                  </Col>
                  <Col shrink>
                    <Checkbox
                      label={t('active_only', { scope: 'react.create_element_modal' })}
                      name="campaigns-modal[active-only]"
                      doNotUseInternalState
                      checked={activeOnly}
                      onChange={({ target: { checked } }) => setActiveOnly(checked)}
                    />
                  </Col>
                </Row>
              )}
              <Row className="mb-16">
                <Searchbox
                  searchBoxText="Search"
                  searchValue={search}
                  handleSearch={({ target: { value } }) => setSearch(value)}
                  hideReset
                />
              </Row>
              {filtredItems.length === 0 && (
                <InfoBox type="info" withIcon>
                  {t('react.create_element_modal.no_results')}
                </InfoBox>
              )}
              {filtredItems.map(item => (
                <Row key={JSON.stringify(item)}>
                  <Tile
                    hoverable
                    ctaLink={item.tiles && item.tiles.length === 1 ? item.tiles[0].url : null}
                    onClick={item.tiles && item.tiles.length > 1 ? () => setselectedItem(item) : null}
                    dataTestId={item.data_test_id}
                    addResource={item.tiles && item.tiles.length === 1 ? item.tiles[0].addResource : null}
                  >
                    <Row center padding="l">
                      <Col shrink>
                        <Icon size="24px" kind={item.icon} colored className={item.icon} inheritColor />
                      </Col>
                      <Col grow>
                        <Row className={classNames({ 'mb-0': item.description })}>
                          <Ellipsis>
                            <ExtraSmallHeading spacing={0}>{item.name}</ExtraSmallHeading>
                          </Ellipsis>
                        </Row>
                        {item.description && <Row className="Tile-description">{item.description}</Row>}
                      </Col>
                      {hasStateProp && (
                        <Col shrink>
                          <StateContent>{{ state: item.state, kind: parentKind }}</StateContent>
                        </Col>
                      )}
                    </Row>
                  </Tile>
                </Row>
              ))}
            </Col>
          )}
          {selectedItem && (
            <Col width="480px" noPadding>
              <Row className="mt-4">
                <SmallHeading spacing={16}>
                  {t(`create_${type}.type`, { scope: 'react.create_element_modal' })}
                </SmallHeading>
              </Row>
              <Row>
                <Col width="480px">
                  <CreateElementTiles className="ml-24" tiles={selectedItem.tiles} />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </ModalBody>
    </React.Fragment>
  );
};

CreateModalBySelectContent.propTypes = {
  backArrowFunc: PropTypes.func,
  forceSelectedItem: PropTypes.object,
  items: PropTypes.array,
  preSelectedItem: PropTypes.object,
  type: PropTypes.string,
  parentKind: PropTypes.string,
};

export const CreateModalBySelect = ({ items, onClose, ...rest }) => (
  <Modal
    onClose={onClose}
    size="medium"
    error={items.length === 0 ? t('react.tables.adtexts.modal_can_not_be_shown') : null}
  >
    <CreateModalBySelectContent items={items} {...rest} />
  </Modal>
);

CreateModalBySelect.propTypes = {
  items: PropTypes.array,
  onClose: PropTypes.func,
};
