import React from 'react';
import PropTypes from 'prop-types';
import { sortableHandle } from 'react-sortable-hoc';
import cs from 'classnames';

const DragHandle = sortableHandle(({ disabled, disabledShow }) => (
  <span
    data-test-id="drag-handle"
    className={cs('DragHandle', {
      'DragHandle--lock': disabled,
      'DragHandle--disabledEye': disabledShow,
    })}
  />
));

DragHandle.propTypes = {
  disabled: PropTypes.bool,
  disabledShow: PropTypes.bool,
};
export default DragHandle;
