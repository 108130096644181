import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import {
  LinkModalCell,
  IconLinkCell,
  MoneyCell,
  IntegerCell,
  IconModalCell,
  HtmlCell,
  TextCell,
  StatusSwitchCell,
} from './cells';
import { LinkEdit, MoneyEdit } from './editable';
import conditions from './conditions';
import { t } from '../../../i18n';

const mapping = notify => ({
  ...setupColumn({
    id: 'name',
    graphqlFields: ['name', 'editLink'],
    // order: 'name',
    required: true,
    cell: LinkModalCell,
    editable: LinkEdit,
    width: '300px',
    editQuery: ({ id }) => ({ text }) =>
      gql`mutation { updateFbBudget(id: ${id}, name: "${text}") { fbBudget { id name } errors } }`,
    header: () => t('activerecord.attributes.fb_budget.name'),
    resolveChildren: ({ name, editLink }) => ({ text: name, link: editLink, bold: true, size: 'fullwithGrid' }),
    fixed: 'left',
  }),
  ...setupColumn({
    id: 'period',
    // order: 'period',
    required: true,
    cell: TextCell,
  }),
  ...setupColumn({
    id: 'budget',
    graphqlFields: ['fbCurrency', 'budget'],
    // order: 'budget',
    width: '120px',
    resolveChildren: data =>
      (data && {
        id: data.id,
        currency: data.fbCurrency,
        money: data.budget,
      }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    header: () => t('activerecord.attributes.fb_budget.budget'),
    editQuery: data =>
      data &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateFbBudget(id: ${id || -1}, budget: ${money || 0}) {
            fbBudget {
              id
              budget
            }
            errors
          }
        }
      `;
      }),
  }),
  ...setupColumn({
    id: 'numberOfProducts',
    cell: IntegerCell,
    // order: 'number_of_products',
    required: true,
    header: () => t('activerecord.attributes.fb_budget.matching_products'),
  }),
  ...setupColumn({
    id: 'timeRestriction',
    graphqlFields: { timeRestriction: ['text'] },
    cell: HtmlCell,
    required: true,
    header: () => t('activerecord.attributes.fb_budget.time_restrictions'),
    resolveChildren: ({ timeRestriction }) => timeRestriction?.text || '',
  }),
  ...setupColumn({
    id: 'enabled',
    graphqlFields: ['status', 'timeRestrictionStatus'],
    cell: StatusSwitchCell,
    header: () => t('activerecord.attributes.fb_budget.active'),
    resolveChildren: ({ status, timeRestrictionStatus }) => ({ enabled: status === 'running', timeRestrictionStatus }),
    editQuery: ({ id }) => ({ enabled }) =>
      gql`mutation { updateFbBudget(id: ${id}, status: "${
        enabled ? 'running' : 'paused'
      }") { fbBudget { id status timeRestrictionStatus } errors } }`,
  }),
  ...setupColumn({
    id: 'editLink',
    graphqlFields: ['name', 'editLink'],
    cell: IconModalCell,
    required: true,
    header: ' ',
    width: '32px',
    resolveChildren: ({ name, editLink }) => ({
      link: editLink,
      size: 'fullwithGrid',
      notify,
      'data-test-id': 'edit-fbBudget-button',
      tooltip: t('views.campaign_settings.index.edit'),
      'data-test-id-edit': name,
      icon: 'edit',
    }),
  }),

  ...setupColumn({
    id: 'duplicateLink',
    graphqlFields: ['name', 'duplicateLink'],
    cell: IconModalCell,
    required: true,
    header: ' ',
    width: '32px',
    resolveChildren: ({ name, duplicateLink }) => ({
      link: duplicateLink,
      size: 'fullwithGrid',
      notify,
      tooltip: t('views.campaign_settings.index.duplicate'),
      'data-test-id-duplicate': name,
      icon: 'duplicate',
    }),
  }),
  ...setupColumn({
    id: 'deleteLink',
    graphqlFields: ['name', 'deleteLink'],
    cell: IconLinkCell,
    required: true,
    header: ' ',
    width: '32px',
    resolveChildren: ({ name, deleteLink }) => ({
      link: deleteLink,
      tooltip: t('views.campaign_settings.index.delete'),
      icon: 'trash',
      'data-remote': 'true',
      'data-method': 'delete',
      'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
      'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
      'data-container': 'body',
      'data-test-id-delete': name,
      rel: 'nofollow',
    }),
  }),
  ...conditions,
});

export default mapping;
