import React from 'react';
import PropTypes from 'prop-types';
import { useMultiEdit } from '../../MultiEditContext';
import ColumnsEdit from './ColumnsEdit';
import SwapVariables from './SwapVariables';
import FindReplace from './FindReplace';
import ChangeTimeRestriction from './ChangeTimeRestriction';

const EditColumns = props => {
  // prettier-ignore
  const { state: { multiEdit } } = useMultiEdit();

  if (props.selectedColumn.value === 'change_variable') return <SwapVariables {...props} />;
  if (props.selectedColumn.value === 'find_and_replace') return <FindReplace {...props} />;
  if (props.selectedColumn.value === 'time_restriction') return <ChangeTimeRestriction {...props} />;
  if (!multiEdit.changeVariable && !multiEdit.columnsEdit && !multiEdit.timeRestriction && !multiEdit.findAndReplace) {
    return null;
  }

  return <ColumnsEdit {...props} />;
};

EditColumns.propTypes = { selectedColumn: PropTypes.object };

export default EditColumns;
