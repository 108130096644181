import React, { useContext, memo } from 'react';
import { hot } from 'react-hot-loader/root';
import { gql, useQuery } from '@apollo/client';
import { PropTypes } from 'prop-types';
import { ErrorChartComponent, NoDataChartComponent, ChartComponent } from './ChartVariants';
import { SubscriptionContext } from './Subscription';
import { createDateRange } from '../../dashboard/utils';
import { formatNumber, formatCurrency, t } from '../../i18n';

const SHOW_NEXT_TIER_LIMIT = 0.8;

const GET_FEATURE_METRICS = gql`
  query GetFeatureMetrics($feature: FeatureName!, $organizationId: BigInt!, $periodId: BigInt!) {
    organization(id: $organizationId) {
      id
      name
      subscriptionFeatureGraph(id: $periodId, feature: $feature) {
        id
        date
        value
      }
    }
  }
`;
const createRange = period => {
  const { beginsOn, endsOn } = period;
  return createDateRange(beginsOn, endsOn, 1);
};

const getChartData = ({ isSpend, usageData, format, range, filterRange }) => {
  let newUsageData = usageData;
  if (isSpend) {
    newUsageData = usageData.reduce((acc, curr) => {
      const newValue = acc?.length > 0 ? acc[acc.length - 1].value + curr.value : curr.value;
      return [...acc, { ...curr, value: newValue, formattedValue: format(newValue) }];
    }, []);
  }

  return range.map(date => {
    const foundObject = (newUsageData || []).find(d => d.date === date) || {};
    const formattedValue = (foundObject && foundObject?.formattedValue) || format(foundObject?.value);

    if (filterRange && filterRange.indexOf(date) === -1) {
      return {
        formattedValue,
        date,
      };
    }
    return {
      ...foundObject,
      formattedValue,
      date,
    };
  });
};

const getTypeVariables = ({ item, isSpend }) => {
  const featurePricing = item?.featuresPricing || [];
  const feature = item?.feature;
  const pricing =
    feature === 'synchronizations' ? featurePricing.filter(({ syncCount }) => syncCount === 8) : featurePricing;

  if (isSpend) {
    const limit = item?.spendLimitFloat;
    const currentValue = item?.spendFloat;
    return {
      pricingKey: 'spendFloat',
      find: ({ spendFloat }) => spendFloat === limit,
      ratio: currentValue / limit,
      pricing,
    };
  }
  const limit = item?.usageLimit;
  const currentValue = item?.usageCount;
  return {
    pricingKey: 'count',
    find: ({ count }) => count === limit,
    ratio: currentValue / limit,
    pricing,
  };
};

const getLimits = args => {
  const { find, ratio, pricingKey, pricing } = getTypeVariables(args);

  const hideNextTier = ratio < SHOW_NEXT_TIER_LIMIT;
  const max = pricing?.length - 1;

  const currentIndex = pricing.findIndex(find);
  const lowerIndex = currentIndex > 0 ? currentIndex - 1 : null;
  const nextIndex = currentIndex < max ? currentIndex + 1 : null;

  return [
    { value: pricing?.[lowerIndex]?.[pricingKey], label: t('lower', { scope: 'feature_period.tiers' }) },
    { value: pricing?.[currentIndex]?.[pricingKey], label: t('current', { scope: 'feature_period.tiers' }) },
    { value: !hideNextTier && pricing?.[nextIndex]?.[pricingKey], label: t('next', { scope: 'feature_period.tiers' }) },
  ];
};

const formatCreator = (currency, isSpend) => val =>
  isSpend
    ? formatCurrency(val, { precision: 2, round_strategy: 'ceil', unit: currency })
    : formatNumber(val, { precision: 0 });

const getEmptyData = range => range.map(x => ({ date: x, spend: Math.random() * 10 }));

const FeatureChart = ({ item, scatteredLabels }) => {
  const featureEnum = item.feature === 'synchronizations_and_support' ? 'synchronizations' : item.feature;

  if (featureEnum === 'image_generator_feed_export') return null;
  const { period } = useContext(SubscriptionContext);
  const { organizationId } = item;
  const range = createRange(period);
  const { resolvedBy } = item.featuresPricing[0];
  const isSpend = resolvedBy === 'spend';
  const format = formatCreator(item?.priceCurrency, isSpend);

  const { data, loading, error } = useQuery(GET_FEATURE_METRICS, {
    variables: { feature: featureEnum, periodId: period.id, organizationId },
  });

  if (error) return <ErrorChartComponent isSpend={isSpend} />;

  const emptyData = getEmptyData(range);
  const usageData = data?.organization?.subscriptionFeatureGraph;
  const isEmpty = !usageData || usageData?.length === 0;

  if (loading || isEmpty || !item.featurePeriod) {
    return (
      <NoDataChartComponent period={period} isSpend={isSpend} height={220} data={emptyData} dataLoading={loading} />
    );
  }

  const referenceLines = getLimits({ item, isSpend });
  const featureRange = createRange(item.featurePeriod);
  const chartData = getChartData({ isSpend, usageData, format, range, filterRange: featureRange });

  return (
    <ChartComponent
      period={period}
      isSpend={isSpend}
      height={220}
      referenceLines={referenceLines}
      data={chartData}
      format={format}
      scatteredLabels={scatteredLabels}
    />
  );
};
FeatureChart.propTypes = {
  item: PropTypes.object,
  scatteredLabels: PropTypes.bool,
};

export default hot(memo(FeatureChart));
