const filterChilds = (elements, allElements, searchText) => (id) => {
  const el = elements[id];
  const search = searchText.trim().toLowerCase();
  return el && (allElements || el.activeForGeneration) && (search.length === 0 || el.search.toLowerCase().indexOf(search) !== -1);
};

const sortAndFilterElements = (elements, allElements = true, searchText = '') => {
  const filterChildsFunc = filterChilds(elements, allElements, searchText);
  const acc = [];
  const parent = 0;
  const childs = Object
    .keys(elements)
    .filter(id => elements[id].parent.toString() === parent.toString());

  const sortedChilds = childs.sort((a, b) => (elements[a].index - elements[b].index));

  return sortedChilds
    .reduce((subacc, id) => subacc.concat([sortAndFilterElementsChilds(elements, filterChildsFunc, id, filterChildsFunc(id) ? [id] : [])]), acc)
    .filter(a => a.length > 0);
};

const sortAndFilterElementsChilds = (elements, filterChildsFunc, parent = 0, acc = []) => {
  const childs = Object
    .keys(elements)
    .filter(id => elements[id].parent.toString() === parent.toString());

  const sortedChilds = parent === 0 ? childs.sort((a, b) => (elements[a].index - elements[b].index)) : childs;

  if (childs.length === 0) {
    if (acc.length === 0) {
      return [];
    }
    return acc.concat(parseInt(parent.toString(), 10) * -1);
  }

  return sortedChilds
    .reduce((subacc, id) => sortAndFilterElementsChilds(elements, filterChildsFunc, id, filterChildsFunc(id) ? subacc.concat(id) : subacc), acc);
};

export default sortAndFilterElements;
