/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

document.addEventListener('DOMContentLoaded', () => {
  const inputs = $('.js-hasText');

  if (inputs.length > 0) {
    inputs.each(function handleAdaptivePlaceholder() {
      if ($(this).val() && $(this).val() !== '') {
        $(this).siblings('.js-label').removeClass('active');
      } else {
        $(this).siblings('.js-label').addClass('active');
      }
    });

    inputs.on('focus', function handleAdaptivePlaceholderFocus() {
      $(this).siblings('.js-label').removeClass('active');
    });

    inputs.on('blur', function handleAdaptivePlaceholderBlur() {
      if ($(this).val() === '') {
        $(this).siblings('.js-label').addClass('active');
      }
    });

    inputs.on('keyup', (event) => {
      if (event.target.value && event.target.value.length !== 0) {
        $(event.targe).siblings('.js-label').removeClass('active');
      } else {
        $(event.targe).siblings('.js-label').addClass('active');
      }
    });
  }
});
