import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import { LinkModalCell, IconLinkCell, IntegerCell, IconModalCell, TextCell, StatusSwitchCell } from './cells';
import { LinkEdit } from './editable';
import conditions from './conditions';
import { t } from '../../../i18n';

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: ['name', 'editLink'],
    // order: 'name',
    required: true,
    cell: LinkModalCell,
    editable: LinkEdit,
    width: '300px',
    editQuery: ({ id }) => ({ text }) =>
      gql`mutation { updateFbPlacement(id: ${id}, name: "${text}") { fbPlacement { id name } errors } }`,
    header: () => t('activerecord.attributes.fb_placement.name'),
    resolveChildren: ({ name, editLink }) => ({ text: name, link: editLink, bold: true, size: 'fullwithGrid' }),
    fixed: 'left',
  }),
  ...setupColumn({
    id: 'description',
    required: true,
    header: () => t('activerecord.attributes.fb_placement.description'),
    cell: TextCell,
  }),
  ...setupColumn({
    id: 'numberOfProducts',
    cell: IntegerCell,
    // order: 'number_of_products',
    required: true,
    header: () => t('activerecord.attributes.fb_placement.matching_products'),
  }),
  ...setupColumn({
    id: 'enabled',
    graphqlFields: ['status'],
    cell: StatusSwitchCell,
    header: () => t('activerecord.attributes.fb_placement.active'),
    resolveChildren: ({ status }) => ({ enabled: status === 'running' }),
    editQuery: ({ id }) => ({ enabled }) =>
      gql`mutation { updateFbPlacement(id: ${id}, status: ${
        enabled ? 'running' : 'paused'
      }) { fbPlacement { id status } errors } }`,
  }),
  ...setupColumn({
    id: 'editLink',
    graphqlFields: ['name', 'editLink'],
    cell: IconModalCell,
    required: true,
    header: ' ',
    width: '32px',
    resolveChildren: ({ name, editLink }) => ({
      link: editLink,
      size: 'fullwithGrid',
      'data-test-id': 'edit-fbBudget-button',
      tooltip: t('views.campaign_settings.index.edit'),
      notify: 'fb_placements',
      'data-test-id-edit': name,
      icon: 'edit',
    }),
  }),

  ...setupColumn({
    id: 'duplicateLink',
    graphqlFields: ['name', 'duplicateLink'],
    cell: IconModalCell,
    required: true,
    header: ' ',
    width: '32px',
    resolveChildren: ({ name, duplicateLink }) => ({
      link: duplicateLink,
      size: 'fullwithGrid',
      notify: 'fb_placements',
      tooltip: t('views.campaign_settings.index.duplicate'),
      'data-test-id-duplicate': name,
      icon: 'duplicate',
    }),
  }),
  ...setupColumn({
    id: 'deleteLink',
    graphqlFields: ['name', 'deleteLink'],
    cell: IconLinkCell,
    required: true,
    header: ' ',
    width: '32px',
    resolveChildren: ({ name, deleteLink }) => ({
      link: deleteLink,
      tooltip: t('views.campaign_settings.index.delete'),
      icon: 'trash',
      'data-method': 'delete',
      'data-remote': 'true',
      'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
      'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
      'data-container': 'body',
      'data-test-id-delete': name,
      rel: 'nofollow',
    }),
  }),
  ...conditions,
};

export default mapping;
