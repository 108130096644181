import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { modalDataUrlOpener } from './RemoteModal';
import Button from './Button';
import useInputState from './useInputState';

const DEFAULT = 'DEFAULT';

const EditElementFromSelect = ({ url, formId, selectName, className }) => {
  const { state } = useInputState({ name: selectName, defaultValue: DEFAULT, formID: formId });
  if (state === DEFAULT || state === '') {
    return null;
  }
  return (
    <Button
      className={className}
      icon="edit"
      secondary
      onlyIcon
      {...modalDataUrlOpener({ url: url.replace('IDIDID', state), size: 'fullwithGrid' })}
    />
  );
};

EditElementFromSelect.propTypes = {
  url: PropTypes.string,
  selectName: PropTypes.string,
  formId: PropTypes.string,
  className: PropTypes.string,
};

export default hot(EditElementFromSelect);
