import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { modalDataUrlOpener, listenOnModalChange, ColorLabel, DropdownMenu, Link, Button, Row, Col } from '../index';
import { t } from '../../i18n';

export default class LabelsSelect extends PureComponent {
  static propTypes = {
    doNotUseInternalState: PropTypes.bool,
    createLabelLink: PropTypes.string,
    className: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    collection: PropTypes.array,
    onChange: PropTypes.func,
    buttonText: PropTypes.string,
    value: PropTypes.any.isRequired,
    'data-test-id': PropTypes.string,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    value: [],
  };

  state = {
    value: JSON.parse(this.props.value || '[]'),
    additionalCollection: [],
  };

  componentDidMount() {
    this.refreshOnUpdateListener = listenOnModalChange(this.addLabelOnCreate);
    this.mounted = true;
  }

  componentWillUnmount() {
    if (this.refreshOnUpdateListener) {
      this.refreshOnUpdateListener();
    }
    this.mounted = false;
  }

  addLabelOnCreate = (notifyLabel, response) => {
    if (notifyLabel === 'labels_table') {
      this.setState({
        additionalCollection: this.state.additionalCollection.concat([
          [response.id, response.name, { color: response.color }],
        ]),
      });
      setTimeout(() => {
        this.handleChange(response.id);
      }, 50);
    }
  };

  handleChange = clickedId => {
    const alreadySelected = this.props.doNotUseInternalState ? JSON.parse(this.props.value || '[]') : this.state.value;

    const newValue =
      alreadySelected.indexOf(clickedId) === -1
        ? alreadySelected.concat(clickedId)
        : alreadySelected.filter(id => id !== clickedId);

    this.setState({ value: newValue });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: JSON.stringify(newValue) } });
    }
  };

  render() {
    const {
      createLabelLink,
      className,
      collection,
      value,
      name,
      disabled,
      doNotUseInternalState,
      buttonText,
    } = this.props;

    const collectionWithNewlyCreated = collection.concat(this.state.additionalCollection);
    const selected = doNotUseInternalState ? JSON.parse(value || '[]') : this.state.value;

    const newLink = createLabelLink
      ? modalDataUrlOpener({ url: createLabelLink, notify: 'labels_table', heading: 'New label' })
      : null;

    return (
      <Row className={className}>
        <input type="hidden" name={`${name}[]`} value="" />
        {selected.map(v => (
          <input type="hidden" key={v} name={`${name}[]`} value={v} />
        ))}

        <Col shrink>
          {newLink && collectionWithNewlyCreated.length === selected.length ? (
            <Button type="button" onlyIcon={!buttonText} icon="tag" tertiary {...newLink} disabled={disabled}>
              {buttonText}
            </Button>
          ) : (
            <DropdownMenu icon="tag" data-test-id="labels-selection" disabled={disabled} placement="bottom-end">
              <div>
                <ul className="Navbar-list" style={{}}>
                  {collectionWithNewlyCreated.map(([colVal, label, { color } = {}]) =>
                    selected.indexOf(colVal) === -1 ? (
                      <li className="mv-4" key={[colVal, label].join('-')}>
                        <Link
                          className="Navigation-item"
                          key={colVal}
                          onClick={() => !disabled && this.handleChange(colVal)}
                        >
                          <ColorLabel color={color} name={label} />
                        </Link>
                      </li>
                    ) : null
                  )}
                </ul>

                <div className="Navbar-dropdownItem Navbar-dropdownItem--footer negative-mh-6">
                  {newLink && (
                    <Link icon="plus" {...newLink}>
                      {t('react.labels.new')}
                    </Link>
                  )}
                </div>
              </div>
            </DropdownMenu>
          )}
        </Col>
        <Col grow>
          <Row flexwrap className="mt-4">
            {selected.map(v => {
              const el = collectionWithNewlyCreated.find(c => c[0] === v);
              if (el) {
                return (
                  <Col shrink key={v}>
                    <ColorLabel onClick={() => !disabled && this.handleChange(v)} color={el[2].color} name={el[1]} />
                  </Col>
                );
              }
              return null;
            })}
          </Row>
        </Col>
      </Row>
    );
  }
}
