import React from 'react';
import PropTypes from 'prop-types';
import { formatMetricValue } from './functions';
import { t } from '../../i18n';
import { Text, Row, Ellipsis } from '../index';

const WidgetPieChartLegend = ({ payload, metricName }) => (
  <div>
    {payload &&
      payload?.length > 0 &&
      payload.map(entry => (
        <Text key={entry.payload.adPlacement} bold color="gray" className="d-block mt-16 mb-24 pl-16">
          <Row>
            <div
              className="mr-8"
              style={{ background: entry.color, width: '4px', height: '16px', borderRadius: '100px' }}
            />
            <div className="pr-16" style={{ width: '80px' }}>
              <Ellipsis>
                {t(entry.payload.adPlacement.toLowerCase(), {
                  scope: 'react.report_table.chart_legend',
                  default: entry.payload.adPlacement,
                })}
              </Ellipsis>
            </div>
            <div className="pr-16" style={{ width: '80px' }}>
              {formatMetricValue(entry.payload[metricName] || '')}
            </div>
          </Row>
        </Text>
      ))}
  </div>
);

WidgetPieChartLegend.propTypes = {
  payload: PropTypes.any,
  metricName: PropTypes.string,
};

export default WidgetPieChartLegend;
