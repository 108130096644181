import { createAdtextFieldText, createAdtextFieldOverflow } from './createAdtextField';

const creator = createAdtextFieldText('ad_dsa_adtext');
const creatorOverflow = createAdtextFieldOverflow('ad_dsa_adtext');

const mapping = {
  ...creator({ field: 'dsaDescription1', updateField: 'description' }),
  ...creator({ field: 'dsaDescription2', updateField: 'description2' }),
  ...creatorOverflow({ field: 'dsaOverflowDescription1', updateField: 'overflowDescription' }),
  ...creatorOverflow({ field: 'dsaOverflowDescription2', updateField: 'overflowDescription2' }),
};

export default mapping;
