import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { hot } from 'react-hot-loader/root';
import uuid from 'uuid';
import { Row, Col, Button, Input, ModalBody, Modal, SectionHeading, ModalFooter } from '../../index';
import Preview from './Preview';
import objectTypes from './objectTypes';
import { buildUrl } from './panels/ImagePlaceholderPanel';
import { t } from '../../../i18n';

const CREATE_SNIPPET_MUTATION = gql`
  mutation CreateImageModifier(
    $dataSourceId: BigInt!
    $name: String!
    $width: String!
    $height: String!
    $objects: String!
  ) {
    createImageModifier(dataSourceId: $dataSourceId, name: $name, width: $width, height: $height, objects: $objects) {
      modifier {
        name
        placeholderName
        editLink
      }
      errors
    }
  }
`;

const PreviewSnippetModal = ({
  closeModal,
  handler,
  snippetObjects = [],
  dataSourceId,
  getUpdatedCustomImages,
  onDesignerUpdate,
  originalObjects,
  selectedObjectsIndexes,
  selectObjects,
}) => {
  const [snippetName, setSnippetName] = useState('');
  const [error, setError] = useState(null);

  const [createSnippet, { loading }] = useMutation(CREATE_SNIPPET_MUTATION, {
    variables: {
      dataSourceId,
      name: snippetName,
      width: `${handler.width}`,
      height: `${handler.height}`,
      objects: JSON.stringify(snippetObjects),
    },
    onCompleted: data => {
      const { errors, modifier } = data.createImageModifier;

      if (errors?.length > 0) {
        const errMsg = errors.join('.</br> ');
        new window.NotificationCenter().show_error(errMsg);
        setError(errMsg);
        return;
      }

      getUpdatedCustomImages();

      const { meta } = objectTypes.imgPlaceholder;
      const object = {
        rotate: 0,
        ...meta.initial,
        type: 'imgPlaceholder',
        x: handler.left,
        y: handler.top,
        width: handler.width,
        height: handler.height,
        uuid: uuid(),
        'data-layer-name': snippetName,
        xlinkHref: buildUrl({ placeholder: `_${modifier.placeholderName}_` }),
      };

      const filtredObjects = originalObjects.filter((_, i) => !selectedObjectsIndexes.includes(i));
      const maxIndex = Math.max(...selectedObjectsIndexes) - selectedObjectsIndexes.length + 1;
      filtredObjects.splice(maxIndex, 0, object);

      setError(null);
      onDesignerUpdate(filtredObjects, true);
      selectObjects([maxIndex]);
      closeModal();
    },
    onError: err => {
      const errMsg = t('react.image_generator.preview_snippet_modal.server_error');
      // eslint-disable-next-line no-console
      console.error(err.message);
      new window.NotificationCenter().show_error(errMsg);
      setError(errMsg);
    },
  });

  const previewSize = 550;

  return (
    <Modal onClose={closeModal} heading={t('react.image_generator.preview_snippet_modal.modal_heading')}>
      <ModalBody>
        <Row className="Text">
          <Col>
            <Input
              label={t('react.image_generator.preview_snippet_modal.name_label')}
              id="snippetName"
              doNotUseInternalState
              value={snippetName}
              onChange={({ target: { value } }) => setSnippetName(value)}
            />
          </Col>
          <Col />
        </Row>
        <Row center>
          <Col>
            <Row>
              <SectionHeading spacing={8}>
                {t('react.image_generator.preview_snippet_modal.preview_heading')}
              </SectionHeading>
            </Row>
            <Row shrink>
              <Col grow>
                <div
                  style={{
                    width: `${previewSize}px`,
                    height: `${previewSize}px`,
                    overflow: 'hidden',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <div
                    style={{
                      transformOrigin: '50% 0',
                      transform: `scale(${previewSize / Math.max(handler.width, handler.height)})`,
                    }}
                  >
                    <Preview objects={snippetObjects} width={handler.width} height={handler.height} />
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Row>
          <Col shrink>
            <Button
              primary
              disabled={snippetName === ''}
              loading={loading}
              loadingType="classic"
              onClick={createSnippet}
            >
              {t('react.image_generator.preview_snippet_modal.create')}
            </Button>
          </Col>
          <Col grow>
            {/* eslint-disable-next-line react/no-danger */}
            {error && <span className="ml-16 ValidationError" dangerouslySetInnerHTML={{ __html: error }} />}
          </Col>
        </Row>
      </ModalFooter>
    </Modal>
  );
};

PreviewSnippetModal.propTypes = {
  closeModal: PropTypes.func,
  dataSourceId: PropTypes.number,
  handler: PropTypes.object,
  snippetObjects: PropTypes.array,
};

export default hot(PreviewSnippetModal);
