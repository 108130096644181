import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const getColor = (degree, colors) => {
  const { firstPartColor, secondPartColor, thirdPartColor } = colors;
  if (degree < 30) {
    return firstPartColor;
  } else if (degree < 150) {
    return secondPartColor;
  }
  return thirdPartColor;
};

const colorMapping = {
  centered: {
    firstPartColor: '#FF9B00',
    secondPartColor: '#3ED95E',
    thirdPartColor: '#D0021B',
  },
  gradual: {
    firstPartColor: '#D0021B',
    secondPartColor: '#FF9B00',
    thirdPartColor: '#3ED95E',
  },
};

const limitDegree = degree => (degree < 180 ? degree : 180);

const ComplexityMeter = ({ degree, kind, width = '83px', height = '45px' }) => {
  const { firstPartColor, secondPartColor, thirdPartColor } = colorMapping[kind];
  return (
    <svg width={width} height={height} viewBox="0 0 83 45" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-7" transform="translate(3.000000, 3.000000)">
          <path
            d="M77,38.5 C77,27.8808622 72.7007414,18.2658701 65.7478679,11.3006672"
            stroke={thirdPartColor}
            strokeWidth="6"
            strokeLinecap="round"
          />
          <path
            d="M11.4234726,11.1300907 C4.3699586,18.1084665 4.18332036e-13,27.793904 4.18332036e-13,38.5"
            stroke={firstPartColor}
            strokeWidth="6"
            strokeLinecap="round"
          />
          <path
            d="M69.3251912,15.4298138 C62.3025897,6.06161892 51.1091783,1.42108547e-14 38.5,1.42108547e-14 C25.1731727,1.42108547e-14 13.4278547,6.771246 6.51677823,17.0610057"
            stroke={secondPartColor}
            strokeWidth="6"
          />
          <g transform={`rotate(${limitDegree(degree)},40,33)`}>
            <path
              d="M39.7116462,40.3169561 C43.5935678,40.3169561 46.740487,37.1700368 46.740487,33.2881152 C46.740487,29.4061936 43.5935678,26.2592744 39.7116462,26.2592744 C35.8297246,26.2592744 19.7566836,31.3860193 12.740487,33.2881152 C18.9376881,34.6356474 35.8297246,40.3169561 39.7116462,40.3169561 Z"
              fill={getColor(degree, colorMapping[kind])}
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

ComplexityMeter.propTypes = {
  degree: PropTypes.number.isRequired,
  kind: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
};

export default ComplexityMeter;

window.initComplexityMeter = () => {
  const containers = document.getElementsByClassName('react-complexity-meter');
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      ReactDOM.render(
        <ComplexityMeter {...container.dataset} degree={JSON.parse(container.dataset.degree || '0')} />,
        container
      );
    });
  }
};
