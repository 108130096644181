import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { restrictToVerticalAxis, restrictToFirstScrollableAncestor, restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

export const DndDragHandle = ({ disabled, disabledShow, ...rest }) => (
  <span
    {...rest}
    data-test-id="drag-handle"
    className={cs('DragHandle', {
      'DragHandle--lock': disabled,
      'DragHandle--disabledEye': disabledShow,
    })}
  />
);
DndDragHandle.propTypes = {
  disabled: PropTypes.bool,
  disabledShow: PropTypes.bool,
};

export const DndSortableElement = (Component, { hasDragHandle } = { hasDragHandle: false }) => {
  const SortableElementWrapper = props => {
    const { active, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

    const isSortingElemenet = active && active.id === props.id;
    const style = {
      transform: active ? CSS.Transform.toString({ ...transform, scaleX: 1, scaleY: 1 }) : '',
      transition,
    };

    const dragHandleProps = { ...attributes, ...listeners };

    return (
      <div
        className={cs('Sortable-item', { isDragging: isSortingElemenet })}
        ref={setNodeRef}
        style={style}
        {...(hasDragHandle ? {} : dragHandleProps)}
      >
        <Component {...props} isSortingActive={!!active} dragHandleProps={hasDragHandle && dragHandleProps} />
      </div>
    );
  };
  SortableElementWrapper.propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  return SortableElementWrapper;
};

export const DndSortableContainer = ({ items, children, onSortEnd, ...rest }) => {
  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);

  const sensors = useSensors(mouseSensor, touchSensor);
  return (
    <DndContext
      {...rest}
      sensors={sensors}
      className="Sortable-container"
      modifiers={[restrictToWindowEdges, restrictToFirstScrollableAncestor, restrictToVerticalAxis]}
      items={items}
      onDragStart={() => {
        document.body.classList.add('grabbing');
      }}
      onDragEnd={e => {
        if (e.active && e.over) {
          const oldIndex = items.indexOf(e.active.id);
          const newIndex = items.indexOf(e.over.id);

          if (typeof onSortEnd === 'function') onSortEnd({ oldIndex, newIndex }, e);
        }

        document.body.classList.remove('grabbing');
      }}
    >
      <SortableContext items={items} strategy={verticalListSortingStrategy}>
        {children}
      </SortableContext>
    </DndContext>
  );
};

DndSortableContainer.propTypes = {
  onSortEnd: PropTypes.func,
  items: PropTypes.array,
  children: PropTypes.any,
};
