import 'whatwg-fetch';
import getCSRFToken from '../utils/getCSRFToken';

let token = '';

document.addEventListener('DOMContentLoaded', () => {
  token = getCSRFToken();
});

export const apiCall = (url, method = 'GET', data = null) =>
  window
    .fetch(`/api_frontend/${url}`, {
      method,
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      ...(data ? { body: JSON.stringify(data) } : {}),
    })
    .then(response => response.json());

export default apiCall;
