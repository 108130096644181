import React from 'react';

const AnimatedUploadIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 24 24"
    version="1.1"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
  >
    <path
      className="AnimatedUpload--arrow"
      fill="#333E47"
      fillRule="evenodd"
      d="M11.5 3.5c.3-.3.8-.3 1.1 0l5 5c.3.3.3.8 0 1.1-.3.3-.8.3-1.1 0l-3.7-3.7V16c0 .4-.3.8-.8.8s-.8-.3-.8-.8V5.8L7.5 9.5c-.3.3-.8.3-1.1 0-.3-.3-.3-.8 0-1.1l5.1-4.9z"
      clipRule="evenodd"
    />
    <path
      fill="#333E47"
      fillRule="evenodd"
      d="M4 16.2c.4 0 .8.3.8.8v2c0 .3.1.6.4.9.2.2.6.4.9.4h12c.3 0 .6-.1.9-.4s.4-.6.4-.9v-2c0-.4.3-.8.8-.8s.8.3.8.8v2a2.732 2.732 0 01-2.7 2.7H6A2.732 2.732 0 013.3 19v-2c-.1-.4.3-.8.7-.8z"
      clipRule="evenodd"
    />
  </svg>
);

export default AnimatedUploadIcon;
