import React from 'react';
import defaultFormats from './defaultFormats';
import { defaultOptions, formatDate, formatNumber, formatRoundedNumber } from './format';
import { createStructuredJSON } from './structuredJSON';

const INTERPOLATION_REGEXP = /%{([\w0-9]+)}/g;

export const getIn = (messages, [current, ...restPath]) => {
  if (!current && restPath.length === 0) {
    return messages;
  }
  if (messages && messages[current]) {
    return getIn(messages[current], restPath);
  }
  if (!messages) {
    return undefined;
  }
  return undefined;
};

export const resolveScope = (key, options) =>
  options.scope
    ? options.scope
        .split('.')
        .concat(key)
        .join('.')
    : key;
export const hasInterpolation = msg => INTERPOLATION_REGEXP.test(msg);

export const interpolate = (msg, dictionary) =>
  msg.replace(INTERPOLATION_REGEXP, (val, match) => `${dictionary[match]}`);

export const getPath = (key, options) => {
  const splittedKey = []
    .concat(key)
    .join('.')
    .split('.');
  return options.scope ? options.scope.split('.').concat(splittedKey) : splittedKey;
};

export class Translator {
  messages = {};
  locale = 'en';
  fallbackLocale = '';

  constructor(options) {
    this.messages = createStructuredJSON(options.messages) || { en: {} };
    this.locale = options.locale;
    this.fallbackLocale = options.fallbackLocale || this.fallbackLocale;
  }

  msg = (key, givenOptions) => this.resolve(key, givenOptions).result;
  t = this.msg;
  // eslint-disable-next-line react/no-danger
  tHtml = (key, givenOptions) => <span dangerouslySetInnerHTML={{ __html: this.msg(key, givenOptions) }} />;

  resolve = (key, givenOptions) => {
    const options = givenOptions || {};
    const defaultTextFromKey = Array.isArray(key) ? key[0] : key;

    if (Array.isArray(key)) {
      const foundMatch =
        this.__getResultForKeys(key, this.__locale(), options) ||
        this.__getResultForKeys(key, this.__fallbackLocale(), options);
      if (foundMatch && typeof foundMatch !== 'object') {
        const usedKey = (
          this.__getUsedKeyForKeys(key, this.__locale(), options) ||
          this.__getUsedKeyForKeys(key, this.__fallbackLocale(), options) ||
          key
        ).join('.');
        return { result: foundMatch, usedKey };
      }
    }

    const result = this.__findTranslation(getPath(key, options));
    const keyPaths = getPath(key, options);
    const defaultText = options.disableDefault ? undefined : options.default || defaultTextFromKey;

    const returnText = result || defaultText;
    const usedKey =
      returnText === defaultTextFromKey && Array.isArray(key) ? resolveScope(key[0], options) : keyPaths.join('.');

    if (typeof returnText === 'string' && hasInterpolation(returnText)) {
      return { result: interpolate(returnText, options), usedKey };
    }

    return { result: returnText, usedKey };
  };

  formatDate = (givenDate, customFormat) => {
    const keywordFormat = customFormat && this.__findTranslation(['date', 'formats', customFormat]);
    const defaultFormat = this.__findTranslation(['date', 'formats', 'default']);

    const format = keywordFormat || customFormat || defaultFormat || defaultFormats.date.formats.default;

    return formatDate(givenDate, this.locale, format);
  };

  formatTime = (givenDate, customFormat) => {
    const keywordFormat = customFormat && this.__findTranslation(['time', 'formats', customFormat]);
    const defaultFormat = this.__findTranslation(['time', 'formats', 'default']);

    const format = keywordFormat || customFormat || defaultFormat || defaultFormats.time.formats.default;

    return formatDate(givenDate, this.locale, format);
  };

  formatInteger = (givenNumber, options = {}) => this.formatNumber(givenNumber, { ...options, precision: 0 });

  formatRoundedNumber = (givenNumber, options) => {
    if (typeof options === 'string') {
      return formatRoundedNumber(givenNumber, this.__getNumberOptions('number', options, {}));
    }
    return formatRoundedNumber(givenNumber, this.__getNumberOptions('number', undefined, options));
  };

  formatNumber = (givenNumber, options) => {
    if (typeof options === 'string') {
      return formatNumber(givenNumber, this.__getNumberOptions('number', options, {}));
    }
    return formatNumber(givenNumber, this.__getNumberOptions('number', undefined, options));
  };

  formatCurrency = (givenNumber, options) => {
    const defaultFormat = this.__getNumberOptions('number', 'currency', options);

    if (options && options.unit) {
      return formatNumber(givenNumber, {
        ...defaultFormat,
        unit: this.__findTranslation(['number_currencies', options.unit]) || options.unit,
      });
    }

    return formatNumber(givenNumber, defaultFormat);
  };

  formatPercentage = (givenNumber, options) => {
    const defaultFormat = this.__getNumberOptions('number', 'percentage', options);

    return formatNumber(givenNumber, defaultFormat);
  };

  __getUsedKeyForKeys(key, locale, options) {
    return key.reduce(
      (acc, subKey) =>
        acc || (!!this.__findTranslationForLocale(locale, getPath(subKey, options)) && getPath(subKey, options)),
      false
    );
  }

  __getResultForKeys(key, locale, options) {
    return key.reduce((acc, subKey) => acc || this.__findTranslationForLocale(locale, getPath(subKey, options)), null);
  }

  // tslint:disable-next-line:typedef
  __getNumberOptions(type, key, overrideOptions) {
    const defaultTypeOptions = this.__findTranslation([type, 'format']);

    const keyOptions = key && this.__findTranslation([type, key, 'format']);

    const result = {
      ...defaultOptions,
      ...defaultFormats[type].format,
      ...(key && defaultFormats[type][key].format),
      ...(defaultTypeOptions && defaultTypeOptions),
      ...(keyOptions && keyOptions),
      ...overrideOptions,
    };

    return result;
  }

  __findTranslation(keys) {
    return (
      this.__findTranslationForLocale(this.__locale(), keys) ||
      this.__findTranslationForLocale(this.__fallbackLocale(), keys)
    );
  }

  __findTranslationForLocale(locale, keys) {
    return getIn(this.__messages(), [locale].concat(keys));
  }

  __locale() {
    return this.locale;
  }

  __fallbackLocale() {
    return this.fallbackLocale || this.locale;
  }

  __messages() {
    return this.messages || {};
  }
}
