import React from 'react';
import RPT from 'prop-types';

const NotificationBox = ({ type, withIcon, children, ...rest }) => (
  <div
    data-test-id={`notification-${type}`}
    className={`Notification Notification--complex Notification--${type}`}
    {...rest}
  >
    <div className={`Notification-content ${withIcon && 'Notification-content--withIcon'}`}>{children}</div>
  </div>
);

NotificationBox.propTypes = {
  withIcon: RPT.bool,
  type: RPT.oneOf(['success', 'info', 'error', 'warning']).isRequired,
  children: RPT.any,
};

export default NotificationBox;
