import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import uuid from 'uuid';
import Downshift from 'downshift';
import { Row } from '../index';
import { t } from '../../i18n';

const Autosuggest = ({ getItemProps, getMenuProps, highlightedIndex, inputValue, isOpen, suggestions }) => {
  if (!suggestions || suggestions.length === 0) return null;
  const filteredSuggestions = suggestions.filter(
    item => item && (!inputValue || item.value.toLowerCase().includes(inputValue.toLowerCase()))
  );
  if (filteredSuggestions.length === 0) {
    return null;
  }
  return (
    <React.Fragment>
      {isOpen ? (
        <div {...getMenuProps()} className="Placeholders-dropdown w-100">
          <div className="Placeholders-list h-100" data-test-id="autosuggest-list">
            {filteredSuggestions.map((item, index) => (
              <Row
                {...getItemProps({
                  key: item.value,
                  index,
                  item,
                  'data-test-id': 'autosuggest-line',
                })}
                className={cs('Placeholders-dropdownItem pa-8 ma-0', { active: highlightedIndex === index })}
              >
                {item.value}
              </Row>
            ))}
          </div>
          <div
            className="Placeholders-dropdownHint"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: t('react.editor.use_arrows_html') }}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

Autosuggest.propTypes = {
  getItemProps: PropTypes.func,
  getMenuProps: PropTypes.func,
  highlightedIndex: PropTypes.number,
  inputValue: PropTypes.string,
  isOpen: PropTypes.bool,
  suggestions: PropTypes.array,
};

export default class Input extends Component {
  static propTypes = {
    hasCompactWidth: PropTypes.bool,
    className: PropTypes.string,
    CustomAutosuggest: PropTypes.any,
    defaultValue: PropTypes.any,
    disableEnter: PropTypes.bool,
    doNotUseInternalState: PropTypes.bool,
    enableAutosuggest: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.any,
    focusOnRender: PropTypes.bool,
    children: PropTypes.any,
    id: PropTypes.string.isRequired,
    inputRightPadding: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    noMargin: PropTypes.bool,
    onFocus: PropTypes.func,
    onChange: PropTypes.func,
    onValidityChange: PropTypes.func,
    required: PropTypes.bool,
    reset: PropTypes.bool,
    search: PropTypes.bool,
    setRef: PropTypes.func,
    style: PropTypes.object,
    suggestions: PropTypes.array,
    type: PropTypes.oneOf(['string', 'text', 'integer', 'float']),
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    required: false,
    type: 'text',
    suggestions: [],
    style: {},
  };

  state = {
    value: this.props.value || this.props.defaultValue,
    validValue: true,
    uuid: uuid(),
    errorType: null,
  };

  setRef = el => {
    if (this.props.focusOnRender && el) {
      setTimeout(() => el.focus(), 10);
    }
    if (this.props.setRef) {
      this.props.setRef(el);
    }
  };

  handleChange = e => {
    const { type, onValidityChange, onChange } = this.props;
    let value = e?.target?.value;

    if (type === 'string' || type === 'text') {
      this.setState({ value, validValue: true });
      if (typeof onValidityChange === 'function') onValidityChange(true);
      if (typeof onChange === 'function') {
        if (typeof value !== 'undefined') {
          onChange(e);
        }
      }
    } else if (type === 'integer') {
      const validValue = parseInt(value, 10).toString() === value;

      // if value is valid parse it
      if (validValue) {
        value = parseInt(value, 10);
        this.setState({ value, validValue, errorType: null });
        if (typeof onChange === 'function') {
          onChange({ target: { value } });
        }
        // Do not propagate invalid value to top component
      } else {
        this.setState({ value, validValue, errorType: t('errors.messages.not_a_number') });
      }
      if (typeof onValidityChange === 'function') onValidityChange(validValue);
    } else if (type === 'float') {
      const normalizedValue = value.replace(',', '.').replace(' ', '');
      const validValue =
        !isNaN(parseFloat(normalizedValue, 10).toString()) && !(value.endsWith('.') || value.endsWith(','));

      // if value is valid parse it
      if (validValue) {
        value = parseFloat(normalizedValue, 10);
        this.setState({ value, validValue, errorType: null });
        if (typeof onChange === 'function') {
          onChange({ target: { value } });
        }
        // Do not propagate invalid value to top component
      } else {
        this.setState({ value, validValue, errorType: t('errors.messages.not_a_number') });
      }
      if (typeof onValidityChange === 'function') onValidityChange(validValue);
    }
  };

  render() {
    const {
      hasCompactWidth,
      className,
      CustomAutosuggest,
      disableEnter,
      doNotUseInternalState,
      enableAutosuggest,
      error,
      errorMessage,
      focusOnRender,
      children,
      id,
      inputRightPadding,
      label,
      name,
      noMargin,
      onFocus,
      required,
      reset,
      search,
      style,
      suggestions,
      value,
      ...rest
    } = this.props;
    const { validValue, errorType } = this.state;
    const selected = doNotUseInternalState && validValue ? value : this.state.value;
    const inputId = id || this.state.uuid;

    const AutosuggestComponent = CustomAutosuggest || Autosuggest;

    return (
      <Downshift
        onSelect={sel => this.handleChange({ target: sel })}
        itemToString={item => (item ? item.value : '')}
        defaultHighlightedIndex={0}
      >
        {({ getInputProps, getLabelProps, ...restAutosuggest }) => (
          <div
            className={cs('Input', 'string', className, {
              'Input--search Input--limitedWidth': search || reset,
              'Input--compactedWidth': (search || reset) && hasCompactWidth,
              'Input--noMargin': noMargin,
              valueFilled: !!selected,
              'has-error': error,
              'pos-relative': enableAutosuggest,
            })}
            style={style}
          >
            {label && (
              <label {...getLabelProps()} htmlFor={inputId}>
                {label}
              </label>
            )}
            <input
              {...getInputProps({
                ...rest,
                ref: this.setRef,
                type: 'text',
                className: cs('input', { required }),
                name,
                id: inputId,
                onChange: this.handleChange,
                value: selected,
                onFocus:
                  typeof onFocus === 'function' ? onFocus({ ...restAutosuggest, suggestions, selected }) : undefined,
                style: { paddingRight: ((reset || search) && '32px') || inputRightPadding },
                onKeyDown: e => {
                  if (disableEnter && e.key === 'Enter') e.preventDefault();
                },
              })}
            />
            {enableAutosuggest && <AutosuggestComponent suggestions={suggestions} {...restAutosuggest} />}
            {children}
            {search && <i className="Input-magnifier fc-input-search" style={{ cursor: 'auto' }} />}
            {(reset || search) && (
              <i className="Input-reset fc-close" onClick={() => this.handleChange({ target: { value: '' } })} />
            )}
            {errorType && <span className="Input-errorLabel">{errorType}</span>}
            {/* eslint-disable-next-line react/no-danger */}
            {errorMessage && <span className="Input-errorLabel" dangerouslySetInnerHTML={{ __html: errorMessage }} />}
          </div>
        )}
      </Downshift>
    );
  }
}
