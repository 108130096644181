import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import uuid from 'uuid';
import { Icon, Row } from '../index';

export default class Chip extends PureComponent {
  static propTypes = {
    doNotUseInternalState: PropTypes.bool,
    className: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    value: PropTypes.any.isRequired,
    noWrap: PropTypes.bool,
    'data-test-id': PropTypes.string,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    required: false,
  };

  state = {
    value: this.props.value,
  };

  handleChange = e => {
    const {
      target: { checked },
    } = e;

    this.setState({ value: checked });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: checked } });
    }
  };

  render() {
    const { className, value, name, id, disabled, label, required, doNotUseInternalState, icon, noWrap } = this.props;

    const selected = doNotUseInternalState ? value : this.state.value;

    const inputId = `${id || name}-input-id-${uuid()}`;

    return (
      <div
        className={cs(className, 'Input--chip', {
          required,
          disabled: !!disabled,
        })}
      >
        <input disabled={!!disabled && 'disabled'} name={name} type="hidden" value="0" />
        <input
          type="checkbox"
          value="1"
          checked={selected}
          disabled={!!disabled && 'disabled'}
          name={name}
          id={inputId}
          onChange={this.handleChange}
        />
        <label htmlFor={inputId} data-test-id={this.props['data-test-id']}>
          <Row center noPadding style={noWrap && { whiteSpace: 'nowrap' }}>
            {icon && <Icon kind={icon} inheritColor size="14px" className="mr-4" />}
            {label}
          </Row>
        </label>
      </div>
    );
  }
}
