import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button, Row, Col } from '../components';

export const WrapperPropTypes = {
  field: PropTypes.string.isRequired,
  options: PropTypes.object,
  children: PropTypes.any,
  connectAddNewFieldsToContainer: PropTypes.func.isRequired,
  addNewField: PropTypes.func.isRequired,
  getMaxCount: PropTypes.func.isRequired,
  getCurrentNotEmptyCount: PropTypes.func.isRequired,
};

export const MainRenderer = ({
  field,
  children,
  connectAddNewFieldsToContainer,
  getMaxCount,
  getCurrentNotEmptyCount,
  withNormalAddButton,
  additionalButtons,
}) => (
  <Col id={field} setRef={connectAddNewFieldsToContainer} className="NestedFields-wrapper">
    {children}
    <Row center padding="l" className={cs({ 'mt-8': withNormalAddButton })}>
      {getMaxCount() > 0 && (
        <Col shrink>
          <span className="Text--gray">
            {getCurrentNotEmptyCount()} / {getMaxCount()}
          </span>
        </Col>
      )}
      {additionalButtons && <Col grow>{additionalButtons}</Col>}
    </Row>
  </Col>
);

MainRenderer.propTypes = {
  ...WrapperPropTypes,
  withNormalAddButton: PropTypes.bool,
  addNewFieldText: PropTypes.string,
  disabled: PropTypes.bool,
};

export const BoxRenderer = ({
  field,
  options,
  children,
  addNewField,
  connectAddNewFieldsToContainer,
  getMaxCount,
  getCurrentNotEmptyCount,
  activeFieldData,
}) => {
  if (activeFieldData.length === 0) {
    setTimeout(() => addNewField({}), 10);
  }

  return (
    <Col className="mb-16">
      <Row>
        <span className="Input-label">
          {options.label}
          {getMaxCount() > 0 && <span className="pl-8">{getCurrentNotEmptyCount()}</span>}
        </span>
      </Row>
      <Row
        className="NestedFields-wrapper--box"
        id={field}
        setRef={connectAddNewFieldsToContainer}
        flexwrap
        style={{ marginBottom: '-8px' }}
      >
        {children}
      </Row>
    </Col>
  );
};
BoxRenderer.propTypes = {
  ...WrapperPropTypes,
  activeFieldData: PropTypes.array,
};

export const NestedActiveDataContext = React.createContext([]);
export const CounterContext = React.createContext({ setCounters: () => {}, counters: {} });

export const FunnelRenderer = ({
  disabled,
  field,
  options,
  addNewFieldText,
  children,
  connectAddNewFieldsToContainer,
  addNewField,
  getMaxCount,
  activeFieldData,
  getCurrentNotEmptyCount,
}) => {
  const [counters, setCounter] = useState({});

  const setCounters = useCallback(
    (id, count) => {
      const key = id.toString();
      if (counters[key] !== count) {
        setCounter({ ...counters, [key]: count });
      }
    },
    [setCounter, counters]
  );

  return (
    <CounterContext.Provider value={{ setCounters, counters }}>
      <NestedActiveDataContext.Provider value={activeFieldData}>
        <Col id={field} setRef={connectAddNewFieldsToContainer} className="NestedFields-wrapper">
          {children}
          <Row center>
            <Button
              className={`js-add-field mb-0 ${options.add_button_class}`}
              disabled={disabled || !addNewField}
              icon={!addNewFieldText ? 'plus' : undefined}
              onClick={() => addNewField({})}
              secondary
              size="small"
              data-test-id={`add-${field}-button`}
              type="button"
              onlyIcon={!addNewFieldText}
            >
              {addNewFieldText}
            </Button>
            {getMaxCount() > 0 && (
              <span className="pl-24 Text--gray">
                {getCurrentNotEmptyCount()} / {getMaxCount()}
              </span>
            )}
          </Row>
        </Col>
      </NestedActiveDataContext.Provider>
    </CounterContext.Provider>
  );
};

FunnelRenderer.propTypes = {
  ...WrapperPropTypes,
  addNewFieldText: PropTypes.string,
  disabled: PropTypes.bool,
};
