import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../index';

const ExampleLoadingButton = ({ shouldFail = false, timeout, ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleClick = () => {
    setLoading(true);

    setTimeout(() => {
      if (shouldFail) {
        setError('Error');
      }
      setLoading(false);
    }, timeout);
  };

  return <Button {...rest} onClick={handleClick} error={error} loading={loading} />;
};

ExampleLoadingButton.propTypes = {
  shouldFail: PropTypes.bool,
  timeout: PropTypes.number,
};

export default ExampleLoadingButton;
