const downloadTextFile = (data, fileName, fileType) => {
  // eslint-disable-next-line no-undef
  const blob = new Blob([data], { type: `text/${fileType}` });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.${fileType}`;
  a.click();
};

export default downloadTextFile;
