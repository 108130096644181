import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { intersection, sortBy } from 'lodash';
import { ErrorBox, Loader } from '../components';
import { NestedActiveDataContext, CounterContext } from './WrapperRenderers';
import { t, formatInteger } from '../i18n';
import { serializeConditions } from '../utils/condition';

export const QUERY = gql`
  query FunnelConditionsCount(
    $dataSourceId: BigInt
    $currentConditions: ConditionInput!
    $previousConditions: [ConditionInput!]
  ) {
    selectedCount: funnelConditionCount(
      dataSourceId: $dataSourceId
      currentConditions: $currentConditions
      previousConditions: $previousConditions
    )
  }
`;

const ConditionalLabelSelectedCounter = ({ options, position, id, conditions }) => {
  const activeFieldData = useContext(NestedActiveDataContext);
  const { setCounters, counters } = useContext(CounterContext);
  const previousFieldData = activeFieldData.filter(fd => fd.position < position);

  const remainingCount =
    options.data_source_count -
    previousFieldData.reduce((out, pfd) => {
      if (typeof out === 'undefined' || typeof counters[pfd.id.toString()] === 'undefined') {
        return out;
      }

      return out + counters[pfd.id.toString()];
    }, 0);

  const { loading, data, error } = useQuery(QUERY, {
    skip:
      remainingCount === 0 ||
      intersection(
        Object.keys(counters),
        previousFieldData.map(pfd => pfd.id.toString())
      ).length < previousFieldData.length,
    variables: {
      ...(options.load_options_filter || {}),
      currentConditions: {
        conditions: serializeConditions(conditions || []),
      },
      previousConditions: sortBy(previousFieldData, 'id').map(pfd => ({
        conditions: serializeConditions(pfd.conditions || []),
      })),
    },
  });

  useEffect(() => setCounters(id, data?.selectedCount), [setCounters, data]);

  return (
    <span className="pos-relative Sortable--hiddenOnSort">
      {error && (
        <ErrorBox>
          {t('views.modifiers.conditional_variable.unable_to_fetch_products_count', {
            default: 'Unable to get selected items count',
          })}
        </ErrorBox>
      )}
      {!error && loading && <Loader size="small" />}
      {!error && !loading && (
        <b className="vam">
          {t('views.modifiers.form_fields.for', { default: 'For' })}{' '}
          {typeof data?.selectedCount === 'undefined' && remainingCount !== 0
            ? '???'
            : formatInteger(data?.selectedCount || 0)}{' '}
          {t('views.modifiers.form_fields.items', { default: 'items' })}
        </b>
      )}
    </span>
  );
};

ConditionalLabelSelectedCounter.propTypes = {
  options: PropTypes.object,
  position: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  conditions: PropTypes.array,
};

export default ConditionalLabelSelectedCounter;
