import storage from '../../utils/storage';

export const getLocalStorageDefaultSearchState = (collectionKey, recordUseOfLocalState = () => {}) => {
  const key = `TableActiveSearch:${collectionKey}`;
  const data = storage.get(key);
  recordUseOfLocalState(key);
  return data && data !== 'undefined' ? JSON.parse(data) : null;
};

export const setLocalStorageDefaultSearchState = (collectionKey, value) =>
  storage.set(`TableActiveSearch:${collectionKey}`, JSON.stringify(value));
