import { useEffect, useState, useContext } from 'react';
import { compress, decompress } from 'lz-string';
import storage from '../utils/storage';
import { LocalStateUsedContext } from './index';

const getConditionalValue = ({ condition, value, callback }) => {
  if (condition) return callback(value);
  return value;
};

const useLocalState = (def, key, options) => {
  const localKey = `useLocalState(${key})`;
  useContext(LocalStateUsedContext)(localKey);

  const condition = options?.useCompression;
  const storageVal = getConditionalValue({
    condition,
    value: storage.get(localKey, { default: '{}' }),
    callback: decompress,
  });

  const value = JSON.parse(storageVal)?.state || def;
  const [state, setState] = useState(value);

  useEffect(() => {
    const storageResult = getConditionalValue({
      condition,
      value: JSON.stringify({ state }),
      callback: compress,
    });
    storage.set(localKey, storageResult);
  }, [state]);
  return [state, setState];
};

export default useLocalState;
