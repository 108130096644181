import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody } from '..';
import Transformations from './Transformations';
import FieldKindIcon from '../FieldKindIcon';

const TransformationsModal = ({ variable, onClose, organizationId, dataSourceId }) => (
  <Modal
    heading={
      <>
        <span className="Text--grape">
          <FieldKindIcon showFieldKind={variable.showFieldKind} size="24px" inheritColor />
        </span>
        {variable.name}
      </>
    }
    onClose={onClose}
    size="fullwithGrid"
    testId="transformations-modal"
  >
    <ModalBody>
      <div>
        <Transformations organizationId={organizationId} dataSourceId={dataSourceId} variableId={variable.id} />
      </div>
    </ModalBody>
  </Modal>
);

TransformationsModal.propTypes = {
  variable: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    showFieldKind: PropTypes.string,
  }),
  onClose: PropTypes.func,
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
};

export default TransformationsModal;
