/* eslint-disable */
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import React from 'react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import Tile from './Tile';
import { Row, Col, SmallHeading, Link, ShowOnHoverElement, ShowOnHoverWrapper, Icon, Text } from '../index';
import { t } from '../../i18n';

const QUERY_DASHBOARD_BLOG_ARTICLES_QUERY = gql`
  query BlogArticles {
    blogArticles(limit: 2, offset: 0) {
      id
      title
      image
      link
    }
  }
`;

export const BlogArticlesComponent = hot(({ blogArticles = [], error, loading, refetch }) => {
  if (blogArticles.length === 0)
    return (
      <Tile
        dashboard
        error={error}
        loading={loading}
        refetch={refetch}
        header={t('react.dashboard.blog_articles.header')}
      >
        <Icon kind="info-btn" size="80px" color="#f2f3f4" className="mb-8" />
        <Text bold>{t('react.dashboard.blog_articles.no_tips')}</Text>
      </Tile>
    );

  return (
    <Tile
      header={t('react.dashboard.blog_articles.header')}
      dashboard
      error={error}
      loading={loading}
      refetch={refetch}
    >
      {blogArticles.map(({ id, title, link, image }) => (
        <a className="Link Link--noUnderline w-100" key={id} href={link} target="_blank">
          <ShowOnHoverWrapper>
            <Row className="delimiter pv-8" shrink top>
              <Col width="128px" className="pos-relative">
                <img src={image} alt={title} className="mw-100" />
                <ShowOnHoverElement>
                  <Row
                    justifyCenter
                    center
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: 'rgba(17, 17, 17, 0.5)',
                    }}
                  >
                    <Icon kind="external" size="24px" color="white" />
                  </Row>
                </ShowOnHoverElement>
              </Col>
              <Col grow>
                <SmallHeading>
                  <div className="line-clamp-4">
                    <div className="clamped-text">{title}</div>
                  </div>
                </SmallHeading>
              </Col>
            </Row>
          </ShowOnHoverWrapper>
        </a>
      ))}
      <Link className="mt-16" external href="https://www.dotidot.io/blog">
        {t('react.dashboard.blog_articles.see_more')}
      </Link>
    </Tile>
  );
});

BlogArticlesComponent.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  blogArticles: PropTypes.array,
};

export const BlogArticles = hot(({ ...rest }) => (
  <Query query={QUERY_DASHBOARD_BLOG_ARTICLES_QUERY}>
    {({ error, data, loading, refetch }) => (
      <BlogArticlesComponent refetch={() => refetch()} loading={loading} error={error} {...rest} {...(data || {})} />
    )}
  </Query>
));

BlogArticles.propTypes = {
  organizationId: PropTypes.number.isRequired,
};
