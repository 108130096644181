import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import Badge, { BetaBadge, MoneyBadge, NewBadge, TipBadge } from './Badge';
import { Row, Col, Icon, Tooltip } from './index';

export const TileBadge = ({ badge, betaBadge, alphaBadge, moneyBadge, newBadge, tipBadge, leftSpacing }) => (
  <div className="Tile-badge">
    {badge && badge.length > 0 && (
      <Badge size="medium" kind="blue" uppercase className={cs({ 'mr-4': !leftSpacing })}>
        {badge}
      </Badge>
    )}
    {newBadge && newBadge.length > 0 && (
      <NewBadge size="medium" text={newBadge} className={cs({ 'mr-4': !leftSpacing, 'ml-4': leftSpacing })} />
    )}
    {betaBadge && betaBadge.length > 0 && (
      <BetaBadge size="medium" text={betaBadge} className={cs({ 'mr-4': !leftSpacing, 'ml-4': leftSpacing })} />
    )}
    {alphaBadge && alphaBadge.length > 0 && (
      <Badge size="medium" kind="lightBlue" uppercase className={cs({ 'mr-4': !leftSpacing, 'ml-4': leftSpacing })}>
        {alphaBadge}
      </Badge>
    )}
    {moneyBadge && <MoneyBadge size="medium" className={cs({ 'mr-4': !leftSpacing, 'ml-4': leftSpacing })} />}
    {tipBadge && <TipBadge size="medium" className={cs({ 'mr-4': !leftSpacing, 'ml-4': leftSpacing })} />}
  </div>
);

TileBadge.propTypes = {
  alphaBadge: PropTypes.string,
  badge: PropTypes.string,
  betaBadge: PropTypes.string,
  leftSpacing: PropTypes.bool,
  moneyBadge: PropTypes.bool,
  newBadge: PropTypes.string,
  tipBadge: PropTypes.bool,
};

const TileCheck = () => (
  <div className="Tile-check">
    <div className="Sequence">
      <div className="Sequence-step Sequence-step--wideBorder" />
    </div>
  </div>
);

export const TileArrowDelimiter = ({ containerPadding = '0px', hasSmallArrow = true }) => (
  <div
    className={cs('Tile-arrowDelimiter', { 'Tile-arrowDelimiter--small': hasSmallArrow })}
    style={{ '--container-padding': `${containerPadding}` }}
  />
);
TileArrowDelimiter.propTypes = {
  containerPadding: PropTypes.string,
  hasSmallArrow: PropTypes.bool,
};

export const TileDelimiter = ({ negativeMargin = 16, className }) => (
  <div className={cs('Tile-delimiter', className, `negative-mh-${negativeMargin}`)} />
);
TileDelimiter.propTypes = {
  className: PropTypes.string,
  negativeMargin: PropTypes.number,
};

const VerticalTileContent = ({
  children,
  text,
  tooltip,
  badge,
  betaBadge,
  alphaBadge,
  moneyBadge,
  newBadge,
  withCheck,
}) => (
  <React.Fragment>
    {withCheck && (
      <Row shrink>
        <TileCheck />
      </Row>
    )}

    {children}
    {text && <div className={tooltip && tooltip.length > 0 && 'pr-8'}>{text}</div>}

    {(badge || betaBadge || alphaBadge || moneyBadge || newBadge) && (
      <Row justifyCenter className="mt-16">
        <TileBadge
          badge={badge}
          betaBadge={betaBadge}
          moneyBadge={moneyBadge}
          newBadge={newBadge}
          alphaBadge={alphaBadge}
          leftSpacing
        />
      </Row>
    )}
  </React.Fragment>
);

VerticalTileContent.propTypes = {
  alphaBadge: PropTypes.string,
  badge: PropTypes.string,
  betaBadge: PropTypes.string,
  children: PropTypes.any,
  moneyBadge: PropTypes.bool,
  newBadge: PropTypes.string,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  withCheck: PropTypes.bool,
};

const HorizontalTileContent = ({ children, text, badge, betaBadge, alphaBadge, moneyBadge, newBadge, withCheck }) => (
  <Row center padding="l">
    {withCheck && (
      <Col shrink>
        <TileCheck />
      </Col>
    )}

    <Col grow>
      {children}
      {text}
    </Col>

    {(badge || betaBadge || alphaBadge || moneyBadge || newBadge) && (
      <Col shrink>
        <TileBadge
          badge={badge}
          betaBadge={betaBadge}
          moneyBadge={moneyBadge}
          newBadge={newBadge}
          alphaBadge={alphaBadge}
          leftSpacing
        />
      </Col>
    )}
  </Row>
);

HorizontalTileContent.propTypes = {
  alphaBadge: PropTypes.string,
  badge: PropTypes.string,
  betaBadge: PropTypes.string,
  children: PropTypes.any,
  moneyBadge: PropTypes.bool,
  newBadge: PropTypes.string,
  text: PropTypes.string,
  withCheck: PropTypes.bool,
};

class Tile extends Component {
  static propTypes = {
    'data-method': PropTypes.oneOf(['post', 'delete', 'put', 'patch']),
    active: PropTypes.bool,
    addResource: PropTypes.object,
    alphaBadge: PropTypes.string,
    badge: PropTypes.string,
    betaBadge: PropTypes.string,
    center: PropTypes.bool,
    className: PropTypes.string,
    contentStyle: PropTypes.object,
    ctaLink: PropTypes.string,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    fullHeight: PropTypes.bool,
    hasDarkBorder: PropTypes.bool,
    horizontal: PropTypes.bool,
    hoverable: PropTypes.bool,
    children: PropTypes.any,
    inDashboardTile: PropTypes.bool,
    moneyBadge: PropTypes.bool,
    noSpaced: PropTypes.bool,
    onClick: PropTypes.func,
    renderOnlyChildren: PropTypes.bool,
    shadow: PropTypes.bool,
    smallSpaced: PropTypes.bool,
    subTile: PropTypes.any,
    text: PropTypes.string,
    tooltip: PropTypes.string,
    withCheck: PropTypes.bool,
  };

  render() {
    const {
      'data-method': dataMethod,
      active,
      addResource = null,
      alphaBadge,
      badge,
      betaBadge,
      center,
      className,
      contentStyle,
      ctaLink,
      dataTestId,
      disabled,
      fullHeight,
      hasDarkBorder,
      horizontal,
      hoverable,
      children,
      inDashboardTile,
      moneyBadge,
      noSpaced,
      onClick,
      renderOnlyChildren,
      shadow,
      smallSpaced,
      subTile,
      text,
      tooltip,
      withCheck,
      subTileAltBackground,
      ...rest
    } = this.props;

    if (renderOnlyChildren) {
      return children;
    }

    const isLink = ctaLink && ctaLink.length > 0 && !onClick;
    const Element = isLink ? 'a' : 'div';
    const isHoverable = hoverable || onClick || (ctaLink && ctaLink.length > 0);

    const elementContent = (
      <div
        className={cs('Tile-content', {
          'Tile-content--noSpaced': noSpaced,
          'Tile-content--smallSpaced': smallSpaced,
          'Tile-content--inDashboardTile': inDashboardTile,
          'pr-32': tooltip && tooltip.length > 0 && horizontal,
        })}
        style={contentStyle}
      >
        {horizontal && <HorizontalTileContent {...this.props} />}
        {!horizontal && <VerticalTileContent {...this.props} />}
        {subTile && (
          <div className={cs('Tile Tile--subTile mt-16', { 'Tile--subTile__altBackground': subTileAltBackground })}>
            <div className="Tile-content Tile-content--smallSpaced">{subTile}</div>
          </div>
        )}
      </div>
    );

    let elementComponent;

    if (addResource) {
      elementComponent = (
        <Element
          className={cs({ 'h-100': fullHeight })}
          data-toggle={addResource.toggle}
          data-url={addResource.url}
          data-notify-on-change={addResource.notify_on_change}
          data-size={addResource.size}
        >
          {elementContent}
        </Element>
      );
    } else {
      elementComponent = (
        <Element
          className={cs({ 'h-100': fullHeight })}
          onClick={onClick}
          href={isLink && ctaLink}
          data-test-id={dataTestId}
          data-method={dataMethod}
        >
          {elementContent}
        </Element>
      );
    }

    return (
      <div
        className={cs('Tile', className, {
          'Tile--shadow': shadow,
          'Tile--disabled': disabled,
          'Tile--hover': isHoverable,
          'Tile--active': active,
          'text-center': center,
          'Tile--fullHeight': fullHeight,
          'Tile--horizontal': horizontal,
          'Tile--darkBorder': hasDarkBorder,
        })}
        {...rest}
      >
        {tooltip && tooltip.length > 0 && (
          <div className="Tile-tooltip">
            <Tooltip text={tooltip}>
              <Icon kind="info-circle-fill" inheritColor size="16px" />
            </Tooltip>
          </div>
        )}
        {elementComponent}
      </div>
    );
  }
}

export const FormTile = props => <Tile {...props} className={cs('mb-16', props.className)} />;
FormTile.propTypes = Tile.propTypes;

export default Tile;
