import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import Searchbox from './Searchbox';
import DefaultList from './DefaultList';
import { apiCall } from '../utils/apiCall';
import { Icon, Row, Col, Ellipsis, Button, Text } from '../components';
import { t } from '../i18n';
import Avatar from '../components/Avatar';
import Badge from '../components/Badge';

const containsText = (name, searchText) => name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1;

const filterOrganizations = (organizations, searchText) =>
  organizations.reduce((acc, organization) => {
    if (containsText(organization.name, searchText)) {
      return [...acc, organization];
    }

    const childOrganizations = (organization.childOrganizations || []).filter(({ name }) =>
      containsText(name, searchText)
    );
    if (childOrganizations.length) {
      return [...acc, { ...organization, childOrganizations }];
    }
    return acc;
  }, []);

const MAX_LINES_WITHOUT_SEARCH = 10;

class OrganizationsDropdown extends React.Component {
  static propTypes = {
    currentOrganization: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    left: PropTypes.bool,
    searchBoxText: PropTypes.string,
    createOrganization: PropTypes.object.isRequired,
    bold: PropTypes.bool,
  };

  state = {
    isClosed: true,
    loading: true,
    loaded: false,
    hasArchivedOrgs: false,
    showArchived: false,
    searchValue: '',
    organizations: [],
    filteredOrganizations: [],
  };

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate() {
    window.attach_tooltips_to_text_overflow();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setSearchboxRef = ref => {
    this.searchBoxRef = ref;
  };

  handleClickOutside = e => {
    if (!document.getElementById('react-organization-dropdown').contains(e.target)) {
      this.setState({
        isClosed: true,
      });
    }

    if (
      e.target &&
      e.target.parentNode &&
      e.target.parentNode.classList &&
      e.target.parentNode.classList.contains('js-profile') &&
      e.target.parentNode.parentNode.classList.contains('active')
    ) {
      if (document.getElementById('js-navbar-backdrop')) {
        document.getElementById('js-navbar-backdrop').classList.remove('d-none');
      }
    }
  };

  search = (organizations, searchValue, showArchived) => {
    const filteredOrgsByState = organizations
      .filter(org => org.archived === showArchived)
      .map(org => (org.parentOrg && org.parentOrg.archived !== showArchived ? { ...org, parentId: null } : org));
    return searchValue.length > 0 ? filterOrganizations(filteredOrgsByState, searchValue) : filteredOrgsByState;
  };

  handleSearch = ({ target: { value } }) => {
    const { organizations, showArchived } = this.state;
    const fixedValue = value.trim();

    this.setState({
      filteredOrganizations: this.search(organizations, fixedValue, showArchived),
      searchValue: value,
    });
  };

  openDropdown = () => {
    const isClosed = !this.state.isClosed;
    this.setState({ isClosed });
    if (isClosed) {
      if (document.getElementById('js-navbar-backdrop')) {
        document.getElementById('js-navbar-backdrop').classList.add('d-none');
      }
    } else {
      if (document.getElementById('js-navbar-backdrop')) {
        document.getElementById('js-navbar-backdrop').classList.remove('d-none');
      }
      setTimeout(() => {
        if (this.searchBoxRef) {
          this.searchBoxRef.focus();
        }
      }, 100);
    }

    if (!this.state.loaded) {
      this.setState({ loading: true });
      apiCall('organizations').then(({ organizations }) => {
        const newOrganizations = organizations
          .concat(
            flatten(
              organizations.map(
                o =>
                  (o.childOrganizations || []).map(childO => ({
                    ...childO,
                    parentOrg: o,
                  })) || []
              )
            )
          )
          .sort((a, b) => a.name.localeCompare(b.name));

        this.setState({
          organizations: newOrganizations,
          hasArchivedOrgs: newOrganizations.filter(org => org.archived).length > 0,
          filteredOrganizations: this.search(newOrganizations, this.state.searchValue, this.state.showArchived),
          loaded: true,
          loading: false,
        });
      });
    }
  };

  render() {
    const { currentOrganization, currentUser, left, searchBoxText, createOrganization, bold } = this.props;
    const {
      isClosed,
      filteredOrganizations,
      searchValue,
      loading,
      organizations,
      loaded,
      showArchived,
      hasArchivedOrgs,
    } = this.state;

    return (
      <div className={cs('Navbar-item', 'ph-0', { active: !isClosed })}>
        <div
          className={cs('Breadcrumbs-dropdownButton js-navbar-dropdown no-user-select', {
            selected: bold,
          })}
          onClick={this.openDropdown}
          data-test-id="organization-dropdown"
        >
          <Row center className="mh-4">
            <Col shrink>
              <Icon kind="building" inheritColor className="Breadcrumbs-dropdownButton-icon" />
            </Col>
            <Col shrink style={{ maxWidth: '270px' }}>
              <Ellipsis>
                {currentOrganization && !currentOrganization.isNewRecord && !currentOrganization.isEmpty ? (
                  <span>{currentOrganization.name}</span>
                ) : (
                  <span>{currentUser.email}</span>
                )}
              </Ellipsis>
            </Col>
            <Col shrink>
              <Icon kind="chevron-down" inheritColor className="Breadcrumbs-dropdownButton-chevron" />
            </Col>
          </Row>
        </div>
        <div className={cs('Breadcrumbs-dropdown', { active: !isClosed, 'Breadcrumbs-dropdown--left': left })}>
          <div
            className={cs('ph-12 pt-12', {
              delimeter: !hasArchivedOrgs,
              'pb-6': hasArchivedOrgs,
              'pb-12': !hasArchivedOrgs,
            })}
          >
            <Col noPadding>
              <Row center noPadding>
                <Col shrink className="mr-12">
                  <Avatar size="xl" icon="building" hasActiveBadge={!currentOrganization.archived} />
                </Col>
                <Col grow className="mr-12">
                  <Text tag="div" size="md" bold>
                    <Ellipsis>
                      {currentOrganization && !currentOrganization.isNewRecord && !currentOrganization.isEmpty
                        ? currentOrganization.name
                        : currentUser.email}
                    </Ellipsis>
                  </Text>
                  {currentOrganization.subscriptionName && (
                    <Badge className="mt-4 align-self-start" kind="lightGreen">
                      {currentOrganization.subscriptionName}
                    </Badge>
                  )}
                </Col>
                <Col shrink>
                  <Button tag="a" href={currentOrganization.editLink} secondary icon="organization-cog" size="small">
                    {t('react.organization.setting_billing', { default: 'Settings & billing' })}
                  </Button>
                </Col>
              </Row>
              {organizations.length > MAX_LINES_WITHOUT_SEARCH && (
                <Row className="mt-12">
                  <Searchbox
                    searchBoxText={searchBoxText}
                    searchValue={searchValue}
                    setInputRef={this.setSearchboxRef}
                    currentUser={currentUser}
                    handleSearch={this.handleSearch}
                  />
                </Row>
              )}
            </Col>
          </div>
          {hasArchivedOrgs && (
            <div className="Breadcrumbs-tabs pa-0 mt-8">
              <ul className="TabNavigation TabNavigation--noSpacing">
                <li className={`TabNavigation-item text-center w-50 ${!showArchived && 'active'}`} key="active">
                  <a
                    onClick={() =>
                      this.setState({
                        showArchived: !showArchived,
                        searchValue: '',
                        filteredOrganizations: this.search(organizations, '', !showArchived),
                      })
                    }
                  >
                    <span className="Text--bold">{t('react.organization.active')}</span>
                  </a>
                </li>
                <li className={`TabNavigation-item text-center w-50 ${showArchived && 'active'}`} key="archived">
                  <a
                    onClick={() =>
                      this.setState({
                        showArchived: !showArchived,
                        searchValue: '',
                        filteredOrganizations: this.search(organizations, '', !showArchived),
                      })
                    }
                  >
                    <span className="Text--bold">{t('react.organization.archived')}</span>
                  </a>
                </li>
              </ul>
            </div>
          )}

          <DefaultList
            organizations={filteredOrganizations}
            loading={loading}
            loaded={loaded}
            currentOrganizationId={currentOrganization.id}
            hasActiveSearch={searchValue?.length > 0}
            listSpacing={12}
          />

          <div className="pa-12 text-center delimiter-top">
            <Button
              tag="a"
              icon="plus"
              kind="primary"
              className="w-100 text-left"
              href={createOrganization.link}
              data-test-id="create-organization"
            >
              {createOrganization.text}
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default OrganizationsDropdown;
