import cs from 'classnames';
import React, { useState, useEffect, useMemo, useContext } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Manager, Reference, Popper } from 'react-popper';
import { useMutation, gql } from '@apollo/client';
import { debounce } from 'lodash';
import Button from '../../Button';
import { Loader } from '../../Icons';
import { Col, Row } from '../../layout';
import OrderContext from '../OrderContext';
import Tooltip from '../../Tooltip';
import Icon from '../../Icon';
import Link from '../../Link';
import { t } from '../../../i18n';
import { getErrorsFromData, getNotificationTextFromData } from '../../../graphql_provider';
import { GrayBox } from '../LoadingTable';
import { MultiEditSelect } from '../MultiEdit';
import ConditionalWrapper from '../../ConditionalWrapper';

const OrderFromContext = props => {
  const contextData = useContext(OrderContext);
  return <Order {...contextData} {...props} />;
};

export const Order = React.memo(
  ({
    order,
    reverseOrder,
    orderedByDescFirst,
    children,
    currentOrderDirection,
    currentOrder,
    changeOrder,
    changeOrderDirection,
    ...rest
  }) => {
    const upDirection = reverseOrder ? 'desc' : 'asc';
    const downDirection = reverseOrder ? 'asc' : 'desc';
    const active = order === currentOrder;

    const toggleOrder = () => {
      if (!active) {
        changeOrder(order);
        changeOrderDirection(orderedByDescFirst ? downDirection : upDirection);
      } else {
        changeOrderDirection(currentOrderDirection === upDirection ? downDirection : upDirection);
      }
    };
    if (!order) {
      return null;
    }
    let direction;
    if (active) {
      direction = currentOrderDirection === upDirection ? 'up' : 'down';
    } else {
      direction = orderedByDescFirst ? 'down' : 'up';
    }

    return (
      <Row {...rest}>
        <Col shrink justifyCenter onClick={toggleOrder} style={{ cursor: 'pointer', maxWidth: 'calc(300px - 23px)' }}>
          {children}
        </Col>
        <Col shrink>
          <Tooltip
            className="pa-8 negative-ma-8"
            text={
              currentOrderDirection === upDirection
                ? t(`order.${upDirection}`, { default: `Order ${upDirection}` })
                : t(`order.${downDirection}`, { default: `Order ${downDirection}` })
            }
          >
            <Link onClick={toggleOrder}>
              <div className="clickable">
                <Icon
                  className={cs('Sticky-sortArrow', { active })}
                  kind={`arrow-${direction}`}
                  size="16px"
                  data-test-id={`order-arrow-${active ? 'active' : ''}`}
                  data-test-direction={direction}
                  inheritColor
                />
              </div>
            </Link>
          </Tooltip>
        </Col>
      </Row>
    );
  }
);

Order.propTypes = {
  children: PropTypes.any,
  order: PropTypes.string.isRequired,
  reverseOrder: PropTypes.bool.isRequired,
  orderedByDescFirst: PropTypes.bool.isRequired,
  currentOrderDirection: PropTypes.string,
  currentOrder: PropTypes.string,
  changeOrder: PropTypes.func,
  changeOrderDirection: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export const WrapperCell = Component => {
  const WrapperCellComponent = React.memo(
    ({
      withCheckbox,
      order,
      orderedByDescFirst,
      editable,
      editButtonAsProp,
      width,
      setRef,
      left,
      last,
      first,
      smallPadding,
      emptyCell,
      right,
      reverseOrder,
      fixed,
      cellCouldBeEmpty,
      ...props
    }) => {
      const ResolvedComponent = useMemo(
        () => (editable ? EditableCellWithPopoverWrapper(Component, editable, editButtonAsProp) : Component),
        [editable, Component, editButtonAsProp]
      );
      let hasChildren = true;
      if (typeof props.children === 'undefined') hasChildren = false;
      else if (
        typeof props.children === 'object' &&
        props.children !== null &&
        !Array.isArray(props.children) &&
        Object.keys(props.children).length !== 0
      ) {
        hasChildren = Object.values(props.children).find(i => i !== undefined) !== undefined;
      }
      return (
        <div
          ref={setRef}
          data-test-id={`table-cell-${props.id}`}
          className={cs('Sticky-Cell', {
            'Sticky-Cell--fixed': fixed,
            'Sticky-Cell--fixedEmptyCell': emptyCell,
            'Sticky-Cell--fixed-last': fixed && last,
            'Sticky-Cell--fixed-first': fixed && first,
          })}
          style={{
            ...((fixed === 'left' && { left: `${left || 0}px` }) ||
              (fixed === 'right' && { right: `${right || 0}px` }) ||
              {}),
            ...(width && !props.multiEditView ? { width, minWidth: width, maxWidth: width } : {}),
            height: props.multiEditView && 'min-content',
          }}
        >
          <Row noPadding>
            {withCheckbox && <MultiEditSelect header className="pr-8" />}
            <ConditionalWrapper
              condition={order}
              wrapper={child => (
                <OrderFromContext order={order} reverseOrder={reverseOrder} orderedByDescFirst={orderedByDescFirst}>
                  {child}
                </OrderFromContext>
              )}
            >
              {cellCouldBeEmpty === true && props.children === null
                ? null
                : (!hasChildren && <GrayBox short />) || <ResolvedComponent {...props} />}
            </ConditionalWrapper>
          </Row>
        </div>
      );
    }
  );

  WrapperCellComponent.propTypes = {
    cellCouldBeEmpty: PropTypes.bool,
    editable: PropTypes.any,
    editButtonAsProp: PropTypes.bool,
    emptyCell: PropTypes.bool,
    first: PropTypes.bool,
    fixed: PropTypes.string,
    children: PropTypes.any,
    id: PropTypes.string,
    last: PropTypes.bool,
    left: PropTypes.number,
    multiEditView: PropTypes.bool,
    order: PropTypes.string,
    orderedByDescFirst: PropTypes.bool,
    reverseOrder: PropTypes.bool,
    right: PropTypes.number,
    setRef: PropTypes.func,
    smallPadding: PropTypes.bool,
    width: PropTypes.string,
    withCheckbox: PropTypes.bool,
  };

  return WrapperCellComponent;
};

export const EditableCellWithPopoverWrapper = (Component, editable, editButtonAsProp = false) => {
  const EditableCellWithPopoverWrapperComponent = React.memo(props => {
    const [editing, changeEdit] = useState(false);
    const [showEdit, changeShowEdit] = useState(false);
    const EditableComponent = editable;

    const debouncedHandleMouseEnter = debounce(() => changeShowEdit(true), 200);

    const handlOnMouseLeave = () => {
      changeShowEdit(false);
      debouncedHandleMouseEnter.cancel();
    };

    const editButton = showEdit && (
      <Col shrink>
        <Button
          tertiary
          onlyIcon
          onClick={() => changeEdit(true)}
          className="ml-4"
          icon="edit-value"
          data-test-id={`table-rename-${props.id}`}
        />
      </Col>
    );

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <Row
              setRef={ref}
              noPadding
              center
              onMouseEnter={debouncedHandleMouseEnter}
              onMouseLeave={handlOnMouseLeave}
            >
              <Col grow justifyCenter style={{ margin: '-10px -5px', padding: '10px 5px' }} className="pos-relative">
                <Component {...props} editButton={editButtonAsProp && editButton} />
              </Col>
              {!editButtonAsProp && editButton}
            </Row>
          )}
        </Reference>
        {editing &&
          ReactDOM.createPortal(
            <Popper placement="bottom">
              {({ ref, style, placement }) => (
                <div className="Sticky-Popover" ref={ref} style={{ ...style }} data-placement={placement}>
                  <EditableComponent {...props} columnHeader={props.columnHeader} onClose={() => changeEdit(false)}>
                    {props.children}
                  </EditableComponent>
                </div>
              )}
            </Popper>,
            document.body
          )}
      </Manager>
    );
  });
  EditableCellWithPopoverWrapperComponent.propTypes = {
    columnHeader: PropTypes.any,
    children: PropTypes.any,
    id: PropTypes.string,
    multiEditView: PropTypes.bool,
  };

  return EditableCellWithPopoverWrapperComponent;
};

const safeSerializeChildren = children => {
  if (typeof children === 'string' || typeof children === 'number') return { children };
  try {
    JSON.stringify(children);
    return children;
  } catch (error) {
    return Object.keys(children)
      .filter(key => {
        try {
          JSON.stringify(children[key]);
          return true;
        } catch (err) {
          return false;
        }
      })
      .reduce((acc, key) => ({ ...acc, [key]: children[key] }), {});
  }
};

const getUniqMutationName = name => `${name}${Math.floor(Math.random() * 10000)}:${name}`;

const constructMutation = mutation => {
  const name = mutation.definitions[0].selectionSet.selections[0].name.value;
  const body = mutation.loc.source.body;

  const partionalResult = body.split(name);
  partionalResult.shift();

  const result = [getUniqMutationName(name), partionalResult].join('').split('}');
  result.pop();

  return result.join('}');
};

const extractVariables = mutation => {
  const body = mutation.loc.source.body;
  const matched = body.match(/mutation ([^{]+)/);

  if (matched && matched[1]) {
    return matched[1];
  }
  return '';
};

const getEditQuery = ({ multiEditView, stateChildren, selectedData, editQuery }) => {
  if (!multiEditView) return editQuery(stateChildren);

  const mutation = selectedData.map(rowData => constructMutation(editQuery(rowData)(stateChildren))).join('\n');

  return gql`mutation ${extractVariables(editQuery(selectedData[0])(stateChildren))}{${mutation}}`;
};

export const EditableWrapper = Component => {
  const EditableWrapperComponent = React.memo(
    ({ onClose, onUpdate, children, columnHeader, editQuery, canEdit, ...rest }) => {
      const [stateChildren, changeChildren] = useState(children);
      useEffect(() => changeChildren(children), [JSON.stringify(safeSerializeChildren(children))]);

      const mutationQuery = getEditQuery({ ...rest, stateChildren, editQuery });

      const [update, { data, loading, error }] = useMutation(mutationQuery, {
        variables: safeSerializeChildren(stateChildren),
        onCompleted: responseData => {
          if (typeof window.NotificationCenter === 'function') {
            const err = getErrorsFromData(responseData);
            if (err.length > 0) {
              new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
            } else {
              const text =
                getNotificationTextFromData(responseData) ||
                t('flash.fb_features.successfully_saved', { default: 'Successfully saved' });

              new window.NotificationCenter().show_success(text);

              if (typeof onUpdate === 'function') onUpdate();
              if (typeof onClose === 'function') onClose();
            }
          }
        },
      });

      const errors = getErrorsFromData(data);

      const onChangeAndSubmit = newChildrenState => {
        const mutation = getEditQuery({ ...rest, stateChildren: newChildrenState, editQuery });
        update({ mutation, variables: safeSerializeChildren(newChildrenState) });
      };
      return (
        <ConditionalWrapper condition={!rest.multiEditView} wrapper={child => <Col grow>{child}</Col>}>
          <form>
            <fieldset disabled={!canEdit}>
              <Component
                {...rest}
                canEdit={canEdit}
                columnHeader={columnHeader}
                onChangeAndSubmit={onChangeAndSubmit}
                onChange={changeChildren}
                onClose={onClose}
                onSubmit={update}
                loading={loading}
              >
                {stateChildren}
              </Component>
            </fieldset>
          </form>
          {loading && <Loader size="medium" absolute />}
          {error && <div className="danger">{t('react.unable_to_save_this_value')}</div>}
          {errors.length > 0 && <div className={cs('danger', { 'ml-16': rest.multiEditView })}>{errors.join(',')}</div>}
        </ConditionalWrapper>
      );
    }
  );
  EditableWrapperComponent.propTypes = {
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
    children: PropTypes.any,
    columnHeader: PropTypes.any,
    editQuery: PropTypes.any,
    canEdit: PropTypes.any,
  };

  return EditableWrapperComponent;
};

const MultiEditWrapper = ({ children, onChange, loading, onSubmit, multiEditView, Component, valid, setValid }) => (
  <Row center padding={!multiEditView ? 'm' : 'l'}>
    <Col>
      <Component onChange={onChange} multiEditView={multiEditView} onValidityChange={setValid}>
        {children}
      </Component>
    </Col>

    <Col shrink>
      <Button
        kind="primary"
        icon="check"
        thin
        onClick={onSubmit}
        disabled={loading || !valid}
        data-test-id="table-rename-save"
      >
        {t('react.popover.save')}
      </Button>
    </Col>
  </Row>
);

const editableWrapperProptypes = {
  children: PropTypes.any,
  multiEditView: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
};

MultiEditWrapper.propTypes = { ...editableWrapperProptypes, Component: PropTypes.any };

export const EditablePopoverWrapper = Component => {
  const EditablePopoverWrapperComponent = React.memo(props => {
    const [valid, setValid] = useState(true);
    if (props.multiEditView) {
      return <MultiEditWrapper setValid={setValid} valid={valid} {...props} Component={Component} />;
    }
    const { onClose, children, onChange, columnHeader, loading, onSubmit } = props;

    return (
      <React.Fragment>
        {columnHeader}
        <Component onChange={onChange} onValidityChange={setValid}>
          {children}
        </Component>

        <Row justifyEnd className="mt-8">
          <Col shrink>
            <Button
              kind="primary"
              icon="check"
              thin
              onClick={onSubmit}
              disabled={loading || !valid}
              data-test-id="table-rename-save"
            >
              {t('react.popover.save')}
            </Button>
          </Col>

          <Col shrink>
            <Button kind="secondary" icon="close" thin onClick={onClose} data-test-id="table-rename-cancel">
              {t('react.popover.cancel')}
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  });
  EditablePopoverWrapperComponent.propTypes = {
    ...editableWrapperProptypes,
    columnHeader: PropTypes.any,
    onClose: PropTypes.func,
  };

  return EditablePopoverWrapperComponent;
};
