/* eslint-disable jsx-a11y/alt-text */

import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from './index';
import funelInput from '../scss_stylesheets/images/funel_input.svg';
import funelRest from '../scss_stylesheets/images/funel_rest.svg';

export const FunelInputCampaignProducts = ({ children }) => (
  <Row center className="ml-24">
    <Col shrink>
      <img src={funelInput} />
    </Col>
    <Col shrink className="pos-relative">
      <span>{children}</span>
    </Col>
  </Row>
);

FunelInputCampaignProducts.propTypes = { children: PropTypes.any };

export const FunelCampaignStage = ({ children }) => (
  <Row center className="ml-24">
    <Col shrink>
      <img src={funelRest} />
    </Col>
    <Col shrink nowrap className="pos-relative">
      <span>{children}</span>
    </Col>
  </Row>
);

FunelCampaignStage.propTypes = { children: PropTypes.any };
