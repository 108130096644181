import React from 'react';
import { t } from '../../../i18n';
import { IconWithTooltipCell } from './cells';
import { Tooltip, Icon } from '../../index';
// eslint-disable-next-line import/no-named-as-default
import setupColumn from './setupColumn';

const mapping = feature =>
  setupColumn({
    id: 'imageEditor',
    category: 'setup',
    header: () => (
      <Tooltip text={t(feature, { scope: 'react.tables.image_editor_tooltip' })} className="line-height-1">
        <Icon kind={feature === 'feed_export' ? 'image-export' : 'image-type'} size="16px" inheritColor />
      </Tooltip>
    ),
    cell: IconWithTooltipCell,
    graphqlFields: ['imageGeneratorStatus'],
    resolveChildren: ({ imageGeneratorStatus }) => ({
      icon: imageGeneratorStatus, // oneOf([active, paused])
      tooltip: t(imageGeneratorStatus, { scope: 'react.tables' }),
      color: '#8C969F',
      size: '16px',
      inheritColor: false,
    }),
  });

export default mapping;
