/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Button } from '../index';
import { getErrorsFromData } from '../../graphql_provider';

const CreateViewReport = ({
  organizationId,
  reportId,
  conditions,
  selectedDimensions,
  zoomFilters,
  selectedFilters,
  action,
  disabled,
}) => {
  const [update, { data, loading, error }] = useMutation(
    gql`mutation CreateAnalyticsReportView($conditions: [String!], $selectedFilters: [String!]) {
    createAnalyticsReportView(
      organizationId: ${organizationId},
      analyticsReportId: ${reportId},
      action: ${action},
      conditions: $conditions
      selectedFilters: $selectedFilters
      ) {
        analyticsReportView {
          id
          conditions
          selectedCount
        }
        errors
      } }`,
    {
      variables: {
        conditions: conditions.map(JSON.stringify),
        selectedFilters: selectedFilters.map(JSON.stringify),
      },
      onCompleted: responseData => {
        if (typeof window.NotificationCenter === 'function') {
          const err = getErrorsFromData(responseData);
          if (err.length > 0) {
            new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
          } else {
            new window.NotificationCenter().show_success('Successfully saved');
          }
        }
      },
    }
  );

  return (
    <div>
      <Button primary disabled={disabled} onClick={() => update()}>
        {action}
      </Button>
    </div>
  );
};

CreateViewReport.propTypes = {
  organizationId: PropTypes.numbner,
  reportId: PropTypes.numbner,
  conditions: PropTypes.array,
  selectedDimensions: PropTypes.array,
  zoomFilters: PropTypes.array,
  selectedFilters: PropTypes.array,
  action: PropTypes.string,
};

export default CreateViewReport;
