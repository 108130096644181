import { gql } from '@apollo/client';
import { setupColumn } from '../setupColumn';
import { TextCell, MoneyCell, SwitchCell } from '../cells';
import { MoneyEdit } from '../editable';
import { t } from '../../../../i18n';
import { sklikHeader } from '../systemHeaders';

const category = 'sklik';

export const sklikMapping = {
  ...setupColumn({
    id: 'sklikSystemId',
    graphqlFields: { sklikSetting: ['systemIdName'] },
    category,
    order: 'sklik_setting.system_id',
    header: () => sklikHeader(t('activerecord.attributes.sklik_setting.system_id')),
    cell: TextCell,
    resolveChildren: data => data && data.sklikSetting && data.sklikSetting.systemIdName,
  }),
  ...setupColumn({
    id: 'sklikBudget',
    graphqlFields: ['sklikCurrency', { sklikSetting: ['budget'] }],
    order: 'sklik_setting.budget',
    header: () => sklikHeader(t('activerecord.attributes.sklik_setting.budget')),
    category,
    resolveChildren: data =>
      (data &&
        data.sklikSetting && {
          id: data.sklikSetting.id,
          currency: data.sklikCurrency,
          money: data.sklikSetting.budget,
        }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    editQuery: data =>
      data?.sklikSetting?.id &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateSklikSetting(id: ${id || -1}, budget: ${money || 0}) {
            sklikSetting {
              id
              budget
            }
            errors
          }
        }
      `;
      }),
  }),

  ...setupColumn({
    id: 'sklikMaxCpc',
    graphqlFields: ['sklikCurrency', { sklikSetting: ['maxCpc'] }],
    order: 'sklik_setting.max_cpc',
    header: () => sklikHeader(t('activerecord.attributes.sklik_setting.max_cpc')),
    category,
    resolveChildren: data =>
      (data &&
        data.sklikSetting && {
          id: data.sklikSetting.id,
          currency: data.sklikCurrency,
          money: data.sklikSetting.maxCpc,
        }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    editQuery: data =>
      data?.sklikSetting?.id &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateSklikSetting(id: ${id || -1}, maxCpc: ${money || 0}) {
            sklikSetting {
              id
              maxCpc
            }
            errors
          }
        }
      `;
      }),
  }),
  ...setupColumn({
    id: 'syncCampaignBudget',
    graphqlFields: [{ sklikSetting: ['syncCampaignBudget'] }],
    resolveChildren: data => data && data.sklikSetting && data.sklikSetting.syncCampaignBudget,
    cell: SwitchCell,
    category,
    header: () => sklikHeader(t('activerecord.attributes.sklik_setting.sync_campaign_budget')),
    editQuery: data =>
      data?.sklikSetting?.id &&
      (synced =>
        gql`mutation { updateSklikSetting(id: ${data &&
          data.sklikSetting &&
          data.sklikSetting.id}, syncCampaignBudget: ${synced}) { sklikSetting { id syncCampaignBudget } errors } }`),
  }),
  ...setupColumn({
    id: 'syncAdgroupCpc',
    graphqlFields: [{ sklikSetting: ['syncAdgroupCpc'] }],
    resolveChildren: data => data && data.sklikSetting && data.sklikSetting.syncAdgroupCpc,
    cell: SwitchCell,
    category,
    header: () => sklikHeader(t('activerecord.attributes.sklik_setting.sync_adgroup_cpc')),
    editQuery: data =>
      data?.sklikSetting?.id &&
      (synced =>
        gql`mutation { updateSklikSetting(id: ${data &&
          data.sklikSetting &&
          data.sklikSetting.id}, syncAdgroupCpc: ${synced}) { sklikSetting { id syncAdgroupCpc } errors } }`),
  }),
};

export default sklikMapping;
