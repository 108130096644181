/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import QuerySelect from './QuerySelect';
import { Row, Col } from '../index';

const QUERY_REPORT_SELECTION = gql`
  query OrganizationReportSelection($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      analyticsReports {
        id
        systemIds
        createdAt
      }
    }
  }
`;

const ReportSelection = ({ organizationId, selectReport, reportId }) => (
  <Row>
    <Col shrink>
      <QuerySelect
        onChange={({ target: { value } }) => selectReport(parseInt(value, 10))}
        translatedName="Report selection"
        name="reports"
        query={QUERY_REPORT_SELECTION}
        getCollection={data => data.organization.analyticsReports}
        value={reportId}
        variables={{
          organizationId: parseInt(organizationId, 10),
        }}
      >
        {({ id, systemIds, createdAt }) => (
          <option key={id} value={id}>
            #{id} {systemIds.join(', ')} ({createdAt})
          </option>
        )}
      </QuerySelect>
    </Col>
  </Row>
);

export default ReportSelection;
