import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import { Row, Col } from '../components/layout';
import Button from '../components/Button';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter } from '../components';

class ShopCategory extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
    category: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    category: this.props.category || '',
  };

  handleCategory = ({ target: { value } }) => {
    if (value !== this.state.category) {
      this.setState({ category: value });
      this.props.updateFieldData(this.props.id, { category: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { category } = this.state;

    return (
      <Row center padding="l" data-test-id="shop_categories" className="mb-8">
        <Col grow data-test-id="shop_categories-section">
          <PlaceholderInput
            defaultValue={category}
            onChange={this.handleCategory}
            placeholder={t('views.shop_categories.fields.category', { default: 'Category' })}
            {...getSubFiledOptions('category')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.shop_categories.example')} <span class="Text--bold">${t(
            'views.shop_categories.example_text'
          )}</span>`}
          data-field={`shop_categories.${category}`}
        >
          <div className="Text">
            {t('views.shop_categories.example')}{' '}
            <span className="Text--bold">{t('views.shop_categories.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default ShopCategory;
