import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { hot } from 'react-hot-loader/root';
import { SortableElement, SortableContainer } from '../../../Sortable';
import { CustomizeImageDropdown, SelectImagesModal } from './index';
import arrayMove from '../../../../utils/arrayMove';
import { Button, Col, DragHandle, Ellipsis, InfoBox, Row, Loader } from '../../../index';
import { t, tHtml } from '../../../../i18n';
import { transformToSlideshowData } from '../utils';

const SortableItem = SortableElement(
  ({ createNewLink, editLink, disabledMove = false, label, notify, onRemove, url, value, ...rest }) => (
    <Row
      center
      className={cs('ShowOnHoverWrapper', 'Preview-tileItem', 'Preview-tileItem--dragable', 'mv-4')}
      style={{ zIndex: 'auto', height: '50px', paddingRight: '4px' }}
    >
      <Col shrink>
        <DragHandle disabled={disabledMove} />
      </Col>

      <Col shrink style={{ overflow: 'hidden' }}>
        <img style={{ maxHeight: '48px', maxWidth: '48px', aspectRatio: 'auto' }} src={url} alt={label} />
      </Col>

      <Col grow>
        <Ellipsis>{label}</Ellipsis>
      </Col>
      <Col width="176px">
        <CustomizeImageDropdown
          {...rest}
          createNewLink={createNewLink}
          editLink={editLink}
          notify={notify}
          value={value}
        />
      </Col>
      <Col shrink>
        <Button tertiary size="small" onlyIcon icon="close" onClick={() => onRemove(value)} />
      </Col>
    </Row>
  )
);

export const SortableList = SortableContainer(({ collection = [], ...rest }) => (
  <div>
    {collection.map((el, index) => (
      <SortableItem
        {...rest}
        key={[index, el?.value, el?.label].join('-')}
        index={index}
        value={el?.value}
        label={el?.label}
        createNewLink={el?.duplicateCopyLink ? el?.duplicateCopyLink : el?.newImageLink}
        url={el?.url}
        editLink={el?.editLink ? el?.editLink : null}
        notify={el?.notify}
      />
    ))}
  </div>
));

const AddImageButton = ({ onClick }) => (
  <Button kind="secondary" className="mv-16" onClick={() => onClick(true)}>
    {t('react.fb_adtexts.add_images_to_slideshow')}
  </Button>
);

const SlideshowImages = ({ data, loading, name, newImageLink, notify, selectedValues, setSelectedValues, ...rest }) => {
  const [showModal, setModalVisibility] = useState(false);
  const wrapper = useRef(null);

  const updateForm = () => {
    if (wrapper.current) {
      const form = wrapper.current.closest('form');
      if (form) {
        form.dispatchEvent(new window.Event('change', { bubbles: true }));
      }
    }
  };
  const onRemove = value => {
    const filtredValues = selectedValues.filter(val => val !== value);
    setSelectedValues(filtredValues);
    updateForm();
  };

  const modalSubmit = newSelectedValues => {
    setSelectedValues(newSelectedValues);
    setModalVisibility(false);
    updateForm();
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setSelectedValues(arrayMove([...selectedValues], oldIndex, newIndex));
    updateForm();
  };

  const transformedData = transformToSlideshowData(data, newImageLink, notify);
  const selectedObjects = selectedValues.map(val => transformedData.find(({ value }) => value === val));

  return (
    <div ref={wrapper}>
      {loading ? (
        <Loader />
      ) : (
        <React.Fragment>
          {selectedValues.length === 0 && (
            <React.Fragment>
              <InfoBox type="warning" withIcon className="mb-16">
                {t('react.fb_adtexts.no_image_selected')}
              </InfoBox>
              <AddImageButton onClick={setModalVisibility} />
            </React.Fragment>
          )}

          {selectedValues.length !== 0 && (
            <React.Fragment>
              <SortableList
                {...rest}
                lockAxis="y"
                helperClass="DragHelper"
                useDragHandle
                collection={selectedObjects}
                onRemove={onRemove}
                onSortEnd={onSortEnd}
              />
              <AddImageButton onClick={setModalVisibility} />
              <InfoBox type="info" withIcon className="mb-16">
                {tHtml('react.slideshow_images.info_html', {
                  default:
                    'You can use selected images also as creatives in other ads in this campaign. If you select on other ad different image for creative it will be automatically added to selection for this slideshow. <a href="#"> Learn More </a>',
                })}
              </InfoBox>
            </React.Fragment>
          )}

          {showModal && (
            <SelectImagesModal
              selectedValues={selectedValues}
              allImages={transformedData}
              heading={t('react.fb_adtexts.add_images_to_slideshow')}
              onClose={() => setModalVisibility(false)}
              modalSubmit={modalSubmit}
            />
          )}
        </React.Fragment>
      )}

      {selectedValues.map(el => (
        <input type="hidden" name={`${name}[]`} key={el} value={el} />
      ))}
    </div>
  );
};

AddImageButton.propTypes = {
  onClick: PropTypes.func,
};

SlideshowImages.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  name: PropTypes.string,
  newImageLink: PropTypes.string,
  notify: PropTypes.string,
  selectedValues: PropTypes.array,
  setSelectedValues: PropTypes.func,
};

SortableItem.propTypes = {
  createNewLink: PropTypes.string,
  disabledMove: PropTypes.bool,
  editLink: PropTypes.string,
  label: PropTypes.string,
  notify: PropTypes.string,
  onRemove: PropTypes.func,
  url: PropTypes.string,
  value: PropTypes.string,
};

SortableList.propTypes = {
  columns: PropTypes.any,
};

export default hot(SlideshowImages);
