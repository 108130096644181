import { Rect, Circle, Text, Image, ImagePlaceholder, Svg } from './objects';

const mapping = {
  text: Text,
  img: Image,
  imgPlaceholder: ImagePlaceholder,
  rect: Rect,
  circle: Circle,
  svg: Svg,
  image_template: Image,
  image_snippet: Image,
};

export default mapping;
