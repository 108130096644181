/* eslint-disable react/no-danger,no-trailing-spaces,no-prototype-builtins */
/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import { Modal as ModalContainer, ModalBody } from '../components/Modal';
import deepSet from '../utils/deepSet';
import splitInputNameIntoKeysArray from '../utils/splitInputNameIntoKeysArray';

export function mapQueryConditionsToObject(data = []) {
  const conditionsAttributes = data
    .filter(c => c.name?.indexOf('conditions_attributes') !== -1)
    .map(c => {
      const index = c.name.indexOf('conditions_attributes');
      if (index !== -1) {
        const newName = c.name.substring(index).replace('conditions_attributes]', 'conditions');
        return { ...c, name: newName };
      }
      return c;
    });

  const result = [];
  let currentObject = {};
  let currentIndex = null;

  conditionsAttributes.forEach(condition => {
    const keys = splitInputNameIntoKeysArray(condition.name);
    const index = keys[1]; // the index of the conditions is the second key

    if (currentIndex !== index) {
      // we have a new set of conditions, so we create a new object
      currentObject = {};
      result.push(currentObject);
      currentIndex = index;
    } else if (currentObject.hasOwnProperty('group_type') && keys[2] === 'group_type') {
      // we have a new set of conditions with the same index, so we create a new object
      currentObject = {};
      result.push(currentObject);
    }

    deepSet(currentObject, keys.slice(2), condition.value, { useIntKeysAsArrayIndex: true });
  });

  return result;
}

class Modal extends React.PureComponent {
  static propTypes = {
    heading: PropTypes.any,
    size: PropTypes.oneOf(['full', 'medium', 'fullwithGrid']),
    onClose: PropTypes.func,
    url: PropTypes.string,
    conditions: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.oneOf(['edit', 'preview']).isRequired,
  };

  static defaultProps = {
    size: 'fullwithGrid',
  };

  state = {
    data: null,
    error: null,
  };

  componentDidMount() {
    const selectedIds = $('input[name="selected_ids[]"]')
      .map((i, e) => e.value)
      .get();
    $.ajax({
      type: this.props.type === 'edit' ? 'get' : 'post',
      url: this.props.url,
      data:
        this.props.type === 'edit'
          ? {
              selected_ids: selectedIds,
            }
          : JSON.stringify({
              conditions: mapQueryConditionsToObject(this.props.conditions),
            }),
      contentType: this.props.type === 'edit' ? undefined : 'application/json',
      dataType: 'html',
      success: data => {
        this.setState({ data });
      },
      error: (response, error) => {
        this.setState({ error });
      },
    });
  }

  componentDidUpdate() {
    setTimeout(() => {
      window.initReact();

      if (this.modalRef) {
        // this hack is for dangerouslySetInnerHTML
        const el = $(this.modalRef).find('script');
        if (el) {
          el.each((i, elScript) => {
            const elS = $(elScript);
            if (!elS.hasClass('react-modal-inited')) {
              window.eval(elS.text());
              elS.addClass('react-modal-inited');
            }
          });
        }

        $(this.modalRef)
          .find('form')
          .on('ajax:success', (e, data, status, xhr) => {
            if (status === 'nocontent' || xhr.status === 201) {
              $(this.modalRef).modal('hide');
              if (status === 'success' && typeof window.pushNewPlaceholder === 'function') {
                window.pushNewPlaceholder(data);
              } else if (typeof window.pushPlaceholderUpdate === 'function') {
                window.pushPlaceholderUpdate();
              }
              if (typeof window.NotificationCenter !== 'undefined') {
                new window.NotificationCenter().show_success('Saved changes');
              }
            } else {
              this.setState({ data });
              setTimeout(window.initReact, 100);
            }
          })
          .on('ajax:error', (e, xhr, status, error) => {
            // eslint-disable-next-line no-console
            console.log('ERRROR', e, error, status, xhr);
          });
      }
    }, 400);
  }

  setModalContainer = ref => {
    this.modalRef = ref;
    $(this.modalRef).on('shown.bs.modal', () => {
      $(this.modalRef).on('hide.bs.modal', event => {
        if (!event.isDefaultPrevented() && typeof this.props.onClose === 'function') {
          this.props.onClose();
          Array.from(document.getElementsByClassName('js-react-refresh-after-in-place-editing-close')).forEach(el => {
            if (el) {
              $(el).trigger('change');
            }
          });
        }
      });
    });
  };

  render() {
    const { heading, size } = this.props;
    const { data, error } = this.state;

    return (
      <ModalContainer
        data-test-id="custom-variable-modal"
        loading={!data}
        error={error}
        size={size}
        heading={heading}
        setRef={this.setModalContainer}
      >
        {data && <span dangerouslySetInnerHTML={{ __html: data }} />}
      </ModalContainer>
    );
  }
}

export default Modal;
