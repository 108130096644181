import React from 'react';
import { SystemIconWithText } from '../../index';

export const adwordsHeader = text => (
  <SystemIconWithText system="adwords" size={14}>
    {text}
  </SystemIconWithText>
);

export const bingHeader = text => (
  <SystemIconWithText system="bing" size={14}>
    {text}
  </SystemIconWithText>
);

export const facebookHeader = text => (
  <SystemIconWithText system="facebook" size={14}>
    {text}
  </SystemIconWithText>
);

export const sklikHeader = text => (
  <SystemIconWithText system="sklik" size={14}>
    {text}
  </SystemIconWithText>
);
