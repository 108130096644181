import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import { Button, Row, Col, Tile, UsedByGraph, Loader } from '../components';
import { t } from '../i18n';
import { QUERY } from './Condition';
import InputDataModal from '../input_data_modal';
import { serializeConditions } from '../utils/condition';

const ConditionSummary = props => {
  const [open, setOpen] = useState(false);
  const { fieldData, options = {}, hideProductViewButton, additionalState } = props;

  const [variables] = useDebounce(
    {
      ...(options.load_options_filter || {}),
      conditions: serializeConditions(fieldData),
    },
    500
  );

  const { loading, data, refetch } = useQuery(QUERY, {
    variables,
  });

  useEffect(() => {
    if (refetch && !loading && additionalState?.forceUpdate) {
      refetch();
    }
  }, [additionalState?.forceUpdate]);

  return (
    <Tile contentStyle={{ padding: '8px 16px' }} className="mt-16">
      <Row center>
        <Col shrink>
          {loading && <Loader size="small" />}
          {!loading && (
            <UsedByGraph
              kind="green"
              size="md"
              products={data?.selectedCount || 0}
              productsTotal={data?.allCount || 0}
              adSetsTotal={-1}
            />
          )}
        </Col>
        <Col grow className="Text Text--softGray Text--semibold pl-8">
          <span>{t('react.condition_summary.selected_products_count', { products: data?.selectedCount || 0 })}</span>
        </Col>

        <Col shrink>
          {open && (
            <InputDataModal
              onClose={() => setOpen(false)}
              {...(options.load_options_filter || {})}
              conditions={fieldData}
            />
          )}
          {!hideProductViewButton && !options.hide_product_view_button && (
            <Button icon="magnifier" secondary onClick={() => setOpen(true)} data-test-id="view-products-btn">
              {t('react.condition_summary.view_products')}
            </Button>
          )}
        </Col>
      </Row>
    </Tile>
  );
};
ConditionSummary.propTypes = {
  fieldData: PropTypes.array,
  additionalState: PropTypes.object,
  options: PropTypes.object,
  hideProductViewButton: PropTypes.bool,
};

export default ConditionSummary;
