import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { orderBy } from 'lodash';
import { formatNumber, formatInteger, t } from '../../i18n';
import { Ellipsis, Col, Row, Button, InfoBox } from '../index';
import Pagination, { setPageToUrl } from '../Pagination';
import { Order } from '../table/mapping/wrappers';
import { Select } from '../form';
import { generateCSV, generateHeader } from './utils';
import { Loader } from '../Icons';
import downloadTextFile from '../../utils/downloadTextFile';

const CSV = ({ header, unfilteredTableData }) => {
  const download = () => {
    const csvData = generateCSV(header, unfilteredTableData);
    downloadTextFile(csvData, 'data-analysis', 'csv');
  };

  return (
    <Button tertiary size="small" icon="download" data-test-id="data-analysis-csv-btn" onClick={download}>
      {t('fb_adtext_call_to_action_type.DOWNLOAD')}
    </Button>
  );
};
CSV.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  unfilteredTableData: PropTypes.arrayOf(PropTypes.object),
};

export const AdditionalButtons = ({ header, unfilteredTableData }) => {
  const container =
    document.getElementById('js-heading-additional-buttons') || document.getElementById('js-sub-additional-buttons');

  if (!container) return null;

  return ReactDOM.createPortal(
    <Row className="ml-8">
      <CSV header={header} unfilteredTableData={unfilteredTableData} />
    </Row>,
    container
  );
};
AdditionalButtons.propTypes = {
  header: PropTypes.arrayOf(PropTypes.object),
  unfilteredTableData: PropTypes.arrayOf(PropTypes.object),
};

const TableCell = ({ value, rowValue, showAsFloat, showAsInteger }) => {
  let showValue = rowValue;
  if (showAsFloat) {
    showValue = formatNumber(rowValue, { precision: 2 });
  } else if (showAsInteger) {
    showValue = formatInteger(rowValue);
  }

  return (
    <div data-test-id={`table-column-${value}`} className="Sticky-Cell">
      <Ellipsis>{showValue}</Ellipsis>
    </div>
  );
};
TableCell.propTypes = {
  value: PropTypes.string,
  rowValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  showAsFloat: PropTypes.bool,
  showAsInteger: PropTypes.bool,
};

const orderData = ({ data, order, orderDirection }) => orderBy(data, [order], [orderDirection]);

const DataAnalysisTable = ({ data, loading, metrics, dimensions }) => {
  const perOptions = [20, 50, 100];

  const [header, setHeader] = useState(generateHeader(dimensions, metrics));
  useEffect(() => setHeader(generateHeader(dimensions, metrics)), [dimensions, metrics]);

  const [order, setOrder] = useState('product_count');
  const [orderDirection, setOrderDirection] = useState('desc');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);

  const generateFilteredData = () =>
    orderData({
      data,
      order,
      orderDirection,
    });

  const [filteredOrderedData, setFilteredOrderedData] = useState(generateFilteredData());
  useEffect(() => {
    if (page !== 0) {
      setPage(0);
      setPageToUrl(0);
    }
    setFilteredOrderedData(generateFilteredData());
  }, [data, order, orderDirection]);

  const tableData = filteredOrderedData.slice(0 + limit * page, limit * (page + 1));

  const totalUnfilteredCount = data.length;

  const changeOrder = change => {
    setOrder(change);
  };

  const changeOrderDirection = change => {
    setOrderDirection(change);
  };

  const changeLimit = ({ target: { value } }) => {
    setLimit(value);
    setPage(0);
    setPageToUrl(0);
  };

  const numberOfFixedColumns = dimensions.length || 0;

  return (
    <React.Fragment>
      <Row shrink>
        <Col shrink>
          {!loading && totalUnfilteredCount > 0 && <AdditionalButtons header={header} unfilteredTableData={data} />}
        </Col>
      </Row>
      {totalUnfilteredCount === 0 && <InfoBox type="warning">{t('data_analysis.no_filtred_data')}</InfoBox>}
      {loading ? (
        <div className="pos-relative" style={{ height: '300px' }}>
          <Loader absolute size="big" />
        </div>
      ) : (
        <div className="pos-relative">
          <div className="Sticky-Wrapper Sticky-Wrapper--scrollable">
            <div className={`Sticky-Table Sticky-Table--fixedColumns${numberOfFixedColumns}`} data-test-id="table">
              <div className="Sticky-Header" data-test-id="table-header">
                {header.map(({ value, label }) => (
                  <div key={[value, label, Math.random()].join('-')} className="Sticky-Cell">
                    <Row noPadding inline>
                      <Order
                        data-test-id={`table-header-${value}`}
                        currentOrder={order}
                        changeOrder={changeOrder}
                        changeOrderDirection={changeOrderDirection}
                        currentOrderDirection={orderDirection}
                        order={value}
                        reverseOrder={false}
                        orderedByDescFirst={false}
                      >
                        <Ellipsis>{label?.label || label}</Ellipsis>
                      </Order>
                    </Row>
                  </div>
                ))}
              </div>
              {tableData.map(rowData => (
                <div className="Sticky-Row" key={JSON.stringify(rowData)} data-test-id="analysis-row">
                  {header.map(column => (
                    <TableCell
                      {...column}
                      rowValue={rowData[column.value]}
                      key={[JSON.stringify(column), rowData[column.value], Math.random()].join('-')}
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>

          <Pagination
            data-test-id="pagination-info"
            data-total={totalUnfilteredCount}
            current={page}
            maxPages={Math.ceil(totalUnfilteredCount / limit)}
            changePage={e => () => setPage(e)}
            className="mt-16"
          >
            <Col shrink>
              {t('react.show', {
                default: 'Show',
              })}
            </Col>
            <Col shrink nowrap>
              <Select
                doNotUseInternalState
                name="products[perPage]"
                id="pagination-products[perPage]"
                value={limit}
                onChange={changeLimit}
                collection={perOptions.map(value => ({
                  value,
                  label: t('react.items_count', { items: value }),
                }))}
              />
            </Col>
            <Col shrink nowrap>
              {t('react.outof', {
                default: 'out of %{total}',
                total: formatInteger(totalUnfilteredCount || 0),
              })}
            </Col>
          </Pagination>
        </div>
      )}
    </React.Fragment>
  );
};
DataAnalysisTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  metrics: PropTypes.array,
  dimensions: PropTypes.array,
};

export default DataAnalysisTable;
