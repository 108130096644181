import React, { memo } from 'react';
import { Col, ColorPicker, UnitInput, Input, Select, SegmentedButton, SmartSelect } from '../../../index';
import styles from './styles';

const InputCol = ({
  property,
  showIf = true,
  shrink = false,
  grow = false,
  width,
  onChange,
  label,
  name,
  children,
  type = 'float',
  collection,
  placeholder,
  value,
  unit,
  beforeUnit,
  ...props
}) => {
  if (!showIf) {
    return <div style={styles.empty} />;
  }

  return (
    <Col shrink={shrink} grow={grow} width={width} style={{ ...props.style }}>
      {label && <label htmlFor={property}>{label}</label>}
      {type === 'integer' && (
        <Input
          doNotUseInternalState
          name={name || property}
          id={name || property}
          data-test-id={`test-input-${name || property}`}
          value={value}
          type="integer"
          onChange={e => onChange(property, e.target.value)}
        />
      )}
      {type === 'float' && (
        <UnitInput
          doNotUseInternalState
          beforeUnit={beforeUnit}
          unit={unit}
          name={name || property}
          id={name || property}
          data-test-id={`test-input-${name || property}`}
          value={value}
          onChange={e => onChange(property, e.target.value)}
        />
      )}
      {type === 'text' && (
        <Input
          doNotUseInternalState
          name={name || property}
          id={name || property}
          data-test-id={`test-input-${name || property}`}
          value={value}
          type="text"
          placeholder={placeholder}
          onChange={e => onChange(property, e.target.value)}
        />
      )}
      {type === 'select' && (
        <Select
          doNotUseInternalState
          name={name || property}
          id={name || property}
          testId={`test-input-${name || property}`}
          value={value}
          collection={collection}
          onChange={e => onChange(property, e.target.value)}
        >
          {children}
        </Select>
      )}
      {type === 'smartSelect' && (
        <SmartSelect
          doNotUseInternalState
          name={name || property}
          id={name || property}
          testId={`test-input-${name || property}`}
          value={value}
          collection={collection}
          onChange={e => onChange(property, e.target.value)}
        />
      )}
      {type === 'color' && (
        <ColorPicker
          doNotUseInternalState
          name={name || property}
          id={name || property}
          data-test-id={`test-input-${name || property}`}
          value={value}
          showOnEnd
          enableAlpha
          onChange={e => onChange(property, e.target.value)}
        />
      )}
      {type === 'segmented' && (
        <SegmentedButton
          name={name || property}
          id={name || property}
          value={value}
          data-test-id={`segmented-button-${name || property}`}
          doNotUseInternalState
          onlyIcons
          size="medium"
          onChange={e => onChange(property, e.target.value)}
          collection={collection}
        />
      )}
    </Col>
  );
};

export default memo(InputCol);
