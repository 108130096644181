/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import { Button, Select, Row, Col } from '../index';
import { getErrorsFromData } from '../../graphql_provider';
import QuerySelect from './QuerySelect';
import { Loader } from '../Icons';

const QUERY_ACCOUNT_SELECTION = gql`
  query OrganizationAccountSelection($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      subAccessAccounts(system: "adwords") {
        systemId
        name
      }
    }
  }
`;

const CreateNewReport = ({ organizationId, selectReport }) => {
  const [systemId, setSystemId] = useState('');
  const [rangeLength, setRangeLength] = useState(30);

  const [update, { data, loading, error }] = useMutation(
    gql`mutation {
      createAnalyticsReport(organizationId: ${organizationId}, rangeLength: ${rangeLength}, systemIds: ["${systemId}"]) {
        analyticsReport {
          id
          rangeLength
          systemIds
        }
        errors
      }
    }`,
    {
      variables: {},
      onCompleted: responseData => {
        if (typeof window.NotificationCenter === 'function') {
          const err = getErrorsFromData(responseData);
          if (err.length > 0) {
            new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
          } else {
            new window.NotificationCenter().show_success('Successfully saved');
            selectReport(responseData.createAnalyticsReport.analyticsReport.id);
          }
        }
      },
    }
  );

  return (
    <Row>
      <Col shrink>
        <QuerySelect
          onChange={({ target: { value } }) => setSystemId(value)}
          name="accounts"
          query={QUERY_ACCOUNT_SELECTION}
          getCollection={data => data.organization.subAccessAccounts}
          variables={{
            organizationId: parseInt(organizationId, 10),
          }}
        >
          {({ name, systemId }) => (
            <option key={systemId} value={systemId}>
              {name} ({systemId})
            </option>
          )}
        </QuerySelect>
      </Col>
      <Col shrink>
        <Select
          collection={[
            { value: 7, label: 'Last 7 days' },
            { value: 14, label: 'Last 14 days' },
            { value: 30, label: 'Last 30 days' },
            { value: 90, label: 'Last 90 days' },
            { value: 180, label: 'Last 180 days' },
          ]}
          value={rangeLength}
          onChange={({ target: { value } }) => setRangeLength(value)}
        />
      </Col>

      <Col shrink>
        <Button primary disabled={loading || systemId.length === 0} onClick={() => update()}>
          Create new report
        </Button>
      </Col>
      {loading && <Loader size="medium" />}
    </Row>
  );
};

export default CreateNewReport;
CreateNewReport.propTypes = {
  organizationId: PropTypes.number,
  selectReport: PropTypes.function,
};
