import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

const reinitTooltips = () => {
  if (typeof window.attach_tooltips_to_text_overflow === 'function') {
    window.attach_tooltips_to_text_overflow();
  }
};

export default class Ellipsis extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    tag: PropTypes.string,
    inline: PropTypes.bool,
  };

  componentDidMount() {
    reinitTooltips();
  }
  componentDidUpdate() {
    reinitTooltips();
  }

  render() {
    const { children, tag, inline, ...rest } = this.props;
    const Comp = tag || 'span';
    return (
      <Comp
        className={cs('js-text-overflow', { 'text-overflow': !inline, 'text-inline-block-overflow': inline })}
        {...rest}
      >
        {children}
      </Comp>
    );
  }
}
