import { setupColumn } from './setupColumn';
import { LabelsCell } from './cells';
import { t } from '../../../i18n';

const mapping = {
  ...setupColumn({
    id: 'labels',
    graphqlFields: [{ labels: ['id', 'name', 'color'] }],
    cell: LabelsCell,
    category: 'setup',
    header: () => t('activerecord.attributes.labels', { default: 'labels' }),
    resolveChildren: ({ labels }) => labels,
  }),
};

export default mapping;
