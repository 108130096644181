import React from 'react';
import { gql } from '@apollo/client';
import { orderBy } from 'lodash';
import { Query } from '@apollo/client/react/components';
import PropTypes from 'prop-types';
import { t, formatNumber } from '../i18n';
import { Row, StatusBox } from '../components/index';

export class AsyncJobLogStatusBox extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    refetch: PropTypes.func.isRequired,
    state: PropTypes.string,
    errorHuman: PropTypes.string,
    inProgress: PropTypes.bool,
    inProgressText: PropTypes.string,
    finishedText: PropTypes.string,
    previousDuration: PropTypes.number,
    createdAt: PropTypes.string,
    startedAt: PropTypes.string,
    finishedAt: PropTypes.string,
    createdAtFormatted: PropTypes.string,
    asyncJobSteps: PropTypes.array,
    disableReloadOnFinish: PropTypes.bool,
  };

  componentWillReceiveProps(nextProps) {
    if (
      (this.props.inProgress || typeof this.props.inProgress === 'undefined') &&
      !nextProps.inProgress &&
      !window.hasNotReloadStarted &&
      !nextProps.disableReloadOnFinish &&
      nextProps.state !== 'failed' &&
      nextProps.state !== 'partialy_failed'
    ) {
      window.hasNotReloadStarted = true;
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  }

  getStatusText() {
    if (this.props.state === 'failed' || this.props.state === 'partialy_failed') {
      return t('react.status_box.async_error_status');
    }
    if (this.props.inProgress) {
      return this.props.inProgressText || t('react.status_box.sync_status');
    }
    return this.props.finishedText || t('react.status_box.finish_status');
  }

  getStatusBoxState() {
    if (this.props.inProgress) {
      return 'running';
    }
    if (this.props.state === 'failed' || this.props.state === 'partialy_failed') {
      return 'failed';
    }
    return 'finished';
  }

  refetch = () => {
    const { refetch } = this.props;
    if (typeof refetch === 'function') {
      refetch();
    } else {
      // eslint-disable-next-line no-console
      console.warn('want to refetch, but refetch function was not provided');
    }
  };

  render() {
    const { error, state, startedAt, previousDuration, createdAtFormatted, asyncJobSteps, ...rest } = this.props;

    if (error) {
      const additionalContent = (
        <div className="Link ml-8" onClick={this.refetch}>
          {t('react.status_box.try_again')}
        </div>
      );

      return (
        <StatusBox
          status="loading"
          statusText={t('react.status_box.loading_failed')}
          additionalContent={additionalContent}
        />
      );
    }

    if (!state) {
      return <StatusBox status="loading" statusText={t('react.status_box.status_loading')} />;
    }

    if (state === 'failed') {
      return (
        <StatusBox status={state} statusText={this.getStatusText()}>
          <Row center>
            <span className="Text ml-8 mr-16">{t('react.status_box.async_contact_support')}</span>
          </Row>
        </StatusBox>
      );
    }

    const steps = orderBy(asyncJobSteps || [], ['position']).map(({ id, nameTranslated, state: status, counter }) => ({
      id,
      text: nameTranslated,
      status,
      children: counter > 0 ? formatNumber(counter, { precision: 0 }) : null,
    }));

    return (
      <StatusBox
        status={this.getStatusBoxState()}
        statusText={this.getStatusText()}
        previousSyncDuration={previousDuration}
        lastImport={startedAt}
        steps={steps}
        dropdownMenuHeader={t('react.status_box.started_at', { time: createdAtFormatted })}
        {...rest}
      />
    );
  }
}

const QUERY_ASYNC_JOB_LOG_STATUS = gql`
  query OrganizationAsyncJobLogStatus($organizationId: BigInt!, $subjectGid: String!) {
    organization(id: $organizationId) {
      id
      lastAsyncJobLog(subjectGid: $subjectGid) {
        id
        inProgress
        state
        errorHuman
        previousDuration
        startedAt
        finishedAt
        createdAt
        createdAtFormatted
        asyncJobSteps {
          id
          name
          nameTranslated
          position
          state
          counter
        }
        childJobLogs {
          id
          inProgress
          state
          errorClass
          errorMessage
          subjectType
          previousDuration
          asyncJobSteps {
            id
            name
            nameTranslated
            position
            state
            counter
          }
        }
      }
    }
  }
`;

const ConnectedAsyncJobLogStatusBox = ({ organizationId, subjectGid, timeout, ...rest }) => (
  <Query
    query={QUERY_ASYNC_JOB_LOG_STATUS}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
      subjectGid,
    }}
    pollInterval={timeout ? parseInt(timeout, 10) : 5000}
  >
    {({ error, data, refetch }) => (
      <AsyncJobLogStatusBox
        refetch={() => refetch()}
        error={error}
        {...rest}
        {...((data && data.organization && data.organization.lastAsyncJobLog) || {})}
      />
    )}
  </Query>
);

ConnectedAsyncJobLogStatusBox.propTypes = {
  organizationId: PropTypes.string.isRequired,
  subjectGid: PropTypes.string.isRequired,
  timeout: PropTypes.string.isRequired,
  inProgressText: PropTypes.string,
  finishedText: PropTypes.string,
};

export default ConnectedAsyncJobLogStatusBox;
