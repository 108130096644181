/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../index';
import { gql, useQuery } from '@apollo/client';

const fixStringCSVValue = value => `"${value.toString().replace(/"/g, '""')}"`;

const exportCSVHandler = (data, columns) => {
  let csvContent =
    'data:text/csv;charset=utf-8,' +
    columns.join(',') +
    '\r\n' +
    data.map(row => columns.map(col => fixStringCSVValue(row[col])).join(',')).join('\r\n');

  var encodedUri = encodeURI(csvContent);
  var link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'my_data.csv');
  document.body.appendChild(link); // Required for FF

  link.click();
};

const ExportCSV = ({ organizationId, reportId, conditions, selectedFilters, disabled }) => {
  return (
    <Button disabled secondary onClick={() => exportCSVHandler([], ['criteria', 'clicks', 'impressions'])}>
      Export CSV
    </Button>
  );
};

export default ExportCSV;
