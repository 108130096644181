/* eslint-disable */
const colorPalete = [
  '#48c9b0',
  '#58d68d',
  '#5dade2',
  '#af7ac5',
  '#5d6d7e',
  '#f4d03f',
  '#eb984e',
  '#dc7633',
  '#ec7063',
  '#f0f3f4',
  '#aab7b8',
  '#0e6251',
  '#186a3b',
  '#1b4f72',
  '#512e5f',
  '#1b2631',
  '#7d6608',
  '#784212',
  '#78281f',
  '#7b7d7d',
  '#4d5656',
  '#45b39d',
  '#52be80',
  '#5499c7',
  '#a569bd',
  '#566573',
  '#f5b041',
  '#fbeee6',
  '#ba4a00',
  '#cd6155',
  '#cacfd2',
  '#99a3a4',
  '#0b5345',
  '#145a32',
  '#154360',
  '#4a235a',
  '#17202a',
  '#7e5109',
  '#6e2c00',
  '#641e16',
  '#626567',
  '#424949',
];

export default colorPalete;
