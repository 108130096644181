import React from 'react';
import PropTypes from 'prop-types';
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache, from, ApolloProvider } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export { default as QueryHolder } from './query_holder';

export const getGqlAttributeText = (text = '') => JSON.stringify(text);

let counter = 0;

document.addEventListener('DOMContentLoaded', () => {
  if (document.body) {
    document.body.dataset.graphqlActive = false;
  }
});

export const createClient = csrfToken => {
  const httpLink = new HttpLink({
    credentials: 'same-origin',
    headers: {
      'X-CSRF-Token': csrfToken,
    },
  });

  const watcherMiddleware = new ApolloLink((operation, forward) => {
    counter += 1;
    let isSubtracted = false;

    document.body.dataset.graphqlActive = counter > 0;
    setTimeout(() => {
      if (!isSubtracted) {
        counter -= 1;
        isSubtracted = true;
        document.body.dataset.graphqlActive = counter > 0;
      }
    }, 3000);

    return forward(operation).map(data => {
      if (!isSubtracted) {
        counter -= 1;
        isSubtracted = true;
        setTimeout(() => {
          document.body.dataset.graphqlActive = counter > 0;
        }, 100);
      }
      return data;
    });
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        // eslint-disable-next-line no-console
        console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }
    // eslint-disable-next-line no-console
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  return new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: from([errorLink, watcherMiddleware, httpLink]),
    cache: new InMemoryCache(),
  });
};

export const getErrorsFromData = data =>
  (data && data[Object.keys(data)[0]] && data[Object.keys(data)[0]].errors) || [];

export const getNotificationTextFromData = data =>
  data && data[Object.keys(data)[0]] && data[Object.keys(data)[0]].updateNotificationText;

const GraphQLProvider = ({ csrfToken, children, client }) => (
  <ApolloProvider client={client || createClient(csrfToken)}>{children}</ApolloProvider>
);

GraphQLProvider.propTypes = {
  csrfToken: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  client: PropTypes.any.isRequired,
};

export default GraphQLProvider;
