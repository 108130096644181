import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { SingleImage, SlideshowImages } from './customImageSelect/index';
import { ErrorBox, listenOnModalChange } from '../../index';
import { t } from '../../../i18n';
import { listenOnPlaceholdersChange } from '../../../placeholders/placeholdersEmitter';
import { filterDataObject, transformData } from './utils';

const IMAGE_MODAL = 'imageModal';

const QUERY_DATA_VARIABLES_SORTED = gql`
  query Variables($dataSourceId: BigInt!, $organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      dataSource(id: $dataSourceId) {
        id
        tableVariablesSorted {
          id
          placeholderName
          editLink
          duplicateCopyLink
          previewImageUrls
          imagesCount
          isImageUrl
        }
      }
    }
  }
`;

const getDefaultValue = (def, mult, selectCollection) => {
  if (!mult) return def || selectCollection[0]?.value;
  return Array.isArray(def) ? def : def.split(' ');
};

const FBAdCustomImageSelect = memo(
  ({ collection = [], value: defaultValue, dataSourceId, organizationId, multiple, ...rest }) => {
    const { data, loading, error, refetch } = useQuery(QUERY_DATA_VARIABLES_SORTED, {
      variables: {
        dataSourceId,
        organizationId,
      },
    });

    const transformedData = transformData(data?.organization?.dataSource?.tableVariablesSorted);
    const selectCollection = transformedData.length > 0 ? transformedData : collection;

    const [selectedValue, setSelectedValue] = useState(getDefaultValue(defaultValue, multiple, selectCollection));
    const [openedDropdown, setOpenedDropdown] = useState(null);

    const selectedObject = filterDataObject(transformedData, selectedValue);

    useEffect(() => listenOnPlaceholdersChange(refreshOnUpdate), [openedDropdown]);
    useEffect(() => listenOnModalChange(refreshOnModalChange), []);

    const refreshOnUpdate = async e => {
      await refetch();
      if (e.text && !multiple) setSelectedValue(e.text);
      if (multiple && openedDropdown && e.text) {
        setSelectedValue(selectedValue.map(el => (el === openedDropdown ? e.text : el)));
      }
    };
    const refreshOnModalChange = e => {
      if (e === IMAGE_MODAL) {
        refetch();
        if (typeof window.startLoadingPreview === 'function') window.startLoadingPreview();
      }
    };

    if (error) return <ErrorBox>{t('react.tuner.error')}</ErrorBox>;

    if (multiple) {
      return (
        <SlideshowImages
          {...rest}
          data={data?.organization?.dataSource?.tableVariablesSorted}
          selectedValues={selectedValue.filter(el => el !== '')}
          setSelectedValues={setSelectedValue}
          dropdownOpen={val => setOpenedDropdown(val)}
          notify={IMAGE_MODAL}
          loading={loading}
        />
      );
    }
    return (
      <SingleImage
        {...rest}
        selectedValue={selectedValue}
        data={data?.organization?.dataSource?.tableVariablesSorted}
        loading={loading}
        dataSourceId={dataSourceId}
        organizationId={organizationId}
        setSelectedValue={setSelectedValue}
        notify={IMAGE_MODAL}
        selectCollection={selectCollection}
        selectedObject={selectedObject}
      />
    );
  }
);

FBAdCustomImageSelect.propTypes = {
  collection: PropTypes.any,
  dataSourceId: PropTypes.number,
  multiple: PropTypes.boolean,
  organizationId: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default FBAdCustomImageSelect;
