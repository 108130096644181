import { gql } from '@apollo/client';
import { setupColumn } from '../setupColumn';
import { TextCell, MoneyCell, SwitchCell, PlaceholderCell } from '../cells';
import { MoneyEdit, PlaceholderSelect } from '../editable';
import { t } from '../../../../i18n';
import { adwordsHeader } from '../systemHeaders';
import { getGqlAttributeText } from '../../../../graphql_provider';

const category = 'adwords';

export const adwordsMapping = {
  ...setupColumn({
    id: 'adwordsSystemId',
    graphqlFields: { adwordsSetting: ['systemId'] },
    category,
    order: 'adwords_setting.system_id',
    header: () => adwordsHeader(t('activerecord.attributes.adwords_setting.system_id')),
    cell: TextCell,
    resolveChildren: data => data && data.adwordsSetting && data.adwordsSetting.systemId,
  }),
  ...setupColumn({
    id: 'adwordsBudget',
    graphqlFields: ['adwordsCurrency', { adwordsSetting: ['budget'] }],
    order: 'adwords_setting.budget',
    header: () => adwordsHeader(t('activerecord.attributes.adwords_setting.budget')),
    category,
    resolveChildren: data =>
      (data?.adwordsSetting?.id &&
        data.adwordsSetting.budget > 0 && {
          id: data.adwordsSetting.id,
          currency: data.adwordsCurrency,
          money: data.adwordsSetting.budget,
        }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    editQuery: data =>
      data?.adwordsSetting?.id &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateAdwordsSetting(id: ${id || -1}, budget: ${money || 0}) {
            adwordsSetting {
              id
              budget
            }
            errors
          }
        }
      `;
      }),
  }),

  ...setupColumn({
    id: 'adwordsMaxCpc',
    graphqlFields: ['adwordsCurrency', { adwordsSetting: ['maxCpc'] }],
    order: 'adwords_setting.max_cpc',
    header: () => adwordsHeader(t('activerecord.attributes.adwords_setting.max_cpc')),
    category,
    resolveChildren: data =>
      (data?.adwordsSetting?.id &&
        data.adwordsSetting.maxCpc > 0 && {
          id: data.adwordsSetting.id,
          currency: data.adwordsCurrency,
          money: data.adwordsSetting.maxCpc,
        }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    editQuery: data =>
      data?.adwordsSetting?.id &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateAdwordsSetting(id: ${id || -1}, maxCpc: ${money || 0}) {
            adwordsSetting {
              id
              maxCpc
            }
            errors
          }
        }
      `;
      }),
  }),
  ...setupColumn({
    id: 'syncCampaignBudget',
    graphqlFields: [{ adwordsSetting: ['syncCampaignBudget'] }],
    resolveChildren: data => data && data.adwordsSetting && data.adwordsSetting.syncCampaignBudget,
    cell: SwitchCell,
    category,
    header: () => adwordsHeader(t('activerecord.attributes.adwords_setting.sync_campaign_budget')),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      (synced =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting
            .id}, syncCampaignBudget: ${synced}) { adwordsSetting { id syncCampaignBudget } errors } }`),
  }),
  ...setupColumn({
    id: 'syncAdgroupCpc',
    graphqlFields: [{ adwordsSetting: ['syncAdgroupCpc'] }],
    resolveChildren: data => data && data.adwordsSetting && data.adwordsSetting.syncAdgroupCpc,
    cell: SwitchCell,
    category,
    header: () => adwordsHeader(t('activerecord.attributes.adwords_setting.sync_adgroup_cpc')),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      (synced =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, syncAdgroupCpc: ${synced}) { adwordsSetting { id syncAdgroupCpc } errors } }`),
  }),
  ...setupColumn({
    id: 'customizerPrice',
    graphqlFields: ['serializedPlaceholders', { adwordsSetting: ['customizerPrice', 'possibleCustomizersInCampaign'] }],
    category: 'adwords',
    order: 'adwords_setting.customizer_price',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_price')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerPrice,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'number'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData => gql`mutation {
        updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerPrice: ${getGqlAttributeText(
        newData?.text
      )}) { adwordsSetting { id customizerPrice } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerSale',
    graphqlFields: ['serializedPlaceholders', { adwordsSetting: ['customizerSale', 'possibleCustomizersInCampaign'] }],
    category: 'adwords',
    order: 'adwords_setting.customizer_sale',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_sale')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerSale,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'number'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerSale: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerSale } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerOther1',
    graphqlFields: [
      'serializedPlaceholders',
      { adwordsSetting: ['customizerOther1', 'possibleCustomizersInCampaign'] },
    ],
    category: 'adwords',
    order: 'adwords_setting.customizer_other1',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_other1')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerOther1,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'number'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerOther1: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerOther1 } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerOther2',
    graphqlFields: [
      'serializedPlaceholders',
      { adwordsSetting: ['customizerOther2', 'possibleCustomizersInCampaign'] },
    ],
    category: 'adwords',
    order: 'adwords_setting.customizer_other2',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_other2')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerOther2,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'number'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerOther2: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerOther2 } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerText1',
    graphqlFields: ['serializedPlaceholders', { adwordsSetting: ['customizerText1', 'possibleCustomizersInCampaign'] }],
    category: 'adwords',
    order: 'adwords_setting.customizer_text1',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_text1')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerText1,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'text'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerText1: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerText1 } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerText2',
    graphqlFields: ['serializedPlaceholders', { adwordsSetting: ['customizerText2', 'possibleCustomizersInCampaign'] }],
    category: 'adwords',
    order: 'adwords_setting.customizer_text2',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_text2')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerText2,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'text'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerText2: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerText2 } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerText3',
    graphqlFields: ['serializedPlaceholders', { adwordsSetting: ['customizerText3', 'possibleCustomizersInCampaign'] }],
    category: 'adwords',
    order: 'adwords_setting.customizer_text3',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_text3')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerText3,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'text'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerText3: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerText3 } errors } }`),
  }),

  ...setupColumn({
    id: 'customizerText4',
    graphqlFields: ['serializedPlaceholders', { adwordsSetting: ['customizerText4', 'possibleCustomizersInCampaign'] }],
    category: 'adwords',
    order: 'adwords_setting.customizer_text4',
    cell: PlaceholderCell,
    editable: PlaceholderSelect,
    header: () => adwordsHeader(t('views.adwords_settings.customizer_text4')),
    resolveChildren: data => ({
      text: data && data.adwordsSetting && data.adwordsSetting.customizerText4,
      placeholders: JSON.parse(data?.serializedPlaceholders || '[]').filter(
        placeholder => placeholder.valueType === 'text'
      ),
    }),
    editQuery: data =>
      data?.adwordsSetting?.id &&
      data?.adwordsSetting?.possibleCustomizersInCampaign &&
      (newData =>
        gql`mutation { updateAdwordsSetting(id: ${data &&
          data.adwordsSetting &&
          data.adwordsSetting.id}, customizerText4: ${getGqlAttributeText(
          newData?.text
        )}) { adwordsSetting { id customizerText4 } errors } }`),
  }),
};

export default adwordsMapping;
