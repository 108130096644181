import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { PieChart, Pie, Cell } from 'recharts';
import useInputState from '../useInputState';
import { convertHexToRGBA } from './utils';
import Modal from './PagesModal';
import { Label, ActionsLine, PagesCount } from './InjectedLabelComponents';
import { t } from '../../i18n';

// prettier-ignore
const PAGES = {
  level_0: { color: '#FBBD04', tooltip: t('zero', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_1: { color: '#438EDC', tooltip: t('first', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_2: { color: '#9BCC63', tooltip: t('second', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_3: { color: '#F06292', tooltip: t('third', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_4: { color: '#34A952', tooltip: t('fourth', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_5: { color: '#4DD0E1', tooltip: t('fifth', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_6: { color: '#E37400', tooltip: t('sixth', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_7: { color: '#0062BF', tooltip: t('seventh', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_8: { color: '#BF362F', tooltip: t('eighth', { scope: 'react.scraper.level_pages_tooltip' }) },
  level_9: { color: '#26394A', tooltip: t('ninth', { scope: 'react.scraper.level_pages_tooltip' }) },
};

const getLabelTranslation = count => {
  const scope = 'react.scraper.level_pages';
  if (parseInt(count, 10) === 0) return t('zero', { scope });
  if (parseInt(count, 10) === 1) return t('first', { scope });
  if (parseInt(count, 10) === 2) return t('second', { scope });
  if (parseInt(count, 10) === 3) return t('third', { scope });

  return t('any', { scope, count });
};

const propagate = ({ target, props, key, ...rest }) => {
  const controlLabelButtons = target.parentNode.querySelector('.control-label-buttons');
  if (!controlLabelButtons) {
    target.insertAdjacentHTML('afterend', '<span class="pos-relative control-label-buttons"></span>');
  }
  const parent = target.parentNode.querySelector('.control-label-buttons');

  const count = props[key];
  const collection = props[`${key}_preview`];

  ReactDOM.render(<Label count={count} {...rest} />, target);
  ReactDOM.render(<ActionsLine collection={collection} pagesCount={count} {...rest} />, parent);
};

const propagateLevels = ({ key, ...rest }) => {
  const originalLabel = getLabelTranslation(key.replace('level_', ''));

  const target = document
    .querySelector(`[name*="[selected_levels][]"][value="${key}"]`)
    .closest('.Checkboxes-item')
    .querySelector('label');

  const isInluded = !!rest.props[key];
  const hasAnyPage = rest.props.with_subpages > 0 || rest.props.without_subpages > 0;
  if (!isInluded && hasAnyPage) target.parentNode.classList.add('d-none');
  if (isInluded || !hasAnyPage) target.parentNode.classList.remove('d-none');

  propagate({
    ...PAGES[key],
    ...rest,
    key,
    originalLabel,
    style: { top: '-8px' },
    target,
  });
};

const propagateSubpages = ({ key, ...rest }) => {
  const originalLabel = t(key, { scope: 'react.scraper.subpages' });
  const tooltip = t(key, { scope: 'react.scraper.subpages.tooltip' });
  const type = key === 'without_subpages' ? 'use_without_subpages' : 'use_subpages';

  const target = document
    .querySelector(`[name*="[${type}]"]`)
    .closest('.Checkboxes-item')
    .querySelector('label');

  target.parentNode.classList.add('mt-4');

  propagate({
    ...rest,
    key,
    originalLabel,
    style: { top: '-12px' },
    target,
    tooltip,
  });
};

const addTotalCountInfo = ({
  props: { with_subpages, without_subpages, with_subpages_preview, without_subpages_preview },
  ...rest
}) => {
  const target = document.getElementById('pages-total-count');
  const totalCount = with_subpages + without_subpages;
  const previewPages = [...with_subpages_preview, ...without_subpages_preview];

  ReactDOM.render(<PagesCount {...rest} totalCount={totalCount} collection={previewPages} />, target);
};

const Chart = ({ collection }) => {
  if (collection.filter(({ value }) => value).length === 0) return null;
  return (
    <PieChart width={136} height={136}>
      <Pie data={collection} cx="50%" cy="50%" outerRadius={68} isAnimationActive={false} stroke="none" dataKey="value">
        {collection.map(entry => (
          <Cell key={`cell-${entry.kind}`} fill={entry.color} />
        ))}
      </Pie>
    </PieChart>
  );
};

Chart.propTypes = {
  collection: PropTypes.array,
};

const getLevelColor = (levels, key) =>
  levels.includes(key) ? PAGES[key].color : convertHexToRGBA(PAGES[key].color, 30);

const PageLevelSelection = props => {
  const { state: tempLevels } = useInputState({ name: 'selected_levels', defaultValue: [] });
  const [modalData, setModalData] = useState({ isOpen: false, data: [] });

  const levels = Array.isArray(tempLevels) ? tempLevels : [tempLevels];
  const chartData = Object.keys(PAGES).map(key => ({ color: getLevelColor(levels, key), value: props[key] }));

  const handleModal = data => setModalData({ ...data, isOpen: true });
  const closeModal = () => setModalData({ ...modalData, isOpen: false });

  useEffect(() => {
    Object.keys(PAGES).forEach(key => propagateLevels({ props, key, color: getLevelColor(levels, key), handleModal }));
    propagateSubpages({ props, key: 'with_subpages', handleModal });
    propagateSubpages({ props, key: 'without_subpages', handleModal });
    addTotalCountInfo({ props, handleModal });
  }, [props]);

  return (
    <div>
      <Chart collection={chartData} />
      {modalData.isOpen && <Modal modalData={modalData} closeModal={closeModal} />}
    </div>
  );
};

export default hot(PageLevelSelection);
