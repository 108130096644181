/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Input } from '../components/form';
import PlaceholderInput from '../placeholders/Input';
import { Row, Col } from '../components/layout';
import Icon from '../components/Icon';
import PreviewDataModal from '../placeholders/PreviewDataModal';
import Button from '../components/Button';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter } from '../components';
import Tile from '../components/Tile';

class AdditionalFields extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
    attribute_name: PropTypes.string,
    attribute_value: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    attributeName: this.props.attribute_name || '',
    attributeValue: this.props.attribute_value || '',
  };

  handleAttributeName = ({ target: { value } }) => {
    if (value !== this.state.attributeName) {
      this.setState({ attributeName: value });
      this.props.updateFieldData(this.props.id, { attribute_name: value });
      updatePreview();
    }
  };

  handleAttributeValue = ({ target: { value } }) => {
    if (value !== this.state.attributeValue) {
      this.setState({ attributeValue: value });
      this.props.updateFieldData(this.props.id, { attribute_value: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { attributeValue, attributeName } = this.state;

    return (
      <Tile className="mb-8" smallSpaced>
        <Row center padding="l" data-test-id="additional-fields">
          <Col grow data-test-id="additional_fields-name" className="mr-8">
            <Input
              defaultValue={attributeName}
              onChange={this.handleAttributeName}
              placeholder={t('views.additional_fields.fields.attribute_name', { default: 'Attribute name' })}
              {...getSubFiledOptions('attribute_name')}
            />
          </Col>
          <Col grow data-test-id="additional_fields-value">
            <PlaceholderInput
              defaultValue={attributeValue}
              onChange={this.handleAttributeValue}
              placeholder={t('views.additional_fields.fields.attribute_value', { default: 'Attribute value' })}
              {...getSubFiledOptions('attribute_value')}
            />
          </Col>

          <Col shrink className="align-self-stretch">
            <TileArrowDelimiter />
          </Col>

          <Col
            width="calc(40% - 48px)"
            data-preview="placeholder"
            data-example={`${t('views.additional_fields.example', {
              default: 'Example:',
            })} <span class="Text--bold">1234</span>`}
            data-field={`additional_fields.${attributeName}`}
          >
            <div className="Text">
              {t('views.additional_fields.example')} <span className="Text--bold">1234</span>
            </div>
          </Col>

          {onRemove && (
            <Col shrink>
              <Button
                tertiary
                onlyIcon
                disabled={disabled}
                onClick={onRemove}
                tabIndex="-1"
                icon="trash"
                data-test-id="nested-field-remove"
              />
            </Col>
          )}
        </Row>
      </Tile>
    );
  }
}

export default AdditionalFields;
