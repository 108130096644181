/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Ellipsis } from './index';

class PreviewArray extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  };

  state = {
    expanded: false,
  };

  render() {
    const { value } = this.props;
    const { expanded } = this.state;

    if (!Array.isArray(value)) {
      return <PreviewText value={value} />;
    }

    const showValues = value.length > 5 && !expanded ? value.slice(0, 5) : value;

    return (
      <Col noPadding>
        {showValues.map((val, index) => (
          <Row shrink key={`${val}_${index}`}>
            <PreviewText value={val} />
          </Row>
        ))}
        {value.length > 5 && !expanded && (
          <div className="Link" onClick={() => this.setState({ expanded: true })}>
            {window.reacti18n.data_input_preview.show_additional} {value.length - 5}
          </div>
        )}
      </Col>
    );
  }
}

const PreviewText = ({ value }) => {
  if (`${value}`.match(/https?:\/\//)) {
    return (
      <Ellipsis>
        <a href={value} target="_blank" className="Link" rel="noopener noreferrer">
          {value}
        </a>
      </Ellipsis>
    );
  }

  if (typeof value === 'number') {
    return value;
  }

  if ((typeof value === 'object' || typeof value === 'boolean') && value !== null) {
    return JSON.stringify(value);
  }

  if (value === null) {
    return <span>&nbsp;</span>;
  }

  return value || null;
};

PreviewText.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const PreviewValue = {
  text: PreviewText,
  image_url: PreviewText,
  generic_url: PreviewText,
  html: PreviewText,
  object: PreviewText,
  boolean: PreviewText,
  date: PreviewText,
  number: PreviewText,
  'text-array': PreviewArray,
  'image_url-array': PreviewArray,
  'generic_url-array': PreviewArray,
  'html-array': PreviewArray,
  'object-array': PreviewArray,
  'boolean-array': PreviewArray,
  'date-array': PreviewArray,
  'number-array': PreviewArray,
};

const PreviewInputValue = ({ value, showFieldKind, showInline = false }) => {
  const Comp = PreviewValue[showFieldKind] || PreviewText;
  if (showInline) {
    return <Comp value={value} />;
  }
  return (
    <Ellipsis>
      <Comp value={value} />
    </Ellipsis>
  );
};
PreviewInputValue.propTypes = {
  value: PropTypes.any,
  showFieldKind: PropTypes.oneOf(Object.keys(PreviewValue)),
  showInline: PropTypes.bool,
};

export default PreviewInputValue;
