import React from 'react';
import RPT from 'prop-types';
import { DropTarget } from 'react-dnd';

const cardTarget = {
  canDrop({ id, elements, child }, monitor) {
    const item = monitor.getItem();
    const element = elements[child || id];
    const canDropByType = child ? item.type === element.type : id.toString() === '0' || item.type === element.type;
    const parent = item.parent.toString();
    const itemId = item.id.toString();
    const underParent = id.toString() !== parent.toString() || (id.toString() === '0' && parent.toString() === '0');
    return underParent && id !== itemId && child !== itemId && canDropByType;
  },
  drop(props, monitor) {
    const item = monitor.getItem();
    props.changeOrder(item.id, props.id, props.child);
    return { moved: true };
  },
};

const styles = {
  base: {
    height: '80px',
    width: '800px',
    left: '-41px',
    zIndex: 9,
    top: '-28px',
    position: 'absolute',
    // UNCOMMNET THIS TO SEE DROP TARGETS
    // background: 'rgba(255,0,0,0.3)',
  },
  isOver: {},
  canDrop: {},
  outsideDrop: {
    top: '-4px',
    left: '0%',
    transform: 'translateX(0%)',
    height: '54px',
    width: '100%',
  },
  firstOutsideDrop: {
    top: '31px',
  },
  mainParent: {
    top: '-24px',
    height: '70px',
  },
  isLast: {
    top: '-48px',
  },
  hidden: {
    height: '0px',
  },
};

const visualStyles = {
  base: {
    height: '28px',
    paddingTop: '1px',
    width: '220px',
    zIndex: 9,
    top: '26px',
    opacity: 1,
    borderRadius: '2px',
    margin: '1px',
    position: 'absolute',
    backgroundColor: '#FFF3BD',
    border: '1px dashed #DDB406',
    textAlign: 'center',
    lineHeight: '24px',
    left: '40px',
  },
  isOver: {
    backgroundColor: '#cebb67',
    border: '1px dashed #DDB406',
  },
  canDrop: {},
  outsideDrop: {
    top: '0px',
    width: 'calc(100% - 28px)',
    height: '32px',
    left: '14px',
  },
  mainParent: {
    top: '20px',
    left: '40px',
  },
  hidden: {
    height: '0px',
    opacity: 0,
  },
  isLast: {
    top: '42px',
  },
};

const getVisualStyle = (canDrop, isOver, parent, outsideDrop, isLast) => {
  if (!canDrop) {
    return visualStyles.hidden;
  }
  const basic = isOver ? visualStyles.isOver : visualStyles.canDrop;

  const lastOverride = isLast ? visualStyles.isLast : {};

  if (outsideDrop) {
    return { ...basic, ...visualStyles.outsideDrop, ...lastOverride };
  }
  if (parent) {
    return { ...basic, ...visualStyles.mainParent, ...lastOverride };
  }
  return { ...basic, ...lastOverride };
};

const getStyle = (canDrop, isOver, parent, outsideDrop, isFirstOnPage, isLast) => {
  if (!canDrop) {
    return styles.hidden;
  }
  const basic = isOver ? styles.isOver : styles.canDrop;
  const firstOnPage = isFirstOnPage ? styles.firstOutsideDrop : {};

  const lastOverride = isLast ? styles.isLast : {};

  if (outsideDrop) {
    return { ...basic, ...styles.outsideDrop, ...firstOnPage, ...lastOverride };
  }

  if (parent) {
    return { ...basic, ...styles.mainParent, ...lastOverride };
  }
  return { ...basic, ...lastOverride };
};

const Place = ({ connectDropTarget, isOver, canDrop, isFirst, outsideDrop, i18n, isLast, isFirstOnPage }) =>
  connectDropTarget(
    <div style={{ ...styles.base, ...getStyle(canDrop, isOver, isFirst, outsideDrop, isFirstOnPage, isLast) }}>
      <div style={{ ...visualStyles.base, ...getVisualStyle(canDrop, isOver, isFirst, outsideDrop, isLast) }}>
        {canDrop && outsideDrop && !isOver && i18n.create_a_new_branch}
        {canDrop && !outsideDrop && !isOver && i18n.move_to_branch}
        {canDrop && isOver && i18n.release_and_place_adtext}
      </div>
    </div>
  );

Place.propTypes = {
  connectDropTarget: RPT.func.isRequired,
  isOver: RPT.bool.isRequired,
  outsideDrop: RPT.bool,
  i18n: RPT.object.isRequired,
  isDragging: RPT.bool,
  isLast: RPT.bool,
  canDrop: RPT.bool.isRequired,
};

const DropElement = DropTarget('card', cardTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  canDrop: monitor.canDrop(),
  // UNCOMMNET THIS LINE TO SHOW DROP PLACES
  // canDrop: true,
}))(Place);

export default DropElement;
