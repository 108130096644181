/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { QueryHolder } from '../components/QueryHolder';
import chevron from '../scss_stylesheets/images/chevron_right.svg';
import { formatCurrency } from '../utils/format';
import Link from '../components/Link';
import { Row, Col } from '../components/layout';
import { t } from '../i18n';

const QUERY_CAMPAIGNS = gql`
  query OrganizationEstoreCampaigns($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreCampaigns(status: "running") {
          id
          name
          adwordsId
          adwordsError
          adwordsPlaLeavesCount
        }
      }
    }
  }
`;

const QUERY_ADGROUPS = gql`
  query OrganizationEstoreAdgroups($organizationId: BigInt!, $campaignSettingId: BigInt!, $estoreCampaignId: String!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreAdgroups(size: -1, status: "running", adwordsPlaDimension: "adgroup", campaignId: $estoreCampaignId) {
          id
          name
          adwordsChildsPlaHierarchyMd5
          bidAdwords
          adwordsId
          adwordsError
          adwordsPlaLeavesCount
        }
      }
    }
  }
`;

const QUERY_DIMENSIONS = gql`
  query OrganizationEstoreAdgroupDimensions(
    $organizationId: BigInt!
    $campaignSettingId: BigInt!
    $adwordsPlaHierarchy: String!
    $limit: Int!
  ) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        adwordsCurrency
        estoreAdgroups(size: $limit, status: "running", adwordsPlaHierarchyMd5: $adwordsPlaHierarchy) {
          id
          name
          adwordsPlaLevel
          adwordsPlaDimension
          adwordsChildsPlaHierarchyMd5
          bidAdwords
          inputPrice
          inputCurrency
          inputOfferId
          inputProductName
          inputImageLink
          inputLink
          adwordsPlaLeavesCount
          status
        }
      }
    }
  }
`;

export default class PLACampaignPreview extends React.Component {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    campaignSettingId: PropTypes.number.isRequired,
    disableCpc: PropTypes.bool,
  };
  render() {
    const { organizationId, campaignSettingId, disableCpc } = this.props;
    return (
      <CampaignList query={QUERY_CAMPAIGNS} variables={{ organizationId, campaignSettingId }} disableCpc={disableCpc} />
    );
  }
}

let estoreCampaignsInterval = null;

const CampaignList = QueryHolder(({ data, refetch, ...rest }) => {
  const { estoreCampaigns } = data.organization.campaignSetting;

  if (estoreCampaignsInterval) {
    clearInterval(estoreCampaignsInterval);
  }

  if (estoreCampaigns.length === 0) {
    estoreCampaignsInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_campaigns_generated_yet')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <div>
      {estoreCampaigns.map(campaign => (
        <CampaignLine key={campaign.id} {...rest} {...campaign} />
      ))}
    </div>
  );
});

class CampaignLine extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    adwordsId: PropTypes.string,
    adwordsError: PropTypes.string,
    organizationId: PropTypes.number,
    campaignSettingId: PropTypes.number,
    adwordsPlaLeavesCount: PropTypes.number,
  };

  state = {
    show: true,
  };

  render() {
    const {
      id,
      name,
      adwordsId,
      adwordsPlaLeavesCount,
      adwordsError,
      organizationId,
      campaignSettingId,
      ...rest
    } = this.props;
    const { show } = this.state;

    return (
      <div className="pos-relative">
        <div
          className="Preview-item Preview-item--link Preview-item--bold pl-8"
          onClick={() => this.setState({ show: !show })}
        >
          <Row center>
            <Col shrink>
              <img src={chevron} className={`Preview-chevron ${show && 'rotate'}`} alt="chevron" />
            </Col>
            <Col>{name}</Col>
            {adwordsError ? <Col className="danger fc-warning">` ${adwordsError}`</Col> : null}
            <Col shrink className="pr-8">
              {adwordsPlaLeavesCount}
            </Col>
          </Row>
        </div>
        {show && (
          <DimensionList
            {...rest}
            adgroupLevel
            depth={0}
            query={QUERY_ADGROUPS}
            variables={{ organizationId, campaignSettingId, estoreCampaignId: id }}
          />
        )}
      </div>
    );
  }
}

let estoreCampaignLineInterval = null;

const DimensionList = QueryHolder(({ adgroupLevel, adwordsPlaLeavesCount, showAll, depth, data, refetch, ...rest }) => {
  const { estoreAdgroups } = data?.organization?.campaignSetting;

  if (estoreCampaignLineInterval) {
    clearInterval(estoreCampaignLineInterval);
  }

  if (!estoreAdgroups || estoreAdgroups?.length === 0) {
    estoreCampaignLineInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_adgroup_product_dimension_generated')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  const shownCount = data.organization.campaignSetting.estoreAdgroups.reduce(
    (o, a) => o + (a.adwordsPlaLeavesCount || 1),
    0
  );
  let plaDimension = 'adgroup';
  if (data.organization.campaignSetting.estoreAdgroups.length > 0) {
    plaDimension = data.organization.campaignSetting.estoreAdgroups[0].adwordsPlaDimension;
  }
  return (
    <div>
      {data.organization.campaignSetting.estoreAdgroups.map((dimension, index) => (
        <DimensionLine
          {...rest}
          {...dimension}
          adgroupLevel={adgroupLevel}
          adwordsCurrency={data.organization.campaignSetting.adwordsCurrency}
          depth={depth}
          index={index}
          key={dimension.id}
        />
      ))}
      {adwordsPlaLeavesCount > shownCount && (
        <div
          style={{ paddingLeft: `${depth * 30 + 15}px` }}
          className={`Preview-item Preview-item--link ${plaDimension === 'leaf' && 'Preview-item--gray'}`}
          onClick={showAll}
        >
          {t('react.pla_campaign_preview.show_all')} ({adwordsPlaLeavesCount - shownCount})
        </div>
      )}
    </div>
  );
});

DimensionList.propTypes = {
  adwordsPlaLeavesCount: PropTypes.number,
  adgroupLevel: PropTypes.bool,
  showAll: PropTypes.func.isRequired,
  depth: PropTypes.number,
  data: PropTypes.any,
  refetch: PropTypes.func.isRequired,
};

class DimensionLine extends React.Component {
  static propTypes = {
    disableCpc: PropTypes.bool,
    name: PropTypes.string,
    depth: PropTypes.number,
    adgroupLevel: PropTypes.bool,
    adwordsError: PropTypes.string,
    adwordsPlaDimension: PropTypes.string,
    adwordsPlaLeavesCount: PropTypes.number,
    adwordsChildsPlaHierarchyMd5: PropTypes.string,
    organizationId: PropTypes.number,
    campaignSettingId: PropTypes.number,
    index: PropTypes.number,
  };
  state = {
    show: this.props.adgroupLevel,
    limit: 5,
  };

  render() {
    const {
      disableCpc,
      name,
      depth,
      adwordsPlaLeavesCount,
      adwordsError,
      adwordsPlaDimension,
      adwordsChildsPlaHierarchyMd5,
      organizationId,
      campaignSettingId,
      index,
    } = this.props;

    const { show, limit } = this.state;
    if (adwordsPlaDimension === 'leaf') {
      return (
        <React.Fragment>
          {index === 0 && (
            <div className="Preview-item Preview-item--gray" style={{ paddingLeft: `${(depth + 1) * 30}px` }}>
              <div className="row mh-0">
                <div className="col-xs-3 col-lg-2 Text--gray Text--bold text-center">
                  <span className="pl-32">{t('react.pla_campaign_preview.product_id')}</span>
                </div>
                <div className="col-xs-5 col-lg-6 Text--gray Text--bold">
                  {t('react.pla_campaign_preview.product_name')}
                </div>
                <div className="col-xs-2 Text--gray Text--bold text-right">{t('react.pla_campaign_preview.price')}</div>
                {!disableCpc && (
                  <div className="col-xs-2 Text--gray Text--bold text-right pr-8">
                    {t('react.pla_campaign_preview.cpc')}
                  </div>
                )}
              </div>
            </div>
          )}
          <ProductLine depth={depth + 1} {...this.props} />
        </React.Fragment>
      );
    }
    const isEverythingElse = name === 'EverythingElseDimension';

    return (
      <div className="pos-relative">
        <div
          className={`Preview-item Preview-item--link ${isEverythingElse ? 'Preview-everything_else' : ''} ${depth ===
            0 && 'Preview-item--bold pl-8'})`}
          style={{ paddingLeft: `${(depth + 1) * 30 + 15}px` }}
          onClick={() => this.setState({ show: !show })}
        >
          <Row center>
            <Col shrink>
              <img src={chevron} className={`Preview-chevron ${show && 'rotate'}`} alt="chevron" />
            </Col>
            <Col>{isEverythingElse ? t('react.pla_campaign_preview.everything_else') : name}</Col>
            {adwordsError ? <Col className="danger fc-warning">` ${adwordsError}`</Col> : null}
            {adwordsPlaLeavesCount > 0 && (
              <Col shrink className="pr-8">
                {adwordsPlaLeavesCount}
              </Col>
            )}
          </Row>
        </div>
        {show && (
          <DimensionList
            disableCpc={disableCpc}
            depth={depth + 1}
            query={QUERY_DIMENSIONS}
            adwordsPlaLeavesCount={adwordsPlaLeavesCount}
            showAll={() => this.setState({ limit: -1 })}
            fetchPolicy="no-cache"
            variables={{ organizationId, campaignSettingId, limit, adwordsPlaHierarchy: adwordsChildsPlaHierarchyMd5 }}
          />
        )}
      </div>
    );
  }
}

class ProductLine extends React.Component {
  static propTypes = {
    disableCpc: PropTypes.bool,
    depth: PropTypes.number,
    bidAdwords: PropTypes.number,
    inputPrice: PropTypes.string,
    adwordsCurrency: PropTypes.string,
    inputCurrency: PropTypes.string,
    inputLink: PropTypes.string,
    inputProductName: PropTypes.string,
    inputOfferId: PropTypes.string,
    inputImageLink: PropTypes.string,
  };

  render() {
    const {
      disableCpc,
      depth,
      bidAdwords,
      inputPrice,
      adwordsCurrency,
      inputLink,
      inputProductName,
      inputCurrency,
      inputOfferId,
      inputImageLink,
    } = this.props;

    return (
      <div className="Preview-item Preview-item--gray" style={{ paddingLeft: `${(depth + 1) * 30}px` }}>
        <div className="row mh-0">
          <div className="col-xs-3 col-lg-2 text-center">
            <div className="Preview-image" style={{ backgroundImage: `url(${inputImageLink})` }} />
            <span className="pl-32">{inputOfferId}</span>
          </div>
          <div className="col-xs-5 col-lg-6">
            {inputLink ? (
              <Link href={inputLink} rel="noopener noreferrer" target="_blank">
                {inputProductName}
              </Link>
            ) : (
              inputProductName
            )}
          </div>
          <div className="col-xs-2 text-right">{formatCurrency(inputPrice, { precision: 2, unit: inputCurrency })}</div>
          {!disableCpc && (
            <div className="col-xs-2 text-right pr-8">
              {formatCurrency(bidAdwords, { precision: 2, unit: adwordsCurrency || '' })}
            </div>
          )}
        </div>
      </div>
    );
  }
}
