import React, { useState } from 'react';
import RPT from 'prop-types';
import { Icon, Ellipsis } from '../components/index';
import { GrayBox } from '../components/table/LoadingTable';

const NUMBER_OF_SHOWED_ROWS = 5;

export const formatChangesKey = key =>
  key
    .replace(/children\./g, '')
    .replace(/\.(\d+)\./g, '#$1 > ')
    .replace(/\./g, ' > ')
    .replace(/_/g, ' ');

const ChangesCell = ({ changes }) => {
  if (typeof changes === 'undefined') return <GrayBox />;
  if (typeof changes !== 'object' || Object.keys(changes).length === 0) return <InvalidCell />;

  const [expand, setExpand] = useState(false);

  const changesRow = Object.keys(changes)
    .filter(key => !key.match(/\b(action|id)$/))
    .filter(key => changes[key] !== null)
    .sort();

  const expandedRows = expand ? changesRow : changesRow.slice(0, NUMBER_OF_SHOWED_ROWS);

  return (
    <table width="100%" className="Sticky-Table Sticky-Table--narrow ml-m mr-m" style={{ width: '100%' }}>
      <tbody className="Sticky-Body">
        {expandedRows.map(key => (
          <AuditLogCreatedRow key={`${key}`} name={key} value={changes[key]} />
        ))}

        {!expand && changesRow.length > NUMBER_OF_SHOWED_ROWS && (
          <tr>
            <td>
              <a className="Link" onClick={() => setExpand(!expand)}>
                {window.reacti18n.data_input_preview.show_additional} {changesRow.length - NUMBER_OF_SHOWED_ROWS}
              </a>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

const AuditLogCreatedRow = ({ name, value }) => (
  <tr>
    <td style={{ width: '40%' }}>
      <Ellipsis>{formatChangesKey(name)}</Ellipsis>
    </td>
    <AuditLogChangedValue value={value} />
  </tr>
);

export const AuditLogChangedValue = ({ value, onlyOne = false }) => {
  let finalValue = value;
  if (Array.isArray(value)) {
    return (
      <React.Fragment>
        <AuditLogChangedValue value={value[0]} onlyOne />
        <AuditLogChangedValue value={value.slice(1).join(', ')} onlyOne />
      </React.Fragment>
    );
  } else if (value === null || typeof value === 'undefined') finalValue = null;
  else if (value === true) finalValue = <Icon kind="check" />;
  else if (value === false) finalValue = <Icon kind="cancel-mark-thin" />;

  return (
    <React.Fragment>
      {!onlyOne && <td style={{ width: '30%' }} />}
      <td style={{ width: '30%' }}>
        <Ellipsis>{finalValue}</Ellipsis>
      </td>
    </React.Fragment>
  );
};

const InvalidCell = () => <div>{window.reacti18n.table.no_data}</div>;

AuditLogCreatedRow.propTypes = {
  name: RPT.string,
  value: RPT.any,
};

AuditLogChangedValue.propTypes = {
  value: RPT.any,
  onlyOne: RPT.bool,
};

ChangesCell.propTypes = {
  changes: RPT.object,
};

export default ChangesCell;
