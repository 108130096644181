import React from 'react';
import { hot } from 'react-hot-loader/root';
import { PropTypes } from 'prop-types';
import { Icon, Row, Link } from '../index';
import { FEATURES } from './constants';
import ConditionalWrapper from '../ConditionalWrapper';

const FeatureWithIcon = ({ color, size, url, feature, featureHuman, kind }) => (
  <Row inline center shrink>
    <Icon kind={kind || FEATURES[feature].icon} size={size} color={color} className="mr-8" />
    <ConditionalWrapper condition={url} wrapper={child => <Link href={url}>{child}</Link>}>
      {featureHuman || feature}
    </ConditionalWrapper>
  </Row>
);

FeatureWithIcon.propTypes = {
  color: PropTypes.string,
  feature: PropTypes.oneOf([
    'campaign_products',
    'campaign_spend_facebook',
    'campaign_spend_search',
    'image_generator_campaigns',
    'image_generator_feed_export',
    'synchronizations_and_support',
    'data_source',
    'feed_export',
    'scraper',
    'synchronizations',
  ]),
  featureHuman: PropTypes.string,
  kind: PropTypes.string,
  size: PropTypes.string,
  url: PropTypes.string,
};

export default hot(FeatureWithIcon);
