import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cs from 'classnames';
import { Col, Row, Icon, Text } from '../components';
import { formatDate, t } from '../i18n';

const getStyles = (days, sign, equation) => {
  if (days !== 0) {
    if (sign === '-' && equation === 'DAYS>') return { circleLeft: true, lines: [0, 1, 1, 1], greenIcon: true };
    if (sign === '-' && equation === 'DAYS>=') {
      return { circleIncluded: true, circleLeft: true, lines: [0, 1, 1, 1], greenIcon: true };
    }
    if (sign === '-' && equation === 'DAYS<') return { circleLeft: true, lines: [1, 0, 0, 0] };
    if (sign === '-' && equation === 'DAYS<=') return { circleIncluded: true, circleLeft: true, lines: [1, 0, 0, 0] };
    if (sign === '+' && equation === 'DAYS>') return { circleRight: true, lines: [0, 0, 0, 1] };
    if (sign === '+' && equation === 'DAYS>=') return { circleIncluded: true, circleRight: true, lines: [0, 0, 0, 1] };
    if (sign === '+' && equation === 'DAYS<') return { circleRight: true, lines: [1, 1, 1, 0], greenIcon: true };
    if (sign === '+' && equation === 'DAYS<=') {
      return { circleIncluded: true, circleRight: true, lines: [1, 1, 1, 0], greenIcon: true };
    }
  }

  if (equation === 'DAYS>') return { lines: [0, 0, 1, 1] };
  if (equation === 'DAYS<') return { lines: [1, 1, 0, 0] };
  if (equation === 'DAYS>=') return { lines: [0, 0, 1, 1], greenIcon: true };
  if (equation === 'DAYS<=') return { lines: [1, 1, 0, 0], greenIcon: true };

  if (equation === '>') return { lines: [0, 0, 1, 1] };
  if (equation === '<') return { lines: [1, 1, 0, 0] };
  if (equation === '>=') return { lines: [0, 0, 1, 1], greenIcon: true };
  if (equation === '<=') return { lines: [1, 1, 0, 0], greenIcon: true };

  return { lines: [] };
};

const getTranslationKey = labelDays => {
  const n = Math.abs(parseInt(labelDays, 10));
  if (n > 4) return 'more_than_4';
  if (n <= 4 && n > 1) return 'between_1_4';
  return 'one';
};

const LabelRow = ({ date, currentDate, labelDays, circleLeft, circleRight }) => {
  const lastY = date
    .clone()
    .subtract(1, 'years')
    .get('year');
  const nextY = date
    .clone()
    .add(1, 'years')
    .get('year');

  return (
    <Row className="mt-0 pos-relative" justifyBetween>
      <Text size="xs" color="softGray" data-test-id="timeline--previous">
        {lastY}
      </Text>
      <Text size="xs" color="softGray" data-test-id="timeline--current">
        {currentDate ? t('react.tables.variables.timeline.current_date') : formatDate(date, 'long')}
      </Text>
      <Text size="xs" color="softGray" data-test-id="timeline--next">
        {nextY}
      </Text>
      {labelDays && (
        <Text
          size="xs"
          color="softGray"
          className={cs('pos-absolute x-center', { 'Timeline--left': circleLeft, 'Timeline--right': circleRight })}
          data-test-id="timeline--floatinCircle"
        >
          {t(`react.tables.variables.timeline.${getTranslationKey(labelDays)}`, { days: labelDays })}
        </Text>
      )}
    </Row>
  );
};

const Timeline = ({ days, sign, equation, date }) => {
  if (!['DAYS>', 'DAYS<', 'DAYS>=', 'DAYS<=', '>', '>=', '<', '<='].includes(equation)) return null;
  if (!days && days !== 0 && !date) return null;

  const { circleLeft, circleRight, circleIncluded, lines, greenIcon } = getStyles(days, sign, equation);

  const currentYear = date ? moment(date) : moment();
  const labelDays = days !== 0 ? sign + days : null;

  return (
    <Row shrink className="mt-8" data-test-id="timeline">
      <Col grow className="mb-8">
        <Row height="16px" style={{ marginBottom: '2px' }}>
          <Col grow className="Timeline">
            {lines.map((l, i) => (
              <div
                key={[l, i].join('-')}
                className={cs('Timeline__line', { 'Timeline__line--active': l })}
                data-test-id="timeline--line"
              />
            ))}

            <div
              className={cs('Timeline__circle', {
                'Timeline__circle--fill': circleIncluded,
                'Timeline--left': circleLeft,
                'Timeline--right': circleRight,
              })}
            />
            <div
              className={cs('Timeline__circle Timeline__circle--icon', { 'Timeline__circle--greenBorder': greenIcon })}
            >
              <Icon kind="calendar" size="12px" color={greenIcon ? '#3ED95E' : '#596774'} colored />
            </div>
          </Col>
        </Row>
        <LabelRow
          date={currentYear}
          currentDate={equation === 'DAYS>=' || equation === 'DAYS<='}
          labelDays={date ? null : labelDays}
          circleRight={circleRight}
          circleLeft={circleLeft}
        />
      </Col>
    </Row>
  );
};

Timeline.propTypes = {
  days: PropTypes.number,
  date: PropTypes.object,
  sign: PropTypes.oneOf(['-', '+']),
  equation: PropTypes.oneOf(['DAYS>', 'DAYS<', 'DAYS>=', 'DAYS<=', '>', '>=', '<', '<=']),
};
LabelRow.propTypes = {
  date: PropTypes.object,
  currentDate: PropTypes.bool,
  labelDays: PropTypes.string,
  circleLeft: PropTypes.bool,
  circleRight: PropTypes.bool,
};

export default Timeline;
