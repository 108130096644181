import React from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import { useMultiEdit, ACTIONS } from '../MultiEditContext';
import { Col, Row, DropdownMenu, Link, modalDataUrlOpener, Tooltip, Icon } from '../../index';
import { t, formatNumberInflection } from '../../../i18n';
import { STATUS } from '../MultiEdit';

const DropdownContext = React.createContext();

const Dropdown = ({ children, columns, hashSelectedData, ...rest }) => {
  // prettier-ignore
  const { state: { mainColumn, selected, multiEdit } } = useMultiEdit();

  const value = { columns, hashSelectedData, mainColumn, selected, multiEdit };

  return (
    <DropdownMenu {...rest} nowrap>
      <DropdownContext.Provider value={value}>{children}</DropdownContext.Provider>
    </DropdownMenu>
  );
};

Dropdown.propTypes = {
  columns: PropTypes.array,
  hashSelectedData: PropTypes.object,
  children: PropTypes.any,
};

function useDropdownContext() {
  const context = React.useContext(DropdownContext);
  if (!context) {
    throw new Error('Dropdown compound components cannot be rendered outside the Dropdown component');
  }
  return context;
}

const ActionWrapper = ({ children, type, tooltipText, icon, color, ...rest }) => {
  const { multiEdit } = useDropdownContext();

  if ((type && !multiEdit[type]) || (typeof rest.onClick !== 'function' && !rest.href)) return null;

  return (
    <Row className="mt-0" height="32px" center>
      <Col grow>
        <Tooltip text={rest.disabled ? tooltipText : null}>
          <Link {...rest}>
            <Row center padding="l">
              <Col shrink>
                <Icon kind={icon} color="danger" size="16px" />
              </Col>
              <Col className="Text--md">{children}</Col>
            </Row>
          </Link>
        </Tooltip>
      </Col>
    </Row>
  );
};
ActionWrapper.propTypes = {
  color: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
  onClick: PropTypes.func,
  tooltipText: PropTypes.string,
  type: PropTypes.string,
};

const EditComponent = ({ onClick, ...rest }) => {
  const { columns } = useDropdownContext();
  if (!columns || columns?.length === 0) return null;

  return (
    <React.Fragment>
      {columns.map(({ value, label }) => (
        <ActionWrapper
          {...rest}
          action
          icon="edit-value"
          data-test-id={`multiEdit-[edit-${value}]`}
          key={value}
          onClick={() => onClick({ value, label })}
          type="columnsEdit"
          value={value}
        >
          {label}
        </ActionWrapper>
      ))}
    </React.Fragment>
  );
};

EditComponent.propTypes = {
  onClick: PropTypes.func,
};

const getDuplicateURL = (url, arr) => url.concat('&template_ids=', arr);

const DuplicateIntoComponent = props => {
  const { hashSelectedData, mainColumn, selected, multiEdit } = useDropdownContext();
  if (!multiEdit.duplicateInto) return null;

  const first = hashSelectedData?.[selected[0]];
  if (!first) return null;
  const element = mainColumn.resolveChildren(first).links.find(x => x?.['data-test-id-duplicate-to']);
  const duplicateUrl = getDuplicateURL(element?.href, selected);

  const sameType = Object.keys(hashSelectedData).every(item => item.type === first.type);

  return (
    <ActionWrapper
      {...props}
      {...modalDataUrlOpener({
        url: duplicateUrl,
        size: 'medium',
        heading: t('react.multi_edit.duplicate_to_heading'),
      })}
      data-test-id-duplicate-into="multiEdit"
      action
      disabled={!sameType}
      tooltipText={t('react.multi_edit.not_the_same_type')}
      href={duplicateUrl}
      icon="duplicate-to"
    >
      {t('react.tables.duplicate_to')}
    </ActionWrapper>
  );
};

const StartComponent = props => (
  <ActionWrapper {...props} data-test-id-status="start" icon="triangle-left">
    {t('react.tables.start')}
  </ActionWrapper>
);

const PauseComponent = props => (
  <ActionWrapper {...props} data-test-id-status="pause" icon="pause">
    {t('react.tables.pause')}
  </ActionWrapper>
);

const getStatusOptions = mutation => {
  if (mutation?.includes(STATUS.ENUM.type)) return STATUS.ENUM;
  if (mutation?.includes(STATUS.BOOL.type)) return STATUS.BOOL;
  if (mutation?.includes(STATUS.ACTIVE_ENUM.type)) return STATUS.ACTIVE_ENUM;

  return null;
};

const StartAndPause = ({ onStart, onPause, ...rest }) => {
  const { hashSelectedData, multiEdit } = useDropdownContext();
  if (!multiEdit.changeStatus) return null;

  const [proceedCb, setProceed] = React.useState(false);

  const {
    dispatch,
    state: { selected, collectionKey },
  } = useMultiEdit();

  React.useEffect(() => {
    if (proceedCb) {
      setProceed(false);
      proceedCb();
    }
  }, [selected]);

  const statusOptions = getStatusOptions(multiEdit.changeStatus);

  const disabledStart = Object.keys(hashSelectedData).every(
    key => hashSelectedData[key][statusOptions.key] === statusOptions.on || hashSelectedData[key].disableStatus
  );
  const disabledPause = Object.keys(hashSelectedData).every(
    key => hashSelectedData[key][statusOptions.key] === statusOptions.off || hashSelectedData[key].disableStatus
  );

  const isFeed = collectionKey === 'feedExports';

  const handleClick = cb => () => {
    const newSelected = selected.filter(id => !hashSelectedData[id].disableStatus);
    dispatch({ type: ACTIONS.overrideSelected, payload: { newSelected } });

    setProceed(cb);
  };

  return (
    <React.Fragment>
      <StartComponent
        {...rest}
        disabled={disabledStart}
        tooltipText={t(isFeed ? 'start_disabled_feed' : 'start_disabled', { scope: 'react.multi_edit' })}
        onClick={handleClick(onStart)}
        action
      />
      <PauseComponent
        {...rest}
        disabled={disabledPause}
        tooltipText={t(isFeed ? 'paused_disabled_feed' : 'paused_disabled', { scope: 'react.multi_edit' })}
        onClick={handleClick(onPause)}
        action
      />
    </React.Fragment>
  );
};

StartAndPause.propTypes = {
  onStart: PropTypes.func,
  onPause: PropTypes.func,
};

const DeleteComponent = ({ count, onClick, callWithConfirmation, ...props }) => {
  const { hashSelectedData } = useDropdownContext();

  const {
    dispatch,
    state: { selected, collectionKey, multiEdit },
  } = useMultiEdit();

  const isDataSource = collectionKey === 'dataSources';

  const [proceedDelete, setProceedDelete] = React.useState(false);

  React.useEffect(() => {
    if (proceedDelete) {
      setProceedDelete(false);
      onClick();
    }
  }, [selected]);

  const deleted = Object.keys(hashSelectedData).reduce(
    (acc, cur) => {
      if (hashSelectedData[cur].deleteLink) return { ...acc, can: { ...acc.can, [cur]: hashSelectedData[cur] } };
      return { ...acc, cant: { ...acc.cant, [cur]: hashSelectedData[cur] } };
    },
    { can: {}, cant: {} }
  );

  let dataConfirmMessage = '';
  if (Object.keys(deleted?.cant).length > 0) {
    dataConfirmMessage = t('react.multi_edit.cant_delete', {
      elements: Object.keys(deleted.cant)
        .map(key => deleted.cant[key].name)
        .join(', '),
    });
  }

  dataConfirmMessage += formatNumberInflection({
    scope: 'react.multi_edit.delete_confirmation',
    count: Object.keys(deleted.can)?.length,
    otherKeys: {
      elements: Object.keys(deleted.can)
        .map(key => deleted.can[key]?.name)
        .join(', '),
    },
  });

  if (multiEdit?.deleteMessage) {
    dataConfirmMessage += `\n <p class="Text Text--bold mt-8">${multiEdit.deleteMessage}</p>`;
  }

  const handleClick = event => {
    event.stopPropagation();
    callWithConfirmation(
      () => {
        const newSelected = Object.keys(deleted?.can).map(id => deleted?.can[id].id);
        dispatch({ type: ACTIONS.overrideSelected, payload: { newSelected } });

        setProceedDelete(true);
      },
      {
        message: dataConfirmMessage,
        confirm: t('views.campaign_settings.index.yes', { default: 'Yes' }),
      }
    );
  };

  return (
    <ActionWrapper
      {...props}
      onClick={handleClick}
      danger
      color="danger"
      disabled={Object.keys(deleted?.can)?.length === 0}
      tooltipText={t(isDataSource ? 'none_deleted_tooltip_data_sources' : 'none_deleted_tooltip', {
        scope: 'react.multi_edit',
      })}
      data-test-id-delete="multiEdit"
      icon="trash"
      key={`delete_element-${count}`}
    >
      {t('react.tables.delete')}
    </ActionWrapper>
  );
};
DeleteComponent.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

const ChangeComponent = ({ onClick, label, ...props }) => (
  <ActionWrapper {...props} onClick={() => onClick({ value: snakeCase(props.type), label })}>
    {label}
  </ActionWrapper>
);
ChangeComponent.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

const VariableComponent = props => (
  <ChangeComponent
    {...props}
    icon="flash"
    type="changeVariable"
    data-test-id="multiEdit[swap-variable]"
    label={t('react.multi_edit.swap_variable')}
  />
);
const FindAndReplaceComponent = props => (
  <ChangeComponent
    {...props}
    icon="remap-type"
    type="findAndReplace"
    data-test-id="multiEdit[find-replace]"
    label={t('react.multi_edit.find_and_replace')}
  />
);

const TimeRestrictionComponent = ({ onDelete, onSelect }) => {
  const { selected, hashSelectedData, multiEdit } = useDropdownContext();
  if (!multiEdit.timeRestriction) return null;

  const countOfValidElements = selected.reduce(
    (acc, cur) => (hashSelectedData[cur]?.timeRestrictionStatus ? acc + 1 : acc),
    0
  );
  const disabledDelete = countOfValidElements === 0;

  return (
    <React.Fragment>
      <ActionWrapper
        action
        data-test-id-status="multiEdit[change-time-restriction]"
        icon="timer"
        onClick={() => onSelect({ value: 'time_restriction', label: t('react.multi_edit.time_restriction.change') })}
      >
        {t('react.multi_edit.time_restriction.change')}
      </ActionWrapper>
      <ActionWrapper
        action
        disabled={disabledDelete}
        tooltipText={t('react.multi_edit.time_restriction.delete_tooltip')}
        data-test-id-status="multiEdit[delete-time-restriction]"
        icon="timer"
        onClick={onDelete}
        data-confirm={formatNumberInflection({
          scope: 'react.multi_edit.time_restriction.are_you_sure_html',
          count: countOfValidElements,
        })}
        data-focus="cancel"
        data-commit={t('views.campaign_settings.index.yes', { default: 'Yes' })}
      >
        {t('react.multi_edit.time_restriction.delete')}
      </ActionWrapper>
    </React.Fragment>
  );
};

TimeRestrictionComponent.propTypes = {
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
};

Dropdown.Delete = DeleteComponent;
Dropdown.Variable = VariableComponent;
Dropdown.FindAndReplace = FindAndReplaceComponent;
Dropdown.DuplicateInto = DuplicateIntoComponent;
Dropdown.Edit = EditComponent;
Dropdown.Status = StartAndPause;
Dropdown.TimeRestriction = TimeRestrictionComponent;

export default Dropdown;
