import React, { useState } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import PropTypes from 'prop-types';
import { Button, Col, ErrorBox, PageHeading, Row, Text } from './index';
import { t, locale } from '../i18n';
import trainingImage from '../scss_stylesheets/images/training.png';
import { DetailWithIconLine, TrainingEventsModal, GET_TRAINING_EVENTS } from './TrainingEvent';
import { GrayBox } from './table/LoadingTable';

const InitialTrainingEvent = ({
  userEmail,
  userFirstName,
  userLastName,
  userCompany,
  userCompanyWeb,
  userPhoneNumber,
  userJobPosition,
  sandboxForceLoading,
  userPhoneCountryCode,
}) => {
  const [selectedEventID, setSelectedEventID] = useState(null);

  const { data, networkStatus, error } = useQuery(GET_TRAINING_EVENTS);

  const loading = networkStatus !== NetworkStatus.ready;

  if (error) {
    return <ErrorBox>{t('react.training_events.error')}</ErrorBox>;
  }

  if (loading || sandboxForceLoading) {
    return (
      <Col width="360px" center>
        <GrayBox width="360px" height="200px" />
        <PageHeading spacing={0} className="text-center mt-24">
          <GrayBox height="22px" width="220px" />
        </PageHeading>
        <Row padding="l" shrink className="mt-8 mb-16">
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
        </Row>
        <GrayBox width="360px" height="64px" />
        <div className="mt-16">
          <GrayBox width="120px" height="32px" />
        </div>
      </Col>
    );
  }

  const trainingEvent = data?.trainingEvents.find(event =>
    event.name.includes(locale === 'cs' ? 'Úvodní školení' : 'Initial Training')
  );

  if ((!trainingEvent || trainingEvent.amIGoing) && !loading) {
    return null;
  }

  return (
    <Col width="360px" center>
      <img
        src={trainingImage}
        width="360px"
        alt="Initial training"
        className="mb-24 mw-100 border-top-left-radius-8 border-top-right-radius-8"
      />
      <PageHeading spacing={0} className="text-center">
        {trainingEvent.name}
      </PageHeading>
      <Row className="mb-16">
        <DetailWithIconLine {...trainingEvent} />
      </Row>
      <Text color="gray" className="text-center">
        {t('react.training_events.initial_training.description')}
      </Text>
      <Button primary onClick={() => setSelectedEventID(trainingEvent.id)} className="mt-16">
        {t('react.training_events.initial_training.sign_up_button')}
      </Button>

      {selectedEventID && (
        <TrainingEventsModal
          userEmail={userEmail}
          userFirstName={userFirstName}
          userLastName={userLastName}
          userCompany={userCompany}
          userCompanyWeb={userCompanyWeb}
          userPhoneCountryCode={userPhoneCountryCode}
          userPhoneNumber={userPhoneNumber}
          userJobPosition={userJobPosition}
          {...trainingEvent}
          onClose={() => setSelectedEventID(null)}
        />
      )}
    </Col>
  );
};

InitialTrainingEvent.propTypes = {
  userEmail: PropTypes.string,
  sandboxForceLoading: PropTypes.bool,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userCompany: PropTypes.string,
  userCompanyWeb: PropTypes.string,
  userPhoneCountryCode: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  userJobPosition: PropTypes.string,
};

export default InitialTrainingEvent;
