import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import CardView from './CardView';
import ChooseLayout from './ChooseLayout';
import TableView from './TableView';
import { QueryHolder } from '../QueryHolder';
import { Input, Pagination, Button, Row, Col, Select, SectionHeading, Link, useLocalState } from '../index';
import { formatInteger, t } from '../../i18n';
import { setPageToUrl } from '../Pagination';
import ProductsViewContext from './ProductsViewContext';
import ProductFilter from './ProductFilter';
import ProductSorter from './ProductSorter';
import no_product_icon from '../../../../assets/images/empty_container/no_product_icon.png';
import DataAnalysis from '../data_analysis/DataAnalysis';

const QUERY_TABLE_VARIABLES_SORTED = gql`
  query DataSourceTableVariables($organizationId: BigInt!, $dataSourceId: BigInt, $campaignSettingId: BigInt) {
    organization(id: $organizationId) {
      id
      itemsSearchVariables(dataSourceId: $dataSourceId, campaignSettingId: $campaignSettingId) {
        id
        cardImage
        mainIdentifier
        tableVariablesSorted {
          name
          placeholderName
          elasticPlaceholderName
          showFieldKind
          isImageUrl
          previewLink
          imagesCount
          showInCard
          showInTable
        }
      }
    }
  }
`;

const QUERY_CONDITIONS_DATA_INPUT = gql`
  query DataSourceConditionsItemsPreview(
    $organizationId: BigInt!
    $dataSourceId: BigInt
    $campaignSettingId: BigInt
    $conditions: [String!]
    $elasticQuery: String
    $search: String
    $size: Int!
    $from: Int!
    $order: String
  ) {
    organization(id: $organizationId) {
      id
      itemsSearch(dataSourceId: $dataSourceId, campaignSettingId: $campaignSettingId) {
        id
        items(
          elasticQuery: $elasticQuery
          conditions: $conditions
          search: $search
          size: $size
          from: $from
          order: $order
        ) {
          data
          status
        }
        totalUnfilteredCount: itemsCount
        totalCount: itemsCount(elasticQuery: $elasticQuery, conditions: $conditions, search: $search)
      }
    }
  }
`;

const DataTransform = Component => {
  const WrapperComponent = ({ tableProps, data, from, size, changePage, perOptions, changeSize, ...rest }) => {
    const totalCount = data.organization.itemsSearch?.totalCount;
    const totalUnfilteredCount = data.organization.itemsSearch?.totalUnfilteredCount;

    if (!data?.organization?.itemsSearch?.items) {
      return null;
    }

    return (
      <React.Fragment>
        <Component
          data-test-id="products-view"
          items={
            data.organization.itemsSearch.items.map(item => ({ ...JSON.parse(item.data), __status: item.status })) || []
          }
          {...tableProps}
          {...rest}
        />

        <Pagination
          current={from / size}
          maxPages={Math.ceil(data.organization.itemsSearch.totalCount / size)}
          changePage={changePage}
          className="mt-16"
          data-all-total={totalUnfilteredCount}
          data-test-id="pagination-info"
          data-from={from + 1}
          data-to={Math.min(from + size, totalCount)}
          data-max-pages={Math.ceil(data.organization.itemsSearch.totalCount / size)}
          data-total={totalCount}
        >
          {t('react.show', {
            default: 'Show',
          })}
          <Select
            className="ml-16 mr-16"
            doNotUseInternalState
            id="products-perPage"
            name="products[perPage]"
            value={size}
            onChange={changeSize}
            collection={perOptions.map(value => ({
              value,
              label: t('react.items_count', { items: value }),
            }))}
          />
          {!isNaN(totalCount) &&
            t('react.outof', {
              default: 'out of %{total}',
              total: formatInteger(totalCount),
            })}{' '}
          {!isNaN(totalUnfilteredCount) &&
            totalCount !== totalUnfilteredCount &&
            t('react.fromtotal', {
              default: 'from all %{total}',
              total: formatInteger(totalUnfilteredCount),
            })}
        </Pagination>
      </React.Fragment>
    );
  };
  WrapperComponent.propTypes = {
    tableProps: PropTypes.string,
    data: PropTypes.object,
    from: PropTypes.number,
    size: PropTypes.number,
    changePage: PropTypes.func,
    changeSize: PropTypes.func,
    perOptions: PropTypes.arrayOf(PropTypes.number),
  };
  return WrapperComponent;
};

const layouts = {
  card: QueryHolder(DataTransform(CardView)),
  table: QueryHolder(DataTransform(TableView)),
  investigation: rest => <DataAnalysis {...rest} />,
};

const ProductsView = ({
  campaignSettingId,
  conditionsOptions = [],
  data,
  dataSourceId,
  disableFilters,
  editColumnsLink,
  elasticQuery,
  formId,
  header,
  newFilteredDataSourceLink,
  organizationId,
  parentConditions = [],
  perOptions = [20, 50, 100],
  showExplorer = false,
  tableProps,
  addNextFieldText,
}) => {
  const [from, setFrom] = useState(0);
  const [size, setSize] = useLocalState(20, 'ProductsView-size');
  const [search, setSearch] = useState('');
  const [conditions, setConditions] = useState([]);

  const [debounceSearch, setDebouncedSearch] = useState(search);

  const [cardImage, setCardImage] = useState(
    data.organization.itemsSearchVariables?.cardImage ||
      `${(data.organization.itemsSearchVariables?.tableVariablesSorted?.find(tv => tv.isImageUrl) || {})
        ?.placeholderName || 'not_existing'}#0`
  );

  const [additonalButtonsRef, setAdditonalButtonsRefState] = useState(null);

  const setAdditonalButtonsRef = useCallback(node => {
    if (node !== null) {
      setAdditonalButtonsRefState(node);
    }
  }, []);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setFrom(0);
      setPageToUrl(0);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  const [order, setOrder] = useState('product_id');
  const [layout, setLayout] = useLocalState('table', 'ProductsView-layout');
  const DataLayout = layouts[layout];

  if (layout === 'investigation' && !showExplorer) setLayout('table');

  if (data.organization.itemsSearchVariables?.tableVariablesSorted?.length === 0) {
    return (
      <div className="EmptyContainer EmptyContainer--center">
        <div className="EmptyContainer-content">
          <img
            src={no_product_icon}
            alt={t('views.data_sources.table..no_products', { default: 'No products found' })}
          />
          <SectionHeading className="mt-16">
            {t('views.data_sources.table..no_products', { default: 'No products found' })}
          </SectionHeading>
          <div>
            <Link href={`/organizations/${organizationId}/data_sources/${dataSourceId}/edit`}>
              {t('views.data_sources.table.edit_data_source', { default: 'Edit source' })}
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <ProductsViewContext.Provider
      value={{
        order,
        setOrder,
        cardImage,
        setCardImage,
        tableVariablesSorted: data.organization.itemsSearchVariables?.tableVariablesSorted,
        mainIdentifier: data.organization.itemsSearchVariables?.mainIdentifier,
      }}
    >
      <form>
        <Row padding="l" center>
          {header && (
            <Col grow>
              <SectionHeading spacing={0}>{header}</SectionHeading>
            </Col>
          )}
          <Col shrink>
            <Input
              id="search"
              disableEnter
              doNotUseInternalState
              placeholder={t('react.search')}
              search
              type="text"
              name="[search]"
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
              onSubmit={e => e.preventDefault()}
            />
          </Col>
          {!disableFilters && (
            <Col shrink>
              <ProductFilter
                newFilteredDataSourceLink={newFilteredDataSourceLink}
                setFrom={setFrom}
                conditions={conditions}
                setConditions={setConditions}
                conditionsOptions={conditionsOptions}
                hideProductViewButton
                addNextFieldText={addNextFieldText}
              />
            </Col>
          )}
          {((search && search.length > 0) || conditions.length > 0) && (
            <Col shrink>
              <Button
                secondary
                onClick={() => {
                  setDebouncedSearch('');
                  setSearch('');
                  setConditions([]);
                  setFrom(0);
                }}
                data-test-id="product-filter-reset"
              >
                {t('react.card_view.reset_filters')}
              </Button>
            </Col>
          )}

          {!header && <Col grow />}

          {layout === 'card' && (
            <React.Fragment>
              <Col shrink className="mr-8">
                <div className="Text Text--gray">{t('react.sort_by')}</div>
              </Col>
              <Col shrink>
                <ProductSorter />
              </Col>
            </React.Fragment>
          )}
          <Col shrink setRef={setAdditonalButtonsRef} />
          <Col shrink className="mr-0">
            <ChooseLayout showExplorer={showExplorer} layout={layout} setLayout={setLayout} />
          </Col>
          <Col shrink id="js-sub-additional-buttons" />
        </Row>

        <div className="pos-relative mt-16" data-form-id={formId ? `#${formId.replace('#', '')}` : undefined}>
          <DataLayout
            tableProps={tableProps}
            size={size}
            perOptions={perOptions}
            changeSize={({ target: { value } }) => {
              setSize(parseInt(value, 10));
              setFrom(0);
              setPageToUrl(0);
            }}
            from={from}
            changePage={newPage => () => {
              setFrom(newPage * size);
            }}
            loaderSize="big"
            additonalButtonsRef={additonalButtonsRef}
            editColumnsLink={editColumnsLink}
            variables={{
              organizationId,
              dataSourceId,
              campaignSettingId,
              elasticQuery,
              from,
              size,
              order,
              search: debounceSearch,
              conditions: parentConditions.concat(conditions).map(cond => {
                const {
                  left_part,
                  equation,
                  right_part,
                  right_part_variable,
                  right_part_array,
                  product_sets,
                  or_conditions,
                  group_id,
                  group_type,
                  negative_product_sets,
                  dynamic_value_count,
                  dynamic_value_percent,
                  dynamic_value_aggregation_function,
                  dynamic_value_selection,
                  dynamic_value_conditions,
                  selected_item_group_id,
                  negative,
                } = typeof cond === 'string' ? JSON.parse(cond) : cond;
                return JSON.stringify({
                  left_part,
                  equation,
                  right_part,
                  right_part_variable,
                  right_part_array,
                  product_sets,
                  or_conditions,
                  group_id,
                  group_type,
                  negative_product_sets,
                  dynamic_value_count,
                  dynamic_value_percent,
                  dynamic_value_aggregation_function,
                  dynamic_value_selection,
                  dynamic_value_conditions_attributes: dynamic_value_conditions,
                  selected_item_group_id,
                  negative,
                });
              }),
            }}
            query={QUERY_CONDITIONS_DATA_INPUT}
          />
        </div>
      </form>
    </ProductsViewContext.Provider>
  );
};
ProductsView.propTypes = {
  addNextFieldText: PropTypes.string,
  campaignSettingId: PropTypes.number,
  conditionsOptions: PropTypes.object,
  data: PropTypes.object,
  dataSourceId: PropTypes.number,
  disableFilters: PropTypes.bool,
  editColumnsLink: PropTypes.bool,
  elasticQuery: PropTypes.string,
  header: PropTypes.string,
  formId: PropTypes.string,
  newFilteredDataSourceLink: PropTypes.string,
  organizationId: PropTypes.number,
  parentConditions: PropTypes.array,
  perOptions: PropTypes.arrayOf(PropTypes.number),
  showExplorer: PropTypes.bool,
  tableProps: PropTypes.object,
};
export default QueryHolder(ProductsView, QUERY_TABLE_VARIABLES_SORTED);
