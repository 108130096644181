import React from 'react';
import PropTypes from 'prop-types';
import { orderBy, upperFirst } from 'lodash';
import { hot } from 'react-hot-loader/root';
import { gql, useQuery, NetworkStatus } from '@apollo/client';
import { Col, Row, SectionHeading, Tile, InfoBox } from '../index';
import { formatInteger, formatNumberInflection, t } from '../../i18n';
import { ActiveProductsBox, ExportStatesBox, ExportsTypesBox, ImageEditorBox } from './Boxes';
import FeedExportModal from './Modal';
import { STATES } from '../table/mapping/cells/index';
import ErrorBox from '../ErrorBox';

export const TYPES = { exportTypes: 'export_types', exportStates: 'export_states', imageEditor: 'image_editor' };

const FEED_EXPORT_QUERY = gql`
  query FeedExportQuery($organizationId: BigInt!) {
    collection(identifier: "FeedExport", page: 0, limit: 10000, organizationId: $organizationId) {
      id
      feedExports {
        feedItemsCount
        generatedAt
        dataSource {
          showLink
          typeName
          icon
          itemsCount
          name
        }
        showLink
        name
        feedExportState
        icon
        imageGeneratorStatus
        typeName
      }
    }
  }
`;

export const transformData = feedExports =>
  feedExports.reduce(
    (acc, curr) => {
      let { allItemsCount, activeItemsCount, imageEditorCount } = acc;
      if (curr.feedExportState === 'active') activeItemsCount += curr.feedItemsCount;
      if (curr.imageGeneratorStatus === 'active') {
        imageEditorCount += 1;
        (acc.withEditor[curr.dataSource.name] = acc.withEditor[curr.dataSource.name] || []).push(curr);
      }
      allItemsCount += curr.feedItemsCount;

      (acc.types[curr?.icon] = acc.types[curr?.icon] || []).push(curr);

      const newItem = STATES[curr?.feedExportState]({ kind: 'feed_export', lastUpdatedAt: curr?.generatedAt });

      const customState =
        curr.feedExportState === 'active' && newItem?.icon !== 'wait'
          ? STATES[curr?.feedExportState]({ kind: 'feed_export' })
          : newItem;

      (acc.states[customState?.icon] = acc.states[customState?.icon] || []).push({
        ...curr,
        customState,
      });

      return {
        allItemsCount,
        activeItemsCount,
        imageEditorCount,
        types: acc.types,
        states: acc.states,
        withEditor: acc.withEditor,
      };
    },
    { allItemsCount: 0, activeItemsCount: 0, imageEditorCount: 0, types: {}, states: {}, withEditor: {} }
  );

const getTypesData = ({ data, tooltipkey }) =>
  Object.keys(data).map(kind => ({
    kind,
    count: data[kind].length,
    tooltip: data[kind][0]?.[tooltipkey],
  }));

const getStatesData = ({ data }) =>
  Object.keys(data).map(x => {
    const y = data[x][0]?.customState;
    const tooltip = upperFirst(y?.text.toLowerCase());
    const kind = x;

    return {
      kind,
      count: data[x].length,
      tooltip,
      color: y?.iconColor,
      badgeKind: y?.badgeKind,
      textColor: y?.textColor,
    };
  });

const transformEditorData = data =>
  Object.keys(data).map(x => ({
    dataSource: data[x][0].dataSource,
    feedExports: [...data[x]],
    count: data[x].length,
  }));

const getData = feedExports => {
  const { allItemsCount, activeItemsCount, imageEditorCount, types, states, withEditor } = transformData(feedExports);

  const typesCollection = orderBy(getTypesData({ data: types, tooltipkey: 'typeName' }), 'count', 'desc');

  const statesCollection = orderBy(getStatesData({ data: states }), 'count', 'desc');

  return {
    allItemsCount,
    activeItemsCount,
    imageEditorCount,
    typesCollection,
    statesCollection,
    withEditor: transformEditorData(withEditor),
  };
};

const LoadingContent = ({ numberOfBoxes }) => {
  const arr = Array(numberOfBoxes).fill(0);
  return (
    <Row padding="l">
      {arr.map((x, i) => (
        <Col key={[x, i].join('-')} grow style={{ height: '48px', background: '#efefef', borderRadius: '8px' }} />
      ))}
    </Row>
  );
};

const OverviewContent = ({ data }) => {
  const [modalState, toggleModal] = React.useState(false);
  const [modalData, setModalData] = React.useState(null);

  const { allItemsCount, activeItemsCount, imageEditorCount, typesCollection, statesCollection, withEditor } = getData(
    data
  );

  const handleModal = md => {
    toggleModal(true);
    setModalData(md);
  };
  return (
    <React.Fragment>
      <Row>
        <ActiveProductsBox
          count={activeItemsCount}
          allItemsCount={allItemsCount}
          heading={t('products_in_active_exports', { scope: 'react.feed_export_overview' })}
          description={formatInteger(activeItemsCount)}
        />
        <ExportStatesBox
          collection={statesCollection}
          heading={t('exports_statuses', { scope: 'react.feed_export_overview' })}
          showModal={col => {
            handleModal({
              data: col,
              heading: t('exports_statuses', { scope: 'react.feed_export_overview.modals' }),
              type: TYPES.exportStates,
            });
          }}
        />
        <ExportsTypesBox
          kind="feed-export"
          color="#AC8EE3"
          collection={typesCollection}
          heading={t('export_types_in_use', { scope: 'react.feed_export_overview' })}
          showModal={col => {
            handleModal({
              data: col,
              heading: t('export_types_in_use', { scope: 'react.feed_export_overview.modals' }),
              type: TYPES.exportTypes,
            });
          }}
        />
        <ImageEditorBox
          count={imageEditorCount}
          collection={withEditor}
          kind="image-export"
          color="#9BCC63"
          heading={t('image_editor_active_in', { scope: 'react.feed_export_overview' })}
          description={formatNumberInflection({
            count: imageEditorCount,
            scope: 'react.feed_export_overview.image_editor_description',
            binaryOptions: true,
          })}
          showModal={col => {
            handleModal({
              data: col,
              heading: t('image_editor_active_in', { scope: 'react.feed_export_overview.modals' }),
              type: TYPES.imageEditor,
            });
          }}
        />
      </Row>
      {modalState && modalData && <FeedExportModal modalData={modalData} toggleModal={toggleModal} />}
    </React.Fragment>
  );
};

const Wrapper = ({ children }) => (
  <Tile className="mb-16" shadow>
    <SectionHeading>{t('react.feed_export_overview.overview')}</SectionHeading>
    {children}
  </Tile>
);

const FeedExportsOverview = ({ organizationId }) => {
  const { data, networkStatus, error } = useQuery(FEED_EXPORT_QUERY, {
    variables: { organizationId: parseInt(organizationId, 10) },
  });
  const loading = networkStatus !== NetworkStatus.ready;
  const feedExports = data?.collection?.feedExports;

  if (error) {
    return (
      <Wrapper>
        <ErrorBox withIcon type="error">
          {t('react.tuner.error')}
        </ErrorBox>
      </Wrapper>
    );
  }

  if (!loading && (!feedExports || feedExports.length === 0)) {
    return (
      <Wrapper>
        <InfoBox withIcon type="warning">
          {t('feature_period.no_data')}
        </InfoBox>
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <LoadingContent numberOfBoxes={4} />
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <OverviewContent data={feedExports} />
    </Wrapper>
  );
};

LoadingContent.propTypes = { numberOfBoxes: PropTypes.number };
OverviewContent.propTypes = { data: PropTypes.array };
Wrapper.propTypes = { children: PropTypes.any };
FeedExportsOverview.propTypes = { organizationId: PropTypes.number };

export default hot(FeedExportsOverview);
