import { TextCell } from './cells';

export const setupColumn = ({
  category,
  cell,
  editable,
  disableMultieditEditation,
  editButtonAsProp,
  editQuery,
  fixed,
  graphqlFields,
  header,
  smallPadding,
  id,
  onUpdate,
  order,
  orderedByDescFirst,
  pollingInterval,
  required,
  resolveChildren,
  reverseOrder,
  cellCouldBeEmpty,
  useEllipsis,
  width,
}) => ({
  [id]: {
    id,
    disableMultieditEditation,
    fixed,
    order,
    orderedByDescFirst: !!orderedByDescFirst,
    reverseOrder: !!reverseOrder,
    required: !!required,
    header: header || id,
    graphqlFields: graphqlFields || id,
    editable: !!editable && editable,
    editButtonAsProp: editable && !!editButtonAsProp,
    editQuery: !!editQuery && editQuery,
    cell: cell || TextCell,
    width,
    smallPadding,
    pollingInterval,
    cellCouldBeEmpty,
    category: category || 'setup',
    useEllipsis,
    onUpdate,
    resolveChildren:
      resolveChildren ||
      (data => {
        const fieldOrId = graphqlFields || id;
        if (typeof fieldOrId === 'string') {
          return data[id];
        }
        return JSON.stringify(data);
      }),
  },
});

export default setupColumn;
