window.initNestedFieldsOrder = () => {
  const updatePositions = () => {
    $('.nested-fields:visible [name$="[position]"]').each((index, e) => {
      $(e).val('');
    });
    $('.nested-fields:visible [name$="[position]"]').each((index, e) => {
      $(e).val(index);
      $(e).trigger('change');
    });
  };

  const handleButtonState = () => {
    $('.js-order-move-up').removeClass('disabled');
    $('.js-order-move-down').removeClass('disabled');
    $('.js-order-move-top').removeClass('disabled');
    $('.js-order-move-bottom').removeClass('disabled');

    if ($('.nested-fields:visible').length >= 0) {
      $('.nested-fields:visible .js-order-move-up')
        .first()
        .addClass('disabled');
      $('.nested-fields:visible .js-order-move-down')
        .last()
        .addClass('disabled');
      $('.nested-fields:visible .js-order-move-top')
        .first()
        .addClass('disabled');
      $('.nested-fields:visible .js-order-move-bottom')
        .last()
        .addClass('disabled');
    }
    updatePositions();
  };

  const attachButtons = () => {
    Array.from(document.getElementsByClassName('js-order-move-top')).forEach(e => {
      if (!e.dataset.positionChangeInitialized) {
        e.dataset.positionChangeInitialized = true;
        e.addEventListener('click', () => {
          const item = $(e).closest('.nested-fields');
          const previous = item.prevAll('.nested-fields').last();
          if (previous.length > 0) {
            item.detach().insertBefore(previous);
          }
          handleButtonState();
        });
      }
    });

    Array.from(document.getElementsByClassName('js-order-move-up')).forEach(e => {
      if (!e.dataset.positionChangeInitialized) {
        e.dataset.positionChangeInitialized = true;
        e.addEventListener('click', () => {
          const item = $(e).closest('.nested-fields');
          const previous = item.prevAll('.nested-fields').first();
          if (previous.length > 0) {
            item.detach().insertBefore(previous);
          }
          handleButtonState();
        });
      }
    });

    Array.from(document.getElementsByClassName('js-order-move-down')).forEach(e => {
      if (!e.dataset.positionChangeInitialized) {
        e.dataset.positionChangeInitialized = true;
        e.addEventListener('click', () => {
          const item = $(e).closest('.nested-fields');
          const next = item.nextAll('.nested-fields').first();
          if (next.length > 0) {
            item.detach().insertAfter(next);
          }
          handleButtonState();
        });
      }
    });

    Array.from(document.getElementsByClassName('js-order-move-bottom')).forEach(e => {
      if (!e.dataset.positionChangeInitialized) {
        e.dataset.positionChangeInitialized = true;
        e.addEventListener('click', () => {
          const item = $(e).closest('.nested-fields');
          const next = item.nextAll('.nested-fields').last();
          if (next.length > 0) {
            item.detach().insertAfter(next);
          }
          handleButtonState();
        });
      }
    });
  };

  attachButtons();
  handleButtonState();
  $('.add_fields, .js-add-field, .choose-regexp').click(() => {
    setTimeout(attachButtons, 100);
    setTimeout(handleButtonState, 100);
  });
};
