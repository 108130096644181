import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../index';
import { Col, Row } from '../layout';
import { t } from '../../i18n';
import Input from '../Input';
import PlaceholderInput from '../../placeholders/Input';

const UtmRow = ({ name, value, id, changeValue, changeName, deleteRow, placeholderInputProps }) => (
  <Row className="mt-16" bottom data-test-id="utm-row">
    <Col width="295px">
      <Input
        id={`utm_builder_${id}_name`}
        name={`utm_builder[${id}][name]`}
        value={name}
        label={t('react.utm_parameters.labels.parameter_name')}
        required={false}
        onChange={changeName}
        placeholder={t('react.utm_parameters.examples.parameter_name_placeholder', { default: 'Example: Language' })}
      />
    </Col>
    <Col width="300px">
      <Row shrink className="mb-4">
        <label className="Input-label string" htmlFor={`utm_builder_${id}_value`}>
          {t('react.utm_parameters.labels.parameter_value')}
        </label>
      </Row>
      <Row className="mb-0" shrink>
        <PlaceholderInput
          id={`utm_builder_${id}_value`}
          {...placeholderInputProps}
          name={`utm_builder[${id}][value]`}
          onChange={changeValue}
          defaultValue={value}
          placeholder={t('react.utm_parameters.examples.parameter_value_placeholder', { default: 'Example: English' })}
        />
      </Row>
    </Col>
    <Col shrink>
      <Button
        onlyIcon
        data-test-id="delete-custom-parameter"
        icon="trash"
        kind="tertiary"
        onClick={() => deleteRow(id)}
      />
    </Col>
  </Row>
);

UtmRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.number,
  changeValue: PropTypes.func,
  changeName: PropTypes.func,
  deleteRow: PropTypes.func,
  placeholderInputProps: PropTypes.object,
};

export default UtmRow;
