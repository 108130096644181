import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Tile, Row, Col, Link, Input, Button, ErrorBox, Loader, SmallHeading, Icon, Text, Tooltip } from './index';
import LikeIcon from './notifications/LikeIcon';
import { t } from '../i18n';

const QUERY_REQUESTED_FEATURES = gql`
  query WishList($category: String!) {
    requestedFeatures(category: $category) {
      name
      id
      status
      category
      liked
      likedCount
      description
      mine
    }
  }
`;

const CREATE_REQUESTED_FEATURE = gql`
  mutation CreateRequestedFeature($name: String!, $category: String!) {
    createRequestedFeature(name: $name, category: $category) {
      requestedFeature {
        name
        id
        status
        category
        liked
        likedCount
        mine
      }
      errors
    }
  }
`;

const INTERACTION_QUERY = gql`
  mutation RecordInteraction($id: String!, $seen: Boolean!, $vote: String) {
    recordNotificationInteraction(id: $id, seen: $seen, vote: $vote) {
      requestedFeature {
        id
        liked
        likedCount
      }
    }
  }
`;

export const WishListLine = ({ name, description, status, likedCount, liked, id, mine }) => {
  const [changeVote] = useMutation(INTERACTION_QUERY);

  return (
    <Tile data-test-id={`wish-list-element-[${name}]`}>
      <Row center padding="l">
        <Col grow noPadding>
          <Row>
            <Text data-test-id="wish-list-title" bold>
              {name}
            </Text>
          </Row>
          <Row>
            <div data-test-id="wish-list-description" className="Tile-description">
              {description}
            </div>
          </Row>
        </Col>
        {mine && (
          <Col shrink>
            <Tooltip text={t('react.wish_list.mine_tooltip')} className="line-height-1">
              <Icon kind="personal" size="16px" color="#598774" />
            </Tooltip>
          </Col>
        )}
        <Col shrink data-test-id="wish-list-status">
          {status === 'draft' && (
            <Tooltip text={t('react.wish_list.status_tooltip')}>
              <Row center>
                <Icon kind="wait" size="14px" color="#8C969F" />
                <Text size="xs" spaced uppercase color="softGray" bold className="ml-8">
                  {t('react.wish_list.status')}
                </Text>
              </Row>
            </Tooltip>
          )}
          {status === 'implemented' && (
            <Row center>
              <Icon kind="active" size="14px" color="#3ed95e" />
              <Text size="xs" spaced uppercase color="green" bold className="ml-8">
                {t('react.wish_list.implemented')}
              </Text>
            </Row>
          )}
          {status === 'approved' && (
            <Row center>
              <Col shrink>
                <Link
                  data-test-id="vote-liked"
                  onClick={() => {
                    changeVote({ variables: { id, seen: true, vote: liked ? 'disliked' : 'liked' } });
                  }}
                >
                  <LikeIcon kind="good" size={24} selected={liked} />
                </Link>
              </Col>
              <Col width="24px">
                {likedCount > 0 && (
                  <Text semibold color={!liked && 'gray'} size="xs" data-test-id="count">
                    {likedCount > 999 ? '999+' : likedCount}
                  </Text>
                )}
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Tile>
  );
};

WishListLine.propTypes = {
  name: PropTypes.string,
  description: PropTypes.string,
  status: PropTypes.string,
  likedCount: PropTypes.number,
  liked: PropTypes.bool,
  id: PropTypes.string,
  mine: PropTypes.bool,
};

export const WishListTable = ({ requestedFeatures = [] }) => (
  <React.Fragment>
    {requestedFeatures.map(feature => (
      <Row key={feature.id}>
        <WishListLine {...feature} />
      </Row>
    ))}
  </React.Fragment>
);
WishListTable.propTypes = {
  requestedFeatures: PropTypes.array,
};

export const WishListForm = ({ category, refetch, search }) => {
  const [name, setName] = useState('');

  const [createRequest, { data, loading, error }] = useMutation(CREATE_REQUESTED_FEATURE, {
    variables: { category, name: search || name },
    onCompleted: d => {
      if (d?.createRequestedFeature?.errors.length > 0) {
        new window.NotificationCenter().show_error(d?.createRequestedFeature?.errors[0]);
      } else {
        refetch();
        setName('');
      }
    },
  });

  const handleClick = () => {
    createRequest();
  };

  const errorString = data?.createRequestedFeature?.errors?.[0] || error?.message;
  if (search) {
    return (
      <Row className="mv-32">
        <Col center>
          <Icon kind="image-text" size="101px" color="#8C969F" />
          <Text bold className="mt-8">
            {t(`${category}.not_found`, { scope: 'react.wish_list.search' })}
          </Text>
          <SmallHeading className="mt-40">
            {t(`${category}.new_request`, { scope: 'react.wish_list.search', search })}
          </SmallHeading>
          <Tooltip text={t('react.wish_list.request_button_tooltip')}>
            <Button primary onClick={handleClick} disabled={loading}>
              {t('react.wish_list.request_button')}
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  }

  return (
    <Row>
      <Col grow>
        <form>
          <Row>
            <Col grow>
              <Input
                name="requested_feature[name]"
                placeholder={t('react.wish_list.request_input_placeholder')}
                value={name}
                onChange={({ target: { value } }) => setName(value)}
                doNotUseInternalState
                error={!!errorString}
              />
              {errorString && <span className="Input-errorLabel">{errorString}</span>}
            </Col>
            <Col shrink>
              <Tooltip text={t('react.wish_list.request_button_tooltip')}>
                <Button secondary onClick={handleClick} disabled={loading} data-test-id="wish-list-submit">
                  {t('react.wish_list.request_button')}
                </Button>
              </Tooltip>
            </Col>
          </Row>
        </form>
      </Col>
    </Row>
  );
};

WishListForm.propTypes = {
  category: PropTypes.string,
  refetch: PropTypes.func,
  search: PropTypes.string,
};

const WishList = ({ category, search, enableQuickWishList, callbackFn }) => {
  const { data, error, loading, refetch } = useQuery(QUERY_REQUESTED_FEATURES, { variables: { category } });
  let requestedFeatures = data?.requestedFeatures || [];

  if (search) {
    requestedFeatures = requestedFeatures.filter(
      ({ name, description }) =>
        name.toLowerCase().includes(search.toLowerCase()) || description?.toLowerCase().includes(search.toLowerCase())
    );
  }

  useEffect(() => {
    if (typeof callbackFn === 'function') {
      callbackFn(requestedFeatures.length === 0 && enableQuickWishList && search);
    }
  }, [requestedFeatures]);

  return (
    <Col data-test-id={`wish-list-${category}`}>
      {error && (
        <Row>
          <ErrorBox withIcon>{error.message}</ErrorBox>
        </Row>
      )}
      {loading && (
        <Row center justifyCenter>
          <Loader size="medium" />
        </Row>
      )}
      {!loading && <WishListTable requestedFeatures={requestedFeatures} />}

      <WishListForm
        category={category}
        refetch={() => refetch()}
        search={requestedFeatures.length === 0 && enableQuickWishList && search}
      />
    </Col>
  );
};

WishList.propTypes = {
  category: PropTypes.string,
  search: PropTypes.string,
  enableQuickWishList: PropTypes.bool,
  callbackFn: PropTypes.func,
};

export default WishList;
