import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { gql, useQuery } from '@apollo/client';
import Tile from './Tile';
import CHART_COLORS, { CHART_X_AXIS_STYLES, CHART_Y_AXIS_STYLES } from '../ChartColors';
import { t, formatDate } from '../../i18n';
import NoDataChartOverlay from '../NoDataChartOverlay';
import LocalizedChartTooltip from '../LocalizedChartTooltip';

const QUERY_DASHBOARD_RECENT_CHANGES_ORGANIZATION_QUERY = gql`
  query DashboardOrganizationRecentChanges($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      auditLogData(dateRange: "LAST_30_DAYS") {
        date
        count
      }
    }
  }
`;
const QUERY_DASHBOARD_RECENT_CHANGES_CAMPAIGNS_QUERY = gql`
  query DashboardCampaignsRecentChanges($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        auditLogData(dateRange: "LAST_30_DAYS") {
          date
          count
        }
      }
    }
  }
`;
const QUERY_DASHBOARD_RECENT_CHANGES_DATASOURCE_QUERY = gql`
  query DashboardDataSourcesRecentChanges($organizationId: BigInt!, $dataSourceId: BigInt!) {
    organization(id: $organizationId) {
      id
      dataSource(id: $dataSourceId) {
        id
        auditLogData(dateRange: "LAST_30_DAYS") {
          date
          count
        }
      }
    }
  }
`;

const QUERY_DASHBOARD_RECENT_CHANGES_FEEDEXPORT_QUERY = gql`
  query DashboardFeedExportsRecentChanges($organizationId: BigInt!, $feedExportId: BigInt!) {
    organization(id: $organizationId) {
      id
      feedExport(id: $feedExportId) {
        id
        auditLogData(dateRange: "LAST_30_DAYS") {
          date
          count
        }
      }
    }
  }
`;

const QUERY_DASHBOARD_RECENT_CHANGES_ADDITIONAL_SOURCE_QUERY = gql`
  query DashboardFeedExportsRecentChanges($organizationId: BigInt!, $additionalSourceId: BigInt!) {
    organization(id: $organizationId) {
      id
      additionalSource(id: $additionalSourceId) {
        id
        auditLogData(dateRange: "LAST_30_DAYS") {
          date
          count
        }
      }
    }
  }
`;

const getCorrectQuery = ({ campaignSettingId, dataSourceId, feedExportId, additionalSourceId }) => {
  if (campaignSettingId) return QUERY_DASHBOARD_RECENT_CHANGES_CAMPAIGNS_QUERY;
  if (dataSourceId) return QUERY_DASHBOARD_RECENT_CHANGES_DATASOURCE_QUERY;
  if (feedExportId) return QUERY_DASHBOARD_RECENT_CHANGES_FEEDEXPORT_QUERY;
  if (additionalSourceId) return QUERY_DASHBOARD_RECENT_CHANGES_ADDITIONAL_SOURCE_QUERY;
  return QUERY_DASHBOARD_RECENT_CHANGES_ORGANIZATION_QUERY;
};

const getData = ({ data, campaignSettingId, dataSourceId, feedExportId, additionalSourceId }) => {
  let auditLogData = data?.organization?.auditLogData;
  if (campaignSettingId) auditLogData = data?.organization?.campaignSetting?.auditLogData;
  if (dataSourceId) auditLogData = data?.organization?.dataSource?.auditLogData;
  if (feedExportId) auditLogData = data?.organization?.feedExport?.auditLogData;
  if (additionalSourceId) auditLogData = data?.organization?.additionalSource?.auditLogData;

  return [].concat(auditLogData).sort((a, b) => a.date.localeCompare(b.date));
};

export const RecentChangesComponent = ({ data, error, loading }) => {
  const totalCount = data && data.reduce((acc, item) => acc + item?.count, 0);
  const legendFormatter = val => formatDate(val, 'short');

  return (
    <Tile header={t('react.dashboard.recent_changes.header')} dashboard error={error} loading={loading}>
      {data && (
        <Fragment>
          <NoDataChartOverlay noData={totalCount === 0} message={t('react.dashboard.recent_changes.no_data')}>
            <ResponsiveContainer width="100%" height={200}>
              <BarChart
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 0,
                  bottom: 5,
                }}
              >
                <XAxis {...CHART_X_AXIS_STYLES} dataKey="date" tickFormatter={legendFormatter} />
                <YAxis {...CHART_Y_AXIS_STYLES} />
                <Tooltip
                  content={props => (
                    <LocalizedChartTooltip
                      legendFormatter={legendFormatter}
                      tooltipTranslationPath={'react.dashboard.recent_changes'}
                      {...props}
                    />
                  )}
                />
                <Bar dataKey="count" fill={CHART_COLORS[0]} barSize={30} isAnimationActive={false} />
              </BarChart>
            </ResponsiveContainer>
          </NoDataChartOverlay>
        </Fragment>
      )}
    </Tile>
  );
};

RecentChangesComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.string,
  ctaLink: PropTypes.string,
  loading: PropTypes.bool,
};

export const RecentChanges = ({
  organizationId,
  campaignSettingId,
  dataSourceId,
  feedExportId,
  additionalSourceId,
  ...rest
}) => {
  const { error, data, loading } = useQuery(
    getCorrectQuery({ campaignSettingId, dataSourceId, feedExportId, additionalSourceId }),
    {
      variables: {
        organizationId: organizationId ? parseInt(organizationId, 10) : 0,
        campaignSettingId: campaignSettingId ? parseInt(campaignSettingId, 10) : 0,
        dataSourceId: dataSourceId ? parseInt(dataSourceId, 10) : 0,
        feedExportId: feedExportId ? parseInt(feedExportId, 10) : 0,
        additionalSourceId: additionalSourceId ? parseInt(additionalSourceId, 10) : 0,
      },
    }
  );

  const sortedData = getData({ data, campaignSettingId, dataSourceId, feedExportId, additionalSourceId });

  return <RecentChangesComponent {...rest} data={sortedData} error={error} loading={loading} />;
};

RecentChanges.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number,
  dataSourceId: PropTypes.number,
  feedExportId: PropTypes.number,
  additionalSourceId: PropTypes.number,
  type: PropTypes.string.isRequired,
};
