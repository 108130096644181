document.addEventListener('DOMContentLoaded', () => {
  const setConfirmModalDefaults = () => {
    const data = {
      title: window.old_t('modal.title'),
      commit: window.old_t('modal.commit'),
      cancel: window.old_t('modal.cancel'),
      confirm: window.old_t('modal.confirm'),
      cancelClass: 'Button Button--tertiary cypress-modal-confirm-cancel',
      commitClass: 'Button Button--red mr-8 cypress-modal-confirm-commit',
      modalClass: 'Modal--confirm',
    };
    window.dataConfirmModal.setDefaults(data);
  };

  if (window.dataConfirmModal?.setDefaults && window.old_t) {
    setConfirmModalDefaults();
  } else {
    setTimeout(setConfirmModalDefaults, 100);
  }
});
