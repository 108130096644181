export const getNestedFieldOptions = ({ formBase, field, index }) => subfield => ({
  name: `${formBase}[${field}_attributes][${index}][${subfield}]`,
  id: `${formBase}_${field}_attributes_${index}_${subfield}`,
  formBase: `${formBase}[${field}_attributes][${index}]`,
});

export const addFieldToNestedFieldOptions = (obj = { name: '', id: '' }) => subfield => ({
  name: `${obj.name}[${subfield}]`,
  id: `${obj.id}_${subfield}`,
});

export default getNestedFieldOptions;
