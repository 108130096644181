import React, { memo } from 'react';
import { Col, Input } from '../../../index';
import styles from './styles';

const Column = ({ showIf = true, shrink = false, width, ...props }) => {
  if (!showIf) {
    return <div style={styles.empty} />;
  }

  return (
    <Col shrink={shrink} width={width} style={{ ...props.style }}>
      {props.label && <div style={styles.inputHelper}>{props.label}</div>}
      {props.children || <Input value={props.value} type="integer" onChange={e => props.onChange(e.target.value)} />}
    </Col>
  );
};

export default memo(Column);
