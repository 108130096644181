import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NestedActiveDataContext, CounterContext } from './WrapperRenderers';
import { t, formatInteger } from '../i18n';
import funelRest from '../scss_stylesheets/images/funel_rest.svg';

const ConditionalLabelFunelCounter = ({ options, position, id }) => {
  const activeFieldData = useContext(NestedActiveDataContext);
  const { counters } = useContext(CounterContext);
  const previousFieldData = activeFieldData.filter(fd => fd.position < position);
  const currentFieldData = activeFieldData.find(fd => fd.id === id);

  const remainingCount = previousFieldData.concat([currentFieldData]).reduce((out, pfd) => {
    if (typeof out === 'undefined' || typeof counters[pfd.id.toString()] === 'undefined') {
      return out;
    }

    return out + counters[pfd.id.toString()];
  }, 0);

  return (
    <span className="pos-relative Sortable-item--hiddenOnSort">
      <img
        src={funelRest}
        className="vam ml-24"
        alt={t('views.modifiers.form_fields.products_remaining', { default: 'products remaining' })}
      />
      <span className="vam ml-24">
        <b className="vam">
          {formatInteger(options.data_source_count - remainingCount)}{' '}
          {t('views.modifiers.form_fields.products_remaining', { default: 'products remaining' })}
        </b>
      </span>
    </span>
  );
};

ConditionalLabelFunelCounter.propTypes = {
  options: PropTypes.object,
  position: PropTypes.number,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ConditionalLabelFunelCounter;
