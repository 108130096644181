import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Button, Col, Link, Modal, ModalBody, ModalFooter, ModalHeader, ModalHeading, Row } from '../index';
import { t, formatInteger } from '../../i18n';

const setPreviewUrl = (link, closeModal) => {
  closeModal();
  const input = document.querySelector('[name*="[preview_variables_url]"]');

  if (input && input.value !== link) {
    input.value = link;
    input.dispatchEvent(new window.Event('change', { bubbles: true }));
    window.startLoadingPreview();
  }
};

const PagesModal = ({ closeModal, modalData }) => (
  <Modal onClose={closeModal} border>
    <ModalHeader>
      <ModalHeading>
        {t('react.scraper.items_pages_to_be_scraped', {
          count: formatInteger(modalData?.pagesCount || modalData?.data.length),
        })}
      </ModalHeading>
    </ModalHeader>
    <ModalBody>
      {modalData?.data.map((link, index) => (
        <Row key={link} className={cs('mh-40 pv-8 mt-0', { delimiter: modalData?.data.length - 1 !== index })} center>
          <Col grow>
            <Link href={link} external className="pv-4">
              {link}
            </Link>
          </Col>
          <Col shrink>
            <Button icon="flash" secondary onClick={() => setPreviewUrl(link, closeModal)}>
              {t('react.scraper.show_scraped_variables_button')}
            </Button>
          </Col>
        </Row>
      ))}
    </ModalBody>
    <ModalFooter>
      <Button tertiary onClick={closeModal}>
        {t('react.dashboard.insights.modal_close')}
      </Button>
    </ModalFooter>
  </Modal>
);

PagesModal.propTypes = {
  closeModal: PropTypes.func,
  modalData: PropTypes.object,
};

export default PagesModal;
