const WorkerCode = `
  var RemapVariableWorkerExecState = {};

  var normalizeMatchingKey = function normalizeMatchingKey(key) {
    var _key$trim;

    return key === null || key === void 0 ? void 0 : (_key$trim = key.trim()) === null || _key$trim === void 0 ? void 0 : _key$trim.toLowerCase();
  };

  var createHashMap = function createHashMap(arr, key) {
    if (arr === void 0) {
      arr = [];
    }

    return arr.reduce(function (acc, curr) {
      acc[normalizeMatchingKey(curr[key])] = curr;
      return acc;
    }, {});
  }; // eslint-disable-next-line no-unused-vars


 var onmessage = function onmessage(e) {
  if (!e) return;
  var _e$data = e.data,
    additionalState = _e$data.additionalState,
    fieldData = _e$data.fieldData,
    functionId = _e$data.functionId,
    data = _e$data.data;

  Object.keys(RemapVariableWorkerExecState).forEach(function(key) { RemapVariableWorkerExecState[key] = false; });
  RemapVariableWorkerExecState[functionId] = true;

  var hashMap = createHashMap(data === null || data === void 0 ? void 0 : data.dataFieldOptions, 'key');
  var hashMapKeys = Object.keys(hashMap);

  var _usedCount = 0;
  var _totalCount = data === null || data === void 0 ? void 0 : data.dataFieldOptions.reduce(function (acc, curr) {
          return acc + curr.docCount;
        }, 0);

  var filtredFieldData = {};

  fieldData.every(function (replace) {
    var _normalizeMatchingKey, _hashMap$normalizeMat;

    if(RemapVariableWorkerExecState[functionId] === false) {
      return false
    };

    if (replace._destroy) {
      filtredFieldData[replace.id] = Object.assign({}, replace, {
        _count: 0,
        _totalCount: _totalCount
      });
      return true;
    }

    if (((_normalizeMatchingKey = normalizeMatchingKey(replace.what)) === null || _normalizeMatchingKey === void 0 ? void 0 : _normalizeMatchingKey.length) === 0) {
      filtredFieldData[replace.id] = Object.assign({}, replace, {
        _count: 0,
        _totalCount: _totalCount
      });
      return true;
    }

    if ((additionalState === null || additionalState === void 0 ? void 0 : additionalState.remapVariableType) === 'remap_match') {
      var normalizedWhat = normalizeMatchingKey(replace.what);

      var _count = hashMapKeys.reduce(function (acc, key) {
        if(RemapVariableWorkerExecState[functionId] === false) {
          return null
        }

        var document = hashMap[key];

        if (document && key.indexOf(normalizedWhat) !== -1) {
          var docCount = document.docCount;
          delete hashMap[key];
          return acc + docCount;
        }

        return acc;
      }, 0);

      filtredFieldData[replace.id] = Object.assign({}, replace, {
        _count: _count,
        _totalCount: _totalCount
      });
      _usedCount= _usedCount + _count;
      return true;
    } else {
      var itemCount = ((_hashMap$normalizeMat = hashMap[normalizeMatchingKey(replace.what)]) === null || _hashMap$normalizeMat === void 0 ? void 0 : _hashMap$normalizeMat.docCount) || 0;
      filtredFieldData[replace.id] = Object.assign({}, replace, {
        _count: itemCount,
        _totalCount: _totalCount
      });
      _usedCount = _usedCount + itemCount;
      return true;
    }
  }); // eslint-disable-next-line no-undef

  if(RemapVariableWorkerExecState[functionId] === true) {
    postMessage({result: filtredFieldData, _totalCount: _totalCount, _unusedCount: _totalCount - _usedCount });
  } else {
    // console.log('Terminated', functionId);
  }

  delete RemapVariableWorkerExecState[functionId];
};
`;
export default WorkerCode;
