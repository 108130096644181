import React from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import Icon from './Icon';

const InfoBox = ({ type, withIcon, children, className, isComplex, htmlContent, ...rest }) => (
  <div
    data-test-id={`info-box-${type}`}
    className={cs('InfoBox', `InfoBox--${type}`, className, { 'InfoBox--complex': isComplex })}
    {...rest}
  >
    <div className={cs('InfoBox-content', { 'InfoBox-content--withIcon': withIcon })}>
      {/* eslint-disable-next-line react/no-danger */}
      {htmlContent && <div dangerouslySetInnerHTML={{ __html: htmlContent }} />}
      {children}
      {rest.onClose && (
        <Icon kind="close" inheritColor size="24px" style={{ cursor: 'pointer' }} onClick={rest.onClose} />
      )}
    </div>
  </div>
);

InfoBox.propTypes = {
  className: RPT.string,
  children: RPT.any,
  isComplex: RPT.bool,
  type: RPT.oneOf(['success', 'info', 'error', 'warning', 'neutral']).isRequired,
  withIcon: RPT.bool,
  onClose: RPT.func,
};

export default InfoBox;
