import React, { memo } from 'react';
import cs from 'classnames';
import { find } from 'lodash';
import styles from './styles';
import { Row, Col, Switch, Icon, Tooltip } from '../../../index';
import Badge from '../../../Badge';

const Columns = ({
  showIf = true,
  onEnable,
  onDisable,
  onChange,
  object,
  withoutLabelPadding = false,
  labelOnSeparateLine = false,
  className,
  delimiter = false,
  switchLabel,
  tooltip,
  badge,
  ...props
}) => {
  if (!showIf) {
    return <div style={styles.empty} />;
  }

  const isColumnOn = onEnable
    ? !!find(Object.keys(onEnable), property => object[property] && object[property].toString().length > 0)
    : true;

  const label = <div className={cs('Text', { 'mb-16': !withoutLabelPadding && isColumnOn })}>{props.label}</div>;

  const Component = delimiter ? 'div' : React.Fragment;

  const delimiterClass = delimiter && 'delimiter negative-ml-16 negative-mr-16 pb-16 ph-16 mb-16';

  const toggleEnabledDisabled = ({ target: { value } }) => {
    if (value) {
      onChange(null, null, onEnable);
    } else {
      onChange(null, null, onDisable || Object.keys(onEnable).reduce((out, key) => ({ ...out, [key]: '' }), {}));
    }
  };

  if (labelOnSeparateLine) {
    return (
      <Component key="columns_label_top" className={delimiterClass}>
        <Row
          className={cs(className, 'mb-0')}
          justifyBetween
          bottom={!withoutLabelPadding}
          center={withoutLabelPadding}
        >
          <Col grow>
            <Row>
              {label}
              {badge && (
                <Badge className="ml-8" size="medium" kind="orange">
                  {badge}
                </Badge>
              )}
              {tooltip && (
                <Tooltip text={tooltip} className="ml-4">
                  <Icon kind="info" size="12px" className="pa-4" />
                </Tooltip>
              )}
            </Row>
          </Col>
          {onEnable && onChange && (
            <Col className={cs({ 'mb-16': isColumnOn })} shrink>
              <Switch doNotUseInternalState value={isColumnOn} onChange={toggleEnabledDisabled} />
            </Col>
          )}
        </Row>
        <Row className="mt-0">{isColumnOn && props.children}</Row>
      </Component>
    );
  }

  return (
    <Row
      className={cs(className, delimiterClass)}
      justifyBetween
      bottom={!withoutLabelPadding}
      center={withoutLabelPadding}
    >
      <Col shrink>
        <Row>
          {label}
          {badge && (
            <Badge className="ml-8" size="medium" kind="orange">
              {badge}
            </Badge>
          )}
          {tooltip && (
            <Tooltip text={tooltip} className="ml-4">
              <Icon kind="info" size="12px" className="pa-4" />
            </Tooltip>
          )}
        </Row>
      </Col>
      {onEnable && onChange && (
        <Col shrink>
          <Switch value={isColumnOn} onChange={toggleEnabledDisabled} doNotUseInternalState />
        </Col>
      )}
      {isColumnOn && (
        <Col shrink>
          <Row center>{props.children}</Row>
        </Col>
      )}
    </Row>
  );
};

export default memo(Columns);
