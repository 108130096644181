import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SearchContext } from '../index';
import { Select } from '../../form';

export const Labels = ({ changeAdditionalTriggeredColumns }) => {
  const {
    searchState,
    searchChange,
    searchOptions: { labels },
  } = useContext(SearchContext);

  React.useEffect(() => {
    if (searchState.labels && searchState.labels !== 'all') changeAdditionalTriggeredColumns(['labels']);
    else changeAdditionalTriggeredColumns([]);
  }, [searchState.labels]);

  if ((labels || []).length === 0) {
    return null;
  }
  return (
    <Select
      value={searchState.labels || 'all'}
      id="labels-search"
      name="table_search[labels]"
      doNotUseInternalState
      medium
      noMargin
      onChange={({ target: { value } }) => searchChange('labels', value)}
      collection={labels}
    />
  );
};

Labels.propTypes = {
  changeAdditionalTriggeredColumns: PropTypes.func,
};

export default Labels;
