import React from 'react';
import PropTypes from 'prop-types';
import InfoBox from '../components/InfoBox';
import { t } from '../i18n';

// If you will change this change it in: lib/invoiced_price.rb
const EU = [
  'be',
  'bg',
  'cz',
  'dk',
  'de',
  'ee',
  'ie',
  'gr',
  'el',
  'es',
  'fr',
  'hr',
  'it',
  'cy',
  'lv',
  'lt',
  'lu',
  'hu',
  'mt',
  'nl',
  'at',
  'pl',
  'pt',
  'ro',
  'si',
  'sk',
  'fi',
  'se',
];

const CzechVatNumber = /^CZ\d{8,10}$/i;

const validateVat = (value, country) => {
  const v = value
    .toString()
    .trim()
    .toLowerCase();
  if (!country) {
    return null;
  }
  if (country === 'cz') {
    if (v.length === 0) {
      return null;
    }
    if (CzechVatNumber.test(v)) {
      return (
        <InfoBox type="success" withIcon data-test-id="vat-number-message-valid_czech">
          {t('react.vat_number.valid_czech')}
        </InfoBox>
      );
    }
    return (
      <InfoBox type="error" withIcon data-test-id="vat-number-message-invalid_czech">
        {t('react.vat_number.invalid_czech')}
      </InfoBox>
    );
  }

  if (EU.indexOf(country) !== -1) {
    if (v.length >= 10 && v.length <= 15) {
      return (
        <InfoBox type="success" withIcon data-test-id="vat-number-message-reverse_charge">
          {t('react.vat_number.reverse_charge')}
        </InfoBox>
      );
    }
    return (
      <InfoBox type="warning" withIcon data-test-id="vat-number-message-not_eu_vat_number">
        {t('react.vat_number.not_eu_vat_number')}
      </InfoBox>
    );
  }
  return (
    <InfoBox type="warning" withIcon data-test-id="vat-number-message-not_eu_vat_number">
      {t('react.vat_number.no_vat_number')}
    </InfoBox>
  );
};

class VatNumberInput extends React.Component {
  static propTypes = {
    value: PropTypes.string,
    name: PropTypes.string,
  };

  state = {
    lastValue: this.props.value,
    message: validateVat(this.props.value, 'cz'),
    country: 'cz',
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ message: validateVat(value, this.state.country), lastValue: value });
  };

  setRef = el => {
    if (el) {
      const countrySelect = el.closest('form').querySelector('select[name*="[country_code]"]');
      if (countrySelect) {
        this.setState({
          country: countrySelect.value.toLowerCase(),
          message: validateVat(this.state.lastValue, countrySelect.value.toLowerCase()),
        });
        countrySelect.addEventListener('change', ({ target: { value } }) =>
          this.setState({
            country: value.toLowerCase(),
            message: validateVat(this.state.lastValue, value.toLowerCase()),
          })
        );
      }
    }
  };

  render() {
    const { name, value } = this.props;
    const { message } = this.state;

    return (
      <div ref={this.setRef} className="Input string optional subscription_invoice_credentials_vat_number">
        <label htmlFor="subscription_invoice_credentials[vat_number]">{t('react.vat_number.dic')}</label>
        <input
          onChange={this.handleChange}
          defaultValue={value}
          className="string optional"
          type="text"
          name={name || 'subscription_invoice_credentials[vat_number]'}
          id="subscription_invoice_credentials_vat_number"
        />
        {message && <div className="mt-16">{message}</div>}
      </div>
    );
  }
}

export default VatNumberInput;
