export const innerText = node => ((node && node.textContent) || '').trim();

const FLOAT_NUMBER_SELECTOR = /[\d|,|.|]+/g;
export const getNumberFromString = str => {
  if (!str) return null;
  const matches = str.match(FLOAT_NUMBER_SELECTOR);
  return !matches || matches?.length === 0
    ? null
    : matches
        .filter(x => x !== '.')
        .filter(y => y !== ',')
        .join(' ');
};

export const isValidSelector = (doc, selector) => {
  try {
    doc.querySelectorAll(selector);
    return true;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Invalid selector', selector);
    return null;
  }
};

export const convertHexToRGBA = (hexCode, opacity = 1) => {
  let hex = hexCode.replace('#', '');

  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);
  const tempOpacity = opacity >= 0 && opacity <= 1 ? opacity : opacity / 100;

  return `rgba(${r},${g},${b},${tempOpacity})`;
};
