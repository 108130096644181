/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import PlaceholderInput from '../placeholders/Input';
import { Input, Row, Col, Select, Icon, Button, Tile, SegmentedButton } from '../components/index';
import updatePreview from './updatePreview';
import { t } from '../i18n';

class ScraperVariable extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    css: PropTypes.string,
    value_type: PropTypes.string,
    value_source: PropTypes.string,
    select_index: PropTypes.string,
    attribute_source: PropTypes.string,
    placeholder_name: PropTypes.string,
    _error_fields: PropTypes.arrayOf(PropTypes.string),
    _errors: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
    _error_fields: [],
  };

  state = {
    css: this.props.css || '',
    value_type: this.props.value_type || 'string',
    value_source: this.props.value_source || 'text',
    select_index: this.props.select_index || '',
    attribute_source: this.props.attribute_source || '',
    placeholder_name: this.props.placeholder_name || '',
  };

  getValueTypes = () => this.props.options.value_types || [];
  getValueSourceTypes = () => this.props.options.value_source_types || [];
  getSelectIndex = () => this.props.options.select_index_types || [];

  handleCss = ({ target: { value } }) => {
    if (value !== this.state.css) {
      this.setState({ css: value });
      this.props.updateFieldData(this.props.id, { css: value });
      updatePreview();
    }
  };
  handleValueType = ({ target: { value } }) => {
    if (value !== this.state.value_type) {
      this.setState({ value_type: value });
      this.props.updateFieldData(this.props.id, { value_type: value });
      updatePreview();
    }
  };

  handleSelectIndex = ({ target: { value } }) => {
    if (value !== this.state.select_index) {
      this.setState({ select_index: value });
      this.props.updateFieldData(this.props.id, { select_index: value });
      updatePreview();
    }
  };

  handleValueSource = ({ target: { value } }) => {
    if (value !== this.state.value_source) {
      this.setState({ value_source: value });
      this.props.updateFieldData(this.props.id, { value_source: value });
      updatePreview();
    }
  };

  handleAttributeSource = ({ target: { value } }) => {
    if (value !== this.state.attribute_source) {
      this.setState({ attribute_source: value });
      this.props.updateFieldData(this.props.id, { attribute_source: value });
      updatePreview();
    }
  };

  handlePlaceholderName = ({ target: { value } }) => {
    if (value !== this.state.placeholder_name) {
      this.setState({ placeholder_name: value });
      this.props.updateFieldData(this.props.id, { placeholder_name: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove, _error_fields } = this.props;
    const { select_index, value_type, css, attribute_source, value_source, placeholder_name } = this.state;
    const inputId = this.props.id;

    return (
      <Tile className="mb-16">
        <Row baseline data-test-id="nested-scraper-variable">
          <Col grow>
            <Row shrink justifyBetween center>
              <Col width="280px">
                <Input
                  doNotUseInternalState
                  value={placeholder_name}
                  error={_error_fields.indexOf('placeholder_name') !== -1}
                  onChange={this.handlePlaceholderName}
                  placeholder={t('views.scraper_variable.fields.placeholder_name', {
                    default: 'Name of variable to store in',
                  })}
                  {...getSubFiledOptions('placeholder_name')}
                />
              </Col>
              <Col shrink>
                {onRemove && (
                  <Col shrink>
                    <Button
                      tertiary
                      onlyIcon
                      disabled={disabled}
                      onClick={onRemove}
                      tabIndex="-1"
                      icon="trash"
                      data-test-id="nested-field-remove"
                    />
                  </Col>
                )}
              </Col>
            </Row>
            <Row shrink center>
              <Col shrink>{t('react.scraper_form.select', { default: 'Select' })}</Col>
              <Col shrink>
                <Select
                  value={value_source}
                  doNotUseInternalState
                  error={_error_fields.indexOf('value_source') !== -1}
                  onChange={this.handleValueSource}
                  className="mb-0"
                  {...getSubFiledOptions('value_source')}
                >
                  {this.getValueSourceTypes().map(option => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </Select>
              </Col>

              {value_source === 'custom_attribute' && (
                <Col data-test-id="scraper-variable-attribute-source" width="136px">
                  <Input
                    value={attribute_source}
                    doNotUseInternalState
                    error={_error_fields.indexOf('attribute_source') !== -1}
                    onChange={this.handleAttributeSource}
                    placeholder={t('views.scraper_variable.fields.custom_attribute', {
                      default: 'attribute name like data-title',
                    })}
                    className="mb-0"
                    {...getSubFiledOptions('attribute_source')}
                  />
                </Col>
              )}

              <Col shrink>{t('react.scraper_form.of', { default: 'Of' })}</Col>
              <Col grow>
                <Input
                  doNotUseInternalState
                  value={css}
                  error={_error_fields.indexOf('css') !== -1}
                  onChange={this.handleCss}
                  placeholder={t('views.scraper_variable.fields.css', {
                    default: 'CSS selector like h1.main-product-name',
                  })}
                  {...getSubFiledOptions('css')}
                />
              </Col>
            </Row>
            <Row shrink center>
              <Col shrink>{t('react.scraper_form.save', { default: 'Save' })}</Col>
              <Col shrink>
                <Select
                  value={select_index}
                  doNotUseInternalState
                  error={_error_fields.indexOf('select_index') !== -1}
                  onChange={this.handleSelectIndex}
                  className="mb-0"
                  {...getSubFiledOptions('select_index')}
                >
                  {this.getSelectIndex().map(option => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </Select>
              </Col>

              {select_index !== 'count' && (
                <React.Fragment>
                  <Col shrink>{t('react.scraper_form.of_found_objects_as', { default: 'of found objects as a' })}</Col>
                  <Col shrink>
                    <SegmentedButton
                      value={value_type}
                      doNotUseInternalState
                      error={_error_fields.indexOf('value_type') !== -1}
                      onChange={this.handleValueType}
                      className="mb-0"
                      id={id || uuid()}
                      {...getSubFiledOptions('value_type')}
                      collection={this.getValueTypes().map(({ value, key }) => ({ value, label: key }))}
                    />
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </Col>
        </Row>

        {this.props._errors && (
          <Row className="mb-4" shrink>
            <Col>
              <div className="ValidationError">{this.props._errors}</div>
            </Col>
          </Row>
        )}
      </Tile>
    );
  }
}

export default ScraperVariable;
