import React, { PureComponent } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import Ellipsis from './Ellipsis';
import ConditionalWrapper from './ConditionalWrapper';
import LoadingButtonWrapper from './LoadingButton';
import { t } from '../i18n';

class Button extends PureComponent {
  static propTypes = {
    action: RPT.bool,
    active: RPT.bool,
    block: RPT.bool,
    className: RPT.string,
    control: RPT.bool,
    disabled: RPT.bool,
    disableFixedWidth: RPT.bool,
    error: RPT.object,
    fixContentWidth: RPT.string,
    grayTable: RPT.bool,
    greenTable: RPT.bool,
    hasIconAfterText: RPT.bool,
    href: RPT.oneOfType([RPT.bool, RPT.string]),
    children: RPT.any,
    icon: RPT.string,
    kind: RPT.string,
    loading: RPT.bool,
    loadingType: RPT.oneOf(['classic', 'upload']),
    maxWidth: RPT.string,
    onClick: RPT.func,
    onlyIcon: RPT.bool,
    primary: RPT.bool,
    primaryTable: RPT.bool,
    red: RPT.bool,
    redTable: RPT.bool,
    secondary: RPT.bool,
    secondaryRed: RPT.bool,
    setRef: RPT.func,
    size: RPT.oneOf(['small', 'medium', 'large']),
    style: RPT.object,
    tag: RPT.string,
    tertiary: RPT.bool,
    tertiaryRed: RPT.bool,
    text: RPT.any,
    type: RPT.oneOf(['button', 'submit', 'reset']),
  };

  handleClick = e => {
    if (typeof this.props.onClick === 'function') {
      e.preventDefault();
      this.props.onClick(e);
      return false;
    }
    return true;
  };

  render() {
    const {
      action,
      active,
      block,
      className,
      control,
      disabled,
      disableFixedWidth,
      error,
      fixContentWidth,
      grayTable,
      greenTable,
      hasIconAfterText,
      href,
      children,
      icon,
      kind,
      loading = false,
      loadingType = null,
      maxWidth,
      onClick,
      onlyIcon,
      primary,
      primaryTable,
      red,
      redTable,
      secondary,
      secondaryRed,
      setRef,
      size = 'medium',
      style = {},
      tag,
      tertiary,
      tertiaryRed,
      text,
      type = 'button',
      ...rest
    } = this.props;

    const buttonKind = kind
      ? { [`Button--${kind}`]: kind }
      : {
          'Button--primary': primary,
          'Button--secondary': secondary || action || control,
          'Button--tertiaryRed': tertiaryRed,
          'Button--tertiary': tertiary,
          'Button--red': red,
          'Button--secondaryRed': secondaryRed,
          'Button--primaryTable': primaryTable,
          'Button--greenTable': greenTable,
          'Button--grayTable': grayTable,
          'Button--redTable': redTable,
        };

    const buttonSize = `Button--${size}`;

    const Component = tag || 'button';

    return (
      <Component
        onClick={this.handleClick}
        ref={setRef}
        disabled={disabled}
        className={cs('Button', buttonKind, buttonSize, className, {
          [`fc-${icon}`]: icon,
          'Button--onlyIcon': onlyIcon,
          'Button--block': block,
          'Button--iconPositionRight': hasIconAfterText,
          active,
        })}
        href={href}
        style={{ ...style, maxWidth }}
        {...(Component === 'button' ? { type } : {})}
        {...rest}
      >
        <ConditionalWrapper condition={maxWidth} wrapper={child => <Ellipsis>{child}</Ellipsis>}>
          <ConditionalWrapper
            condition={loading || loadingType}
            wrapper={child => (
              <LoadingButtonWrapper
                loadingType={loadingType}
                loading={loading}
                onlyIcon={onlyIcon}
                error={error}
                disableFixedWidth={disableFixedWidth}
                fixContentWidth={fixContentWidth}
              >
                {child}
              </LoadingButtonWrapper>
            )}
          >
            <React.Fragment>
              {children}
              {text}
            </React.Fragment>
          </ConditionalWrapper>
        </ConditionalWrapper>
      </Component>
    );
  }
}

export default Button;

export const MazeButton = ({ href, text }) => (
  <Button
    tag="a"
    href={href}
    icon="reply"
    secondary
    size="small"
    target="_blank"
    text={text || t('react.maze_button.leave_feedback')}
  />
);

MazeButton.propTypes = {
  href: RPT.string.required,
  text: RPT.string,
};
