import moment from 'moment';

export const defaultDateFormat = 'D.M.YYYY';

export const defaultOptions = {
  precision: 10,
  delimiter: ',', // used in 10,000,000.10
  separator: '.', // used in 10.10
  format: '%n %u', // %n is placeholder for number, %u is for unit
  unit: '',
  strip_insignificant_zeros: true,
  round_strategy: 'round',
};

export function mergeOptionsWithDefault(givenOptions) {
  return { ...defaultOptions, ...givenOptions };
}

const convertRailsDateFormatToMomentFormat = dateFormat =>
  dateFormat
    .replace('%H', 'HH')
    .replace('%k', 'H')
    .replace('%I', 'hh')
    .replace('%l', 'h')
    .replace('%P', 'a')
    .replace('%p', 'A')
    .replace('%M', 'mm')
    .replace('%S', 'ss')
    .replace('%a', 'ddd')
    .replace('%A', 'dddd')
    .replace('%d', 'DD')
    .replace('%m', 'MM')
    .replace('%b', 'MMM')
    .replace('%B', 'MMMM')
    .replace('%Y', 'YYYY')
    .replace('%z', 'z');

export function formatDate(givenDate, locale, format) {
  const date = moment(givenDate);
  date.locale(locale);
  return date.format(convertRailsDateFormatToMomentFormat(format || defaultDateFormat));
}

export function formatRoundedNumber(input, givenOptions = {}) {
  let precision = 0;
  if (input) {
    if (input < 10) {
      precision = 2;
    } else if (input < 100) {
      precision = 1;
    }
  }
  return formatNumber(input, { ...givenOptions, precision });
}

export function formatNumber(input, givenOptions = {}) {
  const options = mergeOptionsWithDefault(givenOptions);

  const roundedNumber = roundNumber(input, options);
  const formattedNumber = formatNumberSeparatorAndDelimiter(roundedNumber, options);

  if (formattedNumber === 'NaN') {
    return '';
  }

  return options.format
    .replace('%n', formattedNumber)
    .replace('%u', options.unit || '')
    .trim();
}

export function formatCurrency(input, givenOptions = {}) {
  return formatNumber(input, {
    strip_insignificant_zeros: false,
    ...givenOptions,
  });
}

export function formatNumberSeparatorAndDelimiter(input, givenOptions = {}) {
  const options = mergeOptionsWithDefault(givenOptions);
  const numbers = input
    .toFixed(givenOptions.precision)
    .toString()
    .split('.');

  numbers[0] = numbers[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.delimiter);

  if (numbers[1] && givenOptions.strip_insignificant_zeros) {
    numbers[1] = numbers[1].replace(/0+$/g, '');
    if (numbers[1] === '') {
      numbers.splice(1, 1);
    }
  }

  return numbers.join(options.separator);
}

export function roundNumber(input, givenOptions) {
  const options = mergeOptionsWithDefault(givenOptions);
  const decimal = 10 ** options.precision;

  switch (options.round_strategy) {
    case 'round': {
      return Math.round(input * decimal) / decimal;
    }
    case 'ceil': {
      return Math.ceil(input * decimal) / decimal;
    }
    case 'floor': {
      return Math.floor(input * decimal) / decimal;
    }
    default: {
      return Math.round(input * decimal) / decimal;
    }
  }
}
