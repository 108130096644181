import React, { useContext, useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { last } from 'lodash';
import cs from 'classnames';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Modal, ModalBody, ModalFooter, Row, Col, Text, EmptyState, SectionHeading } from '../../../../index';
import { t } from '../../../../../i18n';
import OptionsContext from '../../../OptionsContext';
import UploadNewImage from '../components/UploadNewImage';
import ImageTile from './ImageTile';
import withApiStateHandler from '../../../../withApiStateHandler';
import useSearch from '../../../../../hooks/useSearch';
import Searchbox from '../../../../../organizations_dropdown/Searchbox';
import Pagination from '../../../../Pagination';

const PAGE_LIMIT = 12;

const widthHeightProps = {
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
};

const IMAGES_QUERY = gql`
  query OrganizationImages($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      images {
        id
        filename
        url
        width
        height
      }
    }
  }
`;

const DELETE_IMAGE = gql`
  mutation DeleteImage($id: BigInt!) {
    deleteImage(id: $id) {
      image {
        id
        deleted
      }
    }
  }
`;

const MinDimensionsRow = ({ minWidth, minHeight }) =>
  minWidth && minHeight ? (
    <Row>
      <Text>
        {t('react.image_generator.modal.min_width_and_height')} {minWidth}px × {minHeight}px
      </Text>
    </Row>
  ) : null;

MinDimensionsRow.propTypes = widthHeightProps;

const SelectImageModalList = ({ refetch, onSelect, minWidth, minHeight, disableFormats = [], images }) => {
  const [deleteImage, { loading: isDeleteLoading }] = useMutation(DELETE_IMAGE, { onCompleted: () => refetch() });
  const [currentPage, setCurrentPage] = useState(0);
  const { search, setSearch, result } = useSearch({
    collection: images,
    ignoreKeys: ['id', 'height', 'url', 'width', '__typename'],
  });

  const paginatedImages = useMemo(() => result.slice(0 + PAGE_LIMIT * currentPage, PAGE_LIMIT * (currentPage + 1)), [
    currentPage,
    result,
  ]);

  useEffect(() => {
    setCurrentPage(0);
  }, [result]);

  return (
    <Row grow>
      <div className="w-100">
        <Row className="mb-8" padding="xl" shrink>
          <Col width="392px">
            <Searchbox
              searchBoxText={t('react.image_generator.modal.search')}
              searchValue={search}
              handleSearch={({ target: { value } }) => setSearch(value)}
            />
          </Col>
        </Row>

        <Row flexwrap className="Grid--fourthWithoutMargin mv-8">
          {result.length === 0 && (
            <EmptyState icon="image_editor_icon">
              <SectionHeading className="mt-16">
                {t('react.image_generator.modal.too_much_filtering', {
                  default: 'None of the images match your search query. Try to modify what you’re looking for.',
                })}
              </SectionHeading>
            </EmptyState>
          )}

          {paginatedImages.map(image => (
            <Col width="206px" key={image.id}>
              <ImageTile
                label={image.filename}
                imgUrl={image.url}
                onSelect={() =>
                  onSelect({
                    xlinkHref: image.url,
                    width: image.width || 400,
                    height: image.height || 400,
                    filename: image.filename,
                    id: image.id,
                  })
                }
                onDelete={() => {
                  deleteImage({
                    variables: {
                      id: image.id,
                      deleted: true,
                    },
                  });
                }}
                isDeleteLoading={isDeleteLoading}
                disabled={
                  (minWidth && image.width < minWidth) ||
                  (minHeight && image.height < minHeight) ||
                  disableFormats.indexOf(last(image.filename.split('.'))) !== -1
                }
                disabledMessage={
                  disableFormats.indexOf(last(image.filename.split('.'))) !== -1
                    ? t('react.image_generator.modal.wrong_image_format')
                    : t('react.image_generator.modal.image_too_small')
                }
              />
            </Col>
          ))}
        </Row>

        <Row>
          <Col grow>
            <Pagination
              className="mt-16"
              current={currentPage}
              disableAnchor
              changePage={page => () => setCurrentPage(page)}
              maxPages={Math.ceil(result?.length / PAGE_LIMIT)}
            />
          </Col>
        </Row>
      </div>
    </Row>
  );
};

const SelectImageModalContent = withApiStateHandler(({ refetch, images, ...rest }) => {
  if (images?.length === 0) {
    return (
      <EmptyState icon="image_editor_icon">
        <SectionHeading className="mt-16">{t('react.image_generator.modal.empty_state_feed_image')}</SectionHeading>
        <UploadNewImage
          updateImage={variable => {
            if (variable === 'xlinkHref') {
              refetch();
            }
          }}
        />
      </EmptyState>
    );
  }

  return <SelectImageModalList {...rest} refetch={refetch} images={images} />;
});

SelectImageModalContent.propTypes = {
  ...widthHeightProps,
  disableFormats: PropTypes.array,
  images: PropTypes.array,
  onSelect: PropTypes.func,
  refetch: PropTypes.func,
};

const SelectImageModal = ({ onClose, ...rest }) => {
  const { organizationId } = useContext(OptionsContext);
  const { loading, data, refetch, error } = useQuery(IMAGES_QUERY, {
    variables: { organizationId },
    fetchPolicy: 'network-only',
  });

  const images = data?.organization?.images;

  return (
    <Modal onClose={onClose} heading={t('react.image_generator.modal.select_images')}>
      <ModalBody classNames={cs({ 'overflow-visible': loading })}>
        <MinDimensionsRow {...rest} />
        <SelectImageModalContent {...rest} refetch={refetch} error={error} loading={loading} images={images} />
      </ModalBody>

      {images?.length > 0 && !loading && !error && (
        <ModalFooter>
          <UploadNewImage
            updateImage={variable => {
              if (variable === 'xlinkHref') {
                refetch();
              }
            }}
          />
        </ModalFooter>
      )}
    </Modal>
  );
};

SelectImageModal.propTypes = {
  ...widthHeightProps,
  disableFormats: PropTypes.array,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
};

export default hot(SelectImageModal);
