import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '../components';
import ItemGroupsHandler from './ItemGroupsHandler';

const ConditionItemGroups = ({ children, ...props }) => {
  const FallbackComponent = () => <Row>{children}</Row>;
  return <ItemGroupsHandler componentOptions={{ FallbackComponent, type: 'and' }} {...props} />;
};
ConditionItemGroups.propTypes = {
  children: PropTypes.any,
};

export default ConditionItemGroups;
