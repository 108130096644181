/* eslint-disable no-param-reassign,no-lonely-if */

// Set a value in an object or array, using multiple keys to set in a nested object or array.
// Examples:
//
// deepSet(obj, ["foo"], v)               // obj["foo"] = v
// deepSet(obj, ["foo", "inn"], v)        // obj["foo"]["inn"] = v // Create the inner obj["foo"] object, if needed
// deepSet(obj, ["foo", "inn", "123"], v) // obj["foo"]["arr"]["123"] = v //
//
// deepSet(obj, ["0"], v)                                   // obj["0"] = v
// deepSet(arr, ["0"], v, {useIntKeysAsArrayIndex: true})   // arr[0] = v
// deepSet(arr, [""], v)                                    // arr.push(v)
// deepSet(obj, ["arr", ""], v)                             // obj["arr"].push(v)
// arr = [];
// deepSet(arr, ["", v]          // arr => [v]
// deepSet(arr, ["", "foo"], v)  // arr => [v, {foo: v}]
// deepSet(arr, ["", "bar"], v)  // arr => [v, {foo: v, bar: v}]
// deepSet(arr, ["", "bar"], v)  // arr => [v, {foo: v, bar: v}, {bar: v}]

export default function deepSet(o, keys, value, opts) {
  if (opts == null) {
    opts = {};
  }

  if (o === undefined) {
    throw new Error("ArgumentError: param 'o' expected to be an object or array, found undefined");
  }
  if (!keys || keys.length === 0) {
    throw new Error("ArgumentError: param 'keys' expected to be an array with least one element");
  }

  let key = keys[0];

  // Only one key, then it's not a deepSet, just assign the value in the object or add it to the array.
  if (keys.length === 1) {
    if (key === '') {
      // push values into an array (o must be an array)
      o.push(value);
    } else {
      o[key] = value; // keys can be object keys (strings) or array indexes (numbers)
    }
    return;
  }

  const nextKey = keys[1]; // nested key
  const tailKeys = keys.slice(1); // list of all other nested keys (nextKey is first)

  if (key === '') {
    // push nested objects into an array (o must be an array)
    const lastIdx = o.length - 1;
    const lastVal = o[lastIdx];

    // if the last value is an object or array, and the new key is not set yet
    if (lastVal === Object(lastVal) && deepGet(lastVal, tailKeys) === undefined) {
      key = lastIdx; // then set the new value as a new attribute of the same object
    } else {
      key = lastIdx + 1; // otherwise, add a new element in the array
    }
  }

  if (nextKey === '') {
    // "" is used to push values into the nested array "array[]"
    if (o[key] === undefined || !Array.isArray(o[key])) {
      o[key] = []; // define (or override) as array to push values
    }
  } else {
    if (opts.useIntKeysAsArrayIndex && isValidArrayIndex(nextKey)) {
      // if 1, 2, 3 ... then use an array, where nextKey is the index
      if (o[key] === undefined || !Array.isArray(o[key])) {
        o[key] = []; // define (or override) as array, to insert values using int keys as array indexes
      }
    } else {
      // nextKey is going to be the nested object's attribute
      if (o[key] === undefined || o[key] !== Object(o[key])) {
        o[key] = {}; // define (or override) as object, to set nested properties
      }
    }
  }

  // Recursively set the inner object
  deepSet(o[key], tailKeys, value, opts);
}

function isValidArrayIndex(val) {
  return /^[0-9]+$/.test(String(val));
}

function deepGet(o, keys) {
  if (o === undefined || keys === undefined || keys.length === 0 || (o !== Object(o) && !Array.isArray(o))) {
    return o;
  }
  const key = keys[0];
  if (key === '') {
    // "" means next array index (used by deepSet)
    return undefined;
  }
  if (keys.length === 1) {
    return o[key];
  }
  const tailKeys = keys.slice(1);
  return deepGet(o[key], tailKeys);
}
