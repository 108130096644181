import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { Chip, Row, Icon } from '../index';

const handleChipsSelection = arr => value => {
  const index = arr.indexOf(value);
  const newArray = [...arr];
  // eslint-disable-next-line no-unused-expressions
  index === -1 ? newArray.push(value) : newArray.splice(index, 1);
  return newArray;
};

const ChipsGroup = ({ collection, testId, ...rest }) => (
  <Row noPadding flexwrap data-test-id={testId}>
    {collection.map(({ id, name }) => (
      <CampaignChip testId={testId} id={id} name={name} key={[testId, name].join('-')} {...rest} />
    ))}
  </Row>
);

export const CampaignChip = ({
  disableSelection,
  selectedCollection,
  mutation,
  onChange,
  loading,
  testId,
  id,
  name,
  icon,
}) => {
  const [changePill] = useMutation(mutation);

  if (disableSelection) {
    return (
      <div className="mr-4 mb-4">
        <Icon kind={icon} inheritColor size="14px" className="mr-4" />
        {name}
      </div>
    );
  }

  return (
    <div>
      <Chip
        className="mr-4 mb-4"
        onChange={() => {
          const newArray = handleChipsSelection(selectedCollection)(id);
          changePill({
            variables: { newArray, matchAll: false },
          }).then(() => {
            if (typeof onChange === 'function') {
              onChange();
            }
          });
        }}
        id={id}
        value={selectedCollection.indexOf(id) !== -1}
        data-test-id={[testId, name].join('-')}
        label={name}
        disabled={loading}
        doNotUseInternalState
        icon={icon}
      />
    </div>
  );
};

ChipsGroup.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  mutation: PropTypes.any,
  onChange: PropTypes.func,
  selectedCollection: PropTypes.array,
  testId: PropTypes.string,
};

CampaignChip.propTypes = {
  disableSelection: PropTypes.bool,
  icon: PropTypes.string,
  loading: PropTypes.bool,
  mutation: PropTypes.any,
  selectedCollection: PropTypes.array,
  testId: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.number,
};

export default ChipsGroup;
