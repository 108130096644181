import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-date-picker/dist/entry.nostyle';
import moment from 'moment';
import cs from 'classnames';
import { Icon, Text, cssVariables } from '../index';

const ClearIcon = () => <Icon inheritColor kind="close-outline-squared" size="16px" />;

class DateInput extends Component {
  static propTypes = {
    doNotUseInternalState: PropTypes.bool,
    horizontaly: PropTypes.bool,
    input: PropTypes.any,
    label: PropTypes.string,
    maxDate: PropTypes.string,
    minDate: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    readOnly: PropTypes.bool,
    styles: PropTypes.string,
    testId: PropTypes.string,
    value: PropTypes.any,
    last: PropTypes.bool,
    labelOnTop: PropTypes.bool,
    optional: PropTypes.bool,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    doNotUseInternalState: false,
  };

  state = {
    value: this.props.value && this.props.value.length > 0 ? moment(this.props.value) : null,
  };

  componentDidMount() {
    const { input } = this.props;
    if (input) {
      input.clerInputValue = () => this.handleChange(null);
    }
  }

  handleChange = date => {
    const value = date ? moment(date) : null;
    this.setState({ value });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({
        target: { value: value ? moment(value).format('YYYY-MM-DD') : '' },
      });
    }
  };

  customDayFormat = (locale, date) => {
    const options = { weekday: 'short' };
    return new Intl.DateTimeFormat(locale, options).format(date).slice(0, 2);
  };

  customMonthFormat = (locale, date) => {
    const options = { month: 'short' };
    return new Intl.DateTimeFormat(locale, options).format(date).slice(0, 3);
  };

  render() {
    const {
      doNotUseInternalState,
      readOnly,
      testId,
      input,
      name,
      minDate,
      maxDate,
      styles,
      label,
      horizontaly,
      last,
      labelOnTop,
      optional,
      disabled,
    } = this.props;

    const value = doNotUseInternalState
      ? this.props.value && this.props.value.length > 5 && moment(this.props.value)
      : this.state.value;

    return (
      <div
        className={cs('Input', 'Input--autoWidth', styles, { 'Input--horizontaly': horizontaly })}
        data-test-id={testId}
        data-test-date-input={name || (input && input.getAttribute('name'))}
      >
        {label && (
          <div className={cs('Input-label', { 'Input-label--top': labelOnTop })}>
            {label} {optional && <Text style={{ color: cssVariables.textSubtle }}> (optional)</Text>}
          </div>
        )}
        <DatePicker
          locale={window.I18nLocale === 'en' ? 'en-GB' : 'cs'}
          onChange={this.handleChange}
          value={value ? value.toDate() : null}
          readOnly={readOnly}
          className={cs('ReactDateInput', { 'mr-0': last })}
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder={window.I18nLocale === 'en' ? 'yyyy' : 'rrrr'}
          calendarIcon={null}
          clearIcon={value ? <ClearIcon /> : null}
          minDate={minDate}
          prev2Label={<Icon kind="chevron-first" inheritColor />}
          prevLabel={<Icon kind="chevron-left" inheritColor />}
          next2Label={<Icon kind="chevron-last" inheritColor />}
          nextLabel={<Icon kind="chevron-right" inheritColor />}
          maxDate={
            maxDate ||
            moment()
              .add(50, 'y')
              .toDate()
          }
          formatShortWeekday={this.customDayFormat}
          formatMonth={this.customMonthFormat}
          disabled={disabled}
        />
      </div>
    );
  }
}

export default DateInput;
