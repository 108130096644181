/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import uuid from 'uuid';
import { groupBy, flatten } from 'lodash';
import cs from 'classnames';
import { listenOnModalChange } from '../RemoteModal';
import { QueryHolder, Icon, Row, Col, Text, Ellipsis, Badge, cssVariables } from '../index';
import { t } from '../../i18n';

const icons = {
  FbBudgets: 'budget',
  FbAudiences: 'users-group',
  FbPlacements: 'placement',
  FbOptimizations: 'option',
  FbAdtexts: 'adtext',
};

const state = {
  active: {
    icon: 'active',
    color: '#3ED95E',
    text: t('active', { scope: 'react.fb_manual_campaign_preview.state' }),
  },
  inactive: {
    icon: 'fill-paused',
    color: '#8C969F',
    text: t('inactive', { scope: 'react.fb_manual_campaign_preview.state' }),
  },
  queue: {
    icon: 'arrange-back',
    color: '#8C969F',
    text: t('queue', { scope: 'react.fb_manual_campaign_preview.state' }),
  },
  draft: {
    icon: 'fill-draft',
    color: '#E68C17',
    text: t('main_states.adtext.draft.text'),
    tooltip: t('main_states.adtext.draft.tooltip'),
  },
};

const ItemState = ({ active, running, isDraft }) => {
  let type = 'inactive';
  if (isDraft) {
    type = 'draft';
  } else if (active && running) {
    type = 'active';
  } else if (!active && running) {
    type = 'queue';
  }

  const { icon, color, text } = state[type];
  return (
    <Row center justifyEnd>
      <Col shrink>
        <Icon size="14px" kind={icon} color={color} />
      </Col>
      <Col shrink>
        <Text nowrap size="xs" bold spaced uppercase style={{ color }} data-test-id="ad-status">
          {text}
        </Text>
      </Col>
    </Row>
  );
};

ItemState.propTypes = {
  active: PropTypes.bool,
  isDraft: PropTypes.bool,
  running: PropTypes.bool,
};

const ResourcesList = ({ twoDimensionalCollection, collection, activeHash }) =>
  twoDimensionalCollection.map(arrayOfItems => (
    <div className={cs('Preview', { 'Preview--airy': twoDimensionalCollection.length > 0 })} key={uuid()}>
      {arrayOfItems.map(item => (
        <div
          key={item.id}
          className={cs('Preview-item', 'ph-8', 'ml-32 mt-4', {
            'Preview-item--grape Preview-item--radius': activeHash[item.id],
            'pv-4': collection === 'FbAdtexts' && item.group !== '-1',
          })}
          data-test-id="ad-line"
        >
          <Row center>
            <Col shrink>
              <Icon kind={icons[collection]} size="20px" color={cssVariables.grapePrimary} />
            </Col>
            <Col grow noPadding>
              <Row shrink>
                <Ellipsis>
                  <Text bold color="grape" data-test-id="ad-name">
                    {item.name}
                  </Text>
                </Ellipsis>
              </Row>
              {collection === 'FbAdtexts' && item.group !== '-1' && (
                <Row shrink>
                  <Ellipsis>
                    <Text semibold color="gray" size="sm" data-test-id="ad-branch">
                      {item.group}
                    </Text>
                  </Ellipsis>
                </Row>
              )}
            </Col>
            {item.timeRestriction?.text && (
              <Col shrink>
                <Badge kind="lightGray" size="medium" className="text-center">
                  {/* eslint-disable-next-line react/no-danger */}
                  <span dangerouslySetInnerHTML={{ __html: item.timeRestriction.text }} />
                </Badge>
              </Col>
            )}
            <Col width="120px" className="mr-8">
              <ItemState active={activeHash[item.id]} running={item.status === 'running'} isDraft={item.isDraft} />
            </Col>
          </Row>
        </div>
      ))}
    </div>
  ));

ResourcesList.propTypes = {
  twoDimensionalCollection: PropTypes.array,
  collection: PropTypes.array,
  activeHash: PropTypes.object,
};

const NameActiveFeature = ({ collection, twoDimensionalCollection, activeHash }) => {
  if (twoDimensionalCollection.length > 1 && collection === 'FbAdtexts') {
    const countActiveAds = flatten(twoDimensionalCollection).reduce(
      // eslint-disable-next-line no-return-assign, no-param-reassign
      (acc, cur) => (cur.status === 'running' && activeHash[cur.id] ? (acc += 1) : acc),
      0
    );
    return countActiveAds > 0 ? (
      <Text bold color="gray">
        {t('add_count', { scope: 'react.fb_manual_campaign_preview', count: countActiveAds })}
      </Text>
    ) : null;
  }

  return twoDimensionalCollection.map(arrayOfItems =>
    arrayOfItems.map(
      item =>
        item.status === 'running' &&
        activeHash[item.id] && (
          <Ellipsis key={item.id}>
            <Text bold color="gray">
              {item.name}
            </Text>
          </Ellipsis>
        )
    )
  );
};

const CampaignPreview = ({ data, campaign, collection }) => {
  if (collection === 'FbBudgets' && data.organization.campaignSetting.fbSetting.budgetLevel === 'campaign_budget') {
    const activeHash = campaign.activeFbBudgets.reduce((out, { id }) => ({ ...out, [id]: true }), {});
    const assignedCollection = campaign.assignedFbBudgets || [];
    const twoDimensionalCollection = [assignedCollection];

    return (
      <ResourcesList
        twoDimensionalCollection={twoDimensionalCollection}
        collection={collection}
        activeHash={activeHash}
      />
    );
  }

  return campaign.fbProductGroups.map(fbProductGroup => {
    const activeHash = fbProductGroup[`active${collection}`].reduce((out, { id }) => ({ ...out, [id]: true }), {});
    const assignedCollection = fbProductGroup[`assigned${collection}`] || [];
    const twoDimensionalCollection =
      collection === 'FbAdtexts'
        ? Object.values(groupBy(assignedCollection, ({ group }) => group))
        : [assignedCollection];
    const [isOpen, setOpen] = useState(false);
    const isActiveFeature = Object.keys(activeHash).length > 0;
    const inactiveFeatures = assignedCollection.length - Object.keys(activeHash).length;

    return (
      <div key={fbProductGroup.id} data-test-id="adset-line">
        <div
          className={cs('Preview-item', { 'Preview-item--hidden': !isOpen, 'cursor-pointer': isActiveFeature })}
          onClick={() => setOpen(!isOpen)}
        >
          <Row center padding="l">
            <Col width="18px">
              {(isActiveFeature || inactiveFeatures > 0) && (
                <Icon kind="chevron-down" size="14px" color="#596774" className="Preview-chevron" />
              )}
            </Col>
            <Col shrink>
              <Icon kind="adset" size="20px" color={isActiveFeature ? '#596774' : '#FF493F'} />
            </Col>
            <Col grow>
              <Ellipsis>
                <Text bold color={isActiveFeature ? 'gray' : 'error'} data-test-id="name">
                  {fbProductGroup.name}
                </Text>
              </Ellipsis>
            </Col>
            <Col shrink className="mr-8" data-test-id="content">
              <Row center>
                <NameActiveFeature
                  collection={collection}
                  twoDimensionalCollection={twoDimensionalCollection}
                  activeHash={activeHash}
                />

                {isActiveFeature && inactiveFeatures > 0 && (
                  <Text color="gray" className="ml-4">
                    {t('inactive_features', { scope: 'react.fb_manual_campaign_preview', features: inactiveFeatures })}
                  </Text>
                )}

                {!isActiveFeature && (
                  <Text bold color="error">
                    {inactiveFeatures > 0 &&
                      t(`no_active_${collection.toLowerCase()}`, { scope: 'react.fb_manual_campaign_preview' })}
                    {inactiveFeatures === 0 &&
                      t(`missing_${collection.toLowerCase()}`, { scope: 'react.fb_manual_campaign_preview' })}
                  </Text>
                )}
              </Row>
            </Col>
          </Row>
        </div>
        <ResourcesList
          twoDimensionalCollection={twoDimensionalCollection}
          collection={collection}
          activeHash={activeHash}
        />
      </div>
    );
  });
};

CampaignPreview.propTypes = {
  data: PropTypes.object,
  campaign: PropTypes.object,
  collection: PropTypes.string,
};

const CampaignLine = ({ campaign, data, collection }) => {
  const hasCampaignBudget =
    collection === 'FbBudgets' && data.organization.campaignSetting.fbSetting.budgetLevel === 'campaign_budget';
  const activeCampaignBudget =
    hasCampaignBudget &&
    Object.keys(campaign.activeFbBudgets.reduce((out, { id }) => ({ ...out, [id]: true }), {})).length;
  const inactiveCampaignBudget = hasCampaignBudget && (campaign.assignedFbBudgets || []).length - activeCampaignBudget;

  return (
    <div className="Preview-item">
      <Row center>
        <Col shrink>
          <Icon kind="fb-campaign" size="20px" color={activeCampaignBudget === 0 ? '#FF493F' : '#596774'} />
        </Col>
        <Col grow>
          <Text bold color={activeCampaignBudget === 0 ? 'error' : 'gray'} data-test-id="campaign_name">
            {campaign.name}
          </Text>
        </Col>
        {activeCampaignBudget === 0 && (
          <Col shrink>
            <Text bold color="error" data-test-id="content">
              {inactiveCampaignBudget > 0 && t('no_active_fbbudgets', { scope: 'react.fb_manual_campaign_preview' })}
              {inactiveCampaignBudget === 0 && t('missing_fbbudgets', { scope: 'react.fb_manual_campaign_preview' })}
            </Text>
          </Col>
        )}
      </Row>
    </div>
  );
};

CampaignLine.propTypes = {
  data: PropTypes.object,
  campaign: PropTypes.object,
  collection: PropTypes.string,
};

const PreviewList = ({ collection, data, refetch }) => {
  useEffect(
    listenOnModalChange(() => refetch()),
    []
  );
  return (
    <div className="Preview Preview--smallSpaced" data-test-id="preview-list">
      {data?.collection?.fbProductCampaigns?.map(campaign => (
        <div key={campaign.id} data-test-id="campaign-line">
          <CampaignLine campaign={campaign} data={data} collection={collection} />
          <div className="Preview">
            <CampaignPreview data={data} campaign={campaign} collection={collection} />
          </div>
        </div>
      ))}
    </div>
  );
};
PreviewList.propTypes = {
  data: PropTypes.object,
  refetch: PropTypes.func,
  collection: PropTypes.string,
};

const QueryPreviewList = QueryHolder(PreviewList);

export const FBManualPreview = ({ organizationId, campaignSettingId, collection }) => {
  const fbBudgetQuery = `
    assignedFbBudgets {
      id
      name
      status
    }
    activeFbBudgets {
      id
    }
  `;
  const query = gql`
    query FbManualPreview${collection}($organizationId: BigInt!, $campaignSettingId: BigInt!) {
      organization(id: $organizationId) {
        id
        campaignSetting(id: $campaignSettingId) {
          id
          fbSetting {
            id
            budgetLevel
          }
        }
      }
      collection(identifier: "FBManualPreview${collection}", limit: 100000, page: 0, organizationId: $organizationId, campaignSettingId: $campaignSettingId) {
        id
        fbProductCampaigns {
          id
          name
          ${collection === 'FbBudgets' ? fbBudgetQuery : ''}

          fbProductGroups {
            id
            name
            assigned${collection} {
              id
              name
              status
              ${collection === 'FbAdtexts' ? 'group isDraft' : ''}
              timeRestriction {
                text
              }
            }
            active${collection} {
              id
            }
          }
        }
      }
    }
  `;

  return (
    <QueryPreviewList
      fetchPolicy="no-cache"
      collection={collection}
      query={query}
      variables={{ organizationId, campaignSettingId }}
    />
  );
};

FBManualPreview.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  collection: PropTypes.string.isRequired,
};

export default FBManualPreview;
