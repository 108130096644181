import React from 'react';
import { Row, Col } from './Grid';

const GridSandbox = () => (
  <div>
    <h1>React</h1>
    <div className="Heading Heading--xl">Row system 50/50</div>
    <Row>
      <Col>
        <div className="Grid-example">foo</div>
      </Col>
      <Col>
        <div className="Grid-example">bar</div>
      </Col>
    </Row>

    <hr />
    <div className="Heading Heading--xl">Row system 1/5</div>
    <Row>
      <Col>
        <div className="Grid-example">foo</div>
      </Col>
      <Col>
        <div className="Grid-example">bar</div>
      </Col>
      <Col>
        <div className="Grid-example">bar</div>
      </Col>
      <Col>
        <div className="Grid-example">bar</div>
      </Col>
      <Col>
        <div className="Grid-example">bar</div>
      </Col>
    </Row>

    <hr />
    <div className="Heading Heading--xl">Row system size 1/4 - max - min</div>
    <Row>
      <Col width="25%">
        <div className="Grid-example">25%</div>
      </Col>
      <Col grow>
        <div className="Grid-example">MAX</div>
      </Col>
      <Col shrink>
        <div className="Grid-example">MIN</div>
      </Col>
    </Row>

    <hr />
    <div className="Heading Heading--xl">Row system size 1/4 - padding bigger</div>
    <Row padding="l">
      <Col width="25%">
        <div className="Grid-example">25%</div>
      </Col>
      <Col grow>
        <div className="Grid-example">MAX</div>
      </Col>
      <Col shrink>
        <div className="Grid-example">MIN</div>
      </Col>
    </Row>

    <hr />
    <div className="Heading Heading--xl">Row Cell columns</div>
    <div style={{ height: '200px' }}>
      <Row padding="l" height="100%">
        <Col width="25%">
          <Row shrink>
            <div className="Grid-example">shrink</div>
          </Row>
          <Row grow>
            <div className="Grid-example">grow</div>
          </Row>
          <Row shrink>
            <div className="Grid-example">shrink</div>
          </Row>
        </Col>
        <Col grow padding="s">
          <Row grow>
            <div className="Grid-example">grow</div>
          </Row>
          <Row grow>
            <div className="Grid-example">grow</div>
          </Row>
          <Row shrink>
            <div className="Grid-example">shrink</div>
          </Row>
        </Col>
        <Col shrink>
          <Row grow>
            <div className="Grid-example">MIN</div>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
);

export default GridSandbox;
