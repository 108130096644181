import moment from 'moment';

import './ExampleI18n';
import { Translator } from './translator';
import getInflectedNumber from './inflection';

window.I18n = window.I18n || {};
window.I18nLocale = window.I18nLocale || 'en';

moment.locale('en-short', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a sec',
    m: 'a min',
    mm: '%d min',
    h: '1 h',
    hh: '%d h',
    d: 'a day',
    dd: '%d d',
    M: 'a mo',
    MM: '%d mo',
    y: 'a yr',
    yy: '%d yr',
  },
});

moment.locale('cs-short', {
  relativeTime: {
    future: 'za %s',
    past: 'před %s',
    s: 'pár s',
    m: '1 m',
    mm: '%d m',
    h: '1 h',
    hh: '%d h',
    d: '1 d',
    dd: '%d d',
    M: '1 m',
    MM: '%d m',
    y: '1 r',
    yy: '%d r',
  },
});

const translator = new Translator({
  messages: window.I18n,
  locale: window.I18nLocale,
});

window.translator = translator;

export const locale = window.I18nLocale;
export const t = translator.t;
export const tHtml = translator.tHtml;
export const formatDate = translator.formatDate;
export const formatTime = translator.formatTime;
export const formatNumber = translator.formatNumber;
export const formatRoundedNumber = translator.formatRoundedNumber;
export const formatInteger = translator.formatInteger;
export const formatCurrency = translator.formatCurrency;
export const formatPercentage = translator.formatPercentage;
export const formatNumberInflection = getInflectedNumber;

window.t = translator.t;
window.tHtml = translator.tHtml;
window.formatDate = translator.formatDate;
window.formatTime = translator.formatTime;
window.formatNumber = translator.formatNumber;
window.formatRoundedNumber = translator.formatRoundedNumber;
window.formatInteger = translator.formatInteger;
window.formatCurrency = translator.formatCurrency;
window.formatPercentage = translator.formatPercentage;

export default translator;
