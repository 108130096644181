import React, { useContext } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import FieldKindIcon from '../FieldKindIcon';
import PreviewDataModal from '../../placeholders/PreviewDataModal';
import { Icon, Button, Link, Row, Col } from '../index';
import ProductsViewContext from './ProductsViewContext';
import Ellipsis from '../Ellipsis';
import Tooltip from '../Tooltip';
import { t } from '../../i18n';

function TableHeaderItem({ disabledSort, showFieldKind, name, previewLink, elasticPlaceholderName, className }) {
  const { order, setOrder } = useContext(ProductsViewContext);

  const ascOrder = `${elasticPlaceholderName}`;
  const descOrder = `${elasticPlaceholderName} desc`;
  const active = order === ascOrder || order === descOrder;

  return (
    <th className={cs('js-text-overflow', className)}>
      <Row center noPadding>
        <Col shrink style={{ maxWidth: previewLink ? 'calc(100% - 32px)' : '100%' }}>
          <Tooltip
            text={
              !disabledSort &&
              (!active || order === ascOrder
                ? t('order.asc', { default: 'Order asc' })
                : t('order.desc', { default: 'Order desc' }))
            }
          >
            <Link
              onClick={() => {
                if (!disabledSort) {
                  setOrder(order === ascOrder ? descOrder : ascOrder);
                }
              }}
            >
              <Row center>
                <Col shrink>
                  <FieldKindIcon showFieldKind={showFieldKind} size="20px" />
                </Col>
                <Col grow>
                  <Ellipsis>{name}</Ellipsis>
                </Col>
                {!disabledSort && (
                  <Col shrink>
                    <Icon
                      kind={`arrow-${!active || order === ascOrder ? 'up' : 'down'}`}
                      className={cs('Sticky-sortArrow', { active })}
                      size="16px"
                      inheritColor
                    />
                  </Col>
                )}
              </Row>
            </Link>
          </Tooltip>
        </Col>

        {previewLink && (
          <Col shrink>
            <PreviewDataModal previewLink={previewLink} name={name}>
              <Button tertiary onlyIcon icon="magnifier" />
            </PreviewDataModal>
          </Col>
        )}
      </Row>
    </th>
  );
}
TableHeaderItem.propTypes = {
  className: PropTypes.string,
  showFieldKind: PropTypes.string,
  name: PropTypes.string,
  previewLink: PropTypes.string,
  elasticPlaceholderName: PropTypes.string,
  disabledSort: PropTypes.bool,
};

export default TableHeaderItem;
