/* eslint-disable react/prop-types */
import React from 'react';
import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import {
  MainIdentifierCell,
  TableButtonPillCell,
  TimeAgoCell,
  LinkCell,
  HtmlRightCell,
  SystemStateCell,
  StateCell,
} from './cells';
import { LinkEdit } from './editable';
import { adwordsMapping } from './campaignSettings/adwordsSetting';
import { sklikMapping } from './campaignSettings/sklikSetting';
import { bingMapping } from './campaignSettings/bingSetting';
import { facebookMapping } from './campaignSettings/facebookSetting';
import conditions from './conditions';
import labels from './labels';
import imageEditor from './imageEditor';
import { Tooltip, Icon } from '../../index';
import generateStatsColumns from './stats';
import { t } from '../../../i18n';
import { modalDataUrlOpener } from '../../RemoteModal';
import resolveSystemObject from './campaignSettings/utils/resolveSystemObject';

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: [
      'icon',
      'name',
      'showLink',
      'editLink',
      'duplicateLink',
      'duplicateToOrganizationLink',
      'deleteLink',
      'historyLink',
      'generateLink',
      'synced',
    ],
    required: true,
    cell: MainIdentifierCell,
    order: 'name',
    editable: LinkEdit,
    editButtonAsProp: true,
    editQuery: ({ id }) => ({ text }) =>
      gql`mutation { updateCampaignSetting(id: ${id}, name: "${text}") { campaignSetting { id name updatedAt } errors } }`,
    width: '390px',
    header: () => t('activerecord.attributes.campaign_setting.name'),
    resolveChildren: ({
      id,
      icon,
      name,
      showLink,
      editLink,
      duplicateLink,
      duplicateToOrganizationLink,
      deleteLink,
      historyLink,
      generateLink,
      synced,
    }) => ({
      id,
      icon,
      size: '20px',
      colored: true,
      tooltip: t(`views.campaign_settings.index.${icon.replace(/-/g, '_')}`),
      name,
      text: name,
      link: showLink,
      'data-test-id': `dropdown-action-${name}`,
      links: [
        {
          href: editLink,
          'data-test-id': 'edit-bid-rule-button',
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        {
          href: generateLink,
          children: synced
            ? t('views.campaign_settings.index.synchronize')
            : t('views.campaign_settings.index.generate'),
          'data-test-id-generate': name,
          icon: 'syncing',
        },
        {
          href: historyLink,
          children: t('views.campaign_settings.index.logs'),
          'data-test-id-history': name,
          icon: 'history',
        },
        {
          href: duplicateLink,
          size: 'fullwithGrid',
          children: t('views.campaign_settings.index.duplicate'),
          'data-test-id-duplicate': name,
          icon: 'duplicate',
        },
        {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
        {
          href: duplicateToOrganizationLink,
          ...modalDataUrlOpener({
            url: duplicateToOrganizationLink,
            size: 'medium',
            heading: `Duplicate "${name}" to organization:`,
          }),
          danger: true,
          children: t('views.campaign_settings.index.duplicate_to_organization', {
            default: 'Duplicate to other organization',
          }),
          'data-test-id-duplicate-to-organization': name,
          icon: 'duplicate-to',
        },
      ],
    }),
    fixed: 'left',
  }),

  ...setupColumn({
    id: 'campaignSettingState',
    graphqlFields: ['campaignSettingState', 'syncError', 'lastSyncAt'],
    pollingInterval: {
      interval: 5000,
      shouldSync: ({ campaignSettingState }) => campaignSettingState === 'sync_in_progress',
    },
    order: 'campaign_setting_state',
    cell: StateCell,
    header: () => t('views.feed_export.status'),
    resolveChildren: ({ campaignSettingState, syncError, lastSyncAt }) => ({
      state: campaignSettingState,
      error: syncError,
      lastUpdatedAt: lastSyncAt,
      kind: 'campaign_setting',
    }),
  }),
  ...setupColumn({
    id: 'syncElementErrorsCount',
    graphqlFields: ['cacheSyncElementErrorsCount', 'syncErrorsLink'],
    order: 'cache_sync_element_errors_count',
    orderedByDescFirst: true,
    cell: TableButtonPillCell,
    header: () => (
      <Tooltip text={t('views.campaign_settings.table_head.sync_errors')} className="line-height-1">
        <Icon kind="sync-error" size="16px" inheritColor />
      </Tooltip>
    ),
    resolveChildren: ({ cacheSyncElementErrorsCount, syncErrorsLink }) => ({
      link: syncErrorsLink,
      count: cacheSyncElementErrorsCount,
      hasError: true,
      text: t('views.campaign_settings.index.sync_errors_tooltip'),
    }),
  }),
  ...imageEditor('campaigns'),
  ...setupColumn({
    id: 'decodedCampaignName',
    graphqlFields: ['decodedCampaignName', 'hierarchyLink'],
    order: 'encoded_campaign_name',
    cell: LinkCell,
    category: 'structure',
    header: () => t('activerecord.attributes.campaign_setting.campaign_name'),
    resolveChildren: ({ decodedCampaignName, hierarchyLink }) => ({
      text: decodedCampaignName,
      link: hierarchyLink,
    }),
  }),
  ...setupColumn({
    id: 'decodedAdgroupName',
    graphqlFields: ['decodedAdgroupName', 'hierarchyLink'],
    order: 'encoded_adgroup_name',
    cell: LinkCell,
    category: 'structure',
    header: () => t('activerecord.attributes.campaign_setting.adgroup_name'),
    resolveChildren: ({ decodedAdgroupName, hierarchyLink }) => ({
      text: decodedAdgroupName,
      link: hierarchyLink,
    }),
  }),
  ...setupColumn({
    id: 'dataSourceName',
    graphqlFields: { dataSource: ['name', 'showLink', 'icon'] },
    order: 'data_source.name',
    cell: LinkCell,
    header: () => t('activerecord.attributes.campaign_setting.data_source'),
    resolveChildren: ({ dataSource }) => ({
      icon: dataSource?.icon,
      text: dataSource?.name,
      link: dataSource?.showLink,
    }),
  }),
  ...setupColumn({
    id: 'itemsCount',
    order: 'items_count',
    graphqlFields: 'displayProductsWithGrouped',
    cell: HtmlRightCell,
    resolveChildren: ({ displayProductsWithGrouped }) => displayProductsWithGrouped,
    header: () => t('activerecord.attributes.campaign_setting.product_count'),
  }),
  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    category: 'setup',
    fixed: 'right',
    reverseOrder: true,
    header: () => t('attributes.created_at'),
    cell: TimeAgoCell,
  }),
  ...setupColumn({
    id: 'updatedAt',
    order: 'updated_at',
    category: 'setup',
    fixed: 'right',
    reverseOrder: true,
    header: () => t('attributes.updated_at'),
    cell: TimeAgoCell,
  }),
  ...setupColumn({
    id: 'adSystems',
    graphqlFields: [
      'synced',
      'generated',
      'accountAccessLink',

      'adwordsEnabled',
      'adwordsStatus',
      'adwordsLink',
      'adwordsSynchronizationError',

      'facebookEnabled',
      'facebookStatus',
      'facebookLink',
      'facebookSynchronizationError',

      'bingEnabled',
      'bingStatus',
      'bingLink',
      'bingSynchronizationError',

      'sklikEnabled',
      'sklikStatus',
      'sklikLink',
      'sklikSynchronizationError',

      {
        adwordsSetting: ['systemId', 'timeRestrictionStatus'],
        sklikSetting: ['systemId', 'timeRestrictionStatus'],
        bingSetting: ['systemId', 'timeRestrictionStatus'],
        facebookSetting: ['systemId', 'timeRestrictionStatus'],
      },
    ],
    category: 'adwords',
    order: 'system_rank_adwords',
    disableMultieditEditation: true,
    reverseOrder: true,
    editQuery: ({ id }) =>
      gql`
        mutation UpdateCampaignStatus($adwordsStatus: String, $facebookStatus: String, $bingStatus: String, $sklikStatus: String) {
          updateCampaignSetting(id: ${id}, adwordsStatus: $adwordsStatus, facebookStatus: $facebookStatus, bingStatus: $bingStatus, sklikStatus: $sklikStatus) {
            campaignSetting {
              id
              adwordsStatus
              facebookStatus
              bingStatus
              sklikStatus
              imageGeneratorStatus
              updatedAt
            }
            errors
          }
        }
      `,
    header: () => t('activerecord.attributes.campaign_setting.adsystems'),
    cell: SystemStateCell,
    resolveChildren: data => ({
      synced: data.synced,
      generated: data.generated,
      id: data.id,
      systems: [
        resolveSystemObject(data, 'adwords'),
        resolveSystemObject(data, 'facebook'),
        resolveSystemObject(data, 'bing'),
        resolveSystemObject(data, 'sklik'),
      ],
    }),
  }),
  ...conditions,
  ...labels,
  ...generateStatsColumns(),
  ...generateStatsColumns('adwords'),
  ...generateStatsColumns('facebook'),
  ...generateStatsColumns('bing'),
  ...generateStatsColumns('sklik'),
  ...adwordsMapping,
  ...facebookMapping,
  ...bingMapping,
  ...sklikMapping,
};

export default mapping;
