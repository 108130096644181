import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import {
  ConditionsBadge,
  Icon,
  Tooltip,
  Row,
  Col,
  Text,
  Ellipsis,
  cssVariables,
  Link,
  modalDataUrlOpener,
} from '../../components';
import { t } from '../../i18n';

const ItemGroupDisplay = ({ name, conditions, uniq_character, textColor, iconColor, options, editLink, type }) => (
  <Row center padding="m">
    {!editLink ||
      (!type && (
        <Col shrink>
          <Icon kind="item-group" size={20} color={iconColor} />
        </Col>
      ))}
    <Col maxWidth="144px" noPadding>
      <Row>
        <Col shrink style={{ maxWidth: '100%' }}>
          <Ellipsis>
            {editLink && type ? (
              <Link
                icon="item-group"
                {...modalDataUrlOpener({
                  url: editLink,
                  notify: `item_groups_table__#${type}`,
                  size: 'fullwithGrid',
                })}
              >
                {name}
              </Link>
            ) : (
              <Text bold color={textColor}>
                {name}
              </Text>
            )}
          </Ellipsis>
        </Col>

        {uniq_character && options?.show_item_groups_uniq_charater && (
          <Col shrink>
            <Tooltip text={t('react.product_set.facebook_character_tooltip')}>{uniq_character}</Tooltip>
          </Col>
        )}
      </Row>
    </Col>
    <Col shrink>
      <Row>
        <ConditionsBadge conditions={conditions} itemGroups={options?.item_groups} />
      </Row>
    </Col>
  </Row>
);

ItemGroupDisplay.defaultProps = {
  textColor: 'grape',
  iconColor: cssVariables.iconGrape,
};

ItemGroupDisplay.propTypes = {
  // products_count: PropTypes.number,
  conditions: PropTypes.array,
  editLink: PropTypes.string,
  iconColor: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.any,
  textColor: PropTypes.string,
  type: PropTypes.string,
  uniq_character: PropTypes.string,
};

export default hot(ItemGroupDisplay);
