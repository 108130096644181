import React from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { Icon } from './index';

const Link = ({
  className,
  danger,
  disabled,
  external,
  href = '#',
  children,
  icon,
  iconSize,
  noUnderline,
  onClick,
  size = 'md',
  ...rest
}) => (
  <a
    target={external && '_blank'}
    href={disabled ? '' : href || '#'}
    onClick={
      typeof onClick === 'function' && !disabled
        ? e => {
            e.preventDefault();
            onClick(e);
          }
        : e => {
            if (href === '#') {
              e.preventDefault();
            }
          }
    }
    className={cs('Link', className, {
      'Link--withIcon': icon || external,
      [`fc-${icon}`]: icon,
      [`Link--${size}`]: size,
      'Link--danger': danger,
      'Link--disabled': disabled,
      'Link--noUnderline': noUnderline,
    })}
    {...rest}
  >
    {children}
    {external && <Icon kind="external" inheritColor className="Link-icon" />}
  </a>
);

Link.propTypes = {
  className: RPT.string,
  danger: RPT.bool,
  disabled: RPT.bool,
  external: RPT.bool,
  href: RPT.string,
  children: RPT.any,
  icon: RPT.string,
  iconSize: RPT.string,
  noUnderline: RPT.bool,
  onClick: RPT.func,
  size: RPT.oneOf[('sm', 'md', 'lg')],
};

export default Link;
