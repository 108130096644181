/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';

import { gql, HttpLink, ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

const createClient = csrfToken =>
  new ApolloClient({
    // By default, this client will send queries to the
    //  `/graphql` endpoint on the same host
    // Pass the configuration option { uri: YOUR_GRAPHQL_API_URL } to the `HttpLink` to connect
    // to a different host
    link: new HttpLink({
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    }),
    cache: new InMemoryCache(),
  });

const GET_DATA = (orgId, campaignId) => gql`
{
  organization(id: ${orgId}) {
    id
    name
    campaignSetting(id: ${campaignId}) {
      id
      name
      estoreAdgroups(status: "running", size: 3) {
        name
        campaignName
      }
    }
  }
}
`;

const GET_DATA_ORG = orgId => gql`
{
  organization(id: ${orgId}) {
    id
    name
  }
}
`;
class TestingComponent extends React.Component {
  state = { orgId: 0 };
  render() {
    return (
      <div>
        <button onClick={() => this.setState({ orgId: 1 })}>1</button>
        <button onClick={() => this.setState({ orgId: 2 })}>2</button>
        <button onClick={() => this.setState({ orgId: 3 })}>3</button>
        <Query query={GET_DATA(this.state.orgId, 1)}>
          {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            return JSON.stringify(data);
          }}
        </Query>

        <Query query={GET_DATA_ORG(this.state.orgId)}>
          {({ loading, error, data }) => {
            if (loading) return 'Loading...';
            if (error) return `Error! ${error.message}`;

            return JSON.stringify(data);
          }}
        </Query>
      </div>
    );
  }
}
export default class GraphQLTest extends React.Component {
  static propTypes = {
    csrfToken: PropTypes.string.isRequired,
  };
  render() {
    return (
      <ApolloProvider client={createClient(this.props.csrfToken)}>
        <TestingComponent />
      </ApolloProvider>
    );
  }
}
