import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import ReactCrop from 'react-image-crop';
import { Icon, Button, Tooltip } from '../index';
import { Modal, ModalBody, ModalFooter } from '../Modal';
import Select from './Select';
import { t, tHtml, formatNumber } from '../../i18n';
import { addFieldToNestedFieldOptions } from '../../nested_fields/getNestedFieldOptions';
import SelectImageModal from '../image_generator/react-designer/panels/modals/SelectImageModal';
import OptionsContext from '../image_generator/OptionsContext';
import ErrorBox from '../ErrorBox';

const defaultCropSettings = {
  unit: 'px',
};

function getDefaultCrop(imageData, aspect) {
  let cropWidth = 0;
  let cropHeight = 0;

  if (aspect < 1) {
    // "tall" crop
    cropWidth = Math.min(imageData.height * aspect, imageData.width);
    cropHeight = cropWidth / aspect;
  } else {
    // "wide" or square crop
    cropHeight = Math.min(imageData.width / aspect, imageData.height);
    cropWidth = cropHeight * aspect;
  }

  return {
    ...defaultCropSettings,
    aspect,
    x: Math.floor((imageData.width - cropWidth) / 2),
    y: Math.floor((imageData.height - cropHeight) / 2),
    width: cropWidth,
    height: cropHeight,
  };
}

function getPreviewCrop(preview, crop) {
  if (!preview) {
    return crop;
  }

  const scaleX = preview.naturalWidth / preview.width;
  const scaleY = preview.naturalHeight / preview.height;

  return {
    ...crop,
    x: crop.x / scaleX,
    y: crop.y / scaleY,
    width: crop.width / scaleX,
    height: crop.height / scaleY,
  };
}

function getNaturalCrop(preview, crop) {
  if (!preview) {
    return crop;
  }

  const scaleX = preview.naturalWidth / preview.width;
  const scaleY = preview.naturalHeight / preview.height;

  return {
    ...crop,
    x: Math.round(crop.x * scaleX),
    y: Math.round(crop.y * scaleY),
    width: Math.round(crop.width * scaleX),
    height: Math.round(crop.height * scaleY),
  };
}

const ImageUpload = ({ aspect_ratios = [], disableFormats = [], fieldOptions, id, onRemove, options, ...props }) => {
  const [state, setState] = useState({
    edit: false,
    image_id: props.image_id,
    source: props.source,
    crop: {
      ...defaultCropSettings,
      aspect: props.aspect || ((aspect_ratios || [])[0] || {}).aspect,
      x: props.crop_x,
      y: props.crop_y,
      width: props.crop_width,
      height: props.crop_height,
    },
    originalCrop: null,
    currentCrop: null,
    showModal: false,
  });

  const { source, edit, crop, currentCrop, showModal } = state;
  const aspect = crop.aspect;
  const aspectRatio = aspect_ratios.find(ratio => ratio.aspect === aspect);
  const badImageSize = aspectRatio && (aspectRatio.minWidth > crop.width || aspectRatio.minHeight > crop.height);
  const startEdit = () => setState({ ...state, edit: true, originalCrop: state.crop });

  const getInputIdName = addFieldToNestedFieldOptions(fieldOptions);

  const sourceImage = state.source;

  const useCrop = () => {
    setState(s => ({ ...s, edit: false, currentCrop: null, originalCrop: null, cropImage: null }));

    if (typeof props.onChange === 'function') {
      props.onChange({
        source: state.source,
        aspect: state.crop.aspect,
        crop_x: state.crop.x,
        crop_y: state.crop.y,
        crop_width: state.crop.width,
        crop_height: state.crop.height,
      });
    }
  };
  const cancelCrop = () => {
    setState(s => {
      if (s.edit) {
        // image was not saved so clear it
        if (!s.originalCrop) {
          setTimeout(() => {
            setState(ss => ({ ...ss, showModal: true }));
          }, 500);
          return {
            ...s,
            edit: false,
            cropPreviewImage: null,
            originalCrop: null,
            currentCrop: null,
            cropImage: null,
            source: null,
            image_id: null,
          };
        }

        return {
          ...s,
          edit: false,
          crop: s.originalCrop || s.crop,
          cropImage: null,
          originalCrop: null,
          currentCrop: null,
        };
      }
      return s;
    });
  };

  const updateCrop = newCrop => {
    if (newCrop.x === 0 && newCrop.y === 0 && newCrop.width === 0 && newCrop.height === 0) {
      // do not update state because we don't want to use this bullshit newCrop
      return;
    }

    setState(s => ({ ...s, crop: getNaturalCrop(state.cropImage, newCrop), currentCrop: newCrop }));
  };

  const changeAspectRatio = ({ target: { value } }) => {
    const newCrop = getDefaultCrop(
      { height: state.cropImage.naturalHeight, width: state.cropImage.naturalWidth },
      parseFloat(value, 10)
    );
    setState(s => ({
      ...s,
      crop: newCrop,
    }));
  };

  return (
    <OptionsContext.Provider
      value={{
        organizationId: options.organization_id,
      }}
    >
      <React.Fragment>
        <div
          className={cs('ImageUpload-wrapper', { 'ImageUpload--asButton': !sourceImage })}
          onClick={sourceImage ? startEdit : () => setState(s => ({ ...s, showModal: true }))}
        >
          {!sourceImage && (
            <label htmlFor={getInputIdName('_file').id}>
              <div className="ImageUpload-centeredContent">
                <Icon inheritColor kind="arrow-up" size="24px" />
                <div>{t('image_upload.upload_image')}</div>
              </div>
            </label>
          )}
          {sourceImage && <div className="ImageUpload-overlay" />}

          {sourceImage && (
            <Tooltip text={t('image_upload.edit_tooltip')} className="ImageUpload-edit">
              <Button primary onlyIcon icon="edit" data-test-id="image-upload-edit" size="small" onClick={startEdit} />
            </Tooltip>
          )}
          {sourceImage && onRemove && (
            <Tooltip text={t('image_upload.delete_tooltip')} className="ImageUpload-trash ml-4">
              <Button red onlyIcon icon="trash" data-test-id="nested-field-remove" size="small" onClick={onRemove} />
            </Tooltip>
          )}

          <div className="ImageUpload-centeredContent">
            {sourceImage && (
              <div
                onClick={startEdit}
                className="d-inline-block ImageUpload-transparentBackground ImageUpload-transparentBackground--small"
              >
                {crop && <input type="hidden" value={id} {...getInputIdName('id')} />}
                {crop && <input type="hidden" value={state.image_id} {...getInputIdName('image_id')} />}
                {crop && <input type="hidden" value={state.crop.aspect} {...getInputIdName('aspect')} />}
                {crop && <input type="hidden" value={Math.round(crop.x)} {...getInputIdName('crop_x')} />}
                {crop && <input type="hidden" value={Math.round(crop.y)} {...getInputIdName('crop_y')} />}
                {crop && <input type="hidden" value={Math.round(crop.width)} {...getInputIdName('crop_width')} />}
                {crop && <input type="hidden" value={Math.round(crop.height)} {...getInputIdName('crop_height')} />}
                <div
                  style={{
                    width: `${crop.width}px`,
                    height: `${crop.height}px`,
                    position: 'relative',
                    zoom: crop.width > crop.height ? 100 / crop.width : 100 / crop.height,
                    overflow: 'hidden',
                  }}
                >
                  <img
                    alt=""
                    src={sourceImage}
                    style={{ left: `${crop.x * -1}px`, top: `${crop.y * -1}px`, position: 'absolute' }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        {showModal && (
          <SelectImageModal
            minWidth={aspectRatio?.minWidth}
            minHeight={aspectRatio?.minHeight}
            disableFormats={disableFormats}
            onSelect={d => {
              setState(s => ({
                ...s,
                image_id: d.id,
                source: d.xlinkHref,
                crop: getDefaultCrop(d, aspect),
                originalCrop: null,
                showModal: false,
              }));
              setTimeout(() => {
                setState(s => ({ ...s, edit: true }));
              }, 500);
            }}
            onClose={() => {
              setState(s => ({ ...s, showModal: false }));
            }}
          />
        )}

        {source && edit && (
          <Modal
            onClose={cancelCrop}
            heading={t('image_upload.modal_heading', {
              default: 'Crop image for %{aspect}:1',
              aspect: formatNumber(aspect, {
                strip_insignificant_zeros: true,
                precision: 2,
              }),
            })}
          >
            <ModalBody>
              {aspect_ratios.length > 0 && (
                <Select
                  doNotUseInternalState
                  value={aspect}
                  onChange={changeAspectRatio}
                  collection={aspect_ratios.map(ratio => ({
                    value: ratio.aspect,
                    label: `${ratio.aspect}:1, minimum: ${ratio.minWidth}x${ratio.minHeight}`,
                  }))}
                />
              )}
              <div className="mb-4" style={{ maxHeight: '68vh', textAlign: 'center' }}>
                <ReactCrop
                  src={source}
                  crop={currentCrop || getPreviewCrop(state.cropImage, crop)}
                  onChange={state.cropImage ? updateCrop : () => {}}
                  onImageLoaded={cropImage => setState(s => ({ ...s, cropImage }))}
                  keepSelection
                  className="ImageUpload-transparentBackground"
                  imageStyle={{
                    maxWidth: '100%',
                    maxHeight: '68vh',
                  }}
                />
              </div>

              {badImageSize && (
                <ErrorBox withIcon className="mb-4">
                  <span>
                    {aspectRatio.minWidth > crop.width &&
                      tHtml('image_upload.width_is_smaller', {
                        default: 'Image width <b>%{width}px</b> is smaller than <b>%{minWidth}px</b>.',
                        width: crop.width,
                        minWidth: aspectRatio.minWidth,
                      })}
                    {aspectRatio.minHeight > crop.height &&
                      tHtml('image_upload.height_is_smaller', {
                        default: 'Image height <b>%{height}px</b> is smaller than <b>%{minHeight}px</b>.',
                        height: crop.height,
                        minHeight: aspectRatio.minHeight,
                      })}
                  </span>
                </ErrorBox>
              )}
            </ModalBody>
            <ModalFooter>
              <Button kind="primary" onClick={useCrop} disabled={badImageSize}>
                {t('image_upload.save')}
              </Button>
              <Button kind="secondary" onClick={cancelCrop}>
                {t('image_upload.cancel')}
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </React.Fragment>
    </OptionsContext.Provider>
  );
};

const aspectRatioPropType = PropTypes.shape({
  aspect: PropTypes.number,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
  maxSize: PropTypes.number,
});

ImageUpload.propTypes = {
  aspect_ratios: PropTypes.arrayOf(aspectRatioPropType),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  source: PropTypes.string,
  fieldOptions: PropTypes.object,
  disableFormats: PropTypes.array,
  onChange: PropTypes.func,
  options: PropTypes.object,
  onRemove: PropTypes.func,
  image_id: PropTypes.number,
  aspect: PropTypes.number.isRequired,
  crop_x: PropTypes.number,
  crop_y: PropTypes.number,
  crop_width: PropTypes.number,
  crop_height: PropTypes.number,
};

export default ImageUpload;
