import React from 'react';
import moment from 'moment';
import { hot } from 'react-hot-loader/root';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { DateInput, Select, Row, Col, Button } from '../../../index';
import { t } from '../../../../i18n';
import { useMultiEdit } from '../../MultiEditContext';
import { TimeRestrictionState } from '../../../../rails_form/timeRestriction';

const CHANGE_TIME_RESTRICTION_MUTATION = collectionKey => gql`
  mutation ChangeTimeRestrictionMutation($organizationId: BigInt!, $ids: [String!]!, $minDate: String, $maxDate: String, $generate: String!){
    changeTimeRestriction(organizationId: $organizationId, collectionKey: "${collectionKey}", ids: $ids, minDate: $minDate, maxDate: $maxDate, generate: $generate )
    {
      ${collectionKey} {
        id
        timeRestrictionStatus
        timeRestriction { tooltip id text dateFrom dateTo generateString }
      }
      errors
    }
  }
`;

const ChangeTimeRestriction = ({ onCompleted, hashSelectedData }) => {
  const [minDate, setMinDate] = React.useState('');
  const [maxDate, setMaxDate] = React.useState('');
  const [generate, setGenerate] = React.useState('only');
  // prettier-ignore
  const { state: { selected, multiEdit, collectionKey: tempCollectionKey, organizationId } } = useMultiEdit();

  const firstItemId = selected.find(k => hashSelectedData[k]?.timeRestrictionStatus);
  const firstWithRestriction = hashSelectedData[firstItemId];

  React.useEffect(() => {
    const from = firstWithRestriction?.timeRestriction?.dateFrom;
    const to = firstWithRestriction?.timeRestriction?.dateTo;
    const generateString = firstWithRestriction?.timeRestriction?.generateString;

    setMinDate(from || '');
    setMaxDate(to || '');
    if (generateString) setGenerate(generateString);
  }, []);

  const collectionKey = typeof multiEdit?.timeRestriction === 'string' ? multiEdit.timeRestriction : tempCollectionKey;

  const handleCompleted = res =>
    onCompleted({ res, successMessage: t('react.multi_edit.time_restriction.change_success') });

  const [onSubmit, { loading }] = useMutation(CHANGE_TIME_RESTRICTION_MUTATION(collectionKey), {
    variables: {
      organizationId,
      ids: selected.map(id => `${id}`),
      minDate,
      maxDate,
      generate,
    },
    onCompleted: res => (typeof onCompleted === 'function' ? handleCompleted(res) : null),
  });

  return (
    <Row padding="l" center flexwrap>
      <Col shrink>
        <Select
          name="time-restriction-generate"
          id="time-restriction-generate"
          value={generate}
          onChange={({ target: { value } }) => setGenerate(value)}
          doNotUseInternalState
          horizontaly
          noMargin
          label={t('react.time_restriction.generate')}
          testId="time-restriction-select"
        >
          <option value="only">{t('react.time_restriction.generate_only')}</option>
          <option value="except">{t('react.time_restriction.generate_except')}</option>
        </Select>
      </Col>
      <Col shrink>
        <DateInput
          maxDate={maxDate && moment(maxDate).toDate()}
          onChange={({ target: { value } }) => setMinDate(value)}
          value={minDate}
          doNotUseInternalState
          horizontaly
          label={t('react.time_restriction.from')}
          testId="time-restriction[date_from]"
          name="date_from"
        />
      </Col>
      <Col shrink>
        <DateInput
          minDate={minDate && moment(minDate).toDate()}
          onChange={({ target: { value } }) => setMaxDate(value)}
          value={maxDate}
          doNotUseInternalState
          horizontaly
          label={t('react.time_restriction.until')}
          testId="time-restriction[date_until]"
          name="date_until"
          last
        />
      </Col>
      <Col shrink>
        <Button
          primary
          loading={loading}
          loadingType="classic"
          onClick={onSubmit}
          data-test-id="multiEdit[change-time-restriction-submit]"
        >
          {t('react.multi_edit.time_restriction.submit')}
        </Button>
      </Col>
      <Col shrink>
        <TimeRestrictionState generate={`${generate === 'only'}`} minDate={minDate} maxDate={maxDate} withoutDot />
      </Col>
    </Row>
  );
};

ChangeTimeRestriction.propTypes = {
  onCompleted: PropTypes.func,
  hashSelectedData: PropTypes.object,
};

export default hot(ChangeTimeRestriction);
