import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from './index';

const NoDataChartOverlay = ({ children, noData, message }) =>
  noData ? (
    <div className="pos-relative w-100 h-100">
      <div
        className="pos-absolute z-index-2 d-flex align-items-center justify-content-center border-bottom-left-radius-8 border-bottom-right-radius-8"
        style={{
          width: 'calc(100% + 32px)',
          height: '262px',
          top: '-16px',
          left: '-16px',
          backgroundColor: '#eeeeeeac',
        }}
      >
        <Badge size="extraLarge" kind="gray">
          {message || 'NO DATA'}
        </Badge>
      </div>
      <div className="pos-relative w-100 h-100" style={{ top: 0, left: 0, pointerEvents: 'none' }}>
        {children}
      </div>
    </div>
  ) : (
    children
  );

NoDataChartOverlay.propTypes = {
  children: PropTypes.any.isRequired,
  noData: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default NoDataChartOverlay;
