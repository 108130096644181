import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Row, Button, PageHeading, Heading } from './index';
import SearchContext from './table/SearchContext';
import { t } from '../i18n';

const EmptyState = ({ children, icon, heading, emptySearch, hasBorder = false, hasSmallIcon = false, ...rest }) => {
  const searchContext = React.useContext(SearchContext);
  const enableClear =
    searchContext &&
    searchContext?.clearSearch &&
    typeof searchContext?.clearSearch === 'function' &&
    searchContext?.searchState &&
    Object.keys(searchContext?.searchState).length > 0 &&
    Object.values(searchContext?.searchState).filter(val => val && val !== '' && val !== 'all');

  if (!icon && !heading && !emptySearch) {
    return children;
  }

  const getSvgIcon = icon_name => {
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`../scss_stylesheets/images/${icon_name}.svg`);
    } catch (err) {
      return null;
    }
  };

  const getPngIcon = icon_name => {
    try {
      // eslint-disable-next-line import/no-dynamic-require, global-require
      return require(`../../../assets/images/empty_container/${icon_name}.png`);
    } catch (err) {
      return null;
    }
  };

  return (
    <Row justifyCenter>
      <div className="EmptyContainer" {...rest}>
        <div
          className={cs('EmptyContainer-content', {
            'EmptyContainer-content--withBorder': hasBorder,
            'EmptyContainer-content--withSmallImage': hasSmallIcon,
          })}
        >
          {icon && <img src={getPngIcon(icon) || getSvgIcon(icon)} alt="Empty state icon" />}
          {heading && heading.length > 0 && <PageHeading className="mt-24">{heading}</PageHeading>}
          {emptySearch && emptySearch.length > 0 && (
            <Heading spacing={0} size="xl" className="mt-24">
              {emptySearch}
            </Heading>
          )}
          {children}
          {enableClear && (
            <div className="mt-16">
              <Button onClick={searchContext.clearSearch} primary>
                {t('react.clear_filters')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Row>
  );
};

EmptyState.propTypes = {
  emptySearch: PropTypes.string,
  hasBorder: PropTypes.bool,
  heading: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
  hasSmallIcon: PropTypes.bool,
};

export default EmptyState;
