import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

const Icon = ({
  className,
  children,
  kind,
  color = 'black',
  colored = false,
  inheritColor,
  size = 'auto',
  style = {},
  ...rest
}) => (
  <span
    className={cs(className, `fc-${kind}`, 'Icon', { 'Icon--colored': colored })}
    {...rest}
    style={{ ...style, ...(inheritColor ? {} : { color }), fontSize: size }}
  >
    {children}
  </span>
);

Icon.propTypes = {
  className: PropTypes.string,
  kind: PropTypes.string,
  color: PropTypes.string,
  colored: PropTypes.bool,
  inheritColor: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.any,
};

export default Icon;
