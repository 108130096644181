import qs from 'qs';
import { pick } from 'lodash';

export const setQueryStringWithoutPageReload = qsValue => {
  const newurl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${qsValue}`;
  window.history.pushState({ path: newurl }, '', newurl);
};

export const setQueryStringValue = (key, value, queryString = window.location.search) => {
  const values = qs.parse(queryString.replace('?', ''));
  const newQsValue = qs.stringify({ ...values, [key]: value });
  setQueryStringWithoutPageReload(`?${newQsValue}#page=1`);
};

export const clearQueryStringValue = () => {
  setQueryStringWithoutPageReload('');
};

export const getQueryStringValue = (key, queryString = window.location.search) => {
  const values = qs.parse((queryString || '').replace('?', ''));
  return values[key];
};

export const getQueryDefaultSearchState = (validKeys, queryString = window.location.search) => {
  const result = pick(qs.parse((queryString || '').replace('?', '')), validKeys);
  return Object.keys(result).length > 0 ? result : null;
};
