/* eslint-disable react/prop-types */
import React from 'react';
import { groupBy } from 'lodash';
import { hot } from 'react-hot-loader/root';
import { PropTypes } from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { SubscriptionTable } from './index';
import { consolidatedTableColumns } from './constants';
import { Row, Loader, ErrorBox } from '../index';
import { t } from '../../i18n/index';

const CONSOLIDATED_CHILDS_QUERY = gql`
  query consolidatedChildsQuery($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      childOrganizations {
        id
        showLink
        name
      }
    }
  }
`;

const ConsolidatedContainer = ({ lines, parentOrganizationId, period, ...rest }) => {
  const { data, loading, error } = useQuery(CONSOLIDATED_CHILDS_QUERY, {
    variables: {
      organizationId: parentOrganizationId,
    },
  });

  if (loading) {
    return (
      <Row center justifyCenter>
        <Loader />
      </Row>
    );
  }

  if (error) {
    return (
      <ErrorBox className="mt-16" withIcon>
        {t('feature_period.server_error')}
      </ErrorBox>
    );
  }

  const linesByOrgId = groupBy(lines, 'organizationId');
  const organizationById = groupBy(data.organization.childOrganizations, 'id');
  const mydata = Object.keys(linesByOrgId)
    .map(orgId => ({
      suborganization: organizationById[orgId]?.[0]?.name || orgId,
      showLink: organizationById[orgId]?.[0]?.showLink,
      id: orgId,
      lines: linesByOrgId[orgId],
    }))
    .filter(({ id }) => id.toString() !== parentOrganizationId.toString());

  return (
    <div>
      <SubscriptionTable {...rest} period={period} lines={mydata} columns={consolidatedTableColumns} consolidated />
    </div>
  );
};
ConsolidatedContainer.propTypes = {
  lines: PropTypes.array,
  parentOrganizationId: PropTypes.number,
};

export default hot(ConsolidatedContainer);
