import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Text } from '../index';
import { AdPreviewContainer, FbPagePreview } from './index';
import gradientBackground from '../../../../assets/images/fb_ad_preview/instagram_reels_gradient_background.png';
import actionIcons from '../../../../assets/images/fb_ad_preview/instagram_reels_actions.png';

const InstagramReelsPreview = ({ media, pageImageUrl, pageName, label, ctaText, primaryText }) => (
  <AdPreviewContainer icon="instagram" title={t('fb_ad_preview.instagram', { default: 'Instagram' })}>
    <div className="InstagramReelsPreview-container">
      <div
        className="InstagramReelsPreview-gradientBackground"
        style={{ background: `url(${gradientBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      />
      <div
        className="InstagramReelsPreview-bluredBackground"
        style={{ background: `url(${media.image_url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      />
      <div className="InstagramReelsPreview-mainImage">
        {media?.image_url && (
          <img
            src={media.image_url}
            alt={t('fb_ad_preview.facebook_feed_preview', { default: 'Facebook feed preview' })}
          />
        )}
      </div>
      <Row bottom className="InstagramReelsPreview-footer">
        <Col>
          <FbPagePreview url={pageImageUrl} pageName={pageName} imageSize="22px" nameSize="7px" theme="light" />
          <Text className="InstagramReelsPreview-cta" tag="div" semibold style={{ fontSize: '7px', color: '#050505' }}>
            {ctaText}
          </Text>
          <Text tag="div" bold style={{ marginBottom: '9px', fontSize: '9px', color: '#ffffff' }}>
            {primaryText}
          </Text>
          <Text tag="div" semibold style={{ fontSize: '6px', color: '#ffffff' }}>
            {label}
          </Text>
        </Col>
        <Col shrink style={{ paddingBottom: '8px' }}>
          <img width="19px" src={actionIcons} alt="actions" />
        </Col>
      </Row>
    </div>
  </AdPreviewContainer>
);

InstagramReelsPreview.propTypes = {
  media: PropTypes.object,
  pageImageUrl: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  ctaText: PropTypes.string,
  primaryText: PropTypes.string,
};

export default InstagramReelsPreview;
