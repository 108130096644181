import React from 'react';
import PropTypes from 'prop-types';
import { getNearestElement } from './ScraperSelector';

class Webpage extends React.Component {
  static propTypes = {
    content: PropTypes.string,
    onClick: PropTypes.func,
    valueSource: PropTypes.string,
  };

  setRef = el => {
    if (el) {
      if (!this.ifrm) {
        setTimeout(() => this.injectJavascript(el), 200);
      }
      this.ifrm = el;
    }
  };

  destroyHighlight = (highlightedClass = '.ppcbee--highlight') => {
    Array.from(this.ifrm.contentWindow.document.querySelectorAll(highlightedClass)).map(el => el.remove());
  };

  removeCSS = shouldDisabled => {
    this.destroyHighlight();
    const stylesheets = this.ifrm.contentWindow.document.styleSheets;
    const styles = this.ifrm.contentWindow.document.querySelectorAll('style');

    // eslint-disable-next-line no-return-assign
    Object.keys(styles || {}).map(i => (styles[i].disabled = shouldDisabled));
    // eslint-disable-next-line no-return-assign
    Object.keys(stylesheets || {}).map(i => (stylesheets[i].disabled = shouldDisabled));
  };

  createHighlight = (element, color = 'rgba(0,100,0,0.5)', onlyOne) => {
    const x = this.ifrm.contentWindow.document.createElement('div');
    const cl = ['ppcbee--highlight'];
    if (onlyOne) cl.push('ppcbee--highlight-onlyOne');
    x.style.background = color;
    x.style.position = 'absolute';
    x.classList.add(...cl);
    x.style.userSelect = 'none';
    x.style.pointerEvents = 'none';
    x.style.zIndex = 100000000;

    this.ifrm.contentWindow.document.body.appendChild(x);
    const bcr = element.getBoundingClientRect();
    x.style.width = `${bcr.width}px`;
    x.style.height = `${bcr.height}px`;
    x.style.top = `${bcr.top + this.ifrm.contentWindow.scrollY}px`;
    x.style.left = `${bcr.left + this.ifrm.contentWindow.scrollX}px`;
  };

  injectJavascript = el => {
    const ifrm = el || this.ifrm;
    const x = ifrm.contentWindow.document.createElement('div');
    x.style.position = 'absolute';
    x.style.userSelect = 'none';
    x.style.pointerEvents = 'none';
    x.style.zIndex = 100000000;

    if (!ifrm.contentWindow.document.body) return;

    if (!ifrm.contentWindow.document.body.getAttribute('scraper-inited')) {
      ifrm.contentWindow.document.body.appendChild(x);
      $(ifrm.contentWindow.document).on('mouseover', e => {
        const elem = getNearestElement(e.target, this.props.valueSource, ifrm.contentWindow.document);
        const bg = elem ? 'rgba(100,0,0,0.5)' : 'rgba(100,0,0,0)';
        x.style.background = bg;
        const bcr = e.target.getBoundingClientRect();
        x.style.width = `${bcr.width}px`;
        x.style.height = `${bcr.height}px`;
        x.style.top = `${bcr.top + ifrm.contentWindow.scrollY}px`;
        x.style.left = `${bcr.left + ifrm.contentWindow.scrollX}px`;
      });

      $(ifrm.contentWindow.document).on('mouseout', () => {
        x.style.width = '0px';
        x.style.height = '0px';
      });

      ifrm.contentWindow.document.addEventListener(
        'click',
        e => {
          e.stopPropagation();
          e.preventDefault();
          this.props.onClick(e);
        },
        true
      );

      ifrm.contentWindow.document.body.setAttribute('scraper-inited', true);
    }
  };

  render() {
    return (
      <div style={{ height: '100%', position: 'relative' }} className="Tile Tile--shadow">
        <iframe
          style={{ zoom: 0.5, transformOrigin: '0 0', border: 0, borderRadius: '16px' }}
          className="ppcbee-scrollbar"
          title="Scraper iframe"
          data-test-id="iframe"
          onLoad={e => this.injectJavascript(e.target)}
          id="ifrm"
          ref={this.setRef}
          srcDoc={this.props.content}
          width="100%"
          height="100%"
        />
      </div>
    );
  }
}

export default Webpage;
