/* eslint-disable no-param-reassign */
window.initFBSlideshow = () => {
  Array.from(document.getElementsByClassName('js-fb-slideshow')).forEach(container => {
    if (!container.dataset.initFBSlideshow) {
      container.dataset.initFBSlideshow = true;
      const images = Array.from(container.querySelectorAll('.js-fb-slideshow-item'));

      images.forEach((item, index) => {
        if (index !== 0) {
          item.style.opacity = '0.0';
        }
      });

      images.forEach(item => {
        item.classList.add('FB_AA_d_t_ext-image--slideshowWithAnimation');
      });

      let currentIndex = 0;

      setInterval(() => {
        currentIndex = (currentIndex + 1) % images.length;
        if (images[currentIndex]) {
          images[currentIndex].style.opacity = '1.0';
        }
        images.forEach((item, index) => {
          if (index !== currentIndex) {
            item.style.opacity = '0.0';
          }
        });
      }, 2000);
    }
  });
};
