/* eslint-disable func-names */
import storage from './storage';

const toggleState = (contentEl, showMoreEl, showLessEl) => {
  $(contentEl).toggleClass('d-none');
  $(showMoreEl).toggleClass('d-none');
  $(showLessEl).toggleClass('d-none');
};

window.addEventListener('load', () => {
  $('.js-show-more').each(function() {
    const showMoreEl = $(this);
    const contentEl = $(this).prev('.js-more-content');
    const showLessEl = $(this).next('.js-show-less');

    if (storage.get('navigationState') === 'open') {
      toggleState(contentEl, showMoreEl, showLessEl);
    }

    showMoreEl.click(() => {
      toggleState(contentEl, showMoreEl, showLessEl);
      storage.set('navigationState', 'open');
    });

    showLessEl.click(() => {
      toggleState(contentEl, showMoreEl, showLessEl);
      storage.set('navigationState', 'close');
    });
  });

  const dropdownEl = document.getElementsByClassName('js-navigation-dropdown');
  if (dropdownEl.length > 0) {
    Array.from(dropdownEl).forEach(dropdown => {
      dropdown.addEventListener('click', function() {
        this.classList.toggle('open');
      });
    });
  }
});
