/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { setupColumn } from './setupColumn';
import { GenericCell, TextCell, TimeAgoCell } from './cells';
import ConditionalWrapper from '../../ConditionalWrapper';
import { Icon, Link, Ellipsis } from '../../index';
import { Row, Col } from '../../layout/Grid';
import { t } from '../../../i18n';
import ChangesCell from '../../../audit_logs/ChangesCell';

const iconKindMapping = {
  update: 'edit',
  destroy: 'trash',
  create: 'plus',
  email_sent: 'mail',
  rename_campaign: 'edit',
  subscription_change: 'dollar',
};

const ElementWithLinkCell = ({ link, name, type }) => <NameType link={link} name={name} type={type} />;
ElementWithLinkCell.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export const NameType = ({ name, type, link }) => {
  if (name && type) {
    const translatedType = t(type, { scope: 'activerecord.models' });
    return (
      <React.Fragment>
        {`${translatedType} `}
        <ConditionalWrapper condition={link} wrapper={child => <Link href={link}>{child}</Link>}>
          <Ellipsis>{name} </Ellipsis>
        </ConditionalWrapper>
      </React.Fragment>
    );
  } else if (name) {
    return (
      <ConditionalWrapper condition={link} wrapper={child => <Link href={link}>{child}</Link>}>
        <Ellipsis>{name} </Ellipsis>{' '}
      </ConditionalWrapper>
    );
  } else if (type) {
    const translatedType = t(type, { scope: 'activerecord.models' });
    return (
      <ConditionalWrapper condition={link} wrapper={child => <Link href={link}>{child}</Link>}>
        {translatedType}
      </ConditionalWrapper>
    );
  }

  return null;
};
NameType.propTypes = {
  link: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

const mapping = {
  ...setupColumn({
    id: 'actionSubject',
    graphqlFields: ['action', 'subjectLink', 'subjectName', 'subjectType'],
    required: true,
    cell: GenericCell,
    width: '230px',
    resolveChildren: ({ subjectLink, subjectName, subjectType, action }) => (
      <Row center>
        <Col shrink>
          <Icon kind={iconKindMapping[action]} color="#596774" size="20px" />
        </Col>
        <Col grow>
          <div>
            <ElementWithLinkCell link={subjectLink} name={subjectName} type={subjectType} />{' '}
            {t(action, { default: action, scope: 'react.alert_platform.action_done' })}
          </div>
        </Col>
      </Row>
    ),
    header: () => t('react.auditlogs.header.subject', { default: 'subject' }),
  }),
  ...setupColumn({
    id: 'where',
    required: true,
    graphqlFields: ['ownerAndSubjectEqual', 'ownerLink', 'ownerName', 'ownerType'],
    width: '230px',
    cell: GenericCell,
    resolveChildren: ({ ownerAndSubjectEqual, ownerLink, ownerName, ownerType }) =>
      ownerAndSubjectEqual ? null : <ElementWithLinkCell link={ownerLink} name={ownerName} type={ownerType} />,
    header: () => t('react.auditlogs.header.where', { default: 'where' }),
  }),
  ...setupColumn({
    id: 'userName',
    required: true,
    width: '150px',
    graphqlFields: 'userName',
    cell: TextCell,
    header: () => t('react.auditlogs.header.user', { default: 'user' }),
  }),
  ...setupColumn({
    id: 'createdAt',
    required: true,
    graphqlFields: 'createdAt',
    cell: TimeAgoCell,
    header: () => t('react.auditlogs.header.when', { default: 'when' }),
  }),
  ...setupColumn({
    id: 'changes',
    required: true,
    graphqlFields: 'changes',
    cell: GenericCell,
    useEllipsis: false,
    resolveChildren: ({ changes }) => <ChangesCell changes={changes ? JSON.parse(changes) : undefined} />,
    header: () => (
      <Row>
        <Col width="calc(40% - 8px)">
          {t('react.auditlogs.header.changes', {
            default: 'changes',
          })}
        </Col>
        <Col width="calc(30% - 8px)">
          {t('react.auditlogs.header.old_value', {
            default: 'Old value',
          })}
        </Col>
        <Col grow>
          {t('react.auditlogs.header.new_value', {
            default: 'New value',
          })}
        </Col>
      </Row>
    ),
  }),
  ...setupColumn({
    required: true,
    id: 'userNameForAdmin',
    graphqlFields: 'userNameForAdmin',
    cell: TextCell,
    header: () => t('react.auditlogs.header.user', { default: 'user_for_admin' }),
  }),
};

export default mapping;
