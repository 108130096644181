/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

document.addEventListener('loaded', () => {
  const periods = document.getElementsByClassName('js-period-same-height');

  function findHighest(els) {
    let maxHeight = 0;

    for (let i = 0; i < els.length; i++) {
      if (els[i].offsetHeight > maxHeight) {
        maxHeight = els[i].offsetHeight;
      }
    }

    return maxHeight;
  }

  function setHeight(el, height) {
    for (let i = 0; i < el.length; i++) {
      el[i].style.height = `${height}px`;
    }
  }

  if (periods.length > 1) {
    setHeight(periods, findHighest(periods));
  }
});
