import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import {
  Checkbox,
  modalDataUrlOpener,
  Row,
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Tile,
  EmptyState,
  SectionHeading,
  cssVariables,
} from '../../components';
import { t } from '../../i18n';
import ItemGroupDisplay from './ItemGroupDisplay';
import Searchbox from '../../organizations_dropdown/Searchbox';
import useSearch from '../../hooks/useSearch';

export const NewItemGroupButton = ({ linkDefinition, text, size = 'small', type, conditionId }) => (
  <Button
    type="button"
    size={size}
    secondary
    tabindex="-1"
    data-toggle={linkDefinition.toggle}
    data-url={linkDefinition.url}
    data-test-id={linkDefinition.test_id}
    data-heading={linkDefinition.heading}
    data-notify-on-change={`${linkDefinition.notify_on_change}${conditionId}__#${type}`}
    data-size={linkDefinition.size}
    className="mb-0"
  >
    {text || t('react.filters_and_conditions.create_new_filter_group')}
  </Button>
);

NewItemGroupButton.propTypes = {
  conditionId: PropTypes.string,
  linkDefinition: PropTypes.any,
  text: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
};

const AddItemGroupItem = ({ itemGroup, availableItemGroupIds, selectedIds, selection, setSelection, options }) => {
  const id = itemGroup.id;
  if (availableItemGroupIds.indexOf(id) === -1) {
    return null;
  }
  if (selectedIds.indexOf(id) !== -1) {
    return null;
  }
  const checkedBox = selection.indexOf(id) !== -1;
  return (
    <Row>
      <Tile
        smallSpaced
        active={checkedBox}
        onClick={e => {
          if (e.target.tagName !== 'LABEL' && e.target.tagName !== 'INPUT') {
            if (checkedBox) {
              setSelection(s => s.filter(i => i !== id));
            } else {
              setSelection(s => s.concat(id));
            }
          }
        }}
      >
        <Row center padding="l" className="pl-16" style={{ minHeight: '32px' }}>
          <Col shrink>
            <Checkbox
              value={id}
              style={{ lineHeight: 0, pointerEvents: 'none' }}
              doNotUseInternalState
              checked={checkedBox}
              name={`product-set-id-${id}`}
            />
          </Col>
          <Col grow>
            <ItemGroupDisplay
              {...itemGroup}
              options={options}
              iconColor={checkedBox ? cssVariables.iconGrape : cssVariables.iconDark}
              textColor={checkedBox ? 'grape' : 'default'}
            />
          </Col>

          {itemGroup.edit_link && (
            <Col shrink>
              <Button
                secondary
                onlyIcon
                icon="edit"
                {...modalDataUrlOpener({
                  url: itemGroup.edit_link,
                  size: 'fullwithGrid',
                })}
              />
            </Col>
          )}
        </Row>
      </Tile>
    </Row>
  );
};

AddItemGroupItem.propTypes = {
  selectedIds: PropTypes.array,
  availableItemGroupIds: PropTypes.array,
  options: PropTypes.any,
  setSelection: PropTypes.func.isRequired,
  selection: PropTypes.array.isRequired,
  itemGroup: PropTypes.object.isRequired,
};

export const AddItemGroupModal = hot(
  ({ onClose, onSelect, itemGroups, type, linkDefinition, conditionId, ...rest }) => {
    const [selection, setSelection] = useState([]);
    const { search, setSearch, result } = useSearch({
      collection: itemGroups,
      ignoreKeys: ['conditions', 'id', 'products_count', 'type', 'uniq_character', 'value'],
    });

    const operator = t(`react.filters_and_conditions.${type}_operator`);

    return (
      <Modal onClose={onClose} heading={`${t('react.filters_and_conditions.modal_header')} (${operator})`}>
        <ModalBody>
          <Col padding="l" width="480px" className="ml-a mr-a">
            <Row shrink center>
              <Col grow>
                <Searchbox
                  key="search-box"
                  searchBoxText="Search"
                  searchValue={search}
                  handleSearch={({ target: { value } }) => setSearch(value)}
                />
              </Col>
              <Col shrink>
                <NewItemGroupButton
                  size="medium"
                  linkDefinition={linkDefinition}
                  type={type}
                  conditionId={conditionId}
                />
              </Col>
            </Row>
            <Row shrink>
              <Col>
                {result.length === 0 ? (
                  <EmptyState icon="item_groups_icon">
                    <SectionHeading>
                      {t('react.filters_and_conditions.too_much_filtering', {
                        default: 'None of the items match your search query. Try to modify what you’re looking for.',
                      })}
                    </SectionHeading>
                  </EmptyState>
                ) : (
                  result
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(itemGroup => (
                      <AddItemGroupItem
                        {...rest}
                        key={itemGroup?.id}
                        selection={selection}
                        setSelection={setSelection}
                        itemGroup={itemGroup}
                      />
                    ))
                )}
              </Col>
            </Row>
          </Col>
        </ModalBody>
        <ModalFooter>
          <Button primary tag="a" onClick={() => onSelect(selection)} disabled={selection.length === 0}>
            {t('react.filters_and_conditions.modal_add_button')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
);

AddItemGroupModal.propTypes = {
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  availableItemGroupIds: PropTypes.array,
  selectedIds: PropTypes.array,
  itemGroups: PropTypes.array,
  operator: PropTypes.string,
  linkDefinition: PropTypes.any,
  options: PropTypes.any,
};
