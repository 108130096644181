import React from 'react';
import RPT from 'prop-types';
import { Row, Col, Icon, cssVariables, Text } from '../index';

const InfoBoxLight = ({ text }) => (
  <Row className="InfoBoxLight" center>
    <Col shrink>
      <Icon kind="info-circle-fill" color={cssVariables.statusInformativeDefault} size="20px" />
    </Col>
    <Col>
      <Text bold style={{ color: cssVariables.textMuted }}>
        {text}
      </Text>
    </Col>
  </Row>
);

InfoBoxLight.propTypes = {
  className: RPT.string.isRequired,
};

export default InfoBoxLight;
