import React from 'react';
import ReactDOMServer from 'react-dom/server';
import objectTypes from './objectTypes';

const calculateSnippetProperties = ({ handler, snippetObjects }) => {
  if (!handler || !snippetObjects || snippetObjects.lenght === 0) return [];

  const { top: y0, left: x0 } = handler;

  return snippetObjects.map(({ x, y, svg, type, ...rest }) => {
    const x1 = x - x0;
    const y1 = y - y0;
    const newObjectProperties = { type, x: x1, y: y1 };

    const Element = objectTypes[type];

    return {
      ...rest,
      ...newObjectProperties,
      svg: ReactDOMServer.renderToString(<Element object={{ ...rest, x: x1, y: y1 }} />),
    };
  });
};

export default calculateSnippetProperties;
