import React, { Component } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { Icon } from './index';

export const SandboxTag = ({ disabled = false, kind, size }) => (
  <Tag disabled={disabled} kind={kind} size={size}>
    <Icon kind="edit" inheritColor />
    Tag
    <Icon kind="campaign" inheritColor />
  </Tag>
);
SandboxTag.propTypes = {
  disabled: RPT.bool,
  kind: RPT.oneOf(['grape', 'green', 'orange', 'red', 'gray', 'blue', 'darkGrape']),
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

class Tag extends Component {
  static propTypes = {
    className: RPT.string,
    count: RPT.oneOfType([RPT.number, RPT.string]),
    children: RPT.any,
    disabled: RPT.bool,
    kind: RPT.oneOf(['grape', 'green', 'orange', 'red', 'gray', 'blue', 'darkGrape']),
    onClick: RPT.func,
    setRef: RPT.func,
    size: RPT.oneOf(['medium', 'large', 'extraLarge']),
  };

  static defaultProps = {
    size: 'medium',
  };

  render() {
    const { count, className, children, disabled, kind, size, setRef, ...rest } = this.props;

    return (
      <div
        ref={setRef}
        className={cs('Tag', className, {
          [`Tag--${kind}`]: kind,
          [`Tag--${size}`]: size,
          disabled,
        })}
        {...rest}
      >
        {count && count > 99 ? '99+' : count}
        {children}
      </div>
    );
  }
}

export default Tag;
