import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Input, Button, Row, Col, QueryHolder, Select, useLocalState, ClampedText } from '../components';
import { t, formatInteger } from '../i18n';
import Pagination, { setPageToUrl } from '../components/Pagination';

const QUERY_TRANSFORMATION_DATA_INPUT = gql`
  query DataSourceConditionsItemsPreview(
    $organizationId: BigInt!
    $dataSourceId: BigInt!
    $variableToTransform: String!
    $search: String
    $size: Int!
    $from: Int!
  ) {
    organization(id: $organizationId) {
      id
      itemsSearch(dataSourceId: $dataSourceId) {
        id
        items(search: $search, size: $size, from: $from) {
          id
          productId
          originalData(variable: $variableToTransform)
          transformedData(variable: $variableToTransform)
          transformedDataDiff(variable: $variableToTransform)
        }
        totalUnfilteredCount: itemsCount
        totalCount: itemsCount(search: $search)
      }
    }
  }
`;

const Table = ({ data, variableToTransform, from, size, changePage, perOptions, changeSize }) => {
  const totalCount = data.organization.itemsSearch?.totalCount;
  const totalUnfilteredCount = data.organization.itemsSearch?.totalUnfilteredCount;

  if (!data?.organization?.itemsSearch?.items) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="Sticky-Wrapper Sticky-Wrapper--scrollTable">
        <table className="Sticky-Table mb-0" data-test-id="table">
          <thead>
            <tr className="Sticky-Header">
              <th className="Sticky-Cell--first-child">Product ID</th>
              <th>Final {variableToTransform}</th>
              <th>Original {variableToTransform}</th>
              <th>Diff {variableToTransform}</th>
            </tr>
          </thead>
          <tbody className="Sticky-Body">
            {data?.organization?.itemsSearch?.items.map(item => (
              <tr key={item.product_id} data-test-id="product-row" className="Sticky-Row">
                <td
                  data-value={item.productId?.toString().substring(0, 1000)}
                  className="delimiter js-text-overflow Sticky-Cell--first-child"
                >
                  {item.productId}
                </td>
                <td className="delimiter without-ellipsis">
                  <ClampedText inheritBackgroundColor lines={5}>
                    {item.transformedData}
                  </ClampedText>
                </td>
                <td className="delimiter without-ellipsis">
                  <ClampedText inheritBackgroundColor lines={5}>
                    {item.originalData}
                  </ClampedText>
                </td>
                <td className="delimiter without-ellipsis">
                  <ClampedText inheritBackgroundColor lines={5}>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span className="diff-text" dangerouslySetInnerHTML={{ __html: item.transformedDataDiff }} />
                  </ClampedText>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Pagination
        current={from / size}
        maxPages={Math.ceil(data.organization.itemsSearch.totalCount / size)}
        changePage={changePage}
        className="mt-16"
        data-all-total={totalUnfilteredCount}
        data-test-id="pagination-info"
        data-from={from + 1}
        data-to={Math.min(from + size, totalCount)}
        data-max-pages={Math.ceil(data.organization.itemsSearch.totalCount / size)}
        data-total={totalCount}
        isCompact
      >
        {t('react.show', {
          default: 'Show',
        })}
        <Select
          className="ml-16 mr-16"
          doNotUseInternalState
          id="products-perPage"
          name="products[perPage]"
          value={size}
          onChange={changeSize}
          collection={perOptions.map(value => ({
            value,
            label: t('react.items_count', { items: value }),
          }))}
        />
        {!isNaN(totalCount) &&
          t('react.outof', {
            default: 'out of %{total}',
            total: formatInteger(totalCount),
          })}{' '}
        {!isNaN(totalUnfilteredCount) &&
          totalCount !== totalUnfilteredCount &&
          t('react.fromtotal', {
            default: 'from all %{total}',
            total: formatInteger(totalUnfilteredCount),
          })}
      </Pagination>
    </React.Fragment>
  );
};

Table.propTypes = {
  data: PropTypes.object,
  variableToTransform: PropTypes.string,
  from: PropTypes.number,
  size: PropTypes.number,
  changePage: PropTypes.func,
  perOptions: PropTypes.array,
  changeSize: PropTypes.func,
};

const QueryTable = QueryHolder(Table);

const TransformationModal = ({ dataSourceId, organizationId, variableToTransform }) => {
  const [from, setFrom] = useState(0);
  const [size, setSize] = useLocalState(10, 'VariableRules-size');
  const [search, setSearch] = useState('');
  const [debounceSearch, setDebouncedSearch] = useState(search);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
      setFrom(0);
      setPageToUrl(0);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  return (
    <form style={{ minHeight: '450px' }}>
      <Row padding="l" center>
        <Col shrink>
          <Input
            id="search"
            disableEnter
            doNotUseInternalState
            placeholder={t('react.search')}
            search
            type="text"
            name="[search]"
            value={search}
            onChange={({ target: { value } }) => setSearch(value)}
            onSubmit={e => e.preventDefault()}
          />
        </Col>
        {search && search.length > 0 && (
          <Col shrink>
            <Button
              secondary
              onClick={() => {
                setDebouncedSearch('');
                setSearch('');
                setFrom(0);
              }}
              data-test-id="product-filter-reset"
            >
              {t('react.card_view.reset_filters')}
            </Button>
          </Col>
        )}
      </Row>

      <div className="pos-relative mt-16">
        <QueryTable
          size={size}
          perOptions={[10, 50, 100]}
          changeSize={({ target: { value } }) => {
            setSize(parseInt(value, 10));
            setFrom(0);
            setPageToUrl(0);
          }}
          from={from}
          changePage={newPage => () => {
            setFrom(newPage * size);
          }}
          variableToTransform={variableToTransform}
          variables={{
            organizationId,
            dataSourceId,
            from,
            size,
            search: debounceSearch,
            variableToTransform,
          }}
          query={QUERY_TRANSFORMATION_DATA_INPUT}
        />
      </div>
    </form>
  );
};
TransformationModal.propTypes = {
  dataSourceId: PropTypes.number,
  organizationId: PropTypes.number,
  variableToTransform: PropTypes.string,
};
export default TransformationModal;
