import React from 'react';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import { t } from '../i18n';
import { Text } from './index';

const LocalizedChartTooltip = ({ active, label, payload, legendFormatter, tooltipTranslationPath, valueFormatter }) => {
  const ValuesTable = () => (
    <table>
      <thead>
        <tr>
          <th colSpan={2}>
            <Text color="white">{legendFormatter ? legendFormatter(label) : label}</Text>
          </th>
        </tr>
      </thead>
      <tbody>
        {payload &&
          payload.map(item => (
            <tr key={`legend-${label}-${item.dataKey}`} className="text-left">
              <td>
                <Text className="pr-4" bold style={{ color: item.stroke || item.fill }}>
                  {tooltipTranslationPath
                    ? t(snakeCase(item.dataKey), { scope: tooltipTranslationPath })
                    : item.dataKey}
                </Text>
              </td>
              <td>
                <Text color="white">
                  {valueFormatter ? valueFormatter(item.payload[item.dataKey]) : item.payload[item.dataKey]}
                </Text>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  return active ? (
    <div className="tooltip-inner">
      <ValuesTable />
    </div>
  ) : null;
};

LocalizedChartTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  legendFormatter: PropTypes.func,
  payload: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  tooltipTranslationPath: PropTypes.string,
  valueFormatter: PropTypes.func,
};

export default LocalizedChartTooltip;
