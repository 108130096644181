import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Modal, ModalBody, Ellipsis, Row, Col, SegmentedButton, EmptyState, SectionHeading } from '../../../../index';
import { t } from '../../../../../i18n';
import OptionsContext from '../../../OptionsContext';
import ImageTile from './ImageTile';

const SEGMENTED_BUTTONS_VALUES = ['all', 'feed', 'custom'];

const ImageRadio = ({ checked = false, imgUrl, label, marginRight, onSelect, value, testId }) => (
  <Col width="calc(25% - 8px)">
    <div
      data-test-id={testId}
      className={cs('ImageCheckbox-item', { 'ImageCheckbox-item--checked': checked }, { 'mr-8': marginRight })}
      onClick={() => onSelect(value)}
    >
      <Row shrink>
        <input
          className="Radios-item ml-8 mt-8"
          type="radio"
          value={value}
          checked={checked}
          onChange={() => onSelect(value)}
        />
      </Row>
      <Row grow justifyCenter>
        <img style={{ height: '75px', maxWidth: '100%', aspectRatio: 'auto' }} src={imgUrl} alt={label} />
      </Row>

      <Row shrink justifyCenter className="mv-4 Heading--lg ph-8">
        <Ellipsis>{label}</Ellipsis>
      </Row>
    </div>
  </Col>
);

const SelectImagePlaceholderModal = ({ onSelect, onClose }) => {
  const [imagesFilter, setImagesFilter] = useState(SEGMENTED_BUTTONS_VALUES[0]);
  const { imageUrlFields } = useContext(OptionsContext);

  const filterImages = () => {
    if (imagesFilter === SEGMENTED_BUTTONS_VALUES[0]) return imageUrlFields;
    if (imagesFilter === SEGMENTED_BUTTONS_VALUES[1]) return imageUrlFields.filter(el => !el.isModifier);
    return imageUrlFields.filter(el => el.isModifier);
  };

  return (
    <Modal onClose={onClose} heading={t('react.image_generator.modal.select_images')}>
      <ModalBody>
        <Row shrink className="mt-16">
          <SegmentedButton
            name="slideshowFilter"
            id="slideshowFilter"
            doNotUseInternalState
            size="medium"
            value={imagesFilter}
            onChange={e => setImagesFilter(e.target.value)}
            collection={SEGMENTED_BUTTONS_VALUES.map(val => ({
              value: val,
              label: t(val, { scope: 'react.fb_adtexts.filters' }),
            }))}
          />
        </Row>
        {filterImages().length === 0 && (
          <EmptyState icon="image_editor_icon">
            <SectionHeading className="mt-16" spacing={0}>
              {t('react.image_generator.modal.empty_state_feed_image')}
            </SectionHeading>
          </EmptyState>
        )}
        <Row grow>
          <div className="w-100">
            <Row className="Grid--fourthWithoutMargin mv-8" flexwrap>
              {filterImages().map(({ value, name, url, id }) => (
                <Col width="206px" key={id}>
                  <ImageTile label={name} imgUrl={url} onSelect={() => onSelect(value)} />
                </Col>
              ))}
            </Row>
          </div>
        </Row>
      </ModalBody>
    </Modal>
  );
};

ImageRadio.propTypes = {
  imgUrl: PropTypes.string,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  testId: PropTypes.string,
  value: PropTypes.string,
};

SelectImagePlaceholderModal.propTypes = {
  onClose: PropTypes.func,
};

export default SelectImagePlaceholderModal;
