/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Cell, Label, Legend, Pie, PieChart } from 'recharts';
import { hot } from 'react-hot-loader/root';
import { Row } from '../layout';
import ErrorState from '../ReportTable/ErrorState';
import CHART_COLORS, { CHART_COLOR_OTHER } from '../ChartColors';
import { formatMetricValue } from './functions';
import WidgetPieChartLegend from './CustomPieLegend';

const CustomLabel = ({ title, value, viewBox }) => (
  <foreignObject
    height={100}
    style={{ transform: `translate(-${viewBox?.innerRadius}px, -50px)` }}
    width={2 * (viewBox?.innerRadius || 1)}
    x={viewBox?.cx || 0}
    y={viewBox?.cy}
  >
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
      <div
        className="Text--softGray"
        style={{ textAlign: 'center', lineHeight: '1.4', fontSize: `${(viewBox?.innerRadius || 1) / 72}em` }}
      >
        {title} <br />
        <strong className="Text--default d-block pt-4" style={{ fontSize: '1.42em' }}>
          {value}
        </strong>
      </div>
    </div>
  </foreignObject>
);

CustomLabel.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  viewBox: PropTypes.any,
};

const ReportPieChart = ({ data, metricName }) => {
  const rows = data?.rows;
  const summaryRow = data?.summary[0];

  const generalChartProps = {
    data: rows,
    endAngle: 90,
    startAngle: 450,
    isAnimationActive: false,
  };

  if (!rows) {
    return (
      <Row justifyCenter>
        <ErrorState />
      </Row>
    );
  }

  return (
    <div>
      <PieChart width={363} height={174}>
        <Legend
          layout="vertical"
          verticalAlign="middle"
          align="right"
          content={<WidgetPieChartLegend rows={rows} metricName={metricName} />}
        />

        <Pie {...generalChartProps} dataKey={metricName} innerRadius="78%" outerRadius="100%" paddingAngle={0}>
          <Label
            position="center"
            content={
              <CustomLabel
                title={`Total ${metricName}`}
                value={formatMetricValue(summaryRow[metricName] || '', { metricName, row: summaryRow })}
              />
            }
          />

          {rows?.map((item, index) => {
            const colorFill = item.adPlacement === 'other' ? CHART_COLOR_OTHER : CHART_COLORS[index];
            return <Cell key={item.adPlacement} fill={colorFill} />;
          })}
        </Pie>
      </PieChart>
    </div>
  );
};

export const itemPropType = PropTypes.shape({
  adPlacement: PropTypes.string,
});

ReportPieChart.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(itemPropType),
    summary: PropTypes.arrayOf(itemPropType),
  }),
  metricName: PropTypes.string,
};

export default hot(ReportPieChart);
