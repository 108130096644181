import React, { Component } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import uuid from 'uuid';

export default class Checkbox extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    doNotUseInternalState: PropTypes.bool,
    hasLineCheck: PropTypes.bool,
    checked: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    disabled: false,
    doNotUseInternalState: false,
    required: false,
    value: true,
  };

  state = {
    checked: this.props.checked,
    uuid: uuid(),
  };

  handleChange = e => {
    const {
      target: { value, checked },
    } = e;

    const newValue = checked ? value : false;
    this.setState({ checked: newValue });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: newValue } });
    }
  };

  render() {
    const { value, checked, name, id, doNotUseInternalState, label, disabled, hasLineCheck, ...rest } = this.props;
    const checkedState = doNotUseInternalState ? checked : this.state.checked;
    const inputId = id || this.state.uuid;
    const optionId = `${inputId}_${value}`;

    return (
      <div
        className={cs('checkbox', 'Checkboxes-item', 'mt-0', { 'Checkboxes--lineCheck': hasLineCheck })}
        key={optionId}
        {...rest}
      >
        <input
          id={optionId}
          name={name}
          className="Checkboxes-input"
          checked={checkedState ? 'checked' : false}
          value={value}
          disabled={disabled}
          onChange={this.handleChange}
          type="checkbox"
        />
        <label htmlFor={optionId}>{label}</label>
      </div>
    );
  }
}
