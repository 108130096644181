import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import List from './List';

const FBFeatures = props => {
  const { collectionKey, campaignSettingId, organizationId, elementType } = props;
  const query = useMemo(
    () => gql`
      query FBFeatureQuery${collectionKey}($organizationId: BigInt!, $campaignSettingId: BigInt ${
      elementType ? ', $elementType: String' : ''
    }) {
        organization(id: $organizationId) {
          id
          campaignSetting(id: $campaignSettingId) {
            id
            fbSummaryCount
            facebookCurrency
            itemsCount
            adSetCount
            dataSource {
              isFirstLoading
            }
          }
        }
        collection(identifier: "FBFeatures${collectionKey}", limit: 100000, page: 0, organizationId: $organizationId, campaignSettingId: $campaignSettingId) {
          id
          fbProductCampaigns {
            id
            name
            fbProductGroups {
              id
              name
            }
          }
          ${collectionKey}${elementType ? '(elementType: $elementType)' : ''} {
            fbProductGroupIds
            fbProductCampaignIds
            id
            name
            typeIcon
            position
            numberOfProducts
            numberOfAdSets
            description
            status
            editLink
            matchAll
            duplicateLink
            deleteLink
            timeRestriction {
              text
            }
            timeRestrictionStatus
          }
        }
      }
    `,
    [collectionKey]
  );

  return (
    <List
      {...props}
      query={query}
      variables={{ organizationId, campaignSettingId, elementType }}
      type={collectionKey}
      statusCampaignSwitchWarning
    />
  );
};

FBFeatures.propTypes = {
  addDefaultResource: PropTypes.object,
  addResource: PropTypes.object,
  adSetCount: PropTypes.number,
  campaignSettingId: PropTypes.number,
  collectionKey: PropTypes.string,
  elementType: PropTypes.string,
  disableFunel: PropTypes.bool,
  organizationId: PropTypes.number,
  useOnlyProducts: PropTypes.bool,
  updateGraphqlObject: PropTypes.string,
};
export default FBFeatures;
