// eslint-disable jsx-a11y/alt-text
import React from 'react';
import Vector from './Vector';
import { t } from '../../../../i18n';

import { SizePanel, SvgColorsPanel, ConditionsPanel, TimeRestrictionPanel } from '../panels';

export default class Svg extends Vector {
  static panels = [SizePanel, SvgColorsPanel, TimeRestrictionPanel, ConditionsPanel];

  static meta = {
    icon: 'image-upload',
    initial: {
      preserveAspectRatio: 'xMidYMid meet',
      width: 400,
      height: 400,
      flipHorizontally: false,
      flipVertically: false,
      svgColors: {},
      svgContent: '',
    },
  };

  static getDescription = object =>
    t('react.image_generator.default_name.uploaded_image', { filename: object.filename });

  static doNotDisplayInInsertMenu = true;

  render() {
    const { object } = this.props;

    const baseSvg = object.svgContent
      .replace(/<\?xml([^>])+>/, '')
      .replace(/<svg([^>])+>/, '')
      .replace(/<\/\s*svg>/, '');
    const viewBox = object.svgContent.match(/viewBox="([^"]+)"/i);

    const colorizedSvg = Object.keys(object.svgColors).reduce((outputSvg, sourceColor) => {
      if (sourceColor !== object.svgColors[sourceColor]) {
        return outputSvg.replaceAll(sourceColor, object.svgColors[sourceColor]);
      }
      return outputSvg;
    }, baseSvg);

    return (
      <g ref={this.props.onRender} style={this.getTranformStyle(object)}>
        <svg
          {...this.getObjectAttributes()}
          svgContent={null}
          svgColors={null}
          transform={null}
          filename=""
          width={object.width}
          height={object.height}
          viewBox={viewBox ? viewBox[1] : `0 0 ${object.originalWidth} ${object.originalHeight}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: colorizedSvg }}
        />
      </g>
    );
  }
}
