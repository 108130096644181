import React, { useState } from 'react';
import classNames from 'classnames';
import { gql, useQuery, NetworkStatus, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import concatPresence from '../utils/concatPresence';
import {
  Badge,
  Button,
  Col,
  Ellipsis,
  ErrorBox,
  Icon,
  Input,
  Link,
  PageHeading,
  Row,
  SectionHeading,
  SmallHeading,
  Text,
  Tile,
  Select,
} from './index';
import DashboardTile from './dashboard/Tile';
import { formatDate, t } from '../i18n';
import ConditionalWrapper from './ConditionalWrapper';
import { GrayBox } from './table/LoadingTable';
import { Modal, ModalBody, ModalHeader } from './Modal';

export const GET_TRAINING_EVENTS = gql`
  query GetTrainingEvents($limit: Int) {
    trainingEvents(limit: $limit) {
      amIGoing
      attendeesCount
      date
      detail
      eventType
      id
      inPersonLocationAddress
      limit
      locale
      location
      name
      time
    }
  }
`;

const ATTEND_EVENT_MUTATION = gql`
  mutation AttendEvent(
    $trainingEventId: String!
    $email: String!
    $firstName: String!
    $lastName: String!
    $phoneNumber: String!
    $position: String!
    $company: String!
    $companyWeb: String!
    $countryCode: String!
  ) {
    applyForTrainingEvents(
      trainingEventId: $trainingEventId
      email: $email
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phoneNumber
      position: $position
      company: $company
      companyWeb: $companyWeb
      countryCode: $countryCode
    ) {
      status
      message
      errors
      trainingEvent {
        id
        name
        limit
        attendeesCount
        amIGoing
      }
    }
  }
`;

const DetailWithIcon = ({ icon, text, useElipsis = false, withoutIconMargin }) => (
  <Col shrink>
    <Row center>
      <Icon size="16px" color="#596774" kind={icon} className={classNames({ 'mr-8': !withoutIconMargin })} />
      <Text size="sm">
        <ConditionalWrapper condition={useElipsis} wrapper={child => <Ellipsis>{child}</Ellipsis>}>
          {text}
        </ConditionalWrapper>
      </Text>
    </Row>
  </Col>
);
DetailWithIcon.propTypes = {
  icon: PropTypes.string,
  text: PropTypes.string,
  useElipsis: PropTypes.bool,
  withoutIconMargin: PropTypes.bool,
};

const StatusIcon = ({ amIGoing, size = '24px' }) =>
  amIGoing ? (
    <Icon kind="good" size={size} color="#3ED95E" />
  ) : (
    <Icon kind="chevron-right" size={size} color="#CCD0D4" />
  );
StatusIcon.propTypes = { amIGoing: PropTypes.bool, size: PropTypes.string };

const EventTypeBadge = ({ eventType, classnames, ...rest }) => {
  const badge = t(eventType === 'in person' ? 'in_person' : 'online', { scope: 'react.training_events' });
  return (
    <Badge {...rest} uppercase className={classnames}>
      {badge}
    </Badge>
  );
};
EventTypeBadge.propTypes = {
  classnames: PropTypes.string,
  eventType: PropTypes.string,
};

export const DetailWithIconLine = ({
  attendeesCount,
  limit,
  locale,
  time,
  location,
  eventType,
  inPersonLocationAddress,
  withoutIconMargin = false,
}) => {
  const occupation = t('reserved', { scope: 'react.training_events', attendeesCount, limit });
  const lang = locale === 'cs' ? 'czech' : 'english';

  return (
    <React.Fragment>
      <DetailWithIcon text={occupation} icon="chair-thin" withoutIconMargin={withoutIconMargin} />
      <DetailWithIcon
        text={t(`react.training_events.in_${lang}`)}
        icon={`flag-${lang}-thin`}
        withoutIconMargin={withoutIconMargin}
      />
      <DetailWithIcon text={time} icon="clock-thin" withoutIconMargin={withoutIconMargin} />
      {eventType === 'in person' && (
        <DetailWithIcon
          text={concatPresence([inPersonLocationAddress, location], ', ')}
          icon="pin-thin"
          useElipsis
          withoutIconMargin={withoutIconMargin}
        />
      )}
    </React.Fragment>
  );
};
DetailWithIconLine.propTypes = {
  attendeesCount: PropTypes.number,
  eventType: PropTypes.string,
  limit: PropTypes.number,
  locale: PropTypes.string,
  location: PropTypes.string,
  time: PropTypes.string,
  inPersonLocationAddress: PropTypes.string,
  withoutIconMargin: PropTypes.bool,
};

export const TrainingEventItem = hot(({ amIGoing, date, name, id, onClick, ...rest }) => {
  const dayInWeek = formatDate(date, '%a');

  return (
    <Tile inDashboardTile smallSpaced hoverable contentStyle={{ paddingRight: '16px' }}>
      <Row center className="Training" padding="xl" grow onClick={typeof onClick === 'function' && (() => onClick(id))}>
        <div className="Training-date">
          <SmallHeading className="Heading--grape" spacing={0}>
            {dayInWeek}
          </SmallHeading>
          <SectionHeading className="Heading--grape" spacing={0}>
            {formatDate(date, 'short')}
          </SectionHeading>
        </div>

        <Col grow>
          <Row shrink center className="mb-4">
            <SectionHeading color="black" spacing={0}>
              {name}
            </SectionHeading>

            <EventTypeBadge {...rest} size="medium" classnames="ml-8 flex-shrink-0" kind="lightGray" />
          </Row>
          <div className="Training-detail">
            <Row padding="l" shrink>
              <DetailWithIconLine {...rest} withoutIconMargin />
            </Row>
          </div>
        </Col>
        <Col shrink>
          <StatusIcon amIGoing={amIGoing} />
        </Col>
      </Row>
    </Tile>
  );
});
TrainingEventItem.displayName = 'TrainingEventItem';
TrainingEventItem.propTypes = {
  amIGoing: PropTypes.bool,
  date: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
};

export const TrainingEventLoadingItem = hot(() => (
  <Tile inDashboardTile smallSpaced contentStyle={{ paddingRight: '16px' }}>
    <Row className="Training Training--loading" center padding="xl" grow style={{ height: '48px' }}>
      <div className="Training-date">
        <div className="mb-4">
          <GrayBox short />
        </div>
        <GrayBox medium />
      </div>
      <Col grow>
        <Row shrink className="mb-4">
          <SectionHeading color="black" spacing={0}>
            <GrayBox long withoutMargin />
          </SectionHeading>

          <div style={{ paddingTop: '2px' }}>
            <GrayBox short />
          </div>
        </Row>
        <Row padding="l" shrink>
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
          <Col shrink>
            <GrayBox medium withoutMargin />
          </Col>
        </Row>
      </Col>
      <Col shrink>
        <StatusIcon />
      </Col>
    </Row>
  </Tile>
));
TrainingEventLoadingItem.displayName = 'TrainingEventLoadingItem';

const AmIGoingState = ({
  amIGoing,
  id,
  userEmail,
  userFirstName,
  userLastName,
  userCompany,
  userCompanyWeb,
  userPhoneNumber,
  userCountryCode,
  userJobPosition,
  allCountryCodes,
}) => {
  const [email, setEmail] = useState(userEmail || '');
  const [firstName, setfirstName] = useState(userFirstName || '');
  const [lastName, setLastName] = useState(userLastName || '');
  const [company, setCompany] = useState(userCompany || '');
  const [companyWeb, setCompanyWeb] = useState(userCompanyWeb || '');
  const [position, setPosition] = useState(userJobPosition || '');
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || '');
  const [countryCode, setCountryCode] = useState(userCountryCode || '');
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [attend, { loading }] = useMutation(ATTEND_EVENT_MUTATION, {
    variables: {
      trainingEventId: id,
      email,
      firstName,
      lastName,
      phoneNumber,
      position,
      company,
      companyWeb,
      countryCode,
    },
    onError: () => setError(t('react.training_events.server_error')),
    onCompleted: data => {
      const { status, errors, message } = data.applyForTrainingEvents;
      if (!status) {
        setError(errors);
        setErrorMessage(message);
        return;
      }
      setError(null);
    },
  });

  if (amIGoing) {
    return (
      <Row center>
        <Col shrink>
          <StatusIcon amIGoing size="40px" />
        </Col>
        <Col grow>
          <Row>
            <SectionHeading spacing={0}>{t('react.training_events.modal_attended')}</SectionHeading>
          </Row>
          <Row>{t('react.training_events.modal_email_sent')}</Row>
        </Col>
      </Row>
    );
  }

  return (
    <Col padding="l">
      {errorMessage && <ErrorBox className="mb-8">{errorMessage}</ErrorBox>}
      <Row center>
        <Col>
          <Input
            name="training_events[email]"
            value={email}
            onChange={({ target: { value } }) => setEmail(value)}
            doNotUseInternalState
            error={error && error.email}
            label={t('react.training_events.email_label')}
          />
          {error?.email && <span className="ValidationError mt-4">{error.email[0]}</span>}
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            name="training_events[first_name]"
            value={firstName}
            onChange={({ target: { value } }) => setfirstName(value)}
            doNotUseInternalState
            error={error && error.first_name}
            label={t('react.training_events.first_name_label')}
          />
          {error?.first_name && <span className="ValidationError mt-4">{error.first_name[0]}</span>}
        </Col>
        <Col>
          <Input
            name="training_events[last_name]"
            value={lastName}
            onChange={({ target: { value } }) => setLastName(value)}
            doNotUseInternalState
            error={error && error.last_name}
            label={t('react.training_events.last_name_label')}
          />
          {error?.last_name && <span className="ValidationError mt-4">{error.last_name[0]}</span>}
        </Col>
      </Row>

      <Row>
        <Col width="35%">
          {allCountryCodes && (
            <Select
              name="training_events[country_code]"
              value={countryCode}
              onChange={({ target: { value } }) => setCountryCode(value)}
              doNotUseInternalState
              collection={allCountryCodes.map((code, index) => ({
                key: `${code[1]}-${index}`,
                value: code[1],
                label: code[0],
              }))}
              error={error && error.country_code}
              label={t('react.training_events.country_code_label')}
            />
          )}
          {error?.country_code && <span className="ValidationError mt-4">{error.country_code[0]}</span>}
        </Col>
        <Col>
          <Input
            name="training_events[phone_number]"
            value={phoneNumber}
            onChange={({ target: { value } }) => setPhoneNumber(value)}
            doNotUseInternalState
            error={error && error.phone_number}
            label={t('react.training_events.phone_number_label')}
          />
          {error?.phone_number && <span className="ValidationError mt-4">{error.phone_number[0]}</span>}
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            name="training_events[position]"
            value={position}
            onChange={({ target: { value } }) => setPosition(value)}
            doNotUseInternalState
            error={error && error.position}
            label={t('react.training_events.position_label')}
          />
          {error?.position && <span className="ValidationError mt-4">{error.position[0]}</span>}
        </Col>
      </Row>

      <Row>
        <Col>
          <Input
            name="training_events[company]"
            value={company}
            onChange={({ target: { value } }) => setCompany(value)}
            doNotUseInternalState
            error={error && error.company}
            label={t('react.training_events.company_label')}
          />
          {error?.company && <span className="ValidationError mt-4">{error.company[0]}</span>}
        </Col>
        <Col>
          <Input
            name="training_events[company_web]]"
            value={companyWeb}
            onChange={({ target: { value } }) => setCompanyWeb(value)}
            doNotUseInternalState
            error={error && error.company_web}
            label={t('react.training_events.company_web_label')}
          />
          {error?.company_web && <span className="ValidationError mt-4">{error.company_web[0]}</span>}
        </Col>
      </Row>

      <Row>
        <Col shrink>
          <Button primary loading={loading} disabled={loading} loadingType="classic" onClick={attend}>
            {t('react.training_events.modal_attend_button')}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};
AmIGoingState.propTypes = {
  amIGoing: PropTypes.bool,
  id: PropTypes.string,
  userEmail: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userCompany: PropTypes.string,
  userCompanyWeb: PropTypes.string,
  userPhoneNumber: PropTypes.string,
  userCountryCode: PropTypes.string,
  userJobPosition: PropTypes.string,
  allCountryCodes: PropTypes.array,
};

export const TrainingEventsModal = hot(({ date, name, detail, onClose, ...rest }) => (
  <Modal className="Modal--training" onClose={onClose}>
    <ModalHeader centered>
      <Row height="296px" center>
        <Col center>
          <Row>
            <Col className="text-center">
              <SectionHeading uppercase spacing={0} spaced>
                {formatDate(date, '%A')}
              </SectionHeading>
              <SectionHeading uppercase spacing={0} spaced>
                {formatDate(date, 'medium')}
              </SectionHeading>
            </Col>
          </Row>
          <Row>
            <PageHeading className="mt-24">{name}</PageHeading>
          </Row>
          <Row className="mt-24">
            <EventTypeBadge {...rest} kind="lightGray" />
          </Row>
        </Col>
      </Row>
    </ModalHeader>
    <ModalBody>
      <Row className="pt-16">
        <Col grow center className="negative-mh-16 pb-16 delimiter">
          <Row padding="xl">
            <DetailWithIconLine {...rest} />
          </Row>
        </Col>
      </Row>
      <Row className="pb-16" justifyCenter>
        <Col width="480px">
          <SectionHeading>{t('react.training_events.what_to_expect')}</SectionHeading>
          <Text dangerouslySetInnerHTML={{ __html: detail.join('<br/><br/>') }} color="gray" />
        </Col>
      </Row>
      <Row className="pb-32" justifyCenter>
        <Col width="480px">
          <div>
            <AmIGoingState {...rest} />
          </div>
        </Col>
      </Row>
    </ModalBody>
  </Modal>
));
TrainingEventsModal.displayName = 'TrainingEventsModal';
TrainingEventsModal.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string,
  detail: PropTypes.string,
  onClose: PropTypes.func,
};

export const TrainingEventsContent = ({ data, loading, error, ...rest }) => {
  const [selectedEventID, setSelectedEventID] = React.useState(null);

  if (error) {
    return <ErrorBox>{t('react.training_events.error')}</ErrorBox>;
  }

  if (loading) {
    return (
      <Col width="100%" justifyStart>
        {[1, 2, 3].map(x => (
          <Row shrink key={x}>
            <TrainingEventLoadingItem />
          </Row>
        ))}
      </Col>
    );
  }

  const trainingEvents = data?.trainingEvents;
  const selectedEvent = trainingEvents.find(({ id }) => selectedEventID === id) || {};

  return (
    <Col width="100%" justifyStart>
      {trainingEvents.map(x => (
        <Row shrink key={x.id}>
          <TrainingEventItem {...x} onClick={id => setSelectedEventID(id)} />
        </Row>
      ))}
      {selectedEventID && (
        <TrainingEventsModal
          userEmail={rest.userEmail}
          userFirstName={rest.userFirstName}
          userLastName={rest.userLastName}
          userCompany={rest.userCompany}
          userCompanyWeb={rest.userCompanyWeb}
          userPhoneNumber={rest.userPhoneNumber}
          userJobPosition={rest.userJobPosition}
          userCountryCode={rest.userCountryCode}
          allCountryCodes={rest.allCountryCodes}
          {...selectedEvent}
          onClose={() => setSelectedEventID(null)}
        />
      )}
    </Col>
  );
};

TrainingEventsContent.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.any,
};

export const TrainingEvents = hot(({ limit = 3, ...rest }) => {
  const { data, networkStatus, error, refetch } = useQuery(GET_TRAINING_EVENTS, {
    variables: { limit },
  });

  const loading = networkStatus !== NetworkStatus.ready;

  return (
    <DashboardTile
      header={t('react.dashboard.training_events.header')}
      error={error}
      refetch={() => refetch()}
      dashboard
      noData={!loading && (!data || data?.trainingEvents?.length === 0)}
      flexible={!loading && data && data?.trainingEvents?.length > 0}
    >
      <TrainingEventsContent {...rest} data={data} loading={loading} error={error} />

      <Link external className="mt-4" href="https://www.dotidot.io/webinars#section-webinars">
        {t('react.dashboard.training_events.see_more')}
      </Link>
    </DashboardTile>
  );
});
TrainingEvents.displayName = 'TrainingEvents';
