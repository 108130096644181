import React from 'react';
import ReactDOM from 'react-dom';
import { Button } from '../index';
import { t } from '../../i18n';

const EditColumnsLink = ({ editColumnsLink, container }) => {
  if (!editColumnsLink) return null;
  const button = (
    <Button
      secondary
      onClick={e => {
        e.preventDefault();
        window.location.href = editColumnsLink;
      }}
    >
      {t('react.card_view.edit_columns')}
    </Button>
  );

  if (container) return ReactDOM.createPortal(button, container);
  return button;
};

export default EditColumnsLink;
