import Papa from 'papaparse';

const isDimension = ({ name, showFieldKind }) =>
  (showFieldKind !== 'number' && showFieldKind !== 'number-array') || name === 'product_id';

export const generateDimensions = (arr = []) =>
  arr
    ? arr
        .filter(({ showFieldKind, name }) => isDimension({ showFieldKind, name }))
        .reduce((out, { elasticPlaceholderName, name, placeholderName }) => {
          const wordsLabel = `${name} word count`;
          const charLabel = `${name} character count`;
          return [
            ...out,
            { data: { value: elasticPlaceholderName, label: name }, optionValue: name, isMain: true },
            {
              data: { value: `_words.${placeholderName}`, label: wordsLabel, showAsInteger: true },
              optionValue: `_words.${placeholderName}`,
            },
            {
              data: { value: `_counts.${placeholderName}`, label: charLabel, showAsInteger: true },
              optionValue: `_counts.${placeholderName}`,
            },
          ];
        }, [])
    : [];

const getMetric = ({ name, root, prefix, labelSuffix, isMain }) => [
  {
    data: {
      graphQlField: `${prefix}${root}`,
      tableData: [
        { value: `${prefix}${root}_max`, label: [name, labelSuffix, 'max'].join(' '), showAsFloat: true },
        { value: `${prefix}${root}_min`, label: [name, labelSuffix, 'min'].join(' '), showAsFloat: true },
        { value: `${prefix}${root}_avg`, label: [name, labelSuffix, 'avg'].join(' '), showAsFloat: true },
        { value: `${prefix}${root}_sum`, label: [name, labelSuffix, 'sum'].join(' '), showAsFloat: true },
      ],
    },
    label: [name, labelSuffix].join(' '),
    optionValue: `${prefix}${root}`,
    isMain,
  },
  {
    data: {
      graphQlField: `${prefix}${root}`,
      tableData: [{ value: `${prefix}${root}_max`, label: [name, labelSuffix, 'max'].join(' '), showAsFloat: true }],
    },
    label: [name, labelSuffix, 'max'].join(' '),
    optionValue: `${prefix}${root}_max`,
  },
  {
    data: {
      graphQlField: `${prefix}${root}`,
      tableData: [{ value: `${prefix}${root}_min`, label: [name, labelSuffix, 'min'].join(' '), showAsFloat: true }],
    },
    label: [name, labelSuffix, 'min'].join(' '),
    optionValue: `${prefix}${root}_min`,
  },
  {
    data: {
      graphQlField: `${prefix}${root}`,
      tableData: [{ value: `${prefix}${root}_avg`, label: [name, labelSuffix, 'avg'].join(' '), showAsFloat: true }],
    },
    label: [name, labelSuffix, 'average'].join(' '),
    optionValue: `${prefix}${root}_avg`,
  },
  {
    data: {
      graphQlField: `${prefix}${root}`,
      tableData: [{ value: `${prefix}${root}_sum`, label: [name, labelSuffix, 'sum'].join(' '), showAsFloat: true }],
    },
    label: [name, labelSuffix, 'sum'].join(' '),
    optionValue: `${prefix}${root}_sum`,
  },
];
export const generateMetrics = (arr = []) =>
  arr
    ? arr
        .reduce(
          (out, { elasticPlaceholderName, name, showFieldKind, placeholderName }) => [
            ...out,
            ...(!isDimension({ showFieldKind, name })
              ? getMetric({ name, root: elasticPlaceholderName, prefix: '', isMain: true })
              : [
                  ...getMetric({ name, root: placeholderName, prefix: '_words.', labelSuffix: 'word count' }),
                  ...getMetric({
                    name,
                    root: placeholderName,
                    prefix: '_counts.',
                    labelSuffix: 'character count',
                  }),
                ]),
          ],
          []
        )
        .filter(x => !!x)
    : [];

export const generateHeader = (dimensions = [], metrics = []) =>
  [
    ...dimensions,
    ...metrics.map(({ tableData }) => tableData.reduce((out, td) => [...out, td], [])),
    ...[{ value: 'product_count', label: 'product count', showAsInteger: true }],
  ].flat();

export const generateCSV = (header, tableData) =>
  Papa.unparse(
    {
      fields: header.map(({ label }) => label),
      data: tableData.map(rowData => header.map(({ value }) => rowData[value])),
    },
    {}
  );
