import React from 'react';
import PropTypes from 'prop-types';
import { Col, Icon, IconsWrapper, Row, TextWrapper, Tooltip } from '../index';
import Chart from './Chart';
import { t } from '../../i18n';

export const IconWithCircle = ({ kind, color, size = 42, iconSize = '16px', strokeWidth = 5 }) => (
  <div className="pos-relative" style={{ height: '43px' }}>
    <svg height={size} width={size}>
      <circle
        cx={`${size / 2}`}
        cy={`${size / 2}`}
        r={`${size / 2 - strokeWidth + 2}`}
        stroke="rgba(0, 22, 42, 0.1)"
        strokeWidth={`${strokeWidth}`}
        fill="none"
      />
    </svg>
    <Icon kind={kind} size={iconSize} color={color} className="pos-absolute align-center" />
  </div>
);
IconWithCircle.propTypes = {
  color: PropTypes.string,
  iconSize: PropTypes.string,
  kind: PropTypes.string,
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
};

const Box = ({ children, padding = 'l' }) => (
  <Col>
    <Row padding={padding}>{children}</Row>
  </Col>
);
Box.propTypes = { children: PropTypes.array, padding: PropTypes.string };

const COLORS = {
  ok: '#3ED95E',
  warning: '#FFAE42',
  error: '#FF493F',
};

const getColor = (ratio, showColorIndicator) => {
  if (!showColorIndicator || ratio < 0.8) {
    return COLORS.ok;
  }
  if (ratio < 0.9) {
    return COLORS.warning;
  }
  return COLORS.error;
};

const ActiveProductsBox = props => {
  const unUsedCount = props.allItemsCount === 0 && props.count === 0 ? 1 : props.allItemsCount - props.count;
  const colorUsed = getColor((props.count || 0) / (props.allItemsCount || 1), props.showColorIndicator);

  const collection = [
    { kind: 'used_products', count: props.count, color: colorUsed },
    { kind: 'used_products', count: unUsedCount, color: 'rgba(0, 22, 42, 0.1)' },
  ];

  return (
    <Box padding="m">
      {!!(props.allItemsCount && (props.count || props.count === 0)) && (
        <Col shrink>
          <Tooltip text={props.tooltip || t('usage_tooltip', { scope: 'react.feed_export_overview' })}>
            <Chart
              percentual
              collection={collection}
              labelColor={props.showColorIndicator && colorUsed !== COLORS.ok ? colorUsed : undefined}
              hideLabel={props.hideChartLabel}
              {...props}
            />
          </Tooltip>
        </Col>
      )}
      <TextWrapper {...props} />
    </Box>
  );
};
ActiveProductsBox.propTypes = {
  allItemsCount: PropTypes.number,
  count: PropTypes.number,
  showColorIndicator: PropTypes.bool,
  tooltip: PropTypes.string,
  hideChartLabel: PropTypes.bool,
};

const ExportsTypesBox = ({ showModal, ...rest }) => (
  <Box>
    <Col shrink>
      <IconWithCircle {...rest} />
    </Col>
    <Col>
      <IconsWrapper onClick={showModal} {...rest} />
    </Col>
  </Box>
);
ExportsTypesBox.propTypes = { showModal: PropTypes.func };

const ExportStatesBox = ({ showModal, ...rest }) => (
  <Box>
    <Col shrink>
      <div className="pos-relative">
        <Chart {...rest} />
        <Icon className="pos-absolute align-center" size="16px" color="#596774" kind="tag-o" />
      </div>
    </Col>
    <Col>
      <IconsWrapper onClick={showModal} {...rest} />
    </Col>
  </Box>
);
ExportStatesBox.propTypes = { showModal: PropTypes.func };

const ImageEditorBox = ({ showModal, ...rest }) => (
  <Box>
    <Col shrink>
      <IconWithCircle {...rest} />
    </Col>
    <Col>
      <TextWrapper onClick={showModal} {...rest} />
    </Col>
  </Box>
);
ImageEditorBox.propTypes = { count: PropTypes.number, showModal: PropTypes.func };

export { ActiveProductsBox, ExportStatesBox, ExportsTypesBox, ImageEditorBox };
