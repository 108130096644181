import React, { PureComponent } from 'react';
import qs from 'qs';

import OptionsContext from '../../OptionsContext';
import PropertyGroup from './PropertyGroup';
import Columns from './Columns';
import { Switch, Col, Button } from '../../../index';
import Column from './Column';
import InputCol from './InputCol';
import { t } from '../../../../i18n';
import SelectImagePlaceholderModal from './modals/SelectImagePlaceholderModal';
import Ellipsis from '../../../Ellipsis';
import Modal from '../../../../placeholders/Modal';

export const defaultImagePlaceholderUrl = `${window.location.origin}/image_generator/placeholder?field=`;

export const getOptionsFromUrl = url => {
  const params = qs.parse(url.split('?')[1]);
  let placeholder = `${params.field}`;
  let crop = false;
  let magicWand = false;
  let magicWandMode = '';

  if (/CROP_IMAGE/.test(placeholder)) {
    crop = true;
    placeholder = placeholder.replace('CROP_IMAGE', '');
  }
  if (/MAGIC_WAND/.test(placeholder)) {
    magicWand = true;
    const modeMatch = placeholder.match(/MAGIC_WAND(\d?)/);
    magicWandMode = modeMatch ? modeMatch[1] : '';
    placeholder = placeholder.replace(/MAGIC_WAND\d?/, '');
  }

  placeholder = placeholder.replace(/CROP_IMAGE|MAGIC_WAND\d?|,|\||\{|\}|:/g, '');

  return { placeholder, crop, magicWand, magicWandMode };
};

export const buildUrl = ({ placeholder, crop, magicWand, magicWandMode }) => {
  const options = [];
  if (crop) {
    options.push('CROP_IMAGE');
  }
  if (magicWand) {
    options.push(`MAGIC_WAND${magicWandMode || ''}`);
  }

  return `${defaultImagePlaceholderUrl}{{${options.join(',')}:${placeholder}}}`;
};

export class ImagePlaceholderPanel extends PureComponent {
  state = {
    showModal: false,
    showEditModal: false,
  };

  handlePlaceholderChange = e => {
    const value = e.target.value;
    const { crop, magicWand, magicWandMode } = getOptionsFromUrl(this.props.object.xlinkHref);

    this.props.onChange('xlinkHref', buildUrl({ placeholder: value, crop, magicWand, magicWandMode }));
  };

  handleCropChange = e => {
    const value = e.target.value;
    const { placeholder, magicWand, magicWandMode } = getOptionsFromUrl(this.props.object.xlinkHref);

    this.props.onChange('xlinkHref', buildUrl({ placeholder, crop: value, magicWand, magicWandMode }));
  };

  handleMagicWandChange = e => {
    const value = e.target.value;
    const { placeholder, crop, magicWandMode } = getOptionsFromUrl(this.props.object.xlinkHref);

    this.props.onChange('xlinkHref', buildUrl({ placeholder, crop, magicWand: value, magicWandMode }));
  };

  handleMagicWandModeChange = (_, value) => {
    const { placeholder, crop } = getOptionsFromUrl(this.props.object.xlinkHref);

    this.props.onChange('xlinkHref', buildUrl({ placeholder, crop, magicWand: true, magicWandMode: value }));
  };

  handleOptionalLayerChange = e => {
    const value = e.target.value;
    this.props.onChange('optionalLayer', !value);
  };

  render() {
    const { object, onChange } = this.props;

    const { showModal, showEditModal } = this.state;

    const { crop, magicWand, magicWandMode, placeholder } = getOptionsFromUrl(object.xlinkHref);
    const optionalLayer = !!object.optionalLayer;

    return (
      <OptionsContext.Consumer>
        {({ customImages, imageUrlFields }) => {
          const customImage = customImages.find(ci => `_${ci.placeholderName}_` === placeholder);
          const isCustomImage = !!customImage;
          const imageName = imageUrlFields.filter(image => image.value === placeholder)[0]?.name;

          return (
            <PropertyGroup>
              <Col padding="l">
                <Columns label={t('react.image_generator.label.image')} withoutLabelPadding>
                  <Col className="mr-8" style={{ maxWidth: '240px' }}>
                    <Ellipsis>{imageName}</Ellipsis>
                  </Col>
                  {isCustomImage && customImage.editLink && (
                    <Col shrink>
                      <Button tertiary onlyIcon icon="edit" onClick={() => this.setState({ showEditModal: true })} />
                    </Col>
                  )}
                  <Column shrink>
                    <Button onClick={() => this.setState({ showModal: !showModal })} secondary>
                      {t('react.image_generator.label.change_image')}
                    </Button>
                    {showModal && (
                      <SelectImagePlaceholderModal
                        onSelect={variable => {
                          onChange('xlinkHref', buildUrl({ placeholder: variable, crop, magicWand }));
                          this.setState({ showModal: false });
                        }}
                        onClose={() => this.setState({ showModal: false })}
                      />
                    )}
                  </Column>
                </Columns>
                {!isCustomImage && (
                  <Columns
                    label={t('react.image_generator.label.required_image')}
                    withoutLabelPadding
                    tooltip={t('react.image_generator.hint.required_image', { imageName })}
                  >
                    <Column>
                      <Switch doNotUseInternalState value={!optionalLayer} onChange={this.handleOptionalLayerChange} />
                    </Column>
                  </Columns>
                )}
                {!isCustomImage && (
                  <Columns
                    label={t('react.image_generator.label.remove_background')}
                    withoutLabelPadding
                    delimiter={!magicWand}
                    tooltip="We use an AI-based library to remove the background from your images. For better performance, please use a contrasting background."
                    badge="BETA"
                  >
                    <Column>
                      <Switch doNotUseInternalState value={magicWand} onChange={this.handleMagicWandChange} />
                    </Column>
                  </Columns>
                )}
                {!isCustomImage && magicWand && (
                  <Columns
                    label={t('react.image_generator.label.remove_background_mode', {
                      default: 'Remove background mode',
                    })}
                    withoutLabelPadding
                    delimiter
                    tooltip={t('react.image_generator.hint.remove_background_mode', {
                      default:
                        'Mode A and B works little bit differently so try mode A and if you experience not great results try mode B',
                    })}
                  >
                    <InputCol
                      type="select"
                      collection={[{ value: '', label: 'Mode A' }, { value: '2', label: 'Mode B' }]}
                      value={magicWandMode}
                      property="magicWandMode"
                      onChange={this.handleMagicWandModeChange}
                    />
                  </Columns>
                )}
                {!isCustomImage && (
                  <Columns label={t('react.image_generator.label.trim_image')} withoutLabelPadding>
                    <Column>
                      <Switch doNotUseInternalState value={crop} onChange={this.handleCropChange} />
                    </Column>
                  </Columns>
                )}

                {showEditModal && customImage.editLink && (
                  <Modal
                    onClose={() => {
                      this.setState({ showEditModal: false });
                    }}
                    url={customImage.editLink}
                  />
                )}
              </Col>
            </PropertyGroup>
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}
