window.addEventListener('load', () => {
  const languagePicker = $('.js-language-picker');
  const languageMenu = $('.js-language-picker span.LanguagePicker-menu');

  if (languagePicker.length > 0) {
    languagePicker.on('mouseenter click', () => {
      languageMenu.addClass('active');
    });
    languagePicker.on('mouseleave', () => {
      languageMenu.removeClass('active');
    });
  }
});
