import React from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { Row, Col, Select, Button, Input, Tile, SegmentedButton, GridOverflow } from '../index';
import { t } from '../../i18n';
import { TAGS, HREF, SRC, CONTENT } from './constants';
import { innerText, getNumberFromString, isValidSelector } from './utils';
import { ElementsContainer, OutputContent } from './ElementsContainer';
import { SegmentedStepTile, ListWithButtonsTile, BasicTile } from './StepTile';

const isSelected = (select_index, index, arr) => {
  if (select_index === '' || select_index === 'count') return true;
  const parseSelectedIndex = parseInt(select_index, 10);
  if (parseSelectedIndex === index) return true;
  if (arr.length + parseSelectedIndex === index) return true;
  return false;
};

const getOutputData = ({ elements, value_source, value_type, select_index, isInputSelected }) =>
  Array.from(elements)
    .filter(el => {
      if (value_source === HREF && el?.href) return true;
      if (value_source === SRC && el?.src) return true;
      if (value_source === CONTENT) {
        const text = isInputSelected ? el.value : innerText(el);
        if (value_type === 'string' && text) {
          return true;
        }
        if (value_type === 'number' && getNumberFromString(text)) {
          return true;
        }
      }
      return false;
    })
    .filter((el, index, arr) => isSelected(select_index, index, arr));

const PreviewPanelLogic = ({ setWantedElement, webpageRef, options, setFinalSelector, setOptions, error, ...rest }) => {
  if (error) return <PreviewPanelRender isSelectorValid={() => {}} options={{ value_source: 'content' }} />;

  const querySelectorAll = selector => webpageRef.current?.ifrm.contentWindow.document.querySelectorAll(selector);
  const isSelectorValid = selector => isValidSelector(webpageRef?.current?.ifrm.contentWindow.document, selector);

  const handleChange = (value, key, func) => {
    if (typeof func === 'function') func();
    setOptions({ ...options, [key]: value });
  };

  const handleDeselect = () => {
    setFinalSelector('');
    setWantedElement(null);
  };

  const handleMouseOver = (e, el) => {
    webpageRef.current.destroyHighlight('.ppcbee--highlight-onlyOne');

    el.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
    e.target.closest('.element-row').style.backgroundColor = 'rgba(0,0,100,0.2)';

    webpageRef.current.createHighlight(el, 'rgba(0,0,100,0.5)', true);
  };

  const handleMouseOut = e => {
    webpageRef.current.destroyHighlight('.ppcbee--highlight-onlyOne');
    e.target.closest('.element-row').style.backgroundColor = 'unset';
  };

  return (
    <PreviewPanelRender
      handleChange={handleChange}
      options={options}
      handleDeselect={handleDeselect}
      setFinalSelector={setFinalSelector}
      setOptions={setOptions}
      handleMouseOut={handleMouseOut}
      handleMouseOver={handleMouseOver}
      isSelectorValid={isSelectorValid}
      querySelectorAll={querySelectorAll}
      {...rest}
    />
  );
};

const PreviewPanelRender = ({
  actionButtons,
  finalSelector,
  handleDeselect,
  handleChange,
  handleMouseOut,
  handleMouseOver,
  isInputSelected,
  isSelectorValid,
  options,
  querySelectorAll,
  scraperOptions,
  setFinalSelector,
  setOptions,
  wantedElement,
}) => (
  <GridOverflow>
    <Row>
      <Col className="pt-8 pb-8">
        <Row shrink center>
          <SegmentedStepTile
            step="1"
            fullWidth
            componentKey="type_of_selection"
            shouldShow
            onChange={({ target: { value } }) => handleChange(value, 'value_source', () => handleDeselect())}
            value={options?.value_source}
            collection={TAGS.map(x => ({ value: x, label: t(x, { scope: 'react.scraper.value_source' }) }))}
          />
        </Row>

        <Row shrink center>
          <BasicTile shouldShow={false} step="2" />
        </Row>

        <Row shrink={!wantedElement} height={wantedElement ? '240px' : 'auto'} padding="l">
          <ListWithButtonsTile
            componentKey="wanted_element"
            step="3"
            shouldShow={!!wantedElement}
            buttons={[
              {
                kind: 'secondary',
                label: t('react.scraper.deselect'),
                onClick: handleDeselect,
                'data-test-id': 'scraper-remove-wanted-element',
              },
            ]}
          >
            <ElementsContainer
              elements={wantedElement}
              value_source={options?.value_source}
              isInputSelected={isInputSelected}
              classNames="delimiter pb-8"
              heading={t('react.scraper.selected_object')}
            />
            <ElementsContainer
              elements={wantedElement}
              heading={t('react.scraper.css_selector')}
              kind="input"
              classNames="delimiter pb-8"
              finalSelector={finalSelector}
              setFinalSelector={setFinalSelector}
            />
            {isSelectorValid(finalSelector) && (
              <ElementsContainer
                elements={Array.from(querySelectorAll(finalSelector))}
                heading={t('react.scraper.objects_with_same_selector')}
                finalSelector={finalSelector}
                handleMouseOver={handleMouseOver}
                handleMouseOut={handleMouseOut}
                value_source={options?.value_source}
              />
            )}
          </ListWithButtonsTile>
        </Row>

        <Row shrink>
          <BasicTile shouldShow={!!wantedElement} step="4">
            <Row shrink>
              <Input
                id="scraper-placeholder_name"
                name="scraper[placeholder_name]"
                label={t('react.scraper.name_of_variable')}
                doNotUseInternalState
                value={options?.placeholder_name}
                onChange={({ target: { value } }) => handleChange(value, 'placeholder_name')}
                placeholder={t('react.scraper.new_var')}
              />
            </Row>
            <Row shrink center>
              <Col shrink className="Text--gray">
                {t('react.scraper_form.save', { default: 'Save' })}
              </Col>
              <Col shrink>
                <Select
                  value={options?.select_index}
                  doNotUseInternalState
                  name="scraper[advance-selection]"
                  id="scraper_advance_selection"
                  onChange={({ target: { value } }) => {
                    let value_type = options?.value_type;
                    if (value === 'count') value_type = 'string';
                    setOptions({ ...options, select_index: value, value_type });
                  }}
                >
                  {scraperOptions?.select_index_types.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.key}
                    </option>
                  ))}
                </Select>
              </Col>
            </Row>
            {options?.select_index !== 'count' && options?.value_source === CONTENT && (
              <Row center>
                <Col shrink className="Text--gray">
                  {t('react.scraper_form.of_found_objects_as', {
                    default: 'of found objects as a',
                  })}
                </Col>
                <Col grow>
                  <SegmentedButton
                    value={options?.value_type}
                    name="scraperSelector[value_type]"
                    id="scraper-selector-value-type"
                    doNotUseInternalState
                    onChange={({ target: { value } }) => handleChange(value, 'value_type')}
                    collection={scraperOptions?.value_types.map(({ value, key }) => ({
                      value,
                      label: t(key, { scope: 'react.scraper.value_types' }),
                    }))}
                  />
                </Col>
              </Row>
            )}
          </BasicTile>
        </Row>

        <Row shrink={!wantedElement} height={wantedElement ? '200px' : 'auto'} padding="l">
          <ListWithButtonsTile
            componentKey="output"
            step="5"
            shouldShow={!!wantedElement && isSelectorValid(finalSelector)}
          >
            {isSelectorValid(finalSelector) && (
              <OutputContent
                isInputSelected={isInputSelected}
                {...options}
                elements={getOutputData({
                  ...options,
                  isInputSelected,
                  elements: querySelectorAll(finalSelector),
                })}
              />
            )}
          </ListWithButtonsTile>
        </Row>

        <Row shrink className="mt-8 pb-32 NewLayout-actionBar js-newLayout-actionBar js-disableButtonOnClick">
          <Tile>
            {actionButtons && actionButtons.length > 0 && (
              <Row>
                {actionButtons.map(
                  ({ label, onClick, ...rest }) =>
                    typeof onClick === 'function' && (
                      <Button {...rest} key={label} onClick={onClick}>
                        {label}
                      </Button>
                    )
                )}
              </Row>
            )}
          </Tile>
        </Row>
      </Col>
    </Row>
  </GridOverflow>
);

PreviewPanelLogic.propTypes = {
  error: PropTypes.bool,
  options: PropTypes.object,
  setFinalSelector: PropTypes.func,
  setOptions: PropTypes.func,
  setWantedElement: PropTypes.func,
  webpageRef: PropTypes.object,
};
PreviewPanelRender.propTypes = {
  actionButtons: PropTypes.array,
  finalSelector: PropTypes.string,
  handleDeselect: PropTypes.func,
  handleChange: PropTypes.func,
  handleMouseOut: PropTypes.func,
  handleMouseOver: PropTypes.func,
  isInputSelected: PropTypes.bool,
  isSelectorValid: PropTypes.func,
  options: PropTypes.object,
  querySelectorAll: PropTypes.func,
  scraperOptions: PropTypes.object,
  setFinalSelector: PropTypes.func,
  setOptions: PropTypes.func,
  setWantedElement: PropTypes.func,
  wantedElement: PropTypes.object,
  webpageRef: PropTypes.object,
};

export default hot(PreviewPanelLogic);
