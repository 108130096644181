/* eslint-disable import/prefer-default-export */
export const setUpAPICalls = (organizationId, subAccountAccessId, csrfToken) => (service, operations, method = 'get') =>
  window
    .fetch(`/api_frontend/organizations/${organizationId}/${subAccountAccessId}/api`, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrfToken,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ method, service, operations }),
    })
    .then(response => response.json())
    .then(response => {
      if (response.status === 'error') {
        const error = new Error(response.message);
        error.status = response.status;
        error.detail = response.detail;

        throw error;
      }
      return response;
    });
