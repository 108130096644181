/* eslint-disable */

import PropTypes from 'prop-types';
import React, { PureComponent, useState } from 'react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import Tile from './Tile';
import { Icon, Button, Col } from '../index';
import { t } from '../../i18n';
import RequestModal from '../insights/RequestModal';
import storage from '../../utils/storage';

const QUERY_DASHBOARD_INSIGHTS_QUERY = gql`
  query DashboardOrganizationNotifications($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      insights {
        id
      }
    }
  }
`;

// const QUERY_DASHBOARD_INSIGHTS_CAMPAIGN_QUERY = gql`
//   query DashboardOrganizationCampaignSettingNotifications($organizationId: BigInt!, $campaignSettingId: BigInt!) {
//     organization(id: $organizationId) {
//       id
//       campaignSetting(id: $campaignSettingId) {
//         id
//         insight
//       }
//     }
//   }
// `;

export const NoMoreInsights = ({ organizationId, ...rest }) => {
  const key = 'custom_insights_requested';
  const [showModal, setModal] = useState(false);
  const [requested, setRequested] = useState(storage.get(key));

  const onRequested = () => {
    setRequested(true);
    storage.set(key, true);
  };

  return (
    <Col center justifyBetween>
      <Icon kind="insights" color="#ffcd00" size="80px" className="line-height-1" />
      <div className="text-center">
        {requested ? t('react.dashboard.insights.requested') : t('react.dashboard.insights.insights_description')}
      </div>
      <Button secondary onClick={() => setModal(true)} data-test-id="request-insights-button">
        {requested ? t('react.dashboard.insights.request_button_again') : t('react.dashboard.insights.request_button')}
      </Button>

      {showModal && (
        <RequestModal
          organizationId={organizationId}
          onRequested={onRequested}
          testId="request-insights-modal"
          onClose={() => setModal(false)}
          {...rest}
        />
      )}
    </Col>
  );
};

export const InsightsComponent = ({ error, data, loading, insights, organizationId, ...rest }) => {
  return (
    <Tile header={t('react.dashboard.insights.header')} dashboard loading={loading} error={error}>
      {insights !== undefined &&
        (insights.length == 0 ? <NoMoreInsights organizationId={organizationId} {...rest} /> : <span>--</span>)}

      {insights === undefined && <span>--</span>}
    </Tile>
  );
};

export const InsightsDashboard = ({ organizationId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_INSIGHTS_QUERY}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
    }}
  >
    {({ error, data, loading }) => (
      <InsightsComponent
        loading={loading}
        error={error}
        organizationId={organizationId}
        insights={data && data.organization && data.organization.insights}
        {...rest}
      />
    )}
  </Query>
);

InsightsDashboard.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  requestDataOptions: PropTypes.array.isRequired,
  requestActionsOptions: PropTypes.array.isRequired,
};
