/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import { snakeCase, findKey, pickBy } from 'lodash';
import { setupColumn } from '../setupColumn';
import { PlaceholderCell, AdtextLineCell, SwitchCell, AdtextLineWithMainSwitcherCell } from '../cells';
import { t } from '../../../../i18n';
import { PlaceholderEdit, AdtextLineEdit, AdtextLineSwitcherEdit } from '../editable/index';
import { getGqlAttributeText } from '../../../../graphql_provider';

const CATEGORY_TYPE_PAIRING = {
  ad_performance_adtext: 'PerformanceMaxAdtext',
  ad_responsive_adtext: 'ResponsiveAdtext',
  ad_display_responsive_adtext: 'DisplayResponsiveAdtext',
  ad_expanded_adtext: 'ExpandedAdtext',
  ad_dsa_adtext: 'DSAAdtext',
};

const transformData = fieldsObject => ({
  mainSwitcher: {
    key: findKey(fieldsObject, field => field?.role === 'main'),
    label: pickBy(fieldsObject, field => field?.role === 'main')[findKey(fieldsObject, field => field?.role === 'main')]
      .label,
  },
  colorPickers: Object.keys(pickBy(fieldsObject, field => field?.role === 'color')).map(k => ({
    key: k,
    label: fieldsObject[k]?.label,
  })),
  switchers: Object.keys(pickBy(fieldsObject, field => field?.role === 'switcher')).map(k => ({
    key: k,
    label: fieldsObject[k]?.label,
  })),
  texts: Object.keys(pickBy(fieldsObject, field => field?.role === 'text')).map(k => ({
    key: k,
    label: fieldsObject[k]?.label,
    notEditable: fieldsObject[k]?.notEditable,
  })),
});

export const createAdtextFieldText = category => ({ field, updateField }) =>
  setupColumn({
    id: field,
    category,
    graphqlFields: { adtexts: [field, `${field}Pretty`, { campaignSetting: ['serializedPlaceholders'] }] },
    header: () => t(`attributes.${snakeCase(field)}`),
    cell: PlaceholderCell,
    editable: PlaceholderEdit,
    editQuery: data =>
      typeof data.id === 'string' &&
      !data?.isParent &&
      CATEGORY_TYPE_PAIRING[category] === data?.type &&
      (newData => gql`
        mutation {
          updateBaseAdtext(id: ${data.id}, ${updateField}: ${getGqlAttributeText(newData?.text)}) {
            baseAdtext { id ${field} ${field}Pretty previewJsonData itemsCount}
            baseAdtexts { id itemsCount }
          }
        }
        `),
    resolveChildren: data => {
      const text = data[field];
      const prettyText = data[`${field}Pretty`];

      return text
        ? { text, prettyText, placeholders: JSON.parse(data.campaignSetting?.serializedPlaceholders || '[]') }
        : null;
    },
  });

export const createAdtextFieldOverflow = category => ({ field, updateField }) =>
  setupColumn({
    id: field,
    category,
    graphqlFields: { adtexts: [field] },
    header: () => t(`attributes.${snakeCase(field)}`),
    cell: SwitchCell,
    cellCouldBeEmpty: true,
    editQuery: data =>
      typeof data.id === 'string' &&
      !data?.isParent &&
      CATEGORY_TYPE_PAIRING[category] === data?.type &&
      (children =>
        gql`
      mutation {
        updateBaseAdtext(id: ${data.id}, ${updateField}: ${children}) {
          baseAdtext { id ${field} itemsCount}
          baseAdtexts { id itemsCount }
        }
      }
      `),
    resolveChildren: data => {
      const value = data[field];
      return typeof value === 'boolean' ? value : null;
    },
  });

export const createAdtextDynamicOptionField = category => ({ heading, fieldsObject, editQuery }) =>
  setupColumn({
    id: heading,
    category,
    graphqlFields: { adtexts: Object.keys(fieldsObject) },
    header: () => t(`attributes.${snakeCase(heading)}`),
    cell: AdtextLineWithMainSwitcherCell,
    cellCouldBeEmpty: true,
    editable: AdtextLineSwitcherEdit,
    editQuery,
    resolveChildren: data =>
      data.type === 'DisplayResponsiveAdtext'
        ? {
            ...transformData(fieldsObject),
            ...data,
          }
        : null,
  });

export const createAdtextLineText = category => (field, additionalProps = {}) =>
  setupColumn({
    id: field,
    category,
    graphqlFields: {
      adtexts: {
        [field]: ['text', 'prettyText', 'pinned', 'overflow'],
        campaignSetting: ['serializedPlaceholders'],
      },
    },
    header: () => t(`attributes.${snakeCase(field)}`),
    cell: AdtextLineCell,
    editable: AdtextLineEdit,
    editQuery: data =>
      data &&
      typeof data.id === 'string' &&
      !data?.isParent &&
      CATEGORY_TYPE_PAIRING[category] === data?.type &&
      data[field]?.id &&
      (newData => gql`
          mutation {
            updateAdtextLine(id: ${data[field].id}, text: ${getGqlAttributeText(
        newData?.text
      )}, pinned: ${getGqlAttributeText(newData?.pinned)}, overflow: ${!!newData?.overflow}) {
              adtextLine {
                id
                text
                prettyText
                pinned
                overflow
              }
              baseAdtexts {
                id
                itemsCount
                previewJsonData
              }
            }
          }
        `),
    resolveChildren: data => {
      const lineObj = (data || {})[field];

      return lineObj
        ? {
            ...additionalProps,
            ...lineObj,
            placeholders: JSON.parse(data.campaignSetting?.serializedPlaceholders || '[]'),
          }
        : null;
    },
  });
export const createAdtextFieldTextFromObject = category => (field, additionalProps = {}) =>
  setupColumn({
    id: field,
    category,
    graphqlFields: {
      adtexts: { [field]: ['text', 'prettyText'], campaignSetting: ['serializedPlaceholders'] },
    },
    header: () => t(`attributes.${snakeCase(field)}`),
    cell: PlaceholderCell,
    editable: PlaceholderEdit,
    editQuery: data =>
      typeof data.id === 'string' &&
      !data?.isParent &&
      CATEGORY_TYPE_PAIRING[category] === data?.type &&
      data[field]?.id &&
      (newData => gql`
            mutation {
              updateAdtextLine(id: ${data[field].id}, text: ${getGqlAttributeText(newData?.text)}) {
                adtextLine {
                  id
                  text
                  prettyText
                }
                baseAdtexts {
                  id
                  itemsCount
                  previewJsonData
                }
              }
            }
          `),
    resolveChildren: data => {
      const lineObj = data[field];

      return lineObj
        ? {
            ...additionalProps,
            ...lineObj,
            placeholders: JSON.parse(data.campaignSetting?.serializedPlaceholders || '[]'),
          }
        : null;
    },
  });
