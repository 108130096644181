const resolveSystemObject = (row = {}, system) => {
  const result = {
    system,
    link: row[`${system}Link`],
    enabled: row[`${system}Enabled`],
    status: row[`${system}Status`],
    systemId: row[`${system}Setting`] && row[`${system}Setting`]?.systemId,
    syncError: row[`${system}SynchronizationError`],
    timeRestrictionStatus: row[`${system}Setting`]?.timeRestrictionStatus,
  };
  result.hasInvalidLinkedAccount = !!(!result.enabled && row.generated && row.synced && result.systemId);
  result.link = result.hasInvalidLinkedAccount && row.accountAccessLink ? row.accountAccessLink : result.link;
  result.hasError = !!result.syncError || result.hasInvalidLinkedAccount;

  return result;
};
export default resolveSystemObject;
