import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import uuid from 'uuid';
import { Icon, cssVariables } from '../index';

export default class Switch extends PureComponent {
  static propTypes = {
    doNotUseInternalState: PropTypes.bool,
    className: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    pausePlay: PropTypes.bool,
    visibility: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    timeRestriction: PropTypes.oneOf([null, 'active', 'restricted']),
    value: PropTypes.any.isRequired,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    required: false,
  };

  state = {
    value: this.props.value,
  };

  handleChange = e => {
    const {
      target: { checked },
    } = e;

    this.setState({ value: checked });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: checked } });
    }
  };

  render() {
    const {
      className,
      value,
      name,
      pausePlay,
      visibility,
      id,
      disabled,
      label,
      required,
      doNotUseInternalState,
      timeRestriction,
      loading,
    } = this.props;

    const selected = doNotUseInternalState ? value : this.state.value;

    const inputId = `${id || name}-input-id-${uuid()}`;

    return (
      <div
        className={cs(className, 'form-group', 'inline-switch', 'switch', 'pos-relative', {
          required,
          disabled: !!disabled,
          'Loader--statusSwitch': loading,
        })}
        data-test-id={`switch-wrapper-${name}`}
        data-status-value={disabled ? 'disabled' : `selected-${selected}`}
      >
        <input disabled={!!disabled && 'disabled'} name={name} type="hidden" value="0" />

        {(timeRestriction === 'restricted' || timeRestriction === 'active') && selected && (
          <Icon
            kind="clock"
            size="16px"
            className="mr-4 vam"
            color={
              timeRestriction === 'restricted' ? cssVariables.statusWarningDefault : cssVariables.statusPositiveDefault
            }
          />
        )}

        {visibility && !selected && (
          <Icon kind="disabled-eye" size="16px" className="mr-4 vam" color={cssVariables.interactiveFormGhost} />
        )}

        <input
          className={cs(
            'form-control',
            'switch',
            'switch-input',
            { 'switch-input--icons': pausePlay || visibility },
            { 'switch-input--iconsNotGeneratingRestricted': timeRestriction === 'restricted' },
            { 'switch-input--iconsRestricted': timeRestriction === 'active' },
            { 'switch-input--iconsVisibility': visibility }
          )}
          type="checkbox"
          value="1"
          checked={selected}
          disabled={!!disabled && 'disabled'}
          name={name}
          id={inputId}
          onChange={this.handleChange}
        />
        <label className="switch-label switch" htmlFor={inputId} data-test-id="switch-input">
          <span className="wrapper">
            <span className="moving-box">
              <span className="off" />
              <span className="on" />
            </span>
            <span className="circle" />
          </span>
        </label>

        {!!label && (
          <label className="control-label switch" htmlFor={inputId}>
            {label}
          </label>
        )}

        {loading && <div className={cs('Loader Loader--small active', { 'Loader--right': selected })} />}
      </div>
    );
  }
}
