/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { QueryHolder } from '../components/QueryHolder';
import { Modal, ModalBody } from '../components/Modal';
import ProductPreviewComponent from '../components/data_view_layout/ProductPreviewComponent';
import ProductsView from '../components/data_view_layout/ProductsView';
import TranformationModal from './TransformationModal';

const QUERY_DATA_INPUT = gql`
  query DataSourceItemPreview($organizationId: BigInt!, $dataSourceId: BigInt!, $inputId: String!) {
    organization(id: $organizationId) {
      id
      name
      dataSource(id: $dataSourceId) {
        id
        cardImage
        name
        url
        item(id: $inputId) {
          data
          status
        }
        tableVariablesSorted {
          name
          placeholderName
          showFieldKind
          isImageUrl
          imagesCount
        }
      }
    }
  }
`;

export const PreviewInputDataComponent = ({ data }) => {
  if (!data.organization || !data.organization.dataSource || !data.organization.dataSource.tableVariablesSorted) {
    return 'Not loaded';
  }

  const item = JSON.parse(data.organization.dataSource.item.data);
  item.__status = data.organization.dataSource.item.status;

  return (
    <ProductPreviewComponent
      cardImage={
        data.organization.dataSource.cardImage ||
        `${(data.organization.dataSource.tableVariablesSorted.find(tv => tv.isImageUrl) || {}).placeholderName ||
          'not_existing'}#0`
      }
      tableVariablesSorted={data.organization.dataSource.tableVariablesSorted}
      item={item}
    />
  );
};
PreviewInputDataComponent.propTypes = { data: PropTypes.any };

const PreviewInputData = QueryHolder(PreviewInputDataComponent);

class InputDataModal extends React.PureComponent {
  static propTypes = {
    organizationId: PropTypes.string,
    dataSourceId: PropTypes.string,
    campaignSettingId: PropTypes.string,
    inputId: PropTypes.string,
    variableToTransform: PropTypes.string,
    conditions: PropTypes.object,
    elasticQuery: PropTypes.string,
    onClose: PropTypes.function,
  };

  static defaultProps = {
    conditions: [],
  };

  render() {
    const {
      organizationId,
      conditions,
      elasticQuery,
      dataSourceId,
      campaignSettingId,
      inputId,
      variableToTransform,
      onClose,
    } = this.props;
    if (inputId) {
      return (
        <Modal testId="products-preview-modal" onClose={onClose} heading={window.reacti18n.data_input_preview.heading}>
          <ModalBody>
            <PreviewInputData
              query={QUERY_DATA_INPUT}
              loaderSize="big"
              variables={{
                organizationId: parseInt(organizationId, 10),
                dataSourceId: parseInt(dataSourceId, 10),
                inputId,
              }}
            />
          </ModalBody>
        </Modal>
      );
    }
    if (variableToTransform) {
      return (
        <Modal
          testId="products-preview-modal"
          size="full"
          onClose={onClose}
          heading={`Variable rule transformations for: ${variableToTransform}`}
        >
          <ModalBody classNames="Modal-body--dynamicHeight">
            <TranformationModal
              organizationId={parseInt(organizationId, 10)}
              dataSourceId={parseInt(dataSourceId, 10)}
              variableToTransform={variableToTransform}
            />
          </ModalBody>
        </Modal>
      );
    }

    return (
      <Modal
        testId="products-preview-modal"
        onClose={onClose}
        size="full"
        heading={window.reacti18n.data_input_preview.heading}
      >
        <ModalBody>
          <ProductsView
            loaderSize="big"
            tableProps={{
              className: 'Grid--overflowWrapper--withOverflowShadow',
              style: { maxHeight: 'calc(100vh - 230px)', overflow: 'auto' },
              notScrollable: true,
            }}
            variables={{
              organizationId: parseInt(organizationId, 10),
              dataSourceId: dataSourceId ? parseInt(dataSourceId, 10) : null,
              campaignSettingId: campaignSettingId ? parseInt(campaignSettingId, 10) : null,
              parentConditions: conditions,
              elasticQuery,
            }}
          />
        </ModalBody>
      </Modal>
    );
  }
}

export default InputDataModal;
