import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

export const useAccordion = ({ callback, forceOpen = false }) => {
  const [showDetail, setShowDetail] = useState(forceOpen);

  const handleClick = () => {
    if (typeof callback === 'function') callback(showDetail);
    setShowDetail(!showDetail);
  };

  const Accordion = ({
    headerComponent,
    children,
    onlyClickOnChevron,
    hideChevron = false,
    className,
    contentClass,
    bigHeader = true,
    ...rest
  }) => (
    <div
      className={cs('Accordion', 'Accordion--hoverLikeLink', className, {
        active: showDetail,
        'Accordion--bigHeader': bigHeader,
      })}
    >
      <div className="Accordion-title pa-0 pr-16" {...rest} onClick={onlyClickOnChevron ? undefined : handleClick}>
        {headerComponent}
        {!hideChevron && (
          <i className="Accordion-chevron expand-clickable" onClick={onlyClickOnChevron ? handleClick : undefined} />
        )}
      </div>
      <div className={cs('Accordion-content', contentClass)}>{children}</div>
    </div>
  );

  Accordion.propTypes = {
    headerComponent: PropTypes.any,
    children: PropTypes.any,
    onlyClickOnChevron: PropTypes.bool,
    hideChevron: PropTypes.bool,
    className: PropTypes.string,
    contentClass: PropTypes.string,
    bigHeader: PropTypes.bool,
  };

  return [showDetail, Accordion, setShowDetail];
};

const Accordion = ({
  header,
  className,
  classNameTitle,
  classNameContent,
  children,
  noDelimiter,
  forceOpen = false,
  isDisabledToggle,
}) => {
  const [showDetail, setShowDetail] = useState(forceOpen);
  return (
    <div
      className={cs('Accordion', 'Accordion--hovered', className, {
        'Accordion--noDelimiter': noDelimiter,
        active: showDetail,
      })}
    >
      <div
        className={cs('Accordion-title', classNameTitle)}
        onClick={isDisabledToggle ? undefined : () => setShowDetail(!showDetail)}
      >
        <span className="Text--semibold">{header}</span>
        {!isDisabledToggle && <i className="Accordion-chevron" />}
      </div>
      <div className={cs('Accordion-content', classNameContent)}>{children}</div>
    </div>
  );
};

Accordion.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.any,
  noDelimiter: PropTypes.bool,
  forceOpen: PropTypes.bool,
  isDisabledToggle: PropTypes.bool,
  className: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameContent: PropTypes.string,
};

export default Accordion;
