/* eslint-disable no-undef */
import React, { useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Text } from '../index';
import FieldKindIcon from '../FieldKindIcon';

const VariableNavigation = ({ transformations, activeElements }) => {
  const wrapper = document.querySelector('#js-navigation-wrapper');
  const container = document.getElementById('js-navigation');
  const activeId = useMemo(() => Object.keys(activeElements).find(key => activeElements[key]), [activeElements]);

  useEffect(() => {
    wrapper.classList.remove('d-none');
    return () => {
      wrapper.classList.add('d-none');
    };
  }, []);

  if (container) {
    return ReactDOM.createPortal(
      <div>
        <Text className="pl-8" size="xs" bold spaced uppercase>
          Variables Navigation
        </Text>
        <ul className="Navigation">
          {transformations.map(transformation => (
            <li key={transformation.id} className="mv-4">
              <a
                id={transformation.id}
                href={`#variable-${transformation.id}`}
                className={cs('Navigation-item', { active: activeId === transformation.id })}
              >
                <div className="Navigation-icon">
                  <FieldKindIcon size="20px" inheritColor showFieldKind={transformation.fieldKind} />
                </div>
                {transformation.name}
              </a>
            </li>
          ))}
        </ul>
      </div>,
      container
    );
  }

  return null;
};

VariableNavigation.propTypes = {
  transformations: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    fieldKind: PropTypes.fieldKind,
  }),
  activeElements: PropTypes.object,
};

export default VariableNavigation;
