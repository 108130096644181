import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Tile, Icon } from './index';
import { Loader } from './Icons';

const ImagePreview = ({ children, size, url, withoutTile, fullWidth, spacing, loaderSize = 'big', ...rest }) => {
  const [showEmpty, setShowEmpty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoading(false), 15000);
    return () => clearTimeout(timer);
  }, []);

  const showEmptyIcon = showEmpty || url === undefined;

  const image = (
    <div
      className={cs('pos-relative', `mb-${spacing}`, { 'negative-ma-16': !withoutTile })}
      style={{ width: fullWidth ? '100%' : `${size}px`, height: `${size}px` }}
    >
      {showEmptyIcon && (
        <Icon
          kind="empty-image"
          color="#E5E7E9"
          size={`${(size / 3) * 2}px`}
          className="pos-absolute"
          style={{ left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
        />
      )}
      {loading && !showEmptyIcon && !loaded && (
        <Loader style={{ opacity: 0.3, zIndex: 1 }} absolute size={loaderSize} />
      )}
      <img
        src={url?.split(/,\s*http/)?.[0]}
        onLoad={() => {
          setLoaded(true);
        }}
        onError={() => {
          setShowEmpty(true);
        }}
        alt=""
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          margin: 'auto',
          position: 'absolute',
          maxWidth: '100%',
          maxHeight: '100%',
        }}
      />
      <div className="imagePreviewOverlay" />
    </div>
  );
  if (withoutTile) {
    return (
      <>
        {image}
        {children}
      </>
    );
  }

  return (
    <Tile style={{ overflow: 'hidden' }} {...rest}>
      {image}
      {children}
    </Tile>
  );
};

ImagePreview.propTypes = {
  children: PropTypes.any,
  size: PropTypes.number,
  url: PropTypes.string,
  withoutTile: PropTypes.bool,
  fullWidth: PropTypes.bool,
  spacing: PropTypes.oneOf([0, 4, 8, 12, 16, 20, 24, 28, 32, 40]),
  loaderSize: PropTypes.oneOf(['small', 'big']),
};

ImagePreview.defaultProps = {
  spacing: '16',
};

export default ImagePreview;
