window.addEventListener('load', () => {
  const textContainers = document.getElementsByClassName('js-text-with-check');

  Array.from(textContainers).forEach(container => {
    const checkedInputName = container.dataset.checkedInputName;
    const isCheckedReactInput = container.dataset.isCheckedReactInput === 'true';
    const checkedInputElem = document.querySelector(`[name="${checkedInputName}"]`);

    if (checkedInputElem) {
      if (checkedInputElem.value.length > 0) {
        container.classList.add('isChecked');
      }

      if (isCheckedReactInput) {
        checkedInputElem.addEventListener('change', () => {
          setTimeout(() => {
            if (checkedInputElem.value.length > 0) {
              container.classList.add('isChecked');
            } else {
              container.classList.remove('isChecked');
            }
          }, 100);
        });
      } else {
        checkedInputElem.addEventListener('input', () => {
          if (checkedInputElem.value.length > 0) {
            container.classList.add('isChecked');
          } else {
            container.classList.remove('isChecked');
          }
        });
      }
    }
  });
});
