import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';
import { tHtml } from '../i18n';
import { enhancePreviewLinkByConditions } from './PreviewDataModal';

window.initDataPreviewModal = () => {
  $('[data-preview-modal-link]').each((index, trigger) => {
    const triggerEl = $(trigger);
    if (triggerEl.data('inited-react-preview-modal')) {
      return;
    }
    triggerEl.data('inited-react-preview-modal', true);

    triggerEl.click(e => {
      e.preventDefault();
      const container = document.getElementById('react-modal-for-data-preview');

      const url = enhancePreviewLinkByConditions(trigger.dataset.previewModalLink, triggerEl);

      ReactDOM.render(
        <Modal
          heading={tHtml('preview_data', {
            field: trigger.dataset.previewModalName,
          })}
          size="medium"
          onClose={() => ReactDOM.unmountComponentAtNode(container)}
          url={url}
        />,
        container
      );
    });
  });
};
