const findParents = (elements, id, acc = []) => {
  const current = elements[id];

  if (!current) return acc;

  const parent = elements[current.parent];

  if (current.parent === 0) {
    return acc;
  }
  return findParents(elements, current.parent, acc.concat(parent));
};

export default findParents;
