import $ from 'jquery';
// Split the input name in programatically readable keys.
// Examples:
// "foo"              => ["foo"]
// "[foo]"            => ["foo"]
// "foo[inn][bar]"    => ["foo", "inn", "bar"]
// "foo[inn[bar]]"    => ["foo", "inn", "bar"]
// "foo[inn][arr][0]" => ["foo", "inn", "arr", "0"]
// "arr[][val]"       => ["arr", "", "val"]

export default function splitInputNameIntoKeysArray(nameWithNoType) {
  let keys = nameWithNoType.split('['); // split string into array
  keys = $.map(keys, key => key.replace(/\]/g, '')); // remove closing brackets
  if (keys[0] === '') {
    keys.shift();
  } // ensure no opening bracket ("[foo][inn]" should be same as "foo[inn]")
  return keys;
}
