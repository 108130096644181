import React from 'react';
import { setupColumn } from './setupColumn';
import { DateAndTimeCell, TextCell, GenericCell } from './cells';
import { t } from '../../../i18n';
import Icon from '../../Icon';
import Tooltip from '../../Tooltip';

const mapping = {
  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    reverseOrder: true,
    header: () => t('attributes.downloaded_at'),
    cell: DateAndTimeCell,
  }),
  ...setupColumn({
    id: 'requester',
    order: 'requester',
    header: () => t('attributes.requester'),
    cell: GenericCell,
    // eslint-disable-next-line react/prop-types
    resolveChildren: ({ requester }) =>
      requester === 'user' ? (
        <Tooltip
          text={t('views.feed_export_logs.requester_user', {
            default: 'Downloaded by user from application interface.',
          })}
        >
          <Icon color="#ffae42" colored kind="user" />
        </Tooltip>
      ) : (
        <span>
          <Icon kind="earth" colored color="#2fa648" className="mr-4" /> {requester}
        </span>
      ),
  }),
  ...setupColumn({
    id: 'userAgent',
    order: 'userAgent',
    header: () => t('attributes.userAgent'),
    cell: TextCell,
  }),
};

export default mapping;
