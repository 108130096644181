/* eslint-disable no-useless-escape */
const UNICODE_SPACES = /\u00A0|\u1680|\u180E|\u2000|\u2001|\u2002|\u2003|\u2004|\u2005|\u2006|\u2007|\u2008|\u2009|\u200A|\u200B|\u202F|\u205F|\u3000|\uFEFF/g;
const MULTIPLE_SPACES = /\s{2,}/g;

const fixSearchText = text => {
  if (!text) return '""';
  if (typeof text === 'number') return text;

  const fixedText = text
    .replace(/\s/g, ' ')
    .replace(/"/g, ' ')
    .replace(UNICODE_SPACES, ' ')
    .replace('\b', ' ')
    .replace('\t', ' ')
    .replace(MULTIPLE_SPACES, ' ')
    .trim();

  return `"${fixedText}"`;
};

export default fixSearchText;
