import React, { PureComponent } from 'react';

import PropertyGroup from './PropertyGroup';
import Columns from './Columns';
import TimeRestriction from '../../../../rails_form/timeRestriction';
import { t } from '../../../../i18n';

const timeRestrictionPropsCreator = (data, onChange) => field => ({
  id: `time_restriction_${field}`,
  value: data[field],
  name: `image[time_restriction_attributes][0][${field}]`,
  dataTestId: `time_restriction_${field}`,
  onChange: value => {
    onChange('timeRestriction', { ...data, [field]: value });
  },
});

export default class TimeRestrictionPanel extends PureComponent {
  render() {
    const { object, onChange } = this.props;
    const timeRestrictionProps = timeRestrictionPropsCreator(object.timeRestriction || {}, onChange);
    return (
      <PropertyGroup object={object}>
        <Columns
          label={t('react.image_generator.label.time_restriction')}
          object={object}
          onChange={onChange}
          onEnable={{ timeRestriction: { generate: 'true' } }}
          onDisable={{ timeRestriction: null }}
          labelOnSeparateLine
        >
          <TimeRestriction
            isAllwaysOn
            withoutHeader
            minDateProps={timeRestrictionProps('date_from')}
            maxDateProps={timeRestrictionProps('date_to')}
            generateProps={timeRestrictionProps('generate')}
            locale={window.I18nLocale}
          />
        </Columns>
      </PropertyGroup>
    );
  }
}
