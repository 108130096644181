import React, { Component } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';
import { Icon, Tooltip } from './index';
import { t } from '../i18n';

export const BetaBadge = ({ text, className, size = 'large' }) => (
  <Badge size={size} kind="blue" className={className}>
    <Icon kind="beta" inheritColor />
    {text}
  </Badge>
);
BetaBadge.propTypes = {
  className: RPT.string,
  text: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

export const MoneyBadge = ({ className, size = 'large' }) => (
  <Badge size={size} kind="green" className={className}>
    <Tooltip text={t('views.money_badge.tooltip')}>
      <Icon kind="dollar" inheritColor />
    </Tooltip>
  </Badge>
);
MoneyBadge.propTypes = {
  className: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

export const NewBadge = ({ text, className, size = 'large' }) => (
  <Badge size={size} kind="green" className={className}>
    <Icon kind="flame" inheritColor />
    {text}
  </Badge>
);
NewBadge.propTypes = {
  className: RPT.string,
  text: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

export const TipBadge = ({ className, size = 'large' }) => (
  <Badge size={size} kind="grape" uppercase className={className}>
    <Icon kind="bullseye" inheritColor />
    {t('views.tip_badge', { default: 'Tip' })}
  </Badge>
);
TipBadge.propTypes = {
  className: RPT.string,
  size: RPT.oneOf(['medium', 'large', 'extraLarge']),
};

class Badge extends Component {
  static propTypes = {
    className: RPT.string,
    clickable: RPT.bool,
    count: RPT.oneOfType([RPT.number, RPT.string]),
    children: RPT.any,
    isWithBorder: RPT.bool,
    kind: RPT.oneOf([
      'blue',
      'lightBlue',
      'red',
      'lightRed',
      'gray',
      'lightGray',
      'green',
      'lightGreen',
      'orange',
      'lightOrange',
    ]),
    size: RPT.oneOf(['medium', 'large', 'extraLarge']),
    spaced: RPT.bool,
    uppercase: RPT.bool,
  };

  static defaultProps = {
    size: 'medium',
  };

  render() {
    const { count, clickable, className, isWithBorder, kind, size, spaced, uppercase, children, ...rest } = this.props;

    return (
      <span
        className={cs('Badge', className, {
          'Badge--uppercase': uppercase,
          'Badge--withBorder': isWithBorder,
          'Badge--spaced': spaced,
          [`Badge--${kind}`]: kind,
          [`Badge--${size}`]: size,
          'Badge--empty': !children && typeof count === 'undefined',
        })}
        {...rest}
      >
        {count && count > 99 ? '99+' : count}
        {children}
      </span>
    );
  }
}

export default Badge;
