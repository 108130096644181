import moment from 'moment';

export const defaultDateFormat = 'D.M.YYYY';

export const defaultOptions = () => ({
  precision: 10,
  delimiter: ',', // used in 10,000,000.10
  format: defaultDateFormat,
  separator: '.', // used in 10.10
  template: '%n %u', // %n is placeholder for number, %u is for unit
  unit: '',
  trimTrailingZeros: true,
  emptyPlaceholder: undefined,
  ...(window.reacti18nNumberFormat || {}),
});

export function mergeOptionsWithDefault(givenOptions) {
  return { ...defaultOptions(), ...givenOptions };
}

export function formatDate(givenDate, locale, format = undefined) {
  const date = moment(givenDate);
  date.locale(locale);
  return date.format(format || defaultDateFormat);
}

export function formatInteger(input, givenOptions = {}) {
  return formatNumber(input, { ...givenOptions, precision: 0 });
}

export function formatNumber(input, givenOptions = {}) {
  const options = mergeOptionsWithDefault(givenOptions);

  if (typeof input === 'undefined' || input === null) {
    return options.emptyPlaceholder;
  }

  const rounedNumber = roundNumber(input, options);
  const formattedNumber = formatNumberSeparatorAndDelimiter(rounedNumber, options);

  if (formattedNumber === 'NaN') {
    return '';
  }

  return options.template
    .replace('%n', formattedNumber)
    .replace('%u', options.unit)
    .trim();
}

export function formatCurrency(input, givenOptions = {}) {
  return formatNumber(input, { trimTrailingZeros: false, ...givenOptions });
}

export function formatNumberSeparatorAndDelimiter(input, givenOptions = {}) {
  const options = mergeOptionsWithDefault(givenOptions);
  const numbers = input
    .toFixed(givenOptions.precision)
    .toString()
    .split('.');

  numbers[0] = numbers[0].replace(/\B(?=(\d{3})+(?!\d))/g, options.delimiter);

  if (numbers[1] && givenOptions.trimTrailingZeros) {
    numbers[1] = numbers[1].replace(/0+$/g, '');
    if (numbers[1] === '') {
      numbers.splice(1, 1);
    }
  }

  return numbers.join(options.separator);
}

export function roundNumber(input, givenOptions) {
  const options = mergeOptionsWithDefault(givenOptions);
  const decimal = 10 ** options.precision;
  return Math.round(input * decimal) / decimal;
}

export function formatPercent(input, givenOptions = {}) {
  return formatNumber(100 * input, { ...givenOptions, precision: 2, template: '%n%u', unit: '%' });
}
