import React from 'react';

// const getPixelsScaledCreator = scale => pixel => pixel / this.props.scale;

const colorSnapping = 'rgba(255, 0, 0, 0.5)';
const colorPossibleSnap = 'rgba(44, 44, 44, 0.3)';
const RULERS_DISTANCE_TO_SHOW = 50;
const GetRulers = ({ boundingBox, otherObject, canvasHeight, canvasWidth, barWidth }) => {
  const centerX = boundingBox.left + boundingBox.width / 2;
  const centerY = boundingBox.top + boundingBox.height / 2;
  const right = boundingBox.left + boundingBox.width;
  const bottom = boundingBox.top + boundingBox.height;
  const left = boundingBox.left;
  const top = boundingBox.top;

  const otherTop = otherObject.y;
  const otherLeft = otherObject.x;
  const otherBottom = otherTop + otherObject.height;
  const otherRight = otherLeft + otherObject.width;
  const otherCenterX = otherLeft + otherObject.width / 2;
  const otherCenterY = otherTop + otherObject.height / 2;

  return (
    <React.Fragment>
      <div
        style={{
          top: '-100px',
          height: `${canvasHeight + 200 - barWidth}px`,
          width: `${barWidth}px`,
          left: `${otherLeft}px`,
          position: 'absolute',
          zIndex: 105,
          opacity:
            Math.abs(otherLeft - right) < RULERS_DISTANCE_TO_SHOW ||
            Math.abs(otherLeft - left) < RULERS_DISTANCE_TO_SHOW
              ? 1
              : 0,
          background:
            Math.abs(otherLeft - left) === 0 || Math.abs(otherLeft - right) === 0 ? colorSnapping : colorPossibleSnap,
        }}
      />
      <div
        style={{
          top: '-100px',
          height: `${canvasHeight + 200 - barWidth}px`,
          width: `${barWidth}px`,
          left: `${otherRight}px`,
          position: 'absolute',
          zIndex: 105,
          opacity:
            Math.abs(otherRight - right) < RULERS_DISTANCE_TO_SHOW ||
            Math.abs(otherRight - left) < RULERS_DISTANCE_TO_SHOW
              ? 1
              : 0,
          background:
            Math.abs(otherRight - right) === 0 || Math.abs(otherRight - left) === 0 ? colorSnapping : colorPossibleSnap,
        }}
      />
      <div
        style={{
          top: '-100px',
          height: `${canvasHeight + 200 - barWidth}px`,
          width: `${barWidth}px`,
          left: `${otherCenterX}px`,
          position: 'absolute',
          zIndex: 105,
          opacity: Math.abs(otherCenterX - centerX) < RULERS_DISTANCE_TO_SHOW ? 1 : 0,
          background: Math.abs(otherCenterX - centerX) === 0 ? colorSnapping : colorPossibleSnap,
        }}
      />
      <div
        style={{
          left: '-100px',
          width: `${canvasWidth + 200 - barWidth}px`,
          height: `${barWidth}px`,
          top: `${otherCenterY}px`,
          position: 'absolute',
          zIndex: 105,
          opacity: Math.abs(otherCenterY - centerY) < RULERS_DISTANCE_TO_SHOW ? 1 : 0,
          background: Math.abs(otherCenterY - centerY) === 0 ? colorSnapping : colorPossibleSnap,
        }}
      />
      <div
        style={{
          left: '-100px',
          width: `${canvasWidth + 200 - barWidth}px`,
          height: `${barWidth}px`,
          top: `${otherTop}px`,
          position: 'absolute',
          zIndex: 105,
          opacity:
            Math.abs(otherTop - top) < RULERS_DISTANCE_TO_SHOW || Math.abs(otherTop - bottom) < RULERS_DISTANCE_TO_SHOW
              ? 1
              : 0,
          background:
            Math.abs(otherTop - top) === 0 || Math.abs(otherTop - bottom) === 0 ? colorSnapping : colorPossibleSnap,
        }}
      />
      <div
        style={{
          left: '-100px',
          width: `${canvasWidth + 200 - barWidth}px`,
          height: `${barWidth}px`,
          top: `${otherBottom}px`,
          position: 'absolute',
          zIndex: 105,
          opacity:
            Math.abs(otherBottom - bottom) < RULERS_DISTANCE_TO_SHOW ||
            Math.abs(otherBottom - top) < RULERS_DISTANCE_TO_SHOW
              ? 1
              : 0,
          background:
            Math.abs(otherBottom - bottom) === 0 || Math.abs(otherBottom - top) === 0
              ? colorSnapping
              : colorPossibleSnap,
        }}
      />
    </React.Fragment>
  );
};

const Rulers = ({ boundingBox, width, height, scale, objects, selectedObjectIndex, selectedObjectsIndexes }) => {
  const getPixelsScaled = pixel => pixel / scale;

  const indexes = typeof selectedObjectIndex === 'number' ? [selectedObjectIndex] : selectedObjectsIndexes || [];
  const notSelectedObjects = objects
    .filter((o, i) => indexes.indexOf(i) === -1)
    .filter(obj => obj && !obj.lockLayer && !obj.hideLayer);

  return (
    <React.Fragment>
      <GetRulers
        boundingBox={boundingBox}
        otherObject={{ y: 0, x: 0, width, height }}
        canvasHeight={height}
        canvasWidth={width}
        getPixelsScaled={getPixelsScaled}
        barWidth={getPixelsScaled(1)}
      />
      {notSelectedObjects.map(obj => (
        <GetRulers
          key={`ruler-${obj.uuid}`}
          boundingBox={boundingBox}
          otherObject={obj}
          canvasHeight={height}
          canvasWidth={width}
          getPixelsScaled={getPixelsScaled}
          barWidth={getPixelsScaled(1)}
        />
      ))}
    </React.Fragment>
  );
};

// const styles = {
//   handler: {
//     position: 'absolute',
//     zIndex: 101,
//   },
// };

export default Rulers;
