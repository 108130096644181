/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import React from 'react';
import { uniqBy } from 'lodash';
import { setupColumn } from '../setupColumn';
import {
  AdSystemCell,
  HtmlCell,
  StateCell,
  ConditionsWithGraphCell,
  GenericCell,
  LabelsCell,
  LinkCell,
  SwitchCell,
  MainIdentifierWithSwitcherCell,
} from '../cells';
import { t, formatNumberInflection } from '../../../../i18n';
import { modalDataUrlOpener } from '../../../RemoteModal';
import { AdtextCarousel } from '../../../../adtext_tree/Adtext';
import expanded from './expanded';
import responsive from './responsive';
import smart from './smart';
import dsa from './dsa';
import performanceMax from './performanceMax';
import { LinkEdit } from '../editable';
import { cssVariables } from '../../../index';

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: [
      'createFromRestLink',
      'name',
      'id',
      'type',
      'deleteBranchLink',
      'duplicateBranchLink',
      'duplicateBranchIntoLink',
      'previewJsonData',
      'campaignItemsCount',
      { campaignSetting: [] },
      {
        adtexts: [
          'type',
          'parentId',
          'name',
          'itemsCount',
          'status',
          'timeRestrictionStatus',
          { timeRestriction: ['tooltip', 'dateFrom', 'dateTo', 'generateString'] },
          'editLink',
          'duplicateLink',
          'duplicateToSameBranchLink',
          'deleteLink',
          { otherGroups: ['name', 'lastAdtextId'] },
        ],
      },
    ],
    required: true,
    cell: MainIdentifierWithSwitcherCell,
    width: '440px',
    editable: LinkEdit,
    editButtonAsProp: true,
    editQuery: ({ id, isParent, adtexts }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') changes.push(`status: "${enabled ? 'running' : 'paused'}"`);

      if (isParent) {
        return gql`mutation updateAdtexts {${adtexts
          .map(
            ({ id: adtextId }) =>
              ` id${adtextId}Mutation: updateBaseAdtext(
                  id: ${adtextId},
                  ${changes.join(',')}
                ) { baseAdtext { id status timeRestrictionStatus timeRestriction { tooltip } } errors } `
          )
          .join(' ')}}`;
      }

      return gql`mutation updateAdtext($text: String) {
        updateBaseAdtext(
          id: ${id},
          name: $text,
          ${changes.join(',')}) { baseAdtext { id status name } }}`;
    },
    order: 'name',
    header: (layoutType, assetGroupType) => {
      if (assetGroupType) {
        return layoutType === 'branches' ? (
          <span className="margin-left-99px">{t('react.tables.adtexts.asset_group_branch')}</span>
        ) : (
          <span className="margin-left-67px">{t('react.tables.adtexts.asset_group_name')}</span>
        );
      }
      return layoutType === 'branches' ? (
        <span className="margin-left-99px">{t('react.tables.adtexts.ad_branch')}</span>
      ) : (
        <span className="margin-left-67px">{t('react.tables.adtexts.ad_name')}</span>
      );
    },
    resolveChildren: ({
      modalCallback,
      linkCallback,
      id,
      parentId,
      type,
      isParent,
      adtexts,
      onClick,
      layoutType,
      name,
      status,
      editLink,
      timeRestrictionStatus,
      timeRestriction,
      additionalContent,
      deleteBranchLink,
      duplicateBranchLink,
      duplicateBranchIntoLink,
      deleteLink,
      duplicateLink,
      duplicateToSameBranchLink,
      createFromRestLink,
      // eslint-disable-next-line consistent-return
    }) => {
      const adtextsParams = {
        id: isParent ? undefined : id,
        parentId,
        name,
        text: !isParent ? name : undefined,
        size: '20px',
        color: cssVariables.grapePrimary,
        tooltip: t(`views.adtext_settings.type.${type.toLowerCase()}`),
        disableEditButton: isParent,
        icon:
          (type === 'ExpandedAdtext' && 'eta-adtext') ||
          (type === 'ResponsiveAdtext' && 'rsa-adtext') ||
          (type === 'DisplayResponsiveAdtext' && 'smart-adtext') ||
          (type === 'PerformanceMaxAdtext' && 'performance-max-adtext') ||
          (type === 'DSAAdtext' && 'dsa-adtext') ||
          '',

        link: !isParent && editLink,
        status,
        timeRestrictionTooltip: timeRestriction?.tooltip,
        timeRestrictionStatus,
        'data-test-id': `dropdown-action-${name}`,
        links: [
          {
            href: createFromRestLink,
            children:
              type === 'PerformanceMaxAdtext'
                ? t('react.tables.adtexts.create_asset_group')
                : t('react.tables.adtexts.create_ad'),
            icon: 'plus',
            'data-test-id-create-ad': name,
          },
          {
            href: editLink,
            children: t('react.tables.edit'),
            icon: 'edit',
            'data-test-id-edit': name,
          },
          {
            href: linkCallback && '#',
            onClick: linkCallback && linkCallback,
            children:
              type === 'PerformanceMaxAdtext'
                ? t('react.tables.adtexts.create_new_asset_group_branch')
                : t('react.tables.adtexts.create_new_branch'),
            icon: 'arrow-right',
            'data-test-id-move-to-new-branch': name,
          },
          {
            href: modalCallback && '#',
            onClick: modalCallback && modalCallback,
            children: t('react.tables.adtexts.move_to_different_branch'),
            icon: 'arrow-right',
            'data-test-id-move-to-other-branch': name,
          },
          {
            href: duplicateLink,
            children: t('react.tables.adtexts.duplicate'),
            icon: 'duplicate',
            'data-test-id-duplicate': name,
          },
          {
            href: duplicateBranchLink,
            'data-method': 'post',
            children: t('react.tables.adtexts.duplicate_branch'),
            icon: 'duplicate',
            'data-test-id-duplicate': `${name}`,
          },
          {
            href: duplicateToSameBranchLink,
            children: t('react.tables.adtexts.duplicate_to_same_branch'),
            icon: 'duplicate',
            'data-test-id-duplicate-to-same-branch': name,
          },
          {
            href: duplicateBranchIntoLink,
            children: t('react.tables.adtexts.duplicate_into'),
            'data-test-id-duplicate-branch-into': name,
            icon: 'duplicate-to',
            ...modalDataUrlOpener({
              url: duplicateBranchIntoLink,
              heading: t('views.campaign_settings.index.duplicate_to_campaigns_with_name', {
                default: 'Duplicate %{name} to campaign:',
                name,
              }),
              size: 'medium',
            }),
          },
          {
            href: deleteLink,
            children: t('react.tables.delete'),
            icon: 'trash',
            danger: true,
            'data-method': 'delete',
            'data-confirm': t('views.adtext_settings.table_actions.delete.destroy_branch_confirm'),
            'data-commit': t('views.adtext_settings.table_actions.delete.destroy_branch_commit'),
            'data-test-id-delete': name,
          },
          {
            href: deleteBranchLink,
            children: t('react.tables.delete'),
            'data-method': 'delete',
            icon: 'trash',
            danger: true,
            'data-confirm': t('views.adtext_settings.table_actions.delete.destroy_branch_confirm'),
            'data-commit': t('views.adtext_settings.table_actions.delete.destroy_branch_commit'),
            'data-test-id-delete': `branch-${name}`,
          },
        ],
      };
      if (layoutType === 'adtextsOnly') {
        // simple table with adtexts only
        return adtextsParams;
      } else if (layoutType === 'branches') {
        if (isParent) {
          // branch parent
          return {
            ...adtextsParams,
            status: adtexts.find(({ status: adStatus }) => adStatus === 'running') ? 'running' : 'paused',
            additionalContent,
            onClick,
            link: '#',
          };
        }
        // adtext
        return {
          ...adtextsParams,
          additionalContent,
        };
      }
    },
    fixed: 'left',
  }),

  ...setupColumn({
    id: 'products',
    header: () => t('react.tables.adtexts.products'),
    graphqlFields: ['campaignItemsCount', { adtexts: ['itemsCount', { conditions: ['toDataPreview'] }] }],
    required: true,
    cell: ConditionsWithGraphCell,
    width: '220px',
    resolveChildren: ({ isParent, first, layoutType, conditions = [], products, productsTotal, additionalData }) => {
      if (products === undefined) return undefined;
      return {
        noConditions: isParent,
        noGraph: layoutType === 'adtextsOnly',
        products,
        productsText: isParent && `${products}/${productsTotal}`,
        productsTotal,
        withArrow: !first && !isParent && layoutType !== 'adtextsOnly',
        conditions: conditions.map(c => JSON.parse(c.toDataPreview)),
        additionalData,
      };
    },
  }),
  ...setupColumn({
    id: 'ads',
    header: (layoutType, assetGroupType) => {
      if (assetGroupType) return t('react.tables.adtexts.asset_groups');
      return t('react.tables.adtexts.ads');
    },
    graphqlFields: ['id', 'adtextsCount', 'name', { adtexts: ['type', 'previewJsonData'] }],
    required: true,
    width: '386px',
    smallPadding: true,
    cell: GenericCell,
    resolveChildren: ({ previewJsonData = '{}', isParent, type, adtextsCount, hidden, id, adtexts }) => {
      if (isParent && !hidden) {
        return formatNumberInflection({
          zeroIsSpecial: true,
          countKey: 'number',
          count: adtextsCount,
          scope: type === 'PerformanceMaxAdtext' ? 'attributes.number_of_asset_groups' : 'attributes.number_of_ads',
        });
      }
      return (
        <AdtextCarousel
          isParent={isParent}
          previewJsonData={previewJsonData}
          adtexts={adtexts}
          id={id}
          adtextsCount={adtextsCount}
        />
      );
    },
  }),
  ...setupColumn({
    id: 'validState',
    header: () => t('views.feed_export.status'),
    graphqlFields: ['validState', { adtexts: ['validState'] }],
    required: true,
    cell: StateCell,
    resolveChildren: ({ validState }) => {
      if (typeof validState === 'boolean') {
        return { state: validState ? 'do_not_show_state' : 'draft', kind: 'adtext' };
      }
      return { state: 'do_not_show_state' };
    },
  }),
  ...setupColumn({
    id: 'systems',
    header: () => t('react.tables.adtexts.ad_systems'),
    graphqlFields: ['facebook', 'bing', 'sklik', 'adwords', { adtexts: ['facebook', 'bing', 'sklik', 'adwords'] }],
    required: true,
    cell: AdSystemCell,
    resolveChildren: ({ facebook, bing, sklik, adwords }) =>
      [facebook && 'facebook', bing && 'bing', sklik && 'sklik', adwords && 'adwords'].filter(item => item),
  }),
  ...setupColumn({
    id: 'branch',
    header: () => t('attributes.branch', { default: 'Branch' }),
    graphqlFields: ['name', { adtexts: ['branchName'] }],
    required: true,
    order: 'branch_name',
    resolveChildren: ({ branchName }) => branchName || null,
  }),
  ...setupColumn({
    id: 'campaign',
    header: () => t('react.tables.adtexts.campaign'),
    graphqlFields: [
      { campaignSetting: ['id', 'showLink', 'name'] },
      { adtexts: { campaignSetting: ['showLink', 'name'] } },
    ],
    required: true,
    cell: LinkCell,
    resolveChildren: ({ campaignSetting, layoutType, isParent, showLink }) => {
      let text = '';
      let link = showLink;
      if (isParent || layoutType !== 'branches') {
        text = campaignSetting?.name;
        link = campaignSetting?.showLink;
      }
      return {
        text,
        link,
      };
    },
  }),
  ...setupColumn({
    id: 'useNameAsLabel',
    graphqlFields: { adtexts: ['useNameAsLabel', 'type'] },
    resolveChildren: data =>
      typeof data?.useNameAsLabel === 'boolean' && data?.type !== 'DisplayResponsiveAdtext'
        ? data?.useNameAsLabel
        : null,
    cellCouldBeEmpty: true,
    cell: SwitchCell,
    editQuery: ({ id }) => children =>
      gql`
      mutation {
        updateBaseAdtext(id: ${id}, useNameAsLabel: ${children}) {
          baseAdtext { id useNameAsLabel}
        }
      }
      `,
    header: () => t('views.use_name_as_label.text'),
  }),
  ...setupColumn({
    id: 'labels',
    graphqlFields: { labels: ['id', 'name', 'color'], adtexts: [{ labels: ['id', 'name', 'color'] }] },
    cell: LabelsCell,
    category: 'setup',
    header: () => t('activerecord.attributes.labels', { default: 'labels' }),
    resolveChildren: ({ labels }) => uniqBy(labels, ({ id }) => id),
  }),
  ...setupColumn({
    id: 'timeRestriction',
    graphqlFields: { adtexts: { timeRestriction: ['text'] } },
    cell: HtmlCell,
    width: '150px',
    header: () => t('activerecord.attributes.fb_adtext.time_restrictions'),
    resolveChildren: ({ timeRestriction }) => timeRestriction?.text || null,
  }),
  ...setupColumn({
    id: 'capitalizePathStarts',
    graphqlFields: { adtexts: ['capitalizePathStarts', 'type'] },
    resolveChildren: data =>
      data.type === 'ExpandedAdtext' && typeof data?.capitalizePathStarts === 'boolean'
        ? data?.capitalizePathStarts
        : null,
    cell: SwitchCell,
    cellCouldBeEmpty: true,
    editQuery: ({ id }) => children =>
      gql`
      mutation {
        updateBaseAdtext(id: ${id}, capitalizePathStarts: ${children}) {
          baseAdtext { id capitalizePathStarts}
        }
      }
      `,
    header: () => t('views.adtext_settings.form_expanded.capitalize_url'),
  }),
  ...setupColumn({
    id: 'destUrl',
    graphqlFields: { adtexts: ['destUrl'] },
    header: () => t('attributes.dest_url'),
    resolveChildren: ({ destUrl }) => destUrl || null,
  }),

  ...expanded,
  ...responsive,
  ...smart,
  ...dsa,
  ...performanceMax,
};

export default mapping;
