import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { hot } from 'react-hot-loader/root';
import { QueryHolder } from '../QueryHolder';

const QUERY_ORGANIZATION_DASHBOARD_ASSETS = gql`
  query OrganizationDashboardAssets(
    $organizationId: BigInt!
    $campaignSettingId: BigInt!
    $dateFrom: ISO8601DateTime!
    $dateTo: ISO8601DateTime!
  ) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        placementStats(dateFrom: $dateFrom, dateTo: $dateTo) {
          rows {
            adPlacement
            clicks
            cost
            currency
            impressions
            videoViews
            conversions
            conversionValue
          }
          summary {
            adPlacement
            cost
            clicks
            currency
            impressions
            videoViews
            conversions
            conversionValue
          }
        }
      }
    }
  }
`;

const Helper = QueryHolder(props => {
  const childrenWithProps = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { data: props.data });
    }
    return child;
  });

  return childrenWithProps;
});

const ReportGraphqlWrapper = ({ organizationId, campaignId, dateRange, ...rest }) => (
  <Helper
    {...rest}
    query={QUERY_ORGANIZATION_DASHBOARD_ASSETS}
    variables={{
      organizationId: `${organizationId}`,
      campaignSettingId: `${campaignId}`,
      dateFrom: dateRange.from,
      dateTo: dateRange.to,
    }}
    loaderSize="big"
    hideLoadingText
  />
);

ReportGraphqlWrapper.propTypes = {
  organizationId: PropTypes.string,
  campaignId: PropTypes.string,
  dateRange: PropTypes.shape({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
};

export default hot(ReportGraphqlWrapper);
