import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { t } from '../../i18n';
import { Text } from '../Typography';
import { cssVariables } from '../index';

// TODO this needs to be somehow consolidated!!!

export default class UnitInput extends React.PureComponent {
  static propTypes = {
    beforeUnit: PropTypes.string,
    defaultValue: PropTypes.any,
    doNotUseInternalState: PropTypes.bool,
    error: PropTypes.string,
    hint: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    namespace: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    optional: PropTypes.bool,
    required: PropTypes.bool.isRequired,
    type: PropTypes.string,
    unit: PropTypes.string,
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    optional: false,
    type: 'number',
  };

  componentDidMount() {
    if (this.props.hint && typeof window.initReact === 'function') {
      window.initReact();
    }
  }

  state = {
    value: this.props.value || this.props.defaultValue,
    validValue: true,
    errorType: null,
  };

  handleChange = e => {
    const { type, onChange } = this.props;
    let value = e.target.value;

    if (type === 'string' || type === 'text') {
      this.setState({ value, validValue: true });
      if (typeof onChange === 'function') {
        onChange(e);
      }
    } else if (type === 'integer') {
      const validValue = parseInt(value, 10).toString() === value;

      // if value is valid parse it
      if (validValue) {
        value = parseInt(value, 10);
        this.setState({ value, validValue, errorType: null });
        if (typeof onChange === 'function') {
          onChange({ target: { value } });
        }
        // Do not propagate invalid value to top component
      } else {
        this.setState({ value, validValue, errorType: t('errors.messages.not_a_number') });
      }
    } else if (type === 'float' || type === 'number') {
      const normalizedValue = value.replace(',', '.').replace(' ', '');
      const validValue =
        !isNaN(parseFloat(normalizedValue, 10).toString()) && !(value.endsWith('.') || value.endsWith(','));

      // if value is valid parse it
      if (validValue) {
        value = parseFloat(normalizedValue, 10);
        this.setState({ value, validValue, errorType: null });
        if (typeof onChange === 'function') {
          onChange({ target: { value } });
        }
        // Do not propagate invalid value to top component
      } else {
        this.setState({ value, validValue, errorType: t('errors.messages.not_a_number') });
      }
    }
  };

  render() {
    const {
      doNotUseInternalState,
      name,
      hint,
      namespace,
      label,
      required,
      value,
      error,
      type,
      unit,
      beforeUnit,
      optional,
      ...rest
    } = this.props;
    const { validValue, errorType } = this.state;
    const selected = doNotUseInternalState && validValue ? value : this.state.value;
    const errorMessage = error || errorType;

    let id = name;
    let fieldName = name;
    if (namespace) {
      id = [namespace, name].join('_');
      fieldName = `${namespace}[${name}]`;
    }

    return (
      <div className={cs('Input', 'Input--noBorder', 'string', id, { required, 'has-error': errorMessage })}>
        {label && (
          <label className={cs('Input-label', 'string', { required })} htmlFor={id} style={{ marginBottom: '6px' }}>
            {required && <abbr title="požadováno">*</abbr>}
            <Text medium style={{ marginRight: '4px' }}>
              {label}
            </Text>
            {optional && (
              <Text style={{ color: cssVariables.textSubtle }}>
                ({t('react.unit_input.optional', { default: 'optional' })})
              </Text>
            )}
            {required && (
              <Text medium style={{ color: cssVariables.interactiveAttentionDefault }}>
                *
              </Text>
            )}
          </label>
        )}
        {hint && <span className="Input-tooltip fc-info-circle-fill js-form-hint">{hint}</span>}
        <div className="Input-unitWrapper">
          {beforeUnit && (
            <label className="before label" htmlFor={id}>
              {beforeUnit}
            </label>
          )}
          <input
            {...rest}
            className={cs('string', { required, 'text-right': !beforeUnit && unit })}
            onChange={this.handleChange}
            type={type}
            value={selected}
            name={fieldName}
            id={id}
            onInput={e => {
              if (e.target.value === '-0') e.target.value = '0';
            }}
          />
          {unit && (
            <label className="after label" htmlFor={id}>
              {unit}
            </label>
          )}
        </div>
        {errorMessage && <span className="Input-errorLabel">{errorMessage}</span>}
      </div>
    );
  }
}
