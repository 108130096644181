window.fontResizer = function() {
  var elements = document.getElementsByClassName('js-image-gen-fit-text');
  for (var i = 0; i < elements.length; i += 1) {
    var el = elements[i];
    var parent = el.parentElement;
    var scale = parent.offsetWidth / el.offsetWidth;

    if (parent.style.transformOrigin.length === 0) {
      parent.style.transformOrigin = 'left center';
    }

    if (scale < 1) {
      parent.style.transform = 'scale(' + scale + ')';
    } else {
      parent.style.transform = 'scale(1)';
    }
  }
};
