import React, { useEffect, useState } from 'react';

const DynamicImage = ({ imageName }) => {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    // Dynamically import the image
    // eslint-disable-next-line consistent-return
    const loadImage = async () => {
      try {
        // Assume the images are located in the 'src/assets/images/' directory
        const image = await import(`../../scss_stylesheets/images/${imageName}`);
        setImageSrc(image.default); // Webpack handles images with default export
      } catch (err) {
        return null;
      }
    };

    loadImage();
  }, [imageName]);

  return <div>{imageSrc ? <img src={imageSrc} alt={imageName} /> : <p>Loading image...</p>}</div>;
};

export default DynamicImage;
