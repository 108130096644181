import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import {
  SmallHeading,
  Text,
  Button,
  Row,
  Col,
  Tile,
  ConditionsBadgeTooltipContent,
  convertConditionsAndItemGroupsToConditions,
} from '../components';
import ConditionSummary from './ConditionSummary';
import { WrapperPropTypes } from './WrapperRenderers';
import { t } from '../i18n';

const Heading = ({ children, twoLine }) => {
  if (twoLine) {
    return <Text className="d-block mb-8">{children}</Text>;
  }
  return <SmallHeading className="mt-0">{children}</SmallHeading>;
};

Heading.propTypes = {
  children: PropTypes.any,
  twoLine: PropTypes.any,
};

export const ShowConditionsFormContext = React.createContext(true);

const ConditionsWithProductSetsAndItemGroups = props => {
  const {
    disabled,
    field,
    options,
    addNewFieldText,
    activeFieldData,
    hideProductViewButton,
    children,
    connectAddNewFieldsToContainer,
    addNewField,
    getCurrentNotEmptyCount,
    additionalState,
  } = props;

  const collapse = options.collapse_form && getCurrentNotEmptyCount() > 0;
  const [shownConditionsForm, showConditionsForm] = useState(!collapse);

  return (
    <Col id={field} setRef={connectAddNewFieldsToContainer} data-test-id="conditons-with-groups-wrapper">
      {!shownConditionsForm && (
        <Tile>
          <Row>
            <Col grow className="ml-16 text-center">
              <ConditionsBadgeTooltipContent
                whiteBackground
                conditions={convertConditionsAndItemGroupsToConditions({
                  conditions: activeFieldData,
                  conditionsOptions: options,
                })}
                itemGroups={options.item_groups}
              />
            </Col>
            <Col shrink className="ml-16">
              <Button
                onClick={() => showConditionsForm(true)}
                secondary
                size="small"
                data-test-id="edit-conditions-button"
                type="button"
              >
                {t('react.filters_and_conditions.edit_conditions_button')}
              </Button>
            </Col>
          </Row>
        </Tile>
      )}
      <div className={shownConditionsForm ? '' : 'd-none'}>
        <ShowConditionsFormContext.Provider value={shownConditionsForm}>{children}</ShowConditionsFormContext.Provider>
        <Row justifyBetween noPadding>
          <Col shrink className="mt-8">
            <Button
              className={`js-add-field mb-0 ${options.add_button_class}`}
              disabled={disabled || !addNewField}
              icon={!addNewFieldText || 'plus'}
              onClick={() => addNewField({})}
              secondary
              size="small"
              data-test-id={`add-${field}-button`}
              type="button"
            >
              {addNewFieldText}
            </Button>
          </Col>
        </Row>
      </div>
      {(shownConditionsForm || options.collapse_form === 'conditions') && (
        <ConditionSummary
          fieldData={activeFieldData}
          additionalState={additionalState}
          options={options}
          hideProductViewButton={hideProductViewButton}
        />
      )}
    </Col>
  );
};

ConditionsWithProductSetsAndItemGroups.propTypes = {
  ...WrapperPropTypes,
  addNewField: PropTypes.func.isRequired,
  addNewFieldText: PropTypes.string,
  disabled: PropTypes.bool,
  activeFieldData: PropTypes.array,
  hideProductViewButton: PropTypes.bool,
};

export default hot(ConditionsWithProductSetsAndItemGroups);
