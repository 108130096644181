import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import React, { useState } from 'react';
import { hot } from 'react-hot-loader/root';
import { gql, useLazyQuery, useQuery } from '@apollo/client';
import { generateDimensions, generateMetrics } from './utils';
import { Icon, useLocalState, Button, Col } from '../index';
import { t } from '../../i18n';
import { Loader } from '../Icons';
import DataAnalysisTable from './DataAnalysisTable';
import Form from './Form';

const QUERY_VARIABLES = gql`
  query Variables($organizationId: BigInt!, $dataSourceId: BigInt!) {
    organization(id: $organizationId) {
      id
      dataSource(id: $dataSourceId) {
        id
        tableVariablesSorted {
          name
          placeholderName
          showFieldKind
          elasticPlaceholderName
        }
        __typename
      }
      __typename
    }
  }
`;

const QUERY_ANALYSIS = gql`
  query DataAnalysis(
    $organizationId: BigInt!
    $dataSourceId: BigInt!
    $feedMappingId: BigInt
    $metrics: [String!]
    $dimensions: [String!]
    $conditions: [String!]
    $search: String
    $elasticQuery: String
  ) {
    organization(id: $organizationId) {
      id
      dataSource(id: $dataSourceId) {
        id
        dataAnalysis(
          metrics: $metrics
          dimensions: $dimensions
          conditions: $conditions
          elasticQuery: $elasticQuery
          search: $search
        ) {
          items
        }
      }
    }
    selectedCount: conditionCount(
      dataSourceId: $dataSourceId
      feedMappingId: $feedMappingId
      conditions: $conditions
      elasticQuery: $elasticQuery
      search: $search
    )
  }
`;

const DataAnalysis = ({
  variables: { organizationId, dataSourceId, conditions, search, elasticQuery },
  additonalButtonsRef,
}) => {
  const { data: variablesData, loading: variablesLoading } = useQuery(QUERY_VARIABLES, {
    variables: { organizationId, dataSourceId },
  });

  const tableVariablesSorted = variablesData?.organization?.dataSource?.tableVariablesSorted || [];
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const [metrics, setMetrics] = useLocalState([], `data-analysis-${dataSourceId}-metrics`);
  const [dimensions, setDimensions] = useLocalState([], `data-analysis-${dataSourceId}-dimensions`);

  const [tempMetrics, setTempMetrics] = useState(metrics);
  const [tempDimensions, setTempDimensions] = useState(dimensions);

  const gqlDimensions = _.filter(generateDimensions(tableVariablesSorted), x => dimensions.includes(x.optionValue)).map(
    ({ data: { value } }) => value
  );
  const gqlMetrics = _.filter(generateMetrics(tableVariablesSorted), x => metrics.includes(x.optionValue)).map(
    ({ data: { graphQlField } }) => graphQlField
  );
  const [execute, { data, loading, error, called }] = useLazyQuery(QUERY_ANALYSIS, {
    variables: {
      dimensions: gqlDimensions,
      metrics: gqlMetrics,
      organizationId,
      dataSourceId,
      conditions,
      search,
      elasticQuery,
    },
  });

  const handleSubmit = e => {
    e.preventDefault();
    setMetrics(tempMetrics);
    setDimensions(tempDimensions);
    execute();
    setDropdownOpen(false);
  };

  const closeDropdown = () => {
    setDropdownOpen(false);
    setTempMetrics(metrics);
    setTempDimensions(dimensions);
  };

  return (
    <React.Fragment>
      {!called && (
        <Form
          dimensions={dimensions}
          handleSubmit={handleSubmit}
          loading={loading}
          metrics={metrics}
          setTempDimensions={setTempDimensions}
          setTempMetrics={setTempMetrics}
          tableVariablesSorted={tableVariablesSorted}
          tempDimensions={tempDimensions}
          tempMetrics={tempMetrics}
          variablesLoading={variablesLoading}
        />
      )}
      {additonalButtonsRef &&
        ReactDOM.createPortal(
          <div className="pos-relative">
            <Button
              icon="columns"
              disabled={!called}
              onClick={dropdownOpen ? closeDropdown : () => setDropdownOpen(true)}
              kind="secondary"
              data-test-id="data-analysis-edit-button"
            >
              {t('data_analysis.edit_dimensions_and_metrics_button')}
              <Icon inheritColor kind="chevron-down" size="10px" className="ml-8" />
            </Button>
            {dropdownOpen && (
              <div className="pos-absolute" style={{ top: '35px', right: '0px', zIndex: 100 }}>
                <Form
                  dimensions={dimensions}
                  disableHeading
                  handleSubmit={handleSubmit}
                  inDropdown
                  loading={loading}
                  metrics={metrics}
                  setTempDimensions={setTempDimensions}
                  setTempMetrics={setTempMetrics}
                  tableVariablesSorted={tableVariablesSorted}
                  tempDimensions={tempDimensions}
                  tempMetrics={tempMetrics}
                  variablesLoading={variablesLoading}
                >
                  <Button tertiary onClick={closeDropdown}>
                    {t('data_analysis.cancel_button')}
                  </Button>
                </Form>
              </div>
            )}
          </div>,
          additonalButtonsRef
        )}
      {error && <pre>{JSON.stringify(error)}</pre>}
      {loading && (
        <Col center>
          <Loader size="big" />
        </Col>
      )}
      {!error && data?.organization?.dataSource?.dataAnalysis?.items ? (
        <DataAnalysisTable
          loading={loading}
          data={loading ? [] : data.organization.dataSource.dataAnalysis.items.map(el => JSON.parse(el))}
          dimensions={dimensions.map(
            d => generateDimensions(tableVariablesSorted).find(it => it.optionValue === d).data
          )}
          metrics={metrics.map(m => generateMetrics(tableVariablesSorted).find(it => it.optionValue === m).data)}
        />
      ) : (
        (dimensions.length > 0 || metrics.length > 0) &&
        called &&
        !loading && <div>{t('data_analysis.no_data_present')}</div>
      )}
    </React.Fragment>
  );
};
DataAnalysis.propTypes = {
  variables: PropTypes.object,
  additonalButtonsRef: PropTypes.any,
};

export default hot(DataAnalysis);
