/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cs from 'classnames';
import * as Layout from './layout';
import ErrorBox from './ErrorBox';
import { Loader } from './Icons';
import { SectionHeading, PageHeading, Row, Col } from './index';
import { t } from '../i18n';

export const useModal = () => {
  const [modalVisibility, setModalVisibility] = React.useState(false);
  const hideModal = () => {
    setModalVisibility(false);
  };
  const showModal = () => {
    setModalVisibility(true);
  };

  const ModalComponent = ({ children, onClose, ...rest }) =>
    modalVisibility && (
      <Modal
        {...rest}
        onClose={() => {
          if (typeof onClose === 'function') onClose();
          hideModal();
        }}
      >
        {children}
      </Modal>
    );

  return { Modal: ModalComponent, hideModal, showModal };
};

export class Modal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    error: PropTypes.string,
    heading: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    id: PropTypes.string,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    setRef: PropTypes.func,
    size: PropTypes.oneOf(['full', 'fullwithGrid', 'medium', 'small', 'dynamic']),
    subHeading: PropTypes.string,
    testId: PropTypes.string,
    'data-test-id': PropTypes.string,
  };

  static defaultProps = {
    size: 'medium',
  };

  componentWillMount() {
    this.container = document.createElement('div', '');
    document.getElementById('react-modal-container').appendChild(this.container);
  }

  componentWillUnmount() {
    if (this.modalRef) {
      $(this.modalRef).modal('hide');
    }
    if (this.container) {
      document.getElementById('react-modal-container').removeChild(this.container);
    }
  }

  setModalContainer = ref => {
    this.modalRef = ref;
    const modal = $(ref).modal({ keyboard: this.props.size !== 'fullwithGrid' });

    if (typeof this.props.setRef === 'function') {
      this.props.setRef(ref);
    }

    if (typeof window.attach_tooltips_to_text_overflow === 'function') {
      window.attach_tooltips_to_text_overflow();
    }

    modal.on('shown.bs.modal', event => {
      modal.on('hide.bs.modal', e => {
        if (!e.isDefaultPrevented()) {
          if (typeof this.props.onClose === 'function') {
            this.props.onClose();
          }
        }
      });
    });

    modal.show();
  };

  container = null;

  render() {
    const { className, children, id, error, loading, heading, subHeading, size, testId } = this.props;

    return ReactDOM.createPortal(
      <div
        className={cs(className, 'modal', 'Modal', `Modal--${size}`, 'fade')}
        ref={this.setModalContainer}
        tabIndex="-1"
        id={id}
        role="dialog"
        data-test-id={this.props['data-test-id']}
      >
        <div className={cs('modal-dialog', 'Modal-dialog')} role="document">
          <div className="Modal-content">
            {heading && size !== 'fullWidth' && (
              <ModalHeader testId={testId}>
                <ModalHeading>{heading}</ModalHeading>
                {subHeading && <ModalSubHeading>{subHeading}</ModalSubHeading>}
              </ModalHeader>
            )}
            {error && (
              <ModalBody>
                <ErrorBox>{error}</ErrorBox>
              </ModalBody>
            )}
            {loading && (
              <ModalBody>
                <div className="mv-24 text-center">
                  <Loader size="big" />
                </div>
              </ModalBody>
            )}
            {!loading && children}
          </div>
        </div>
      </div>,
      this.container
    );
  }
}

export const ModalClose = ({ testId }) => (
  <a href="#" className="modal-close Modal-close fc-close" data-dismiss="modal" data-test-id={`${testId}-close`}>
    {' '}
  </a>
);
ModalClose.propTypes = { testId: PropTypes.string };

export const ModalHeader = ({ children, testId, centered }) => (
  <div className="Modal-header">
    <Layout.Row center noPadding={centered}>
      <Layout.Col grow>{children}</Layout.Col>
      <ModalClose testId={testId} />
    </Layout.Row>
  </div>
);
ModalHeader.propTypes = {
  children: PropTypes.any,
  center: PropTypes.bool,
  testId: PropTypes.string,
  centered: PropTypes.bool,
};

export const ModalHeading = ({ children }) => <SectionHeading spacing={0}>{children}</SectionHeading>;

ModalHeading.propTypes = { children: PropTypes.any };

export const ModalSubHeading = ({ children }) => <div className="Text Text--gray mt-4">{children}</div>;
ModalSubHeading.propTypes = { children: PropTypes.any };

export const ModalBody = ({ children, classNames }) => (
  <div className={cs('modal-body', 'Modal-body', classNames)}>{children}</div>
);
ModalBody.propTypes = {
  children: PropTypes.any,
  classNames: PropTypes.string,
};

export const ModalBodyWithCategories = ({
  groupCategoryOriginal,
  groupCategory,
  selectedCategory,
  setSelectedCategory,
  sidebarAllItem,
  children,
  fullWidthContent,
  sortedCategory,
  ...rest
}) => {
  const groupCategoryKeys = sortedCategory ? Object.keys(groupCategory)?.sort() : Object.keys(groupCategory);

  return (
    <ModalBody {...rest}>
      <Row top justifyCenter={Object.keys(groupCategoryOriginal).length < 2} style={{ minHeight: '472px' }}>
        {Object.keys(groupCategoryOriginal).length > 1 && (
          <Col width="196px" className="mr-32">
            <div className="Navigation">
              <span
                className={cs('Navigation-item', 'mb-4', { active: selectedCategory === null })}
                onClick={() => setSelectedCategory(null)}
              >
                {sidebarAllItem || t('react.create_element_modal.all')}
              </span>
              {groupCategoryKeys.map(category => (
                <span
                  className={cs('Navigation-item', 'mv-4', { active: selectedCategory === category })}
                  key={category}
                  onClick={() => setSelectedCategory(category)}
                >
                  {t(category || 'unknown', { scope: 'react.create_element_modal.category_sidebar' })}
                </span>
              ))}
            </div>
          </Col>
        )}
        <Col width={!fullWidthContent && '480px'} grow={fullWidthContent}>
          {children}
        </Col>
      </Row>
    </ModalBody>
  );
};

ModalBodyWithCategories.defaultProps = {
  fullWidthContent: false,
  sortedCategory: false,
};

ModalBodyWithCategories.propTypes = {
  children: PropTypes.any,
  groupCategory: PropTypes.object,
  groupCategoryOriginal: PropTypes.object,
  selectedCategory: PropTypes.string,
  setSelectedCategory: PropTypes.func,
  sidebarAllItem: PropTypes.string,
  sortedCategory: PropTypes.bool,
  fullWidthContent: PropTypes.bool,
};

export const ModalFooter = ({ children }) => <div className="modal-footer' Modal-footer">{children}</div>;
ModalFooter.propTypes = { children: PropTypes.any };

export default Modal;
