import React from 'react';
import RPT from 'prop-types';
import qs from 'qs';

const Form = ({ onSubmit, children, ...rest }) => {
  const handleSubmit = e => {
    e.preventDefault();
    onSubmit(qs.parse($(e.target).serialize()));
    return false;
  };

  return (
    <form {...rest} onSubmit={handleSubmit}>
      {children}
    </form>
  );
};

Form.propTypes = {
  onSubmit: RPT.func,
  children: RPT.any,
};

export default Form;
