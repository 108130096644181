import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {
  Badge,
  Button,
  Col,
  Icon,
  Link,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalHeading,
  Row,
  Text,
  Tooltip,
  cssVariables,
} from '../index';
import { t, formatInteger, formatNumberInflection } from '../../i18n';
import { TYPES } from './FeedExportsOverview';

const ExportTypesModal = ({ data }) => (
  <ModalBody>
    {data.map(({ kind, count, tooltip }) => (
      <Row center className="mt-16" key={kind}>
        <Col shrink>
          <Icon kind={kind} size="24px" color="#AC8EE3" />
        </Col>
        <Col grow>
          <Text bold>{tooltip}</Text>
        </Col>
        <Col shrink>
          <Text color="gray">
            {formatNumberInflection({
              count,
              scope: 'react.feed_export_overview.modals.export_types_in_use_description',
            })}
          </Text>
        </Col>
      </Row>
    ))}
  </ModalBody>
);

const ExportStatesModal = ({ data }) => (
  <ModalBody>
    {data.map(({ kind, badgeKind, count, tooltip, textColor }) => (
      <Row center className="mt-16" key={kind}>
        <Col grow>
          <Row>
            <Badge kind={badgeKind} size="extraLarge" isWithBorder uppercase className="Badge--spacedText">
              <Icon kind={kind} inheritColor />
              <span style={textColor ? { color: textColor } : {}}>{tooltip}</span>
            </Badge>
          </Row>
        </Col>
        <Col shrink>
          <Text color="gray">
            {formatNumberInflection({
              count,
              scope: 'react.feed_export_overview.modals.exports_statuses_description',
            })}
          </Text>
        </Col>
      </Row>
    ))}
  </ModalBody>
);

const EditorRow = ({ typeName, icon, showLink, name, className, ...rest }) => (
  <Row center className={className} {...rest}>
    <Tooltip text={typeName}>
      <Icon kind={icon} size="24px" color={cssVariables.iconGrape} />
    </Tooltip>
    <Link className="ml-8" href={showLink}>
      {name}
    </Link>
  </Row>
);

const ImageEditorsModal = ({ data }) => (
  <ModalBody>
    {Object.keys(data).map((x, index) => {
      const { dataSource: ds, count, feedExports } = data[x];
      return (
        <React.Fragment key={[x, index].join('-')}>
          <Row
            center
            className={cs({ 'mt-16': index === 0, 'delimiter pb-16 mb-16': index !== Object.keys(data).length - 1 })}
          >
            <Col>
              <Row center className="mb-0">
                <Col grow>
                  <EditorRow {...ds} />
                </Col>
                <Col shrink>
                  <Text color="gray">
                    {formatNumberInflection({
                      count,
                      scope: 'react.feed_export_overview.modals.image_editor_description',
                      binaryOptions: true,
                      otherKeys: { products: formatInteger(ds.itemsCount) },
                    })}
                  </Text>
                </Col>
              </Row>
              {feedExports &&
                feedExports.length > 0 &&
                feedExports.map(fe => <EditorRow className="pl-24 mt-16 mb-0" key={fe.showLink} {...fe} />)}
            </Col>
          </Row>
        </React.Fragment>
      );
    })}
  </ModalBody>
);

const FeedExportModal = ({ toggleModal, modalData: { data, heading, type } }) => {
  let Component = null;
  if (type === TYPES.exportTypes) Component = ExportTypesModal;
  if (type === TYPES.exportStates) Component = ExportStatesModal;
  if (type === TYPES.imageEditor) Component = ImageEditorsModal;
  const close = () => toggleModal(false);

  return (
    <Modal onClose={close} border size="small">
      <ModalHeader>
        <ModalHeading>{heading}</ModalHeading>
      </ModalHeader>
      <Component data={data} />
      <ModalFooter>
        <Button tertiary onClick={close}>
          {t('react.dashboard.insights.modal_close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

const dataProps = { data: PropTypes.array };

FeedExportModal.propTypes = {
  modalData: PropTypes.object,
  toggleModal: PropTypes.func,
};
ImageEditorsModal.propTypes = dataProps;
ExportTypesModal.propTypes = dataProps;
ExportStatesModal.propTypes = dataProps;

EditorRow.propTypes = {
  typeName: PropTypes.string,
  icon: PropTypes.string,
  showLink: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
};

export default FeedExportModal;
