import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { SegmentedButton } from '../form/index';
import { t } from '../../i18n';

const collection = [{ value: 'card', icon: 'blocks' }, { value: 'table', icon: 'list' }];
const explorerCollection = [
  ...collection,
  {
    value: 'investigation',
    icon: 'explorer',
    badge: t('react.new'),
    tooltipText: t('data_analysis.data_explorer'),
  },
];

const ChooseLayout = ({ setLayout, layout, showExplorer }) => {
  const [id] = useState(uniqueId());

  return (
    <SegmentedButton
      name={`products_view[${id}][layout]`}
      id={`layout_${id}`}
      value={layout}
      size="medium"
      onlyIcons
      onChange={e => setLayout(e.target.value)}
      collection={showExplorer ? explorerCollection : collection}
    />
  );
};

ChooseLayout.propTypes = {
  layout: PropTypes.string,
  setLayout: PropTypes.func,
  showExplorer: PropTypes.bool,
};

export default ChooseLayout;
