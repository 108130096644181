import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Ellipsis, Input, Link } from '../index';
import { t } from '../../i18n';
import { HREF, SRC } from './constants';
import { innerText, getNumberFromString } from './utils';

const Empty = () => null;

const ElementeRow = ({ element, remove, index, classNames, handleMouseOver, handleMouseOut, heading, ...rest }) => (
  <Row justifyBetween center shrink className={classNames}>
    {<Col className="Text--gray">{(!index || index === 0) && heading}</Col>}
    <Col
      className="element-row"
      onMouseOver={typeof handleMouseOver === 'function' ? e => handleMouseOver(e, element) : undefined}
      onMouseOut={typeof handleMouseOut === 'function' ? e => handleMouseOut(e) : undefined}
    >
      <Ellipsis>
        <ShowElementValue {...rest} element={element} />
      </Ellipsis>
    </Col>
  </Row>
);

export const ElementsContainer = ({ elements, ...rest }) => {
  const isEmpty = !elements || elements?.length === 0;

  if (isEmpty) return <Empty />;
  if (!Array.isArray(elements)) {
    return <ElementeRow {...rest} element={elements} />;
  }

  return (
    <Row grow className="pos-relative" overflow>
      <Col>
        {elements.map((el, index) => (
          <ElementeRow {...rest} key={[el?.tagName, index].join('-')} element={el} index={index} />
        ))}
      </Col>
    </Row>
  );
};

const ShowElementValue = ({
  value_type = 'string',
  value_source,
  element,
  isInputSelected,
  kind,
  finalSelector,
  testId,
  setFinalSelector,
}) => {
  if (kind && kind === 'input') {
    return (
      <Input
        id="scraper-finalSelector"
        name="scraper[finalSelector]"
        doNotUseInternalState
        value={finalSelector}
        onChange={({ target: { value } }) => setFinalSelector(value)}
        placeholder={'Final selector'}
      />
    );
  }

  if (value_source === HREF) {
    return (
      <Link href={element.href} target="_blank" data-test-id={testId}>
        {element.href}
      </Link>
    );
  }
  if (value_source === SRC) {
    return (
      <Link href={element.src} target="_blank" data-test-id={testId}>
        {element.src}
      </Link>
    );
  }

  const text = isInputSelected ? element.value : innerText(element);

  return (
    <span className="Text Text--gray Text--bold" data-test-id={testId}>
      {value_type === 'string' ? text : getNumberFromString(text)}
    </span>
  );
};

export const OutputContent = ({ elements, select_index, value_type, placeholder_name, ...rest }) => {
  if (elements.length === 0) return <Empty />;
  if (select_index === 'count') {
    return (
      <span data-test-id="output-elements">
        {t('react.scraper.selected_elements_count', { count: elements.length })}
      </span>
    );
  }

  return (
    <ElementsContainer
      {...rest}
      elements={elements}
      heading={placeholder_name}
      value_type={value_type}
      classNames="pn"
      testId="output-elements"
    />
  );
};

ElementeRow.propTypes = {
  classNames: PropTypes.string,
  element: PropTypes.object,
  handleMouseOut: PropTypes.func,
  handleMouseOver: PropTypes.func,
  heading: PropTypes.string,
  index: PropTypes.number,
  remove: PropTypes.func,
};

ShowElementValue.propTypes = {
  element: PropTypes.object,
  finalSelector: PropTypes.string,
  isInputSelected: PropTypes.bool,
  kind: PropTypes.string,
  setFinalSelector: PropTypes.func,
  testId: PropTypes.string,
  value_source: PropTypes.string,
  value_type: PropTypes.string,
};

ElementsContainer.propTypes = {
  elements: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

OutputContent.propTypes = {
  elements: PropTypes.array,
  select_index: PropTypes.string,
  value_type: PropTypes.string,
  placeholder_name: PropTypes.string,
};
