import { useEffect } from 'react';

const useHiddenInputsObserver = (formSelector, callback) => {
  useEffect(() => {
    const formElement = document.querySelector(formSelector);

    if (formElement) {
      // Add event listeners for form input and change events
      formElement.addEventListener('input', callback);
      formElement.addEventListener('change', callback);

      // MutationObserver for hidden inputs
      const observer = new window.MutationObserver(mutationsList => {
        mutationsList.forEach(mutation => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
            callback();
          } else if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
              if (node.nodeType === 1 && node.matches("input[type='hidden']")) {
                observer.observe(node, { attributes: true, attributeFilter: ['value'] });
              }
            });

            mutation.removedNodes.forEach(node => {
              if (node.nodeType === 1 && node.matches("input[type='hidden']")) {
                callback();
              }
            });
          }
        });
      });

      // Initial observation setup
      observer.observe(formElement, { childList: true, subtree: true, attributes: true, attributeFilter: ['value'] });

      // Observe existing hidden inputs
      formElement.querySelectorAll("input[type='hidden']").forEach(hiddenInput => {
        observer.observe(hiddenInput, { attributes: true, attributeFilter: ['value'] });
      });

      return () => {
        formElement.removeEventListener('input', callback);
        formElement.removeEventListener('change', callback);
        observer.disconnect();
      };
    }
    return false;
  }, [formSelector, callback]);
};

export default useHiddenInputsObserver;
