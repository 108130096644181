import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Icon, Text } from '../index';
import { AdPreviewContainer, FbPagePreview } from './index';
import fbActions from '../../../../assets/images/fb_ad_preview/fb_actions.png';

const FbSearchResultPreview = ({ media, pageImageUrl, pageName, label, primaryText, type }) => (
  <AdPreviewContainer
    icon="facebook-square"
    title={t('fb_ad_preview.facebook_search_result', { default: 'Facebook - search result' })}
  >
    <div className="FbFeedPreview-container">
      <div className="FbFeedPreview-header">
        <Row padding="s" center justifyBetween>
          <Col shrink>
            <FbPagePreview
              url={pageImageUrl}
              pageName={pageName}
              label={label}
              imageSize="17px"
              nameSize="7px"
              labelSize="6px"
            />
          </Col>
          <Col shrink>
            <Icon kind="more" style={{ color: '#ACB0B8' }} size="9px" />
          </Col>
        </Row>
        <Text style={{ display: 'block', marginTop: '4px', fontSize: '6px', color: '#050505' }}>{primaryText}</Text>
      </div>
      {type === 'carousel' && (
        <div className="FbFeedPreview-main">
          <div className="FbFeedPreview-main--carousel">
            {media?.map(
              (item, i) =>
                i < 3 && (
                  <div key={item.id} className="FbFeedPreview-main--carouselSearchItem">
                    <div>
                      <img
                        src={item.image_url}
                        alt={t('fb_ad_preview.facebook_feed_preview', { default: 'Facebook feed preview' })}
                      />
                    </div>
                    <Row center justifyBetween className="FbFeedPreview-main--carouselSearchItemFooter">
                      <Text style={{ display: 'block', fontSize: '5px', color: '#050505' }}>
                        {item.name || item.headline}
                      </Text>
                    </Row>
                  </div>
                )
            )}
          </div>
        </div>
      )}
      <div className="FbFeedPreview-footer">
        <img src={fbActions} alt={t('fb_ad_preview.facebook_actions', { default: 'Facebook actions' })} />
      </div>
    </div>
  </AdPreviewContainer>
);

FbSearchResultPreview.propTypes = {
  media: PropTypes.any,
  pageImageUrl: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  primaryText: PropTypes.string,
  headline: PropTypes.string,
  type: PropTypes.oneOf(['single', 'carousel']),
};

export default FbSearchResultPreview;
