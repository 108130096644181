import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { snakeCase } from 'lodash';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
import { Col, Icon, Row, Text } from '../components/index';
import { t } from '../i18n';
import CHART_COLORS from '../components/ChartColors';

const Trend = ({ trend }) => {
  let trendArrow = 'arrow-decrease';

  if (trend === null) {
    trendArrow = null;
  } else if (trend >= -0.1 && trend <= 0.1) {
    trendArrow = 'arrow-right';
  } else if (trend > 0) {
    trendArrow = 'arrow-increase';
  }
  return <Icon className="ml-8" kind={trendArrow} color="inherit" size="8px" />;
};

const ValueWithTrend = ({ isEmpty, format, stats, dataKey, trendField }) => {
  const value = isEmpty ? 0 : stats[dataKey];
  const trend = isEmpty ? null : stats[trendField];

  return (
    <React.Fragment>
      <h5 className="PreviewChart-tabHeading text-left" data-test-id={`preview-value-${dataKey}`}>
        {format ? format({ [dataKey]: value, currency: stats?.currency }) : value}
      </h5>
      <Trend trend={trend} color={'inherit'} />
    </React.Fragment>
  );
};

const PreviewTab = ({ data, dataKey, isEmpty, isSelected, onClick, width, ...rest }) => (
  <Col
    data-test-id={`preview-tab-${dataKey}`}
    onClick={() => onClick(dataKey)}
    width={width}
    className={cs('mr-n pb-n PreviewChart', {
      'PreviewChart--selected': isSelected,
      'PreviewChart--empty': isEmpty,
    })}
  >
    <div className="PreviewChart-tab" data-test-id="preview-box">
      <Text tag="p" size="sm" semibold className="Text--inheritColor mb-n mt-4 text-left">
        {t(snakeCase(dataKey), { scope: 'stats_metrics' })}
      </Text>
      <Row center className="mt-n mb-s">
        <ValueWithTrend isEmpty={isEmpty} dataKey={dataKey} {...rest} />
      </Row>
      <ResponsiveContainer width="100%" height={16}>
        <LineChart data={data} baseValue="dataMin" margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
          <Line dot={false} type="monotone" dataKey={dataKey} stroke={isEmpty ? '#98A2B3' : CHART_COLORS[0]} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  </Col>
);

ValueWithTrend.propTypes = {
  isEmpty: PropTypes.bool,
  format: PropTypes.func,
  stats: PropTypes.object,
  dataKey: PropTypes.string,
  trendField: PropTypes.string,
};
Trend.propTypes = {
  trend: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

PreviewTab.propTypes = {
  data: PropTypes.array,
  dataKey: PropTypes.string,
  isEmpty: PropTypes.bool,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  width: PropTypes.string,
};

export default PreviewTab;
