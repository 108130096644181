/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
/* eslint-disable prefer-spread */

import 'whatwg-fetch';
import React from 'react';
import RPT from 'prop-types';
import withDragDropContext from '../components/withDragDropContext';
import assignIndexOrder from './assignIndexOrder';
import countRestFromElements from './countRestFromElements';
import DragElement from './DragElement.react';
import DropElement from './DropElement.react';
import EmptyLine from './EmptyLine.react';
import EntryCount from './EntryCount';
import findChild from './findChild';
import sortAndFilterElements from './sortAndFilterElements';
import StatusSwitch from './StatusSwitch';
import EmptyState from '../components/EmptyState';
import { CreateElementButton, Row, Col } from '../components/index';
import ErrorBox from '../components/ErrorBox';

const ErrorMessage = ({ error }) => <ErrorBox withIcon>{error}</ErrorBox>;

ErrorMessage.propTypes = {
  error: RPT.string.isRequired,
};

class AdtextTree extends React.Component {
  static contextTypes = {
    dragDropManager: RPT.object,
  };

  static defaultProps = {
    hideHeading: false,
    csrfToken: null,
    elements: {},
    head: {},
    i18n: {},
    updateUrl: null,
    actions: {},
  };

  static propTypes = {
    hideHeading: RPT.bool,
    csrfToken: RPT.string,
    elements: RPT.object.isRequired,
    head: RPT.object.isRequired,
    i18n: RPT.object.isRequired,
    showAllCampaigns: RPT.bool.isRequired,
    hideSystems: RPT.bool,
    emptyStateIcon: RPT.string,
    emptyStateText: RPT.string,
    updateUrl: RPT.string,
    search: RPT.string,
    filterStatus: RPT.string,
  };

  state = {
    page: 0,
    error: null,
    isDragging: false,
    draggingId: null,
    allAdtexts: this.props.filterStatus !== 'running_only',
    searchText: this.props.search || '',
    elements: assignIndexOrder(this.props.elements),
  };

  componentDidMount() {
    this.clearMonitorSubscription = this.context.dragDropManager
      .getMonitor()
      .subscribeToStateChange(this.handleMonitorChange);
    this.initAdditionalJavascript();
  }

  componentDidUpdate() {
    this.initAdditionalJavascript();
  }

  componentWillUnmount() {
    this.clearMonitorSubscription();
  }

  handleChangeStatus = event => {
    const { elements } = this.state;
    const { i18n } = this.props;

    if (event.detail && event.detail.data && event.detail.data.indexOf('base_adtext') !== -1) {
      this.updateAdtexts([], `${event.detail.url}/update_nesting`)
        .then(result => {
          const updatedElements = { ...elements };
          result.itemCounts.forEach(item => {
            updatedElements[item.id].itemsCount = item.itemsCount;
          });
          this.setState({ error: null, elements: updatedElements });
        })
        .catch(e => {
          // eslint-disable-next-line no-console
          console.error(e);
          this.setState({ error: i18n.unable_to_update_adtexts });
        });
    }
  };

  initAdditionalJavascript = () => {
    window.addEventListener('ajaxCallNotificationCenterCompleted', this.handleChangeStatus);

    if (typeof window.attach_tooltips_to_text_overflow === 'function') {
      window.attach_tooltips_to_text_overflow();
    }

    if (typeof document.initLiveFormUpdate === 'function') {
      document.initLiveFormUpdate();
    }
  };

  handleMonitorChange = () => {
    const isDragging = this.context.dragDropManager.getMonitor().isDragging();

    if (!this.canEdit()) {
      return;
    }

    if (isDragging === true) {
      const component = this.context.dragDropManager.getMonitor().registry.pinnedSource.component;
      const draggingId = (component && component.props && component.props.id) || null;
      this.setState({ isDragging, draggingId });
    } else {
      this.setState({ isDragging, draggingId: null });
    }
  };

  changeOrder = (draggedId, droppedId, child = null) => {
    const { elements } = this.state;
    const { updateUrl, i18n } = this.props;
    const dragged = elements[draggedId];
    const newState = {};

    // get maximum index
    if (droppedId === 0) {
      const newIndex =
        Math.max.apply(
          null,
          Object.keys(elements).map(id => elements[id].index || 0)
        ) + 1;
      newState[draggedId] = {
        ...elements[draggedId],
        parent: droppedId,
        index: newIndex,
      };
    } else {
      newState[draggedId] = {
        ...elements[draggedId],
        parent: droppedId,
        index: null,
      };
    }

    // Move Dragged element under parent

    // Move child of dragged element to dragged element parent
    const draggedChildId = findChild(elements, draggedId);
    if (draggedChildId) {
      newState[draggedChildId] = {
        ...elements[draggedChildId],
        parent: dragged.parent,
        index: dragged.index,
      };
    }

    // Move child element of dropped to dragged
    if (droppedId.toString() !== '0') {
      const droppedChildId = findChild(elements, droppedId);
      if (droppedChildId) {
        newState[droppedChildId] = {
          ...elements[droppedChildId],
          parent: draggedId,
        };
      }
    }

    if (child) {
      newState[draggedId].index = elements[child].index;
      newState[child] = { ...elements[child], parent: draggedId };
    }

    if (updateUrl) {
      const data = Object.keys(newState).map(id => ({
        id,
        parent_id: newState[id].parent,
      }));
      this.setState({
        error: null,
        elements: {
          ...elements,
          ...newState,
          [draggedId]: { ...newState[draggedId], loading: true },
        },
      });

      this.updateAdtexts(data)
        .then(result => {
          const finalState = { ...elements, ...newState };
          result.itemCounts.forEach(item => {
            finalState[item.id].itemsCount = item.itemsCount;
          });
          this.setState({ error: null, elements: finalState });

          if (droppedId === 0) {
            setTimeout(this.scrollToAdtext(draggedId), 10);
          }
        })
        .catch(() => {
          this.setState({
            error: i18n.unable_to_change_order,
            elements: { ...elements },
          });
        });
    } else {
      this.setState({ elements: { ...elements, ...newState } });
    }
  };

  scrollToAdtext = draggedId => () => {
    document.getElementsByClassName(`js-a_d_t_e_x_t_${draggedId}`)[0].scrollIntoView();
  };

  updateAdtexts(data, overrideUrl = null) {
    const { updateUrl, csrfToken } = this.props;

    return window
      .fetch(overrideUrl || updateUrl, {
        method: 'PATCH',
        credentials: 'same-origin',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-Token': csrfToken,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ adtext_settings: data }),
      })
      .then(response => {
        if (response.status !== 200) {
          throw new Error('Bad Request');
        }
        return response.json();
      });
  }

  handleChangeFilterState = ({ target: { checked } }) => {
    this.setState({ ...this.state, allAdtexts: checked, page: 0 });
  };

  handleChangeSearch = ({ target: { value } }) => {
    this.setState({ ...this.state, searchText: value, page: 0 });
  };

  handleChangePage = page => () => {
    this.setState({ ...this.state, page });
  };

  canEdit() {
    const { updateUrl } = this.props;
    return !!(updateUrl && updateUrl.length > 0 && updateUrl !== 'false');
  }

  render() {
    const { isDragging, allAdtexts, error, elements, searchText } = this.state;
    const {
      head: { adtext, name, itemsCount, system, conditions, timeRestriction, createButton },
      hideHeading,
      emptyStateIcon,
      emptyStateText,
      i18n,
      showAllCampaigns,
      hideSystems,
    } = this.props;
    const elementIds = sortAndFilterElements(elements, allAdtexts, searchText);

    const isSearched = !!(searchText && searchText.length > 0);
    const canEdit = this.canEdit();

    if (Object.keys(elements).length === 0) {
      return (
        <EmptyState icon={emptyStateIcon} heading={emptyStateText}>
          <CreateElementButton {...createButton} />
        </EmptyState>
      );
    }

    return (
      <div className={!showAllCampaigns && isDragging && 'A_d_t_ext--draging'}>
        {error && <ErrorMessage error={error} />}
        <Row>
          <Col shrink className="mr-40">
            {!hideHeading && <CreateElementButton {...createButton} buttonText="" />}
          </Col>
          <Col shrink>
            {!hideHeading && (
              <form className="app-header__search-form app-header__search-form--200 app-header__search-form--left inline-form fc-search A_d_t_ext-search">
                <div className="Input Input--liveSearch reactSearch Input--noMargin">
                  <input
                    name="search"
                    placeholder="Search"
                    type="text"
                    value={searchText}
                    onChange={this.handleChangeSearch}
                  />
                  <i className="fc-close Input-reset hidden" />
                  <i className="fc-input-search Input-magnifier" />
                </div>
              </form>
            )}
          </Col>
          <Col shrink>
            {!hideHeading && (
              <StatusSwitch
                allAdtexts={allAdtexts}
                i18n={i18n}
                handleChangeFilterState={this.handleChangeFilterState}
              />
            )}
          </Col>
        </Row>
        <div style={{ position: 'relative', overflow: 'visible' }}>
          <DropElement
            outsideDrop
            isFirst
            isFirstOnPage
            id={0}
            elements={elements}
            i18n={i18n}
            index={elementIds.length}
            changeOrder={this.changeOrder}
          />
        </div>
        {elementIds.length === 0 && (isSearched || !allAdtexts) && (
          <div className="pt-40">
            <EmptyState icon={emptyStateIcon} emptySearch={i18n.no_results} />
          </div>
        )}
        {elementIds.length > 0 && (
          <div className="Sticky-Wrapper Sticky-Wrapper--smallTable mt-16">
            <table className="Sticky-Table Sticky-Table--noCellHeight">
              <thead>
                <tr className="Sticky-Header">
                  <th />
                  <th dangerouslySetInnerHTML={{ __html: name }} />
                  <th />
                  <th dangerouslySetInnerHTML={{ __html: adtext }} style={{ minWidth: '380px' }} />
                  <th dangerouslySetInnerHTML={{ __html: itemsCount }} />
                  {!hideSystems && <th dangerouslySetInnerHTML={{ __html: system }} />}
                  <th className="text-center" dangerouslySetInnerHTML={{ __html: conditions }} />
                  <th className="text-center" dangerouslySetInnerHTML={{ __html: timeRestriction }} />
                </tr>
              </thead>
              <tbody className="Sticky-Body pos-relative">
                {elementIds.reduce(
                  (acc, ids) =>
                    acc.concat(
                      ids.map((id, index) =>
                        id > 0
                          ? [
                              index === 0 && !isSearched && (
                                <EntryCount
                                  isDragging={isDragging}
                                  id={id}
                                  hideSystems={hideSystems}
                                  showAllCampaigns={showAllCampaigns}
                                  elements={elements}
                                  parent={elements[id].parent}
                                  i18n={i18n}
                                  actions={elements[id].branchActions}
                                  changeOrder={this.changeOrder}
                                  key={`${id}-allcount`}
                                />
                              ),
                              <DragElement
                                canEdit={canEdit}
                                isSearched={isSearched}
                                allItemsCount={elements[id].allItemsCount}
                                isLast={index === ids.length - 2}
                                handleOverflow
                                i18n={i18n}
                                key={id}
                                id={id}
                                elements={elements}
                                showAllCampaigns={showAllCampaigns}
                                hideSystems={hideSystems}
                                changeOrder={this.changeOrder}
                              />,
                            ]
                          : [
                              !isSearched && (
                                <EmptyLine
                                  restCount={countRestFromElements(elements, elements[id * -1].allItemsCount, id * -1)}
                                  i18n={i18n}
                                  key={id}
                                  id={id * -1}
                                  hideSystems={hideSystems}
                                  createFromRestUrl={elements[id * -1].createFromRestUrl}
                                />
                              ),
                              <tr className="noHover" key={`${id}-drop-outside`}>
                                <td
                                  className="delimiter overflow-visible"
                                  colSpan={8}
                                  style={{
                                    position: 'relative',
                                    height: '30px',
                                  }}
                                >
                                  <DropElement
                                    outsideDrop
                                    isFirst
                                    id={0}
                                    elements={elements}
                                    index={elementIds.length}
                                    i18n={i18n}
                                    changeOrder={this.changeOrder}
                                  />
                                </td>
                              </tr>,
                            ]
                      )
                    ),
                  []
                )}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withDragDropContext(AdtextTree);
