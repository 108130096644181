import React from 'react';
import QueryHolder from '../graphql_provider/query_holder';
import { t } from '../i18n';
import { SmartSelect } from './index';

const getIdFromFieldName = name => name.replace(/\[|\]/g, '_').replace(/_{2,}/g, '_');

const QuerySelect = QueryHolder(({ children, data, onChange, name, value, getCollection, translatedName, error }) => (
  <div className={`Input ${error && 'has-error'}`}>
    <div className="Dropdown">
      <label className="Input-label select optional" htmlFor={getIdFromFieldName(name)}>
        {translatedName}
      </label>
      <SmartSelect
        name={name}
        id={getIdFromFieldName(name)}
        data-test-id={getIdFromFieldName(name)}
        onChange={onChange}
        value={value}
        placeholder={t('react.google_spreadsheet_importer.please_select')}
        initWithoutSelectedValue
        collection={getCollection(data).map(children)}
      />
    </div>
    {error && <span className="Input-errorLabel">{error}</span>}
  </div>
));

export default QuerySelect;
