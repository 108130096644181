import React, { useContext } from 'react';
import { SearchContext } from '../index';
import { SmartSelect } from '../../form';
import { t } from '../../../i18n';

export const Systems = () => {
  const { searchState, searchChange, searchOptions } = useContext(SearchContext);

  if (!searchOptions.systems) {
    return null;
  }

  return (
    <div style={{ minWidth: '210px' }}>
      <SmartSelect
        value={searchState.systems ? searchState.systems.split(',') : []}
        multiple
        id="systems-search"
        name="table_search[select_system]"
        placeholder={t('views.allAdSystems')}
        doNotUseInternalState
        isClearable={false}
        medium
        noMargin
        onChange={({ target: { value } }) => {
          searchChange('systems', value.join(','));
        }}
        collection={[
          {
            label: t('views.shared.empty_container.adwords'),
            value: 'adwords',
            systemIcon: 'adwords',
          },
          {
            label: t('views.shared.empty_container.sklik'),
            value: 'sklik',
            systemIcon: 'sklik',
          },
          {
            label: t('views.shared.empty_container.bing'),
            value: 'bing',
            systemIcon: 'bing',
          },
        ]}
      />
    </div>
  );
};

Systems.propTypes = {};

export default Systems;
