document.addEventListener('DOMContentLoaded', () => {
  const textAreaElems = document.querySelectorAll('.js-adjustable-textarea');

  if (textAreaElems) {
    Array.from(textAreaElems).forEach(textAreaElem => {
      const textArea = textAreaElem;
      textArea.style.height = '32px';

      if (textArea.scrollHeight > 32) {
        const areaSpacing = textArea.scrollHeight >= 66 ? 20 : 2;
        textArea.style.height = `${areaSpacing + textArea.scrollHeight}px`;
      }
    });
  }
});
