/* eslint-disable func-names */
$(document).ready(() => {
  $(document).on('click', '.js-copy-btn', function(e) {
    const textInput = $(this)
      .closest('td')
      .find('.js-copy-input');
    textInput.select();
    e.preventDefault();
    try {
      document.execCommand('copy');
      $(e.target)
        .tooltip('enable')
        .attr('data-original-title', 'Copied')
        .tooltip('show');
      setTimeout(() => {
        $(e.target)
          .tooltip('hide')
          .tooltip('disable');
      }, 1000);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Oops, unable to copy');
    }
  });

  $(document).on('click', '.js-copy-input', function() {
    this.select();
  });
});
