import { createContext } from 'react';

const ProductsViewContext = createContext({
  order: null,
  setOrder: () => {},
  tableVariblesSorted: [],
  cardImage: '',
});

export default ProductsViewContext;
