/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import CreateNewReport from './CreateNewReport';
import ReportSelection from './ReportSelection';
import Report from './Report';

class Browser extends React.Component {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
  };

  state = {
    reportId: null,
  };

  render() {
    const { organizationId } = this.props;
    const { reportId } = this.state;

    return (
      <div>
        <h2>Create new keyword report</h2>
        <CreateNewReport
          organizationId={organizationId}
          selectReport={createdReportId => this.setState({ reportId: createdReportId })}
        />
        <h2>Select from already created reports</h2>
        <ReportSelection
          organizationId={organizationId}
          selectReport={selectedReportId => this.setState({ reportId: selectedReportId })}
          reportId={reportId}
        />

        {reportId && <Report organizationId={organizationId} reportId={reportId} />}
      </div>
    );
  }
}

export default Browser;
