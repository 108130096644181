import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

export const Row = React.memo(({ children, className }) => (
  <div data-test-id="sticky-row" className={cs('Sticky-Row', className)}>
    {children}
  </div>
));
Row.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};

export const Header = React.memo(({ children }) => <div className="Sticky-Header">{children}</div>);
Header.propTypes = {
  children: PropTypes.any,
};

export class Table extends PureComponent {
  static propTypes = {
    children: PropTypes.any,
    numberOfFixedColumns: PropTypes.number,
    evenStriped: PropTypes.bool,
    striped: PropTypes.bool,
  };

  static defaultProps = {
    numberOfFixedColumns: 0,
    striped: true,
    oddStriped: false,
  };

  render() {
    const { numberOfFixedColumns, striped, oddStriped, children } = this.props;

    return (
      <div className="pos-relative">
        <div ref={this.setRef} className="Sticky-Wrapper Sticky-Wrapper--scrollable">
          <div
            className={cs('Sticky-Table', `Sticky-Table--fixedColumns${numberOfFixedColumns}`, {
              'Sticky-Table--striped': striped && !oddStriped,
              'Sticky-Table--oddStriped': oddStriped,
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
}
