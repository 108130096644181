import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import { Row, Col } from '../components/layout';
import Button from '../components/Button';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter } from '../components';

class FacebookType extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
    types: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    type: this.props.types || '',
  };

  handleType = ({ target: { value } }) => {
    if (value !== this.state.type) {
      this.setState({ type: value });
      this.props.updateFieldData(this.props.id, { type: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { type } = this.state;

    return (
      <Row center padding="l" data-test-id="facebook_types" className="mb-8">
        <Col grow data-test-id="facebook_types-section">
          <PlaceholderInput
            defaultValue={type}
            onChange={this.handleType}
            placeholder={t('views.facebook_types.fields.type', { default: 'Type' })}
            {...getSubFiledOptions('types')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.facebook_types.example')} <span class="Text--bold">${t(
            'views.facebook_types.example_text'
          )}</span>`}
          data-field={`facebook_types.${type}`}
        >
          <div className="Text">
            {t('views.facebook_types.example')}{' '}
            <span className="Text--bold">{t('views.facebook_types.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default FacebookType;
