import additionalSources from './additionalSources';
import adtextAdditionalRow from './adtextAdditionalRow';
import assetStats from './assetStats';
import adtextGroups from './adtextGroups';
import auditLogs from './auditLogs';
import bidRules from './bidRules';
import feedExportLogs from './feedExportLogs';
import feedExportItems from './feedExportItems';
import campaignSettings from './campaignSettings';
import dataSources from './dataSources';
import dynamicImages from './dynamicImages';
import fbAdtexts from './fbAdtexts';
import fbAudiences from './fbAudiences';
import fbBudgets from './fbBudgets';
import fbOptimizations from './fbOptimizations';
import fbPlacements from './fbPlacements';
import feedExports from './feedExports';
import keywordSettings from './keywordSettings';
import variables from './variables';
import sitelinks from './siteLinks';
import accountAccesses from './accountAccesses';
import subAccessAccounts from './subAccessAccounts';
import asyncJobLogs from './asyncJobLogs';

export { setupColumn } from './setupColumn';

const mapping = {
  assetStats,
  adtextGroups,
  adtextAdditionalRow,
  auditLogs,
  variables,
  dynamicImages,
  campaignSettings,
  dataSources,
  bidRules,
  fbBudgets: fbBudgets('fb_budgets'),
  fbCampaignBudgets: fbBudgets('fb_campaign_budgets'),
  fbAdSetBudgets: fbBudgets('fb_ad_set_budgets'),
  fbAudiences,
  fbPlacements,
  fbOptimizations,
  fbAdtexts,
  feedExports,
  keywordSettings,
  additionalSources,
  sitelinks,
  accountAccesses,
  subAccessAccounts,
  feedExportLogs,
  feedExportItems,
  asyncJobLogs,
};

export const mappingAdditional = {
  adtextGroups: {
    query: 'itemGroups {id, name}',
    key: 'itemGroups',
  },
  bidRules: {
    query: 'itemGroups {id, name}',
    key: 'itemGroups',
  },
  keywordSettings: {
    query: 'itemGroups {id, name}',
    key: 'itemGroups',
  },
};

export default mapping;
