import qs from 'qs';

export const getNextId = array => (array.length === 0 ? 1 : Math.max(...array.map(({ id }) => id)) + 1);

export const decodeURL = url => {
  const utmParams = {
    utm_source: '',
    utm_medium: '',
    utm_campaign: '',
    utm_content: '',
  };

  const params = [];

  const parserParams = qs.parse(url);

  Object.keys(parserParams).map(key => {
    if (Object.keys(utmParams).indexOf(key) !== -1) {
      utmParams[key] = parserParams[key];
    } else {
      params.push({
        id: getNextId(params),
        name: key,
        value: Array.isArray(parserParams[key]) ? parserParams[key].join(' ') : parserParams[key],
      });
    }
    return -1;
  });

  return { orderedUtmParams: utmParams, params };
};

export const encodeURL = (utmParams = {}, params = []) => {
  const fullObject = params.reduce((out, element) => ({ ...out, [element.name]: element.value }), { ...utmParams });

  return qs.stringify(
    Object.keys(fullObject)
      .filter(key => fullObject[key].length > 0)
      .reduce((out, key) => ({ ...out, [key]: fullObject[key] }), {})
  );
};
