window.addEventListener('load', () => {
  const $root = $('html, body');

  $('.js-scroll').click(function animateScroll() {
    $root.animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 70
    }, 500);

    return false;
  });
});
