import React, { useContext, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button, PreviewInputValue, ImagePreview, Col, Row } from '../index';
import { getImageObj, unmodifiedValue } from './utils';
import ProductsViewContext from './ProductsViewContext';
import ImageSwitcher from './ImageSwitcher';
import FieldKindIcon from '../FieldKindIcon';
import InfoBox from '../InfoBox';
import { t } from '../../i18n';
import Pagination from '../Pagination';

const PAGE_LIMIT = 50;

export const ProductPreviewDataFromContext = props => {
  const { cardImage, tableVariablesSorted } = useContext(ProductsViewContext);

  return <ProductPreviewComponent {...props} cardImage={cardImage} tableVariablesSorted={tableVariablesSorted} />;
};

const ProductPreviewComponent = ({ item, cardImage, tableVariablesSorted }) => {
  const [image, setImage] = useState(cardImage);
  const [currentPage, setCurrentPage] = useState(0);
  const imageObj = getImageObj(tableVariablesSorted, item);
  const imageObjKeysArr = Object.keys(imageObj);
  const slicedImages = imageObjKeysArr.slice(0 + PAGE_LIMIT * currentPage, PAGE_LIMIT * (currentPage + 1));

  const disabledItem = item.__status !== 'enabled';

  return (
    <Row padding="l" className="mt-8" data-test-id="product-preview-content">
      {disabledItem && (
        <InfoBox type="warning" withIcon className="mb-16">
          {t('view.model.adgroup.product_is_removed_from_data_source', {
            default: 'Item is not in data source any more.',
          })}
        </InfoBox>
      )}
      <Col padding="l" className="pos-relative" width="407px">
        <Row shrink data-test-id="preview-component-main-image">
          <ImageSwitcher
            value={image}
            onChange={setImage}
            cardImage={image}
            tableVariablesSorted={tableVariablesSorted}
            item={item}
            size={307}
          >
            <Button
              onlyIcon
              icon="external"
              size="small"
              secondary
              style={{ position: 'absolute', right: '8px', top: '8px' }}
              onClick={() => {
                window.open(imageObj[image].url, '_blank');
              }}
            />
          </ImageSwitcher>
        </Row>
        <Row shrink flexwrap style={{ width: '308px' }}>
          {slicedImages.length &&
            slicedImages.map((key, i) => (
              <Col key={key} width="55px" height="55px" className={cs({ 'mr-0': (i + 1) % 5 === 0 })}>
                <ImagePreview
                  data-test-id="preview-component-small-image"
                  active={key === image}
                  onClick={() => setImage(key)}
                  url={imageObj[key].url}
                  size={50}
                  loaderSize="small"
                />
              </Col>
            ))}
        </Row>
        <Row grow>
          <Col grow>
            <Pagination
              disableAnchor
              isCompact
              current={currentPage}
              changePage={page => () => setCurrentPage(page)}
              maxPages={Math.ceil(imageObjKeysArr?.length / PAGE_LIMIT)}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <table className="Sticky-Table Sticky-Table--narrow">
          <tbody className="Sticky-Body">
            {tableVariablesSorted.map(({ name, placeholderName, showFieldKind }) => (
              <tr key={name} className="Text Text--gray">
                <td className="js-text-overflow" style={{ maxWidth: '100px' }}>
                  <FieldKindIcon showFieldKind={showFieldKind} size="18px" />
                  <span className="ml-8">{name}</span>
                </td>
                <td className="Text--bold js-text-overflow">
                  <PreviewInputValue value={unmodifiedValue({ item, placeholderName })} showFieldKind={showFieldKind} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Col>
    </Row>
  );
};
ProductPreviewComponent.propTypes = {
  item: PropTypes.object.isRequired,
  tableVariablesSorted: PropTypes.array,
  cardImage: PropTypes.string,
};

export default hot(ProductPreviewComponent);
