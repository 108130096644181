export const unmodifiedValue = ({ item, placeholderName }) => {
  const modifiers = item.modifiers || {};
  return item[placeholderName] === undefined ? modifiers[placeholderName] : item[placeholderName];
};

export const getImageObj = (tableVariablesSorted = [], item = {}) => {
  const array = tableVariablesSorted
    .filter(({ isImageUrl }) => isImageUrl)
    .reduce((out, el) => {
      const value = unmodifiedValue({ item, placeholderName: el.placeholderName });
      const arrayValue = Array.isArray(value) ? value : [value];
      let maxCount = el.imagesCount > arrayValue.length ? el.imagesCount : arrayValue.length;
      if (maxCount > 100) {
        maxCount = 100;
      }
      return Array.from(Array(maxCount)).reduce(
        (oOut, x, i) =>
          arrayValue[i] && arrayValue[i].length > 0
            ? [
                ...oOut,
                {
                  key: maxCount > 1 ? `${el.placeholderName}#${i}` : el.placeholderName,
                  url: arrayValue[i],
                },
              ]
            : oOut,
        out
      );
    }, []);

  return array.reduce(
    (out, { key, url }, index) => ({
      ...out,
      [key]: {
        url,
        prev: array[index - 1 < 0 ? array.length - 1 : index - 1].key,
        next: array[index + 1 === array.length ? 0 : index + 1].key,
      },
    }),
    {}
  );
};
export const getImageVariables = ({ tableVariablesSorted }) =>
  tableVariablesSorted
    .filter(({ isImageUrl }) => isImageUrl)
    .map(el =>
      Array.from(Array(el.imagesCount)).map((x, i) => ({
        value: `${el.placeholderName}#${i}`,
        label: `${el.name} #${i}`,
      }))
    )
    .flat();

export const isActiveCondition = array =>
  array.filter(
    cond =>
      !cond._destroy &&
      cond.equation &&
      cond.left_part &&
      (cond.right_part ||
        cond.right_part_variable ||
        cond.right_part_array?.length > 0 ||
        cond.equation === 'MISSING' ||
        cond.equation === 'PRESENT')
  );
