import React from 'react';

const CroppedImagePreview = ({ image, size, style }) => {
  const { crop, source, place } = image;

  const styles = {
    borderRadius: '8px',
    ...style,
  };

  return (
    <div
      className="MediaPreviewCard-imagePreviewContainer"
      style={{ width: size?.width, height: size?.height, ...styles }}
    >
      <div className="MediaPreviewCard-imagePreviewContent">
        {crop && size ? (
          <div
            style={{
              width: `${crop.width}px`,
              height: `${crop.height}px`,
              position: 'relative',
              zoom: crop.width > crop.height ? size?.width / crop.width : size?.height / crop.height,
              overflow: 'hidden',
            }}
          >
            <img
              alt={`Preview for ${place}`}
              src={source}
              style={{ left: `${crop.x * -1}px`, top: `${crop.y * -1}px`, position: 'absolute' }}
            />
          </div>
        ) : (
          <img className="MediaPreviewCard-imageOriginal" src={source} alt={`Preview for ${place}`} />
        )}
      </div>
    </div>
  );
};

export default CroppedImagePreview;
