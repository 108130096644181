import moment from 'moment';
import { pickBy } from 'lodash';

export const createDateRange = (dateStart, dateEnd, step, format = 'YYYY-MM-DD', stepType = 'days') => {
  // Check if inputs are valid
  if (!moment(dateStart).isValid() || !moment(dateEnd).isValid()) return null;
  const dS = moment(dateStart);
  const dE = moment(dateEnd);
  if (dE.diff(dS, stepType) < 0) return null;
  // Create Date Array
  const days = [];
  while (dE.diff(dS, stepType) >= 0) {
    days.push(dS.format(format));
    dS.add(step, stepType);
  }
  return days;
};

export const transformData = (data = [], interval, keys) => {
  const empty = keys.reduce((o, key) => ({ ...o, [key]: 0 }), {});
  return interval.map(date => ({
    ...empty,
    ...pickBy((data || []).find(d => d.date === date) || {}, v => v && v !== 'null'),
    date,
  }));
};

export const setCorrectAnchor = ({ start, end }, value) => {
  if (start === value) return { textAnchor: 'left' };
  else if (end === value) return { offsetX: 32, textAnchor: 'right' };
  return null;
};
