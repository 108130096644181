import React, { memo } from 'react';
import PropTypes from 'prop-types';
import SmartSelect from '../SmartSelect';

const FBAdImageIndexSelect = memo(({ collection, value, ...rest }) => (
  <SmartSelect className="SmartSelect--imageSelector" value={value} {...rest} collection={collection} />
));

FBAdImageIndexSelect.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  collection: PropTypes.any,
  multiple: PropTypes.boolean,
};

export default FBAdImageIndexSelect;
