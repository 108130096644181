import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SearchContext } from '../index';
import { Input } from '../../form';
import { t } from '../../../i18n';

export const FulltextSearch = ({ compactSearch }) => {
  const { searchState, searchChange } = useContext(SearchContext);
  return (
    <Input
      type="text"
      name="table_search[fulltext]"
      id="search-fulltext"
      doNotUseInternalState
      value={searchState.fulltext || ''}
      onChange={({ target: { value } }) => searchChange('fulltext', value)}
      placeholder={t('react.search')}
      search
      hasCompactWidth={compactSearch}
      noMargin
    />
  );
};

FulltextSearch.propTypes = { compactSearch: PropTypes.bool };

export default React.memo(FulltextSearch);
