import React from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Header } from './components';
import { Loader } from '../Icons';

export const GrayBox = ({ width, short, long, medium, withoutMargin = false, height = false, maxWidth }) => (
  <div
    className="animated-loading"
    style={{
      backgroundColor: '#efefef',
      height: height || '15px',
      width:
        width ||
        (short && `${30 + Math.floor(Math.random() * 50 + 1)}px`) ||
        (medium && `${40 + Math.floor(Math.random() * 70 + 1)}px`) ||
        (long && `${100 + Math.floor(Math.random() * 130 + 1)}px`),
      borderRadius: '5px',
      marginRight: withoutMargin ? '0px' : '10px',
      marginLeft: withoutMargin ? '0px' : width !== '15px' && '10px',
      maxWidth,
    }}
  />
);

GrayBox.propTypes = {
  width: PropTypes.string,
  short: PropTypes.bool,
  long: PropTypes.bool,
  medium: PropTypes.bool,
  height: PropTypes.string,
  withoutMargin: PropTypes.bool,
  maxWidth: PropTypes.string,
};

const CellContent = ({ children, short, long, medium }) => (
  <div className="Sticky-Cell" style={{ maxWidth: '250px' }}>
    <div style={{ display: 'flex' }}>
      {children}
      <GrayBox short={short} long={long} medium={medium} />
    </div>
  </div>
);
CellContent.propTypes = {
  children: PropTypes.any,
  short: PropTypes.bool,
  long: PropTypes.bool,
  medium: PropTypes.bool,
};

const arr = num => Array.from({ length: num }, (el, i) => i);

const LoadingTable = ({ allowSelect, cols, rows }) => (
  <Table oddStriped>
    <Header isHeader>
      <CellContent medium>
        {allowSelect && <GrayBox width="15px" />}
        <Loader size="small" />
      </CellContent>
      {arr(cols || 5).map(h => (
        <CellContent key={h} medium />
      ))}
      <div className="Sticky-Cell" />
      <div className="Sticky-Cell" />
      <div className="Sticky-Cell" />
    </Header>
    {arr(rows || 9).map((b, index) => (
      <Row key={b}>
        <CellContent long>{allowSelect && <GrayBox width="15px" />}</CellContent>
        {arr(cols || 5).map(c => (
          <CellContent key={c} medium={index % 2 === 0} short={index % 2 !== 0} />
        ))}
      </Row>
    ))}
  </Table>
);

LoadingTable.propTypes = { allowSelect: PropTypes.bool, cols: PropTypes.number, rows: PropTypes.number };

export const LoadingTableRows = ({ rows, cols, maxColWidth }) => (
  <>
    {arr(rows || 9).map((b, index) => (
      <Row key={b}>
        {arr(cols).map(c => (
          <td key={c}>
            <GrayBox medium={index % 2 === 0} short={index % 2 !== 0} maxWidth={maxColWidth} />
          </td>
        ))}
      </Row>
    ))}
  </>
);

LoadingTableRows.propTypes = {
  cols: PropTypes.number.isRequired,
  rows: PropTypes.number,
  maxColWidth: PropTypes.string,
};

export default LoadingTable;
