import { setupColumn } from '../setupColumn';
import { TextCell } from '../cells';
import { t } from '../../../../i18n';
import { facebookHeader } from '../systemHeaders';

const category = 'facebook';

export const facebookMapping = {
  ...setupColumn({
    id: 'facebookSystemId',
    graphqlFields: { fbSetting: ['systemId'] },
    category,
    order: 'fb_setting.system_id',
    header: () => facebookHeader(t('activerecord.attributes.fb_setting.system_id')),
    cell: TextCell,
    resolveChildren: data => data && data.fbSetting && data.fbSetting.systemId,
  }),
};

export default facebookMapping;
