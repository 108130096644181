import React, { useState, useContext, createRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { gql, useMutation } from '@apollo/client';
import { Button } from '../../../../index';
import uploadFile from '../uploadFile';
import OptionsContext from '../../../OptionsContext';
import { t } from '../../../../../i18n';

const UploadNewImage = ({ forceOpen, updateImage }) => {
  const { organizationId, cannotEdit } = useContext(OptionsContext);
  const [wasForcedOpened, setForcedOpened] = useState(false);
  const refDropzone = createRef();

  useEffect(() => {
    if (!wasForcedOpened && forceOpen && refDropzone.current) {
      refDropzone.current.open();
      setForcedOpened(true);
    }
  });

  const [error, setError] = useState(null);
  const [isUploading, setUploading] = useState(0);

  const [createImage, { loading }] = useMutation(
    gql`
      mutation CreateImage($organizationId: BigInt!, $filename: String, $imageData: String) {
        createImage(organizationId: $organizationId, filename: $filename, imageData: $imageData) {
          image {
            id
            url
          }
          errors
        }
      }
    `,
    {
      onCompleted: data => {
        updateImage('xlinkHref', data.createImage.image.url);
        setUploading(prev => prev - 1);
        setError(null);
      },
      onError: err => {
        setError(err);
        setUploading(prev => prev - 1);
      },
    }
  );

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }

    acceptedFiles.forEach(file => {
      setUploading(prev => prev + 1);
      // Get image resolution
      const reader = new FileReader();
      reader.onload = entry => {
        const image = new Image();
        image.src = entry.target.result;
        image.onload = () => {
          updateImage('width', image.width, { height: image.height, filename: file.name });
        };
      };
      reader.readAsDataURL(file);

      const successCallback = signedId =>
        createImage({
          variables: {
            organizationId,
            filename: file.name,
            imageData: signedId,
          },
        });
      const errorCallback = err => {
        setError(err);
        setUploading(prev => prev - 1);
      };

      uploadFile(file, successCallback, errorCallback);
    });
  };

  return (
    <Dropzone
      accept="image/png,image/gif,image/jpg,image/jpeg,image/pjpeg,image/tiff,image/bmp,image/webp,image/svg,image/svg+xml"
      onDrop={onDrop}
      multiple
      maxSize={4 * 1024 * 1024}
      ref={refDropzone}
      style={{
        float: 'left',
        marginRight: '3px',
        padding: '3px',
        width: '100%',
        textAlign: 'center',
      }}
      activeStyle={{
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      {({ getRootProps, getInputProps, fileRejections }) => {
        const unsupportedFile = fileRejections?.[0]?.errors?.[0]?.code === 'file-invalid-type';
        function getErrorMsgKey() {
          if (error) return 'server_error';
          if (unsupportedFile) return 'file_invalid_type';
          return 'upload_error';
        }
        const { ref, ...rootProps } = getRootProps();
        return (
          <React.Fragment>
            <Button
              secondary
              disabled={cannotEdit}
              loadingType="upload"
              className="pos-relative"
              loading={isUploading > 0 || loading}
              error={error}
              {...rootProps}
              setRef={ref}
            >
              <input {...getInputProps()} />
              <span>{t('react.image_generator.modal.upload_button')}</span>
            </Button>
            {(error || (fileRejections && fileRejections.length > 0)) && (
              <span className="ml-16 ValidationError">
                {t(getErrorMsgKey(), { scope: 'react.image_generator.modal' })}
              </span>
            )}
          </React.Fragment>
        );
      }}
    </Dropzone>
  );
};

export default UploadNewImage;
