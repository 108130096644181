import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { sortBy, flatten, groupBy } from 'lodash';
import { Icon, Tooltip, Badge } from './index';
import { t } from '../i18n';

export const CONDITION_OR = 'or';
export const CONDITION_AND = 'and';

export const convertConditionsAndItemGroupsToConditions = ({ conditions, conditionsOptions }) =>
  flatten(
    conditions
      ? conditions
          .filter(
            cond =>
              !cond._destroy &&
              ((cond.equation &&
                cond.left_part &&
                (cond.right_part ||
                  cond.right_part_variable ||
                  cond.right_part_array ||
                  cond.equation === 'MISSING' ||
                  cond.equation === 'PRESENT')) ||
                cond.selected_item_group_id)
          )
          .map(cond => ({
            ...cond,
            selected_item_group_name: conditionsOptions.item_groups.find(ig => ig.id === cond.selected_item_group_id)
              ?.name,
          }))
      : []
  );

const AND_JOINER = ' <span class="ConditionsContent-text--orange"><b>AND</b></span> ';
const OR_JOINER = ' <span class="ConditionsContent-text--green"><b>OR</b></span> ';
const NOT_JOINER = ' <span class="ConditionsContent-text--red"><b>NOT</b></span> ';

const parseConditionFromPossibleString = condition =>
  typeof condition?.toDataPreview === 'string' ? JSON.parse(condition.toDataPreview) : condition;

const parseConditionsFromArrayOfString = conditions => conditions.map(parseConditionFromPossibleString);

const formatConditionTooltip = ({
  leftPart,
  left_part,
  negative,
  humanizedEquation,
  equation,
  rightPart,
  right_part,
  rightPartArray,
  right_part_array,
  right_part_variable,
  dynamic_value_selection,
  dynamic_value_percent,
  dynamic_value_count,
  dynamic_value_aggregation_function,
  rightPartVariable,
  selected_item_group_id,
  selected_item_group_name,
}) => {
  const right = rightPartArray || right_part_array || [];
  const shortRight = [].concat(right).slice(0, 5);
  const rightText = rightPart || right_part;
  const rightVariable =
    right_part_variable?.replace('_modifiers.', '') || rightPartVariable?.replace('_modifiers.', '');
  const isProductSet = !!selected_item_group_id;

  if (isProductSet) {
    return `<span class="nowrap">${
      negative ? NOT_JOINER : ''
    }<span class="Icon fc-item-group"></span> ${selected_item_group_name || 'Product Set'}</span>`;
  }

  return `
        <span>
          ${
            equation === 'HIGHEST' || equation === 'LOWEST'
              ? `<span class="placeholder-text d-inline">
            <span class="placeholder-box">${dynamic_value_percent || dynamic_value_count}${
                  dynamic_value_aggregation_function === 'percent'
                    ? t(dynamic_value_aggregation_function, { scope: 'react.filters_and_conditions' })
                    : ''
                }</span>
          </span>
           <span>${t('of_products_with', { default: 'of products with', scope: 'react.filters_and_conditions' })}</span>
           <span class="ConditionsContent-text">${humanizedEquation ||
             t(equation, { scope: 'condition_equations_texts' })}</span>
            <span class="placeholder-box">${(leftPart || left_part || '').replace('_modifiers.', '')}</span>
           <span>${t('from', { default: 'from', scope: 'react.filters_and_conditions' })}</span>
            <span class="placeholder-text d-inline">
            <span class="placeholder-box">${t(dynamic_value_selection, {
              scope: 'react.filters_and_conditions',
            })}</span>
          </span>`
              : `<span class="placeholder-text d-inline">
            <span class="placeholder-box">${(leftPart || left_part || '').replace('_modifiers.', '')}</span>
          </span>
          <span class="ConditionsContent-text">${humanizedEquation ||
            t(equation, { scope: 'condition_equations_texts' })}</span>
          <b>
          ${(equation === 'MISSING' ? ' ' : null) ||
            (equation === 'PRESENT' ? ' ' : null) ||
            (rightText && (rightText.length > 40 ? `${rightText.slice(0, 39)}&hellip;` : rightText)) ||
            shortRight.join(', ')}
          ${right.length > 5 ? `, + ${right.length - 5} more` : ''}
          ${
            rightVariable
              ? `<span class="placeholder-text d-inline">
            <span class="placeholder-box">${rightVariable}</span>
          </span>`
              : ''
          }
          </b>`
          }
        </span>`;
};

// eslint-disable-next-line consistent-return
const formatAnyConditionTooltip = (condition, itemGroups) => {
  const leftPart = condition.left_part || condition.leftPart;
  const orConditions = condition.or_conditions || condition.orConditions;
  const productSets = condition.product_sets || condition.productSets;
  const negativeProductSets = condition.negative_product_sets || condition.negativeProductSets;

  if (leftPart) {
    return formatConditionTooltip(condition);
  } else if (orConditions) {
    if (orConditions.length > 1) {
      return `<span class="ConditionsContent-text">(</span><span>${orConditions
        .map(orCondition => formatConditionTooltip(orCondition, itemGroups))
        .join(
          orConditions?.[0].group_type === CONDITION_AND ? AND_JOINER : OR_JOINER
        )}</span><span class="ConditionsContent-text">)</span>`;
    }
    return formatConditionTooltip(orConditions[0], itemGroups);
  } else if (productSets) {
    const productSetsText = productSets
      .map(product_set => `<span class="nowrap"><span class="Icon fc-item-group"></span> ${product_set.name}</span>`)
      .join(OR_JOINER);

    return productSets.length > 1 ? `(${productSetsText})` : productSetsText;
  } else if (negativeProductSets) {
    const negativeProductSetsText = negativeProductSets
      .map(product_set => `<span class="nowrap"><span class="Icon fc-item-group"></span> ${product_set.name}</span>`)
      .join(OR_JOINER);

    return `${NOT_JOINER} ${
      negativeProductSets.length > 1
        ? `<span class="ConditionsContent-text">(</span><span> ${negativeProductSetsText} </span><span class="ConditionsContent-text">)</span>`
        : negativeProductSetsText
    }`;
  }
};

const formatConditions = (conditions, itemGroups) => {
  const sortedConditions = sortBy(
    conditions,
    condition =>
      (condition.left_part && 1) ||
      (condition.leftPart && 1) ||
      (condition.or_conditions && 2) ||
      (condition.orConditions && 2) ||
      (condition.product_sets && 3) ||
      (condition.productSets && 3) ||
      (condition.negative_product_sets && 4) ||
      (condition.negativeProductSets && 4) ||
      5
  );

  const basicConditions = sortedConditions.filter(
    condition => condition.left_part || condition.leftPart || condition.selected_item_group_id
  );
  const otherConditions = sortedConditions.filter(
    condition => !(condition.left_part || condition.leftPart || condition.selected_item_group_id)
  );
  const basicGrouped = groupBy(basicConditions, condition => condition.group_id || condition.groupId);

  const basicWithOr = flatten(
    Object.keys(basicGrouped).map(orGroup =>
      orGroup === 'undefined' ? basicGrouped[orGroup] : { or_conditions: basicGrouped[orGroup] }
    )
  );

  const group_type = basicWithOr?.[0]?.or_conditions?.[0]?.group_type || basicWithOr?.[0]?.orConditions?.[0]?.groupType;

  return basicWithOr
    .concat(otherConditions)
    .map(item => formatAnyConditionTooltip(item, itemGroups))
    .filter(x => !!x)
    .join(group_type === 'and' ? OR_JOINER : AND_JOINER);
};

export const ConditionsBadgeTooltipContent = ({ conditions: inputConditions, whiteBackground, itemGroups }) => {
  const conditions = parseConditionsFromArrayOfString(inputConditions);

  return (
    <div
      className={cs('ConditionsContent', { 'ConditionsContent--white': whiteBackground })}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: formatConditions(conditions, itemGroups) }}
    />
  );
};
ConditionsBadgeTooltipContent.propTypes = {
  conditions: PropTypes.array,
  whiteBackground: PropTypes.bool,
  itemGroups: PropTypes.array,
};

const ConditionsBadge = ({ conditions: inputConditions, itemGroups }) => {
  if (!inputConditions || inputConditions.length === 0) {
    return null;
  }

  const conditions = parseConditionsFromArrayOfString(inputConditions?.flatMap(i => i));

  const productSets = conditions.filter(({ type }) => type === 'ProductSetGroup' || type === 'ProductSet');
  const productSetsCount = productSets.reduce((acc, nps) => acc + (nps.product_sets || nps.productSets).length, 0);
  const negativeProductSets = conditions.filter(({ type }) => type === 'NegativeProductSetGroup');
  const negativeProductSetsCount = negativeProductSets.reduce(
    (acc, nps) => acc + (nps.negative_product_sets || nps.negativeProductSets).length,
    0
  );

  const basicConditions = flatten(
    flatten(conditions)
      .filter(
        ({ type, selected_item_group_id }) =>
          (type === 'Condition' || type === 'ConditionOrGroup' || !type) && !selected_item_group_id
      )
      .map(c => c.or_conditions || c.conditions || [c])
  );

  const productSetConditions = flatten(conditions)
    .filter(({ selected_item_group_id }) => !!selected_item_group_id)
    .map(c => c.or_conditions || c.conditions || [c]);

  const hasConditions = basicConditions.length > 0;
  const hasProductSets = productSetsCount > 0;
  const hasNegativeProductSets = negativeProductSetsCount > 0;
  const hasProductSetConditions = productSetConditions.length > 0;

  if (!hasConditions && !hasProductSets && !hasNegativeProductSets && !hasProductSetConditions) {
    return null;
  }
  return (
    <React.Fragment>
      <Tooltip
        data-test-id="conditions-badge-tooltip"
        placement="top"
        reactContent={<ConditionsBadgeTooltipContent conditions={flatten(conditions)} itemGroups={itemGroups} />}
      >
        <Badge kind="lightOrange" size="medium">
          {hasConditions > 0 && (
            <React.Fragment>
              <Icon kind="filter" inheritColor />
              <span>{basicConditions.length}</span>
            </React.Fragment>
          )}
          {!!productSetConditions.length && (
            <React.Fragment>
              <Icon className={cs({ 'ml-8': hasConditions })} kind="item-group" inheritColor />
              <span>{productSetConditions.length}</span>
            </React.Fragment>
          )}
          {hasProductSets && (
            <React.Fragment>
              <Icon className={cs({ 'ml-8': hasConditions })} kind="item-group" inheritColor />
              <span>{productSetsCount}</span>
            </React.Fragment>
          )}
          {hasNegativeProductSets && (
            <React.Fragment>
              <span className={cs({ 'ml-8': hasProductSets })}>-</span>
              <Icon kind="item-group" inheritColor />
              <span>{negativeProductSetsCount}</span>
            </React.Fragment>
          )}
        </Badge>
      </Tooltip>
    </React.Fragment>
  );
};
ConditionsBadge.propTypes = {
  conditions: PropTypes.array,
  itemGroups: PropTypes.array,
};

export default ConditionsBadge;
