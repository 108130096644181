import React from 'react';
import RPT from 'prop-types';
import classNames from 'classnames';
import InputWithPlaceholders from './Editor';

export default class PlaceholderInput extends React.Component {
  static propTypes = {
    defaultValue: RPT.any,
    hierarchyName: RPT.bool,
    id: RPT.string.isRequired,
    label: RPT.string,
    multiline: RPT.bool,
    name: RPT.string.isRequired,
    onChange: RPT.func,
    readOnly: RPT.bool,
    error: RPT.string,
  };

  state = {
    container: null,
  };

  setContainerRef = el => {
    this.setState({ container: el });
  };

  render() {
    const { defaultValue, hierarchyName, multiline, readOnly, id, name, onChange, label, error, ...rest } = this.props;
    const { container } = this.state;
    return (
      <React.Fragment>
        {label && <label htmlFor={id}>{label}</label>}
        <div className={classNames('Input', { 'has-error': error })}>
          <div ref={this.setContainerRef} className={classNames('Input-reactInput')}>
            {container && (
              <InputWithPlaceholders
                {...rest}
                container={container}
                defaultValue={defaultValue}
                hierarchyName={hierarchyName}
                onChange={onChange}
                useInternalInput
                inputProps={{ id, name, 'data-test-id': id || 'test-input-placeholders' }}
                multiline={multiline}
                readOnly={readOnly}
              />
            )}
          </div>
        </div>
        {error && <span className="Input-errorLabel">{error}</span>}
      </React.Fragment>
    );
  }
}
