import React from 'react';
import ReactDOM from 'react-dom';
import moment from 'moment';
import { DateInput } from '../components/form';

window.initFormDatePicker = () => {
  const containers = document.getElementsByClassName('react-form-date-picker');
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      if (!container.classList.contains('form-date-picker-mounted')) {
        const input = container.getElementsByTagName('input')[0];
        const readOnly = input.disabled || (input.closest('fieldset') && input.closest('fieldset').disabled);

        const contentContainer = container.getElementsByClassName('react-form-date-picker-content')[0];

        const inputProps = {
          id: input.getAttribute('id'),
          value: input.getAttribute('value'),
          dataTestId: input.getAttribute('data-test-id'),
          locale: window.I18nLocale,
        };
        input.removeAttribute('id');
        input.removeAttribute('data-test-id');

        let minDate = null;
        let maxDate = null;

        const renderDateInput = () => {
          if (contentContainer) {
            ReactDOM.render(
              <DateInput
                input={input}
                maxDate={maxDate}
                minDate={minDate}
                onChange={({ target: { value } }) => {
                  input.value = value;
                  input.dispatchEvent(new window.Event('change', { bubbles: true }));
                }}
                readOnly={readOnly}
                {...inputProps}
              />,
              contentContainer
            );
          }
        };

        if (
          input.dataset.datepickerMaxDateFrom &&
          document.querySelector(`[data-datepicker-id="${input.dataset.datepickerMaxDateFrom}"]`)
        ) {
          const inputMax = document.querySelector(`[data-datepicker-id="${input.dataset.datepickerMaxDateFrom}"]`);
          maxDate = inputMax.value.length > 5 ? moment(inputMax.value).toDate() : null;
          inputMax.addEventListener('change', e => {
            maxDate = e.target.value.length > 5 ? moment(e.target.value).toDate() : null;
            renderDateInput();
          });
        }
        if (
          input.dataset.datepickerMinDateFrom &&
          document.querySelector(`[data-datepicker-id="${input.dataset.datepickerMinDateFrom}"]`)
        ) {
          const inputMin = document.querySelector(`[data-datepicker-id="${input.dataset.datepickerMinDateFrom}"]`);
          minDate = inputMin.value.length > 5 ? moment(inputMin.value).toDate() : null;
          inputMin.addEventListener('change', e => {
            minDate = e.target.value.length > 5 ? moment(e.target.value).toDate() : null;
            renderDateInput();
          });
        }

        if (readOnly) {
          contentContainer.classList.add('disabled');
        }

        // Fix enable/disable when date picker is used in form show_if_fieldset
        setInterval(() => {
          if (input.disabled || (input.closest('fieldset') && input.closest('fieldset').disabled)) {
            contentContainer.classList.add('disabled');
          } else {
            contentContainer.classList.remove('disabled');
          }
        }, 100);

        renderDateInput();
        container.classList.add('form-date-picker-mounted');
      }
    });
  }
};
