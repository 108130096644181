/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class AukroParam extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    identifier: PropTypes.string,
    value: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    identifier: this.props.identifier || '',
    name: this.props.name || '',
    value: this.props.value || '',
  };

  handleIdentifier = ({ target: { value } }) => {
    if (value !== this.state.identifier) {
      this.setState({ identifier: value });
      this.props.updateFieldData(this.props.id, { identifier: value });
      updatePreview();
    }
  };

  handleName = ({ target: { value } }) => {
    if (value !== this.state.name) {
      this.setState({ name: value });
      this.props.updateFieldData(this.props.id, { name: value });
      updatePreview();
    }
  };

  handleValue = ({ target: { value } }) => {
    if (value !== this.state.value) {
      this.setState({ value });
      this.props.updateFieldData(this.props.id, { value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { value, name, identifier } = this.state;

    return (
      <Row center padding="l" data-test-id="aukro-params" className="mb-8">
        <Col width="10%" data-test-id="aukro_params-identifier" className="mr-8">
          <PlaceholderInput
            defaultValue={identifier}
            onChange={this.handleIdentifier}
            placeholder={t('views.aukro_params.fields.identifier', { default: 'ID' })}
            {...getSubFiledOptions('identifier')}
          />
        </Col>
        <Col grow data-test-id="aukro-params-name" className="mr-8">
          <PlaceholderInput
            defaultValue={name}
            onChange={this.handleName}
            placeholder={t('views.aukro_params.fields.name', { default: 'Name' })}
            {...getSubFiledOptions('name')}
          />
        </Col>
        <Col grow data-test-id="aukro-params-value">
          <PlaceholderInput
            defaultValue={value}
            onChange={this.handleValue}
            placeholder={t('views.aukro_params.fields.value', { default: 'Value' })}
            {...getSubFiledOptions('value')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.aukro_params.example')} <span class="Text--bold">${t(
            'views.aukro_params.example_text'
          )}</span>`}
          data-field={`aukro_params.${identifier}`}
        >
          <div className="Text">
            {t('views.aukro_params.example')} <span className="Text--bold">{t('views.aukro_params.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default AukroParam;
