import { DirectUpload } from '@rails/activestorage';
import { v4 } from 'uuid';
import { aspectRatios, defaultAspectRatioOptions, defaultCropSettings } from './meta';

export const uploadFile = (file, successCallback, errorCallback) => {
  const url = '/rails/active_storage/direct_uploads';
  const upload = new DirectUpload(file, url);

  upload.create((error, blob) => {
    if (error) {
      errorCallback(error);
    } else {
      successCallback(blob.signed_id);
    }
  });
};

export function getDefaultCrop(imageData, aspect) {
  if (!aspect) {
    return null;
  }
  let cropWidth = 0;
  let cropHeight = 0;

  if (aspect < 1) {
    // "tall" crop
    cropWidth = Math.min(imageData.height * aspect, imageData.width);
    cropHeight = cropWidth / aspect;
  } else {
    // "wide" or square crop
    cropHeight = Math.min(imageData.width / aspect, imageData.height);
    cropWidth = cropHeight * aspect;
  }

  return {
    ...defaultCropSettings,
    aspect,
    x: Math.floor((imageData.width - cropWidth) / 2),
    y: Math.floor((imageData.height - cropHeight) / 2),
    width: cropWidth,
    height: cropHeight,
  };
}

export function getPreviewCrop(preview, crop) {
  if (!preview) {
    return crop;
  }

  const scaleX = preview.naturalWidth / preview.width;
  const scaleY = preview.naturalHeight / preview.height;

  return {
    ...crop,
    x: crop.x / scaleX,
    y: crop.y / scaleY,
    width: crop.width / scaleX,
    height: crop.height / scaleY,
  };
}

export function getNaturalCrop(preview, crop) {
  if (!preview) {
    return crop;
  }

  const scaleX = preview.naturalWidth / preview.width;
  const scaleY = preview.naturalHeight / preview.height;

  return {
    ...crop,
    x: Math.round(crop.x * scaleX),
    y: Math.round(crop.y * scaleY),
    width: Math.round(crop.width * scaleX),
    height: Math.round(crop.height * scaleY),
  };
}

export const getAspectRatioOptions = (media, type) => {
  const options = [defaultAspectRatioOptions];
  if (type === 'single') {
    const aspectRatio = aspectRatios.find(a => a.forMediaPlaces.includes(media?.place));
    const defaultCrop = getDefaultCrop(media, aspectRatio?.aspect);
    options.push({
      value: aspectRatio.aspect,
      label: aspectRatio.label,
      disabled: aspectRatio.minWidth > defaultCrop.width || aspectRatio.minHeight > defaultCrop.height,
    });
  } else {
    aspectRatios.forEach(aspectRatio => {
      const defaultCrop = getDefaultCrop(media, aspectRatio?.aspect);
      options.push({
        value: aspectRatio.aspect,
        label: aspectRatio.label,
        disabled: aspectRatio.minWidth > defaultCrop.width || aspectRatio.minHeight > defaultCrop.height,
      });
    });
  }
  return options;
};

export const getPreviewSize = (media, adType, sizeType) => {
  if (adType === 'single') {
    return aspectRatios.find(a => a.forMediaPlaces.includes(media?.place))?.previewSizes[sizeType];
  } else {
    return media.crop
      ? aspectRatios.find(a => a.aspect.toString() === media.crop?.aspect.toString())?.previewSizes[sizeType]
      : null;
  }
};

export const buildMediaFromProps = propsMedia => {
  if (propsMedia) {
    const mediaArray = [];
    propsMedia.forEach(line => {
      mediaArray.push({
        place: line.place,
        line_id: line.id,
        image_id: line.image_asset?.image_id,
        asset_id: line.image_asset?.id,
        source: line.image?.url,
        width: line.image?.width,
        height: line.image?.height,
        crop: line.image_asset?.aspect
          ? {
              x: line.image_asset?.crop_x,
              y: line.image_asset?.crop_y,
              height: line.image_asset?.crop_height,
              width: line.image_asset?.crop_width,
              aspect: line.image_asset?.aspect,
            }
          : null,
        id: v4(),
      });
    });
    return mediaArray;
  } else {
    return [];
  }
};

export const buildCarouselMediaFromProps = propsMedia => {
  if (propsMedia) {
    const mediaArray = [];
    propsMedia.forEach(line => {
      mediaArray.push({
        line_id: line.id,
        image_id: line.image_asset?.image_id,
        asset_id: line.image_asset?.id,
        source: line.image?.url,
        name: line.image?.filename,
        width: line.image?.width,
        height: line.image?.height,
        crop: line.image_asset?.aspect
          ? {
              x: line.image_asset?.crop_x,
              y: line.image_asset?.crop_y,
              height: line.image_asset?.crop_height,
              width: line.image_asset?.crop_width,
              aspect: line.image_asset?.aspect,
            }
          : null,
        id: v4(),
        headline: line.name,
        description: line.text,
        websiteUrl: line.url,
        errors: line.errors,
        open: !!Object.keys(line.errors).length,
        position: line.position,
      });
    });
    return mediaArray.sort((a, b) => a.position - b.position);
  } else {
    return [];
  }
};
