/* eslint-disable react/no-array-index-key */

import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { t } from '../i18n';
import { Icon, Ellipsis, Row, Col, cssVariables, Text } from '../components';
import EmptyState from '../components/EmptyState';

const DefaultListItem = ({ organization, showLink, currentOrganizationId, coloredIcon, iconType }) => (
  <li key={organization.id} data-test-id="organization-dropdown-item">
    <a
      className={cs('Breadcrumbs-listItem', { active: organization.id === currentOrganizationId })}
      href={organization.link || showLink}
    >
      <Row padding="m" center>
        {organization.icon && (
          <Col shrink>
            <Icon
              kind={organization.icon}
              className={cs(organization.icon, iconType)}
              colored={coloredIcon}
              inheritColor
            />
          </Col>
        )}
        <Col grow>
          <Ellipsis>{organization.name}</Ellipsis>
        </Col>
      </Row>
      {organization.defaultOrganizationIcon && (
        // eslint-disable-next-line react/no-danger
        <div dangerouslySetInnerHTML={{ __html: organization.defaultOrganizationIcon }} />
      )}
      {organization.id === currentOrganizationId && (
        <Icon size="20px" kind="check" className="mh-2" color={cssVariables.interactivePrimaryDefault} />
      )}
    </a>
  </li>
);

DefaultListItem.propTypes = {
  coloredIcon: PropTypes.bool,
  currentOrganizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconType: PropTypes.string,
  organizations: PropTypes.array.isRequired,
};

const getChildOrgs = (organizations, org) => organizations.filter(o => o.parentId === org.id);

const DefaultList = ({
  organizations,
  currentOrganizationId,
  iconType,
  listSpacing,
  loading,
  loaded,
  coloredIcon = true,
  hasActiveSearch,
}) => (
  <ul className={`Breadcrumbs-list pv-${listSpacing}`}>
    {organizations
      .filter(o => !o.parentId)
      .map(organization => (
        <React.Fragment key={organization.id}>
          <DefaultListItem
            organization={organization}
            showLink={organization.showLink}
            currentOrganizationId={currentOrganizationId}
            coloredIcon={coloredIcon}
            iconType={iconType}
          />

          {!organization.isChildOrganization && getChildOrgs(organizations, organization)?.length > 0 && (
            <ul className="Breadcrumbs-subOrgList">
              {getChildOrgs(organizations, organization).map(childOrganization => (
                <DefaultListItem
                  organization={childOrganization}
                  showLink={organization.showLink}
                  currentOrganizationId={currentOrganizationId}
                  coloredIcon={coloredIcon}
                />
              ))}
            </ul>
          )}
        </React.Fragment>
      ))}

    {organizations.length === 0 && loaded ? (
      <EmptyState icon="no_product_icon" emptySearch={t('react.breadcrumb.not_found')} hasSmallIcon>
        {hasActiveSearch && (
          <Text tag="div" className="mt-16">
            {t('react.breadcrumb.not_found_description')}
          </Text>
        )}
      </EmptyState>
    ) : null}

    {loading && (
      <li className="Breadcrumbs-listItem Breadcrumbs-listItem--loader Breadcrumbs-listItem--noHover mt-32">
        <span className="Loader" />
      </li>
    )}
  </ul>
);

DefaultList.defaultProps = {
  listSpacing: 6,
};

DefaultList.propTypes = {
  organizations: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  loaded: PropTypes.bool,
  iconType: PropTypes.string,
  currentOrganizationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  coloredIcon: PropTypes.bool,
  hasActiveSearch: PropTypes.bool,
};

export default DefaultList;
