export default ({ object, startPoint, mouse, holdAspectRatio, scale }) => {
  const { objectX, objectY, clientX, clientY } = startPoint;

  if (holdAspectRatio || object.type === 'img' || object.type === 'svg') {
    const diff = Math.max(mouse.x / scale - clientX / scale, mouse.y / scale - clientY / scale);
    const aspectH = startPoint.width > startPoint.height ? 1 : startPoint.height / startPoint.width;
    const aspectW = startPoint.width > startPoint.height ? startPoint.width / startPoint.height : 1;
    const width = Math.round(startPoint.width + diff * aspectW);
    const height = Math.round(startPoint.height + diff * aspectH);

    return {
      ...object,
      x: width > 0 ? objectX : objectX + width,
      y: height > 0 ? objectY : objectY + height,
      width: Math.abs(width),
      height: Math.abs(height),
    };
  }

  const width = startPoint.width + mouse.x / scale - clientX / scale;
  const height = startPoint.height + mouse.y / scale - clientY / scale;

  return {
    ...object,
    x: width > 0 ? objectX : objectX + width,
    y: height > 0 ? objectY : objectY + height,
    width: Math.abs(width),
    height: Math.abs(height),
  };
};
