import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { hot } from 'react-hot-loader/root';

import { ListComponent } from './List';
import { QueryHolder } from '../QueryHolder';
import { Button, Tile } from '../index';

export const GroupedList = props => {
  let counter = -1;
  const groups = (props.data.collection[props.collectionKey] || []).reduce((out, item) => {
    counter += 1;
    return { ...out, [item.group]: [...(out[item.group] || []), { ...item, index: counter }] };
  }, {});

  const groupsSorted = Object.keys(groups).sort();
  const itemGroups = props.data.collection.itemGroups || [];

  return (
    <div>
      {groupsSorted.map(groupId => (
        <Tile key={groupId} className="mb-16">
          <ListComponent
            heading={groupId}
            className="mb-l"
            {...props}
            data={{
              ...props.data,
              collection: {
                ...props.data.collection,
                [props.collectionKey]: groups[groupId],
              },
              itemGroups,
            }}
            otherBranches={groupsSorted
              .filter(b => b !== groupId)
              .map(branch => ({
                linkParams: `${props.updateLinkParamRoot}[group]=${encodeURIComponent(branch)}`,
                branch,
              }))}
            addResource={
              props.addResource && {
                ...props.addResource,
                useGroup: true,
                url: `${props.addResource.url}?insert_last=true&group=${encodeURIComponent(groupId)}`,
              }
            }
          />
        </Tile>
      ))}

      {groupsSorted.length === 0 && (
        <div className="hidden">
          {/* This list will ensure that when first ad is added whole list gets refreshed */}
          <ListComponent
            {...props}
            data={{
              ...props.data,
              collection: { [props.collectionKey]: [] },
            }}
          />
        </div>
      )}

      {props.addResource && (
        <div>
          <Button
            kind="secondary"
            data-toggle={props.addResource.toggle}
            data-url={props.addResource.url}
            data-test-id={props.addResource.test_id}
            data-notify-on-change={props.addResource.notify_on_change}
            data-size={props.addResource.size}
            block
            icon={props.addResource.icon || 'adtext'}
          >
            {props.addResource.button_text}
          </Button>
        </div>
      )}
    </div>
  );
};

GroupedList.propTypes = {
  addResource: PropTypes.object,
  updateLink: PropTypes.string,
  updateLinkParamRoot: PropTypes.string,
  collectionKey: PropTypes.string,
  data: PropTypes.object,
};

const QueryGroupedList = QueryHolder(GroupedList);

const FBGroupedFeatures = props => {
  const { collectionKey, campaignSettingId, organizationId } = props;

  const query = useMemo(
    () => gql`
      query FBFeatureQueryGroupedFeatures${collectionKey}($organizationId: BigInt!, $campaignSettingId: BigInt) {
        organization(id: $organizationId) {
          id
          campaignSetting(id: $campaignSettingId) {
            id
            facebookCurrency
            itemsCount: itemsCountLive
            adSetCount
            dataSource {
              isFirstLoading
            }
          }
        }
        collection(identifier: "GroupedFeatures${collectionKey}", limit: 100000, page: 0, organizationId: $organizationId, campaignSettingId: $campaignSettingId) {
          id
          fbProductCampaigns {
            id
            name
            fbProductGroups {
              id
              name
            }
          }
          ${collectionKey} {
            fbProductGroupIds
            fbProductCampaignIds
            id
            name
            group
            position
            numberOfProducts
            numberOfAdSets
            description
            status
            editLink
            matchAll
            ${collectionKey === 'fbProductSets' ? 'conditions { toDataPreview }' : ''}
            duplicateLink
            updateLink
            deleteLink
            disabledDeleteLink
            ${
              collectionKey === 'fbProductSets'
                ? 'duplicateIntoCampaignsLink'
                : `
              isDraft
              timeRestriction {
                text
              }
              timeRestrictionStatus
            `
            }
          }
          itemGroups {
            id,
            name
          }
        }
      }
    `,
    [collectionKey]
  );

  return (
    <QueryGroupedList
      {...props}
      query={query}
      variables={{ organizationId, campaignSettingId }}
      statusCampaignSwitchWarning
    />
  );
};

FBGroupedFeatures.propTypes = {
  addResource: PropTypes.object,
  adSetCount: PropTypes.number,
  campaignSettingId: PropTypes.number,
  collectionKey: PropTypes.string,
  organizationId: PropTypes.number,
  updateGraphqlObject: PropTypes.string,
  updateLinkParamRoot: PropTypes.string,
};

export default hot(FBGroupedFeatures);
