import { useState, useEffect, useMemo } from 'react';
import { debounce, uniq, isObject, isNil, isEqual } from 'lodash';

const isPrimitive = arg => !isObject(arg) && !isNil(arg);

// eslint-disable-next-line consistent-return
const foundQueryCreator = query => xxx => {
  if (isPrimitive(xxx)) {
    return xxx
      .toString()
      .toLowerCase()
      .includes(query.toLowerCase());
  }
};

const filterCreator = ({ collection, ignoreKeys = [] }) => search => {
  if (collection.length === 0 || search === '' || !search) return collection;

  const foundQuery = foundQueryCreator(search);

  if (isPrimitive(collection[0])) {
    return collection.filter(foundQuery);
  }

  const res = collection.reduce((acc, cur) => {
    let result = [...acc];
    const newC = { ...cur };
    Object.keys(newC).forEach(k => {
      if (ignoreKeys.includes(k)) return;

      const searchSuccess = foundQuery(newC[k]);
      if (searchSuccess) result = [...result, newC];

      if (Array.isArray(cur[k])) {
        const temp = filterCreator({ collection: newC[k] })(search);
        newC[k] = temp;
        if (temp.length === 0) {
          delete newC[k];
        } else {
          result = [...result, newC];
        }
      }
    });
    return result;
  }, []);

  return uniq(res);
};

const fixIgnoreKeys = ignoreKeys => {
  if (Array.isArray(ignoreKeys)) return ignoreKeys;
  if (typeof ignoreKeys === 'string') return [ignoreKeys];
  return [];
};

const useSearch = ({ initialSearch = '', collection = [], ignoreKeys = [], debounceVal = 300 } = {}) => {
  const [search, setSearch] = useState(initialSearch);
  const [result, setResult] = useState(collection);

  const fixedKeys = fixIgnoreKeys(ignoreKeys);

  const proccedFiltering = useMemo(
    () =>
      debounce(searchQuery => {
        const filtredResult = filterCreator({ collection, ignoreKeys: fixedKeys })(searchQuery);
        setResult(filtredResult);
      }, debounceVal),
    [collection]
  );

  useEffect(() => {
    proccedFiltering(search);
  }, [search]);

  useEffect(() => {
    if (!isEqual(collection, result) && !search) {
      setResult(collection);
    }
  }, [collection]);

  return { search, setSearch, result };
};

export default useSearch;
