import React from 'react';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import { Modal, ModalFooter, ModalBody } from '../Modal';
import { Button, Input, Form, Checkboxes, Row, Col } from '../index';
import { t } from '../../i18n';

const RequestModal = ({ onClose, organizationId, onRequested, requestDataOptions, requestActionsOptions, testId }) => {
  const [requestCustomInsight] = useMutation(
    gql`mutation RequestCustomInsights($requestedInsights: String!){
      requestCustomInsights(organizationId: ${organizationId} requestedInsights: $requestedInsights) {
        request
        errors
      }
    }`,
    {
      variables: {},
      onCompleted: () => {
        onRequested();
        onClose();
      },
    }
  );

  return (
    <Modal
      size="small"
      heading={t('react.dashboard.insights.modal_heading')}
      onClose={onClose}
      subHeading={t('react.dashboard.insights.modal_subheading')}
      testId={testId}
    >
      <ModalBody>
        <Form
          id="request-modal-form"
          onSubmit={result => requestCustomInsight({ variables: { requestedInsights: JSON.stringify(result) } })}
        >
          <Col>
            <Row className="mt-16">
              <Checkboxes
                name="dataOptions"
                label={t('react.dashboard.insights.modal_data')}
                collection={requestDataOptions}
              />
            </Row>
            <Row>
              <Input name="dataCustom" placeholder={t('react.dashboard.insights.input_placeholder')} />
            </Row>
            <Row className="mt-32">
              <Checkboxes
                name="actionOptions"
                label={t('react.dashboard.insights.modal_actions')}
                collection={requestActionsOptions}
              />
            </Row>
            <Row>
              <Input name="actionCustom" placeholder={t('react.dashboard.insights.input_placeholder')} />
            </Row>
          </Col>
        </Form>
      </ModalBody>
      <ModalFooter>
        <a className="Link mr-16" onClick={onClose} data-test-id={`${testId}-close`}>
          {t('react.dashboard.insights.modal_close')}
        </a>
        <Button primary type="submit" form="request-modal-form" data-test-id={`${testId}-submit`}>
          {t('react.dashboard.insights.modal_request')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

RequestModal.propTypes = {
  onClose: PropTypes.func,
  organizationId: PropTypes.number,
  onRequested: PropTypes.func,
  requestActionsOptions: PropTypes.array,
  requestDataOptions: PropTypes.array,
  testId: PropTypes.string,
};

export default RequestModal;
