import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { union } from 'lodash';
import TransformationVariable from './TransformationVariable';
import { listenOnModalChange } from '../RemoteModal';
import { Col } from '../layout';
import emptyImage from '../../../../assets/images/empty_container/variable_rules_icon.svg';
import { t } from '../../i18n';
import { SectionHeading } from '../Typography';
import { Loader } from '../Icons';
import Filter from './Filter';
import { CreateElementButton } from '../index';
import VariableNavigation from './VariableNavigation';
import ErrorBox from '../ErrorBox';

const NAV_VISIBILITY_THRESHOLD = 10;

export const GET_TRANSFORMATIONS = gql`
  query VariableTransformationsQuery($page: Int!, $limit: Int!, $organizationId: BigInt!, $dataSourceId: BigInt) {
    collection(
      identifier: "variables"
      page: $page
      limit: $limit
      organizationId: $organizationId
      dataSourceId: $dataSourceId
    ) {
      id
      variableTransformations {
        id
        name
        placeholderName
        fieldKind
        deleteWithConnectionsLink
        createTransformationTiles {
          category
          description
          heading
          icon
          type
          url
          addResource {
            heading
            notifyOnChange
            size
            testId
            toggle
            url
          }
        }
        transformationSubjects {
          id
          position
          itemsCount
          status
          duplicateLink
          duplicateToLink
          transformation {
            deleteLink
            duplicateCopyLink
            editLink
            id
            name
            fieldKind
            enabled
            dataSource {
              allItemsCount
            }
          }
          appliedFeedExports {
            id
            name
          }
          useForAllFeedExports
        }
      }
      __typename
    }
  }
`;

export const UPDATE_TRANSFORMATION_SUBJECT = gql`
  mutation updateTransformationSubject($transformationSubjects: [TransformationSubjectInput!]!) {
    updateTransformationSubject(transformationSubjects: $transformationSubjects) {
      transformationSubjects {
        id
        position
        status
      }
    }
  }
`;

export const GET_DATA_SOURCES = gql`
  query DataSourcesQuery($organizationId: BigInt!) {
    collection(identifier: "data-sources", page: 0, limit: 2, organizationId: $organizationId) {
      id
      dataSources {
        id
      }
    }
  }
`;

const Transformations = props => {
  const [filter, setFilter] = useState({ search: '', feedExport: '' });
  const [firstRenderItemIds, setFirstRenderItemIds] = useState([]);
  const [activeElements, setActiveElemets] = useState({});

  const { data: queryData, refetch, loading, error } = useQuery(GET_TRANSFORMATIONS, {
    variables: {
      limit: 10000,
      page: 0,
      organizationId: props.organizationId,
      dataSourceId: props.dataSourceId,
    },
  });

  const { data: dataSourcesData } = useQuery(GET_DATA_SOURCES, {
    variables: {
      organizationId: props.organizationId,
    },
  });

  const data = useMemo(
    () =>
      props.variableId
        ? queryData?.collection.variableTransformations?.filter(
            variableTransformation => variableTransformation.id === props.variableId
          )
        : queryData?.collection.variableTransformations,
    [queryData, props.variableId]
  );

  const createButtonProps = useMemo(
    () =>
      props.variableId
        ? {
            tiles: data?.[0]?.createTransformationTiles,
            parents: [],
            sidebarAllItem: 'All variable types',
            testId: 'open-create-transformation-modal-button',
            heading: t('react.transformations.select_rule'),
            buttonKind: 'secondary',
            hideText: false,
            shouldCloseOnSuccess: true,
          }
        : props.createButtonProps,
    [props.variableId, data]
  );

  useEffect(() => {
    const ids =
      data
        ?.filter(variableTransformation => !!variableTransformation.transformationSubjects.length)
        .map(variableTransformation => variableTransformation.id) || [];
    setFirstRenderItemIds(prev => union(prev, ids));
  }, [data]);

  const [updateTransformationSubject] = useMutation(UPDATE_TRANSFORMATION_SUBJECT, {
    onError: () => {
      new window.NotificationCenter().show_error(t('react.transformations.status_change_error'));
    },
  });

  const isEmpty = useMemo(
    () => !data?.reduce((acc, variableTransformation) => acc + variableTransformation.transformationSubjects.length, 0),
    [data]
  );

  const handleOnModalUpdateData = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => listenOnModalChange(handleOnModalUpdateData), [handleOnModalUpdateData]);

  const filteredTransformations = useMemo(() => {
    let result = data?.filter(variableTransformation => firstRenderItemIds.includes(variableTransformation.id));

    if (filter.search) {
      result =
        result.filter(variableTransformation =>
          variableTransformation.name.toLowerCase().includes(filter.search.toLowerCase())
        ) || [];
    }

    if (filter.feedExport) {
      result =
        result.filter(variableTransformation =>
          variableTransformation.transformationSubjects.some(subject =>
            subject.appliedFeedExports.some(feedExport => feedExport.id === filter.feedExport)
          )
        ) || [];
    }

    return [...(result || [])]?.sort((a, b) => a.name?.localeCompare(b.name));
  }, [data, filter, firstRenderItemIds]);

  const totalTransformations = firstRenderItemIds.length || 0;
  const multipleDataSources = dataSourcesData?.collection?.dataSources?.length > 1;

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <ErrorBox>{t('react.transformations.error')}</ErrorBox>;
  }

  if (!loading && isEmpty) {
    return (
      <Col center className="text-center">
        <img src={emptyImage} alt="" style={{ width: '274px' }} className="mb-24" />
        <SectionHeading spacing={8}>{t('react.transformations.empty.title')}</SectionHeading>
        <p className="mb-16" style={{ maxWidth: '460px' }}>
          {t('react.transformations.empty.text')}
        </p>
        <p>
          <CreateElementButton
            buttonText={t('react.transformations.create_new_rule')}
            {...createButtonProps}
            hideText={false}
            toolbox={{
              size: 'large',
            }}
            tooltip={undefined}
            shouldCloseOnSuccess
          />
        </p>
      </Col>
    );
  }

  return (
    <div>
      {totalTransformations > NAV_VISIBILITY_THRESHOLD && (
        <VariableNavigation transformations={filteredTransformations} activeElements={activeElements} />
      )}

      <Filter
        filter={filter}
        setFilter={setFilter}
        variableTransformations={data}
        createButtonProps={createButtonProps}
        isSearchHidden={!!props.variableId}
      />

      {filteredTransformations.length ? (
        filteredTransformations.map(transformation => (
          <TransformationVariable
            key={transformation.id}
            transformation={transformation}
            updateTransformationSubject={updateTransformationSubject}
            filter={filter}
            organizationId={props.organizationId}
            dataSourceId={props.dataSourceId}
            setActiveElemets={setActiveElemets}
            setFilter={setFilter}
            isOneVariableView={!!props.variableId}
            multipleDataSources={multipleDataSources}
          />
        ))
      ) : (
        <p>No search results</p>
      )}
    </div>
  );
};

Transformations.propTypes = {
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  createButtonProps: PropTypes.object,
  variableId: PropTypes.string,
};

export default Transformations;
