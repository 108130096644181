import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Tile from '../Tile';
import { SectionHeading, SmallHeading } from '../Typography';
import Transformation from './Transformation';
import { DndSortableContainer } from '../SortableDnd';
import arrayMove from '../../utils/arrayMove';
import { Col, Row } from '../layout';
import Accordion from '../Accordion';
import DropdownMenu from '../DropdownMenu';
import Link from '../Link';
import { t } from '../../i18n';
import Badge from '../Badge';
import Switch from '../form/Switch';
import Preview from './Preview';
import { TransformationsFilterT } from './Filter';
import { CreateElementButton } from '../index';
import FieldKindIcon from '../FieldKindIcon';
import useElementPosition from '../../hooks/useElementPosition';

const TransformationVariable = ({
  transformation,
  updateTransformationSubject,
  filter,
  dataSourceId,
  organizationId,
  setActiveElemets,
  isOneVariableView,
  multipleDataSources,
}) => {
  const isEmpty = !transformation.transformationSubjects.length;
  const getOrderArr = useCallback(
    () =>
      [...transformation.transformationSubjects]
        .sort((a, b) => (a?.position || 0) - (b?.position || 0))
        .map(({ id }) => id),
    [transformation.transformationSubjects]
  );

  const [order, setOrder] = useState(getOrderArr());
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  useEffect(() => {
    setOrder(getOrderArr());
  }, [setOrder, getOrderArr]);

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const newOrder = arrayMove([...order], oldIndex, newIndex);
    setOrder(newOrder);
    updateTransformationSubject({
      variables: { transformationSubjects: newOrder.map((id, index) => ({ id, position: index })) },
    });
  };

  const sortedTransformationSubjects = useMemo(
    () => [...transformation.transformationSubjects].sort((a, b) => order.indexOf(a?.id) - order.indexOf(b.id)),
    [transformation.transformationSubjects, order]
  );

  const ref = useRef(null);
  const isElementVisible = useElementPosition(ref);

  useEffect(() => {
    setActiveElemets(prev => ({ ...prev, [transformation.id]: isElementVisible }));
  }, [isElementVisible, setActiveElemets]);

  return (
    <div ref={ref} data-test-id={`transformation-variable-${transformation.name}`}>
      <Tile
        shadow
        className="mb-16"
        id={`variable-${transformation.id}`}
        contentStyle={isEmpty ? { paddingBottom: 0 } : {}}
      >
        <Accordion
          className="pb-0"
          classNameContent="pb-0 negative-mh-16"
          classNameTitle="pv-0"
          forceOpen
          isDisabledToggle={isOneVariableView}
          header={
            <Row onClick={event => event.stopPropagation()} center className="cursor-auto">
              <Col>
                <SectionHeading spacing={8} className="ma-0">
                  <Row center>
                    <span className="Text--grape">
                      <FieldKindIcon
                        showFieldKind={transformation.fieldKind}
                        size="24px"
                        className="mr-4"
                        inheritColor
                      />
                    </span>
                    {transformation.name}
                  </Row>
                </SectionHeading>
              </Col>
              {!isEmpty && (
                <Col shrink className="Accordion-hidden-on-active">
                  <Badge size="medium">
                    {transformation.transformationSubjects.length}{' '}
                    {t(`react.transformations.${transformation.transformationSubjects.length > 1 ? 'rules' : 'rule'}`, {
                      count: transformation.transformationSubjects.length,
                    })}
                  </Badge>
                </Col>
              )}
              {transformation.deleteWithConnectionsLink && !isOneVariableView && (
                <Col shrink>
                  <DropdownMenu>
                    <Link
                      danger
                      icon="trash"
                      data-remote="true"
                      data-confirm={t('react.transformations.variable_rule_delete_confirmation', {
                        name: transformation.name,
                      })}
                      data-commit={t('react.transformations.yes_btn')}
                      data-container="body"
                      data-test-id-delete={transformation.name}
                      data-method="delete"
                      href={transformation.deleteWithConnectionsLink}
                    >
                      Delete
                    </Link>
                  </DropdownMenu>
                </Col>
              )}
            </Row>
          }
        >
          <div className="delimiter mt-16" />
          <div className="pt-8 ph-16 pb-24 background-light-gray-opacity">
            <div className="mt-16 ml-a mr-a" style={{ maxWidth: '800px' }}>
              {!isEmpty ? (
                <DndSortableContainer onSortEnd={handleSortEnd} items={order}>
                  {sortedTransformationSubjects.map(transformationSubject => (
                    <Transformation
                      key={transformationSubject.id}
                      transformationSubject={transformationSubject}
                      onSortEnd={handleSortEnd}
                      id={transformationSubject.id}
                      filter={filter}
                      multipleDataSources={multipleDataSources}
                    />
                  ))}
                </DndSortableContainer>
              ) : (
                <div className="text-center">
                  <SmallHeading spacing={0}>{t('react.transformations.no_rules')}</SmallHeading>
                  <p>{t('react.transformations.start_with_create_rule')}</p>
                </div>
              )}
            </div>

            {transformation.createTransformationTiles && (
              <Row justifyCenter>
                <CreateElementButton
                  tiles={transformation.createTransformationTiles}
                  parents={[]}
                  sidebarAllItem="All variable types"
                  testId="open-create-transformation-modal-button"
                  heading={t('react.transformations.select_rule')}
                  buttonText={t('react.transformations.add_rule')}
                  buttonKind="secondary"
                  hideText={false}
                  shouldCloseOnSuccess
                />
              </Row>
            )}
          </div>

          {!isEmpty && (
            <div className="ph-16 pb-8">
              <div className="delimiter mb-16 negative-mh-16" />
              <div className={classNames('pos-relative', { 'pb-24': !isPreviewVisible })}>
                <Switch
                  onChange={event => setIsPreviewVisible(event.target.value)}
                  value={isPreviewVisible}
                  label={t('react.transformations.preview_switch_label')}
                  className="pos-absolute mt-8"
                />
                {isPreviewVisible && (
                  <Preview
                    transformationVariable={transformation}
                    dataSourceId={dataSourceId}
                    organizationId={organizationId}
                    filter={filter}
                    transformationSubjects={sortedTransformationSubjects}
                  />
                )}
              </div>
            </div>
          )}
        </Accordion>
      </Tile>
    </div>
  );
};

export const TransformationSubjectT = PropTypes.shape({
  id: PropTypes.string,
});

TransformationVariable.propTypes = {
  updateTransformationSubject: PropTypes.func,
  transformation: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    placeholderName: PropTypes.string,
    fieldKind: PropTypes.string,
    transformationSubjects: PropTypes.arrayOf(TransformationSubjectT),
  }),
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  setActiveElemets: PropTypes.func,
  filter: TransformationsFilterT,
  isOneVariableView: PropTypes.bool,
  multipleDataSources: PropTypes.bool,
};

export default TransformationVariable;
