import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useMutation } from '@apollo/client';
import cs from 'classnames';
import { Cell } from './mapping/cells';
import { Table, Row, Header } from './components';
import { useMultiEdit } from './MultiEditContext';
import { SortableElement, SortableContainer } from '../Sortable';
import { Col, DragHandle, Icon, InfoBox } from '../index';
import allCollectionsMapping from './mapping';
import { Loader } from '../Icons';
// eslint-disable-next-line import/no-named-as-default
import Modal, { ModalBody, ModalFooter } from '../Modal';
import Button from '../Button';
import { t } from '../../i18n';

const adtextAdditionalRow = allCollectionsMapping?.adtextAdditionalRow;

const resolveHeader = ({ header, layoutType, assetGroupType }) =>
  typeof header === 'function' ? header(layoutType, assetGroupType) : header;

const MoveAdtextModal = ({ modal, setModal, moveAdtextMutation }) => {
  const [selected, setSelected] = useState();
  return (
    <Modal
      onClose={() => setModal({ ...modal, show: false })}
      heading={t('react.tables.adtexts.move_to_different_branch')}
    >
      <ModalBody>
        {t('react.tables.adtexts.move_to_different_branch_text')}
        {modal.data.otherGroups.length === 0 && (
          <InfoBox type="warning" withIcon className="mv-16">
            {t('react.tables.adtexts.cant_move')}
          </InfoBox>
        )}
        <div>
          {modal.data.otherGroups.map(({ name, lastAdtextId }) => (
            <div className="Radios-item mt-8" key={name}>
              <input
                id={name}
                name="create_element_modal[parent_id]"
                className="Radios-input"
                // eslint-disable-next-line eqeqeq
                checked={selected == lastAdtextId}
                value={lastAdtextId}
                onChange={({ target: { value } }) => setSelected(value)}
                type="radio"
              />
              <label htmlFor={name}>{name}</label>
            </div>
          ))}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button primary disabled={!selected} onClick={() => moveAdtextMutation(modal.data.adtextId, selected)}>
          {t('react.tables.adtexts.move')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
MoveAdtextModal.propTypes = {
  modal: PropTypes.object,
  setModal: PropTypes.func,
  moveAdtextMutation: PropTypes.func,
};

const SingleRow = ({ className, rowData, mapping, canEdit, statusCampaignSwitchWarning, ...rest }) => (
  <Row key={rowData.id} className={cs('Sticky-Row--adtext', className)}>
    {mapping.map(column => (
      <column.cell
        key={column.id}
        id={column.id}
        width={column.width}
        canEdit={canEdit}
        cellCouldBeEmpty={column.cellCouldBeEmpty}
        smallPadding={column?.smallPadding}
        editable={!!column.editQuery && !!column.editQuery({ ...rowData, ...rest }) && column.editable}
        editButtonAsProp={column.editButtonAsProp}
        editQuery={column.editQuery && column.editQuery({ ...rowData, ...rest })}
        columnHeader={column.header}
        statusCampaignSwitchWarning={statusCampaignSwitchWarning}
      >
        {column.resolveChildren({ ...rowData, ...rest })}
      </column.cell>
    ))}
  </Row>
);

SingleRow.propTypes = {
  className: PropTypes.string,
  rowData: PropTypes.object,
  mapping: PropTypes.array,
  canEdit: PropTypes.bool,
  layoutType: PropTypes.string,
  hidden: PropTypes.bool,
  isParent: PropTypes.bool,
  additionalContent: PropTypes.any,
  statusCampaignSwitchWarning: PropTypes.bool,
};

const SortableList = SortableContainer(({ children }) => <div>{children}</div>);

const SortableSingleRow = SortableElement(SingleRow);

const AdtextRow = ({
  moveAdtextMutation,
  setModal,
  forceOpenBranches,
  mapping,
  className,
  rowData,
  layoutType,
  canEdit,
  rowIndex,
  additionalData,
  statusCampaignSwitchWarning,
}) => {
  const [hidden, setHidden] = useState(!forceOpenBranches);
  const props = { mapping, className, rowData, layoutType, canEdit, additionalData };

  const additionalMapping = mapping.map(({ id }) => adtextAdditionalRow[id] || { ...adtextAdditionalRow.empty, id });

  if (rowData.adtexts.length === 0) {
    return null;
  }
  if (layoutType === 'adtextsOnly') {
    return (
      <React.Fragment>
        {rowData.adtexts.map(adtext => (
          <SingleRow
            key={adtext.id}
            {...props}
            rowData={adtext}
            products={adtext.itemsCount}
            statusCampaignSwitchWarning={statusCampaignSwitchWarning}
          />
        ))}
      </React.Fragment>
    );
  } else if (layoutType === 'branches') {
    const productsUsedInBranch = rowData.adtexts.reduce((a, { itemsCount }) => a + itemsCount, 0);
    const productsTotal = rowData.campaignItemsCount;
    const productsLeft = productsTotal - productsUsedInBranch;

    return (
      <React.Fragment>
        <SingleRow
          key={`branch_${rowData.id}_${hidden ? 'hidden' : 'shown'}`}
          {...props}
          rowData={rowData}
          isParent
          hidden={hidden}
          products={productsUsedInBranch}
          productsTotal={productsTotal}
          additionalContent={
            <React.Fragment>
              <Col shrink className="mr-16" style={{ marginLeft: '3px' }}>
                <Icon
                  data-test-id={`show-more-${rowData?.name}`}
                  onClick={() => setHidden(!hidden)}
                  kind={`chevron-${hidden ? 'right' : 'down'}`}
                  size="14px"
                  color="#596774"
                  style={{ cursor: 'pointer' }}
                />
              </Col>
            </React.Fragment>
          }
          onClick={() => setHidden(!hidden)}
          statusCampaignSwitchWarning={statusCampaignSwitchWarning}
        />

        {!hidden &&
          rowData.adtexts.map((adtext, index) => {
            const data = { otherGroups: adtext?.otherGroups, adtextId: adtext.id };

            return (
              <React.Fragment key={adtext.id}>
                <SortableSingleRow
                  additionalContent={
                    <Col shrink className="pos-relative" style={{ marginRight: '80px' }}>
                      <DragHandle />
                    </Col>
                  }
                  index={index}
                  first={index === 0}
                  collection={rowIndex}
                  {...props}
                  className="Sticky-Row--gray"
                  rowData={adtext}
                  products={adtext.itemsCount}
                  productsTotal={productsTotal}
                  modalCallback={() => setModal({ show: true, data })}
                  linkCallback={rowData.adtexts.length > 1 ? () => moveAdtextMutation(adtext.id, -1) : null}
                  statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                />

                {index + 1 === rowData.adtexts.length && (
                  <SingleRow
                    {...props}
                    mapping={additionalMapping}
                    rowData={rowData}
                    products={productsLeft}
                    productsTotal={productsTotal}
                    className="Sticky-Row--gray"
                    statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                  />
                )}
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  }
  return null;
};
AdtextRow.propTypes = {
  moveAdtextMutation: PropTypes.func,
  setModal: PropTypes.func,
  forceOpenBranches: PropTypes.bool,
  rowIndex: PropTypes.number,
  mapping: PropTypes.array,
  className: PropTypes.string,
  rowData: PropTypes.object,
  layoutType: PropTypes.string,
  canEdit: PropTypes.bool,
  statusCampaignSwitchWarning: PropTypes.bool,
};

const DisplayAdtextsTable = ({
  forceOpenBranches,
  activeSearchState,
  allowSelect,
  canEdit,
  data,
  refetch,
  mapping,
  layoutType,
  assetGroupType,
  additionalData,
  statusCampaignSwitchWarning,
}) => {
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ show: false, data: [] });

  const {
    state: { selected },
  } = useMultiEdit();

  const [update] = useMutation(
    gql`
      mutation {
        updateAdtextNesting(id: "-1", parentId: "-1") {
          baseAdtexts {
            id
          }
          errors
        }
      }
    `,
    {
      onCompleted: responseData => {
        if (typeof window.NotificationCenter === 'function') {
          const err = responseData.updateAdtextNesting.errors;
          if (err.length > 0) {
            new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
          } else {
            new window.NotificationCenter().show_success('Successfully saved');
          }

          setLoading(false);
          setModal({ ...modal, show: false });
          if (typeof window.startLoadingPreview === 'function') {
            window.startLoadingPreview();
          }
        }
      },
    }
  );

  const moveAdtextMutation = (id, parentId, useRefetch = true) =>
    update({
      mutation: gql`
    mutation moveAdtext{
      updateAdtextNesting(id: ${id}, parentId: ${parentId}) {
        baseAdtexts {
          id
          adtextGroup(active: "${activeSearchState?.active || 'all'}", labels: "${activeSearchState?.labels ||
        'all'}") { name id adtexts { id itemsCount } }
        }
        errors
      }
    }
  `,
    }).then(useRefetch ? refetch : () => {});

  const onSortEnd = ({ collection, oldIndex, newIndex }) => {
    const adtextId = data[collection].adtexts[oldIndex].id;
    const difference = newIndex - oldIndex;
    let parentId;
    if (difference === 0) parentId = adtextId;
    else if (newIndex === 0) {
      // There can be hidden Ad before first active - so take parent Id of first active ad insted of 0 - first ad in branch
      parentId = data[collection].adtexts[0]?.parentId || null;
    } else if (newIndex === data[collection].adtexts.length - 1) {
      parentId = data[collection].adtexts[data[collection].adtexts.length - 1].id;
    } else if (oldIndex === 0 && difference > 1) {
      parentId = data[collection].adtexts[newIndex + difference - 2]?.id;
    } else if (difference < 0) {
      parentId = data[collection].adtexts[newIndex + difference]?.id;
      if (typeof parentId === 'undefined') {
        parentId = data[collection].adtexts[Math.abs(newIndex + difference) - 1]?.id;
      }
    } else parentId = data[collection].adtexts[newIndex + difference - 1].id;
    if (parentId !== adtextId) {
      setLoading(true);
      moveAdtextMutation(adtextId, parentId, false);
    }
  };

  return (
    <SortableList onSortEnd={onSortEnd} useDragHandle lockAxis="y" lockToContainerEdges useWindowAsScrollContainer>
      {loading && <Loader absolute />}
      {modal.show && <MoveAdtextModal {...{ modal, setModal, moveAdtextMutation }} />}
      <div className="ph-0">
        <Table oddStriped={layoutType !== 'branches'} striped={false}>
          <Header>
            {mapping.map((column, index) => (
              <Cell
                order={column.order}
                reverseOrder={column.reverseOrder}
                orderedByDescFirst={column.orderedByDescFirst}
                key={column.id}
                id={column.id}
                width={column.width}
                useEllipsis={column.useEllipsis}
                withCheckbox={(allowSelect && column.id === 'name') || index === 0}
                isHeader
              >
                {resolveHeader({ header: column.header, layoutType, assetGroupType })}
              </Cell>
            ))}
          </Header>
          {data.map((rowData, index) => {
            const selectedRow = selected.indexOf(rowData.id) !== -1;

            return (
              <AdtextRow
                moveAdtextMutation={moveAdtextMutation}
                setModal={setModal}
                forceOpenBranches={forceOpenBranches}
                rowIndex={index}
                key={rowData.id}
                mapping={mapping}
                rowData={rowData}
                layoutType={layoutType}
                canEdit={canEdit}
                className={cs({
                  'Sticky-Row--selected Sticky-Row--selectedBorder': selectedRow,
                })}
                additionalData={additionalData}
                statusCampaignSwitchWarning={statusCampaignSwitchWarning}
              />
            );
          })}
        </Table>
      </div>
    </SortableList>
  );
};

DisplayAdtextsTable.propTypes = {
  activeSearchState: PropTypes.object,
  allowSelect: PropTypes.bool,
  assetGroupType: PropTypes.bool,
  canEdit: PropTypes.bool,
  data: PropTypes.array,
  forceOpenBranches: PropTypes.bool,
  layoutType: PropTypes.string,
  mapping: PropTypes.array,
  refetch: PropTypes.func,
  statusCampaignSwitchWarning: PropTypes.bool,
};

export default DisplayAdtextsTable;
