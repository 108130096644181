import React, { useEffect } from 'react';
import { isPlainObject } from 'lodash';
import PropTypes from 'prop-types';
import { Col, Row } from '../../../index';
import { DEFAULT_COLUMN } from '../../MultiEditBox';
import { t } from '../../../../i18n';

const allAreEquals = array =>
  !array.some(value => {
    if (isPlainObject(array[0]) && array[0].text) return array[0].text !== value?.text;

    return value !== array[0];
  });

const ColumnsEdit = ({ validColumns, selectedColumn, setSelectedColumn }) => {
  const activeColumn = validColumns[selectedColumn.value];
  useEffect(() => {
    if (!activeColumn) setSelectedColumn(DEFAULT_COLUMN);
  }, [activeColumn]);

  if (!activeColumn) return null;
  const { resolveChildren, editable, cell, ...rest } = activeColumn;
  const firstSelectedRow = rest.selectedData[0];

  const allChildrenEquals = allAreEquals(rest.selectedData.map(rowData => resolveChildren(rowData)));
  const EditableComponent = editable || cell;

  return (
    <Col>
      <Row center>
        <EditableComponent
          {...rest}
          key={selectedColumn.value}
          order={undefined}
          editable={rest.editQuery && rest.editable}
          editButtonAsProp={rest.editButtonAsProp}
          columnHeader={rest.header}
          validColumns={validColumns}
          canEdit
          multiEditView
          typeMulti="columns"
        >
          {resolveChildren(firstSelectedRow)}
        </EditableComponent>
        {!allChildrenEquals && (
          <Col className="ml-16" shrink data-test-id="multiEdit[different-values]">
            {t('react.multi_edit.different_values_in_selected_rows')}
          </Col>
        )}
      </Row>
    </Col>
  );
};
ColumnsEdit.propTypes = {
  selectedColumn: PropTypes.object,
  setSelectedColumn: PropTypes.func,
  validColumns: PropTypes.object,
};

export default ColumnsEdit;
