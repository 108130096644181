import React, { Component } from 'react';
import SVGRenderer from './SVGRenderer';

class Preview extends Component {
  componentWillMount() {
    this.objectRefs = {};
  }

  render() {
    const { background, width, height, objects, responsive = false } = this.props;
    let fixedObjects = objects;
    const style = {
      ...styles.container,
      ...this.props.style,
      width: responsive ? '100%' : width,
      height: responsive ? '100%' : height,
      padding: 0,
    };

    const canvas = {
      width: responsive ? '100%' : width,
      height: responsive ? '100%' : height,
      canvasWidth: responsive ? '100%' : width,
      canvasHeight: responsive ? '100%' : height,
    };

    const aspectX = width < height ? width / height : 1;
    const aspectY = width < height ? 1 : height / width;

    if (responsive) {
      fixedObjects = fixedObjects.map(object => ({
        ...object,
        width: (object.width / width) * 30 * aspectX,
        height: (object.height / height) * 30 * aspectY,
        x: (object.x / width) * 30 * aspectX,
        y: (object.y / height) * 30 * aspectY,
      }));
    }

    return (
      <div className={'container'} style={style}>
        <SVGRenderer
          disableHiding
          background={background}
          width={width}
          height={height}
          objects={fixedObjects}
          objectRefs={this.objectRefs}
          onRender={ref => {
            this.svgElement = ref;
          }}
          canvas={canvas}
        />
      </div>
    );
  }
}

const styles = {
  container: {
    position: 'relative',
  },
};

export default Preview;
