import { intersection, debounce } from 'lodash';
import { convertBoolean } from '../components/form';

export const matchingCondition = (value, currentValue, matchByRegexp, arrayExactMatch) =>
  (typeof value === 'boolean' && value === convertBoolean(currentValue)) ||
  (typeof currentValue === 'boolean' && convertBoolean(value) === currentValue) ||
  value === currentValue ||
  (typeof value === 'number' && typeof currentValue !== 'number' && value.toString() === currentValue) ||
  (typeof value !== 'number' && typeof currentValue === 'number' && value === currentValue.toString()) ||
  (matchByRegexp && new RegExp(value).test(currentValue)) ||
  (Array.isArray(value) &&
    Array.isArray(currentValue) &&
    (arrayExactMatch
      ? currentValue.length === value.length && intersection(value, currentValue).length === value.length
      : intersection(value, currentValue).length > 0)) ||
  (Array.isArray(value) && value.indexOf(currentValue) !== -1) ||
  (Array.isArray(currentValue) && currentValue.indexOf(value) !== -1);

window.conditionalFields = [];

const recalculateAllConditionalFields = () => window.conditionalFields.forEach(f => f());

window.recalculateAllConditionalFields = debounce(() => {
  recalculateAllConditionalFields();
  setTimeout(recalculateAllConditionalFields, 5);
}, 10);

window.formConditionalShow = () => {
  const formConditionalContainers = document.querySelectorAll('[data-form-condition]');
  if (formConditionalContainers && formConditionalContainers.length > 0) {
    Array.from(formConditionalContainers)
      .reverse()
      .forEach(container => {
        const fieldName = container.dataset.formField;
        const formOverrideFormId = container.dataset.formOverrideFormId;
        const form = (formOverrideFormId && document.getElementById(formOverrideFormId)) || container.closest('form');
        if (!form) {
          return;
        }

        const fields = form.querySelectorAll(`[name*="[${fieldName}]"]`);

        form.removeEventListener('change', window.recalculateAllConditionalFields);
        form.addEventListener('change', window.recalculateAllConditionalFields);

        Array.from(fields).forEach(f => {
          f.removeEventListener('change', window.recalculateAllConditionalFields);
          f.addEventListener('change', window.recalculateAllConditionalFields);
          f.removeEventListener('keyup', window.recalculateAllConditionalFields);
          f.addEventListener('keyup', window.recalculateAllConditionalFields);
        });

        if (container.dataset.formConditionalInited) {
          return;
        }

        const isFieldset = container.type === 'fieldset';
        const condition = container.dataset.formCondition;
        const remainVissible = container.dataset.formRemainVissible === 'true';
        const matchByRegexp = JSON.parse(container.dataset.formFieldMatchByRegexp);
        const arrayExactMatch = JSON.parse(container.dataset.formFieldArrayExactMatch);

        const value = JSON.parse(container.dataset.formFieldValue);

        const decideToShowHideContainer = () => {
          if (form) {
            const activeFields = Array.from(form.querySelectorAll(`[name*="[${fieldName}]"]`)).filter(
              field => !field.disabled
            );
            let currentValue;
            if (activeFields.length === 1) {
              currentValue = activeFields[0].value;
            }
            if (activeFields.length > 1) {
              if (
                activeFields[activeFields.length - 1].type === 'checkbox' ||
                activeFields[activeFields.length - 1].type === 'radio'
              ) {
                const currentArrayOfValues = activeFields.filter(f => f.checked).map(f => f.value);

                if (currentArrayOfValues.length > 1) {
                  currentValue = currentArrayOfValues;
                } else if (currentArrayOfValues.length === 1) {
                  currentValue = currentArrayOfValues[0];
                } else {
                  currentValue = activeFields[0].value;
                }
              } else if (activeFields[0].name.indexOf('[]') !== -1) {
                const currentArrayOfValues = activeFields.map(f => f.value);
                if (currentArrayOfValues.length > 1) {
                  currentValue = currentArrayOfValues;
                } else if (currentArrayOfValues.length === 1) {
                  currentValue = currentArrayOfValues[0];
                } else {
                  currentValue = activeFields[0].value;
                }
              } else {
                currentValue = activeFields[activeFields.length - 1].value;
              }
            }

            if (matchingCondition(value, currentValue, matchByRegexp, arrayExactMatch)) {
              if (condition === 'if') {
                if (!remainVissible) container.classList.remove('hidden');
                if (isFieldset) {
                  container.disabled = false; // eslint-disable-line no-param-reassign
                  container.querySelectorAll('select,input').forEach(e => {
                    e.disabled = false; // eslint-disable-line no-param-reassign
                  });
                }
              } else {
                if (!remainVissible) container.classList.add('hidden');
                if (isFieldset) {
                  container.disabled = true; // eslint-disable-line no-param-reassign
                  container.querySelectorAll('select,input').forEach(e => {
                    e.disabled = true; // eslint-disable-line no-param-reassign
                  });
                }
              }
            } else if (condition === 'if') {
              if (!remainVissible) container.classList.add('hidden');
              if (isFieldset) {
                container.disabled = true; // eslint-disable-line no-param-reassign
                container.querySelectorAll('select,input').forEach(e => {
                  e.disabled = true; // eslint-disable-line no-param-reassign
                });
              }
            } else {
              if (!remainVissible) container.classList.remove('hidden');
              if (isFieldset) {
                container.disabled = false; // eslint-disable-line no-param-reassign
                container.querySelectorAll('select,input').forEach(e => {
                  e.disabled = false; // eslint-disable-line no-param-reassign
                });
              }
            }
          }
        };
        decideToShowHideContainer();

        window.conditionalFields.push(decideToShowHideContainer);

        // ensure that it will recalculate after all fields were conditioned
        setTimeout(decideToShowHideContainer, 50);

        container.dataset.formConditionalInited = true; // eslint-disable-line no-param-reassign
      });
  }
};

document.addEventListener('DOMContentLoaded', window.formConditionalShow);
setTimeout(window.formConditionalShow, 50);
setTimeout(window.formConditionalShow, 100);
setTimeout(window.formConditionalShow, 150);
setTimeout(window.formConditionalShow, 200);
setTimeout(window.formConditionalShow, 1000);

export default window.formConditionalShow;
