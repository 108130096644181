import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Link, Icon } from './../index';
import PreviewInputValue from '../PreviewInputValue';
import TableHeaderItem from './TableHeaderItem';
import ProductInfoButton from './ProductInfoButton';
import { unmodifiedValue } from './utils';
import ProductsViewContext from './ProductsViewContext';
import EditColumnsLink from './EditColumnsLink';
import ImageSwitcher from './ImageSwitcher';

const TableView = ({ items = [], style, className, notScrollable = false, editColumnsLink, additonalButtonsRef }) => {
  const { tableVariablesSorted = [], mainIdentifier, cardImage } = useContext(ProductsViewContext);
  let checkedMainIdentifier = mainIdentifier;
  let mainField = tableVariablesSorted.find(({ placeholderName }) => placeholderName === mainIdentifier);
  if (!mainField) {
    mainField = tableVariablesSorted.find(({ placeholderName }) => placeholderName === 'product_id');
    checkedMainIdentifier = 'product_id';
  }
  const hasImage = cardImage !== 'not_existing#0';

  return (
    <div
      style={style}
      className={cs(
        'Sticky-Wrapper',
        'Sticky-Wrapper--smallTable',
        { 'Sticky-Wrapper--scrollable': !notScrollable },
        className
      )}
    >
      <EditColumnsLink editColumnsLink={editColumnsLink} container={additonalButtonsRef} />
      <table className="Sticky-Table Sticky-Table--striped mb-0" data-test-id="table">
        <thead>
          <tr className="Sticky-Header">
            <TableHeaderItem {...mainField} className="Sticky-Cell--first-child" />
            {hasImage && <TableHeaderItem name="Image" showFieldKind="image_url" disabledSort />}
            {tableVariablesSorted
              .filter(({ placeholderName, showInTable }) => placeholderName !== checkedMainIdentifier && showInTable)
              .map(field => (
                <TableHeaderItem key={field.name} {...field} />
              ))}
          </tr>
        </thead>
        <tbody className="Sticky-Body">
          {items.map(item => (
            <tr key={item.product_id} data-test-id="product-row" className="Sticky-Row">
              <td
                data-value={item[checkedMainIdentifier]?.toString().substring(0, 1000)}
                className={cs('delimiter', 'js-text-overflow', 'Sticky-Cell--first-child')}
              >
                <ProductInfoButton
                  showImage
                  tableVariablesSorted={tableVariablesSorted}
                  item={item}
                  mainIdentifier={checkedMainIdentifier}
                >
                  {({ onClick }) => (
                    <Link data-test-id="product-row-main-identifier" onClick={onClick} bold>
                      <PreviewInputValue
                        showInline
                        value={unmodifiedValue({ item, placeholderName: checkedMainIdentifier })}
                        showFieldKind={mainField?.showFieldKind}
                      />
                      <Icon kind="info" className="ml-8" />
                    </Link>
                  )}
                </ProductInfoButton>
              </td>
              {hasImage && (
                <td className="delimiter" data-test-id={'product-row-PREVIEW-IMAGE'}>
                  <ImageSwitcher
                    cardImage={cardImage}
                    tableVariablesSorted={tableVariablesSorted}
                    item={item}
                    withoutTile
                    fullWidth
                    size={85}
                    spacing={0}
                  />
                </td>
              )}
              {tableVariablesSorted
                .filter(({ placeholderName, showInTable }) => placeholderName !== checkedMainIdentifier && showInTable)
                .map(({ name, placeholderName, showFieldKind }) => (
                  <td
                    data-value={unmodifiedValue({ item, placeholderName })
                      ?.toString()
                      .substring(0, 1000)}
                    data-test-id={`product-row-${placeholderName}`}
                    key={name}
                    className="delimiter js-text-overflow"
                  >
                    <PreviewInputValue
                      value={unmodifiedValue({ item, placeholderName })}
                      showFieldKind={showFieldKind}
                    />
                  </td>
                ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

TableView.propTypes = {
  additonalButtonsRef: PropTypes.any,
  className: PropTypes.string,
  editColumnsLink: PropTypes.bool,
  items: PropTypes.array,
  mainIdentifier: PropTypes.string,
  notScrollable: PropTypes.bool,
  order: PropTypes.string,
  setOrder: PropTypes.func,
  style: PropTypes.object,
  tableVariablesSorted: PropTypes.array,
};

export default TableView;
