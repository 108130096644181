const toggleState = containerEl => {
  containerEl.querySelector('.js-show-more-content').classList.toggle('hidden-js-block');
  containerEl.querySelector('.js-show-more-link').classList.toggle('hidden-js-block');
  containerEl.querySelector('.js-show-less-link').classList.toggle('hidden-js-block');
};

window.addEventListener('load', () => {
  $(document).on('click', '.js-show-more-container .js-show-more-link', event => {
    toggleState(event.target.closest('.js-show-more-container'));
  });

  $(document).on('click', '.js-show-more-container .js-show-less-link', event => {
    toggleState(event.target.closest('.js-show-more-container'));
  });
});
