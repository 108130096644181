import React from 'react';
import PropTypes from 'prop-types';

export const ArrowDown = ({ size = '12' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height={size} viewBox="0 0 10 12">
    <g fill="none" fillOpacity="0.2" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="#00162A" transform="translate(-63 -184)">
        <path d="M69 184v8.376L71.558 190 73 191.282 67.992 196 63 191.282 64.426 190 67 192.391V184h2z" />
      </g>
    </g>
  </svg>
);

ArrowDown.propTypes = {
  size: PropTypes.string,
};

export const ArrowWrapper = ({ arrowSize = '8', children }) => (
  <div className="pos-relative">
    <div className="pos-absolute align-horizontal line-height-1" style={{ top: '-12px' }}>
      <ArrowDown size={arrowSize} />
    </div>
    {children}
  </div>
);

ArrowWrapper.propTypes = {
  arrowSize: PropTypes.string,
  children: PropTypes.any,
};

export const ArrowWithCircle = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    version="1.1"
    width="12"
    height="36"
    viewBox="0 0 12 36"
    xmlSpace="preserve"
  >
    <path
      fill="#00162A"
      d="M7 32.4V11.9c2.8-.5 5-2.9 5-5.9 0-3.3-2.7-6-6-6S0 2.7 0 6c0 3 2.2 5.4 5 5.9v20.5L2.4 30 1 31.3 6 36l5-4.7L9.6 30 7 32.4zM2 6c0-2.2 1.8-4 4-4s4 1.8 4 4-1.8 4-4 4-4-1.8-4-4z"
      opacity="0.2"
    />
  </svg>
);
