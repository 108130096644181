/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { useDebounce } from 'use-debounce';
import cs from 'classnames';
import { includes } from 'lodash';
import { SmartSelect, Select, Input, Button, Row, Col, Tile } from '../components';
import PreviewDataModal from '../placeholders/PreviewDataModal';
import updatePreview from './updatePreview';
import { t } from '../i18n';

export const QUERY = gql`
  query ConditionsCount(
    $dataSourceId: BigInt
    $campaignSettingId: BigInt
    $feedMappingId: BigInt
    $conditions: [String!]
    $elasticQuery: String
  ) {
    allCount: conditionCount(
      dataSourceId: $dataSourceId
      campaignSettingId: $campaignSettingId
      feedMappingId: $feedMappingId
      elasticQuery: $elasticQuery
    )
    selectedCount: conditionCount(
      dataSourceId: $dataSourceId
      campaignSettingId: $campaignSettingId
      feedMappingId: $feedMappingId
      conditions: $conditions
      elasticQuery: $elasticQuery
    )
    errors: validateConditions(conditions: $conditions)
  }
`;

const CustomCondition = props => {
  const { isLast, disabled, disableRemove, getSubFiledOptions, id, onRemove, options = {}, updateFieldData } = props;
  const [state, setState] = useState({
    left_part: props.left_part || '',
    right_part: props.right_part || '',
    right_part_array: props.right_part_array || [],
    equation: props.equation || '',
    validationError: null,
  });
  const { left_part, right_part, right_part_array, equation, validationError } = state;

  const [debouncedVariables] = useDebounce(
    () => ({
      ...(options.load_options_filter || {}),
      conditions: [JSON.stringify({ left_part, equation, right_part, right_part_array })],
    }),
    1000,
    { leading: true }
  );

  const { loading, data, error } = useQuery(QUERY, {
    variables: debouncedVariables,
  });

  const getLeftPart = () => options.left_part_options || [];
  const getEquation = () =>
    (options.equation_options || []).filter(eq => !eq.available || eq.available.indexOf(options.available) !== -1);

  const getValidateRightPart = value => {
    const type = getEquationType(left_part);

    if (type === 'number' && !/^\s*[\d\s,.]*\s*$/.test(value)) {
      return t('errors.messages.not_a_number');
    }
    return null;
  };

  const getEquationType = () => (getLeftPart().find(option => option.value === left_part) || { type: 'all' }).type;

  const getValidEquations = () => {
    const type = getEquationType();
    return getEquation().filter(eq => includes(eq.type, type) || eq.type === 'all' || eq.type === 'field');
  };
  const handleLeftPart = ({ target: { value } }) => {
    setState({ ...state, left_part: value, validationError: getValidateRightPart(right_part, value) });
    updateFieldData(id, { left_part: value });
    updatePreview();
  };
  const handleEquation = ({ target: { value } }) => {
    setState({ ...state, equation: value, validationError: getValidateRightPart(right_part) });
    updateFieldData(id, { equation: value });
    updatePreview();
  };
  const handleRightPart = ({ target: { value } }) => {
    setState({ ...state, right_part: value, validationError: getValidateRightPart(value) });
    updateFieldData(id, { right_part: value });
    updatePreview();
  };

  const handleRightPartArray = ({ target: { value } }) => {
    setState({ ...state, right_part_array: value });
    updateFieldData(id, { right_part_array: value });
    updatePreview();
  };

  const renderRightPart = () => {
    const selectedEquation = getEquation().find(eq => eq.value === equation);

    if (selectedEquation && selectedEquation.type === 'field') {
      return null;
    }

    if (selectedEquation && (equation === 'ONEOF' || equation === 'NOTONEOF')) {
      return (
        <SmartSelect
          value={right_part_array}
          multiple
          doNotUseInternalState
          creatable
          key={left_part}
          loadOptionsFilter={
            options.load_options_filter && left_part && { ...options.load_options_filter, field: left_part }
          }
          onChange={handleRightPartArray}
          {...getSubFiledOptions('right_part_array')}
        />
      );
    }

    return (
      <Input
        value={right_part}
        doNotUseInternalState
        key={left_part}
        onChange={handleRightPart}
        {...getSubFiledOptions('right_part')}
      />
    );
  };
  const leftPart = getLeftPart().find(left => left_part === left.value);
  const hasLeftPartMissingValue = !leftPart;
  const { twoLine } = options;

  useEffect(() => {
    const validationErr = getValidateRightPart(right_part);
    if (validationErr) {
      setState({ ...state, validationErr });
    }
  }, []);

  return (
    <React.Fragment>
      <Tile contentStyle={{ padding: '8px 16px' }} className="mb-8" style={{ maxWidth: '800px' }}>
        <Row center data-test-id="nested-field-condition">
          <Col noPadding>
            <Row padding="m" grow top className={cs({ 'flex-gap-8': twoLine })} flexwrap={twoLine}>
              <Col
                style={!twoLine && { maxWidth: '285px' }}
                grow
                width={twoLine && '100%'}
                className={cs({ 'mb-8': twoLine })}
              >
                <Row>
                  <Col grow>
                    <Select
                      value={left_part}
                      doNotUseInternalState
                      onChange={handleLeftPart}
                      {...getSubFiledOptions('left_part')}
                    >
                      {getLeftPart().map(option =>
                        hasLeftPartMissingValue && option.value === '' ? (
                          <option key={option.value} value={left_part}>
                            {options.missing_field_text.replace(
                              '%{missing_field}',
                              left_part.replace('_modifiers.', '')
                            )}
                          </option>
                        ) : (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        )
                      )}
                    </Select>
                  </Col>
                  {leftPart && leftPart.previewLink ? (
                    <Col shrink>
                      <PreviewDataModal previewLink={leftPart.previewLink} name={leftPart.key}>
                        <Button tertiary onlyIcon icon="magnifier" tabIndex="-1" />
                      </PreviewDataModal>
                    </Col>
                  ) : null}
                  <Col grow>
                    <Select
                      value={equation}
                      doNotUseInternalState
                      onChange={handleEquation}
                      {...getSubFiledOptions('equation')}
                    >
                      {getValidEquations().map(option =>
                        hasLeftPartMissingValue && option.value === '' ? (
                          <option key={option.value} value={left_part}>
                            {options.missing_field_text.replace('%{missing_field}', options.equations_texts[equation])}
                          </option>
                        ) : (
                          <option key={option.value} value={option.value}>
                            {option.key}
                          </option>
                        )
                      )}
                    </Select>
                  </Col>
                </Row>
              </Col>
              <Col
                className={cs({ 'mb-0': twoLine })}
                grow={!twoLine}
                width={twoLine ? '100%' : undefined}
                style={{ minWidth: '200px' }}
              >
                {renderRightPart()}
              </Col>
            </Row>
          </Col>
          <Col shrink>
            {id && onRemove && (
              <Button
                tertiary
                onlyIcon
                disabled={disabled || disableRemove}
                onClick={onRemove}
                icon="trash"
                tabIndex="-1"
                data-test-id="nested-field-remove"
              />
            )}
          </Col>
        </Row>
        {(left_part.toString().length > 0 ||
          equation.toString().length > 0 ||
          right_part.toString().length > 0 ||
          right_part_array.length > 0) &&
          data?.errors &&
          !loading &&
          data.errors.length > 0 && (
            <Row className="mt-4" shrink>
              <Col>
                <div className="ValidationError" data-test-id="condition-erro">
                  {data.errors}
                </div>
              </Col>
            </Row>
          )}
      </Tile>
      {!isLast && <div className="mb-8 ml-16 Text Text--softGray Text--semibold">{t('react.and')}</div>}
    </React.Fragment>
  );
};
CustomCondition.propTypes = {
  isLast: PropTypes.bool,
  disabled: PropTypes.bool,
  disableRemove: PropTypes.bool,
  equation: PropTypes.string,
  fieldCount: PropTypes.number,
  getSubFiledOptions: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  left_part: PropTypes.string,
  right_part_array: PropTypes.array,
  onRemove: PropTypes.func.isRequired,
  options: PropTypes.object,
  right_part: PropTypes.string,
  updateFieldData: PropTypes.func.isRequired,
};

export default CustomCondition;
