import React from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { gql, useQuery, useMutation, useLazyQuery } from '@apollo/client';
import qs from 'qs';
import cs from 'classnames';
import {
  Button,
  Col,
  DropdownMenu,
  SmallHeading,
  Icon,
  Link,
  Loader,
  PageHeading,
  Row,
  SectionHeading,
  Text,
} from './index';
import { t } from '../i18n';
import { IconBox, OrganizationsBox, RelatedSubject, TimeInfo, ActionBar } from './notifications/NotificationMessage';
import emptyStateImage from '../../../assets/images/empty_container/notifications_icon.png';
import Avatar from './Avatar';

const QUERY_NOTIFICATION_COUNT = gql`
  query NotificationCount($organizationId: BigInt!) {
    notificationsAllCount
    notificationsUnseenCount
    notificationsUrl
    notificationsSettingUrl(organizationId: $organizationId)
  }
`;

const QUERY_NOTIFICATION = gql`
  query Notification($page: Int!, $limit: Int!, $kind: String, $visibility: String, $organizationId: BigInt) {
    notifications(page: $page, limit: $limit, kind: $kind, visibility: $visibility, organizationId: $organizationId) {
      id
      time
      icon
      subject
      message
      link {
        href
        children
        icon
      }
      relatedSubject {
        href
        icon
        children
      }
      organizations {
        name
        showLink
      }
      seen
      likes
      likesEnabled
      dislikes
      vote
      youtubeLink
      imageLink
      learnMoreLink {
        href
        icon
        children
      }
      author {
        firstName
        lastName
        image
      }
      state
    }
  }
`;

const MARK_ALL_SEEN_MUTATION = gql`
  mutation MarkAllInteractionsAsSeen {
    markInteractionsAsSeen {
      notifications {
        id
        seen
      }
      errors
    }
  }
`;

const INTERACTION_QUERY = gql`
  mutation RecordInteraction($id: String!, $seen: Boolean!, $vote: String) {
    recordNotificationInteraction(id: $id, seen: $seen, vote: $vote) {
      notification {
        id
        seen
        vote
      }
    }
  }
`;

const NotificationItem = ({ notificationsUrl, ...notification }) => {
  const urlParams = qs.stringify({ notification: notification.id });
  const [changeVisibility] = useMutation(INTERACTION_QUERY);
  const markAsSeen = () => changeVisibility({ variables: { id: notification.id, seen: true } });

  return (
    <li data-test-id={`notifications-item--${notification.id}`}>
      <Link className={cs({ 'Link--grayBackground': !notification.seen })} href={`${notificationsUrl}?${urlParams}`}>
        <Row padding="l">
          <Col width="40px" className="pos-relative">
            {notification.icon === 'mail' ? (
              <Avatar imageUrl={notification.author?.image} icon="user" size="xl" />
            ) : (
              <IconBox {...notification} lightBackground={!notification.seen} />
            )}
            {!notification.seen && <span className="Notification-stateDot" />}
          </Col>
          <Col grow>
            {(notification.subject || notification.time) && (
              <Row center className="mt-8">
                {notification.subject && (
                  <Col grow className="mb-8">
                    <SmallHeading spacing={0}>{notification.subject}</SmallHeading>
                  </Col>
                )}
                {notification.time && (
                  <Col shrink className="ml-a mb-8">
                    <TimeInfo {...notification} />
                  </Col>
                )}
              </Row>
            )}

            {notification.organizations && notification.organizations.length > 0 && (
              <Row>
                <OrganizationsBox {...notification} />
              </Row>
            )}

            {notification.relatedSubject && (
              <Row>
                <RelatedSubject {...notification} twoLines />
              </Row>
            )}

            <ActionBar {...notification} isAccordionOpen={false} markAsSeen={markAsSeen} />
          </Col>
        </Row>
      </Link>
    </li>
  );
};

const EmptyItem = () => (
  <li className="ph-16 pv-48">
    <Col center padding="l">
      <Row>
        <img src={emptyStateImage} width="164px" alt="Empty state" />
      </Row>
      <Row>
        <PageHeading spacing={0}>
          {t('views.layout_elements.header.components.notification_incidents.empty_heading', {
            default: 'No New Notifications',
          })}
        </PageHeading>
      </Row>
      <Row>
        <Text color="gray">
          {t('views.layout_elements.header.components.notification_incidents.no_erros_found', {
            default: "You're all caught up! Check back later for updates.",
          })}
        </Text>
      </Row>
    </Col>
  </li>
);

const ListContent = ({ loading, notifications, notificationsUrl }) => {
  if (loading) {
    return (
      <div className="pa-24 text-center">
        <Loader />
      </div>
    );
  }

  return notifications && notifications.length > 0 ? (
    notifications.map(n => <NotificationItem {...n} notificationsUrl={notificationsUrl} key={n?.id} />)
  ) : (
    <EmptyItem />
  );
};

const NotificationCenter = ({ organizationId }) => {
  const { data, loading, error } = useQuery(QUERY_NOTIFICATION_COUNT, {
    variables: {
      organizationId,
    },
  });
  const [loadNotifications, notificationQuery] = useLazyQuery(QUERY_NOTIFICATION, {
    variables: {
      page: 1,
      limit: 10,
      kind: 'all',
      visibility: 'all',
      organizationId,
    },
  });
  const [markAll, { called }] = useMutation(MARK_ALL_SEEN_MUTATION);

  if (error) {
    return (
      <Col shrink nowrap className="mr-24">
        <Icon kind="error-circle" color="#ff493f" />
      </Col>
    );
  }

  if (loading) {
    return (
      <Col shrink nowrap className="mr-24">
        <Loader size="small" />
      </Col>
    );
  }

  const { notificationsUnseenCount, notificationsUrl, notificationsSettingUrl } = data;
  const { notifications } = notificationQuery.data || {};
  const count = called ? 0 : notificationsUnseenCount;
  return (
    <Col shrink nowrap className="mr-24">
      <DropdownMenu
        badgeCount={count}
        dataTestId="notifications-button"
        error={error}
        icon="bell"
        loading={loading}
        onOpen={() => loadNotifications()}
        placement="bottom-end"
        primary={false}
        secondary
        withBadge
        withList
      >
        <div data-test-id="notifications-dropdown">
          <div className="DropdownMenu-heading">
            <Row baseline className="pt-12 pb-4">
              <SectionHeading spacing={0}>{t('react.notification.page_heading')}</SectionHeading>
              {!called && count !== 0 && (
                <Link
                  className="Link--dropdown ml-a"
                  data-test-id="notifications-mark_all_as_seen_button"
                  icon="double-check"
                  onClick={() => markAll()}
                >
                  {t('react.notification.mark_all_as_seen')}
                </Link>
              )}
            </Row>
          </div>
          <ul className="DropdownMenu-list">
            <ListContent
              loading={notificationQuery?.loading}
              notifications={notifications}
              notificationsUrl={notificationsUrl}
            />
          </ul>
          <div className="DropdownMenu-footer pt-16 pb-12">
            <Row center justifyBetween>
              <Col shrink>
                <Button
                  className="Link--footer"
                  href={notificationsSettingUrl}
                  icon="notification-settings"
                  secondary
                  tag="a"
                >
                  Notification Settings
                </Button>
              </Col>
              <Col shrink>
                <Button
                  className="Link--footer"
                  data-test-id="notifications-all_notifications_button"
                  href={notificationsUrl}
                  primary
                  tag="a"
                >
                  {t('views.layout_elements.header.components.notification_incidents.all_notification', {
                    default: 'Show All',
                  })}
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </DropdownMenu>
    </Col>
  );
};

ListContent.propTypes = {
  notificationsUrl: PropTypes.string,
  notifications: PropTypes.array,
  loading: PropTypes.bool,
};

NotificationItem.propTypes = {
  icon: PropTypes.string,
  id: PropTypes.string,
  link: PropTypes.object,
  notificationsUrl: PropTypes.string,
  organizations: PropTypes.array,
  subject: PropTypes.string,
  time: PropTypes.string,
};

NotificationCenter.propTypes = {
  organizationId: PropTypes.number,
};

export default hot(NotificationCenter);
