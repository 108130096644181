/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import ErrorBox from './ErrorBox';
import Storage from '../utils/storage';
import { t } from '../i18n';

export const LocalStateUsedContext = React.createContext(() => {});

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.any,
  };

  state = { hasError: false, possibleRetry: true };
  usedLocalState = [];

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  registerUsedLocalState = key => {
    if (this.usedLocalState.indexOf(key) === -1) {
      this.usedLocalState = this.usedLocalState.concat(key);
    }
  };

  tryToRenderWithoutLocalState = () => {
    setTimeout(() => {
      console.log('cleaning local storage', this.usedLocalState);
      this.usedLocalState.map(s => Storage.remove(s));
      this.usedLocalState = [];
      this.setState({ hasError: false, possibleRetry: false });
    }, 1);
  };

  render() {
    if (this.state.hasError) {
      if (this.usedLocalState.length > 0 && this.state.possibleRetry) {
        this.tryToRenderWithoutLocalState();
      }
      return <ErrorBox withIcon>{t('react.error_with_component_render')}</ErrorBox>;
    }

    return (
      <LocalStateUsedContext.Provider value={this.registerUsedLocalState}>
        {this.props.children}
      </LocalStateUsedContext.Provider>
    );
  }
}
