/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import { gql, useQuery } from '@apollo/client';
import Chart from './Chart';
import NestedFields from '../../nested_fields';
import equationOptions from './equationOptions';
import { Button, Select, Row, Col, Tile } from '../index';
import CreateReportView from './CreateReportView';
import DimensionFilter from './DimensionFilter';
import { formatCurrency, formatNumber, formatInteger } from '../../i18n';
import ExportCSV from './exportCSV';
import { Loader } from '../Icons';

const convertDimensionsToConditions = dimensions => [
  {
    key: 'Select',
  },
  ...Object.keys(dimensions).map(dimension => ({ key: dimension, value: dimension, type: dimensions[dimension] })),
];

const QUERY_REPORT_DATA = dimensions => gql`
  query OrganizationReportData($organizationId: BigInt!, $reportId: BigInt!, $conditions: [String!], $zoomFilters: [String!], $selectedFilters: [String!], $dimensions: [String!]!) {
    organization(id: $organizationId) {
      id
      analyticsReport(id: $reportId) {
        analyticsReportView(conditions: $conditions, zoomFilters: $zoomFilters, selectedFilters: $selectedFilters) {
          keywords(size: 400, order: "clicks desc", dimensions: $dimensions) {
            currency
            criteria
            count
            dimensionX
            dimensionY
            dimensionXMin
            dimensionYMin
            dimensionXMax
            dimensionYMax
            ${dimensions.map(camelCase).join(' ')}
          }
          summaryAll {
            count
            cost
            currency
            ${dimensions.map(camelCase).join(' ')}
          }
          summarySelected {
            count
            cost
            currency
            ${dimensions.map(camelCase).join(' ')}
          }
        }
      }
    }
  }
`;

const QUERY_REPORT_DIMENSIONS = gql`
  query OrganizationReportData($organizationId: BigInt!, $reportId: BigInt!) {
    organization(id: $organizationId) {
      id
      analyticsReport(id: $reportId) {
        dimensions
      }
    }
  }
`;

const Summary = ({ data, selectedDimensions }) => {
  return (
    <Row>
      <Col className="text-center">
        <Tile>
          <b>{formatInteger(data.count)}</b>
          <br />
          <i>count</i>
        </Tile>
      </Col>
      <Col className="text-center">
        <Tile>
          <b>{formatCurrency(data.cost, { currency: data.currency })}</b>
          <br />
          <i>cost</i>
        </Tile>
      </Col>
      {selectedDimensions.map(d => (
        <Col className="text-center" key={d}>
          <Tile>
            <b>{formatNumber(data[d], { precision: 0 })}</b>
            <br />
            <i>{d}</i>
          </Tile>
        </Col>
      ))}
    </Row>
  );
};

const ReportData = ({
  organizationId,
  reportId,
  conditions,
  selectedDimensions,
  allDimensions,
  zoomFilters,
  selectedFilters,
  ...rest
}) => {
  const { loading, error, data } = useQuery(QUERY_REPORT_DATA(selectedDimensions), {
    variables: {
      organizationId,
      reportId,
      dimensions: selectedDimensions,
      conditions: conditions.map(JSON.stringify),
      zoomFilters: zoomFilters.map(JSON.stringify),
      selectedFilters: selectedFilters.map(JSON.stringify),
    },
  });
  return (
    <div className="mt-16">
      {error && 'ERROR'}
      {loading && <Loader size="small" />}
      <Row top>
        <Col grow>
          <Chart
            selectedDimensions={selectedDimensions}
            data={error || !data ? [] : data.organization.analyticsReport.analyticsReportView.keywords}
            organizationId={organizationId}
            reportId={reportId}
            {...rest}
          />
        </Col>
        <Col shrink>
          Total summary
          <Summary
            selectedDimensions={selectedDimensions}
            data={error || !data ? [] : data.organization.analyticsReport.analyticsReportView.summaryAll}
          />
          Filtered summary
          <Summary
            selectedDimensions={selectedDimensions}
            data={error || !data ? [] : data.organization.analyticsReport.analyticsReportView.summarySelected}
          />
        </Col>
      </Row>
    </div>
  );
};

const Report = ({ organizationId, reportId }) => {
  const [conditions, setConditions] = useState([]);
  const [zoomFilters, setZoomFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedDimensions, setDimensions] = useState(['clicks', 'impressions']);

  const { loading, error, data: dimensionsData } = useQuery(QUERY_REPORT_DIMENSIONS, {
    variables: { organizationId, reportId },
  });
  if (loading) return <Loader size="big" />;
  if (error) return `Error! ${error.message}`;

  const allDimensions = JSON.parse(dimensionsData.organization.analyticsReport.dimensions);
  const numberDimensions = Object.keys(allDimensions).filter(dimension => allDimensions[dimension] === 'number');
  const groupsDimensions = Object.keys(allDimensions).filter(
    dimension => ['word_count', 'campaign_name'].indexOf(dimension) !== -1
  );

  return (
    <div>
      <h2>Keywords report #{reportId}</h2>

      <Row justifyBetween>
        <Col width="40%">
          <label>Filters</label>
          <NestedFields
            field="conditions"
            formBase="x"
            fieldData={[]}
            options={{
              missing_field_text: '',
              left_part_options: convertDimensionsToConditions(allDimensions),
              equation_options: equationOptions,
              equations_texts: [],
            }}
            onChange={givenConditions =>
              setConditions(
                givenConditions
                  .filter(
                    c =>
                      c.right_part &&
                      c.right_part.length > 0 &&
                      c.left_part &&
                      c.left_part.length > 0 &&
                      c.equation &&
                      c.equation.length > 0
                  )
                  .map(({ left_part, equation, right_part }) => ({ left_part, equation, right_part }))
              )
            }
          />
        </Col>
        <Col width="40%">
          <Row>
            <Col>
              <Select
                label="Dimenze X"
                id="dimensio0"
                collection={numberDimensions}
                value={selectedDimensions[0]}
                doNotUseInternalState
                onChange={({ target: { value } }) => {
                  setDimensions([value, selectedDimensions[1], selectedDimensions[2]].filter(x => x && x.length > 0));
                  setZoomFilters([]);
                }}
              />
            </Col>
            <Col shrink>
              <Button
                secondary
                className="mt-24"
                onClick={() => {
                  setDimensions(
                    [selectedDimensions[1], selectedDimensions[0], selectedDimensions[2]].filter(x => x && x.length > 0)
                  );
                }}
              >
                Swap
              </Button>
            </Col>
            <Col>
              <Select
                label="Dimenze Y"
                id="dimension1"
                collection={numberDimensions}
                value={selectedDimensions[1]}
                doNotUseInternalState
                onChange={({ target: { value } }) => {
                  setDimensions([selectedDimensions[0], value, selectedDimensions[2]].filter(x => x && x.length > 0));
                  setZoomFilters([]);
                }}
              />
            </Col>
            <Col>
              <Select
                label="Group By"
                id="dimension2"
                collection={[''].concat(groupsDimensions)}
                value={selectedDimensions[2] || ''}
                doNotUseInternalState
                onChange={({ target: { value } }) =>
                  setDimensions([selectedDimensions[0], selectedDimensions[1], value].filter(x => x && x.length > 0))
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row justifyBetween>
        <Col width="40%">
          {selectedFilters.length > 0 && (
            <React.Fragment>
              <label>Selected Dimension Filters</label>
              {selectedFilters.map(filter => (
                <DimensionFilter
                  key={filter.id}
                  {...filter}
                  useDimensions={() =>
                    setDimensions(
                      [filter.dimensionX, filter.dimensionY, selectedDimensions[2]].filter(x => x && x.length > 0)
                    )
                  }
                  removeFilter={() => setSelectedFilters(selectedFilters.filter(f => f.id !== filter.id))}
                />
              ))}
            </React.Fragment>
          )}
        </Col>
        <Col width="40%">
          {zoomFilters.length > 0 && (
            <React.Fragment>
              <label>Summary filters</label>
              {zoomFilters.map(filter => (
                <DimensionFilter
                  key={filter.id}
                  {...filter}
                  useDimensions={() =>
                    setDimensions(
                      [filter.dimensionX, filter.dimensionY, selectedDimensions[2]].filter(x => x && x.length > 0)
                    )
                  }
                  removeFilter={() => setZoomFilters(zoomFilters.filter(f => f.id !== filter.id))}
                />
              ))}
            </React.Fragment>
          )}
        </Col>
      </Row>

      <ReportData
        organizationId={organizationId}
        reportId={reportId}
        conditions={conditions}
        selectedDimensions={selectedDimensions}
        zoomFilters={zoomFilters}
        selectedFilters={selectedFilters}
        addZoomFilter={newFilter => setZoomFilters([newFilter])}
        addSelectedFilter={newFilter => setSelectedFilters([...selectedFilters, newFilter])}
      />

      <Row>
        <Col shrink>
          <CreateReportView
            organizationId={organizationId}
            reportId={reportId}
            conditions={conditions}
            selectedDimensions={selectedDimensions}
            zoomFilters={zoomFilters}
            selectedFilters={selectedFilters}
            action="stop_keywords"
            disabled
          />
        </Col>
        <Col shrink>
          <CreateReportView
            organizationId={organizationId}
            reportId={reportId}
            conditions={conditions}
            selectedDimensions={selectedDimensions}
            zoomFilters={zoomFilters}
            selectedFilters={selectedFilters}
            action="apply_label"
            disabled
          />
        </Col>
        <Col shrink>
          <ExportCSV
            organizationId={organizationId}
            reportId={reportId}
            conditions={conditions}
            selectedFilters={selectedFilters}
            disabled
          />
        </Col>
      </Row>
    </div>
  );
};

export default Report;
