export default {
  propertyPanel: {},
  propertyGroup: {},
  inputHelper: {
    fontSize: 9,
    color: '#d2d2d2',
    paddingTop: 2,
    paddingLeft: 5,
  },
  inlineInputHelper: {
    fontSize: 9,
    display: 'inline-block',
    marginLeft: 10,
  },
  panelTitle: {
    padding: 3,
    color: '#b8b8b8',
  },
  columns: {
    overflow: 'hidden',
    marginTop: 10,
  },
  column: {
    marginRight: 5,
  },
  empty: {
    display: 'none',
  },
  button: {
    color: '#515151',
    textDecoration: 'none',
    display: 'block',
    padding: '2px 0',
  },
  item: {
    padding: '2px 6px',
    cursor: 'default',
  },

  highlightedItem: {
    color: 'white',
    background: 'hsl(200, 50%, 50%)',
    padding: '2px 6px',
    cursor: 'default',
  },
};
