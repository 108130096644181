import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Icon } from './index';

const Avatar = ({ size, className, isHoverable, icon, initials, imageUrl, hasActiveBadge }) => (
  <div
    className={cs('Avatar', `Avatar--${size}`, className, {
      'Avatar--hoverable': isHoverable,
      'Avatar--default': icon,
      'Avatar--initials': initials,
      'Avatar--withStateBadge': hasActiveBadge,
    })}
  >
    {imageUrl && <img className="Avatar-image" src={imageUrl} alt="User avatar" />}
    {!imageUrl && icon && <Icon kind={icon} inheritColor />}
    {!imageUrl && !icon && initials && <span>{initials}</span>}
  </div>
);

Avatar.propTypes = {
  className: PropTypes.string,
  hasActiveBadge: PropTypes.bool,
  icon: PropTypes.string,
  imageUrl: PropTypes.string,
  initials: PropTypes.string,
  isHoverable: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'xl', 'xxl']),
};

export default Avatar;
