/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class TermPrices extends React.PureComponent {
  static propTypes = {
    desc: PropTypes.string,
    from: PropTypes.string,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    to: PropTypes.string,
    final: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    desc: this.props.desc || '',
    from: this.props.from || '',
    to: this.props.to || '',
    final: this.props.final || '',
  };

  handleDesc = ({ target: { value } }) => {
    if (value !== this.state.desc) {
      this.setState({ desc: value });
      this.props.updateFieldData(this.props.id, { desc: value });
      updatePreview();
    }
  };

  handleFrom = ({ target: { value } }) => {
    if (value !== this.state.from) {
      this.setState({ from: value });
      this.props.updateFieldData(this.props.id, { from: value });
      updatePreview();
    }
  };

  handleTo = ({ target: { value } }) => {
    if (value !== this.state.to) {
      this.setState({ to: value });
      this.props.updateFieldData(this.props.id, { to: value });
      updatePreview();
    }
  };

  handleFinal = ({ target: { value } }) => {
    if (value !== this.state.final) {
      this.setState({ final: value });
      this.props.updateFieldData(this.props.id, { final: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { to, from, desc, final } = this.state;

    return (
      <Row center padding="l" data-test-id="term_prices" className="mb-8">
        <Col width="12%" data-test-id="term_prices-desc" className="mr-8">
          <PlaceholderInput
            defaultValue={desc}
            onChange={this.handledesc}
            placeholder={t('views.term_prices.fields.desc_id', { default: 'Desc ID' })}
            {...getSubFiledOptions('desc')}
          />
        </Col>
        <Col grow data-test-id="term_prices-from" style={{ maxWidth: '12%' }} className="mr-8">
          <PlaceholderInput
            defaultValue={from}
            onChange={this.handleFrom}
            placeholder={t('views.term_prices.fields.age_from', { default: 'Age from' })}
            {...getSubFiledOptions('from')}
          />
        </Col>
        <Col grow data-test-id="term_prices-to" style={{ maxWidth: '12%' }} className="mr-8">
          <PlaceholderInput
            defaultValue={to}
            onChange={this.handleTo}
            placeholder={t('views.term_prices.fields.age_to', { default: 'Age to' })}
            {...getSubFiledOptions('to')}
          />
        </Col>
        <Col grow data-test-id="term_prices-final">
          <PlaceholderInput
            defaultValue={final}
            onChange={this.handleFinal}
            placeholder={t('views.term_prices.fields.final_price', { default: 'Final price' })}
            {...getSubFiledOptions('final')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.term_prices.example')} <span class="Text--bold">${t(
            'views.term_prices.example_text'
          )}</span>`}
          data-field={`term_prices.${desc}`}
        >
          <div className="Text">
            {t('views.term_prices.example')} <span className="Text--bold">{t('views.term_prices.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default TermPrices;
