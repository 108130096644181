document.addEventListener('DOMContentLoaded', () => {
  const resolveBarSticky = e => {
    const actionBars = e.target.querySelectorAll('.js-newLayout-actionBar');
    const stickyBars = e.target.querySelectorAll('.js-newLayout-stickyBar');
    const container = e.target;

    if (actionBars.length > 0) {
      if (container.offsetHeight + container.scrollTop + 30 < container.scrollHeight) {
        actionBars.forEach(bar => {
          bar.classList.add('NewLayout-actionBar--sticky');
        });
      } else {
        actionBars.forEach(bar => {
          bar.classList.remove('NewLayout-actionBar--sticky');
        });
      }
    }

    if (stickyBars.length > 0 && container) {
      try {
        const containerStyle = container.currentStyle || window.getComputedStyle(container);
        const containerPadding = parseFloat(containerStyle.paddingTop);

        Array.from(stickyBars).map(stickyBar => {
          // eslint-disable-next-line no-param-reassign
          stickyBar.style.top = `-${containerPadding}px`;
          if (stickyBar.getBoundingClientRect().top === container.getBoundingClientRect().top) {
            stickyBar.classList.add('NewLayout-stickyBar--sticky');
          } else {
            stickyBar.classList.remove('NewLayout-stickyBar--sticky');
          }
          return null;
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Action bar error:', error);
      }
    }
  };

  document.addEventListener('scroll', resolveBarSticky, true);

  const initBars = element => {
    Array.from(element.querySelectorAll('.js-newLayout-actionBar')).map(actionBar =>
      resolveBarSticky({ target: actionBar.offsetParent })
    );

    Array.from(element.querySelectorAll('.js-newLayout-stickyBar')).map(stickyBar =>
      resolveBarSticky({ target: stickyBar.offsetParent })
    );
  };

  initBars(document);
  setTimeout(() => initBars(document), 100);

  window.initBars = initBars;

  $(document).on('shown.bs.modal', e => {
    const modal = e.target;
    setTimeout(() => initBars(modal), 10);
  });
});
