import React from 'react';
import PropTypes from 'prop-types';
import { Input } from '../components/form';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Row, Col, Button, TileArrowDelimiter } from '../components/index';

class GoogleProductDetail extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
    section_name: PropTypes.string,
    attribute_name: PropTypes.string,
    attribute_value: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    sectionName: this.props.section_name || '',
    attributeName: this.props.attribute_name || '',
    attributeValue: this.props.attribute_value || '',
  };

  handleSectionName = ({ target: { value } }) => {
    if (value !== this.state.sectionName) {
      this.setState({ sectionName: value });
      this.props.updateFieldData(this.props.id, { section_name: value });
      updatePreview();
    }
  };

  handleAttributeName = ({ target: { value } }) => {
    if (value !== this.state.attributeName) {
      this.setState({ attributeName: value });
      this.props.updateFieldData(this.props.id, { attribute_name: value });
      updatePreview();
    }
  };

  handleAttributeValue = ({ target: { value } }) => {
    if (value !== this.state.attributeValue) {
      this.setState({ attributeValue: value });
      this.props.updateFieldData(this.props.id, { attribute_value: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { attributeValue, attributeName, sectionName } = this.state;

    return (
      <Row padding="l" center data-test-id="product-details" className="mb-8">
        <Col width="25%" data-test-id="product_details-section" className="mr-8">
          <Input
            defaultValue={sectionName}
            onChange={this.handleSectionName}
            placeholder={t('views.google_merchant_params.fields.section_name', { default: 'Section name' })}
            {...getSubFiledOptions('section_name')}
          />
        </Col>
        <Col grow data-test-id="product_details-name" className="mr-8">
          <Input
            defaultValue={attributeName}
            onChange={this.handleAttributeName}
            placeholder={t('views.google_merchant_params.fields.attribute_name', { default: 'Attribute name' })}
            {...getSubFiledOptions('attribute_name')}
          />
        </Col>
        <Col grow data-test-id="product_details-value">
          <PlaceholderInput
            defaultValue={attributeValue}
            onChange={this.handleAttributeValue}
            placeholder={t('views.google)merchant_params.fields.attribute_value', { default: 'Attribute value' })}
            {...getSubFiledOptions('attribute_value')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.google_merchant_params.example')} <span>1234</span>`}
          data-field={`product_details.${attributeName}`}
        >
          <div className="Text">
            {t('views.google_merchant_params.example')} <span>1234</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default GoogleProductDetail;
