import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { hot } from 'react-hot-loader/root';
import { Badge, Icon, Tooltip } from './index';

const IconWithBadge = ({ count, className, tooltip, ...rest }) => (
  <Tooltip text={tooltip}>
    <span className={cs(className, 'pos-relative')}>
      <Icon size="24px" {...rest} />
      <Badge kind="lightGray" size="medium" className="pos-absolute" style={{ bottom: '-6px', right: '-7.5px' }}>
        <span>{count && count > 99 ? '99+' : `${count}x`}</span>
      </Badge>
    </span>
  </Tooltip>
);

IconWithBadge.propTypes = { count: PropTypes.number, className: PropTypes.string, tooltip: PropTypes.string };

export default hot(IconWithBadge);
