import { setupColumn } from './setupColumn';
import { TextCell, TimeAgoCell, PreviewRowCell } from './cells';
import { t } from '../../../i18n';

const mapping = {
  ...setupColumn({
    id: 'productId',
    required: true,
    header: () => t('attributes.product_id'),
    cell: TextCell,
  }),
  ...setupColumn({
    id: 'previewData',
    header: () => t('attributes.preview_data'),
    cell: PreviewRowCell,
    required: true,
    graphqlFields: ['previewData', 'columnSeparator'],
    resolveChildren: ({ previewData, columnSeparator }) => ({ data: previewData, maxRows: 12, columnSeparator }),
  }),
  ...setupColumn({
    id: 'updatedAt',
    required: true,
    header: () => t('attributes.updated_at'),
    cell: TimeAgoCell,
  }),
};

export default mapping;
