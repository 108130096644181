import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { Icon, Row, Col, Button, UsedByGraph, Text } from '../index';
import { t, formatInteger } from '../../i18n';
import { ArrowWrapper } from './Arrows';
import { SIZES } from './FBManualCampaigns';

const NonDefaultFooter = ({ newManualCampaign, allAdSetsText, disableFunel }) => (
  <React.Fragment>
    {!disableFunel && (
      <Col
        shrink
        className={cs('mr-4')}
        style={newManualCampaign ? { paddingLeft: '12px' } : null}
        data-test-id="graph-status"
      >
        <ArrowWrapper>
          <Icon size="25px" kind="graph-check" color="#3ED95E" />
        </ArrowWrapper>
      </Col>
    )}
    <Col grow noPadding>
      <Text color="gray">{allAdSetsText}</Text>
    </Col>
  </React.Fragment>
);

NonDefaultFooter.propTypes = {
  disableFunel: PropTypes.bool,
  newManualCampaign: PropTypes.bool,
  allAdSetsText: PropTypes.string,
};

const ActiveUnsuedFooter = ({
  disableFunel,
  chartSize,
  newManualCampaign,
  itemsCount,
  useOnlyProducts,
  useUsageProducts,
  adSetCount,
  dataSourceStillLoading,
  doNotShowAdSets,
  remainingProducts,
  remainingAdSets,
  withoutText,
}) => {
  if (disableFunel) return null;

  return (
    <React.Fragment>
      <Col shrink className={cs('mr-4')} style={newManualCampaign ? { paddingLeft: '6px' } : null}>
        <ArrowWrapper>
          <UsedByGraph
            size={chartSize}
            products={remainingProducts}
            adSets={useUsageProducts || useOnlyProducts ? -1 : remainingAdSets}
            adSetsTotal={useUsageProducts || useOnlyProducts ? -1 : adSetCount}
            kind="remaining"
            productsTotal={itemsCount}
            doNotShowPercent={dataSourceStillLoading}
          />
        </ArrowWrapper>
      </Col>
      <Col grow noPadding>
        {useUsageProducts ? (
          <Text color="gray">
            {t('react.fb_features.products', {
              products: formatInteger(remainingProducts > 0 ? remainingProducts : 0),
            })}{' '}
            {withoutText}
          </Text>
        ) : (
          <Text color="gray">
            {!doNotShowAdSets &&
              t('react.fb_features.adsets', {
                adsets: formatInteger(remainingAdSets),
              })}
            {!doNotShowAdSets && ' ('}
            {t('react.fb_features.products', {
              products: formatInteger(remainingProducts > 0 ? remainingProducts : 0),
            })}
            {(!doNotShowAdSets && ') ') || ' '}
            {withoutText}
          </Text>
        )}
      </Col>
    </React.Fragment>
  );
};

ActiveUnsuedFooter.propTypes = {
  disableFunel: PropTypes.bool,
  doNotShowAdSets: PropTypes.bool,
  newManualCampaign: PropTypes.bool,
  useOnlyProducts: PropTypes.bool,
  useUsageProducts: PropTypes.bool,
  dataSourceStillLoading: PropTypes.bool,
  withoutText: PropTypes.string,
  chartSize: PropTypes.string,
  itemsCount: PropTypes.number,
  adSetCount: PropTypes.number,
  remainingProducts: PropTypes.number,
  remainingAdSets: PropTypes.number,
};

const ListFooter = ({ newManualCampaign, addResource, nonDeafultData, isAllUsed, ...rest }) => {
  const Footer = nonDeafultData.length > 0 && isAllUsed ? NonDefaultFooter : ActiveUnsuedFooter;

  return (
    <div
      className={cs(
        'Preview-tileItem Preview-tileItem--noBorder pv-0',
        { 'mt-8 pl-32': newManualCampaign },
        { 'mt-16 pl-0': !newManualCampaign }
      )}
    >
      <Row center>
        <Col
          grow={!newManualCampaign}
          width={newManualCampaign ? `${SIZES.first - 40}px` : null}
          noPadding
          className="ma-0"
        >
          {addResource && !addResource.restButtonOnlyForRestProducts && !addResource.useGroup && (
            <Row>
              <Col shrink>
                <Button
                  kind="secondary"
                  data-toggle={addResource.toggle}
                  data-url={addResource.url}
                  data-test-id={addResource.test_id}
                  data-notify-on-change={addResource.notify_on_change}
                  data-size={addResource.size}
                  block
                  icon={newManualCampaign ? 'adset' : addResource.buttonIcon}
                >
                  {addResource.button_text}
                </Button>
              </Col>
            </Row>
          )}

          {addResource && !addResource.restButtonOnlyForRestProducts && addResource.useGroup && (
            <Row>
              <Col shrink>
                <Button
                  secondary
                  data-toggle={addResource.toggle}
                  data-url={addResource.url}
                  data-test-id={addResource.test_id}
                  data-notify-on-change={addResource.notify_on_change}
                  data-size={addResource.size}
                >
                  {addResource.create_in_branch_button_text || t('react.fb_features.create_ad_in_branch')}
                </Button>
              </Col>
            </Row>
          )}
        </Col>
        <Col width="316px">
          <Row center data-test-id="products-without-adset">
            <Footer newManualCampaign={newManualCampaign} {...rest} />
            {addResource && addResource.restButtonOnlyForRestProducts && addResource.useGroup && !isAllUsed && (
              <Row>
                <Col shrink>
                  <Button
                    secondary
                    size="small"
                    data-toggle={addResource.toggle}
                    data-url={addResource.url}
                    data-test-id={addResource.test_id}
                    data-notify-on-change={addResource.notify_on_change}
                    data-size={addResource.size}
                  >
                    {addResource.create_in_branch_button_text || t('react.fb_features.create_ad_in_branch')}
                  </Button>
                </Col>
              </Row>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

ListFooter.propTypes = {
  addResource: PropTypes.object,
  newManualCampaign: PropTypes.bool,
  nonDeafultData: PropTypes.array,
  isAllUsed: PropTypes.bool,
};

export default ListFooter;
