import React from 'react';

const OrderContext = React.createContext({
  currentOrder: null,
  currentOrderDirection: 'asc',
  changeOrder: () => {},
  changeOrderDirection: () => {},
});

export default OrderContext;
