/* eslint-disable no-restricted-syntax */
/* eslint-disable import/prefer-default-export */
// Run this example by adding <%= javascript_pack_tag 'hello_react' %> to the head of your layout file,
// like app/views/layouts/application.html.erb. All it does is render <div>Hello React</div> at the bottom
// of the page.
import React from 'react';
import ReactDOM from 'react-dom';
import showdown from 'showdown';
import 'trix';
import '@rails/actiontext';

import * as rive from '@rive-app/canvas';

import './airbrake';
import './utils/braintree';

import AdtextTreeCreator from './adtext_tree/index';
import AdwordsAccountTrackingTemplate from './adwords_account_tracing_template';
import GraphQLTest from './graphql_test';
import GrapqlProvider from './graphql_provider';
import InputDataModal from './input_data_modal';
import GoogleSpreadsheetSelector from './google_drive/select_spreadsheet';
import NestedFields from './nested_fields';
import OrganizationsDropdown from './organizations_dropdown/index';
import PLAHierarchy from './pla_campaigns/Hierarchy';
import SystemIcon from './components/SystemIcon';
import VatNumberInput from './vat_number_input';
import { ConnectedOrganizationDashboard, ConnectedCampaignDashboard } from './dashboard/index';
import './i18n';
import './rails_form/datePicker';
import './rails_form/timeRestriction';
import './utils/adjustableTextarea';
import './utils/accordion';
import './utils/actionBar';
import './utils/dataConfigrmModal';
import './utils/disableSubmitButtonOnClick';
import './utils/dropdownMenu';
import './utils/feedExportCopyUrl';
import './utils/handleSearchInputs';
import './utils/highlight';
import './utils/fbPreviewCarousel';
import './utils/fbSlideshow';
import './utils/formConditionalShow';
import './utils/adaptivePlaceholders';
import './utils/animateScroll';
import './utils/scrollAndHighlightAnchor';
import './utils/initInfoBanner';
import './utils/introSwiper';
import './utils/signupSwiper';
import './utils/nestedFieldsOrder';
import './utils/languagePicker';
import './utils/dsaHandleUrlSelection';
import './utils/modal';
import './utils/navbar';
import './utils/navigation';
import './utils/scrollByMouse';
import './utils/resizePeriods';
import './utils/selectedIdsButton';
import './utils/scrollIntoError';
import './utils/showMoreLink';
import './utils/stickyTableHeading';
import './utils/textWithCheck';
import './utils/toolbox';
import './placeholders/Editor';
import './placeholders/initPreviewDataModalFromRails';
import './components/graphs/ComplexityMeter';
import './components/initReactComponent';
import getCSRFToken from './utils/getCSRFToken';

const renderUnlessAlreadyRendered = (react, container) => {
  if (!container.dataset.reactComponentInited) {
    ReactDOM.render(react, container);
    container.dataset.reactComponentInited = true; // eslint-disable-line no-param-reassign
  }
};

document.addEventListener('DOMContentLoaded', () => {
  const csrfToken = getCSRFToken();
  const organizationsDropdownContainer = document.getElementById('react-organization-dropdown');
  if (organizationsDropdownContainer) {
    const {
      dataset: { currentOrganization, currentUser, left, searchBoxText, createOrganization, bold },
    } = organizationsDropdownContainer;

    renderUnlessAlreadyRendered(
      <OrganizationsDropdown
        currentOrganization={JSON.parse(currentOrganization)}
        currentUser={JSON.parse(currentUser)}
        left={JSON.parse(left || 'false')}
        bold={bold && JSON.parse(bold)}
        searchBoxText={searchBoxText}
        createOrganization={JSON.parse(createOrganization)}
      />,
      organizationsDropdownContainer
    );
  }

  const adtextTreeContainers = document.getElementsByClassName('react-adtext-tree');
  if (adtextTreeContainers.length > 0) {
    Array.from(adtextTreeContainers).forEach(container => {
      const AdtextTree = AdtextTreeCreator;

      renderUnlessAlreadyRendered(
        <AdtextTree
          {...container.dataset}
          hideHeading={container.dataset.hideHeading && JSON.parse(container.dataset.hideHeading)}
          i18n={JSON.parse(container.dataset.i18n)}
          showAllCampaigns={container.dataset.showAllCampaigns && JSON.parse(container.dataset.showAllCampaigns)}
          hideSystems={container.dataset.hideSystems && JSON.parse(container.dataset.hideSystems)}
          elements={JSON.parse(container.dataset.elements)}
          head={JSON.parse(container.dataset.head)}
        />,
        container
      );
    });
  }

  const plaHierarchy = document.getElementById('react-pla-hierarchy');
  if (plaHierarchy) {
    const valueField = document.getElementsByClassName('js-react-pla-hierarchy')[0];
    renderUnlessAlreadyRendered(
      <PLAHierarchy
        {...plaHierarchy.dataset}
        disabled={plaHierarchy.dataset.formDisabled === 'true'}
        possibleFields={JSON.parse(plaHierarchy.dataset.possibleFields)}
        selectedValues={(valueField.value || 'offer_id').split(' ')}
        valueField={valueField}
        i18n={window.reacti18n.pla_hierarchy}
      />,
      plaHierarchy
    );
  }

  const adwordsAccountTrackingTemplate = document.getElementsByClassName('react-adwords-account-tracing-template');
  if (adwordsAccountTrackingTemplate.length > 0) {
    Array.from(adwordsAccountTrackingTemplate).forEach(container => {
      renderUnlessAlreadyRendered(
        <AdwordsAccountTrackingTemplate
          {...container.dataset}
          csrfToken={csrfToken}
          i18n={window.reacti18n.adwords_account_tracking_template}
        />,
        container
      );
    });
  }

  const vatNumberInputContainer = document.getElementsByClassName('react-vat-number-input');
  if (vatNumberInputContainer.length > 0) {
    Array.from(vatNumberInputContainer).forEach(container => {
      renderUnlessAlreadyRendered(
        <VatNumberInput {...container.dataset} name={container.querySelectorAll('input')[0].name} />,
        container
      );
    });
  }

  const googleSpreadsheetContainer = document.getElementsByClassName('react-google-spreadsheet-selection');
  if (googleSpreadsheetContainer.length > 0) {
    Array.from(googleSpreadsheetContainer).forEach(container => {
      renderUnlessAlreadyRendered(
        <GrapqlProvider csrfToken={csrfToken}>
          <GoogleSpreadsheetSelector
            {...container.dataset}
            errors={container.dataset.errors ? JSON.parse(container.dataset.errors) : {}}
            i18n={window.reacti18n.google_spreadsheet_importer}
          />
        </GrapqlProvider>,
        container
      );
    });
  }

  window.initNestedFields = () => {
    const nestedFieldsContainers = document.getElementsByClassName('react-nested-fields');
    if (nestedFieldsContainers.length > 0) {
      Array.from(nestedFieldsContainers).forEach(container => {
        const children =
          container.innerHTML.length > 0 ? (
            // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: container.innerHTML }} />
          ) : (
            undefined
          );

        renderUnlessAlreadyRendered(
          <GrapqlProvider csrfToken={csrfToken}>
            <NestedFields
              csrfToken={csrfToken}
              {...container.dataset}
              fieldData={JSON.parse(container.dataset.fieldData)}
              disabled={JSON.parse(container.dataset.disabled)}
              options={JSON.parse(container.dataset.options)}
              withTile={container.dataset.withTile === 'true'}
            >
              {children}
            </NestedFields>
          </GrapqlProvider>,
          container
        );
      });
    }
    $('.add_fields, .js-add-field').click(() => {
      setTimeout(window.initNestedFields, 100);
    });
  };

  const initMarkdownEditor = (
    className,
    hlevel = 1,

    preTransformations = text => text,
    additionalTransformations = text => text
  ) => {
    const converter = new showdown.Converter();
    const editors = Array.from(document.getElementsByClassName(className));
    converter.setOption('headerLevelStart', hlevel);

    editors.forEach(editor => {
      const textarea = editor.getElementsByTagName('textarea')[0] || editor.getElementsByTagName('input')[0];
      const preview = editor.getElementsByClassName('js-editor-preview')[0];

      const handle = ({ target: { value } }) => {
        if (editor.dataset && editor.dataset.replaceExamples) {
          const examples = JSON.parse(editor.dataset.replaceExamples);
          preview.innerHTML = additionalTransformations(
            converter.makeHtml(
              Object.keys(examples).reduce(
                (out, key) => out.replace(`_${key}_`, examples[key]),
                preTransformations(value)
              )
            )
          );
        } else {
          preview.innerHTML = additionalTransformations(converter.makeHtml(preTransformations(value)));
        }
      };

      handle({ target: { value: textarea.value } });
      textarea.addEventListener('input', handle);
      textarea.addEventListener('change', handle);
    });
  };
  initMarkdownEditor('js-news-editor');
  initMarkdownEditor(
    'js-meeting-mail-editor',
    3,
    inputMD => inputMD.replace(/\n\r?\n\r?\n\r?/g, '\n\n<br/>\n\n'),
    text =>
      text
        .replace(/<h3([^>]*)>/g, '<h3 class="pt-15"$1><strong>')
        .replace(/<\/h3>/g, '</strong></h3>')
        .replace('<h4', '<h4 class="pt-15"')
  );

  const GraphQlTestContainer = document.getElementById('react-graphql-test');
  if (GraphQlTestContainer) {
    renderUnlessAlreadyRendered(<GraphQLTest csrfToken={csrfToken} />, GraphQlTestContainer);
  }

  window.initIcons = () => {
    const systemIconContainers = document.getElementsByClassName('react-system-icon');
    if (systemIconContainers.length > 0) {
      Array.from(systemIconContainers).forEach(container => {
        renderUnlessAlreadyRendered(
          <SystemIcon
            {...container.dataset}
            hover={JSON.parse(container.dataset.hover || 'false')}
            active={JSON.parse(container.dataset.active || 'false')}
          />,
          container
        );
      });
    }
  };

  const dashboardOrganizationContainer = document.getElementsByClassName('react-organization-dashboard');
  if (dashboardOrganizationContainer.length > 0) {
    Array.from(dashboardOrganizationContainer).forEach(container => {
      renderUnlessAlreadyRendered(
        <GrapqlProvider csrfToken={csrfToken}>
          <ConnectedOrganizationDashboard {...container.dataset} />
        </GrapqlProvider>,
        container
      );
    });
  }

  const dashboardCampaignContainer = document.getElementsByClassName('react-campaign-dashboard');
  if (dashboardCampaignContainer.length > 0) {
    Array.from(dashboardCampaignContainer).forEach(container => {
      renderUnlessAlreadyRendered(
        <GrapqlProvider csrfToken={csrfToken}>
          <ConnectedCampaignDashboard {...container.dataset} />
        </GrapqlProvider>,
        container
      );
    });
  }

  window.initInputDataModal = () => {
    $(document).on('click', '[data-trigger="react-preview-item"]', e => {
      const element = e.target.closest('[data-trigger="react-preview-item"]');
      if (element.dataset) {
        const node = document.getElementById('react-input-data-modal-container');
        ReactDOM.render(
          <GrapqlProvider csrfToken={csrfToken}>
            <InputDataModal
              {...element.dataset}
              conditions={element.dataset.conditions ? JSON.parse(element.dataset.conditions) : []}
              onClose={() => ReactDOM.unmountComponentAtNode(node)}
            />
          </GrapqlProvider>,
          node
        );
      }
    });
  };
});

const runIfFunction = maybeFunc => {
  if (typeof maybeFunc === 'function') {
    maybeFunc();
  }
};

window.initRive = () => {
  const containers = document.getElementsByClassName('rive-animation');
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      if (!container.classList.contains('rive-animation-inited')) {
        container.classList.add('rive-animation-inited');
        (() =>
          new rive.Rive({
            src: container.dataset.riveUrl,
            canvas: container,
            animations: JSON.parse(container.dataset.riveAnimations || '[]'),
            autoplay: true,
          }))();
      }
    });
  }
};

window.initReact = () => {
  runIfFunction(window.formConditionalShow);
  setTimeout(() => runIfFunction(window.formConditionalShow), 10);
  setTimeout(() => runIfFunction(window.formConditionalShow), 200);
  setTimeout(() => runIfFunction(window.formConditionalShow), 800);
  runIfFunction(window.init_form_hints);
  runIfFunction(window.initAccordion);
  runIfFunction(window.initComplexityMeter);
  runIfFunction(window.initDataPreviewModal);
  runIfFunction(window.initDraftJsEditor);
  runIfFunction(window.initFormDatePicker);
  runIfFunction(window.initFormTimeRestriction);
  runIfFunction(window.initIcons);
  runIfFunction(window.initInputDataModal);
  runIfFunction(window.initIntegerWithControls);
  runIfFunction(window.initNavbar);
  runIfFunction(window.initNestedFields);
  runIfFunction(window.initNestedFieldsOrder);
  runIfFunction(window.initReactComponents);
  runIfFunction(window.initReactFormComponents);
  runIfFunction(window.initFBSlideshow);
  runIfFunction(window.initFBPreviewCarousel);
  runIfFunction(document.initLiveFormUpdate);
  runIfFunction(window.initStickyTableHeading);
  runIfFunction(window.initRive);
};

document.addEventListener('DOMContentLoaded', () => {
  setInterval(() => runIfFunction(window.initStickyTableHeading), 500);
  window.initReact();

  $(window).on('shown.bs.modal', () => {
    setTimeout(window.initReact, 10);
  });

  $(window).on('cocoon:after-insert', () => {
    setTimeout(window.initReact, 10);
  });

  setInterval(() => {
    // eslint-disable-next-line array-callback-return
    Array.from(document.querySelectorAll('[role="tooltip"]:not(.dee-js-scope [role="tooltip"])')).map(tooltip => {
      if (document.querySelectorAll(`[aria-describedby="${tooltip.id}"]`).length === 0) {
        tooltip.remove();
      }
    });

    runIfFunction(document.initLiveFormUpdate);
  }, 500);
});

require('trix');
require('@rails/actiontext');
