import React, { memo } from 'react';
import cs from 'classnames';

import styles from './styles';

const PropertyGroup = ({ showIf = true, className, ...props }) => {
  if (!showIf) {
    return <span style={styles.empty} />;
  }
  return (
    <div className={cs('ImageGenerator-propertyGroup', className)} style={styles.propertyGroup}>
      {props.children}
    </div>
  );
};

export default memo(PropertyGroup);
