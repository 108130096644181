import storage from '../utils/storage';

const initInfoBannerVisibility = () => {
  const infoBannerButton = $('.js-feature-info-banner-button');
  const infoBannerClosedButton = $('.js-tile-close-button');
  const infoBanner = $('.js-tile-closable');
  const infoBannerId = infoBanner.length > 0 && infoBanner[0].getAttribute('id');
  let visibility = storage.get(infoBannerId) || 'visible';

  if (infoBannerButton && infoBanner && infoBannerId) {
    const showHideDashboard = localVisibility => {
      if (localVisibility === 'visible') {
        infoBanner.show();
        storage.set(infoBannerId, 'visible');
        visibility = 'visible';
        infoBannerButton.addClass('active');
      } else {
        infoBanner.hide();
        storage.set(infoBannerId, 'hidden');
        visibility = 'hidden';
        infoBannerButton.removeClass('active');
      }
    };

    showHideDashboard(visibility);

    infoBannerButton.on('click', () => {
      showHideDashboard(visibility === 'visible' ? 'hidden' : 'visible');
    });

    infoBannerClosedButton.on('click', () => {
      showHideDashboard(visibility === 'visible' ? 'hidden' : 'visible');
    });
  }
};

window.addEventListener('load', initInfoBannerVisibility);
