import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import uuid from 'uuid';

export default class Select extends Component {
  static propTypes = {
    className: PropTypes.string,
    collection: PropTypes.arrayOf(PropTypes.object).isRequired,
    disabled: PropTypes.bool,
    doNotUseInternalState: PropTypes.bool,
    horizontaly: PropTypes.bool,
    children: PropTypes.any,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    medium: PropTypes.bool,
    name: PropTypes.string.isRequired,
    narrow: PropTypes.bool,
    onChange: PropTypes.func,
    required: PropTypes.bool,
    testId: PropTypes.string,
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    collection: [],
    doNotUseInternalState: false,
    required: false,
  };

  state = {
    value: this.props.value,
    uuid: uuid(),
  };

  handleChange = e => {
    const {
      target: { value },
    } = e;

    this.setState({ value });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange(e);
    }
  };

  render() {
    const {
      className,
      collection,
      value,
      name,
      id,
      children,
      doNotUseInternalState,
      required,
      label,
      horizontaly,
      testId,
      narrow,
      medium,
      disabled = false,
    } = this.props;
    const selected = doNotUseInternalState ? value : this.state.value;
    const inputId = id || this.state.uuid;

    return (
      <div
        className={cs(
          'Dropdown',
          {
            'Dropdown--horizontaly': horizontaly,
            'Dropdown--narrow': narrow,
            'Dropdown--medium': medium,
          },
          'select',
          className
        )}
      >
        {label && <label htmlFor={inputId}>{label}</label>}
        <div className="Dropdown-wrapper">
          <select
            className={cs('select', { required })}
            name={name}
            id={inputId}
            data-test-id={testId}
            value={selected}
            onChange={this.handleChange}
            disabled={disabled}
          >
            {children}
            {collection.map(option => {
              if (typeof option === 'object') {
                const { key, value: optionValue, labelFromOption, label: optionLabel } = option;
                const optionId = `${inputId}_${key || optionValue}`;

                return (
                  <option value={optionValue} key={optionId}>
                    {labelFromOption || optionLabel || optionValue}
                  </option>
                );
              }
              return (
                <option value={option} key={option}>
                  {option}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
}
