/* eslint-disable no-eval */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
// import {
//   t,
//   formatDate,
//   formatNumber,
//   formatPercentage,
//   formatCurrency,
// } from '../i18n';

const Example = ({ text, note, children }) => (
  <tr>
    <td>
      <pre>{text}</pre>
    </td>
    <td style={{ fontSize: '20px', margin: '5px' }}>{'>'}</td>
    <td>
      <pre>{eval(text)}</pre>
    </td>
    {children && <td>{children}</td>}
    {note && (
      <td>
        <pre>{note}</pre>
      </td>
    )}
  </tr>
);

Example.propTypes = {
  text: PropTypes.string,
  note: PropTypes.string,
  children: PropTypes.any,
};

const importCode = `import {
  t,
  formatDate,
  formatNumber,
  formatPercentage,
  formatCurrency,
} from '../i18n';`;

class I18nExample extends React.Component {
  render() {
    return (
      <div>
        <h1>Translations</h1>
        <pre>{importCode}</pre>
        <table>
          <thead>
            <tr>
              <th>Code</th>
              <th />
              <th>Example result</th>
              <th>Transaltion text</th>
            </tr>
          </thead>
          <tbody>
            <Example text="t('stats_metrics.clicks')" />
            <Example
              text="t('clicks', {scope: 'stats_metrics'})"
              note="You can use dot notation and also scope option"
            />
            <Example text="t('stats_metrics.undefined', {default: 'Some othef text'})" />
            <Example text="t('missing_translation_key')" />
            <Example text="t('missing_scope.missing_translation_key')" />
            <Example text="t('missing_translation_key', {scope: 'missing_scope'})" />
            <Example
              text="t('missing_translation_key', { disableDefault: true })"
              note="this will produce `undefined` result"
            />
            <Example
              text="t('data_source_download_error.csv_is_not_valid_on_line', {line: 10})"
              note="CSV není validní na řádce %{line}"
            />
            <Example text="formatNumber(1234567890)" />
            <Example text="formatNumber(1234567890.098765432)" />
            <Example text="formatNumber(1234567890.098765432, { precision: 2 })" />
            <Example text="formatPercentage(1234567890.098765432)" />
            <Example text="formatPercentage(-1234567890.098765432, {precision: 2})" />
            <Example text="formatPercentage(-1234567890.00000001, {precision: 2})" />
            <Example text="formatPercentage(-1234567890.00000001, {precision: 2, strip_insignificant_zeros: true})" />
            <Example text="formatCurrency(1234567890.098765432)" />
            <Example text="formatCurrency(1234567890.098765432, {unit: 'CZK'})" />
            <Example text="formatCurrency(1234567890.098765432, {unit: 'EUR'})" />
            <Example text="formatCurrency(1234567890.098765432, {unit: 'USD'})" />
            <Example text="formatDate('2019-02-03')" />
            <Example text="formatDate('2019-02-03', 'short')" />
            <Example text="formatDate('2019-02-03', 'YYYY -- MM -- DD')">
              <a
                className="Link"
                href="https://momentjs.com/docs/#/displaying/format/"
              >
                Use custom format from moment js
              </a>
            </Example>
          </tbody>
        </table>
      </div>
    );
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('react-i18n-example');
  if (container) {
    ReactDOM.render(<I18nExample />, container);
  }
});
