const isEmptyObject = (val, opts = { ignoreKeys: null }) => {
  if (typeof val === 'string') return val.trim().length === 0;
  if (typeof val === 'number') return false;
  if (typeof val === 'boolean') return false;
  if (typeof val === 'undefined') return true;
  if (val === null) return true;
  if (Array.isArray(val)) return val.length === 0 ? true : !val.some(v => !isEmptyObject(v, opts));

  return isEmptyObject(
    Object.entries(val).reduce(
      (out, [key, v]) => (typeof opts.ignoreKeys === 'function' && opts.ignoreKeys(key) ? out : [...out, v]),
      []
    ),
    opts
  );
};

export default isEmptyObject;
