import { PureComponent } from 'react';

import {
  SizePanel,
  TextPanel,
  StylePanel,
  ImagePanel,
  SvgColorsPanel,
  ImagePosition,
  ConditionsPanel,
  TimeRestrictionPanel,
} from '../panels';

export default class Vector extends PureComponent {
  static panels = [
    SizePanel,
    TextPanel,
    StylePanel,
    ImagePanel,
    SvgColorsPanel,
    ImagePosition,
    TimeRestrictionPanel,
    ConditionsPanel,
  ];

  getStyle() {
    const { object } = this.props;
    return {
      mixBlendMode: object.blendMode,
    };
  }

  getTransformMatrix = ({ rotate, x, y, width, height }) => {
    if (rotate) {
      const centerX = width / 2 + x;
      const centerY = height / 2 + y;
      return `rotate(${rotate} ${centerX} ${centerY})`;
    }
    return '';
  };

  getTranformStyle = ({ rotate, x, y, width, height, flipHorizontally, flipVertically }) => {
    const centerX = width / 2 + x;
    const centerY = height / 2 + y;
    const rotatations = [];
    if (rotate) {
      rotatations.push(`rotate(${rotate}deg)`);
    }

    const xRotation = flipHorizontally ? 180 : 0;
    rotatations.push(`rotateX(${xRotation}deg)`);

    const yRotation = flipVertically ? 180 : 0;
    rotatations.push(`rotateY(${yRotation}deg)`);

    return {
      transformOrigin: `${centerX}px ${centerY}px`,
      transform: rotatations.join(' '),
    };
  };

  static getDescription = () => '';
  static getIcon() {
    return this.meta.icon;
  }

  getObjectAttributes() {
    const { object, onRender, conditions, timeRestriction, lockLayer, hideLayer, svg, ...rest } = this.props;

    return {
      ...object,
      transform: this.getTransformMatrix(object),
      ref: onRender,
      svg: '',
      ...rest,
    };
  }
}
