import storage from '../utils/storage';

const initDashboardVisibility = () => {
  const dashboardButton = $('.js-dashboard-button');
  const dashboardClosedButton = $('.js-dashboard-close-button');
  const dashboard = $('.js-dashboard');
  let visibility = storage.get('dashboardVisibility') || 'visible';

  if (dashboardButton && dashboard) {
    const showHideDashboard = localVisibility => {
      if (localVisibility === 'visible') {
        dashboard.show();
        storage.set('dashboardVisibility', 'visible');
        visibility = 'visible';
        dashboardButton.addClass('active');
      } else {
        dashboard.hide();
        storage.set('dashboardVisibility', 'hidden');
        visibility = 'hidden';
        dashboardButton.removeClass('active');
      }
    };

    showHideDashboard(visibility);

    dashboardButton.on('click', () => {
      showHideDashboard(visibility === 'visible' ? 'hidden' : 'visible');
    });

    dashboardClosedButton.on('click', () => {
      showHideDashboard(visibility === 'visible' ? 'hidden' : 'visible');
    });
  }
};
setTimeout(initDashboardVisibility, 1);
window.addEventListener('load', initDashboardVisibility);

export default initDashboardVisibility;
