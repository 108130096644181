import React, { useState } from 'react';
import { Tile, Input, Button } from '../index';
import ScraperSelector from './ScraperSelector';

const CustomScraperForSandbox = ({ ...rest }) => {
  const [url, setUrl] = useState(null);
  const [inputVal, setInputVal] = useState(null);

  return (
    <div className="mt-24 mb-24">
      <Tile>
        <Input
          id="scraper-placeholder_name"
          name="scraper[placeholder_name]"
          doNotUseInternalState
          onChange={({ target: { value } }) => setInputVal(value)}
          placeholder="Valid Url"
        />
        <Button primary onClick={() => setUrl(inputVal)}>
          Show that shit
        </Button>
      </Tile>

      {url && url.trim().length > 0 ? <ScraperSelector url={url.trim()} {...rest} /> : <p>First type valid URL</p>}
    </div>
  );
};

export default CustomScraperForSandbox;
