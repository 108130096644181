/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class Delivery extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    delivery: PropTypes.string,
    cod: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    delivery: this.props.delivery || '',
    price: this.props.price || '',
    cod: this.props.cod || '',
  };

  handleDelivery = ({ target: { value } }) => {
    if (value !== this.state.delivery) {
      this.setState({ delivery: value });
      this.props.updateFieldData(this.props.id, { delivery: value });
      updatePreview();
    }
  };

  handlePrice = ({ target: { value } }) => {
    if (value !== this.state.price) {
      this.setState({ price: value });
      this.props.updateFieldData(this.props.id, { price: value });
      updatePreview();
    }
  };

  handleCod = ({ target: { value } }) => {
    if (value !== this.state.cod) {
      this.setState({ cod: value });
      this.props.updateFieldData(this.props.id, { cod: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { cod, price, delivery } = this.state;

    return (
      <Row center padding="l" data-test-id="other_deliveries" className="mb-8">
        <Col width="17%" data-test-id="other-deliveries-id" className="mr-8">
          <PlaceholderInput
            defaultValue={delivery}
            onChange={this.handleDelivery}
            placeholder={t('views.other_deliveries.fields.delivery_id', { default: 'Delivery' })}
            {...getSubFiledOptions('delivery')}
          />
        </Col>
        <Col grow data-test-id="other-deliveries-price" className="mr-8">
          <PlaceholderInput
            defaultValue={price}
            onChange={this.handlePrice}
            placeholder={t('views.other_deliveries.fields.price', { default: 'Price (paying in advance)' })}
            {...getSubFiledOptions('price')}
          />
        </Col>
        <Col width="20%" data-test-id="other-deliveries-price-cod">
          <PlaceholderInput
            defaultValue={cod}
            onChange={this.handleCod}
            placeholder={t('views.other_deliveries.fields.price_cod', {
              default: 'Price (paying by cash on delivery)',
            })}
            {...getSubFiledOptions('cod')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.other_deliveries.example')} <span class="Text--bold">${t(
            'views.other_deliveries.example_text'
          )}</span>`}
          data-field={`other_deliveries.${delivery}`}
        >
          <div className="Text">
            {t('views.other_deliveries.example')}{' '}
            <span className="Text--bold">{t('views.other_deliveries.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default Delivery;
