import { uniq, flatten } from 'lodash';
import deepmerge from 'deepmerge';

const resolveOneField = graphqlFields => {
  if (typeof graphqlFields === 'string') {
    return graphqlFields;
  } else if (Array.isArray(graphqlFields)) {
    return graphqlFields.map(f => resolveOneField(f)).sort();
  } else if (typeof graphqlFields === 'object') {
    return Object.keys(graphqlFields)
      .sort()
      .reduce((out, parent) => ({ ...out, [parent]: uniq(['id'].concat(resolveOneField(graphqlFields[parent]))) }), {});
  }
  return [];
};

const isNestedFields = graphqlFields => !(typeof graphqlFields === 'string' || Array.isArray(graphqlFields));

const mergeNestedQueries = (mapping, columns) => {
  const mappedColumns = flatten(columns.map(c => mapping[c] && mapping[c].graphqlFields).filter(c => !!c));
  const nestedColumns = mappedColumns.filter(graphqlFields => isNestedFields(graphqlFields));
  const normalColumns = mappedColumns.filter(graphqlFields => !isNestedFields(graphqlFields));
  const x = nestedColumns.reduce((out, graphqlFields) => deepmerge(out, resolveOneField(graphqlFields)), {});
  return [...normalColumns, x];
};

const resolveQueryToString = query => {
  if (typeof query === 'string') {
    return query;
  } else if (Array.isArray(query)) {
    return query
      .map(f => resolveQueryToString(f))
      .filter(f => f.length > 0)
      .sort()
      .join(' ');
  } else if (typeof query === 'object') {
    return Object.keys(query)
      .sort()
      .map(parent => `${parent} { ${resolveQueryToString(query[parent])} }`)
      .join(' ');
  }
  return '';
};

export const getGraphqlQuery = (mapping, columns) =>
  resolveQueryToString(
    uniq(
      ['id'].concat(
        mergeNestedQueries(mapping, columns)
          .reduce((out, graphqlFields) => out.concat(resolveOneField(graphqlFields)), [])
          .sort()
      )
    )
  );

const resolveDataForField = (fields, data) => {
  if (typeof fields === 'string') {
    return {
      id: data.id,
      [fields]: data[fields],
    };
  } else if (Array.isArray(fields)) {
    return fields.reduce(
      (out, field) => {
        if (typeof field === 'string') {
          return { ...out, [field]: data[field] };
        }
        return Object.keys(field).reduce((o, f) => ({ ...o, [f]: resolveDataForField(field[f], data[f]) }), out);
      },
      { id: data.id }
    );
  } else if (typeof fields === 'object') {
    return Object.keys(fields).reduce(
      (out, parent) => ({ ...out, [parent]: resolveDataForField(fields[parent], data[parent]) }),
      { id: data.id }
    );
  }
  return {};
};

export const getDataForField = (columnMapping, data) => {
  const resolved = resolveOneField(columnMapping.graphqlFields);
  return resolveDataForField(resolved, data);
};
