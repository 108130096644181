import React from 'react';
import ReactDOM from 'react-dom';
import RemoteModal from '../components/RemoteModal';

const getModalMargins = (index, total) => {
  if (index === 0) {
    if (total < 3) return [true, 1, 0];
    if (total > 3) return [false, 0, 1];
    return [true, 0, 1];
  } else if (index === 1) {
    if (total < 3) return [true, 2, 0];
    if (total === 3) return [true, 1, 1];
    if (total === 4) return [true, 0, 2];
    if (total > 3) return [false, 0, 2];
    return [true, 0, 2];
  } else if (total === index + 1) {
    return [true, 2, 0];
  } else if (total === index + 2) {
    return [true, 1, 1];
  } else if (total === index + 3) {
    return [true, 0, 2];
  }

  return [false, 0, 2];
};

document.addEventListener('DOMContentLoaded', () => {
  let modalZIndex = 1050;
  const initialBackdrop = 1049;
  const additionIndex = 10;
  let modals = [];

  const handleModalsPosition = () => {
    const fullModals = $('.Modal--fullwithGrid');

    fullModals.each((index, modal) => {
      const [visible, modalLeft, modalRight] = getModalMargins(index, fullModals.length);
      if (visible) {
        $(modal)
          .find('.Modal-content')
          .css('margin-left', `${modalLeft * 10}vw`)
          .css('margin-right', `${modalRight * 10}vw`)
          .removeClass('js-hide-stacked-modal')
          .css('display', 'block');
      } else {
        $(modal)
          .find('.Modal-content')
          .addClass('js-hide-stacked-modal');
      }
    });
    setTimeout(() => {
      $('.modal-backdrop.fade').each((index, backdrop) => {
        // eslint-disable-next-line no-param-reassign
        backdrop.style.zIndex = initialBackdrop + additionIndex * index;
      });
    }, 0);
  };
  $(document).on('show.bs.modal', handleModalsPosition);
  $(document).on('hide.bs.modal', handleModalsPosition);

  const handleInlineModalsShow = e => {
    if (e.target.classList.contains('js-inline-modal')) {
      e.target.dataset.ModalPrevieousParent = e.target.parent;
      document.body.appendChild(e.target);
    }
  };

  $(document).on('show.bs.modal', handleInlineModalsShow);

  $(document).on('shown.bs.modal', e => {
    const modal = e.target;
    modal.relatedTarget = e.relatedTarget;
    $('.js-hide-stacked-modal').css('display', 'none');

    const wantedBackdrops = Array.from($('.modal.fade.in')).map(
      t => $(t).data('bs.modal').$backdrop && $(t).data('bs.modal').$backdrop[0]
    );
    $('.modal-backdrop.fade.in').each((i, backdrop) => {
      if (wantedBackdrops.indexOf(backdrop) === -1) {
        backdrop.remove();
      }
    });

    if (typeof window.dataLayer !== 'undefined') {
      const name = (modal.dataset || {}).testId || modal.id;
      window.dataLayer.push({
        event: 'VirtualPageView',
        virtualPageURL: `${document.location.pathname.replace(/\d+/, 'id')}?modal=${name}`,
        virtualPageTitle: `${name} | ${document.title}`,
      });
    }

    window.attach_tooltips_to_text_overflow();
  });

  $(document).on('click', '[data-toggle="modal-url"]', e => {
    if (e.target.closest('label') || e.target.nodeName === 'INPUT' || e.target.closest('.DropdownMenu-button')) {
      return true;
    }

    e.preventDefault();
    e.stopPropagation();
    const element = e.target.closest('[data-toggle="modal-url"]');
    const wrapper = document.getElementById('react-modal-container');
    const container = document.createElement('div', '');
    wrapper.appendChild(container);

    ReactDOM.render(
      <RemoteModal
        {...element.dataset}
        testId={`${element.dataset.testId}-modal`}
        onClose={() => {
          ReactDOM.unmountComponentAtNode(container);
          container.remove();
        }}
      />,
      container
    );
    return false;
  });

  $(document).on('show.bs.modal', e => {
    const modal = e.target;
    modal.style.zIndex = modalZIndex;
    modalZIndex += additionIndex;
    modals.push(e.target);
  });

  $(document).on('hide.bs.modal', e => {
    if (modals.indexOf(e.target) !== -1) {
      modalZIndex -= additionIndex;
      modals = modals.filter(m => m !== e.target);
    }

    $(e.target).on('hidden.bs.modal', () => {
      if (e.target.relatedTarget) e.target.relatedTarget.blur();

      if ($('.modal.in').length > 0) {
        $('body').addClass('modal-open');
      }
    });
  });
});
