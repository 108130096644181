import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Loader } from '../index';

const getOpacity = ({ selected, loading }) => {
  if (loading) return 0.1;
  if (selected) return 1;

  return 0.5;
};

const LikeIcon = ({ kind, selected, loading = false, size = 'normal' }) => (
  <div>
    {loading && (
      <div className="pos-absolute">
        <Loader size={cs({ medium: size === 'normal', semiBig: size === 'big' })} />
      </div>
    )}
    <svg
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cs('Icon--likeIcon', { 'Icon--likeIcon__big': size === 'big' })}
    >
      <g opacity={getOpacity({ selected, loading })} data-test-id={`like-icon-${kind}`}>
        {kind === 'good' ? <GoodIconCurves /> : <BadIconCurves />}
      </g>
    </svg>
  </div>
);

const BadIconCurves = () => (
  <React.Fragment>
    <path
      d="M32 16C32 24.8364 24.8364 32 16 32C7.16444 32 0 24.8364 0 16C0 7.16444 7.16444 0 16 0C24.8364 0 32 7.16444 32 16Z"
      fill="#FFCC4D"
    />
    <path
      d="M12 15.0303C12 16.9057 11.1048 18.4242 10 18.4242C8.8952 18.4242 8 16.9057 8 15.0303C8 13.1559 8.8952 11.6364 10 11.6364C11.1048 11.6364 12 13.1559 12 15.0303Z"
      fill="#664500"
    />
    <path
      d="M24 15.0303C24 16.9057 23.1048 18.4242 22 18.4242C20.8952 18.4242 20 16.9057 20 15.0303C20 13.1559 20.8952 11.6364 22 11.6364C23.1048 11.6364 24 13.1559 24 15.0303Z"
      fill="#664500"
    />
    <path
      d="M4.88796 11.6364C4.70307 11.6364 4.51551 11.5838 4.35551 11.4747C3.96262 11.2073 3.88351 10.7006 4.17773 10.3434C7.07731 6.82828 10.9475 6.78788 11.1111 6.78788C11.6018 6.78788 12 7.1499 12 7.59596C12 8.04121 11.6044 8.40242 11.1147 8.40404C10.9769 8.40566 7.9422 8.47354 5.59996 11.3131C5.42485 11.5248 5.15818 11.6364 4.88796 11.6364Z"
      fill="#664500"
    />
    <path
      d="M27.1114 11.6364C26.8402 11.6364 26.5735 11.5248 26.3992 11.3131C24.0519 8.46788 21.0101 8.40485 20.8812 8.40404C20.3921 8.39919 19.9973 8.03556 20 7.59192C20.0027 7.14667 20.3975 6.78788 20.8865 6.78788C21.0501 6.78788 24.9205 6.82828 27.8218 10.3434C28.117 10.7006 28.037 11.2073 27.644 11.4747C27.484 11.5838 27.2972 11.6364 27.1114 11.6364Z"
      fill="#664500"
    />
    <path
      d="M20.9862 25.5796C20.9753 25.537 19.9444 21.3333 15.9997 21.3333C12.0532 21.3333 11.0223 25.537 11.0132 25.5796C10.965 25.7862 11.0523 25.9976 11.2232 26.1071C11.3941 26.2158 11.6168 26.1905 11.765 26.049C11.7741 26.0422 12.6869 25.2121 15.9997 25.2121C19.2625 25.2121 20.1971 26.017 20.2326 26.049C20.3189 26.1362 20.4316 26.1818 20.5453 26.1818C20.6217 26.1818 20.6989 26.1615 20.7689 26.1198C20.9471 26.0111 21.0362 25.791 20.9862 25.5796Z"
      fill="#664500"
    />
  </React.Fragment>
);

const GoodIconCurves = () => (
  <React.Fragment>
    <path
      d="M32 16C32 24.8533 24.8533 32 16 32C7.14667 32 0 24.8533 0 16C0 7.14667 7.14667 0 16 0C24.8533 0 32 7.14667 32 16Z"
      fill="#FFCC4D"
    />
    <path
      d="M31.9098 6.35975C31.6511 4.83163 30.8748 3.5865 29.581 3.19032C28.1837 2.79414 26.9417 3.36011 25.7514 4.71844C25.0786 2.68094 24.0436 1.15282 22.3876 0.360463C20.7315 -0.431896 19.0755 0.134074 18.0405 1.549C16.9537 3.02052 16.5397 5.341 17.6782 8.34065C18.765 11.1705 23.5779 16.8302 23.7331 17C23.9401 16.8868 29.3223 13.208 30.616 11.3969C31.9098 9.64238 32.1686 7.88787 31.9098 6.35975Z"
      fill="#F46767"
    />
    <path
      d="M13.9627 1.549C12.9309 0.134074 11.2799 -0.431896 9.62888 0.360463C7.97789 1.15282 6.94603 2.68094 6.27531 4.71844C5.03707 3.4167 3.79883 2.79414 2.40581 3.24691C1.16757 3.64309 0.342073 4.88823 0.0841064 6.41635C-0.17386 7.88787 0.1357 9.69898 1.37394 11.4535C2.71537 13.208 8.08108 16.8868 8.28745 17C8.44223 16.8302 13.2404 11.1705 14.3239 8.34065C15.4589 5.341 15.0462 3.07712 13.9627 1.549Z"
      fill="#F46767"
    />
    <path
      d="M25 19.7C25 19.3 24.7353 18.8 24.0471 18.65C22.1941 18.3 19.4941 18 16 18C12.5059 18 9.80588 18.35 7.95294 18.65C7.21176 18.8 7 19.3 7 19.7C7 23.35 9.96471 27 16 27C22.0353 26.95 25 23.3 25 19.7Z"
      fill="#664E27"
    />
    <path
      d="M23.2037 19.6099C21.9523 19.3659 19.3357 19 15.9797 19C12.6237 19 10.0072 19.3659 8.75582 19.6099C8.01636 19.7319 7.95948 20.0368 8.01636 20.5247C8.07324 20.7687 8.07324 21.1346 8.18701 21.5005C8.24389 21.8665 8.35765 22.0494 8.92646 21.9884C10.0072 21.8665 22.0092 21.8665 23.0899 21.9884C23.6587 22.0494 23.7156 21.8665 23.8294 21.5005C23.8862 21.1346 23.9431 20.8297 24 20.5247C24 20.0368 23.9431 19.7319 23.2037 19.6099Z"
      fill="white"
    />
  </React.Fragment>
);

LikeIcon.propTypes = {
  kind: PropTypes.oneOf(['good', 'bad']),
  size: PropTypes.oneOf(['normal', 'big']),
  selected: PropTypes.bool,
  loading: PropTypes.loading,
};

export default LikeIcon;
