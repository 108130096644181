const SNAP_DISTANCE = 15;

const isInReachable = (snap, pos, out, newPos) => {
  const diff = Math.abs(snap - pos);
  if (diff < SNAP_DISTANCE) {
    out.push({ diff, snap: newPos });
  }
};

const resolvePossiblePosition = (possiblePositions, out, property) => {
  if (possiblePositions.length > 0) {
    // eslint-disable-next-line no-param-reassign
    out[property] = possiblePositions.sort((a, b) => b.diff - a.diff)[0].snap;
  }
};

export default ({ object, startPoint, mouse, scale, dragOptions }) => {
  const newPosition = {
    ...object,
    x: mouse.x / scale - (startPoint.clientX / scale - startPoint.objectX),
    y: mouse.y / scale - (startPoint.clientY / scale - startPoint.objectY),
  };

  // const tempPos = { ...newPosition };

  const { handler: boundingBox, snappingObjects } = dragOptions;

  const possibleXPositions = [];
  const possibleYPositions = [];

  snappingObjects
    .filter(obj => !obj.lockLayer && !obj.hideLayer)
    .forEach(otherObject => {
      const otherTop = otherObject.y;
      const otherLeft = otherObject.x;
      const otherBottom = otherTop + otherObject.height;
      const otherRight = otherLeft + otherObject.width;
      const otherCenterX = otherLeft + otherObject.width / 2;
      const otherCenterY = otherTop + otherObject.height / 2;

      const left = newPosition.x;
      const top = newPosition.y;
      const centerX = left + boundingBox.width / 2;
      const centerY = top + boundingBox.height / 2;
      const right = left + boundingBox.width;
      const bottom = top + boundingBox.height;

      // left side
      isInReachable(otherLeft, left, possibleXPositions, otherLeft);
      isInReachable(otherLeft, right, possibleXPositions, otherLeft - boundingBox.width);

      // right side
      isInReachable(otherRight, right, possibleXPositions, otherRight - boundingBox.width);
      isInReachable(otherRight, left, possibleXPositions, otherRight);

      // center horizontal
      isInReachable(otherCenterX, centerX, possibleXPositions, otherCenterX - boundingBox.width / 2);

      // top side
      isInReachable(otherTop, top, possibleYPositions, otherTop);
      isInReachable(otherTop, bottom, possibleYPositions, otherTop - boundingBox.height);

      // bot side
      isInReachable(otherBottom, bottom, possibleYPositions, otherBottom - boundingBox.height);
      isInReachable(otherBottom, top, possibleYPositions, otherBottom);

      // center vertical
      isInReachable(otherCenterY, centerY, possibleYPositions, otherCenterY - boundingBox.height / 2);
    });

  resolvePossiblePosition(possibleXPositions, newPosition, 'x');
  resolvePossiblePosition(possibleYPositions, newPosition, 'y');

  // console.log('X:', tempPos.x, '--->', newPosition.x, 'Y:', tempPos.y, '--->', newPosition.y )
  return newPosition;
};
