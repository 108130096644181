/* eslint-disable no-plusplus */
document.addEventListener('DOMContentLoaded', () => {
  window.handleSearchInputs = () => {
    const searchInputs = document.getElementsByClassName('js-input-search');
    const submitBtns = document.getElementsByClassName('js-search-submit');
    const resetBtns = document.getElementsByClassName('js-search-reset');

    if (searchInputs.length > 0) {
      // initial check
      Array.from(searchInputs).forEach(searchInput => {
        if (searchInput.value === '') {
          searchInput.parentNode.classList.remove('valueFilled');
        } else {
          searchInput.parentNode.classList.add('valueFilled');
        }
      });

      // live check
      Array.from(searchInputs).forEach(searchInput => {
        searchInput.addEventListener('keyup', () => {
          if (searchInput.value === '') {
            searchInput.parentNode.classList.remove('valueFilled');
          } else {
            searchInput.parentNode.classList.add('valueFilled');
          }
        });
      });

      // submit binded on class
      Array.from(submitBtns).forEach(submitBtn => {
        submitBtn.addEventListener('click', () => {
          if (submitBtn.parentNode.classList.contains('js-remote')) {
            $(submitBtn.parentNode).trigger('submit.rails');
          } else {
            submitBtn.parentNode.submit();
          }
        });
      });

      // reset handler for both live search and normal search
      Array.from(resetBtns).forEach(resetBtn => {
        resetBtn.addEventListener('click', e => {
          e.preventDefault();
          if (resetBtn.parentNode.classList.contains('Input--search')) {
            $(resetBtn)
              .siblings('.js-input-search')
              .val('');
            $(resetBtn)
              .siblings('.js-input-search')
              .change();

            if (resetBtn.parentNode.classList.contains('js-remote')) {
              $(resetBtn.parentNode).trigger('submit.rails');
            } else {
              resetBtn.parentNode.submit();
            }
          } else {
            $(resetBtn)
              .siblings('.js-input-search')
              .val('');
            $(resetBtn)
              .siblings('.js-input-search')
              .change();
            resetBtn.parentNode.classList.remove('valueFilled');
          }
        });
      });
    }
  };

  window.handleSearchInputs();
});
