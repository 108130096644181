import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import cs from 'classnames';
import { Link, Tile, Row, Col, PreviewInputValue, Ellipsis } from '../index';
import ProductInfoButton from './ProductInfoButton';
import { unmodifiedValue } from './utils';
import ImageSwitcher from './ImageSwitcher';
import ProductsViewContext from './ProductsViewContext';
import EditColumnsLink from './EditColumnsLink';

const Card = ({ item }) => {
  const { tableVariablesSorted, mainIdentifier, cardImage } = useContext(ProductsViewContext);

  return (
    <Tile fullHeight>
      <div className="Card" data-test-id="product-card">
        <ImageSwitcher
          cardImage={cardImage}
          tableVariablesSorted={tableVariablesSorted}
          item={item}
          withoutTile
          fullWidth
          size={200}
        />

        <div className="delimiter negative-ml-16 negative-mr-16 mb-16" />

        <ProductInfoButton item={item} mainIdentifier={mainIdentifier}>
          {({ onClick }) => (
            <Ellipsis>
              <Link onClick={onClick} bold data-test-id="product-card-main-identifier">
                {unmodifiedValue({ item, placeholderName: mainIdentifier })}
              </Link>
            </Ellipsis>
          )}
        </ProductInfoButton>

        <div className="Card-content">
          {tableVariablesSorted
            .filter(({ showInCard }) => showInCard)
            .slice(0, 4)
            .map(({ name, placeholderName, showFieldKind }) => {
              const isValueLink = `${unmodifiedValue({ item, placeholderName })}`.match(/https?:\/\//);

              return (
                <div className={cs('mt-4', { 'd-flex': isValueLink })} key={placeholderName}>
                  <span className={cs('vam', { 'text-nowrap': isValueLink })}>
                    {name}: {showFieldKind.endsWith('-array') && <br />}
                  </span>
                  <strong
                    className={cs('vam', {
                      'overflow-hidden ml-4': isValueLink,
                      'js-text-overflow text-inline-block-overflow': !isValueLink,
                    })}
                  >
                    <PreviewInputValue
                      showInline
                      value={unmodifiedValue({ item, placeholderName })}
                      showFieldKind={showFieldKind}
                    />
                  </strong>
                </div>
              );
            })}
        </div>
      </div>
    </Tile>
  );
};
Card.propTypes = {
  item: PropTypes.object,
};

const CardView = ({ items = [], style, className, editColumnsLink, additonalButtonsRef }) => (
  <React.Fragment>
    <EditColumnsLink editColumnsLink={editColumnsLink} container={additonalButtonsRef} />
    <Row style={style} className={className} flexwrap>
      {items.map(item => (
        <Col key={item.product_id} width="232px">
          <Card item={item} />
        </Col>
      ))}
    </Row>
  </React.Fragment>
);
CardView.propTypes = {
  additonalButtonsRef: PropTypes.any,
  className: PropTypes.string,
  editColumnsLink: PropTypes.string,
  items: PropTypes.array,
  style: PropTypes.object,
};

export default hot(CardView);
