export default ({ object, startPoint, mouse, scale, startPoints }) => {
  const boxLeft = Math.min(...startPoints.map(sp => sp.objectX));
  const boxTop = Math.min(...startPoints.map(sp => sp.objectY));
  const boxWidth = Math.max(...startPoints.map(sp => sp.objectX + sp.width - boxLeft));
  const boxHeight = Math.max(...startPoints.map(sp => sp.objectY + sp.height - boxTop));

  const { objectX, objectY, clientX, clientY } = startPoint;

  const diff = Math.max(mouse.x / scale - clientX / scale, mouse.y / scale - clientY / scale);

  const aspectBoxH = boxWidth > boxHeight ? 1 : boxHeight / boxWidth;
  const aspectBoxW = boxWidth > boxHeight ? boxWidth / boxHeight : 1;

  const width = Math.round(startPoint.width + (startPoint.width / boxWidth) * diff * aspectBoxW);
  const height = Math.round(startPoint.height + (startPoint.height / boxHeight) * diff * aspectBoxH);

  const newX = objectX + ((objectX - boxLeft) / boxWidth) * diff * aspectBoxW;
  const newY = objectY + ((objectY - boxTop) / boxHeight) * diff * aspectBoxH;

  const output = {
    ...object,
    x: width > 0 ? newX : newX + width,
    y: height > 0 ? newY : newY + height,
    width: Math.abs(width),
    height: Math.abs(height),
  };

  if (startPoint.fontSize) {
    output.fontSize = Math.abs(startPoint.fontSize + (startPoint.fontSize / boxHeight) * diff * aspectBoxH);
  }

  return output;
};
