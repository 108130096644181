import { createPopper } from '@popperjs/core';
import uuid from 'uuid';

document.addEventListener('DOMContentLoaded', () => {
  $(document).on('click', '.js-dropdown-menu-button', e => {
    const dropdownMenuButtonEl = e.target.closest('.js-dropdown-menu-button');
    if (dropdownMenuButtonEl) {
      if (dropdownMenuButtonEl.dataset.opened) {
        dropdownMenuButtonEl.hideDropdown();
      } else {
        const placement = dropdownMenuButtonEl.dataset.placement || 'bottom-end';
        dropdownMenuButtonEl.dataset.opened = true;
        const dropdownMenu = dropdownMenuButtonEl.closest('.DropdownMenu');
        const dropdownMenuBox = dropdownMenu.querySelector('.DropdownMenu-dropdown');

        dropdownMenuButtonEl.classList.add('open');
        dropdownMenuBox.classList.add('open');
        dropdownMenuBox.classList.add('js-dropdown-menu-customHandler');

        dropdownMenuButtonEl.dataset.dropdownButtonId = uuid();
        dropdownMenuBox.dataset.dropdownButtonId = dropdownMenuButtonEl.dataset.dropdownButtonId;

        const popperInstance = createPopper(dropdownMenuButtonEl, dropdownMenuBox, {
          strategy: 'fixed',
          placement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 4],
              },
            },
          ],
          onFirstUpdate: () => {
            popperInstance.update();
          },
        });
        if (e.target.closest('.Modal-body')) {
          e.target.closest('.Modal-body').appendChild(dropdownMenuBox);
        } else {
          document.body.appendChild(dropdownMenuBox);
        }

        const hideDropdown = event => {
          if (
            dropdownMenuButtonEl.dataset.hideOnClick === 'false' &&
            event &&
            event.target &&
            event.target.closest('.DropdownMenu-dropdown')
          ) {
            e.stopImmediatePropagation();
            return;
          }

          if (dropdownMenuButtonEl?.removeEventListenerForHide) {
            dropdownMenuButtonEl.removeEventListenerForHide();
          }
          popperInstance.destroy();
          delete dropdownMenuButtonEl.dataset.opened;
          dropdownMenu.appendChild(dropdownMenuBox);
          dropdownMenuBox.classList.remove('open');
          dropdownMenuButtonEl.classList.remove('open');
          dropdownMenuButtonEl.hideDropdown = undefined;
          dropdownMenuButtonEl.removeEventListenerForHide = undefined;

          if (typeof dropdownMenu.onClose === 'function') {
            dropdownMenu.onClose(dropdownMenuBox);
          }
        };

        dropdownMenuButtonEl.hideDropdown = hideDropdown;

        setTimeout(() => window.addEventListener('click', hideDropdown), 10);
        dropdownMenuButtonEl.removeEventListenerForHide = () => {
          window.removeEventListener('click', hideDropdown);
        };

        if (typeof dropdownMenu.onOpen === 'function') {
          dropdownMenu.onOpen(dropdownMenuBox);
        }
      }
    }
  });

  setInterval(() => {
    Array.from(document.querySelectorAll('.DropdownMenu-dropdown.open.js-dropdown-menu-customHandler')).forEach(
      dropdownMenu => {
        if (
          document.querySelectorAll(
            `.js-dropdown-menu-button[data-dropdown-button-id="${dropdownMenu.dataset.dropdownButtonId}"]`
          ).length === 0
        ) {
          dropdownMenu.remove();
        }
      }
    );
  }, 500);
});

window.addEventListener('load', () => {
  $('.js-dropdown-force-open:first').click();
});
