import React from 'react';
import image from '../../scss_stylesheets/images/error_shape_stared.svg';
import { PageHeading } from '../Typography';
import { t } from '../../i18n';

const ErrorState = () => (
  <div>
    <img alt="Error no data" src={image} />
    <div>
      <PageHeading className="mt-16">{t('react.report_table.no_data', { default: 'No data to view' })}</PageHeading>
    </div>
  </div>
);

export default ErrorState;
