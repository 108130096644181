import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { snakeCase, uniq, flatten } from 'lodash';
import { formatInteger, t } from '../../i18n';
import Pagination from '../Pagination';
import { Select } from '../form';
import { QueryHolder } from '../QueryHolder';
import { EmptyState } from '../index';
import * as Layout from '../layout';
import { listenOnModalChange } from '../RemoteModal';
import { MultiEditWrapper } from './MultiEdit';
import LoadingTable from './LoadingTable';
import DisplayTable from './DisplayTable';
import DisplayGrid from './DisplayGrid';
import DisplayAdtextsTable from './DisplayAdtextsTable';
import { mappingAdditional } from './mapping';

const getArrayContainingSpecificAttribute = (arr, attr) =>
  arr
    .map(obj => {
      if (obj[attr]) return obj[attr];
      return null;
    })
    .filter(pol => pol);

export const getPollingIntervals = (columnsMapping = [], data = []) => {
  if (!Array.isArray(columnsMapping) || !Array.isArray(data)) return undefined;

  const pollingArr = getArrayContainingSpecificAttribute(columnsMapping, 'pollingInterval');
  if (pollingArr.length === 0) return undefined;

  const res = pollingArr
    .map(({ shouldSync, interval }) => (data.map(rowData => shouldSync(rowData)).includes(true) ? interval : null))
    .filter(rowEl => !!rowEl);

  return res.length > 0 ? Math.min(...res) : null;
};

export class StickyTable extends PureComponent {
  static propTypes = {
    activeSearchState: PropTypes.object,
    assetGroupType: PropTypes.bool,
    canEdit: PropTypes.bool,
    collectionKey: PropTypes.string.isRequired,
    createItem: PropTypes.object,
    data: PropTypes.object,
    previousData: PropTypes.object,
    emptyState: PropTypes.object,
    forceOpenBranches: PropTypes.bool,
    getGraphqlQuery: PropTypes.string,
    grid: PropTypes.bool,
    changeLimit: PropTypes.func,
    changePage: PropTypes.func.isRequired,
    children: PropTypes.any,
    itemKey: PropTypes.string,
    refetchTableOnClick: PropTypes.string,
    layoutType: PropTypes.string,
    loading: PropTypes.bool,
    mapping: PropTypes.array,
    multiEdit: PropTypes.object,
    organizationId: PropTypes.number,
    campaignSettingId: PropTypes.number,
    dataSourceId: PropTypes.number,
    perOptions: PropTypes.array,
    refetch: PropTypes.func,
    startPolling: PropTypes.func,
    stopPolling: PropTypes.func,
    onLoadingStateChange: PropTypes.func,
    hidePagination: PropTypes.bool,
    statusCampaignSwitchWarning: PropTypes.bool,
  };

  static defaultProps = {
    data: {
      collection: {
        pagination: {},
      },
    },
    multiEdit: {},
  };

  state = {
    fixedWidths: {},
  };

  componentDidMount() {
    this.refreshOnUpdateListener = listenOnModalChange(this.refreshOnUpdate);
    this.mounted = true;
    if (this.props.refetchTableOnClick) {
      $(document).on('click', this.props.refetchTableOnClick, this.refetchOnClickCallback);
    }
  }

  componentWillUnmount() {
    if (this.refreshOnUpdateListener) {
      this.refreshOnUpdateListener();
    }

    this.mounted = false;

    if (this.props.refetchTableOnClick) {
      $(document).off('click', this.props.refetchTableOnClick, this.refetchOnClickCallback);
    }
  }

  refreshOnUpdate = e => {
    if (typeof this.props.refetch === 'function') {
      if (
        !e ||
        (typeof e === 'string'
          ? e === snakeCase(this.props.collectionKey)
          : e.find(assoc => assoc === snakeCase(this.props.collectionKey)))
      ) {
        this.props.refetch();
      }
    }
  };

  refetchOnClickCallback = () => {
    setTimeout(() => {
      this.props.refetch();
    }, 2000);
  };

  componentDidUpdate(prevProps) {
    if (this.props.onLoadingStateChange && prevProps.loading !== this.props.loading) {
      this.props.onLoadingStateChange(this.props.loading);
    }
  }

  mounted = false;

  render() {
    const {
      activeSearchState,
      canEdit,
      collectionKey,
      createItem,
      emptyState,
      grid,
      changeLimit,
      changePage,
      children,
      layoutType,
      loading,
      mapping,
      multiEdit,
      perOptions,
      startPolling,
      stopPolling,
      assetGroupType,
      organizationId,
      campaignSettingId,
      dataSourceId,
      getGraphqlQuery,
      hidePagination,
      statusCampaignSwitchWarning,
    } = this.props;

    const data = this.props.data?.collection?.[collectionKey] || [];
    const additionalData = this.props.data?.collection?.[mappingAdditional[collectionKey]?.key || ''];
    const prevDataRowsCount = this.props.previousData?.collection?.[collectionKey]?.length;
    const { totalCount, totalPages, totalUnfilteredCount, limit, page } = this.props.data?.collection?.pagination || {};

    if (totalUnfilteredCount === 0) {
      return (
        <Layout.Col padding="l">
          <EmptyState icon={emptyState.icon} heading={emptyState.text} hasBorder>
            {createItem}
          </EmptyState>
        </Layout.Col>
      );
    }

    const pollInterval = getPollingIntervals(mapping, data);
    if (typeof pollInterval !== 'undefined') {
      if (pollInterval) startPolling(pollInterval);
      else stopPolling();
    }

    const allowSelect = !!multiEdit.delete || !!multiEdit.columnsEdit || !!multiEdit.paused;
    const multiEditData = flatten(data.map(item => (item.adtexts ? item.adtexts : [item])));
    const dataIds = allowSelect && multiEditData.map(({ id }) => id);

    const ShowDataComponent = [
      collectionKey === 'adtextGroups' && DisplayAdtextsTable,
      grid && DisplayGrid,
      DisplayTable,
    ].find(i => i);

    const noDataToShow = loading ? (
      <LoadingTable allowSelect={allowSelect} rows={prevDataRowsCount} />
    ) : (
      <EmptyState
        hasBorder
        icon={this.props?.emptyState?.icon}
        emptySearch={t('react.table.to_complex_filter_with_no_records', {
          default:
            'You filtered too much, no record to be shown but you have %{totalUnfilteredCount} records. Try to modify search criteria.',
          totalUnfilteredCount,
        })}
      />
    );

    return (
      <Layout.Col padding="l">
        <MultiEditWrapper
          organizationId={organizationId}
          dataSourceId={dataSourceId}
          campaignSettingId={campaignSettingId}
          collectionKey={collectionKey}
          dataIds={dataIds}
          activeColumns={mapping}
          itemKey={this.props.itemKey}
          data={multiEditData}
          getGraphqlQuery={getGraphqlQuery}
          allowSelect={allowSelect}
          multiEdit={multiEdit}
          refetch={this.props.refetch}
        >
          {!!children && <Layout.Row shrink>{children}</Layout.Row>}
          {data.length === 0 ? (
            noDataToShow
          ) : (
            <React.Fragment>
              <Layout.Row grow style={{ position: 'relative' }}>
                <Layout.Col grow style={{ position: 'relative' }}>
                  <ShowDataComponent
                    forceOpenBranches={this.props.forceOpenBranches}
                    activeSearchState={activeSearchState}
                    layoutType={layoutType}
                    assetGroupType={assetGroupType}
                    data={data}
                    additionalData={additionalData}
                    refetch={this.props.refetch}
                    mapping={mapping}
                    allowSelect={allowSelect}
                    canEdit={canEdit}
                    organizationId={organizationId}
                    dataSourceId={dataSourceId}
                    statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                  />
                </Layout.Col>
              </Layout.Row>
              {!hidePagination && (
                <Pagination current={page} maxPages={totalPages} changePage={changePage}>
                  <Layout.Col shrink>
                    {t('react.show', {
                      default: 'Show',
                    })}
                  </Layout.Col>
                  <Layout.Col shrink nowrap>
                    <Select
                      doNotUseInternalState
                      id={`${collectionKey}-perPage`}
                      name={`${collectionKey}[perPage]`}
                      value={limit}
                      onChange={changeLimit}
                      collection={uniq(perOptions.concat([limit])).map(value => ({
                        value,
                        label: t('react.items_count', { items: value }),
                      }))}
                    />
                  </Layout.Col>
                  <Layout.Col shrink nowrap>
                    {t('react.outof', {
                      default: 'out of %{total}',
                      total: formatInteger(totalCount || 0),
                    })}{' '}
                    {totalCount !== totalUnfilteredCount &&
                      t('react.fromtotal', {
                        default: 'from all %{total}',
                        total: formatInteger(totalUnfilteredCount || 0),
                      })}
                  </Layout.Col>
                </Pagination>
              )}
            </React.Fragment>
          )}
        </MultiEditWrapper>
      </Layout.Col>
    );
  }
}

export const QueryStickyTable = hot(QueryHolder(StickyTable));
