import { t } from '../../../i18n';

const FREE = 0;
const DRAG = 1;
const SCALE = 2;
const ROTATE = 3;
const DRAW = 4;
const TYPE = 5;
const EDIT_OBJECT = 6;

export const DEFAULT_IMAGE_PLACEHOLDER =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAhSURBVHgBtYmxDQAADII8lv9faBNH4yoJLAi4ppxgMZoPoxQrXYyeEfoAAAAASUVORK5CYII=';

export const CUSTOM_RESOLUTION = 'CUSTOM_RESOLUTION';

export const RESOLUTIONS = {
  '900x900': () => '900 x 900',
  '1200x628': () => t('react.image_generator.setup.image_size_1200x628'),
  '1920x1080': () => '1920 x 1080 (16:9)',
  '1080x1080': () => t('react.image_generator.setup.image_size_1080x1080'),
  '1024x1280': () => '1024 x 1280 (4:5)',
  '1024x1536': () => '1024 x 1536 (2:3)',
  '1080x1920': () => '1080 x 1920 (9:16)',
  CUSTOM_RESOLUTION: () => t('react.image_generator.setup.custom'),
};

export const modes = {
  FREE,
  DRAG,
  SCALE,
  ROTATE,
  DRAW,
  TYPE,
  EDIT_OBJECT,
};

export default modes;
