import React from 'react';
import { PropTypes } from 'prop-types';
import { Modal, ModalBody, ModalHeader, ModalHeading, ModalSubHeading, InfoBox } from '../index';
import { t, formatPercentage, formatInteger } from '../../i18n';

const DomainsTable = ({ pricingInfo }) => (
  <div className="Sticky-Wrapper">
    <table className="Sticky-Table">
      <thead>
        <tr className="Sticky-Header">
          <th>{t('feature_period.info_levels_modal.domains.header')}</th>
        </tr>
      </thead>
      <tbody className="Sticky-Body">
        {pricingInfo.map(domain => (
          <tr className="Row--selected" key={domain}>
            <td className="Sticky-Cell">{domain}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const PricingTable = ({ pricingInfo, index, tableData: { syncCount = 4 }, pricing: { resolvedBy } }) => {
  const limitedPricing = isNaN(index) ? pricingInfo : pricingInfo.filter((x, i) => i <= index + 4);
  const isSync = resolvedBy === 'usage_and_sync_count';
  const isUnlimited = syncCount === 1 || syncCount === 4;
  const isDomains = resolvedBy === 'domains_count';

  if (isSync && isUnlimited) {
    return (
      <InfoBox type="info" withIcon>
        {t(syncCount, { scope: 'feature_period.info_levels_modal.unlimited_use' })}
      </InfoBox>
    );
  }

  return (
    <div className="Sticky-Wrapper">
      <table className="Sticky-Table">
        <thead>
          <tr className="Sticky-Header">
            <th>{t('maximum_usage', { scope: 'feature_period.info_levels_modal.header' })}</th>
            <th>{t('resolver', { scope: 'feature_period.info_levels_modal.header' })}</th>
            <th>{t(isSync ? 'synces' : 'price', { scope: 'feature_period.info_levels_modal.header', syncCount })}</th>
          </tr>
        </thead>

        <tbody className="Sticky-Body">
          {limitedPricing.map(
            ({ resolver, price, spend, percents, count }, id) =>
              (count || spend) && (
                <tr key={[resolver, id].join('-')} className="Row--selected">
                  <td className="Sticky-Cell">
                    {count
                      ? t(isDomains ? `domains_count.${count === 1 ? 'one' : 'any'}` : 'products', {
                          scope: 'react.fb_features',
                          products: formatInteger(count),
                          domains: formatInteger(count),
                        })
                      : spend}
                  </td>
                  <td className="Sticky-Cell">{t(resolver, { scope: 'feature_period.price_resolver_text' })}</td>
                  <td className="Sticky-Cell">{price || formatPercentage(percents * 100)}</td>
                </tr>
              )
          )}
        </tbody>
      </table>
    </div>
  );
};

const PricingModal = ({ setModalPricing, modalPricing }) => {
  const heading = modalPricing?.domains ? t('feature_period.header.domains') : t('feature_period.header.pricing');
  const subheading =
    modalPricing?.resolvedBy === 'domains_count'
      ? t('feature_period.info_levels_modal.domains.subheading')
      : t(modalPricing.feature, { scope: 'feature_period.resolved_from' });

  const TableComponent = modalPricing?.resolvedBy === 'domains_count' ? DomainsTable : PricingTable;
  return (
    <Modal onClose={() => setModalPricing(null)} border size="small">
      <ModalHeader>
        <ModalHeading>{heading}</ModalHeading>
        <ModalSubHeading>{subheading}</ModalSubHeading>
      </ModalHeader>
      <ModalBody>
        <TableComponent {...modalPricing} />
      </ModalBody>
    </Modal>
  );
};

DomainsTable.propTypes = {
  pricingInfo: PropTypes.array,
};

PricingTable.propTypes = {
  index: PropTypes.number,
  pricing: PropTypes.object,
  pricingInfo: PropTypes.array,
  tableData: PropTypes.object,
};

PricingModal.propTypes = {
  modalPricing: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  setModalPricing: PropTypes.func,
};

export default PricingModal;
