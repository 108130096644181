export function findEntity(text, position, entities) {
  // eslint-disable-next-line no-restricted-syntax
  for (const entity of entities) {
    let start = text.indexOf(entity);

    // Continue searching until no more instances of the entity are found
    while (start !== -1) {
      const end = start + entity.length;

      // Check if the position is within the range of this entity
      if (position >= start && position <= end) {
        return { start, end };
      }

      // Search for the next occurrence of the entity after the current one
      start = text.indexOf(entity, start + 1);
    }
  }

  return null;
}

export function findEntityPosition(text, entity, position) {
  let start = text.indexOf(entity);

  // Continue searching until no more instances of the entity are found
  while (start !== -1) {
    const end = start + entity.length;

    // Check if the position is within the range of this entity
    if (position >= start && position <= end) {
      return { start, end };
    }

    // Search for the next occurrence of the entity after the current one
    start = text.indexOf(entity, start + 1);
  }

  // If no entity is found at the given position, return null
  return null;
}

export function determineClickSide(event) {
  const element = event.target;

  // Get the bounding rectangle of the element
  const rect = element.getBoundingClientRect();

  // Get the horizontal center of the element
  const elementCenter = rect.left + rect.width / 2;

  // Compare the event's click position (clientX) with the center of the element
  if (event.clientX < elementCenter) {
    return 'left';
  } else {
    return 'right';
  }
}
