import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { t } from '../../../../i18n';
import { Icon, Loader, Col, Row, SystemIcon, Tooltip, Text, DropdownMenu, Switch, Badge } from '../../../index';
import ConditionalWrapper from '../../../ConditionalWrapper';

const SystemDropdownContent = ({ link, system, iconColor, subtext, subtextColor, children }) => (
  <a href={link} className="pv-4">
    <Row key={system} center padding="l">
      <Col shrink>
        <SystemIcon color={iconColor} size="14" system={system} />
      </Col>
      <Col noPadding grow>
        <Row>
          <Text size="md" medium className="mb-4">
            {t(`adtext_systems.${system}`)}
          </Text>
        </Row>
        <Row>
          <Text size="sm" color={subtextColor}>
            {subtext}
          </Text>
        </Row>
      </Col>
      <Col className="ml-40" shrink>
        {children}
      </Col>
    </Row>
  </a>
);
SystemDropdownContent.propTypes = {
  link: PropTypes.string,
  system: PropTypes.string,
  iconColor: PropTypes.string,
  subtext: PropTypes.string,
  subtextColor: PropTypes.string,
  children: PropTypes.any,
};

const contents = ({ syncError, hasError, hasInvalidLinkedAccount, enabled, generated, synced, status }) => [
  {
    condition: Boolean(hasError && hasInvalidLinkedAccount),
    iconColor: '#FF493F',
    subtext: t('react.tables.missing_account_access'),
    subtextColor: 'error',
    linkContent: <Text className="Link">{t('react.tables.resolve')}</Text>,
  },
  {
    condition: Boolean(hasError),
    iconColor: '#FF493F',
    subtext: t('react.tables.sync_error'),
    subtextColor: 'error',
    linkContent: <Text className="Link">{t('react.tables.resolve')}</Text>,
    toolTip: syncError !== 'Error' && syncError,
  },
  {
    condition: Boolean(enabled && (generated || synced) && status === 'paused'),
    iconColor: 'color',
    subtext: t('react.tables.paused'),
    subtextColor: 'gray',
  },
  {
    condition: Boolean(enabled && (generated || synced)),
    iconColor: 'color',
    subtext: t('react.tables.in_sync'),
    subtextColor: 'lightGray',
  },
  {
    condition: Boolean(enabled),
    iconColor: 'color',
    subtext: t('react.tables.prepared'),
    subtextColor: 'lightGray',
    linkContent: <Text className="Link">{t('react.tables.edit')}</Text>,
  },
  {
    condition: true,
    iconColor: 'grey',
    subtext: t('react.tables.not_set'),
    subtextColor: 'lightGray',
    linkContent: <Text className="Link">{t('react.tables.set_up')}</Text>,
  },
];

const SystemDropdownRow = props => {
  const { system, link, children } = props;
  const { iconColor, subtext, subtextColor, linkContent, toolTip } = contents(props).find(({ condition }) => condition);

  const inside = linkContent || children;

  return (
    <ConditionalWrapper condition={toolTip} wrapper={child => <Tooltip text={toolTip}>{child}</Tooltip>}>
      <SystemDropdownContent
        link={link}
        system={system}
        iconColor={iconColor}
        subtext={subtext}
        subtextColor={subtextColor}
      >
        {inside}
      </SystemDropdownContent>
    </ConditionalWrapper>
  );
};
SystemDropdownRow.propTypes = {
  system: PropTypes.string,
  link: PropTypes.string,
  children: PropTypes.any,
};

const Label = ({ systems }) => (
  <Row padding="m" center grow>
    {systems
      .filter(({ link }) => link)
      .map(({ system, enabled, hasError, status, timeRestrictionStatus }) => {
        const errorIcon = Boolean(hasError);
        const activeIcon = Boolean(!hasError && enabled && status === 'running');
        const pausedIcon = Boolean(!hasError && enabled && status === 'paused');
        const badgeKind = activeIcon && timeRestrictionStatus === 'restricted' ? 'orange' : 'green';
        return (
          <React.Fragment key={system}>
            <Col shrink>
              <Row center>
                <div className="pa-2 mr-2">
                  <SystemIcon color={(enabled && 'color') || 'grey'} size="12" system={system} />
                </div>
                <div className={cs('d-flex', { 'justify-content-center': errorIcon })} style={{ minWidth: '16px' }}>
                  {(activeIcon || pausedIcon) && (
                    <Badge
                      className="ml-4"
                      style={{ minWidth: '4px', minHeight: '4px' }}
                      kind={pausedIcon ? 'neutral' : badgeKind}
                    />
                  )}
                  {errorIcon && <Icon color="#FF493F" kind="alert-filled" size="16px" />}
                </div>
              </Row>
            </Col>
          </React.Fragment>
        );
      })}
  </Row>
);
Label.propTypes = { systems: PropTypes.array };

function SystemsDropdown({ systems = [], forceOpen, synced, generated, loading, setLoading, editQuery, update }) {
  return (
    <DropdownMenu
      dataTestId="systems-dropdown"
      hideOnClick={false}
      label={<Label systems={systems} />}
      forceOpen={forceOpen}
      isButtonRounded
      isButtonWithBorder
      small
      gray
      hasChevronDelimiter
    >
      {systems?.length > 0 &&
        systems
          .filter(({ link }) => link)
          .map(el => (
            <SystemDropdownRow generated={generated} synced={synced} key={el.system} {...el}>
              {loading && <Loader absolute />}

              <Switch
                timeRestriction={el.timeRestrictionStatus}
                pausePlay
                doNotUseInternalState
                value={el.status === 'running'}
                onChange={({ target: { value } }) => {
                  setLoading(true);
                  return editQuery && update({ variables: { [`${el.system}Status`]: value ? 'running' : 'paused' } });
                }}
              />
            </SystemDropdownRow>
          ))}
    </DropdownMenu>
  );
}
SystemsDropdown.propTypes = {
  systems: PropTypes.array,
  forceOpen: PropTypes.bool,
  synced: PropTypes.bool,
  generated: PropTypes.bool,
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  editQuery: PropTypes.object,
  update: PropTypes.func,
};

export default SystemsDropdown;
