import React from 'react';
import PropTypes from 'prop-types';
import useInputState from './useInputState';
import CharacterCounter from '../nested_fields/CharacterCounter';
import { t } from '../i18n';

const LiveCharacterCounter = ({ name, maxTextCount }) => {
  const { state } = useInputState({ name, defaultValue: '', useExactNames: true });

  return (
    <CharacterCounter
      name={name}
      id={name}
      without_variables_count={state.trim().length}
      chars={state.trim()}
      overLimit={state.trim().length > maxTextCount}
      max_text_count={maxTextCount}
      without_variables={t('text_length', { default: 'Letter count' })}
    />
  );
};

LiveCharacterCounter.propTypes = {
  name: PropTypes.string,
  maxTextCount: PropTypes.number,
};

export default LiveCharacterCounter;
