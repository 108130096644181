import React, { Component } from 'react';
import RPT from 'prop-types';
import cs from 'classnames';

class InfoTooltip extends Component {
  static propTypes = {
    tooltipText: RPT.string,
    className: RPT.string,
  };

  render() {
    const { tooltipText, className, ...rest } = this.props;

    return (
      <span {...rest} className={cs('fc-info', 'js-tooltip', className)} data-title={tooltipText} data-html="true" />
    );
  }
}

export default InfoTooltip;
