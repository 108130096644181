import React, { useContext } from 'react';
import { SearchContext } from '../index';
import { t } from '../../../i18n';
import { Switch } from '../../index';

export const ActiveSearch = () => {
  const {
    searchState,
    searchChange,
    searchOptions: { onlyActiveText },
  } = useContext(SearchContext);
  return (
    <Switch
      id="active-state-search"
      value={searchState.active === 'only_active' || false}
      doNotUseInternalState
      onChange={({ target: { value } }) => searchChange('active', value ? 'only_active' : 'all')}
      label={onlyActiveText || t('react.active_search.only_active')}
    />
  );
};

ActiveSearch.propTypes = {};

export default ActiveSearch;
