const findChild = (elements, id, allAdtexts = true) => {
  const activeForGeneration = !allAdtexts;

  const child = Object.keys(elements).find(
    subid => elements[subid].parent.toString() === id.toString()
  );
  if (!activeForGeneration) {
    return child;
  }
  if (child) {
    if (!elements[child].activeForGeneration) {
      return findChild(elements, child, allAdtexts);
    }
  }
  return child;
};

export default findChild;
