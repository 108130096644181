import React from 'react';
import PropTypes from 'prop-types';
import Badge from './Badge';

export const ColorSquare = ({ color, size }) => <div style={{ backgroundColor: color, width: size, height: size }} />;
ColorSquare.propTypes = {
  color: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

// eslint-disable-next-line consistent-return
const brightnessByColor = inputColor => {
  if (!inputColor) {
    return (299 + 587 + 114) / 1000;
  }
  const color = `${inputColor}`;
  let r;
  let g;
  let b;
  if (inputColor?.indexOf('#') === 0) {
    const hasFullSpec = color.length === 7;
    const m = color.substr(1).match(hasFullSpec ? /(\S{2})/g : /(\S{1})/g);
    if (m) {
      r = parseInt(m[0] + (hasFullSpec ? '' : m[0]), 16);
      g = parseInt(m[1] + (hasFullSpec ? '' : m[1]), 16);
      b = parseInt(m[2] + (hasFullSpec ? '' : m[2]), 16);
    }
  }
  if (inputColor.indexOf('rgb') === 0) {
    const m = color.match(/(\d+){3}/g);
    if (m) {
      r = m[0];
      g = m[1];
      b = m[2];
    }
  }
  if (typeof r !== 'undefined') {
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
};

const ColorLabel = ({ color, name, ...rest }) => (
  <Badge
    {...rest}
    className="bright-hover"
    style={{
      background: color,
      color: brightnessByColor(color) < 128 ? '#fff' : '#000',
      cursor: rest.onClick ? 'pointer' : 'defalt',
    }}
  >
    {name}
  </Badge>
);

ColorLabel.propTypes = {
  color: PropTypes.string,
  name: PropTypes.string,
};

export default ColorLabel;
