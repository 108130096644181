import { DirectUpload } from '@rails/activestorage';

const uploadFile = (file, successCallback, errorCallback) => {
  // your form needs the file_field direct_upload: true, which
  //  provides data-direct-upload-url
  const url = '/rails/active_storage/direct_uploads';
  const upload = new DirectUpload(file, url);

  upload.create((error, blob) => {
    if (error) {
      errorCallback(error);
    } else {
      successCallback(blob.signed_id);
    }
  });
};

export default uploadFile;
