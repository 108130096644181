import React from 'react';
import { hot } from 'react-hot-loader/root';
import { omit } from 'lodash';
import Papa from 'papaparse';
import { gql, useLazyQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { Icon, Row, Col, DropdownMenu } from '../components/index';
import { t } from '../i18n';

const DOWNLOAD_PERIODS = [
  'download_last_30_days',
  'download_last_7_days',
  'download_last_month',
  'download_this_month',
];

const QUERY_DOWNLOAD_LOG = gql`
  query AuditLogDownload(
    $page: Int!
    $limit: Int!
    $organizationId: BigInt!
    $campaignSettingId: BigInt
    $dataSourceId: BigInt
    $feedExportId: BigInt
    $additionalSourceId: BigInt
    $fulltext: String
  ) {
    collection(
      identifier: "AuditLog"
      page: $page
      limit: $limit
      organizationId: $organizationId
      campaignSettingId: $campaignSettingId
      dataSourceId: $dataSourceId
      feedExportId: $feedExportId
      additionalSourceId: $additionalSourceId
    ) {
      auditLogs(fulltext: $fulltext) {
        userName
        action
        subjectType
        subjectName
        createdAt
        ownerType
        ownerName
        subjectLink
        ownerLink
      }
    }
  }
`;

const download = tableData => {
  const csvData = Papa.unparse(tableData);
  // eslint-disable-next-line no-undef
  const blob = new Blob([csvData], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = url;
  a.download = 'audit-log.csv';
  a.click();
};

const showMessage = (text, isError = false) => {
  if (typeof window.NotificationCenter !== 'undefined') {
    return isError
      ? new window.NotificationCenter().show_error(text)
      : new window.NotificationCenter().show_success(text);
  } else return null;
};

const AuditLogDownloadDropdown = ({
  organizationId,
  dataSourceId,
  campaignSettingId,
  feedExportId,
  additionalSourceId,
}) => {
  const [getCSVData, { loading }] = useLazyQuery(QUERY_DOWNLOAD_LOG, {
    variables: {
      organizationId,
      dataSourceId,
      campaignSettingId,
      feedExportId,
      additionalSourceId,
      limit: -1,
      page: 0,
      fulltext: 'download_last_30_days',
    },
    fetchPolicy: 'network-only',
    onCompleted: data => {
      if (!data || data?.collection?.auditLogs.length === 0) {
        showMessage(t('react.audit_log.actions.download_impossible'), true);
        return;
      }
      const tableData = data?.collection?.auditLogs.map(x => omit(x, ['__typename']));
      download(tableData);
      showMessage(t('react.audit_log.actions.download_success'));
    },
    onError: () => {
      showMessage(t('views.errors.error_500.heading'), true);
    },
  });

  const handleDownload = fulltext => {
    getCSVData({
      variables: {
        organizationId,
        dataSourceId,
        campaignSettingId,
        feedExportId,
        additionalSourceId,
        limit: -1,
        page: 0,
        fulltext,
      },
    });
  };

  return (
    <DropdownMenu
      labelIcon={<Icon kind="download" color="inherit" />}
      label={t('react.audit_log.actions.download')}
      data-test-id="dropdown-action-downloadCSV"
      loading={loading}
    >
      {DOWNLOAD_PERIODS.map(period => (
        <Row noPadding center key={period} className="Navigation-item mt-0">
          <Col grow onClick={() => handleDownload(period)}>
            {t(period, { scope: 'react.audit_log.actions' })}
          </Col>
        </Row>
      ))}
    </DropdownMenu>
  );
};

AuditLogDownloadDropdown.propTypes = {
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  campaignSettingId: PropTypes.number,
  additionalSourceId: PropTypes.number,
  feedExportId: PropTypes.number,
};

export default hot(AuditLogDownloadDropdown);
