/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { sortableHandle } from 'react-sortable-hoc';
import { find } from 'lodash';
import {
  ShowOnHoverWrapper,
  ShowOnHoverElement,
  Row,
  Col,
  IconSwitch,
  Ellipsis,
  Checkbox,
  ConditionsBadge,
  Link,
  DropdownMenu,
  Icon,
  TimeRestrictionBadge,
  convertConditionsAndItemGroupsToConditions,
} from '../../index';
import { t } from '../../../i18n';
import objectTypes from './objectTypes';
import Preview from './Preview';
import OptionsContext from '../OptionsContext';

const DragHandle = sortableHandle(() => <span className="DragHandle" />);

export default class Layer extends React.Component {
  static propTypes = {
    object: PropTypes.object,
    onClick: PropTypes.func,
    onObjectChange: PropTypes.func,
    removeObject: PropTypes.func,
    duplicateObject: PropTypes.func,
    selectObjects: PropTypes.func,
    selectedObjectIndex: PropTypes.func,
    selectedObjectsIndexes: PropTypes.array,
  };

  render() {
    const {
      object,
      onClick,
      onObjectChange,
      removeObject,
      duplicateObject,
      objectIndex,
      selectObjects,
      selectedObjectIndex,
      selectedObjectsIndexes: selectedObjectsIndexesTemp,
    } = this.props;
    const ObjectType = objectTypes[object.type];

    const selectedObjectsIndexes = selectedObjectsIndexesTemp || [];

    const openLayer = () => {
      if (typeof onClick === 'function') {
        onClick();
      }
    };

    const styleOnClickable = { paddingTop: '12px', paddingBottom: '12px', paddingRight: '16px', cursor: 'pointer' };
    return (
      <OptionsContext.Consumer>
        {({ conditionsOptions, customImages }) => (
          <ShowOnHoverWrapper
            className="Preview-tileItem Preview-tileItem--dragable"
            style={{ paddingTop: '0px', paddingBottom: '0px' }}
          >
            <Row center padding="n">
              <DragHandle />
              <Col width="30px" center>
                <Checkbox
                  style={{ top: '1px' }}
                  checked={selectedObjectIndex === objectIndex || selectedObjectsIndexes.indexOf(objectIndex) !== -1}
                  value={objectIndex}
                  key={object.uuid}
                  doNotUseInternalState
                  onChange={() => {
                    let selectedArray = [...selectedObjectsIndexes, selectedObjectIndex].filter(
                      x => typeof x === 'number'
                    );

                    selectedArray =
                      selectedArray.indexOf(objectIndex) === -1
                        ? [...selectedArray, objectIndex]
                        : selectedArray.filter(i => i !== objectIndex);

                    selectObjects(selectedArray);
                  }}
                />
              </Col>
              <Col width="30px" center onClick={openLayer} style={{ ...styleOnClickable, paddingLeft: '16px' }}>
                {ObjectType.meta.useIconInLayers ? (
                  <Icon kind={ObjectType.getIcon(object, customImages)} color="#596774" size="20px" />
                ) : (
                  <div style={{ width: '30px', height: '30px', overflow: 'hidden' }}>
                    <Preview
                      objects={[{ ...object, x: 0, y: 0 }]}
                      width={object.width}
                      height={object.height}
                      responsive
                    />
                  </div>
                )}
              </Col>
              <Col onClick={openLayer} style={styleOnClickable}>
                <div className="Text Text--md Text--gray">
                  <Ellipsis>
                    {find(
                      [
                        object['data-layer-name'],
                        object['dataLayerName'],
                        ObjectType.meta.description,
                        ObjectType.getDescription(object),
                      ],
                      x => typeof x === 'string'
                    )}
                  </Ellipsis>
                </div>
              </Col>
              <Col shrink onClick={openLayer} style={styleOnClickable}>
                <ConditionsBadge
                  conditions={convertConditionsAndItemGroupsToConditions({
                    conditions: object.conditions,
                    conditionsOptions,
                  })}
                />
              </Col>

              <Col shrink onClick={openLayer} style={styleOnClickable}>
                <TimeRestrictionBadge timeRestriction={object.timeRestriction} />
              </Col>

              <Col shrink className="mh-4">
                <ShowOnHoverElement show={object.lockLayer}>
                  <IconSwitch
                    onIcon="locked"
                    offIcon="unlocked"
                    id={`lockLayer-${object.uuid}`}
                    label={t(object?.lockLayer ? 'lock' : 'unlock', { scope: 'react.image_generator.layer' })}
                    doNotUseInternalState
                    value={object.lockLayer}
                    onChange={() => {
                      onObjectChange({ lockLayer: !object.lockLayer });
                    }}
                  />
                </ShowOnHoverElement>
              </Col>
              <Col shrink className="mr-4">
                <ShowOnHoverElement show={object.hideLayer}>
                  <IconSwitch
                    onIcon="disabled-eye"
                    offIcon="eye"
                    id={`hideLayer-${object.uuid}`}
                    label={t(object?.hideLayer ? 'show' : 'hide', { scope: 'react.image_generator.layer' })}
                    doNotUseInternalState
                    value={object.hideLayer}
                    onChange={() => onObjectChange({ hideLayer: !object.hideLayer })}
                  />
                </ShowOnHoverElement>
              </Col>
              <Col shrink>
                <DropdownMenu key={`dropdownMenu-${object.uuid}`}>
                  <Link icon="edit" onClick={openLayer}>
                    {t('react.image_generator.layer.edit')}
                  </Link>
                  <Link icon="duplicate" onClick={duplicateObject}>
                    {t('react.image_generator.layer.duplicate')}
                  </Link>
                  <Link icon="trash" onClick={removeObject}>
                    {t('react.image_generator.layer.delete')}
                  </Link>
                </DropdownMenu>
              </Col>
            </Row>
          </ShowOnHoverWrapper>
        )}
      </OptionsContext.Consumer>
    );
  }
}
