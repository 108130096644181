/* eslint-disable react/no-danger */
import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import cs from 'classnames';
import { hot } from 'react-hot-loader/root';
import { snakeCase, omitBy, groupBy } from 'lodash';
import ConditionalWrapper from '../ConditionalWrapper';
import { ArrowWrapper } from './Arrows';
import { ConditionBadgeContent, HtmlCellContent, StatusSwitchContent } from '../table/mapping/cells';
import {
  DropdownMenu,
  Badge,
  Button,
  Link,
  Icon,
  Ellipsis,
  Tooltip,
  UsedByGraph,
  Row,
  Col,
  Text,
  cssVariables,
  SectionHeading,
} from '../index';
import { EditableWrapper } from '../table/mapping/wrappers';
import { ListComponent } from './List';
import { modalDataUrlOpener, listenOnModalChange } from '../RemoteModal';
import { QueryHolder } from '../QueryHolder';
import { t, tHtml, formatPercentage, formatNumberInflection } from '../../i18n';

export const SIZES = {
  first: 256,
  second: 272,
  last: 146,
};

const TimeRestrictionSwitch = EditableWrapper(StatusSwitchContent);

const getUnusedFeaturesTooltip = (allFeatures, name) => {
  const unUsedFeatures = omitBy(allFeatures, x => x?.name === name);
  return Object.keys(unUsedFeatures)
    .map(
      x =>
        `${unUsedFeatures[x]?.name} (${t(unUsedFeatures[x]?.status === 'running' ? 'queue' : 'paused', {
          scope: 'react.fb_manual_structure.un_used_features',
        })})`
    )
    .join('</br>');
};

export class FeatureLine extends React.Component {
  static propTypes = {
    additionalDescritpion: PropTypes.string,
    allFeatures: PropTypes.array,
    data: PropTypes.object,
    editLink: PropTypes.string,
    icon: PropTypes.string,
    iconTooltip: PropTypes.string,
    translationKey: PropTypes.string,
  };

  render() {
    const {
      icon,
      iconTooltip,
      data,
      additionalDescritpion,
      allFeatures = [],
      translationKey,
      editLink,
      itemGroups,
      ...rest
    } = this.props;
    const tooltip = getUnusedFeaturesTooltip(allFeatures, data?.name);

    if (!data) {
      return (
        <Row center>
          <Link
            danger
            {...(editLink && modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' }))}
            data-test-id="feature-budget"
          >
            <Icon kind={icon} inheritColor size="15px" className="mr-8" />
            {t(translationKey, { scope: 'react.fb_manual_campaign_preview' })}
          </Link>
        </Row>
      );
    }

    const { name, description } = data;
    const allFeaturesCount = allFeatures.length - 1;

    return (
      <Row>
        <Col grow {...rest}>
          <Row top>
            <Col shrink>
              <Row shrink top className="text-wrap">
                <Col shrink>
                  <Tooltip text={iconTooltip}>
                    <Icon kind={icon} color={cssVariables.grapePrimary} size="15px" />
                  </Tooltip>
                </Col>
                <Col grow>
                  <Ellipsis style={{ maxWidth: '380px' }}>
                    <Link
                      {...(data.editLink && modalDataUrlOpener({ url: data.editLink, size: 'fullwithGrid' }))}
                      className="mr-8"
                    >
                      {name}
                    </Link>
                  </Ellipsis>
                </Col>
              </Row>
              {data.conditions && data.conditions.length > 0 && (
                <Row>
                  <Col shrink>
                    <span
                      className="cursor-pointer"
                      {...modalDataUrlOpener({ url: data.editLink, size: 'fullwithGrid' })}
                    >
                      <ConditionBadgeContent itemGroups={itemGroups}>{data.conditions}</ConditionBadgeContent>
                    </span>
                  </Col>
                </Row>
              )}
            </Col>
            <Col grow className="align-self-start">
              <Ellipsis>
                <Text color="gray" size="sm">
                  {additionalDescritpion && <span>{additionalDescritpion} </span>}
                  {description}
                </Text>
              </Ellipsis>
            </Col>
            {data?.timeRestriction?.text && (
              <Col shrink>
                <Badge kind="lightGray" size="medium" className="text-center ml-8">
                  <HtmlCellContent>{data?.timeRestriction?.text}</HtmlCellContent>
                </Badge>
              </Col>
            )}
            {allFeaturesCount > 0 && (
              <Col shrink>
                <Tooltip text={tooltip}>
                  <Badge kind="lightGray" size="medium" uppercase className="text-center ml-8">
                    <Link href={data.indexLink} noUnderline data-test-id="inactive-badge">
                      {t('react.fb_manual_structure.inactive', { count: allFeaturesCount })}
                    </Link>
                  </Badge>
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    );
  }
}

const ProductGroupAssets = ({
  activeFbAdtexts,
  activeFbAudience,
  activeFbBudget,
  activeFbOptimization,
  activeFbPlacement,
  assignedFbAudiences,
  assignedFbBudgets,
  assignedFbOptimizations,
  assignedFbPlacements,
  assignedFbAdtexts,
  budgetType,
  editLink,
}) => {
  const assignedFbAdtextsByGroup = groupBy(assignedFbAdtexts, 'group');
  const activeFbAdtextsByGroup = groupBy(activeFbAdtexts, 'group');
  return (
    <Col>
      {budgetType === 'ad_set_budget' && (
        <FeatureLine
          editLink={editLink}
          data={activeFbBudget}
          allFeatures={assignedFbBudgets}
          icon="budget"
          translationKey="missing_fbbudgets"
          data-test-id="feature-budget"
        />
      )}
      <FeatureLine
        editLink={editLink}
        data={activeFbAudience}
        allFeatures={assignedFbAudiences}
        icon="users-group"
        translationKey="missing_fbaudiences"
        data-test-id="feature-audience"
      />
      <FeatureLine
        editLink={editLink}
        data={activeFbPlacement}
        allFeatures={assignedFbPlacements}
        icon="placement"
        translationKey="missing_fbplacements"
        data-test-id="feature-placement"
      />
      <FeatureLine
        editLink={editLink}
        data={activeFbOptimization}
        allFeatures={assignedFbOptimizations}
        icon="option"
        translationKey="missing_fboptimizations"
        data-test-id="feature-optimization"
      />
      {Object.keys(assignedFbAdtextsByGroup).map(
        group =>
          activeFbAdtextsByGroup[group]?.[0] && (
            <FeatureLine
              key={group}
              editLink={editLink}
              data={activeFbAdtextsByGroup[group]?.[0]}
              allFeatures={assignedFbAdtextsByGroup[group]}
              icon={activeFbAdtextsByGroup[group]?.[0].useDynamicFormat ? 'dynamic-fb-ad' : 'single-fb-ad'}
              iconTooltip={
                activeFbAdtextsByGroup[group]?.[0].useDynamicFormat
                  ? t('react.fb_manual_structure.dynamic_ad_tooltip')
                  : t('react.fb_manual_structure.single_carousel_ad_tooltip')
              }
              additionalDescritpion={
                activeFbAdtextsByGroup[group]?.[0].useDynamicFormat
                  ? t('react.fb_manual_structure.dynamic_ad')
                  : t('react.fb_manual_structure.single_carousel_ad')
              }
              data-test-id={`feature-adtext-${activeFbAdtextsByGroup[group]?.[0].name}`}
            />
          )
      )}
      {assignedFbAdtexts.length === 0 && (
        <FeatureLine
          editLink={editLink}
          icon="adtext"
          translationKey="missing_fbadtexts"
          data-test-id="feature-adtext-missing"
        />
      )}
    </Col>
  );
};

ProductGroupAssets.propTypes = {
  activeFbAdtexts: PropTypes.object,
  activeFbAudience: PropTypes.object,
  activeFbBudget: PropTypes.object,
  activeFbOptimization: PropTypes.object,
  activeFbPlacement: PropTypes.object,
  assignedFbAudiences: PropTypes.array,
  assignedFbBudgets: PropTypes.array,
  assignedFbOptimizations: PropTypes.array,
  assignedFbPlacements: PropTypes.array,
  assignedFbAdtexts: PropTypes.array,
  budgetType: PropTypes.oneOf(['ad_set_budget', 'campaign_budget']),
  editLink: PropTypes.string,
};

export class GroupedList extends React.Component {
  static propTypes = {
    addProductGroupResource: PropTypes.object,
    addResource: PropTypes.object,
    budgetType: PropTypes.string,
    canEdit: PropTypes.bool,
    collectionKey: PropTypes.string,
    data: PropTypes.object,
    getGraphqlObject: PropTypes.string,
    refetch: PropTypes.func,
    unusedItemText: PropTypes.string,
    updateGraphqlObject: PropTypes.string,
    statusCampaignSwitchWarning: PropTypes.bool,
  };

  componentDidMount() {
    this.refreshOnUpdateListener = listenOnModalChange(this.refreshOnUpdate);
    this.mounted = true;
  }

  componentWillUnmount() {
    if (this.refreshOnUpdateListener) {
      this.refreshOnUpdateListener();
    }
    this.mounted = false;
  }

  refreshOnUpdate = e => {
    if (typeof this.props.refetch === 'function') {
      if (!e || snakeCase(this.props.collectionKey).indexOf(e) !== -1) {
        this.props.refetch();
        if (typeof window.startLoadingPreview === 'function') {
          window.startLoadingPreview();
        }
      }
    }
  };

  mounted = false;

  render() {
    const {
      addProductGroupResource,
      addResource,
      budgetType,
      canEdit,
      collectionKey,
      data,
      getGraphqlObject,
      refetch,
      updateGraphqlObject,
      statusCampaignSwitchWarning,
    } = this.props;
    const collection = data?.collection ? data?.collection[collectionKey] : [];
    const productsTotal = data?.organization?.campaignSetting?.itemsCount;
    const hadData = collection && collection.length > 0;
    const itemGroups = data?.collection?.itemGroups || [];

    if (collection.length > 0 && collection.find(campaign => (campaign.fbProductGroups || []).length > 0)) {
      // eslint-disable-next-line no-unused-expressions
      document.querySelector('.js-fb-hierarchy-save-button')?.removeAttribute('disabled');
    } else {
      // eslint-disable-next-line no-unused-expressions
      document.querySelector('.js-fb-hierarchy-save-button')?.setAttribute('disabled', '');
    }

    return (
      <div>
        <div className="Sticky-Wrapper Sticky-Wrapper--border">
          <table className="Sticky-Table Sticky-Table--withoutBorder">
            <thead>
              <tr className="Sticky-Header">
                <th width={`${SIZES.first}px`} className="pl-16">
                  {t('facebook_campaigns_ad_sets', { scope: 'react.fb_manual_structure' })}
                </th>
                <th width={`${SIZES.second}px`} />
                <th>{t('budget_features', { scope: 'react.fb_manual_structure' })}</th>
                <th width={`${SIZES.last}px`} />
              </tr>
            </thead>
            {hadData && (
              <tbody className="Sticky-Body">
                {collection.map(x => (
                  <React.Fragment key={x.id}>
                    <tr
                      {...(x?.editLink && modalDataUrlOpener({ url: x?.editLink, size: 'fullwithGrid' }))}
                      data-test-id={`campaign-row-${x.name}`}
                      className="cursor-pointer noHover"
                    >
                      <td className="Sticky-Cell pa-0" colSpan={4}>
                        <Row className="mt-16 mr-16 ml-8">
                          <Col width={`${SIZES.first - 8 - 1}px`} noPadding className="ma-0 pl-8">
                            <IconLink
                              {...x}
                              icon="fb-campaign"
                              isLargeIcon
                              description={formatNumberInflection({
                                count: x?.fbProductGroups?.length,
                                scope: 'react.fb_manual_structure.adsets',
                                zeroIsSpecial: true,
                              })}
                            />
                          </Col>
                          <Col width={`${SIZES.second + 1}px`} className="ma-0 pa-8" />

                          {budgetType === 'campaign_budget' ? (
                            <Col grow noPadding className="ma-0 pa-8" data-test-id="campaign-budget">
                              <FeatureLine
                                data={x.activeFbBudget}
                                productsTotal={productsTotal}
                                allFeatures={x.assignedFbBudgets}
                                icon="budget"
                                translationKey="missing_fbbudgets"
                                data-test-id="feature-budget"
                              />
                            </Col>
                          ) : (
                            <Col grow />
                          )}
                          <ActionCell
                            {...x}
                            newBranchName={undefined}
                            getGraphqlObject={getGraphqlObject}
                            updateGraphqlObject={updateGraphqlObject}
                            refetch={refetch}
                            canEdit={canEdit}
                            statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                          />
                        </Row>
                      </td>
                    </tr>
                    <tr className="noHover" data-test-id={`adset-line-${x.name}`}>
                      <td className="Sticky-Cell delimiter" colSpan={4}>
                        <ListComponent
                          disableFunel
                          disableNewBranch
                          useRemainingProducts
                          {...this.props}
                          data={{
                            ...data,
                            organization: {
                              ...data.organization,
                              campaignSetting: {
                                ...data.organization.campaignSetting,
                                itemsCount: productsTotal,
                              },
                            },
                            collection: {
                              fbProductGroups: (x?.fbProductGroups || []).map(l => ({
                                ...l,
                                children: <ProductGroupAssets {...l} budgetType={budgetType} />,
                              })),
                            },
                            itemGroups,
                          }}
                          updateGraphqlObject="updateFbProductGroup"
                          getGraphqlObject="fbProductGroup"
                          collectionKey="fbProductGroups"
                          otherBranches={collection
                            .filter(c => c.id !== x?.id)
                            .map(c => ({
                              linkParams: `fb_product_group[fb_product_campaign_id]=${c.id}`,
                              branch: c.name,
                            }))}
                          newManualCampaign
                          addResource={
                            addProductGroupResource && {
                              ...addProductGroupResource,
                              useGroup: false,
                              url: `${
                                addProductGroupResource.url
                              }?insert_last=true&fb_product_campaign_id=${encodeURIComponent(x?.id)}`,
                            }
                          }
                          statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            )}
          </table>
          {addResource && (
            <React.Fragment>
              {hadData && <div className="delimiter" />}
              <div className="ma-16">
                <Button
                  kind={hadData ? 'secondary' : 'primary'}
                  data-toggle={addResource.toggle}
                  data-url={addResource.url}
                  data-test-id={addResource.test_id}
                  data-notify-on-change={addResource.notify_on_change}
                  data-size={addResource.size}
                  block
                  icon="fb-campaign"
                >
                  {addResource.button_text}
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

export const ActionCell = ({
  canEdit,
  deleteLink,
  duplicateLink,
  editLink,
  getGraphqlObject,
  id,
  name,
  newBranchName,
  otherBranches,
  refetch,
  status,
  timeRestrictionStatus,
  updateGraphqlObject,
  updateLink,
  statusCampaignSwitchWarning,
}) => (
  <Row shrink center padding="m" width={`${SIZES.last - 16}px`} className="ma-0">
    {editLink && (
      <Col shrink>
        <Button
          tertiary
          onlyIcon
          tag="a"
          href="#"
          icon="edit"
          {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}
        />
      </Col>
    )}
    {(editLink || duplicateLink || deleteLink || updateLink) && (
      <Col shrink>
        <DropdownMenu dataTestId={`dropdown-action-${name}`}>
          {editLink && (
            <Link href="#" data-test-id-edit={name} {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}>
              <Icon kind="edit" inheritColor className="pr-8" />
              <span>{t('react.fb_campaign_preview.edit')}</span>
            </Link>
          )}
          {updateLink &&
            (otherBranches || []).map(({ linkParams, branch }) => (
              <Link
                key={branch}
                href={`${updateLink}?${linkParams}`}
                data-method="PATCH"
                data-remote="true"
                data-container="body"
                onClick={() => document.body.click()}
                data-test-id-move-item={name}
                data-test-id-move-branch={branch}
                rel="nofollow"
              >
                <Icon kind="arrow-right" inheritColor className="pr-8" />
                <span>
                  {tHtml('react.fb_campaign_preview.move_to_html', {
                    branch,
                    default: 'Move to <b>%{branch}</b>',
                  })}
                </span>
              </Link>
            ))}
          {updateLink && newBranchName && (
            <Link
              key={newBranchName}
              href={`${updateLink}?fb_adtext[group]=${encodeURIComponent(newBranchName)}`}
              data-method="PATCH"
              data-remote="true"
              data-container="body"
              onClick={() => document.body.click()}
              data-test-id-move-item={name}
              data-test-id-move-branch={newBranchName}
              rel="nofollow"
            >
              <Icon kind="arrow-right" inheritColor className="pr-8" />
              <span>
                {tHtml('react.fb_campaign_preview.move_to_new_html', {
                  branch: newBranchName,
                  default: 'Move to new <b>%{branch}</b>',
                })}
              </span>
            </Link>
          )}
          {duplicateLink && (
            <Link
              href="#"
              data-test-id-duplicate={name}
              {...modalDataUrlOpener({ url: duplicateLink, size: 'fullwithGrid' })}
            >
              <Icon kind="duplicate" inheritColor className="pr-8" />
              <span>{t('react.fb_campaign_preview.duplicate')}</span>
            </Link>
          )}
          {deleteLink && (
            <Link
              danger
              href={deleteLink}
              data-method="delete"
              data-remote="true"
              data-confirm={t('react.fb_campaign_preview.delete_confirmation', { name })}
              data-commit={t('views.campaign_settings.indeyes', { default: 'Yes' })}
              data-container="body"
              data-test-id-delete={name}
              rel="nofollow"
            >
              <Icon kind="trash" inheritColor className="pr-8" />
              <span>{t('react.fb_campaign_preview.delete')}</span>
            </Link>
          )}
        </DropdownMenu>
      </Col>
    )}
    <Col grow>
      <TimeRestrictionSwitch
        canEdit={canEdit}
        onUpdate={refetch}
        editQuery={({ enabled }) =>
          gql`mutation { ${updateGraphqlObject}(id: ${id}, status: "${
            enabled ? 'running' : 'paused'
          }") { ${getGraphqlObject} { id status } errors } }`
        }
        statusCampaignSwitchWarning={statusCampaignSwitchWarning}
      >
        {{ enabled: status === 'running', timeRestrictionStatus }}
      </TimeRestrictionSwitch>
    </Col>
  </Row>
);

ActionCell.propTypes = {
  canEdit: PropTypes.bool,
  deleteLink: PropTypes.string,
  duplicateLink: PropTypes.string,
  editLink: PropTypes.string,
  getGraphqlObject: PropTypes.string,
  id: PropTypes.number,
  name: PropTypes.string,
  newBranchName: PropTypes.string,
  otherBranches: PropTypes.array,
  refetch: PropTypes.func,
  status: PropTypes.string,
  timeRestrictionStatus: PropTypes.string,
  updateGraphqlObject: PropTypes.string,
  updateLink: PropTypes.string,
};

export const ProductsCell = ({
  children,
  conditions,
  dataSourceStillLoading,
  numberOfProducts,
  productsTotal,
  status,
  timeRestrictionStatus,
  unusedItemText,
  useArrow = false,
  editLink,
}) => {
  if (numberOfProducts === 0 && timeRestrictionStatus !== 'restricted' && status !== 'paused') {
    return (
      <div style={{ paddingLeft: '7px' }} data-test-id="unused-item">
        <Tooltip text={unusedItemText}>
          <Row center>
            <ConditionalWrapper condition={useArrow} wrapper={child => <ArrowWrapper>{child}</ArrowWrapper>}>
              <Icon size="24px" kind="graph-error" color="red" />
            </ConditionalWrapper>
            <Col className="ml-8">
              <Row>{children}</Row>
              {conditions && conditions.length > 0 && (
                <Row>
                  <span
                    className="ml-8 cursor-pointer"
                    {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}
                  >
                    <ConditionBadgeContent>{conditions}</ConditionBadgeContent>
                  </span>
                </Row>
              )}
            </Col>
          </Row>
        </Tooltip>
      </div>
    );
  }
  return (
    <Row shrink center className="mw-100">
      <ConditionalWrapper condition={useArrow} wrapper={child => <ArrowWrapper>{child}</ArrowWrapper>}>
        <UsedByGraph
          size="lg"
          products={numberOfProducts}
          productsTotal={productsTotal}
          doNotShowPercent={dataSourceStillLoading}
        />
      </ConditionalWrapper>
      <Col className="ml-8">
        <Row>{children}</Row>
        <Row>
          <Text data-test-id="number-of-products">
            {numberOfProducts}{' '}
            {formatNumberInflection({
              count: numberOfProducts,
              scope: 'remap_variables.products',
            })}{' '}
            ({formatPercentage(productsTotal > 0 ? (numberOfProducts / productsTotal) * 100 : 0)})
          </Text>
        </Row>
      </Col>

      {conditions && (
        <span
          className="ml-8 cursor-pointer text-nowrap"
          {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}
          data-test-id="conditions-badge"
        >
          <ConditionBadgeContent>{conditions}</ConditionBadgeContent>
        </span>
      )}
    </Row>
  );
};

ProductsCell.propTypes = {
  children: PropTypes.any,
  conditions: PropTypes.array,
  dataSourceStillLoading: PropTypes.bool,
  editLink: PropTypes.string,
  numberOfProducts: PropTypes.number,
  productsTotal: PropTypes.number,
  status: PropTypes.string,
  timeRestrictionStatus: PropTypes.string,
  unusedItemText: PropTypes.string,
  useArrow: PropTypes.bool,
};

export const IconLink = ({ name, icon, isLargeIcon, description, editLink, spaced }) => (
  <Row center>
    <Col shrink>
      <Icon kind={icon} color={cssVariables.grapePrimary} size={isLargeIcon ? '24px' : '20px'} />
    </Col>
    <Col noPadding className={cs({ 'ml-8': spaced })}>
      <Row>
        <Link
          href="#"
          {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}
          data-test-id="icon-link-name"
          className="mw-100"
        >
          {isLargeIcon ? (
            <SectionHeading className="Heading--inheritColor" spacing={0}>
              <Ellipsis>{name}</Ellipsis>
            </SectionHeading>
          ) : (
            <Ellipsis>{name}</Ellipsis>
          )}
        </Link>
      </Row>
      <Row>
        <Ellipsis>
          <Text color="gray" size="sm" data-test-id="icon-link-description">
            {description}
          </Text>
        </Ellipsis>
      </Row>
    </Col>
  </Row>
);

IconLink.propTypes = {
  description: PropTypes.string,
  editLink: PropTypes.string,
  icon: PropTypes.string,
  isLargeIcon: PropTypes.bool,
  name: PropTypes.string,
  spaced: PropTypes.bool,
};
const QueryGroupedList = QueryHolder(GroupedList);

class FBManualCampaigns extends PureComponent {
  static propTypes = {
    addProductGroupResource: PropTypes.object,
    addResource: PropTypes.object,
    adSetCount: PropTypes.number,
    campaignSettingId: PropTypes.number,
    collectionKey: PropTypes.string,
    organizationId: PropTypes.number,
    updateGraphqlObject: PropTypes.string,
  };

  render() {
    const { collectionKey, campaignSettingId, organizationId } = this.props;

    const query = gql`
      query FBFeatureQueryManualCampaigns${collectionKey}($organizationId: BigInt!, $campaignSettingId: BigInt) {
        organization(id: $organizationId) {
          id
          campaignSetting(id: $campaignSettingId) {
            id
            itemsCount: itemsCountLive
            adSetCount
            dataSource {
              id
              isFirstLoading
            }
          }
        }
        collection(identifier: "FBManualCampaigns${collectionKey}", limit: 100000, page: 0, organizationId: $organizationId, campaignSettingId: $campaignSettingId) {
          id
          ${collectionKey} {
            id
            name
            position
            numberOfProducts
            numberOfAdSets
            description
            status
            editLink
            duplicateLink
            deleteLink
            activeFbBudget {
              name
              editLink
              indexLink
              period
              budget
              description
              __typename
            }
            assignedFbBudgets {
              name
              status
            }
            timeRestriction {
              text
            }
            timeRestrictionStatus
            fbProductGroups {
              id
              name
              position
              numberOfProducts
              numberOfAdSets
              description
              status
              editLink
              duplicateLink
              updateLink
              deleteLink
              timeRestriction {
                text
              }
              timeRestrictionStatus
              assignedFbAdtexts{
                name
                group
                status
                __typename
              }
              activeFbAdtexts {
                name
                editLink
                group
                indexLink
                description
                useDynamicFormat
                timeRestriction {
                  text
                }
              }
              fbProductSet {
                name
                conditions { toDataPreview }
                editLink
                indexLink
                description
              }
              activeFbBudget {
                name
                editLink
                indexLink
                period
                budget
                description
                timeRestriction {
                text
                }
                __typename
              }
              assignedFbBudgets {
                name
                status
              }
              activeFbOptimization {
                name
                editLink
                indexLink
                description
                timeRestriction {
                text
                }
                __typename
              }
              assignedFbOptimizations {
                name
                status
              }
              activeFbPlacement {
                name
                editLink
                indexLink
                description
                timeRestriction {
                text
                }
                __typename
              }
              assignedFbPlacements {
                name
                status
              }
              activeFbAudience {
                name
                editLink
                indexLink
                description
                timeRestriction {
                text
                }
                __typename
              }
              assignedFbAudiences {
                name
                status
              }
            }
          }
          itemGroups {
            id,
            name
          }
        }
      }
    `;

    return (
      <QueryGroupedList
        loaderSize="big"
        {...this.props}
        query={query}
        variables={{ organizationId, campaignSettingId }}
      />
    );
  }
}

export default hot(FBManualCampaigns);
