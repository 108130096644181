import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, NetworkStatus } from '@apollo/client';
import SmartSelect from '../SmartSelect';
import { Ellipsis, Button, Row, Col } from '../../index';

export const Asset = ({ name, url }) => (
  <React.Fragment>
    <Row center>
      <Col shrink>
        <img src={url} alt={name} />
      </Col>
      <Col grow>
        <Ellipsis>{name}</Ellipsis>
      </Col>
    </Row>
  </React.Fragment>
);
Asset.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
};

const FB_AD_IMAGE_QUERY = gql`
  query($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        fbAdImages {
          id
          name
          url
        }
      }
    }
  }
`;

const FBAdImageSelect = memo(({ organizationId, campaignSettingId, value, ...rest }) => {
  const [findOptions, { refetch, error, data, called, networkStatus }] = useLazyQuery(FB_AD_IMAGE_QUERY, {
    variables: { organizationId, campaignSettingId },
    notifyOnNetworkStatusChange: true,
  });

  const collection = [];
  // ensure that selected image is allways added as option
  if (value.length > 0) {
    const { id, url, name } = value.startsWith('{') ? JSON.parse(value) : {};
    collection.push({ value, originalLabel: name, label: <Asset id={id} name={name} url={url} /> });
  }

  const loading = networkStatus !== NetworkStatus.ready && !error;
  const asyncOptions = (
    (data && data.organization && data.organization.campaignSetting && data.organization.campaignSetting.fbAdImages) ||
    []
  )
    .map(asset => {
      const optionValue = JSON.stringify({ id: asset.id, url: asset.url, name: asset.name });
      if (collection.find(c => c.value === optionValue)) {
        return null;
      }

      return {
        value: optionValue,
        originalLabel: asset.name,
        label: <Asset {...asset} />,
      };
    })
    .filter(x => !!x);

  if (value.length > 0 && !called) {
    findOptions();
  }

  let errorElement = null;
  if (error) {
    let errorMessage = null;
    try {
      errorMessage = error.networkError.result.error.message;
    } catch (e) {
      errorMessage = error.message;
    }

    errorElement = (
      <span className="ValidationError">
        {errorMessage}
        <a className="Link ml-8" onClick={() => refetch()}>
          {window.reacti18n.pla_campaign_preview.try_again}
        </a>
      </span>
    );
  }

  return (
    <React.Fragment>
      <Row center>
        <Col grow>
          <SmartSelect
            className="SmartSelect--imageSelector"
            isLoading={loading}
            asyncOptions={asyncOptions}
            onMenuOpen={() => !called && findOptions()}
            value={value}
            asyncLoadOptions
            {...rest}
            collection={collection}
          />
        </Col>
        {refetch && (
          <Col shrink>
            <Button tertiary onlyIcon icon="refresh" onClick={() => refetch()} />
          </Col>
        )}
      </Row>
      {errorElement}
    </React.Fragment>
  );
});

FBAdImageSelect.propTypes = {
  organizationId: PropTypes.number,
  campaignSettingId: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default FBAdImageSelect;
