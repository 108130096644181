/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class TourLocation extends React.PureComponent {
  static propTypes = {
    area: PropTypes.string,
    country: PropTypes.string,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    place: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    country: this.props.country || '',
    area: this.props.area || '',
    place: this.props.place || '',
  };

  handleCountry = ({ target: { value } }) => {
    if (value !== this.state.country) {
      this.setState({ country: value });
      this.props.updateFieldData(this.props.id, { country: value });
      updatePreview();
    }
  };

  handleArea = ({ target: { value } }) => {
    if (value !== this.state.area) {
      this.setState({ area: value });
      this.props.updateFieldData(this.props.id, { area: value });
      updatePreview();
    }
  };

  handlePlace = ({ target: { value } }) => {
    if (value !== this.state.place) {
      this.setState({ place: value });
      this.props.updateFieldData(this.props.id, { place: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { place, area, country } = this.state;

    return (
      <Row center padding="l" data-test-id="tour_locations" className="mb-8">
        <Col width="12%" data-test-id="tour_locations-country" className="mr-8">
          <PlaceholderInput
            defaultValue={country}
            onChange={this.handleCountry}
            placeholder={t('views.tour_locations.fields.country_id', { default: 'Country ID' })}
            {...getSubFiledOptions('country')}
          />
        </Col>
        <Col grow data-test-id="tour_locations-area" style={{ maxWidth: '19%' }} className="mr-8">
          <PlaceholderInput
            defaultValue={area}
            onChange={this.handleArea}
            placeholder={t('views.tour_locations.fields.area_title', { default: 'Area title' })}
            {...getSubFiledOptions('area')}
          />
        </Col>
        <Col grow data-test-id="tour_locations-place">
          <PlaceholderInput
            defaultValue={place}
            onChange={this.handlePlace}
            placeholder={t('views.tour_locations.fields.place_title', { default: 'Place title' })}
            {...getSubFiledOptions('place')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.tour_locations.example')} <span class="Text--bold">${t(
            'views.tour_locations.example_text'
          )}</span>`}
          data-field={`tour_locations.${country}`}
        >
          <div className="Text">
            {t('views.tour_locations.example')}{' '}
            <span className="Text--bold">{t('views.tour_locations.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default TourLocation;
