import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button, Ellipsis, Row, Col, Text, Tile, Icon, Tooltip } from '../../../../index';
import { t } from '../../../../../i18n';

const ImageTile = ({
  disabled = false,
  svgPreview,
  isDeleteLoading,
  label,
  onSelect,
  onDelete,
  disabledMessage,
  resolution,
  imgUrl,
  imageHeight,
}) => (
  <Tile
    className={cs('Tile--imageWithButton')}
    disabled={disabled}
    fullHeight
    hoverable
    data-test-id="insert-image-tile"
  >
    <div className="pos-relative">
      {svgPreview && (
        <div
          className="Tile-image mb-16"
          style={{ height: imageHeight ? `${imageHeight}` : 'auto' }}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: svgPreview.replaceAll('js-image-gen-fit-text', '').replaceAll('js-image-gen-shorten-text', ''),
          }}
        />
      )}

      {imgUrl && (
        <div className="Tile-image Tile-image--preview mb-16">
          <img src={imgUrl} alt={label} />
        </div>
      )}

      {!svgPreview && !imgUrl && (
        <Row className="Tile-image Tile-image--preview mb-16 background-ultra-soft-gray" center justifyCenter>
          <Icon kind="empty-image" size="32px" color="#CCD0D4" />
        </Row>
      )}

      <div className="Tile-buttonWrapper pos-absolute align-center">
        <Button onClick={onSelect} primary size="large" data-test-id="insert-image-button">
          {t('react.image_generator.modal.insert_button')}
        </Button>
      </div>

      {onDelete && (
        <div className="Tile-buttonWrapper pos-absolute" style={{ top: 0, right: 0 }}>
          <Tooltip text={t('react.image_generator.modal.delete_button_tooltip')}>
            <Button
              kind="red"
              icon="trash"
              onlyIcon
              onClick={onDelete}
              primary
              size="small"
              data-test-id="delete-image-button"
              disabled={isDeleteLoading}
            />
          </Tooltip>
        </div>
      )}
    </div>
    <div className="delimiter negative-mh-16" />

    <Row grow className="mt-16" center>
      <Text bold className="mw-100 overflow-wrap-break-word">
        {label}
      </Text>
    </Row>

    {resolution && (
      <Row shrink className="mt-8">
        <Col shrink>
          <Icon color="#596774" kind="ig-carousel-ads" size="14px" />
        </Col>
        <Col grow>
          <Text color="gray" size="sm">
            <Ellipsis>{resolution}</Ellipsis>
          </Text>
        </Col>
      </Row>
    )}

    {disabled && disabledMessage && (
      <div className="ValidationError mt-8">
        <Ellipsis>{disabledMessage}</Ellipsis>
      </div>
    )}
  </Tile>
);

ImageTile.propTypes = {
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  imgUrl: PropTypes.string,
  isDeleteLoading: PropTypes.bool,
  label: PropTypes.string,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  resolution: PropTypes.string,
  svgPreview: PropTypes.string,
};

export default ImageTile;
