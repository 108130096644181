import React from 'react';
import { t } from '../../../i18n';
import { Button, cssVariables } from '../../index';
import { DateTimeCell, GenericCell, LinkContent, MainIdentifierSystemCell, StateCell } from './cells';
import { setupColumn } from './setupColumn';

export const CUSTOM_STATES = {
  error: {
    badgeKind: 'lightRed',
    icon: 'fill-error',
    iconColor: cssVariables.statusAttentionDefault,
    text: t('react.tables.account_accesses.status.connection_error', { default: 'Connection Error' }),
    textColor: cssVariables.statusAttentionBold,
  },
  warning: {
    badgeKind: 'lightRed',
    icon: 'fill-error',
    iconColor: cssVariables.statusAttentionDefault,
    text: t('react.tables.account_accesses.status.connection_warning', { default: 'Can not be used' }),
    textColor: cssVariables.statusAttentionBold,
  },
  active: {
    badgeKind: 'lightGreen',
    icon: 'active',
    iconColor: cssVariables.statusPositiveDefault,
    text: t('react.tables.account_accesses.status.connected', { default: 'Connected' }),
    textColor: cssVariables.statusPositiveBold,
  },
};

const mapping = {
  ...setupColumn({
    id: 'account',
    order: 'system',
    graphqlFields: ['email', 'id', 'showLink', 'reconnectLink', 'refreshLink', 'deleteLink', 'system'],
    required: true,
    cell: MainIdentifierSystemCell,
    fixed: 'left',
    width: '300px',
    resolveChildren: ({ id, email, showLink, reconnectLink, refreshLink, deleteLink, system }) => ({
      id,
      system,
      size: '20px',
      name: email,
      text: email,
      link: showLink,
      'data-test-id': `dropdown-action-${email}`,
      links: [
        {
          href: reconnectLink,
          children:
            system === 'sklik'
              ? t('react.tables.account_accesses.edit')
              : t('react.tables.account_accesses.reconnect', { default: 'Reconnect' }),
          'data-test-id-edit': email,
          icon: system === 'sklik' ? 'edit' : 'refresh',
        },
        {
          href: refreshLink,
          children: t('react.tables.account_accesses.refresh'),
          'data-test-id-refresh': email,
          icon: 'repeat',
        },
        {
          href: deleteLink,
          children: t('react.tables.account_accesses.delete'),
          danger: true,
          icon: 'trash',
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('react.tables.account_accesses.delete_confirmation', { email }),
          'data-commit': t('react.tables.account_accesses.confirm', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': email,
          rel: 'nofollow',
        },
      ],
    }),
    header: () => t('react.tables.account_accesses.name'),
  }),
  ...setupColumn({
    id: 'state',
    graphqlFields: ['alertErrorName', 'alert', 'alertMessage'],
    cell: StateCell,
    header: () => t('react.tables.account_accesses.status.header'),
    resolveChildren: ({ alert }) => ({
      state: alert ? 'error' : 'active',
      customIcons: CUSTOM_STATES,
      error: alert ? t('react.tables.account_accesses.error_reconnect') : null,
    }),
  }),
  ...setupColumn({
    id: 'connectedBy',
    graphqlFields: 'user {email}',
    cell: GenericCell,
    header: () => t('react.tables.account_accesses.connected_by'),
    resolveChildren: ({ user }) => {
      if (user?.email) {
        return (
          <LinkContent>
            {{
              text: user?.email || '-',
              link: user?.email ? `mailto:${user?.email}` : null,
            }}
          </LinkContent>
        );
      }

      return '-';
    },
  }),
  ...setupColumn({
    id: 'createdAt',
    order: 'createdAt',
    cell: DateTimeCell,
    header: () => t('react.tables.account_accesses.connected_date'),
    resolveChildren: ({ createdAt }) => createdAt,
  }),
  // Temporary hidden - waiting for subAccessAccountsCount be repaired
  // ...setupColumn({
  //   id: 'subAccessAccountsCount',
  //   order: 'subAccessAccountsCount',
  //   orderedByDescFirst: true,
  //   graphqlFields: ['showLink', 'subAccessAccountsCount'],
  //   cell: TableButtonPillCell,
  //   header: () => t('react.tables.account_accesses.sub_accounts'),
  //   resolveChildren: ({ subAccessAccountsCount, showLink }) => ({
  //     count: subAccessAccountsCount,
  //     icon: 'hierarchy',
  //     showNull: true,
  //     href: showLink
  //   }),
  // }),
  ...setupColumn({
    id: 'actions',
    orderedByDescFirst: true,
    graphqlFields: 'reconnectLink',
    cell: GenericCell,
    header: () => t('react.tables.account_accesses.actions'),
    // eslint-disable-next-line react/prop-types
    resolveChildren: ({ reconnectLink, alert }) =>
      alert && !!reconnectLink ? (
        <Button
          primary
          icon="refresh"
          tag="a"
          href={reconnectLink}
          text={t('react.tables.account_accesses.reconnect')}
        />
      ) : null,
  }),
};

export default mapping;
