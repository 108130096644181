import React, { useState, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import { InfoBox } from '../components';
import { t } from '../i18n';
import useHiddenInputsObserver from '../hooks/useHiddenInputsObserver';

const GET_FEED_MAPPING_QUERY = gql`
  query GetFeedMapping($organizationId: BigInt!, $feedMappingId: BigInt!) {
    organization(id: $organizationId) {
      feedMapping(id: $feedMappingId) {
        dataSource {
          tableVariablesSorted {
            isImageUrl
            placeholderName
            imageGen {
              id
            }
          }
        }
      }
    }
  }
`;

const FBAPlusAdSlideshowWarning = ({ organizationId }) => {
  const [feedMappingValue, setFeedMappingValue] = useState(1);
  const [customImageValue, setCustomImageValue] = useState('');

  const { loading, data, refetch } = useQuery(GET_FEED_MAPPING_QUERY, {
    variables: { organizationId, feedMappingId: feedMappingValue },
  });

  const handleFormChange = () => {
    setTimeout(() => {
      const feedMappingInput = document.querySelector('input[name="fb_adtext[feed_mapping_id]"]');
      const customImageInput = document.querySelector(
        '.Grid--InputWrapper.mt-8:not(.hidden) input[name="fb_adtext[custom_image]"]'
      );
      setFeedMappingValue(feedMappingInput?.value);
      setCustomImageValue(customImageInput?.value);
    }, 100);
  };

  useHiddenInputsObserver("form[data-test-id='fb_adtext_form']", handleFormChange);

  useEffect(() => {
    refetch();
  }, [feedMappingValue]);

  const imageVariables = data?.organization?.feedMapping?.dataSource?.tableVariablesSorted?.filter(
    variable => variable.isImageUrl
  );
  const currentImageVariable = imageVariables?.find(
    variable => variable.placeholderName === customImageValue?.replace(/^_+|_+$/g, '')
  );

  if (loading) {
    return null;
  }

  return (
    <div className="mt-16">
      {currentImageVariable?.imageGen ? (
        <InfoBox type="warning" withIcon>
          {t('fb_a_plus.carousel_slideshow_custom_image_warning', {
            default: 'Please do not use the slideshow in combination with the catalog when the Image Editor is in use.',
          })}
        </InfoBox>
      ) : (
        <InfoBox type="info" withIcon>
          {t('fb_a_plus.carousel_slideshow_custom_image_info', {
            default:
              'Ensure you have multiple images for each item in your product set. If multiple images are not available, a single image will be displayed instead.',
          })}
        </InfoBox>
      )}
    </div>
  );
};

export default FBAPlusAdSlideshowWarning;
