/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import React from 'react';
import { setupColumn } from './setupColumn';
import { ArrayHtmlCell, LinkCell, MainIdentifierWithSwitcherCell, TextCell, StateCell } from './cells';
import { LinkEdit } from './editable';
import conditions from './conditions';
import labels from './labels';
import { t } from '../../../i18n';
import { HeaderWithTooltip } from './dataSources';

const getTranslationKey = key => {
  let newKey = 'yesterday';
  if (key !== newKey) newKey = key.replace(/d$/, '_days');
  return t(newKey, { scope: 'additional_sources.time_range' });
};

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: ['name', 'editLink', 'deleteLink', 'status', 'importer'],
    order: 'name',
    required: true,
    cell: MainIdentifierWithSwitcherCell,
    width: '220px',
    fixed: 'left',
    editable: LinkEdit,
    editButtonAsProp: true,
    specialEditableCase: true,
    editQuery: ({ id }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') changes.push(`status: "${enabled ? 'running' : 'paused'}"`);
      return gql`mutation UpdateAdditionalSource($text: String) {
        updateAdditionalSource(id: ${id}, name: $text, ${changes.join(
        ','
      )} ) { additionalSource { id status name } errors updateNotificationText } }`;
    },
    onUpdate: ({ importer }) => () => {
      // eslint-disable-next-line no-undef
      window.dispatchEvent(new Event(`additionalDataSourceStateToggled-${importer}`));
    },
    header: () => t('activerecord.attributes.bid_rule.name'),
    resolveChildren: ({ id, icon, name, editLink, deleteLink, status }) => ({
      id,
      icon,
      status,
      name,
      text: name,
      link: editLink,
      bold: true,
      size: 'fullwithGrid',
      'data-test-id': `dropdown-action-${name}`,
      links: [
        {
          href: editLink,
          size: 'fullwithGrid',
          'data-test-id': 'edit-bid-rule-button',
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          icon: 'trash',
          danger: true,
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
      ],
    }),
  }),
  ...conditions,
  ...labels,
  ...setupColumn({
    id: 'systemId',
    order: 'systemId',
    cell: TextCell,
    header: () => t('system_id', { scope: 'activerecord.attributes.additional_source' }),
    resolveChildren: ({ systemId }) => systemId,
  }),
  ...setupColumn({
    id: 'columnsPrefix',
    graphqlFields: ['editLink', 'columnsPrefix'],
    cell: LinkCell,
    header: () => t('columns_prefix', { scope: 'activerecord.attributes.additional_source' }),
    resolveChildren: ({ columnsPrefix, editLink }) => ({
      text: columnsPrefix,
      link: editLink,
    }),
  }),
  ...setupColumn({
    id: 'dateRanges',
    cell: ArrayHtmlCell,
    graphqlFields: 'dateRanges',
    header: () => t('date_ranges', { scope: 'activerecord.attributes.additional_source' }),
    resolveChildren: ({ dateRanges = [] }) => dateRanges.map(d => getTranslationKey(d)),
  }),
  ...setupColumn({
    id: 'columns',
    cell: ArrayHtmlCell,
    graphqlFields: 'columns',
    header: () => t('columns', { scope: 'activerecord.attributes.additional_source' }),
    resolveChildren: ({ columns = [] }) => columns,
  }),
  ...setupColumn({
    id: 'mappingField',
    graphqlFields: ['mappingField', 'mappingFields', 'useMultipleMappingFields'],
    cell: ArrayHtmlCell,
    header: () => t('mapping_field', { scope: 'activerecord.attributes.additional_source' }),
    resolveChildren: ({ mappingField, mappingFields, useMultipleMappingFields }) =>
      useMultipleMappingFields ? mappingFields : [mappingField],
  }),
  ...setupColumn({
    id: 'additionalMappingField',
    graphqlFields: ['additionalMappingField', 'additionalMappingFields', 'useMultipleMappingFields'],
    cell: ArrayHtmlCell,
    header: () => t('additional_mapping_field', { scope: 'activerecord.attributes.additional_source' }),
    resolveChildren: ({ additionalMappingField, additionalMappingFields, useMultipleMappingFields }) =>
      useMultipleMappingFields ? additionalMappingFields : [additionalMappingField],
  }),
  ...setupColumn({
    id: 'additionalSourceState',
    graphqlFields: 'additionalSourceState',
    cell: StateCell,
    header: () => t('views.feed_export.status'),
    resolveChildren: ({ additionalSourceState, humanizedDownloadError, lastImport }) => ({
      state: additionalSourceState,
      error: humanizedDownloadError,
      lastUpdatedAt: lastImport,
      kind: 'data_source',
    }),
  }),
  ...setupColumn({
    id: 'scrapedDomains',
    cell: ArrayHtmlCell,
    graphqlFields: 'scrapedDomains',
    header: () => <HeaderWithTooltip translationKey="scraped_domains" />,
    resolveChildren: ({ scrapedDomains = [] }) => scrapedDomains,
  }),
  ...setupColumn({
    id: 'dataSourceName',
    graphqlFields: { additionalDataSource: ['name', 'showLink', 'icon'] },
    cell: LinkCell,
    header: () => t('activerecord.attributes.additional_source.additional_data_source'),
    resolveChildren: ({ additionalDataSource }) => ({
      icon: additionalDataSource?.icon,
      text: additionalDataSource?.name,
      link: additionalDataSource?.showLink,
    }),
  }),

  ...setupColumn({
    id: 'ownerDataSourceName',
    graphqlFields: { dataSource: ['name', 'showLink', 'icon'] },
    cell: LinkCell,
    header: () => t('activerecord.attributes.additional_source.data_source'),
    resolveChildren: ({ dataSource }) => ({
      icon: dataSource?.icon,
      text: dataSource?.name,
      link: dataSource?.showLink,
    }),
  }),
};

export default mapping;
