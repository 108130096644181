export const serializeCondition = ({
  left_part,
  equation,
  group_id,
  group_type,
  right_part,
  right_part_variable,
  right_part_array,
  product_sets,
  or_conditions,
  or_group,
  negative,
  negative_product_sets,
  selected_item_group_id,
  _destroy,
  dynamic_value_count,
  dynamic_value_percent,
  dynamic_value_aggregation_function,
  dynamic_value_selection,
  dynamic_value_conditions,
}) =>
  !_destroy &&
  ((equation && left_part) || selected_item_group_id) &&
  (right_part ||
    right_part_variable ||
    right_part_array?.length > 0 ||
    dynamic_value_count ||
    dynamic_value_percent ||
    equation === 'MISSING' ||
    equation === 'PRESENT' ||
    product_sets ||
    or_conditions ||
    selected_item_group_id ||
    negative_product_sets)
    ? JSON.stringify({
        left_part,
        equation,
        group_type,
        group_id,
        right_part,
        right_part_variable,
        right_part_array,
        product_sets,
        or_group,
        or_conditions,
        negative,
        negative_product_sets,
        selected_item_group_id,
        dynamic_value_count,
        dynamic_value_percent,
        dynamic_value_aggregation_function,
        dynamic_value_selection,
        dynamic_value_conditions_attributes: dynamic_value_conditions
          ? dynamic_value_conditions.map(dvc => ({
              left_part: dvc.left_part,
              equation: dvc.equation,
              right_part: dvc.right_part,
              right_part_variable: dvc.right_part_variable,
              right_part_array: dvc.right_part_array,
              or_group: dvc.or_group,
              group_id: dvc.group_id,
              group_type: dvc.group_type,
            }))
          : [],
      })
    : null;

export const serializeConditions = conditions => conditions.map(serializeCondition).filter(c => !!c);
