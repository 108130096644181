import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { groupBy, isEmpty } from 'lodash';
import {
  Modal,
  ModalBodyWithCategories,
  Row,
  Col,
  Loader,
  Text,
  SmartSelect,
  EmptyState,
  SectionHeading,
  ErrorLoadingQuote,
  Pagination,
} from '../../../../index';
import { t } from '../../../../../i18n';
import OptionsContext from '../../../OptionsContext';
import ImageTile from './ImageTile';
import Searchbox from '../../../../../organizations_dropdown/Searchbox';

const BASE_QUERY = `
  imageSnippets {
    id
    name
    category
    imageType
    svgPreview(size: 250)
    svgObjects
    width
    height
    resolution
  }
`;

const IMAGE_SNIPPETS_QUERY = gql`
  query ImageSnippets {
    ${BASE_QUERY}
  }
`;
const IMAGE_SNIPPETS_ORGANIZATION_QUERY = gql`
  query ImageSnippets($organizationId: BigInt!) {
    organization(id: $organizationId) {
      ${BASE_QUERY}
    }
  }
`;

const PAGE_LIMIT = 15;

const sortBySize = sizesArray =>
  sizesArray.sort((a, b) => {
    if (a[0] === 'all') return -1;
    return b[0] === 'all' ? 1 : (a[1] || '').localeCompare(b[1] || '');
  });

const getCategory = images => groupBy(images, 'category');
const getResolutions = images => ({ ...groupBy(images, 'resolution'), all: [] });

const getOptions = ({ images, selectedCategoryTemp, selectedSizeTemp, search }) => {
  const selectedSize = selectedSizeTemp === 'all' ? null : selectedSizeTemp;

  let searchedImages = images;
  let categories = getCategory(images);
  let resolutions = getResolutions(images);

  if (selectedSize && selectedCategoryTemp) {
    searchedImages = images
      .filter(el => !selectedCategoryTemp || el.category === selectedCategoryTemp)
      .filter(el => !selectedSize || el.resolution === selectedSizeTemp);

    categories = getCategory(searchedImages);
    resolutions = getResolutions(searchedImages);
  }

  if (!selectedCategoryTemp && selectedSize) {
    searchedImages = images.filter(img => img.resolution === selectedSizeTemp);
    categories = getCategory(searchedImages);
  }
  if (selectedCategoryTemp && !selectedSize) {
    searchedImages = images.filter(img => img.category === selectedCategoryTemp);
    resolutions = getResolutions(searchedImages);
  }

  if (search) {
    searchedImages = searchedImages.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
    categories = getCategory(searchedImages);
    resolutions = getResolutions(searchedImages);
  }

  return { categories, resolutions, searchedImages };
};

const SelectImageSnippetModal = ({ onSelect, onClose, minWidth, minHeight, imageType }) => {
  const { organizationId, imageUrlFields } = useContext(OptionsContext);
  const [selectedCategoryTemp, setSelectedCategory] = useState(null);
  const [cache, setCache] = useState(null);
  const [selectedSizeTemp, setSelectedSize] = useState(null);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    setCurrentPage(0);
  }, [search, selectedSizeTemp, selectedCategoryTemp]);

  const { loading, data, refetch, error } = useQuery(
    organizationId ? IMAGE_SNIPPETS_ORGANIZATION_QUERY : IMAGE_SNIPPETS_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: { organizationId },
    }
  );

  const images = (data?.organization?.imageSnippets || data?.imageSnippets || []).filter(
    image => image.imageType === imageType
  );

  const { categories, resolutions, searchedImages } = getOptions({
    images,
    selectedCategoryTemp,
    selectedSizeTemp,
    search,
  });

  if (cache) {
    if (selectedCategoryTemp && selectedCategoryTemp !== cache.category) {
      setCache({ ...cache, category: selectedCategoryTemp });
    }
    if (selectedSizeTemp && selectedSizeTemp !== cache.size) {
      setCache({ ...cache, size: selectedSizeTemp });
    }
  }

  if (search === '' && cache) {
    setSelectedCategory(cache.category);
    setSelectedSize(cache.size);
    setCache(null);
  }

  if (searchedImages.length === 0 && (selectedCategoryTemp || selectedSizeTemp)) {
    setCache({ category: selectedCategoryTemp, size: selectedSizeTemp });
    setSelectedCategory(null);
    setSelectedSize(null);
  }

  const groupCategoryOriginal = groupBy(images, 'category');
  const groupSizeOriginal = { all: [], ...groupBy(images, 'resolution') };

  const sortedSizeOriginal = sortBySize(
    Object.keys(resolutions).map(size => (size === 'all' ? ['all', t('react.image_generator.modal.all_size')] : size))
  );

  const sortedCategories = sortBySize(Object.keys(categories)).reduce((acc, cur) => ({ ...acc, [cur]: cur }), {});
  const selectedCategory = Object.keys(categories).indexOf(selectedCategoryTemp) !== -1 ? selectedCategoryTemp : null;
  const selectedSize = Object.keys(resolutions).indexOf(selectedSizeTemp) !== -1 ? selectedSizeTemp : null;

  const translationScope = ['snippet', 'template'].includes(imageType)
    ? `react.image_generator.modal.${imageType}`
    : 'react.image_generator.modal';

  const paginatedImages = (searchedImages || []).slice(0 + PAGE_LIMIT * currentPage, PAGE_LIMIT * (currentPage + 1));

  return (
    <Modal onClose={onClose} heading={t('select_images', { scope: translationScope })} size="full">
      <ModalBodyWithCategories
        groupCategoryOriginal={groupCategoryOriginal}
        groupCategory={sortedCategories}
        sortedCategory
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        sidebarAllItem={t('all_templates', { scope: translationScope })}
        fullWidthContent
      >
        {minWidth && minHeight && (
          <Row>
            <Text>
              {t('react.image_generator.modal.min_width_and_height')} {minWidth}px × {minHeight}px
            </Text>
          </Row>
        )}
        <Row grow>
          <div className="w-100">
            {loading && <Loader absolute />}
            {error && (
              <div className="Preview-item">
                <ErrorLoadingQuote onClick={() => refetch()} />
              </div>
            )}
            {!loading && !error && (
              <React.Fragment>
                {Object.keys(groupCategoryOriginal).length > 0 && (
                  <Row className="mb-8" padding="xl">
                    <Col width="392px">
                      <Searchbox
                        searchBoxText={t('react.image_generator.modal.search')}
                        searchValue={search}
                        handleSearch={({ target: { value } }) => setSearch(value)}
                        hideReset
                      />
                    </Col>
                    {!isEmpty(groupSizeOriginal) && imageType === 'template' && (
                      <Col width="196px">
                        <SmartSelect
                          key={JSON.stringify(resolutions)}
                          id="size-select"
                          name="size_select"
                          collection={sortedSizeOriginal || []}
                          onChange={({ target: { value } }) => setSelectedSize(value)}
                          value={selectedSize || 'all'}
                          initWithoutSelectedValue
                          doNotUseInternalState
                        />
                      </Col>
                    )}
                  </Row>
                )}
                {searchedImages.length === 0 && (
                  <EmptyState icon="image_editor_icon">
                    <SectionHeading className="mt-16" spacing={0}>
                      {t('empty_state', { scope: translationScope })}
                    </SectionHeading>
                  </EmptyState>
                )}
                <Row className="mt-16 mb-8" flexwrap>
                  {paginatedImages.map(image => (
                    <Col width="284px" key={image.id}>
                      <ImageTile
                        label={image.name}
                        svgPreview={image.svgPreview}
                        resolution={image.resolution}
                        onSelect={() => {
                          const imageName =
                            (imageUrlFields.find(img => !img.isModifier) || imageUrlFields[0])?.name || 'image';
                          const imageValue =
                            (imageUrlFields.find(img => !img.isModifier) || imageUrlFields[0])?.value ||
                            '_snippet_image_link_';
                          const template = JSON.parse(
                            JSON.stringify(image)
                              .replaceAll('_snippet_image_link_', imageValue)
                              .replaceAll(/[^_]snippet_image_link[^_]/g, imageName)
                          );

                          onSelect(JSON.parse(template.svgObjects), template);
                        }}
                        disabled={(minWidth && image.width < minWidth) || (minHeight && image.height < minHeight)}
                        disabledMessage={t('react.image_generator.modal.image_too_small')}
                        imageUrlFields={imageUrlFields}
                        imageHeight="250px"
                      />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col grow>
                    <Pagination
                      className="mt-16"
                      current={currentPage}
                      disableAnchor
                      changePage={page => () => setCurrentPage(page)}
                      maxPages={Math.ceil(searchedImages?.length / PAGE_LIMIT)}
                    />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </div>
        </Row>
      </ModalBodyWithCategories>
    </Modal>
  );
};

SelectImageSnippetModal.propTypes = {
  imageType: PropTypes.oneOf(['template', 'snippet']).required,
  onSelect: PropTypes.func,
  onClose: PropTypes.func,
  minWidth: PropTypes.number,
  minHeight: PropTypes.number,
};

export default SelectImageSnippetModal;
