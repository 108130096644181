import React, { useState } from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import TableHeader from './TableHeader';
import TableBody from './TableBody';

const Table = ({ lines, period, columns, ...rest }) => {
  const [additionalContent, setAdditionalContent] = useState([]);
  const isAdditionalContentShown = item => additionalContent.indexOf(item.id) !== -1;
  const handleClick = item =>
    isAdditionalContentShown(item)
      ? setAdditionalContent(additionalContent.filter(d => d !== item.id))
      : setAdditionalContent(additionalContent.concat([item.id]));

  if (lines.length <= 0) return <p>Sorry but there are no data for this period</p>;

  return (
    <table className="Sticky-Table Sticky-Table--noHeight" style={{ tableLayout: 'fixed', whiteSpace: 'normal' }}>
      <TableHeader {...rest} columns={columns} period={period} />
      <TableBody
        {...rest}
        data={lines}
        columns={columns}
        itemProps={{ handleClick, isAdditionalContentShown }}
        period={period}
      />
    </table>
  );
};

Table.propTypes = {
  lines: PropTypes.array,
  columns: PropTypes.array,
  period: PropTypes.string,
};

export default hot(Table);
