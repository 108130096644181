import React from 'react';
import useInputState from './useInputState';

const TestComponent = () => {
  const { state: switchState } = useInputState({ name: 'testing_form[switch]' });
  const { state: selectState } = useInputState({ name: 'testing_form[select]' });
  const { state: radioState } = useInputState({ name: 'testing_form[horizontal_radios]' });
  const { state: segmented } = useInputState({ name: 'testing_form[segmented]' });

  return (
    <React.Fragment>
      <div>Switch state je: {`${switchState}`}</div>
      <div>Select state je: {`${selectState}`}</div>
      <div>Radio state je: {`${radioState}`}</div>
      <div>Segmented state je: {`${segmented}`}</div>
    </React.Fragment>
  );
};

export default TestComponent;
