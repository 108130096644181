import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import cs from 'classnames';
import { Manager, Reference, Popper } from 'react-popper';
import PropTypes from 'prop-types';
import NestedFields from '../../nested_fields';
import { Button, Icon, Row, Col, Badge } from '../index';
import { isActiveCondition } from './utils';
import { t } from '../../i18n';
import { CSRFField } from '../../utils/getCSRFToken';

const ConditionHiddenField = ({
  formBase,
  group_type,
  group_id,
  id,
  left_part,
  negative,
  equation,
  right_part,
  right_part_array,
  right_part_variable,
  selected_item_group_id,
  _destroy,
}) => (
  <div>
    <input type="hidden" name={`${formBase}[conditions_attributes][${id}][group_id]`} value={group_id} />
    <input type="hidden" name={`${formBase}[conditions_attributes][${id}][group_type]`} value={group_type} />
    <input type="hidden" name={`${formBase}[conditions_attributes][${id}][negative]`} value={negative} />
    <input
      type="hidden"
      name={`${formBase}[conditions_attributes][${id}][selected_item_group_id]`}
      value={selected_item_group_id}
    />

    <input type="hidden" name={`${formBase}[conditions_attributes][${id}][left_part]`} value={left_part} />
    <input type="hidden" name={`${formBase}[conditions_attributes][${id}][right_part]`} value={right_part} />
    <input
      type="hidden"
      name={`${formBase}[conditions_attributes][${id}][right_part_variable]`}
      value={right_part_variable}
    />
    <input type="hidden" name={`${formBase}[conditions_attributes][${id}][equation]`} value={equation} />
    <input
      type="hidden"
      name={`${formBase}[conditions_attributes][${id}][_destroy]`}
      value={_destroy ? 'true' : 'false'}
    />

    {right_part_array &&
      right_part_array.map(value => (
        <input
          key={value}
          type="hidden"
          name={`${formBase}[conditions_attributes][${id}][right_part_array][]`}
          value={value}
        />
      ))}
  </div>
);

ConditionHiddenField.propTypes = {
  formBase: PropTypes.string,
  group_id: PropTypes.string,
  group_type: PropTypes.string,
  id: PropTypes.string,
  left_part: PropTypes.string,
  equation: PropTypes.string,
  negative: PropTypes.bool,
  right_part: PropTypes.string,
  right_part_variable: PropTypes.string,
  right_part_array: PropTypes.array,
  selected_item_group_id: PropTypes.string,
  _destroy: PropTypes.bool,
};

function ProductFilter({
  conditions,
  setConditions,
  conditionsOptions,
  setFrom,
  hideProductViewButton,
  newFilteredDataSourceLink,
  addNextFieldText,
}) {
  const [tempConditions, setTempConditions] = useState(conditions);
  const [show, setShow] = useState(false);

  const activeConditions = isActiveCondition(conditions) || [];
  const activeTempConditions = isActiveCondition(tempConditions) || [];

  const activeTempConditionsCount = isActiveCondition(tempConditions).length;
  const activeConditionsCount = isActiveCondition(conditions).length;

  useEffect(() => {
    setTempConditions(conditions);
  }, [conditions]);

  if (conditionsOptions.length === 0) return null;
  return (
    <div>
      <div className="hidden">
        {tempConditions.map(cond => (
          <ConditionHiddenField key={cond.id} {...cond} formBase="filters" />
        ))}
      </div>
      <Manager>
        <Reference>
          {({ ref }) => (
            <Button
              icon="filter"
              data-test-id="product-filter"
              secondary
              setRef={ref}
              onClick={() => setShow(!show)}
              active={show || activeConditionsCount > 0}
            >
              {t('react.card_view.filters')}
              {activeConditionsCount > 0 && (
                <Badge count={activeConditionsCount} kind="blue" size="medium" className="ml-8" />
              )}
              <Icon inheritColor kind="chevron-down" size="10px" className="ml-8 line-height-1" />
            </Button>
          )}
        </Reference>
        {show &&
          ReactDOM.createPortal(
            <Popper placement="bottom-start">
              {({ ref, style, placement }) => (
                <div
                  className="Sticky-Popover"
                  ref={ref}
                  style={{ ...style, width: '684px', top: '4px' }}
                  data-placement={placement}
                >
                  <NestedFields
                    field="conditions"
                    formBase="conditions"
                    addNewFieldText={t('react.and')}
                    addNextFieldText={addNextFieldText || t('react.and')}
                    fieldData={(conditions.length > 0 && conditions) || tempConditions}
                    options={{ ...conditionsOptions }}
                    onChange={newConditions => {
                      setTempConditions(newConditions);
                    }}
                    hideProductViewButton={hideProductViewButton}
                  />
                  <Row className="mt-16">
                    <Col shrink>
                      <Button
                        disabled={
                          JSON.stringify(activeTempConditions) === JSON.stringify(activeConditions) &&
                          activeTempConditionsCount === activeConditionsCount
                        }
                        primary
                        onClick={() => {
                          setConditions(tempConditions);
                          setFrom(0);
                          setShow(false);
                        }}
                        data-test-id="product-conditions-apply"
                      >
                        {t('react.card_view.apply_filters')}
                      </Button>
                    </Col>

                    <Col shrink>
                      <Button
                        tertiary
                        onClick={() => {
                          setShow(false);
                          setTempConditions([]);
                        }}
                      >
                        {t('cancel')}
                      </Button>
                    </Col>

                    <Col shrink className={cs({ 'ml-a': !newFilteredDataSourceLink })}>
                      <Button
                        disabled={activeConditionsCount === 0}
                        secondary
                        onClick={() => {
                          setConditions([]);
                          setShow(false);
                          setFrom(0);
                        }}
                        data-test-id="product-conditions-reset"
                      >
                        {t('react.card_view.reset_filters')}
                      </Button>
                    </Col>

                    {newFilteredDataSourceLink && (
                      <Col shrink className="ml-a">
                        <form action={newFilteredDataSourceLink} method="post">
                          <input type="hidden" name="_method" value="get" />
                          <CSRFField />
                          {tempConditions.map(cond => (
                            <ConditionHiddenField key={cond.id} {...cond} formBase="data_source" />
                          ))}

                          <Button
                            secondary
                            type="submit"
                            data-test-id="product-conditions-create-filtered-data-source"
                            disabled={activeTempConditionsCount === 0 && activeConditionsCount === 0}
                          >
                            {t('react.card_view.create_filtered_data_source')}
                          </Button>
                        </form>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </Popper>,
            document.body
          )}
      </Manager>
    </div>
  );
}
ProductFilter.propTypes = {
  conditions: PropTypes.array,
  setConditions: PropTypes.func,
  conditionsOptions: PropTypes.object,
  setFrom: PropTypes.func,
  hideProductViewButton: PropTypes.bool,
  newFilteredDataSourceLink: PropTypes.string,
  addNextFieldText: PropTypes.string,
};

export default ProductFilter;
