document.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    const hasErrorFields = document.querySelectorAll('.has-error:not(.hidden)');
    if (hasErrorFields.length > 0) {
      hasErrorFields[0].scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center',
      });
    }
  }, 100);
});
