import React from 'react';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import Select from './Select';
import { Row, Col } from '../layout';

const resolveLanguageFromProps = ({ callToActionTexts, value }) => {
  const wantedLanguage = Object.keys(callToActionTexts).find(
    language => callToActionTexts[language].indexOf(value) !== -1
  );

  return (
    wantedLanguage ||
    Object.keys(callToActionTexts).find(lang => lang.indexOf('UK') !== -1) ||
    Object.keys(callToActionTexts).find(lang => lang.indexOf('English') !== -1)
  );
};

class CallToActionText extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    callToActionTexts: PropTypes.object.isRequired,
  };

  state = {
    language: resolveLanguageFromProps(this.props),
    value: this.props.value || '',
  };

  handleChangeValue = ({ target: { value } }) => {
    this.setState({ value });
  };

  handleChangeLanguage = ({ target: { value } }) => {
    this.setState({ language: value });
  };

  render() {
    const { id, name, callToActionTexts, ...rest } = this.props;
    const { language, value } = this.state;
    return (
      <Row>
        <Col>
          <Select
            key={language}
            {...rest}
            id={id}
            name={name}
            onChange={this.handleChangeValue}
            collection={lodash.uniq(callToActionTexts[language]).map(text => ({
              key: `${language}_${text}`,
              value: text,
              label: text,
            }))}
            value={value}
          />
        </Col>
        <Col>
          <Select
            onChange={this.handleChangeLanguage}
            collection={Object.keys(callToActionTexts).map(lang => ({
              value: lang,
              label: lang,
            }))}
            value={language}
          />
        </Col>
      </Row>
    );
  }
}

export default CallToActionText;
