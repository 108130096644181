/* eslint-disable react/prop-types */
import { createAdtextFieldText, createAdtextLineText } from './createAdtextField';

const creator = createAdtextFieldText('ad_responsive_adtext');
const creatorLine = createAdtextLineText('ad_responsive_adtext');

const mapping = {
  ...creatorLine('responsiveHeadline1'),
  ...creatorLine('responsiveHeadline2'),
  ...creatorLine('responsiveHeadline3'),
  ...creatorLine('responsiveHeadline4'),
  ...creatorLine('responsiveHeadline5'),
  ...creatorLine('responsiveHeadline6'),
  ...creatorLine('responsiveHeadline7'),
  ...creatorLine('responsiveHeadline8'),
  ...creatorLine('responsiveHeadline9'),
  ...creatorLine('responsiveHeadline10'),
  ...creatorLine('responsiveHeadline11'),
  ...creatorLine('responsiveHeadline12'),
  ...creatorLine('responsiveHeadline13'),
  ...creatorLine('responsiveHeadline14'),
  ...creatorLine('responsiveHeadline15'),
  ...creatorLine('responsiveDescription1'),
  ...creatorLine('responsiveDescription2'),
  ...creatorLine('responsiveDescription3'),
  ...creatorLine('responsiveDescription4'),

  ...creator({ field: 'responsivePath1', updateField: 'path1' }),
  ...creator({ field: 'responsivePath2', updateField: 'path2' }),
};

export default mapping;
