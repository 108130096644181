import React from 'react';
import PropTypes from 'prop-types';
import { Button, ImageUpload, Col, Tooltip } from '../components';
import updatePreview from './updatePreview';
import { BoxRenderer } from './WrapperRenderers';
import { t } from '../i18n';

class ImageAsset extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    image_asset: PropTypes.object,
    resource_name: PropTypes.string,
    url: PropTypes.string,
    name: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    updateFieldData: PropTypes.func.isRequired,
  };

  static WrapperRenderer = BoxRenderer;

  static defaultProps = {
    options: {},
  };

  state = {
    image_asset: this.props.image_asset || {},
  };

  handleImageChange = () => {
    this.props.updateFieldData(this.props.id, { updated: true });
    updatePreview();
  };

  render() {
    const { id, getSubFiledOptions, onRemove, options, resource_name, url, name } = this.props;
    const { image_asset } = this.state;

    if (resource_name && resource_name.length > 0) {
      return (
        <Col className="ImageUpload" data-test-id="nested-field-imageAsset">
          <div className="ImageUpload-wrapper">
            {<input type="hidden" value={resource_name} {...getSubFiledOptions('resource_name')} />}
            {<input type="hidden" value={url} {...getSubFiledOptions('url')} />}
            {<input type="hidden" value={name} {...getSubFiledOptions('name')} />}
            <Tooltip text={t('image_upload.imported_from_gogole_ads_no_edit')} className="ImageUpload-edit">
              <Button primary onlyIcon disabled icon="edit" data-test-id="image-upload-noedit" size="small" />
            </Tooltip>
            {onRemove && (
              <Tooltip text={t('image_upload.delete_tooltip')} className="ImageUpload-trash ml-4">
                <Button red onlyIcon icon="trash" data-test-id="nested-field-remove" size="small" onClick={onRemove} />
              </Tooltip>
            )}

            <img className="ImageUpload-centeredContent" alt={name} src={url} />
          </div>
        </Col>
      );
    }
    return (
      <Col className="ImageUpload" data-test-id="nested-field-imageAsset">
        <ImageUpload
          aspect_ratios={options.aspect_ratios}
          disableFormats={options.disable_formats}
          options={options}
          onChange={this.handleImageChange}
          onRemove={id && onRemove && onRemove}
          fieldOptions={getSubFiledOptions('image_asset_attributes')}
          {...image_asset}
        />
      </Col>
    );
  }
}

export default ImageAsset;
