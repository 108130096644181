import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Button, Col, Ellipsis, InfoBox, Modal, ModalBody, Row, SegmentedButton } from '../../../index';
import { ModalFooter } from '../../../Modal';
import { t } from '../../../../i18n';

const SEGMENTED_BUTTONS_VALUES = ['all', 'feed', 'custom'];

const ImageCheckbox = ({ checked = false, imgUrl, label, onToggle, value }) => (
  <Col
    width="calc(25% - 8px)"
    className={cs('ImageCheckbox-item', 'mn', { 'ImageCheckbox-item--checked': checked })}
    onClick={() => onToggle(value)}
  >
    <Row shrink>
      <input className="ml-8 mt-8" type="checkbox" value={value} checked={checked} onChange={() => onToggle(value)} />
    </Row>
    <Row grow justifyCenter>
      <img style={{ height: '75px', maxWidth: '100%', aspectRatio: 'auto' }} src={imgUrl} alt={label} />
    </Row>

    <Row shrink justifyCenter className="mv-4">
      <Ellipsis>
        <span className="Heading--lg">{label}</span>
      </Ellipsis>
    </Row>
  </Col>
);

const SelectImagesModal = ({ allImages, heading, modalSubmit, selectedValues, onClose }) => {
  const [selectedImages, setselectedImages] = useState(selectedValues);
  const [imagesFilter, setImagesFilter] = useState(SEGMENTED_BUTTONS_VALUES[0]);

  const toggleCheck = value => {
    if (selectedImages.includes(value)) {
      setselectedImages(selectedImages.filter(origValue => origValue !== value));
    } else setselectedImages([...selectedImages, value]);
  };

  const filterImages = () => {
    if (imagesFilter === SEGMENTED_BUTTONS_VALUES[0]) return allImages;
    if (imagesFilter === SEGMENTED_BUTTONS_VALUES[1]) return allImages.filter(el => el.editLink);
    return allImages.filter(el => !el.editLink);
  };

  return (
    <Modal onClose={onClose} heading={heading}>
      <ModalBody>
        <Row shrink className="mb-16">
          <InfoBox type="info" withIcon>
            <span>
              {t('react.fb_adtexts.hint')}
              <span className="Text--bold">{t('react.fb_adtexts.hint_bold')}</span>
            </span>
          </InfoBox>
        </Row>
        <Row shrink>
          <SegmentedButton
            name="slideshowFilter"
            id="slideshowFilter"
            doNotUseInternalState
            size="medium"
            value={imagesFilter}
            onChange={e => setImagesFilter(e.target.value)}
            collection={SEGMENTED_BUTTONS_VALUES.map(val => ({
              value: val,
              label: t(val, { scope: 'react.fb_adtexts.filters' }),
            }))}
          />
        </Row>
        <Row flexwrap padding="m" grow className="mt-16">
          {filterImages().map(({ label, url, value }, index) => (
            <ImageCheckbox
              key={[value, index].join('-')}
              label={label}
              imgUrl={url}
              value={value}
              onToggle={toggleCheck}
              checked={selectedImages.includes(value)}
            />
          ))}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button kind="primary" onClick={() => modalSubmit(selectedImages)}>
          {t('react.fb_adtexts.add_selected_images_button', {
            count: selectedImages.length,
          })}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

ImageCheckbox.propTypes = {
  checked: PropTypes.bool,
  imgUrl: PropTypes.string,
  label: PropTypes.string,
  onToggle: PropTypes.func,
  value: PropTypes.string,
};

SelectImagesModal.propTypes = {
  allImages: PropTypes.array,
  heading: PropTypes.string,
  modalSubmit: PropTypes.func,
  onClose: PropTypes.func,
  selectedValues: PropTypes.array,
};

export default SelectImagesModal;
