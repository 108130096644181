/* eslint-disable */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import Tile from './Tile';
import { Icon } from '../index';
import { t, formatNumber } from '../../i18n';

const QUERY_DASHBOARD_NOTIFICATIONS_QUERY = gql`
  query DashboardOrganizationNotifications($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      incidentsErrorCount(state: OPEN)
      incidentsWarningCount(state: OPEN)
    }
  }
`;

const QUERY_DASHBOARD_NOTIFICATIONS_CAMPAIGN_QUERY = gql`
  query DashboardOrganizationCampaignSettingNotifications($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        incidentsErrorCount(state: OPEN)
        incidentsWarningCount(state: OPEN)
      }
    }
  }
`;

const getNotification = (incidentsErrorCount, incidentsWarningCount) => {
  if (incidentsErrorCount === 0 && incidentsWarningCount === 0) {
    return {
      text: t('react.dashboard.notification.good'),
      icon: <Icon kind="good" color="#3ED95E" size="80px" />,
    };
  }
  if (incidentsErrorCount > 0) {
    return {
      text: `${t('react.dashboard.notification.error', {
        count: formatNumber(incidentsErrorCount, { precision: 0 }),
      })}`,
      icon: <Icon kind="error-circle" color="#D0021B" size="80px" />,
    };
  }

  return {
    text: `${t('react.dashboard.notification.warning', {
      count: formatNumber(incidentsWarningCount, { precision: 0 }),
    })}`,
    icon: <Icon kind="warning" color="#FF9B00" size="80px" />,
  };
};

export const NotificationsComponent = ({
  incidentsErrorCount,
  incidentsWarningCount,
  ctaLink,
  loading,
  error,
  refetch,
}) => {
  const { text, icon } = getNotification(incidentsErrorCount, incidentsWarningCount);

  return (
    <Tile header={t('react.dashboard.notification.header')} refetch={refetch} dashboard loading={loading} error={error}>
      <div className={ctaLink && 'mt-24'}>{icon}</div>
      <div className="Text Text--bold mt-4">{text}</div>
      {ctaLink && (
        <a href={ctaLink} className="Link mt-a" data-test-id="dashboard-notifications-link">
          {t('react.dashboard.notification.link_text')}
        </a>
      )}
    </Tile>
  );
};

NotificationsComponent.propTypes = {
  incidentsErrorCount: PropTypes.number,
  incidentsWarningCount: PropTypes.number,
  ctaLink: PropTypes.string,
  loading: PropTypes.any,
  error: PropTypes.any,
  refetch: PropTypes.func,
};

export const Notifications = ({ organizationId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_NOTIFICATIONS_QUERY}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
    }}
  >
    {({ error, data, loading, refetch }) => (
      <NotificationsComponent
        refetch={() => refetch()}
        loading={loading}
        error={error}
        {...rest}
        {...((data && data.organization) || {})}
      />
    )}
  </Query>
);

Notifications.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export const NotificationsCampaign = ({ organizationId, campaignSettingId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_NOTIFICATIONS_CAMPAIGN_QUERY}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
      campaignSettingId: campaignSettingId ? parseInt(campaignSettingId, 10) : 0,
    }}
  >
    {({ error, data, loading }) => (
      <NotificationsComponent
        loading={loading}
        error={error}
        {...rest}
        {...((data && data.organization && data.organization.campaignSetting) || {})}
      />
    )}
  </Query>
);

Notifications.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
};
