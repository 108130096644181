import { gql } from '@apollo/client';
import React from 'react';
import PropTypes from 'prop-types';
import { setupColumn } from './setupColumn';
import {
  IntegerCell,
  TimeAgoCell,
  MainIdentifierWithSwitcherCell,
  HtmlRightCell,
  LinkCell,
  StateCell,
  DataSourceLastLogCell,
  ArrayHtmlCell,
  AdditionalSourcesCell,
  ChildDataSourcesCell,
} from './cells';
import labels from './labels';
import { LinkEdit } from './editable';
import { Tooltip, Icon } from '../../index';
import { t } from '../../../i18n';

export const HeaderWithTooltip = ({ translationKey }) => (
  <span>
    {t(translationKey, { scope: 'activerecord.attributes.data_source' })}
    <Tooltip
      text={t(translationKey, { scope: 'activerecord.attributes.data_source.table_tooltips' })}
      className="ml-4 mr-4"
    >
      <Icon kind="info" color="#596774" size="12px" />
    </Tooltip>
  </span>
);
HeaderWithTooltip.propTypes = {
  translationKey: PropTypes.string.isRequired,
};

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: [
      'icon',
      'name',
      'id',
      'showLink',
      'editLink',
      'variablesLink',
      'refreshUrl',
      'deleteLink',
      'disabledDeleteLink',
      'historyLink',
      'approveChangeStatusModalLink',
      'status',
      'typeName',
    ],
    required: true,
    cell: MainIdentifierWithSwitcherCell,
    editable: LinkEdit,
    order: 'name',
    editButtonAsProp: true,
    width: '390px',
    header: () => t('activerecord.attributes.data_source.name'),
    editQuery: ({ id }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') changes.push(`status: "${enabled ? 'running' : 'paused'}"`);
      return gql`mutation UpdateDataSource($text: String) { updateDataSource(id: ${id}, name: $text, ${changes.join(
        ','
      )} ) {dataSource { id name status updatedAt dataSourceState } errors updateNotificationText }  }`;
    },
    resolveChildren: ({
      approveChangeStatusModalLink,
      typeName,
      id,
      icon,
      name,
      status,
      showLink,
      editLink,
      variablesLink,
      deleteLink,
      disabledDeleteLink,
      historyLink,
      refreshUrl,
    }) => ({
      approveChangeStatusModalLink,
      approveChangeStatusModalHeading:
        status === 'running'
          ? t('views.data_sources.pause_data_source', {
              default: 'Pause data source %{name}',
              name,
            })
          : t('views.data_sources.reactivate_data_source', {
              default: 'Reactivate data source %{name}',
              name,
            }),
      id,
      icon,
      status,
      size: '20px',
      tooltip: typeName,
      color: '#596774',
      name,
      text: name,
      link: showLink,
      'data-test-id': `dropdown-action-${name}`,
      links: [
        {
          href: editLink,
          'data-test-id': 'edit-bid-rule-button',
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        {
          href: refreshUrl,
          children: t('views.data_sources.index.refresh'),
          'data-test-id-refresh': name,
          icon: 'refresh',
        },
        {
          href: variablesLink,
          children: t('views.layout_elements.sidebar.data_source.variables', { default: 'variables' }),
          'data-test-id-variable': name,
          icon: 'flash',
        },
        {
          href: historyLink,
          children: t('views.campaign_settings.index.logs'),
          'data-test-id-history': name,
          icon: 'history',
        },
        {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
        {
          disabled: disabledDeleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
        },
      ],
    }),
    fixed: 'left',
  }),

  ...setupColumn({
    id: 'parentName',
    graphqlFields: { parentDataSource: ['name', 'showLink'] },
    cell: LinkCell,
    header: () => t('activerecord.attributes.data_source.parent_data_source_name'),
    resolveChildren: ({ parentDataSource }) => ({
      text: parentDataSource?.name,
      link: parentDataSource?.showLink,
      bold: true,
    }),
  }),

  ...setupColumn({
    id: 'dataSourceState',
    graphqlFields: ['dataSourceState', 'humanizedDownloadError', 'lastImport'],
    pollingInterval: { interval: 5000, shouldSync: ({ dataSourceState }) => dataSourceState === 'sync_in_progress' },
    order: 'data_source_state',
    cell: StateCell,
    header: () => t('views.feed_export.status'),
    resolveChildren: ({ dataSourceState, humanizedDownloadError, lastImport }) => ({
      state: dataSourceState,
      error: humanizedDownloadError,
      lastUpdatedAt: lastImport,
      kind: 'data_source',
    }),
  }),
  ...setupColumn({
    id: 'itemsCount',
    order: 'items_count',
    graphqlFields: 'displayProductsWithGrouped',
    cell: HtmlRightCell,
    resolveChildren: ({ displayProductsWithGrouped }) => displayProductsWithGrouped,
    header: () => t('activerecord.attributes.data_source.product_count'),
  }),

  ...setupColumn({
    id: 'additionalSources',
    graphqlFields: {
      additionalSources: ['icon', 'importer', 'indexLink', 'status', 'importerNameTranslated'],
    },
    cell: AdditionalSourcesCell,
    resolveChildren: ({ additionalSources }) => additionalSources || [],
    header: () => <HeaderWithTooltip translationKey="additional_sources" />,
  }),

  ...labels,
  ...setupColumn({
    id: 'childDataSources',
    graphqlFields: [{ connectedDataSources: ['icon', 'importer', 'typeName', 'status'] }, 'childDataSourcesLink'],
    cell: ChildDataSourcesCell,
    resolveChildren: ({ connectedDataSources, childDataSourcesLink }) => ({
      array: connectedDataSources || [],
      link: childDataSourcesLink,
    }),
    header: () => <HeaderWithTooltip translationKey="child_data_sources" />,
  }),

  ...setupColumn({
    id: 'scrapedDomains',
    graphqlFields: 'scrapedDomainWithEnrichment',
    cell: ArrayHtmlCell,
    header: () => <HeaderWithTooltip translationKey="scraped_domains" />,
    resolveChildren: ({ scrapedDomainWithEnrichment }) => scrapedDomainWithEnrichment,
  }),
  ...setupColumn({
    id: 'unusedItemsCountLive',
    order: 'unused_items_count_live',
    header: () => t('activerecord.attributes.data_source.unused_product_count'),
    cell: IntegerCell,
  }),

  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    reverseOrder: true,
    fixed: 'right',
    header: () => t('attributes.created_at'),
    cell: TimeAgoCell,
  }),
  ...setupColumn({
    id: 'lastLog',
    graphqlFields: { lastLog: ['showLink', 'skippedProductsCount'] },
    order: 'skipped_products_count',
    fixed: 'right',
    header: () => t('activerecord.attributes.data_source.last_log'),
    cell: DataSourceLastLogCell,
    resolveChildren: ({ lastLog }) => ({
      showLink: lastLog?.showLink || null,
      skippedProductsCount: lastLog?.skippedProductsCount || 0,
    }),
  }),
};

export default mapping;
