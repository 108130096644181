import React, { useState, useEffect } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Line, ComposedChart } from 'recharts';
import CHART_COLORS, { CHART_X_AXIS_STYLES, CHART_Y_AXIS_STYLES } from './ChartColors';
import Badge from './Badge';
import Button from './Button';
import { Loader } from './Icons';
import LocalizedChartTooltip from './LocalizedChartTooltip';
import { t } from '../i18n';

const CustomizeDot = ({ payload, onMouseMove, height, cx }) => {
  if (payload?.auditLogCount === 0) {
    return null;
  }

  return (
    <svg onMouseMove={onMouseMove} width={height} height={height} style={{ overflow: 'visible' }}>
      <circle cx={cx} cy={height} r={5} fill="#98A2B3" strokeWidth={0} />;
      <circle cx={cx} cy={height} r={3} fill="#fff" strokeWidth={0} />;
    </svg>
  );
};
CustomizeDot.propTypes = {
  cx: PropTypes.number,
  height: PropTypes.number,
  payload: PropTypes.object,
  onMouseMove: PropTypes.func,
};

const Chart = ({
  areas,
  canAreasChange = false,
  customizedTooltip = null,
  data,
  height = 400,
  isAnimated = false,
  isEmpty = false,
  legend = 'legend',
  disableTooltip = false,
  legendFormatter = undefined,
  tooltipFormatter = undefined,
  margin = { top: 20, right: 30, left: 0, bottom: 0 },
  showGrid = false,
  activeDot,
  showToggles = true,
  smooth = true,
  strokeDasharray = '3 3',
  tooltipTranslationPath = undefined,
  verticalLines = true,
  xAxis = null,
  yAxis = null,
  hideYAxis = false,
  noHistoryData = true,
  scale = 'band',
  openHistoryModal = null,
  getDataKey,
  dataLoading = false,
  referenceLines,
}) => {
  if (canAreasChange) {
    useEffect(() => {
      setShownAreas(areas);
    }, [areas]);
  }

  const [tooltipType, setTooltipType] = useState('area');

  const [shownAreas, setShownAreas] = useState(areas);
  const toggleArea = name => () =>
    setShownAreas({
      ...shownAreas,
      [name]: { ...shownAreas[name], hidden: !shownAreas[name].hidden },
    });

  const TooltipComponent = !customizedTooltip ? LocalizedChartTooltip : customizedTooltip;

  return (
    <div style={{ width: '100%', position: 'relative' }} data-test-id="area-chart">
      <ResponsiveContainer width="100%" height={height}>
        <ComposedChart data={data} margin={margin} onMouseLeave={() => setTooltipType('area')}>
          {showGrid && <CartesianGrid strokeDasharray={strokeDasharray} vertical={verticalLines} stroke="#e6e6e6" />}

          <XAxis {...CHART_X_AXIS_STYLES} {...xAxis} dataKey={legend} tickFormatter={legendFormatter} scale={scale} />
          {!disableTooltip && (
            <Tooltip
              cursor={false}
              content={props => (
                <TooltipComponent
                  legendFormatter={legendFormatter}
                  valueFormatter={tooltipFormatter}
                  chartType={tooltipType}
                  tooltipTranslationPath={tooltipTranslationPath}
                  {...props}
                />
              )}
            />
          )}
          {Object.keys(shownAreas).map((name, index) => {
            const color = shownAreas[name].color || CHART_COLORS[index % CHART_COLORS.length];
            const strokeColor = shownAreas[name].strokeColor || color;
            const strokeWidth = shownAreas[name].strokeWidth || null;

            return (
              !shownAreas[name].hidden && [
                <defs key={`${name}-gradient`}>
                  <linearGradient id={`${name}-area-color-${index}`} x1="0" x2="0" y1="0" y2="1">
                    <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={color} stopOpacity={0} />
                  </linearGradient>
                </defs>,
                <Area
                  key={name}
                  type={smooth ? 'monotone' : 'linear'}
                  dataKey={typeof getDataKey === 'function' ? getDataKey : name}
                  stackId="1"
                  strokeWidth={strokeWidth}
                  stroke={strokeColor}
                  fill={`url(#${name}-area-color-${index})`}
                  fillOpacity={0.1}
                  activeDot={tooltipType === 'area' && activeDot}
                  isAnimationActive={isAnimated}
                />,
              ]
            );
          })}
          {!noHistoryData && (
            <Line
              dataKey="beOnAxis"
              stroke="rgba(255,0,0,0.0000001)"
              strokeWidth={20}
              dot={
                <CustomizeDot onMouseMove={() => setTooltipType('line')} onMouseLeave={() => setTooltipType('area')} />
              }
              activeDot={
                tooltipType === 'line' && {
                  cursor: 'pointer',
                  r: 5,
                  strokeWidth: 4,
                  onMouseLeave: () => setTooltipType('area'),
                  onClick: x => openHistoryModal(x.payload.date),
                  stroke: '#98A2B3',
                }
              }
              isAnimationActive={isAnimated}
            />
          )}
          <YAxis {...CHART_Y_AXIS_STYLES} {...yAxis} hide={hideYAxis} />
          {referenceLines && referenceLines?.length > 0 && referenceLines.map(x => x)}
        </ComposedChart>
      </ResponsiveContainer>
      {isEmpty && (
        <h3
          data-test-id="sample-data"
          className="Heading mt-16 mb-8"
          style={{
            position: 'absolute',
            top: '20%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#98A2B3',
          }}
        >
          {dataLoading ? <Loader size="big" /> : t('stats.empty_heading')}
        </h3>
      )}
      {showToggles && (
        <div className="mt-4">
          {Object.keys(shownAreas).map((name, index) => {
            const color = shownAreas[name].color || CHART_COLORS[index % CHART_COLORS.length];
            return (
              <Button
                tag="div"
                key={name}
                onClick={toggleArea(name)}
                style={{
                  border: 'none',
                  opacity: shownAreas[name].hidden ? 0.5 : 1,
                  padding: '10px 0 0 12px',
                  marginRight: '10px',
                }}
              >
                <Badge style={{ backgroundColor: color, marginRight: '10px' }} />
                <span style={{ display: 'inline-block' }}>{shownAreas[name].label}</span>
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

Chart.propTypes = {
  activeDot: PropTypes.object,
  areas: PropTypes.object.isRequired,
  canAreasChange: PropTypes.bool,
  customizedTooltip: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({ legend: PropTypes.string })).isRequired,
  disableTooltip: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isAnimated: PropTypes.bool,
  isEmpty: PropTypes.bool,
  legend: PropTypes.string,
  legendFormatter: PropTypes.func,
  margin: PropTypes.object,
  noHistoryData: PropTypes.bool,
  openHistoryModal: PropTypes.bool,
  scale: PropTypes.string,
  showGrid: PropTypes.bool,
  showToggles: PropTypes.bool,
  smooth: PropTypes.bool,
  strokeDasharray: PropTypes.string,
  tooltipFormatter: PropTypes.func,
  tooltipTranslationPath: PropTypes.string,
  verticalLines: PropTypes.bool,
  xAxis: PropTypes.object,
  yAxis: PropTypes.object,
  getDataKey: PropTypes.func,
  referenceLines: PropTypes.array,
  hideYAxis: PropTypes.bool,
  dataLoading: PropTypes.bool,
};

export default hot(Chart);
