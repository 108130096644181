import React from 'react';
import ReactDOMServer from 'react-dom/server';
import qs from 'qs';
import { debounce } from 'lodash';
import { ErrorBox } from '../components';
import { t } from '../i18n';

window.loadingPreviews = window.loadingPreviews || [];

window.addLoadingPreviews = func => {
  window.loadingPreviews.push(func);
};
window.startLoadingPreview = () => {
  window.loadingPreviews.forEach(f => f());
};

function bind_not_bound(elements, on, func) {
  const initKey = `initinalized-${on}`;
  // eslint-disable-next-line func-names
  elements.each(function() {
    if (!$(this).attr(initKey)) {
      $(this).bind(on, func);
      $(this).attr('initKey', true);
    }
  });
}

function initSelectedIdsButton(form_id, preview_url, refresh_url, timeout = 500) {
  const form = () => $(`form${form_id}`);
  const previewContainer = $(`.js-preview[data-form-id="${form_id}"]`);
  const loader = $(`.Loader--background[data-form-id="${form_id}"]`);

  let loader_count = 0;
  let initialized = false;

  const load_preview = (additionalParams = {}) => {
    loader.addClass('active');
    if (!form()) {
      return;
    }

    const fieldset = form().find('fieldset[disabled=disabled]')[0];

    // Enable form for short period
    if (fieldset) fieldset.removeAttribute('disabled');

    const data = form().serialize();
    if (data.length === 0) {
      return;
    }
    // Disable form again
    if (fieldset) fieldset.setAttribute('disabled', 'disabled');

    loader_count += 1;
    $.ajax({
      type: 'POST',
      url: `${preview_url}${preview_url.includes('?') ? '&' : '?'}${qs.stringify({
        ...additionalParams,
        search: $('#js-preview-search').val(),
        count: $(`.js-count-${form_id.replace('#', '')}`).val() || $('#count').val() || 10,
        form_id,
        show_others: $('[name="show_others"]:checked').val(),
        show_valid_invalid: $('[name="show_valid_invalid"]').val(),
      })}`,
      data: `${data}&_method=post`,
      dataType: 'html',
      success: ajaxdata => {
        previewContainer.html(ajaxdata);
      },
      error: (xhr, status, error) => {
        try {
          try {
            const customError = JSON.parse(xhr.responseText);
            previewContainer.html(
              ReactDOMServer.renderToString(
                <ErrorBox className="mv-16">
                  <h4>
                    {customError.title}
                    {customError.error}
                  </h4>
                  <p>{customError.message}</p>
                </ErrorBox>
              )
            );
          } catch (_) {
            previewContainer.html(
              ReactDOMServer.renderToString(
                <ErrorBox className="mv-16">
                  {error.message || t('views.campaign_settings.selected_ids_button.error', { message: xhr.statusText })}
                </ErrorBox>
              )
            );
          }
        } catch (_) {
          // eslint-disable-next-line no-console
          console.log('Error');
        }
      },
      complete: () => {
        loader_count -= 1;
        if (loader_count <= 0) {
          loader.removeClass('active');
        }
      },
    });
  };

  const start_loading_preview = e => {
    if (e && (e.code === 'ArrowDown' || e.code === 'ArrowUp' || e.code === 'ArrowLeft' || e.code === 'ArrowRight')) {
      return;
    }

    if (previewContainer.data('previewtimer')) {
      clearTimeout(previewContainer.data('previewtimer'));
    }

    const newTimer = setTimeout(() => {
      load_preview();
      previewContainer.data('previewtimer', null);
    }, timeout);

    previewContainer.data('previewtimer', newTimer);
  };

  window.addLoadingPreviews(start_loading_preview);

  // eslint-disable-next-line consistent-return
  const refreshIds = () => {
    if (`${refresh_url}` === 'true') {
      return undefined;
    }
    loader.addClass('active');
    loader_count += 1;
    const formData = form()
      .serialize()
      .replace('&_method=patch', '');
    $.ajax({
      type: 'POST',
      data: formData,
      url: `${refresh_url}${refresh_url.includes('?') ? '&' : '?'}${qs.stringify({
        search: $('#js-preview-search').val(),
        count: $(`.js-count-${form_id.replace('#', '')}`).val() || $('#count').val() || 10,
        form_id,
        show_others: $('[name="show_others"]:checked').val(),
        show_valid_invalid: $('[name="show_valid_invalid"]').val(),
      })}`,
      success: data => {
        if (form()) {
          form()
            .find('input[name="selected_ids[]"]')
            .remove();
          form().append(data);
          load_preview();
        }
      },
      error: () => {},
      complete: () => {
        loader_count -= 1;
        if (loader_count <= 0) {
          loader.removeClass('active');
        }
      },
      dataType: 'html',
    });
  };

  form()[0].refreshIds = refreshIds;

  const bindPreviewElements = () => {
    form()[0].addEventListener('change', start_loading_preview, true);
    form()[0].addEventListener('keyup', start_loading_preview, true);
    form()[0].addEventListener('input', start_loading_preview, true);
    form()[0].addEventListener('paste', start_loading_preview, true);
    form()[0].addEventListener('propertychange', start_loading_preview, true);

    $('#js-preview-search').keypress(e => {
      // on enter in searchbox!
      if (e.which === 13) {
        e.preventDefault();
        refreshIds();
        return false;
      }
      return true;
    });

    bind_not_bound($('[name="show_others"]'), 'change', () => {
      start_loading_preview();
    });

    bind_not_bound($('[name="show_valid_invalid"]'), 'change', () => {
      refreshIds();
    });

    bind_not_bound($('[name="count"]'), 'change', () => {
      start_loading_preview();
    });

    bind_not_bound($('.js-reset-ids'), 'click', e => {
      e.preventDefault();
      $(e.target)
        .closest('.Input--search')
        .find('.js-input-search')
        .val('');
      $(e.target)
        .closest('.Input--search')
        .removeClass('valueFilled');
      refreshIds();
    });

    bind_not_bound($('.js-refresh-selected-ids'), 'click', e => {
      e.preventDefault();
      refreshIds();
    });

    bind_not_bound($('.js-refresh-ids'), 'click', e => {
      e.preventDefault();
      refreshIds();
    });

    bind_not_bound($('.js-count-all-campaigns'), 'click', e => {
      e.preventDefault();
      load_preview({ with_campaigns_count: true });
    });
  };

  const debouncedRefreshIds = debounce(refreshIds, 1000);

  const bindRefreshIdsElements = () => {
    if (form()[0]) {
      const conditionsInput = form()[0].querySelector('[name="_conditions_"]');
      if (conditionsInput && !conditionsInput.dataset.boundRefreshIds) {
        conditionsInput.dataset.boundRefreshIds = true;
        conditionsInput.addEventListener(
          'change',
          () => {
            // Ensure that we are not doing preview load unnecessary
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 10);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 100);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 300);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 700);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 900);
            debouncedRefreshIds();
          },
          true
        );
      }

      const itemGroupsInput = form()[0].querySelector('[name="_additional_conditions_"]');
      if (itemGroupsInput && !itemGroupsInput.dataset.boundRefreshIds) {
        itemGroupsInput.dataset.boundRefreshIds = true;
        itemGroupsInput.addEventListener(
          'change',
          () => {
            // Ensure that we are not doing preview load unnecessary
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 10);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 100);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 300);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 700);
            setTimeout(() => clearTimeout(previewContainer.data('timer')), 900);
            debouncedRefreshIds();
          },
          true
        );
      }
    }

    setTimeout(bindRefreshIdsElements, 1000);
  };

  const initPreviewLoading = () => {
    if (!initialized) {
      bindPreviewElements();
      setTimeout(bindRefreshIdsElements, 100);
      setTimeout(start_loading_preview, 1000);
      initialized = true;
    }
  };

  return initPreviewLoading;
}

window.initSelectedIdsButton = initSelectedIdsButton;
