import React, { PureComponent } from 'react';
import _ from 'lodash';
import { Col, Tooltip } from '../../../index';
import PropertyGroup from './PropertyGroup';
import Columns from './Columns';
import Column from './Column';
import InputCol from './InputCol';
import SwitchState from './SwitchState';
import { t } from '../../../../i18n';

export default class SizePanel extends PureComponent {
  render() {
    const { object, onChange } = this.props;
    return (
      <PropertyGroup>
        <Col padding="l">
          {_.has(object, 'width', 'height') && (
            <Columns label={t('react.image_generator.label.size')} withoutLabelPadding>
              <InputCol
                width="88px"
                showIf={_.has(object, 'width')}
                unit="w"
                value={object.width}
                property="width"
                onChange={onChange}
              />
              <InputCol
                width="88px"
                showIf={_.has(object, 'height')}
                unit="h"
                value={object.height}
                property="height"
                onChange={onChange}
              />
            </Columns>
          )}

          <Columns label={t('react.image_generator.label.position')} withoutLabelPadding>
            <InputCol
              width="88px"
              showIf={_.has(object, 'x')}
              unit="x"
              value={object.x}
              property="x"
              onChange={onChange}
            />
            <InputCol
              width="88px"
              showIf={_.has(object, 'y')}
              unit="y"
              value={object.y}
              property="y"
              onChange={onChange}
            />
          </Columns>

          {_.has(object, 'rotate') && (
            <Columns
              label={
                _.has(object, 'flipHorizontally') || _.has(object, 'flipVertically')
                  ? t('react.image_generator.label.flip_rotate')
                  : t('react.image_generator.label.rotate')
              }
              withoutLabelPadding
            >
              <Column showIf={_.has(object, 'flipVertically')}>
                <Tooltip text={t('vertically', { scope: 'react.image_generator.flip' })}>
                  <SwitchState
                    icon="flip-vertically"
                    defaultValue={false}
                    nextState={!object.flipVertically}
                    value={object.flipVertically || false}
                    onChange={this.props.onChange.bind(this, 'flipVertically')}
                  />
                </Tooltip>
              </Column>
              <Column showIf={_.has(object, 'flipHorizontally')}>
                <Tooltip text={t('horizontally', { scope: 'react.image_generator.flip' })}>
                  <SwitchState
                    icon="flip-horizontally"
                    defaultValue={false}
                    nextState={!object.flipHorizontally}
                    value={object.flipHorizontally || false}
                    onChange={this.props.onChange.bind(this, 'flipHorizontally')}
                  />
                </Tooltip>
              </Column>
              <InputCol width="88px" unit="°" value={object.rotate} property="rotate" onChange={onChange} />
            </Columns>
          )}
        </Col>
      </PropertyGroup>
    );
  }
}
