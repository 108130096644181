import React from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import Input from './Input';
import DropdownMenu from '../DropdownMenu';

const PRESET_COLORS = [
  '#F97316',
  '#EF4444',
  '#FACC15',
  '#4ADE80',
  '#2DD4BF',
  '#3B82F6',
  '#6366F1',
  '#EC4899',
  '#F43F5E',
  '#D946EF',
  '#8B5CF6',
  '#0EA5E9',
  '#10B981',
  '#84CC16',
];

const percentToHex = p => {
  const percent = Math.max(0, Math.min(100, p)); // bound percent from 0 to 100
  const intValue = Math.round((percent / 100) * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};

const sketchStyles = {
  default: {
    picker: {
      padding: '6px',
      width: '250px',
      borderRadius: 'none',
      boxShadow: 'none',
    },
    saturation: {
      borderRadius: '4px',
      paddingBottom: '70%',
    },
    controls: {
      marginTop: '8px',
    },
    hue: {
      borderRadius: '12px',
      position: 'relative',
      overflow: 'hidden',
      height: '12px',
    },
    alpha: {
      borderRadius: '12px',
      height: '12px',
      marginTop: '6px',
    },
    color: {
      width: '30px',
      height: 'auto',
      minHeight: '12px',
      borderRadius: '4px',
      marginLeft: '8px',
      marginBottom: '4px',
    },
  },
};

class ColorPicker extends React.Component {
  static propTypes = {
    doNotUseInternalState: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    enableAlpha: PropTypes.bool,
    showOnEnd: PropTypes.bool,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func,
  };

  state = {
    color: this.props.value || '',
  };

  setRef = el => {
    this.input = el;
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChangeComplete = color => {
    const hex = this.props.enableAlpha ? `${color.hex}${percentToHex(color.rgb.a * 100)}` : color.hex;
    this.setState({ color: hex });
    if (this.input) {
      this.input.dispatchEvent(new window.Event('change', { bubbles: true }));
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: hex } });
    }
  };

  render() {
    const { doNotUseInternalState, id, name, showOnEnd, enableAlpha, ...rest } = this.props;
    const { color } = this.state;

    const colorValue = doNotUseInternalState ? this.props.value : color;
    const label = (
      <Input
        {...rest}
        className="Input--withColor"
        doNotUseInternalState
        id={id}
        name={name}
        onClick={this.handleClick}
        value={colorValue}
        readOnly
        setRef={this.setRef}
        inputRightPadding="36px"
      >
        <div className="Input-color" style={{ backgroundColor: colorValue, borderColor: colorValue }} />
      </Input>
    );
    return (
      <DropdownMenu
        onOpen={e => {
          const input = e.querySelector('input');
          input.focus();
          input.select();
        }}
        buttonOnlyLabel
        hideOnClick={false}
        label={label}
        isButtonWithDefaultFont
      >
        <SketchPicker
          className="ColorPicker"
          styles={sketchStyles}
          disableAlpha={!enableAlpha}
          color={colorValue}
          onChangeComplete={this.handleChangeComplete}
          presetColors={PRESET_COLORS}
        />
      </DropdownMenu>
    );
  }
}

export default ColorPicker;
