import React, { useState, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import cs from 'classnames';
import ReactPropTypes from 'prop-types';
import { Row, Col, Text, Icon, Button, cssVariables } from '../index';
import { t } from '../../i18n';

const ChipsInput = ({ placeholder = '', maxItems, maxItemLength, assistiveText, name = '', data }) => {
  const initialData = data?.value ? data?.value : [];
  const [chips, setChips] = useState(initialData.map(value => ({ id: uuidv4(), value })));
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);

  const handleKeyDown = event => {
    if ((event.key === 'Enter' || event.key === ',') && inputValue.trim() !== '') {
      setChips([...chips, { id: uuidv4(), value: inputValue }]);
      setInputValue('');
      event.preventDefault(); // To prevent form submission if inside a form
    } else if (event.key === 'Backspace' && inputValue === '') {
      if (chips.length > 0) {
        setChips(chips.slice(0, -1));
      }
      event.preventDefault(); // To prevent navigating back in browser if inside a form
    }
  };

  const handleDeleteChip = id => {
    setChips(chips.filter(chip => chip.id !== id));
  };

  const handleWrapperClick = () => {
    inputRef.current.focus();
  };

  const handleCopyToClipboard = () => {
    const chipValues = chips.map(chip => chip.value).join(' ');
    window.navigator.clipboard.writeText(chipValues).then(() => {
      new window.NotificationCenter().show_success(t('views.copied_to_clipboard', { default: 'Copied to clipboard' }));
    });
  };

  let errorStatus = false;
  const errors = [];
  const values = chips.map(item => item.value);

  // Create an object to count occurrences of each value
  const valueCounts = values.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {});

  // Find duplicates
  const duplicates = Object.keys(valueCounts).filter(value => valueCounts[value] > 1);

  if (duplicates.length > 0 && !errors.some(error => error.type === 'duplicates')) {
    errors.push({
      type: 'duplicates',
      text: t('react.chips_input.duplicates_error', { default: 'Duplicate items found. Each theme must be unique.' }),
    });
  }

  if (maxItems && chips.length > maxItems) {
    errorStatus = true;
    errors.push({
      type: 'maxItems',
      text: t('react.chips_input.max_items_error', {
        maxItems,
        default: `You've reached the maximum entry limit of <span style="font-weight:bold">${maxItems}</span> entries.`,
      }),
    });
  }

  if (
    maxItemLength &&
    chips.some(chip => chip.value.length > maxItemLength) &&
    !errors.some(error => error.type === 'maxItemLength')
  ) {
    errors.push({
      type: 'maxItemLength',
      text: t('react.chips_input.max_item_length_error', {
        maxItemLength,
        default: `One or more entries exceeded the maximum allowed length of <span style="font-weight:bold">${maxItemLength} characters.</span>`,
      }),
    });
  }

  return (
    <>
      <div style={{ width: '100%' }}>
        <div
          /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
          tabIndex="0"
          className={cs('ChipsInputWrapper', {
            'ChipsInputWrapper--focused': isFocused,
            'ChipsInputWrapper--error': errorStatus,
          })}
          data-test-id="theme-input"
          onClick={handleWrapperClick}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        >
          <Row center>
            <Col grow>
              <div className="ChipsInput-chipsContainer">
                {chips.map(chip => {
                  let error = false;
                  if (duplicates.includes(chip.value)) {
                    error = true;
                  }
                  if (maxItemLength && chip.value?.length > maxItemLength) {
                    error = true;
                  }
                  return (
                    <div className={cs('ChipsInput-chipItem', { 'ChipsInput-chipItem--error': error })} key={chip.id}>
                      {error && (
                        <Icon
                          kind="danger"
                          size="14px"
                          color={cssVariables.statusAttentionDefault}
                          style={{ marginRight: '5px' }}
                        />
                      )}
                      {chip.value}
                      <Icon
                        kind="close"
                        size="14px"
                        data-test-id="delete-theme"
                        onClick={() => handleDeleteChip(chip.id)}
                        color={
                          error ? cssVariables.interactiveAttentionDefault : cssVariables.interactivePrimaryDefault
                        }
                        style={{ marginLeft: '4px', cursor: 'pointer' }}
                      />
                    </div>
                  );
                })}
                <input
                  type="text"
                  value={inputValue}
                  onChange={e => setInputValue(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder={chips.length === 0 ? placeholder : ''}
                  className="ChipsInput-input"
                  ref={inputRef}
                  style={{ width: `${inputValue.length * 0.85}ch` }}
                />
              </div>
            </Col>
            <Col shrink>
              <Button onlyIcon secondary icon="duplicate-alt" onClick={handleCopyToClipboard} size="small" />
            </Col>
          </Row>
        </div>
        {(assistiveText || !!errors.length || maxItems) && (
          <Row alignBetween wrap style={{ marginTop: '6px' }} data-test-id="message-info">
            {assistiveText && !errors.length && (
              <Row center padding="s">
                <Col shrink>
                  <Icon color={cssVariables.iconSubtle} kind="info-circle" size="16px" />
                </Col>
                <Col shrink>
                  <Text style={{ color: cssVariables.textSubtle, marginTop: '1px' }}>
                    <span
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: assistiveText }}
                    />
                  </Text>
                </Col>
              </Row>
            )}
            {!!errors.length && (
              <Col>
                {errors.map(error => (
                  <Row center padding="s" style={{ marginBottom: '4px' }} key={error.type}>
                    <Col shrink>
                      <Icon color={cssVariables.statusAttentionDefault} kind="error-circle" size="16px" />
                    </Col>
                    <Col shrink>
                      <Text style={{ color: cssVariables.statusAttentionDefault, marginTop: '1px' }}>
                        <span
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{ __html: error.text }}
                        />
                      </Text>
                    </Col>
                  </Row>
                ))}
              </Col>
            )}
            {maxItems && (
              <Text
                size="xs"
                style={{
                  color: errorStatus ? cssVariables.statusAttentionDefault : cssVariables.textSubtle,
                  marginTop: '2px',
                }}
              >
                {`${chips.length}/${maxItems}`}
              </Text>
            )}
          </Row>
        )}
      </div>
      {chips.map(chip => (
        <input key={chip.key} type="hidden" name={name} value={chip.value} />
      ))}
      {!chips.length && <input type="hidden" name={name} value={null} />}
    </>
  );
};

ChipsInput.propTypes = {
  placeholder: ReactPropTypes.string,
  maxItems: ReactPropTypes.number,
  maxItemLength: ReactPropTypes.number,
  assistiveText: ReactPropTypes.string,
};

export default ChipsInput;
