/* eslint-disable */

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import cs from 'classnames';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Modal, ModalBody, ModalHeader, Badge, Link, Tooltip, Row, Col, Loader } from '../index';
import Tile from './Tile';
import ComplexityMeter from '../graphs/ComplexityMeter';
import { t, formatPercentage, formatNumber } from '../../i18n';

const QUERY_DASHBOARD_RECOMMENDATIONS_QUERY = gql`
  query DashboardOrganizationRecommendations($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      recommendation {
        recommendation
        recommendationType
        onlyActiveCampaigns
        ctaLink
        score
        totalScoreIncrease
        weight
        satisfied
        unsatisfied
        inactive
        ignored
        sortWeight
        rules {
          recommendation
          recommendationType
          ctaLink
          score
          totalScoreIncrease
          weight
          satisfied
          unsatisfied
          inactive
          ignored
          sortWeight
          rules {
            recommendation
            recommendationType
            ctaLink
            score
            totalScoreIncrease
            weight
            satisfied
            unsatisfied
            inactive
            ignored
            sortWeight
          }
        }
      }
    }
  }
`;

const QUERY_DASHBOARD_CAMPAIGN_RECOMMENDATIONS_QUERY = gql`
  query DashboardCampaignRecommendations($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        recommendation {
          recommendation
          recommendationType
          ctaLink
          score
          totalScoreIncrease
          weight
          satisfied
          unsatisfied
          inactive
          ignored
          sortWeight
          rules {
            recommendation
            recommendationType
            ctaLink
            score
            totalScoreIncrease
            weight
            satisfied
            unsatisfied
            inactive
            ignored
            sortWeight
          }
        }
      }
    }
  }
`;

const filterActiveRules = rule => !(rule.recommendationType === 'rule' && rule.inactive === 1);
const sortCampaignsByWeight = (a, b) => a.sortWeight * (100 - a.score) - b.sortWeight * (100 - b.score);
const sortByTotalScoreIncrease = (a, b) => b.totalScoreIncrease - a.totalScoreIncrease;
const Rules = ({ rules }) => (
  <React.Fragment>
    {rules &&
      rules
        .filter(filterActiveRules)
        .filter(rule => rule.recommendationType === 'rule' && rule.score < 100)
        .sort(sortByTotalScoreIncrease)
        .map(rule => <RecommendationItem key={rule.recommendation} {...rule} />)}
  </React.Fragment>
);

const RecommendationItem = ({ recommendation, totalScoreIncrease, score, ctaLink }) => {
  const [showDetail, setShowDetail] = useState(false);
  return (
    <div
      className={cs('Accordion Accordion--smallPadding Accordion--noDelimiter Accordion--hovered', {
        active: showDetail,
      })}
    >
      <div className="Accordion-title" onClick={() => setShowDetail(!showDetail)}>
        <Row center className="mr-8">
          <Col shrink>
            <Tooltip text={formatPercentage(score, { precision: 0 })}>
              <Badge style={{ backgroundColor: getTextColor((score / 100) * 180) }} />
            </Tooltip>
          </Col>
          <Col grow>
            <span className="Text Text--bold">{t(`recommendations.${recommendation}.heading`)}</span>
          </Col>
          <Col shrink nowrap>
            <div className="Text">{formatPercentage(totalScoreIncrease, { precision: 0, format: '+ %n %' })}</div>
          </Col>
          <Col shrink nowrap>
            <a
              href={ctaLink}
              onClick={e => e.stopPropagation()}
              className="Button Button--primaryTable fc-fix ml-32 mr-24"
              data-test-id={`dashboard-recommendationFix-link-${recommendation}`}
            >
              {t('react.dashboard.recommendation.fix')}
            </a>
          </Col>
        </Row>
        <i className={cs('Accordion-chevron', { 'fc-chevron-down': !showDetail, 'fc-chevron-up': showDetail })} />
      </div>
      {showDetail && (
        <div className="Accordion-content">
          <div className="background-soft-gray background-radius pa-8 mt-4">
            {t(`recommendations.${recommendation}.detail`)}
          </div>
        </div>
      )}
    </div>
  );
};

const getTextColor = degree => {
  if (degree < 30) {
    return '#D0021B';
  } else if (degree < 150) {
    return '#FF9B00';
  }
  return '#3ED95E';
};
export class RecommendationsComponent extends React.Component {
  static propTypes = {
    error: PropTypes.string,
    ignored: PropTypes.number,
    inactive: PropTypes.number,
    loading: PropTypes.bool,
    onlyActiveCampaigns: PropTypes.bool,
    recommendationType: PropTypes.string,
    rules: PropTypes.array,
    satisfied: PropTypes.number,
    score: PropTypes.number,
    unsatisfied: PropTypes.number,
    weight: PropTypes.number,
    refetch: PropTypes.func,
  };

  state = {
    isModalShow: false,
  };

  render() {
    const {
      recommendationType,
      onlyActiveCampaigns,
      score,
      weight,
      satisfied,
      unsatisfied,
      inactive,
      ignored,
      refetch,
      rules,
      error,
      loading,
    } = this.props;
    const { isModalShow } = this.state;
    const degree = (score / 100) * 180;

    const activeRules = (rules || []).filter(filterActiveRules);
    const campaignRules = activeRules.filter(rule => rule.recommendationType === 'campaign' && rule.score < 100);

    const modalSubHeading = (
      <span>
        {score >= 0 && (
          <span>
            {t('react.dashboard.recommendation.current_score', {
              default: 'Current score: %{score}',
              score: formatPercentage(score, { precision: 0 }),
            })}{' '}
          </span>
        )}
        {recommendationType === 'organization' &&
          (onlyActiveCampaigns
            ? t('react.dashboard.recommendation.score_only_active_campaigns')
            : t('react.dashboard.recommendation.score_all_campaigns'))}
      </span>
    );

    return (
      <Tile
        header={t('react.dashboard.recommendation.header')}
        refetch={refetch}
        dashboard
        error={error}
        loading={loading}
      >
        <div className="mt-16">
          <ComplexityMeter degree={degree} kind="gradual" width="130px" height="75px" />
        </div>
        <div className="Heading Heading--xl mt-4" style={{ color: getTextColor(degree) }}>
          {formatPercentage(score, { precision: 0 })}
        </div>
        <div className="Text Text--gray">{t('react.dashboard.recommendation.score')}</div>
        {unsatisfied > 0 && (
          <div
            className="Link mt-a"
            data-test-id="dashboard-recommendationsModal-link"
            onClick={() => this.setState({ isModalShow: true })}
          >
            {t('react.dashboard.recommendation.recommendations', {
              recommendations: formatNumber(unsatisfied, { precision: 0 }),
            })}
          </div>
        )}
        {(unsatisfied === 0 || unsatisfied === undefined) && (
          <div className="Text--gray mt-a">{t('react.dashboard.recommendation.no_recommendations')}</div>
        )}

        {isModalShow && (
          <Modal
            heading={t('react.dashboard.recommendation.modal_header')}
            subHeading={modalSubHeading}
            loading={!rules}
            small
            onClose={() => this.setState({ isModalShow: false })}
          >
            <ModalBody>
              <Rules rules={activeRules} />
              {campaignRules.sort(sortCampaignsByWeight).map((rule, index) => (
                <React.Fragment key={rule.recommendation}>
                  {index > 0 && <hr />}
                  <div>
                    <Row className="mt-16 mb-4">
                      <Col grow>
                        <Link href={rule.ctaLink}>
                          <span className="Text--bold">{rule.recommendation}</span>
                        </Link>
                      </Col>
                      <Col width="215px">
                        <span className="Text Text--bold">{formatPercentage(rule.score, { precision: 0 })}</span>
                      </Col>
                    </Row>
                    <div className="pl-16">
                      <Rules rules={rule.rules} />
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </ModalBody>
          </Modal>
        )}
      </Tile>
    );
  }
}

export const Recommendations = ({ organizationId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_RECOMMENDATIONS_QUERY}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
    }}
  >
    {({ error, data, loading, refetch }) => (
      <RecommendationsComponent
        loading={loading}
        refetch={() => refetch()}
        error={error}
        {...rest}
        {...((data && data.organization && data.organization.recommendation) || {})}
      />
    )}
  </Query>
);

Recommendations.propTypes = {
  organizationId: PropTypes.number.isRequired,
};

export const RecommendationsCampaign = ({ organizationId, campaignSettingId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_CAMPAIGN_RECOMMENDATIONS_QUERY}
    variables={{
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
      campaignSettingId: campaignSettingId ? parseInt(campaignSettingId, 10) : 0,
    }}
  >
    {({ error, data, loading }) => (
      <RecommendationsComponent
        loading={loading}
        error={error}
        {...rest}
        {...((data &&
          data.organization &&
          data.organization.campaignSetting &&
          data.organization.campaignSetting.recommendation) ||
          {})}
      />
    )}
  </Query>
);

RecommendationsCampaign.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
};
