/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { gql, NetworkStatus } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import chevron from '../scss_stylesheets/images/chevron_right.svg';
import {
  Row,
  Col,
  Button,
  Icon,
  Tooltip,
  Switch,
  InfoBox,
  Ellipsis,
  UsedByGraph,
  ErrorLoadingQuote,
} from '../components';
import { t, formatPercentage, formatInteger, formatCurrency, formatNumberInflection } from '../i18n';
import { modalDataUrlOpener } from '../components/RemoteModal';

// eslint-disable-next-line react/prop-types
const QueryHolder = WrappedComponent => ({ query, variables, ...rest }) => (
  <Query query={query} variables={variables} notifyOnNetworkStatusChange>
    {({ error, data, refetch, networkStatus }) => {
      const loading = networkStatus !== NetworkStatus.ready;
      if (loading) {
        return (
          <div className="Preview-item">
            <div
              className="loading-status Loader Loader--small h-auto w-auto"
              style={{ left: `${rest.depth * 30 + 16}px`, top: 0 }}
            >
              {t('react.pla_campaign_preview.loading')}
            </div>
          </div>
        );
      }
      if (error) {
        return (
          <div className="Preview-item">
            <ErrorLoadingQuote onClick={() => refetch()} />
          </div>
        );
      }

      return <WrappedComponent data={data} refetch={() => refetch()} {...variables} {...rest} />;
    }}
  </Query>
);

const QUERY_CAMPAIGNS = gql`
  query OrganizationFBStoreCampaigns($organizationId: BigInt!, $campaignSettingId: BigInt!, $limit: Int!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        itemsCount
        fbActiveSummaryCount
        facebookCurrency
        fbStoreCampaignCount
        fbStoreAdSetCount
        fbSetting {
          budgetLevel
        }
        fbStoreCampaigns(size: $limit) {
          id
          name
          productCount
          productCountPercent
          fbStoreAdSetsCount
          syncError
          status
          activeFbBudget {
            name
            description
          }
        }
      }
    }
  }
`;

const QUERY_ADSETS = gql`
  query OrganizationFBStoreAdSets(
    $organizationId: BigInt!
    $campaignSettingId: BigInt!
    $fbStoreCampaignId: BigInt!
    $limit: Int!
  ) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        itemsCount
        fbStoreCampaign(id: $fbStoreCampaignId) {
          id
          fbStoreAdSetsCount
          fbStoreAdSets(size: $limit) {
            id
            name
            productCount
            productCountPercent
            status
            fbProductSet {
              id
              name
              compoundConditionsElastic
              conditions {
                json
              }
              syncProductSetError
            }
            syncAdSetError
            syncAdTextError
            activeFbBudget {
              id
              name
              period
              budget
              editLink
              duplicateLink
              description
            }
            activeFbAdtext {
              id
              name
              editLink
              duplicateLink
              description
            }
            fbAdSetFbAdtexts {
              id
              syncError
              fbAdtext {
                id
                name
                editLink
                duplicateLink
                description
              }
            }
            activeFbOptimization {
              id
              name
              editLink
              duplicateLink
              description
            }
            activeFbPlacement {
              id
              name
              editLink
              duplicateLink
              description
            }
            activeFbAudience {
              id
              name
              editLink
              duplicateLink
              description
            }
          }
        }
      }
    }
  }
`;

const StatusSwitch = ({ id, status }) => (
  <div className="pos-relative">
    <Switch name={`status_switch_${id}`} disabled pausePlay value={status === 'running'} />
  </div>
);

StatusSwitch.propTypes = {
  id: PropTypes.number,
  status: PropTypes.oneOf(['running', 'paused']),
};

const SummaryLine = QueryHolder(({ data }) => {
  const { fbStoreCampaignCount, fbStoreAdSetCount, facebookCurrency } = data.organization.campaignSetting;
  const { campaignDaily, adSetDaily, campaignLifetime, adSetLifetime } = JSON.parse(
    data.organization.campaignSetting.fbActiveSummaryCount || '{}'
  );

  return (
    <div className="Tile Tile--shadow mb-24">
      <Row justifyAround center className="pa-16">
        <Col shrink>
          <Row center padding="l">
            <Col shrink>
              <Icon kind="fb-campaign" size="32px" color="#74748C" />
            </Col>
            <Col>
              <div className="Text Text--gray Text--bold">
                {t('react.fb_campaign_preview.campaigns_count', { count: formatInteger(fbStoreCampaignCount) })}
              </div>
            </Col>
          </Row>
        </Col>
        <Col shrink>
          <Row center padding="l">
            <Col shrink>
              <Icon kind="adset" size="32px" color="#74748C" />
            </Col>
            <Col>
              <div className="Text Text--gray Text--bold">
                {formatNumberInflection({
                  count: fbStoreAdSetCount,
                  scope: 'react.fb_campaign_preview.adset_overall_inflected',
                  zeroIsSpecial: true,
                })}
              </div>
            </Col>
          </Row>
        </Col>
        <Col shrink>
          <Row center padding="l">
            <Col shrink>
              <Icon kind="budget" size="32px" color="#74748C" />
            </Col>
            <Col padding="s">
              <Row>
                <div className="Text Text--bold Text--gray">
                  {t('react.fb_campaign_preview.daily_budget', {
                    budget: formatCurrency(campaignDaily || adSetDaily || 0, { unit: facebookCurrency }),
                  })}
                </div>
              </Row>
              <Row>
                <div className="Text Text--bold Text--gray">
                  {t('react.fb_campaign_preview.lifetime_budget', {
                    budget: formatCurrency(campaignLifetime || adSetLifetime || 0, { unit: facebookCurrency }),
                  })}
                </div>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
});

SummaryLine.propTypes = {
  data: PropTypes.object,
};

export default class FBRetargetingCampaignPreview extends React.Component {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    campaignSettingId: PropTypes.number.isRequired,
  };

  state = {
    limit: 5,
  };

  showAll = count => this.setState({ limit: count });

  render() {
    const { organizationId, campaignSettingId } = this.props;
    const { limit } = this.state;

    return (
      <React.Fragment>
        <SummaryLine query={QUERY_CAMPAIGNS} variables={{ organizationId, campaignSettingId, limit: 10000 }} />
        <CampaignList
          query={QUERY_CAMPAIGNS}
          limit={limit}
          showAll={this.showAll}
          variables={{ organizationId, campaignSettingId, limit }}
        />
      </React.Fragment>
    );
  }
}

let fbStoreCampaignsInterval = null;

const CampaignList = QueryHolder(({ data, refetch, showAll, limit, ...rest }) => {
  const { fbStoreCampaigns, fbSetting, fbStoreCampaignCount, itemsCount } = data.organization.campaignSetting;

  if (fbStoreCampaignCount > limit) {
    showAll(fbStoreCampaignCount);
  }

  if (fbStoreCampaignsInterval) {
    clearInterval(fbStoreCampaignsInterval);
  }

  if (fbStoreCampaigns.length === 0) {
    fbStoreCampaignsInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_campaigns_generated_yet')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <div className="Tile Tile--shadow pb-4">
      <Row center className="delimeter mb-4 pa-16">
        <Col grow>
          <div className="Text--gray Text--bold">{t('react.fb_campaign_preview.campaigns')}</div>
        </Col>
        {fbSetting.budgetLevel && (
          <Col width="250px">
            <div className="Text--gray Text--bold">{t('react.fb_campaign_preview.campaign_budget')}</div>
          </Col>
        )}
        <Col width="130px">
          <div className="Text--gray Text--bold">{t('react.fb_campaign_preview.campaign_ad_sets')}</div>
        </Col>
        <Col width="314px">
          <div className="Text--gray Text--bold">
            {t('react.fb_campaign_preview.campaign_products_count', {
              count: formatInteger(itemsCount),
            })}
          </div>
        </Col>
      </Row>

      {fbStoreCampaigns.map((campaign, index) => (
        <CampaignLine
          budgetLevel={fbSetting.budgetLevel}
          itemsCount={data.organization.campaignSetting.itemsCount}
          allAdSetsCount={data.organization.campaignSetting.fbStoreAdSetCount}
          key={campaign.id}
          campaignIndex={index}
          {...rest}
          {...campaign}
        />
      ))}
    </div>
  );
});

CampaignList.propTypes = {
  data: PropTypes.any,
  refetch: PropTypes.func.isRequired,
  showAll: PropTypes.func,
  limit: PropTypes.number,
};

class CampaignLine extends React.Component {
  static propTypes = {
    activeFbBudget: PropTypes.object,
    allAdSetsCount: PropTypes.number,
    budgetLevel: PropTypes.oneOf(['campaign_budget', 'ad_set_budget']),
    campaignIndex: PropTypes.number,
    campaignSettingId: PropTypes.number,
    fbStoreAdSetsCount: PropTypes.number,
    id: PropTypes.number,
    itemsCount: PropTypes.number,
    name: PropTypes.string,
    organizationId: PropTypes.number,
    productCount: PropTypes.number,
    productCountPercent: PropTypes.number,
    syncError: PropTypes.string,
    status: PropTypes.oneOf(['running', 'paused']),
  };

  state = {
    limit: 15,
    show: true,
  };

  componentWillMount() {
    if (this.props.campaignIndex === 0) {
      this.setState({ show: true });
    }
  }

  render() {
    const {
      allAdSetsCount,
      campaignSettingId,
      fbStoreAdSetsCount,
      id,
      itemsCount,
      name,
      activeFbBudget,
      organizationId,
      productCount,
      productCountPercent,
      budgetLevel,
      syncError,
      status,
      ...rest
    } = this.props;

    const { show, limit } = this.state;

    const limitedProductsByTotal = parseInt(productCount, 10) < itemsCount ? parseInt(productCount, 10) : itemsCount;
    return (
      <React.Fragment>
        <div
          className={cs('Preview-item', 'Preview-item--link', 'Preview-item--bold', 'Preview-item--topBorder', 'pl-8', {
            open: show,
          })}
          data-test-id="campaign_line"
          onClick={() => this.setState({ show: !show })}
        >
          <Row center>
            <Col shrink>
              <img src={chevron} className={cs('Preview-chevron', { rotate: show })} alt="chevron" />
            </Col>
            {syncError && (
              <Col shrink>
                <Tooltip text={syncError}>
                  <Icon kind="warning" color="#FF493F" />
                </Tooltip>
              </Col>
            )}
            <Col shrink>
              <Icon kind="fb-campaign" className="line-height-1" />
            </Col>
            <Col grow data-test-id="campaign_name">
              {name}
            </Col>
            <Col width="250px" data-test-id="campaign_budget">
              {budgetLevel === 'campaign_budget' && activeFbBudget && activeFbBudget.name && (
                <div className="Text Text--normal">
                  <Ellipsis>
                    {activeFbBudget.name}: {activeFbBudget.description}
                  </Ellipsis>
                </div>
              )}
            </Col>
            <Col width="130px">
              <div className="Text Text--normal" data-test-id="campaign_adsets">
                {formatNumberInflection({
                  count: fbStoreAdSetsCount,
                  scope: 'react.fb_campaign_preview.adsets_inflected',
                  zeroIsSpecial: true,
                })}
              </div>
            </Col>
            <Col width="260px">
              <div className="Text Text--normal">
                <Row center padding="s">
                  <Col shrink>
                    <UsedByGraph
                      size="lg"
                      products={limitedProductsByTotal}
                      productsTotal={itemsCount}
                      adSets={fbStoreAdSetsCount}
                      adSetsTotal={allAdSetsCount}
                      disableTooltip
                    />
                  </Col>
                  <Col shrink>
                    {t('react.fb_campaign_preview.products_count', {
                      count: formatInteger(limitedProductsByTotal),
                      percent: formatPercentage(productCountPercent <= 100 ? productCountPercent : 100, {
                        precision: 2,
                      }),
                    })}
                  </Col>
                </Row>
              </div>
            </Col>
            <Col width="30px" className="mr-32">
              <StatusSwitch status={status} id={id} />
            </Col>
          </Row>
        </div>

        {show && (
          <AdSetList
            {...rest}
            budgetLevel={budgetLevel}
            depth={0}
            query={QUERY_ADSETS}
            variables={{ organizationId, campaignSettingId, fbStoreCampaignId: id, limit }}
            showAll={count => this.setState({ limit: count })}
            limit={limit}
          />
        )}
      </React.Fragment>
    );
  }
}

let fbStoreAdSetInterval = null;

const AdSetList = QueryHolder(({ depth, data, refetch, limit, showAll, ...rest }) => {
  const { fbStoreAdSets, fbStoreAdSetsCount } = data.organization.campaignSetting.fbStoreCampaign;

  if (fbStoreAdSetInterval) {
    clearInterval(fbStoreAdSetInterval);
  }

  if (fbStoreAdSets.length === 0) {
    fbStoreAdSetInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item ph-16">
        {t('react.pla_campaign_preview.no_adgroup_product_dimension_generated')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <div data-test-id="adset-container">
      {fbStoreAdSets.map((adSet, index) => (
        <AdSetLine
          campaignSettingId={data.organization.campaignSetting.id}
          depth={depth}
          index={index}
          itemsCount={data.organization.campaignSetting.itemsCount}
          key={adSet.id}
          {...rest}
          {...adSet}
        />
      ))}

      {fbStoreAdSetsCount > limit && (
        <div
          className="Preview-item Preview-item--link Preview-item--topBorder pl-24"
          onClick={() => showAll(fbStoreAdSetsCount)}
          style={{ marginLeft: `${(depth + 1) * 30}px` }}
        >
          {t('react.pla_campaign_preview.show_all')} ({fbStoreAdSetsCount - limit})
        </div>
      )}
    </div>
  );
});

AdSetList.propTypes = {
  data: PropTypes.any,
  depth: PropTypes.number,
  limit: PropTypes.number,
  productCount: PropTypes.number,
  refetch: PropTypes.func.isRequired,
  showAll: PropTypes.func,
};

class AdSetLine extends React.Component {
  static propTypes = {
    activeFbAdtext: PropTypes.object,
    activeFbAudience: PropTypes.object,
    activeFbBudget: PropTypes.object,
    activeFbOptimization: PropTypes.object,
    activeFbPlacement: PropTypes.object,
    budgetLevel: PropTypes.oneOf(['campaign_budget', 'ad_set_budget']),
    fbProductSet: PropTypes.object,
    depth: PropTypes.number,
    fbAdSetFbAdtexts: PropTypes.array,
    id: PropTypes.number,
    itemsCount: PropTypes.number,
    name: PropTypes.string,
    status: PropTypes.oneOf(['running', 'paused']),
    organizationId: PropTypes.number,
    campaignSettingId: PropTypes.number,
    productCount: PropTypes.number,
    productCountPercent: PropTypes.number,
    syncAdSetError: PropTypes.string,
    syncAdTextError: PropTypes.string,
  };

  state = {
    show: false,
  };

  render() {
    const {
      id,
      itemsCount,
      fbProductSet,
      campaignSettingId,
      budgetLevel,
      depth,
      syncAdSetError,
      syncAdTextError,
      activeFbAdtext,
      activeFbAudience,
      activeFbBudget,
      activeFbOptimization,
      activeFbPlacement,
      fbAdSetFbAdtexts,
      name,
      status,
      organizationId,
      productCount,
      productCountPercent,
    } = this.props;

    const { show } = this.state;

    const limitedProductsByTotal = parseInt(productCount, 10) < itemsCount ? parseInt(productCount, 10) : itemsCount;
    return (
      <div
        className={cs('Preview-item--bold', {
          'Preview-tileItem Preview-tileItem--gray Preview-tileItem--link pa-16 mr-16': show,
          'Preview-item Preview-item--link Preview-item--hiddenBorder Preview-item--topBorder Preview-item--animation pl-16': !show,
        })}
        data-test-id="adset-line"
        style={{ marginLeft: `${(depth + 1) * 30}px` }}
        onClick={() => this.setState({ show: !show })}
      >
        <Row center className={show && 'mb-16'}>
          <Col shrink>
            <img src={chevron} className={cs('Preview-chevron', { rotate: show })} alt="chevron" />
          </Col>
          {(fbProductSet?.syncProductSetError || syncAdSetError || syncAdTextError) && (
            <Col shrink>
              <Tooltip text={fbProductSet?.syncProductSetError || syncAdSetError || syncAdTextError}>
                <Icon kind="warning" color="#FF493F" size={18} />
              </Tooltip>
            </Col>
          )}
          <Col shrink>
            <Icon kind="adset" className="line-height-1" />
          </Col>
          <Col shrink data-test-id="adset-name">
            {name}
          </Col>
          <Col grow data-test-id="product-set-name" className="ml-24">
            <span>
              <Icon kind="product-set" className="mr-8" />
              {fbProductSet.name}
            </span>
          </Col>
          <Col width="260px">
            <div className="Text Text--normal">
              <Row center padding="l">
                <Col shrink className="mr-4">
                  <UsedByGraph
                    size="lg"
                    products={limitedProductsByTotal}
                    productsTotal={parseInt(itemsCount, 10)}
                    disableTooltip
                  />
                </Col>
                <Col shrink>
                  {t('react.fb_campaign_preview.products_count', {
                    count: formatInteger(limitedProductsByTotal),
                    percent: formatPercentage(productCountPercent <= 100 ? productCountPercent : 100, { precision: 2 }),
                  })}
                </Col>
                <Col shrink>
                  <Button
                    tertiary
                    onlyIcon
                    icon="magnifier"
                    data-trigger="react-preview-item"
                    data-organization-id={organizationId}
                    data-campaign-setting-id={campaignSettingId}
                    data-conditions={JSON.stringify((fbProductSet?.conditions || []).map(c => c.json))}
                    data-elastic-query={JSON.stringify(fbProductSet?.compoundConditionsElastic || {})}
                  />
                </Col>
              </Row>
            </div>
          </Col>
          <Col width="30px" className={cs({ 'mr-32': !show })}>
            <StatusSwitch status={status} id={id} />
          </Col>
        </Row>
        {show && (
          <React.Fragment>
            {budgetLevel === 'ad_set_budget' && (
              <FeatureLine translationKey="missing_fbbudgets" data={activeFbBudget} depth={depth} icon="dollar" />
            )}
            <FeatureLine
              translationKey="missing_fbaudiences"
              data={activeFbAudience}
              depth={depth}
              icon="users-group"
            />
            <FeatureLine translationKey="missing_fbplacements" data={activeFbPlacement} depth={depth} icon="columns" />
            <FeatureLine
              translationKey="missing_fboptimizations"
              data={activeFbOptimization}
              depth={depth}
              icon="fix"
            />
            {!activeFbAdtext && (!fbAdSetFbAdtexts || fbAdSetFbAdtexts.length === 0) && (
              <FeatureLine translationKey="missing_fbadtexts" depth={depth} icon="adtext" />
            )}
            {activeFbAdtext && (
              <FeatureLine
                translationKey="missing_fbadtexts"
                data={activeFbAdtext}
                depth={depth}
                icon="adtext"
                error={syncAdTextError}
              />
            )}
            {fbAdSetFbAdtexts &&
              fbAdSetFbAdtexts.map(fbAdSetFbAdtext => (
                <FeatureLine
                  translationKey="missing_fbadtexts"
                  key={fbAdSetFbAdtext.id}
                  data={fbAdSetFbAdtext.fbAdtext}
                  error={fbAdSetFbAdtext.syncError}
                  depth={depth}
                  icon="adtext"
                />
              ))}
          </React.Fragment>
        )}
      </div>
    );
  }
}

class FeatureLine extends React.Component {
  static propTypes = {
    depth: PropTypes.number,
    data: PropTypes.object,
    icon: PropTypes.string,
    error: PropTypes.string,
    translationKey: PropTypes.string,
  };

  render() {
    const { depth, icon, data, error, translationKey } = this.props;

    if (!data) {
      return (
        <div
          className="mb-8 cursor-auto"
          style={{ marginLeft: `${(depth + 1) * 48}px` }}
          onClick={e => e.stopPropagation()}
        >
          <InfoBox type="warning">
            <div>
              <Icon kind={icon} color="#74748c" size="15px" className="mr-4" />{' '}
              {translationKey
                ? t(`react.fb_manual_campaign_preview.${translationKey}`)
                : t('react.fb_campaign_preview.missing_data')}
            </div>
          </InfoBox>
        </div>
      );
    }

    const { name, editLink, duplicateLink, description } = data;

    return (
      <div
        className="Preview-tileItem"
        style={{ marginLeft: `${(depth + 1) * 48}px` }}
        onClick={e => e.stopPropagation()}
      >
        <Row center>
          {error && (
            <Col shrink>
              <Tooltip text={error}>
                <Icon kind="warning" color="#FF493F" />
              </Tooltip>
            </Col>
          )}
          <Col shrink>
            <Icon kind={icon} color="#74748c" size="15px" className="mr-4" />
          </Col>
          <Col grow noPadding>
            <Row data-test-id="feature-line-name">{name}</Row>
            <Row>
              <div className="Text Text--gray w-100">
                <Ellipsis>{description}</Ellipsis>
              </div>
            </Row>
          </Col>
          {editLink && (
            <Col shrink className="mr-16">
              <Tooltip text={t('react.fb_campaign_preview.edit')}>
                <Button
                  tertiary
                  onlyIcon
                  icon="edit"
                  {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}
                />
              </Tooltip>
            </Col>
          )}
          {duplicateLink && (
            <Col shrink>
              <Tooltip text={t('react.fb_campaign_preview.duplicate')}>
                <Button
                  tertiary
                  onlyIcon
                  icon="duplicate"
                  onClick={e => e.stopPropagation()}
                  {...modalDataUrlOpener({ url: duplicateLink, size: 'fullwithGrid' })}
                />
              </Tooltip>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
