import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Tile, SmallHeading, SectionHeading, SmartSelect, Button, Chip, Text } from '../index';
import { t } from '../../i18n';
import { generateDimensions, generateMetrics } from './utils';

const ChipsGroup = ({ collection, selectedCollection = [], handleChange, loading, testId }) => (
  <div className="mt-8" data-test-id={testId}>
    {collection.map(c => (
      <Chip
        key={[testId, c.value].join('-')}
        className="mr-4 mb-8"
        onChange={({ target: { value } }) => handleChange({ state: value, value: c.value })}
        id={c.value}
        value={selectedCollection.indexOf(c.value) !== -1}
        data-test-id={[testId, c.value].join('-')}
        label={c?.label}
        disabled={loading}
        doNotUseInternalState
      />
    ))}
  </div>
);

const parseData = (data, tempState, setTempState, generateFunc) => {
  const generatedData = generateFunc(data).map(d => ({
    value: d.optionValue,
    label: d?.data?.label || d.label,
    isMain: d.isMain,
  }));

  const mainGeneratedData = generatedData.filter(d => d.isMain);
  const additionalGeneratedData = generatedData.filter(d => !d.isMain);

  const mainData = tempState.filter(d => mainGeneratedData.find(gd => gd.value === d));
  const additionalData = tempState.filter(d => additionalGeneratedData.find(gd => gd.value === d));

  const onChange = ({ value, state }) => {
    if (state) {
      setTempState([...mainData, value].concat(additionalData));
    } else {
      setTempState(tempState.filter(d => d !== value));
    }
  };

  return [generatedData, mainGeneratedData, additionalGeneratedData, mainData, additionalData, onChange];
};

const Form = ({
  handleSubmit,
  tableVariablesSorted,
  variablesLoading,
  dimensions,
  metrics,
  loading,
  disableHeading,
  tempMetrics,
  tempDimensions,
  setTempMetrics,
  setTempDimensions,
  children,
  inDropdown,
}) => {
  const [
    generatedDimensions,
    mainGeneratedDimensions,
    additionalGeneratedDimensions,
    mainDimensions,
    additionalDimensions,
    onDimensionChange,
  ] = parseData(tableVariablesSorted, tempDimensions, setTempDimensions, generateDimensions);

  const [
    generatedMetrics,
    mainGeneratedMetrics,
    additionalGeneratedMetrics,
    mainMetrics,
    additionalMetrics,
    onMetricChange,
  ] = parseData(tableVariablesSorted, tempMetrics, setTempMetrics, generateMetrics);

  return (
    <Tile style={{ maxWidth: '800px', minWidth: '590px' }} className={cs({ 'elevate-2': inDropdown })}>
      {!disableHeading && (
        <React.Fragment>
          <SectionHeading spacing={4}>{t('data_analysis.data_explorer')}</SectionHeading>
          <Text>{t('data_analysis.data_explorer_detail')}</Text>
        </React.Fragment>
      )}
      <SmallHeading className={cs({ 'mt-16': !disableHeading })}>{t('react.data_analysis.dimensions')}</SmallHeading>
      <ChipsGroup
        handleChange={onDimensionChange}
        collection={mainGeneratedDimensions}
        selectedCollection={mainDimensions}
        loading={variablesLoading}
        testId="main-dimensions"
      />
      <SmartSelect
        multiple
        key={[generatedDimensions.map(gd => gd.value).join('-'), dimensions.join('-')].join(';')}
        placeholder={t('data_analysis.other_dimensions_placeholder')}
        id="dimensions"
        name="data_analysis[additional_dimensions]"
        collection={additionalGeneratedDimensions}
        isLoading={variablesLoading}
        onChange={({ target: { value } }) => setTempDimensions(mainDimensions.concat(value))}
        value={additionalDimensions}
        disabled={variablesLoading}
      />
      <SmallHeading className="mt-16">{t('react.data_analysis.metrics')}</SmallHeading>
      <ChipsGroup
        handleChange={onMetricChange}
        collection={mainGeneratedMetrics}
        selectedCollection={mainMetrics}
        loading={variablesLoading}
        testId="main-metrics"
      />
      <SmartSelect
        placeholder={t('data_analysis.other_metrics_placeholder')}
        key={['other', generatedMetrics.map(gd => gd.value).join('-'), metrics.join('-')].join(';')}
        multiple
        id="metrics"
        name="data_analysis[additional_metrics]"
        isLoading={variablesLoading}
        onChange={({ target: { value } }) => setTempMetrics(mainMetrics.concat(value))}
        value={additionalMetrics}
        collection={additionalGeneratedMetrics}
        disabled={variablesLoading}
      />
      <Button
        onClick={handleSubmit}
        className="mt-16"
        kind="primary"
        type="button"
        disabled={variablesLoading || loading || (tempMetrics.length === 0 && tempDimensions.length === 0)}
        data-test-id="data-analysis-generate-btn"
      >
        {t('react.data_analysis.generate')}
      </Button>
      {children}
    </Tile>
  );
};

Form.propTypes = {
  dimensions: PropTypes.array,
  disableHeading: PropTypes.bool,
  handleSubmit: PropTypes.func,
  children: PropTypes.any,
  inDropdown: PropTypes.bool,
  loading: PropTypes.bool,
  metrics: PropTypes.array,
  setTempDimensions: PropTypes.func,
  setTempMetrics: PropTypes.func,
  tableVariablesSorted: PropTypes.array,
  tempDimensions: PropTypes.array,
  tempMetrics: PropTypes.array,
  variablesLoading: PropTypes.bool,
};
ChipsGroup.propTypes = {
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectedCollection: PropTypes.array,
  testId: PropTypes.string,
};

export default Form;
