import { setupColumn } from './setupColumn';
import { IntegerCell, MoneyCell, FloatCell, PercentageCell } from './cells';
import { t } from '../../../i18n';
import { adwordsHeader, sklikHeader, bingHeader, facebookHeader } from './systemHeaders';

const generateStatsColumns = (systemPrefix = '') => {
  const getHeader = text => {
    if (systemPrefix === 'adwords') {
      return adwordsHeader(text);
    }
    if (systemPrefix === 'facebook') {
      return facebookHeader(text);
    }
    if (systemPrefix === 'bing') {
      return bingHeader(text);
    }
    if (systemPrefix === 'sklik') {
      return sklikHeader(text);
    }
    return text;
  };

  let prefix = '';
  if (systemPrefix.length > 0) {
    prefix = `${systemPrefix}_`;
  }
  const category = `${prefix}performance_stats`;
  const fieldWithPrefix = field => {
    if (systemPrefix.length > 0) {
      return systemPrefix + field.charAt(0).toUpperCase() + field.slice(1);
    }
    return field;
  };

  return {
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsClicks'),
      graphqlFields: { stats: [fieldWithPrefix('clicks')] },
      required: false,
      cell: IntegerCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}clicks::Float`,
      header: () => getHeader(t('stats_metrics.clicks')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('clicks')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsImpressions'),
      graphqlFields: { stats: [fieldWithPrefix('impressions')] },
      required: false,
      cell: IntegerCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}impressions::Float`,
      header: () => getHeader(t('stats_metrics.impressions')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('impressions')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsConversions'),
      graphqlFields: { stats: [fieldWithPrefix('conversions')] },
      required: false,
      cell: FloatCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}conversions::Float`,
      header: () => getHeader(t('stats_metrics.conversions')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('conversions')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsSpend'),
      graphqlFields: { stats: [fieldWithPrefix('spend'), 'currency'] },
      required: false,
      cell: MoneyCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}price::Float`,
      header: () => getHeader(t('stats_metrics.price')),
      resolveChildren: ({ stats }) =>
        (stats && { money: stats[fieldWithPrefix('spend')], currency: stats.currency }) || {},
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsAvgCPC'),
      graphqlFields: { stats: [fieldWithPrefix('avgCpc'), 'currency'] },
      required: false,
      cell: MoneyCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}avg_cpc::Float`,
      header: () => getHeader(t('stats_metrics.avgCpc')),
      resolveChildren: ({ stats }) =>
        (stats && { money: stats[fieldWithPrefix('avgCpc')], currency: stats.currency }) || {},
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsConversionValue'),
      graphqlFields: { stats: [fieldWithPrefix('conversionValue')] },
      required: false,
      cell: FloatCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}conversion_value::Float`,
      header: () => getHeader(t('stats_metrics.conversion_value')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('conversionValue')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsCtr'),
      graphqlFields: { stats: [fieldWithPrefix('ctr')] },
      required: false,
      cell: PercentageCell,
      order: `cache_performane_stats->${prefix}ctr::Float`,
      orderedByDescFirst: true,
      header: () => getHeader(t('stats_metrics.ctr')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('ctr')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsCpa'),
      graphqlFields: { stats: [fieldWithPrefix('cpa')] },
      required: false,
      cell: MoneyCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}cpa::Float`,
      header: () => getHeader(t('stats_metrics.cpa')),
      resolveChildren: ({ stats }) =>
        (stats && { money: stats[fieldWithPrefix('cpa')], currency: stats.currency }) || {},
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsConversionRate'),
      graphqlFields: { stats: [fieldWithPrefix('conversionRate')] },
      required: false,
      cell: PercentageCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}conversion_rate::Float`,
      header: () => getHeader(t('stats_metrics.conversions_rate')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('conversionRate')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsConversionPNO'),
      graphqlFields: { stats: [fieldWithPrefix('conversionPno')] },
      required: false,
      cell: PercentageCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}conversion_pno::Float`,
      header: () => getHeader(t('stats_metrics.conversion_pno')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('conversionPno')],
    }),
    ...setupColumn({
      category,
      id: fieldWithPrefix('statsConversionValueCost'),
      graphqlFields: { stats: [fieldWithPrefix('conversionValueCost')] },
      required: false,
      cell: PercentageCell,
      orderedByDescFirst: true,
      order: `cache_performane_stats->${prefix}conversion_value_cost::Float`,
      header: () => getHeader(t('stats_metrics.roas')),
      resolveChildren: ({ stats }) => stats && stats[fieldWithPrefix('conversionValueCost')],
    }),
  };
};
export default generateStatsColumns;
