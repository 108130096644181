import React from 'react';
import useInputState from '../useInputState';
import FBManualCampaigns from './FBManualCampaigns';

const FBMainManualCampaigns = props => {
  const { state: budgetType } = useInputState({
    name: 'budget_level',
    defaultValue: 'campaign_budget',
  });

  return <FBManualCampaigns {...props} budgetType={budgetType} />;
};

FBMainManualCampaigns.propTypes = {};

export default FBMainManualCampaigns;
