/* eslint-disable react/prop-types */
import { createAdtextFieldText, createAdtextFieldOverflow } from './createAdtextField';

const creator = createAdtextFieldText('ad_expanded_adtext');
const creatorOverflow = createAdtextFieldOverflow('ad_expanded_adtext');

const mapping = {
  ...creator({ field: 'expandedHeadline1', updateField: 'headline1' }),
  ...creator({ field: 'expandedHeadline2', updateField: 'headline2' }),
  ...creator({ field: 'expandedHeadline3', updateField: 'headline3' }),
  ...creator({ field: 'expandedDescription1', updateField: 'description' }),
  ...creator({ field: 'expandedDescription2', updateField: 'description2' }),
  ...creator({ field: 'expandedPath1', updateField: 'path1' }),
  ...creator({ field: 'expandedPath2', updateField: 'path2' }),

  ...creatorOverflow({ field: 'expandedOverflowHeadline1', updateField: 'overflowHeadline1' }),
  ...creatorOverflow({ field: 'expandedOverflowHeadline2', updateField: 'overflowHeadline2' }),
  ...creatorOverflow({ field: 'expandedOverflowDescription1', updateField: 'overflowDescription' }),
  ...creatorOverflow({ field: 'expandedOverflowHeadline3', updateField: 'overflowHeadline3' }),
  ...creatorOverflow({ field: 'expandedOverflowDescription2', updateField: 'overflowDescription2' }),
};

export default mapping;
