/* eslint-disable react/no-danger */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const SimpleTooltip = ({ children, className, placement = 'top', text }) => {
  const [visible, setVisible] = useState(false);
  const [show, setShow] = useState(false); // Additional state for controlling fade in/out
  const componentRef = useRef(null);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const component = componentRef.current;

    const showTooltip = () => {
      setShow(true);
      setTimeout(() => setVisible(true), 30); // Small delay to allow reflow/repaint
    };
    const hideTooltip = () => {
      setVisible(false);
      setTimeout(() => setShow(false), 300); // Delay to allow fade out animation
    };

    if (component) {
      component.addEventListener('mouseenter', showTooltip);
      component.addEventListener('mouseleave', hideTooltip);
    }

    return () => {
      if (component) {
        component.removeEventListener('mouseenter', showTooltip);
        component.removeEventListener('mouseleave', hideTooltip);
      }
    };
  }, []);

  useEffect(() => {
    if (show && tooltipRef.current && componentRef.current) {
      const tooltip = tooltipRef.current;
      const component = componentRef.current;
      const rect = component.getBoundingClientRect();
      const bodyWidth = document.body.clientWidth;
      const tooltipWidth = tooltip.offsetWidth;

      const positions = {
        top: {
          left: rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2,
          top: rect.top + window.scrollY - tooltip.offsetHeight,
        },
        bottom: {
          left: rect.left + window.scrollX + rect.width / 2 - tooltip.offsetWidth / 2,
          top: rect.bottom + window.scrollY,
        },
        left: {
          left: rect.left + window.scrollX - tooltip.offsetWidth,
          top: rect.top + window.scrollY + rect.height / 2 - tooltip.offsetHeight / 2,
        },
        right: {
          left: rect.right + window.scrollX,
          top: rect.top + window.scrollY + rect.height / 2 - tooltip.offsetHeight / 2,
        },
      };

      // eslint-disable-next-line prefer-const
      let { top, left } = positions[placement];

      // Adjust if the tooltip is out of the viewport
      if (left < 0) {
        left = 5;
      } else if (left + tooltipWidth > bodyWidth) {
        left = bodyWidth - tooltipWidth - 5;
      }

      // Set the final position
      tooltip.style.top = `${top}px`;
      tooltip.style.left = `${left}px`;
    }
  }, [show, placement]);

  return (
    <span className={className} ref={componentRef}>
      {children}
      {show &&
        createPortal(
          <div
            ref={tooltipRef}
            className={`SimpleTooltip SimpleTooltip--${placement} ${visible ? 'SimpleTooltip--visible' : ''}`}
            style={{ position: 'absolute' }} // Ensure absolute positioning
            dangerouslySetInnerHTML={{ __html: text }}
          />,
          document.body
        )}
    </span>
  );
};

SimpleTooltip.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  text: PropTypes.string.isRequired,
};

export default SimpleTooltip;
