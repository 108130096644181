import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import cs from 'classnames';
import { gql, useMutation } from '@apollo/client';
import { Col } from '../index';
import { Checkbox } from '../form/';
import { Loader } from '../Icons';
import { MultiEditProvider, useMultiEdit, ACTIONS } from './MultiEditContext';
import { t } from '../../i18n';
import MultiEditBox from './MultiEditBox';
import { ConfirmationModal, useConfirmationModal } from '../confirmationModal/ConfirmationModal';

export const STATUS = {
  BOOL: { type: 'STATUS_BOOL', key: 'enabled', on: true, off: false },
  ENUM: { type: 'STATUS_ENUM', key: 'status', on: 'running', off: 'paused' },
  ACTIVE_ENUM: { type: 'ACTIVE_ENUM', key: 'active', on: 'running', off: 'paused' },
};

const sanitizeId = id => id.toString().replace(/[^\w]/g, '');
const dummyMutation = 'deleteCampaignSetting(id: -1,) { campaignSetting {id} errors }';
const idForGraphql = id => (typeof id === 'string' ? `"${id}"` : id);

const onComplete = ({ res, refetch, callback, successMessage }) => {
  if (typeof window.NotificationCenter === 'function') {
    const err = Object.keys(res).reduce((out, key) => out.concat(res[key].errors), []);
    if (err.length > 0) {
      new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
    } else {
      new window.NotificationCenter().show_success(successMessage);
      if (typeof callback === 'function') callback();
      refetch();
    }
  }
};

const DELETE_ELEMENT_MUTATION = (selected, multiEdit, itemKey) =>
  gql`mutation deleteElement {${
    selected.length === 0
      ? dummyMutation
      : selected
          .map(
            id =>
              `element${sanitizeId(id)}: ${multiEdit.delete}(id: ${idForGraphql(id || -1)},) {${itemKey} {id} errors }`
          )
          .join('\n')
  } }`;

const CHANGE_STATUS_MUTATION = status => (selected, multiEdit) =>
  gql`mutation changeStatus {${
    selected.length === 0 || !multiEdit?.changeStatus
      ? dummyMutation
      : selected
          .map(id =>
            multiEdit.changeStatus
              .replace('SANITIZE_ID', `element${sanitizeId(id)}`)
              .replace('ID', id)
              .replace(STATUS.ENUM.type, status ? STATUS.ENUM.on : STATUS.ENUM.off)
              .replace(STATUS.ACTIVE_ENUM.type, status ? STATUS.ACTIVE_ENUM.on : STATUS.ACTIVE_ENUM.off)
              .replace(STATUS.BOOL.type, status)
          )
          .join('\n')
  }}`;

const PAUSE_ALL_MUTATION = CHANGE_STATUS_MUTATION(false);
const RUN_ALL_MUTATION = CHANGE_STATUS_MUTATION(true);

const DELETE_TIME_RESTRICTION_MUTATION = collectionKey => gql`
  mutation DeleteTimeRestrictionMutation($organizationId: BigInt!, $ids: [String!]!){
    deleteTimeRestriction(organizationId: $organizationId, collectionKey: "${collectionKey}", ids: $ids)
    {
      ${collectionKey} {
        id
        timeRestrictionStatus
        timeRestriction { tooltip id text dateFrom dateTo generateString }
      }
      errors
    }
  }
`;

export function MultiEditWrapperContent({ children, multiEdit = {}, itemKey, refetch }) {
  const [loading, setLoading] = useState(false);
  const [callWithConfirmation, modalProps] = useConfirmationModal();

  // prettier-ignore
  const { state: { selected, allowSelect, organizationId, campaignSettingId, dataSourceId, collectionKey }, dispatch } = useMultiEdit();
  React.useEffect(() => dispatch({ type: ACTIONS.setState, payload: { multiEdit } }), []);

  const onCompleted = props => {
    setLoading(false);
    onComplete({ ...props, refetch });
  };

  const onError = () => {
    new window.NotificationCenter().show_error(t('react.multi_edit.error'));
    setLoading(false);
  };

  const [onDelete] = useMutation(DELETE_ELEMENT_MUTATION(selected, multiEdit, itemKey), {
    onCompleted: res =>
      onCompleted({
        res,
        callback: () => dispatch({ type: ACTIONS.deselectAll }),
        successMessage: t('react.multi_edit.success_deleted'),
      }),
    onError,
  });
  const [onPause] = useMutation(PAUSE_ALL_MUTATION(selected, multiEdit), {
    onCompleted: res =>
      onCompleted({
        res,
        successMessage: t('react.multi_edit.success_paused'),
      }),
    onError,
  });
  const [onStart] = useMutation(RUN_ALL_MUTATION(selected, multiEdit), {
    onCompleted: res =>
      onCompleted({
        res,
        successMessage: t('react.multi_edit.success_started'),
      }),
    onError,
  });

  const timeCollectionKey = typeof multiEdit?.timeRestriction === 'string' ? multiEdit.timeRestriction : collectionKey;
  const [onTimeRestrictionDelete] = useMutation(DELETE_TIME_RESTRICTION_MUTATION(timeCollectionKey), {
    variables: { organizationId, dataSourceId, campaignSettingId, ids: selected.map(id => `${id}`) },
    onCompleted: res => onCompleted({ res, successMessage: t('react.multi_edit.time_restriction.deleted') }),
    onError,
  });

  const handle = func => {
    if (typeof func === 'function') {
      setLoading(true);
      func();
    }
  };

  const actionBar = document.querySelector('.NewLayout-actionBar');

  return (
    <React.Fragment>
      {loading && <Loader absolute />}
      {children}
      {allowSelect && selected.length > 0 && (
        <div className="pos-sticky text-center" style={{ bottom: actionBar ? '80px' : '20px' }}>
          <MultiEditBox
            onDelete={() => handle(onDelete)}
            onPause={() => handle(onPause)}
            onStart={() => handle(onStart)}
            onTimeRestrictionDelete={e => {
              e.preventDefault();
              handle(onTimeRestrictionDelete);
            }}
            onCompleted={onCompleted}
            callWithConfirmation={callWithConfirmation}
          />
        </div>
      )}

      <ConfirmationModal {...modalProps} />
    </React.Fragment>
  );
}

MultiEditWrapperContent.propTypes = {
  children: PropTypes.any,
  multiEdit: PropTypes.object,
  itemKey: PropTypes.string,
  refetch: PropTypes.func,
};

export const MultiEditWrapper = props => (
  <MultiEditProvider {...props}>
    <MultiEditWrapperContent {...props} />
  </MultiEditProvider>
);

MultiEditWrapper.propTypes = {
  allowSelect: PropTypes.bool,
  dataIds: PropTypes.array,
};

export function MultiEditSelect({ id, header, className, name }) {
  const { state, dispatch } = useMultiEdit();
  const { selected, dataIds, allowSelect } = state;
  const selectedSize = selected.length;

  const throttledDispatch = throttle(options => dispatch(options), 100, { trailing: false });

  const toggleSingleItem = () => throttledDispatch({ type: ACTIONS.toggleSingle, payload: { id } });

  const toggleAll = () => {
    const type = selectedSize === 0 ? ACTIONS.selectAll : ACTIONS.deselectAll;
    throttledDispatch({ type });
  };

  if (!allowSelect) {
    return null;
  }

  if (header) {
    return (
      <Col shrink inline className={cs('vam', className)}>
        <div
          className={cs('Checkboxes', {
            'Checkboxes--lineCheck': selectedSize !== dataIds.length && selectedSize !== 0,
          })}
        >
          <Checkbox
            name="multi_edit_header"
            onChange={toggleAll}
            doNotUseInternalState
            checked={selectedSize > 0}
            value={id}
            data-test-id="multiEdit-checkbox[select-all]"
          />
        </div>
      </Col>
    );
  }

  return (
    <Col shrink>
      <Checkbox
        name="multi_edit"
        data-test-id={`multiEdit-checkbox[single-item][${name}]`}
        onChange={toggleSingleItem}
        doNotUseInternalState
        checked={selected.indexOf(id) !== -1}
        value={id}
      />
    </Col>
  );
}
MultiEditSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  header: PropTypes.bool,
};
