import React from 'react';
import { PropTypes } from 'prop-types';

const ProgressBar = ({ color, label, width, isModal, modal }) => (
  <div className="ProgressBar">
    <div className="ProgressBar-wrapper">
      <div className={`ProgressBar-progress ProgressBar-progress--${color}`} style={{ width }} />
      <div className={`Text Text--uppercase ProgressBar-label ${color === 'gray' && 'ProgressBar-label--gray'}`}>
        {isModal && modal?.label ? modal?.label : label}
      </div>
    </div>
  </div>
);

ProgressBar.propTypes = {
  color: PropTypes.string,
  modal: PropTypes.object,
  label: PropTypes.string,
  width: PropTypes.string,
  isModal: PropTypes.bool,
};

export default ProgressBar;
