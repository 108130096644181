import React, { Component } from 'react';
import RPT from 'prop-types';
import Modal from './Modal';
import { setUpAPICalls } from './adwords_api_call';

class AdwordsAccountTrackingTemplate extends Component {
  static propTypes = {
    csrfToken: RPT.string,
    organizationId: RPT.string,
    customerId: RPT.string,
    subAccountAccessId: RPT.string,
    accountName: RPT.string,
    i18n: RPT.object,
  };

  state = {
    apiCall: setUpAPICalls(this.props.organizationId, this.props.subAccountAccessId, this.props.csrfToken),
    shown: false,
  };

  render() {
    const { shown } = this.state;

    return (
      <React.Fragment>
        <button className="Button Button--secondary Button--small mt-16" onClick={() => this.setState({ shown: true })}>
          {this.props.i18n.edit_tracking}
        </button>
        {shown && (
          <Modal
            accountName={this.props.accountName}
            customerId={this.props.customerId}
            onClose={() => this.setState({ shown: false })}
            apiCall={this.state.apiCall}
            i18n={this.props.i18n}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AdwordsAccountTrackingTemplate;
