/* eslint-disable func-names */
window.initAccordion = () => {
  $('.js-accordion-content').each(function() {
    const accordionContentEl = $(this);

    if (!this.dataset.accordionAttached) {
      accordionContentEl
        .closest('.Accordion')
        .find('.js-accordion-title')
        .click(function() {
          $(this)
            .closest('.Accordion')
            .toggleClass('active');
        });
      this.setAttribute('data-accordion-attached', true);
    }
  });
};
