/* eslint-disable react/no-danger */

import React from 'react';
import RPT from 'prop-types';
import { DragSource } from 'react-dnd';
import DropElement from './DropElement.react';
import findParents from './findParents';
import { Adtext } from './Adtext';
import SystemIcon from '../components/SystemIcon';
import { FunelCampaignStage, DropdownMenu, Row, Col } from '../components';

const cardSource = {
  beginDrag(props) {
    if (typeof window.scrollByMouseActivate === 'function') {
      window.scrollByMouseActivate();
    }

    const element = props.elements[props.id];
    return {
      id: props.id,
      parent: element.parent,
      type: element.type,
    };
  },
  endDrag() {
    if (typeof window.scrollByMouseDectivate === 'function') {
      window.scrollByMouseDectivate();
    }
  },
};

class Element extends React.Component {
  static propTypes = {
    allItemsCount: RPT.number.isRequired,
    timeRestricted: RPT.bool.isRequired,
    isPaused: RPT.string.isRequired,
    canEdit: RPT.bool.isRequired,
    isSearched: RPT.bool.isRequired,
    isLast: RPT.bool.isRequired,
    id: RPT.string.isRequired,
    i18n: RPT.object.isRequired,
    elements: RPT.object.isRequired,
    changeOrder: RPT.func.isRequired,
    connectDragSource: RPT.func.isRequired,
    isDragging: RPT.bool.isRequired,
    showAllCampaigns: RPT.bool.isRequired,
    hideSystems: RPT.bool.isRequired,
  };

  componentDidMount() {
    window.setupToggle();
    window.handleGenerators();
  }

  render() {
    const {
      canEdit,
      isSearched,
      allItemsCount,
      isLast,
      elements,
      id,
      i18n,
      connectDragSource,
      changeOrder,
      isDragging,
      showAllCampaigns,
      hideSystems,
    } = this.props;

    const {
      adtext,
      nameText,
      name,
      itemsCount,
      system,
      actions,
      conditions,
      conditionsCount,
      changeStatus,
      timeRestriction,
      timeRestrictionTitle,
      loading,
      timeRestricted,
      isPaused,
    } = elements[id];
    const afterCounts = findParents(elements, id).reduce((acc, el) => acc - el.itemsCount, allItemsCount - itemsCount);

    return (
      <tr
        id={id}
        className={`js-a_d_t_e_x_t_${id}`}
        style={{
          opacity: isDragging || loading ? 0.3 : 1,
          transition: 'all .25s',
        }}
      >
        <td>
          <span dangerouslySetInnerHTML={{ __html: changeStatus }} />
        </td>
        <td dangerouslySetInnerHTML={{ __html: name }} />
        <td>
          <DropdownMenu data-test-id={`dropdown-action-${nameText}`}>
            <span dangerouslySetInnerHTML={{ __html: actions.edit || '' }} />
            <span dangerouslySetInnerHTML={{ __html: actions.duplicateToSameBranch || '' }} />
            <span dangerouslySetInnerHTML={{ __html: actions.duplicate || '' }} />
            <span dangerouslySetInnerHTML={{ __html: actions.delete || '' }} />
          </DropdownMenu>
        </td>
        {!isSearched ? (
          <td className="pv-0 pos-relative overflow-visible">
            <div>
              <div>
                {showAllCampaigns || !canEdit ? (
                  <Adtext {...adtext} key="dragElement" />
                ) : (
                  connectDragSource(
                    <div key="dragElement" className="AA_d_t_ext-draggable">
                      <Row center>
                        <Col shrink>
                          <Adtext {...adtext} />
                        </Col>
                        <Col shrink>
                          <span className="AA_d_t_ext-mover" />
                        </Col>
                      </Row>
                    </div>
                  )
                )}
                {loading && (
                  <span
                    className="Loader active"
                    style={{
                      position: 'absolute',
                      top: '5px',
                      left: '50%',
                    }}
                  />
                )}
              </div>
            </div>
            <FunelCampaignStage>
              <DropElement
                key="dropAfter"
                i18n={i18n}
                isLast={isLast}
                id={id}
                elements={elements}
                changeOrder={changeOrder}
              />
              <span>
                <b>{afterCounts}</b> {i18n.remaining_products_output}
              </span>
            </FunelCampaignStage>
          </td>
        ) : (
          <td className="A_d_t_ext-showcase A_d_t_ext-showcase--searched">
            <Adtext {...adtext} key="dragElement" />
          </td>
        )}
        <td className="pr-16">
          <span className={timeRestricted || isPaused ? 'pr-8' : ''}>{itemsCount}</span>
          {(timeRestricted || isPaused) && (
            <span
              className="js-tooltip fc-info"
              data-title={isPaused ? i18n.ad_is_paused : i18n.ad_is_restricted}
              data-placement="top"
            />
          )}
        </td>

        {!hideSystems && (
          <td className="text-center">
            <div className="System">
              <div className="System-wrapper">
                <SystemIcon
                  system="adwords"
                  size={19}
                  color={system.indexOf('adwords') === -1 && system !== 'all' ? 'grey' : 'color'}
                />
                <SystemIcon
                  system="bing"
                  size={19}
                  color={system.indexOf('bing') === -1 && system !== 'all' ? 'grey' : 'color'}
                />
                <SystemIcon
                  system="sklik"
                  size={19}
                  color={system.indexOf('sklik') === -1 && system !== 'all' ? 'grey' : 'color'}
                />
              </div>
            </div>
          </td>
        )}
        <td className="text-center js-no-overflow-tooltip" style={{ overflow: 'visible', zIndex: '2' }}>
          {conditions && (
            <div className="Conditions">
              <span className="Conditions-text">{conditionsCount}</span>
              <div className="Conditions-list" dangerouslySetInnerHTML={{ __html: conditions }} />
            </div>
          )}
        </td>
        <td className="time-restriction text-center">
          <span className="tooltip--adtext tooltip--adtext-center">
            <span
              className="js-tooltip"
              data-title={timeRestrictionTitle}
              data-html="true"
              data-placement="top"
              dangerouslySetInnerHTML={{ __html: timeRestriction }}
            />
          </span>
        </td>
      </tr>
    );
  }
}

const DragElement = DragSource('card', cardSource, (connect, monitor) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
}))(Element);

export default DragElement;
