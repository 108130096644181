import React from 'react';
import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import { HtmlCell, LinkCell, AdSystemCell, MainIdentifierCustomContentCell } from './cells';
import conditions from './conditions';
import { modalDataUrlOpener } from '../../RemoteModal';
import { t } from '../../../i18n';
import { Adtext } from '../../../adtext_tree/Adtext';

const mapping = {
  ...setupColumn({
    id: 'name',
    header: () => t('react.sitelinks_table.sitelink'),
    graphqlFields: [
      'id',
      'head',
      'editLink',
      'duplicateLink',
      'duplicateIntoCampaignsLink',
      'deleteLink',
      'status',
      'timeRestrictionStatus',
      'previewJsonData',
      'desc1',
      'desc2',
      'url',
      { timeRestriction: ['tooltip', 'dateFrom', 'dateTo', 'generateString'] },
    ],
    required: true,
    width: '440px',
    smallPadding: true,
    cell: MainIdentifierCustomContentCell,
    order: 'head',
    fixed: 'left',
    editButtonAsProp: true,
    specialEditableCase: true,
    editQuery: ({ id }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') changes.push(`status: "${enabled ? 'running' : 'paused'}"`);
      return gql`mutation UpdateSitelink($head: String) { updateSitelink(id: ${id}, head: $head, ${changes.join(
        ','
      )} ) {sitelink { id head status updatedAt } errors updateNotificationText }  }`;
    },
    resolveChildren: ({
      previewJsonData = '{}',
      head,
      id,
      icon,
      desc1,
      desc2,
      url,
      editLink,
      duplicateLink,
      duplicateIntoCampaignsLink,
      deleteLink,
      status,
      timeRestrictionStatus,
      timeRestriction,
    }) => ({
      content: <Adtext {...JSON.parse(previewJsonData)} editLink={editLink} type="sitelink" />,
      id,
      icon,
      desc1,
      desc2,
      url,
      status,
      name: head,
      timeRestrictionStatus,
      timeRestrictionTooltip: timeRestriction?.tooltip,
      text: head,
      link: editLink,
      size: 'fullwithGrid',
      'data-test-id': `dropdown-action-${head}`,
      links: [
        {
          href: editLink,
          size: 'fullwithGrid',
          'data-test-id': 'edit-bid-rule-button',
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': head,
          icon: 'edit',
        },
        {
          href: duplicateLink,
          size: 'fullwithGrid',
          children: t('views.campaign_settings.index.duplicate'),
          'data-test-id-duplicate': head,
          icon: 'duplicate',
        },
        {
          href: duplicateIntoCampaignsLink,
          heading: t('views.duplicate_into_campaigns_button.duplicate_bid_rule', {
            default: 'Duplicate %{head}',
            head,
          }),
          ...modalDataUrlOpener({
            url: duplicateIntoCampaignsLink,
            size: 'medium',
            heading: t('views.sitelinks.index.duplicate_to_campaigns_with_name', {
              default: 'Duplicate "%{head}" to campaign:',
              head,
            }),
          }),
          children: t('views.shared.duplicate_into_campaigns_button.duplicate_to'),
          'data-test-id-duplicate-to': head,
          icon: 'duplicate-to',
        },
        {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          icon: 'trash',
          danger: true,
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name: head }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': head,
          rel: 'nofollow',
        },
      ],
    }),
  }),
  ...setupColumn({
    id: 'campaign',
    cell: LinkCell,
    graphqlFields: 'campaignSetting {sitelinksLink name}',
    resolveChildren: ({ campaignSetting }) => ({ link: campaignSetting?.sitelinksLink, text: campaignSetting?.name }),
    header: () => t('react.tables.keyword_settings.campaign'),
  }),
  ...setupColumn({
    id: 'level',
    graphqlFields: 'levelString',
    cell: HtmlCell,
    header: () => t('level', { scope: 'react.sitelinks_table' }),
    resolveChildren: ({ levelString }) => levelString && t(levelString, { scope: 'react.sitelinks_table.levels' }),
  }),
  ...setupColumn({
    id: 'timeRestriction',
    graphqlFields: { timeRestriction: ['text'] },
    cell: HtmlCell,
    header: () => t('time_restrictions', { scope: 'react.sitelinks_table' }),
    resolveChildren: ({ timeRestriction }) => timeRestriction?.text || '',
  }),
  ...setupColumn({
    id: 'system',
    cell: AdSystemCell,
    header: () => t('system', { scope: 'react.sitelinks_table' }),
    resolveChildren: ({ system }) => system,
  }),
  ...conditions,
};

export default mapping;
