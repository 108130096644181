import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';

const defaultRefFunction = () => {};

const Searchbox = ({
  searchBoxText,
  searchValue,
  handleSearch,
  setInputRef,
  hideReset = false,
  debounce: debounceDelay,
  testId,
}) => {
  const [value, setValue] = useState(searchValue);

  const debounceFn = useCallback(debounce(handleDebounceFn, 300), []);

  function handleDebounceFn(newValue) {
    handleSearch({ target: { value: newValue } });
  }

  const handleChange = event => {
    setValue(event.target.value);
    debounceFn(event.target.value);
  };

  return (
    <div className="Input Input--noMargin Input--liveSearch reactSearch" data-test-id={testId}>
      <input
        value={debounceDelay ? value : searchValue}
        placeholder={searchBoxText}
        onChange={debounceDelay ? handleChange : handleSearch}
        ref={setInputRef || defaultRefFunction}
      />
      <i className="Input-magnifier fc-input-search" />
      {!hideReset && (value || searchValue) && (
        <i
          className={'Input-reset fc-close-outline-squared'}
          onClick={() => {
            setValue('');
            handleSearch({ target: { value: '' } });
          }}
        />
      )}
    </div>
  );
};

Searchbox.propTypes = {
  searchBoxText: PropTypes.string.isRequired,
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
  setInputRef: PropTypes.func,
  hideReset: PropTypes.bool,
  debounce: PropTypes.number,
  testId: PropTypes.string,
};

export default Searchbox;
