/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import { gql, NetworkStatus } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Row, Col, Icon, Loader, UsedByGraphWithLegend, ErrorBox, Hr, ErrorLoadingQuote } from '../index';
import { listenOnModalChange } from '../RemoteModal';
import { t, formatCurrency } from '../../i18n';

const QUERY_FB_SUMMARY_COUNT = gql`
  query FbSummaryCount($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        fbSummaryCount
        facebookCurrency
      }
    }
  }
`;

export class SummaryCountComponent extends React.Component {
  static propTypes = {
    adSetsCount: PropTypes.number,
    adSetsCovered: PropTypes.number,
    adSetDaily: PropTypes.number,
    adSetLifetime: PropTypes.number,
    campaignDaily: PropTypes.number,
    campaignLifetime: PropTypes.number,
    facebookCurrency: PropTypes.string,
    productsCount: PropTypes.number,
    productsCovered: PropTypes.number,
  };

  ensureCorretStateOfContinueButton = () => {
    const buttons = Array.from(document.getElementsByClassName('react-continue-button'));
    if (buttons.length > 0) {
      if (this.props.adSetsCovered > 0) {
        buttons.map(button => button.removeAttribute('disabled'));
        $('.react-continue-help-text').addClass('hidden');
      } else {
        buttons.map(button => button.setAttribute('disabled', 'disabled'));
        $('.react-continue-help-text').removeClass('hidden');
      }
    }
  };

  ensureCorretStateOfContinueButtonDebounced = debounce(this.ensureCorretStateOfContinueButton, 100);

  render() {
    const {
      adSetsCount,
      adSetsCovered,
      adSetDaily,
      adSetLifetime,
      campaignDaily,
      campaignLifetime,
      productsCount,
      productsCovered,
      facebookCurrency,
    } = this.props;

    this.ensureCorretStateOfContinueButtonDebounced();

    return (
      <div>
        <Row center padding="l" className="mv-16">
          <Col shrink>
            <Icon kind="budget" size="24px" color="#596774" />
          </Col>
          <Col padding="s">
            <Row>
              <div className="Text Text--bold" data-test-id="preview-daily_budget">
                {t('react.fb_features.preview.daily_budget', {
                  budget: formatCurrency(campaignDaily || adSetDaily || 0, { unit: facebookCurrency }),
                })}
              </div>
            </Row>
            <Row>
              <div className="Text Text--bold" data-test-id="preview-lifetime_budget">
                {t('react.fb_features.preview.lifetime_budget', {
                  budget: formatCurrency(campaignLifetime || adSetLifetime || 0, { unit: facebookCurrency }),
                })}
              </div>
            </Row>
          </Col>
        </Row>

        <UsedByGraphWithLegend
          size="md"
          adSets={adSetsCovered}
          adSetsTotal={adSetsCount}
          products={productsCovered}
          productsTotal={productsCount}
          padding="l"
        />

        <Hr className="mt-16" />
      </div>
    );
  }
}

export const SummaryCount = ({ organizationId, campaignSettingId, ...rest }) => (
  <Query
    query={QUERY_FB_SUMMARY_COUNT}
    variables={{
      organizationId,
      campaignSettingId,
    }}
    notifyOnNetworkStatusChange
  >
    {({ error, data, networkStatus, refetch }) => {
      useEffect(
        listenOnModalChange(() => refetch()),
        []
      );

      const loading = networkStatus !== NetworkStatus.ready;
      if (loading || (data && typeof data.organization?.campaignSetting?.facebookCurrency === 'undefined')) {
        return (
          <div className="text-center mt-16">
            <Loader size="small" />
            <div className="Text Text--gray mt-8">{window.reacti18n.pla_campaign_preview.loading}</div>
          </div>
        );
      }

      if (error) {
        return (
          <ErrorBox withIcon>
            <ErrorLoadingQuote onClick={() => refetch()} />
          </ErrorBox>
        );
      }

      return (
        <SummaryCountComponent
          refetch={refetch}
          {...rest}
          facebookCurrency={data && data.organization.campaignSetting?.facebookCurrency}
          {...((data && JSON.parse(data.organization.campaignSetting?.fbSummaryCount || '{}')) || {})}
        />
      );
    }}
  </Query>
);

SummaryCount.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
};

export default SummaryCount;
