/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter, Row, Col, Button } from '../components/index';

class GoogleLoyaltyProgram extends React.PureComponent {
  static propTypes = {
    cashback: PropTypes.string,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    points: PropTypes.string,
    price: PropTypes.price,
    program: PropTypes.string,
    tier: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    program: this.props.program || '',
    tier: this.props.tier || '',
    points: this.props.points || '',
    price: this.props.price || '',
    cashback: this.props.cashback || '',
  };

  handleProgram = ({ target: { value } }) => {
    if (value !== this.state.program) {
      this.setState({ program: value });
      this.props.updateFieldData(this.props.id, { program: value });
      updatePreview();
    }
  };

  handleTier = ({ target: { value } }) => {
    if (value !== this.state.tier) {
      this.setState({ tier: value });
      this.props.updateFieldData(this.props.id, { tier: value });
      updatePreview();
    }
  };

  handlePoints = ({ target: { value } }) => {
    if (value !== this.state.points) {
      this.setState({ points: value });
      this.props.updateFieldData(this.props.id, { points: value });
      updatePreview();
    }
  };

  handlePrice = ({ target: { value } }) => {
    if (value !== this.state.price) {
      this.setState({ price: value });
      this.props.updateFieldData(this.props.id, { price: value });
      updatePreview();
    }
  };

  handleCashback = ({ target: { value } }) => {
    if (value !== this.state.cashback) {
      this.setState({ cashback: value });
      this.props.updateFieldData(this.props.id, { cashback: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { program, tier, points, price, cashback } = this.state;

    return (
      <Row padding="l" center data-test-id="loyalty_program">
        <Col>
          <Row>
            <Col data-test-id="loyalty-program-program" className="mr-8">
              <PlaceholderInput
                defaultValue={program}
                onChange={this.handleProgram}
                placeholder={t('views.loyalty_program.fields.program', { default: 'Label' })}
                label={t('views.loyalty_program.fields.program_label', { default: 'Program label' })}
                {...getSubFiledOptions('program')}
              />
            </Col>
            <Col grow data-test-id="loyalty-program-tier">
              <PlaceholderInput
                defaultValue={tier}
                onChange={this.handletier}
                placeholder={t('views.loyalty_program.fields.tier', { default: 'Label' })}
                label={t('views.loyalty_program.fields.tier_label', { default: 'Tier label' })}
                {...getSubFiledOptions('tier')}
              />
            </Col>
          </Row>
          <Row>
            <Col data-test-id="loyalty-program-points" className="mr-8">
              <PlaceholderInput
                defaultValue={points}
                onChange={this.handlePoints}
                placeholder={t('views.loyalty_program.fields.points', { default: 'Points' })}
                label={t('views.loyalty_program.fields.points_label', { default: 'Loyalty points' })}
                {...getSubFiledOptions('points')}
              />
            </Col>
            <Col data-test-id="loyalty-program-cashback" className="mr-8">
              <PlaceholderInput
                defaultValue={cashback}
                onChange={this.handleCashback}
                placeholder={t('views.loyalty_program.fields.min_handling', { default: 'Cashback' })}
                label={t('views.loyalty_program.fields.min_handling_label', { default: 'Cashback for future use' })}
                {...getSubFiledOptions('cashback')}
              />
            </Col>
            <Col grow data-test-id="loyalty-program-price">
              <PlaceholderInput
                defaultValue={price}
                onChange={this.handlePrice}
                placeholder={t('views.loyalty_program.fields.price', { default: 'Price' })}
                label={t('views.loyalty_program.fields.price_label', { default: 'Price' })}
                {...getSubFiledOptions('price')}
              />
            </Col>
          </Row>
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.loyalty_program.example')} <span>${t('views.loyalty_program.example_text')}</span>`}
          data-field={`loyalty_program.${program}`}
        >
          <div className="Text">
            {t('views.loyalty_program.example')} <span>{t('views.loyalty_program.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default GoogleLoyaltyProgram;
