/* eslint-disable no-param-reassign */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cs from 'classnames';
import moment from 'moment';
import storage from '../utils/storage';
import { t, formatDate } from '../i18n';
import {
  Row,
  Col,
  Button,
  DateInput,
  Switch,
  Tile,
  SectionHeading,
  SmallHeading,
  TileDelimiter,
  Icon,
  Text,
  InfoBox,
} from '../components/index';
import SegmentedButton from '../components/form/SegmentedButton';

const Columns = ({ children, label }) => (
  <Row center>
    <Col width="120px">
      <Text medium>{label}</Text>
    </Col>
    <Col shrink>{children}</Col>
  </Row>
);
Columns.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
};

export const TimeRestrictionState = ({ generate, minDate, maxDate, withoutDot }) => (
  <div className="Text Text--lightGray">
    <span>
      {generate === 'true' ? t('react.time_restriction.generated') : t('react.time_restriction.generation_stopped')}
    </span>
    {!minDate && !maxDate && <span>{generate === 'true' && ` ${t('react.time_restriction.generated_all_time')}`}</span>}
    {minDate && (
      <span>
        {' '}
        {t('react.time_restriction.from_date', {
          date: formatDate(minDate, window.I18nLocale === 'en' && 'DD/MM/YYYY'),
        })}
      </span>
    )}
    {maxDate && (
      <span>
        {' '}
        {t('react.time_restriction.until_date', {
          date: formatDate(maxDate, window.I18nLocale === 'en' && 'DD/MM/YYYY'),
        })}
      </span>
    )}
    {!withoutDot && <span>.</span>}
  </div>
);

TimeRestrictionState.propTypes = {
  generate: PropTypes.string.isRequired,
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  withoutDot: PropTypes.bool,
};

class TimeRestriction extends React.Component {
  static propTypes = {
    generateProps: PropTypes.object.isRequired,
    children: PropTypes.any,
    isAllwaysOn: PropTypes.bool,
    locale: PropTypes.string,
    maxDateProps: PropTypes.object.isRequired,
    minDateProps: PropTypes.object.isRequired,
    readOnly: PropTypes.bool,
    showWarning: PropTypes.bool,
    withoutHeader: PropTypes.bool,
  };

  state = {
    minDate: this.props.minDateProps.value,
    maxDate: this.props.maxDateProps.value,
    generate: this.props.generateProps.value,
    isLastUsedVisible: false,
    isEnabled: !!(this.props.minDateProps.value || this.props.maxDateProps.value || this.props.isAllwaysOn),
  };

  componentDidMount() {
    if (
      storage.get('timeRestrictionMinDate') ||
      storage.get('timeRestrictionMaxDate') ||
      storage.get('timeRestrictionGenerate')
    ) {
      this.setLastUsedVisibility(true);
    }
  }

  onChangeMinDate = ({ target: { value } }) => {
    this.setState({ minDate: value }, this.storeLastChangedData);
    this.props.minDateProps.onChange(value);
  };

  onChangeMaxDate = ({ target: { value } }) => {
    this.setState({ maxDate: value }, this.storeLastChangedData);
    this.props.maxDateProps.onChange(value);
  };

  onChangeGenerate = ({ target: { value } }) => {
    this.setState({ generate: value }, this.storeLastChangedData);
    this.props.generateProps.onChange(value);
  };

  setValues = ({ minDate, maxDate, generate }) => {
    this.setState({ minDate, maxDate, generate });
    this.props.minDateProps.onChange(minDate);
    this.props.maxDateProps.onChange(maxDate);
    this.props.generateProps.onChange(generate);
  };

  changeEnabledState = ({ target: { value } }) => {
    this.setState({ isEnabled: value, minDate: null, maxDate: null });
    this.setValues({
      minDate: null,
      maxDate: null,
      generate: 'true',
    });
  };

  setLastUsedVisibility = value => {
    this.setState({ isLastUsedVisible: value });
  };

  storeLastChangedData = () => {
    this.setLastUsedVisibility(false);
    storage.set('timeRestrictionMinDate', this.state.minDate === null ? '' : this.state.minDate);
    storage.set('timeRestrictionMaxDate', this.state.maxDate === null ? '' : this.state.maxDate);
    storage.set('timeRestrictionGenerate', this.state.generate);
  };

  render() {
    const { maxDate, minDate, generate, isLastUsedVisible, isEnabled } = this.state;
    const { isAllwaysOn, readOnly, children, showWarning, withoutHeader } = this.props;

    const generateTypeSegmentedButton = (
      <SegmentedButton
        doNotUseInternalState
        id="time-restriction-generate"
        name="time-restriction-generate"
        onChange={this.onChangeGenerate}
        size="medium"
        data-test-id="time-restriction-select"
        value={generate}
        collection={[
          { value: 'true', label: t('react.time_restriction.generate_only') },
          { value: 'false', label: t('react.time_restriction.generate_except') },
        ]}
      />
    );

    const fromDateInput = (
      <DateInput
        {...this.props.minDateProps}
        maxDate={maxDate && moment(maxDate).toDate()}
        onChange={this.onChangeMinDate}
        readOnly={readOnly}
        value={minDate}
        doNotUseInternalState
        horizontaly
        testId="time-restriction-date-from"
      />
    );

    const untilDateInput = (
      <DateInput
        {...this.props.maxDateProps}
        minDate={minDate && moment(minDate).toDate()}
        onChange={this.onChangeMaxDate}
        readOnly={readOnly}
        value={maxDate}
        doNotUseInternalState
        horizontaly
        testId="time-restriction-date-until"
        last
      />
    );

    return (
      <div className="TimeRestriction">
        {children}

        {!withoutHeader && (
          <Row center noPadding className={cs({ 'mb-32': isEnabled })}>
            {!isAllwaysOn && (
              <Col shrink className="mr-12">
                <Switch value={isEnabled} id="useTimeRestriction" onChange={this.changeEnabledState} />
              </Col>
            )}
            <Col grow>
              <SectionHeading spacing={0}>{t('react.time_restriction.heading')}</SectionHeading>
            </Col>
          </Row>
        )}

        {isEnabled && (
          <React.Fragment>
            {showWarning && (
              <InfoBox type="warning" withIcon>
                {t('react.time_restrictions.pause_ad_set')}
              </InfoBox>
            )}

            <Tile className="mt-16" contentStyle={{ paddingTop: '8px' }}>
              <Row center noPadding className="mb-8">
                <Col shrink className="mr-12">
                  <Icon kind="timer" size="20px" inheritColor className="Icon--grape" />
                </Col>
                <Col grow>
                  <SmallHeading spacing={0}>{t('react.time_restriction.tile_heading')}</SmallHeading>
                </Col>
              </Row>

              <TileDelimiter />

              <Row center className="pv-16">
                <Col padding="l" grow>
                  <Columns label={t('react.time_restriction.restriction_type')}>{generateTypeSegmentedButton}</Columns>
                  <Columns label={t('react.time_restriction.generate_from')}>{fromDateInput}</Columns>
                  <Columns label={t('react.time_restriction.generate_until')}>{untilDateInput}</Columns>
                </Col>
              </Row>

              <TileDelimiter className="mb-16" />

              <TimeRestrictionState generate={generate} maxDate={maxDate} minDate={minDate} />
            </Tile>

            {isLastUsedVisible && (
              <Tile className="mt-16">
                <Row center>
                  <Col grow>
                    <Row center noPadding flexwrap className="flex-gap-8">
                      <span className="Text">{t('react.time_restriction.last_used')}:</span>

                      <TimeRestrictionState
                        minDate={storage.get('timeRestrictionMinDate')}
                        maxDate={storage.get('timeRestrictionMaxDate')}
                        generate={storage.get('timeRestrictionGenerate')}
                        withoutDot
                      />
                    </Row>
                  </Col>

                  <Col shrink>
                    <Button
                      secondary
                      data-test-id="apply-last-date-values"
                      onClick={() => {
                        this.setValues({
                          minDate: storage.get('timeRestrictionMinDate'),
                          maxDate: storage.get('timeRestrictionMaxDate'),
                          generate: storage.get('timeRestrictionGenerate'),
                        });
                        this.setLastUsedVisibility(false);
                      }}
                    >
                      {t('react.time_restriction.apply')}
                    </Button>
                  </Col>
                </Row>
              </Tile>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }
}

const getInputProps = input => {
  const inputProps = {
    id: input.getAttribute('id'),
    value: input.getAttribute('value'),
    name: input.getAttribute('name'),
    dataTestId: input.getAttribute('data-test-id'),
    onChange: value => {
      input.value = value;
      input.dispatchEvent(new window.Event('change', { bubbles: true }));
    },
  };
  input.removeAttribute('id');
  input.removeAttribute('data-test-id');

  return inputProps;
};

export default TimeRestriction;

window.initFormTimeRestriction = () => {
  const containers = document.querySelectorAll('[data-react-time-restriction="wrapper"]');
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      if (!container.classList.contains('form-time-restriction-mounted')) {
        const fromInput = container.querySelector('[data-react-time-restriction="from"]');
        const toInput = container.querySelector('[data-react-time-restriction="to"]');
        const generateInput = container.querySelector('[data-react-time-restriction="generate"]');
        const showWarning = container.getAttribute('data-show-warning') === 'true';

        const readOnly =
          fromInput.disabled || (fromInput.closest('fieldset') && fromInput.closest('fieldset').disabled);

        const contentContainer = container.querySelector('[data-react-time-restriction="container"]');
        const children =
          contentContainer.innerHTML.length > 0 ? (
            // eslint-disable-next-line react/no-danger
            <span dangerouslySetInnerHTML={{ __html: contentContainer.innerHTML }} />
          ) : (
            undefined
          );

        ReactDOM.render(
          <TimeRestriction
            minDateProps={getInputProps(fromInput)}
            maxDateProps={getInputProps(toInput)}
            generateProps={getInputProps(generateInput)}
            locale={window.I18nLocale}
            readOnly={readOnly}
            showWarning={showWarning}
          >
            {' '}
            {children}{' '}
          </TimeRestriction>,
          contentContainer
        );

        if (readOnly) {
          contentContainer.classList.add('disabled');
        }

        container.classList.add('form-time-restriction-mounted');
      }
    });
  }
};
