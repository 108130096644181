import React from 'react';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import AdditionalContent from './AdditionalContent';
import { Button } from '../index';

const createKey = (item, column) => item.id + (column.path || column.key);

const TableBody = ({ currency, columns = [], data = [], disableFirstEmptyColumn, children, itemProps, ...rest }) => (
  <tbody className="Sticky-Body">
    {data.map(item => (
      <React.Fragment key={item.id}>
        <tr className={`${rest.noHover && 'noHover'}`}>
          {!disableFirstEmptyColumn && (
            <td width="50px" className="Sticky-Cell">
              <Button
                width="50px"
                onlyIcon
                tertiary
                icon={itemProps.isAdditionalContentShown(item) ? 'chevron-down' : 'chevron-right'}
                onClick={() => itemProps.handleClick(item)}
              />
            </td>
          )}
          {columns.map(column => (
            <td key={createKey(item, column)} className="Sticky-Cell">
              {column.content ? (
                <column.content currency={currency} item={{ ...item }} child={children} period={rest.period} />
              ) : (
                _.get(item, column.path)
              )}
            </td>
          ))}
        </tr>
        <AdditionalContent
          {...rest}
          in={itemProps.isAdditionalContentShown(item)}
          maxRows={columns.length + 1}
          item={item}
        />
      </React.Fragment>
    ))}
  </tbody>
);

TableBody.propTypes = {
  currency: PropTypes.string,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  disableFirstEmptyColumn: PropTypes.bool,
  children: PropTypes.any,
  itemProps: PropTypes.object,
  suborganization: PropTypes.string,
};

export default hot(TableBody);
