import React from 'react';

import Vector from './Vector';

const calculateRadius = object =>
  object.radius ? Math.min(object.radius, Math.min(object.width, object.height) / 2) : undefined;

export default class Rect extends Vector {
  static meta = {
    icon: 'rectangle',
    useIconInLayers: true,
    initial: {
      width: 400,
      height: 400,
      stroke: '',
      strokeWidth: '',
      fill: 'blue',
      radius: 0,
      blendMode: 'normal',
      rotate: 0,
      'data-layer-name': 'Rectangle',
    },
  };

  static getDescription = () => 'Rectangle';

  getStyle() {
    const { object } = this.props;

    return {
      fill: object.fill.length === 0 ? 'transparent' : object.fill,
    };
  }

  render() {
    const { object } = this.props;

    return (
      <rect
        style={this.getStyle()}
        {...this.getObjectAttributes()}
        rx={calculateRadius(object)}
        width={object.width}
        height={object.height}
      />
    );
  }
}
