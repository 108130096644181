export * from './SmartSelects';
export { default as CallToActionText } from './CallToActionText';
export { default as ColorPicker } from './ColorPicker';
export { default as DateInput } from './DateInput';
export { default as FBCustomAudience } from './FBCustomAudience';
export { FBCustomAudienceExclusion } from './FBCustomAudience';
export { default as Form } from './Form';
export { default as Hint } from './Hint';
export { default as Checkbox } from './Checkbox';
export { default as Checkboxes } from './Checkboxes';
export { default as IconSwitch } from './IconSwitch';
export { default as ImageUpload } from './ImageUpload';
export { default as Input } from './Input';
export { default as LabelsSelect } from './LabelsSelect';
export { default as Chip } from './Chip';
export { default as SegmentedButton } from './SegmentedButton';
export { default as Select } from './Select';
export { default as SmartSelect } from './SmartSelect';
export { default as Switch } from './Switch';
export { default as ThreeStatedCheckbox } from './ThreeStatedCheckbox';
export { default as UnitInput } from './UnitInput';
export { default as UTMParams } from './UTMParams';

export const convertBoolean = (bool, defaultValue = false) => {
  if (typeof bool === 'undefined' || bool === null) {
    return defaultValue;
  }
  return bool === true || bool === 'true' || bool === 1 || bool === '1';
};
