/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Select, Input, SegmentedButton, Switch, convertBoolean } from '../components/form';
import PlaceholderInput from '../placeholders/Input';
import { Row, Col } from '../components/layout';
import Button from '../components/Button';
import updatePreview from './updatePreview';
import CharacterCounter from './CharacterCounter';

class AdtextDisplayLine extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    _error_fields: PropTypes.arrayOf(PropTypes.string),
    _errors: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    text: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: { enablePlaceholders: false },
  };

  state = {
    text: this.props.text || '',
  };

  handleText = ({ target: { value } }) => {
    if (this.state.text !== value) {
      this.setState({ text: value });
      this.props.updateFieldData(this.props.id, { text: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove, options } = this.props;
    const { text, pinned, overflow } = this.state;
    const inputId = this.props.id;

    const counts = {};

    if (options.count_on_the_fly) {
      const count = (text || '').trim().length;
      if (options.max_text_count) {
        counts.overLimit = count > options.max_text_count;
      }
      counts.without_variables_count = count;
    }

    return (
      <React.Fragment>
        <Row
          center
          className={options.max_text_count && options.max_text_count > 0 ? 'mb-0' : 'mb-8'}
          data-test-id="nested-field-adtextDisplayLine"
        >
          <Col grow>
            {options.enablePlaceholders ? (
              <PlaceholderInput
                error={!!this.props._errors}
                defaultValue={text}
                onChange={this.handleText}
                {...getSubFiledOptions('text')}
                placeholder={options.placeholder}
              />
            ) : (
              <Input
                error={!!this.props._errors}
                defaultValue={text}
                onChange={this.handleText}
                {...getSubFiledOptions('text')}
                placeholder={options.placeholder}
              />
            )}
          </Col>
          <Col shrink>
            {id && onRemove && (
              <Button
                tertiary
                onlyIcon
                disabled={disabled}
                onClick={onRemove}
                tabIndex="-1"
                icon="trash"
                data-test-id="nested-field-remove"
              />
            )}
          </Col>
        </Row>
        {this.props._errors && (
          <Row className="mb-4 mt-0" shrink>
            <Col>
              <div className="ValidationError">{this.props._errors}</div>
            </Col>
          </Row>
        )}
        {options.max_text_count && options.max_text_count > 0 && (
          <Row className="mb-8 mt-4">
            <Col>
              <CharacterCounter
                {...getSubFiledOptions('_text_counter')}
                {...counts}
                chars={options.chars}
                max_text_count={options.max_text_count}
                show_variables_count={options.show_variables_count}
                without_variables={options.without_variables}
              />
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}

export default AdtextDisplayLine;
