import React from 'react';
import { Row, Col, Heading, Text, Icon, cssVariables, Button } from '../index';
import { t } from '../../i18n';

const FeaturesOverviewTable = ({
  budgetPeriod,
  budgetSum,
  budgetEditLink,
  campaignOptimizationLabel,
  campaignOptimizationEditLink,
  campaignBid,
  campaignBidEditLink,
  isCampaignSynced,
}) => {
  const costPerResult = campaignBid
    ? `${campaignBid} CZK`
    : t('react.fb_advantage_plus.campaign_overview.not_set', { default: 'Not set' });
  return (
    <div className="FeaturesOverviewTable-Wrapper">
      <div className="FeaturesOverviewTable-Container" data-test-id="features-overview">
        <Row className="FeaturesOverviewTable-Thead">
          <Col>
            <Text medium style={{ color: cssVariables.textSubtle }}>
              {t('react.fb_advantage_plus.campaign_overview.features_overview', { default: 'Features overview' })}
            </Text>
          </Col>
        </Row>
        <Row center justifyBetween className="FeaturesOverviewTable-Row" data-test-id="budget-overview">
          <Col>
            <Row center>
              <Col shrink style={{ marginRight: '12px' }}>
                <Icon kind="budget" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink style={{ marginRight: '12px' }}>
                <Heading tag="h4" size="md" spacing={0}>
                  {t('react.fb_advantage_plus.campaign_overview.budget', { default: 'Budget' })}
                </Heading>
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textSubtle }}>{`${budgetPeriod} ${budgetSum} CZK`}</Text>
              </Col>
            </Row>
          </Col>
          <Col shrink>
            <Button
              tag="a"
              href={budgetEditLink}
              size="sm"
              kind="tertiary"
              icon="edit"
              onlyIcon
              data-test-id="budget-edit"
            />
          </Col>
        </Row>
        <Row center justifyBetween className="FeaturesOverviewTable-Row" data-test-id="bidding-strategy-overview">
          <Col>
            <Row center>
              <Col shrink style={{ marginRight: '12px' }}>
                <Icon kind="target" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink style={{ marginRight: '12px' }}>
                <Heading tag="h4" size="md" spacing={0}>
                  {t('react.fb_advantage_plus.campaign_overview.bidding_strategy', { default: 'Bidding strategy' })}
                </Heading>
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textSubtle }}>{campaignOptimizationLabel}</Text>
              </Col>
            </Row>
          </Col>
          {!isCampaignSynced && (
            <Col shrink>
              <Button tag="a" href={campaignOptimizationEditLink} size="sm" kind="tertiary" icon="edit" onlyIcon />
            </Col>
          )}
        </Row>
        <Row center justifyBetween className="FeaturesOverviewTable-Row" data-test-id="cost-per-result-overview">
          <Col>
            <Row center>
              <Col shrink style={{ marginRight: '12px' }}>
                <Icon kind="credits" size="20px" color={cssVariables.iconSubtle} />
              </Col>
              <Col shrink style={{ marginRight: '12px' }}>
                <Heading tag="h4" size="md" spacing={0}>
                  {t('react.fb_advantage_plus.campaign_overview.cost_per_result', { default: 'Cost per result' })}
                </Heading>
              </Col>
              <Col shrink>
                <Text style={{ color: cssVariables.textSubtle }}>{costPerResult}</Text>
              </Col>
            </Row>
          </Col>
          <Col shrink>
            <Button tag="a" href={campaignBidEditLink} size="sm" kind="tertiary" icon="edit" onlyIcon />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FeaturesOverviewTable;
