/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import ErrorState from './ErrorState';
import { SortableWrapper, useSortable } from '../../hooks/useSortable';
import { filterRowColumnsForReport, formatMetricValue } from '../ReportCharts/functions';
import { t } from '../../i18n';
import { Row } from '../index';

const PropTypesCell = {
  children: PropTypes.any,
  index: PropTypes.number,
  className: PropTypes.string,
};

const Th = ({ children, className }) => <td className={classNames('Sticky-Cell', className)}>{children}</td>;
Th.propTypes = PropTypesCell;

const Td = ({ children, index, className }) => (
  <td className={classNames('Sticky-Cell', className, { 'Text--bold': index === 0 })}>{children}</td>
);
Td.propTypes = PropTypesCell;

const ReportTable = ({ data }) => {
  const rows = data?.rows;
  const summary = data?.summary;

  const [sortableProps, sortedTableRows] = useSortable(
    rows,
    useCallback(sortBy => row => row?.[sortBy], [])
  );

  if (!rows) {
    return (
      <Row justifyCenter>
        <ErrorState />
      </Row>
    );
  }

  return (
    <div className="Sticky-Wrapper Sticky-Wrapper--scrollable">
      <table className="Sticky-Table">
        <thead>
          <tr className="Sticky-Header">
            {Object.keys(filterRowColumnsForReport(sortedTableRows[0])).map(colHeading => (
              <Th key={`thead-${colHeading}`}>
                <SortableWrapper {...sortableProps} sortByValue={colHeading}>
                  {t(colHeading.toLowerCase(), { scope: 'react.report_table', default: colHeading })}
                </SortableWrapper>
              </Th>
            ))}
          </tr>
        </thead>
        <tbody className="Sticky-Body">
          {sortedTableRows.map((row, rowIndex) => (
            <tr key={`tbody-row-${rowIndex}`} className="Sticky-Row">
              {Object.keys(filterRowColumnsForReport(row)).map((key, cellIndex) => (
                <Td key={`tbody-cell-${cellIndex}`} index={cellIndex} className="Text--firstUppercase">
                  {formatMetricValue(row[key], { row, metricName: key })}
                </Td>
              ))}
            </tr>
          ))}
        </tbody>
        {summary && summary.length > 0 && (
          <tfoot>
            {summary.map((row, rowIndex) => (
              <tr key={`tfoot-row-${rowIndex}`} className="Sticky-Footer Sticky-Row Sticky-Row--gray">
                {Object.keys(filterRowColumnsForReport(row)).map((key, cellIndex) => (
                  <Td key={`tfoot-cell-${cellIndex}`} index={cellIndex} className="Text--gray Text--firstUppercase">
                    {formatMetricValue(row[key], { row, metricName: key })}
                  </Td>
                ))}
              </tr>
            ))}
          </tfoot>
        )}
      </table>
    </div>
  );
};

const itemPropType = PropTypes.shape({
  adPlacement: PropTypes.string,
});

ReportTable.propTypes = {
  data: PropTypes.shape({
    rows: PropTypes.arrayOf(itemPropType),
    summary: PropTypes.arrayOf(itemPropType),
  }),
};

export default hot(ReportTable);
