import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Button, Tooltip } from '../../../index';
import { t } from '../../../../i18n';

const ButtonWithTooltip = ({ onClick, position }) => (
  <Col shrink center>
    <Tooltip text={t(position, { scope: 'react.image_generator.arrange' })}>
      <Button onlyIcon tertiary icon={`arrange-${position}`} data-test-id={`arrange-${position}`} onClick={onClick} />
    </Tooltip>
  </Col>
);

export default class ArrangePanel extends PureComponent {
  render() {
    return (
      <Row>
        <ButtonWithTooltip position="front" onClick={this.props.onArrange.bind(this, 'front')} />
        <ButtonWithTooltip position="forward" onClick={this.props.onArrange.bind(this, 'up')} />
        <ButtonWithTooltip position="backward" onClick={this.props.onArrange.bind(this, 'down')} />
        <ButtonWithTooltip position="back" onClick={this.props.onArrange.bind(this, 'back')} />
      </Row>
    );
  }
}

ButtonWithTooltip.propTypes = {
  onClick: PropTypes.func.isRequired,
  position: PropTypes.oneOf(['front', 'forward', 'backward', 'back']).isRequired,
};
