import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { difference, uniq } from 'lodash';
import { hot } from 'react-hot-loader/root';
import { t } from '../i18n';
import { Row, Button, Col, Tile, listenOnModalChangeConditions, modalDataUrlOpener } from '../components';
import ItemGroupDisplay from './item_groups/ItemGroupDisplay';
import { NewItemGroupButton, AddItemGroupModal } from './item_groups/AddItemGroup';
import { createHashMap } from '../components/table/utils';

const LINKS = {
  and: 'new_item_group_link',
  or: 'new_product_set_link',
  not: 'new_negative_product_set_link',
};

const ItemGroupsHandler = ({ componentOptions, itemGroups = [], ...props }) => {
  const [showModal, setShowModal] = useState(false);
  const [itemGroupToNotify, setItemGroupToNotify] = useState(null);
  const isMounted = React.useRef(false);
  const hasTwoLine = props.options?.twoLine;

  const { FallbackComponent, type } = componentOptions;
  if (!props.options.show_item_groups) {
    return <FallbackComponent />;
  }

  const itemsGroupIds = `${type}_item_group_ids`;
  const link = LINKS[type];
  const newLink = props.options?.[link];
  const propItemGroups = useMemo(
    () =>
      props.fieldData
        .filter(item => item.group_id === props.group_id && item.selected_item_group_id && props.id === item.id)
        .map(item => item.selected_item_group_id),
    [props.group_id, props.id, props.fieldData]
  );
  const forceUpdateCount = props?.additionalState?.forceUpdate || 0;

  useEffect(() => {
    let unmountListenCallback = () => {};
    isMounted.current = true;
    if (props.options.new_item_group_link?.notify_on_change) {
      unmountListenCallback = listenOnModalChangeConditions((name, data) => {
        if (data?.item_updated && isMounted.current) {
          props.onItemGroupsUpdate(data);
          if (name?.indexOf('item_groups_table_') !== -1 && name.split('__#')[1] === type) {
            props.setAdditionalState({
              forceUpdate: forceUpdateCount + 1,
            });
          }
        }
        if (
          name &&
          isMounted.current &&
          !data?.item_updated &&
          (name.indexOf('negative_product_set_table_') !== -1 ||
            name.indexOf('product_set_table_') !== -1 ||
            name.indexOf('item_groups_table_') !== -1)
        ) {
          const newItem = {
            ...data,
            label: data.name,
            value: data.id,
          };

          if (itemGroupToNotify === props.group_id) {
            props.setAdditionalState(
              {
                [itemsGroupIds]: uniq(propItemGroups.concat(data.id)),
              },
              [data.id],
              props.group_id
            );
            setShowModal(false);
          }
          props.onItemGroupsAdd(newItem);
          props.setAdditionalStateUpdated(s => ({ ...s, [newItem.id]: newItem }));
        }
      });
    }
    return () => {
      unmountListenCallback();
      isMounted.current = false;
    };
  }, [propItemGroups, itemsGroupIds, forceUpdateCount, isMounted, itemGroupToNotify]);

  const allItemGroups = Object.values({
    ...createHashMap(itemGroups, 'id'),
    ...(props.additionalStateUpdated ? props.additionalStateUpdated : {}),
  });
  const allItemGroupsInGroup = props.fieldData
    .filter(item => props.group_id === item.group_id && item.selected_item_group_id)
    .map(item => item.selected_item_group_id);

  const selectedIds = allItemGroupsInGroup || [];
  const availableItemGroupIds = difference(
    allItemGroups.map(({ id }) => id),
    propItemGroups
  );

  const useProductSet =
    (availableItemGroupIds.length === 0 || difference(availableItemGroupIds, selectedIds).length === 0) && newLink ? (
      <NewItemGroupButton
        linkDefinition={newLink}
        text={t('react.filters_and_conditions.add_saved_filter')}
        type={type}
      />
    ) : (
      <Button
        secondary
        type="button"
        onClick={() => {
          setItemGroupToNotify(props.group_id);
          setShowModal(true);
        }}
        size="small"
        className="mb-0"
        icon={'plus'}
      >
        {t('react.filters_and_conditions.add_saved_filter')}
      </Button>
    );

  return (
    <Col noPadding style={hasTwoLine && { flexBasis: '72px' }}>
      <Row
        center
        shrink
        flexwrap={hasTwoLine && !props.isOnlyAddButton}
        className={classNames({ 'flex-gap-8': hasTwoLine })}
      >
        {!props.isOnlyAddButton && (
          <div
            className={classNames('Segmented', 'Segmented--withButtons', { 'mr-8': !hasTwoLine, 'w-100': hasTwoLine })}
          >
            <div className="Segmented-wrapper">
              <Button
                className={classNames('Segmented-button', { 'w-100': hasTwoLine })}
                active={!props.negative}
                onClick={() => props.toggleNegative(props.id)}
                type="button"
                size="medium"
                secondary
                style={{ minWidth: '77px' }}
              >
                Include
              </Button>
              <Button
                className={classNames('Segmented-button', { 'w-100': hasTwoLine })}
                active={!!props.negative}
                onClick={() => props.toggleNegative(props.id)}
                type="button"
                size="medium"
                secondary
                style={{ minWidth: '77px' }}
              >
                Exclude
              </Button>
            </div>
          </div>
        )}

        {!props.isOnlyAddButton &&
          [props.selected_item_group_id].map(id => {
            const itemGroup = allItemGroups.find(ig => ig.id === id);
            if (!itemGroup) {
              return null;
            }
            return (
              <React.Fragment key={id}>
                <Col grow key={id} className={classNames('ma-0', { 'align-self-stretch': !hasTwoLine })}>
                  <Tile noSpaced contentStyle={{ paddingTop: '3px', paddingBottom: '3px' }} fullHeight>
                    <Row className="ph-8" style={{ height: '100%', minHeight: '24px' }}>
                      <Col grow>
                        <ItemGroupDisplay
                          {...itemGroup}
                          options={props.options}
                          type={type}
                          editLink={itemGroup.edit_link}
                        />
                      </Col>
                      {itemGroup.edit_link && (
                        <Col shrink>
                          <Button
                            onlyIcon
                            tertiary
                            icon="edit"
                            size="small"
                            {...modalDataUrlOpener({
                              url: itemGroup.edit_link,
                              notify: `item_groups_table__#${type}`,
                              size: 'fullwithGrid',
                            })}
                          />
                        </Col>
                      )}
                    </Row>
                  </Tile>
                </Col>
              </React.Fragment>
            );
          })}
        {props.isOnlyAddButton && useProductSet}
      </Row>
      {showModal && (
        <AddItemGroupModal
          onClose={() => setShowModal(false)}
          onSelect={newIds => {
            setShowModal(false);
            props.setAdditionalState(
              {
                [itemsGroupIds]: uniq(propItemGroups.concat(newIds)),
              },
              newIds,
              props.group_id
            );
          }}
          availableItemGroupIds={availableItemGroupIds}
          selectedIds={selectedIds}
          itemGroups={allItemGroups}
          linkDefinition={newLink}
          type={type}
          conditionId={props.id}
        />
      )}
    </Col>
  );
};
ItemGroupsHandler.propTypes = {
  options: PropTypes.object,
  componentOptions: PropTypes.object,
  children: PropTypes.any,
  formBase: PropTypes.string,
  setAdditionalState: PropTypes.func,
  additionalState: PropTypes.any,
  isOnlyAddButton: PropTypes.bool,
  id: PropTypes.string,
  group_id: PropTypes.string,
  fieldData: PropTypes.array,
  negative: PropTypes.bool,
  selected_item_group_id: PropTypes.string,
  toggleNegative: PropTypes.func,
  additionalStateUpdated: PropTypes.object,
  setAdditionalStateUpdated: PropTypes.func,
};

export default hot(ItemGroupsHandler);
