import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { debounce } from 'lodash';

const gridCellProps = {
  baseline: PropTypes.bool,
  bottom: PropTypes.bool,
  center: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  flexwrap: PropTypes.bool,
  grow: PropTypes.bool,
  height: PropTypes.string,
  inline: PropTypes.bool,
  justifyAround: PropTypes.bool,
  justifyBetween: PropTypes.bool,
  justifyCenter: PropTypes.bool,
  justifyEnd: PropTypes.bool,
  justifyStart: PropTypes.bool,
  noPadding: PropTypes.bool,
  nowrap: PropTypes.bool,
  padding: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
  setRef: PropTypes.func,
  shrink: PropTypes.bool,
  style: PropTypes.object,
  top: PropTypes.bool,
  width: PropTypes.string,
  wrap: PropTypes.bool,
};

const gridCellStyle = ({
  baseline,
  bottom,
  center,
  className,
  direction = 'row',
  flexwrap = false,
  grow = false,
  height = null,
  inline = false,
  justifyBetween,
  justifyAround,
  justifyCenter,
  justifyEnd,
  justifyStart,
  noPadding = false,
  nowrap = false,
  overflow = false,
  padding = 'm',
  shrink = false,
  wrap = false,
  top,
  width = null,
  style = {},
  ...rest
}) => {
  let newStyle = { ...style };
  if (width || height) {
    newStyle = {
      ...style,
      flexGrow: width || height ? '0' : '1',
      flexBasis: width || height || '100%',
      flexShrink: width || height ? '0' : '1',
      ...(width ? { width } : {}),
      ...(height ? { height } : {}),
    };
  }

  return {
    ...rest,
    className: cs(className, `Grid--${direction}`, {
      [`Grid--padding${(padding || '').toUpperCase()}`]: !noPadding,
      'Grid--top': top,
      'Grid--bottom': bottom,
      'Grid--center': center,
      'Grid--baseline': baseline,
      'Grid--justifyCenter': justifyCenter,
      'Grid--justifyEnd': justifyEnd,
      'Grid--justifyStart': justifyStart,
      'Grid--justifyBetween': justifyBetween,
      'Grid--justifyAround': justifyAround,
      'Grid--overflow': overflow,
      'Grid--textNowrap': nowrap,
      'Grid--flexwrap': flexwrap,
      'Grid--shrink': shrink,
      'Grid--grow': grow,
      'Grid--shrinkWrap': wrap,
      'Grid--inline': inline,
    }),
    style: newStyle,
  };
};

export const Row = ({ children, setRef, ...rest }) => (
  <div {...gridCellStyle({ direction: 'row', ...rest })} ref={setRef}>
    {children}
  </div>
);

Row.propTypes = gridCellProps;

export const Col = ({ children, setRef, ...rest }) => (
  <div {...gridCellStyle({ direction: 'col', ...rest })} ref={setRef}>
    {children}
  </div>
);

Col.propTypes = gridCellProps;

export const GridOverflow = ({ children, className, setRef, ...rest }) => (
  <div {...rest} className={cs('Grid--overflowWrapper', className)} ref={setRef}>
    {children}
  </div>
);

GridOverflow.propTypes = gridCellProps;

document.addEventListener('DOMContentLoaded', () => {
  const initGridSticky = () => {
    document
      .querySelectorAll(
        '.Grid--overflowWrapper--withOverflowShadow:not(Grid--overflowWrapper--withOverflowShadow-inited)'
      )
      .forEach(container => {
        if (container.classList.contains('Grid--overflowWrapper--withOverflowShadow-inited')) {
          return;
        }
        container.classList.add('Grid--overflowWrapper--withOverflowShadow-inited');

        const resolveGridSticky = () => {
          if (container.scrollTop > 0) {
            container.classList.add('Grid--overflowWrapper--stickyTop');
          } else {
            container.classList.remove('Grid--overflowWrapper--stickyTop');
          }
          if (container.scrollHeight > container.scrollTop + container.offsetHeight) {
            container.classList.add('Grid--overflowWrapper--stickyBottom');
          } else {
            container.classList.remove('Grid--overflowWrapper--stickyBottom');
          }
        };
        resolveGridSticky();
        setInterval(resolveGridSticky, 500);
      });
  };
  initGridSticky();
  setInterval(initGridSticky, 1000);

  const initGridExpandable = () => {
    document
      .querySelectorAll('.Grid--overflowWrapper--expandable:not(Grid--overflowWrapper--expandable-inited)')
      .forEach(container => {
        if (container.classList.contains('Grid--overflowWrapper--expandable-inited')) {
          return;
        }
        container.classList.add('Grid--overflowWrapper--expandable-inited');

        const resolveGridExpandable = () => {
          if (container.scrollWidth > container.offsetWidth) {
            container.classList.add('Grid--overflowWrapper--expandable--withHoverEffect');
          } else {
            container.classList.remove('Grid--overflowWrapper--expandable--withHoverEffect');
          }
        };
        resolveGridExpandable();
        const debouncedResolveGridExpandable = debounce(resolveGridExpandable, { raf: true });
        window.addEventListener('resize', debouncedResolveGridExpandable);
      });
  };
  initGridExpandable();
  setInterval(initGridExpandable, 1000);
});
