import React from 'react';
import cs from 'classnames';
import { Col, Row } from '../components';
import { dsImporterDescriptions } from './meta';

function CampaignDataSourceTileDescription({ importer, campaignType }) {
  if (!dsImporterDescriptions?.[campaignType]?.[importer]) return null;

  const { title, type, positiveAspects, negativeAspects } = dsImporterDescriptions[campaignType][importer];
  const hasAspects = !!positiveAspects?.length || !!negativeAspects?.length;

  return (
    <Row flexwrap className="text-left">
      {title && (
        <Row
          center
          className={cs('w-100', 'mb-8', 'Text', 'Text--bold', {
            'Text--default': ['neutral'].includes(type),
            'Text--warning': ['warning'].includes(type),
            'Text--red': ['error'].includes(type),
          })}
        >
          {['warning', 'error'].includes(type) && (
            <Col shrink>
              <span className="Icon fc-warning mr-8" style={{ fontSize: '20px' }} />
            </Col>
          )}
          <Col grow>
            <span>{title}</span>
          </Col>
        </Row>
      )}
      {hasAspects && (
        <Row className="mt-0 mb-8">
          {!!positiveAspects?.length && (
            <Col>
              <ul className="List List--check">
                {positiveAspects.map(item => (
                  <li className="List-item" key={item}>
                    <span className="Text Text--gray">{item}</span>
                  </li>
                ))}
              </ul>
            </Col>
          )}
          {!!negativeAspects?.length && (
            <Col>
              <ul className="List List--errorCircle">
                {negativeAspects.map(item => (
                  <li className="List-item" key={item}>
                    <span className="Text Text--gray">{item}</span>
                  </li>
                ))}
              </ul>
            </Col>
          )}
        </Row>
      )}
    </Row>
  );
}

export default CampaignDataSourceTileDescription;
