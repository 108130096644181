import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Loader } from '../Icons';
import { t } from '../../i18n';
import NoDataChartOverlay from '../NoDataChartOverlay';
import { SectionHeading, Icon, ErrorLoadingQuote, Row, Col } from '../index';

const TileNavigation = ({ tabs, className }) => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <div className={cs('Tile-tabs', className)}>
      <ul className="TabNavigation TabNavigation--noSpacing">
        {tabs.map((tab, index) => (
          <li className={`TabNavigation-item ${activeTab === index && 'active'}`} key={tab.title}>
            <a
              onClick={() => {
                setActiveTab(index);
                tab.onClick();
              }}
            >
              {tab.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

TileNavigation.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  className: PropTypes.string,
};

const Tile = ({
  additionalHeaderContent,
  alignLeft,
  commingSoon,
  dashboard,
  error,
  fullHeight,
  header,
  children,
  loading,
  noData,
  refetch,
  tabs,
  flexible,
}) => {
  const contentBackground = commingSoon && commingSoon.length > 0 ? { backgroundImage: `url(${commingSoon})` } : {};
  return (
    <div
      className={cs('Tile', {
        'Tile--dashboard': dashboard,
        'Tile--dashboardFlexible': flexible,
        'Tile--fullHeight': fullHeight,
      })}
    >
      <div
        className={cs('Tile-header', {
          'pt-0': tabs && !additionalHeaderContent && !header,
          'pb-0': tabs && (!header || additionalHeaderContent),
        })}
      >
        <Row center className="mw-100">
          {header && (
            <Col grow>
              <SectionHeading spacing={0}>{header}</SectionHeading>{' '}
            </Col>
          )}
          {additionalHeaderContent && (
            <Col className="ml-a" shrink>
              {additionalHeaderContent}
            </Col>
          )}
        </Row>
        {tabs && !noData && (
          <TileNavigation
            tabs={tabs}
            className={cs({
              'pos-static negative-mb-1': !header || additionalHeaderContent,
              'mt-8': additionalHeaderContent,
            })}
          />
        )}
      </div>
      <div
        className={cs('Tile-content', { 'Tile-content--image': commingSoon, 'Tile-content--left': alignLeft })}
        style={contentBackground}
      >
        {loading && <Loader size="big" />}

        {error && (
          <div className="text-center">
            <Icon kind="close" color="#D0021B" size="60px" />
            <div className="Text Text--bold Text--error mt-8">
              <ErrorLoadingQuote onClick={refetch} />
            </div>
          </div>
        )}

        {commingSoon && (
          <div className="Badge Badge--extraLarge Badge--blue">
            <div className="Text Text--bold">{t('react.dashboard.comming_soon')}</div>
          </div>
        )}

        {noData && (
          <NoDataChartOverlay noData message={t('react.dashboard.products_timeline.no_data')}>
            {children}
          </NoDataChartOverlay>
        )}

        {!loading && !error && !commingSoon && !noData && children}
      </div>
    </div>
  );
};

Tile.propTypes = {
  alignLeft: PropTypes.bool,
  children: PropTypes.any,
  header: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
  dashboard: PropTypes.bool,
  loading: PropTypes.bool,
  error: PropTypes.string,
  commingSoon: PropTypes.string,
  refetch: PropTypes.func,
  noData: PropTypes.bool,
  fullHeight: PropTypes.bool,
  additionalHeaderContent: PropTypes.any,
  flexible: PropTypes.bool,
};

export default Tile;
