import { setupColumn } from './setupColumn';
import { TextCell, PmaxAssetCell, PmaxStatusCell, IconLinkCell, BadgeCell, LinkCell } from './cells';
import { t } from '../../../i18n';

const mapping = {
  ...setupColumn({
    id: 'text',
    order: 'text',
    graphqlFields: ['text', 'videoTitle', 'imageUrl', 'type', 'videoUrl', 'callToAction'],
    cell: PmaxAssetCell,
    header: () => t('views.campaign_settings.pmax.asset_col_title'),
    resolveChildren: ({ text, videoTitle, imageUrl, type, videoUrl, callToAction }) => ({
      text,
      videoTitle,
      imageUrl,
      type,
      videoUrl,
      callToAction,
    }),
  }),
  ...setupColumn({
    id: 'performanceLabel',
    order: 'performanceLabel',
    graphqlFields: ['performanceLabel'],
    cell: BadgeCell,
    header: () => t('views.campaign_settings.pmax.performance_col_title'),
    resolveChildren: ({ performanceLabel }) => ({
      size: 'medium',
      kind: performanceLabel === 'LOW' ? 'red' : 'gray',
      text: t(`views.campaign_settings.pmax.enums.performance.${performanceLabel}`),
    }),
  }),
  ...setupColumn({
    id: 'status',
    order: 'status',
    graphqlFields: ['status'],
    cell: PmaxStatusCell,
    header: () => t('views.campaign_settings.pmax.status_col_title'),
    resolveChildren: ({ status }) => t(`views.campaign_settings.pmax.enums.status.${status}`),
  }),
  ...setupColumn({
    id: 'type',
    order: 'type',
    graphqlFields: ['type'],
    cell: TextCell,
    header: () => t('views.campaign_settings.pmax.type_col_title'),
    resolveChildren: ({ type }) => type,
  }),
  ...setupColumn({
    id: 'assetGroup',
    graphqlFields: ['assetGroup'],
    cell: LinkCell,
    header: () => t('views.campaign_settings.pmax.asset_group_col_title'),
    resolveChildren: ({ assetGroup, link }) => ({
      text: assetGroup,
      link,
    }),
  }),
  ...setupColumn({
    id: 'id',
    graphqlFields: ['link'],
    cell: IconLinkCell,
    header: () => 'Action',
    resolveChildren: ({ link }) => ({
      icon: 'edit',
      link,
      kind: 'secondary',
    }),
  }),
};

export default mapping;
