// eslint-disable-next-line import/prefer-default-export
export function sortDataSource(data) {
  const sortOrder = {
    active: 1,
    sync_in_progress: 2,
    not_in_use: 3,
    paused: 4,
    first_import_error: 5,
    error: 6,
    archived: 7,
    deleted: 8,
  };
  if (data?.length > 0) {
    return [...data].sort((a, b) => {
      const stateA = sortOrder[a.dataSourceState] || 9;
      const stateB = sortOrder[b.dataSourceState] || 9;

      if (stateA === stateB) {
        const nameA = a.name.toLowerCase();
        const nameB = b.name.toLowerCase();
        return nameA.localeCompare(nameB);
      }
      return stateA - stateB;
    });
  } else return data;
}

export const mapDataSourcesCorrectImporter = dataSources =>
  dataSources.map(dataSource => {
    if (dataSource.importer.includes('csv')) {
      return { ...dataSource, importer: 'csv_importer' };
    }
    if (dataSource.importer.includes('xml')) {
      return { ...dataSource, importer: 'xml_importer' };
    }
    if (dataSource.importer.includes('json')) {
      return { ...dataSource, importer: 'json_importer' };
    }
    return dataSource;
  });
