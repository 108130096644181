import React from 'react';
import PropTypes from 'prop-types';
import FieldKindIcon from './FieldKindIcon';

const dictionary = { conditional: 'text', conditional_number: 'number' };

function PlaceholderTypeIcon({ valueType, className, size = '18px', isActive = false }) {
  const type = dictionary[valueType] || valueType;

  return <FieldKindIcon showFieldKind={type} size={size} className={className} isActive={isActive} />;
}
PlaceholderTypeIcon.propTypes = {
  valueType: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  isActive: PropTypes.bool,
};

export default PlaceholderTypeIcon;
