/* eslint-disable */
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { flatten, uniq } from 'lodash';
import { hot } from 'react-hot-loader/root';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { t } from '../../i18n';
import { Icon, FieldKindIcon } from '../index';
import ReactFlow, { Background, MiniMap, Controls } from 'react-flow-renderer';

const QUERY_VARIABLES_TOPOPLOGY = gql`
  query VariablesGraph($organizationId: BigInt!, $dataSourceId: BigInt!) {
    feedExports: collection(
      organizationId: $organizationId
      dataSourceId: $dataSourceId
      identifier: "feedExports"
      page: 0
      limit: -1
    ) {
      feedExports {
        id
        icon
        name
        getPlaceholdersWithoutConditions
        getConditionsPlaceholders
      }
    }

    additionalSources: collection(
      organizationId: $organizationId
      dataSourceId: $dataSourceId
      identifier: "additionalSources"
      page: 0
      limit: -1
    ) {
      additionalSources {
        id
        icon
        name
        mappingField
        mappingFields
      }
    }
    campaignSettings: collection(
      organizationId: $organizationId
      dataSourceId: $dataSourceId
      identifier: "campaignSettings"
      page: 0
      limit: -1
    ) {
      campaignSettings {
        id
        icon
        name
        getPlaceholdersWithoutConditions
        getConditionsPlaceholders
        adwordsSetting {
          id
          getPlaceholdersWithoutConditions
        }
        sklikSetting {
          id
        }
        bingSetting {
          id
          getPlaceholdersWithoutConditions
        }
        keywordSettings {
          id
          name
          getPlaceholdersWithoutConditions
          getConditionsPlaceholders
        }
        baseAdtexts {
          id
          type
          parentId
          name
          getPlaceholdersWithoutConditions
          getConditionsPlaceholders
        }
        bidRules {
          id
          name
          getConditionsPlaceholders
        }
      }
    }

    variables: collection(
      organizationId: $organizationId
      dataSourceId: $dataSourceId
      identifier: "variables"
      page: 0
      limit: -1
    ) {
      variables {
        id
        name
        placeholderName
        showFieldKind
        getPlaceholdersWithoutConditions
        getConditionsPlaceholders
        imageGen {
          getPlaceholdersWithoutConditions
          getConditionsPlaceholders
        }
        additionalSource {
          id
        }
      }
    }
  }
`;

const getVariableDepth = (variables, variableName) => {
  const target = variables.find(v => v.placeholderName === variableName);
  const sources = (target?.getPlaceholdersWithoutConditions || [])
    .concat(target.getConditionsPlaceholders || [])
    .concat(target?.imageGen?.getConditionsPlaceholders || [])
    .concat(target?.imageGen?.getPlaceholdersWithoutConditions || []);

  return sources.reduce((out, sourceName) => {
    const newLevel = getVariableDepth(variables, sourceName) + 1;
    return newLevel > out ? newLevel : out;
  }, 0);
};

const handleSelectedChilds = (elements, id) => {
  if (id) {
    const foundEl = elements.find(e => e.id === id);
    if (foundEl) {
      foundEl.show = 'down';
    }

    elements
      .filter(e => e.source === id)
      .map(e => {
        e.show = e.parentLink ? 'down-parent' : 'down';
        handleSelectedChilds(elements, e.target);
      });
  }
};

const handleSelectedParents = (elements, id) => {
  if (id) {
    const foundEl = elements.find(e => e.id === id);
    if (foundEl) {
      foundEl.show = 'up';
    }

    elements
      .filter(e => e.target === id)
      .map(e => {
        e.show = e.parentLink ? 'up-parent' : 'up';
        if (!e.parentLink) {
          handleSelectedParents(elements, e.source);
        }
      });
  }
};

const changeStylesOfSelected = (elements, id) => {
  if (id) {
    elements
      .filter(e => e.show)
      .map(e => {
        // is link
        if (e.target && e.source) {
          console.log(e.show);
          if (e.show === 'up') {
            e.style.stroke = 'blue';
            e.animated = true;
          } else if (e.show === 'up-parent') {
            e.style.stroke = 'green';
          } else if (e.show === 'down-parent') {
            e.style.stroke = 'orange';
          } else {
            e.style.stroke = 'red';
            e.animated = true;
          }
        }
      });
    elements
      .filter(e => !e.show)
      .map(e => {
        e.style.opacity = 0.3;
      });
  } else {
    elements.map(e => {
      e.animated = false;
      e.style.stroke = undefined;
      e.style.opacity = 1;
    });
  }
};

export const VariablesTopologyComponent = ({
  feedExports = [],
  variables = [],
  campaignSettings = [],
  additionalSources = [],
  error,
  loading,
}) => {
  const [selected, setSelected] = useState([]);

  // is node selected
  const selectedId = selected && selected.length > 0 && selected[0].position ? selected[0].id : undefined;

  const dataSourceNodes = [
    {
      id: 'dataSource',
      name: 'Data Source',
    },
  ];

  const elements = [];
  let originalVariablesCount = 0;
  variables?.variables
    ?.filter(v => v.id.indexOf('Modifier') === -1 && !v.additionalSource)
    ?.map((variable, i) => {
      originalVariablesCount += 1;
      elements.push({
        id: variable.placeholderName,
        type: 'input',
        data: {
          label: (
            <React.Fragment>
              <FieldKindIcon showFieldKind={variable.showFieldKind} /> {variable.name}
            </React.Fragment>
          ),
        },
        style: { background: '#438EDC' },
        position: { x: 0, y: originalVariablesCount * 80 },
        sourcePosition: 'right',
      });
    });

  additionalSources?.additionalSources?.map((as, i) => {
    const asId = `additionalSource-${as.id}`;
    originalVariablesCount += 1;
    elements.push({
      id: asId,
      data: {
        label: (
          <React.Fragment>
            <Icon kind={as.icon} /> {as.name}
          </React.Fragment>
        ),
      },
      style: { background: '#ffdaaa' },
      position: { x: 250, y: originalVariablesCount * 80 },
      sourcePosition: 'right',
      targetPosition: 'left',
    });

    if (as.useMultipleMappingFields) {
      as.mappingFields.map(mf => {
        elements.push({
          id: `${mf}-${asId}`,
          source: mf,
          style: {},
          target: asId,
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    } else {
      elements.push({
        id: `${as.mappingField}-${asId}`,
        source: as.mappingField,
        style: {},
        target: asId,
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
      });
    }
  });

  variables?.variables
    ?.filter(v => v.id.indexOf('Modifier') === -1 && v.additionalSource)
    ?.map((variable, i) => {
      originalVariablesCount += 1;
      elements.push({
        id: variable.placeholderName,
        type: 'default',
        data: {
          label: (
            <React.Fragment>
              <FieldKindIcon showFieldKind={variable.showFieldKind} /> {variable.name}
            </React.Fragment>
          ),
        },
        style: { background: '#438EDC' },
        position: { x: 500, y: originalVariablesCount * 80 },
        sourcePosition: 'right',
        targetPosition: 'left',
      });

      elements.push({
        id: `additionalSource-${variable.additionalSource.id}-${variable.placeholderName}`,
        target: variable.placeholderName,
        style: {},
        source: `additionalSource-${variable.additionalSource.id}`,
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
      });
    });

  const modifiers = []
    .concat(variables?.variables?.filter(v => v.id.indexOf('Modifier') !== -1) || [])
    .sort(
      (a, b) =>
        getVariableDepth(variables?.variables, a.placeholderName) -
        getVariableDepth(variables?.variables, b.placeholderName)
    );

  modifiers.map((variable, i) => {
    const sources = uniq(
      (variable?.getPlaceholdersWithoutConditions || [])
        .concat(variable?.getConditionsPlaceholders || [])
        .concat(variable?.imageGen?.getConditionsPlaceholders || [])
        .concat(variable?.imageGen?.getPlaceholdersWithoutConditions || [])
    );

    elements.push({
      id: variable.placeholderName,
      type: sources.length > 0 ? 'default' : 'input',
      data: {
        label: (
          <React.Fragment>
            <FieldKindIcon showFieldKind={variable.showFieldKind} /> {variable.name}
          </React.Fragment>
        ),
      },
      style: { background: '#9BCC63' },
      position: {
        x: 750 + getVariableDepth(variables?.variables, variable.placeholderName) * 250,
        y: originalVariablesCount * 80 + i * 80,
      },
      sourcePosition: 'right',
      targetPosition: 'left',
    });

    sources.map(source => {
      elements.push({
        id: `${variable.placeholderName}-${source}`,
        source,
        style: {},
        target: variable.placeholderName,
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
      });
    });
  });

  const leftPos = elements.reduce((out, el) => {
    if (el.position) {
      return el.position.x > out ? el.position.x : out;
    } else {
      return out;
    }
  }, 0);

  feedExports?.feedExports?.map((el, i) => {
    const elId = `feed-export-${el.id}`;
    elements.push({
      id: elId,
      data: {
        label: (
          <React.Fragment>
            <Icon kind={el.icon} size="40px" />
            <br />
            {el.name}
          </React.Fragment>
        ),
      },
      type: 'output',
      style: { height: '80px', background: '#f1f1f1' },
      position: { x: 750 + leftPos, y: i * 150 },
      targetPosition: 'left',
    });

    const sources = uniq((el?.getPlaceholdersWithoutConditions || []).concat(el?.getConditionsPlaceholders || []));

    sources.map(source => {
      elements.push({
        id: `${elId}-${source}`,
        source,
        target: elId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
      });
    });
  });

  let feedExportsCount = (feedExports?.feedExports || []).length;
  let childsIndex = 0;

  campaignSettings?.campaignSettings?.map((el, i) => {
    console.log(el);
    const elId = `campaing-${el.id}`;
    elements.push({
      id: elId,
      data: {
        label: (
          <React.Fragment>
            <Icon kind={el.icon} size="40px" />
            <br />
            {el.name}
          </React.Fragment>
        ),
      },
      style: { height: '80px', background: '#f1f1f1' },
      position: { x: 250 + leftPos, y: (i + feedExportsCount) * 150 },
      sourcePosition: 'right',
      targetPosition: 'left',
    });

    const sources = uniq((el.getPlaceholdersWithoutConditions || []).concat(el.getConditionsPlaceholders || []));

    sources.map(source => {
      elements.push({
        id: `${elId}-${source}`,
        source,
        target: elId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
      });
    });

    if (el.adwordsSetting) {
      const settId = `adwordsSetting-${el.adwordsSetting.id}`;
      elements.push({
        id: settId,
        data: {
          label: <React.Fragment>Google Ads Setting</React.Fragment>,
        },
        type: 'output',
        style: { height: '30px', background: '#f1f1f1' },
        position: { x: 750 + leftPos, y: (i + childsIndex + feedExportsCount) * 150 },
        targetPosition: 'left',
      });

      elements.push({
        id: `${elId}-${settId}`,
        source: elId,
        target: settId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
        parentLink: true,
      });

      childsIndex += 1;

      const sources = uniq(
        (el.adwordsSetting.getPlaceholdersWithoutConditions || []).concat(
          el.adwordsSetting.getConditionsPlaceholders || []
        )
      );

      sources.map(source => {
        elements.push({
          id: `${elId}-${settId}-${source}`,
          source,
          target: settId,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    }

    if (el.bingSetting) {
      const settId = `bingSetting-${el.bingSetting.id}`;
      elements.push({
        id: settId,
        data: {
          label: <React.Fragment>Bing Setting</React.Fragment>,
        },
        type: 'output',
        style: { height: '30px', background: '#f1f1f1' },
        position: { x: 750 + leftPos, y: (i + childsIndex + feedExportsCount) * 150 },
        targetPosition: 'left',
      });

      elements.push({
        id: `${elId}-${settId}`,
        source: elId,
        target: settId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
        parentLink: true,
      });

      childsIndex += 1;

      const sources = uniq(
        (el.bingSetting.getPlaceholdersWithoutConditions || []).concat(el.bingSetting.getConditionsPlaceholders || [])
      );

      sources.map(source => {
        elements.push({
          id: `${elId}-${settId}-${source}`,
          source,
          target: settId,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    }

    if (el.sklikSetting) {
      const settId = `sklikSetting-${el.sklikSetting.id}`;
      elements.push({
        id: settId,
        data: {
          label: <React.Fragment>Sklik Setting</React.Fragment>,
        },
        type: 'output',
        style: { height: '30px', background: '#f1f1f1' },
        position: { x: 750 + leftPos, y: (i + childsIndex + feedExportsCount) * 150 },
        targetPosition: 'left',
      });

      elements.push({
        id: `${elId}-${settId}`,
        source: elId,
        target: settId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
        parentLink: true,
      });

      childsIndex += 1;

      const sources = uniq(
        (el.sklikSetting.getPlaceholdersWithoutConditions || []).concat(el.sklikSetting.getConditionsPlaceholders || [])
      );

      sources.map(source => {
        elements.push({
          id: `${elId}-${settId}-${source}`,
          source,
          target: settId,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    }

    el?.keywordSettings?.map(child => {
      const childId = `keywordSettings-${child.id}`;
      elements.push({
        id: childId,
        data: {
          label: <React.Fragment>KW: {child.name}</React.Fragment>,
        },
        type: 'output',
        style: { height: '80px', background: '#f1f1f1' },
        position: { x: 750 + leftPos, y: (i + childsIndex + feedExportsCount) * 150 },
        targetPosition: 'left',
      });

      elements.push({
        id: `${elId}-${childId}`,
        source: elId,
        target: childId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
        parentLink: true,
      });

      childsIndex += 1;

      const sources = uniq(
        (child.getPlaceholdersWithoutConditions || []).concat(child.getConditionsPlaceholders || [])
      );

      sources.map(source => {
        elements.push({
          id: `${elId}-${childId}-${source}`,
          source,
          target: childId,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    });

    el?.baseAdtexts?.map(child => {
      const childId = `baseAdtexts-${child.id}`;
      elements.push({
        id: childId,
        data: {
          label: (
            <React.Fragment>
              AD:
              {child.name}
            </React.Fragment>
          ),
        },
        type: el?.baseAdtexts?.find(ba => ba.parentId == child.id) ? 'default' : 'output',
        style: { height: '80px', background: '#f1f1f1' },
        position: { x: 750 + leftPos + (child.parentId ? 100 : 0), y: (i + childsIndex + feedExportsCount) * 150 },
        targetPosition: 'left',
        sourcePosition: 'bottom',
      });

      elements.push({
        id: `${elId}-${childId}`,
        source: elId,
        target: childId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
        parentLink: true,
      });

      if (child.parentId) {
        // propojeni s parentem ..!!! nevim jak spravne a jestli vubec to delat
        elements.push({
          id: `${elId}-${childId}-${child.parentId}`,
          target: childId,
          source: `baseAdtexts-${child.parentId}`,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
          parentLink: true,
        });
      }

      childsIndex += 1;

      const sources = uniq(
        (child.getPlaceholdersWithoutConditions || []).concat(child.getConditionsPlaceholders || [])
      );

      sources.map(source => {
        elements.push({
          id: `${elId}-${childId}-${source}`,
          source,
          target: childId,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    });

    el?.bidRules?.map(child => {
      const childId = `bidRules-${child.id}`;
      elements.push({
        id: childId,
        data: {
          label: <React.Fragment>Bid: {child.name}</React.Fragment>,
        },
        type: 'output',
        style: { height: '80px', background: '#f1f1f1' },
        position: { x: 750 + leftPos, y: (i + childsIndex + feedExportsCount) * 150 },
        targetPosition: 'left',
      });

      elements.push({
        id: `${elId}-${childId}`,
        source: elId,
        target: childId,
        style: {},
        arrowHeadType: 'arrowclosed',
        type: 'smooth',
        parentLink: true,
      });

      childsIndex += 1;

      const sources = child.getConditionsPlaceholders;

      sources.map(source => {
        elements.push({
          id: `${elId}-${childId}-${source}`,
          source,
          target: childId,
          style: {},
          arrowHeadType: 'arrowclosed',
          type: 'smooth',
        });
      });
    });
  });

  handleSelectedChilds(elements, selectedId);
  handleSelectedParents(elements, selectedId);
  changeStylesOfSelected(elements, selectedId);

  console.log(elements);
  // const links = []
  // variables?.variables?.map(variable => {
  //   links.push({source: 'dataSource', target: variable.placeholderName})
  //   variable.getPlaceholdersWithoutConditions.concat(variable.getConditionsPlaceholders).map(target => {
  //     links.push({source: variable.placeholderName, target})
  //   })
  // })
  // { id: '1', type: 'input', data: { label: 'Node 1' }, position: { x: 250, y: 5 } },
  // // you can also pass a React Node as a label
  // { id: '2', data: { label: <div>Node 2</div> }, position: { x: 100, y: 100 } },
  // { id: 'e1-2', source: '1', target: '2', animated: true },
  return (
    <div style={{ height: '100%' }}>
      <ReactFlow
        elements={elements}
        elementsSelectable
        isDraggable={false}
        snapToGrid={true}
        onSelectionChange={setSelected}
      >
        <MiniMap />
        <Controls showInteractive={false} />
        <Background color="#d1d1d1" variant="dots" gap={24} size={1} />
      </ReactFlow>
    </div>
  );
};

VariablesTopologyComponent.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  blogArticles: PropTypes.array,
};

const VariablesTopology = ({ organizationId, dataSourceId, ...rest }) => (
  <Query query={QUERY_VARIABLES_TOPOPLOGY} variables={{ organizationId, dataSourceId }}>
    {({ error, data, loading }) => (
      <VariablesTopologyComponent loading={loading} error={error} {...rest} {...(data || {})} />
    )}
  </Query>
);

VariablesTopology.propTypes = {
  organizationId: PropTypes.number.isRequired,
  dataSourceId: PropTypes.number.isRequired,
};

export default hot(VariablesTopology);
