import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { SegmentedButton as SgmtButton } from '../index';
import { t } from '../../i18n';

const SegmentedButton = ({ name, matchAll, mutation, campaignBudget, onChange }) => {
  const [toggleMatchAll] = useMutation(mutation);

  return (
    <div>
      <SgmtButton
        className="mb-8"
        value={`${matchAll}` === 'true'}
        name={name}
        id={name}
        doNotUseInternalState
        onChange={({ target }) => {
          toggleMatchAll({
            variables: { matchAll: `${target.value}` === 'true' },
          })
            .then(() => target.closest('form')?.dispatchEvent(new window.Event('change', { bubbles: true })))
            .then(() => {
              if (typeof onChange === 'function') {
                onChange();
              }
            });
        }}
        collection={[
          {
            label: t(campaignBudget ? 'match_all_campaign' : 'match_all', { scope: 'react.fb_campaign_preview' }),
            value: true,
          },
          {
            label: t(campaignBudget ? 'manual_select_campaign' : 'manual_select', {
              scope: 'react.fb_campaign_preview',
            }),
            value: false,
          },
        ]}
      />
    </div>
  );
};

SegmentedButton.propTypes = {
  campaignBudget: PropTypes.bool,
  matchAll: PropTypes.bool,
  mutation: PropTypes.any,
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default SegmentedButton;
