/* eslint-disable */
import React, { PureComponent } from 'react';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { partition } from 'lodash';

let data = [];
for (var i = 0; i < 10000; ++i)
  data[i] = {
    a: 0 + Math.random() * 500,
    x: 0 + Math.random() * 500,
    y: 0 + Math.random() * 500,
    z: 0 + Math.random() * 20,
  };

// const data = [
//   { x: 100, y: 200, z: 200, a: 200 },
//   { x: 120, y: 100, z: 260, a: 220 },
//   { x: 170, y: 300, z: 400, a: 270 },
//   { x: 140, y: 250, z: 280, a: 240 },
//   { x: 150, y: 400, z: 500, a: 250 },
//   { x: 110, y: 280, z: 200, a: 210 },
// ];

const CustomTooltip = ({ label, payload, active }) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        {JSON.stringify(payload)}
        <p className="desc">Anything you want can be displayed here.</p>
      </div>
    );
  }

  return null;
};

export default class Example extends PureComponent {
  static jsfiddleUrl = 'https://jsfiddle.net/alidingling/uLysj0u2/';
  state = {
    dimensionX: 'x',
    dimensionY: 'y',
    dimensionZ: 'z',
    start: null,
    end: null,
    pressed: false,
  };
  render() {
    const { start, end, pressed, startData, endData, dimensionX, dimensionY, dimensionZ } = this.state;

    const [selected, unselected] = partition(
      data,
      item =>
        start &&
        end &&
        item.x >= Math.min(startData.x, endData.x) &&
        item.x <= Math.max(startData.x, endData.x) &&
        item.y >= Math.min(startData.y, endData.y) &&
        item.y <= Math.max(startData.y, endData.y)
    );

    return (
      <div>
        <select
          onChange={({ target: { value } }) => this.setState({ dimensionX: value, start: null, end: null })}
          value={dimensionX}
        >
          <option value="x">X</option>
          <option value="y">Y</option>
          <option value="z">Z</option>
          <option value="a">A</option>
        </select>
        <select
          onChange={({ target: { value } }) => this.setState({ dimensionY: value, start: null, end: null })}
          value={dimensionY}
        >
          <option value="x">X</option>
          <option value="y">Y</option>
          <option value="z">Z</option>
          <option value="a">A</option>
        </select>
        <select
          onChange={({ target: { value } }) => this.setState({ dimensionZ: value, start: null, end: null })}
          value={dimensionZ}
        >
          <option value="x">X</option>
          <option value="y">Y</option>
          <option value="z">Z</option>
          <option value="a">A</option>
        </select>

        <div style={{ width: '100%', height: '70vh' }}>
          <ResponsiveContainer debounce={100}>
            <ScatterChart
              onMouseDown={e =>
                e &&
                this.setState({
                  start: { x: e.chartX, y: e.chartY },
                  startData: { x: e.xValue, y: e.yValue },
                  pressed: true,
                })
              }
              onMouseMove={e =>
                e &&
                this.state.pressed &&
                this.setState({ end: { x: e.chartX, y: e.chartY }, endData: { x: e.xValue, y: e.yValue } })
              }
              onMouseUp={e => {
                if (e) {
                  this.setState({
                    end: { x: e.chartX, y: e.chartY },
                    endData: { x: e.xValue, y: e.yValue },
                    pressed: false,
                  });
                  console.log(
                    'Drawn: [',
                    this.state.startData.x,
                    ',',
                    this.state.startData.y,
                    '] x [',
                    e.xValue,
                    ',',
                    e.yValue,
                    ']'
                  );
                }
              }}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid />
              <XAxis type="number" dataKey={dimensionX} name="stature" unit="cm" />
              <YAxis type="number" dataKey={dimensionY} name="weight" unit="kg" />
              <ZAxis type="number" dataKey={dimensionZ} range={[0, 20]} name="score" unit="clicks" />
              <Tooltip cursor={{ strokeDasharray: '3 3' }} content={<CustomTooltip />} />
              <Scatter name="Selected" data={selected} fill="#8884d8" shape="point" />
              <Scatter name="Unselected" data={unselected} fill="#888888" shape="point" />
              {start && end && (
                <rect
                  x={Math.min(start.x, end.x)}
                  y={Math.min(start.y, end.y)}
                  width={Math.max(start.x, end.x) - Math.min(start.x, end.x)}
                  height={Math.max(start.y, end.y) - Math.min(start.y, end.y)}
                  style={{ fill: pressed ? 'rgba(255, 0,0, 0.05)' : 'rgba(0,0,255, 0.05)' }}
                />
              )}
              <Legend />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
        <table>
          <thead>
            <tr>
              <th>X</th>
              <th>Y</th>
              <th>Z</th>
            </tr>
          </thead>
          <tbody>
            {selected.map(i => (
              <tr key={`${i.x}-${i.y}-${i.z}`}>
                <td>{i.x}</td>
                <td>{i.y}</td>
                <td>{i.z}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
