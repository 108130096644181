import React from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { Icon, SyncingLoaderIcon, Row, Text } from './index';
import { t } from '../i18n';

const getColor = kind => {
  if (['draft', 'free_trial', 'trial_running', 'trial_available', 'trial_active'].includes(kind)) return '#FFAE42';
  if (['synced', 'up_to_date', 'active', 'yet_active', 'active_not_used'].includes(kind)) return '#3ED95E';
  if (['sync_error', 'update_error', 'trial_expired'].includes(kind)) return '#FF493F';
  if (kind === 'deleted') return '#BF362F';
  if (kind === 'not_active') return '#C4C4C4';
  if (kind === 'stopped') return '#8C969F';
  return '#8C969F';
};

const getIconType = kind => {
  if (
    ['free_trial', 'trial_running', 'trial_passed', 'trial_expired', 'trial_available', 'trial_active'].includes(kind)
  ) {
    return 'fill-trial';
  }
  if (['synced', 'up_to_date', 'active', 'yet_active', 'active_not_used'].includes(kind)) return 'active';
  if (['sync_error', 'update_error'].includes(kind)) return 'fill-error';
  if (['paused', 'sync_paused'].includes(kind)) return 'fill-paused';
  if (kind === 'draft') return 'circle';
  if (kind === 'stopped') return 'fill-stopped';
  if (kind === 'not_active') return 'fill-draft';
  return 'minus-sign';
};

export const Status = ({ kind }) =>
  !kind ? null : (
    <Row inline center shrink>
      {['loading_status', 'syncing', 'updating'].includes(kind) ? (
        <SyncingLoaderIcon color="#4C759C" size="14px" />
      ) : (
        <Icon kind={getIconType(kind)} size="14px" color={getColor(kind)} />
      )}
      {
        <Text bold size="xs" className="ml-8 d-block" style={{ color: getColor(kind), letterSpacing: '1px' }}>
          {t(kind, { scope: 'subscription.status', default: kind }).toUpperCase()}
        </Text>
      }
    </Row>
  );

Status.propTypes = {
  kind: PropTypes.oneOf([
    'active_not_used',
    'active',
    'deleted',
    'draft',
    'free_trial',
    'loading_status',
    'not_active',
    'paused',
    'stopped',
    'sync_error',
    'sync_paused',
    'synced',
    'syncing',
    'trial_active',
    'trial_available',
    'trial_expired',
    'trial_passed',
    'trial_running',
    'up_to_date',
    'update_error',
    'updating',
    'yet_active',
  ]),
};
export default hot(Status);
