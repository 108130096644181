import React from 'react';
import Image from './Image';
import OptionsContext from '../../OptionsContext';
import {
  SizePanel,
  ImagePosition,
  defaultImagePlaceholderUrl,
  ImagePlaceholderPanel,
  ConditionsPanel,
  TimeRestrictionPanel,
  getOptionsFromUrl,
} from '../panels';

export default class ImagePlaceholder extends Image {
  static panels = [SizePanel, ImagePlaceholderPanel, ImagePosition, TimeRestrictionPanel, ConditionsPanel];

  static getDescription = objectAttributes =>
    objectAttributes.xlinkHref
      .replace(/[^=]*=/, '')
      .replace(/(\{|\}|:)/g, '')
      .replace(/&.*/, '')
      .replace(/CROP_IMAGE|MAGIC_WAND\d?|,/g, '');
  static meta = {
    icon: 'image-feed',
    useIconInLayers: true,
    initial: {
      width: 400,
      height: 400,
      preserveAspectRatio: 'xMidYMid meet',
      flipHorizontally: false,
      flipVertically: false,
      // Just a simple base64-encoded outline
      xlinkHref: defaultImagePlaceholderUrl,
    },
  };

  static getIcon(object, customImages) {
    const { placeholder } = getOptionsFromUrl(object.xlinkHref);
    const customImage = customImages.find(ci => `_${ci.placeholderName}_` === placeholder);

    if (customImage) {
      return 'image-type';
    }

    return this.meta.icon;
  }

  render() {
    const { object, onClick, onRender } = this.props;
    return (
      <OptionsContext.Consumer>
        {({ customImages }) => {
          const { placeholder } = getOptionsFromUrl(object.xlinkHref);
          const customImage = customImages.find(ci => `_${ci.placeholderName}_` === placeholder);

          if (customImage) {
            return (
              <g onClick={onClick} ref={onRender} style={this.getTranformStyle(object)}>
                <svg
                  x={object.x}
                  y={object.y}
                  preserveAspectRatio={object.preserveAspectRatio}
                  width={object.width}
                  height={object.height}
                  viewBox={customImage.viewBox}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: customImage.svg.join(' ') }}
                />
              </g>
            );
          }
          return (
            <image
              xlinkHref={object.xlinkHref}
              {...this.getObjectAttributes()}
              transform={null}
              style={this.getTranformStyle(object)}
              preserveAspectRatio={object.preserveAspectRatio}
              width={object.width}
              height={object.height}
            />
          );
        }}
      </OptionsContext.Consumer>
    );
  }
}
