import React from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { snakeCase } from 'lodash';
import MainChart from './MainPerformanceChart';
import PreviewTab from './PreviewTab';
import { INPUTS } from './index';
import { t } from '../i18n';
import { Col, Row, SectionHeading, useLocalState } from '../components/index';
import EmptyChart from './EmptyChart';
import HideButton from './HideButton';

const PerformanceOverview = ({ hideCloseButton, statsByDate, stats, sourceIsEmpty, ...rest }) => {
  const [selectedMetric, setSelectedMetric] = useLocalState(
    Object.keys(INPUTS).find(k => INPUTS[k].show),
    'PerformanceOverview-metric'
  );

  const isEmpty = sourceIsEmpty || statsByDate.length < 0 || !stats || Object.keys(stats).length <= 0;

  return (
    <div className="Tile Tile--shadow" data-test-id="performance-tile">
      <div className="Tile-header">
        <Row center>
          <Col shrink nowrap>
            <SectionHeading spacing={0}>{t(snakeCase(selectedMetric), { scope: 'stats_metrics' })}</SectionHeading>
          </Col>
          <Col shrink nowrap className="ml-a">
            <div className="Text Text--gray">{t(!isEmpty && 'stats.info')}</div>
          </Col>
          {!hideCloseButton && <HideButton />}
        </Row>
      </div>

      <div className="Tile-content text-center">
        <Row>{isEmpty ? <EmptyChart /> : <MainChart {...rest} area={selectedMetric} stats={stats} />}</Row>
        <Row shrink>
          {Object.keys(INPUTS)
            .filter(x => INPUTS[x].show)
            .map(k => (
              <PreviewTab
                data={statsByDate}
                dataKey={k}
                {...INPUTS[k]}
                stats={stats}
                isEmpty={isEmpty}
                isSelected={k === selectedMetric}
                key={k}
                onClick={setSelectedMetric}
                width={`${100 / Object.keys(INPUTS).filter(x => INPUTS[x].show).length}%`}
              />
            ))}
        </Row>
      </div>
    </div>
  );
};

PerformanceOverview.propTypes = {
  hideCloseButton: PropTypes.string,
  statsByDate: PropTypes.array,
  sourceIsEmpty: PropTypes.bool,
  stats: PropTypes.object,
};

export default hot(PerformanceOverview);
