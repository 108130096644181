import React, { PureComponent, useState, useContext } from 'react';
import { hot } from 'react-hot-loader/root';
import _ from 'lodash';
import WebFont from 'webfontloader';
import Dropzone from 'react-dropzone';
import { gql, useQuery, useMutation } from '@apollo/client';

import OptionsContext from '../../OptionsContext';
import PropertyGroup from './PropertyGroup';
import SwitchState from './SwitchState';
import { Row, Col, Button, Tooltip, SmartSelect, DropdownMenu, SmallHeading } from '../../../index';
import InputCol from './InputCol';
import PlaceholderInput from '../../../../placeholders/Input';
import Columns from './Columns';
import uploadFile from './uploadFile';
import { t } from '../../../../i18n';

const UploadNewFont = ({ refetchFonts, updateFamily }) => {
  const { organizationId, cannotEdit } = useContext(OptionsContext);

  const [isUploading, setUploading] = useState(false);
  const [error, setError] = useState(null);

  const [createFont, { loading }] = useMutation(
    gql`
      mutation CreateFont($organizationId: BigInt!, $name: String, $family: String, $fontData: String) {
        createFont(organizationId: $organizationId, name: $name, family: $family, fontData: $fontData) {
          font {
            id
            name
            family
            url
          }
          errors
        }
      }
    `,
    {
      onCompleted: data => {
        if (typeof refetchFonts === 'function') {
          refetchFonts();
          setTimeout(() => {
            updateFamily('', data.createFont.font.family);
            setUploading(false);
            setError(null);
            new window.NotificationCenter().show_success(t('react.image_generator.text.font_added'));
          }, 300);
        }
      },
      onError: err => {
        setError(err);
        setUploading(null);
        new window.NotificationCenter().show_error(t('react.image_generator.modal.server_error'));
      },
    }
  );

  const onDrop = acceptedFiles => {
    if (acceptedFiles.length === 0) {
      return;
    }
    setUploading(true);
    const file = acceptedFiles[0];
    const family = file.name
      .replace('.woff', '')
      .replace('.ttf', '')
      .replace('-webfont', '')
      .replace(/(_|-)/g, ' ');
    uploadFile(
      file,
      signedId => {
        createFont({
          variables: {
            organizationId,
            fontData: signedId,
            family,
            name: family,
          },
        });
      },
      err => {
        new window.NotificationCenter().show_error(t('react.image_generator.modal.server_error'));
        setError(err);
        setUploading(null);
      }
    );
  };

  return (
    <Dropzone
      accept=".woff,.ttf"
      onDrop={onDrop}
      multiple={false}
      style={{
        float: 'left',
        marginRight: '3px',
        padding: '3px',
        width: '32px',
        textAlign: 'center',
      }}
      activeStyle={{
        backgroundColor: 'white',
        color: 'black',
      }}
    >
      {({ getRootProps, getInputProps }) => (
        <Tooltip text={t('react.image_generator.layer.font')}>
          <Button
            secondary
            disabled={cannotEdit}
            error={error}
            loadingType="upload"
            loading={isUploading || loading}
            className="pos-relative"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <span>{t('react.image_generator.text.upload_font')}</span>
          </Button>
        </Tooltip>
      )}
    </Dropzone>
  );
};

let globalFontFamilies = [];
const loadedFonts = [];

export const loadWebFontIfNeeded = (fontFamily, fontFamilies = null, forceLoad = false) => {
  const foundFont = (fontFamilies || globalFontFamilies).find(font => font.family === fontFamily);

  if (foundFont && loadedFonts.indexOf(fontFamily) === -1) {
    if (foundFont && foundFont.url && foundFont.url.match(/\.(ttf|woff)$/i)) {
      const fontformat = foundFont.url.match(/\.(ttf|woff)$/i)[1].toLowerCase() === 'ttf' ? 'truetype' : 'woff';
      const css = `
      @font-face {
        font-family: '${fontFamily}';
        font-style: 'normal';
        font-weight: 'normal';
        src: url('${foundFont.url}') format('${fontformat}');
      }`;

      const styleEl = document.createElement('style');
      styleEl.setAttribute('type', 'text/css');
      if (styleEl.styleSheet) {
        // IE
        styleEl.styleSheet.cssText = css;
      } else {
        styleEl.appendChild(document.createTextNode(css));
      }

      document.head.appendChild(styleEl);
    } else {
      WebFont.load({
        google: {
          families: [fontFamily],
        },
      });
    }
    loadedFonts.push(fontFamily);
  } else if (forceLoad && loadedFonts.indexOf(fontFamily) === -1) {
    WebFont.load({
      google: {
        families: [fontFamily],
      },
    });
  }
  return foundFont;
};

class TextPanel extends PureComponent {
  handleFontFamilyChange = (x, value) => {
    const foundFont = loadWebFontIfNeeded(value, this.props.fontFamilies);
    this.props.onChange('fontFamily', value, {
      fontFamilyUrl: (foundFont && foundFont.url) || null,
      fontFamilyHover: null,
    });
  };

  handleTextDecorationLineChange = value => {
    this.props.onChange('textDecorationLine', value);

    if (this.props.object.textDecorationLine === 'none') {
      this.props.onChange('textDecorationLine', value, { textDecorationColor: this.props.object.color });
    }
  };

  handleAutomaticFontSizeChange = (x, value) => {
    this.props.onChange('automaticFontSize', value === 'true');
  };

  handleTextDecorationLineWidthChange = (x, value) => {
    this.props.onChange('textDecorationLineWidth', value);
  };

  handleTextDecorationStyleChange = (x, value) => {
    this.props.onChange('textDecorationStyle', value);
  };

  sortFonts = (f1, f2) => {
    if (f1.name.toLowerCase() > f2.name.toLowerCase()) {
      return 1;
    }
    return f1.name.toLowerCase() < f2.name.toLowerCase() ? -1 : 0;
  };

  unthrottledOnTextHover = el => {
    if (this.props.object.fontFamilyHover !== el.value) {
      loadWebFontIfNeeded(el.value, this.props.fontFamilies);
      this.props.onChange('fontFamilyHover', el.value);
    }
  };
  onTextHover = _.throttle(this.unthrottledOnTextHover, 300, { trailing: true });

  unthrottledOnTextBlur = () => {
    if (this.props.object.fontFamilyHover) {
      this.props.onChange('fontFamilyHover', null);
    }
  };

  onTextBlur = _.throttle(this.unthrottledOnTextBlur, 300, { trailing: true });

  render() {
    const { object, onChange, fontFamilies, refetchFonts } = this.props;
    const foundFont = fontFamilies.find(font => font.family === object.fontFamily);

    const fontFamiliesCollection = []
      .concat(fontFamilies)
      .sort(this.sortFonts)
      .map(({ family, name, ...rest }) => ({ ...rest, value: family, label: name }));

    return (
      <PropertyGroup key={object.uuid} showIf={_.has(object, 'text')}>
        <Col padding="l">
          <OptionsContext.Consumer>
            {({ formId }) => (
              <Columns label={t('react.image_generator.label.text')} labelOnSeparateLine delimiter>
                <PlaceholderInput
                  formId={formId}
                  defaultValue={object.text}
                  onChange={e => onChange('text', e.target.value)}
                />
              </Columns>
            )}
          </OptionsContext.Consumer>
          <Row className="mb-0" center>
            <Col grow>
              <SmartSelect
                key={`fontFamiliesSelect-${fontFamiliesCollection?.length}`}
                doNotUseInternalState
                name="imageEditor[fontFamily]"
                id="fontFamily"
                testId="test-input-fontFamily"
                value={object.fontFamily}
                onItemFocus={this.onTextHover}
                onChange={e => this.handleFontFamilyChange(null, e.target.value)}
                optionRenderer="FontFamilies"
                onMenuOpen={() => fontFamiliesCollection.map(({ value }) => loadWebFontIfNeeded(value, fontFamilies))}
                onMenuClose={this.onTextBlur}
                collection={fontFamiliesCollection}
              />
            </Col>
            <Col shrink>
              <UploadNewFont refetchFonts={refetchFonts} updateFamily={this.handleFontFamilyChange} />
            </Col>
          </Row>
          <Row className="mt-16 mb-16">
            <InputCol
              width="88px"
              property="fontSize"
              beforeUnit="Aᴀ"
              showIf={_.has(object, 'fontSize')}
              value={object.fontSize}
              onChange={onChange}
            />
            <InputCol
              property="color"
              value={object.color}
              type="color"
              showIf={_.has(object, 'color')}
              onChange={onChange}
            />

            {_.has(object, 'fontWeight') && (!foundFont || !foundFont.url) && (
              <Col shrink className="ml-8">
                <Tooltip text={t('bold', { scope: 'react.image_generator.text' })}>
                  <SwitchState
                    icon="bold"
                    defaultValue={'normal'}
                    nextState={'bold'}
                    value={object.fontWeight}
                    onChange={this.props.onChange.bind(this, 'fontWeight')}
                  />
                </Tooltip>
              </Col>
            )}
            {_.has(object, 'fontStyle') && (
              <Col shrink>
                <Tooltip text={t('italic', { scope: 'react.image_generator.text' })}>
                  <SwitchState
                    icon="italic"
                    defaultValue={'normal'}
                    nextState={'italic'}
                    value={object.fontStyle}
                    onChange={this.props.onChange.bind(this, 'fontStyle')}
                  />
                </Tooltip>
              </Col>
            )}
            {_.has(object, 'textDecorationLine') && (
              <React.Fragment>
                <Col shrink>
                  <Tooltip text={t('underline', { scope: 'react.image_generator.text' })}>
                    <SwitchState
                      icon="underline"
                      defaultValue={object.textDecorationLine.includes('line-through') ? 'line-through' : 'none'}
                      nextState={
                        object.textDecorationLine.includes('line-through') ? 'underline line-through' : 'underline'
                      }
                      value={object.textDecorationLine}
                      onChange={this.handleTextDecorationLineChange}
                    />
                  </Tooltip>
                </Col>

                <Col shrink>
                  <Tooltip text={t('strikethrough', { scope: 'react.image_generator.text' })}>
                    <SwitchState
                      icon="strikethrough"
                      defaultValue={object.textDecorationLine.includes('underline') ? 'underline' : 'none'}
                      nextState={
                        object.textDecorationLine.includes('underline') ? 'underline line-through' : 'line-through'
                      }
                      value={object.textDecorationLine}
                      onChange={this.handleTextDecorationLineChange}
                    />
                  </Tooltip>
                </Col>
              </React.Fragment>
            )}
          </Row>

          {_.has(object, 'textDecorationLine') &&
            (object.textDecorationLine.includes('underline') || object.textDecorationLine.includes('line-through')) && (
              <React.Fragment>
                <Columns label={t('react.image_generator.label.line')} withoutLabelPadding>
                  <InputCol
                    width="88px"
                    unit="px"
                    value={object.textDecorationThickness}
                    property="textDecorationThickness"
                    onChange={onChange}
                  />

                  <InputCol
                    property="textDecorationColor"
                    value={object.textDecorationColor}
                    type="color"
                    showIf={_.has(object, 'textDecorationColor')}
                    onChange={onChange}
                  />

                  <DropdownMenu
                    minWidth="320px"
                    placement="bottom-end"
                    hideOnClick={false}
                    secondary
                    primary={false}
                    withoutScroll
                  >
                    <div className="DropdownMenu-heading">
                      <SmallHeading spacing={0}>
                        {t('react.image_generator.dropdown_heading.text_decoration')}
                      </SmallHeading>
                    </div>
                    <div className="pt-16 pl-16 pr-16 pb-8">
                      <Columns label={t('react.image_generator.label.line_style')} withoutLabelPadding>
                        <InputCol
                          width="130px"
                          type="select"
                          collection={[
                            { value: 'solid', label: t('react.image_generator.label.line_style_solid') },
                            { value: 'double', label: t('react.image_generator.label.line_style_double') },
                            { value: 'dotted', label: t('react.image_generator.label.line_style_dotted') },
                            { value: 'dashed', label: t('react.image_generator.label.line_style_dashed') },
                            { value: 'wavy', label: t('react.image_generator.label.line_style_wavy') },
                          ]}
                          value={object.textDecorationStyle || 'solid'}
                          property="textDecorationStyle"
                          onChange={this.handleTextDecorationStyleChange}
                        />
                      </Columns>

                      <Columns label={t('react.image_generator.label.line_width')} withoutLabelPadding>
                        <InputCol
                          width="130px"
                          type="smartSelect"
                          collection={[
                            {
                              value: 0,
                              label: t('react.image_generator.label.line_width_0_spaces'),
                              icon: 'strikethrough-width',
                            },
                            {
                              value: 1,
                              label: t('react.image_generator.label.line_width_1_spaces'),
                              icon: 'strikethrough-width-medium',
                            },
                            {
                              value: 2,
                              label: t('react.image_generator.label.line_width_2_spaces'),
                              icon: 'strikethrough-width-large',
                            },
                          ]}
                          value={object.textDecorationLineWidth || 0}
                          property="textDecorationLineWidth"
                          onChange={this.handleTextDecorationLineWidthChange}
                        />
                      </Columns>
                    </div>
                  </DropdownMenu>
                </Columns>
              </React.Fragment>
            )}

          <Columns label={t('react.image_generator.label.long_text')} withoutLabelPadding>
            <InputCol
              type="select"
              collection={[
                { value: true, label: t('react.image_generator.label.automatic_size') },
                { value: false, label: t('react.image_generator.label.preserve_size') },
              ]}
              value={object.automaticFontSize || false}
              property="automaticFontSize"
              onChange={this.handleAutomaticFontSizeChange}
            />
          </Columns>
          <Columns label={t('react.image_generator.label.text_alignment')} withoutLabelPadding>
            <InputCol
              shrink
              type="segmented"
              value={object.textVerticalAlign}
              property="textVerticalAlign"
              onChange={onChange}
              collection={[
                {
                  value: 'start',
                  icon: 'arrow-top',
                  tooltipText: t('top', { scope: 'react.image_generator.align' }),
                },
                {
                  value: 'center',
                  icon: 'arrow-align-center',
                  tooltipText: t('vcenter', { scope: 'react.image_generator.align' }),
                },
                {
                  value: 'end',
                  icon: 'arrow-bottom',
                  tooltipText: t('bottom', { scope: 'react.image_generator.align' }),
                },
              ]}
            />
            <InputCol
              shrink
              type="segmented"
              value={object.textAlign}
              property="textAlign"
              onChange={onChange}
              collection={[
                {
                  value: 'left',
                  icon: 'text-left',
                  tooltipText: t('left', { scope: 'react.image_generator.text_alignment' }),
                },
                {
                  value: 'center',
                  icon: 'text-center',
                  tooltipText: t('center', { scope: 'react.image_generator.text_alignment' }),
                },
                {
                  value: 'right',
                  icon: 'text-right',
                  tooltipText: t('right', { scope: 'react.image_generator.text_alignment' }),
                },
              ]}
            />
          </Columns>

          {_.has(object, 'padding') && (
            <Columns label={t('react.image_generator.label.text_padding')} withoutLabelPadding>
              <InputCol width="88px" unit="px" value={object.padding} property="padding" onChange={onChange} />
            </Columns>
          )}
        </Col>
      </PropertyGroup>
    );
  }
}

const QUERY_ORGANIZATION_FONTS = gql`
  query LoadOrganizationFonts($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      allFonts {
        id
        name
        family
        url
      }
    }
  }
`;
const FontFamiliesLoader = props => {
  const { organizationId } = useContext(OptionsContext);
  const { data, refetch } = useQuery(QUERY_ORGANIZATION_FONTS, {
    variables: {
      organizationId,
    },
  });
  globalFontFamilies = (data && data.organization && data.organization.allFonts) || [];

  return (
    <TextPanel
      {...props}
      fontFamilies={(data && data.organization && data.organization.allFonts) || []}
      refetchFonts={refetch}
    />
  );
};

export const PreloadFontFamilies = ({ organizationId, objects }) => {
  const [preloaded, setPreloaded] = useState(false);
  const { data } = useQuery(QUERY_ORGANIZATION_FONTS, {
    variables: {
      organizationId,
    },
  });
  if (!preloaded) {
    const fontFamilies = [].concat((data && data.organization && data.organization.allFonts) || []);
    if (fontFamilies.length > 0) {
      // eslint-disable-next-line array-callback-return
      objects.map(object => {
        if (object.fontFamily) {
          loadWebFontIfNeeded(object.fontFamily, fontFamilies);
        }
      });
      setTimeout(() => setPreloaded(true), 1);
    }
  }
  return null;
};

export default hot(FontFamiliesLoader);
