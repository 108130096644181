/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { flatten } from 'lodash';
import { gql } from '@apollo/client';
import cs from 'classnames';
import {
  Badge,
  Button,
  Col,
  DragHandle,
  DropdownMenu,
  Ellipsis,
  Icon,
  InfoBox,
  Link,
  Row,
  Text,
  Tile,
  Tooltip,
  UsedByGraph,
  cssVariables,
} from '../index';
import { modalDataUrlOpener } from '../RemoteModal';
import { t, tHtml, formatPercentage, formatNumberInflection } from '../../i18n';
import { ConditionBadgeContent, HtmlCellContent, StatusSwitchContent, StateContent } from '../table/mapping/cells';
import { EditableWrapper } from '../table/mapping/wrappers';
import { ArrowWrapper } from './Arrows';
import ChipsGroup, { CampaignChip } from './ChipsGroup';
import {
  IconLink,
  ProductsCell,
  ActionCell,
  SIZES,
  FeatureLine as ProductSetFeatureContent,
} from './FBManualCampaigns';
import SegmentedButton from './SegmentedButton';

const TimeRestrictionSwitch = EditableWrapper(StatusSwitchContent);

const UPDATE_FB_PRODUCT_GROUP_IDS = ({ updateGraphqlObject, id, getGraphqlObject }) =>
  gql`mutation UpdateGraphqlObject($newArray: [BigInt!], $matchAll: Boolean){ ${updateGraphqlObject}(id: ${id}, fbProductGroupIds: $newArray, matchAll: $matchAll) { ${getGraphqlObject} { id fbProductGroupIds matchAll } errors } }`;

const UPDATE_FB_PRODUCT_CAMPAIGN_IDS = ({ updateGraphqlObject, id, getGraphqlObject }) =>
  gql`mutation UpdateGraphqlObject($newArray: [BigInt!], $matchAll: Boolean){ ${updateGraphqlObject}(id: ${id}, fbProductCampaignIds: $newArray, matchAll: $matchAll) { ${getGraphqlObject} { id fbProductCampaignIds matchAll } errors } }`;

const UPDATE_MATCH_ALL = ({ updateGraphqlObject, id, getGraphqlObject }) =>
  gql`mutation UpdateGraphqlObject($matchAll: Boolean){ ${updateGraphqlObject}(id: ${id}, matchAll: $matchAll) { ${getGraphqlObject} { id fbProductGroupIds fbProductCampaignIds matchAll } errors } }`;

const TooltipBadgeWrapper = ({ children, onClick, ...rest }) => (
  <Tooltip {...rest}>
    <span onClick={onClick}>
      <Badge uppercase kind="lightGray" className="cursor-pointer" size="medium">
        {children}
      </Badge>
    </span>
  </Tooltip>
);
TooltipBadgeWrapper.propTypes = { children: PropTypes.any.isRequired, onClick: PropTypes.func };

const getTooltipContent = (campaignIds, adIds, campaigns) => {
  const selectedCampaigns = campaignIds.map(id => campaigns.find(c => c.id === id)).map(({ name }) => name);
  const allAds = flatten(campaigns.reduce((acc, cur) => [...acc, cur?.fbProductGroups], []));

  const selectedAds = adIds
    .map(id => allAds.find(c => c.id === id))
    .filter(x => !!x)
    .map(({ name }) => name);

  if (selectedCampaigns?.length === 0 && selectedAds?.length === 0) return null;

  if (selectedCampaigns?.length > 0 && selectedAds?.length > 0) {
    return `<div>
              <div class='Text--bold'>${t('react.fb_manual_structure.selected_campaigns')}:</div>
              <div class='mt-4'> ${selectedCampaigns.join(', ')}</div>
              <div class='Text--bold mt-8'>${t('react.fb_manual_structure.selected_ads')}:</div>
              <div class='mt-4'> ${selectedAds.join(', ')}</div>
            </div>`;
  }

  if (selectedCampaigns?.length > 0) {
    return `<div>
              <div class='Text--bold'>${t('react.fb_manual_structure.selected_campaigns')}:</div>
              <div class='mt-4'> ${selectedCampaigns.join(', ')}</div>
            </div>`;
  }

  return `<div>
            <div class='Text--bold mt-8'>${t('react.fb_manual_structure.selected_ads')}:</div>
            <div class='mt-4'> ${selectedAds.join(', ')}</div>
          </div>`;
};

const SelectedBadge = ({ matchAll, campaignIds = [], adIds = [], campaigns = [], onClick }) => {
  const campaignsSize = campaignIds?.length;
  const adsSize = adIds?.length;

  if (matchAll) {
    return (
      <TooltipBadgeWrapper text={t('react.fb_manual_structure.match_all_tooltip')} onClick={onClick}>
        {t('react.fb_manual_structure.match_all')}
      </TooltipBadgeWrapper>
    );
  }

  if (campaignsSize === 0 && adsSize === 0) {
    return <span />;
  }

  return (
    <TooltipBadgeWrapper text={getTooltipContent(campaignIds, adIds, campaigns)} onClick={onClick}>
      <Row center>
        {campaignsSize > 0 && (
          <Col shrink>
            <span>
              <Icon kind="fb-campaign" color="#26394a" size="11px" className="mr-4" />
              {campaignsSize}
            </span>
          </Col>
        )}
        {adsSize > 0 && (
          <Col shrink>
            <span>
              <Icon kind="adset" color="#26394a" size="11px" className="mr-4" />
              {adsSize}
            </span>
          </Col>
        )}
      </Row>
    </TooltipBadgeWrapper>
  );
};
SelectedBadge.propTypes = {
  adIds: PropTypes.array,
  campaignIds: PropTypes.array,
  campaigns: PropTypes.array,
  matchAll: PropTypes.bool,
  onClick: PropTypes.func,
};

const OriginalFeatureLine = ({
  adSetsTotal,
  canEdit,
  dataSourceStillLoading,
  disableFunel,
  disableProductCampaignSelection,
  campaigns,
  getGraphqlObject,
  disableStatusSwitch,
  icon,
  isDraft,
  newBranchName,
  otherBranches = [],
  productsTotal,
  refetch,
  type,
  unusedItemText,
  updateGraphqlObject,
  conditions,
  deleteLink,
  disabledDeleteLink,
  description,
  adwordsErrors,
  duplicateLink,
  duplicateIntoCampaignsLink,
  editLink,
  fbProductCampaignIds,
  fbProductGroupIds,
  children,
  id,
  isDefault,
  matchAll,
  name,
  numberOfAdSets,
  numberOfProducts,
  status,
  timeRestriction,
  timeRestrictionStatus,
  typeIcon,
  updateLink,
  isDropdownOpen,
  toggleDropdown,
  useOnlyProducts,
  useUsageProducts,
  updateLinkParamRoot,
  statusCampaignSwitchWarning,
  itemGroups,
}) => (
  <div style={{ zIndex: 110 }}>
    <div
      className={cs(
        'Preview-tileItem',
        'Preview-tileItem--dragable',
        'Preview-tileItem--hoverable',
        'Preview-tileItem--spaced',
        'mv-8',
        { 'pb-0': isDropdownOpen }
      )}
      style={{ zIndex: 'auto' }}
      data-test-id="feature-line"
    >
      {!isDefault && !disableFunel && <DragHandle disabled={!canEdit} />}
      <Row center style={{ padding: '1px 0' }}>
        {!disableStatusSwitch && (
          <Col shrink>
            <Button
              tertiary
              size="small"
              onlyIcon
              icon={isDropdownOpen ? 'chevron-down' : 'chevron-right'}
              onClick={toggleDropdown}
              data-test-id="accordeon-details"
            />
          </Col>
        )}
        {icon && (
          <Col shrink>
            <Icon kind={icon} color="#74748c" size="15px" className="mr-4" />
          </Col>
        )}
        <Col grow>
          <Row
            center
            className="cursor-pointer"
            {...(editLink && modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' }))}
          >
            {typeIcon && !icon && (
              <Col shrink className="mr-16">
                <Icon kind={typeIcon} color={cssVariables.grapePrimary} size="20px" />
              </Col>
            )}
            <Col grow noPadding>
              <Row>
                <Text bold color="grape">
                  {name}
                </Text>
              </Row>
              <Row>
                <Text color="gray" className="w-100" size="sm">
                  <Ellipsis>
                    {/* eslint-disable-next-line react/no-danger */}
                    <span dangerouslySetInnerHTML={{ __html: description }} />
                  </Ellipsis>
                </Text>
              </Row>
            </Col>
            {adwordsErrors && (
              <Col shrink>
                <Tooltip text={adwordsErrors}>
                  <Icon kind="warning" color="red" size={16} />
                </Tooltip>
              </Col>
            )}
          </Row>
        </Col>

        <Col width="204px" className="mr-16">
          <Row center>
            <Col shrink className="mr-4">
              {numberOfProducts === 0 &&
              numberOfAdSets === 0 &&
              timeRestrictionStatus !== 'restricted' &&
              status !== 'paused' ? (
                <ArrowWrapper>
                  <Tooltip text={unusedItemText}>
                    <Icon size="24px" kind="graph-error" color="red" />
                  </Tooltip>
                </ArrowWrapper>
              ) : (
                <ArrowWrapper>
                  <UsedByGraph
                    size="md"
                    adSets={useUsageProducts ? -1 : numberOfAdSets}
                    adSetsTotal={useUsageProducts ? -1 : adSetsTotal}
                    products={numberOfProducts}
                    productsTotal={productsTotal}
                    doNotShowPercent={dataSourceStillLoading}
                  />
                </ArrowWrapper>
              )}
            </Col>
            {!useUsageProducts && adSetsTotal > 0 && (
              <Col shrink>
                <Text color="gray">
                  {formatNumberInflection({
                    count: numberOfAdSets,
                    scope: 'react.fb_campaign_preview.adsets_inflected',
                    zeroIsSpecial: true,
                  })}{' '}
                  {numberOfAdSets > 0 && <span>({formatPercentage((numberOfAdSets / adSetsTotal) * 100)})</span>}
                </Text>
              </Col>
            )}
            <Col grow noPadding>
              <Row>
                {useUsageProducts && (
                  <Col shrink>
                    <Text color="gray">
                      {formatNumberInflection({
                        count: numberOfProducts,
                        scope: 'react.fb_campaign_preview.products',
                        zeroIsSpecial: true,
                      })}{' '}
                      {numberOfProducts > 0 && (
                        <span>({formatPercentage((numberOfProducts / productsTotal) * 100)})</span>
                      )}
                    </Text>
                  </Col>
                )}
                {!useUsageProducts && useOnlyProducts && (
                  <Col shrink>
                    <Text color="gray">
                      {formatNumberInflection({
                        count: numberOfProducts,
                        scope: 'react.fb_campaign_preview.ad_groups_inflected',
                        zeroIsSpecial: true,
                      })}{' '}
                      {numberOfProducts > 0 && (
                        <span>({formatPercentage((numberOfProducts / productsTotal) * 100)})</span>
                      )}
                    </Text>
                  </Col>
                )}
              </Row>
              {!useOnlyProducts && (
                <Row>
                  <SelectedBadge
                    matchAll={matchAll}
                    campaigns={campaigns}
                    campaignIds={fbProductCampaignIds}
                    onClick={toggleDropdown}
                  />
                </Row>
              )}
              {conditions && conditions.length > 0 && (
                <Row className="mt-4">
                  <ConditionBadgeContent itemGroups={itemGroups}>{conditions}</ConditionBadgeContent>
                </Row>
              )}
            </Col>
          </Row>
        </Col>

        <Col width="96px">
          <Row center justifyEnd>
            {timeRestriction?.text && (
              <Col shrink>
                <Badge kind="lightGray" size="medium" className="text-center">
                  <HtmlCellContent>{timeRestriction.text}</HtmlCellContent>
                </Badge>
              </Col>
            )}

            <Col shrink>
              {(!!isDraft && <StateContent>{{ state: 'draft', kind: 'adtext' }}</StateContent>) ||
                (!disableStatusSwitch && (
                  <TimeRestrictionSwitch
                    canEdit={canEdit}
                    onUpdate={refetch}
                    editQuery={({ enabled }) =>
                      gql`mutation { ${updateGraphqlObject}(id: ${id}, status: "${
                        enabled ? 'running' : 'paused'
                      }") { ${getGraphqlObject} { id status } errors } }`
                    }
                    statusCampaignSwitchWarning={statusCampaignSwitchWarning}
                  >
                    {{ enabled: status === 'running', timeRestrictionStatus }}
                  </TimeRestrictionSwitch>
                ))}
            </Col>

            {(editLink || duplicateLink || duplicateIntoCampaignsLink || deleteLink || updateLink) && (
              <Col shrink>
                <DropdownMenu small dataTestId={`dropdown-action-${name}`}>
                  {editLink && (
                    <Link
                      href="#"
                      data-test-id-edit={name}
                      {...modalDataUrlOpener({ url: editLink, size: 'fullwithGrid' })}
                    >
                      <Icon kind="edit" inheritColor className="pr-8" />
                      <span>{t('react.fb_campaign_preview.edit')}</span>
                    </Link>
                  )}
                  {updateLink &&
                    (otherBranches || []).map(({ linkParams, branch }) => (
                      <Link
                        key={branch}
                        href={`${updateLink}?${linkParams}`}
                        data-method="PATCH"
                        data-remote="true"
                        data-container="body"
                        onClick={() => document.body.click()}
                        data-test-id-move-item={name}
                        data-test-id-move-branch={branch}
                        rel="nofollow"
                      >
                        <Icon kind="arrow-right" inheritColor className="pr-8" />
                        <span>
                          {tHtml('react.fb_campaign_preview.move_to_html', {
                            branch,
                            default: 'Move to <b>%{branch}</b>',
                          })}
                        </span>
                      </Link>
                    ))}
                  {updateLink && newBranchName && (
                    <Link
                      key={newBranchName}
                      href={`${updateLink}?${updateLinkParamRoot}[group]=${encodeURIComponent(newBranchName)}`}
                      data-method="PATCH"
                      data-remote="true"
                      data-container="body"
                      onClick={() => document.body.click()}
                      data-test-id-move-item={name}
                      data-test-id-move-branch={newBranchName}
                      rel="nofollow"
                    >
                      <Icon kind="arrow-right" inheritColor className="pr-8" />
                      <span>
                        {tHtml('react.fb_campaign_preview.move_to_new_html', {
                          branch: newBranchName,
                          default: 'Move to new <b>%{branch}</b>',
                        })}
                      </span>
                    </Link>
                  )}
                  {duplicateLink && (
                    <Link
                      href="#"
                      data-test-id-duplicate={name}
                      {...modalDataUrlOpener({ url: duplicateLink, size: 'fullwithGrid' })}
                    >
                      <Icon kind="duplicate" inheritColor className="pr-8" />
                      <span>{t('react.fb_campaign_preview.duplicate')}</span>
                    </Link>
                  )}
                  {duplicateIntoCampaignsLink && (
                    <Link
                      href="#"
                      data-test-id-duplicate-to={name}
                      {...modalDataUrlOpener({
                        url: duplicateIntoCampaignsLink,
                        size: 'medium',
                        heading: t('views.campaign_settings.index.duplicate_to_campaigns_with_name', {
                          default: 'Duplicate %{name} to campaign:',
                          name,
                        }),
                      })}
                    >
                      <Icon kind="duplicate-to" inheritColor className="pr-8" />
                      <span>{t('views.shared.duplicate_into_campaigns_button.duplicate_to')}</span>
                    </Link>
                  )}
                  {deleteLink && (
                    <Link
                      danger
                      href={deleteLink}
                      data-method="delete"
                      data-remote="true"
                      data-confirm={t('react.fb_campaign_preview.delete_confirmation', { name })}
                      data-commit={t('views.campaign_settings.index.yes', { default: 'Yes' })}
                      data-container="body"
                      data-test-id-delete={name}
                      rel="nofollow"
                    >
                      <Icon kind="trash" inheritColor className="pr-8" />
                      <span>{t('react.fb_campaign_preview.delete')}</span>
                    </Link>
                  )}
                  {disabledDeleteLink && (
                    <Tooltip text={disabledDeleteLink}>
                      <Link danger disabled>
                        <Icon kind="trash" inheritColor className="pr-8" />
                        <span>{t('react.fb_campaign_preview.delete')}</span>
                      </Link>
                    </Tooltip>
                  )}
                </DropdownMenu>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      {children && <Row>{children}</Row>}
      {isDropdownOpen && (
        <div className="mt-8">
          <SegmentedButton
            name={`feature-line[${id}][selection-match]`}
            matchAll={matchAll}
            mutation={UPDATE_MATCH_ALL({ getGraphqlObject, id, updateGraphqlObject })}
            onChange={refetch}
            campaignBudget={type === 'fbCampaignBudgets'}
          />
          {type === 'fbCampaignBudgets'
            ? `${matchAll}` === 'false' && (
                <Tile className="mb-8" contentStyle={{ paddingBottom: '12px' }}>
                  <ChipsGroup
                    collection={campaigns}
                    icon="fb-campaign"
                    mutation={UPDATE_FB_PRODUCT_CAMPAIGN_IDS({ updateGraphqlObject, id, getGraphqlObject })}
                    selectedCollection={fbProductCampaignIds}
                    onChange={refetch}
                    testId="campaign-group"
                  />
                </Tile>
              )
            : `${matchAll}` === 'false' &&
              campaigns?.map(campaign => (
                <Tile className="mb-8" contentStyle={{ paddingBottom: '12px' }} key={campaign.id}>
                  <CampaignChip
                    collection={campaign.fbProductGroups}
                    mutation={UPDATE_FB_PRODUCT_CAMPAIGN_IDS({ getGraphqlObject, id, updateGraphqlObject })}
                    selectedCollection={fbProductCampaignIds}
                    onChange={refetch}
                    testId="adset-campaign"
                    id={campaign.id}
                    name={campaign.name}
                    icon="fb-campaign"
                    disableSelection={disableProductCampaignSelection}
                  />

                  {fbProductCampaignIds.indexOf(campaign.id) === -1 && (
                    <React.Fragment>
                      <div className="mv-4">
                        <Text>{t('views.campaign_settings.fb_resource.conditions.ad_sets')}</Text>
                      </div>
                      <ChipsGroup
                        collection={campaign.fbProductGroups}
                        icon="adset"
                        mutation={UPDATE_FB_PRODUCT_GROUP_IDS({ getGraphqlObject, id, updateGraphqlObject })}
                        onChange={refetch}
                        selectedCollection={fbProductGroupIds}
                        testId="adset-group"
                      />
                    </React.Fragment>
                  )}
                </Tile>
              ))}
        </div>
      )}
    </div>
  </div>
);

OriginalFeatureLine.propTypes = {
  adSetsTotal: PropTypes.number,
  campaigns: PropTypes.array,
  canEdit: PropTypes.bool,
  conditions: PropTypes.array,
  data: PropTypes.object,
  disableProductCampaignSelection: PropTypes.bool,
  disableStatusSwitch: PropTypes.bool,
  adwordsErrors: PropTypes.string,
  dataSourceStillLoading: PropTypes.bool,
  deleteLink: PropTypes.string,
  disabledDeleteLink: PropTypes.string,
  description: PropTypes.string,
  disableFunel: PropTypes.bool,
  duplicateLink: PropTypes.string,
  duplicateIntoCampaignsLink: PropTypes.string,
  editLink: PropTypes.string,
  fbProductCampaignIds: PropTypes.array,
  fbProductGroupIds: PropTypes.array,
  getGraphqlObject: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.string,
  isDraft: PropTypes.bool,
  id: PropTypes.number,
  isDefault: PropTypes.bool,
  isDropdownOpen: PropTypes.bool,
  matchAll: PropTypes.bool,
  name: PropTypes.string,
  newBranchName: PropTypes.string,
  numberOfAdSets: PropTypes.number,
  numberOfProducts: PropTypes.number,
  otherBranches: PropTypes.array,
  productsTotal: PropTypes.number,
  refetch: PropTypes.func,
  status: PropTypes.string,
  timeRestriction: PropTypes.object,
  timeRestrictionStatus: PropTypes.string,
  toggleDropdown: PropTypes.func,
  type: PropTypes.string,
  typeIcon: PropTypes.string,
  unusedItemText: PropTypes.string,
  useOnlyProducts: PropTypes.bool,
  useUsageProducts: PropTypes.bool,
  updateGraphqlObject: PropTypes.string,
  updateLink: PropTypes.string,
  updateLinkParamRoot: PropTypes.string,
  statusCampaignSwitchWarning: PropTypes.bool,
};

const CustomFeatureLine = props => (
  <div style={{ zIndex: 110 }} className="pl-32" data-test-id="feature-line">
    <div
      className={cs('Preview-tileItem', 'Preview-tileItem--hoverable', 'Preview-tileItem--spaced', 'mv-8', {
        'pb-0': props?.isDropdownOpen,
      })}
      style={{ zIndex: 'auto' }}
    >
      {!props?.disableFunel && <DragHandle disabled={!props?.canEdit} />}
      <Row className="border-color-soft-gray border-bottom pv-8 negative-mh-8 ph-8 mb-8">
        <Col noPadding className="ma-0 pa-8">
          <IconLink {...props} spaced icon="adset" />
        </Col>
        <ActionCell {...props} />
      </Row>
      <Row top className="mt-0">
        <Col width={`${SIZES.first + SIZES.second - 50}px`} className="ma-0 pa-8">
          <Row center>
            {props.data.fbProductSet ? (
              <ProductsCell {...props} useArrow={!props?.disableFunel}>
                <ProductSetFeatureContent
                  editLink={props.data.editLink}
                  data={props.data.fbProductSet}
                  itemGroups={props.itemGroups}
                  icon="product-set"
                  data-test-id="feature-productset"
                />
              </ProductsCell>
            ) : (
              <Link
                danger
                {...(props.data.editLink && modalDataUrlOpener({ url: props.data.editLink, size: 'fullwithGrid' }))}
                data-test-id="feature-budget"
              >
                Missing product set
              </Link>
            )}
          </Row>
        </Col>
        {props?.children && (
          <Col grow className="ma-0 pa-8">
            {props?.children}
          </Col>
        )}
      </Row>
    </div>
  </div>
);

CustomFeatureLine.propTypes = {
  data: PropTypes.object,
  itemGroups: PropTypes.array,
};

export default class FeatureLine extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    newManualCampaign: PropTypes.bool,
    statusCampaignSwitchWarning: PropTypes.bool,
  };

  state = {
    isDropdownOpen: false,
  };

  toggleDropdown = () => this.setState({ isDropdownOpen: !this.state.isDropdownOpen });

  render() {
    if (!this.props.data) {
      return (
        <div className="mb-8 cursor-auto" onClick={e => e.stopPropagation()}>
          <InfoBox type="warning">{t('react.fb_campaign_preview.missing_data')}</InfoBox>
        </div>
      );
    }

    const { isDropdownOpen } = this.state;
    const Component = this.props?.newManualCampaign ? CustomFeatureLine : OriginalFeatureLine;

    return (
      <Component
        {...this.props}
        {...this.props.data}
        toggleDropdown={this.toggleDropdown}
        isDropdownOpen={isDropdownOpen}
      />
    );
  }
}
