import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../../placeholders/Input';
import UtmBuilder from '../utm_builder/UtmBuilder';
import { Link, Modal } from '../index';
import { t } from '../../i18n';

class UTMParams extends React.Component {
  static propTypes = {
    disableProductPlaceholders: PropTypes.object,
    doNotUseInternalState: PropTypes.bool,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    placeholders: PropTypes.array,
    setParameters: PropTypes.func,
    value: PropTypes.any.isRequired,
  };

  state = {
    value: this.props.value || '',
    defaultValue: this.props.value || '',
    showModal: false,
  };

  setModalVisibility = val => this.setState({ showModal: val });

  setParameters = value => {
    this.setState({ defaultValue: value, value });
    this.setModalVisibility(false);
  };
  setValue = value => {
    this.setState({ value });
  };

  setRef = el => {
    this.input = el;
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ value });
    if (this.input) {
      this.input.dispatchEvent(new window.Event('change', { bubbles: true }));
    }
    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value } });
    }
  };

  render() {
    const value = this.props.doNotUseInternalState ? this.props.value : this.state.value;
    const placeholderInputProps = {
      placeholders: this.props.placeholders,
      disableProductPlaceholders: this.props.disableProductPlaceholders,
    };
    return (
      <div>
        <PlaceholderInput
          name={this.props.name}
          {...placeholderInputProps}
          onChange={this.handleChange}
          defaultValue={this.state.defaultValue}
          key={this.state.defaultValue}
          setRef={this.setRef}
          placeholder={this.props.placeholder}
        />
        <Link
          data-test-id="override-text-link"
          className="mt-4 d-inline-block"
          onClick={() => {
            this.setValue(value);
            this.setModalVisibility(true);
          }}
        >
          {t('react.utm_parameters.modal_heading')}
        </Link>
        {this.state.showModal && (
          <Modal heading={t('react.utm_parameters.modal_heading')} onClose={() => this.setModalVisibility(false)}>
            <UtmBuilder
              placeholderInputProps={placeholderInputProps}
              url={this.state.value}
              setParameters={this.setParameters}
            />
          </Modal>
        )}
      </div>
    );
  }
}

export default UTMParams;
