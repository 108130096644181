import { gql } from '@apollo/client';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import List from '../fb_features/List';

const AdwordsExtensionsList = props => {
  const { collectionKey, campaignSettingId, organizationId, elementType } = props;
  const query = useMemo(
    () => gql`
      query ADWORDS_EXTENSION_QUERY${collectionKey}($organizationId: BigInt!, $campaignSettingId: BigInt ${
      elementType ? ', $elementType: String' : ''
    }) {
        organization(id: $organizationId) {
          id
          campaignSetting(id: $campaignSettingId) {
            id
            itemsCount
            dataSource {
              isFirstLoading
            }
          }
        }
        collection(identifier: "FBFeatures${collectionKey}", limit: 100000, page: 0, organizationId: $organizationId, campaignSettingId: $campaignSettingId) {
          id
          ${collectionKey}${elementType ? '(elementType: $elementType)' : ''} {
            id
            name
            typeIcon
            position
            numberOfProducts
            description
            status
            conditions { toDataPreview }
            editLink
            matchAll
            adwordsErrors
            duplicateLink
            duplicateIntoCampaignsLink
            deleteLink
            timeRestriction {
              text
            }
            timeRestrictionStatus
          }
        }
      }
    `,
    [collectionKey]
  );
  return (
    <List
      {...props}
      query={query}
      variables={{ organizationId, campaignSettingId, elementType }}
      type={collectionKey}
      statusCampaignSwitchWarning
    />
  );
};

AdwordsExtensionsList.propTypes = {
  addDefaultResource: PropTypes.object,
  addResource: PropTypes.object,
  adSetCount: PropTypes.number,
  campaignSettingId: PropTypes.number,
  collectionKey: PropTypes.string,
  elementType: PropTypes.string,
  organizationId: PropTypes.number,
  useOnlyProducts: PropTypes.bool,
  updateGraphqlObject: PropTypes.string,
};
export default AdwordsExtensionsList;
