/* eslint-disable react/prop-types */
import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import {
  MainIdentifierWithSwitcherCell,
  ArrayHtmlCell,
  AdSystemCell,
  HtmlCell,
  LinkCell,
  TextCell,
  IntegerCell,
  TimeAgoCell,
  SwitchCell,
} from './cells';
import { t } from '../../../i18n';
import labels from './labels';
import { modalDataUrlOpener } from '../../RemoteModal';
import conditions from './conditions';
import { IntegerEdit, LinkEdit } from './editable/index';

const mapping = {
  ...setupColumn({
    id: 'name',
    order: 'name',
    graphqlFields: [
      'name',
      'id',
      'editLink',
      'duplicateIntoCampaignsLink',
      'duplicateLink',
      'deleteLink',
      'status',
      'timeRestrictionStatus',
      { timeRestriction: ['tooltip', 'dateFrom', 'dateTo', 'generateString'] },
    ],
    required: true,
    cell: MainIdentifierWithSwitcherCell,
    editable: LinkEdit,
    editButtonAsProp: true,
    fixed: 'left',
    width: '300px',
    editQuery: ({ id, useOnlyID }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') {
        changes.push(`status: "${enabled ? 'running' : 'paused'}"`);
      }
      if (useOnlyID) {
        return gql`mutation { updateKeywordSetting(id: ${id}, ${changes.join(
          ','
        )} ) { keywordSetting { id status timeRestrictionStatus timeRestriction { tooltip id } } errors } }`;
      }
      return gql`mutation UpdateKW($text: String) { updateKeywordSetting(id: ${id}, name: $text, ${changes.join(
        ','
      )} ) { keywordSetting { id status name timeRestrictionStatus timeRestriction { tooltip id } } errors } }`;
    },
    resolveChildren: ({
      id,
      name,
      editLink,
      duplicateIntoCampaignsLink,
      duplicateLink,
      deleteLink,
      status,
      timeRestrictionStatus,
      timeRestriction,
    }) => ({
      id,
      name,
      status,
      timeRestrictionStatus,
      text: name,
      timeRestrictionTooltip: timeRestriction?.tooltip,
      link: editLink,
      'data-test-id': `dropdown-action-${name}`,
      links: [
        {
          href: editLink,
          'data-test-id': 'edit-bid-rule-button',
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        {
          href: duplicateLink,
          size: 'fullwithGrid',
          children: t('views.campaign_settings.index.duplicate'),
          'data-test-id-duplicate': name,
          icon: 'duplicate',
        },
        {
          href: duplicateIntoCampaignsLink,
          ...modalDataUrlOpener({
            url: duplicateIntoCampaignsLink,
            size: 'medium',
            heading: t('views.campaign_settings.index.duplicate_to_campaigns_with_name', {
              default: 'Duplicate %{name} to campaign:',
              name,
            }),
          }),
          children: t('views.campaign_settings.index.duplicate_to_campaigns', {
            default: 'Duplicate to other campaigns',
          }),
          'data-test-id-duplicate-to': name,
          icon: 'duplicate-to',
        },
        {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
      ],
    }),
    header: () => t('react.tables.keyword_settings.name'),
  }),

  // placeholder-box Placeholders-item--missing
  ...setupColumn({
    id: 'campaign',
    cell: LinkCell,
    graphqlFields: 'campaignSetting {showLink name}',
    resolveChildren: ({ campaignSetting }) => ({ link: campaignSetting?.showLink, text: campaignSetting?.name }),
    header: () => t('react.tables.keyword_settings.campaign'),
  }),

  ...conditions,
  ...labels,

  ...setupColumn({
    id: 'keywordPreview',
    cell: ArrayHtmlCell,
    graphqlFields: 'keywordsPreviews',
    resolveChildren: ({ keywordsPreviews = [] }) => keywordsPreviews,
    header: () => t('react.tables.keyword_settings.keywordPreview'),
  }),
  ...setupColumn({
    id: 'systems',
    header: () => t('react.tables.adtexts.ad_systems'),
    graphqlFields: ['facebook', 'bing', 'sklik', 'adwords'],
    required: true,
    cell: AdSystemCell,
    resolveChildren: ({ facebook, bing, sklik, adwords }) =>
      [facebook && 'facebook', bing && 'bing', sklik && 'sklik', adwords && 'adwords'].filter(item => item),
  }),

  ...setupColumn({
    id: 'timeRestriction',
    cell: HtmlCell,
    graphqlFields: 'timeRestriction {text}',
    resolveChildren: ({ timeRestriction }) => timeRestriction?.text || '',
    header: () => t('react.tables.keyword_settings.timeRestriction'),
  }),
  ...setupColumn({
    id: 'prefix',
    cell: ArrayHtmlCell,
    graphqlFields: 'prefixPreview',
    width: '150px',
    resolveChildren: ({ prefixPreview }) => prefixPreview,
    header: () => t('activerecord.attributes.keyword_setting.prefix'),
  }),
  ...setupColumn({
    id: 'keywordText',
    cell: ArrayHtmlCell,
    graphqlFields: 'keywordTextPreview',
    resolveChildren: ({ keywordTextPreview }) => keywordTextPreview,
    header: () => t('react.tables.keyword_settings.keywordText'),
  }),
  ...setupColumn({
    id: 'suffix',
    cell: ArrayHtmlCell,
    graphqlFields: 'suffixPreview',
    width: '150px',
    resolveChildren: ({ suffixPreview }) => suffixPreview,
    header: () => t('activerecord.attributes.keyword_setting.suffix'),
  }),

  ...setupColumn({
    id: 'limitWords',
    order: 'limit_words',
    cell: IntegerCell,
    width: '150px',
    editable: IntegerEdit,
    graphqlFields: ['limitWordsEnable', 'limitWords'],
    header: () => t('activerecord.attributes.keyword_setting.limit_words'),
    resolveChildren: ({ limitWordsEnable, limitWords }) => (limitWordsEnable ? limitWords : null),
    editQuery: ({ limitWordsEnable, id }) =>
      limitWordsEnable &&
      (children =>
        gql`
        mutation {
          updateKeywordSetting(id: ${id}, limitWords: ${children}) { keywordSetting { id limitWords } errors }
        }
      `),
  }),

  ...setupColumn({
    id: 'matchType',
    cell: TextCell,
    graphqlFields: 'matchType',
    header: () => t('activerecord.attributes.keyword_setting.match_type'),
    resolveChildren: ({ matchType }) => matchType && t(matchType, { scope: 'keyword_match_types' }),
  }),

  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    cell: TimeAgoCell,
    graphqlFields: 'createdAt',
    reverseOrder: true,
    fixed: 'right',
    header: () => t('attributes.created_at'),
  }),
  ...setupColumn({
    id: 'updatedAt',
    order: 'updated_at',
    cell: TimeAgoCell,
    reverseOrder: true,
    fixed: 'right',
    graphqlFields: 'updatedAt',
    header: () => t('attributes.updated_at'),
  }),
  ...setupColumn({
    id: 'removeStopWords',
    cell: SwitchCell,
    order: 'remove_stop_words',
    graphqlFields: 'removeStopWords',
    header: () => t('activerecord.attributes.keyword_setting.remove_stop_words'),
    editQuery: ({ id }) => removeStopWords =>
      gql`mutation { updateKeywordSetting(id: ${id}, removeStopWords: ${removeStopWords}) { keywordSetting { id removeStopWords } errors } }`,
  }),
  ...setupColumn({
    id: 'removeDuplicityWords',
    cell: SwitchCell,
    order: 'remove_duplicity_words',
    graphqlFields: 'removeDuplicityWords',
    header: () => t('activerecord.attributes.keyword_setting.remove_duplicity_words'),
    editQuery: ({ id }) => removeDuplicityWords =>
      gql`mutation { updateKeywordSetting(id: ${id}, removeDuplicityWords: ${removeDuplicityWords}) { keywordSetting { id removeDuplicityWords } errors } }`,
  }),
  ...setupColumn({
    id: 'transliterate',
    cell: SwitchCell,
    order: 'transliterate',
    graphqlFields: 'transliterate',
    header: () => t('activerecord.attributes.keyword_setting.transliterate'),
    editQuery: ({ id }) => transliterate =>
      gql`mutation { updateKeywordSetting(id: ${id}, transliterate: ${transliterate}) { keywordSetting { id transliterate } errors } }`,
  }),
  ...setupColumn({
    id: 'minWords',
    order: 'min_words',
    cell: IntegerCell,
    width: '120px',
    editable: IntegerEdit,
    graphqlFields: 'minWords',
    header: () => t('activerecord.attributes.keyword_setting.min_words'),
    editQuery: ({ id }) => minWords =>
      gql`
        mutation {
          updateKeywordSetting(id: ${id}, minWords: ${minWords}) { keywordSetting { id minWords } errors }
        }
      `,
  }),
  ...setupColumn({
    id: 'maxWords',
    order: 'max_words',
    cell: IntegerCell,
    width: '120px',
    editable: IntegerEdit,
    graphqlFields: 'maxWords',
    header: () => t('activerecord.attributes.keyword_setting.max_words'),
    editQuery: ({ id }) => maxWords =>
      gql`
        mutation {
          updateKeywordSetting(id: ${id}, maxWords: ${maxWords}) { keywordSetting { id maxWords } errors }
        }
      `,
  }),
  ...setupColumn({
    id: 'minLength',
    order: 'min_length',
    cell: IntegerCell,
    width: '120px',
    editable: IntegerEdit,
    graphqlFields: 'minLength',
    header: () => t('activerecord.attributes.keyword_setting.min_length'),
    editQuery: ({ id }) => minLength =>
      gql`
        mutation {
          updateKeywordSetting(id: ${id}, minLength: ${minLength}) { keywordSetting { id minLength } errors }
        }
      `,
  }),
  ...setupColumn({
    id: 'maxLength',
    order: 'max_length',
    cell: IntegerCell,
    width: '120px',
    editable: IntegerEdit,
    header: () => t('activerecord.attributes.keyword_setting.max_length'),
    editQuery: ({ id }) => maxLength =>
      gql`
        mutation {
          updateKeywordSetting(id: ${id}, maxLength: ${maxLength}) { keywordSetting { id maxLength } errors }
        }
      `,
  }),
};

export default mapping;
