import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Icon, Text } from '../index';
import { AdPreviewContainer, FbPagePreview } from './index';
import icons from '../../../../assets/images/fb_ad_preview/instagram_feed_icons.png';
import bookmark_icon from '../../../../assets/images/fb_ad_preview/bookmark.png';
import slider_dots from '../../../../assets/images/fb_ad_preview/slider_dots.png';

const InstagramFeedPreview = ({ media, pageImageUrl, pageName, label, primaryText, ctaText, type }) => (
  <AdPreviewContainer icon="instagram" title={t('fb_ad_preview.instagram', { default: 'Instagram' })}>
    <div className="InstagramFeedPreview-container">
      <div className="InstagramFeedPreview-header">
        <Row padding="s" center justifyBetween>
          <Col shrink>
            <FbPagePreview
              url={pageImageUrl}
              pageName={pageName}
              label={label}
              imageSize="15px"
              nameSize="6px"
              labelSize="6px"
              nameBold
            />
          </Col>
          <Col shrink>
            <Icon kind="more" style={{ color: '#000000' }} size="7px" />
          </Col>
        </Row>
      </div>
      <div className="InstagramFeedPreview-main">{media?.image_url && <img src={media.image_url} alt="" />}</div>
      <Row className="InstagramFeedPreview-cta" center justifyBetween>
        <Col shrink>
          <Text bold style={{ color: '#ffffff', fontSize: '6px' }}>
            {ctaText}
          </Text>
        </Col>
        <Col shrink>
          <Icon kind="chevron-right-round" size="12px" color="#ffffff" />
        </Col>
      </Row>
      <Row className="InstagramFeedPreview-icons" center justifyBetween>
        <Col shrink>
          <img src={icons} alt="" width="50" />
        </Col>
        <Col shrink>
          <img src={bookmark_icon} alt="" width="9" />
        </Col>
        {type === 'carousel' && <img src={slider_dots} alt="dots" className="InstagramFeedPreview-sliderDots" />}
      </Row>
      <div className="InstagramFeedPreview-footer">
        <Text bold style={{ display: 'block', color: '#000000', fontSize: '6px' }}>
          {t('fb_ad_preview.n_likes', { default: '728 Likes' })}
        </Text>
        <div style={{ display: 'flex', marginTop: '2px' }}>
          <Text bold style={{ color: '#000000', fontSize: '6px', marginRight: '2px' }}>
            {pageName}
          </Text>
          <Text style={{ color: '#000000', fontSize: '6px' }}>{primaryText}</Text>
        </div>
      </div>
    </div>
  </AdPreviewContainer>
);

InstagramFeedPreview.propTypes = {
  media: PropTypes.object,
  pageImageUrl: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  primaryText: PropTypes.string,
  ctaText: PropTypes.string,
  type: PropTypes.oneOf(['single', 'carousel']),
};

export default InstagramFeedPreview;
