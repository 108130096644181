import React from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { t } from '../../i18n';

const UsagePrice = ({ value, estimated, condition, children, format }) => {
  if (!value && !estimated && !condition) return <span>{t('feature_period.no_data')}</span>;

  if (condition) return children;
  return (
    <span>
      {format ? format(value) : value}
      {estimated && `${value ? '' : '0'} / ${format ? format(estimated) : estimated}`}
    </span>
  );
};

UsagePrice.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  estimated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  condition: PropTypes.bool,
  children: PropTypes.any,
  format: PropTypes.func,
};

export default hot(UsagePrice);
