/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class TourPhotos extends React.PureComponent {
  static propTypes = {
    url: PropTypes.string,
    order: PropTypes.string,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    desc: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    order: this.props.order || '',
    url: this.props.url || '',
    desc: this.props.desc || '',
  };

  handleOrder = ({ target: { value } }) => {
    if (value !== this.state.order) {
      this.setState({ order: value });
      this.props.updateFieldData(this.props.id, { order: value });
      updatePreview();
    }
  };

  handleUrl = ({ target: { value } }) => {
    if (value !== this.state.url) {
      this.setState({ url: value });
      this.props.updateFieldData(this.props.id, { url: value });
      updatePreview();
    }
  };

  handleDesc = ({ target: { value } }) => {
    if (value !== this.state.desc) {
      this.setState({ desc: value });
      this.props.updateFieldData(this.props.id, { desc: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { desc, url, order } = this.state;

    return (
      <Row center padding="l" data-test-id="tour_photos" className="mb-8">
        <Col width="10%" data-test-id="tour_photos-order" className="mr-8">
          <PlaceholderInput
            defaultValue={order}
            onChange={this.handleOrder}
            placeholder={t('views.tour_photos.fields.order_id', { default: 'Order' })}
            {...getSubFiledOptions('order')}
          />
        </Col>
        <Col grow data-test-id="tour_photos-url" style={{ maxWidth: '20%' }} className="mr-8">
          <PlaceholderInput
            defaultValue={url}
            onChange={this.handleUrl}
            placeholder={t('views.tour_photos.fields.url_title', { default: 'URL' })}
            {...getSubFiledOptions('url')}
          />
        </Col>
        <Col grow data-test-id="tour_photos-desc">
          <PlaceholderInput
            defaultValue={desc}
            onChange={this.handleDesc}
            placeholder={t('views.tour_photos.fields.desc', { default: 'Description' })}
            {...getSubFiledOptions('desc')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.tour_photos.example')} <span class="Text--bold">${t(
            'views.tour_photos.example_text'
          )}</span>`}
          data-field={`tour_photos.${order}`}
        >
          <div className="Text">
            {t('views.tour_photos.example')} <span className="Text--bold">{t('views.tour_photos.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default TourPhotos;
