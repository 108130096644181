import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row, Text, InfoTooltip, Link } from '../index';
import { formatNumberInflection } from '../../i18n';

export const ActionsLine = ({ tooltip, handleModal, pagesCount, collection, style }) => {
  const handleClick = () => handleModal({ data: collection, pagesCount });

  return (
    <Row center inline className="ml-8 pos-absolute" style={style}>
      <Col shrink>
        <InfoTooltip className="tooltip--smallHoverArea" tooltipText={tooltip} />
      </Col>
      <Col grow>
        <Button
          disabled={!collection || collection?.length === 0}
          tertiary
          size="small"
          onlyIcon
          icon="magnifier"
          onClick={handleClick}
        />
      </Col>
    </Row>
  );
};
ActionsLine.propTypes = {
  collection: PropTypes.array,
  handleModal: PropTypes.func,
  pagesCount: PropTypes.number,
  style: PropTypes.object,
  tooltip: PropTypes.string,
};

export const Label = ({ originalLabel, count, color }) => {
  const label = `${originalLabel} (${formatNumberInflection({ count, scope: 'react.scraper.pages' })})`;

  return (
    <Row shrink center>
      {color && (
        <Col shrink>
          <span
            className="d-inline-block border-radius-50"
            style={{ background: color, width: '16px', height: '16px' }}
          />
        </Col>
      )}
      <Col grow>
        <Text size="sm">{count ? label : originalLabel}</Text>
      </Col>
    </Row>
  );
};

Label.propTypes = {
  color: PropTypes.string,
  count: PropTypes.number,
  originalLabel: PropTypes.string,
};

export const PagesCount = ({ totalCount, collection, handleModal }) => {
  if (isNaN(totalCount)) return null;
  const handleClick = () => handleModal({ data: collection, pagesCount: totalCount });

  return (
    <Link disabled={totalCount === 0} onClick={handleClick} icon="sitelink">
      {formatNumberInflection({ count: totalCount, scope: 'react.scraper.pages' })}
    </Link>
  );
};

PagesCount.propTypes = {
  collection: PropTypes.array,
  handleModal: PropTypes.func,
  totalCount: PropTypes.number,
};
