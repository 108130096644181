import React, { useState } from 'react';
import { pick, uniq, flattenDeep, pickBy } from 'lodash';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { SegmentedButton, Row, Col, Input, Button, Tooltip } from '../components';
import { t } from '../i18n';
import updatePreview from './updatePreview';
import useInputState from '../components/useInputState';
import ConditionalWrapper from '../components/ConditionalWrapper';

const EMPTY_VALS = { method: 'dig', take: '', key: '', value: '' };

const ColInput = ({ rowData, index, setValue, valueKey, suggestions }) => (
  <Col>
    <Input
      suggestions={suggestions}
      enableAutosuggest
      autosuggestFilterCallback={inputValue =>
        suggestions.filter(item => !inputValue || item.toLowerCase().includes(inputValue.toLowerCase()))
      }
      value={rowData[valueKey]}
      name="extractor_variable[method_input]"
      disabled={rowData?.isLast}
      id="extractor-variable[method-input]"
      onFocus={x => (x?.suggestions?.length > 0 && x?.selected === '' ? x?.openMenu : null)}
      doNotUseInternalState
      label={t(valueKey, { scope: 'react.extractor_variable.labels' })}
      onChange={({ target: { value: val } }) => setValue(index, { [valueKey]: val })}
    />
  </Col>
);

ColInput.propTypes = {
  index: PropTypes.number,
  rowData: PropTypes.object,
  setValue: PropTypes.func,
  suggestions: PropTypes.array,
  valueKey: PropTypes.string,
};

const InputsComponent = props => {
  if (props.rowData.method === 'dig') {
    return (
      <Row center>
        <ColInput {...props} suggestions={props.rowData.suggestions} valueKey={'take'} />
        <Col />
      </Row>
    );
  }
  return (
    <Row center>
      <ColInput {...props} suggestions={props.rowData.keySuggestions} valueKey={'key'} />
      <ColInput {...props} suggestions={props.rowData.valueSuggestions} valueKey={'value'} />
    </Row>
  );
};
InputsComponent.propTypes = { rowData: PropTypes.object };

const MyLine = ({ onRemove, isLast, ...rest }) => (
  <Row className="mb-8" center>
    <Col shrink>
      <label htmlFor={`extractor_variable[select_type]-${rest.index}`}>
        {t('choose_method', { scope: 'react.extractor_variable' })}
      </label>
      <SegmentedButton
        name={`extractor_variable[select_type]-${rest.index}`}
        id={`extractor-variable[select-type]-${rest.index}`}
        value={rest.rowData.method}
        doNotUseInternalState
        onChange={e => rest.setValue(rest.index, { method: e.target.value })}
        collection={[
          { value: 'dig', label: t('dig', { scope: 'react.extractor_variable' }) },
          { value: 'filter', label: t('filter', { scope: 'react.extractor_variable' }) },
        ]}
      />
    </Col>
    <InputsComponent {...rest} />
    <Col shrink className="pl-8 pt-16">
      <Button tertiary onlyIcon onClick={onRemove} tabIndex="-1" icon="trash" data-test-id="nested-field-remove" />
    </Col>
  </Row>
);
MyLine.propTypes = {
  rowData: PropTypes.object,
  setValue: PropTypes.func,
  onRemove: PropTypes.func,
  index: PropTypes.number,
  isLast: PropTypes.bool,
};

const clearResult = obj => {
  if (obj.method === 'dig') return pick(obj, ['method', 'take']);
  return pick(obj, ['method', 'key', 'value']);
};

const digMe = (data, res) => {
  let dataArr = data;
  if (!Array.isArray(data)) dataArr = [data];

  return {
    ...res,
    suggestions:
      typeof dataArr[0] === 'object'
        ? uniq(flattenDeep(dataArr.map(d => Object.keys(d)))).reduce((acc, cur) => [...acc, { value: cur }], [])
        : [],
    methodResult: !res.take || res.take === '' ? [] : flattenDeep(dataArr.map(d => Object.values(pick(d, res.take)))),
    isLast: typeof dataArr[0] !== 'object',
  };
};

const isValidJSON = str =>
  typeof str === 'string' && (str.startsWith('[') || str.startsWith('{')) && (str.endsWith('}') || str.endsWith(']'));

const filterMe = (data, res) => {
  let dataArr = data;
  if (!Array.isArray(data)) dataArr = [data];

  const hasKey = res.key && res.key !== '';
  const hasValue = res.value && res.value !== '';

  const hasKeyAndVal = hasKey && hasValue;

  return {
    ...res,
    keySuggestions:
      typeof dataArr[0] === 'object'
        ? uniq(
            flattenDeep(
              dataArr.map(d => {
                let filtredObj = d;
                if (hasValue) filtredObj = pickBy(d, val => val === res.value);
                return Object.keys(filtredObj);
              })
            )
          ).reduce((acc, cur) => [...acc, { value: cur }], [])
        : [],
    valueSuggestions:
      typeof dataArr[0] === 'object'
        ? uniq(
            flattenDeep(
              dataArr.map(d => {
                let filtredObj = d;
                if (hasKey) filtredObj = pick(d, [res?.key]);
                return Object.values(filtredObj);
              })
            )
          ).reduce((acc, cur) => [...acc, { value: cur }], [])
        : [],
    isLast: typeof dataArr[0] !== 'object',
    methodResult: !hasKeyAndVal
      ? []
      : dataArr.filter(d => {
          if (d[res.key] === res.value) return d;
          return false;
        }),
  };
};

export const getSuggestions = ({ inputState, result }) => {
  const isValidJson = isValidJSON(inputState);

  if (!isValidJson) return null;

  const data = JSON.parse(inputState);

  return result.reduce((acc, cur, index) => {
    const evaluation = stepData =>
      cur.method === 'dig' ? [...acc, digMe(stepData, cur)] : [...acc, filterMe(stepData, cur)];

    return index === 0 ? evaluation(data) : evaluation(acc[index - 1].methodResult);
  }, []);
};

const ExtractorVariable = ({ options }) => {
  const { inputState } = useExtract();
  const { extractor_options_json = [] } = options;
  const parsedOptions = extractor_options_json.map(x => ({ ...EMPTY_VALS, ...JSON.parse(x) }));
  const initialOptions = parsedOptions?.length > 0 ? parsedOptions : [EMPTY_VALS];
  const [result, setResult] = useState(initialOptions);

  const enhancedResults = getSuggestions({ inputState, result });

  React.useEffect(() => {
    updatePreview();
  }, []);

  const handleResult = (i, value) => {
    const newItem = { ...enhancedResults[i], ...value };
    const newRes = [...enhancedResults];
    newRes[i] = newItem;
    setResult(newRes);
    updatePreview();
  };

  const removeRow = i => {
    const newResults = [...enhancedResults];
    newResults.splice(i, 1);
    setResult(newResults);
    updatePreview();
  };

  const isParsable = isValidJSON(inputState);

  if (!isParsable) {
    return (
      <Row center>
        <Col center>{t('not_valid_json', { scope: 'react.extractor_variable' })}</Col>
      </Row>
    );
  }

  const cantbeNestedAnymore = [...enhancedResults].reverse()?.[0]?.isLast;

  return (
    <div>
      {enhancedResults.map((row, i) => (
        <React.Fragment key={[row.method, i].join('-')}>
          <MyLine
            setValue={handleResult}
            index={i}
            rowData={row}
            isLast={enhancedResults.length - 1 === i}
            onRemove={() => removeRow(i)}
          />
          <input type="hidden" name="modifier[extractor_options_json][]" value={JSON.stringify(clearResult(row))} />
        </React.Fragment>
      ))}
      <ConditionalWrapper
        condition={cantbeNestedAnymore}
        wrapper={child => <Tooltip text={t('react.extractor_variable.cant_nested_anymore')}>{child}</Tooltip>}
      >
        <Button
          secondary
          type="button"
          disabled={cantbeNestedAnymore}
          onClick={() => setResult([...enhancedResults, EMPTY_VALS])}
        >
          {t('add_new_row', { scope: 'react.extractor_variable' })}
        </Button>
      </ConditionalWrapper>
    </div>
  );
};

ExtractorVariable.propTypes = { options: PropTypes.object };

const ExtractorContext = React.createContext();

// eslint-disable-next-line react/prop-types
ExtractorVariable.WrapperRenderer = ({ children }) => {
  const { state } = useInputState({ name: 'modifier_preview_input_value', defaultValue: '[]' });
  return <ExtractorContext.Provider value={{ inputState: state }}>{children}</ExtractorContext.Provider>;
};

export const useExtract = () => React.useContext(ExtractorContext);

export default hot(ExtractorVariable);
