/* eslint-disable react/sort-comp */
/* eslint-disable array-callback-return */

import React, { Component } from 'react';
import _, { debounce } from 'lodash';
import { GlobalHotKeys } from 'react-hotkeys';
import generateUuid from 'uuid';

import storage from '../../../utils/storage';
import InsertMenu from './panels/InsertMenu';
import SVGRenderer from './SVGRenderer';
import Handler from './Handler';
import SelectionHandler from './SelectionHandler';
import Rulers from './Rulers';
import { modes, RESOLUTIONS, CUSTOM_RESOLUTION } from './constants';
import * as actions from './actions';
import objectTypes from './objectTypes';
import PanelList from './panels/PanelList';
import PreviewPanel from './PreviewPanel';
import { Input, Button, Col, Row, Select, ColorPicker, InfoBox } from '../../index';
import { t, tHtml } from '../../../i18n';
import { buildUrl } from './panels/ImagePlaceholderPanel';

const arrayMove = (array, from, to) => {
  const startIndex = to < 0 ? array.length + to : to;
  const item = array.splice(from, 1)[0];
  array.splice(startIndex, 0, item);
};

const getNameForObject = object => {
  const ObjectType = objectTypes[object.type];
  return (
    object['data-layer-name'] ||
    object['dataLayerName'] ||
    ObjectType.meta.description ||
    ObjectType.getDescription(object)
  );
};

export const handleImageFitToCanvas = (canvasWidth, canvasHeight, imageWidth, imageHeight) => {
  const widthMultiplier = imageHeight / imageWidth;
  const heightMultiplier = imageWidth / imageHeight;

  let resizedImageWidth = imageWidth;
  let resizedImageHeight = imageHeight;

  if (resizedImageWidth > canvasWidth) {
    resizedImageWidth = canvasWidth;
    resizedImageHeight = canvasWidth * widthMultiplier;
    return handleImageFitToCanvas(canvasWidth, canvasHeight, resizedImageWidth, resizedImageHeight);
  }
  if (resizedImageHeight > canvasHeight) {
    resizedImageHeight = canvasHeight;
    resizedImageWidth = canvasHeight * heightMultiplier;
    return handleImageFitToCanvas(canvasWidth, canvasHeight, resizedImageWidth, resizedImageHeight);
  }
  return { resizedImageWidth, resizedImageHeight };
};

class Designer extends Component {
  static defaultProps = {
    snapToGrid: 1,
    svgStyle: {},
    insertMenu: InsertMenu,
  };

  state = {
    mode: modes.FREE,
    handler: {
      top: 200,
      left: 200,
      width: 50,
      height: 50,
      rotate: 0,
    },
    selectionHandler: null,
    holdAspectRatio: false,
    currentObjectIndex: null,
    selectedObjectIndex: null,
    selectedObjectsIndexes: null,
    selectedTool: null,
    showHandler: false,
    multiSelect: false,
  };

  keyMap = {
    removeObject: ['del', 'backspace'],
    duplicateObject: ['command+d', 'ctrl+d'],
    copy: ['command+c', 'ctrl+c'],
    selectAll: ['command+a', 'ctrl+a'],
    paste: ['command+v', 'ctrl+v'],
    undo: ['command+z', 'ctrl+z'],
    redo: ['command+y', 'ctrl+y'],
    moveLeft: ['left', 'shift+left'],
    moveRight: ['right', 'shift+right'],
    moveUp: ['up', 'shift+up'],
    moveDown: ['down', 'shift+down'],
    closePath: ['enter'],
    multiSelectStart: { sequence: 'command', action: 'keydown' },
    multiSelectStartCtrl: { sequence: 'ctrl', action: 'keydown' },
    multiSelectEnd: { sequence: 'command', action: 'keyup' },
    multiSelectEndCtrl: { sequence: 'ctrl', action: 'keyup' },
  };

  componentWillMount() {
    this.objectRefs = {};
    window.addEventListener('keydown', this.handleShiftKey('keydown'));
    window.addEventListener('keyup', this.handleShiftKey('keyup'));
    window.addEventListener('blur', this.windwowGotBlur);
  }

  componentDidMount() {
    this.createLayersFromProps();
  }
  createLayersFromProps = () => {
    const { createLayers } = this.props;
    if (createLayers && createLayers.length > 0) {
      createLayers.map(placeholder => {
        const tool = 'imgPlaceholder';
        const { meta } = objectTypes[tool];
        const object = {
          x: 100,
          y: 100,
          rotate: 0,
          ...meta.initial,
          width: 880,
          height: 880,
          type: tool,
          uuid: generateUuid(),
          xlinkHref: buildUrl({ placeholder }),
        };
        this.createNewObject(object, {
          startPoint: {
            clientX: 10,
            clientY: 10,
            objectX: 10,
            objectY: 10,
            width: 50,
            height: 50,
            rotate: 0,
          },
          mode: modes.FREE,
          selectedTool: null,
        });
      });
    }
  };

  selectMultipleObjects = selectedObjectsIndexes => {
    this.setState({
      selectedObjectsIndexes,
      currentObjectIndex: null,
      selectedObjectIndex: null,
      showHandler: true,
    });

    if (selectedObjectsIndexes.length > 0) {
      this.updateHandler(null, null, selectedObjectsIndexes);
    } else {
      this.hideHandler();
    }
  };

  showHandler = (index, selectOptions = { forceSingleSelect: false }) => {
    const { mode, showHandler, multiSelect } = this.state;
    const { objects } = this.props;
    const object = objects[index];

    if (showHandler && mode !== modes.FREE && this.props.newProductSetsEmpty) {
      return;
    }

    if (multiSelect && !selectOptions.forceSingleSelect) {
      const oldSelection =
        typeof this.state.selectedObjectIndex === 'number'
          ? [this.state.selectedObjectIndex]
          : this.state.selectedObjectsIndexes || [];

      const newSelection =
        oldSelection.indexOf(index) === -1 ? oldSelection.concat([index]) : oldSelection.filter(x => x !== index);
      this.setState({
        currentObjectIndex: null,
        selectedObjectIndex: null,
        selectedObjectsIndexes: newSelection,
        showHandler: true,
      });
      if (newSelection.length > 0) {
        this.updateHandler(null, null, newSelection);
      } else {
        this.hideHandler();
      }
    } else {
      this.updateHandler(index, object);
      this.setState({
        currentObjectIndex: index,
        selectedObjectIndex: index,
        selectedObjectsIndexes: null,
        showHandler: true,
      });
    }
  };

  hideHandler() {
    const { mode } = this.state;
    if (mode === modes.FREE) {
      this.setState({
        showHandler: false,
      });
    }
  }

  getStartPointBundle(event, object) {
    const { currentObjectIndex, selectedObjectsIndexes } = this.state;
    const { objects } = this.props;

    const mouse = this.getMouseCoords(event);

    if (selectedObjectsIndexes) {
      return selectedObjectsIndexes.map(ind => {
        const obj = objects[ind];
        return {
          index: ind,
          clientX: mouse.x,
          clientY: mouse.y,
          objectX: obj.x,
          objectY: obj.y,
          width: obj.width,
          height: obj.height,
          fontSize: obj.fontSize,
          rotate: 0,
        };
      });
    }
    const obj = object || objects[currentObjectIndex];

    return {
      clientX: mouse.x,
      clientY: mouse.y,
      objectX: obj?.x,
      objectY: obj?.y,
      width: obj.width,
      height: obj?.height,
      rotate: obj?.rotate,
    };
  }

  startDrag(mode, event) {
    const { currentObjectIndex, selectedObjectsIndexes } = this.state;
    if (selectedObjectsIndexes && selectedObjectsIndexes.length > 0) {
      this.setState({
        mode,
        startPoint: this.getStartPointBundle(event),
      });
    } else {
      this.setState({
        mode,
        startPoint: this.getStartPointBundle(event),
        selectedObjectIndex: currentObjectIndex,
      });
    }
  }

  resetSelection() {
    this.setState({
      currentObjectIndex: null,
      selectedObjectIndex: null,
      selectedObjectsIndexes: null,
      showHandler: false,
    });
  }

  resetSelectionOnContainer = e => {
    const mouse = this.getMouseCoords(e);
    if (e.target.tagName === 'svg' || e.target.classList.contains('start-selection-container')) {
      this.resetSelection();
      this.setState({
        selectionHandler: {
          startY: mouse.y,
          startX: mouse.x,
          top: mouse.y,
          left: mouse.x,
          width: 3,
          height: 3,
        },
      });
    } else if (
      !e.target.closest('svg') &&
      !e.target.classList.contains('handler') &&
      !e.target.classList.contains('resize-anchor') &&
      !e.target.classList.contains('rotate-anchor') &&
      !e.target.classList.contains('resize-anchor-wrapper') &&
      !e.target.classList.contains('rotate-anchor-wrapper')
    ) {
      this.resetSelection();
    }
  };

  setDefaultName(selectedTool) {
    const { objects } = this.props;

    const objectNames =
      objects
        .filter(object => object.type === selectedTool)
        .map(object => object['data-layer-name'] || object['dataLayerName'])
        .filter(name => !!name)
        .map(name => name.match(/#(\d+)$/))
        .filter(name => !!name)
        .map(name => parseInt(name[1], 10))
        .sort((a, b) => b - a)[0] || 0;

    return t(selectedTool, { scope: 'react.image_generator.default_name', index: objectNames + 1 });
  }

  newObject(event) {
    const { scale } = this.props;
    const { mode, selectedTool } = this.state;

    if (mode !== modes.DRAW) {
      return;
    }

    this.resetSelection(event);

    const { meta } = objectTypes[selectedTool];
    const mouse = this.getMouseCoords(event);

    const object = {
      rotate: 0,
      ...meta.initial,
      type: selectedTool,
      x: mouse.x / scale,
      y: mouse.y / scale,
      uuid: generateUuid(),
      'data-layer-name': this.setDefaultName(selectedTool),
    };

    this.createNewObject(object, {
      startPoint: this.getStartPointBundle(event, object),
      mode: meta.editor ? modes.EDIT_OBJECT : modes.SCALE,
      selectedTool: null,
    });
  }

  createNewObject = (object, additionalState = {}) => {
    const { objects, onUpdate } = this.props;
    onUpdate([...objects, object], true);

    const index = objects.length;
    this.setState({
      currentObjectIndex: index,
      selectedObjectIndex: index,
      selectedObjectsIndexes: null,
      ...additionalState,
    });

    setTimeout(() => {
      if (!this.state.showHandler) {
        this.showHandler(index);
      } else {
        this.updateHandler(index, object);
      }
    }, 100);
  };

  updatePath = object => {
    const { path } = object;
    const diffX = object.x - object.moveX;
    const diffY = object.y - object.moveY;

    const newPath = path.map(({ x1, y1, x2, y2, x, y }) => ({
      x1: diffX + x1,
      y1: diffY + y1,
      x2: diffX + x2,
      y2: diffY + y2,
      x: diffX + x,
      y: diffY + y,
    }));

    return {
      ...object,
      path: newPath,
      moveX: object.x,
      moveY: object.y,
    };
  };

  updateObjects = newObjectsWithIndexes => {
    const { objects, onUpdate } = this.props;

    onUpdate(
      objects.map((object, index) => {
        const changes = newObjectsWithIndexes.find(el => el.index === index)?.object;
        if (changes) {
          const newObject = {
            ...object,
            ...changes,
          };

          return newObject;
        }
        return object;
      })
    );
  };

  updateObject = (objectIndex, changes, updatePath) => {
    const { objects, onUpdate } = this.props;
    setTimeout(
      () =>
        this.updateHandlerDebounced(objectIndex, {
          ...objects[objectIndex],
          ...changes,
        }),
      10
    );

    onUpdate(
      objects.map((object, index) => {
        if (index === objectIndex) {
          const newObject = {
            ...object,
            ...changes,
          };

          return updatePath ? this.updatePath(newObject) : newObject;
        }
        return object;
      })
    );
  };

  getOffset() {
    const parent = this.svgElement.getBoundingClientRect();
    const { canvasWidth, canvasHeight } = this.getCanvas();
    return {
      x: parent.left,
      y: parent.top,
      width: canvasWidth,
      height: canvasHeight,
    };
  }

  applyOffset(bundle) {
    const offset = this.getOffset();
    return {
      ...bundle,
      x: bundle.x - offset.x,
      y: bundle.y - offset.y,
    };
  }

  updateHandler(index, object, indexes) {
    const { canvasOffsetX, canvasOffsetY } = this.getCanvas();

    if (indexes && indexes.length > 0) {
      const { scale, objects } = this.props;
      const xxxx = indexes.map(i => {
        const obj = objects[i];
        const target = this.objectRefs[i];
        const bbox = target.getBoundingClientRect();
        const offset = this.getOffset();

        return {
          ...this.state.handler,
          width: Math.max(obj.width, bbox.width / scale),
          height: Math.max(obj.height, bbox.height / scale),
          top: Math.min(obj.y, (bbox.y - offset.y) / scale),
          left: Math.min(obj.x, (bbox.x - offset.x) / scale),
          rotate: 0,
        };
      });

      const left = Math.min(...xxxx.map(h => h.left));
      const top = Math.min(...xxxx.map(h => h.top));

      this.setState({
        handler: {
          ...this.state.handler,
          height: Math.max(...xxxx.map(h => h.top + h.height)) - top,
          rotate: 0,
          left,
          top,
          width: Math.max(...xxxx.map(h => h.left + h.width)) - left,
        },
      });
    } else {
      const target = this.objectRefs[index];
      const bbox = target.getBoundingClientRect();

      let handler = {
        ...this.state.handler,
        width: object.width || bbox.width,
        height: object.height || bbox.height,
        top: object.y + canvasOffsetY,
        left: object.x + canvasOffsetX,
        rotate: object.rotate,
      };

      if (!object.width) {
        const offset = this.getOffset();
        handler = {
          ...handler,
          left: bbox.left - offset.x,
          top: bbox.top - offset.y,
        };
      }
      this.setState({
        handler,
      });
    }
  }

  updateHandlerDebounced = debounce(this.updateHandler, 10);

  windwowGotBlur = () => {
    this.setState({ holdAspectRatio: false, multiSelect: false });
  };

  handleShiftKey = direction => event => {
    if (event.key === 'Shift') {
      if (direction === 'keydown' && !this.state.holdAspectRatio) {
        this.setState({ holdAspectRatio: true });
      }
      if (direction === 'keyup' && this.state.holdAspectRatio) {
        this.setState({ holdAspectRatio: false });
      }
    }
  };

  snapCoordinates({ x, y }) {
    const { snapToGrid } = this.props;
    return {
      x: x - (x % snapToGrid),
      y: y - (y % snapToGrid),
    };
  }

  getMouseCoords({ clientX, clientY }) {
    const coords = this.applyOffset({
      x: clientX,
      y: clientY,
    });

    return this.snapCoordinates(coords);
  }

  onDrag(event) {
    const {
      currentObjectIndex,
      selectedObjectsIndexes,
      startPoint,
      mode,
      holdAspectRatio,
      selectionHandler,
      handler,
    } = this.state;
    const { objects, width, height, snapToGuidelines } = this.props;
    const { scale, scaleMultiple, rotate, drag } = actions;

    const mouse = this.getMouseCoords(event);

    const indexes = typeof currentObjectIndex === 'number' ? [currentObjectIndex] : selectedObjectsIndexes || [];
    const notSelectedObjects = objects.filter((o, i) => indexes.indexOf(i) === -1);

    const dragOptions = {
      handler,
      snappingObjects: snapToGuidelines ? [{ x: 0, y: 0, width, height }].concat(notSelectedObjects) : [],
    };

    if (typeof currentObjectIndex === 'number') {
      const object = objects[currentObjectIndex];

      const map = {
        [modes.SCALE]: scale,
        [modes.ROTATE]: rotate,
        [modes.DRAG]: drag,
      };

      const action = map[mode];

      if (action) {
        const newObject = action({
          object,
          startPoint,
          mouse,
          objectIndex: currentObjectIndex,
          objectRefs: this.objectRefs,
          holdAspectRatio,
          scale: this.props.scale,
          dragOptions,
        });

        if (!isNaN(newObject.x)) {
          this.updateObject(currentObjectIndex, newObject);
          if (!this.state.showHandler) {
            this.showHandler(currentObjectIndex);
          } else {
            this.updateHandler(currentObjectIndex, newObject);
          }
        }
      }
    } else if (selectedObjectsIndexes && selectedObjectsIndexes.length > 0) {
      const currObjects = selectedObjectsIndexes.map(ind => ({ obj: objects[ind], index: ind }));

      if (mode === modes.SCALE) {
        const newObjects = currObjects
          // eslint-disable-next-line consistent-return
          .map(({ obj, index }) => {
            const newObject = scaleMultiple({
              object: obj,
              startPoint: startPoint.find(sp => sp.index === index),
              mouse,
              objectIndex: index,
              objectRefs: this.objectRefs,
              holdAspectRatio,
              scale: this.props.scale,
              startPoints: startPoint,
              dragOptions,
            });

            if (!isNaN(newObject.x)) {
              return { index, object: newObject };
            }
          })
          .filter(x => !!x);

        this.updateObjects(newObjects);

        if (!this.state.showHandler) {
          this.setState({ showHandler: true });
        } else {
          this.updateHandler(null, null, selectedObjectsIndexes);
        }
      } else if (mode === modes.DRAG) {
        const actionData = drag({
          object: {},
          startPoint: startPoint[0],
          mouse,
          objectIndex: 0,
          objectRefs: this.objectRefs,
          holdAspectRatio,
          scale: this.props.scale,
          startPoints: startPoint,
          dragOptions,
        });

        const updateDiff = {
          x: actionData.x - startPoint[0].objectX,
          y: actionData.y - startPoint[0].objectY,
        };

        const newObjects = currObjects
          .map(({ obj, index }) => {
            const sp = startPoint.find(s => s.index === index);
            return { index, object: { ...obj, x: sp.objectX + updateDiff.x, y: sp.objectY + updateDiff.y } };
          })
          .filter(x => !!x);

        this.updateObjects(newObjects);

        if (!this.state.showHandler) {
          this.setState({ showHandler: true });
        } else {
          this.updateHandler(null, null, selectedObjectsIndexes);
        }
      }
    } else if (selectionHandler) {
      const mouseCoords = this.getMouseCoords(event);

      const newHandlerState = { ...selectionHandler };
      if (selectionHandler.startX > mouseCoords.x) {
        newHandlerState.left = mouseCoords.x / this.props.scale;
        newHandlerState.width = (selectionHandler.startX - mouseCoords.x) / this.props.scale;
      } else {
        newHandlerState.left = selectionHandler.startX / this.props.scale;
        newHandlerState.width = (mouseCoords.x - selectionHandler.startX) / this.props.scale;
      }

      if (selectionHandler.startY > mouseCoords.y) {
        newHandlerState.top = mouseCoords.y / this.props.scale;
        newHandlerState.height = (selectionHandler.startY - mouseCoords.y) / this.props.scale;
      } else {
        newHandlerState.top = selectionHandler.startY / this.props.scale;
        newHandlerState.height = (mouseCoords.y - selectionHandler.startY) / this.props.scale;
      }

      this.setState({ selectionHandler: newHandlerState });
    }

    // if (currentObjectIndex !== null) {
    //   this.detectOverlappedObjects(event);
    // }
  }

  detectOverlappedObjects(event) {
    const { currentObjectIndex } = this.state;
    const mouse = this.getMouseCoords(event);

    const refs = this.objectRefs;
    const keys = Object.keys(refs);
    const offset = this.getOffset();

    const currentRect = refs[currentObjectIndex].getBoundingClientRect();

    keys
      .filter((object, index) => index !== currentObjectIndex && !object.lockLayer && !object.hideLayer)
      .forEach(key => {
        const rect = refs[key].getBoundingClientRect();
        const { left, top, width, height } = rect;

        const newLeft = left - offset.x;
        const newLop = top - offset.y;

        const isOverlapped =
          mouse.x > newLeft &&
          mouse.x < newLeft + width &&
          mouse.y > newLop &&
          mouse.y < newLop + height &&
          currentRect.width > width &&
          currentRect.height > height;

        if (isOverlapped) {
          this.showHandler(Number(key));
        }
      });
  }

  stopDrag() {
    const { scale } = this.props;
    const { mode, selectionHandler } = this.state;

    if (selectionHandler) {
      const { objects } = this.props;
      const refs = this.objectRefs;

      const selLeft = selectionHandler.left;
      const selTop = selectionHandler.top;
      const selBottom = selectionHandler.top + selectionHandler.height;
      const selRight = selectionHandler.left + selectionHandler.width;
      const selectedIndexes = Object.keys(refs).filter(index => {
        if (objects[index].lockLayer || objects[index].hideLayer) {
          return false;
        }
        const { left, top, width, height } = refs[index].getBoundingClientRect();
        const { x, y } = this.applyOffset({ x: left, y: top });
        const leftX = x / scale;
        const topX = y / scale;
        const right = x + width / scale;
        const bottom = y + height / scale;

        // console.log(leftX, topX, right, bottom, 'x', selLeft, selTop, selRight,  selBottom, (selRight > right && selLeft < leftX && selTop < topX && selBottom > bottom))
        return selRight > right && selLeft < leftX && selTop < topX && selBottom > bottom;
      });

      // reset selection handler
      if (selectedIndexes.length > 0) {
        this.setState({
          selectionHandler: null,
          selectedObjectsIndexes: selectedIndexes.map(i => parseInt(i, 10)),
          showHandler: true,
        });

        this.updateHandler(null, null, selectedIndexes);
        this.props.setPanel('layers');
      } else {
        this.setState({ selectionHandler: null, selectedObjectsIndexes: null, showHandler: false });
      }
      return;
    }
    if (_.includes([modes.DRAG, modes.ROTATE, modes.SCALE], mode)) {
      this.setState({
        mode: modes.FREE,
      });
    }

    setTimeout(() => this.props.historyCheckpoint(), 200);
  }

  showEditor() {
    const { selectedObjectIndex } = this.state;

    const { objects } = this.props;
    const currentObject = objects[selectedObjectIndex];
    const objectComponent = objectTypes[currentObject.type];

    if (objectComponent.meta.editor) {
      this.setState({
        mode: modes.EDIT_OBJECT,
        showHandler: false,
      });
    }
  }

  showPropertyPanel() {
    this.props.setPanel('property');
  }

  getCanvas() {
    const { width, height } = this.props;
    const { canvasWidth = width, canvasHeight = height } = this.props;
    return {
      width,
      height,
      canvasWidth,
      canvasHeight,
      canvasOffsetX: (canvasWidth - width) / 2,
      canvasOffsetY: (canvasHeight - height) / 2,
    };
  }

  renderSVG() {
    const canvas = this.getCanvas();
    const { width, height } = canvas;
    const { background, objects, previewObjects, preview, scale } = this.props;

    return (
      <SVGRenderer
        className="elevate-3 ImageGenerator--workplace"
        background={background}
        width={width}
        scale={scale}
        canvas={canvas}
        height={height}
        objects={objects}
        preview={preview}
        previewObjects={previewObjects}
        onMouseOver={this.showHandler}
        objectRefs={this.objectRefs}
        onRender={ref => {
          this.svgElement = ref;
        }}
        onMouseDown={this.newObject.bind(this)}
      />
    );
  }

  selectTool = (tool, additionalProps = {}) => {
    const canvas = this.getCanvas();
    const { width, height } = canvas;
    const { meta } = objectTypes[tool];
    const layerName = {};
    if (tool === 'rect' || tool === 'circle' || tool === 'text') {
      layerName['data-layer-name'] = this.setDefaultName(tool);
    }

    let imageWidth = additionalProps?.width;
    let imageHeight = additionalProps?.height;

    if (tool === 'img' && imageWidth && imageHeight) {
      const { resizedImageWidth, resizedImageHeight } = handleImageFitToCanvas(width, height, imageWidth, imageHeight);
      imageWidth = resizedImageWidth;
      imageHeight = resizedImageHeight;
    }

    const object = {
      x: 10,
      y: 10,
      rotate: 0,
      ...meta.initial,
      type: tool,
      uuid: generateUuid(),
      ...layerName,
      ...additionalProps,
      width: imageWidth || meta.initial.width,
      height: imageHeight || meta.initial.height,
    };

    object.x = width / 2 - (object.width || 100) / 2;
    object.y = height / 2 - (object.height || 100) / 2;

    this.createNewObject(object, {
      startPoint: {
        clientX: 10,
        clientY: 10,
        objectX: 10,
        objectY: 10,
        width: 50,
        height: 50,
        rotate: 0,
      },
      mode: modes.EDIT_OBJECT,
      selectedTool: null,
    });
    this.props.setPanel('property');
  };

  insertNewLayers = (layers, template) => {
    const { objects, onUpdate, updateGeneratorState } = this.props;

    if (objects.length === 0 && template?.imageType === 'template') {
      updateGeneratorState({ width: template.width, height: template.height });
    }

    onUpdate(
      [
        ...objects,
        ...layers.map(layer => ({
          ...layer,
          uuid: generateUuid(),
        })),
      ],
      true
    );

    // Do not select all layers from template on insert - select only when inserting snippet
    if (template?.imageType !== 'template') {
      setTimeout(() => {
        this.selectMultipleObjects(layers.map((l, index) => index + objects.length));
      }, 200);
    }
    this.props.setPanel('layers');
  };

  handleObjectChange(key, value, additionalData = {}) {
    const { selectedObjectIndex } = this.state;

    const keyValue = key ? { [key]: value } : {};
    this.updateObject(selectedObjectIndex, {
      ...additionalData,
      ...keyValue,
    });
    setTimeout(() => this.props.historyCheckpoint(), 200);
  }

  handleArrange = arrange => {
    const { selectedObjectIndex } = this.state;
    const { objects } = this.props;
    const object = objects[selectedObjectIndex];

    const arrangers = {
      front: (rest, obj) => [[...rest, obj], rest.length],
      up: () => {
        if (selectedObjectIndex === objects.length - 1) {
          return [objects, objects.length - 1];
        }
        const newArray = [...objects];
        arrayMove(newArray, selectedObjectIndex, selectedObjectIndex + 1);
        return [newArray, selectedObjectIndex + 1];
      },
      down: () => {
        if (selectedObjectIndex === 0) {
          return [objects, 0];
        }
        const newArray = [...objects];
        arrayMove(newArray, selectedObjectIndex, selectedObjectIndex - 1);
        return [newArray, selectedObjectIndex - 1];
      },
      back: (rest, obj) => [[obj, ...rest], 0],
    };

    const rest = objects.filter(obj => object !== obj);

    this.setState(
      {
        selectedObjectIndex: null,
      },
      () => {
        const arranger = arrangers[arrange];
        const [arranged, newIndex] = arranger(rest, object);
        this.props.onUpdate(arranged);
        this.setState({
          selectedObjectIndex: newIndex,
        });
      }
    );
  };

  removeCurrent = () => {
    const indexes =
      typeof this.state.selectedObjectIndex === 'number'
        ? [this.state.selectedObjectIndex]
        : this.state.selectedObjectsIndexes || [];

    if (indexes.length === 0) {
      new window.NotificationCenter().show_error('Nothing selected to be deleted');
    } else {
      this.removeObjectAtIndex(indexes);
    }
  };

  removeObjectAtIndex = index => {
    const { objects } = this.props;
    let newObjects = objects;
    if (Array.isArray(index)) {
      const objectsToRemove = index.map(ind => objects[ind]);
      newObjects = objects.filter(obj => objectsToRemove.indexOf(obj) === -1);
    } else {
      const object = objects[index];
      newObjects = objects.filter(obj => object !== obj);
    }

    this.setState(
      {
        currentObjectIndex: null,
        selectedObjectIndex: null,
        selectedObjectsIndexes: null,
        showHandler: false,
        handler: null,
        multiSelect: false,
      },
      () => {
        this.objectRefs = {};
        this.props.onUpdate(newObjects);
      }
    );
  };

  duplicateCurrent = e => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    const indexes =
      typeof this.state.selectedObjectIndex === 'number'
        ? [this.state.selectedObjectIndex]
        : this.state.selectedObjectsIndexes || [];

    if (indexes.length === 0) {
      new window.NotificationCenter().show_error('Nothing selected to be duplicated');
    } else {
      this.insertNewLayers(
        indexes.map(index => {
          const object = this.props.objects[index];
          return { ...object, 'data-layer-name': `${getNameForObject(object)} - copy` };
        })
      );
    }
    this.setState({
      multiSelect: false,
    });
    return true;
  };

  duplicateObjectAtIndex = index => {
    const { objects } = this.props;
    const object = objects[index];
    this.setState({ multiSelect: false });
    this.createNewObject({
      ...object,
      x: object.x,
      y: object.y,
      uuid: generateUuid(),
      'data-layer-name': `${getNameForObject(object)} - copy`,
    });
  };

  undo = () => {
    if (this.props.historyLength !== 0) {
      this.props.historyBack();

      this.setState({
        multiSelect: false,
      });
    }
  };

  redo = () => {
    if (this.props.nextStepsFromHistoryLength !== 0) {
      this.props.historyForward();

      this.setState({
        multiSelect: false,
      });
    }
  };

  selectAll = () => {
    this.setState({
      selectedObjectIndex: null,
      selectedObjectsIndexes: this.props.objects.map((obj, index) => index),
      multiSelect: false,
    });
  };

  copy = () => {
    this.setState({ multiSelect: false });
    const indexes =
      typeof this.state.selectedObjectIndex === 'number'
        ? [this.state.selectedObjectIndex]
        : this.state.selectedObjectsIndexes || [];
    if (indexes.length === 0) {
      new window.NotificationCenter().show_error('Nothing selected to be copied');
    } else {
      storage.set(
        'ImageEditorClipboard',
        JSON.stringify(
          [...indexes]
            .sort((a, b) => a - b)
            .map(index => {
              const { ...copyObject } = this.props.objects[index];
              return {
                ...copyObject,
                'data-layer-name': getNameForObject(copyObject),
              };
            })
        ),
        { useCompression: true }
      );
      new window.NotificationCenter().show_success(
        t('react.image_generator.copy_success', {
          default: 'Copied %{layersCount} layers to clipboard',
          layersCount: indexes.length,
        })
      );
    }
  };

  paste = () => {
    const newObjects = JSON.parse(storage.get('ImageEditorClipboard', { default: '[]', useCompression: true }));
    if (newObjects.length === 0) {
      this.setState({ multiSelect: false });
      new window.NotificationCenter().show_error('Nothing to paste from clipboard');
    } else {
      this.setState({
        mode: modes.FREE,
        selectionHandler: null,
        holdAspectRatio: false,
        selectedTool: null,
        multiSelect: false,
        currentObjectIndex: null,
        selectedObjectIndex: null,
        selectedObjectsIndexes: null,
        showHandler: false,
      });

      this.insertNewLayers(
        newObjects.map(newObject => ({
          ...newObject,
          uuid: generateUuid(),
          'data-layer-name': `${getNameForObject(newObject)} - copy`,
        }))
      );

      new window.NotificationCenter().show_success(
        t('react.image_generator.paste_success', {
          default: 'Pasted %{layersCount} layers to image',
          layersCount: newObjects.length,
        })
      );
    }
  };

  moveSelectedObject(attr, points, event) {
    let newPoints = points;
    const { objects } = this.props;

    if (event.shiftKey) {
      newPoints *= 10;
    }

    const indexes =
      typeof this.state.selectedObjectIndex === 'number'
        ? [this.state.selectedObjectIndex]
        : this.state.selectedObjectsIndexes || [];

    const newObjects = indexes.map(index => {
      const object = objects[index];
      return {
        index,
        object: { [attr]: object[attr] + newPoints },
      };
    });
    this.updateObjects(newObjects);
    this.updateHandler(null, null, indexes);
  }

  getKeymapHandlers() {
    const handlers = {
      copy: this.copy,
      paste: this.paste,
      selectAll: this.selectAll,
      undo: this.undo,
      redo: this.redo,
      removeObject: this.removeCurrent,
      duplicateObject: this.duplicateCurrent,
      moveLeft: this.moveSelectedObject.bind(this, 'x', -1),
      moveRight: this.moveSelectedObject.bind(this, 'x', 1),
      moveUp: this.moveSelectedObject.bind(this, 'y', -1),
      moveDown: this.moveSelectedObject.bind(this, 'y', 1),
      closePath: () => this.setState({ mode: modes.FREE }),
      multiSelectStart: () => this.setState({ multiSelect: true }),
      multiSelectStartCtrl: () => this.setState({ multiSelect: true }),
      multiSelectEnd: () => this.setState({ multiSelect: false }),
      multiSelectEndCtrl: () => this.setState({ multiSelect: false }),
    };

    return _.mapValues(handlers, handler => event => {
      if (event.target.tagName !== 'INPUT') {
        event.preventDefault();
        handler(event);
      }
    });
  }

  render() {
    const {
      showHandler,
      handler,
      selectionHandler,
      mode,
      selectedObjectIndex,
      selectedObjectsIndexes,
      selectedTool,
      multiSelect,
    } = this.state;

    const {
      errors,
      objects,
      scale,
      panel,
      insertMenu: InsertMenuComponent,
      renderPreviewRef,
      modifierPreviewRoot,
      updateGeneratorState,
      background,
      variableName,
      isTemplateModalOpen,
      showGuidelines,
      onNewProductSetsAdded,
    } = this.props;

    const currentObject = objects[selectedObjectIndex];
    const isEditMode = mode === modes.EDIT_OBJECT;
    const showPropertyPanel =
      selectedObjectIndex !== null || (selectedObjectsIndexes && selectedObjectsIndexes.length === 1);

    const { width, height } = this.getCanvas();

    let objectComponent;
    let objectWithInitial;
    let ObjectEditor;
    if (currentObject) {
      objectComponent = objectTypes[currentObject.type];
      objectWithInitial = {
        ...objectComponent.meta.initial,
        ...currentObject,
      };
      ObjectEditor = objectComponent.meta.editor;
    }

    const currObjects =
      selectedObjectsIndexes && selectedObjectsIndexes.length > 0
        ? selectedObjectsIndexes.map(ind => ({ obj: objects[ind], index: ind }))
        : null;
    const lockedCurrentObject = currentObject && currentObject.lockLayer;
    const lockedCurrObjects = !!(currObjects && currObjects.find(obj => obj.lockLayer));

    const hotkeysProps = {
      keyMap: this.keyMap,
      style: styles.keyboardManager,
      handlers: this.getKeymapHandlers(),
    };
    return (
      <GlobalHotKeys {...hotkeysProps}>
        {/* Left Panel: Displays insertion tools (shapes, images, etc.) */}
        {InsertMenuComponent && (
          <InsertMenuComponent
            forceOpen={objects.length === 0}
            tools={objectTypes}
            currentTool={selectedTool}
            onSelect={this.selectTool}
            insertNewLayers={this.insertNewLayers}
            isTemplateModalOpen={isTemplateModalOpen}
          />
        )}
        <div
          className={('container', 'ppcbee-scrollbar', 'start-selection-container', 'js-main-container-to-get-sizes')}
          style={{
            ...styles.container,
            ...this.props.style,
          }}
          onMouseMove={this.onDrag.bind(this)}
          onMouseUp={this.stopDrag.bind(this)}
          onMouseDown={this.resetSelectionOnContainer}
        >
          <div
            className="start-selection-container"
            style={{
              width: `${(width + 48) * scale}px`,
              height: `${(height + 120) * scale}px`,
              margin: 'auto',
              flex: 'none',
            }}
          >
            {/* Center Panel: Displays the preview */}
            <div
              className="start-selection-container"
              style={{
                ...styles.canvasContainer,
                width: `${width}px`,
                height: `${height}px`,
                margin: '24px',
                marginBottom: '96px',
                transform: `scale(${scale})`,
                transformOrigin: '0 0',
              }}
            >
              {isEditMode && ObjectEditor && (
                <ObjectEditor
                  object={currentObject}
                  offset={this.getOffset()}
                  onUpdate={object => this.updateObject(selectedObjectIndex, object)}
                  onClose={() => this.setState({ mode: modes.FREE })}
                  width={width}
                  height={height}
                />
              )}

              {selectionHandler &&
                !isNaN(selectionHandler.width) &&
                !isNaN(selectionHandler.height) &&
                !isNaN(selectionHandler.top) &&
                !isNaN(selectionHandler.left) && (
                  <SelectionHandler
                    scale={scale}
                    onDrag={!lockedCurrentObject && !lockedCurrObjects && this.startDrag.bind(this, modes.DRAG)}
                    boundingBox={selectionHandler}
                  />
                )}
              {mode === modes.DRAG && showGuidelines && (
                <Rulers
                  boundingBox={handler}
                  width={width}
                  height={height}
                  scale={scale}
                  objects={objects}
                  selectedObjectIndex={selectedObjectIndex}
                  selectedObjectsIndexes={selectedObjectsIndexes}
                />
              )}
              {showHandler &&
                !isNaN(handler.width) &&
                !isNaN(handler.height) &&
                !isNaN(handler.top) &&
                !isNaN(handler.left) && (
                  <Handler
                    multiSelect={multiSelect}
                    scale={scale}
                    boundingBox={handler}
                    canResize={
                      ((_(currentObject).has('width') || _(currentObject).has('height')) && !lockedCurrentObject) ||
                      (currObjects && !lockedCurrObjects)
                    }
                    canRotate={_(currentObject).has('rotate') && !lockedCurrentObject}
                    // onMouseLeave={this.hideHandler.bind(this)}
                    // onDoubleClick={this.showEditor.bind(this)}
                    onDoubleClick={this.showPropertyPanel.bind(this)}
                    onDrag={!lockedCurrentObject && !lockedCurrObjects && this.startDrag.bind(this, modes.DRAG)}
                    onResize={this.startDrag.bind(this, modes.SCALE)}
                    onRotate={this.startDrag.bind(this, modes.ROTATE)}
                  />
                )}

              {this.renderSVG()}
            </div>
          </div>
        </div>
        <div className="d-none">
          <PreviewPanel
            dataSourceId={this.props.dataSourceId}
            getUpdatedCustomImages={this.props.getUpdatedCustomImages}
            panel={panel}
            setPanel={this.props.setPanel}
            hotkeysProps={hotkeysProps}
            renderPreviewRef={renderPreviewRef}
            modifierPreviewRoot={modifierPreviewRoot}
            objects={objects}
            updateState={updateGeneratorState}
            updateObject={this.updateObject}
            removeObjectAtIndex={this.removeObjectAtIndex}
            duplicateObjectAtIndex={this.duplicateObjectAtIndex}
            showHandler={this.showHandler}
            handler={this.state.handler}
            selectedObjectIndex={selectedObjectIndex}
            selectObjects={this.selectMultipleObjects}
            selectedObjectsIndexes={selectedObjectsIndexes}
            onArrange={this.handleArrange}
            onDesignerUpdate={this.props.onUpdate}
            propertyPanel={
              showPropertyPanel ? (
                <PanelList
                  key={`selected-${selectedObjectIndex}-${currentObject?.type}`}
                  id={this.props.id}
                  object={objectWithInitial}
                  onArrange={this.handleArrange}
                  onChange={this.handleObjectChange.bind(this)}
                  onNewProductSetsAdded={onNewProductSetsAdded}
                  objectComponent={objectComponent}
                />
              ) : null
            }
          >
            <Col padding="l">
              <Row>
                <Col>
                  <label htmlFor="variable_name_input">{t('react.image_generator.setup.image_name')}</label>
                  <Input
                    value={variableName}
                    name="setup[name]"
                    id="variable_name_input"
                    onChange={({ target: { value } }) => updateGeneratorState({ variableName: value })}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Select
                    label={t('react.image_generator.setup.image_size')}
                    doNotUseInternalState
                    onChange={({ target: { value } }) => {
                      if (value === CUSTOM_RESOLUTION) {
                        updateGeneratorState({ width: 400, height: 400 });
                      } else {
                        const [newWidth, newHeight] = value.split('x');
                        updateGeneratorState({ width: parseInt(newWidth, 10), height: parseInt(newHeight, 10) });
                      }
                    }}
                    value={RESOLUTIONS[`${width}x${height}`] ? `${width}x${height}` : CUSTOM_RESOLUTION}
                  >
                    {Object.keys(RESOLUTIONS).map(value => (
                      <option value={value} key={value}>
                        {RESOLUTIONS[value]()}
                      </option>
                    ))}
                  </Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <label htmlFor="resolution_width">{t('react.image_generator.setup.width')}</label>
                  <Input
                    value={width}
                    doNotUseInternalState
                    type="integer"
                    id="resolution_width"
                    onChange={({ target: { value } }) => updateGeneratorState({ width: value })}
                  />
                </Col>
                <Col>
                  <label htmlFor="resolution_height">{t('react.image_generator.setup.height')}</label>
                  <Input
                    value={height}
                    doNotUseInternalState
                    type="integer"
                    id="resolution_height"
                    onChange={({ target: { value } }) => updateGeneratorState({ height: value })}
                  />
                </Col>
              </Row>
              <div className="delimiter mt-32 mb-16 negative-ml-32 negative-mr-32" />

              <Row justifyBetween center>
                <Col shrink>
                  <label htmlFor="background">{t('react.image_generator.setup.background_color')}</label>
                </Col>
                <Col shrink>
                  {background && (
                    <Row center>
                      <Col shrink>
                        <ColorPicker
                          value={background}
                          onChange={({ target: { value } }) => updateGeneratorState({ background: value })}
                          shown
                        />
                      </Col>
                      <Col shrink>
                        <Button
                          onlyIcon
                          icon="close"
                          tertiary
                          onClick={() => updateGeneratorState({ background: undefined })}
                        />
                      </Col>
                    </Row>
                  )}
                  {!background && (
                    <Button secondary onClick={() => updateGeneratorState({ background: '#FF2222' })}>
                      {t('react.image_generator.setup.add_color')}
                    </Button>
                  )}
                </Col>
              </Row>

              <Row className="mt-16">
                <InfoBox withIcon type="info">
                  {tHtml('react.image_generator.info_text_html')}
                </InfoBox>
              </Row>
              {/* <Button kind="secondary" onClick={this.download}>
                Export SVG
              </Button> */}
            </Col>
            {errors && <div className="Text Text--error mt-16">{errors.join(', ')}</div>}
          </PreviewPanel>
        </div>
      </GlobalHotKeys>
    );
  }
}

export const styles = {
  container: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'row',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0',
    width: '100%',
    height: '100%',
    overflow: 'auto',
  },
  canvasContainer: {
    position: 'relative',
    margin: 'auto',
  },
  keyboardManager: {
    outline: 'none',
    height: '100%',
    position: 'relative',
  },
};

export default Designer;
