import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, NetworkStatus } from '@apollo/client';
import SmartSelect, { CustomAudience } from '../SmartSelect';
import { Button, Row, Col } from '../../index';

const FB_CUSTOM_AUDIENCE_QUERY = gql`
  query($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        fbCustomAudiences {
          id
          name
          subtype
          approximateCount
          deliveryStatus
          description
        }
      }
    }
  }
`;

const convertAudienceToOptions = audience => ({
  value: audience.id,
  originalLabel: audience.name,
  label: <CustomAudience {...audience} />,
});

const FBCustomAudienceSelect = memo(
  ({ organizationId, campaignSettingId, value, asyncOptionsPreloaded = [], ...rest }) => {
    const [findOptions, { refetch, error, data, called, networkStatus }] = useLazyQuery(FB_CUSTOM_AUDIENCE_QUERY, {
      variables: { organizationId, campaignSettingId },
      notifyOnNetworkStatusChange: true,
    });

    const loading = networkStatus !== NetworkStatus.ready && !error;
    const asyncOptions = (
      (data &&
        data.organization &&
        data.organization.campaignSetting &&
        data.organization.campaignSetting.fbCustomAudiences) ||
      []
    )
      .filter(a => !asyncOptionsPreloaded.find(ao => ao.id === a.id))
      .map(convertAudienceToOptions);

    let errorElement = null;
    if (error) {
      let errorMessage = null;
      try {
        errorMessage = error.networkError.result.error.message;
      } catch (e) {
        errorMessage = error.message;
      }

      errorElement = (
        <span className="ValidationError">
          {errorMessage}
          <a className="Link ml-8" onClick={() => refetch()}>
            {window.reacti18n.pla_campaign_preview.try_again}
          </a>
        </span>
      );
    }

    return (
      <React.Fragment>
        <Row center>
          <Col grow>
            <SmartSelect
              isLoading={loading}
              asyncOptions={asyncOptions}
              collection={asyncOptionsPreloaded.map(convertAudienceToOptions)}
              onMenuOpen={() => !called && findOptions()}
              value={value}
              asyncLoadOptions
              {...rest}
            />
          </Col>
          {refetch && (
            <Col shrink>
              <Button tertiary onlyIcon icon="refresh" onClick={() => refetch()} />
            </Col>
          )}
        </Row>
        {errorElement}
      </React.Fragment>
    );
  }
);

FBCustomAudienceSelect.propTypes = {
  organizationId: PropTypes.number,
  campaignSettingId: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.string),
  asyncOptionsPreloaded: PropTypes.array,
};

export default FBCustomAudienceSelect;
