import React, { PureComponent } from 'react';
import _ from 'lodash';
import cs from 'classnames';

import PropertyGroup from './PropertyGroup';
import InputCol from './InputCol';
import Columns from './Columns';
import { Col } from '../../../index';
import objectTypes from '../objectTypes';
import { t } from '../../../../i18n';

export default class StylePanel extends PureComponent {
  modes = [
    'normal',
    'multiply',
    'screen',
    'overlay',
    'darken',
    'lighten',
    'color-dodge',
    'color-burn',
    'hard-light',
    'soft-light',
    'difference',
    'exclusion',
    'hue',
    'saturation',
    'color',
    'luminosity',
  ];

  borderStyles = ['dotted', 'dashed', 'solid', 'double', 'groove', 'ridge', 'inset', 'outset', 'none'];

  render() {
    const { object, onChange } = this.props;
    const {
      meta: { initial: metaInitial },
    } = objectTypes[object.type];

    return (
      <PropertyGroup
        showIf={
          _.has(object, 'fill') ||
          _.has(object, 'blendMode') ||
          _.has(object, 'stroke') ||
          _.has(object, 'strokeWidth') ||
          _.has(object, 'radius') ||
          _.has(object, 'backgroundColor') ||
          _.has(object, 'borderColor') ||
          _.has(object, 'borderWidth') ||
          _.has(object, 'borderRadius') ||
          _.has(object, 'borderStyle')
        }
        className={cs({ 'pt-16': _.has(object, 'borderRadius' || _.has(object, 'radius')) })}
      >
        <Col padding="l">
          {_.has(object, 'borderRadius') && (
            <Columns label={t('react.image_generator.label.round_corners')} withoutLabelPadding delimiter>
              <InputCol
                width="88px"
                type="float"
                showIf={_.has(object, 'borderRadius')}
                value={object.borderRadius}
                property="borderRadius"
                unit="px"
                onChange={onChange}
              />
            </Columns>
          )}

          {_.has(object, 'radius') && (
            <Columns label={t('react.image_generator.label.round_corners')} withoutLabelPadding delimiter>
              <InputCol
                width="88px"
                type="float"
                showIf={_.has(object, 'radius')}
                value={`${object.radius}`}
                property="radius"
                unit="px"
                onChange={onChange}
              />
            </Columns>
          )}

          {_.has(object, 'fill') && (
            <Columns
              label={t('react.image_generator.label.fill')}
              object={object}
              onChange={onChange}
              onEnable={{ fill: metaInitial.fill, blendMode: 'normal' }}
              labelOnSeparateLine
              delimiter
            >
              <InputCol
                shrink
                type="color"
                showIf={_.has(object, 'fill')}
                value={object.fill}
                property="fill"
                onChange={onChange}
              />
              {/* <InputCol
                type="select"
                showIf={_.has(object, 'blendMode')}
                collection={this.modes}
                value={object.blendMode}
                property="blendMode"
                onChange={onChange}
              /> */}
            </Columns>
          )}

          {_.has(object, 'backgroundColor') && (
            <Columns
              label={t('react.image_generator.label.fill')}
              object={object}
              onChange={onChange}
              onEnable={{ backgroundColor: 'transparent', blendMode: 'normal' }}
              labelOnSeparateLine
              delimiter
            >
              <InputCol
                shrink
                type="color"
                showIf={_.has(object, 'backgroundColor')}
                value={object.backgroundColor}
                property="backgroundColor"
                onChange={onChange}
              />
              <InputCol
                type="select"
                showIf={_.has(object, 'blendMode')}
                collection={this.modes}
                value={object.blendMode}
                property="blendMode"
                onChange={onChange}
              />
            </Columns>
          )}

          {(_.has(object, 'stroke') || _.has(object, 'strokeWidth')) && (
            <Columns
              label={t('react.image_generator.label.border')}
              object={object}
              onChange={onChange}
              onEnable={{ stroke: '#000000FF', strokeWidth: 1 }}
              labelOnSeparateLine
            >
              <Col>
                <Columns label={t('react.image_generator.label.border_color')} className="mb-16" withoutLabelPadding>
                  <InputCol shrink type="color" value={object.stroke} property="stroke" onChange={onChange} />
                </Columns>

                <Columns label={t('react.image_generator.label.line_thickness')} withoutLabelPadding>
                  <InputCol
                    width="88px"
                    type="float"
                    value={`${object.strokeWidth}`}
                    property="strokeWidth"
                    unit="px"
                    onChange={onChange}
                  />
                </Columns>
              </Col>
            </Columns>
          )}

          {(_.has(object, 'borderColor') || _.has(object, 'borderStyle')) && (
            <Columns
              label={t('react.image_generator.label.border')}
              object={object}
              onChange={onChange}
              onEnable={{ borderColor: '#000000FF', borderStyle: 'solid', borderWidth: 1 }}
              labelOnSeparateLine
            >
              <InputCol
                shrink
                type="color"
                showIf={_.has(object, 'borderColor')}
                value={object.borderColor}
                property="borderColor"
                onChange={onChange}
              />
              <InputCol
                grow
                type="select"
                showIf={_.has(object, 'borderStyle')}
                collection={this.borderStyles}
                value={object.borderStyle}
                property="borderStyle"
                onChange={onChange}
              />
            </Columns>
          )}

          {_.has(object, 'borderWidth') && object.borderWidth && (
            <Columns label={t('react.image_generator.label.line_thickness')} withoutLabelPadding>
              <InputCol
                width="88px"
                type="float"
                showIf={_.has(object, 'borderWidth')}
                value={object.borderWidth}
                property="borderWidth"
                unit="px"
                onChange={onChange}
              />
            </Columns>
          )}
        </Col>
      </PropertyGroup>
    );
  }
}
