import React from 'react';
import RPT from 'prop-types';

// TODO this needs to be somehow consolidated!!!

export default class Input extends React.PureComponent {
  static propTypes = {
    name: RPT.string.isRequired,
    hint: RPT.string,
    namespace: RPT.string,
    error: RPT.string,
    value: RPT.string.isRequired,
    required: RPT.bool.isRequired,
    label: RPT.string.isRequired,
    onChange: RPT.func.isRequired,
    placeholder: RPT.string,
  };

  componentDidMount() {
    if (this.props.hint && typeof window.initReact === 'function') {
      window.initReact();
    }
  }

  render() {
    const { name, hint, namespace, label, required, value, onChange, error, placeholder } = this.props;
    let id = name;
    let fieldName = name;
    if (namespace) {
      id = [namespace, name].join('_');
      fieldName = `${namespace}[${name}]`;
    }

    return (
      <div className={`Input string ${required && 'required'} ${id} ${error && 'has-error'}`}>
        <label className={`Input-label string ${required && 'required'}`} htmlFor={id}>
          {required && <abbr title="požadováno">*</abbr>}
          {label}
        </label>
        {hint && <span className="Input-tooltip fc-info-circle-fill js-form-hint">{hint}</span>}
        <input
          className={`string ${required && 'required'}`}
          onChange={onChange}
          type="text"
          value={value}
          name={fieldName}
          id={id}
          placeholder={placeholder}
        />
        {error && <span className="Input-errorLabel">{error}</span>}
      </div>
    );
  }
}
