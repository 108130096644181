import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Text, Icon, cssVariables } from '../index';

const AdPreviewContainer = ({ icon, title, children }) => (
  <div className="FbPreview-previewContainer">
    <Row center justifyCenter className="mb-12">
      <Col shrink>
        <Icon kind={icon} size="20px" color={cssVariables.iconSubtle} />
      </Col>
      <Col shrink>
        <Text bold spacing={0} tag="h3">
          {title}
        </Text>
      </Col>
    </Row>
    <div className="FbPreview-previewContent">{children}</div>
  </div>
);

AdPreviewContainer.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default AdPreviewContainer;
