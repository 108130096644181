import qs from 'qs';
import { t } from '../../i18n';
import {
  ACTIVE,
  CURRENTLY_ACTIVE_BUT_IT_WILL_END_SOON,
  CURRENTLY_ACTIVE,
  NOT_ACTIVE_CANCELED,
  REGULAR,
  NOT_ACTIVE,
  POSSIBLE_REGULAR,
  POSSIBLE_TRIAL,
  TRIAL,
  FEED_EXPORT,
} from './constants';

export const putPeriodToUrl = period =>
  period ? window.history.pushState({}, '', [window.location.pathname, qs.stringify({ period })].join('?')) : null;

export const parseUrl = url => qs.parse(url, { ignoreQueryPrefix: true });

export const isUsed = ({ tarif, feature, usageCount, numberPrice }) => {
  if (tarif === TRIAL) return true;
  if (feature === FEED_EXPORT) return usageCount > 0;

  return numberPrice > 0;
};

export const getValues = ({
  tarif,
  featureStatus,
  trialRemainingDays,
  featuresPricing,
  expiredDays,
  trialDays,
  isFeatureUsed,
}) => {
  if (tarif === POSSIBLE_TRIAL) {
    if (featureStatus === NOT_ACTIVE) {
      return {
        color: 'orange',
        label: t('subscription.status.free_trial'),
        subheading: t('feature_period.days_available', { days: trialDays }),
        modal: {
          subheading: t('feature_period.days_starting_now', { days: trialDays }),
        },
        tableStatusKind: 'trial_available',
      };
    }
  }
  if (tarif === TRIAL) {
    if (
      featureStatus === ACTIVE ||
      featureStatus === CURRENTLY_ACTIVE_BUT_IT_WILL_END_SOON ||
      featureStatus === CURRENTLY_ACTIVE
    ) {
      return {
        color: 'orange',
        label: t('subscription.status.free_trial'),
        compute: true,
        subheading: t('feature_period.days_left', { days: trialRemainingDays }),
        tableStatusKind: 'trial_active',
      };
    }
  }
  if (tarif === POSSIBLE_REGULAR) {
    if (featureStatus === NOT_ACTIVE_CANCELED) {
      return {
        color: 'dark',
        label: t('subscription.status.stopped'),
        tableStatusKind: 'stopped',
      };
    }
    if (featureStatus === NOT_ACTIVE) {
      return {
        color: 'dark',
        subheading: t('feature_period.from_money_month', { price: featuresPricing?.[0]?.price }),
        modal: {
          label: t('subscription.status.starting_now'),
          disableSubheading: true,
        },
        tableStatusKind: 'not_active',
      };
    }
  }
  if (tarif === REGULAR) {
    if (featureStatus === ACTIVE || featureStatus === CURRENTLY_ACTIVE) {
      if (!isFeatureUsed) {
        return {
          color: 'green',
          label: t('subscription.status.active_not_used'),
          tableStatusKind: 'active_not_used',
        };
      }
      return { color: 'green', label: t('subscription.status.active'), tableStatusKind: 'active' };
    }
    if (featureStatus === NOT_ACTIVE) {
      return {
        color: 'dark',
        subheading: t('feature_period.from_money_month', { price: featuresPricing?.[0]?.price }),
        modal: {
          label: t('subscription.status.starting_now'),
          disableSubheading: true,
        },
        tableStatusKind: 'not_active',
      };
    }
    if (featureStatus === CURRENTLY_ACTIVE_BUT_IT_WILL_END_SOON) {
      return {
        color: 'green',
        label: t('subscription.status.yet_active'),
        subheading: t('feature_period.days_left', { days: expiredDays }),
        tableStatusKind: 'yet_active',
      };
    }
  }
  return {
    color: 'dark',
    label: t('subscription.status.stopped'),
    tableStatusKind: 'stopped',
  };
};
