/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Input } from '../components/form';
import PlaceholderInput from '../placeholders/Input';
import { Row, Col } from '../components/layout';
import Button from '../components/Button';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter } from '../components';
import Tile from '../components/Tile';

class HeurekaParamContent extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    param: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    value: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
    paramPlaceholder: PropTypes.string,
    valuePlaceholder: PropTypes.string,
    withTile: PropTypes.bool,
    _error_fields: PropTypes.arrayOf(PropTypes.string),
    _errors: PropTypes.string,
  };

  static defaultProps = {
    options: {},
    right_part_array: [],
  };

  state = {
    param: this.props.param || '',
    value: this.props.value || '',
  };

  handleParam = ({ target: { value } }) => {
    if (value !== this.state.param) {
      this.setState({ param: value });
      this.props.updateFieldData(this.props.id, { param: value });
      updatePreview();
    }
  };

  handleValue = ({ target: { value } }) => {
    if (value !== this.state.value) {
      this.setState({ value });
      this.props.updateFieldData(this.props.id, { value });
      updatePreview();
    }
  };

  render() {
    const {
      disabled,
      id,
      getSubFiledOptions,
      onRemove,
      paramPlaceholder,
      valuePlaceholder,
      withTile,
      _errors,
    } = this.props;
    const { value, param } = this.state;

    return (
      <Row padding="l" center data-test-id="nested-heureka-param" className={cs({ 'mb-8': !withTile })}>
        <Col
          width={withTile ? '25%' : 'calc(25% + 34px)'}
          data-test-id="heureka-params-param"
          className={cs({ 'mr-8': !withTile })}
        >
          <Input
            defaultValue={param}
            onChange={this.handleParam}
            placeholder={paramPlaceholder || t('views.heureka_param.fields.param', { default: 'Barva' })}
            error={_errors}
            errorMessage={_errors}
            {...getSubFiledOptions('param')}
          />
        </Col>

        {withTile && (
          <Col shrink className="align-self-stretch">
            <TileArrowDelimiter hasSmallArrow={false} containerPadding="8px" />
          </Col>
        )}

        <Col grow data-test-id="heureka-params-value">
          <PlaceholderInput
            defaultValue={value}
            onChange={this.handleValue}
            placeholder={valuePlaceholder || t('views.heureka_param.fields.value', { default: 'Black' })}
            {...getSubFiledOptions('value')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter hasSmallArrow={!withTile} containerPadding={withTile ? '8px' : '0px'} />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={'Example: <span class="Text--bold">123</span>'}
          data-field={`heureka_params.${param}`}
        >
          <div className="Text">
            {t('views.feed_export.example')} <span className="Text--bold">124</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

const HeurekaParam = ({ withTile, ...rest }) => {
  if (!withTile) {
    return <HeurekaParamContent {...rest} />;
  }

  return (
    <Tile className="mb-8" smallSpaced>
      <HeurekaParamContent {...rest} withTile />
    </Tile>
  );
};

HeurekaParam.propTypes = {
  withTile: PropTypes.bool,
};

export default HeurekaParam;
