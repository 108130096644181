/* eslint-disable */

import React, { useState } from 'react';
import { Button, Input, Form, Checkboxes } from '../index';

const ExampleReactForm = () => {
  return (
    <div>
      React form
      <Form onSubmit={console.log}>
        <Input name="name" value="foo" label="Name" />
        <Checkboxes label="Options" name="options" collection={['foo', 'bar']} />
        <Button primary>Save</Button>
      </Form>
    </div>
  );
};

export default ExampleReactForm;
