import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';

export default class Tooltip extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
    text: PropTypes.any,
    content: PropTypes.any,
    reactContent: PropTypes.any,
  };

  static defaultProps = {
    placement: 'top',
  };

  componentDidUpdate() {
    setTimeout(() => this.initTooltip(this.props.text), 100);
  }

  componentWillUnmount() {
    if (this.component && this.component?.tooltip) {
      $(this.component).tooltip('destroy');
    }
  }

  setRef = el => {
    if (el) {
      this.component = el;
      this.initTooltip(this.props.text);
    }
  };

  initTooltip = () => {
    const { reactContent, text } = this.props;
    const tooltipContent = reactContent ? ReactDOMServer.renderToStaticMarkup(reactContent) : text;

    if (this.component && this.component?.tooltip) {
      $(this.component).tooltip('destroy');
    }

    $(this.component).tooltip({
      container: 'body',
      html: true,
      title: tooltipContent,
    });
  };

  render() {
    const { className, placement, children, text, content, reactContent, ...rest } = this.props;

    if (!text && !reactContent) {
      return children;
    }

    return (
      <span
        {...rest}
        className={className}
        data-placement={placement}
        data-toggle="tooltip"
        data-html="true"
        ref={this.setRef}
        content={content}
      >
        {children}
      </span>
    );
  }
}
