import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Manager, Popper, Reference } from 'react-popper';
import Icon from './Icon';
import { Row, Col, Badge } from './index';
import { Loader } from './Icons';
import usePrevious from '../hooks/usePrevious';

const DropdownMenu = ({
  action = false,
  badgeCount,
  buttonOnlyLabel = false,
  className,
  dataTestId,
  error,
  forceOpen = false,
  gray = false,
  hasChevronDelimiter = false,
  hideOnClick = true,
  children,
  icon = 'more',
  isButtonRounded = false,
  isButtonWithBorder = false,
  isButtonWithDefaultFont = false,
  label,
  labelIcon,
  loading = false,
  maxHeight,
  minWidth,
  nowrap = true,
  onClose,
  onOpen,
  open = false,
  placement = 'bottom-start',
  secondary = false,
  showButtonLikeBadge = false,
  small = false,
  withBadge = false,
  withList = false,
  withoutScroll = false,
  ...rest
}) => {
  const containerRef = useRef(null);
  const popperElRef = useRef(null);
  const toggleElRef = useRef(null);
  const [show, setShow] = useState(forceOpen || open);
  const prevShow = usePrevious(show);

  const handleOutsideClick = useCallback(
    e => {
      if (
        !hideOnClick &&
        popperElRef.current &&
        toggleElRef.current &&
        !popperElRef.current.contains(e.target) &&
        !toggleElRef.current.contains(e.target)
      ) {
        setShow(false);
      } else if (hideOnClick && toggleElRef.current && !toggleElRef.current.contains(e.target)) {
        setShow(false);
      }
    },
    [popperElRef, toggleElRef, setShow, hideOnClick]
  );

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (show && onOpen && popperElRef.current) {
      onOpen(popperElRef.current);
    }
    if (prevShow !== show && !show && onClose) {
      onClose();
    }
  }, [show]);

  const dropdownMenuButtonClass = showButtonLikeBadge
    ? {
        'Badge Badge--likeDropdownButton Badge--spacedText Badge--uppercase Badge--withBorder Badge--extraLarge': !buttonOnlyLabel,
        'Badge--lightBlue': !error,
        'Badge--lightRed': error,
      }
    : {
        'DropdownMenu-button': !buttonOnlyLabel,
        'DropdownMenu-button--onlyLabel': buttonOnlyLabel,
        'DropdownMenu-button--secondary': action || secondary,
        'DropdownMenu-button--gray': gray,
        'DropdownMenu-button--rounded': isButtonRounded,
        'DropdownMenu-button--withBorder': isButtonWithBorder,
        'DropdownMenu-button--withDefaultFont': isButtonWithDefaultFont,
      };

  return (
    <span
      ref={el => {
        containerRef.current = el?.closest('.modal') || document.body;
      }}
    >
      <Manager>
        <Reference>
          {({ ref }) => (
            <div
              className={cs('DropdownMenu', className, {
                'DropdownMenu--small': small,
                'DropdownMenu--withLabel': label || labelIcon,
                'DropdownMenu--withBadge': withBadge,
              })}
              ref={el => {
                ref(el);
                toggleElRef.current = el;
              }}
              onClick={() => setShow(!show)}
              {...rest}
            >
              {withBadge && badgeCount > 0 && (
                <div className="DropdownMenu--badge">
                  <Badge
                    kind="red"
                    textColor="light"
                    size="tiny"
                    className="d-flex"
                    count={badgeCount > 9 ? '9+' : badgeCount}
                    data-test-id={`${dataTestId}-badge_count`}
                  />
                </div>
              )}
              <div
                className={cs(dropdownMenuButtonClass, {
                  open: show,
                  [`fc-${icon}`]: !label,
                })}
                data-test-id={dataTestId}
                role="button"
                tabIndex={0}
              >
                {(label || labelIcon) && (
                  <Row center>
                    {labelIcon && (
                      <Col className={cs('Icon', { 'negative-ml-4': !showButtonLikeBadge })} shrink>
                        {labelIcon}
                      </Col>
                    )}
                    {label && (
                      <Col grow nowrap={nowrap} className={cs({ 'mr-0': hasChevronDelimiter })}>
                        {label}
                      </Col>
                    )}
                    {((!buttonOnlyLabel && label) || loading) && (
                      <>
                        {hasChevronDelimiter && (
                          <Col shrink className="align-self-stretch pv-4 mh-6">
                            <div className="DropdownMenu-verticalDelimiter" />
                          </Col>
                        )}
                        <Col shrink>
                          {!loading ? (
                            <Icon
                              className={cs('DropdownMenu-chevron negative-mr-4', {
                                'DropdownMenu-chevron--gray': gray,
                              })}
                              kind="chevron-down"
                              inheritColor
                            />
                          ) : (
                            <div>
                              <Loader size="extraSmall" />
                            </div>
                          )}
                        </Col>
                      </>
                    )}
                  </Row>
                )}
              </div>
            </div>
          )}
        </Reference>
        {show &&
          containerRef.current &&
          ReactDOM.createPortal(
            <Popper placement={placement}>
              {({ ref, style }) => (
                <div
                  ref={el => {
                    ref(el);
                    popperElRef.current = el;
                  }}
                  className={cs('DropdownMenu-dropdown DropdownMenu-dropdown-react', {
                    open: show,
                    'DropdownMenu-dropdown--error': error,
                    'DropdownMenu-dropdown--withList': withList,
                    'DropdownMenu-dropdown--withoutScroll': withoutScroll,
                  })}
                  style={{
                    ...style,
                    minWidth: minWidth || 'auto',
                    maxHeight: maxHeight || 'auto',
                    position: containerRef.current === document.body ? 'absolute' : 'fixed',
                  }}
                >
                  {children}
                </div>
              )}
            </Popper>,
            containerRef.current
          )}
      </Manager>
    </span>
  );
};

DropdownMenu.propTypes = {
  badgeCount: PropTypes.number,
  buttonOnlyLabel: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  error: PropTypes.bool,
  forceOpen: PropTypes.bool,
  gray: PropTypes.bool,
  hasChevronDelimiter: PropTypes.bool,
  hideOnClick: PropTypes.bool,
  children: PropTypes.any,
  icon: PropTypes.string,
  isButtonRounded: PropTypes.bool,
  isButtonWithBorder: PropTypes.bool,
  isButtonWithDefaultFont: PropTypes.bool,
  label: PropTypes.any,
  labelIcon: PropTypes.any,
  loading: PropTypes.bool,
  maxHeight: PropTypes.string,
  minWidth: PropTypes.string,
  nowrap: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  open: PropTypes.bool,
  placement: PropTypes.oneOf([
    'auto',
    'auto-start',
    'auto-end',
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
  ]),
  primary: PropTypes.bool,
  action: PropTypes.bool,
  small: PropTypes.bool,
  secondary: PropTypes.bool,
  showButtonLikeBadge: PropTypes.bool,
  withBadge: PropTypes.bool,
  withList: PropTypes.bool,
  withoutScroll: PropTypes.bool,
};

export default DropdownMenu;
