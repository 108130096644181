/* eslint-disable react/no-danger */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { gql, useQuery } from '@apollo/client';
import { TransformationsFilterT } from './Filter';
import { Text } from '../Typography';
import Pagination, { PER_OPTIONS, usePagination } from '../Pagination';
import { Col, Row } from '../layout';
import Select from '../form/Select';
import { formatInteger, t } from '../../i18n';
import Searchbox from '../../organizations_dropdown/Searchbox';
import ProductPreview from './ProductPreview';
import { listenOnModalChange } from '../RemoteModal';
import FieldKindIcon from '../FieldKindIcon';
import Ellipsis from '../Ellipsis';
import InfoBox from '../InfoBox';
import { VARIABLE_RULE_STATUS_RUNNING } from './Transformation';
import { LoadingTableRows } from '../table/LoadingTable';

const GET_TRANSFORMATION_PREVIEW = gql`
  query VariableTransformationsQuery(
    $organizationId: BigInt!
    $dataSourceId: BigInt
    $feedExportId: BigInt
    $variable: String!
    $from: Int
    $size: Int
    $search: String
  ) {
    organization(id: $organizationId) {
      dataSource(id: $dataSourceId) {
        tableVariablesSorted {
          name
          placeholderName
          elasticPlaceholderName
          showFieldKind
          isImageUrl
          previewLink
          imagesCount
          showInCard
          showInTable
        }
      }
      itemsSearch(dataSourceId: $dataSourceId) {
        id
        items(feedExportId: $feedExportId, from: $from, size: $size, search: $search) {
          id
          productId
          data
          originalData(variable: $variable)
          transformedData(variable: $variable)
          transformedDataDiff(variable: $variable)
          appliedTransformations(variable: $variable) {
            id
            transformationResultPreview
            transformation {
              id
            }
          }
        }
        itemsCount(search: $search)
      }
      __typename
    }
  }
`;

export const PreviewValueCompare = ({
  originalData,
  transformedData,
  transformedDataDiff,
  customVariableOriginalData,
}) => (
  <>
    <Text lineTrought color="red">
      {originalData || customVariableOriginalData}
    </Text>{' '}
    <Text color="green">{transformedData || transformedDataDiff}</Text>
  </>
);

PreviewValueCompare.propTypes = {
  originalData: PropTypes.string,
  transformedData: PropTypes.string,
  transformedDataDiff: PropTypes.string,
  customVariableOriginalData: PropTypes.string,
};

const Preview = ({ transformationVariable, organizationId, dataSourceId, filter, transformationSubjects }) => {
  const [search, setSearch] = useState('');
  const [productPreviewId, setProductPreviewId] = useState(null);
  const { pagination, setPaginationInfo } = usePagination({ totalItems: 0 });

  const { data, loading, refetch } = useQuery(GET_TRANSFORMATION_PREVIEW, {
    variables: {
      organizationId,
      dataSourceId,
      variable: transformationVariable.name,
      feedExportId: filter.feedExport || null,
      search,
      from: pagination.currentPage * pagination.pageSize,
      size: Number(pagination.pageSize),
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const transformationSubjectsActive = useMemo(
    () => transformationSubjects.filter(transformation => transformation.status === VARIABLE_RULE_STATUS_RUNNING),
    [transformationSubjects]
  );

  useEffect(() => {
    const total = data?.organization.itemsSearch.itemsCount;
    setPaginationInfo({ ...(!!total || total === 0 ? { totalItems: data?.organization.itemsSearch.itemsCount } : {}) });
  }, [data?.organization.itemsSearch.itemsCount]);

  const handleShowProductPreview = (event, productId) => {
    event.preventDefault();
    setProductPreviewId(productId);
  };

  const handleCloseProductPreview = () => {
    setProductPreviewId(null);
  };

  const handleOnModalUpdateData = useCallback(() => {
    refetch();
  }, [refetch]);

  useEffect(() => listenOnModalChange(handleOnModalUpdateData), []);

  useEffect(() => {
    refetch();
  }, transformationVariable.transformationSubjects);

  useEffect(() => {
    pagination.setCurrentPage(0);
  }, [search, pagination.setCurrentPage]);

  const previewProductData = useMemo(
    () => data?.organization.itemsSearch.items?.find(item => item.productId === productPreviewId),
    [data?.organization.itemsSearch.items, productPreviewId]
  );

  return (
    <div>
      <Row justifyEnd className="mb-16">
        <Col width="186px">
          <Searchbox
            searchValue={search}
            handleSearch={({ target: { value } }) => setSearch(value)}
            debounce={400}
            searchBoxText=""
          />
        </Col>
      </Row>
      <InfoBox type="info" withIcon className="mb-16">
        {t('react.transformations.preview_notice')}
      </InfoBox>

      <div className="Sticky-Wrapper">
        <table className="Sticky-Table Sticky-Table--doNotStickFirstColumn">
          <thead>
            <tr className="Sticky-Header">
              <th className="Sticky-Cell">Product ID</th>
              <th className="Sticky-Cell">Original</th>
              {transformationSubjectsActive.map((subject, index) => (
                <th key={subject.id} className="Sticky-Cell">
                  <Row center>
                    {index + 1}.:
                    <FieldKindIcon showFieldKind={subject.transformation.fieldKind} size="1.6em" />
                    <Ellipsis>{subject.transformation.name}</Ellipsis>
                  </Row>
                </th>
              ))}
              <th className="Sticky-Cell">Diff</th>
              <th className="Sticky-Cell">Final</th>
            </tr>
          </thead>
          <tbody className="Sticky-Body">
            {!loading ? (
              data?.organization.itemsSearch.items?.map(item => {
                const customVariableOriginalData = JSON.parse(item.data)?.modifiers?.[
                  transformationVariable.placeholderName
                ];

                return (
                  <tr key={item.id} className="Sticky-Row">
                    <td className="Sticky-Cell">
                      <a href={`#${item.productId}`} onClick={event => handleShowProductPreview(event, item.productId)}>
                        {item.productId}
                      </a>
                    </td>
                    <td className="Sticky-Cell">{item.originalData || customVariableOriginalData}</td>
                    {transformationSubjectsActive.map(subject => (
                      <td key={`td-${subject.id}`} className="Sticky-Cell">
                        {
                          item.appliedTransformations.find(applied => applied.id === subject.id)
                            ?.transformationResultPreview
                        }
                      </td>
                    ))}
                    <td className="Sticky-Cell">
                      {item.originalData !== item.transformedData ? (
                        <PreviewValueCompare {...item} customVariableOriginalData={customVariableOriginalData} />
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="Sticky-Cell">{item.transformedData}</td>
                  </tr>
                );
              })
            ) : (
              <LoadingTableRows
                rows={pagination.pageSize}
                cols={transformationSubjectsActive.length + 4}
                maxColWidth="65px"
              />
            )}
          </tbody>
        </table>
      </div>

      <LoadingTableRows
        rows={pagination.pageSize}
        headerCells={[
          'Product ID',
          'Original',
          ...transformationSubjectsActive.map(subject => subject.transformation.name),
          'Diff',
          'Final',
        ]}
      />

      <Pagination
        className="mt-16"
        current={pagination.currentPage}
        disableAnchor
        maxPages={pagination.maxPages}
        changePage={page => () => pagination.setCurrentPage(page)}
        isCompact
      >
        <Col shrink>
          {t('react.show', {
            default: 'Show',
          })}
        </Col>
        <Col shrink nowrap>
          <Select
            doNotUseInternalState
            name="products[perPage]"
            id="pagination-products[perPage]"
            value={pagination.pageSize}
            onChange={event => pagination.setPageSize(event.currentTarget.value)}
            collection={PER_OPTIONS.map(item => ({ label: `${item} rows`, value: item }))}
          />
        </Col>
        <Col shrink nowrap>
          {t('react.outof', {
            default: 'out of %{total}',
            total: formatInteger(pagination.total || 0),
          })}
        </Col>
      </Pagination>

      <ProductPreview
        productData={previewProductData}
        productId={productPreviewId}
        onClose={handleCloseProductPreview}
        transformationVariable={transformationVariable}
        tableVariablesSorted={data?.organization.dataSource.tableVariablesSorted}
      />
    </div>
  );
};

Preview.propTypes = {
  transformationVariable: PropTypes.shape({
    name: PropTypes.string,
    fieldKind: PropTypes.string,
  }),
  organizationId: PropTypes.number,
  dataSourceId: PropTypes.number,
  filter: TransformationsFilterT,
  transformationSubjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      transformation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        fieldKind: PropTypes.string,
      }),
    })
  ),
};

export default Preview;
