export default ({ object, startPoint, mouse, scale, holdAspectRatio }) => {
  const angle = Math.atan2(
    startPoint.objectX + (object.width || 0) / 2 - mouse.x / scale,
    startPoint.objectY + (object.height || 0) / 2 - mouse.y / scale
  );

  const asDegree = (angle * 180) / Math.PI;
  const rotation = (asDegree + 45) * -1;

  return {
    ...object,
    rotate: holdAspectRatio ? Math.round(rotation / 15) * 15 : rotation,
  };
};
