import React from 'react';
import PropTypes from 'prop-types';
import { Loader, ErrorLoadingQuote } from './index';

const withApiStateHandler = Component => {
  const withApiStateHandlerComponent = props => {
    if (props.loading) {
      return (
        <div className="d-flex justify-content-center myl">
          <Loader data-test-id="widget-loader" size="big" />
        </div>
      );
    }

    if (props.error) {
      return <ErrorLoadingQuote onClick={() => props.refetch()} />;
    }

    return <Component {...props} />;
  };

  withApiStateHandlerComponent.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.string,
    refetch: PropTypes.func,
  };

  return withApiStateHandlerComponent;
};

export default withApiStateHandler;
