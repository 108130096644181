/* eslint-disable react/prop-types */
import React from 'react';
import { setupColumn } from './setupColumn';
import { ConditionsWithGraphCell, GenericCell } from './cells';
import { t } from '../../../i18n';
import Button from '../../Button';

const mapping = {
  ...setupColumn({
    id: 'name',
    cell: GenericCell,
    resolveChildren: ({ createFromRestLink = '', type }) => (
      <Button size="small" secondary href={createFromRestLink} tag="a" className="ml-a">
        {type === 'PerformanceMaxAdtext'
          ? t('react.tables.adtexts.create_asset_group')
          : t('react.tables.adtexts.create_ad')}
      </Button>
    ),
  }),
  ...setupColumn({
    id: 'products',
    cell: ConditionsWithGraphCell,
    resolveChildren: ({ products = 0, productsTotal = 0 }) => ({
      noConditions: true,
      withArrow: true,
      productsTotal,
      products,
      productsText: t('react.tables.adtexts.products_left', { products }),
    }),
  }),
  ...setupColumn({
    id: 'empty',
    cell: GenericCell,
    resolveChildren: () => null,
  }),
};

export default mapping;
