import React, { memo } from 'react';
import { Button } from '../../../index';

const SwitchState = props => {
  const selected = props.value !== props.defaultValue;
  const newValue = selected ? props.defaultValue : props.nextState;

  return (
    <Button
      onlyIcon
      data-test-id={`switch-state-${props.icon}`}
      icon={props.icon}
      tertiary
      active={selected}
      onClick={() => props.onChange(newValue)}
    />
  );
};

export default memo(SwitchState);
