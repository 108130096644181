import React, { useRef, useEffect, useState } from 'react';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { t } from '../i18n';
import { Button, Tile, Input, Row, Col, DndDragHandle, UsedByGraph } from '../components';
import updatePreview from './updatePreview';

const handle = ({ target: { value } }, props, key) => {
  if (value !== props[key]) {
    props.updateFieldData(value);
  }
};

const debounceStateChange = ({ updateFieldData, id, stateValue, key }) => {
  updateFieldData(id, { [key]: stateValue });
  updatePreview();
};

function RemapMatch({
  additionalState,
  disabled,
  getSubFiledOptions,
  id,
  isLast,
  onRemove,
  dragHandleProps,
  replace_type,
  to,
  what,
  updateFieldData,
  computedData,
}) {
  const [statenTo, setStatenTo] = useState(to);
  const [statenWhat, setStatenWhat] = useState(what);

  const _count = computedData?._count;
  const _totalCount = computedData?._totalCount;

  const debounceStateWhat = useRef(debounce(debounceStateChange, 1000)).current;
  const debounceStateTo = useRef(debounce(debounceStateChange, 1000)).current;

  useEffect(() => {
    if (to !== statenTo) {
      debounceStateTo({ updateFieldData, id, stateValue: statenTo, key: 'to' });
    }
  }, [statenTo]);

  useEffect(() => {
    if (to !== statenTo) {
      setStatenTo(to);
      updatePreview();
    }
  }, [to]);

  useEffect(() => {
    if (what !== statenWhat) {
      debounceStateWhat({ updateFieldData, id, stateValue: statenWhat, key: 'what' });
    }
  }, [statenWhat]);

  useEffect(() => {
    if (what !== statenWhat) {
      setStatenWhat(what);
      updatePreview();
    }
  }, [what]);

  return (
    <Tile contentStyle={{ padding: '8px 16px' }} className="mb-8" style={{ maxWidth: '800px' }}>
      <input type="hidden" {...getSubFiledOptions('replace_type')} value="text" />
      <Row center data-test-id="nested-field-replace">
        {additionalState?.remapVariableType === 'remap_match' && (
          <Col width="25px">
            <DndDragHandle {...dragHandleProps} />
          </Col>
        )}
        <Col shrink data-test-id="graph">
          <UsedByGraph size="md" products={_count || 0} productsTotal={_totalCount || 1} />
        </Col>
        <Col grow data-test-id="replace-input">
          <Input
            doNotUseInternalState
            value={statenWhat}
            onChange={e => handle(e, { updateFieldData: setStatenWhat, id, what }, 'what')}
            placeholder={t('views.replaces.fields.content_value')}
            enableAutosuggest
            suggestions={[...(additionalState?.suggestions || [])].slice(0, 100)}
            {...getSubFiledOptions('what')}
          />
        </Col>
        <Col grow data-test-id="replace-to">
          {replace_type !== 'regexp_extract' && (
            <Input
              doNotUseInternalState
              value={statenTo}
              onChange={e => handle(e, { updateFieldData: setStatenTo, id, to }, 'to')}
              placeholder={t('views.replaces.fields.new_value')}
              {...getSubFiledOptions('to')}
            />
          )}
        </Col>
        <Col shrink>
          {id && onRemove && (
            <Button
              tertiary
              onlyIcon
              disabled={disabled || isLast}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          )}
        </Col>
      </Row>
    </Tile>
  );
}

export default RemapMatch;

RemapMatch.propTypes = {
  computedData: PropTypes.object,
  additionalState: PropTypes.object,
  disabled: PropTypes.bool,
  dragHandleProps: PropTypes.any,
  getSubFiledOptions: PropTypes.func.isRequired,
  hasPosition: PropTypes.boolean,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isLast: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  replace_type: PropTypes.string,
  to: PropTypes.string,
  updateFieldData: PropTypes.func.isRequired,
  what: PropTypes.string,
};
