/* eslint-disable react/prop-types */
import React from 'react';
import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import {
  FeedExportsStatusCell,
  GenericCell,
  IntegerCell,
  LinkCell,
  MainIdentifierWithSwitcherCell,
  TextCell,
  TextContent,
  TimeAgoCell,
  TimeAgoCellContent,
  StateCell,
} from './cells';
import { LinkEdit } from './editable';
import imageEditor from './imageEditor';
import labels from './labels';
import conditions from './conditions';
import { t } from '../../../i18n';
import { modalDataUrlOpener } from '../../RemoteModal';

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: [
      'id',
      'active',
      'disableStatus',
      'name',
      'activateLink',
      'editLink',
      'duplicateLink',
      'showLink',
      'deleteLink',
      'downloadUrl',
      'icon',
      'regenerateTokenLink',
      'regenerateDataLink',
      'generated',
      'typeName',
    ],
    required: true,
    cell: MainIdentifierWithSwitcherCell,
    order: 'name',
    editable: LinkEdit,
    editButtonAsProp: true,
    editQuery: ({ id }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') changes.push(`active: "${enabled ? 'running' : 'paused'}"`);
      return gql`mutation UpdateFeedExports($text: String) { updateFeedExport(id: ${id}, name: $text, ${changes.join(
        ','
      )} ) {feedExport { id name active imageGeneratorStatus feedExportState updatedAt } errors updateNotificationText }  }`;
    },
    width: '300px',
    header: () => t('views.feed_export.feed_name'),
    resolveChildren: ({
      id,
      name,
      disableStatus,
      active,
      activateLink,
      editLink,
      showLink,
      duplicateLink,
      downloadUrl,
      deleteLink,
      icon,
      regenerateTokenLink,
      regenerateDataLink,
      generated,
      typeName,
    }) => ({
      id,
      icon: `${icon}`,
      size: '20px',
      color: '#596774',
      tooltip: [typeName, 'export'].join(' '),
      status: active,
      disableStatus,
      name,
      text: name,
      link: showLink,
      'data-test-id': `dropdown-action-${name}`,
      links: [
        downloadUrl && {
          href: downloadUrl,
          children: t('views.organizations.feed_exports.table_item.to_download'),
          icon: 'download',
          'data-test-id': `download-${name}`,
        },
        editLink && {
          href: editLink,
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        {
          href: duplicateLink,
          size: 'fullwithGrid',
          children: t('views.campaign_settings.index.duplicate'),
          'data-test-id-duplicate': name,
          icon: 'duplicate',
        },
        active === 'stopped' &&
          activateLink && {
            href: '#',
            children: t('feed_exports.activate'),
            'data-test-id-activate': name,
            icon: 'wiz-sync',
            ...modalDataUrlOpener({
              url: activateLink,
              size: 'medium',
            }),
          },
        regenerateDataLink &&
          generated && {
            href: regenerateDataLink,
            children: t('views.organizations.feed_exports.table_item.regenerate_data_link', {
              default: 'Regenerate data',
            }),
            icon: 'refresh',
            'data-method': 'get',
            'data-container': 'body',
            'data-test-id-regenerate-data': name,
            rel: 'nofollow',
          },
        regenerateTokenLink &&
          generated && {
            href: regenerateTokenLink,
            children: t('views.organizations.feed_exports.table_item.regenerate_link', {
              default: 'A new link will be generated by clicking this button',
            }),
            danger: true,
            icon: 'generate-new-url',
            'data-method': 'get',
            'data-confirm': t('views.organizations.feed_exports.table_item.regenerate_modal_text', {
              default: 'The current link will be deleted and won’t work if used anywhere.',
            }),
            'data-commit': t('views.organizations.feed_exports.table_item.regenerate_modal_commit', {
              default: 'Generate a new link',
            }),
            'data-container': 'body',
            'data-test-id-regenerate-token': name,
            rel: 'nofollow',
          },
        deleteLink && {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          danger: true,
          icon: 'trash',
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
      ].filter(f => !!f),
    }),
    fixed: 'left',
  }),
  ...setupColumn({
    id: 'feedExportState',
    graphqlFields: ['feedExportState', 'generatedAt', 'generationError'],
    order: 'feed_export_state',
    cell: StateCell,
    header: () => t('views.feed_export.status'),
    resolveChildren: ({ feedExportState, generatedAt, generationError }) => ({
      state: feedExportState,
      lastUpdatedAt: generatedAt,
      kind: 'feed_export',
      error: generationError,
    }),
  }),
  ...setupColumn({
    id: 'dataSourceName',
    graphqlFields: { dataSource: ['name', 'showLink', 'icon'] },
    order: 'data_source.name',
    cell: LinkCell,
    header: () => t('views.feed_export.data_source_name'),
    resolveChildren: ({ dataSource }) => ({
      icon: dataSource?.icon,
      text: dataSource?.name,
      link: dataSource?.showLink,
    }),
  }),
  ...setupColumn({
    id: 'typeName',
    graphqlFields: ['templateType', 'subType', 'typeName'],
    order: 'template_type',
    cell: TextCell,
    header: () => t('views.feed_export.template_type'),
    resolveChildren: ({ typeName, subType }) => subType || typeName,
  }),
  ...setupColumn({
    id: 'feedItemsCount',
    order: 'feed_items_count',
    cell: IntegerCell,
    header: () => t('views.feed_export.items_count'),
  }),
  ...setupColumn({
    id: 'generatedAt',
    graphqlFields: ['wasEverGenerated', 'generatedAt'],
    cell: GenericCell,
    order: 'generated_at',
    reverseOrder: true,
    fixed: 'right',
    header: () => t('views.feed_export.last_generation'),
    resolveChildren: ({ wasEverGenerated, generatedAt }) =>
      wasEverGenerated ? (
        <TimeAgoCellContent>{generatedAt}</TimeAgoCellContent>
      ) : (
        <TextContent> {t('views.feed_export.never_generated')}</TextContent>
      ),
  }),
  ...setupColumn({
    id: 'generating',
    graphqlFields: ['active', 'activateLink', 'generating', 'downloadUrl', 'feedExportState', 'wasEverGenerated'],
    pollingInterval: { interval: 5000, shouldSync: ({ generating }) => generating },
    cell: FeedExportsStatusCell,
    header: () => t('views.feed_export.feed_export_url'),
    resolveChildren: ({ active, activateLink, generating, downloadUrl, feedExportState, wasEverGenerated }) => ({
      active,
      activateLink,
      generating,
      defaultValue: downloadUrl,
      feedExportState,
      wasEverGenerated,
    }),
  }),
  ...imageEditor('feed_export'),
  ...conditions,
  ...labels,
  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    cell: TimeAgoCell,
    reverseOrder: true,
    header: () => t('attributes.created_at'),
  }),
  ...setupColumn({
    id: 'startGeneratingAt',
    order: 'start_generating_at',
    cell: TimeAgoCell,
    reverseOrder: true,
    header: () => t('attributes.start_generating_at'),
  }),
  ...setupColumn({
    id: 'updatedAt',
    order: 'updated_at',
    cell: TimeAgoCell,
    reverseOrder: true,
    header: () => t('attributes.updated_at'),
  }),
  ...setupColumn({
    id: 'lastDownloadedAt',
    cell: TimeAgoCell,
    reverseOrder: true,
    header: () => t('attributes.last_downloaded_at'),
  }),
};

export default mapping;
