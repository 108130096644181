import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Heading, Text, Icon, Button, cssVariables } from '../components';
import { t, tHtml } from '../i18n';
import FbAdList from '../fb_advantage_ads_form/FbAdList';
import FbAdsImporter from '../components/fb_ads_importer/FbAdsImporter';

const FbAdvantagePlusAdsOverview = ({
  organizationId,
  campaignSettingId,
  campaignName,
  createBtnProps,
  showTipsByDefault,
}) => {
  const [showTips, setShowTips] = useState(showTipsByDefault);
  const [hasAdsToImport, setHasAdsToImport] = useState(false);
  const [version, setVersion] = useState(0); // for triggering refetch between adlist and importer
  return (
    <>
      <Row className="mb-24" justifyBetween center>
        <Col shrink>
          <Heading spacing={0}>{t('fb_advantage_plus.ads_overview_page.title', { default: 'Ads' })}</Heading>
        </Col>
        <Col shrink>
          <Button kind="tertiary" onClick={() => setShowTips(!showTips)} icon="info">
            {showTips
              ? t('fb_advantage_plus.ads_overview_page.hide_tips', { default: 'Hide tips' })
              : t('fb_advantage_plus.ads_overview_page.show_tips', { default: 'Show tips' })}
          </Button>
        </Col>
      </Row>
      {showTips && (
        <div className="Tile Tile-content mb-32">
          <Row center className="mb-16">
            <Col shrink style={{ marginRight: '12px' }}>
              <Icon kind="info" size="20px" color={cssVariables.iconAccent} />
            </Col>
            <Col>
              <Heading spacing={0} size="xl">
                {t('fb_advantage_plus.ads_overview_page.tips_title', { default: 'How to create successful ads' })}
              </Heading>
            </Col>
          </Row>
          <ul className="List List--bullets">
            <li className="List-item">
              <Text>
                {tHtml('fb_advantage_plus.ads_overview_page.tips.item_1', {
                  default:
                    '<b>Use high-performing ads:</b> Import your top-performing ads into the Advantage+ Campaign to maximize their impact.',
                })}
              </Text>
            </li>
            <li className="List-item">
              <Text>
                {tHtml('fb_advantage_plus.ads_overview_page.tips.item_2', {
                  default:
                    '<b>Diversify ad formats:</b> Include at least three different types of ads, like catalog ads. Try to have at least 10 ads and no more than 150.',
                })}
              </Text>
            </li>
            <li className="List-item">
              <Text>
                {tHtml('fb_advantage_plus.ads_overview_page.tips.item_3', {
                  default:
                    '<b>Test different creatives and messages:</b> Test various creatives and messages to enable Meta to identify what resonates most with your audience.',
                })}
              </Text>
            </li>
            <li className="List-item">
              <Text>
                {tHtml('fb_advantage_plus.ads_overview_page.tips.item_4', {
                  default:
                    '<b>Mix different ad types: </b> Using a mix of images, videos, and Advantage+ catalog ads in one campaign can increase your return on ad spend by up to 8 %.',
                })}
              </Text>
            </li>
          </ul>
        </div>
      )}
      <FbAdList
        organizationId={organizationId}
        campaignSettingId={campaignSettingId}
        createBtnProps={createBtnProps}
        version={version}
        setVersion={setVersion}
        hasAdsToImport={hasAdsToImport}
      />
      <FbAdsImporter
        organizationId={organizationId}
        campaignSettingId={campaignSettingId}
        version={version}
        setVersion={setVersion}
        campaignName={campaignName}
        setHasAdsToImport={setHasAdsToImport}
      />
    </>
  );
};

FbAdvantagePlusAdsOverview.propTypes = {
  organizationId: PropTypes.number.isRequired,
  campaignSettingId: PropTypes.number.isRequired,
  campaignName: PropTypes.string.isRequired,
  createBtnProps: PropTypes.object,
  showTipsByDefault: PropTypes.bool.isRequired,
};

export default FbAdvantagePlusAdsOverview;
