/* eslint-disable no-param-reassign */
import moment from 'moment';
import { throttle } from 'lodash';
import storage from './storage';

const DO_NOT_SHOW_NEXT_TOOLBOX_FOR_X_SECONDS = 5;

const closeToolbox = (toolbox, stopSettingPosition) => {
  toolbox.classList.remove('active');
  const data = JSON.parse(storage.get('hiddenToolboxes') || '[]') || [];
  data.push(toolbox.dataset.toolboxName);
  storage.set('hiddenToolboxes', JSON.stringify(data));
  storage.set(
    'hiddenToolboxesUntil',
    moment()
      .add(DO_NOT_SHOW_NEXT_TOOLBOX_FOR_X_SECONDS, 'seconds')
      .format()
  );
  stopSettingPosition();
};

window.clearHiddenToolboxes = () => {
  storage.set('hiddenToolboxes', JSON.stringify([]));
  storage.set(
    'hiddenToolboxesUntil',
    moment()
      .subtract(DO_NOT_SHOW_NEXT_TOOLBOX_FOR_X_SECONDS, 'seconds')
      .format()
  );
  window.location.reload();
};

const setToolboxPosition = (toolbox, toolboxTarget) => {
  const targetPosition = toolboxTarget.dataset.toolboxPosition;
  const targetParam = toolboxTarget.getBoundingClientRect();
  let windowScroll = window.scrollY;

  if (toolboxTarget.dataset.toolboxNavbar === 'true') {
    windowScroll = 0;
  }

  toolbox.style.top = `${windowScroll + targetParam.top + targetParam.height / 2 - 36}px`;

  if (targetPosition === 'left') {
    toolbox.style.left = `${targetParam.left - toolbox.offsetWidth - 15}px`;
    toolbox.classList.add('Toolbox--left');
  } else if (targetPosition === 'bottom-end') {
    toolbox.classList.add('Toolbox--bottomEnd');
    toolbox.style.top = `${targetParam.top + targetParam.height + 15}px`;

    toolbox.style.left = `${targetParam.right - toolbox.offsetWidth + 10}px`;
  } else {
    toolbox.style.left = `${targetParam.left + targetParam.width + 15}px`;
  }
};

const initButtons = (toolbox, toolboxTarget, stopSettingPosition) => {
  const toolboxTryButton = toolbox.getElementsByClassName('js-toolbox-try-button')[0];
  const toolboxLearnButton = toolbox.getElementsByClassName('js-toolbox-learn-button')[0];
  const toolboxCloseButton = toolbox.getElementsByClassName('js-close-toolbox')[0];

  toolboxCloseButton.addEventListener('click', () => {
    closeToolbox(toolbox, stopSettingPosition);
  });

  if (toolboxTarget.dataset.toolboxTryLink) {
    toolboxTryButton.setAttribute('href', toolboxTarget.dataset.toolboxTryLink);
    toolboxTryButton.classList.remove('hidden');
    toolboxTryButton.addEventListener('click', () => {
      closeToolbox(toolbox, stopSettingPosition);
    });
  }

  if (toolboxTarget.dataset.toolboxLearn !== 'false') {
    toolboxLearnButton.classList.remove('hidden');
    toolboxLearnButton.setAttribute('href', toolboxTarget.dataset.toolboxLearn);
  }
};

const initToolboxes = () => {
  const toolboxes = document.querySelectorAll('[data-toolbox-name]');
  let shown = false;
  if (
    storage.get('hiddenToolboxesUntil') &&
    storage.get('hiddenToolboxesUntil').length > 0 &&
    moment(storage.get('hiddenToolboxesUntil')) > moment()
  ) {
    return;
  }

  if (toolboxes && toolboxes.length > 0) {
    Array.from(toolboxes)
      .sort((a, b) => parseInt(a.dataset.toolboxCounter, 10) - parseInt(b.dataset.toolboxCounter, 10))
      .forEach(toolbox => {
        if (
          !shown &&
          (!storage.get('hiddenToolboxes') ||
            !JSON.parse(storage.get('hiddenToolboxes')).includes(toolbox.dataset.toolboxName))
        ) {
          const toolboxTarget = document.querySelector(`[data-toolbox="${toolbox.dataset.toolboxName}"]`);

          if (toolboxTarget) {
            const visible = !!(
              toolboxTarget.offsetWidth ||
              toolboxTarget.offsetHeight ||
              toolboxTarget.getClientRects().length
            );

            if (visible) {
              toolbox.classList.add('active');
              shown = true;

              if (toolboxTarget.dataset.toolboxNavbar === 'true') {
                const sideNavbar = document.getElementsByClassName('js-navbar-side')[0];
                const toggleNavbarBtn = document.getElementsByClassName('js-navbar-toggle')[0];

                toolbox.classList.add('Toolbox--navbar');

                toggleNavbarBtn.addEventListener('click', () => {
                  setTimeout(() => setToolboxPosition(toolbox, toolboxTarget), 300); // navbar opening transition time
                });

                sideNavbar.addEventListener('scroll', () => {
                  setToolboxPosition(toolbox, toolboxTarget);
                });
              }

              window.addEventListener('resize', () => {
                setToolboxPosition(toolbox, toolboxTarget);
              });

              setToolboxPosition(toolbox, toolboxTarget);

              const setToolboxPosAnonymousFunc = throttle(() => {
                setToolboxPosition(toolbox, toolboxTarget);
              }, 10);

              const closestOverflowWrapper = toolboxTarget?.closest('.Grid--overflowWrapper') || document;
              closestOverflowWrapper.addEventListener('scroll', setToolboxPosAnonymousFunc);

              const stopSettingPosition = () => {
                closestOverflowWrapper.removeEventListener('scroll', setToolboxPosAnonymousFunc);
              };

              initButtons(toolbox, toolboxTarget, stopSettingPosition);
            }
          }
        }
      });
  }
};

window.initToolboxes = initToolboxes;
window.addEventListener('load', initToolboxes);
setTimeout(() => initToolboxes(), 4000);
