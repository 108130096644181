/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { Icon, Row, Col, Button, TileArrowDelimiter } from '../components';

class GoogleFreeShippingThreshold extends React.PureComponent {
  static propTypes = {
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.string,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    country: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    country: this.props.country || '',
    price: this.props.price || '',
  };

  handleCountry = ({ target: { value } }) => {
    if (value !== this.state.country) {
      this.setState({ country: value });
      this.props.updateFieldData(this.props.id, { country: value });
      updatePreview();
    }
  };

  handlePrice = ({ target: { value } }) => {
    if (value !== this.state.price) {
      this.setState({ price: value });
      this.props.updateFieldData(this.props.id, { price: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { price, country } = this.state;

    return (
      <Row padding="l" center data-test-id="free_shipping_threshold" className="mb-8">
        <Col width="25%" data-test-id="free-shipping-threshold-country" className="mr-8">
          <PlaceholderInput
            defaultValue={country}
            onChange={this.handleCountry}
            placeholder={t('views.free_shipping_threshold.fields.country', { default: 'Country' })}
            {...getSubFiledOptions('country')}
          />
        </Col>
        <Col grow data-test-id="free-shipping-threshold-price">
          <PlaceholderInput
            defaultValue={price}
            onChange={this.handlePrice}
            placeholder={t('views.free_shipping_threshold.fields.price_threshold', { default: 'Price threshold' })}
            {...getSubFiledOptions('price')}
          />
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.free_shipping_threshold.example')} <span>${t(
            'views.free_shipping_threshold.example_text'
          )}</span>`}
          data-field={`free_shipping_threshold.${country}`}
        >
          <div className="Text">
            {t('views.free_shipping_threshold.example')} <span>{t('views.free_shipping_threshold.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default GoogleFreeShippingThreshold;
