/* eslint-disable no-trailing-spaces */
import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';
import { tHtml } from '../i18n';

function getFormFromElement(element) {
  const el = $(element);
  const formId = el.data('previewModalFormId') || el.closest('[data-form-id]').data('formId');
  return (formId && formId.length > 2 && $(formId)[0]) || el.closest('form')[0];
}

export const enhancePreviewLinkByConditions = (url, element) => {
  if (!element) {
    return url;
  }
  const form = getFormFromElement(element);

  if (form) {
    const itemGroups = $(form)
      .serialize()
      .split('&')
      .filter(i => i.indexOf('_additional_conditions_') !== -1)
      .join('&');
    return [url, itemGroups].filter(f => f && f.length > 0).join('&');
  }
  return url;
};

class PreviewDataModal extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
    className: PropTypes.string,
    name: PropTypes.any,
    onClose: PropTypes.func,
    previewLink: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  };

  state = {
    preview: false,
    conditions: [],
  };

  onClick = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ preview: true });
    return false;
  };

  render() {
    const { children, onClose, className, name, previewLink } = this.props;
    const { preview } = this.state;

    if (!previewLink) {
      return null;
    }

    const form = getFormFromElement(this.anchorElement);
    if (form) {
      this.conditions = $(form).serializeArray();
    }

    return (
      <React.Fragment>
        <a
          href="#"
          className={className}
          onClick={this.onClick}
          ref={el => {
            this.anchorElement = el;
          }}
        >
          {children}
        </a>
        {preview && (
          <Modal
            heading={tHtml('preview_data', { field: name })}
            size="medium"
            onClose={() => {
              this.setState({ preview: false });
              if (typeof onClose === 'function') {
                onClose();
              }
            }}
            url={enhancePreviewLinkByConditions(previewLink, this.anchorElement)}
            conditions={this.conditions}
            type="preview"
          />
        )}
      </React.Fragment>
    );
  }
}

export default PreviewDataModal;
