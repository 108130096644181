/* eslint-disable no-undef */
export default class WebWorkerEnabler {
  constructor(workerCode) {
    let blob;
    try {
      blob = new Blob([workerCode], { type: 'application/javascript' });
    } catch (e) {
      // Backwards-compatibility
      window.BlobBuilder = window.BlobBuilder || window.WebKitBlobBuilder || window.MozBlobBuilder;
      blob = new BlobBuilder();
      blob.append(workerCode);
      blob = blob.getBlob();
    }

    return new Worker(URL.createObjectURL(blob));
  }
}
