import React from 'react';
import { Asset } from './FBAdImageSelect';

export const transformData = (data = []) =>
  data
    .filter(({ isImageUrl }) => isImageUrl)
    .reduce(
      (out, variable) =>
        variable.imagesCount === 1
          ? [
              ...out,
              {
                label: <Asset name={variable.placeholderName} url={variable.previewImageUrls[0]} />,
                originalLabel: variable.placeholderName,
                name: variable.placeholderName,
                url: variable.previewImageUrls[0],
                editLink: variable.editLink,
                duplicateCopyLink: variable.duplicateCopyLink,
                value: `_${variable.placeholderName}_`,
              },
            ]
          : Array.from(Array(variable.imagesCount)).reduce(
              (variableOut, el, index) => [
                ...variableOut,
                {
                  name: `${variable.placeholderName} #${index + 1}`,
                  label: <Asset name={variable.placeholderName} url={variable.previewImageUrls[index]} />,
                  originalLabel: variable.placeholderName,
                  url: variable.previewImageUrls[index],
                  editLink: variable.editLink,
                  duplicateCopyLink: variable.duplicateCopyLink,
                  value: `_${variable.placeholderName}_${index + 1}_`,
                },
              ],
              out
            ),
      []
    );

export const transformToSlideshowData = (data = [], newImageLink, notify) =>
  data
    .filter(({ isImageUrl }) => isImageUrl)
    .reduce(
      (out, variable) =>
        variable.imagesCount === 1
          ? [
              ...out,
              {
                label: variable.placeholderName,
                url: variable.previewImageUrls[0],
                editLink: variable.editLink,
                duplicateCopyLink: variable.duplicateCopyLink,
                newImageLink: `${newImageLink}?image_layer=_${variable.placeholderName}_`,
                value: `_${variable.placeholderName}_`,
                notify,
              },
            ]
          : Array.from(Array(variable.imagesCount)).reduce(
              (variableOut, el, index) => [
                ...variableOut,
                {
                  label: `${variable.placeholderName} #${index + 1}`,
                  url: variable.previewImageUrls[index],
                  editLink: variable.editLink,
                  duplicateCopyLink: variable.duplicateCopyLink,
                  newImageLink: `${newImageLink}?image_layer=_${variable.placeholderName}_${index + 1}_`,
                  value: `_${variable.placeholderName}_${index + 1}_`,
                  notify,
                },
              ],
              out
            ),
      []
    );

export const filterDataObject = (arr, val) => {
  if (!arr) return null;
  return arr.find(x => x.value === val);
};
