/* eslint-disable */
const equationOptions = [
  {
    key: 'Select condition',
    value: '',
    type: 'all',
  },
  {
    key: 'contains',
    value: '~=',
    type: 'string',
  },
  {
    key: "doesn't contain",
    value: '~!',
    type: 'string',
  },
  {
    key: 'matches regex',
    value: '=~',
    type: 'string',
  },
  {
    key: "doesn't match regex",
    value: '!~',
    type: 'string',
  },
  {
    key: '=',
    value: '==',
    type: 'all',
  },
  {
    key: '≠',
    value: '!=',
    type: 'all',
  },
  {
    key: '<',
    value: '<',
    type: 'number',
  },
  {
    key: '≤',
    value: '<=',
    type: 'number',
  },
  {
    key: '>',
    value: '>',
    type: 'number',
  },
  {
    key: '≥',
    value: '>=',
    type: 'number',
  },
];
export default equationOptions;
