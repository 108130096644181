import deepMerge from 'deepmerge';

export function __isSplittableKey(key) {
  return (
    key.includes('.') &&
    !key.includes(' ') &&
    key.lastIndexOf('.') !== key.length - 1
  );
}

export function createStructuredJSON(messages) {
  let result = {};
  if (messages && typeof messages === 'object') {
    // we only want to incluence objects
    Object.keys(messages).forEach(key => {
      const value = messages[key];
      if (key) {
        if (__isSplittableKey(key)) {
          // can we broke it into smaller objects?
          result = deepMerge(result, __structureObject(key.split('.'), value));
        } else {
          // if not, let's test the value - it may contain some splittable keys
          result[key] = createStructuredJSON(value);
        }
      }
    });
  } else {
    // otherwise just set the value
    result = messages;
  }
  return result;
}

export function __structureObject(keys, val) {
  const obj = {};
  if (keys.length !== 1) {
    const currentKey = keys.shift(); // let's take the head and start recursion
    if (currentKey) {
      obj[currentKey] = __structureObject(keys, val);
    }
  } else {
    // we already have the tail
    obj[keys[0]] = val; // so let's store the value
  }
  return obj;
}
