import React, { forwardRef, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { Icon } from '../components';
import Link from './Link';

export const Text = forwardRef(
  (
    {
      bold,
      className,
      color,
      children,
      inheritColor,
      inheritSize,
      lineTrought,
      medium,
      nowrap,
      semibold,
      size,
      spaced,
      tag,
      truncated,
      underline,
      uppercase,
      withCheck,
      withLine,
      ...rest
    },
    ref
  ) => {
    const Component = tag || 'span';

    return (
      <Component
        ref={ref}
        className={cs('Text', className, {
          [`Text--${color}`]: color,
          [`Text--${size}`]: size,
          'Text--withLine': withLine,
          'Text--bold': bold,
          'Text--semibold': semibold,
          'Text--medium': medium,
          'Text--spaced': spaced,
          'Text--uppercase': uppercase,
          'Text--withCheck': withCheck,
          'Text--lineTrought': lineTrought,
          'Text--underline': underline,
          'Text--inheritColor': inheritColor,
          'Text--inheritSize': inheritSize,
          'text-nowrap': nowrap,
          'Text--truncated': truncated,
        })}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);
Text.propTypes = {
  bold: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  children: PropTypes.any,
  inheritColor: PropTypes.bool,
  inheritSize: PropTypes.bool,
  lineTrought: PropTypes.bool,
  medium: PropTypes.bool,
  nowrap: PropTypes.bool,
  semibold: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'default', 'md', 'lg', 'xl']),
  tag: PropTypes.string,
  underline: PropTypes.bool,
  withCheck: PropTypes.bool,
  withLine: PropTypes.bool,
};

export const ClampedText = ({ children, className, lines = 2, textProps, inheritBackgroundColor }) => {
  const textRef = useRef(null);
  const [showMore, setShowMore] = useState(false);
  const [showLink, setShowLink] = useState(false);

  useEffect(() => {
    setShowMore(false);
    if (textRef.current) {
      setShowLink(textRef.current.clientHeight < textRef.current.scrollHeight);
    }
  }, [textRef, setShowMore, setShowLink]);

  return (
    <div
      className={cs('mt-4 mb-0 pos-relative', className, {
        'background-inherit': inheritBackgroundColor,
      })}
      style={{ WebkitLineClamp: !showMore ? lines : 'unset' }}
    >
      <Text ref={textRef} {...textProps} className="clamped-text">
        {children}
      </Text>
      {showLink && (
        <Text
          {...textProps}
          className={cs({
            'pos-absolute': !showMore,
            'background-white': !inheritBackgroundColor,
            'background-inherit': inheritBackgroundColor,
          })}
          style={{ bottom: 0, right: 0 }}
        >
          {!showMore && <span className="pr-4">...</span>}
          <Link onClick={() => setShowMore(!showMore)}>{showMore ? 'Show less' : 'Show more'}</Link>
        </Text>
      )}
    </div>
  );
};
ClampedText.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  lines: PropTypes.number,
  textProps: PropTypes.object,
  inheritBackgroundColor: PropTypes.bool,
};

export const TextWithLine = props => <Text {...props} withLine />;
TextWithLine.propTypes = Text.propTypes;

export const TextWithNumberAndCheck = ({ number, isChecked, children, className, ...rest }) => (
  <Text {...rest} withCheck className={cs(className, { isChecked })}>
    <span className="Text-order mr-4">{number}</span>
    <Icon kind="active" size="14px" color="#3ED95E" className="mr-4" />
    <span>{children}</span>
  </Text>
);
TextWithNumberAndCheck.defaultProps = {
  isChecked: false,
};

TextWithNumberAndCheck.propTypes = {
  className: PropTypes.string,
  children: PropTypes.string,
  isChecked: PropTypes.bool,
  number: PropTypes.string,
};

export const Section = ({ children, color, size, ...rest }) => (
  <div className={cs('Text', 'mb-4', { [`Text--${color}`]: color, [`Text--${size}`]: size })} {...rest}>
    {children}
  </div>
);

Section.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'default']),
};

export const SectionInfo = props => <Section {...props} size="sm" color="gray" />;
SectionInfo.propTypes = Section.propTypes;

export const Hr = ({ className, spacing }) => <hr className={cs(className, `mb-${spacing}`)} />;
Hr.defaultProps = {
  spacing: 8,
};

Hr.propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.oneOf([0, 4, 8, 12, 16, 20, 24, 28, 32, 40]),
};

export const Heading = ({
  children,
  tag,
  className,
  spacing = 16,
  sticky,
  size = '3xl',
  uppercase,
  spaced,
  ...rest
}) => {
  const Component = tag || 'h1';

  return (
    <Component
      className={cs('Heading', `Heading--${size}`, `mb-${spacing}`, className, {
        'Heading--sticky': sticky,
        'Heading--uppercase': uppercase,
        'Heading--spaced': spaced,
      })}
      {...rest}
    >
      {children}
    </Component>
  );
};

Heading.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  size: PropTypes.string,
  spaced: PropTypes.bool,
  spacing: PropTypes.oneOf([0, 4, 6, 8, 12, 16, 20, 24, 28, 32, 40]),
  sticky: PropTypes.bool,
  tag: PropTypes.string,
  uppercase: PropTypes.bool,
};

export const PageHeading = ({ children, ...rest }) => (
  <Heading {...rest} tag="h1" size="3xl">
    {children}
  </Heading>
);

PageHeading.propTypes = Heading.propTypes;

export const SectionHeading = ({ children, ...rest }) => (
  <Heading {...rest} tag="h2" size="2xl">
    {children}
  </Heading>
);

SectionHeading.propTypes = Heading.propTypes;

export const SmallHeading = ({ children, spacing = 8, ...rest }) => (
  <Heading {...rest} tag="h3" size="lg" spacing={spacing}>
    {children}
  </Heading>
);

SmallHeading.propTypes = Heading.propTypes;

export const ExtraSmallHeading = ({ children, spacing = 8, ...rest }) => (
  <Heading {...rest} tag="h4" size="md" spacing={spacing}>
    {children}
  </Heading>
);

ExtraSmallHeading.propTypes = Heading.propTypes;
