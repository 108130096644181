import React from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Text } from '../index';
import labelIcon from '../../../../assets/images/fb_ad_preview/label_icon.png';

const FbPagePreview = ({
  url,
  pageName,
  label,
  theme = 'dark',
  imageSize,
  nameSize,
  labelSize,
  nameBold,
  withIcon,
}) => (
  <>
    <Row padding="s" center>
      <Col shrink>
        <div
          className={cs('FbPreview-fbPageImage', { 'FbPreview-fbPageImage--noImage': !url })}
          style={{ width: imageSize, height: imageSize }}
        >
          {url && <img src={url} alt={t('fb_ad_preview.facebook_page', { default: 'Facebook page' })} />}
        </div>
      </Col>
      {(pageName || label) && (
        <Col shrink>
          <div className="FbPreview-fbPageName">
            {pageName && (
              <Text
                bold={nameBold}
                semibold={!nameBold}
                style={{ display: 'block', color: theme === 'dark' ? '#050505' : '#fff', fontSize: nameSize }}
              >
                {pageName}
              </Text>
            )}
            {label && (
              <Row center>
                <Text style={{ display: 'block', color: theme === 'dark' ? '#ACB0B8' : '#fff', fontSize: labelSize }}>
                  {label}
                </Text>
                {withIcon && (
                  <>
                    <span className="FbPreview-labelIcon" />
                    <div
                      style={{
                        width: '5px',
                        height: '5px',
                        marginLeft: '2px',
                        background: `url(${labelIcon})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                      }}
                    />
                  </>
                )}
              </Row>
            )}
          </div>
        </Col>
      )}
    </Row>
  </>
);

FbPagePreview.propTypes = {
  url: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  theme: PropTypes.oneOf(['dark', 'light']),
  imageSize: PropTypes.string,
  nameSize: PropTypes.string,
  labelSize: PropTypes.string,
  nameBold: PropTypes.bool,
  withIcon: PropTypes.bool,
};

export default FbPagePreview;
