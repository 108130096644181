import React, { Component } from 'react';
import objectTypes from './objectTypes';

class SVGRenderer extends Component {
  static defaultProps = {
    onMouseOver: () => {},
    preview: false,
    previewObjects: {},
  };

  renderObject = (object, index) => {
    const { disableHiding, objectRefs, onMouseOver, previewObjects, preview } = this.props;
    const Renderer = objectTypes[object.type];
    const objectData = { ...object, ...((preview && previewObjects[object.uuid]) || {}) };

    if (object.hideLayer && !disableHiding) {
      return (
        <g style={{ visibility: 'hidden' }}>
          <Renderer
            onRender={ref => {
              objectRefs[index] = ref;
            }}
            object={objectData}
            key={object.uuid}
            index={index}
          />
        </g>
      );
    }

    if (object.lockLayer && !disableHiding) {
      return (
        <g style={{ pointerEvents: 'none' }}>
          <Renderer
            onRender={ref => {
              objectRefs[index] = ref;
            }}
            object={objectData}
            key={object.uuid}
            index={index}
          />
        </g>
      );
    }

    return (
      <Renderer
        onRender={ref => {
          objectRefs[index] = ref;
        }}
        onClick={!object.lockLayer && onMouseOver.bind(this, index)}
        object={objectData}
        key={object.uuid}
        index={index}
      />
    );
  };

  render() {
    const { className, background, objects, svgStyle, canvas, onMouseDown, onRender, scale } = this.props;
    const { width, height, canvasOffsetX, canvasOffsetY } = canvas;

    const style = {
      ...canvas,
      ...styles.canvas,
      ...styles.grid,
      backgroundSize: scale ? `${20 / scale}px` : 'auto',
      ...svgStyle,
      marginTop: canvasOffsetY,
      marginLeft: canvasOffsetX,
      height: `${height}px`,
      width: `${width}px`,
    };

    const svgStyleElement = background ? { backgroundColor: background } : {};

    return (
      <div style={style} className={className}>
        <svg
          onMouseDown={onMouseDown}
          ref={onRender}
          width={width}
          viewbox={`0 0 ${width} ${height}`}
          height={height}
          style={svgStyleElement}
          isRoot
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          {objects.map(this.renderObject)}
        </svg>
      </div>
    );
  }
}

export const styles = {
  canvas: {
    backgroundSize: 400,
  },
  grid: {
    backgroundImage:
      'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5' +
      'vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+CjxyZWN0IHdpZHRoPSIyMCIgaGVpZ2h0' +
      'PSIyMCIgZmlsbD0iI2ZmZiI+PC9yZWN0Pgo8cmVjdCB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIGZpbGw9I' +
      'iNGN0Y3RjciPjwvcmVjdD4KPHJlY3QgeD0iMTAiIHk9IjEwIiB3aWR0aD0iMTAiIGhlaWdodD0iMTAiIG' +
      'ZpbGw9IiNGN0Y3RjciPjwvcmVjdD4KPC9zdmc+)',
  },
};

export default SVGRenderer;
