import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import cs from 'classnames';
import { Heading, InfoBox, EmptyState, Text } from '../index';
import { FbSingleAdPreview, FbCarouselAdPreview } from './index';
import { t } from '../../i18n';
import { mapDataToAdPreview } from './utils';

const FbAdPreviewMain = ({ options }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const organizationId = options?.organization_id;
  const campaignSettingId = options?.campaign_setting_id;

  const isSelectedAccount = data?.fbPageName;

  let showSinglePreview = false;
  let showCarouselPreview = false;

  if (data?.creativeSource === 'manual_upload') {
    if (data?.format === 'single_image_or_video') {
      if (isSelectedAccount && data?.feedsMedia && data?.reelsMedia && data?.rightColumnMedia) {
        showSinglePreview = true;
      }
    } else if (data?.format === 'carousel_images_multi_items') {
      if (isSelectedAccount && data?.carouselMedia?.length > 1) {
        showCarouselPreview = true;
      }
    }
  }

  if (data?.creativeSource === 'catalog') {
    if (data?.format === 'single_image_or_video') {
      if (isSelectedAccount && data?.catalogId && data?.catalogMedia?.length > 0) {
        showSinglePreview = true;
      }
    } else if (data?.format === 'carousel_images_multi_items') {
      if (isSelectedAccount && data?.catalogId && data?.catalogMedia?.length > 1) {
        showCarouselPreview = true;
      }
    }
  }

  const showPreview = showSinglePreview || showCarouselPreview;

  const handleFormChange = useCallback(
    debounce(() => {
      setLoading(true);
      setError(null);
      const formData = $('.simple_form').serialize();

      $.ajax({
        url: `/organizations/${organizationId}/campaign_settings/${campaignSettingId}/fb_a_d_s/preview_ads`,
        type: 'POST',
        data: formData,
        dataType: 'json',
        success(response) {
          setLoading(false);
          setData(mapDataToAdPreview(response));
        },
        error(xhr, status, e) {
          setLoading(false);
          setError(e);
        },
      });
    }, 1000),
    [organizationId, campaignSettingId]
  );

  useEffect(() => {
    const formElement = document.querySelector("form[data-test-id='fb_adtext_form']");

    if (formElement) {
      const debouncedHandleFormChange = handleFormChange;

      // Event listeners for form input and change events
      formElement.addEventListener('input', debouncedHandleFormChange);
      formElement.addEventListener('change', debouncedHandleFormChange);

      // MutationObserver for hidden inputs
      const observer = new window.MutationObserver(mutationsList => {
        // eslint-disable-next-line no-restricted-syntax
        for (const mutation of mutationsList) {
          if (mutation.type === 'attributes' && mutation.attributeName === 'value') {
            debouncedHandleFormChange();
          } else if (mutation.type === 'childList') {
            mutation.addedNodes.forEach(node => {
              if (node.nodeType === 1) {
                if (node.matches("input[type='hidden']")) {
                  observer.observe(node, { attributes: true, attributeFilter: ['value'] });
                }
              }
            });

            mutation.removedNodes.forEach(node => {
              if (node.nodeType === 1 && node.matches("input[type='hidden']")) {
                debouncedHandleFormChange();
              }
            });
          }
        }
      });

      // Initial observation setup
      observer.observe(formElement, { childList: true, subtree: true, attributes: true, attributeFilter: ['value'] });

      // Observe existing hidden inputs
      formElement.querySelectorAll("input[type='hidden']").forEach(hiddenInput => {
        observer.observe(hiddenInput, { attributes: true, attributeFilter: ['value'] });
      });

      return () => {
        formElement.removeEventListener('input', debouncedHandleFormChange);
        formElement.removeEventListener('change', debouncedHandleFormChange);
        observer.disconnect();
      };
    }
    return false;
  }, [handleFormChange, organizationId, campaignSettingId]);

  return (
    <div className="FbPreview-wrapper">
      <div className={cs('Loader--background', { active: loading })}>
        <div className="Loader Loader--big" data-test-id="preview-loader" />
      </div>
      <Heading spacing={24}>{t('fb_ad_preview.preview', { default: 'Preview' })}</Heading>
      {!showPreview && !error && (
        <EmptyState icon="image_editor_icon">
          <Heading className="mt-24" tag="h2">
            {t('fb_ad_preview.no_preview_yet', { default: 'There is no preview yet' })}
          </Heading>
          <Text color="darkGray">
            {t('fb_ad_preview.no_preview_yet_info', { default: 'Please setup your ad first to show preview.' })}
          </Text>
        </EmptyState>
      )}
      {showPreview && !error && (
        <InfoBox type="info" withIcon className="mb-24">
          {t('fb_ad_preview.preview_info', {
            default:
              'Below is a basic preview of your ad, full preview will be added in future. If you need to see all templates, please check it in Facebook.',
          })}
        </InfoBox>
      )}
      {error && (
        <InfoBox type="error" withIcon className="mb-24">
          {t('fb_ad_preview.preview_error', {
            default: 'An error occurred while fetching the preview. Please try again later.',
          })}
        </InfoBox>
      )}
      {showSinglePreview && !error && <FbSingleAdPreview data={data} />}
      {showCarouselPreview && !error && <FbCarouselAdPreview data={data} />}
    </div>
  );
};

export default FbAdPreviewMain;
