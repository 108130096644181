import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from './index';
import { formatTime } from '../i18n';

class TimeAgo extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    long: PropTypes.bool,
    hideTooltip: PropTypes.bool,
  };

  static defaultProps = {
    hideTooltip: false,
  };

  state = {
    now: moment(),
  };

  componentDidMount() {
    this.intervalId = setInterval(this.updateNow, 1000);
  }

  componentWillUnmount() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  updateNow = () => {
    this.setState({ now: moment() });
  };

  render() {
    const { children, long, hideTooltip } = this.props;

    if (!children) {
      return null;
    }

    if (hideTooltip) {
      return moment(children)
        .locale(long ? window.I18nLocale : `${window.I18nLocale}-short`)
        .fromNow();
    }

    return (
      <Tooltip text={formatTime(children)} className="hide-in-percy">
        {moment(children)
          .locale(long ? window.I18nLocale : `${window.I18nLocale}-short`)
          .fromNow()}
      </Tooltip>
    );
  }
}

export default TimeAgo;
