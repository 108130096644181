import React from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { INPUTS } from './index';
import { setCorrectAnchor } from './utils';
import { Chart, Text, SmallHeading, Modal, ModalBody, GraphqlTable, cssVariables } from '../components/index';
import { formatDate, formatNumberInflection, t } from '../i18n';
import concatPresence from '../utils/concatPresence';
import CHART_COLORS from '../components/ChartColors';

const ModalContent = ({ campaignSettingId = null, organizationId, date }) => (
  <GraphqlTable
    organizationId={organizationId ? parseInt(organizationId, 10) : null}
    campaignSettingId={campaignSettingId ? parseInt(campaignSettingId, 10) : null}
    collectionKey="auditLogs"
    columns={['actionSubject', 'where', 'userName', 'createdAt', 'changes']}
    disableFilters
    overrideFilters={{ dateFrom: date, dateTo: date }}
    disableColumnsChange
    emptyState={{
      text: t('views.shared.audit_log.react_audit_log_component.no_audit_logs'),
    }}
  />
);

ModalContent.propTypes = {
  campaignSettingId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  organizationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  date: PropTypes.string.isRequired,
};

const AuditLogModal = ({ closeModal, ...rest }) => (
  <Modal
    onClose={closeModal}
    heading={concatPresence([t('views.campaign_settings.audit_log.heading'), formatDate(rest?.date)], ' - ')}
    size="full"
  >
    <ModalBody>
      <ModalContent {...rest} />
    </ModalBody>
  </Modal>
);

AuditLogModal.propTypes = {
  closeModal: PropTypes.func,
};

const CustomizedTooltip = hot(({ active, label, payload, valueFormatter, chartType }) => {
  if (!active || !payload) return null;

  const tooltipPayload = payload?.reduce(
    (acc, cur) =>
      cur.dataKey === 'beOnAxis'
        ? { ...acc, historyData: [...acc.historyData, cur] }
        : { ...acc, areaData: [...acc.areaData, cur] },
    { historyData: [], areaData: [] }
  );

  return (
    <div className="CustomizedTooltip pa-8">
      <Text size="sm" color="black-light">
        {formatDate(label, 'medium')}
      </Text>

      {chartType === 'area' &&
        tooltipPayload.areaData.map(item => (
          <SmallHeading className="CustomizedTooltip--mainHeading" spacing={0} key={`legend-${label}-${item.dataKey}`}>
            {valueFormatter ? valueFormatter(item.payload[item.dataKey]) : item.payload[item.dataKey]}
          </SmallHeading>
        ))}

      {chartType === 'line' &&
        tooltipPayload.historyData.map(item => (
          <SmallHeading className="CustomizedTooltip--mainHeading" spacing={0} key={`legend-${label}-${item.dataKey}`}>
            {formatNumberInflection({ scope: 'stats.changes_tooltip', count: item.payload.auditLogCount })}
          </SmallHeading>
        ))}
    </div>
  );
});

export const CustomizedAxisTick = ({ x, y, payload, format, dataKey, options, fixPositioning }) => {
  const positionFix = setCorrectAnchor({ ...fixPositioning }, payload.value);

  return (
    <g transform={`translate(${positionFix?.offsetX ? x - positionFix?.offsetX : x},${y})`}>
      <text
        x={0}
        y={0}
        dy={options?.dy}
        dx={options?.dx}
        textAnchor={positionFix?.textAnchor || options.textAnchor}
        fill="#8C969F"
        fontSize="11px"
        fontWeight={600}
      >
        {options?.skipFirst
          ? payload?.value !== 0 && format({ [dataKey]: payload?.value })
          : format({ [dataKey]: payload?.value })}
      </text>
    </g>
  );
};

const MainChart = ({ data, area, stats, ...rest }) => {
  const [modalVisible, setModalVisibility] = React.useState(false);
  const [date, setDate] = React.useState(null);

  const openHistoryModal = wantedDate => {
    setDate(wantedDate);
    setModalVisibility(true);
  };

  const closeModal = () => {
    setDate(null);
    setModalVisibility(false);
  };

  return (
    <>
      <Chart
        {...rest}
        areas={{
          [area]: {
            strokeColor: CHART_COLORS[0],
          },
        }}
        canAreasChange
        data={data}
        height={120}
        isAnimated
        legend="date"
        legendFormatter={val => INPUTS.date.format(val)}
        margin={{ left: -60 }}
        showGrid
        showToggles={false}
        smooth={false}
        activeDot={{ r: 4, stroke: cssVariables.blueNebula, strokeWidth: 4, orientation: 'inner', fill: 'white' }}
        strokeDasharray={null}
        customizedTooltip={CustomizedTooltip}
        tooltipFormatter={val => INPUTS[area].format({ [area]: val, currency: stats.currency })}
        tooltipTranslationPath={'stats_metrics'}
        verticalLines={false}
        scale="point"
        openHistoryModal={openHistoryModal}
        xAxis={{
          tick: (
            <CustomizedAxisTick
              {...INPUTS.date}
              data={data}
              dataKey="date"
              options={{ dy: 9, textAnchor: 'middle' }}
              fixPositioning={{ start: data[0].date, end: data[data.length - 1].date }}
            />
          ),
          interval: 6,
          tickLine: false,
          stroke: '#8C969F',
          strokeWidth: 1,
        }}
        yAxis={{
          axisLine: false,
          tickLine: false,
          tick: (
            <CustomizedAxisTick
              {...INPUTS[area]}
              data={data}
              dataKey={area}
              options={{ skipFirst: true, dy: 16, dx: 10, textAnchor: 'start' }}
            />
          ),
        }}
      />
      {modalVisible && <AuditLogModal date={date} {...rest} closeModal={closeModal} />}
    </>
  );
};
MainChart.propTypes = {
  area: PropTypes.string,
  data: PropTypes.array,
  isEmpty: PropTypes.bool,
  stats: PropTypes.object,
};

CustomizedAxisTick.propTypes = {
  dataKey: PropTypes.string,
  fixPositioning: PropTypes.object,
  format: PropTypes.func,
  options: PropTypes.object,
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
};

CustomizedTooltip.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string,
  payload: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  valueFormatter: PropTypes.func,
};

export default MainChart;
