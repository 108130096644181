/* eslint-disable no-unused-vars */
import Swiper from 'swiper/dist/js/swiper';

window.addEventListener('DOMContentLoaded', () => {
  const signUpSwiper = new Swiper('.js-signup-swiper', {
    direction: 'horizontal',
    loop: true,
    autoHeight: true,
    updateOnWindowResize: true,
    effect: 'fade',
    speed: 1000,
    fadeEffect: {
      crossFade: true,
    },
    autoplay: {
      disableOnInteraction: true,
      delay: 4000,
    },
    keyboard: {
      enabled: true,
    },
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev',
    },
  });
});
