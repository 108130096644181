/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { gql, NetworkStatus } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import { Row, Col, ErrorLoadingQuote, Text, SmallHeading } from '../components';
import { t } from '../i18n';
import Icon from '../components/Icon';
import UsedByGraph from '../components/graphs/UsedByGraph';

// eslint-disable-next-line react/prop-types
const QueryHolder = WrappedComponent => ({ query, variables, ...rest }) => (
  <Query query={query} variables={variables} notifyOnNetworkStatusChange>
    {({ error, data, previousData, refetch, networkStatus }) => {
      const loadingByNetwork = networkStatus !== NetworkStatus.ready;
      if (loadingByNetwork && !data) {
        return (
          <div className="Preview-item">
            <div className="loading-status Loader Loader--small h-auto w-auto" style={{ left: '32px', top: 0 }}>
              {t('react.pla_campaign_preview.loading')}
            </div>
          </div>
        );
      }
      if (error) {
        return (
          <div className="Preview-item">
            <ErrorLoadingQuote onClick={() => refetch()} />
          </div>
        );
      }

      return <WrappedComponent data={data || previousData} refetch={() => refetch()} {...variables} {...rest} />;
    }}
  </Query>
);

const QUERY_CAMPAIGNS = gql`
  query OrganizationEstoreCampaigns($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreCampaigns(status: "running") {
          id
          name
          adwordsId
          adwordsError
        }
        itemsCount
      }
    }
  }
`;

const QUERY_ADGROUPS = gql`
  query OrganizationEstoreAdgroups($organizationId: BigInt!, $campaignSettingId: BigInt!, $estoreCampaignId: String!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        estoreAdgroups(size: -1, status: "running", campaignId: $estoreCampaignId) {
          id
          name
          adwordsId
          adwordsError
          estoreAdtexts(status: "running") {
            id
            name
            modelAdtext
            baseAdtext {
              id
              itemsCount
            }
          }
        }
      }
    }
  }
`;

export default class SmartDisplayCampaignPreview extends React.Component {
  static propTypes = {
    organizationId: PropTypes.number.isRequired,
    campaignSettingId: PropTypes.number.isRequired,
  };
  render() {
    const { organizationId, campaignSettingId } = this.props;
    return <CampaignList query={QUERY_CAMPAIGNS} variables={{ organizationId, campaignSettingId }} />;
  }
}

let estoreCampaignsInterval = null;

const CampaignList = QueryHolder(({ data, refetch, ...rest }) => {
  const { estoreCampaigns, itemsCount } = data.organization.campaignSetting;

  if (estoreCampaignsInterval) {
    clearInterval(estoreCampaignsInterval);
  }

  if (estoreCampaigns.length === 0) {
    estoreCampaignsInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_campaigns_generated_yet')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <div>
      {estoreCampaigns.map(campaign => (
        <CampaignLine key={campaign.id} itemsCount={itemsCount} {...rest} {...campaign} />
      ))}
    </div>
  );
});

class CampaignLine extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    adwordsId: PropTypes.string,
    adwordsError: PropTypes.string,
    organizationId: PropTypes.number,
    campaignSettingId: PropTypes.number,
    itemsCount: PropTypes.number,
  };

  render() {
    const { id, name, adwordsId, itemsCount, adwordsError, organizationId, campaignSettingId, ...rest } = this.props;

    return (
      <>
        <div className="Preview-item Preview-item--bold border-none" data-test-id="performance-max-preview-campaign">
          <Row center>
            <Col grow>
              <Row center>
                <Col shrink>
                  <Icon className="Icon--grape" size="20px" kind="performance-max-campaign" inheritColor />
                </Col>
                <Col grow>
                  <Row>
                    <SmallHeading spacing={0}>{name}</SmallHeading>
                    {adwordsError ? <span className="danger fc-warning ml-8"> {adwordsError}</span> : ''}
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col width="100px">
              <Row center>
                <Col shrink>
                  <UsedByGraph size="md" products={itemsCount} productsTotal={itemsCount} />
                </Col>
                <Col shrink>
                  <Text size="sm" color="softGray" data-test-id="performance-max-preview-items-count">
                    {itemsCount}
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <AdgroupList
          {...rest}
          query={QUERY_ADGROUPS}
          variables={{ organizationId, campaignSettingId, estoreCampaignId: id }}
          totalItemsCount={itemsCount}
        />
      </>
    );
  }
}

let estoreCampaignLineInterval = null;

const AdgroupList = QueryHolder(({ data, refetch, totalItemsCount, ...rest }) => {
  const { estoreAdgroups } = data.organization.campaignSetting;

  if (estoreCampaignLineInterval) {
    clearInterval(estoreCampaignLineInterval);
  }

  if (estoreAdgroups.length === 0) {
    estoreCampaignLineInterval = setInterval(refetch, 5000);

    return (
      <div className="Preview-item">
        {t('react.pla_campaign_preview.no_adgroup_product_dimension_generated')}
        <br />
        <a className="Link" onClick={refetch}>
          {t('react.pla_campaign_preview.try_to_laod_again_or_wait_5s_to_refresh')}
        </a>
      </div>
    );
  }

  return (
    <ul className="List List--arrow ml-4 pb-8">
      {data.organization.campaignSetting.estoreAdgroups.map((dimension, index) =>
        dimension.estoreAdtexts.map(adtext => (
          <li className="List-item List-item--tile" key={adtext.id}>
            <AssetGroupLine index={index} {...rest} adtext={adtext} {...dimension} totalItemsCount={totalItemsCount} />
          </li>
        ))
      )}
    </ul>
  );
});

AdgroupList.propTypes = {
  data: PropTypes.any,
  refetch: PropTypes.func.isRequired,
};

class AssetGroupLine extends React.Component {
  static propTypes = {
    adtext: PropTypes.object,
    adwordsError: PropTypes.string,
    totalItemsCount: PropTypes.number,
  };

  componentDidMount() {
    if (typeof window.initIcons === 'function') {
      setTimeout(window.initIcons, 1);
    }
  }

  componentDidUpdate() {
    if (typeof window.initIcons === 'function') {
      setTimeout(window.initIcons, 1);
    }
  }

  render() {
    const { adtext, adwordsError, totalItemsCount } = this.props;

    return (
      <div className="Preview-tileItem ma-0">
        <Row center>
          <Col grow className="pl-8">
            <Row center>
              <Col shrink>
                <Icon className="Icon--grape" size="18px" kind="item-group" inheritColor />
              </Col>
              <Col grow>
                <Text bold>{adtext.name}</Text>
              </Col>
            </Row>
          </Col>
          <Col shrink wrap>
            {/* eslint-disable-next-line react/no-danger */}
            <div className="js-tooltip-no-left-margin" dangerouslySetInnerHTML={{ __html: adtext.modelAdtext }} />
          </Col>
          {adtext?.baseAdtext?.itemsCount && adtext.baseAdtext.itemsCount > 0 && (
            <Col width="91px">
              <Row center>
                <Col shrink>
                  <UsedByGraph size="md" products={adtext.baseAdtext.itemsCount} productsTotal={totalItemsCount} />
                </Col>
                <Col shrink>
                  <Text size="sm" color="softGray">
                    {adtext.baseAdtext.itemsCount}
                  </Text>
                </Col>
              </Row>
            </Col>
          )}
          {adwordsError ? <Col className="danger fc-warning">` ${adwordsError}`</Col> : null}
        </Row>
      </div>
    );
  }
}
