/* eslint-disable no-console */
/* eslint-disable react/no-danger */
import React, { useState, useContext } from 'react';
import RPT from 'prop-types';
import ReactDOM from 'react-dom';
import cs from 'classnames';
import { ContentState, CompositeDecorator, Editor, EditorState, Modifier, SelectionState } from 'draft-js';
import { debounce } from 'lodash';
import Modal from './Modal';
import PreviewDataModal from './PreviewDataModal';
import { Button, Tooltip, Icon, Text } from '../components';
import * as Layout from '../components/layout';
import AddNewVariable from './AddNewVariable';
import { listenOnPlaceholdersChange } from './placeholdersEmitter';
import Autocomplete from './autocomplete';
import { t } from '../i18n';
import PlaceholderTypeIcon from '../components/PlaceholderTypeIcon';
import InfoBox from '../components/InfoBox';
import { findEntity, findEntityPosition, determineClickSide } from '../utils';

const EditContext = React.createContext({
  editLink: null,
  editName: null,
  setEditLink: () => {},
});

/*
 *
 *  = f.input :value, as: :placeholder
 *  = f.input :value, as: :placeholder_multi
 *  = f.input :value, as: :placeholder, input_html: { data: { placeholders: ['_campaign_name_', '_adgroup_name_', '_system_name_'].to_json } }
 */
const LONG_UNDERSCORE = '＿';
const LONG_UNDERSCORE_REGEX = /＿/g;

const MISSING_PLACEHOLDER_REGEX = /_[a-zA-Z0-9_]+_/g;
const ARRAY_INDEX_REGEX = /(\d+)_/g;
const NEW_LINES_REGEXP = /\n/g;

const handleHierarchyStrategy = getPossiblePlaceholders => (contentBlock, callback) => {
  const possiblePlaceholders = getPossiblePlaceholders();
  return findWithRegex(
    possiblePlaceholders.length > 0 ? new RegExp(`(${possiblePlaceholders.join('|')})`, 'g') : undefined,
    contentBlock,
    callback
  );
};

const handlePlaceholderStrategy = (contentBlock, callback) =>
  findWithRegex(MISSING_PLACEHOLDER_REGEX, contentBlock, callback);

const handleLongUnderscoreStrategy = (contentBlock, callback) => {
  const text = contentBlock.getText();
  let matchArr = LONG_UNDERSCORE_REGEX.exec(text);
  while (matchArr !== null) {
    const start = matchArr.index;
    const matchedText = matchArr[0];
    callback(start, start + matchedText.length);
    matchArr = LONG_UNDERSCORE_REGEX.exec(text);
  }
};

function findWithRegex(regex, contentBlock, callback) {
  if (!regex) {
    return;
  }
  const text = contentBlock.getText();
  let matchArr = regex.exec(text);
  while (matchArr !== null) {
    let start = matchArr.index;
    let textLength = 0;
    const matchedText = matchArr[0];

    for (let i = 0; matchedText.length > i; i += 1) {
      if (matchedText[i] === '_' && (matchedText[i + 1] === '_' || i + 1 >= matchedText.length)) {
        textLength += 1;
        if (textLength > 2) {
          callback(start, start + textLength);
        }
        start += textLength;
        textLength = 0;
      } else {
        textLength += 1;
      }
    }

    matchArr = regex.exec(text);
  }
}

const extractUsedPlaceholders = text => {
  const output = [];
  findWithRegex(MISSING_PLACEHOLDER_REGEX, { getText: () => text }, (start, end) =>
    output.push(text.slice(start, end))
  );
  return output;
};

function getTabs(placeholders) {
  const valueTypes = new Set();
  Object.keys(placeholders).forEach(key => {
    valueTypes.add(placeholders[key].valueType);
  });

  return Array.from(valueTypes).sort((a, b) => a.localeCompare(b));
}

function mapStringVariablesToTextType(placeholders) {
  return Object.keys(placeholders).reduce((acc, key) => {
    acc[key] = { ...placeholders[key] };
    if (['string'].includes(acc[key].valueType)) {
      acc[key].valueType = 'text';
    }
    return acc;
  }, {});
}

const infoMessages = {
  string_in_numeric_variable: {
    condition: (inputValue, placeholders) => {
      if (!inputValue) return false;
      const variables = extractUsedPlaceholders(inputValue) || [];
      return variables.some(variable => placeholders[variable]?.valueType === 'text');
    },
    type: 'info',
    text: t('react.placeholders.string_in_numeric_variable_warning', {
      default:
        'You are creating a numeric variable, but you have chosen a string type. This variable will still be stored as a numeric variable.',
    }),
  },
};

const LongUnderscoreDecorator = {
  strategy: handleLongUnderscoreStrategy,
  component: ({ children }) => <span className="Placeholders-convertedUnderscore">{children}</span>,
};
LongUnderscoreDecorator.component.propTypes = {
  children: RPT.any,
};

class ListComponent extends React.Component {
  static propTypes = {
    children: RPT.any,
    display: RPT.bool,
    newLinks: RPT.object,
    restoreFocus: RPT.func,
    stickyToBottom: RPT.string,
    tabs: RPT.array,
    activeTab: RPT.number,
    onTabChange: RPT.func,
  };

  state = {
    display: this.props.display,
    left: 0,
    top: 0,
    isInModal: false,
    translation: window.reacti18n.editor || {},
    stickyTop: false,
    stickyBottom: false,
    stickyToBottom: null,
  };

  componentWillMount() {
    this.node = document.createElement('div', '');
    document.body.appendChild(this.node);
    document.removeEventListener('scroll', this.updateListPositionDebounced);
  }

  componentDidMount() {
    this.mounted = true;
    this.updateListPosition();
    setTimeout(this.updateListPosition, 10);
    document.addEventListener('scroll', this.updateListPositionDebounced, true);
  }

  componentWillReceiveProps(nextProps) {
    this.updateListPosition();

    if (this.state.display && !nextProps.display) {
      setTimeout(() => {
        if (this.mounted) {
          this.setState({ display: false });
        }
      }, 200);
    }
    if (!this.state.display && nextProps.display) {
      this.setState({ display: true });
      setTimeout(this.updateListPosition, 10);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    if (this.node) {
      document.body.removeChild(this.node);
    }
  }

  updateListPosition = () => {
    if (this.mounted && this.state.display) {
      const selection = window.getSelection();
      if (selection.rangeCount !== 0) {
        const parent = selection.getRangeAt(0).startContainer.parentElement;

        if (parent) {
          // Check if the parent's text content consists only of underscores
          const textContent = parent.textContent;
          const startsWithUnderscore = /^_/.test(textContent);
          const isUnderscoredEntity = /^_[a-zA-Z_]+_$/.test(textContent);
          if (!startsWithUnderscore || isUnderscoredEntity) {
            this.setState({ display: false });
            return;
          }
          const { left, top, height } = parent.getBoundingClientRect();
          const dropdownWidth = this.container.offsetWidth;
          let adjustedLeft = left;

          // Check if the dropdown goes beyond the window's width
          if (left + dropdownWidth > window.innerWidth) {
            adjustedLeft = window.innerWidth - dropdownWidth - 20;
          }
          this.setState({
            left: adjustedLeft,
            top: top + height + window.scrollY,
            isInModal: !!parent.closest('.modal'),
          });
        }
      }
    }
  };

  updateListPositionDebounced = debounce(this.updateListPosition, 5, { useRAF: true });

  setListRef = el => {
    if (el) {
      const resolveGridSticky = () => {
        if (el.scrollTop > 0) {
          this.setState({ stickyTop: true });
        } else {
          this.setState({ stickyTop: false });
        }
        if (el.scrollHeight > el.scrollTop + el.offsetHeight) {
          this.setState({ stickyBottom: true });
        } else {
          this.setState({ stickyBottom: false });
        }
      };
      resolveGridSticky();
      el.addEventListener('scroll', resolveGridSticky);
    }
  };

  render() {
    const { children, newLinks, restoreFocus, stickyToBottom, tabs, onTabChange, activeTab } = this.props;
    const { display, translation, left, top, isInModal, stickyTop, stickyBottom } = this.state;
    const transform = stickyToBottom ? `translate(0, calc(-100% - ${stickyToBottom}))` : 'none';

    const onTabMouseDown = index => e => {
      e.preventDefault();
      onTabChange(index);
    };

    return ReactDOM.createPortal(
      <div
        className="Placeholders-dropdown"
        style={{
          left: `${left}px`,
          top: `${top + 8}px`,
          transform,
          display: display ? 'inline-block' : 'none',
          position: 'absolute',
          zIndex: isInModal ? 1060 : 105,
        }}
        ref={el => {
          this.container = el;
        }}
      >
        <AddNewVariable newLinks={newLinks} restoreFocus={restoreFocus} />
        {!!tabs.length && (
          <ul className="TabNavigation TabNavigation--noSpacing TabNavigation--inDropdownMenu">
            {tabs.map((tab, index) => (
              <li
                className={`TabNavigation-item TabNavigation-item--inDropdownMenu ${activeTab === index && 'active'}`}
                key={tab}
              >
                <a onMouseDown={onTabMouseDown(index)}>
                  <PlaceholderTypeIcon size="20px" valueType={tab} isActive={activeTab === index} />
                  <span>{t(`react.placeholders.${tab}`)}</span>
                </a>
              </li>
            ))}
          </ul>
        )}
        <div
          className={cs('Placeholders-list', {
            'Placeholders-list--stickyTop': stickyTop,
            'Placeholders-list--stickyBottom': stickyBottom,
          })}
          ref={this.setListRef}
        >
          {children}
        </div>
        {children.length > 1 && (
          <div
            className="Placeholders-dropdownHint"
            dangerouslySetInnerHTML={{ __html: translation.use_arrows_html_updated_styles }}
          />
        )}
      </div>,
      this.node
    );
  }
}

const PlaceholderBox = (
  getPossiblePlaceholders,
  showMissing,
  removePlaceholder,
  getEditorState,
  readOnly,
  setState,
  convertPlaceholderToText
) => {
  const PlaceholderBoxComponent = ({ children, offsetKey, decoratedText }) => {
    const [showButtons, setButtonsVisibility] = useState(false);
    const { setEditLink } = useContext(EditContext);
    const { possiblePlaceholders, placeholdersInfo } = getPossiblePlaceholders();
    const isPresent =
      possiblePlaceholders.indexOf(decoratedText) !== -1 ||
      possiblePlaceholders.indexOf(decoratedText.replace(ARRAY_INDEX_REGEX, '')) !== -1;
    const nameOfPlaceholder = children[0] && (children[0].props || {}).text;
    const info =
      (nameOfPlaceholder && (placeholdersInfo || {})[nameOfPlaceholder]) ||
      (placeholdersInfo || {})[nameOfPlaceholder.replace(ARRAY_INDEX_REGEX, '')];
    const editLink = info && info.editLink;

    const handleCursorShift = e => {
      const editorState = getEditorState();
      const selection = editorState.getSelection();
      const blockKey = selection.getStartKey(); // Get the key of the block where the cursor is
      const content = editorState.getCurrentContent();
      // Get the plain text content of the editor
      const plainText = content.getPlainText();
      const startOffset = selection.getStartOffset();
      const position = findEntityPosition(plainText, decoratedText, startOffset);
      const part = determineClickSide(e);
      if (position) {
        setTimeout(() => {
          const { start, end } = position;
          const cursorDestination = part === 'left' ? start : end;
          const newSelection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: cursorDestination,
            focusOffset: cursorDestination,
          });
          // Force the editor to move the cursor to the new selection
          const newEditorState = EditorState.forceSelection(editorState, newSelection);

          // Update the editor state to reflect the new cursor position
          setState({ editorState: newEditorState });
        }, 30);
      }
    };

    return (
      <span
        className={cs('Placeholders-item', 'Placeholders-item--insideInput', {
          missing: !isPresent,
          'read-only': readOnly,
        })}
        data-offset-key={offsetKey}
        style={{
          position: 'relative',
          zIndex: showButtons ? 10 : 1,
        }}
        onMouseEnter={() => setButtonsVisibility(true)}
        onMouseLeave={() => setButtonsVisibility(false)}
      >
        <Icon kind="flash" inheritColor size="16px" className="pos-sticky ml-4 mr-4" tabIndex={0} />
        <span onClick={handleCursorShift}>{children}</span>
        {!readOnly && (
          <div
            className="Placeholders-remove fc-close"
            onClick={() =>
              removePlaceholder(
                offsetKey.replace(/-\d+-\d+$/, ''),
                children[0].props.start,
                children[0].props.start + decoratedText.length
              )
            }
          />
        )}
        {showButtons && !readOnly && !isPresent && (
          <div
            className="Placeholders-convertToText"
            onClick={() =>
              convertPlaceholderToText(
                offsetKey.replace(/-\d+-\d+$/, ''),
                children[0].props.start,
                children[0].props.start + decoratedText.length,
                decoratedText
              )
            }
          >
            <Icon color="white" kind="flash" />
            <span style={{ color: 'white' }}> → </span>
            <Icon color="white" kind="type" />
          </div>
        )}
        {showButtons && !readOnly && editLink && (
          <div className="Placeholders-edit fc-edit" onClick={() => setEditLink(editLink, nameOfPlaceholder)} />
        )}
      </span>
    );
  };
  PlaceholderBoxComponent.propTypes = {
    children: RPT.any,
    offsetKey: RPT.string,
    decoratedText: RPT.string,
  };
  return PlaceholderBoxComponent;
};

const OneAutocompleteItem = ({
  current,
  editLink,
  enableInlineEdit = true,
  item,
  onModalClose,
  onSelect,
  previewLink,
  selectSuggestion,
  valueType = 'text',
}) => {
  const { setEditLink } = useContext(EditContext);

  return (
    <Layout.Row
      center
      onClick={onSelect}
      onMouseEnter={typeof selectSuggestion === 'function' ? selectSuggestion : () => {}}
      className={`Placeholders-dropdownItem mt-0 ${current && 'js-placeholder-item-active active'} `}
    >
      <Layout.Col shrink>
        <PlaceholderTypeIcon size="20px" valueType={valueType} />
      </Layout.Col>
      <Layout.Col grow>
        <Text medium size="md">
          {item}
        </Text>
      </Layout.Col>

      {((editLink && enableInlineEdit) || previewLink) && <div className="Placeholders-delimeter" />}

      {editLink && enableInlineEdit && (
        <Layout.Col shrink>
          <Button
            tertiary
            onlyIcon
            icon="edit"
            size="small"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setEditLink(editLink, item);
            }}
          />
        </Layout.Col>
      )}
      {previewLink && (
        <Layout.Col shrink>
          <PreviewDataModal previewLink={previewLink} name={item} onClose={onModalClose}>
            <Button tertiary size="small" onlyIcon icon="search-chart" />
          </PreviewDataModal>
        </Layout.Col>
      )}
    </Layout.Row>
  );
};

OneAutocompleteItem.propTypes = {
  current: RPT.bool,
  editLink: RPT.string,
  enableInlineEdit: RPT.bool,
  item: RPT.any,
  onModalClose: RPT.func,
  onSelect: RPT.func,
  previewLink: RPT.oneOfType([RPT.string, RPT.boolean]),
  selectSuggestion: RPT.func,
  valueType: RPT.string,
};

const defaultNewLinks = {
  tiles: [],
  heading: undefined,
  description: undefined,
  buttonText: undefined,
};

const InfoMessage = ({ infoContainer, content, condition, type = 'info' }) =>
  condition
    ? ReactDOM.createPortal(
        <InfoBox className="mt-16" type={type} withIcon>
          {content}
        </InfoBox>,
        infoContainer
      )
    : null;

InfoMessage.propTypes = {
  infoContainer: RPT.any.isRequired,
  content: RPT.string.isRequired,
  condition: RPT.bool.isRequired,
  type: RPT.string,
};

export default class InputWithPlaceholders extends React.Component {
  static propTypes = {
    useInternalInput: RPT.bool,
    container: RPT.any,
    defaultValue: RPT.any,
    useNewVariableWhenAdded: RPT.string,
    input: RPT.any,
    multiline: RPT.bool,
    hierarchyName: RPT.bool,
    readOnly: RPT.bool,
    formId: RPT.string,
    onChange: RPT.func,
    inputProps: RPT.object,
    placeholder: RPT.string,
    disableProductPlaceholders: RPT.bool,
    placeholdersWithData: RPT.array,
    placeholders: RPT.array,
    disablePlaceholderButton: RPT.bool,
    stickyToBottom: RPT.string,
  };
  constructor(props) {
    super(props);
    let formPossiblePlaceholders = [];
    let fieldPossiblePlaceholders = [];
    const { container, input, useInternalInput, defaultValue, formId, inputProps } = props;

    this.state = {
      editorState: EditorState.createEmpty(), // Initialize editorState
      activeTab: 0,
    };

    try {
      const form = container.closest('form') || (formId && document.getElementById(formId));
      const formPlaceholders = form || form.getElementsByClassName('react-placholders')[0];
      const placeholdersData = JSON.parse(formPlaceholders.dataset.placeholders);

      formPossiblePlaceholders = placeholdersData.map(p => p.text);
      this.placeholdersInfo = placeholdersData.reduce((out, field) => ({ ...out, [field.text]: field }), {});
      if (formPlaceholders && formPlaceholders.dataset && formPlaceholders.dataset.placeholderLinks) {
        this.newLinks = JSON.parse(formPlaceholders.dataset.placeholderLinks);
      }
    } catch (e) {
      console.warn('Unable to use placeholders for draft editor', container.closest('form'));
    }

    try {
      if (!useInternalInput && input.dataset.placeholders) {
        fieldPossiblePlaceholders = JSON.parse(input.dataset.placeholders);
      } else if (this.props.placeholdersWithData) {
        formPossiblePlaceholders = this.props.placeholdersWithData.map(p => p.text);
        this.placeholdersInfo = this.props.placeholdersWithData.reduce(
          (out, field) => ({ ...out, [field.text]: field }),
          {}
        );
      } else if (this.props.placeholders) {
        fieldPossiblePlaceholders = this.props.placeholders;
      }
    } catch (e) {
      console.warn('Unable to use placeholders for draft editor from field', input);
    }

    this.placeholdersInfo = mapStringVariablesToTextType(this.placeholdersInfo);

    let disableProductPlaceholders = false;
    if (!useInternalInput && input.dataset.disableProductPlaceholders) {
      disableProductPlaceholders = JSON.parse(input.dataset.disableProductPlaceholders);
    } else if (this.props.disableProductPlaceholders) {
      disableProductPlaceholders = this.props.disableProductPlaceholders;
    }

    if (disableProductPlaceholders) {
      this.newLinks = defaultNewLinks;
    }

    this.possiblePlaceholders = []
      .concat(disableProductPlaceholders ? [] : formPossiblePlaceholders)
      .concat(fieldPossiblePlaceholders)
      .sort((a, b) => b.length - a.length);

    this.sortedPlaceholders = [].concat(this.possiblePlaceholders).sort((a, b) => a.localeCompare(b));
    this.tabs = getTabs(this.placeholdersInfo);
    let matched_text_prefix = '';
    const placeholderAutocomplete = {
      // The prefix to match to enable this
      prefix: '_',
      // Entity type to be created when an item is selected
      type: 'PLACEHOLDER',
      // Mutability of the entity
      mutability: 'IMMUTABLE',
      // Callback called when prefix match. Need to return an array of items you want to display
      onMatch: inputText => {
        let text = `${inputText}`;
        matched_text_prefix = '';

        let placeholders = this.sortedPlaceholders?.filter(placeholder => placeholder !== undefined);

        if (inputProps.withTabs) {
          placeholders = placeholders.filter(placeholder => {
            const info = this.placeholdersInfo[placeholder];
            return info && info.valueType === this.tabs[this.state.activeTab];
          });
        }

        if (/[^A-Za-z_]/.test(text)) {
          matched_text_prefix = `_${text.match(/^.*_/)[0].replace(/_$/, '')}`;
          text = text.replace(/^.*_/, '');
        }
        if (/^.*__/.test(text)) {
          const newText = text.replace(/^.*__/, '_');
          matched_text_prefix = `_${text.match(/^.*__/)[0].replace(/_$/, '')}`;
          return placeholders.filter(placeholder => placeholder.indexOf(newText) !== -1);
        } else if (text.startsWith('_')) {
          matched_text_prefix = '_';
        }

        return placeholders.filter(placeholder => placeholder.indexOf(text) !== -1);
      },
      // The entity component
      component: PlaceholderBox(
        () => ({
          possiblePlaceholders: this.possiblePlaceholders,
          placeholdersInfo: this.placeholdersInfo,
        }),
        true,
        this.removePlaceholder,
        this.getEditorState,
        props.readOnly,
        this.setState.bind(this),
        this.convertPlaceholderToText
      ),
      // The items list component to use
      listComponent: listProps => (
        <ListComponent
          restoreFocus={this.restoreFocus}
          newLinks={this.newLinks}
          stickyToBottom={this.props.stickyToBottom}
          tabs={this.props.inputProps.withTabs ? this.tabs : []}
          onTabChange={this.setActiveTab}
          activeTab={this.state.activeTab}
          {...listProps}
        />
      ),
      // The item component to use
      itemComponent: ({ item, current, onClick, selectSuggestion }) => (
        <OneAutocompleteItem
          {...(this.placeholdersInfo[item] || {})}
          selectSuggestion={selectSuggestion}
          current={current}
          onSelect={onClick}
          item={item}
          onModalClose={this.restoreFocus}
        />
      ),
      // Callback to format the item as it will be displayed into entity
      format: item => `${matched_text_prefix}${item}`,
    };
    this.placeholderAutocomplete = placeholderAutocomplete;
    this.autocompletes = [placeholderAutocomplete];
    this.propagateCompositDecorator(props);

    const text = useInternalInput ? defaultValue || '' : props.input.value || '';
    const content = ContentState.createFromText(this.props.multiline ? text : text.replace(NEW_LINES_REGEXP, ' '));
    container.classList.add('placeholder_drop');

    if (!this.props.multiline) {
      container.classList.add('Input-reactInput--smallPadding');
    }

    this.state = {
      editLink: false,
      editorState: EditorState.moveSelectionToEnd(
        EditorState.createWithContent(content, new CompositeDecorator(this.compositeDecorator))
      ),
      activeTab: 0,
    };
    this.Modifier = Modifier;
    this.EditorState = EditorState;
    container.onClick = this.restoreFocus;
  }

  // Method to return the current editorState
  getEditorState = () => this.state.editorState;

  componentDidMount() {
    this.unlistenOnPlaceholderChange = listenOnPlaceholdersChange(this.addNewPlaceholder);
  }

  componentWillUnmount() {
    if (this.unlistenOnPlaceholderChange) {
      this.unlistenOnPlaceholderChange();
    }
  }

  handleKeyDown = e => {
    const { editorState } = this.state;

    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      const selection = editorState.getSelection();
      const startOffset = selection.getStartOffset();
      const blockKey = selection.getStartKey(); // Get the key of the block where the cursor is
      const content = editorState.getCurrentContent();
      // Get the plain text content of the editor
      const plainText = content.getPlainText();
      const position = findEntity(plainText, startOffset, this.possiblePlaceholders);
      const wordRightEnd = startOffset === position?.end && e.key === 'ArrowRight';
      const wordLeftEnd = startOffset === position?.start && e.key === 'ArrowLeft';
      if (position && !wordRightEnd && !wordLeftEnd) {
        const moveTo = e.key === 'ArrowLeft' ? position.start + 1 : position.end;
        const newSelection = SelectionState.createEmpty(blockKey).merge({
          anchorOffset: moveTo, // Move cursor to position 0 (start)
          focusOffset: moveTo,
        });
        // Force the editor to move the cursor to the new selection
        const newEditorState = EditorState.forceSelection(editorState, newSelection);

        // Update the editor state to reflect the new cursor position
        this.setState({ editorState: newEditorState });
        return 'handled';
      }
    }

    return 'not-handled';
  };

  onChange = editorState => {
    const text = editorState.getCurrentContent().getPlainText();

    if (!this.props.multiline && text.match(NEW_LINES_REGEXP)) {
      const replacedText = text.replace(NEW_LINES_REGEXP, ' ');
      // Undo will prevent user to do opraration which would insert new lines
      this.setState({ editorState: EditorState.undo(editorState) });
      this.changeInputValue(replacedText);
    } else {
      this.setState({ editorState });
      this.changeInputValue(text);
    }
  };

  getSelectedBlocks = () => {
    const selectionState = this.state.editorState.getSelection();
    const contentState = this.state.editorState.getCurrentContent();

    const startKey = selectionState.getStartKey();
    const endKey = selectionState.getEndKey();
    const isSameBlock = startKey === endKey;
    const startingBlock = contentState.getBlockForKey(startKey);
    const selectedBlocks = [startingBlock];

    if (!isSameBlock) {
      let blockKey = startKey;

      while (blockKey !== endKey) {
        const nextBlock = contentState.getBlockAfter(blockKey);
        selectedBlocks.push(nextBlock);
        blockKey = nextBlock.getKey();
      }
    }
    return selectedBlocks;
  };

  setEditorRef = el => {
    if (el && el.editor) {
      this.editor = el.editor;
      this.setEditorProps();
    }
  };

  setEditorProps = () => {
    if (this.editor) {
      if (this.props.inputProps.id) {
        this.editor.setAttribute('id', this.props.inputProps.id);
      }
      if (this.props.inputProps.dataTestId) {
        this.editor.setAttribute('data-test-id', this.props.inputProps.dataTestId);
      }
    }
  };

  setEditLink = (editLink, editName) => {
    this.setState({ editLink, editName });
  };

  changeInputValue(newVal) {
    if (this.state.inputValue !== newVal) {
      this.setState({ inputValue: newVal });
    }

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: newVal } });
    }

    if (this.props.input && this.props.input.value !== newVal) {
      this.props.input.value = newVal;
      this.props.input.dispatchEvent(new window.Event('change', { bubbles: true }));
    }
  }

  addNewPlaceholder = newPlaceholder => {
    window.placeholderAutocomplete = this.placeholderAutocomplete;
    this.possiblePlaceholders = this.possiblePlaceholders.concat([newPlaceholder.text]);
    this.sortedPlaceholders = [].concat(this.possiblePlaceholders).sort((a, b) => a.localeCompare(b));
    this.propagateCompositDecorator(this.props);

    if (`_${this.props.useNewVariableWhenAdded}_` === newPlaceholder.text) {
      const { editorState } = this.state;
      const selection = editorState.getSelection();

      const modifier = selection.isCollapsed() ? Modifier.insertText : Modifier.replaceText;
      this.onChange(
        EditorState.push(
          this.state.editorState,
          modifier(editorState.getCurrentContent(), selection, newPlaceholder.text),
          'insert-characters'
        )
      );
    }
  };

  propagateCompositDecorator = props => {
    if (props.hierarchyName) {
      this.compositeDecorator = [
        LongUnderscoreDecorator,
        {
          component: PlaceholderBox(
            () => ({
              possiblePlaceholders: this.possiblePlaceholders,
              placeholdersInfo: this.placeholdersInfo,
            }),
            true,
            this.removePlaceholder,
            this.getEditorState,
            props.readOnly,
            this.setState.bind(this),
            this.convertPlaceholderToText
          ),
          strategy: handleHierarchyStrategy(() => this.possiblePlaceholders),
        },
      ];
    } else if (this.possiblePlaceholders.length > 0) {
      this.compositeDecorator = [
        LongUnderscoreDecorator,
        {
          component: PlaceholderBox(
            () => ({
              possiblePlaceholders: this.possiblePlaceholders,
              placeholdersInfo: this.placeholdersInfo,
            }),
            true,
            this.removePlaceholder,
            this.getEditorState,
            props.readOnly,
            this.setState.bind(this),
            this.convertPlaceholderToText
          ),
          strategy: handlePlaceholderStrategy,
        },
      ];
    } else {
      this.compositeDecorator = [
        LongUnderscoreDecorator,
        {
          strategy: handlePlaceholderStrategy,
          component: PlaceholderBox(
            () => ({ possiblePlaceholders: [], placeholdersInfo: {} }),
            false,
            this.removePlaceholder,
            this.getEditorState,
            props.readOnly,
            this.setState.bind(this),
            this.convertPlaceholderToText
          ),
        },
      ];
    }
  };

  logState = () => console.log(this.state.editorState.toJS());

  handleArrow = () => {
    const active = document.getElementsByClassName('js-placeholder-item-active');
    const scrollable = document.getElementsByClassName('js-placeholder-item-active');

    if (active.length > 0 && scrollable.length > 0) {
      setTimeout(() => {
        scrollable[0].parentElement.scrollTop = active[0].offsetTop;
      }, 10);
      return 'handled';
    }

    return 'not-handled';
  };

  removePlaceholder = (key, start, end) => {
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();

    // Create a selection state explicitly within the correct range
    const selectionState = SelectionState.createEmpty(key).merge({
      anchorOffset: start,
      focusOffset: end,
      hasFocus: true,
      isBackward: false,
    });

    // Replace the selected text (remove the placeholder)
    let newContentState = Modifier.replaceText(contentState, selectionState, '');

    // Retrieve the block after the modification
    const blockKey = selectionState.getStartKey();
    const blockText = newContentState.getBlockForKey(blockKey).getText();

    // Remove any trailing space that might have been left behind
    if (blockText.endsWith(' ')) {
      const spaceSelectionState = selectionState.merge({
        anchorOffset: blockText.length - 1,
        focusOffset: blockText.length,
      });

      newContentState = Modifier.removeRange(newContentState, spaceSelectionState, 'backward');
    }

    // Push the new content state and update the editor state
    const newEditorState = EditorState.push(editorState, newContentState, 'remove-range');

    // Update the state and restore focus to the editor
    this.setState({ editorState: newEditorState }, () => {
      this.editor.focus(); // Restore focus to the editor
    });
  };

  convertPlaceholderToText = (key, start, end, text) => {
    const editorState = this.state.editorState;
    const selectionState = SelectionState.createEmpty(key).merge({
      anchorOffset: start,
      focusOffset: end,
      hasFocus: true,
      isBackward: false,
    });

    this.onChange(
      EditorState.push(
        editorState,
        Modifier.replaceText(editorState.getCurrentContent(), selectionState, text.replace(/_/g, LONG_UNDERSCORE)),
        'remove-range'
      )
    );
  };

  placeholdersInfo = {};
  newLinks = defaultNewLinks;

  focus = () => {
    this.editor.focus();
  };

  restoreFocusWithoutDebounce = () => {
    const oldSelection = this.state.editorState.getSelection();
    this.focus();
    setTimeout(() => {
      const newAnchor = this.state.editorState.getSelection().get('anchorOffset');
      if (newAnchor === 0 && newAnchor !== oldSelection.get('anchorOffset')) {
        this.setState({ editorState: EditorState.forceSelection(this.state.editorState, oldSelection) });
      }
    }, 3);
  };
  restoreFocus = debounce(this.restoreFocusWithoutDebounce, 10);

  handlePastedText = text => {
    const { editorState } = this.state;
    const newText = this.props.multiline ? text : text.replace(NEW_LINES_REGEXP, ' ');

    const blockMap = ContentState.createFromText(newText).blockMap;
    const newState = Modifier.replaceWithFragment(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      blockMap
    );
    this.onChange(EditorState.push(editorState, newState, 'insert-fragment'));

    return true;
  };

  insertFirstPlaceholderChar = e => {
    e.preventDefault();
    e.stopPropagation();
    this.restoreFocus();

    setTimeout(() => {
      const { editorState } = this.state;
      const selection = editorState.getSelection();
      const contentState = editorState.getCurrentContent();

      const text = contentState.getBlockForKey(selection.get('focusKey')).get('text');
      const cursorIndex = selection.get('focusOffset');

      if (text[cursorIndex - 1] !== '_' || (text[cursorIndex - 2] && /[^_]/.test(text[cursorIndex - 2]))) {
        this.onChange(
          EditorState.push(editorState, Modifier.insertText(contentState, selection, '_'), 'insert-characters')
        );
      }
    }, 10);

    return false;
  };

  handleCompositionStart = () => {
    if (this.getSelectedBlocks().length > 1) {
      // if multi blocks in selection, remove selection range when composition start
      const nextEditorState = EditorState.push(
        this.state.editorState,
        Modifier.removeRange(
          this.state.editorState.getCurrentContent(),
          this.state.editorState.getSelection(),
          'backward'
        ),
        'remove-range'
      );

      this.setState({
        editorState: nextEditorState,
      });
    }
  };

  setActiveTab = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    const { editLink, editName } = this.state;
    const { inputProps } = this.props;

    return (
      <>
        <EditContext.Provider value={{ editLink, editName, setEditLink: this.setEditLink }}>
          <div
            onCompositionStart={this.handleCompositionStart}
            onKeyDown={this.handleKeyDown} // Attach onKeyDown listener to parent div
          >
            {this.props.useInternalInput && (
              <input type="hidden" {...this.props.inputProps} value={this.state.inputValue || ''} />
            )}
            <Autocomplete
              onDownArrow={this.handleArrow}
              onUpArrow={this.handleArrow}
              editorState={this.state.editorState}
              onChange={this.onChange}
              onFocus={() => this.props.container.classList.add('focus')}
              onBlur={() => this.props.container.classList.remove('focus')}
              autocompletes={this.autocompletes}
              additionalDecorators={this.compositeDecorator}
              handlePastedText={this.handlePastedText}
              spellCheck={false}
              readOnly={this.props.readOnly}
              setEditorRef={this.setEditorRef}
              placeholder={this.props.placeholder}
              activeTab={this.state.activeTab}
            >
              <Editor />
            </Autocomplete>
            <Tooltip
              text={t(this.props.disablePlaceholderButton ? 'no_variables_tooltip' : 'tooltip', {
                scope: 'react.placeholders',
              })}
              className="Input-reactInput--showPlaceholders"
            >
              <Button
                onlyIcon
                kind="primary"
                disabled={this.props.readOnly || this.props.disablePlaceholderButton}
                icon="flash"
                size="small"
                tabIndex="-1"
                onClick={() => false}
                onMouseDown={this.insertFirstPlaceholderChar}
              />
            </Tooltip>
          </div>
          {editLink && (
            <Modal
              onClose={() => {
                this.setState({ editLink: false });
                this.restoreFocus();
              }}
              url={editLink}
              type="edit"
            />
          )}
        </EditContext.Provider>
        {inputProps.infoMessage && (
          <InfoMessage
            infoContainer={this.props.infoContainer}
            content={infoMessages[inputProps.infoMessage].text}
            type={infoMessages[inputProps.infoMessage].type}
            condition={infoMessages[inputProps.infoMessage].condition(this.state.inputValue, this.placeholdersInfo)}
          />
        )}
      </>
    );
  }
}

window.initDraftJsEditor = () => {
  const containers = document.getElementsByClassName('react-placeholder-input');
  let infoContainer = null;
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      if (!container.classList.contains('draft-js-editor-mounted')) {
        const input = container.getElementsByTagName('input')[0] || container.getElementsByTagName('textarea')[0];
        const readOnly =
          input.disabled || input.readonly || (input.closest('fieldset') && input.closest('fieldset').disabled);
        const editorContainer = container.getElementsByClassName('react-placeholder-block')[0];
        const multiline = container.classList.contains('react-placeholder-multiline');
        const hierarchyName = container.classList.contains('react-hierarchy-name');

        const inputProps = {
          id: input.getAttribute('id'),
          dataTestId: input.getAttribute('data-test-id'),
          withTabs: input.getAttribute('data-with-tabs'),
          infoMessage: input.getAttribute('data-info-message'),
        };
        input.removeAttribute('id');
        input.removeAttribute('data-test-id');
        input.removeAttribute('data-with-tabs');
        input.removeAttribute('data-info-message');

        if (readOnly) {
          editorContainer.classList.add('disabled');
        }

        if (inputProps.infoMessage) {
          infoContainer = document.createElement('div');
          container.appendChild(infoContainer);
        }

        // Fix enable/disable when date picker is used in form show_if_fieldset
        setInterval(() => {
          const newReadOnly =
            input.disabled || input.readonly || (input.closest('fieldset') && input.closest('fieldset').disabled);

          if (newReadOnly) {
            if (!editorContainer.classList.contains('disabled')) {
              editorContainer.classList.add('disabled');
              ReactDOM.render(
                <InputWithPlaceholders
                  hierarchyName={hierarchyName}
                  useNewVariableWhenAdded={input.getAttribute('data-useNewVariableWhenAdded')}
                  multiline={multiline}
                  container={editorContainer}
                  infoContainer={infoContainer}
                  input={input}
                  readOnly={newReadOnly}
                  inputProps={inputProps}
                  placeholder={input.getAttribute('placeholder')}
                />,
                editorContainer
              );
            }
          } else if (editorContainer.classList.contains('disabled')) {
            editorContainer.classList.remove('disabled');
            ReactDOM.render(
              <InputWithPlaceholders
                hierarchyName={hierarchyName}
                useNewVariableWhenAdded={input.getAttribute('data-useNewVariableWhenAdded')}
                multiline={multiline}
                container={editorContainer}
                infoContainer={infoContainer}
                input={input}
                readOnly={newReadOnly}
                inputProps={inputProps}
                placeholder={input.getAttribute('placeholder')}
              />,
              editorContainer
            );
          }
        }, 100);

        ReactDOM.render(
          <InputWithPlaceholders
            hierarchyName={hierarchyName}
            useNewVariableWhenAdded={input.getAttribute('data-useNewVariableWhenAdded')}
            multiline={multiline}
            container={editorContainer}
            infoContainer={infoContainer}
            input={input}
            readOnly={readOnly}
            inputProps={inputProps}
            placeholder={input.getAttribute('placeholder')}
          />,
          editorContainer
        );
        container.classList.add('draft-js-editor-mounted');
      }
    });
  }
};
