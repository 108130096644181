/* eslint-disable react/no-danger */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { throttle } from 'lodash';
import uuid from 'uuid';
import {
  Button,
  Col,
  Checkbox,
  Loader,
  Modal,
  ModalBody,
  ModalFooter,
  Text,
  ModalHeader,
  ModalHeading,
  Icon,
  Row,
  InfoBox,
} from '../../../index';
import PlaceholderInput from '../../../../placeholders/Input';
import { useMultiEdit } from '../../MultiEditContext';
import { t } from '../../../../i18n';
import useVariablePlaceholders, { statuses } from '../hooks/useVariablePlaceholders';
import useReplaceSubmit from '../hooks/useReplaceSubmit';

const parseAndCompareInt = (x, y) => x.toString() === y.toString();

const FindReplaceModal = ({
  closeModal,
  findReplaceData,
  hashSelectedData,
  ignoreCase,
  onCompleted,
  reset,
  source,
  target,
}) => {
  // prettier-ignore
  const { state: { selected } } = useMultiEdit();
  const [finalSelected, setFinalSelected] = useState(selected);

  const noData =
    !findReplaceData?.generatorFindAndReplace || findReplaceData?.generatorFindAndReplace.changes.length === 0;

  useEffect(() => {
    if (!noData) {
      const clearedData = finalSelected.filter(x => {
        const change = findReplaceData?.generatorFindAndReplace?.changes?.find(y => parseAndCompareInt(x, y.id));
        if (typeof change === 'undefined') return false;
        if (change?.hasMissingVariable) return false;
        return true;
      });
      setFinalSelected(clearedData);
    }
  }, []);

  const handleCompleted = res =>
    onCompleted({
      res,
      callback: () => {
        reset();
        closeModal();
      },
      successMessage: t('react.multi_edit.find_replace_success_html', { source, target }),
    });

  const { loading, onSubmit } = useReplaceSubmit({
    source,
    target,
    ignoreCase,
    save: true,
    onCompleted: handleCompleted,
    finalSelected,
  });

  if (noData) {
    return (
      <Col grow data-test-id="multiEdit[no-data]">
        <span dangerouslySetInnerHTML={{ __html: t('react.multi_edit.no_data_to_replace_html', { text: source }) }} />
      </Col>
    );
  }

  const toggleItem = throttle(
    id =>
      finalSelected.indexOf(id) === -1
        ? setFinalSelected([...finalSelected, id])
        : setFinalSelected(finalSelected.filter(elId => elId !== id)),
    100,
    { trailing: false }
  );

  const selectedNames = Object.keys(hashSelectedData).map(x => ({
    name: hashSelectedData[x]?.name,
    head: hashSelectedData[x]?.head,
    id: hashSelectedData[x].id,
    possibleChanges: findReplaceData?.generatorFindAndReplace.changes.find(
      el => el.id.toString() === hashSelectedData[x].id.toString()
    ),
    hasMissingVariable: findReplaceData?.generatorFindAndReplace.changes.find(
      el => el.id.toString() === hashSelectedData[x].id.toString()
    )?.hasMissingVariable,
  }));

  return (
    <Modal onClose={closeModal} border>
      <ModalHeader>
        <ModalHeading>{t('heading', { scope: 'react.multi_edit.find_and_replace_modal' })}</ModalHeading>
      </ModalHeader>
      <ModalBody>
        <Col padding="l">
          <Row>
            <Text
              dangerouslySetInnerHTML={{
                __html: t('desc', { scope: 'react.multi_edit.find_and_replace_modal', target, source }),
              }}
            />
          </Row>
          {selectedNames.map(({ name, head, id, possibleChanges, hasMissingVariable }) => {
            const changes = possibleChanges?.changes;
            const nameOrHead = name || head;
            return (
              <Row key={[nameOrHead, id].join('-')} data-test-id={`find-replace-row[${nameOrHead}]`}>
                <Col>
                  <Row shrink>
                    <Checkbox
                      label={nameOrHead}
                      name={`find-replace[selected-generator-${nameOrHead}]`}
                      doNotUseInternalState
                      disabled={!changes}
                      checked={finalSelected.indexOf(id) !== -1}
                      onChange={() => toggleItem(id)}
                    />
                  </Row>
                  <Row shrink>
                    <Col className="ml-40" data-test-id="content">
                      {!changes && (
                        <Text data-test-id="nothing-to-change-here" color="gray">
                          {t('react.multi_edit.nothing_to_change_here')}
                        </Text>
                      )}
                      {changes &&
                        changes.map(row => {
                          const find = row[0];
                          const replace = row[1];

                          return (
                            <Row key={[possibleChanges?.id, row[0]].join('-')} center shrink>
                              <Text
                                color="gray"
                                data-test-id="find"
                                className="mb-s"
                                dangerouslySetInnerHTML={{ __html: find }}
                              />
                              <Icon kind="arrow-right" size="16px" color="#8C969F" className="mh-8" />
                              <Text
                                color="gray"
                                className="mb-s"
                                data-test-id="replace"
                                dangerouslySetInnerHTML={{ __html: replace }}
                              />
                            </Row>
                          );
                        })}
                      {hasMissingVariable && (
                        <InfoBox type="warning" withIcon>
                          {t('react.multi_edit.losing_variable')}
                        </InfoBox>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            );
          })}
        </Col>
      </ModalBody>
      <ModalFooter>
        <Button
          primary
          onClick={() => onSubmit()}
          loadingType="classic"
          loading={loading}
          data-test-id="find-replace-proceed"
        >
          {t('react.multi_edit.confirm')}
        </Button>
        <Button tertiary onClick={closeModal}>
          {t('react.dashboard.insights.modal_close')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

FindReplaceModal.propTypes = {
  closeModal: PropTypes.func,
  source: PropTypes.string,
  target: PropTypes.string,
  findReplaceData: PropTypes.object,
  hashSelectedData: PropTypes.object,
  ignoreCase: PropTypes.bool,
  onCompleted: PropTypes.func,
  reset: PropTypes.func,
};

const ReplaceInput = ({ setValue, keyId, value: val, placeholders = [], ...rest }) => (
  <Col style={{ maxWidth: '200px', minWidth: '140px' }}>
    <PlaceholderInput
      {...rest}
      key={[keyId, JSON.stringify(placeholders)].join('-')}
      id={`find-replace-${keyId}`}
      disablePlaceholderButton={placeholders.length === 0}
      placeholders={placeholders}
      disableProductPlaceholders={placeholders.length === 0}
      name={`find_replace_${keyId}`}
      required
      defaultValue={val}
      onChange={({ target: { value } }) => setValue(value)}
      stickyToBottom="32px"
    />
  </Col>
);

ReplaceInput.propTypes = {
  keyId: PropTypes.string.isRequired,
  placeholders: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired,
};

const FindReplace = props => {
  const [isModalOpen, setModal] = useState(false);
  const [source, setSource] = useState('');
  const [target, setTarget] = useState('');
  const [uniqKey, setUniqKey] = useState(uuid());
  const [ignoreCase, setIgnoreCase] = useState(false);

  const resetInputs = () => setUniqKey(uuid());

  const { data, loading, onSubmit } = useReplaceSubmit({ source, target, ignoreCase, save: false });
  const { uniqueVariables, allVariables, status } = useVariablePlaceholders({
    firstIsEmpty: false,
    uniqFunc: value => ['', value, ''].join('_'),
    allFunc: ({ text }) => text,
    keyType: 'findAndReplace',
  });

  if (status === statuses.LOADING) return <Loader size="small" />;

  const showChanges = () => {
    onSubmit();
    setModal(true);
  };

  const reset = () => {
    setTarget('');
    setSource('');
    setIgnoreCase(false);
    resetInputs();
  };

  return (
    <Col grow>
      <Row center padding="l">
        <ReplaceInput
          keyId={`${uniqKey}[source]`}
          value={source}
          placeholders={uniqueVariables}
          setValue={setSource}
          placeholder={t('react.multi_edit.find')}
        />
        <ReplaceInput
          keyId={`${uniqKey}[target]`}
          value={target}
          placeholders={allVariables}
          setValue={setTarget}
          placeholder={t('react.multi_edit.replace_with')}
        />

        <Col shrink>
          <Checkbox
            label={t('react.multi_edit.ignore_case')}
            name="find-replace[ignore_case]"
            doNotUseInternalState
            checked={ignoreCase}
            onChange={({ target: { checked } }) => setIgnoreCase(checked)}
          />
        </Col>
        <Col shrink>
          <Button
            loading={loading}
            loadingType="classic"
            primary
            disabled={source === ''}
            onClick={showChanges}
            data-test-id="multiEdit[find-replace-submit]"
          >
            {t('react.multi_edit.replace')}
          </Button>
        </Col>
        {isModalOpen && !loading && (
          <FindReplaceModal
            {...props}
            findReplaceData={data}
            source={source}
            target={target}
            ignoreCase={ignoreCase}
            closeModal={() => setModal(false)}
            reset={reset}
          />
        )}
      </Row>
    </Col>
  );
};

FindReplace.propTypes = {
  hashSelectedData: PropTypes.object,
  onCompleted: PropTypes.func,
};

export default FindReplace;
