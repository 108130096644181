import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import Tile from './Tile';
import Chart from '../Chart';
import { formatDate, t, formatNumber } from '../../i18n';

const QUERY_DASHBOARD_PRODUCTS_TIMELINE_QUERY = gql`
  query DashboardOrganizationProductsTimeline($organizationId: BigInt!) {
    organization(id: $organizationId) {
      id
      productsTimeline {
        date
        added
        updated
        noChange
        removed
        active
        inactive
      }
    }
  }
`;

export const VIEWS = {
  AVAILABLE: 'AVAILABLE',
  CHANGES: 'CHANGES',
};

export const ProductsTimelineComponent = ({ data, loading, error, refetch }) => {
  const [view, setView] = useState(VIEWS.AVAILABLE);
  const lastData = data && data[data.length - 1];
  const noData = data && data.reduce((acc, item) => acc + item.active + item.inactive, 0);

  return (
    <Tile
      header={t('react.dashboard.products_timeline.header')}
      loading={loading}
      error={error}
      refetch={refetch}
      dashboard
      tabs={[
        {
          title: t(`react.dashboard.products_timeline.views.${VIEWS.AVAILABLE}`),
          onClick: () => setView(VIEWS.AVAILABLE),
        },
        {
          title: t(`react.dashboard.products_timeline.views.${VIEWS.CHANGES}`),
          onClick: () => setView(VIEWS.CHANGES),
        },
      ]}
      noData={noData === 0}
    >
      {data && (
        <Fragment>
          {view === VIEWS.AVAILABLE && (
            <Chart
              key="1"
              data={data}
              areas={{
                active: {
                  label: `${t('react.dashboard.products_timeline.products.active')} (${formatNumber(lastData.active, {
                    precision: 0,
                  })})`,
                },
              }}
              legend="date"
              legendFormatter={val => formatDate(val, 'short')}
              tooltipTranslationPath={'react.dashboard.products_timeline.products'}
              height={170}
            />

            /* UNTIL 20.4.2023 there will be no INACTIVE DATA after that time you can uncomment this chart and remove previous code
            <Chart
              key="1"
              data={data}
              areas={{
                active: {
                  label: `${t('react.dashboard.products_timeline.products.active')} (${formatNumber(lastData.active, {
                    precision: 0,
                  })})`,
                },
                inactive: {
                  label: `${t('react.dashboard.products_timeline.products.inactive')} (${formatNumber(
                    lastData.inactive,
                    { precision: 0 }
                  )})`,
                },
              }}
              legend="date"
              legendFormatter={val => formatDate(val, 'short')}
              tooltipTranslationPath={'react.dashboard.products_timeline.products'}
              height={170}
            />
            */
          )}
          {view === VIEWS.CHANGES && (
            <Chart
              key="2"
              data={data}
              areas={{
                added: {
                  label: `${t('react.dashboard.products_timeline.products.added')} (${formatNumber(lastData.added, {
                    precision: 0,
                  })})`,
                },
                updated: {
                  label: `${t('react.dashboard.products_timeline.products.updated')} (${formatNumber(lastData.updated, {
                    precision: 0,
                  })})`,
                },
                noChange: {
                  label: `${t('react.dashboard.products_timeline.products.no_change')} (${formatNumber(
                    lastData.noChange,
                    { precision: 0 }
                  )})`,
                },
                removed: {
                  label: `${t('react.dashboard.products_timeline.products.removed')} (${formatNumber(lastData.removed, {
                    precision: 0,
                  })})`,
                },
              }}
              legend="date"
              legendFormatter={val => formatDate(val, 'short')}
              tooltipTranslationPath={'react.dashboard.products_timeline.products'}
              height={170}
            />
          )}
        </Fragment>
      )}
    </Tile>
  );
};

ProductsTimelineComponent.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  error: PropTypes.string,
  refetch: PropTypes.func,
};

export const ProductsTimeline = ({ organizationId }) => (
  <Query query={QUERY_DASHBOARD_PRODUCTS_TIMELINE_QUERY} variables={{ organizationId }}>
    {({ error, data, loading, refetch }) => (
      <ProductsTimelineComponent
        error={error}
        refetch={() => refetch()}
        loading={loading}
        data={data && data.organization && data.organization.productsTimeline}
      />
    )}
  </Query>
);

ProductsTimeline.propTypes = {
  organizationId: PropTypes.number.isRequired,
};
