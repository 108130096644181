import React, { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { groupBy } from 'lodash';
import { gql, useQuery, useMutation } from '@apollo/client';
import Filters from './Filters';
import Pagination, { setPageToUrl } from '../components/Pagination';
import { Row, Col, Select, Icon, Loader, SectionHeading, PageHeading, Button, EmptyState } from '../components';
import { formatInteger, formatDate, t } from '../i18n';
import NotificationMessage from '../components/notifications/NotificationMessage';

const NOTIFICATION_QUERY = gql`
  query Notification(
    $page: Int
    $limit: Int
    $kind: String
    $visibility: String
    $subjectClass: String
    $subjectId: BigInt
  ) {
    notificationsAllCount(subjectClass: $subjectClass, subjectId: $subjectId)
    notificationsUnseenCount(subjectClass: $subjectClass, subjectId: $subjectId)
    notificationsFiltredCount(kind: $kind, visibility: $visibility, subjectClass: $subjectClass, subjectId: $subjectId)
    notificationsUrl
    notifications(
      page: $page
      limit: $limit
      kind: $kind
      visibility: $visibility
      subjectClass: $subjectClass
      subjectId: $subjectId
    ) {
      id
      time
      icon
      subject
      message
      link {
        href
        children
        icon
      }
      relatedSubject {
        href
        icon
        children
      }
      organizations {
        name
        showLink
      }
      seen
      likes
      likesEnabled
      dislikes
      vote
      youtubeLink
      imageLink
      learnMoreLink {
        href
        icon
        children
      }
      author {
        firstName
        lastName
        image
      }
      state
    }
  }
`;

const MARK_ALL_SEEN_MUTATION = gql`
  mutation MarkAllInteractionsAsSeen {
    markInteractionsAsSeen {
      notificationsUnseenCount
      notifications {
        id
        seen
      }
      errors
    }
  }
`;

const perOptions = [20, 50, 100];

const daysAgo = day => {
  if (day === moment().format('YYYY-MM-DD')) return <span>{t('react.notification.today')}</span>;
  if (
    day ===
    moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD')
  ) {
    return <span>{t('react.notification.yesterday')}</span>;
  }
  return formatDate(day, 'long');
};

const NotificationIncidents = ({ subjectId, subjectClass }) => {
  const showOnlyIncidents = subjectClass && subjectId;
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [kind, setKind] = useState(showOnlyIncidents ? 'incidents' : 'all');
  const [visibility, setVisibility] = useState('all');

  const { data, loading, error } = useQuery(NOTIFICATION_QUERY, {
    variables: {
      page: parseInt(page, 10),
      limit: parseInt(limit, 10),
      kind,
      visibility,
      subjectClass,
      subjectId,
    },
    fetchPolicy: 'network-only',
  });

  const changeLimit = ({ target: { value } }) => {
    setLimit(value);
    setPage(0);
    setPageToUrl(0);
  };

  const selecetedID = new URLSearchParams(window.location.search);
  const searchParams = selecetedID.get('notification');

  useEffect(() => {
    if (!loading && searchParams) {
      const element = document.getElementById(searchParams);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        selecetedID.delete('notification');
        const newURL = `${window.location.pathname}${selecetedID && ['?', selecetedID].join('')}${
          window.location.hash
        }`;
        window.history.replaceState({}, '', newURL);
      }
    }
  }, [loading]);

  const [markAll, markAllQuery] = useMutation(MARK_ALL_SEEN_MUTATION);

  if (error) {
    return (
      <Col shrink nowrap className="mr-16">
        <Icon kind="error-circle" color="#ff493f" />
      </Col>
    );
  }

  const totalUnfilteredCount = data?.notificationsFiltredCount;
  const totalUnseenCount = data?.notificationsUnseenCount;
  const parsedData = groupBy(data?.notifications || [], item => moment(item.time).format('YYYY-MM-DD'));

  return (
    <React.Fragment>
      {showOnlyIncidents && (
        <Row justifyBetween center className="mb-16">
          <PageHeading spacing={0}>{t('views.organizations.menu.issues')}</PageHeading>
        </Row>
      )}
      {!showOnlyIncidents && (
        <Row justifyBetween center className="mb-16" style={{ minHeight: '32px' }}>
          <PageHeading spacing={0}>{t('react.notification.page_heading')}</PageHeading>
          {totalUnseenCount > 0 && (
            <Button
              secondary
              disabled={markAllQuery.loading}
              data-test-id="notifications-mark_all_as_seen_button"
              onClick={() =>
                markAll().then(
                  () =>
                    typeof window.NotificationCenter !== 'undefined' &&
                    new window.NotificationCenter().show_success(t('react.notification.mark_all_as_seen_success'))
                )
              }
            >
              {t('react.notification.mark_all_as_seen')}
            </Button>
          )}
        </Row>
      )}
      {!showOnlyIncidents && (
        <Row center className="mb-16">
          <Col shrink>
            <Filters
              value={kind}
              collection={[
                { value: 'all', label: t('react.notification.all'), onlyIconText: true },
                { value: 'incidents', icon: 'warning', tooltipText: t('react.notification.incidents_tooltip') },
                { value: 'news_articles', icon: 'mail', tooltipText: t('react.notification.news_articles_tooltip') },
                { value: 'app_updates', icon: 'gift', tooltipText: t('react.notification.app_updates_tooltip') },
              ]}
              onChange={setKind}
              name="notifications[filter]"
              id="notification_filter"
              onlyIcons
              size="medium"
            />
          </Col>
          <Col shrink>
            <Filters
              value={visibility}
              collection={[
                { value: 'all', label: t('react.notification.all') },
                { value: 'seen', label: t('react.notification.seen') },
                { value: 'unseen', label: t('react.notification.unseen') },
              ]}
              onChange={setVisibility}
              name="no[visibility_filter]"
              id="no_visibility_filter"
              size="medium"
            />
          </Col>
        </Row>
      )}
      {loading && (
        <Col className="mt-32" shrink nowrap center>
          <Loader size="big" />
        </Col>
      )}
      {!loading && totalUnfilteredCount === 0 && (
        <EmptyState
          icon="notifications_icon"
          heading={t(`no_content_${visibility}`, { scope: 'react.notification_platform' })}
          data-test-id="empty-state"
          hasBorder
        />
      )}
      {!loading &&
        totalUnfilteredCount > 0 &&
        Object.keys(parsedData).map(day => (
          <div key={day}>
            <SectionHeading spacing={8}>{daysAgo(day)}</SectionHeading>
            {parsedData[day].map(message => (
              <div className="Message" key={message.id} data-test-id="notification-message">
                <NotificationMessage selecetedID={searchParams} {...message} />
              </div>
            ))}
          </div>
        ))}
      {!loading && totalUnfilteredCount !== 0 && (
        <Row className="mt-16">
          <Col>
            <Pagination
              data-test-id="pagination-info"
              data-total={totalUnfilteredCount}
              current={page}
              maxPages={Math.ceil(totalUnfilteredCount / limit)}
              changePage={e => () => setPage(e + 1)}
            >
              <Col shrink>
                {t('react.show', {
                  default: 'Show',
                })}
              </Col>
              <Col shrink nowrap>
                <Select
                  id="notifications_perPage"
                  doNotUseInternalState
                  name="notifications[perPage]"
                  value={limit}
                  onChange={changeLimit}
                  collection={perOptions.map(value => ({
                    value,
                    label: t('react.items_count', { items: value }),
                  }))}
                />
              </Col>
              <Col shrink nowrap>
                {t('react.outof', {
                  default: 'out of %{total}',
                  total: formatInteger(totalUnfilteredCount || 0),
                })}
              </Col>
            </Pagination>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

NotificationIncidents.propTypes = {
  subjectId: PropTypes.number,
  subjectClass: PropTypes.string,
};

export default NotificationIncidents;
