import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Tooltip } from './index';
import { t, formatDate } from '../i18n';

const makeTimeRestrictionText = timeRestriction => {
  const always = t('views.time_restrictions.form_fields.always');

  const generate =
    typeof timeRestriction.generate === 'string' ? timeRestriction.generate === 'true' : timeRestriction.generate;

  return [
    generate
      ? t('views.time_restrictions.form_fields.generate')
      : t('views.time_restrictions.form_fields.do_not_generate'),

    t('from_date'),

    timeRestriction.date_from ? formatDate(timeRestriction.date_from, 'short') : always,

    t('to_date'),

    timeRestriction.date_to ? formatDate(timeRestriction.date_to, 'short') : always,
  ].join(' ');
};
const TimeRestrictionBadge = ({ timeRestriction }) =>
  (timeRestriction && (timeRestriction.date_from || timeRestriction.date_to) && (
    <Tooltip text={makeTimeRestrictionText(timeRestriction)}>
      <div className="Text Text--gray">
        <Icon className="mr-4" kind="clock" inheritColor />
      </div>
    </Tooltip>
  )) ||
  null;

TimeRestrictionBadge.propTypes = {
  children: PropTypes.any,
};

export default TimeRestrictionBadge;
