/* eslint-disable no-param-reassign */
import { t } from '../i18n';

document.addEventListener('DOMContentLoaded', () => {
  $(document).on(
    'click',
    '.js-disableButtonOnClick [type="submit"], .js-disableButtonOnClick [kind="primary"], .js-disable-submit-button-on-click',
    e => {
      const tagName = e.target.tagName;
      const button =
        tagName === 'input' || tagName === 'button' || tagName === 'a'
          ? e.target
          : e.target.closest('button, input, a');

      setTimeout(() => {
        button.setAttribute('disabled', 'disabled');
        button.dataset.originalText = button.innerText;
        button.innerText = t('loading_button_text');
      }, 20);
    }
  );
});

window.restoreActionBarButtonFromLoading = () => {
  Array.from(
    document.querySelectorAll(
      '.js-disableButtonOnClick [type="submit"], .js-disableButtonOnClick [kind="primary"], .js-disable-submit-button-on-click'
    )
  ).map(button => {
    button.disabled = null;
    if (button.dataset.originalText) {
      button.innerText = button.dataset.originalText;
    }
    return false;
  });
};
