import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Icon } from '../index';

const Hint = ({ icon = 'info', ...rest }) => (
  <Tooltip {...rest}>
    <Icon kind={icon} />
  </Tooltip>
);

Hint.propTypes = {
  icon: PropTypes.string,
};

export default Hint;
