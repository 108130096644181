import { omitBy } from 'lodash';
import React from 'react';
import variables from './variables';
import { setupColumn } from './setupColumn';
import { LinkCell, MainIdentifierCell } from './cells';
import { t } from '../../../i18n';
import ImagePreview from '../../ImagePreview';

const mapping = {
  ...omitBy(
    variables,
    ({ category, cell }) =>
      category === 'number_formatting' ||
      category === 'text_transformations' ||
      category === 'array_setup' ||
      cell === MainIdentifierCell
  ),
  ...setupColumn({
    id: 'randomPreviewValue',
    graphqlFields: 'randomPreviewValue',
    width: '80px',
    header: () =>
      t('sitelink_settings.form.preview', {
        default: 'Image preview',
      }),
    // eslint-disable-next-line react/prop-types
    resolveChildren: ({ randomPreviewValue }) => <ImagePreview url={randomPreviewValue?.[0]} size={50} />,
    fixed: 'left',
    required: true,
  }),
  ...setupColumn({
    id: 'name',
    graphqlFields: ['name', 'previewLink', 'editLink', 'randomPreviewValue', 'duplicateCopyLink', 'deleteLink'],
    cell: LinkCell,
    resolveChildren: ({ name, previewLink }) => ({ link: previewLink, text: name }),
    header: () => t('react.tables.variables.name'),
  }),
  ...setupColumn({
    id: 'dataSourceName',
    graphqlFields: { dataSource: ['name', 'showLink'] },
    order: 'data_source_name',
    cell: LinkCell,
    header: () => t('activerecord.attributes.campaign_setting.data_source'),
    resolveChildren: ({ dataSource }) => ({
      text: dataSource && dataSource.name,
      link: dataSource && dataSource.showLink,
    }),
  }),
};

export default mapping;
