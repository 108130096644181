import React from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { Button } from '../index';
import { t } from '../../i18n';
import useInputState from '../useInputState';

const getElement = key => Array.from(document.querySelectorAll(`[name*="[${key}]"]:not(:disabled)`)).reverse()[0];

const handleAll = (inputs, shouldSelect = false) => {
  inputs.forEach(key => {
    const input = getElement(key);
    input.checked = shouldSelect;
  });
  getElement(inputs[0]).dispatchEvent(new window.Event('change', { bubbles: true }));
};

const SelectAllButton = ({ inputs }) => {
  const tempInputs = {};
  inputs.forEach(inp => {
    const { state } = useInputState({ name: inp, useExactNames: true });
    tempInputs[inp] = state === '1';
  });

  const allSelected = inputs.every(key => tempInputs[key]);

  return (
    <Button secondary size="small" className="mb-16" onClick={() => handleAll(inputs, !allSelected)}>
      {t(allSelected ? 'deselect_all' : 'select_all', { scope: 'react.scraper' })}
    </Button>
  );
};

SelectAllButton.propTypes = {
  inputs: PropTypes.array,
};

export default hot(SelectAllButton);
