/* eslint-disable no-nested-ternary */
import React from 'react';
import { uniq } from 'lodash';
import { PropTypes } from 'prop-types';
import { Transition } from 'react-transition-group';
import { t, formatInteger, formatPercentage } from '../../i18n';
import { SubscriptionTable, FeatureWithIcon, UsagePrice } from './index';
import { Link, Row } from '../index';
import { tableColumnsByPeriodType } from './constants';
import FeatureChart from './FeatureChart';

const DURATION = 450;
const TRANSITION = { transition: `all ${DURATION}ms ease-in-out` };
const TD_TRANS_STYLES = {
  entering: { marginTop: '-50%', padding: '0' },
  entered: { marginTop: '0', padding: '16px' },
  exiting: { marginTop: '-50%', padding: '0' },
  exited: { marginTop: '-50%', padding: '0' },
};

const HEADERS = {
  future: {
    spend: 'feature_period.header.future.spend.until_yesterday',
    fix: 'feature_period.header.future.fix.until_yesterday',
    pricing_level: 'feature_period.header.future.pricing_level_until_yesterday',
  },
  not_future: {
    spend: 'feature_period.header.not_future.spend.until_yesterday',
    fix: 'feature_period.header.not_future.fix.until_yesterday',
    pricing_level: 'feature_period.header.not_future.pricing_level_until_yesterday',
  },
};

const AddForecast = (Component, displayName) => {
  const Wrapper = props => (
    <React.Fragment>
      <td className="Sticky-Cell">
        <Component {...props} />
      </td>

      {props.item.forecast && (
        <td className="Sticky-Cell">
          <Component {...props} tableData={{ ...props.tableData, ...props.tableData.forecast, forecast: true }} />
        </td>
      )}
    </React.Fragment>
  );
  if (displayName) {
    // eslint-disable-next-line no-param-reassign
    Component.displayName = displayName;
  }
  Wrapper.propTypes = {
    item: PropTypes.object,
    tableData: PropTypes.object,
  };
  Wrapper.displayName = `AddForecast(${Component.displayName})`;
  return Wrapper;
};

const PriceResolver = AddForecast(
  ({ tableData }) => (
    <UsagePrice value={tableData.price} condition={tableData.tarif === 'trial'}>
      <Row center inline shrink>
        <span className="Text Text--softGray Text--default">{tableData.price}</span>
        <span className="ml-4 Text Text--default">{t('views.subscription.free_in_trial')}</span>
      </Row>
    </UsagePrice>
  ),
  'Price'
);

const PricingFormater = ({
  tableData: { spendLimit, usageLimit = 0, syncCount = 4, priceCalculation, percents, featuresPricing, price },
  pricing: { resolvedBy },
}) => {
  if (resolvedBy === 'spend') {
    const translationKey = `feature_period.price_resolver.price_from.spend.${priceCalculation}`;
    const index = featuresPricing.findIndex(({ spend }) => spend === spendLimit);

    return {
      value: t(translationKey, { spendLimit, percent: formatPercentage(percents * 100), fixedPrice: price }),
      index: index < 0 ? 0 : index,
    };
  }

  let index = featuresPricing.findIndex(({ count }) => count === usageLimit);

  if (resolvedBy === 'domains_count' || resolvedBy === 'usage_and_sync_count') {
    const countKey = parseInt(usageLimit, 10) === 1 ? 'one' : 'any';
    const translationKey = `feature_period.price_resolver.price_from.${resolvedBy}.${countKey}`;

    if (resolvedBy === 'usage_and_sync_count') {
      const customFeaturesPricing = featuresPricing.filter(f => f.syncCount === syncCount);
      index = customFeaturesPricing.findIndex(({ count }) => count === usageLimit);
      const translation =
        syncCount > 4 ? translationKey : 'feature_period.price_resolver.price_from.usage_and_sync_count.unlimited';
      return {
        value: t(translation, { usageLimit: formatInteger(usageLimit), syncCount }),
        index,
        customFeaturesPricing,
      };
    }

    return { value: t(translationKey, { usageLimit: formatInteger(usageLimit), syncCount }), index };
  }

  return {
    value: t(resolvedBy, { scope: 'feature_period.price_resolver.price_from', usageLimit: formatInteger(usageLimit) }),
    index,
  };
};

const PricingResolver = AddForecast(({ showPricing, ...rest }) => {
  const { value, index, customFeaturesPricing } = PricingFormater(rest);
  return (
    <Link
      onClick={() =>
        showPricing({
          ...rest,
          pricingInfo: customFeaturesPricing || rest.tableData.featuresPricing,
          feature: rest.tableData.feature,
          index,
        })
      }
    >
      {value}
    </Link>
  );
}, 'Pricing');

const SubjectResolver = AddForecast(
  ({ tableData: { subjectId, subjectName } }) =>
    subjectId ? (
      <FeatureWithIcon
        feature="data_source"
        featureHuman={subjectName}
        url={subjectId && `data_sources/${subjectId}`}
      />
    ) : null,
  'Subject'
);

const ValueResolver = AddForecast(({ tableData, pricing, showPricing }) => {
  if (pricing.resolvedBy === 'domains_count') {
    const specificKey = tableData.usageCount === 1 ? 'one' : 'any';
    return (
      <div>
        {tableData?.arrayUsage?.length > 0 ? (
          <Link
            onClick={() =>
              showPricing({ pricingInfo: tableData.arrayUsage, feature: 'feature', resolvedBy: pricing.resolvedBy })
            }
          >
            {t(`react.fb_features.domains_count.${specificKey}`, { domains: formatInteger(tableData.usageCount) || 0 })}
          </Link>
        ) : (
          t(`react.fb_features.domains_count.${specificKey}`, { domains: formatInteger(tableData.usageCount) || 0 })
        )}
      </div>
    );
  }
  if (pricing.resolvedBy === 'spend') {
    return <div>{tableData.spend || 0}</div>;
  }
  if (pricing.resolvedBy === 'usage_and_sync_count') {
    return (
      <div>
        {t('react.fb_features.usage_and_sync_count', {
          products: formatInteger(tableData.usageCount) || 0,
          synces: tableData.syncCount || 4,
        })}
      </div>
    );
  }
  return <div>{t('react.fb_features.products', { products: formatInteger(tableData.usageCount) || 0 })}</div>;
}, 'Value');

const BASIC_TABLE = [
  {
    header: ({ period, resolvedBy }) =>
      t(HEADERS[period === 'future' ? 'future' : 'not_future'][resolvedBy === 'spend' ? 'spend' : 'fix']),
    content: ValueResolver,
  },
  {
    header: ({ period }) => t(HEADERS[period === 'future' ? 'future' : 'not_future'].pricing_level),
    content: PricingResolver,
  },
];

const BASIC_TABLE_WITH_FORECAST = [
  {
    ...BASIC_TABLE[0],
    forecast: {
      header: ({ resolvedBy }) =>
        t(`feature_period.header.not_future.${resolvedBy === 'spend' ? 'spend' : 'fix'}.end_of_the_period`),
    },
  },
  {
    ...BASIC_TABLE[1],
    forecast: {
      header: () => t('feature_period.header.not_future.pricing_level_end_of_the_period'),
    },
  },
];

const COMPOUND_TABLE = [
  {
    header: () => t('default_generator_name.data_source'),
    content: SubjectResolver,
  },
  ...BASIC_TABLE,
  {
    header: ({ period }) =>
      t(`feature_period.header.${period === 'future' ? 'future' : 'not_future'}.price_until_yesterday`),
    content: PriceResolver,
  },
];

const COMPOUND_TABLE_WITH_FORECAST = [
  {
    header: () => t('default_generator_name.data_source'),
    content: SubjectResolver,
  },
  ...BASIC_TABLE_WITH_FORECAST,
  {
    header: () => t('feature_period.header.not_future.price_until_yesterday'),
    content: PriceResolver,
    forecast: {
      header: () => t('feature_period.header.not_future.price_end_of_the_period'),
    },
  },
];

const HeaderResolver = ({ header, itemForecast, pricing, period }) => (
  <React.Fragment>
    <th>{header({ ...pricing, period })}</th>
    {itemForecast && <th>{itemForecast?.header(pricing)}</th>}
  </React.Fragment>
);

const composeTables = period => {
  if (period === 'future' || period === 'past') {
    return { basic: [...BASIC_TABLE], compound: [...COMPOUND_TABLE] };
  }

  return { basic: [...BASIC_TABLE_WITH_FORECAST], compound: [...COMPOUND_TABLE_WITH_FORECAST] };
};

const PricingTable = ({ item: tableData, period, ...rest }) => {
  const TABLES = composeTables(period);
  const allUsages = uniq(
    tableData.usageDecompositionParsed.concat(tableData?.forecast?.usageDecompositionParsed || []).map(r => r.subjectId)
  );

  if (allUsages?.length === 0) {
    return (
      <table className="Sticky-Table ma-0">
        <thead>
          <tr className="Sticky-Header">
            {TABLES.basic.map(({ header, forecast }, index) => (
              <HeaderResolver
                key={['nonUsageDecompositionTable', 'header', index].join('-')}
                header={header}
                period={period}
                itemForecast={forecast}
                pricing={tableData?.featuresPricing[0]}
              />
            ))}
          </tr>
        </thead>
        <tbody className="Sticky-Body">
          <tr className="pt-4">
            {TABLES.basic.map((item, index) => {
              const Component = item.content;
              return (
                <Component
                  {...rest}
                  key={['nonUsageDecompositionTable', 'body', index].join('-')}
                  tableData={tableData}
                  item={item}
                  pricing={tableData?.featuresPricing[0]}
                />
              );
            })}
          </tr>
        </tbody>
      </table>
    );
  }
  return (
    <table className="Sticky-Table ma-0">
      <thead>
        <tr className="Sticky-Header">
          {TABLES.compound.map(({ header, forecast }, index) => (
            <HeaderResolver
              key={['usageDecompositionTable', 'header', index].join('-')}
              header={header}
              period={period}
              itemForecast={forecast}
              pricing={tableData?.featuresPricing[0]}
            />
          ))}
        </tr>
      </thead>
      <tbody className="Sticky-Body">
        {allUsages.map(subjectId => (
          <tr key={subjectId}>
            {TABLES.compound.map((item, index) => {
              const Component = item.content;
              const used = tableData.usageDecompositionParsed.find(r => r.subjectId === subjectId);
              const forecast = tableData?.forecast?.usageDecompositionParsed.find(f => f.subjectId === subjectId);

              return (
                <Component
                  {...rest}
                  key={['usageDecompositionTable', 'body', index].join('-')}
                  tableData={{
                    ...(used || { subjectId: forecast.subjectId, subjectName: forecast.subjectName }),
                    forecast,
                    featuresPricing: tableData?.featuresPricing,
                    feature: tableData?.feature,
                  }}
                  item={item}
                  pricing={tableData?.featuresPricing[0]}
                />
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const AdditionalContent = ({ in: inProp, maxRows, consolidated, item, ...rest }) => (
  <Transition
    in={inProp}
    duration={DURATION}
    timeout={{
      appear: DURATION / 4,
      enter: DURATION / 5,
      exit: DURATION,
    }}
    mountOnEnter
    unmountOnExit
  >
    {state => (
      <tr className="noHover">
        <td
          style={{
            backgroundColor: '#F2F3F4',
            height: '0px !important',
            padding: '0px',
            border: 'none',
          }}
          colSpan={maxRows}
        >
          <div
            style={{
              ...TRANSITION,
              ...TD_TRANS_STYLES[state],
              lineHeight: 'auto',
              height: '100% !important',
              width: '100%',
            }}
            className="ppcbee-scrollbar"
          >
            <div className="Sticky-Wrapper Sticky-Wrapper--border" style={{ overflow: 'hidden' }}>
              {!consolidated ? (
                <React.Fragment>
                  <PricingTable {...rest} item={item} />
                  <div className="delimiter" />
                  <FeatureChart item={item} scatteredLabels={item?.feature === 'feed_export'} />
                </React.Fragment>
              ) : (
                <SubscriptionTable columns={tableColumnsByPeriodType[rest.period]} {...rest} lines={item.lines} />
              )}
            </div>
          </div>
        </td>
      </tr>
    )}
  </Transition>
);

PricingFormater.propTypes = {
  tableData: PropTypes.object,
  pricing: PropTypes.object,
};

HeaderResolver.propTypes = {
  header: PropTypes.func,
  itemForecast: PropTypes.object,
  period: PropTypes.string,
  pricing: PropTypes.object,
};
ValueResolver.propTypes = {
  isSpend: PropTypes.bool,
  item: PropTypes.object,
  showPricing: PropTypes.func,
  tableData: PropTypes.object,
};
PricingTable.propTypes = {
  item: PropTypes.object,
  period: PropTypes.string,
};
AdditionalContent.propTypes = {
  consolidated: PropTypes.bool,
  in: PropTypes.bool,
  item: PropTypes.object,
  maxRows: PropTypes.number,
};

export default AdditionalContent;
