import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { gql, useLazyQuery, NetworkStatus } from '@apollo/client';
import SmartSelect from '../SmartSelect';
import { Ellipsis, Button, Row, Col } from '../../index';

export const Asset = ({ title, picture }) => (
  <React.Fragment>
    <Row center>
      <Col shrink>
        <img src={picture} alt={title} />
      </Col>
      <Col grow>
        <Ellipsis>{title}</Ellipsis>
      </Col>
    </Row>
  </React.Fragment>
);
Asset.propTypes = {
  title: PropTypes.string,
  picture: PropTypes.string,
};

const FB_AD_VIDEO_QUERY = gql`
  query($organizationId: BigInt!, $campaignSettingId: BigInt!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        fbAdVideos {
          id
          title
          picture
        }
      }
    }
  }
`;

const FBAdVideoSelect = memo(({ organizationId, campaignSettingId, value, ...rest }) => {
  const [findOptions, { refetch, error, data, called, networkStatus }] = useLazyQuery(FB_AD_VIDEO_QUERY, {
    variables: { organizationId, campaignSettingId },
    notifyOnNetworkStatusChange: true,
  });

  const loading = networkStatus !== NetworkStatus.ready && !error;

  const collection = [];
  // ensure that selected image is allways added as option
  if (value.length > 0) {
    const { id, picture, title } = value.startsWith('{') ? JSON.parse(value) : {};
    collection.push({ value, originalLabel: title, label: <Asset id={id} title={title} picture={picture} /> });
  }

  const asyncOptions = (
    (data && data.organization && data.organization.campaignSetting && data.organization.campaignSetting.fbAdVideos) ||
    []
  )
    .map(asset => {
      const optionValue = JSON.stringify({ id: asset.id, picture: asset.picture, title: asset.title });
      if (collection.find(c => c.value === optionValue)) {
        return null;
      }

      return {
        value: optionValue,
        originalLabel: asset.title,
        label: <Asset {...asset} />,
      };
    })
    .filter(x => !!x);

  if (value.length > 0 && !called) {
    findOptions();
  }

  let errorElement = null;
  if (error) {
    let errorMessage = null;
    try {
      errorMessage = error.networkError.result.error.message;
    } catch (e) {
      errorMessage = error.message;
    }

    errorElement = (
      <span className="ValidationError">
        {errorMessage}
        <a className="Link ml-8" onClick={() => refetch()}>
          {window.reacti18n.pla_campaign_preview.try_again}
        </a>
      </span>
    );
  }

  return (
    <React.Fragment>
      <Row center>
        <Col grow>
          <SmartSelect
            className="SmartSelect--imageSelector"
            isLoading={loading}
            asyncOptions={asyncOptions}
            onMenuOpen={() => !called && findOptions()}
            value={value}
            asyncLoadOptions
            {...rest}
            collection={collection}
          />
        </Col>
        {refetch && (
          <Col shrink>
            <Button tertiary onlyIcon icon="refresh" onClick={() => refetch()} />
          </Col>
        )}
      </Row>
      {errorElement}
    </React.Fragment>
  );
});

FBAdVideoSelect.propTypes = {
  organizationId: PropTypes.number,
  campaignSettingId: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.string),
};

export default FBAdVideoSelect;
