import { gql } from '@apollo/client';
import { setupColumn } from './setupColumn';
import { FloatCell, IntegerCell, TimeAgoCell, HtmlCell, AdSystemCell, MainIdentifierWithSwitcherCell } from './cells';
import { FloatEdit, LinkEdit } from './editable';
import conditions from './conditions';
import { modalDataUrlOpener } from '../../RemoteModal';
import { t } from '../../../i18n';

const mapping = {
  ...setupColumn({
    id: 'name',
    graphqlFields: [
      'name',
      'editLink',
      'duplicateLink',
      'duplicateIntoCampaignsLink',
      'deleteLink',
      'enabled',
      'timeRestrictionStatus',
      { timeRestriction: ['tooltip', 'dateFrom', 'dateTo', 'generateString'] },
    ],
    order: 'name',
    required: true,
    cell: MainIdentifierWithSwitcherCell,
    width: '390px',
    fixed: 'left',
    editable: LinkEdit,
    editButtonAsProp: true,
    specialEditableCase: true,
    editQuery: ({ id }) => ({ enabled }) => {
      const changes = [];
      if (typeof enabled !== 'undefined') changes.push(`enabled: ${enabled}`);
      return gql`mutation UpdateBidRule($text: String) { updateBidRule(id: ${id}, name: $text, ${changes.join(
        ','
      )} ) { bidRule { id enabled name timeRestrictionStatus timeRestriction { tooltip id } } errors } }`;
    },
    header: () => t('activerecord.attributes.bid_rule.name'),
    resolveChildren: ({
      id,
      icon,
      name,
      editLink,
      duplicateLink,
      duplicateIntoCampaignsLink,
      deleteLink,
      enabled,
      timeRestrictionStatus,
      timeRestriction,
    }) => ({
      id,
      icon,
      status: enabled ? 'running' : 'paused',
      name,
      timeRestrictionStatus,
      timeRestrictionTooltip: timeRestriction?.tooltip,
      text: name,
      link: editLink,
      size: 'fullwithGrid',
      'data-test-id': `dropdown-action-${name}`,
      links: [
        {
          href: editLink,
          size: 'fullwithGrid',
          'data-test-id': 'edit-bid-rule-button',
          children: t('views.campaign_settings.index.edit'),
          'data-test-id-edit': name,
          icon: 'edit',
        },
        {
          href: duplicateLink,
          size: 'fullwithGrid',
          children: t('views.campaign_settings.index.duplicate'),
          'data-test-id-duplicate': name,
          icon: 'duplicate',
        },
        {
          href: duplicateIntoCampaignsLink,
          heading: t('views.duplicate_into_campaigns_button.duplicate_bid_rule', {
            default: 'Duplicate %{name}',
            name,
          }),
          ...modalDataUrlOpener({
            url: duplicateIntoCampaignsLink,
            size: 'medium',
            heading: t('views.campaign_settings.index.duplicate_to_campaigns_with_name', {
              default: 'Duplicate %{name} to campaign:',
              name,
            }),
          }),
          children: t('views.shared.duplicate_into_campaigns_button.duplicate_to'),
          'data-test-id-duplicate-to': name,
          icon: 'duplicate-to',
        },
        {
          href: deleteLink,
          children: t('views.campaign_settings.index.delete'),
          icon: 'trash',
          danger: true,
          'data-method': 'delete',
          'data-remote': 'true',
          'data-confirm': t('views.campaign_settings.index.delete_confirmation', { name }),
          'data-commit': t('views.campaign_settings.index.yes', { default: 'Yes' }),
          'data-container': 'body',
          'data-test-id-delete': name,
          rel: 'nofollow',
        },
      ],
    }),
  }),
  ...setupColumn({
    id: 'bid',
    order: 'bid',
    cell: FloatCell,
    width: '120px',
    editable: FloatEdit,
    header: () => t('activerecord.attributes.bid_rule.bid'),
    editQuery: ({ id }) => bid =>
      gql`
        mutation {
          updateBidRule(id: ${id}, bid: ${bid}) {
            bidRule {
              id
              bid
            }
            errors
          }
        }
      `,
  }),
  ...setupColumn({
    id: 'numberOfProducts',
    cell: IntegerCell,
    order: 'number_of_products',
    required: true,
    header: () => t('activerecord.attributes.bid_rule.matching_products'),
  }),
  ...setupColumn({
    id: 'timeRestriction',
    graphqlFields: { timeRestriction: ['text', 'dateFrom', 'dateTo', 'generateString'] },
    cell: HtmlCell,
    required: true,
    header: () => t('activerecord.attributes.bid_rule.time_restrictions'),
    resolveChildren: ({ timeRestriction }) => timeRestriction?.text || '',
  }),
  ...setupColumn({
    id: 'system',
    cell: AdSystemCell,
    required: true,
    header: () => t('activerecord.attributes.bid_rule.system'),
    resolveChildren: ({ system }) => system,
  }),
  ...setupColumn({
    id: 'createdAt',
    order: 'created_at',
    category: 'setup',
    fixed: 'right',
    reverseOrder: true,
    header: () => t('attributes.created_at'),
    cell: TimeAgoCell,
  }),
  ...setupColumn({
    id: 'updatedAt',
    order: 'updated_at',
    category: 'setup',
    fixed: 'right',
    reverseOrder: true,
    header: () => t('attributes.updated_at'),
    cell: TimeAgoCell,
  }),
  ...conditions,
};

export default mapping;
