import React from 'react';
import { gql } from '@apollo/client';
import { CUSTOM_STATES } from './accountAccesses';
import { formatNumberInflection, t } from '../../../i18n';
import { IntegerCell, SimpleListModalCell, StateCell, LinkCell, SwitchCellWithOptions, TextCell } from './cells';
import { setupColumn } from './setupColumn';
import { Text } from '../../index';

const formatGoogleAdsAccountNumber = id => {
  const idstring = id.toString();
  if (idstring.length === 10 && idstring.match(/\d+/)) {
    return [idstring.slice(0, 3), idstring.slice(3, 6), idstring.slice(6, 10)].join('-');
  }
  return id;
};

const mapping = {
  ...setupColumn({
    id: 'name',
    required: true,
    fixed: 'left',
    graphqlFields: 'name',
    cell: TextCell,
    header: () => t('name', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'dataSourceName',
    required: true,
    fixed: 'left',
    graphqlFields: ['dataSourceName', 'dataSourceLink', 'dataSourceIcon'],
    cell: LinkCell,
    header: () => t('data_source_name', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ dataSourceName, dataSourceLink, dataSourceIcon }) => ({
      text: dataSourceName,
      icon: dataSourceIcon,
      link: dataSourceLink,
    }),
  }),
  ...setupColumn({
    id: 'googleSpreadsheetId',
    required: true,
    cell: LinkCell,
    header: () => t('google_spreadsheet_id', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ googleSpreadsheetId }) => ({
      text: googleSpreadsheetId,
      link: `https://docs.google.com/spreadsheets/d/${googleSpreadsheetId}`,
      external: true,
    }),
  }),
  ...setupColumn({
    id: 'googleSheetName',
    required: true,
    cell: TextCell,
    header: () => t('google_sheet_name', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'uniqueColumn',
    required: true,
    cell: TextCell,
    header: () => t('unique_column', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'currency',
    graphqlFields: 'currency',
    cell: TextCell,
    header: () => t('currency', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'systemId',
    graphqlFields: 'systemId',
    cell: TextCell,
    header: () => t('account_id', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ systemId }) => formatGoogleAdsAccountNumber(systemId),
  }),
  ...setupColumn({
    id: 'fbBusinessName',
    graphqlFields: 'fbBusinessName',
    cell: TextCell,
    header: () => t('fb_business_name', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'systemId',
    graphqlFields: 'systemId',
    cell: TextCell,
    header: () => t('account_id', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'mechantCenterWebsiteUrl',
    graphqlFields: 'mechantCenterWebsiteUrl',
    cell: TextCell,
    header: () => t('mechant_center_website_url', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'status',
    cell: StateCell,
    header: () => t('status', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ status }) => ({
      state: status === 'ok' ? 'active' : 'warning',
      customIcons: CUSTOM_STATES,
      error: status !== 'ok' ? status : undefined,
    }),
  }),
  ...setupColumn({
    id: 'fbPages',
    graphqlFields: 'fbPages',
    cell: SimpleListModalCell,
    header: () => t('fb_pages', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ fbPages }) => {
      if (!fbPages || fbPages?.length === 0) return null;

      return {
        doNotUseModal: fbPages?.length === 1,
        text:
          fbPages?.length === 1 ? (
            <div>
              {JSON.parse(fbPages[0]).name}
              <p>
                {JSON.parse(fbPages[0])?.['instagram_accounts']?.map(y => (
                  <Text className="pl-8" size="sm" key={y?.id}>
                    {' '}
                    {y?.username} <Text size="sm">({y?.id})</Text>
                  </Text>
                ))}
              </p>
            </div>
          ) : (
            formatNumberInflection({
              count: fbPages?.length,
              scope: 'react.tables.sub_account_accesses.pages_modal',
            })
          ),
        modalHeader: t('fb_pages', { scope: 'react.tables.sub_account_accesses' }),
        modalContent: (
          <ul className="List List--bullets">
            {fbPages.map(x => {
              const parsedItem = JSON.parse(x);
              return (
                <li key={parsedItem?.id} className="List-item">
                  <div>
                    <strong>{parsedItem?.name} </strong>{' '}
                    <Text className="pl-16" size="sm">
                      ({parsedItem?.id})
                    </Text>
                  </div>
                  {parsedItem?.['instagram_accounts']?.map(y => (
                    <Text tag="p" className="pl-16" key={y?.id}>
                      {y?.username}{' '}
                      <Text className="pl-16" size="sm">
                        ({y?.id})
                      </Text>
                    </Text>
                  ))}
                </li>
              );
            })}
          </ul>
        ),
      };
    },
  }),
  ...setupColumn({
    id: 'fbProductCatalogs',
    graphqlFields: 'fbProductCatalogs',
    cell: SimpleListModalCell,
    header: () => t('fb_product_catalogs', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ fbProductCatalogs }) => {
      if (typeof fbProductCatalogs === 'undefined') return undefined;
      if (!fbProductCatalogs || fbProductCatalogs?.length === 0) return null;

      return {
        doNotUseModal: fbProductCatalogs?.length === 1,
        text:
          fbProductCatalogs?.length === 1 ? (
            <p className="Text">
              {JSON.parse(fbProductCatalogs[0]).name}
              <Text size="sm" className="pl-16">
                {' '}
                ({JSON.parse(fbProductCatalogs[0]).id})
              </Text>
            </p>
          ) : (
            formatNumberInflection({
              count: fbProductCatalogs?.length,
              scope: 'react.tables.sub_account_accesses.fb_product_catalogs_modal',
            })
          ),
        modalHeader: t('fb_product_catalogs', { scope: 'react.tables.sub_account_accesses' }),
        modalContent: (
          <ul className="List List--bullets">
            {fbProductCatalogs.map(x => {
              const parsedItem = JSON.parse(x);
              return (
                <li key={parsedItem?.id} className="List-item">
                  <p className="Text">
                    {parsedItem?.name}{' '}
                    <Text className="pl-16" size="sm">
                      ({parsedItem?.id})
                    </Text>
                  </p>
                </li>
              );
            })}
          </ul>
        ),
      };
    },
  }),
  ...setupColumn({
    id: 'fbPixel',
    graphqlFields: 'fbPixel',
    cell: TextCell,
    header: () => t('fb_pixel', { scope: 'react.tables.sub_account_accesses' }),
    // eslint-disable-next-line react/prop-types
    resolveChildren: ({ fbPixel }) => {
      const pixel = fbPixel && JSON.parse(fbPixel);
      if (!pixel) {
        return '';
      }
      return (
        <span className="Text">
          {pixel.name} <Text size="sm">({pixel.id})</Text>
        </span>
      );
    },
  }),
  ...setupColumn({
    id: 'campaignsCount',
    cell: IntegerCell,
    header: () => t('campaigns_count', { scope: 'react.tables.sub_account_accesses' }),
  }),
  ...setupColumn({
    id: 'linkedGoogleAdsAccounts',
    graphqlFields: 'mechantCenterAdwordsLinks',
    cell: SimpleListModalCell,
    header: () => t('linked_google_ads_accounts', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ mechantCenterAdwordsLinks }) => {
      if (typeof mechantCenterAdwordsLinks === 'undefined') return undefined;
      if (!mechantCenterAdwordsLinks || mechantCenterAdwordsLinks?.mechantCenterAdwordsLinks === 0) {
        return null;
      }

      return {
        doNotUseModal: mechantCenterAdwordsLinks?.length === 1,
        text:
          mechantCenterAdwordsLinks?.length === 1 ? (
            <p className="Text">{mechantCenterAdwordsLinks[0]}</p>
          ) : (
            formatNumberInflection({
              count: mechantCenterAdwordsLinks?.length,
              scope: 'react.tables.sub_account_accesses.linked_google_ads_accounts_modal',
            })
          ),
        modalHeader: t('linked_google_ads_accounts', { scope: 'react.tables.sub_account_accesses' }),
        modalContent: (
          <ul className="List List--bullets">
            {mechantCenterAdwordsLinks.map(x => (
              <li key={x} className="List-item">
                {formatGoogleAdsAccountNumber(x)}
              </li>
            ))}
          </ul>
        ),
      };
    },
  }),
  ...setupColumn({
    id: 'visible',
    cell: SwitchCellWithOptions,
    graphqlFields: ['id', 'visible', 'visibilityView', 'visibilityDisabled'],
    header: () => t('visible', { scope: 'react.tables.sub_account_accesses' }),
    resolveChildren: ({ visible, visibilityView, visibilityDisabled }) => ({
      value: visible,
      doNotShow: !visibilityView,
      disabled: visibilityDisabled,
    }),
    editQuery: ({ id }) => visible =>
      gql`mutation { updateSubAccessAccount(id: ${id}, visible: ${visible}) { subAccessAccount { id visibilityView visible visibilityDisabled } errors } }`,
  }),
};

export default mapping;
