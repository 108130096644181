/* eslint-disable no-unused-vars */
import Swiper from 'swiper/dist/js/swiper';

window.introSwiperInit = () => {
  if ($('#js-intro-swiper').length > 0 && !document.getElementById('js-intro-swiper').dataset.swiperInited) {
    document.getElementById('js-intro-swiper').dataset.swiperInited = true;
    const continueText = $('.js-switch-continue');
    const startText = $('.js-switch-start');
    const prevButton = $('.js-back');

    const introSwiper = new Swiper('#js-intro-swiper', {
      direction: 'horizontal',
      loop: false,
      keyboard: {
        enabled: true,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.js-continue',
        prevEl: '.js-back',
      },
      watchOverflow: true,
      on: {
        init() {
          if (this.slides.length === 1) {
            prevButton.addClass('d-none');
            continueText.addClass('d-none');
            startText.toggleClass('d-none js-send-api-call');
          } else {
            startText.slideUp('200', () => {
              continueText.slideDown();
              startText.removeClass('d-none');
            });
          }
        },
        slideChange() {
          if (this.activeIndex === this.slides.length - 1) {
            continueText.slideUp('120', () => {
              startText.slideDown();
            });
            startText.addClass('js-send-api-call');

            if (window.introVideoPlayer) {
              window.introVideoPlayer.playVideo();
            }
          } else {
            startText.slideUp('120', () => {
              continueText.slideDown();
            });
            startText.removeClass('js-send-api-call');

            if (window.introVideoPlayer && window.introVideoPlayer.getPlayerState() === 1) {
              window.introVideoPlayer.pauseVideo();
            }
          }
        },
      },
    });
  }
};
