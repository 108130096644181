/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { groupBy, compact } from 'lodash';
import { t, formatCurrency } from '../../i18n';
import { SubscriptionStatus, UsagePrice, CircleIcon } from './index';
import { Col, Row, Link, Tooltip } from '../index';
import FeatureWithIcon from './FeatureWithIcon';

export const POSSIBLE_REGULAR = 'possible_regular';
export const POSSIBLE_TRIAL = 'possible_trial';
export const REGULAR = 'regular';
export const TRIAL = 'trial';
export const NOT_ACTIVE = 'not_active';
export const ACTIVE = 'active';
export const CURRENTLY_ACTIVE_BUT_IT_WILL_END_SOON = 'currently_active_but_it_will_end_soon';
export const NOT_ACTIVE_CANCELED = 'not_active_canceled';
export const CURRENTLY_ACTIVE = 'currently_active';
export const FREE_TRIAL = 'free_trial';
export const PAUSED = 'paused';
export const STOPPED = 'stopped';
export const TRIAL_PASSED = 'trial_passed';
export const YET_ACTIVE = 'yet_active';

export const FEATURES = {
  campaign_products: {
    icon: 'menu-campaign',
    circleColor: '#19A7B0',
    heading: t('campaign_products', { scope: 'feature_period.features' }),
  },
  campaign_spend_search: {
    icon: 'menu-campaign',
    circleColor: '#19A7B0',
    heading: t('campaign_spend_search', { scope: 'feature_period.features' }),
  },
  campaign_spend_facebook: {
    icon: 'fb-retargeting',
    circleColor: '#706DE2',
    heading: t('campaign_spend_facebook', { scope: 'feature_period.features' }),
  },
  image_generator_campaigns: {
    icon: 'image-type',
    circleColor: '#9BCC63',
    heading: t('image_generator_campaigns', { scope: 'feature_period.features' }),
  },
  image_generator_feed_export: {
    icon: 'image-export',
    circleColor: '#9BCC63',
    heading: t('image_generator_feed_export', { scope: 'feature_period.features' }),
  },
  synchronizations_and_support: {
    icon: 'support',
    heading: t('synchronizations_and_support', { scope: 'feature_period.features' }),
  },
  data_source: {
    icon: 'url-ds',
  },
  scraper: {
    icon: 'scraper',
    circleColor: '#F06292',
    heading: t('scraper', { scope: 'feature_period.features' }),
  },
  feed_export: {
    icon: 'feed-export',
    circleColor: '#AC8EE3',
    heading: t('feed_export', { scope: 'feature_period.features' }),
  },
  synchronizations: {
    icon: 'refresh',
    circleColor: '#B28E6D',
    heading: t('synchronizations', { scope: 'feature_period.features' }),
  },
};

export const PRICING_TABLE_HEADER = ['maximum_usage', 'resolver', 'price'];

export const FEED_EXPORT = 'feed_export';

const columns = {
  feature: {
    key: 'feature',
    label: () => t('feature_period.header.feature'),
    content: memo(({ item }) => (
      <FeatureWithIcon size={'14px'} color="#596774" feature={item.feature} featureHuman={item.featureHuman} />
    )),
  },
  status: {
    key: 'status',
    label: () => t('feature_period.header.status'),
    content: memo(({ item, period }) => <SubscriptionStatus {...item} period={period} />),
  },
  price_until_yesterday: label => ({
    key: 'price_until_yesterday',
    label,
    content: memo(
      ({ item }) =>
        item.tarif !== POSSIBLE_TRIAL && (
          <UsagePrice value={item?.price} condition={item.tarif === 'trial'}>
            <Row center inline shrink>
              <span className="Text Text--softGray Text--default">{item.trialPrice}</span>
              <span className="ml-4 Text Text--default">{t('views.subscription.free_in_trial')}</span>
            </Row>
          </UsagePrice>
        )
    ),
  }),
  price_end_of_the_period: {
    key: 'price_end_of_the_period',
    label: () => t('feature_period.header.not_future.price_end_of_the_period'),
    content: memo(
      ({ item }) =>
        item.tarif !== POSSIBLE_TRIAL && (
          <UsagePrice value={item?.forecast?.price} condition={item.tarif === 'trial'}>
            <Row center inline shrink>
              <span className="Text Text--softGray Text--default">{item?.forecast?.price || item?.trialPrice}</span>
              <span className="ml-4 Text Text--default">{t('views.subscription.free_in_trial')}</span>
            </Row>
          </UsagePrice>
        )
    ),
  },
  period: {
    key: 'period',
    label: () => t('feature_period.header.period'),
    content: ({ item }) =>
      (item.beginsOn && item.tarif !== POSSIBLE_TRIAL && (
        <span>{`${item.beginsOn} - ${item?.endsOn || t('feature_period.not_limited_by_time')}`}</span>
      )) ||
      null,
  },
};

export const tableColumnsByPeriodType = {
  future: [
    columns.feature,
    columns.status,
    columns.price_until_yesterday(() => t('feature_period.header.future.price_until_yesterday')),
    columns.period,
  ],
  current: [
    columns.feature,
    columns.status,
    columns.price_until_yesterday(() => t('feature_period.header.not_future.price_until_yesterday')),
    columns.price_end_of_the_period,
    columns.period,
  ],
  current_past: [
    columns.feature,
    columns.status,
    columns.price_until_yesterday(() => t('feature_period.header.future.price_until_yesterday')),
    columns.period,
  ],
  past: [
    columns.feature,
    columns.status,
    columns.price_until_yesterday(() => t('feature_period.header.future.price_until_yesterday')),
    columns.period,
  ],
};

const getFeaturePrice = lines => lines.reduce((o, i) => o + (i?.numberPrice || 0), 0);

const isFeatureActive = lines => {
  if (lines.find(l => l.tarif === TRIAL)) return true;
  if (lines[0].feature === FEED_EXPORT) return lines.find(l => l?.usageCount > 0);

  return getFeaturePrice(lines) > 0;
};

export const consolidatedTableColumns = [
  {
    key: 'feature',
    label: () => t('feature_period.header.feature'),
    content: memo(({ item }) => <Link href={`${item.showLink}/subscription`}>{item.suborganization}</Link>),
  },
  {
    key: 'price',
    label: () => t('feature_period.header.price'),
    content: memo(({ currency, item }) => (
      <Row>{formatCurrency(getFeaturePrice(item.lines), { unit: currency || 'EUR' })}</Row>
    )),
  },
  {
    key: 'features_in_use',
    label: () => t('feature_period.header.features_in_use'),
    content: memo(({ item }) => (
      <Row>
        {Object.values(groupBy(item.lines, 'feature')).map(lines => {
          const isActive = isFeatureActive(lines);

          return (
            <Col key={lines[0].id} shrink>
              <Tooltip text={FEATURES[lines[0].feature].heading}>
                <CircleIcon feature={lines[0].feature} disable={!isActive} />
              </Tooltip>
            </Col>
          );
        })}
      </Row>
    )),
  },
];

export const synchronizationTableColumns = hideAction =>
  compact([
    {
      key: 'service',
      label: () => t('feature_period.header.service'),
      content: memo(() => (
        <FeatureWithIcon
          color="#596774"
          size={'14px'}
          feature="synchronizations"
          featureHuman={t('feature_period.features.synchronizations')}
        />
      )),
    },
    {
      key: 'synchronizations',
      label: () => t('feature_period.header.synchronization'),
      content: memo(({ item }) => item.syncFrequency),
    },
    !hideAction && {
      key: 'changeSubscription',
      label: () => t('feature_period.header.action'),
      content: ({ child }) => child,
    },
  ]);

export const synchronizationTableWithSupportColumns = hideAction =>
  compact([
    {
      key: 'service',
      label: () => t('feature_period.header.service'),
      content: memo(() => (
        <FeatureWithIcon
          color="#596774"
          size={'14px'}
          feature="synchronizations_and_support"
          featureHuman={t('feature_period.features.synchronizations_and_support')}
        />
      )),
    },
    {
      key: 'synchronizations',
      label: () => t('feature_period.header.synchronization'),
      content: memo(({ item }) => item.syncFrequency),
    },
    {
      key: 'support',
      label: () => t('feature_period.header.support'),
      content: memo(({ item }) => item.support),
    },
    !hideAction && {
      key: 'changeSubscription',
      label: () => t('feature_period.header.action'),
      content: ({ child }) => child,
    },
  ]);
