import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { HotKeys } from 'react-hotkeys';
import { hot } from 'react-hot-loader/root';
import uuid from 'uuid';
import cs from 'classnames';
import { reverse, find } from 'lodash';
import { TimeAgo, Row, Col, Button, IconSwitch, Icon, DropdownMenu, Link, Input } from '../../index';
import Layers from './Layers';
import arrayMove from '../../../utils/arrayMove';
import OptionsContext from '../OptionsContext';
import Preview from './Preview';
import { t } from '../../../i18n';
import { ArrangePanel } from './panels';
import objectTypes from './objectTypes';
import { PreviewSnippetModal, calculateSnippetProperties } from './index';

const ObjectPropertyHeader = ({ onBack, onDuplicate, onRemove, object, onObjectChange, onArrange }) => {
  const ObjectType = objectTypes[object.type];

  return (
    <React.Fragment>
      <Row center className="delimiter negative-ml-16 negative-mr-16 ph-16 pb-16">
        <Col shrink>
          <Button tertiary onlyIcon icon="arrow-left" onClick={onBack} />
        </Col>
        <Col shrink center>
          <OptionsContext.Consumer>
            {({ customImages }) =>
              ObjectType.meta.useIconInLayers ? (
                <Icon kind={ObjectType.getIcon(object, customImages)} size="24px" color="#596774" />
              ) : (
                <div style={{ width: '30px', height: '30px', overflow: 'hidden' }}>
                  <Preview
                    objects={[{ ...object, x: 0, y: 0 }]}
                    width={object.width}
                    height={object.height}
                    responsive
                  />
                </div>
              )
            }
          </OptionsContext.Consumer>
        </Col>
        <Col>
          <div className="Text">
            <Input
              doNotUseInternalState
              value={find(
                [
                  object['data-layer-name'],
                  object['dataLayerName'],
                  ObjectType.meta.description,
                  ObjectType.getDescription(object),
                ],
                x => typeof x === 'string'
              )}
              onChange={({ target: { value } }) => onObjectChange({ 'data-layer-name': value || '' })}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col grow>
          <ArrangePanel onArrange={onArrange} />
        </Col>
        <Col shrink onClick={e => e.stopPropagation()}>
          <IconSwitch
            onIcon="locked"
            offIcon="unlocked"
            id={`lockLayer-${object.uuid}`}
            label={t(object?.lockLayer ? 'lock' : 'unlock', { scope: 'react.image_generator.layer' })}
            testId={object?.lockLayer ? 'unlock_button' : 'lock_button'}
            doNotUseInternalState
            value={object.lockLayer}
            onChange={() => onObjectChange({ lockLayer: !object.lockLayer })}
          />
        </Col>
        <Col shrink onClick={e => e.stopPropagation()}>
          <IconSwitch
            onIcon="disabled-eye"
            offIcon="eye"
            id={`hideLayer-${object.uuid}`}
            label={t('react.image_generator.layer.hide')}
            doNotUseInternalState
            value={object.hideLayer}
            onChange={() => onObjectChange({ hideLayer: !object.hideLayer })}
          />
        </Col>
        <DropdownMenu dataTestId="previewPanelDropdown">
          <Link icon="duplicate" onClick={onDuplicate} data-test-id="duplicate-layer">
            {t('react.image_generator.layer.duplicate')}
          </Link>
          <Link icon="trash" onClick={onRemove} data-test-id="delete-layer">
            {t('react.image_generator.layer.delete')}
          </Link>
        </DropdownMenu>
      </Row>
    </React.Fragment>
  );
};

ObjectPropertyHeader.propTypes = {
  onBack: PropTypes.func,
  onDuplicate: PropTypes.func,
  onRemove: PropTypes.func,
  onObjectChange: PropTypes.func,
  object: PropTypes.object,
  onArrange: PropTypes.func,
};

const PreviewPanel = ({
  children,
  duplicateObjectAtIndex,
  hotkeysProps,
  modifierPreviewRoot,
  objects,
  propertyPanel,
  removeObjectAtIndex,
  renderPreviewRef,
  selectedObjectIndex,
  showHandler,
  updateObject,
  updateState,
  setPanel,
  selectObjects,
  selectedObjectsIndexes,
  panel,
  onArrange,
  handler,
  ...rest
}) => {
  const BOTTOM_SPACE = '48px';
  const { cannotEdit } = useContext(OptionsContext);
  useEffect(() => {
    if (selectedObjectIndex !== null && panel !== 'preview' && panel !== 'property') {
      setPanel('property');
    }
    if (selectedObjectIndex == null && panel === 'property') {
      setPanel('layers');
    }
  }, [selectedObjectIndex]);

  const [isModalOpen, setModalOpen] = React.useState(false);
  const [snippetObjects, setSnippetObjects] = React.useState([]);

  const closeModal = () => setModalOpen(false);

  const handleCalculate = () => {
    const snipObjects = Object.keys(objects)
      .filter(x => selectedObjectsIndexes.includes(parseInt(x, 10)))
      .map(x => objects[x]);

    const result = calculateSnippetProperties({ handler, snippetObjects: snipObjects });
    setSnippetObjects(result);

    setModalOpen(true);
  };

  const elements = (
    <HotKeys {...hotkeysProps} style={{ height: '100%', outline: 'none' }}>
      <Col height="100%">
        <Row
          shrink
          className={cs({
            'negative-ml-16 negative-mr-16 ph-16 mb-0':
              panel === 'property' || panel === 'history' || panel === 'layers',
            'pb-8': panel === 'property',
            delimiter: panel === 'property' || panel === 'history',
            'pb-16': panel === 'history',
          })}
        >
          <Col grow>
            {panel === 'property' && !!objects[selectedObjectIndex] && (
              <ObjectPropertyHeader
                onObjectChange={changes => updateObject(selectedObjectIndex, changes)}
                object={objects[selectedObjectIndex]}
                onBack={() => setPanel('layers')}
                onDuplicate={() => duplicateObjectAtIndex(selectedObjectIndex)}
                onRemove={() => removeObjectAtIndex(selectedObjectIndex)}
                onArrange={onArrange}
              />
            )}
            {panel === 'history' && (
              <Row center>
                <Col shrink>
                  <Button tertiary onlyIcon icon="arrow-left" onClick={() => setPanel('setup')} />
                </Col>
                <Col shrink>{t('react.image_generator.panel.history')}</Col>
              </Row>
            )}
            {panel !== 'property' && panel !== 'history' && (
              <ul className="TabNavigation d-flex negative-ml-16 negative-mr-16 negative-mt-8">
                <li className={cs('TabNavigation-item flex-1 text-center', { active: panel === 'setup' })}>
                  <a className="pv-8" onClick={() => setPanel('setup')}>
                    {t('react.image_generator.panel.setup')}
                  </a>
                </li>
                <li className={cs('TabNavigation-item flex-1 text-center', { active: panel === 'layers' })}>
                  <a className="pv-8" onClick={() => setPanel('layers')}>
                    {t('react.image_generator.panel.layers')}
                  </a>
                </li>
                <li className={cs('TabNavigation-item flex-1 text-center', { active: panel === 'preview' })}>
                  <a className="pv-8" onClick={() => setPanel('preview')}>
                    {t('react.image_generator.panel.preview')}
                  </a>
                </li>
              </ul>
            )}
          </Col>
        </Row>
        <Row style={{ display: panel === 'setup' ? 'flex' : 'none' }} grow>
          {panel === 'setup' && <div className="w-100 mt-24 ph-16">{children}</div>}
        </Row>
        <Row style={{ display: panel === 'property' ? 'flex' : 'none', position: 'relative' }} className="pt-16" grow>
          {panel === 'property' && (
            <div className="Grid--overflowWrapper negative-ml-16 Grid--overflowWrapper--withOverflowShadow ">
              <div className="pt-16 ph-16" style={{ paddingBottom: BOTTOM_SPACE }}>
                {propertyPanel || 'Please select element in editor'}
              </div>
            </div>
          )}
        </Row>
        <Row style={{ display: panel === 'history' ? 'flex' : 'none', position: 'relative' }} className="pt-16" grow>
          {panel === 'history' && (
            <div className="Grid--overflowWrapper negative-ml-16 Grid--overflowWrapper--withOverflowShadow ">
              <OptionsContext.Consumer>
                {({ nextStepsFromHistory, history, setHistory }) => (
                  <div className="ImageGenerator-history" style={{ paddingBottom: BOTTOM_SPACE }}>
                    {nextStepsFromHistory.map(his => (
                      <div className="PreviewItem" key={uuid()}>
                        <a className="Link ph-16 d-inline-block" onClick={() => setHistory(his)}>
                          <div style={{ width: '200px', height: '200px', overflow: 'hidden', opacity: 0.4 }}>
                            <div
                              style={{
                                transformOrigin: '0 0',
                                transform: `scale(${200 / Math.max(his.width, his.height)})`,
                              }}
                            >
                              <Preview
                                background={his.background}
                                objects={his.objects}
                                width={his.width}
                                height={his.height}
                              />
                            </div>
                          </div>
                          <TimeAgo>{his.historyRecorder}</TimeAgo>
                        </a>
                      </div>
                    ))}
                    {reverse(history.concat([])).map(his => (
                      <div className="PreviewItem" key={uuid()}>
                        <a className="Link ph-16 d-inline-block" onClick={() => setHistory(his)}>
                          <div style={{ width: '200px', maxHeight: '200px', overflow: 'hidden' }}>
                            <div
                              style={{
                                transformOrigin: '0 0',
                                transform: `scale(${200 / Math.max(his.width, his.height)})`,
                              }}
                            >
                              <Preview
                                background={his.background}
                                objects={his.objects}
                                width={his.width}
                                height={his.height}
                              />
                            </div>
                          </div>
                          <TimeAgo>{his.historyRecorder}</TimeAgo>
                        </a>
                      </div>
                    ))}
                  </div>
                )}
              </OptionsContext.Consumer>
            </div>
          )}
        </Row>
        {panel === 'layers' && (
          <React.Fragment>
            <Row style={{ display: panel === 'layers' ? 'flex' : 'none', position: 'relative' }} grow>
              <div className="Grid--overflowWrapper negative-ml-16 Grid--overflowWrapper--withOverflowShadow ">
                <div className="ph-16" style={{ width: '100%', paddingBottom: BOTTOM_SPACE }}>
                  <Layers
                    showHandler={(index, selectOptions) => {
                      setPanel('property');
                      showHandler(objects.length - index - 1, selectOptions);
                    }}
                    lockAxis="y"
                    useDragHandle
                    selectObjects={selectObjects}
                    selectedObjectIndex={selectedObjectIndex}
                    selectedObjectsIndexes={selectedObjectsIndexes}
                    helperClass="Preview-tileItem--dragging"
                    objects={[...objects].reverse()}
                    onObjectChange={index => changes => updateObject(objects.length - index - 1, changes)}
                    removeObject={index => () => removeObjectAtIndex(objects.length - index - 1)}
                    duplicateObject={index => () => duplicateObjectAtIndex(objects.length - index - 1)}
                    onSortEnd={({ oldIndex, newIndex }) => {
                      selectObjects([]);
                      updateState({ objects: arrayMove([...objects].reverse(), oldIndex, newIndex).reverse() });
                    }}
                  />
                </div>
              </div>
            </Row>
            {rest?.dataSourceId && selectedObjectsIndexes?.length > 0 && (
              <Row shrink>
                <Button secondary onClick={handleCalculate} disabled={cannotEdit}>
                  {t('react.image_generator.preview_snippet_modal.create_symbol')}
                </Button>
              </Row>
            )}
            {isModalOpen && (
              <PreviewSnippetModal
                {...rest}
                selectedObjectsIndexes={selectedObjectsIndexes}
                originalObjects={objects}
                closeModal={closeModal}
                handler={handler}
                snippetObjects={snippetObjects}
                selectObjects={selectObjects}
              />
            )}
          </React.Fragment>
        )}
        <Row
          className="mt-16"
          style={{ display: panel === 'preview' ? 'flex' : 'none' }}
          grow
          setRef={modifierPreviewRoot ? renderPreviewRef : () => {}}
        />
      </Col>
    </HotKeys>
  );

  if (modifierPreviewRoot) {
    return ReactDOM.createPortal(elements, modifierPreviewRoot);
  }
  return elements;
};

PreviewPanel.propTypes = {
  dataSourceId: PropTypes.number,
  duplicateObjectAtIndex: PropTypes.func,
  children: PropTypes.any,
  modifierPreviewRoot: PropTypes.any,
  objects: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onArrange: PropTypes.func,
  propertyPanel: PropTypes.node,
  removeObjectAtIndex: PropTypes.func,
  renderPreviewRef: PropTypes.func,
  selectedObjectsIndexes: PropTypes.array,
  selectObjects: PropTypes.func,
  updateObject: PropTypes.func,
  updateState: PropTypes.func,
};

export default hot(PreviewPanel);
