import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase, camelCase } from 'lodash';
import { t } from '../../i18n';
import { SmallHeading, SegmentedButton, Button, Row, Col, GridOverflow } from '../index';

const SegmentedButtonsStepContent = ({ componentKey, ...rest }) => (
  <SegmentedButton
    doNotUseInternalState
    id={`scraper-selector-${kebabCase(componentKey)}`}
    name={`scraperSelector[${camelCase(componentKey)}]`}
    className="mb-0"
    {...rest}
  />
);

const ListWithButtons = ({ buttons, children }) => (
  <React.Fragment>
    <Row overflow grow className="pos-relative">
      <GridOverflow className="pl-0">{children}</GridOverflow>
    </Row>
    <Row shrink className="mt-8">
      {buttons && buttons.length > 0 && (
        <Row>
          {buttons.map(
            ({ label, onClick, ...rest }) =>
              typeof onClick === 'function' && (
                <Button {...rest} key={label} onClick={onClick}>
                  {label}
                </Button>
              )
          )}
        </Row>
      )}
    </Row>
  </React.Fragment>
);

const Basic = ({ children }) => <Col>{children}</Col>;

const Step = Component => ({ shouldShow, step, ...rest }) => {
  const WrapperComponent = (
    <div className="Tile pa-16">
      <Col height="100%">
        <Row shrink>
          <SmallHeading spacing={shouldShow ? 8 : 0}>
            {step && <span className="mr-8">{step}.</span>}
            <span>{t(`step_${step}`, { scope: 'react.scraper.steps' })}</span>
          </SmallHeading>
        </Row>
        {shouldShow ? <Component {...rest} /> : null}
      </Col>
    </div>
  );
  Step.propTypes = {
    step: PropTypes.string,
    shouldShow: PropTypes.bool,
  };
  return WrapperComponent;
};

ListWithButtons.propTypes = {
  buttons: PropTypes.array,
  children: PropTypes.any,
};

Basic.propTypes = {
  children: PropTypes.any,
};

SegmentedButtonsStepContent.propTypes = {
  componentKey: PropTypes.string,
};

Step.propTypes = {
  Component: PropTypes.element,
};

export const BasicTile = Step(Basic);
export const SegmentedStepTile = Step(SegmentedButtonsStepContent);
export const ListWithButtonsTile = Step(ListWithButtons);
