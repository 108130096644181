import React from 'react';
import PropTypes from 'prop-types';
import HeurekaParam from './HeurekaParam';
import { t } from '../i18n';

const PriceInclGroup = ({ children, ...props }) => (
  <HeurekaParam
    {...props}
    paramPlaceholder={t('views.price_group.fields.param', { default: 'Name' })}
    valuePlaceholder={t('views.price_group.fields.value', { default: 'Description' })}
  />
);

PriceInclGroup.propTypes = {
  children: PropTypes.any,
};

export default PriceInclGroup;
