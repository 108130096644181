import { t, formatInteger } from './index';

const getInflectedNumber = ({
  count,
  scope,
  countKey = 'count',
  binaryOptions = false,
  zeroIsSpecial = false,
  otherKeys = {},
}) => {
  const c = parseInt(count, 10);

  if (zeroIsSpecial && c === 0) return t('zero', { scope, [countKey]: formatInteger(count), ...otherKeys });

  if (c === 1) return t('one', { scope, [countKey]: formatInteger(count), ...otherKeys });

  if (!binaryOptions && c > 1 && c < 5) return t('two', { scope, [countKey]: formatInteger(count), ...otherKeys });

  return t('any', { scope, [countKey]: formatInteger(count), ...otherKeys });
};

export default getInflectedNumber;
