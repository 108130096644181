/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';

const ErrorLoadingQuote = ({ onClick }) => (
  <React.Fragment>
    <span dangerouslySetInnerHTML={{ __html: window.reacti18n.pla_campaign_preview.error_data_loading }} />
    <br />
    {typeof onClick === 'function' && (
      <a className="Link" onClick={onClick}>
        {window.reacti18n.pla_campaign_preview.try_again}
      </a>
    )}
  </React.Fragment>
);

ErrorLoadingQuote.propTypes = { onClick: PropTypes.func };

export default ErrorLoadingQuote;
