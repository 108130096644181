import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Cell, Pie, PieChart } from 'recharts';
import { Col, Row, Text } from '../index';

import { formatInteger, formatPercentage, t } from '../../i18n';

const GRAPH_DEFAULT_SIZE = 42;

const UsedFieldGraph = ({
  totalCount,
  filledCount,
  size = GRAPH_DEFAULT_SIZE,
  color,
  withoutGraph,
  withoutGraphDescription,
}) => {
  const usageProductPercent = (filledCount * 100) / totalCount;
  const productsData = useMemo(
    () => [
      { kind: 'used', count: filledCount, color },
      { kind: 'unused', count: totalCount - filledCount, color: 'rgba(0, 22, 42, 0.1)' },
    ],
    [totalCount, filledCount]
  );
  const graphLineWidth = (size / GRAPH_DEFAULT_SIZE) * 5;

  return (
    <Row center>
      {!withoutGraph && (
        <Col shrink>
          <div className="pos-relative">
            <PieChart width={size} height={size}>
              <Pie
                isAnimationActive={false}
                data={productsData}
                dataKey="count"
                paddingAngle={0}
                innerRadius={size / 2 - graphLineWidth}
                outerRadius={size / 2}
                startAngle={90}
                endAngle={-270}
              >
                {productsData.map(entry => (
                  <Cell key={`cell-${entry.kind}`} fill={entry.color} strokeWidth="10" stroke="rgba(0,0,0,0.01)" />
                ))}
              </Pie>
            </PieChart>
            <Text
              bold
              className="pos-absolute align-center"
              style={{ color, fontSize: `${size / 4}px`, lineHeight: `${size / 4}px`, marginTop: '1px' }}
            >
              {formatPercentage(isNaN(usageProductPercent) ? 0 : usageProductPercent)}
            </Text>
          </div>
        </Col>
      )}
      {!withoutGraphDescription && (
        <Col grow>
          <Text style={{ color }}>
            {filledCount === totalCount
              ? t('react.filled_items_graph.all_filled')
              : t('react.filled_items_graph.field_count', {
                  count: formatInteger(filledCount),
                  total: formatInteger(totalCount),
                })}
          </Text>
        </Col>
      )}
    </Row>
  );
};

UsedFieldGraph.propTypes = {
  color: PropTypes.string,
  filledCount: PropTypes.number.isRequired,
  size: PropTypes.number,
  totalCount: PropTypes.number.isRequired,
  withoutGraph: PropTypes.bool,
  withoutGraphDescription: PropTypes.bool,
};

export default UsedFieldGraph;
