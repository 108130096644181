import React, { useState } from 'react';
import _ from 'lodash';
import PropertyGroup from './PropertyGroup';
import Columns from './Columns';
import Column from './Column';
import { Button, Col, Ellipsis } from '../../../index';
import { t } from '../../../../i18n';

import SelectImageModal from './modals/SelectImageModal';

const ImagePanel = ({ object, onChange }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <PropertyGroup object={object} showIf={_.has(object, 'xlinkHref')}>
      <Columns label={t('react.image_generator.label.image')} withoutLabelPadding>
        <Col className="mr-8" style={{ maxWidth: '240px' }}>
          <Ellipsis>{object.filename}</Ellipsis>
        </Col>
        <Column shrink>
          <Button onClick={() => setShowModal(true)} secondary>
            {t('react.image_generator.label.change_image')}
          </Button>

          {showModal && (
            <SelectImageModal
              onSelect={serializedData => {
                const data = JSON.parse(serializedData);
                onChange('xlinkHref', data.xlinkHref, data);
                setShowModal(false);
              }}
              onClose={() => setShowModal(false)}
            />
          )}
        </Column>
      </Columns>
    </PropertyGroup>
  );
};

export default ImagePanel;
