import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { snakeCase, flatten } from 'lodash';
import { hot } from 'react-hot-loader/root';
import { gql, useMutation } from '@apollo/client';
import { SortableElement, SortableContainer } from '../Sortable';
import { QueryHolder } from '../QueryHolder';
import { listenOnModalChange } from '../RemoteModal';
import FeatureLine from './FeatureLine';
import arrayMove from '../../utils/arrayMove';
import ListHeader from './ListHeader';
import ListFooter from './ListFooter';

const getMutations = (updateGraphqlObject, getGraphqlObject, collection) =>
  `mutation updateFeatures { ${collection
    .map(
      c =>
        `fbFeature${c.id}: ${updateGraphqlObject}(id: ${c.id}, position: ${c.position}) { ${getGraphqlObject} { id position } errors }`
    )
    .join('\n')} }
    `;

const SortableList = SortableContainer(({ children }) => <div>{children}</div>);

export const SortableListUpdater = ({ collection, collectionKey, getGraphqlObject, updateGraphqlObject, children }) => {
  const [update] = useMutation(gql`mutation { ${updateGraphqlObject}(id: -1, position: -1) { position } }`, {
    onCompleted: responseData => {
      if (typeof window.NotificationCenter === 'function') {
        const err = flatten(Object.keys(responseData).map(mutationName => responseData[mutationName].errors));
        if (err.length > 0) {
          new window.NotificationCenter().show_error(`Error saving data: ${err.join(', ')}`);
        } else {
          new window.NotificationCenter().show_success('Successfully saved');
        }

        if (typeof window.startLoadingPreview === 'function') {
          window.startLoadingPreview();
        }
        if (typeof window.pushNewModalChange === 'function') {
          window.pushNewModalChange(collectionKey);
        }
      }
    },
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    arrayMove(collection, oldIndex, newIndex);
    update({
      mutation: gql(
        getMutations(
          updateGraphqlObject,
          getGraphqlObject,
          collection.map(({ id }, index) => ({ id, position: index }))
        )
      ),
    });
  };

  return (
    <SortableList lockAxis="y" lockToContainerEdges useWindowAsScrollContainer onSortEnd={onSortEnd} useDragHandle>
      {children}
    </SortableList>
  );
};
SortableListUpdater.propTypes = {
  collection: PropTypes.array,
  collectionKey: PropTypes.string,
  updateGraphqlObject: PropTypes.string,
  getGraphqlObject: PropTypes.string,
  children: PropTypes.any,
};

export const SortableFeatureLine = SortableElement(FeatureLine);

export class ListComponent extends PureComponent {
  static propTypes = {
    addResource: PropTypes.object,
    allAdSetsText: PropTypes.string,
    canEdit: PropTypes.bool,
    className: PropTypes.string,
    collectionKey: PropTypes.string.isRequired,
    data: PropTypes.object,
    disableProductCampaignSelection: PropTypes.bool,
    disableStatusSwitch: PropTypes.bool,
    disableNewBranch: PropTypes.bool,
    useUsageProducts: PropTypes.bool,
    disableFunel: PropTypes.bool,
    doNotShowAdSets: PropTypes.bool,
    getGraphqlObject: PropTypes.string.isRequired,
    heading: PropTypes.string,
    newManualCampaign: PropTypes.bool,
    otherBranches: PropTypes.array,
    refetch: PropTypes.func,
    smallHeading: PropTypes.bool,
    type: PropTypes.string,
    unusedItemText: PropTypes.string,
    updateGraphqlObject: PropTypes.string.isRequired,
    useRemainingProducts: PropTypes.bool,
    useOnlyProducts: PropTypes.bool,
    withoutText: PropTypes.string,
    updateLinkParamRoot: PropTypes.string,
    statusCampaignSwitchWarning: PropTypes.bool,
    itemGroups: PropTypes.array,
  };

  componentDidMount() {
    this.refreshOnUpdateListener = listenOnModalChange(this.refreshOnUpdate);
    this.mounted = true;
  }

  componentWillUnmount() {
    if (this.refreshOnUpdateListener) {
      this.refreshOnUpdateListener();
    }
    this.mounted = false;
  }

  refreshOnUpdate = e => {
    if (e !== 'formUpdated' && typeof this.props.refetch === 'function') {
      if (!e || snakeCase(this.props.collectionKey).indexOf(e) !== -1 || this.props.collectionKey === e) {
        this.props.refetch();
        if (typeof window.startLoadingPreview === 'function') {
          window.startLoadingPreview();
        }
      }
    }
  };

  mounted = false;

  render() {
    const {
      disableProductCampaignSelection,
      disableStatusSwitch,
      canEdit,
      className,
      collectionKey,
      disableNewBranch,
      disableFunel,
      doNotShowAdSets,
      getGraphqlObject,
      heading,
      otherBranches,
      smallHeading,
      type,
      unusedItemText,
      useOnlyProducts,
      useUsageProducts,
      updateGraphqlObject,
      useRemainingProducts,
      updateLinkParamRoot,
      newManualCampaign = false,
      statusCampaignSwitchWarning,
    } = this.props;

    const data = this.props.data.collection[collectionKey] || [];
    const fbCampaigns = this.props.data.collection.fbProductCampaigns;
    const itemsCount = this.props.data.organization.campaignSetting?.itemsCount || 0;
    const itemGroups = this.props.data.itemGroups || [];
    const dataSourceStillLoading = this.props.data.organization?.campaignSetting?.dataSource?.isFirstLoading;
    const adSetCount = doNotShowAdSets ? -1 : this.props.data?.organization?.campaignSetting?.adSetCount || 0;
    const nonDeafultData = data.filter(c => !c.isDefault).sort((a, b) => a.position - b.position);
    const remainingProducts = itemsCount - data.reduce((o, d) => o + d.numberOfProducts, 0);
    const remainingAdSets = adSetCount - data.reduce((o, d) => o + d.numberOfAdSets, 0);

    const isAllUsed = useRemainingProducts ? remainingProducts <= 0 : remainingAdSets <= 0;

    const chartSize = newManualCampaign ? 'lg' : 'md';

    const refetchCollectionOnChange = () => {
      if (typeof window.pushNewModalChange === 'function') {
        window.pushNewModalChange(collectionKey);
      }
    };

    return (
      <div className={className}>
        {!newManualCampaign && (
          <ListHeader
            heading={heading}
            smallHeading={smallHeading}
            newManualCampaign={newManualCampaign}
            chartSize={chartSize}
            itemsCount={itemsCount}
            useOnlyProducts={useOnlyProducts}
            useUsageProducts={useUsageProducts}
            adSetCount={adSetCount}
            dataSourceStillLoading={dataSourceStillLoading}
          />
        )}
        <SortableListUpdater
          collection={nonDeafultData}
          collectionKey={collectionKey}
          updateGraphqlObject={updateGraphqlObject}
          getGraphqlObject={getGraphqlObject}
        >
          {nonDeafultData.map((line, index) => (
            <SortableFeatureLine
              disableProductCampaignSelection={disableProductCampaignSelection}
              disableStatusSwitch={disableStatusSwitch}
              key={line.id}
              index={index}
              data={line}
              adSetsTotal={adSetCount}
              productsTotal={itemsCount}
              useOnlyProducts={useOnlyProducts}
              useUsageProducts={useUsageProducts}
              canEdit={canEdit}
              updateLinkParamRoot={updateLinkParamRoot}
              refetch={refetchCollectionOnChange}
              updateGraphqlObject={updateGraphqlObject}
              getGraphqlObject={getGraphqlObject}
              dataSourceStillLoading={dataSourceStillLoading}
              otherBranches={otherBranches}
              newBranchName={disableNewBranch || line.group === line.name ? undefined : line.name}
              unusedItemText={unusedItemText}
              disableFunel={disableFunel}
              campaigns={fbCampaigns}
              type={type}
              newManualCampaign={newManualCampaign}
              statusCampaignSwitchWarning={statusCampaignSwitchWarning}
              itemGroups={itemGroups}
            />
          ))}
        </SortableListUpdater>
        <ListFooter
          {...this.props}
          adSetCount={adSetCount}
          remainingAdSets={remainingAdSets}
          dataSourceStillLoading={dataSourceStillLoading}
          disableFunel={disableFunel}
          doNotShowAdSets={doNotShowAdSets}
          chartSize={chartSize}
          isAllUsed={isAllUsed}
          itemsCount={itemsCount}
          newManualCampaign={newManualCampaign}
          nonDeafultData={nonDeafultData}
          remainingProducts={remainingProducts}
          useOnlyProducts={useOnlyProducts}
          useUsageProducts={useUsageProducts}
        />
      </div>
    );
  }
}

const QueryList = QueryHolder(ListComponent);

export default hot(QueryList);
