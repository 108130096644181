import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import Tooltip from '../components/Tooltip';
import Icon from '../components/Icon';

export const SortingIconElement = ({ sort = 'asc', active }) => (
  <Tooltip text={sort === 'asc' ? 'Order ascending' : 'Order descending'}>
    <Icon
      className={cs('Sticky-sortArrow', 'pt-4', 'pl-4', 'pb-4', { active })}
      inheritColor
      kind={sort === 'asc' ? 'arrow-up' : 'arrow-down'}
      size="16px"
    />
  </Tooltip>
);

SortingIconElement.propTypes = {
  sort: PropTypes.string,
  active: PropTypes.bool,
};

const SortPropTypes = {
  direction: PropTypes.oneOf(['desc', 'asc']),
  sortBy: PropTypes.string,
};

function sortWithNullLast(direction, getValue) {
  return (rowA, rowB) => {
    const valA = getValue(rowA);
    const valB = getValue(rowB);

    // equal items sort equally
    if (valA === valB) {
      return 0;
    }

    // nulls sort after anything else
    if (valA === null) {
      return 1;
    }
    if (valB === null) {
      return -1;
    }

    // otherwise, if we're ascending, lowest sorts first
    if (direction === 'asc') {
      return valA < valB ? -1 : 1;
    }

    // if descending, highest sorts first
    return valA < valB ? 1 : -1;
  };
}

const DEFAULT_OVERRIDE_STATE = {};

export function useSortable(
  tableRows,
  getValue,
  overrideDefaultState = DEFAULT_OVERRIDE_STATE,
  onSortChange,
  customSortState
) {
  const [sortableState, setSortableState] = useState({
    direction: 'asc',
    sortBy: undefined,
    ...overrideDefaultState,
  });

  const sortableProps = customSortState || sortableState;

  const prevOverrideDefaultState = useRef();
  useEffect(() => {
    prevOverrideDefaultState.current = overrideDefaultState;
  }, [overrideDefaultState]);

  useEffect(() => {
    if (!isEqual(prevOverrideDefaultState.current, overrideDefaultState)) {
      setSortableState(state => ({
        ...state,
        ...overrideDefaultState,
      }));
    }
  }, [overrideDefaultState, prevOverrideDefaultState.current]);

  const onSort = useCallback(
    ({ direction, sortBy }) => {
      setSortableState({ direction, sortBy });
      if (typeof onSortChange === 'function') {
        onSortChange({ direction, sortBy });
      }
    },
    [setSortableState, onSortChange]
  );

  const sortPropsWithCallbacks = useMemo(
    () => ({
      ...sortableProps,
      onSort,
    }),
    [sortableProps, onSort]
  );

  const sortedData = useMemo(() => {
    if (sortableProps.sortBy) {
      const getRowValue = getValue(sortableProps.sortBy);
      return [...tableRows].sort(sortWithNullLast(sortableProps.direction || 'asc', getRowValue));
    }
    return tableRows;
  }, [tableRows, sortableProps.direction, sortableProps.sortBy, getValue]);

  return [sortPropsWithCallbacks, sortedData];
}

export const SortElement = ({ direction, sortBy, sortByValue }) => {
  if (sortBy === sortByValue) {
    return <SortingIconElement sort={direction} active />;
  }

  return (
    <div className="d-inline-block">
      <SortingIconElement />
    </div>
  );
};

SortElement.propTypes = {
  direction: SortPropTypes.direction,
  sortBy: SortPropTypes.sortBy,
  sortByValue: PropTypes.string,
};

export const SortableWrapper = ({ children, defaultDirection, direction, onSort, sortBy, sortByValue }) => {
  let nextDirection = defaultDirection || 'asc';
  if (sortBy === sortByValue) {
    nextDirection = direction === 'asc' ? 'desc' : 'asc';
  }

  return (
    <div
      className={'cursor-pointer select-none d-inline-flex align-items-center'}
      style={{
        marginTop: '-4px',
        paddingTop: '4px',
        marginBottom: '-4px',
        paddingBottom: '4px',
      }}
      onClick={() => onSort({ sortBy: sortByValue, direction: nextDirection })}
    >
      {children}
      <SortElement direction={direction} sortBy={sortBy} sortByValue={sortByValue} />
    </div>
  );
};

SortableWrapper.propTypes = {
  children: PropTypes.any,
  defaultDirection: PropTypes.oneOf(['desc', 'asc']),
  sortByValue: PropTypes.string,
  direction: SortPropTypes.direction,
  sortBy: SortPropTypes.sortBy,
  onSort: PropTypes.func,
};
