/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter, Row, Col, Button } from '../components/index';

class GoogleOtherShipping extends React.PureComponent {
  static propTypes = {
    country: PropTypes.string,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    region: PropTypes.string,
    service: PropTypes.string,
    price: PropTypes.price,
    updateFieldData: PropTypes.func.isRequired,
    minhandling: PropTypes.string,
    maxhandling: PropTypes.string,
    mintransit: PropTypes.string,
    maxtransit: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    country: this.props.country || '',
    region: this.props.region || '',
    service: this.props.service || '',
    price: this.props.price || '',
    minhandling: this.props.minhandling || '',
    maxhandling: this.props.maxhandling || '',
    mintransit: this.props.mintransit || '',
    maxtransit: this.props.maxtransit || '',
  };

  handleCountry = ({ target: { value } }) => {
    if (value !== this.state.country) {
      this.setState({ country: value });
      this.props.updateFieldData(this.props.id, { country: value });
      updatePreview();
    }
  };

  handleRegion = ({ target: { value } }) => {
    if (value !== this.state.region) {
      this.setState({ region: value });
      this.props.updateFieldData(this.props.id, { region: value });
      updatePreview();
    }
  };

  handleService = ({ target: { value } }) => {
    if (value !== this.state.service) {
      this.setState({ service: value });
      this.props.updateFieldData(this.props.id, { service: value });
      updatePreview();
    }
  };

  handlePrice = ({ target: { value } }) => {
    if (value !== this.state.price) {
      this.setState({ price: value });
      this.props.updateFieldData(this.props.id, { price: value });
      updatePreview();
    }
  };

  handleMinhandling = ({ target: { value } }) => {
    if (value !== this.state.minhandling) {
      this.setState({ minhandling: value });
      this.props.updateFieldData(this.props.id, { minhandling: value });
      updatePreview();
    }
  };

  handleMaxhandling = ({ target: { value } }) => {
    if (value !== this.state.maxhandling) {
      this.setState({ maxhandling: value });
      this.props.updateFieldData(this.props.id, { maxhandling: value });
      updatePreview();
    }
  };

  handleMintransit = ({ target: { value } }) => {
    if (value !== this.state.mintransit) {
      this.setState({ mintransit: value });
      this.props.updateFieldData(this.props.id, { mintransit: value });
      updatePreview();
    }
  };

  handleMaxtransit = ({ target: { value } }) => {
    if (value !== this.state.maxtransit) {
      this.setState({ maxtransit: value });
      this.props.updateFieldData(this.props.id, { maxtransit: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, id, getSubFiledOptions, onRemove } = this.props;
    const { service, region, country, price, maxhandling, minhandling, maxtransit, mintransit } = this.state;

    return (
      <Row padding="l" center data-test-id="other_shipping">
        <Col>
          <Row>
            <Col data-test-id="other-shipping-country" className="mr-8">
              <PlaceholderInput
                defaultValue={country}
                onChange={this.handleCountry}
                placeholder={t('views.other_shipping.fields.country', { default: 'Country' })}
                label={t('views.other_shipping.fields.country_label', { default: 'Shipping country' })}
                {...getSubFiledOptions('country')}
              />
            </Col>
            <Col grow data-test-id="other-shipping-region" className="mr-8">
              <PlaceholderInput
                defaultValue={region}
                onChange={this.handleRegion}
                placeholder={t('views.other_shipping.fields.region', { default: 'Region' })}
                label={t('views.other_shipping.fields.region_label', { default: 'Shipping region' })}
                {...getSubFiledOptions('region')}
              />
            </Col>
            <Col grow data-test-id="other-shipping-service" className="mr-8">
              <PlaceholderInput
                defaultValue={service}
                onChange={this.handleService}
                placeholder={t('views.other_shipping.fields.service', { default: 'Service' })}
                label={t('views.other_shipping.fields.service_label', { default: 'Shipping service' })}
                {...getSubFiledOptions('service')}
              />
            </Col>
            <Col grow data-test-id="other-shipping-price">
              <PlaceholderInput
                defaultValue={price}
                onChange={this.handlePrice}
                placeholder={t('views.other_shipping.fields.price', { default: 'Price' })}
                label={t('views.other_shipping.fields.price_label', { default: 'Shipping price' })}
                {...getSubFiledOptions('price')}
              />
            </Col>
          </Row>
          <Row>
            <Col data-test-id="other-shipping-minhandling" className="mr-8">
              <PlaceholderInput
                defaultValue={minhandling}
                onChange={this.handleMinhandling}
                placeholder={t('views.other_shipping.fields.min_handling', { default: 'Min handling time' })}
                label={t('views.other_shipping.fields.min_handling_label', { default: 'Min handling time' })}
                {...getSubFiledOptions('minhandling')}
              />
            </Col>
            <Col grow data-test-id="other-shipping-maxhandling" className="mr-8">
              <PlaceholderInput
                defaultValue={maxhandling}
                onChange={this.handleMaxhandling}
                placeholder={t('views.other_shipping.fields.max_handling', { default: 'Max handling time' })}
                label={t('views.other_shipping.fields.max_handling_label', { default: 'Max handling time' })}
                {...getSubFiledOptions('maxhandling')}
              />
            </Col>
            <Col grow data-test-id="other-shipping-mintransit" className="mr-8">
              <PlaceholderInput
                defaultValue={mintransit}
                onChange={this.handleMintransit}
                placeholder={t('views.other_shipping.fields.min_transit', { default: 'Min transit time' })}
                label={t('views.other_shipping.fields.min_transit_label', { default: 'Min transit time' })}
                {...getSubFiledOptions('mintransit')}
              />
            </Col>
            <Col grow data-test-id="other-shipping-maxtransit">
              <PlaceholderInput
                defaultValue={maxtransit}
                onChange={this.handleMaxtransit}
                placeholder={t('views.other_shipping.fields.max_transit', { default: 'Max transit time' })}
                label={t('views.other_shipping.fields.max_transit_label', { default: 'Max transit time' })}
                {...getSubFiledOptions('maxtransit')}
              />
            </Col>
          </Row>
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.other_shipping.example')} <span>${t('views.other_shipping.example_text')}</span>`}
          data-field={`other_shipping.${country}`}
        >
          <div className="Text">
            {t('views.other_shipping.example')} <span>{t('views.other_shipping.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default GoogleOtherShipping;
