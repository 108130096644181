/* eslint-disable no-param-reassign */
window.initStickyTableHeading = () => {
  const tables = document.querySelectorAll('.Sticky-Wrapper--scrollable');

  tables.forEach(table => {
    if (!table.dataset.scrollTableInited) {
      table.dataset.scrollTableInited = true;
      table.parentElement.style.position = 'relative';
      const topEl = document.createElement('div');
      topEl.style.position = 'sticky';
      topEl.style.top = '0';
      topEl.style.height = '1px';
      topEl.style.marginBottom = '-1px';

      table.parentElement.insertBefore(topEl, table);

      let counter = 0;
      let lastOffsetTop = topEl.offsetTop;

      const requestRAF = () => {
        // window.scrollTable(table, topEl);

        if (topEl.offsetTop !== lastOffsetTop) {
          if (counter === 0) {
            Array.from(table.querySelectorAll('.Sticky-Header .Sticky-Cell, .Sticky-Header th')).forEach(el => {
              el.style.top = `${0}px`;
              el.style.transition = 'unset';
            });
          }
          counter = 10;
        } else if (counter > 0) {
          if (counter === 1) {
            const offset = topEl.offsetTop > 10 ? topEl.offsetTop - 2 : topEl.offsetTop;
            Array.from(table.querySelectorAll('.Sticky-Header .Sticky-Cell, .Sticky-Header th')).forEach(el => {
              el.style.transition = 'top .2s ease';
              el.style.top = `${offset}px`;
            });
          }
          counter -= 1;
        }

        lastOffsetTop = topEl.offsetTop;

        window.requestAnimationFrame(requestRAF);
      };

      window.requestAnimationFrame(requestRAF);
    }
  });
};
