/* eslint-disable react/no-multi-comp */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useLazyQuery, NetworkStatus } from '@apollo/client';
import { Loader, ErrorLoadingQuote } from './index';
import isEmptyObject from '../utils/isEmptyObject';
import ErrorBoundary from './ErrorBoundary';

export const RefetchContext = React.createContext(() => {});

export const QueryHolder = (WrappedComponent, wrapperQuery) => {
  const QueryRenderer = ({
    loaderSize = 'small',
    query,
    fullQuery,
    fetchPolicy = 'cache-first',
    variables,
    children,
    showChildrenWhileLoading,
    cachePreviousData = true,
    hideLoadingText,
    ...rest
  }) => {
    const mainQuery = useQuery(query || wrapperQuery, {
      variables,
      fetchPolicy,
      notifyOnNetworkStatusChange: true,
    });

    const { error, data, refetch, previousData, networkStatus } = mainQuery;
    const loading = networkStatus !== NetworkStatus.ready;
    let { startPolling, stopPolling } = mainQuery;

    let finalData = !cachePreviousData ? data : data || previousData;

    let finalLoading = false;
    let finalRefetch = refetch;

    if (fullQuery) {
      const [loadFullData, fullQueryResult] = useLazyQuery(fullQuery, {
        variables,
        fetchPolicy,
        notifyOnNetworkStatusChange: true,
      });
      startPolling = fullQueryResult.startPolling;
      stopPolling = fullQueryResult.stopPolling;
      finalLoading = fullQueryResult.loading;
      finalRefetch = fullQueryResult.refetch;

      finalData = fullQueryResult.data || fullQueryResult.previousData || finalData;

      useEffect(() => {
        loadFullData();
      }, [finalData, fullQuery, variables]);
    }

    if (
      !showChildrenWhileLoading &&
      loading &&
      (!finalData || isEmptyObject(finalData, { ignoreKeys: key => key.match(/^__/) }))
    ) {
      return (
        <ErrorBoundary>
          {showChildrenWhileLoading && children}
          <div className="Preview-item text-center mb-24">
            <Loader size={loaderSize} />
            {!hideLoadingText && (
              <div className="Text Text--gray mt-16">{window.reacti18n.pla_campaign_preview.loading}</div>
            )}
          </div>
        </ErrorBoundary>
      );
    }
    if (error) {
      return (
        <ErrorBoundary>
          {showChildrenWhileLoading && children}
          <div className="Preview-item">
            <ErrorLoadingQuote onClick={finalRefetch} />
          </div>
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        <RefetchContext.Provider value={finalRefetch}>
          <WrappedComponent
            loading={loading}
            finalLoading={finalLoading}
            data={finalData}
            previousData={previousData}
            stopPolling={() => typeof stopPolling === 'function' && stopPolling()}
            startPolling={sp => typeof startPolling === 'function' && startPolling(sp)}
            refetch={finalRefetch}
            {...variables}
            {...rest}
          >
            {children}
          </WrappedComponent>
          {!showChildrenWhileLoading && loading && <Loader size={loaderSize} absolute />}
        </RefetchContext.Provider>
      </ErrorBoundary>
    );
  };
  QueryRenderer.propTypes = {
    children: PropTypes.any,
    loaderSize: PropTypes.oneOf(['big', 'small']),
    query: PropTypes.object,
    fetchPolicy: PropTypes.oneOf([
      'cache-first',
      'cache-only',
      'cache-and-network',
      'network-only',
      'no-cache',
      'standby',
    ]),
    fullQuery: PropTypes.object,
    showChildrenWhileLoading: PropTypes.bool,
    variables: PropTypes.object,
    cachePreviousData: PropTypes.bool,
    hideLoadingText: PropTypes.bool,
  };

  return QueryRenderer;
};
