import React, { PureComponent } from 'react';

import styles from './styles';

class PanelList extends PureComponent {
  render() {
    const { objectComponent, id } = this.props;
    if (!objectComponent || !objectComponent.panels) {
      return null;
    }

    return (
      <div style={{ ...styles.propertyPanel }}>
        {objectComponent.panels.map(Panel => (
          <Panel key={Panel.name} id={id} {...this.props} />
        ))}
      </div>
    );
  }
}

export default PanelList;
