import React, { useContext } from 'react';
import { SearchContext } from '../index';
import { Select } from '../../form';

export const ElementType = () => {
  const {
    searchState,
    searchChange,
    searchOptions: { elementTypes },
  } = useContext(SearchContext);
  if ((elementTypes || []).length === 0) {
    return null;
  }
  return (
    <Select
      value={searchState.elementType || 'all'}
      id="element-type-search"
      name="table_search[select_type]"
      doNotUseInternalState
      medium
      noMargin
      onChange={({ target: { value } }) => searchChange('elementType', value)}
      collection={elementTypes}
    />
  );
};

ElementType.propTypes = {};

export default ElementType;
