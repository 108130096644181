/* eslint-disable react/no-danger */
import React from 'react';
import RPT from 'prop-types';
import { FunelInputCampaignProducts, DropdownMenu } from '../components';
import { t } from '../i18n';
import DropElement from './DropElement.react';

const EntryCount = ({
  isDragging,
  id,
  changeOrder,
  showAllCampaigns,
  hideSystems,
  parent,
  elements,
  i18n,
  actions,
}) => {
  if (isDragging) {
    return (
      <tr className="noHover" key={`${id}-allcount`}>
        <td colSpan={3} />
        <td className="pv-0 vab">
          <FunelInputCampaignProducts>
            <DropElement
              key="dropBefore"
              isFirst
              i18n={i18n}
              id={parent}
              child={id}
              elements={elements}
              changeOrder={changeOrder}
            />
            <b>{elements[id].allItemsCount}</b> {i18n.products}
          </FunelInputCampaignProducts>
        </td>
      </tr>
    );
  }

  return (
    <tr className="noHover StickyTable-Row--branchTableOverride" key={`${id}-allcount`}>
      <td />
      <td>
        {t('adtext_branch', { default: 'Branch' })}: {elements[id].nameText}
      </td>
      <td className="pv-0">
        <DropdownMenu data-test-id={`dropdown-action-branch-${elements[id].nameText}`}>
          <span dangerouslySetInnerHTML={{ __html: actions.duplicateBranch || '' }} />
          <span dangerouslySetInnerHTML={{ __html: actions.duplicateBranchInto || '' }} />
          <span dangerouslySetInnerHTML={{ __html: actions.deleteBranch || '' }} />
        </DropdownMenu>
      </td>
      <td className="pv-0 vab" colSpan={!showAllCampaigns && 5}>
        <FunelInputCampaignProducts>
          <b>{elements[id].allItemsCount}</b> {i18n.products}
        </FunelInputCampaignProducts>
      </td>
      {showAllCampaigns && (
        <td colSpan={hideSystems ? 3 : 4}>
          {t('adtext_branch_campaign', { default: 'Campaign' })}:
          <span className="pl-8" dangerouslySetInnerHTML={{ __html: elements[id].campaignName }} />
        </td>
      )}
    </tr>
  );
};

EntryCount.propTypes = {
  actions: RPT.object.isRequired,
  changeOrder: RPT.func.isRequired,
  elements: RPT.object,
  hideSystems: RPT.bool.isRequired,
  i18n: RPT.object.isRequired,
  id: RPT.string.isRequired,
  isDragging: RPT.bool.isRequired,
  parent: RPT.string.isRequired,
  showAllCampaigns: RPT.bool.isRequired,
};

export default EntryCount;
