import React, { useState } from 'react';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { gql } from '@apollo/client';
import { Query } from '@apollo/client/react/components';
import moment from 'moment';
import Tile from './Tile';
import { Badge, Button, Col, Ellipsis, Icon, Link, Row, Text, VideoModal } from '../index';
import { Vote } from '../notifications/NotificationMessage';
import { t } from '../../i18n';

const QUERY_DASHBOARD_NEWS_ARTICLES_QUERY = gql`
  query NewsArticles($organizationId: BigInt!) {
    notifications(page: 0, limit: 1, kind: "news_articles", organizationId: $organizationId) {
      id
      subject
      link {
        href
        children
      }
      image
      youtubeLink
      date
      message
      learnMoreLink {
        href
      }
      likesEnabled
      vote
    }
  }
`;

const imageStyle = image => ({
  height: '120px',
  backgroundImage: image ? `url("${image}")` : 'unset',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundColor: !image ? 'red' : 'unset',
  width: '100%',
});
imageStyle.propTypes = {
  image: PropTypes.string,
};

export const NewsArticlesComponent = hot(({ notifications = [], error, loading, refetch }) => {
  if (notifications.length === 0) {
    return (
      <Tile
        refetch={refetch}
        error={error}
        loading={loading}
        dashboard
        header={t('react.dashboard.news_article.header')}
      >
        <Icon kind="info-btn" size="80px" color="#f2f3f4" className="mb-8" />
        <Text bold>{t('react.dashboard.news_article.no_news')}</Text>
      </Tile>
    );
  }

  const newsArticle = notifications[0] || {};
  const {
    date,
    image,
    youtubeLink,
    subject: title,
    message: content,
    link = {},
    learnMoreLink = {},
    vote,
    id,
  } = newsArticle;
  const tryFeatureLinkText = link?.children;
  const tryFeatureLink = link?.href;
  const learnLink = learnMoreLink?.href;
  const isNew = date ? moment(date).add(14, 'days') > moment() : false;
  const [isVideoModalOpen, setVideoModalOpen] = useState(false);
  const youtubeVideoId = youtubeLink ? youtubeLink.split('/').at(-1) : '';

  if (typeof refetch === 'function') {
    window.newsArticlesRefetchQuery = refetch;
  }

  return (
    <Tile
      alignLeft={!loading}
      header={
        <React.Fragment>
          <Row center className>
            <div style={{ maxWidth: isNew ? 'calc(100% - 45px)' : '100%' }}>
              <Ellipsis>{title || t('react.dashboard.news_article.header')}</Ellipsis>
            </div>
            {isNew && (
              <Badge className="ml-8" uppercase kind="blue" size="medium">
                {t('react.new')}
              </Badge>
            )}
          </Row>
        </React.Fragment>
      }
      dashboard
      error={error}
      loading={loading}
    >
      <Col width="100%">
        {image && !youtubeLink && <Row shrink style={imageStyle(image)} />}
        {youtubeLink && (
          <Row shrink>
            <Link onClick={() => setVideoModalOpen(true)} noUnderline className="d-block w-100">
              <div
                className="pos-relative"
                style={imageStyle(image || `https://i.ytimg.com/vi/${youtubeVideoId}/maxresdefault.jpg`)}
              >
                <Icon kind="video-play" size="60px" inheritColor className="pos-absolute align-center" />
              </div>
            </Link>
          </Row>
        )}
        {isVideoModalOpen && (
          <VideoModal
            onClose={() => setVideoModalOpen(false)}
            videoUrl={`https://www.youtube.com/embed/${youtubeVideoId}`}
          />
        )}
        {content && (
          <Row shrink>
            <div
              className={cs('line-clamp-3', { 'line-clamp-6': !tryFeatureLink && !learnLink })}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </Row>
        )}
        <Row center shrink noPadding style={{ marginTop: 'auto' }} flexwrap className="flex-gap-16">
          {tryFeatureLink && (
            <Col shrink>
              <Button secondary size="small" tag="a" href={tryFeatureLink} data-test-id="dashboard-news-try-button">
                {tryFeatureLinkText || t('react.dashboard.news_article.try')}
              </Button>
            </Col>
          )}
          {learnLink && (
            <Col shrink>
              <Link external href={learnLink} data-test-id="dashboard-news-learn-link">
                {t('react.dashboard.news_article.learn_more')}
              </Link>
            </Col>
          )}
          <Col shrink>
            <Vote key={[id, vote].join('-')} {...newsArticle} />
          </Col>
        </Row>
      </Col>
    </Tile>
  );
});

NewsArticlesComponent.displayName = 'NewsArticlesComponent';
NewsArticlesComponent.propTypes = {
  error: PropTypes.string,
  loading: PropTypes.bool,
  NewsArticles: PropTypes.array,
};

export const NewsArticles = hot(({ organizationId, ...rest }) => (
  <Query
    query={QUERY_DASHBOARD_NEWS_ARTICLES_QUERY}
    variables={{
      organizationId,
    }}
  >
    {({ error, data, loading, refetch }) => (
      <NewsArticlesComponent loading={loading} error={error} refetch={() => refetch()} {...rest} {...(data || {})} />
    )}
  </Query>
));

NewsArticles.displayName = 'NewsArticles';
NewsArticles.propTypes = {
  organizationId: PropTypes.number.isRequired,
};
