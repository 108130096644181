import { setupColumn } from './setupColumn';
import { ConditionCell } from './cells';
import { t } from '../../../i18n';

const mapping = {
  ...setupColumn({
    id: 'conditions',
    graphqlFields: { conditions: ['toDataPreview'] },
    required: false,
    cell: ConditionCell,
    header: () => t('attributes.conditions'),
    resolveChildren: ({ conditions = [] }) => conditions.map(c => JSON.parse(c.toDataPreview)),
  }),
};

export default mapping;
