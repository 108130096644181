/* eslint-disable no-eval */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-did-mount-set-state */

import React from 'react';
import PropTypes from 'prop-types';
import PlaceholderInput from '../placeholders/Input';
import updatePreview from './updatePreview';
import { t } from '../i18n';
import { TileArrowDelimiter, Row, Col, Button } from '../components/index';

class SizeVariation extends React.PureComponent {
  static propTypes = {
    identifier: PropTypes.string,
    disabled: PropTypes.bool,
    getSubFiledOptions: PropTypes.func.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    onRemove: PropTypes.func.isRequired,
    options: PropTypes.object,
    availability: PropTypes.string,
    size: PropTypes.string,
    quantity: PropTypes.string,
    updateFieldData: PropTypes.func.isRequired,
    price: PropTypes.string,
    link: PropTypes.string,
    mpn: PropTypes.string,
    ean: PropTypes.string,
  };

  static defaultProps = {
    options: {},
  };

  state = {
    identifier: this.props.identifier || '',
    availability: this.props.availability || '',
    size: this.props.size || '',
    quantity: this.props.quantity || '',
    price: this.props.price || '',
    link: this.props.link || '',
    mpn: this.props.mpn || '',
    ean: this.props.ean || '',
  };

  handleIdentifier = ({ target: { value } }) => {
    if (value !== this.state.identifier) {
      this.setState({ identifier: value });
      this.props.updateFieldData(this.props.id, { identifier: value });
      updatePreview();
    }
  };

  handleAvailability = ({ target: { value } }) => {
    if (value !== this.state.availability) {
      this.setState({ availability: value });
      this.props.updateFieldData(this.props.id, { availability: value });
      updatePreview();
    }
  };

  handleSize = ({ target: { value } }) => {
    if (value !== this.state.size) {
      this.setState({ size: value });
      this.props.updateFieldData(this.props.id, { size: value });
      updatePreview();
    }
  };

  handleQuantity = ({ target: { value } }) => {
    if (value !== this.state.quantity) {
      this.setState({ quantity: value });
      this.props.updateFieldData(this.props.id, { quantity: value });
      updatePreview();
    }
  };

  handlePrice = ({ target: { value } }) => {
    if (value !== this.state.price) {
      this.setState({ price: value });
      this.props.updateFieldData(this.props.id, { price: value });
      updatePreview();
    }
  };

  handleLink = ({ target: { value } }) => {
    if (value !== this.state.link) {
      this.setState({ link: value });
      this.props.updateFieldData(this.props.id, { link: value });
      updatePreview();
    }
  };

  handleMpn = ({ target: { value } }) => {
    if (value !== this.state.mpn) {
      this.setState({ mpn: value });
      this.props.updateFieldData(this.props.id, { mpn: value });
      updatePreview();
    }
  };

  handleEan = ({ target: { value } }) => {
    if (value !== this.state.ean) {
      this.setState({ ean: value });
      this.props.updateFieldData(this.props.id, { ean: value });
      updatePreview();
    }
  };

  render() {
    const { disabled, getSubFiledOptions, onRemove } = this.props;
    const { size, availability, identifier, quantity, link, price, ean, mpn } = this.state;

    return (
      <Row padding="l" center data-test-id="other_shipping">
        <Col>
          <Row>
            <Col data-test-id="size-variation-identifier" className="mr-8">
              <PlaceholderInput
                defaultValue={identifier}
                onChange={this.handleIdentifier}
                placeholder={t('views.size_variation.fields.identifier', { default: 'ID' })}
                label={t('views.size_variation.fields.identifier_label', { default: 'Variation unique ID' })}
                {...getSubFiledOptions('identifier')}
              />
            </Col>
            <Col grow data-test-id="size-variation-availability" className="mr-8">
              <PlaceholderInput
                defaultValue={availability}
                onChange={this.handleAvailability}
                placeholder={t('views.size_variation.fields.availability', { default: 'Availability' })}
                label={t('views.size_variation.fields.availability_label', { default: 'Variation availability' })}
                {...getSubFiledOptions('availability')}
              />
            </Col>
            <Col grow data-test-id="size-variation-size" className="mr-8">
              <PlaceholderInput
                defaultValue={size}
                onChange={this.handleSize}
                placeholder={t('views.size_variation.fields.size', { default: 'Size' })}
                label={t('views.size_variation.fields.size_label', { default: 'Variation size' })}
                {...getSubFiledOptions('size')}
              />
            </Col>
            <Col grow data-test-id="size-variation-quantity">
              <PlaceholderInput
                defaultValue={quantity}
                onChange={this.handleQuantity}
                placeholder={t('views.size_variation.fields.quantity', { default: 'quantity' })}
                label={t('views.size_variation.fields.quantity_label', { default: 'Variation quantity' })}
                {...getSubFiledOptions('quantity')}
              />
            </Col>
          </Row>
          <Row>
            <Col data-test-id="size-variation-price" className="mr-8">
              <PlaceholderInput
                defaultValue={price}
                onChange={this.handlePrice}
                placeholder={t('views.size_variation.fields.price', { default: 'Price' })}
                label={t('views.size_variation.fields.price_label', { default: 'Variation price' })}
                {...getSubFiledOptions('price')}
              />
            </Col>
            <Col grow data-test-id="size-variation-link" className="mr-8">
              <PlaceholderInput
                defaultValue={link}
                onChange={this.handleLink}
                placeholder={t('views.size_variation.fields.link', { default: 'Link' })}
                label={t('views.size_variation.fields.link_label', { default: 'Variation link' })}
                {...getSubFiledOptions('link')}
              />
            </Col>
            <Col grow data-test-id="size-variation-mpn" className="mr-8">
              <PlaceholderInput
                defaultValue={mpn}
                onChange={this.handleMpn}
                placeholder={t('views.size_variation.fields.mpn', { default: 'MPN' })}
                label={t('views.size_variation.fields.mpn_label', { default: 'Variation MPN' })}
                {...getSubFiledOptions('mpn')}
              />
            </Col>
            <Col grow data-test-id="size-variation-ean">
              <PlaceholderInput
                defaultValue={ean}
                onChange={this.handleEan}
                placeholder={t('views.size_variation.fields.ean', { default: 'EAN' })}
                label={t('views.size_variation.fields.ean_label', { default: 'Variation EAN' })}
                {...getSubFiledOptions('ean')}
              />
            </Col>
          </Row>
        </Col>

        <Col shrink className="align-self-stretch">
          <TileArrowDelimiter />
        </Col>

        <Col
          width="calc(40% - 48px)"
          data-preview="placeholder"
          data-example={`${t('views.size_variation.example')} <span>${t('views.size_variation.example_text')}</span>`}
          data-field={`size_variation.${identifier}`}
        >
          <div className="Text">
            {t('views.size_variation.example')} <span>{t('views.size_variation.example_text')}</span>
          </div>
        </Col>

        {onRemove && (
          <Col shrink>
            <Button
              tertiary
              onlyIcon
              disabled={disabled}
              onClick={onRemove}
              tabIndex="-1"
              icon="trash"
              data-test-id="nested-field-remove"
            />
          </Col>
        )}
      </Row>
    );
  }
}

export default SizeVariation;
