import React from 'react';
import { PropTypes } from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { Col, Icon, Row, UsedByGraph, PageHeading, Text } from '../index';
import { GrayBox } from '../table/LoadingTable';
import { formatPercentage, formatCurrency, formatInteger, t } from '../../i18n';

const MainHeading = ({ price, currency, percentage, noFormat, loading = false }) => {
  let heading = t('feature_period.no_data');
  if (noFormat) heading = price;
  if (percentage && !noFormat) heading = formatPercentage(percentage);
  if (currency && price && !noFormat) {
    heading = formatCurrency(price, { precision: 0, round_strategy: 'ceil', unit: currency });
  }
  if (price && !currency && !noFormat) heading = formatInteger(price);

  return (
    <PageHeading className="mt-0 mb-s">
      {' '}
      {loading ? <GrayBox height="22px" withoutMargin medium /> : heading}{' '}
    </PageHeading>
  );
};

const LoadingSkeleton = ({ icon, label, subHeading }) => (
  <Row center>
    <Col center>
      <Row center inline className="pr-8">
        {icon && <Icon kind={icon} color="#8C969F" size="40px" />}
      </Row>
    </Col>
    <Col shrink>
      {label && (
        <Text tag="h3" size="sm" className="mb-0 mt-0 Text--inheritColor">
          {' '}
          {label}{' '}
        </Text>
      )}
      <MainHeading loading />
      {subHeading && (
        <Text tag="p" size="sm" color="softGray" className="mb-0 mt-4">
          <GrayBox long withoutMargin />
        </Text>
      )}
    </Col>
  </Row>
);

const UsageFeatureContent = ({ subHeading, icon, percentage, label, pastPrice, ...rest }) => (
  <Row center>
    <Col center>
      <Row center inline className="pr-8">
        {icon && <Icon kind={icon} color="#8C969F" size="40px" />}
        {percentage && <UsedByGraph adSets={percentage} adSetsTotal={100} size="xl" />}
      </Row>
    </Col>
    <Col shrink>
      {label && (
        <Text tag="h3" size="sm" className="mb-0 mt-0 Text--inheritColor">
          {' '}
          {label}{' '}
        </Text>
      )}
      <MainHeading {...rest} percentage={percentage} />
      {subHeading && (
        <Text tag="p" size="sm" color="softGray" className="mb-0 mt-4">
          {pastPrice}
          {subHeading}
        </Text>
      )}
    </Col>
  </Row>
);

const UsageFeature = ({ loading = false, ...rest }) =>
  loading ? <LoadingSkeleton {...rest} /> : <UsageFeatureContent {...rest} />;

MainHeading.propTypes = {
  currency: PropTypes.string,
  noFormat: PropTypes.bool,
  percentage: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.bool,
};
UsageFeatureContent.propTypes = {
  currency: PropTypes.string,
  icon: PropTypes.string,
  label: PropTypes.string,
  pastPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  percentage: PropTypes.number,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subHeading: PropTypes.string,
};
UsageFeature.propTypes = {
  loading: PropTypes.bool,
};
LoadingSkeleton.propTypes = {
  icon: PropTypes.string,
  label: PropTypes.string,
  subHeading: PropTypes.string,
};

export default hot(UsageFeature);
