import React from 'react';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import QuerySelect from '../QuerySelect';
import { t } from '../../i18n/';

const QUERY_FACEBOOK_PRODUCT_CATALOGS = gql`
  query OrganizationGoogleSpreadsheetFiles($organizationId: BigInt!, $businessId: String!) {
    organization(id: $organizationId) {
      id
      fbBusiness(id: $businessId) {
        id
        fbProductCatalogs {
          id
          name
        }
      }
    }
  }
`;

class FacebookCatalogSelection extends React.Component {
  static propTypes = {
    organizationId: PropTypes.string.isRequired,
    facebookBusinessId: PropTypes.string,
    facebookProductCatalogId: PropTypes.string,
    errors: PropTypes.object,
  };

  state = {
    facebookBusinessId: this.props.facebookBusinessId || null,
    facebookProductCatalogId: this.props.facebookProductCatalogId || '',
  };

  componentDidMount = () => {
    const businessElement = document.getElementById('react-select-facebook-business');
    businessElement.addEventListener('change', this.handleBusinessChange);
  };

  handleBusinessChange = ({ target: { value } }) => {
    if (this.state.facebookBusinessId !== value) {
      this.setState({
        facebookBusinessId: value,
        facebookProductCatalogId: '',
      });
    }
  };

  render() {
    const { organizationId, errors } = this.props;
    const { facebookBusinessId, facebookProductCatalogId } = this.state;

    if (!facebookBusinessId || facebookBusinessId.length === 0) {
      return null;
    }

    return (
      <React.Fragment>
        <QuerySelect
          onChange={({ target: { value } }) =>
            this.setState({
              facebookProductCatalogId: value,
            })
          }
          query={QUERY_FACEBOOK_PRODUCT_CATALOGS}
          value={facebookProductCatalogId}
          name="data_source[facebook_product_catalog_id]"
          getCollection={data => data.organization.fbBusiness.fbProductCatalogs}
          variables={{
            organizationId: parseInt(organizationId, 10),
            businessId: facebookBusinessId,
          }}
          error={errors.facebook_product_catalog_id}
          translatedName={t('react.facebook_importer.facebook_product_catalog_id')}
        >
          {({ id, name }) => ({ label: name, value: id })}
        </QuerySelect>
      </React.Fragment>
    );
  }
}

export default FacebookCatalogSelection;
