import { gql } from '@apollo/client';
import { setupColumn } from '../setupColumn';
import { TextCell, MoneyCell, SwitchCell } from '../cells';
import { MoneyEdit } from '../editable';
import { t } from '../../../../i18n';
import { bingHeader } from '../systemHeaders';

const category = 'bing';

export const bingMapping = {
  ...setupColumn({
    id: 'bingSystemId',
    graphqlFields: { bingSetting: ['systemId'] },
    category,
    order: 'bing_setting.system_id',
    header: () => bingHeader(t('activerecord.attributes.bing_setting.system_id')),
    cell: TextCell,
    resolveChildren: data => data && data.bingSetting && data.bingSetting.systemId,
  }),
  ...setupColumn({
    id: 'bingBudget',
    graphqlFields: ['bingCurrency', { bingSetting: ['budget'] }],
    order: 'bing_setting.budget',
    header: () => bingHeader(t('activerecord.attributes.bing_setting.budget')),
    category,
    resolveChildren: data =>
      (data &&
        data.bingSetting && {
          id: data.bingSetting.id,
          currency: data.bingCurrency,
          money: data.bingSetting.budget,
        }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    editQuery: data =>
      data?.bingSetting?.id &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateBingSetting(id: ${id || -1}, budget: ${money || 0}) {
            bingSetting {
              id
              budget
            }
            errors
          }
        }
      `;
      }),
  }),

  ...setupColumn({
    id: 'bingMaxCpc',
    graphqlFields: ['bingCurrency', { bingSetting: ['maxCpc'] }],
    order: 'bing_setting.max_cpc',
    header: () => bingHeader(t('activerecord.attributes.bing_setting.max_cpc')),
    category,
    resolveChildren: data =>
      (data &&
        data.bingSetting && {
          id: data.bingSetting.id,
          currency: data.bingCurrency,
          money: data.bingSetting.maxCpc,
        }) ||
      {},
    cell: MoneyCell,
    editable: MoneyEdit,
    editQuery: data =>
      data?.bingSetting?.id &&
      (newData => {
        const id = newData?.id;
        const money = newData?.money;
        return gql`
        mutation {
          updateBingSetting(id: ${id || -1}, maxCpc: ${money || 0}) {
            bingSetting {
              id
              maxCpc
            }
            errors
          }
        }
      `;
      }),
  }),
  ...setupColumn({
    id: 'syncCampaignBudget',
    graphqlFields: [{ bingSetting: ['syncCampaignBudget'] }],
    resolveChildren: data => data && data.bingSetting && data.bingSetting.syncCampaignBudget,
    cell: SwitchCell,
    category,
    header: () => bingHeader(t('activerecord.attributes.bing_setting.sync_campaign_budget')),
    editQuery: data =>
      data?.bingSetting?.id &&
      (synced =>
        gql`mutation { updateBingSetting(id: ${data &&
          data.bingSetting &&
          data.bingSetting.id}, syncCampaignBudget: ${synced}) { bingSetting { id syncCampaignBudget } errors } }`),
  }),
  ...setupColumn({
    id: 'syncAdgroupCpc',
    graphqlFields: [{ bingSetting: ['syncAdgroupCpc'] }],
    resolveChildren: data => data && data.bingSetting && data.bingSetting.syncAdgroupCpc,
    cell: SwitchCell,
    category,
    header: () => bingHeader(t('activerecord.attributes.bing_setting.sync_adgroup_cpc')),
    editQuery: data =>
      data?.bingSetting?.id &&
      (synced =>
        gql`mutation { updateBingSetting(id: ${data &&
          data.bingSetting &&
          data.bingSetting.id}, syncAdgroupCpc: ${synced}) { bingSetting { id syncAdgroupCpc } errors } }`),
  }),
};

export default bingMapping;
