import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalBody, Button } from '../index';
import { ProductPreviewDataFromContext } from './ProductPreviewComponent';

function ProductInfoButton(props) {
  const { children, item, mainIdentifier } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      {typeof children === 'function' ? (
        children({ onClick: () => setShowModal(true) })
      ) : (
        <Button onClick={() => setShowModal(true)} tertiary onlyIcon icon="info" />
      )}
      {showModal && (
        <Modal onClose={() => setShowModal(false)} border heading={item[mainIdentifier]} size="full">
          <ModalBody>
            <ProductPreviewDataFromContext {...props} />
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
ProductInfoButton.propTypes = {
  children: PropTypes.func,
  item: PropTypes.object,
  mainIdentifier: PropTypes.string,
};

export default ProductInfoButton;
