/* eslint-disable import/namespace */
/* eslint-disable no-param-reassign */

import React from 'react';
import ReactDOM from 'react-dom';
import { debounce } from 'lodash';

import getCSRFToken from '../utils/getCSRFToken';
import GrapqlProvider, { createClient } from '../graphql_provider';
import * as mapping from './index';
import ErrorBoundary from './ErrorBoundary';

window.changePropsForComponent = (name, overrideProps = {}) => {
  const container = document.querySelector(`[data-listen-on-props-change="${name}"]`);
  if (container) {
    if (typeof container.changeProps === 'function') {
      container.changeProps(overrideProps);
    }
  }
};

const initReactComponents = () => {
  const csrfToken = getCSRFToken();

  const containers = document.getElementsByClassName('react-component');
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      if (!container.classList.contains('react-component-inited')) {
        container.classList.add('react-component-inited');
        const Component = mapping[container.dataset.component];
        if (!Component) {
          // eslint-disable-next-line no-console
          console.error(
            'Unable to render ',
            container.dataset.component,
            'into',
            container,
            'with',
            container.dataset.json,
            'becuase it is missing in',
            Object.keys(mapping)
          );
        } else {
          const props = JSON.parse(container.dataset.json || '{}');
          const children =
            props.children ||
            (container.innerHTML.length > 0 ? (
              // eslint-disable-next-line react/no-danger
              <span dangerouslySetInnerHTML={{ __html: container.innerHTML }} />
            ) : (
              undefined
            ));

          const renderComponent = (overrideProps = {}) => {
            const comp = (
              <ErrorBoundary>
                <Component {...props} {...overrideProps}>
                  {children}
                </Component>
              </ErrorBoundary>
            );

            if (props.graphql) {
              const existingClient = container.graphqlClient || createClient(csrfToken);
              container.graphqlClient = existingClient;

              ReactDOM.render(<GrapqlProvider client={existingClient}>{comp}</GrapqlProvider>, container);
            } else {
              ReactDOM.render(comp, container);
            }
          };

          renderComponent();

          if (props.listenOnPropsChange) {
            container.dataset.listenOnPropsChange = props.listenOnPropsChange;
            container.changeProps = (overrideProps = {}) => renderComponent(overrideProps);
          }
        }
      }
    });
  }
};

window.initReactComponents = debounce(initReactComponents, 30, {
  leading: true,
  trailing: true,
});

const initReactFormComponents = () => {
  const csrfToken = getCSRFToken();
  const containers = document.getElementsByClassName('react-form-input');
  if (containers.length > 0) {
    Array.from(containers).forEach(container => {
      if (!container.classList.contains('react-form-component-inited')) {
        container.classList.add('react-form-component-inited');
        const Component = mapping[container.dataset.component];
        if (!Component) {
          // eslint-disable-next-line no-console
          console.error(
            'Unable to render form component ',
            container.dataset.component,
            'into',
            container,
            'with',
            container.dataset.json,
            'because it is missing in',
            Object.keys(mapping)
          );
        } else {
          const input = container.querySelector('input');
          const data = JSON.parse(input.dataset.json || '{}');

          const inputProps = {
            name: input.name,
            value: data.value || input.value,
            placeholder: input.placeholder,
            id: input.id,
          };

          const comp = (
            <ErrorBoundary>
              <Component {...data} {...inputProps} />
            </ErrorBoundary>
          );

          if (data.graphql) {
            ReactDOM.render(<GrapqlProvider csrfToken={csrfToken}>{comp}</GrapqlProvider>, container);
          } else {
            ReactDOM.render(comp, container);
          }
        }
      }
    });
  }
};

window.initReactFormComponents = debounce(initReactFormComponents, 30, {
  leading: true,
  trailing: true,
});
