import React from 'react';
import PropTypes from 'prop-types';
import IconTile from '../components/IconTile';
import { StateContent } from '../components';
import CampaignDataSourceTileDescription from './CampaignDataSourceTileDescription';

const CampaignDataSourceTile = ({ name, item, selected, setSelected, campaignType }) => {
  const showSubTile =
    ['PerformanceMaxCampaign', 'FBAdvantagePlusCampaign'].includes(campaignType) && selected === item?.id;

  if (!item) return null;

  return (
    <span className="radio">
      <input
        className="RadioTiles-input"
        type="radio"
        value={item?.id}
        name={name}
        checked={selected === item?.id ? 'checked' : ''}
        id={`data_source_id_${item?.id}`}
      />
      <label htmlFor={`data_source_id_${item?.id}`} className="collection_radio_buttons">
        <IconTile
          horizontal
          hoverable
          withCheck
          heading={item?.name}
          icon={item?.icon}
          active={selected === item?.id}
          onClick={() => setSelected(item?.id)}
          additionalContent={<StateContent>{{ state: item?.dataSourceState, kind: 'data_source' }}</StateContent>}
          subTile={
            showSubTile && <CampaignDataSourceTileDescription importer={item?.importer} campaignType={campaignType} />
          }
        />
      </label>
    </span>
  );
};

CampaignDataSourceTile.propTypes = {
  item: PropTypes.object,
  name: PropTypes.string.isRequired,
  selected: PropTypes.string,
  setSelected: PropTypes.func,
};

export default CampaignDataSourceTile;
