export const CHART_X_AXIS_STYLES = {
  tick: {
    fill: '#98A2B3',
  },
  tickLine: false,
  stroke: '#CCD0D4',
};

export const CHART_Y_AXIS_STYLES = {
  tick: {
    fill: '#98A2B3',
  },
  tickLine: false,
  stroke: '#CCD0D4',
};

const CHART_COLORS = [
  '#7BBC9A',
  '#478DB8',
  '#E9BA5D',
  '#E46E53',
  '#9C71C6',
  '#6CBDDF',
  '#757595',
  '#9D5373',
  '#B0D364',
  '#AF7C4F',
  '#79AF4F',
  '#4F70AF',
  '#DF7172',
  '#E177C4',
  '#93CB80',
  '#B9A970',
  '#5FC1C8',
  '#615FC8',
  '#C85F78',
  '#805B84',
];

export const CHART_COLOR_OTHER = '#B8B8B8';

export default CHART_COLORS;
