import React, { useContext } from 'react';
import { SearchContext } from '../index';
import { Select } from '../../form';

export const PmaxPerformance = () => {
  const {
    searchState,
    searchChange,
    searchOptions: { pmaxPerformances },
  } = useContext(SearchContext);

  if ((pmaxPerformances || []).length === 0) {
    return null;
  }
  return (
    <Select
      value={searchState.pmaxPerformances || 'all'}
      id="pmax-type-search"
      name="table_search[select_pmax_type]"
      doNotUseInternalState
      medium
      noMargin
      onChange={({ target: { value } }) => searchChange('pmaxPerformances', value)}
      collection={pmaxPerformances}
    />
  );
};

PmaxPerformance.propTypes = {};

export default PmaxPerformance;
