import React, { Component } from 'react';

class SelectionHandler extends Component {
  onMouseDown(event) {
    // event.preventDefault();
    if (event.target.classList.contains('selection-handler')) {
      this.props.onDrag(event);
    }
  }

  getPixelsScaled = pixel => pixel / this.props.scale;

  render() {
    const { boundingBox } = this.props;

    const handlerStyle = {
      ...styles.handler,
      ...boundingBox,
      width: boundingBox.width + this.getPixelsScaled(10),
      height: boundingBox.height + this.getPixelsScaled(10),
      left: boundingBox.left - this.getPixelsScaled(5),
      top: boundingBox.top - this.getPixelsScaled(5),
      border: `${this.getPixelsScaled(2)}px dashed #8cc8ff`,
      transform: `rotate(${boundingBox.rotate}deg)`,
    };

    // console.log(handlerStyle);

    return <div className={'selection-handler'} style={handlerStyle} onMouseDown={this.onMouseDown.bind(this)} />;
  }
}

const styles = {
  handler: {
    position: 'absolute',
    zIndex: 101,
  },
};

export default SelectionHandler;
