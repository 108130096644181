import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Row, Col, Tile, SmallHeading, Text, Tooltip, Modal, ModalBody, ScraperSelector } from '../components';
import ScraperButton from '../components/scraper/Button';
import { WrapperPropTypes } from './WrapperRenderers';
import { t } from '../i18n';
import imagePicker from '../scss_stylesheets/images/scraper_variable_picker.png';
import imageManual from '../scss_stylesheets/images/scraper_variable_manual.png';
import Badge from '../components/Badge';

const WizardTile = ({ image, type, children }) => (
  <Tile smallSpaced>
    <Row>
      <Col shrink>
        <img
          width="120px"
          height="120px"
          className="mw-100 border-radius-6"
          src={image}
          alt={`Variables selecttion - ${type}`}
        />
      </Col>
      <Col grow>
        <SmallHeading spacing={0}>{t('heading', { scope: `react.scraper.selection.variable_${type}` })}</SmallHeading>
        <Text className="mb-8">{t('description', { scope: `react.scraper.selection.variable_${type}` })}</Text>
        <div className="mt-a">{children}</div>
      </Col>
    </Row>
  </Tile>
);

WizardTile.propTypes = {
  image: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.any,
};

const ScraperWithWizardSelection = ({
  disabled,
  field,
  fieldData,
  options,
  addNewFieldText,
  children,
  connectAddNewFieldsToContainer,
  addNewField,
  getCurrentNotEmptyCount,
}) => {
  const [showForm, setShowForm] = useState(getCurrentNotEmptyCount() > 0);
  const [state, setState] = useState({ isOpened: false, url: '', urls: [], useJsBrowser: false });
  const containerRef = useRef(null);

  useEffect(() => {
    if (fieldData.filter(d => !d._destroy).length === 0) setShowForm(false);
  }, [fieldData.length]);

  // eslint-disable-next-line consistent-return
  const openModal = () => {
    const form = containerRef.current.closest('form');

    const urls = Array.from(document.querySelectorAll('[name*="[preview_variables_urls]"]')).map(e => e.value);
    const url = form.querySelector('[name*="[preview_variables_url]"]')?.value || urls[0];
    if (!url) {
      // eslint-disable-next-line no-alert
      return window.alert(t('react.scraper.wait_for_loading'));
    }
    setState({
      isOpened: true,
      url,
      urls,
      useJsBrowser: form.querySelectorAll('[name*="[use_js_browser]"]:checked').length > 0,
    });
  };

  const closeModal = () => {
    setState({ isOpened: false, url: '', useJsBrowser: false });
  };

  const saveAndCloseModal = ({
    css = 'div',
    value_type = 'string',
    value_source = 'text',
    select_index = '',
    attribute_source = '',
    placeholder_name = 'Test',
  }) => {
    if (!showForm) {
      setShowForm(true);
    }
    addNewField({
      css,
      value_type,
      value_source,
      select_index,
      attribute_source,
      placeholder_name,
    });
    closeModal();
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current
          .closest('form')
          .querySelector('[name*="[name]"]')
          .dispatchEvent(new window.Event('change', { bubbles: true }));
      }
    }, 0);
  };

  const saveAndCreateAnother = ({
    css = 'div',
    value_type = 'string',
    value_source = 'text',
    select_index = '',
    attribute_source = '',
    placeholder_name = 'Test',
  }) => {
    if (!showForm) {
      setShowForm(true);
    }
    addNewField({
      css,
      value_type,
      value_source,
      select_index,
      attribute_source,
      placeholder_name,
    });
    setTimeout(() => {
      if (containerRef.current) {
        containerRef.current
          .closest('form')
          .querySelector('[name*="[name]"]')
          .dispatchEvent(new window.Event('change', { bubbles: true }));
      }
    }, 0);
  };

  const getHeading = () => (
    <span>
      {t('react.scraper.scraper_wizard')}
      <Badge
        kind="darkBlue"
        textColor="light"
        className="ml-8 mr-16"
        size="small"
        style={{ top: '-5px', position: 'relative' }}
      >
        {t('views.beta_badge')}
      </Badge>
    </span>
  );

  const changeURL = newURL => {
    if (state.urls.indexOf(newURL) === -1) {
      setState(prev => ({ ...prev, urls: [...state.urls, newURL] }));
    }
    setState(prev => ({ ...prev, url: newURL }));
  };

  const randomizeUrl = () => {
    const { url, urls } = state;
    const index = urls.indexOf(url);
    setState({ ...state, url: urls[index + 2 > urls.length ? 0 : index + 1] });
  };

  return (
    <Col id={field} setRef={connectAddNewFieldsToContainer}>
      {!showForm && (
        <Row center padding="l" className="flex-responsive-row">
          <Col grow>
            <WizardTile type="picker" image={imagePicker}>
              <ScraperButton openModal={openModal} />
            </WizardTile>
          </Col>
          <Col shrink>
            <Text color="gray" uppercase className="text-center mv-8">
              {t('or', { scope: 'react.scraper.selection' })}
            </Text>
          </Col>
          <Col grow>
            <WizardTile type="manual" image={imageManual}>
              <Tooltip text={t('react.scraper.add_new_variable_tooltip')} className="tooltip--button">
                <Button
                  className={`js-add-field mb-0 ${options.add_button_class}`}
                  disabled={disabled || !addNewField}
                  icon="plus"
                  onClick={() => {
                    setShowForm(true);
                    if (showForm || fieldData.filter(d => !d._destroy).length === 0) {
                      addNewField({});
                    }
                  }}
                  secondary
                  data-test-id={`add-${field}-button`}
                  type="button"
                >
                  {addNewFieldText}
                </Button>
              </Tooltip>
            </WizardTile>
          </Col>
        </Row>
      )}
      {(showForm || fieldData.filter(d => d._destroy).length > 0) && children}
      {showForm && (
        <Row center>
          <Col shrink>
            <ScraperButton openModal={openModal} />
          </Col>
          <Col shrink>
            <Text color="gray" uppercase>
              {t('or', { scope: 'react.scraper.selection' })}
            </Text>
          </Col>
          <Col shrink>
            <Tooltip text={t('react.scraper.add_new_variable_tooltip')}>
              <Button
                className={`js-add-field mb-0 ${options.add_button_class}`}
                disabled={disabled || !addNewField}
                icon="plus"
                onClick={() => {
                  setShowForm(true);
                  if (showForm) {
                    addNewField({});
                  }
                }}
                secondary
                data-test-id={`add-${field}-button`}
                type="button"
              >
                {addNewFieldText}
              </Button>
            </Tooltip>
          </Col>
        </Row>
      )}
      <div ref={containerRef}>
        {state.isOpened && (
          <Modal onClose={closeModal} size="full" heading={getHeading()} className="Modal--grayBackground">
            <ModalBody classNames="pt-0 pr-0 pb-0 pl-16">
              <ScraperSelector
                {...state}
                style={{ height: '24px' }}
                changeURL={changeURL}
                onSaveClose={saveAndCloseModal}
                onSaveNew={saveAndCreateAnother}
                options={options}
                randomizeUrl={randomizeUrl}
              />
            </ModalBody>
          </Modal>
        )}
      </div>
    </Col>
  );
};

ScraperWithWizardSelection.propTypes = {
  ...WrapperPropTypes,
  addNewField: PropTypes.func.isRequired,
  addNewFieldText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ScraperWithWizardSelection;
