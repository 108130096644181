import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader/root';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import cs from 'classnames';
import { t } from '../../../i18n';
import { DropdownMenu, Loader, Button, Link, Row, Col, Icon } from '../../index';
import ColumnSettings from './index';
import { useMultiEdit, ACTIONS } from '../MultiEditContext';

const QUERY = gql`
  query($collectionKey: String!) {
    user {
      columnPresets(collection: $collectionKey) {
        id
        name
        columns
        global
        default
      }
    }
  }
`;

const SET_DEFAULT_QUERY = gql`
  mutation SetDefaultColumnPreset($id: BigInt!, $collection: String!) {
    setDefaultColumnPreset(id: $id, collection: $collection) {
      errors
    }
  }
`;

const ColumnPresetDropdown = ({
  columns,
  collectionKey: tempCollectionKey,
  collectionMapping,
  updateColumns,
  doNotShowColumns = [],
  suffix,
}) => {
  const collectionKey = suffix ? `${tempCollectionKey}-${suffix}` : tempCollectionKey;

  const [getColumnPresets, { loading, data, previousData, refetch }] = useLazyQuery(QUERY, {
    variables: { collectionKey },
  });
  const [columnPreset, setModalOpened] = useState(false);

  const finalData = data || previousData;

  const [setDefaultColumnPreset] = useMutation(SET_DEFAULT_QUERY);

  const { dispatch } = useMultiEdit();
  return (
    <React.Fragment>
      <DropdownMenu
        dataTestId={'table-custiom-columns-dropdown'}
        minWidth="200px"
        maxHeight="300px"
        onOpen={getColumnPresets}
        label={t('react.customized_columns.dropdown.columns')}
        labelIcon={<Icon kind="columns" inheritColor />}
        secondary
      >
        {finalData &&
          finalData.user &&
          finalData.user.columnPresets &&
          finalData.user.columnPresets.map(cp => {
            const isActive = JSON.stringify(columns) === JSON.stringify(cp.columns);
            const dataActive = isActive ? { 'data-test-id': `active-preset-${cp.global ? 'default' : cp.name}` } : {};
            return (
              <a key={cp.id} className={cs('ShowOnHoverWrapper', { active: isActive })} {...dataActive}>
                <Row noPadding center>
                  <Col
                    grow
                    data-test-id={cp.global ? 'preset-default' : `preset-${cp.name}`}
                    onClick={() => {
                      updateColumns(cp.columns);
                      if (!cp.global) {
                        setDefaultColumnPreset({ variables: { id: cp.id, collection: collectionKey } });
                      }
                    }}
                  >
                    {cp.global ? t('views.default_columns') : cp.name}
                  </Col>
                  <Col shrink className="ShowOnHoverElement mb-s mt-s">
                    {!cp.global && (
                      <Button
                        data-test-id={`edit-preset-${cp.name}`}
                        tertiary
                        onlyIcon
                        size="small"
                        icon="edit"
                        onClick={() => setModalOpened(cp)}
                      />
                    )}
                  </Col>
                </Row>
              </a>
            );
          })}
        {loading && (
          <div className="ml-a mr-a mv-8">
            <Loader size="small" />
          </div>
        )}
        <div className="DropdownMenu-delimiter mv-8 negative-mh-6" />
        <Link data-test-id="customize-columns" onClick={() => setModalOpened({ columns })}>
          {t('react.customized_columns.dropdown.customized_columns')}
        </Link>
      </DropdownMenu>
      {columnPreset && (
        <ColumnSettings
          {...columnPreset}
          doNotShowColumns={doNotShowColumns}
          collectionKey={collectionKey}
          updateColumns={newColumns => {
            updateColumns(newColumns);
            setModalOpened(false);
            dispatch({ type: ACTIONS.updateQuery, payload: { mapping: collectionMapping, columns: newColumns } });
          }}
          mapping={collectionMapping}
          refetchColumnPresets={() => {
            setModalOpened(false);
            refetch();
          }}
          onClose={() => setModalOpened(false)}
        />
      )}
    </React.Fragment>
  );
};

ColumnPresetDropdown.propTypes = {
  columns: PropTypes.array,
  collectionKey: PropTypes.string,
  collectionMapping: PropTypes.any,
  doNotShowColumns: PropTypes.array,
  updateColumns: PropTypes.func,
  suffix: PropTypes.string,
};

export default hot(ColumnPresetDropdown);
