/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { QueryHolder } from '../QueryHolder';

const QuerySelect = QueryHolder(({ children, data, onChange, name, value, getCollection, translatedName, error }) => (
  <div className={`Input ${error && 'has-error'}`}>
    <div className="Dropdown">
      {translatedName && (
        <label className="Input-label select optional" htmlFor={name}>
          {translatedName}
        </label>
      )}
      <select name={name} id={name} data-test-id={name} onChange={onChange} value={value}>
        <option value="" disabled="disabled" selected="selected">
          Please select account
        </option>
        {getCollection(data).map(children)}
      </select>
    </div>
    {error && <span className="Input-errorLabel">{error}</span>}
  </div>
));

export default QuerySelect;
