import React from 'react';
import moment from 'moment';
import { gql, useQuery } from '@apollo/client';
import { values, keyBy, merge } from 'lodash';
import PropTypes from 'prop-types';
import './init_hideshow_container';
import PerformanceOverview from './PerformanceOverview';
import { formatDate, formatInteger, formatPercentage, formatCurrency, t } from '../i18n';
import { createDateRange, transformData } from './utils';
import { ErrorBox, Loader } from '../components/index';

export const INPUTS = {
  date: {
    format: data => formatDate(data?.date || data, 'short'),
  },
  currency: {},
  clicks: { format: data => formatInteger(data.clicks), show: true, trendField: 'trendClicks' },
  impressions: { format: data => formatInteger(data.impressions), trendField: 'trendImpressions', show: true },
  spend: {
    format: data => formatCurrency(data.spend, { precision: 2, unit: data.currency }),
    trendField: 'trendSpend',
    show: true,
  },
  ctr: {
    format: data => formatPercentage(data.ctr, { precision: 2 }),
    trendField: 'trendCtr',
    show: true,
  },
  conversionValueCost: {
    format: data => formatPercentage(data.conversionValueCost, { precision: 2 }),
    trendField: 'trendConversionValueCost',
    show: true,
  },
  conversionValue: {
    format: data => formatCurrency(data.conversionValue, { precision: 2, unit: data.currency }),
    trendField: 'trendConversionValue',
    show: true,
  },
  conversions: {
    format: data => formatInteger(data.conversions),
    trendField: 'trendConversions',
    show: true,
  },
};

const PERFORMANCE_QUERY_DASHBOARD_ORGANIZATION = gql`
  query OrganizationDashboardStatus($organizationId: BigInt!, $dateFrom: ISO8601DateTime!, $dateTo: ISO8601DateTime!) {
    organization(id: $organizationId) {
      id
      statsByDate(dateFrom: $dateFrom, dateTo: $dateTo) {
        ${Object.keys(INPUTS).join(' ')}
      }
      stats {
        ${Object.keys(INPUTS).join(' ')}
        ${Object.keys(INPUTS)
          .filter(x => INPUTS[x].trendField)
          .map(y => INPUTS[y].trendField)
          .join(' ')}
      }
      auditLogData(dateFrom: $dateFrom, dateTo: $dateTo) {
        date
        count
      }
    }
  }
`;

const PERFORMANCE_QUERY_DASHBOARD_CAMPAIGN_STATS = gql`
  query OrganizationDashboardStatus($organizationId: BigInt!, $campaignSettingId: BigInt!, $dateFrom: ISO8601DateTime!, $dateTo: ISO8601DateTime!) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        id
        statsByDate(dateFrom: $dateFrom, dateTo: $dateTo) {
        ${Object.keys(INPUTS).join(' ')}
        }
        stats {
        ${Object.keys(INPUTS).join(' ')}
        ${Object.keys(INPUTS)
          .filter(x => INPUTS[x].trendField)
          .map(y => INPUTS[y].trendField)
          .join(' ')}
      }
      auditLogData(dateFrom: $dateFrom, dateTo: $dateTo) {
        date
        count
      }
      }
    }
  }
`;

export const DashboardRendererContent = ({ loading, error, data, date, ...rest }) => {
  if (loading) {
    return (
      <div className="Tile" style={{ height: '289px' }}>
        <div className="Tile-header" style={{ height: '41px' }} />
        <div className="Tile-content text-center mt-40">
          <Loader size="big" />
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <ErrorBox className="mb-24" withIcon>
        {t('alert_platform.incident_category.error')}
      </ErrorBox>
    );
  }

  const roughstatsByDate = data?.organization?.campaignSetting?.statsByDate || data?.organization?.statsByDate;
  const stats = data?.organization?.campaignSetting?.stats || data?.organization?.stats;
  const roughAuditLogData = data?.organization?.campaignSetting?.auditLogData || data?.organization?.auditLogData;

  const noHistoryData =
    !roughAuditLogData || roughAuditLogData.length === 0 || roughAuditLogData.filter(x => x?.count !== 0).length === 0;

  const interval = createDateRange(date.from, date.to, 1);
  const statsByDate = transformData(roughstatsByDate, interval, Object.keys(INPUTS));
  const auditLogData = transformData(roughAuditLogData, interval, ['date', 'count']).map(x =>
    x.count === 0
      ? { date: x.date, beOnAxis: 0, auditLogCount: x.count }
      : { date: x.date, auditLogCount: x.count, beOnAxis: 0 }
  );

  return (
    <PerformanceOverview
      {...rest}
      data={values(merge(keyBy(statsByDate, 'date'), keyBy(auditLogData, 'date')))}
      statsByDate={statsByDate}
      noHistoryData={noHistoryData}
      stats={stats}
      sourceIsEmpty={!roughstatsByDate || roughstatsByDate.length === 0}
    />
  );
};

DashboardRendererContent.propTypes = {
  data: PropTypes.object,
  date: PropTypes.object,
  error: PropTypes.bool,
  loading: PropTypes.bool,
};

export const DashboardRenderer = ({ query, queryOptions, ...rest }) => {
  const date = {
    from: moment()
      .subtract(30, 'days')
      .format('YYYY-MM-DD'),
    to: moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD'),
  };

  const queryResult = useQuery(query, queryOptions(date.from, date.to));

  return <DashboardRendererContent {...queryResult} {...rest} date={date} />;
};

export const ConnectedOrganizationDashboard = ({ organizationId, hideCloseButton }) => {
  const query = PERFORMANCE_QUERY_DASHBOARD_ORGANIZATION;
  const queryOptions = (from, to) => ({
    variables: {
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
      dateFrom: from,
      dateTo: to,
    },
  });

  return (
    <DashboardRenderer
      query={query}
      queryOptions={queryOptions}
      hideCloseButton={hideCloseButton}
      organizationId={organizationId}
    />
  );
};

export const ConnectedCampaignDashboard = ({ organizationId, campaignSettingId }) => {
  const query = PERFORMANCE_QUERY_DASHBOARD_CAMPAIGN_STATS;
  const queryOptions = (from, to) => ({
    variables: {
      organizationId: organizationId ? parseInt(organizationId, 10) : 0,
      campaignSettingId: campaignSettingId ? parseInt(campaignSettingId, 10) : 0,
      dateFrom: from,
      dateTo: to,
    },
  });

  return (
    <DashboardRenderer
      query={query}
      queryOptions={queryOptions}
      organizationId={organizationId}
      campaignSettingId={campaignSettingId}
      hideCloseButton
    />
  );
};

DashboardRenderer.propTypes = {
  query: PropTypes.object,
  queryOptions: PropTypes.func,
};

ConnectedOrganizationDashboard.propTypes = {
  hideCloseButton: PropTypes.string,
  organizationId: PropTypes.string.isRequired,
};

ConnectedCampaignDashboard.propTypes = {
  campaignSettingId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired,
};

export default ConnectedOrganizationDashboard;
