/* eslint-disable no-new */
import { Notifier } from '@airbrake/browser';

const airbrake = new Notifier({
  projectId: 309881,
  projectKey: 'e68b47879ea39a218e3d35327893fd6f',
  environment: process.env.RAILS_ENV,
});

airbrake.addFilter(notice => {
  if (process.env.RAILS_ENV !== 'production' && process.env.RAILS_ENV !== 'stage') {
    return null;
  }

  if (notice.errors[0].backtrace.some(item => item.file.includes('chrome-extension'))) {
    return null;
  }
  return notice;
});
