import React, { PureComponent } from 'react';
import _ from 'lodash';

import PropertyGroup from './PropertyGroup';
import InputCol from './InputCol';
import Columns from './Columns';
import { Col } from '../../../index';
import { t } from '../../../../i18n';

export default class ImagePosition extends PureComponent {
  render() {
    const { object, onChange } = this.props;
    if (!object.preserveAspectRatio) {
      return null;
    }
    const [pos, size] = object.preserveAspectRatio.split(' ');
    const [horizontal, vertical] = pos.replace('x', '').split('Y');

    if ((object.filename || '').endsWith('.svg')) {
      return null;
    }

    return (
      <PropertyGroup>
        <Col padding="l">
          <Columns label={t('react.image_generator.label.fit_mode')} withoutLabelPadding>
            <InputCol
              shrink
              showIf={_.has(object, 'preserveAspectRatio')}
              type="segmented"
              value={size}
              property="size"
              onChange={(x, value) => onChange('preserveAspectRatio', `x${horizontal}Y${vertical} ${value}`)}
              collection={[
                { value: 'meet', icon: 'crop-fit', tooltipText: t('fit', { scope: 'react.image_generator.crop' }) },
                { value: 'slice', icon: 'crop-fill', tooltipText: t('fill', { scope: 'react.image_generator.crop' }) },
              ]}
            />
          </Columns>

          <Columns label={t('react.image_generator.label.image_alignment')} withoutLabelPadding>
            <InputCol
              shrink
              type="segmented"
              name="horizontal_align"
              showIf={_.has(object, 'preserveAspectRatio')}
              value={horizontal}
              property="preserveAspectRatio"
              onChange={(x, value) => onChange('preserveAspectRatio', `x${value}Y${vertical} ${size}`)}
              collection={[
                { value: 'Min', icon: 'align-left', tooltipText: t('left', { scope: 'react.image_generator.align' }) },
                {
                  value: 'Mid',
                  icon: 'align-hcenter',
                  tooltipText: t('hcenter', { scope: 'react.image_generator.align' }),
                },
                {
                  value: 'Max',
                  icon: 'align-right',
                  tooltipText: t('right', { scope: 'react.image_generator.align' }),
                },
              ]}
            />
            <InputCol
              shrink
              type="segmented"
              name="vertical_align"
              showIf={_.has(object, 'preserveAspectRatio')}
              value={vertical}
              property="preserveAspectRatio"
              onChange={(x, value) => onChange('preserveAspectRatio', `x${horizontal}Y${value} ${size}`)}
              collection={[
                { value: 'Min', icon: 'align-top', tooltipText: t('top', { scope: 'react.image_generator.align' }) },
                {
                  value: 'Mid',
                  icon: 'align-vcenter',
                  tooltipText: t('vcenter', { scope: 'react.image_generator.align' }),
                },
                {
                  value: 'Max',
                  icon: 'align-bottom',
                  tooltipText: t('bottom', { scope: 'react.image_generator.align' }),
                },
              ]}
            />
          </Columns>
        </Col>
      </PropertyGroup>
    );
  }
}
