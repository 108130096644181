import React, { PureComponent } from 'react';
import _ from 'lodash';
import uuid from 'uuid';

import PropertyGroup from './PropertyGroup';
import InputCol from './InputCol';
import Columns from './Columns';
import Column from './Column';
import { Button, Col, Row, Loader, ErrorBox } from '../../../index';
import { t } from '../../../../i18n';

export default class SvgColorsPanel extends PureComponent {
  state = {
    showAllColors: false,
    errorMessage: null,
  };

  loadSvgColors = () => {
    if (this.loading) {
      return;
    }
    this.loading = true;
    window
      .fetch(this.props.object.xlinkHref)
      .then(d => d.text())
      .then(body => {
        const id = uuid();
        const newBody = (body.match(/id="([^"]+)"/g, '') || []).reduce((out, match) => {
          const matchId = match.replace('id="', '').replace('"', '');
          return out.replaceAll(matchId, `${id}-${matchId}`);
        }, body);
        this.props.onChange(
          'svgColors',
          {},
          {
            type: 'svg',
            originalWidth: this.props.object.width,
            originalHeight: this.props.object.height,
            svgContent: newBody,
          }
        );
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.warn(e.message);
        this.setState({ errorMessage: 'Unable to load SVG content' });
      });
  };

  render() {
    const { showAllColors, errorMessage } = this.state;
    const { object, onChange } = this.props;

    if (errorMessage) {
      return (
        <PropertyGroup>
          <Col padding="l">
            <Columns label={t('react.image_generator.label.svg_colors')} withoutLabelPadding>
              <ErrorBox>{errorMessage}</ErrorBox>
            </Columns>
          </Col>
        </PropertyGroup>
      );
    }
    if (object.type === 'img' && (object.filename || '').endsWith('.svg')) {
      this.loadSvgColors();
      return (
        <PropertyGroup>
          <Col padding="l">
            <Columns label={t('react.image_generator.label.svg_colors')} withoutLabelPadding>
              <Loader />
            </Columns>
          </Col>
        </PropertyGroup>
      );
    }

    const svgColors = _.uniq((object.svgContent || '').match(/#[a-f0-9]{3,6}/gi));

    return (
      <PropertyGroup showIf={object.type === 'svg'}>
        <Col padding="l">
          <Columns label={t('react.image_generator.label.svg_colors')} withoutLabelPadding>
            <Column shrink>
              <Button
                disabled={_.isEmpty(object.svgColors)}
                icon="repeat"
                size="small"
                onClick={() => onChange('svgColors', {})}
                secondary
              >
                {t('react.image_generator.button.reset')}
              </Button>
            </Column>
          </Columns>

          {svgColors.slice(0, showAllColors ? svgColors.length : 4).map(sourceColor => (
            <Row key={sourceColor} className="mb-8">
              <InputCol
                width="120px"
                type="color"
                value={object.svgColors[sourceColor] || sourceColor}
                onChange={(property, value) => onChange('svgColors', { ...object.svgColors, [sourceColor]: value })}
              />
            </Row>
          ))}

          {svgColors.length > 4 && (
            <Row>
              <Button
                secondary
                size="small"
                onClick={() => this.setState({ showAllColors: !showAllColors })}
                className="mt-8"
              >
                {showAllColors
                  ? t('react.image_generator.button.hide_colors')
                  : t('react.image_generator.button.show_all_colors', { count: svgColors.length })}
              </Button>
            </Row>
          )}
        </Col>
      </PropertyGroup>
    );
  }
}
