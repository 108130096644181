export default function countRestFromElements(elements, allCount, elementId) {
  if (allCount === -1) {
    return 0;
  }
  return findParents(elements, elementId).reduce((acc, el) => acc - el.itemsCount, allCount);
}

export function findParents(elements, parent, acc = []) {
  const el = elements[parent];
  if (el && el.parent !== 0) {
    return findParents(elements, el.parent, acc.concat(el));
  }
  return acc.concat(el);
}
