import React from 'react';
import PropTypes from 'prop-types';
import { t } from '../../i18n';
import { Row, Col, Icon, Text } from '../index';
import { AdPreviewContainer, FbPagePreview } from './index';
import gradientBackground from '../../../../assets/images/fb_ad_preview/fb_stories_gradient_background.png';
import progressBarSingle from '../../../../assets/images/fb_ad_preview/progress_single.png';
import progressBarCarousel from '../../../../assets/images/fb_ad_preview/progress_carousel.png';

const InstagramStoriesPreview = ({ media, pageImageUrl, pageName, label, ctaText, type }) => (
  <AdPreviewContainer icon="instagram" title={t('fb_ad_preview.instagram', { default: 'Instagram' })}>
    <div className="InstagramStoriesPreview-container">
      <div
        className="InstagramStoriesPreview-gradientBackground"
        style={{ background: `url(${gradientBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      />
      <div
        className="InstagramStoriesPreview-bluredBackground"
        style={{ background: `url(${media.image_url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      />
      <div className="InstagramStoriesPreview-mainHeader">
        <img
          className="InstagramStoriesPreview-progressBar"
          src={type === 'single' ? progressBarSingle : progressBarCarousel}
          alt="Progress bar"
        />
        <Row padding="s" center justifyBetween>
          <Col shrink>
            <FbPagePreview
              url={pageImageUrl}
              pageName={pageName}
              label={label}
              imageSize="22px"
              nameSize="7px"
              labelSize="6px"
              theme="light"
              withIcon
            />
          </Col>
          <Col shrink>
            <Row center>
              <Icon kind="more" color="#ffffff" size="9px" />
              <Icon kind="close" color="#ffffff" style={{ marginLeft: '15px' }} size="8px" />
            </Row>
          </Col>
        </Row>
      </div>
      <div className="InstagramStoriesPreview-mainImage">
        {media?.image_url && (
          <img
            src={media.image_url}
            alt={t('fb_ad_preview.facebook_feed_preview', { default: 'Facebook feed preview' })}
          />
        )}
      </div>
      <Row className="InstagramStoriesPreview-footerCtaWrapper" justifyCenter>
        <Row shrink className="InstagramStoriesPreview-footerCta" center>
          <Col shrink style={{ marginRight: '2px' }}>
            <Icon kind="anchor" color="#839A58" size="13px" />
          </Col>
          <Col>
            <Text tag="div" semibold style={{ fontSize: '13px', color: '#839A58' }}>
              {ctaText}
            </Text>
          </Col>
        </Row>
      </Row>
    </div>
  </AdPreviewContainer>
);

InstagramStoriesPreview.propTypes = {
  media: PropTypes.object,
  pageImageUrl: PropTypes.string,
  pageName: PropTypes.string,
  label: PropTypes.string,
  primaryText: PropTypes.string,
  ctaText: PropTypes.string,
  type: PropTypes.oneOf(['single', 'carousel']),
};

export default InstagramStoriesPreview;
