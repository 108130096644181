import { gql, useMutation } from '@apollo/client';
import { useMultiEdit } from '../../MultiEditContext';

const FIND_AND_REPLACE = (collectionKey, save) => gql`
  mutation FindAndReplace($organizationId: BigInt!,$ids: [String!]!, $source: String!, $target: String!, $ignoreCase: Boolean ){
    generatorFindAndReplace(organizationId: $organizationId, collectionKey: "${collectionKey}", ids: $ids, find: $source, replace: $target, ignoreCase: $ignoreCase, save: ${save} ) {
      ${
        !save
          ? ''
          : `${collectionKey} {
        id
        getPlaceholdersWithoutConditions
        possibleVariables
      }`
      }
      changes {
        id
        changes
        hasMissingVariable
      }
      errors
    }
  }
`;

const useReplaceSubmit = ({ source, target, ignoreCase, save, onCompleted, finalSelected }) => {
  // prettier-ignore
  const { state: { multiEdit, selected, organizationId, collectionKey: tempCollectionKey } } = useMultiEdit();
  const ids = finalSelected || selected;

  const collectionKey = typeof multiEdit?.findAndReplace === 'string' ? multiEdit.findAndReplace : tempCollectionKey;

  const [onSubmit, { data, loading }] = useMutation(FIND_AND_REPLACE(collectionKey, save), {
    variables: { organizationId, ids: ids.map(id => `${id}`), source, target, ignoreCase },
    onCompleted: res => (typeof onCompleted === 'function' ? onCompleted(res) : null),
  });

  return { onSubmit, data, loading, source, target, ignoreCase };
};

export default useReplaceSubmit;
