import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { listenOnModalChangeConditions } from '../RemoteModal';
import { SmartSelect } from '../form';
import { cssVariables, Icon, SimpleTooltip } from '../index';

const GET_PRODUCT_SETS_QUERY = gql`
  query GetProductSetsList(
    $organizationId: BigInt!
    $campaignSettingId: BigInt!
    $dataSourceId: BigInt!
    $fulltext: String
  ) {
    organization(id: $organizationId) {
      id
      campaignSetting(id: $campaignSettingId) {
        dataSource {
          allItemsCount
          name
        }
      }
    }
    collection(
      page: 1
      limit: 10000
      identifier: "itemGroup"
      dataSourceId: $dataSourceId
      organizationId: $organizationId
    ) {
      itemGroups(fulltext: $fulltext) {
        id
        name
        productsCount
        matchAll
        conditions {
          toDataPreview
          selectedItemGroupId
        }
      }
    }
  }
`;

const ProductSetsList = ({
  organization_id: organizationId,
  campaignSetting_id: campaignSettingId,
  data_source_id: dataSourceId,
  option_renderer: optionRenderer,
  notify_id: notifyId,
  value,
  hint,
  label,
}) => {
  const { loading, data, refetch } = useQuery(GET_PRODUCT_SETS_QUERY, {
    variables: { organizationId, campaignSettingId, dataSourceId, fulltext: '' },
  });

  const allItemGroups = data?.collection?.itemGroups || [];
  const allItemGroupsSorted = [...allItemGroups].sort((a, b) => {
    if (a.matchAll && !b.matchAll) {
      return -1;
    }
    if (!a.matchAll && b.matchAll) {
      return 1;
    }
    return b.id - a.id;
  });

  const allItemGroupsNormalized = allItemGroupsSorted.map(item => ({
    label: item.name,
    value: parseInt(item.id, 10) || item.id,
    editLink: `/organizations/${organizationId}/data_sources/${dataSourceId}/product_sets/${item.id}/edit`,
    matchAll: item.matchAll,
    conditions: item.conditions,
    notifyId: `product_set_selection-${notifyId}`,
  }));

  useEffect(() => {
    const unsubscribe = listenOnModalChangeConditions(refetch);
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <div>
        <div className="d-flex align-items-baseline flex-gap-4">
          <span className="Input-label mb-4 react_form optional">{label}</span>
          <SimpleTooltip text={hint} style={{ marginBottom: '4px' }}>
            <Icon color={cssVariables.textGhost} kind="info-circle-fill" size="16px" />
          </SimpleTooltip>
        </div>

        {!loading && (
          <SmartSelect
            initOnPropsChange
            collection={allItemGroupsNormalized}
            label={label}
            optionRenderer={optionRenderer}
            selectOnModalChange={notifyId}
            value={value}
            name="fb_adtext[item_group_subject_attributes][item_group_id]"
          />
        )}
      </div>
    </>
  );
};

ProductSetsList.propTypes = {
  organization_id: PropTypes.number.isRequired,
  campaignSetting_id: PropTypes.number.isRequired,
  data_source_id: PropTypes.number.isRequired,
  option_renderer: PropTypes.string.isRequired,
  notify_id: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  hint: PropTypes.string,
  label: PropTypes.string,
};

export default ProductSetsList;
