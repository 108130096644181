import React from 'react';
import RPT from 'prop-types';

const StatusSwitch = ({ allAdtexts, i18n, handleChangeFilterState }) => (
  <div>
    <div className="Segmented Segmented--medium">
      <div className="Segmented-wrapper">
        <div />
        <div className="radio">
          <input
            id="all_adtexts"
            name="status_switch"
            type="radio"
            checked={allAdtexts ? 'checked' : ''}
            value="1"
            onChange={e => {
              handleChangeFilterState({
                target: { checked: e.target.value === '1' },
              });
            }}
          />
          <label htmlFor="all_adtexts">{i18n.status_all}</label>
        </div>
        <div className="radio">
          <input
            id="running_only_adtexts"
            name="status_switch"
            type="radio"
            checked={allAdtexts ? '' : 'checked'}
            value="0"
            onChange={e => {
              handleChangeFilterState({
                target: { checked: e.target.value === '1' },
              });
            }}
          />
          <label htmlFor="running_only_adtexts">{i18n.status_running_only}</label>
        </div>
      </div>
    </div>
  </div>
);

StatusSwitch.propTypes = {
  allAdtexts: RPT.bool.isRequired,
  i18n: RPT.object.isRequired,
  handleChangeFilterState: RPT.func.isRequired,
};
export default StatusSwitch;
