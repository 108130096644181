import React, { useMemo } from 'react';
import { isEqual, uniqWith } from 'lodash';
import PropTypes from 'prop-types';
import { Col, Row } from '../layout';
import Searchbox from '../../organizations_dropdown/Searchbox';
import Select from '../form/Select';
import { t } from '../../i18n';
import { CreateElementButton } from '../index';

const Filter = ({ filter, setFilter, variableTransformations, createButtonProps, isSearchhidden }) => {
  const feedExportOptions = useMemo(
    () => [
      { label: t('react.transformations.filter.feed_export_all'), value: '' },
      ...uniqWith(
        variableTransformations.flatMap(variableTransformation =>
          variableTransformation.transformationSubjects.flatMap(subject =>
            subject.appliedFeedExports.map(feedExport => ({
              key: `option-feed-${feedExport.id}`,
              value: feedExport.id,
              label: feedExport.name,
            }))
          )
        ),
        isEqual
      ),
    ],
    [variableTransformations]
  );

  return (
    <Row className="mb-16">
      <Col shrink>
        <CreateElementButton {...createButtonProps} shouldCloseOnSuccess />
      </Col>
      {!isSearchhidden && (
        <Col width="180px">
          <Searchbox
            searchBoxText={t('react.general.search')}
            searchValue={filter.search}
            handleSearch={({ target: { value } }) => setFilter(prev => ({ ...prev, search: value }))}
            testId="form-transformations-filter-search"
          />
        </Col>
      )}
      <Col width="180px">
        <Select
          id="filter-feed-export"
          name="filter-feed-export"
          collection={feedExportOptions}
          value={filter.feedExport}
          doNotUseInternalState
          onChange={({ target: { value } }) => setFilter(prev => ({ ...prev, feedExport: value }))}
          testId="form-transformations-filter-feed-export"
        />
      </Col>
    </Row>
  );
};

export const TransformationsFilterT = PropTypes.shape({
  feedExport: PropTypes.string,
  search: PropTypes.string,
});

Filter.propTypes = {
  createButtonProps: PropTypes.object,
  filter: TransformationsFilterT,
  setFilter: PropTypes.func,
  isSearchhidden: PropTypes.bool,
  variableTransformations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      transformationSubjects: PropTypes.arrayOf(
        PropTypes.shape({
          appliedFeedExports: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              name: PropTypes.string,
            })
          ),
        })
      ),
    })
  ),
};

export default Filter;
