import { cssVariables } from '../../index';

const smartSelectStyles = {
  container: (provided, state) => ({
    ...provided,
    pointerEvents: state.isDisabled && 'all',
    fontSize: '12px',
    '&:hover': state.isDisabled && { cursor: 'not-allowed' },
    'fieldset:disabled &:hover': { cursor: 'not-allowed' },
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow:
      (state.menuIsOpen && '0px 0px 0px 2px #E0DAFA') ||
      (state.isDisabled && 'unset') ||
      '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
    transition: 'none',
    borderColor:
      (state.menuIsOpen && cssVariables.interactivePrimaryDefault) ||
      (state.isDisabled && cssVariables.interfaceDisabledDefault) ||
      cssVariables.interactiveFormGhost,
    borderWidth: '1px',
    cursor: 'text',
    minHeight: '32px',
    color: state.isDisabled ? cssVariables.interfaceDisabledOnDefault : cssVariables.interactiveFormIntense,
    pointerEvents: state.isDisabled && 'none',
    backgroundColor:
      (state.menuIsOpen && cssVariables.interactiveFormContrast) ||
      (state.isDisabled && cssVariables.interfaceDisabledDefault) ||
      cssVariables.interactiveFormDefault,
    '&:hover': !state.menuIsOpen && {
      borderColor: cssVariables.interactiveFormSubtle,
      backgroundColor: cssVariables.interactiveFormContrast,
    },
    'fieldset:disabled &': {
      pointerEvents: 'none',
      color: cssVariables.interfaceDisabledOnDefault,
      backgroundColor: cssVariables.interfaceDisabledDefault,
      borderColor: cssVariables.interfaceDisabledDefault,
      boxShadow: 'unset',
    },
  }),
  valueContainer: provided => ({
    ...provided,
    alignSelf: 'stretch',
    padding: '1px 8px',
    gap: '4px',
    '.SmartSelect--list &': {
      padding: '4px 6px',
    },
  }),
  placeholder: provided => ({
    ...provided,
    margin: '0',
    color: cssVariables.interactiveFormMuted,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color:
      (state.selectProps.menuIsOpen && cssVariables.iconMuted) ||
      (state.selectProps.isDisabled && cssVariables.interfaceDisabledOnDefault) ||
      cssVariables.iconSubtle,
    transform: (state.selectProps.menuIsOpen && 'rotateX(180deg)') || 'rotateX(0)',
    transition: 'transform ease 0.15s',
    padding: '0 8px 0 0',
    lineHeight: '20px',
    cursor: 'pointer',
  }),
  clearIndicator: provided => ({
    ...provided,
    padding: 0,
    color: cssVariables.iconGhost,
    '&:hover, &:focus': {
      cursor: 'pointer',
      color: cssVariables.iconMuted,
    },
    '&:active': { color: cssVariables.iconSubtle },
  }),
  singleValue: provided => ({
    ...provided,
    margin: '0',
    color: 'inherit',
  }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.isDisabled
      ? cssVariables.interfaceDisabledDefault
      : cssVariables.interactivePrimaryGhost,
    color: state.selectProps.isDisabled
      ? cssVariables.interfaceDisabledOnDefault
      : cssVariables.interactivePrimaryIntense,
    border: `1px solid ${cssVariables.interactivePrimarySubtle}`,
    borderRadius: '6px',
    padding: '2px 6px',
    display: 'inline-flex',
    alignItems: 'center',
    gap: '4px',
    margin: 0,
  }),
  multiValueLabel: provided => ({
    ...provided,
    fontFamily: 'Inter Medium',
    fontSize: '11px',
    lineHeight: '14px',
    color: 'inherit',
    padding: 0,
    paddingLeft: 0,
    '& .Icon': {
      color: cssVariables.interactivePrimaryDefault,
    },
  }),
  multiValueRemove: provided => ({
    ...provided,
    color: 'inherit',
    padding: 0,
    '&:hover, &:focus': {
      opacity: 0.7,
      cursor: 'pointer',
      backgroundColor: 'inherit',
      color: 'inherit',
    },
    '&:active': { backgroundColor: 'inherit', color: 'inherit' },
  }),
  menu: (provided, state) => ({
    ...provided,
    zIndex: '110',
    boxShadow: '0px 4px 6px 0px rgba(0, 0, 0, 0.08)',
    marginTop: '6px',
    border: `1px solid ${cssVariables.interfaceOutlineBold}`,
    borderRadius: '8px',
    display: state.selectProps.isDisabled && 'none',
    'fieldset:disabled &': {
      display: 'none',
    },
  }),
  menuList: provided => ({
    ...provided,
    padding: '6px',
  }),
  option: (provided, state) => ({
    ...provided,
    fontFamily: 'Inter Medium',
    fontSize: '13px',
    lineHeight: '120%',
    borderRadius: '4px',
    color: cssVariables.interactiveSecondaryOnSecondary,
    marginBottom: '4px',
    backgroundColor:
      (state.isSelected && cssVariables.interactivePrimaryFaint) ||
      (state.isFocused && cssVariables.interactiveNeutralGhost),
    '&:hover, &:focus': {
      backgroundColor:
        (state.isSelected && cssVariables.interactivePrimaryGhost) || cssVariables.interactiveNeutralGhost,
      cursor: 'pointer',
    },
    '&:active': {
      backgroundColor:
        (state.isSelected && cssVariables.interactivePrimarySubtle) || cssVariables.interactiveNeutralSubtle,
      cursor: 'pointer',
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
  }),
};

export default smartSelectStyles;
