import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { gql, useMutation } from '@apollo/client';
import { Button, Col, Row, Icon, SmallHeading, Link, TimeAgo, Text, Ellipsis } from '../index';
import defaultImage from './default_profile_image.jpg';
import LikeIcon from './LikeIcon';
import { useAccordion } from '../Accordion';
import { t } from '../../i18n';
import Badge from '../Badge';

const INTERACTION_QUERY = gql`
  mutation RecordInteraction($id: String!, $seen: Boolean!, $vote: String) {
    recordNotificationInteraction(id: $id, seen: $seen, vote: $vote) {
      notification {
        id
        seen
        vote
      }
    }
  }
`;

export const IconBox = ({ icon, lightBackground }) =>
  icon ? (
    <div className={cs('IconBox', { 'IconBox--lightBackground': lightBackground })}>
      <Icon
        kind={icon}
        className={`Icon--colored ${icon === 'warning' ? 'notification-warning' : icon}`}
        inheritColor
        size="24px"
      />
    </div>
  ) : null;

export const OrganizationsBox = ({ organizations }) =>
  organizations && organizations.length > 0 ? (
    <div className="mb-8">
      <Row center flexwrap>
        {organizations.map(({ name, showLink }) => (
          <Col shrink>
            <Link className="Link--dropdown" key={name} href={showLink}>
              {name}
            </Link>
          </Col>
        ))}
      </Row>
    </div>
  ) : null;

export const RelatedSubject = ({ relatedSubject, twoLines }) =>
  relatedSubject && relatedSubject?.children ? (
    <React.Fragment>
      {!twoLines && (
        <Text color="softGray" className="ph-8 mb-8">
          {'>'}
        </Text>
      )}
      <Ellipsis className="mb-8">
        <Link className="Link--dropdown" href={relatedSubject.href}>
          {relatedSubject.children}
        </Link>
      </Ellipsis>
    </React.Fragment>
  ) : null;

export const AuthorInfo = ({ author }) =>
  author ? (
    <React.Fragment>
      <img
        src={author.image || defaultImage}
        width="16px"
        height="16px"
        alt={`${author.firstName} ${author.lastName} icon`}
        className="mr-4 border-radius-50"
      />
      <Ellipsis style={{ fontSize: '11px' }}>
        <Text size="sm" color="softGray" className="mr-16">
          {author?.firstName} {author.lastName}
        </Text>
      </Ellipsis>
    </React.Fragment>
  ) : null;

export const TimeInfo = ({ time }) =>
  time ? (
    <Text size="sm" color="softGray" className="text-nowrap">
      <TimeAgo>{time}</TimeAgo>
    </Text>
  ) : null;

const PrimaryActionButton = ({ href, children, hideButton, small, markAsSeen }) =>
  !hideButton && children ? (
    <Button
      className="Link--dropdown"
      tag="a"
      href={href}
      primary
      size={small && 'small'}
      maxWidth={small ? '120px' : undefined}
      data-test-id="notifications-primary_action_button"
      onClick={e => {
        e.preventDefault();
        markAsSeen().then(() => {
          window.location.href = href;
        });
      }}
    >
      {children}
    </Button>
  ) : null;

export const ActionBar = ({ state, link, isAccordionOpen, markAsSeen }) =>
  state ? (
    <Col shrink top>
      {state === 'closed' ? (
        <Badge kind="lightGreen" size="extraLarge">
          <Icon kind="double-check" className="mr-4" inheritColor />
          {t('react.notification.resolved')}
        </Badge>
      ) : (
        <PrimaryActionButton {...link} hideButton={isAccordionOpen} markAsSeen={markAsSeen} />
      )}
    </Col>
  ) : null;

const ImageOrVideo = ({ imageLink, youtubeLink, ...rest }) =>
  imageLink || youtubeLink ? (
    <Col shrink>
      {youtubeLink ? (
        <React.Fragment>
          <div className="video-wrapper video-wrapper--notification">
            <iframe
              frameBorder="0"
              src={youtubeLink.replace('https://youtu.be/', 'https://www.youtube.com/embed/')}
              width="560px"
              height="315px"
              title="Youtube embeded video"
              allowFullScreen
            />
          </div>
          <DetailContentOptions {...rest} className="mt-16" />
        </React.Fragment>
      ) : (
        <img src={imageLink} width="300px" alt="Message detail" />
      )}
    </Col>
  ) : null;

const LIKED = 'liked';
const DISLIKED = 'disliked';

export const Vote = ({ likesEnabled, vote, id, shouldRefetchAfterVote, ...rest }) => {
  const [voteState, setVoteState] = React.useState({ current: vote, likeLoading: false, dislikeLoading: false });
  if (!likesEnabled) return null;

  const [executeVote] = useMutation(INTERACTION_QUERY, {
    onCompleted: d => {
      const newVote = d?.recordNotificationInteraction?.notification?.vote;
      setVoteState({ current: newVote, likeLoading: false, dislikeLoading: false });
      new window.NotificationCenter().show_success(t('react.dashboard.votes.vote_recorder'));

      if (shouldRefetchAfterVote && newVote) window.newsArticlesRefetchQuery();
    },
    onError: () => {
      setVoteState(prev => ({ ...prev, likeLoading: false, dislikeLoading: false }));
      new window.NotificationCenter().show_error(t('react.dashboard.votes.vote_recorder_error'));
    },
  });

  const handleVote = kind => {
    setVoteState(prev => ({ ...prev, likeLoading: kind === LIKED, dislikeLoading: kind === DISLIKED }));
    executeVote({ variables: { id, seen: true, vote: kind } });
  };

  const isLike = voteState?.current === LIKED;
  const isDislike = voteState?.current === DISLIKED;
  const noVote = voteState?.current === null;

  return (
    <Col shrink>
      <Row data-test-id="vote">
        <Link className="mr-16" data-test-id="vote-liked" onClick={() => !isLike && handleVote(LIKED)}>
          <LikeIcon {...rest} loading={voteState.likeLoading} kind="good" selected={isLike || noVote} />
        </Link>
        <Link data-test-id="vote-disliked" onClick={() => !isDislike && handleVote(DISLIKED)}>
          <LikeIcon {...rest} loading={voteState.dislikeLoading} kind="bad" selected={isDislike || noVote} />
        </Link>
      </Row>
    </Col>
  );
};

const DetailContentOptions = ({ state, learnMoreLink, link, className, ...rest }) => (
  <Row shrink center flexwrap className={className} padding="l">
    {state && state === 'opened' && link && link?.children && (
      <Col shrink className={cs({ 'mr-24': !learnMoreLink, 'mr-8': learnMoreLink })}>
        <PrimaryActionButton {...link} markAsSeen={rest.markAsSeen} />
      </Col>
    )}
    {learnMoreLink && (
      <Col shrink className="mr-24">
        <Button secondary href={learnMoreLink.href} tag="a" data-test-id="notifications-learn_more">
          {t('react.notification.learn_more')}
        </Button>
      </Col>
    )}
    <Vote {...rest} />
  </Row>
);

const Detail = ({ message, youtubeLink, ...rest }) => (
  <Col grow>
    {/* eslint-disable-next-line react/no-danger */}
    <div className="Text Text--gray mb-16" dangerouslySetInnerHTML={{ __html: message }} />
    {!youtubeLink && <DetailContentOptions {...rest} />}
  </Col>
);

const NotificationHeader = ({
  notification,
  isAccordionOpen = false,
  markAsSeen,
  isSeen = false,
  onlyHeader = false,
}) => (
  <Row
    className={cs('Message-header', { 'Message-header--accordion': !onlyHeader, 'Message-header--stripe': !isSeen })}
    center
    padding="xl"
    data-test-id="notification-message_header"
  >
    <Col width="40px">
      <IconBox {...notification} />
    </Col>
    <Col grow>
      {notification.subject && <SmallHeading spacing={4}>{notification.subject}</SmallHeading>}
      <Row shrink noPadding className="mb-0" center>
        <OrganizationsBox {...notification} />
        <RelatedSubject {...notification} />
      </Row>
      <Row center>
        <AuthorInfo {...notification} />
        <TimeInfo {...notification} />
      </Row>
    </Col>
    {onlyHeader && !isSeen && (
      <Col shrink className="mr-16">
        <Button tertiary onClick={() => markAsSeen()} data-test-id="notifications-mark_as_seen_button">
          {t('react.notification.mark_as_seen')}
        </Button>
      </Col>
    )}
    <ActionBar {...notification} isAccordionOpen={isAccordionOpen} markAsSeen={markAsSeen} />
  </Row>
);

const NotificationMessage = React.memo(notification => {
  const [changeVisibility] = useMutation(INTERACTION_QUERY);
  const markAsSeen = () => changeVisibility({ variables: { id: notification.id, seen: true } });

  const isOpenedOnRender = notification?.selecetedID === notification.id;
  const [isAccordionOpen, Accordion] = useAccordion({ callback: markAsSeen, forceOpen: isOpenedOnRender });

  if (!notification.message && !notification.youtubeLink && !notification.imageLink) {
    return (
      <div className={cs('Message-wrapper', { Highlight: isOpenedOnRender })} id={notification.id}>
        <NotificationHeader
          isSeen={notification?.seen}
          notification={notification}
          onlyHeader
          markAsSeen={markAsSeen}
        />
      </div>
    );
  }
  return (
    <div
      className={cs('Message-wrapper', { Highlight: isOpenedOnRender })}
      id={notification.id}
      data-test-id={`notification-message--${notification.id}`}
    >
      <Accordion
        data-test-id={`notification-message-accordion--${notification.id}`}
        headerComponent={
          <NotificationHeader
            isSeen={notification?.seen}
            isAccordionOpen={isAccordionOpen}
            notification={notification}
            markAsSeen={markAsSeen}
          />
        }
      >
        <Row padding="l" data-test-id={`notification-message-content--${notification.id}`}>
          <ImageOrVideo {...notification} />
          <Detail {...notification} markAsSeen={markAsSeen} />
        </Row>
      </Accordion>
    </div>
  );
});

const LinkType = PropTypes.shape({ children: PropTypes.string, href: PropTypes.string });

IconBox.propTypes = {
  icon: PropTypes.string,
  lightBackground: PropTypes.bool,
};

OrganizationsBox.propTypes = {
  organizations: PropTypes.array,
};

RelatedSubject.propTypes = {
  relatedSubject: PropTypes.object,
  twoLines: PropTypes.bool,
};

AuthorInfo.propTypes = {
  author: PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string, image: PropTypes.string }),
};

TimeInfo.propTypes = {
  time: PropTypes.string,
};

PrimaryActionButton.propTypes = {
  hideButton: PropTypes.bool,
  href: PropTypes.string,
  children: PropTypes.any,
  markAsSeen: PropTypes.func,
  small: PropTypes.bool,
};

ActionBar.propTypes = {
  isAccordionOpen: PropTypes.bool,
  link: LinkType,
  markAsSeen: PropTypes.func,
  state: PropTypes.oneOf(['opened', 'open', 'closed']),
};

ImageOrVideo.propTypes = {
  youtubeLink: PropTypes.string,
  imageLink: PropTypes.string,
};

Vote.propTypes = {
  id: PropTypes.string,
  likesEnabled: PropTypes.bool,
  shouldRefetchAfterVote: PropTypes.bool,
  size: PropTypes.number,
  vote: PropTypes.string,
};

DetailContentOptions.propTypes = {
  className: PropTypes.string,
  link: LinkType,
  learnMoreLink: LinkType,
  state: PropTypes.oneOf(['opened', 'open', 'closed']),
};

Detail.propTypes = {
  message: PropTypes.string,
  youtubeLink: PropTypes.string,
};

NotificationHeader.propTypes = {
  notification: PropTypes.object,
  markAsSeen: PropTypes.func,
  isAccordionOpen: PropTypes.bool,
  onlyHeader: PropTypes.bool,
  isSeen: PropTypes.bool,
};
NotificationMessage.propTypes = {};

export default NotificationMessage;
