import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import uuid from 'uuid';
import { Button, Tooltip } from '../index';

export default class IconSwitch extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    disabled: PropTypes.bool,
    doNotUseInternalState: PropTypes.bool,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    offIcon: PropTypes.string,
    onChange: PropTypes.func,
    onIcon: PropTypes.string,
    required: PropTypes.bool,
    testId: PropTypes.string,
    value: PropTypes.any.isRequired,
  };

  static defaultProps = {
    doNotUseInternalState: false,
    required: false,
  };

  state = {
    value: this.props.value,
  };

  handleChange = e => {
    const {
      target: { checked },
    } = e;

    this.setState({ value: checked });

    if (typeof this.props.onChange === 'function') {
      this.props.onChange({ target: { value: checked } });
    }
  };

  handleButtonClick = e => {
    // eslint-disable-next-line no-unused-expressions
    typeof e?.target?.blur === 'function' && e.target.blur();
    this.setState(state => {
      this.props.onChange({ target: { value: !state.value } });
      return { value: !state.value };
    });
  };

  render() {
    const {
      className,
      value,
      name,
      id,
      disabled,
      label,
      required,
      doNotUseInternalState,
      onIcon,
      offIcon,
      testId,
    } = this.props;
    const selected = doNotUseInternalState ? value : this.state.value;

    const inputId = `${id || name}-input-id-${uuid()}`;

    const elements = (
      <div
        className={cs(className, 'form-group', 'inline-switch', 'switch', {
          required,
          disabled: !!disabled,
        })}
      >
        <input disabled={!!disabled && 'disabled'} name={name} type="hidden" value="0" />
        <input
          className={cs('hidden')}
          type="checkbox"
          value="1"
          checked={selected}
          disabled={!!disabled && 'disabled'}
          name={name}
          id={inputId}
          onChange={this.handleChange}
        />
        <label htmlFor={inputId}>
          <Button
            data-test-id={testId}
            tertiary
            onClick={this.handleButtonClick}
            onlyIcon
            icon={value ? onIcon : offIcon}
          />
        </label>
      </div>
    );

    return label ? <Tooltip text={label}>{elements}</Tooltip> : elements;
  }
}
