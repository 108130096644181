import React, { useEffect } from 'react';
import { Col, Button } from '../components/index';
import initDashboardVisibility from './init_hideshow_container';

const HideButton = () => {
  useEffect(() => {
    initDashboardVisibility();
  }, []);

  return (
    <Col shrink>
      <Button onlyIcon size="small" kind="tertiary" icon="close" className="js-dashboard-close-button" />
    </Col>
  );
};

export default HideButton;
