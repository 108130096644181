/* eslint-disable import/prefer-default-export */
import { t, tHtml } from '../i18n';

export const dsImporterDescriptions = {
  PerformanceMaxCampaign: {
    csv_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_pmax', {
        default: 'This source is ideal for running Performance Max Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_gmc_attribute_optimization.', {
          default: 'Enables GMC attribute optimization.',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.seamlessly_integrates_with_various_platforms', {
          default: 'Seamlessly integrates with various platforms.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
    },
    xml_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_pmax', {
        default: 'This source is ideal for running Performance Max Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_gmc_attribute_optimization.', {
          default: 'Enables GMC attribute optimization.',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.seamlessly_integrates_with_various_platforms', {
          default: 'Seamlessly integrates with various platforms.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
    },
    json_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_pmax', {
        default: 'This source is ideal for running Performance Max Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_gmc_attribute_optimization.', {
          default: 'Enables GMC attribute optimization.',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.seamlessly_integrates_with_various_platforms', {
          default: 'Seamlessly integrates with various platforms.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
    },
    filtered_data_source_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_pmax', {
        default: 'This source is ideal for running Performance Max Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_gmc_attribute_optimization.', {
          default: 'Enables GMC attribute optimization.',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.seamlessly_integrates_with_various_platforms', {
          default: 'Seamlessly integrates with various platforms.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
    },
    resolve_importer_on_the_fly: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_pmax', {
        default: 'This source is ideal for running Performance Max Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_gmc_attribute_optimization', {
          default: 'Enables GMC attribute optimization.',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.seamlessly_integrates_with_various_platforms', {
          default: 'Seamlessly integrates with various platforms.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
    },
    merchant_center_importer: {
      type: 'error',
      title: t('data_source_selection.pmax_not_suitable', {
        default: 'This source is not suitable for Performance Max campaign due to many limitations.',
      }),
      negativeAspects: [
        t('data_source_selection.accepts_maximum_1000_product_listings', {
          default: 'It accepts a maximum of 1000 product listings when filtering over custom variables.',
        }),
        t('data_source_selection.updates_only_from_gmc', {
          default: 'Updates are sourced only from Google Merchant Center.',
        }),
        t('data_source_selection.editing_gmc_attributes_not_permitted', {
          default: 'Editing GMC attributes is not permitted.',
        }),
      ],
    },
    scraper_importer: {
      type: 'warning',
      title: t('data_source_selection.suitable_if_no_df_pmax', {
        default:
          "Suitable if you don't have a data feed. It allows you to acquire all the required data for configuring a Performance Max campaign using the website's sitemap.",
      }),
      negativeAspects: [
        t('data_source_selection.data_scraping_slower_method', {
          default: 'Data scraping is a comparatively slower method.',
        }),
        t('data_source_selection.places_demands_on_server_performance', {
          default: 'It places demands on server performance.',
        }),
      ],
    },
    google_spreadsheet_importer: {
      type: 'warning',
      title: tHtml('data_source_selection.google_sheet_in_pmax', {
        default:
          "Using this type of data source for a Performance Max Campaign isn't very typical, but it can be done. <br />Before you begin, make sure the Google Sheet has all the information you need to create a Performance Max Campaign.",
      }),
    },
    google_analytics_4_importer: {
      type: 'warning',
      title: t('data_source_selection.not_ideal_for_pmax', {
        default:
          "This feed isn't ideal for Performance Max Campaigns. Unfortunately, it can't provide all the data needed to create a Performance Max Campaign. Consider using a different source.",
      }),
    },
    facebook_product_catalog_importer: {
      type: 'warning',
      title: t('data_source_selection.not_ideal_fb_for_pmax', {
        default:
          "This data source isn't ideal for Performance Max Campaigns. Using Facebook catalog could lead to a product ID mismatch. Before using this source, ensure that your IDs match those currently in your catalog and Pixel.",
      }),
    },
  },
  FBAdvantagePlusCampaign: {
    csv_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_fb', {
        default: 'This source is ideal for running Facebook Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.replace_data_source_for_catalog', {
          default: 'In the case of using an existing catalog, we replace the data source for the catalog',
        }),
      ],
    },
    xml_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_fb', {
        default: 'This source is ideal for running Facebook Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.replace_data_source_for_catalog', {
          default: 'In the case of using an existing catalog, we replace the data source for the catalog',
        }),
      ],
    },
    json_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_fb', {
        default: 'This source is ideal for running Facebook Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.replace_data_source_for_catalog', {
          default: 'In the case of using an existing catalog, we replace the data source for the catalog',
        }),
      ],
    },
    filtered_data_source_importer: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_fb', {
        default: 'This source is ideal for running Facebook Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.replace_data_source_for_catalog', {
          default: 'In the case of using an existing catalog, we replace the data source for the catalog',
        }),
      ],
    },
    resolve_importer_on_the_fly: {
      type: 'neutral',
      title: t('data_source_selection.ideal_for_fb', {
        default: 'This source is ideal for running Facebook Campaigns.',
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.replace_data_source_for_catalog', {
          default: 'In the case of using an existing catalog, we replace the data source for the catalog',
        }),
      ],
    },
    grouped_data_source_importer: {
      type: 'neutral',
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.replace_data_source_for_catalog', {
          default: 'In the case of using an existing catalog, we replace the data source for the catalog',
        }),
      ],
    },
    merchant_center_importer: {
      type: 'error',
      title: '',
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.loads_data_slower', {
          default: 'Loads data slower than direct feed link - noticable only with really large product portfolio.',
        }),
      ],
    },
    scraper_importer: {
      type: 'warning',
      title: t('data_source_selection.suitable_if_no_df', {
        default:
          "Suitable if you don't have a data feed. It allows you to acquire all the required data for configuring campaign using the website's sitemap.",
      }),
      negativeAspects: [
        t('data_source_selection.some_cases_not_100_of_information', {
          default: 'In some cases there is not 100 % of information',
        }),
        t('data_source_selection.not_suitable_for_large_amounts_of_products', {
          default: 'Not suitable for large amounts of products.',
        }),
      ],
    },
    google_spreadsheet_importer: {
      type: 'warning',
      title: tHtml('data_source_selection.google_sheet_isnt_typical', {
        default:
          "Using this type of data source isn't very typical, but it can be done. <br />Before you begin, make sure the Google Sheet has all the information you need to create campaign.",
      }),
      positiveAspects: [
        t('data_source_selection.real_time_data_updates', { default: 'Real-time data updates.' }),
        t('data_source_selection.accepts_unlimited_product_listings', {
          default: 'Accepts unlimited product listings.',
        }),
        t('data_source_selection.allows_custom_variables_enrichment', {
          default: 'Allows use of custom variables/enrichment to filter products.',
        }),
        t('data_source_selection.enables_facebook_catalog_optimization', {
          default: 'Enables Facebook catalog optimization',
        }),
        t('data_source_selection.adaptable_data_structures', {
          default: 'Adaptable data structures to suit specific needs.',
        }),
        t('data_source_selection.scales_effectively_with_business_growth', {
          default: 'Scales effectively with business growth.',
        }),
      ],
      negativeAspects: [
        t('data_source_selection.sheets_not_usually_updated', { default: 'Sheets do not usually tend to be updated.' }),
        t('data_source_selection.loads_data_slower', {
          default: 'Loads data slower than direct feed link - noticeable only with large product portfolio.',
        }),
      ],
    },
  },
};
