import { useState, useEffect } from 'react';

const useInputState = ({ name, defaultValue = 'default val', formID = null, useExactNames = false }) => {
  const [state, setState] = useState(defaultValue);
  const [invalidSelector, setInvalidSelector] = useState(false);
  let interval;

  useEffect(() => {
    const initFunctions = () => {
      const form = formID && document.getElementById(formID);
      const base = form || document;
      const searchedName = useExactNames ? `[${name}]` : name;

      const elements = base.querySelectorAll(`[name*="${searchedName}"]:not(:disabled)`);
      if (elements.length === 0) {
        clearInterval(interval);
        setInvalidSelector(true);
        return;
      }
      if (elements[0].dataset.initedInputState) {
        clearInterval(interval);
        return;
      }

      elements[0].dataset.initedInputState = true;
      const handleChange = () => {
        setTimeout(() => {
          const last = elements[elements.length - 1];
          if (last.type === 'checkbox') {
            const newValue =
              Array.from(elements)
                .reverse()
                .filter(el => el.checked)
                .map(x => x.value) ||
              elements[0].value ||
              false;
            if (Array.isArray(newValue) && newValue.length === 0) {
              setState(elements[0].value || false);
            } else if (Array.isArray(newValue) && newValue.length === 1) {
              setState(newValue[0]);
            } else {
              setState(newValue);
            }
          } else if (last.type === 'radio') {
            setState(Array.from(elements).filter(el => el.checked)?.[0]?.value || defaultValue);
          } else {
            setState(last?.value);
          }
        }, 10);
      };
      handleChange();
      elements.forEach(el => el.addEventListener('change', handleChange));
      elements.forEach(el => el.addEventListener('keyup', handleChange));

      // eslint-disable-next-line consistent-return
      return () => {
        elements.forEach(el => el.addEventListener('change', handleChange));
        elements.forEach(el => el.addEventListener('keyup', handleChange));
      };
    };
    initFunctions();
    interval = setInterval(initFunctions, 200);
  }, []);

  return { state, invalidSelector };
};

export default useInputState;
