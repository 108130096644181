import React, { PureComponent, useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { gql, useMutation } from '@apollo/client';
import { SortableElement, SortableContainer } from '../../Sortable';
import { t } from '../../../i18n';
import { sortColumns } from '../GraphqlTable';
import arrayMove from '../../../utils/arrayMove';
import {
  SmallHeading,
  Row,
  Col,
  Loader,
  Ellipsis,
  Link,
  Checkbox,
  Input,
  Accordion,
  Button,
  Chip,
  DragHandle,
  Modal,
  ModalBody,
  ModalFooter,
} from '../../index';

const resolveHeader = header => (typeof header === 'function' ? header() : header);

const ColumnSwitch = ({ id, header, disabled, selected, onChange }) => (
  <Chip
    className="mb-8 mr-8"
    onChange={({ target: { value } }) => onChange(value)}
    disabled={disabled}
    id={id}
    value={selected}
    data-test-id={`custom-column-${id}-preset`}
    label={resolveHeader(header)}
    doNotUseInternalState
  />
);
ColumnSwitch.propTypes = {
  id: PropTypes.string,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const ListItem = ({ disabledMove, disabledShow, disabledRemove, value, label, onColumnChange }) => (
  <Row
    center
    className={cs('ShowOnHoverWrapper', 'Preview-tileItem', 'Preview-tileItem--dragable', 'mv-4')}
    style={{ zIndex: 'auto', height: '40px', paddingRight: '4px' }}
  >
    <Col shrink>
      <DragHandle disabled={disabledMove} disabledShow={disabledShow} />
    </Col>
    <Col grow>
      <Ellipsis>{label}</Ellipsis>
    </Col>
    {!disabledRemove && (
      <Col shrink className="ShowOnHoverElement">
        <Button
          data-test-id={`remove-column-${value}-preset`}
          tertiary
          size="small"
          onlyIcon
          icon="close"
          onClick={() => onColumnChange(value)(false)}
        />
      </Col>
    )}
  </Row>
);

ListItem.propTypes = {
  disabledMove: PropTypes.bool,
  disabledRemove: PropTypes.bool,
  disabledShow: PropTypes.bool,
  value: PropTypes.string,
  label: PropTypes.string,
  onColumnChange: PropTypes.func,
};

const SortableItem = SortableElement(ListItem);

const SortableList = SortableContainer(({ columns, doNotShowColumns, mapping, onColumnChange }) => (
  <div>
    {columns.map((value, index) => (
      <SortableItem
        disabledMove={(mapping[value] ? !!mapping[value].fixed : true) || doNotShowColumns.indexOf(value) !== -1}
        disabledRemove={(mapping[value] ? mapping[value].required : true) || doNotShowColumns.indexOf(value) !== -1}
        disabledShow={doNotShowColumns.indexOf(value) !== -1}
        key={`item-${value}`}
        index={index}
        onColumnChange={onColumnChange}
        value={value}
        label={mapping[value] ? resolveHeader(mapping[value].header) : ''}
      />
    ))}
  </div>
));

SortableList.propTypes = {
  columns: PropTypes.any,
  doNotShowColumns: PropTypes.array,
  mapping: PropTypes.object,
  onColumnChange: PropTypes.func,
};

const SubmitColumns = ({
  id,
  collectionKey,
  cancelColumnsChange,
  name,
  updateName,
  updateColumns,
  columns,
  refetchColumnPresets,
}) => {
  const [savePreset, setSavePreset] = useState(!!name);
  const query = gql`
    mutation UpdateColumnPreset($id: BigInt!, $collectionKey: String!, $name: String, $columns: [String!]) {
      updateColumnPreset(id: $id, collection: $collectionKey, name: $name, columns: $columns) {
        columnPreset {
          id
          columns
          name
        }
        errors
      }
    }
  `;

  const [update, { loading }] = useMutation(query, {
    variables: {
      collectionKey,
      name,
      columns,
      id: id || -1,
    },
    onCompleted: () => {
      updateColumns(columns);
      refetchColumnPresets();
    },
  });

  const deleteQuery = gql`
    mutation DeleteColumnPreset($id: BigInt!) {
      deleteColumnPreset(id: $id) {
        errors
      }
    }
  `;

  const [deletePreset, { loading: loadingDelete }] = useMutation(deleteQuery, {
    variables: {
      id,
    },
    onCompleted: () => {
      refetchColumnPresets();
    },
  });

  return (
    <Row center>
      {!id && (
        <React.Fragment>
          <Col shrink>
            <Checkbox
              doNotUserInternalState
              name="column_preset[save]"
              checked={savePreset}
              onChange={({ target: { value } }) => setSavePreset(value)}
              label={t('react.customized_columns.modal.save_preset')}
            />
          </Col>
          {savePreset && (
            <Col width="250px">
              <Input
                doNotUserInternalState
                name="column_preset[name]"
                placeholder={t('react.customized_columns.modal.input.placeholder')}
                value={name}
                onChange={updateName}
              />
            </Col>
          )}
        </React.Fragment>
      )}

      <Col shrink>
        <Button
          primary
          data-test-id="save-custom-column-preset"
          onClick={savePreset && name ? update : () => updateColumns(columns)}
        >
          {(id && t('react.customized_columns.modal.button.save')) ||
            (savePreset && name && t('react.customized_columns.modal.button.save_and_use')) ||
              t('react.customized_columns.modal.button.use')}
        </Button>
      </Col>
      <Col shrink>
        <Button tertiary data-test-id="close-custom-column-preset" onClick={cancelColumnsChange}>
          {t('react.customized_columns.modal.button.cancel')}
        </Button>
      </Col>
      {loading && (
        <Col shrink>
          <Loader size="small" />
        </Col>
      )}
      <Col grow />
      {id && (
        <Col shrink>
          <Link danger icon="trash" data-test-id="delete-custom-column-preset" onClick={deletePreset}>
            {t('react.customized_columns.modal.delete')}
          </Link>
        </Col>
      )}
      {loadingDelete && (
        <Col shrink>
          <Loader size="small" />
        </Col>
      )}
    </Row>
  );
};
SubmitColumns.propTypes = {
  id: PropTypes.any,
  collectionKey: PropTypes.string,
  name: PropTypes.string,
  updateColumns: PropTypes.func,
  updateName: PropTypes.func,
  columns: PropTypes.array,
  refetchColumnPresets: PropTypes.func,
  cancelColumnsChange: PropTypes.func,
};

export default class ColumnSettings extends PureComponent {
  static propTypes = {
    id: PropTypes.any,
    name: PropTypes.string,
    columns: PropTypes.any,
    doNotShowColumns: PropTypes.array,
    collectionKey: PropTypes.string,
    refetchColumnPresets: PropTypes.func,
    onClose: PropTypes.func,
    mapping: PropTypes.object,
    updateColumns: PropTypes.func,
  };

  state = {
    columns: sortColumns(
      this.props.columns.filter(col => this.props.mapping[col]),
      this.props.mapping
    ),
    name: this.props.name,
  };

  useColumns = () => {
    this.props.updateColumns(this.state.columns);
  };

  cancelColumnsChange = () => {
    this.props.updateColumns(this.props.columns);
  };

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(s => ({ columns: sortColumns(arrayMove([...s.columns], oldIndex, newIndex), this.props.mapping) }));
  };

  onColumnChange = column => value => {
    if (value) {
      this.setState(s => ({ columns: [...s.columns, column] }));
    } else {
      this.setState(s => ({ columns: s.columns.filter(c => c !== column) }));
    }
  };

  updateName = ({ target: { value } }) => this.setState({ name: value });

  render() {
    const { onClose, mapping, id, collectionKey, updateColumns, refetchColumnPresets, doNotShowColumns } = this.props;
    const { columns, name } = this.state;

    const mappingArray = Object.values(mapping);
    const mapByCategory = mappingArray.reduce(
      (out, map) => ({ ...out, [map.category]: (out[map.category] || []).concat(map) }),
      {}
    );

    return (
      <Modal
        heading={id ? t('react.customized_columns.modal.edit_header') : t('react.customized_columns.modal.header')}
        onClose={onClose}
        border
      >
        <ModalBody>
          <Row padding="xl">
            <Col
              width="35%"
              className="area--scrollable Grid--overflowWrapper--withOverflowShadow"
              style={{ maxHeight: '70vh', overflowY: 'auto' }}
            >
              {id && (
                <Input
                  doNotUserInternalState
                  label={t('react.customized_columns.modal.input.label')}
                  name="column_preset[name]"
                  placeholder={t('react.customized_columns.modal.input.placeholder')}
                  value={name}
                  onChange={this.updateName}
                />
              )}

              <SmallHeading className={cs({ 'pt-16': !!id })}>
                {t('react.customized_columns.modal.used_columns')}
              </SmallHeading>
              <SortableList
                lockAxis="y"
                helperClass="DragHelper"
                useDragHandle
                columns={columns}
                doNotShowColumns={doNotShowColumns}
                mapping={mapping}
                onSortEnd={this.onSortEnd}
                onColumnChange={this.onColumnChange}
              />
            </Col>
            <Col
              grow
              className="mr-l pl-16 pr-24 area--scrollable Grid--overflowWrapper--withOverflowShadow"
              style={{ maxHeight: '70vh', overflowY: 'auto' }}
            >
              <SmallHeading>{t('react.customized_columns.modal.select_columns')}</SmallHeading>
              {Object.keys(mapByCategory).map(category => (
                <Accordion key={category} header={t(`react.customized_columns.modal.category.${category}`)} forceOpen>
                  <Row flexwrap>
                    {mapByCategory[category].map(m => (
                      <ColumnSwitch
                        key={m.id}
                        {...m}
                        disabled={m.required}
                        onChange={this.onColumnChange(m.id)}
                        selected={columns.indexOf(m.id) !== -1}
                      />
                    ))}
                  </Row>
                </Accordion>
              ))}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <SubmitColumns
            refetchColumnPresets={refetchColumnPresets}
            collectionKey={collectionKey}
            cancelColumnsChange={this.cancelColumnsChange}
            id={id}
            name={this.state.name}
            updateName={this.updateName}
            columns={this.state.columns}
            updateColumns={updateColumns}
          />
        </ModalFooter>
      </Modal>
    );
  }
}
