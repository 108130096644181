import React, { useState } from 'react';
import { Modal, ModalFooter, ModalBody } from '../Modal';
import RemoteModal from '../RemoteModal';
import Button from '../Button';

const ExampleModals = () => {
  const [showNormalModal, updateNormalModal] = useState(false);
  const [showSubModal, updateSubModal] = useState(false);
  const [showWithSubheadingModal, updateWithSubheadingModal] = useState(false);
  const [showRemoteModal, updateRemoteModal] = useState(false);

  return (
    <div>
      <Button primary onClick={() => updateNormalModal(true)}>
        Show Normal Modal
      </Button>
      {showNormalModal && (
        <Modal onClose={() => updateNormalModal(false)} heading="Somerhint wery cool">
          <ModalBody>
            Ahoj Jak se mas?
            <Button primary onClick={() => updateSubModal(true)}>
              Show Sub Modal
            </Button>
            {showSubModal && (
              <Modal size="small" onClose={() => updateSubModal(false)} heading="Somerhint wery cool">
                <ModalBody>Ahoj Jak se mas? - in sub modal</ModalBody>
                <ModalFooter>
                  <Button primary>Some cool function</Button>
                </ModalFooter>
              </Modal>
            )}
          </ModalBody>
          <ModalFooter>
            <Button primary>Some cool function</Button>
          </ModalFooter>
        </Modal>
      )}

      <Button primary onClick={() => updateWithSubheadingModal(true)}>
        Show Normal Modal + subheading
      </Button>
      {showWithSubheadingModal && (
        <Modal onClose={() => updateWithSubheadingModal(false)} heading="Somerhint wery cool" subHeading="Subheading">
          <ModalBody>Ahoj Jak se mas?</ModalBody>
          <ModalFooter>
            <Button primary>Some cool function</Button>
          </ModalFooter>
        </Modal>
      )}

      <Button primary onClick={() => updateRemoteModal(true)}>
        Remote modal
      </Button>
      {showRemoteModal && (
        <RemoteModal
          onClose={() => updateRemoteModal(false)}
          url="/sandbox/sandbox/url_modals/simple_modal"
          heading="Somerhint wery cool"
        />
      )}
    </div>
  );
};

export default ExampleModals;
