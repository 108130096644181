import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ImagePreview } from '../index';
import { getImageObj } from './utils';
import { ShowOnHoverElement, ShowOnHoverWrapper } from '../ShowOnHover';

function ImageSwitcher({ value, onChange, children, item, size, tableVariablesSorted, cardImage, ...rest }) {
  const [stateImage, setImage] = useState(cardImage);
  const imageObj = getImageObj(tableVariablesSorted, item);
  const image = value || stateImage;

  const activeImageObj =
    imageObj[image] || imageObj[`${image}#0`] || imageObj[image?.replace('#0', '')] || Object.values(imageObj)[0] || {};

  return (
    <ShowOnHoverWrapper>
      <div className="pos-relative">
        <ImagePreview key={activeImageObj.url || 'unknown'} {...rest} size={size} url={activeImageObj.url}>
          {Object.keys(imageObj).length > 1 && (
            <ShowOnHoverElement>
              <Button
                data-test-id="image-switch-left"
                onlyIcon
                secondary
                icon="chevron-left"
                size="small"
                style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)' }}
                onClick={() => {
                  if (typeof onChange === 'function') onChange(activeImageObj.prev);
                  setImage(activeImageObj.prev);
                }}
              />
              <Button
                data-test-id="image-switch-right"
                onlyIcon
                secondary
                icon="chevron-right"
                size="small"
                style={{ position: 'absolute', right: '0', top: '50%', transform: 'translateY(-50%)' }}
                onClick={() => {
                  if (typeof onChange === 'function') onChange(activeImageObj.next);
                  setImage(activeImageObj.next);
                }}
              />
            </ShowOnHoverElement>
          )}
          {children}
        </ImagePreview>
      </div>
    </ShowOnHoverWrapper>
  );
}
ImageSwitcher.propTypes = {
  children: PropTypes.any,
  value: PropTypes.string,
  onChange: PropTypes.func,
  item: PropTypes.object,
  size: PropTypes.number,
  withoutTile: PropTypes.bool,
  cardImage: PropTypes.string,
  tableVariablesSorted: PropTypes.array,
};

export default ImageSwitcher;
