import React from 'react';
import { PropTypes } from 'prop-types';
import { ProgressBar } from './index';
import { getValues } from './utils';
import { Col, Icon, Row, Text } from '../index';
import { t, tHtml } from '../../i18n';
import { FEATURES } from './constants';

const computeWidth = (trialDays, trialRemainingDays) => `${(100 * (trialDays - trialRemainingDays)) / trialDays}%`;

export const CircleIcon = ({ feature, disable, circleSize = '32px', iconSize = '16px', ...rest }) => {
  const { icon, circleColor } = FEATURES[feature];
  const saturation = disable ? { backgroundColor: '#E5E7E9' } : {};
  return (
    <span
      {...rest}
      style={{
        height: circleSize,
        width: circleSize,
        borderRadius: '50%',
        backgroundColor: circleColor,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...saturation,
      }}
    >
      <Icon kind={icon} color={disable ? '#8C969F' : '#ffffff'} size={iconSize} />
    </span>
  );
};
export const SubscriptionFeature = ({
  feature,
  featureStatus,
  featuresPricing,
  heading,
  children,
  tarif,
  text,
  trialDays,
  trialRemainingDays,
  endsInDays,
  isModal,
}) => {
  const properties = getValues({
    tarif,
    featureStatus,
    trialRemainingDays,
    featuresPricing,
    expiredDays: endsInDays,
    trialDays,
    isFeatureUsed: true,
  });
  return (
    <React.Fragment>
      <Row center>
        <Col shrink className="mr-16">
          <CircleIcon feature={feature} circleSize="40px" iconSize="24px" />
        </Col>
        <Col grow>
          <Row noPadding className="mb-0" center>
            <p className="Text Text--default Text--bold">{heading}</p>
          </Row>
          <Row shrink>
            <Text tag="p" size="sm" color="softGray" semibold>
              {t(feature, { scope: 'feature_period.subscription_feature.pricing_by' })}
            </Text>
          </Row>
        </Col>
        <Col shrink>
          <Row noPadding className="mb-0" center>
            {properties?.label || (isModal && properties?.modal?.label) ? (
              <ProgressBar
                {...properties}
                isModal={isModal}
                width={properties?.compute ? computeWidth(trialDays, trialRemainingDays) : '100%'}
              />
            ) : null}
          </Row>
          <Row shrink justifyCenter>
            {!properties?.subheading || (isModal && properties?.modal?.disableSubheading) ? null : (
              <Text tag="p" size="sm" color="softGray" semibold>
                {isModal && properties?.modal?.subheading ? properties.modal.subheading : properties.subheading}
              </Text>
            )}
          </Row>
        </Col>
      </Row>
      {text && (
        <Row grow>
          <Text tag="p" size="sm" color="gray" semibold>
            {tHtml(text)}
          </Text>
        </Row>
      )}
      {children && <Row>{children}</Row>}
    </React.Fragment>
  );
};

CircleIcon.propTypes = {
  circleSize: PropTypes.string,
  disable: PropTypes.bool,
  feature: PropTypes.oneOf([
    'campaign_products',
    'campaign_spend_facebook',
    'campaign_spend_search',
    'image_generator_campaigns',
    'image_generator_feed_export',
    'scraper',
    'feed_export',
    'synchronizations',
  ]).isRequired,
  iconSize: PropTypes.string,
};

SubscriptionFeature.propTypes = {
  active: PropTypes.bool,
  endsInDays: PropTypes.number,
  feature: PropTypes.oneOf([
    'campaign_products',
    'campaign_spend_facebook',
    'campaign_spend_search',
    'image_generator_campaigns',
    'image_generator_feed_export',
    'scraper',
    'feed_export',
    'synchronizations',
  ]).isRequired,

  featuresPricing: PropTypes.array,
  featureStatus: PropTypes.string,
  heading: PropTypes.string.isRequired,
  children: PropTypes.any,
  isModal: PropTypes.bool,
  tarif: PropTypes.string,
  text: PropTypes.string.isRequired,
  trialDays: PropTypes.number,
  trialRemainingDays: PropTypes.number,
};
